import { compose } from 'redux';
import { useEffect } from 'react';
import { initialize } from 'redux-form/immutable';
import { connect, useDispatch } from 'react-redux';
import { Unarchive } from '@material-ui/icons';

import withStyles from '@material-ui/styles/withStyles';
import {
  Grid,
  Button,
  Typography,
  TableContainer,
  Paper,
} from '@material-ui/core';

import { BasePage } from 'altus-ui-components';

import {
  onLoad,
  onUnload,
  submitUpdateProject,
  deleteProject,
  updateProjectExternalId,
  updateProjectMeasurementPreference,
  archiveProject,
} from 'features/projects/details/details.actions';

import {
  DETAILS_ACTIONS,
  EDIT_PROJECT_FORM,
} from 'features/projects/details/details.constants';

import {
  TASK_VIEWS,
  TASK_ACTIONS,
} from 'features/projects/tasks/tasks.constants';

import {
  getActionDataStateFromState,
  getSummarizedDataStateFromState,
  getAvailableDepartmentsFromState,
} from 'app/app.selectors';

import { useHeader } from 'app/hooks/useHeader';
import TaskTableChart from 'features/projects/tasks/TaskTableChart';
import HasSystemPermission from 'app/components/HasSystemPermission';
import { SystemPermission, ProjectPermission } from 'app/app.constants';
import { getCurrentProject } from 'features/projects/projects.selectors';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import { getTaskTemplates } from 'features/projects/tasks/tasks.selectors';
import { getAllTasksFromState } from 'features/projects/tasks/tasks.selectors';
import { toggleModal, initializeMeasurementPreferences } from 'app/app.actions';
import { useProjectPermissions } from 'app/hooks/authorization/useProjectPermissions';
import { ACTIVITIES_ACTIONS } from 'features/projects/activities/activities.constants';
import ProjectDetailsForm from 'features/projects/details/components/ProjectDetailsForm';
import { getAllTasksWithProjects } from 'features/projects/activities/activities.actions';
import RiskMatrixComponent from 'features/projects/details/projectRisk/RiskMatrixComponent';
import { useTheme } from '@material-ui/core/styles';

const ProjectDetailsContainer = ({
  tasks,
  classes,
  projectId,
  dataState,
  breadcrumb,
  departments,
  templateTasks,
  dispatchOnLoad,
  dispatchOnUnLoad,
  dispatchGetTasks,
  dispatchCopyTask,
  dispatchSortTasks,
  dispatchDeleteTask,
  dispatchToggleModal,
  totalPlannedDuration,
  totalElapsedDuration,
  dispatchDeleteProject,
  dispatchUpdateProject,
  dispatchArchiveProject,
  updateExternalIdDataState,
  dispatchUpdateProjectExternalId,
  dispatchUpdateMeasurementPreference,
  dispatchInitializeMeasurementPreferences,
}) => {
  const dispatch = useDispatch();
  const project = useCurrentProject();
  const isArchive = project.get('isArchive');
  const theme = useTheme();

  const archiveProject = (isArchiveProject) => {
    dispatchArchiveProject(projectId, isArchiveProject);
  };

  useHeader({
    subTitle: breadcrumb,
  });

  useEffect(() => {
    dispatchOnLoad(projectId);
    dispatchGetTasks(projectId);

    return () => dispatchOnUnLoad();
  }, [projectId, dispatchOnLoad, dispatchOnUnLoad, dispatchGetTasks]);

  useEffect(() => {
    dispatch(initialize(EDIT_PROJECT_FORM.FORM_ID, project));
  }, [dispatch, project]);

  const { hasPermission } = useProjectPermissions(
    ProjectPermission.EDIT_PROJECT_DETAILS,
  );

  const { hasPermission: hasCreateTaskPermission } = useProjectPermissions(
    ProjectPermission.CREATE_TASKS,
  );

  return (
    <BasePage dataState={dataState}>
      <Grid container item xs>
        <Grid
          item
          xs={8}
          className={classes.contentContainer}
          style={{ paddingTop: isArchive ? '1vh' : 0 }}
        >
          <Typography variant="h6">Project details</Typography>
          <ProjectDetailsForm
            project={project}
            projectId={projectId}
            departments={departments}
            hasPermission={hasPermission}
            hasCreateTaskPermission={hasCreateTaskPermission}
            templateTasks={templateTasks}
            toggleModal={dispatchToggleModal}
            updateProject={dispatchUpdateProject}
            selectMeasurementPreference={dispatchUpdateProject}
            updateExternalIdDataState={updateExternalIdDataState}
            updateProjectExternalId={dispatchUpdateProjectExternalId}
            updateMeasurementPreference={dispatchUpdateMeasurementPreference}
            initializeMeasurementPreferences={
              dispatchInitializeMeasurementPreferences
            }
            isArchive={isArchive}
          />
          <Grid
            style={{
              marginTop: '3vh',
              marginBottom: '2hv',
            }}
          >
            <Typography variant="h6">Risks</Typography>
            <TableContainer component={Paper} className={classes.paper}>
              <RiskMatrixComponent allRisks={project.get('allRisks')} />
            </TableContainer>
          </Grid>
          <Grid
            style={{
              marginTop: '3vh',
              marginBottom: '2hv',
            }}
          >
            <Typography variant="h6">Tasks</Typography>
            <TableContainer component={Paper} className={classes.paper}>
              <TaskTableChart
                tasks={tasks}
                project={project}
                showContigency={false}
                projectId={projectId}
                copyTask={dispatchCopyTask}
                sortTasks={dispatchSortTasks}
                defaultView={TASK_VIEWS.CHART}
                deleteTask={dispatchDeleteTask}
                totalPlannedDuration={totalPlannedDuration}
                totalElapsedDuration={totalElapsedDuration}
                showToggleButtonOption={false}
                showActivities={false}
                showDefaultButtons={false}
                showNavigatorChart={false}
                chartBackgroundColor={theme.palette.secondary.darkGrey}
              />
            </TableContainer>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '10px',
            }}
          >
            {!project.get('isDeleted') && (
              <HasSystemPermission
                permissions={SystemPermission.DELETE_PROJECTS}
              >
                <Button
                  onClick={() => dispatchDeleteProject(project.get('id'))}
                >
                  DELETE PROJECT
                </Button>
              </HasSystemPermission>
            )}
            {isArchive ? (
              <>
                <Button
                  onClick={() => archiveProject(false)}
                  color={'primary'}
                  variant="contained"
                >
                  <Unarchive style={{ marginRight: '10px' }} />
                  RESTORE PROJECT
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => archiveProject(true)}
                  variant="contained"
                >
                  <Unarchive style={{ marginRight: '10px' }} />
                  ARCHIVE PROJECT
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  deleteProjectBtn: {
    zIndex: 1,
    position: 'absolute',
    left: theme.spacing(4),
    bottom: theme.spacing(4),
  },
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
  contentContainer: {
    marginLeft: '17%',
  },
  paper: {
    paddingTop: '4vh',
    paddingBottom: '4vh',
    paddingRight: '6%',
    paddingLeft: '6%',
    borderRadius: '0px !important',
  },
});

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  tasks: getAllTasksFromState(state),
  templateTasks: getTaskTemplates(state),
  departments: getAvailableDepartmentsFromState(state),
  dataState: getSummarizedDataStateFromState(
    state,
    DETAILS_ACTIONS.PROJECT_DETAILS_PAGE_LOADED,
    DETAILS_ACTIONS.DELETE_PROJECT,
    TASK_ACTIONS.COPY_TASK,
    TASK_ACTIONS.SORT_TASKS,
    TASK_ACTIONS.GET_ALL_TASKS,
    TASK_ACTIONS.CONFIRM_DELETE_TASK,
    TASK_ACTIONS.PROJECT_TASKS_PAGE_LOADED,
    ACTIVITIES_ACTIONS.GET_TASK_ACTIVITIES,
  ),
  updateExternalIdDataState: getActionDataStateFromState(
    state,
    DETAILS_ACTIONS.UPDATE_EXTERNAL_ID,
  ),
});

const mapDispatchToProps = (dispatch) => {
  const dispatchUpdateProject = () => dispatch(submitUpdateProject);
  const dispatchToggleModal = (modalId) => dispatch(toggleModal({ modalId }));

  return {
    dispatchToggleModal,
    dispatchUpdateProject,
    dispatchGetTasks: (projectId) =>
      dispatch(getAllTasksWithProjects(projectId)),
    dispatchOnUnLoad: () => dispatch(onUnload()),
    dispatchOnLoad: (projectId) => dispatch(onLoad(projectId)),
    dispatchInitializeMeasurementPreferences: (system, preferences) =>
      dispatch(initializeMeasurementPreferences(system, preferences)),
    dispatchDeleteProject: (projectId) => dispatch(deleteProject(projectId)),
    dispatchUpdateMeasurementPreference: (values, projectId) =>
      dispatch(updateProjectMeasurementPreference(projectId, values)),
    dispatchUpdateProjectExternalId: (projectId) =>
      dispatch(updateProjectExternalId(projectId)),
    dispatchArchiveProject: (projectId, isArchive) =>
      dispatch(archiveProject(projectId, isArchive)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ProjectDetailsContainer);
