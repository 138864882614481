import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { Button } from 'app/components/withNavigation';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { toMobileProjectStatus } from 'utils/route.util';

const ProjectStatusOverviewContainer = ({ classes, status, projectId }) => {
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="body2" align="left" color="textSecondary">
          Project Status
        </Typography>
      </Grid>
      <Grid container>
        <Grid item xs={8}>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              {status.getIn(['current', 'taskNumber'])}.{' '}
              {status.getIn(['current', 'task', 'customTitle'])}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {status.getIn(['current', 'activity', 'name'])}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Button
            to={toMobileProjectStatus(projectId)}
            variant="contained"
            color="primary"
            className={classes.statusButton}
          >
            Status
            <ChevronRight className={classes.buttonIcon} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  durationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(2),
  },
  durationItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonIcon: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0),
  },
  statusButton: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
});

export default withStyles(styles)(ProjectStatusOverviewContainer);
