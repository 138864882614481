import Close from '@material-ui/icons/Close';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, AppBar, Typography, IconButton } from '@material-ui/core';

import CreateProjectModalAppBarTextField from 'features/projects/components/CreateProjectModalContainer/CreateProjectModalAppBarTextField';

const CreateProjectModalAppBar = ({
  title,
  items,
  classes,
  toggleModal,
  clearFields,
  currentIndex,
  setCurrentIndex,
}) => (
  <AppBar color="default" position="static" className={classes.appBarRoot}>
    <Grid container>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={4} />
          <Grid item xs={4} align="center">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6">{title}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} align="right">
            <IconButton
              disableRipple
              title="Close"
              color="inherit"
              onClick={toggleModal}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} />
      <Grid container spacing={2} className={classes.inputContainer}>
        {items.map((item, index) => (
          <Grid item xs key={index}>
            <CreateProjectModalAppBarTextField
              item={item}
              index={index}
              clearFields={clearFields}
              setCurrentIndex={setCurrentIndex}
              isActive={currentIndex === index}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </AppBar>
);

const styles = (theme) => ({
  appBarRoot: {
    padding: theme.spacing(3),
    background: theme.palette.grey[900],
  },
  inputContainer: {
    padding: theme.spacing(2.5),
  },
});

export default withStyles(styles)(CreateProjectModalAppBar);
