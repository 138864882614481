import { compose } from 'redux';
import { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';

import routePaths from 'app/routePaths';
import { toFacility } from 'utils/route.util';
import { useHeader } from 'app/hooks/useHeader';
import BackButton from 'app/components/BackButton';
import { EMPTY_MAP } from 'app/app.constants';

import {
  getApplicationHeaderFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';

import LogoAndAppTitle from 'app/components/ApplicationHeader/LogoAndAppTitle';
import UserMenuContainer from 'app/components/ApplicationHeader/UserMenuContainer';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import ApplicationHeaderTitle from 'app/components/ApplicationHeader/ApplicationHeaderTitle';

import { getFacilityById } from 'features/facilities/facilities.actions';
import { FACILITIES_ACTIONS } from 'features/facilities/facilities.constants';
import { getFacilityByIdFromState } from 'features/facilities/facilities.selectors';

const FacilityHeader = ({ facilityId, dispatchOnLoad }) => {
  const applicationHeader = useSelector(getApplicationHeaderFromState);

  const logoNavigation = applicationHeader.get('logoNavigation');

  const defaultPreviousPage = toFacility(facilityId);

  const facility = useSelector(getFacilityByIdFromState);

  useHeader({
    title: facility === EMPTY_MAP ? '' : `${facility.get('name')}`,
    logoNavigation: routePaths.facilities.root,
  });

  useEffect(() => {
    dispatchOnLoad(facilityId);
  }, [dispatchOnLoad, facilityId]);

  return (
    <ApplicationHeaderBase>
      <Grid
        container
        spacing={2}
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs>
          <Switch>
            <Route path={routePaths.wells.events}>
              <BackButton to={defaultPreviousPage} />
            </Route>
            <Route>
              <LogoAndAppTitle to={logoNavigation} />
            </Route>
          </Switch>
        </Grid>
        <Grid item xs={8}>
          <ApplicationHeaderTitle />
        </Grid>
        <Grid item xs>
          <Grid container justifyContent="flex-end" alignItems="center">
            <UserMenuContainer />
          </Grid>
        </Grid>
      </Grid>
    </ApplicationHeaderBase>
  );
};

export default compose(
  connect(
    (state) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        FACILITIES_ACTIONS.GET_FACILITY_BY_ID,
      ),
    }),
    {
      dispatchOnLoad: getFacilityById,
    },
  ),
)(FacilityHeader);
