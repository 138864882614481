import { compose } from 'redux';
import { useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useState, useCallback } from 'react';
import { arrayMoveImmutable } from 'array-move';
import { connect, useDispatch } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, Button } from '@material-ui/core';

import { useModal } from 'altus-modal';
import { BasePage } from 'altus-ui-components';

import {
  saveCableToTask,
  deleteCableFromTask,
  onLoadTaskSurfaceEquipment,
  saveSurfaceToTask,
  saveSurfaceTemplateToTask,
  deleteSurfaceEquipmentFromTask,
} from 'features/projects/tasks/tasks.actions';

import {
  getTaskFromState,
  getTaskCablesFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';

import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { TASK_MODALS } from 'features/projects/tasks/task/task.constants';
import { EQUIPMENT_ACTIONS } from 'features/equipment/equipment.constants';
import { TASK_SURFACE_EQUIPMENT_ACTIONS } from 'features/projects/tasks/tasks.constants';
import { getTaskSurfaceEquipmentFromState } from 'features/projects/tasks/tasks.selectors';
import AddedCablesTable from 'features/projects/tasks/task/surfaceEquipment/components/cables/AddedCablesTable';
import AddCablesModalContainer from 'features/projects/tasks/task/surfaceEquipment/components/cables/AddCablesModalContainer';
import AddedSurfaceEquipmentInPlanningTable from 'features/projects/tasks/task/surfaceEquipment/components/surfaceEquipment/AddedSurfaceEquipmentInPlanningTable';
import AddSurfaceEquipmentInPlanningModalContainer from 'features/projects/tasks/task/surfaceEquipment/components/surfaceEquipment/AddSurfaceEquipmentInPlanningModalContainer';

const TaskSurfaceEquipmentContainer = ({
  cables,
  taskId,
  classes,
  projectId,
  dataState,
  dispatchOnLoad,
  surfaceEquipmentFromState,
}) => {
  const dispatch = useDispatch();
  const [selectedCables, setSelectedCables] = useState([]);
  const [selectedSurfaceEquipment, setSelectedSurfaceEquipment] = useState([]);

  const [isOpenAddCable, toggleModalAddCable] = useModal(
    TASK_MODALS.ADD_CABLE_MODAL,
  );

  const [isOpenAddSurfaceEquipment, toggleModalAddSurfaceEquipment] = useModal(
    TASK_MODALS.SURFACE_EQUIPMENT_MODAL,
  );

  useEffect(() => {
    if (cables.size) setSelectedCables(cables.toJS());
  }, [cables]);

  useEffect(() => {
    if (!isNaN(surfaceEquipmentFromState?.size))
      setSelectedSurfaceEquipment(surfaceEquipmentFromState.toJS());
  }, [surfaceEquipmentFromState]);

  useEffect(() => {
    dispatchOnLoad(projectId, taskId);
  }, [taskId, dispatchOnLoad, projectId]);

  const onSaveCable = useCallback(
    (cable) => {
      setSelectedCables((prev) => [...prev, cable.toJS()]);
      const taskCable = {
        taskId,
        cableId: cable.get('externalGuid'),
      };
      dispatch(saveCableToTask(taskCable, projectId, taskId));
    },
    [dispatch, projectId, taskId],
  );

  const onSaveSurfaceEquipment = useCallback(
    (tool) => {
      setSelectedSurfaceEquipment((prev) => [...prev, tool.toJS()]);
      const taskSurfaceTool = {
        taskId,
        surfaceId: tool.get('externalId'),
      };
      dispatch(saveSurfaceToTask(taskSurfaceTool, projectId, taskId));
    },
    [dispatch, projectId, taskId],
  );

  const onSaveSurfaceEquipmentTemplate = useCallback(
    (template) => {
      const taskSurfaceTool = {
        taskId,
        templateId: template.get('id'),
      };
      dispatch(saveSurfaceTemplateToTask(taskSurfaceTool, projectId, taskId));
    },
    [dispatch, projectId, taskId],
  );

  const onRemoveCable = useCallback(
    (cable) => {
      const taskCable = {
        TaskCableId: null,
        TaskId: taskId,
        CableId: cable.get('externalGuid'),
      };
      dispatch(deleteCableFromTask(taskCable, projectId, taskId));
    },
    [dispatch, projectId, taskId],
  );

  const onRemoveSurface = useCallback(
    (tool) => {
      const surfaceEquipment = {
        taskId: taskId,
        surfaceId: tool.get('externalId'),
      };
      dispatch(
        deleteSurfaceEquipmentFromTask(surfaceEquipment, projectId, taskId),
      );
    },
    [dispatch, projectId, taskId],
  );

  const onDuplicateItem = useCallback(
    (cable) => {
      const cables = [...selectedCables];
      const cableIndex = cables.indexOf(
        cables.find((cableX) => cableX.externalGuid === cable.externalGuid),
      );
      cables.push(cable);
      const lastCableIndex = cables.lastIndexOf(cable);
      const newCables = arrayMoveImmutable(
        cables,
        lastCableIndex,
        cableIndex + 1,
      );
      setSelectedCables(newCables);
      const taskCable = {
        taskId,
        cableId: cable.externalGuid,
      };
      dispatch(saveCableToTask(taskCable, projectId, taskId));
    },
    [selectedCables, dispatch, projectId, taskId],
  );

  const onDuplicateToolItem = useCallback(
    (cable) => {
      const tools = [...selectedSurfaceEquipment];
      const cableIndex = tools.indexOf(
        tools.find((cableX) => cableX.id === cable.id),
      );
      tools.push(cable);
      const lastCableIndex = tools.lastIndexOf(cable);
      const newCables = arrayMoveImmutable(
        tools,
        lastCableIndex,
        cableIndex + 1,
      );
      setSelectedSurfaceEquipment(newCables);
      const taskSurfaceTool = {
        taskId,
        surfaceId: cable.externalId,
      };
      dispatch(saveSurfaceToTask(taskSurfaceTool, projectId, taskId));
    },
    [selectedSurfaceEquipment, dispatch, projectId, taskId],
  );

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container justifyContent="center" spacing={6}>
        <Grid
          item
          container
          lg={8}
          xs={12}
          className={classes.equipmentContainer}
        >
          <Grid item container xs={12} justifyContent="space-between">
            <Typography variant="h5">Cables</Typography>
            <Button
              size="small"
              color="primary"
              variant="contained"
              title="Add cable"
              onClick={toggleModalAddCable}
            >
              <AddIcon className={classes.leftIcon} />
              Add cable
            </Button>
          </Grid>
          {selectedCables.length === 0 ? (
            <Grid item container xs={12} className={classes.emptyEquipment}>
              <Typography variant="caption" color="textSecondary">
                No cables added.
              </Typography>
            </Grid>
          ) : (
            <AddedCablesTable
              onRemoveCable={onRemoveCable}
              selectedCables={selectedCables}
              onDuplicateItem={onDuplicateItem}
              setSelectedCables={setSelectedCables}
            />
          )}
        </Grid>
        <Grid
          item
          container
          lg={8}
          xs={12}
          className={classes.equipmentContainer}
        >
          <Grid item container xs={12} justifyContent="space-between">
            <Typography variant="h5">Surface Equipment</Typography>
            <Button
              size="small"
              color="primary"
              variant="contained"
              title="Add surface equipment"
              onClick={toggleModalAddSurfaceEquipment}
            >
              <AddIcon className={classes.leftIcon} />
              Add surface equipment
            </Button>
          </Grid>
          {selectedSurfaceEquipment.length === 0 ? (
            <Grid item container xs={12} className={classes.emptyEquipment}>
              <Typography variant="caption" color="textSecondary">
                No surface equipment added.
              </Typography>
            </Grid>
          ) : (
            <AddedSurfaceEquipmentInPlanningTable
              onRemoveSurface={onRemoveSurface}
              onDuplicateToolItem={onDuplicateToolItem}
              selectedSurfaceEquipment={selectedSurfaceEquipment}
              setSelectedSurfaceEquipment={setSelectedSurfaceEquipment}
            />
          )}
        </Grid>
      </Grid>
      <AddCablesModalContainer
        classes={classes}
        dataState={dataState}
        isOpen={isOpenAddCable}
        onAddCable={onSaveCable}
        onRemoveCable={onRemoveCable}
        selectedCables={selectedCables}
        toggleModal={toggleModalAddCable}
        setSelectedCables={setSelectedCables}
        projectId={projectId}
      />
      <AddSurfaceEquipmentInPlanningModalContainer
        classes={classes}
        dataState={dataState}
        onRemoveSurface={onRemoveSurface}
        isOpen={isOpenAddSurfaceEquipment}
        showTemplateSelection={true}
        toggleModal={toggleModalAddSurfaceEquipment}
        onSaveSurfaceEquipment={onSaveSurfaceEquipment}
        selectedSurfaceEquipment={selectedSurfaceEquipment}
        setSelectedSurfaceEquipment={setSelectedSurfaceEquipment}
        onSaveSurfaceEquipmentTemplate={onSaveSurfaceEquipmentTemplate}
      />
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
  leftIcon: {
    marginRight: 10,
  },
  emptyEquipment: {
    border: `thin solid ${theme.palette.grey[700]}`,
    justifyContent: 'center',
    alignItems: 'center',
    height: '15vh',
  },
  equipmentContainer: {
    gap: theme.spacing(2.25),
  },
  actions: {
    display: 'flex',
    width: '100%',
    direction: 'row',
    justifyContent: 'flex-end',
  },
});

const mapStateToProps = (state) => ({
  task: getTaskFromState(state),
  cables: getTaskCablesFromState(state),
  surfaceEquipmentFromState: getTaskSurfaceEquipmentFromState(state),
  dataState: getSummarizedDataStateFromState(
    state,
    EQUIPMENT_ACTIONS.SEARCH_CABLES,
    EQUIPMENT_ACTIONS.SEARCH_EQUIPMENT,
    EQUIPMENT_ACTIONS.CABLES_LOADED_BY_PROJECTID,
    TASK_SURFACE_EQUIPMENT_ACTIONS.SAVE_CABLE,
    EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_LOADED,
    TASK_SURFACE_EQUIPMENT_ACTIONS.PAGE_LOADED,
    TASK_SURFACE_EQUIPMENT_ACTIONS.DELETE_CABLE,
    TASK_SURFACE_EQUIPMENT_ACTIONS.CABLES_LOADED,
    EQUIPMENT_ACTIONS.RECEIVE_SURFACE_EQUIPMENT_GROUPS,
    EQUIPMENT_ACTIONS.CABLES_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoadTaskSurfaceEquipment,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(TaskSurfaceEquipmentContainer);
