import { useSelector } from 'react-redux';
import { LinearProgress } from '@material-ui/core';

import { PROJECT_STATUS } from 'app/app.constants';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';

const ProjectLinearProgress = () => {
  const projectStatus = useSelector(getExecuteStatusFromState);

  const status = projectStatus.get('status');

  if (status !== PROJECT_STATUS.EXECUTE) return null;

  return (
    <LinearProgress
      variant="determinate"
      value={projectStatus.get('progress')}
    />
  );
};

export default ProjectLinearProgress;
