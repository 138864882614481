import { HubConnectionState } from '@microsoft/signalr';
import { useState, useEffect, useCallback } from 'react';

import { invokeIfFunction } from 'utils/app.util';

const useStream = (
  connection,
  connectionState,
  createStream,
  onStartStreaming,
  onStopStreaming,
) => {
  const [stream, setStream] = useState();

  const startStreaming = useCallback(() => {
    if (!createStream) return;

    createStream(connection).then((stream) =>
      setStream((currentStream) => {
        if (currentStream) {
          currentStream.complete();
        }

        invokeIfFunction(onStartStreaming, stream);

        return stream;
      }),
    );
  }, [connection, createStream, onStartStreaming]);

  const stopStreaming = useCallback(() => {
    setStream((currentStream) => {
      if (!currentStream) return;

      currentStream.complete();

      return null;
    });

    invokeIfFunction(onStopStreaming);
  }, [onStopStreaming]);

  useEffect(() => {
    switch (connectionState) {
      case HubConnectionState.Connected: {
        startStreaming();
        break;
      }

      case HubConnectionState.Reconnecting:
      case HubConnectionState.Disconnecting:
      case HubConnectionState.Disconnected: {
        stopStreaming();
        break;
      }

      default:
        break;
    }
  }, [connectionState, stopStreaming, startStreaming]);

  useEffect(() => {
    return () => stopStreaming();
  }, [stopStreaming]);

  return stream;
};

export default useStream;
