import _isNil from 'lodash/isNil';
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { Collection } from 'immutable';
import HighchartsReact from 'highcharts-react-official';

import { formatValue } from 'utils/format.util';
import { EMPTY_MAP } from 'app/app.constants';
import { getQuantityUnitFromItems } from 'utils/app.util';
import {
  getSimulationChartSeries,
  getSimulationCompressionDepthSeries,
} from 'features/projects/tasks/task/simulation/simulation.utils';
import {
  SimulationCharts,
  SimulationDirection,
  SimulationResultSeries,
} from 'features/projects/tasks/task/simulation/simulation.constants';
import { useTheme } from '@material-ui/core/styles';

const SimulationResultsChart = ({
  tab,
  simulationResults,
  simulationResultsRih = EMPTY_MAP,
  simulationResultsPooh = EMPTY_MAP,
  operationLoadPercent,
}) => {
  const theme = useTheme();
  const { label, activeSeries } = tab;
  const chartRef = useRef(null);
  const tabKey = useRef(null);
  const [chartOptions, setChartOptions] = useState({ series: {}, chart: {} });

  useEffect(() => {
    const depthUnit = getQuantityUnitFromItems(
      simulationResults !== EMPTY_MAP
        ? simulationResults
        : simulationResultsRih,
      (result) => result.get('measuredDepth'),
    );

    const xLabel = (tab) => {
      const path = SimulationCharts.find((c) => c.key === tab.key)?.unit;
      if (!_isNil(path)) {
        const u = getQuantityUnitFromItems(
          simulationResults !== EMPTY_MAP
            ? simulationResults
            : simulationResultsRih,
          (result) => result.getIn(path),
        );
        return `${label} (${u})`;
      }
      return label;
    };

    let simulationChartSeries;

    if (simulationResults !== EMPTY_MAP) {
      simulationChartSeries = getSimulationChartSeries(
        simulationResults,
        operationLoadPercent,
      )
        .filter((series) => activeSeries?.includes(series.id))
        .map((series) =>
          tab.key === tabKey.current
            ? { id: series.id, data: series.data } // Only update data when tab is unchanged
            : series,
        ); // Set series to initial configuration on a new tab
    } else {
      let simulationChartSeriesRih;
      let simulationChartSeriesPooh;

      if (simulationResultsRih !== EMPTY_MAP) {
        simulationChartSeriesRih = getSimulationChartSeries(
          simulationResultsRih,
          operationLoadPercent,
          undefined,
          SimulationDirection.RUN_IN_HOLE,
        )
          .filter((series) => activeSeries?.includes(series.id))
          .map((series) =>
            tab.key === tabKey.current
              ? { id: series.id, data: series.data } // Only update data when tab is unchanged
              : series,
          ); // Set series to initial configuration on a new tab
      }

      if (simulationResultsPooh !== EMPTY_MAP) {
        simulationChartSeriesPooh = getSimulationChartSeries(
          simulationResultsPooh,
          operationLoadPercent,
          undefined,
          SimulationDirection.PULL_OUT_OF_HOLE,
        )
          .filter((series) => activeSeries?.includes(series.id))
          .map((series) =>
            tab.key === tabKey.current
              ? { id: series.id, data: series.data } // Only update data when tab is unchanged
              : series,
          ); // Set series to initial configuration on a new tab
      }

      simulationChartSeries = [
        ...simulationChartSeriesRih,
        ...simulationChartSeriesPooh,
      ];
    }

    // Add compression depth if it is defined on the tab
    if (!_isNil(tab.compressionDepth)) {
      tab.compressionDepth.forEach((compression) => {
        const compressionSeries = getSimulationCompressionDepthSeries(
          compression.series + SimulationResultSeries.COMPRESSION_DEPTH,
          compression.direction,
          simulationResults, // TODO check what results to display
          compression.value,
        );
        if (!_isNil(compressionSeries)) {
          simulationChartSeries = [...simulationChartSeries, compressionSeries];
        }
      });
    }

    var result = {
      series: simulationChartSeries,
      chart: {
        inverted: true,
        backgroundColor: theme.palette.grey[800],
      },
      yAxis: [
        {
          allowDecimals: false,
          gridLineDashStyle: 'ShortDash',
          gridLineColor: theme.palette.grey[700],
          title: {
            text: xLabel(tab),
          },
          plotLines: [
            {
              value: 0,
              width: 2,
              color: theme.palette.grey[300],
            },
          ],
        },
      ],
      xAxis: {
        min: 0,
        gridLineWidth: 1,
        allowDecimals: false,
        gridLineDashStyle: 'ShortDash',
        gridLineColor: theme.palette.grey[700],
        max: simulationResults.keySeq().last(), // TODO check what results to display
        title: {
          text: `Tool Depth (${depthUnit})`,
        },
      },
      legend: {
        shadow: false,
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        backgroundColor: 'transparent',
      },
      tooltip: {
        shared: true,
        crosshairs: true,
        formatter() {
          let format = `<b>Depth: ${formatValue(this.x, depthUnit)}</b>`;

          this.points.forEach((point) => {
            const { series, y } = point;
            const {
              color,
              name,
              options: {
                custom: { unit, tooltip = true },
              },
            } = series;
            if (tooltip) {
              const formattedValue = formatValue(y, unit);
              format += `<br /><span style="color: ${color}">${name}: ${formattedValue}</span>`;
            }
          });

          return format;
        },
        positioner: function (w, h, point) {
          this.chart.pointer.chartPosition = null;
          return this.getPosition(w, h, point);
        },
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      title: {
        text: null,
      },
    };

    setChartOptions(result);
    tabKey.current = tab.key;
  }, [
    tab,
    label,
    theme.palette.grey,
    tab.key,
    tab.compressionDepth,
    activeSeries,
    operationLoadPercent,
    simulationResults,
    simulationResultsRih,
    simulationResultsPooh,
  ]);

  // reset tab key to reset the data filtered only in case simulationData have changed
  useEffect(() => {
    tabKey.current = null;
  }, [simulationResults, simulationResultsRih, simulationResultsPooh]);

  return (
    <HighchartsReact
      options={chartOptions}
      highcharts={Highcharts}
      ref={chartRef}
      oneToOne={true}
    />
  );
};

SimulationResultsChart.propTypes = {
  simulationResults: PropTypes.instanceOf(Collection),
  simulationResultsRih: PropTypes.instanceOf(Collection),
  simulationResultsPooh: PropTypes.instanceOf(Collection),
  tab: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    label: PropTypes.string,
    activeSeries: PropTypes.arrayOf(
      PropTypes.oneOf(Object.values(SimulationResultSeries)),
    ),
  }).isRequired,
};

export default SimulationResultsChart;
