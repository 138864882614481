import { useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { List, isImmutable } from 'immutable';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Tooltip, IconButton } from '@material-ui/core';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { formatValue } from 'utils/format.util';
import { SystemPermission } from 'app/app.constants';
import { getQuantityUnitFromItems } from 'utils/app.util';
import TableCellDense from 'app/components/Table/TableCellDense';
import ToolImageTableCell from 'app/components/ToolImageTableCell';
import HasSystemPermission from 'app/components/HasSystemPermission';
import { ExifOrientation } from 'features/projects/tool/tool.constants';
import TableHeaderCellDense from 'app/components/Table/TableHeaderCellDense';
import ToolImageContainer from 'features/projects/tool/components/ToolImageContainer';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';

const defaultInitialState = {
  pageSize: 50,
};

const getSubRows = (row) =>
  isImmutable(row)
    ? row.get('toolStringItems')?.toArray().reverse()
    : row.original?.toolStringItems?.toArray().reverse();

const BhaTemplatesTable = ({
  classes,
  bhaTemplates,
  onEditTemplate,
  ...rest
}) => {
  const initialState = useMemo(
    () => ({
      ...defaultInitialState,
      sortBy: [
        {
          id: 'sequence',
          desc: false,
        },
      ],
    }),
    [],
  );

  const lengthUnit = getQuantityUnitFromItems(bhaTemplates, (result) =>
    result.get('length'),
  );

  const weightUnit = getQuantityUnitFromItems(bhaTemplates, (result) =>
    result.get('weight'),
  );

  const diameterUnit = getQuantityUnitFromItems(bhaTemplates, (result) =>
    result.get('outerDiameter'),
  );

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 1,
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (!row.canExpand) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <IconButton
                {...row.getToggleRowExpandedProps({
                  ...row.getToggleRowExpandedProps({
                    style: {
                      paddingLeft: `${1}rem`,
                    },
                  }),
                })}
              >
                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 1,
        id: 'dataVerified',
        Header: '',
        Cell: ({ row }) => {
          const tool = row.original;

          if (row.canExpand) {
            const toolStringItems = tool.get('toolStringItems').toJS();
            const hasNullDataVerified = toolStringItems.some((item) => {
              const verifiedDataExists = item.verifiedData ?? item.dataVerified;
              return verifiedDataExists === false;
            });

            if (hasNullDataVerified) {
              return (
                <TableRowActionsCell minItems={0}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Tooltip title="Unverified tool">
                      <WarningIcon className={classes.warningIcon} />
                    </Tooltip>
                  </Grid>
                </TableRowActionsCell>
              );
            } else {
              return null;
            }
          } else {
            const verifiedData =
              tool.get('dataVerified') ?? tool.get('verifiedData');
            if (!verifiedData) {
              return (
                <TableRowActionsCell minItems={0}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Tooltip title="Unverified tool">
                      <WarningIcon className={classes.warningIcon} />
                    </Tooltip>
                  </Grid>
                </TableRowActionsCell>
              );
            } else return null;
          }
        },
      },
      {
        xs: 11,
        id: 'image',
        Header: 'Image',
        Cell: ({ row }) => {
          const { original: tool } = row;

          const toolStringItems = getSubRows(tool);

          const bhaLength = tool.getIn(['length', 'roundedValue']);

          if (!row.canExpand && tool.get('toolAssemblyTools')) {
            const tools = tool.get('toolAssemblyTools');
            return (
              <Grid container className={classes.toolContainer}>
                {tools.map((tool, index) => {
                  return (
                    <ToolImageContainer
                      key={index}
                      onHoverDarken
                      title={tool.name}
                      toolName={tool.name}
                      toolImageUrl={tool.imageUrl}
                      orientation={ExifOrientation.DEGREES_90_MIRRORED}
                      style={{
                        maxHeight: '50px',
                        maxWidth: `${100 / tools.length}%`,
                      }}
                    />
                  );
                })}
              </Grid>
            );
          }

          if (!tool.get('imageUrl') && toolStringItems) {
            const updatedToolStringItems = toolStringItems.map((item) => {
              const lengthRoundedValue = item.getIn(['length', 'roundedValue']);
              const ratio = (lengthRoundedValue / bhaLength) * 100;
              return item.set('ratio', ratio);
            });
            return (
              <Grid container className={classes.toolContainer}>
                {updatedToolStringItems.map((item, index) => {
                  const toolAssemblyTools = item.get('toolAssemblyTools');
                  if (toolAssemblyTools) {
                    const assemblyLength = item.get('ratio');
                    const totalLengthOfTools = toolAssemblyTools.reduce(
                      (total, tool) => total + tool.length.roundedValue,
                      0,
                    );

                    toolAssemblyTools.forEach((tool) => {
                      const lengthRoundedValue = tool.length.roundedValue;
                      const toolPercentOfAssembly =
                        (lengthRoundedValue / totalLengthOfTools) * 100;
                      tool.ratio =
                        (toolPercentOfAssembly / 100) * assemblyLength;
                    });
                    return toolAssemblyTools.map((tool, index) => {
                      return (
                        <ToolImageContainer
                          key={index}
                          onHoverDarken
                          title={tool.name}
                          toolName={tool.name}
                          toolImageUrl={tool.imageUrl}
                          orientation={ExifOrientation.DEGREES_90_MIRRORED}
                          style={{
                            maxHeight: '50px',
                            maxWidth: `${tool.ratio}%`,
                          }}
                        />
                      );
                    });
                  }
                  return (
                    <ToolImageContainer
                      key={index}
                      onHoverDarken
                      title={item.get('name')}
                      toolName={item.get('name')}
                      toolImageUrl={item.get('imageUrl')}
                      orientation={ExifOrientation.DEGREES_90_MIRRORED}
                      style={{
                        maxHeight: '50px',
                        maxWidth: `${item.get('ratio')}%`,
                      }}
                    />
                  );
                })}
              </Grid>
            );
          }
          return (
            <ToolImageTableCell
              onHoverDarken
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth}rem`,
                },
              })}
              title={tool.get('name')}
              toolName={tool.get('name')}
              toolImageUrl={tool.get('imageUrl')}
              orientation={ExifOrientation.DEGREES_90_MIRRORED}
            />
          );
        },
      },
      {
        xs: 3,
        id: 'name',
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
      {
        xs: 2,
        id: 'length',
        accessor: (tool) =>
          formatValue(tool.getIn(['length', 'value']), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Length" unit={lengthUnit} />
        ),
      },
      {
        xs: 2,
        id: 'weight',
        Header: (
          <QuantityTableHeaderTitleCell title="Weight" unit={weightUnit} />
        ),
        accessor: (template) =>
          formatValue(template.getIn(['weight', 'value']), '', 0.01),
      },
      {
        xs: 2,
        id: 'outerDiameter',
        accessor: (tool) =>
          formatValue(tool.getIn(['outerDiameter', 'value']), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell
            title="Outer Dia."
            unit={diameterUnit}
          />
        ),
      },
      {
        xs: 1,
        id: 'editTool',
        Cell: ({ row }) => {
          const { original: tool } = row;

          if (row.canExpand) {
            return (
              <HasSystemPermission
                permissions={[SystemPermission.ITEM_TAB_EDIT]}
              >
                <Grid item container justifyContent="flex-end">
                  <IconButton>
                    <EditIcon onClick={() => onEditTemplate(tool.get('id'))} />
                  </IconButton>
                </Grid>
              </HasSystemPermission>
            );
          }
          return null;
        },
      },
    ],
    [
      lengthUnit,
      weightUnit,
      diameterUnit,
      onEditTemplate,
      classes.warningIcon,
      classes.toolContainer,
    ],
  );

  return (
    <Grid item container direction="column">
      <Grid xs item container wrap="nowrap" direction="column">
        <Grid item>
          <Table
            useExpanded
            stickyHeader
            items={bhaTemplates}
            usePagination
            columns={columns}
            useGlobalFilter={false}
            getSubRows={getSubRows}
            initialState={initialState}
            noItemsMessage="No BHA found. Try changing the filters"
            TableCellComponent={TableCellDense}
            TableHeaderCellComponent={TableHeaderCellDense}
            {...rest}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

BhaTemplatesTable.propTypes = {
  tools: PropTypes.instanceOf(List),
};

const styles = (theme) => ({
  rowActionCell: {
    paddingRght: '10px',
  },
  mainDiv: {
    transform: `rotate(${90}deg)`,
  },
  toolContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
    maxHeight: '100%',
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
});

export default compose(withStyles(styles))(BhaTemplatesTable);
