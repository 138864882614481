import Agent from 'infrastructure/agent';
import activitiesMapper from 'features/projects/activities/activities.mappers';
import { ProjectExecutionHub } from 'utils/activity.util';

const allActivitiesForTask = (projectId, taskId) =>
  Agent.get(`/project/${projectId}/tasks/${taskId}/activities`);

const allActivitiesForProject = (projectId) =>
  Agent.get(`/project/${projectId}/activities`);

const activityById = (id, rid, aid) =>
  Agent.get(`/project/${id}/tasks/${rid}/activities/${aid}`);

const createActivity = (projectId, taskId, activity) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/activities`,
    activitiesMapper.Activity.to(activity),
  );

const updateActivity = (projectId, taskId, activity) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/activities/${activity.id}`,
    activitiesMapper.Activity.to(activity),
  );

const deleteActivity = (projectId, taskId, activityId) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/activities/${activityId}`,
  );

const updateActivitySetOrRetrieveItems = (projectId, taskId, updateData) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/activities/updatesetorretrievetools`,
    updateData,
  );

const deleteActivitySetOrRetrieveItems = (projectId, taskId, activityId) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/activities/updatesetorretrievetools/${activityId}/delete`,
  );

const updateActivitySequence = (projectId, taskId, activityId, itemSequence) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/activities/${activityId}/sequence`,
    itemSequence,
  );

const startOperation = (projectId) =>
  Agent.post(`/project/${projectId}/execute/start`);

const completeActivity = (projectId, taskId, activityId) =>
  Agent.post(
    `/project/${projectId}/execute/completeactivity`,
    activitiesMapper.CompleteActivity.to(taskId, activityId),
  );

const completeTask = (
  projectId,
  completeTask,
  status,
  continueWithContingencyTaskId,
) =>
  Agent.post(
    `/project/${projectId}/execute/completetask`,
    activitiesMapper.CompleteTask.to(
      completeTask,
      status,
      continueWithContingencyTaskId,
    ),
  );

const completeAndRepeatTask = (projectId, completeTask, status) =>
  Agent.post(
    `/project/${projectId}/execute/completetaskrepeat`,
    activitiesMapper.CompleteTask.to(completeTask, status),
  );

const completeTaskAndEndOperation = (projectId, completeTask, status) =>
  Agent.post(
    `/project/${projectId}/execute/completetaskend`,
    activitiesMapper.CompleteTask.to(completeTask, status),
  );

const getAllActivityTypes = () =>
  Agent.get('/search/filter/valueset/activitytypes');

const getAllWaitingActivityTypes = () =>
  Agent.get('/search/filter/valueset/activitytypes/waiting');

const getAllPointInTimeActivityTypes = () =>
  Agent.get('/search/filter/valueset/activitytypes/pointintime');

const createNewPointInTimeActivity = (
  projectId,
  taskId,
  activityId,
  activityTypeId,
) =>
  Agent.post(
    `/project/${projectId}/execute/newpointintimeactivity`,
    activitiesMapper.CreateActivity.to(taskId, activityId, activityTypeId),
  );

const pauseActivity = (projectId, taskId, activityId, activityTypeId) =>
  Agent.post(
    `/project/${projectId}/execute/pauseactivity`,
    activitiesMapper.CreateActivity.to(taskId, activityId, activityTypeId),
  );

const endOperation = (projectId, endOperation) =>
  Agent.post(
    `/project/${projectId}/execute/end`,
    activitiesMapper.EndOperation.to(endOperation),
  );

const completeActivityAbortTask = (projectId, taskId, activityId) =>
  Agent.post(
    `/project/${projectId}/execute/completeactivityend`,
    activitiesMapper.CompleteActivity.to(taskId, activityId),
  );

const getAllProjectTimers = (projectId) =>
  Agent.get(`/project/${projectId}/execute/timers`);

const updateProjectTimer = (projectId, timer) =>
  Agent.put(
    `/project/${projectId}/execute/timers/${timer.id}`,
    activitiesMapper.Timer.to(timer),
  );

const createProjectTimer = (projectId, timer) =>
  Agent.post(
    `/project/${projectId}/execute/timers`,
    activitiesMapper.Timer.to(timer),
  );

const goBack = (projectId) =>
  Agent.post(`/project/${projectId}/execute/goback`);

const sendProjectChangedNotification = (connection, projectId, taskId) =>
  connection.invoke(ProjectExecutionHub.RelayProjectChange, projectId, taskId);

const activitiesService = {
  goBack,
  activityById,
  completeTask,
  endOperation,
  allActivitiesForTask,
  pauseActivity,
  updateActivity,
  createActivity,
  deleteActivity,
  startOperation,
  completeActivity,
  sendProjectChangedNotification,
  createProjectTimer,
  updateProjectTimer,
  getAllProjectTimers,
  getAllActivityTypes,
  completeAndRepeatTask,
  updateActivitySequence,
  allActivitiesForProject,
  completeActivityAbortTask,
  getAllWaitingActivityTypes,
  completeTaskAndEndOperation,
  createNewPointInTimeActivity,
  getAllPointInTimeActivityTypes,
  updateActivitySetOrRetrieveItems,
  deleteActivitySetOrRetrieveItems,
};

export default activitiesService;
