export const TASK_INFO_ACTIONS = {
  TASK_INFO_PAGE_LOADED: 'TASK_INFO_PAGE_LOADED',
};

export const RISK_TABLE_ACTIONS = {
  REQUEST_TASK_RISK_SECTIONS: 'REQUEST_TASK_RISK_SECTIONS',
  REQUEST_CREATE_TASK_RISK_SECTION: 'REQUEST_CREATE_TASK_RISK_SECTION',
  REQUEST_UPDATE_TASK_RISK_SECTION: 'REQUEST_UPDATE_TASK_RISK_SECTION',
  DELETE_TASK_RISK_SECTION: 'DELETE_TASK_RISK_SECTION',
  REQUEST_DELETE_TASK_RISK_SECTION: 'REQUEST_DELETE_TASK_RISK_SECTION',
  RECEIVE_TASK_RISK_SECTION: 'RECEIVE_TASK_RISK_SECTION',
  RECEIVE_TASK_RISK_SECTIONS: 'RECEIVE_TASK_RISK_SECTIONS',

  REQUEST_TASK_RISK_BY_PROJECT: 'REQUEST_TASK_RISK_BY_PROJECT',
  REQUEST_PROJECT_RISK_SECTIONS: 'REQUEST_PROJECT_RISK_SECTIONS',
  REQUEST_CREATE_PROJECT_RISK_SECTION: 'REQUEST_CREATE_PROJECT_RISK_SECTION',
  REQUEST_UPDATE_PROJECT_RISK_SECTION: 'REQUEST_UPDATE_PROJECT_RISK_SECTION',
  DELETE_PROJECT_RISK_SECTION: 'DELETE_PROJECT_RISK_SECTION',
  REQUEST_DELETE_PROJECT_RISK_SECTION: 'REQUEST_DELETE_PROJECT_RISK_SECTION',
  RECEIVE_PROJECT_RISK_SECTION: 'RECEIVE_PROJECT_RISK_SECTION',
  RECEIVE_PROJECT_RISK_SECTIONS: 'RECEIVE_PROJECT_RISK_SECTIONS',
  RECEIVE_TASK_RISK_BY_PROJECT: 'RECEIVE_TASK_RISK_BY_PROJECT',
};
