import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { MenuItem, TextField } from '@material-ui/core';

const TeamProjectRoleAccessCell = ({
  classes,
  projectMember,
  teamProjectRoles,
  assignTeamProjectRoleChange,
}) => {
  const value =
    teamProjectRoles && projectMember
      ? teamProjectRoles.find(
          (x) =>
            x.get('teamProjectRoleId') ===
            projectMember.get('teamProjectRoleId'),
        )
      : null;

  return (
    <TextField
      select
      fullWidth
      margin="none"
      value={value ? value.get('teamProjectRoleId') : ''}
      InputProps={{
        classes: {
          root: classes.inputRoot,
        },
      }}
      onChange={(event) => {
        const selectedItem = teamProjectRoles.find(
          (x) => x.get('teamProjectRoleId') === event.target.value,
        );
        if (selectedItem.size > 0) {
          return assignTeamProjectRoleChange(projectMember, selectedItem);
        }
      }}
    >
      {teamProjectRoles.valueSeq().map((role) => (
        <MenuItem
          key={role.get('teamProjectRoleId')}
          value={role.get('teamProjectRoleId')}
          title={role.get('name')}
        >
          {role.get('name')}
        </MenuItem>
      ))}
    </TextField>
  );
};

const styles = (theme) => ({
  inputRoot: {
    fontSize: theme.typography.body2.fontSize,
  },
});

export default compose(withStyles(styles))(TeamProjectRoleAccessCell);
