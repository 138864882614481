import { compose } from 'redux';
import { connect } from 'react-redux';
import { useEffect, useCallback } from 'react';

import { BasePage, RouteContainer } from 'altus-ui-components';

import {
  getProjectResources,
  projectPagesOnUnload,
  resetProjectResources,
} from 'features/projects/projects.actions';

import routePaths from 'app/routePaths';
import { projectSubRoutes } from 'app/routes';
import { markProjectAsSeen } from 'app/app.actions';
import { getActionDataStateFromState } from 'app/app.selectors';
import ProjectSteps from 'features/projects/components/ProjectSteps';
import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';
import ProjectRoutesContainer from 'app/components/ProjectRoutesContainer';

const stepperRoutes = [
  {
    path: routePaths.plan.task.match,
    component: null,
  },
  {
    path: routePaths.events,
    component: null,
  },
];

const ProjectContainer = ({
  projectId,
  dataState,
  dispatchOnUnload,
  dispatchProjectSeen,
  dispatchGetProjectResources,
  dispatchResetProjectResources,
}) => {
  useEffect(() => {
    dispatchResetProjectResources();
    dispatchProjectSeen(projectId);
    dispatchGetProjectResources(projectId);

    return () => {
      dispatchOnUnload();
      dispatchProjectSeen(projectId);
      dispatchResetProjectResources();
    };
  }, [
    projectId,
    dispatchOnUnload,
    dispatchProjectSeen,
    dispatchGetProjectResources,
    dispatchResetProjectResources,
  ]);

  const renderProjectRoutes = useCallback(() => {
    if (!dataState.requested || dataState.isLoading()) {
      return (
        <BasePage
          dataState={dataState}
          LoadingOverlayProps={{
            timeout: 0,
            text: 'Loading project resources...',
          }}
        />
      );
    }

    return <ProjectRoutesContainer routes={projectSubRoutes} />;
  }, [dataState]);

  return (
    <>
      {renderProjectRoutes()}
      <RouteContainer
        routes={stepperRoutes}
        MissingRouteComponent={ProjectSteps}
      />
    </>
  );
};

export default compose(
  connect(
    (state) => ({
      dataState: getActionDataStateFromState(
        state,
        PROJECTS_ACTIONS.GET_PROJECT_RESOURCES,
      ),
    }),
    {
      dispatchOnUnload: projectPagesOnUnload,
      dispatchProjectSeen: markProjectAsSeen,
      dispatchGetProjectResources: getProjectResources,
      dispatchResetProjectResources: resetProjectResources,
    },
  ),
)(ProjectContainer);
