import React from 'react';

import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';

import { ASSET_HISTORY_MAINTENANCE_TYPES } from 'features/equipment/equipment.constants';
import { AssetMaintenanceType } from 'features/equipment/assets/components/CreateAssetHistoryModalContainer';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';
import CableCutMaintenanceFormComponent from 'features/equipment/assets/components/equipmentMaintenance/CableCutMaintenanceFormComponent';
import CableServiceMaintenanceFormComponent from 'features/equipment/assets/components/equipmentMaintenance/CableServiceMaintenanceFormComponent';
import CableSpoolingMaintenanceFormComponent from 'features/equipment/assets/components/equipmentMaintenance/CableSpoolingMaintenanceFormComponent';

const MaintenanceForm = ({
  form,
  maintenanceTypeItems = ASSET_HISTORY_MAINTENANCE_TYPES.filter(
    (item) => item.showByDefault,
  ),
  defaultMaintenanceTypeValue,
  selectedCustomUnitsOfMeasure,
  unitsOfMeasurePreference,
  projectMeasurementSystem,
}) => {
  const getId = (item) => item.id;
  const getName = (item) => item.name;
  const { values } = useFormikContext();

  const selectedMaintenanceType = values[AssetHistoryForm.TYPE_OF_TEST];

  const maintenanceTypeComponents = React.useMemo(
    () => ({
      [AssetMaintenanceType.CABLE_CUT]: {
        CustomComponent: CableCutMaintenanceFormComponent,
      },
      [AssetMaintenanceType.CABLE_SERVICE]: {
        CustomComponent: CableServiceMaintenanceFormComponent,
      },
      [AssetMaintenanceType.CABLE_SPOOLING]: {
        CustomComponent: CableSpoolingMaintenanceFormComponent,
      },
    }),
    [],
  );

  const SelectedComponent = maintenanceTypeComponents[
    selectedMaintenanceType
  ] || {
    CustomComponent: () => null,
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid item>
              {defaultMaintenanceTypeValue ? null : (
                <SelectTextFieldFormik
                  getItemName={getName}
                  label="Type of Maintenance"
                  getItemValue={getId}
                  items={maintenanceTypeItems}
                  name={AssetHistoryForm.TYPE_OF_TEST}
                  displayEmpty={false}
                  placeholder="Select"
                />
              )}
              <>
                <SelectedComponent.CustomComponent
                  form={form}
                  selectedCustomUnitsOfMeasure={selectedCustomUnitsOfMeasure}
                  unitsOfMeasurePreference={unitsOfMeasurePreference}
                  projectMeasurementSystem={projectMeasurementSystem}
                />
              </>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MaintenanceForm;
