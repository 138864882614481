import { memo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Switch } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import BreakpointThumbIcon from 'features/projects/tasks/task/toolstring/components/BreakpointThumbIcon';

const ToolstringPreviewBreakpoint = ({
  classes,
  toggleable,
  hasBreakpoint,
  onToggleBreakpoint,
}) => (
  <Grid item className={classes.breakpointAnchor}>
    <Grid
      title={toggleable ? 'Toggle breakpoint' : 'Breakpoint'}
      onClick={toggleable ? onToggleBreakpoint : undefined}
      className={classNames(classes.breakpoint, {
        [classes.toggleable]: toggleable,
      })}
    >
      {!toggleable && hasBreakpoint && <BreakpointThumbIcon color="primary" />}
      {toggleable && (
        <Switch
          size="small"
          color="primary"
          onChange={undefined}
          checked={hasBreakpoint}
          checkedIcon={<BreakpointThumbIcon />}
          icon={<BreakpointThumbIcon darkText />}
        />
      )}
    </Grid>
  </Grid>
);

const styles = (theme) => ({
  breakpointAnchor: {
    position: 'relative',
  },
  breakpoint: {
    zIndex: 5,
    left: '60%',
    position: 'absolute',
    padding: theme.spacing(1),
    transform: 'translateY(-50%)',
  },
  toggleable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

ToolstringPreviewBreakpoint.propTypes = {
  hasBreakpoint: PropTypes.bool,
  onToggleBreakpoint: PropTypes.func,
};

export default compose(memo, withStyles(styles))(ToolstringPreviewBreakpoint);
