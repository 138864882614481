import { Map, OrderedMap } from 'immutable';

import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import { ACTIONS } from 'features/projects/wellbore/details/projectWellboreDetails.constants';
import projectWellboreDetailsMappers from 'features/projects/wellbore/details/projectWellboreDetails.mappers';

const initialState = Map({
  details: EMPTY_MAP,
  changelog: EMPTY_LIST,
  activeDetailsId: null,
});

const projectWellboreDetailReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.DELETE_PROJECT_WELLBORE_DETAIL: {
      if (error) return state;

      const { projectWellboreDetailId } = action;

      return state.deleteIn(['details', projectWellboreDetailId.toString()]);
    }

    case ACTIONS.RECEIVE_PROJECT_WELLBORE_DETAILS: {
      if (error) return state;

      return state.set(
        'details',
        OrderedMap(
          payload.map((projectWellboreDetail) => [
            projectWellboreDetail.projectWellboreDetailId.toString(),
            projectWellboreDetailsMappers.ProjectWellboreDetail.from(
              projectWellboreDetail,
            ),
          ]),
        ),
      );
    }

    case ACTIONS.RECEIVE_PROJECT_WELLBORE_DETAIL: {
      if (error) return state;

      return state.setIn(
        ['details', payload.projectWellboreDetailId.toString()],
        projectWellboreDetailsMappers.ProjectWellboreDetail.from(payload),
      );
    }

    case ACTIONS.CLEAR_PROJECT_WELLBORE_DETAILS: {
      return state.set('details', initialState.get('details'));
    }

    case ACTIONS.SET_ACTIVE_WELLBOREDETAILSID: {
      return state.set('activeDetailsId', payload);
    }

    default:
      return state;
  }
};

export default projectWellboreDetailReducer;
