import Agent from 'infrastructure/agent';
import { saveFile } from 'utils/app.util';

// M3
const refreshToolsWithM3Items = (projectId, taskId) =>
  Agent.get(`/project/${projectId}/tasks/${taskId}/toolstrings/refreshM3Data`);

const getField = (fieldId) => Agent.get(`/Field/${fieldId}/organization`);

const getAllDocuments = (fieldId) => Agent.get(`/Field/${fieldId}/files`);

const deleteFile = (fieldId, fieldFileId) =>
  Agent.delete(`/field/${fieldId}/files/${fieldFileId}`);

const downloadFile = async (fieldId, fieldFileId) => {
  const [file, filename] = await Agent.file.get(
    `/field/${fieldId}/files/${fieldFileId}/download`,
  );
  return saveFile(file, filename);
};

const uploadFile = (fieldId, file, meta) =>
  Agent.file.post(`/field/${fieldId}/files`, file, meta);

const updateFile = (fieldId, fieldFileId, file) =>
  Agent.put(`/field/${fieldId}/files/${fieldFileId}`, file);

const fieldService = {
  refreshToolsWithM3Items,
  getField,
  getAllDocuments,
  deleteFile,
  downloadFile,
  uploadFile,
  updateFile,
};

export default fieldService;
