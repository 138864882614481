import { Grid } from '@material-ui/core';
import { Field } from 'redux-form/immutable';

import DateTimePickerField from 'app/components/form/DateTimePickerField';

const AdjustDurationField = ({
  names,
  disabled,
  endTimeProps,
  durationProps,
  startTimeProps,
  ...rest
}) => {
  const [startTimeName] = names;
  const startTime = rest[startTimeName];

  return (
    <>
      <Grid container
            justifyContent="center"
            alignContent="center">
        <Grid item xs={5}>
          <Field
          format={null}
          name={startTime.input.name}
          component={DateTimePickerField}
          DateTimePickerProps={{
            margin: 'normal',
            label: 'Started',
            disableFuture: true,
            ...startTimeProps,
          }}
        />
          </Grid>
      </Grid>

        {/*If item is stopped => edit end time when editing duration*/}
        {/*If item is active => edit start time when edititing duration*/}
    </>
  );
};

export default AdjustDurationField;
