import * as THREE from 'three';
import ThreeJsUtils from 'app/threeJs/ThreeJsUtils';
import {
  between,
  convertMetric,
} from 'app/components/WellboreTrajectoryDetailed3DView/Utils';
import { Materials } from 'app/components/WellGraphAndGuages/WellGraphAndGuagesTrajectoryScene';

export const BHACablePrefix = 'bha-cable';
export const BHAPrefix = 'BHA';
export const BHATool = 'BHATool';
export const ToolStringGroupName = 'ToolStringGroup';
const startVector = new THREE.Vector3();
const endVector = new THREE.Vector3();

export const renderCable = ({
  trajectoryPoints,
  maxTVD,
  toolsByToolstringToolId,
  BHA_LENGTH,
  currentDepth,
}) => {
  const toolStringGroup = new THREE.Group();
  toolStringGroup.name = ToolStringGroupName;
  trajectoryPoints.forEach((previousPoint, index) => {
    const currentPoint = trajectoryPoints.get(index + 1);

    // Bottom reached
    if (!currentPoint) return false;

    if (
      currentDepth.value &&
      convertMetric(currentDepth.value, currentDepth.unit) >
        convertMetric(
          currentPoint.getIn(['measuredDepth', 'value']),
          currentPoint.getIn(['measuredDepth', 'unit']),
        )
    ) {
      const cable = createCable({
        previousPoint,
        maxTVD: convertMetric(maxTVD.value, maxTVD.unit),
        currentPoint,
      });
      cable.position.y += convertMetric(BHA_LENGTH.value, BHA_LENGTH.unit) / 4;
      toolStringGroup.add(cable);
    } else if (
      currentDepth.value &&
      between(
        convertMetric(currentDepth.value, currentDepth.unit),
        convertMetric(
          previousPoint.getIn(['measuredDepth', 'value']),
          previousPoint.getIn(['measuredDepth', 'unit']),
        ),
        convertMetric(
          currentPoint.getIn(['measuredDepth', 'value']),
          currentPoint.getIn(['measuredDepth', 'unit']),
        ),
      )
    ) {
      const BHA = createToolString({
        BHA_LENGTH,
        maxTVD: convertMetric(maxTVD.value, maxTVD.unit),
        previousPoint,
        currentPoint,
        toolsByToolstringToolId,
        currentDepth: convertMetric(currentDepth.value, currentDepth.unit),
        measuredDepthStart: convertMetric(
          previousPoint.getIn(['measuredDepth', 'value']),
          previousPoint.getIn(['measuredDepth', 'unit']),
        ),
        measuredDepthEnd: convertMetric(
          currentPoint.getIn(['measuredDepth', 'value']),
          currentPoint.getIn(['measuredDepth', 'unit']),
        ),
      });
      toolStringGroup.add(BHA);
    }
  });
  return toolStringGroup;
};

export const createCable = ({ previousPoint, currentPoint, maxTVD }) => {
  startVector.set(
    convertMetric(
      previousPoint.getIn(['eastWest', 'value']),
      previousPoint.getIn(['eastWest', 'unit']),
    ),
    maxTVD -
      convertMetric(
        previousPoint.getIn(['verticalDepth', 'value']),
        previousPoint.getIn(['verticalDepth', 'unit']),
      ),
    convertMetric(
      previousPoint.getIn(['northSouth', 'value']),
      previousPoint.getIn(['northSouth', 'unit']),
    ),
  );

  endVector.set(
    convertMetric(
      currentPoint.getIn(['eastWest', 'value']),
      currentPoint.getIn(['eastWest', 'unit']),
    ),
    maxTVD -
      convertMetric(
        currentPoint.getIn(['verticalDepth', 'value']),
        currentPoint.getIn(['verticalDepth', 'unit']),
      ),
    convertMetric(
      currentPoint.getIn(['northSouth', 'value']),
      currentPoint.getIn(['northSouth', 'unit']),
    ),
  );

  const cylinder = ThreeJsUtils.createCylinder({
    v1: startVector,
    v2: endVector,
    material: Materials.cable,
    radius: 0.2,
  });

  const measuredDepth = convertMetric(
    currentPoint.getIn(['measuredDepth', 'value']),
    currentPoint.getIn(['measuredDepth', 'unit']),
  );
  cylinder.name = `${BHACablePrefix}-${measuredDepth}`;
  cylinder.data = {
    ...cylinder.data,
    measuredDepth,
  };

  return cylinder;
};
export const createToolString = ({
  BHA_LENGTH,
  previousPoint,
  currentPoint,
  maxTVD,
  toolsByToolstringToolId,
  currentDepth,
  measuredDepthStart,
  measuredDepthEnd,
}) => {
  if (previousPoint && currentPoint) {
    startVector.set(
      convertMetric(
        previousPoint.getIn(['eastWest', 'value']),
        previousPoint.getIn(['eastWest', 'unit']),
      ),
      maxTVD -
        convertMetric(
          previousPoint.getIn(['verticalDepth', 'value']),
          previousPoint.getIn(['verticalDepth', 'unit']),
        ),
      convertMetric(
        previousPoint.getIn(['northSouth', 'value']),
        previousPoint.getIn(['northSouth', 'unit']),
      ),
    );

    endVector.set(
      convertMetric(
        currentPoint.getIn(['eastWest', 'value']),
        currentPoint.getIn(['eastWest', 'unit']),
      ),
      maxTVD -
        convertMetric(
          currentPoint.getIn(['verticalDepth', 'value']),
          currentPoint.getIn(['verticalDepth', 'unit']),
        ),
      convertMetric(
        currentPoint.getIn(['northSouth', 'value']),
        currentPoint.getIn(['northSouth', 'unit']),
      ),
    );
  }

  const cylinder = ThreeJsUtils.createToolString({
    v1: startVector,
    v2: endVector,
    BHA_LENGTH,
    material: Materials.cable,
    toolsByToolstringToolId,
  });
  cylinder.name = `${BHAPrefix}`;
  cylinder.position.y += convertMetric(BHA_LENGTH.value, BHA_LENGTH.unit) / 2;
  const diff = currentDepth - (measuredDepthStart + measuredDepthEnd) / 2;
  cylinder.translateY(diff);

  return cylinder;
};
