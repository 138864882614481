import _isNil from 'lodash/isNil';
import { compose } from 'redux';
import { connect } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, Divider, Box } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import { useCallback, useEffect, useState } from 'react';
import ExportPdfIcon from '@material-ui/icons/PictureAsPdf';

import {
  BasePage,
  LoadingOverlay,
  NavTabsContainer,
} from 'altus-ui-components';

import { useModal } from 'altus-modal';
import { NotRequestedDataState } from 'altus-datastate';

import {
  loadSimulation,
  requestDeleteSimulation,
  requestUpdateSimulation,
  requestDuplicateSimulation,
  requestToggleSimulationPlanned,
  requestToggleSimulationFavorite,
} from 'features/projects/tasks/task/simulation/simulation.actions';

import {
  getTaskFromState,
  getTaskToolstringsFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';

import {
  ACTIONS,
  SimulationState,
  MODAL as SIMULATION_MODAL,
} from 'features/projects/tasks/task/simulation/simulation.constants';

import {
  getActionDataStateFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';

import { EMPTY_MAP, EMPTY_LIST } from 'app/app.constants';
import { getCurrentProject } from 'features/projects/projects.selectors';
import ProjectRoutesContainer from 'app/components/ProjectRoutesContainer';
import { MODAL as CABLE_MODAL } from 'features/projects/tool/tool.constants';
import SimulationHeader from 'features/projects/tasks/task/simulation/components/SimulationHeader';
import CablePickerModalContainer from 'features/projects/tool/components/CablePickerModalContainer';
import { getSimulationFromState } from 'features/projects/tasks/task/simulation/simulation.selectors';
import RunSimulationProgressBar from 'features/projects/tasks/task/simulation/components/SimulationLinearProgress';
import SimulationProjectInformation from 'features/projects/tasks/task/simulation/components/TaskSimulationDetails';
import TaskSimulationCableSelect from 'features/projects/tasks/task/simulation/components/TaskSimulationCableSelect';
import SimulationResultsContainer from 'features/projects/tasks/task/simulation/components/SimulationResultsContainer';
import TaskSimulationToolstringSelect from 'features/projects/tasks/task/simulation/components/TaskSimulationToolstringSelect';
import SimulationExportModalContainer from 'features/projects/tasks/task/simulation/components/SimulationExportModalContainer';
import TaskSimulationToolstringContainer from 'features/projects/tasks/task/simulation/components/TaskSimulationToolstringContainer';

const TaskSimulationContainer = ({
  routes,
  taskId,
  classes,
  dataState,
  projectId,
  simulationId,
  dispatchOnLoad,
  task = EMPTY_MAP,
  project = EMPTY_MAP,
  simulation = EMPTY_MAP,
  toolstrings = EMPTY_LIST,
  dispatchUpdateSimulation,
  dispatchDeleteSimulation,
  dispatchDuplicateSimulation,
  dispatchToggleSimulationPlanned,
  dispatchToggleSimulationFavorite,
  runSimulationDataState = NotRequestedDataState,
}) => {
  const cable = simulation.get('cable');
  const toolstringId = simulation.get('toolstringId');
  const cableId = simulation.get('cableId');
  const isEnableSimulationRunButton = [cableId, toolstringId].every(
    (v) => !_isNil(v),
  );

  const [toolDataIsVerified, setToolDataIsVerified] = useState(null);

  const [isCablePickerModalOpen, toggleCablePickerModal] = useModal(
    CABLE_MODAL.CABLE_PICKER_MODAL_ID,
  );
  const [isSimulationExportModalOpen, toggleSimulationExportModal] = useModal(
    SIMULATION_MODAL.SIMULATION_EXPORT_MODAL_ID,
  );

  useEffect(
    () => dispatchOnLoad(projectId, taskId, simulationId),
    [projectId, taskId, simulationId, dispatchOnLoad],
  );

  const onToggleFavorite = useCallback(
    () => dispatchToggleSimulationFavorite(projectId, taskId, simulationId),
    [taskId, projectId, simulationId, dispatchToggleSimulationFavorite],
  );

  const onTogglePlanned = useCallback(
    () =>
      dispatchToggleSimulationPlanned(
        projectId,
        taskId,
        simulationId,
        simulation.get('state') === SimulationState.PLANNED,
      ),
    [
      taskId,
      projectId,
      simulationId,
      simulation,
      dispatchToggleSimulationPlanned,
    ],
  );

  const onUpdateSimulation = useCallback(
    (simulation) =>
      dispatchUpdateSimulation(projectId, taskId, simulationId, simulation),
    [taskId, projectId, simulationId, dispatchUpdateSimulation],
  );

  const onToolstringChange = useCallback(
    (event) =>
      onUpdateSimulation({
        toolstringId: event.target.value,
      }),
    [onUpdateSimulation],
  );

  const onCableChange = useCallback(
    (cable) => {
      onUpdateSimulation({
        cableId: cable.get('id'),
      });
      toggleCablePickerModal();
    },
    [onUpdateSimulation, toggleCablePickerModal],
  );

  const isPlanned = simulation.get('isPlanned');
  const headerActions = [
    {
      Icon: DeleteIcon,
      title: 'Delete',
      disabled: isPlanned,
      onClick: () =>
        dispatchDeleteSimulation(projectId, taskId, simulationId, simulationId),
    },
    {
      Icon: DuplicateIcon,
      title: 'Duplicate',
      onClick: () =>
        dispatchDuplicateSimulation(projectId, taskId, simulationId),
    },
    {
      Icon: ExportPdfIcon,
      title: 'Export',
      disabledTooltip: !toolDataIsVerified
        ? 'Some of the tools in the BHA are unverified'
        : 'Re-simulate with latest changes to export',
      // Has to be completed or planned to be able to generate a valid report.
      disabled:
        [
          SimulationState.NOT_COMPLETED,
          SimulationState.OUTDATED,
          SimulationState.ERROR,
        ].includes(simulation.get('state')) || !toolDataIsVerified,
      onClick: toggleSimulationExportModal,
    },
  ];

  return (
    <>
      <Grid xs container item wrap="nowrap" className={classes.root}>
        <Grid container item xs direction="column">
          <SimulationHeader
            task={task}
            project={project}
            actions={headerActions}
            simulation={simulation}
            togglePlanned={onTogglePlanned}
            toggleFavorite={onToggleFavorite}
            onNameChange={onUpdateSimulation}
            toolDataIsVerified={toolDataIsVerified}
            isEnableSimulationRunButton={isEnableSimulationRunButton}
          />
          <SimulationProjectInformation project={project} task={task} />
          <BasePage>
            <Grid item padding={2} component={Box} className={classes.section}>
              <NavTabsContainer variant="scrollable" routes={routes} />
              <Box paddingBottom={3} paddingTop={3}>
                <ProjectRoutesContainer routes={routes} />
              </Box>
            </Grid>
            <Grid container item xs className={classes.section}>
              <SimulationResultsContainer
                taskId={taskId}
                projectId={projectId}
                simulation={simulation}
                simulationId={simulationId}
              />
            </Grid>
          </BasePage>
        </Grid>
        <Divider orientation="vertical" flexItem />
      </Grid>
      <Grid
        item
        xs={2}
        container
        padding={2}
        wrap="nowrap"
        component={Box}
        direction="column"
      >
        <Grid
          item
          container
          component={Box}
          marginBottom={isPlanned ? undefined : 2}
        >
          <TaskSimulationCableSelect
            disabled={isPlanned}
            selectedCable={cable}
            onClick={toggleCablePickerModal}
          />
        </Grid>
        <Grid
          item
          container
          component={Box}
          paddingBottom={isPlanned ? undefined : 2}
        >
          <TaskSimulationToolstringSelect
            disabled={isPlanned}
            toolstrings={toolstrings}
            selectedToolstringId={toolstringId}
            onToolstringChange={onToolstringChange}
          />
        </Grid>
        <TaskSimulationToolstringContainer
          taskId={taskId}
          projectId={projectId}
          simulation={simulation}
          simulationId={simulationId}
          toolstringId={toolstringId}
          toolDataIsVerified={toolDataIsVerified}
          setToolDataIsVerified={setToolDataIsVerified}
        />
      </Grid>
      <CablePickerModalContainer
        open={isCablePickerModalOpen}
        onSelectCable={onCableChange}
        toggleModal={toggleCablePickerModal}
        projectId={projectId}
      />
      <SimulationExportModalContainer
        taskId={taskId}
        projectId={projectId}
        simulationId={simulationId}
        open={isSimulationExportModalOpen}
        toggleModal={toggleSimulationExportModal}
      />
      <LoadingOverlay timeout={250} dataState={dataState} />
      <RunSimulationProgressBar
        runSimulationDataState={runSimulationDataState}
      />
    </>
  );
};

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
  },
  section: {
    marginBottom: theme.spacing(2),
    border: `2px solid ${theme.palette.divider}`,
  },
});

export default compose(
  connect(
    (_initialState, { simulationId, taskId }) => {
      return (state) => ({
        task: getTaskFromState(state),
        project: getCurrentProject(state),
        simulation: getSimulationFromState(state, simulationId),
        toolstrings: getTaskToolstringsFromState(state, taskId),
        dataState: getSummarizedDataStateFromState(
          state,
          ACTIONS.LOAD_SIMULATION,
          ACTIONS.REQUEST_UPDATE_SIMULATION,
          ACTIONS.DOWNLOAD_SIMULATION_PDF_REPORT,
        ),
        runSimulationDataState: getActionDataStateFromState(
          state,
          ACTIONS.REQUEST_RUN_SIMULATION,
        ),
      });
    },
    {
      dispatchOnLoad: loadSimulation,
      dispatchUpdateSimulation: requestUpdateSimulation,
      dispatchDeleteSimulation: requestDeleteSimulation,
      dispatchDuplicateSimulation: requestDuplicateSimulation,
      dispatchToggleSimulationPlanned: requestToggleSimulationPlanned,
      dispatchToggleSimulationFavorite: requestToggleSimulationFavorite,
    },
  ),
  withStyles(styles),
)(TaskSimulationContainer);
