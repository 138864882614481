import { CABLE_TAB_FIELDS } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import _ from 'lodash';
import { editorStateToString } from 'utils/app.util';

export const richEditorStateToString = (editorState) => {
  return editorState ? editorStateToString(editorState) : null;
};

const removeCableCommentsFromDataAcquisitionStructure = (
  dataAcquisitionStructure,
) => {
  const { [CABLE_TAB_FIELDS.COMMENTS]: cableComments, ...restCurrent } =
    dataAcquisitionStructure;
  return restCurrent;
};
export const isEqualRestOfDataAcquisitionStructure = (
  currentDataAcquisition,
  initialDataAcquisition,
) => {
  const restCurrent = removeCableCommentsFromDataAcquisitionStructure(
    currentDataAcquisition,
  );
  const restInitial = removeCableCommentsFromDataAcquisitionStructure(
    initialDataAcquisition,
  );

  return _.isEqual(restCurrent, restInitial);
};
