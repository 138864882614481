import { initialize } from 'redux-form/immutable';
import { replace } from 'connected-react-router';

import {
  AUTHORIZATION_ACTIONS,
  PROJECT_ROLE_FORM,
  SYSTEM_ROLE_FORM,
} from 'authorization/authorization.constants';

import { getMainVendorUsers } from 'app/app.actions';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import { toProjectRole, toSystemRole } from 'utils/route.util';
import authorizationService from 'services/authorization.service';
import authorizationMappers from 'authorization/authorization.mappers';

/** System Roles */
export const userAccessOnLoad = () => (dispatch) =>
  dispatch(getSystemRoles()).then(() => dispatch(getMainVendorUsers()));

export const systemRoleAccessRightsOnLoad = (systemRoleId) => (dispatch) =>
  Promise.all([
    dispatch(getProjectPermissions()),
    dispatch(getSystemPermissions()),
  ]).then(() =>
    Promise.all([
      dispatch(getSystemRolePermissions(systemRoleId)),
      dispatch(getSystemRoleProjectPermissions(systemRoleId)),
    ]),
  );

export const getSystemRoles = () => (dispatch) => {
  const payload = authorizationService.getSystemRoles();

  dispatch({
    type: AUTHORIZATION_ACTIONS.GET_SYSTEM_ROLES,
    payload,
  });

  return payload;
};

export const getSystemRole = (systemRoleId) => (dispatch) => {
  const payload = authorizationService.getSystemRole(systemRoleId);

  dispatch({
    type: AUTHORIZATION_ACTIONS.GET_SYSTEM_ROLE,
    payload,
  });

  return payload;
};

export const createSystemRole = (systemRole) => (dispatch) => {
  const payload = authorizationService.createSystemRole(systemRole);

  dispatch({
    type: AUTHORIZATION_ACTIONS.CREATE_SYSTEM_ROLE,
    payload,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Creating...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Project Role created successfully',
    },
  });

  return payload;
};

export const createSystemRoleOnSubmit =
  (toggleModal) => (dispatch) => (systemRole) => {
    dispatch(createSystemRole(systemRole.toJS()))
      .then(({ roleId }) => dispatch(replace(toSystemRole(roleId))))
      .then(toggleModal);
  };

export const deleteSystemRole = (systemRoleId) => (dispatch) => {
  const payload = authorizationService.deleteSystemRole(systemRoleId);

  dispatch({
    type: AUTHORIZATION_ACTIONS.DELETE_SYSTEM_ROLE,
    systemRoleId,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Deleting...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'System Role deleted successfully',
    },
    payload,
  });

  return payload;
};

export const deleteSystemRoleAskConfirmation = (systemRole) => (dispatch) => {
  const systemRoleId = systemRole.get('roleId');

  dispatch({
    type: AUTHORIZATION_ACTIONS.DELETE_SYSTEM_ROLE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: 'Delete System Role',
      confirmButtonText: 'Delete',
      description: `Are you sure you want to delete ${systemRole.get('name')}?`,
    },
    payload: () =>
      dispatch(deleteSystemRole(systemRoleId)).then(() =>
        dispatch(replace(toSystemRole())),
      ),
  });
};

export const updateSystemRole = (systemRoleId, systemRole) => (dispatch) => {
  const payload = authorizationService.updateSystemRole(
    systemRoleId,
    systemRole,
  );

  dispatch({
    type: AUTHORIZATION_ACTIONS.UPDATE_SYSTEM_ROLE,
    payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Saved',
    },
  });

  return payload;
};

export const systemRoleFormOnChange = (values, dispatch, props) => {
  const { dirty, systemRoleId } = props;

  if (dirty) {
    dispatch(updateSystemRole(systemRoleId, values.toJS()));
  }
};

export const initializeSystemRoleForm = (systemRole) => (dispatch) => {
  return dispatch(initialize(SYSTEM_ROLE_FORM.ID, systemRole));
};

export const removeSystemRoleFromUser = (systemRole, user) => (dispatch) =>
  dispatch({
    type: AUTHORIZATION_ACTIONS.REMOVE_SYSTEM_ROLE_FROM_USER,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Removing Role...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The role was successfully removed',
    },
    confirmationDialog: {
      title: 'Remove role',
      confirmButtonText: 'Remove',
      description: `Are you sure you want to remove ${systemRole.get(
        'name',
      )} from ${user.get('name')}?`,
    },
    userId: user.get('id'),
    systemRoleId: systemRole.get('roleId'),
    payload: () =>
      authorizationService.removeSystemRoleFromUser(
        systemRole.get('roleId'),
        user.get('id'),
      ),
  });

export const addSystemRoleToUser = (systemRoleId, userId) => (dispatch) => {
  const payload = authorizationService.addSystemRoleToUser(
    systemRoleId,
    userId,
  );

  dispatch({
    type: AUTHORIZATION_ACTIONS.ADD_SYSTEM_ROLE_TO_USER,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Adding Role...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The role was successfully added',
    },
    payload,
    userId,
    systemRoleId,
  });

  return payload;
};

export const getUsersAssignedToSystemRole = (systemRoleId) => (dispatch) => {
  const payload =
    authorizationService.getUsersAssignedToSystemRole(systemRoleId);

  dispatch({
    type: AUTHORIZATION_ACTIONS.GET_USERS_ASSIGNED_TO_SYSTEM_ROLE,
    roleId: systemRoleId,
    payload,
  });

  return payload;
};

export const getSystemRolePermissions = (roleId) => (dispatch) => {
  const payload = authorizationService.getSystemRolePermissions(roleId);

  dispatch({
    type: AUTHORIZATION_ACTIONS.GET_SYSTEM_ROLE_PERMISSIONS,
    payload,
    roleId,
  });

  return payload;
};

export const assignSystemPermissionsToSystemRole =
  (roleId, permissionIds) => (dispatch) => {
    const payload = authorizationService.assignSystemPermissionsToSystemRole(
      roleId,
      permissionIds.map((permissionId) =>
        authorizationMappers.RolePermission.to(roleId, permissionId),
      ),
    );

    dispatch({
      type: AUTHORIZATION_ACTIONS.ASSIGN_SYSTEM_PERMISSIONS_TO_SYSTEM_ROLE,
      payload,
      roleId,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Assigning...',
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'Role permissions assigned successfully',
      },
    });

    return payload;
  };

export const unassignSystemPermissionsFromSystemRole =
  (roleId, permissionIds) => (dispatch) => {
    const payload =
      authorizationService.unassignSystemPermissionsFromSystemRole(
        roleId,
        permissionIds,
      );

    dispatch({
      type: AUTHORIZATION_ACTIONS.UNASSIGN_SYSTEM_PERMISSIONS_FROM_SYSTEM_ROLE,
      payload,
      roleId,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Unassigning...',
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'Role permissions unassigned successfully',
      },
    });

    return payload;
  };

export const getSystemRoleProjectPermissions = (roleId) => (dispatch) => {
  const payload = authorizationService.getSystemRoleProjectPermissions(roleId);

  dispatch({
    type: AUTHORIZATION_ACTIONS.GET_SYSTEM_ROLE_PROJECT_PERMISSIONS,
    payload,
    roleId,
  });

  return payload;
};

export const assignProjectPermissionsToSystemRole =
  (roleId, permissionIds) => (dispatch) => {
    const payload = authorizationService.assignProjectPermissionsToSystemRole(
      roleId,
      permissionIds.map((permissionId) =>
        authorizationMappers.RolePermission.to(roleId, permissionId),
      ),
    );

    dispatch({
      type: AUTHORIZATION_ACTIONS.ASSIGN_PROJECT_PERMISSIONS_TO_SYSTEM_ROLE,
      payload,
      roleId,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Assigning...',
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'Role permissions assigned successfully',
      },
    });

    return payload;
  };

export const unassignProjectPermissionsFromSystemRole =
  (roleId, permissionIds) => (dispatch) => {
    const payload =
      authorizationService.unassignProjectPermissionsFromSystemRole(
        roleId,
        permissionIds,
      );

    dispatch({
      type: AUTHORIZATION_ACTIONS.UNASSIGN_PROJECT_PERMISSIONS_FROM_SYSTEM_ROLE,
      payload,
      roleId,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Unassigning...',
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'Role permissions unassigned successfully',
      },
    });

    return payload;
  };

/** Project Roles */

export const projectRoleAccessRightsOnLoad = (projectRoleId) => (dispatch) =>
  dispatch(getProjectPermissions()).then(() =>
    dispatch(getProjectRolePermissions(projectRoleId)),
  );

export const getProjectRoles = () => (dispatch) => {
  const payload = authorizationService.getProjectRoles();

  dispatch({
    type: AUTHORIZATION_ACTIONS.GET_PROJECT_ROLES,
    payload,
  });

  return payload;
};

export const getProjectRole = (projectRoleId) => (dispatch) => {
  const payload = authorizationService.getProjectRole(projectRoleId);

  dispatch({
    type: AUTHORIZATION_ACTIONS.GET_PROJECT_ROLE,
    payload,
    projectRoleId,
  });

  return payload;
};

export const deleteProjectRole = (projectRoleId) => (dispatch) => {
  const payload = authorizationService.deleteProjectRole(projectRoleId);

  dispatch({
    type: AUTHORIZATION_ACTIONS.DELETE_PROJECT_ROLE,
    projectRoleId,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Deleting...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Project Role deleted successfully',
    },
    payload,
  });

  return payload;
};

export const deleteProjectRoleAskConfirmation = (projectRole) => (dispatch) => {
  const projectRoleId = projectRole.get('roleId');

  dispatch({
    type: AUTHORIZATION_ACTIONS.DELETE_PROJECT_ROLE_ASK_CONFIRMATION,
    projectRoleId,
    confirmationDialog: {
      title: 'Delete Project Role',
      confirmButtonText: 'Delete',
      description: `Are you sure you want to delete ${projectRole.get(
        'name',
      )}?`,
    },
    payload: () =>
      dispatch(deleteProjectRole(projectRoleId)).then(() =>
        dispatch(replace(toProjectRole())),
      ),
  });
};

export const createProjectRole = (projectRole) => (dispatch) => {
  const payload = authorizationService.createProjectRole(projectRole);

  dispatch({
    type: AUTHORIZATION_ACTIONS.CREATE_PROJECT_ROLE,
    payload,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Creating...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Project Role created successfully',
    },
  });

  return payload;
};

export const createProjectRoleOnSubmit =
  (toggleModal) => (dispatch) => (projectRole) => {
    dispatch(createProjectRole(projectRole.toJS()))
      .then(({ roleId }) => dispatch(replace(toProjectRole(roleId))))
      .then(toggleModal);
  };

export const updateProjectRole = (projectRoleId, projectRole) => (dispatch) => {
  const payload = authorizationService.updateProjectRole(
    projectRoleId,
    projectRole,
  );

  dispatch({
    type: AUTHORIZATION_ACTIONS.UPDATE_PROJECT_ROLE,
    payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Saved',
    },
  });

  return payload;
};

export const projectRoleFormOnChange = (projectRole, dispatch, props) => {
  const { dirty, projectRoleId } = props;

  if (dirty) {
    dispatch(updateProjectRole(projectRoleId, projectRole.toJS()));
  }
};

export const initializeProjectRoleForm = (projectRole) => (dispatch) => {
  return dispatch(initialize(PROJECT_ROLE_FORM.ID, projectRole));
};

export const getProjectRolePermissions = (roleId) => (dispatch) => {
  const payload = authorizationService.getProjectRolePermissions(roleId);

  dispatch({
    type: AUTHORIZATION_ACTIONS.GET_PROJECT_ROLE_PERMISSIONS,
    payload,
    roleId,
  });

  return payload;
};

export const assignPermissionsToProjectRole =
  (roleId, permissionIds) => (dispatch) => {
    const payload = authorizationService.assignPermissionsToProjectRole(
      roleId,
      permissionIds.map((permissionId) =>
        authorizationMappers.RolePermission.to(roleId, permissionId),
      ),
    );

    dispatch({
      type: AUTHORIZATION_ACTIONS.ASSIGN_PERMISSIONS_TO_PROJECT_ROLE,
      payload,
      roleId,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Assigning...',
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'Role permissions assigned successfully',
      },
    });

    return payload;
  };

export const unassignPermissionsFromProjectRole =
  (roleId, permissionIds) => (dispatch) => {
    const payload = authorizationService.unassignPermissionsFromProjectRole(
      roleId,
      permissionIds,
    );

    dispatch({
      type: AUTHORIZATION_ACTIONS.UNASSIGN_PERMISSIONS_FROM_PROJECT_ROLE,
      payload,
      roleId,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Unassigning...',
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'Role permissions unassigned successfully',
      },
    });

    return payload;
  };

export const getProjectPermissions = () => (dispatch) => {
  const payload = authorizationService.getProjectPermissions();

  dispatch({
    type: AUTHORIZATION_ACTIONS.GET_PROJECT_PERMISSIONS,
    payload,
  });

  return payload;
};

export const getSystemPermissions = () => (dispatch) => {
  const payload = authorizationService.getSystemPermissions();

  dispatch({
    type: AUTHORIZATION_ACTIONS.GET_SYSTEM_PERMISSIONS,
    payload,
  });

  return payload;
};
