import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Button, Typography } from '@material-ui/core';

const AddEquipmentTemplatesModalHeader = ({ title, closeModal }) => (
  <Grid container alignItems="center" justifyContent="spaceBetween">
    <Grid item xs>
      <Typography variant="h6">{title}</Typography>
    </Grid>
    <Grid container item xs justifyContent="flex-end" spacing={2}>
      <Grid item>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Close
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

const styles = () => ({});

export default compose(withStyles(styles))(AddEquipmentTemplatesModalHeader);
