import { Field } from 'formik';
import { InputAdornment } from '@material-ui/core';

import { required } from 'utils/validation.util';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import MultiphaseLiquidFormSection from 'app/components/FluidsFormSection/FluidForm/FluidSurfaceProperties/MultiphaseFormSection/BlackOilMultiphaseFormSection/MultiphaseLiquidFormSection';
import { FluidFormFields as FormFields } from 'utils/fluids/constants.utils';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';

const BlackOilMultiphaseFormSection = ({ disabled }) => (
  <>
    <MultiphaseLiquidFormSection />
    <Field
      required
      type="number"
      destroyOnUnmount
      disabled={disabled}
      validate={required}
      label="Gas/Liquid Ratio"
      component={TextFieldFormik}
      name={FormFields.GAS_LIQUID_RATIO}
      InputProps={{
        endAdornment: <InputAdornment position="end">scf/bbl</InputAdornment>,
      }}
      useRoundedValue={true}
    />
    <Field
      required
      type="number"
      destroyOnUnmount
      label="Gas Density"
      disabled={disabled}
      validate={required}
      component={QuantityTextFieldFormik}
      name={FormFields.MULTIPHASE_GAS_DENSITY}
      useRoundedValue={true}
    />
  </>
);

export default BlackOilMultiphaseFormSection;
