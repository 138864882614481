import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useCallback, useState } from 'react';
import Replay from '@material-ui/icons/Replay';
import DoneAll from '@material-ui/icons/DoneAll';
import toJSComponent from 'with-immutable-props-to-js';
import withStyles from '@material-ui/styles/withStyles';

import { useModal } from 'altus-modal';

import { Button } from 'app/components/withTooltip';
import { toggleCompleteTaskModal } from 'features/projects/activities/activities.actions';
import { COMPLETE_TASK_MODAL_ID } from 'features/projects/activities/activities.constants';
import CompleteTaskModalContainer from 'features/projects/activities/components/CompleteTaskModalContainer';

const CompleteTask = ({
  tasks,
  taskId,
  goBack,
  classes,
  nextTask,
  projectId,
  currentTask,
}) => {
  const onGoBack = useCallback(
    () => goBack(projectId, taskId),
    [projectId, taskId, goBack],
  );

  const [isOpen, toggleModal] = useModal(COMPLETE_TASK_MODAL_ID);

  const [objectivesMet, setObjectivesMet] = useState();

  const toggleCompleteTaskModal = useCallback(
    (isObjectivesMet) => {
      setObjectivesMet(isObjectivesMet);
      toggleModal();
    },
    [toggleModal],
  );

  return (
    <Grid item className={classes.completeTask}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={() => toggleCompleteTaskModal(true)}
          >
            <DoneAll className={classes.leftIcon} />
            Objectives met
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => toggleCompleteTaskModal(false)}
          >
            <Close className={classes.leftIcon} />
            Objectives not met
          </Button>
        </Grid>
        <Grid item>
          <Button title="Go back" variant="contained" onClick={onGoBack}>
            <Replay />
          </Button>
        </Grid>
      </Grid>
      <CompleteTaskModalContainer
        tasks={tasks}
        isOpen={isOpen}
        taskId={taskId}
        nextTask={nextTask}
        projectId={projectId}
        toggleModal={toggleModal}
        currentTask={currentTask}
        objectivesMet={objectivesMet}
      />
    </Grid>
  );
};

const styles = (theme) => ({
  completeTask: {
    marginTop: 30,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

export default compose(
  connect(null, {
    dispatchToggleCompleteTaskModal: toggleCompleteTaskModal,
  }),
  withStyles(styles),
  toJSComponent,
)(CompleteTask);
