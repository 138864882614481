export const TOOL_ACTIONS = {
  // M3
  UPDATE_TOOLS_FROM_M3_ITEMS: 'UPDATE_TOOLS_FROM_M3_ITEMS',

  // Tool Images
  UPLOAD_TOOL_IMAGE: 'UPLOAD_TOOL_IMAGE',
  UPDATE_TOOL_IMAGE: 'UPDATE_TOOL_IMAGE',
  DELETE_TOOL_IMAGE: 'DELETE_TOOL_IMAGE',
  ASSOCIATE_IMAGE_TO_TOOL: 'ASSOCIATE_IMAGE_TO_TOOL',
  DISASSOCIATE_IMAGE_FROM_TOOL: 'DISASSOCIATE_IMAGE_FROM_TOOL',

  // Saga
  REQUEST_TOOL_IMAGES: 'REQUEST_TOOL_IMAGES',
  RECEIVE_TOOL_IMAGES: 'RECEIVE_TOOL_IMAGES',
  REQUEST_TOOLS_SEARCH: 'REQUEST_TOOLS_SEARCH',
  RECEIVE_TOOLS_SEARCH: 'RECEIVE_TOOLS_SEARCH',
  REQUEST_TOOL_GROUPS: 'REQUEST_TOOL_GROUPS',
  RECEIVE_TOOL_GROUPS: 'RECEIVE_TOOL_GROUPS',
  REQUEST_TOOL_SUPPLIERS: 'REQUEST_TOOL_SUPPLIERS',
  RECEIVE_TOOL_SUPPLIERS: 'RECEIVE_TOOL_SUPPLIERS',
  REQUEST_TOOL_GROUPS_BY_CATEGORY: 'REQUEST_TOOL_GROUPS_BY_CATEGORY',
  TOOL_PICKER_MODAL_ON_LOAD: 'TOOL_PICKER_MODAL_ON_LOAD',
  REQUEST_TOOL_CONNECTORS: 'REQUEST_TOOL_CONNECTORS',
  RECEIVE_TOOL_CONNECTORS: 'RECEIVE_TOOL_CONNECTORS',
  REQUEST_TOOL_TOP_CONNECTORS: 'REQUEST_TOOL_TOP_CONNECTORS',
  RECEIVE_TOOL_TOP_CONNECTORS: 'RECEIVE_TOOL_TOP_CONNECTORS',
  REQUEST_TOOL_BOTTOM_CONNECTORS: 'REQUEST_TOOL_BOTTOM_CONNECTORS',
  RECEIVE_TOOL_BOTTOM_CONNECTORS: 'RECEIVE_TOOL_BOTTOM_CONNECTORS',
  REQUEST_REFRESH_ALL_TOOLS_WITH_M3: 'REQUEST_REFRESH_ALL_TOOLS_WITH_M3',
  REQUEST_CABLES: 'REQUEST_CABLES',
  REQUEST_CABLES_BY_PROJECT: 'REQUEST_CABLES_BY_PROJECT',
  RECEIVE_CABLES: 'RECEIVE_CABLES',
  RECEIVE_TOOL_PROJECT_HISTORY: 'RECEIVE_TOOL_PROJECT_HISTORY',

  // IMPORT
  IMPORT_TOOLS_FROM_FILE: 'IMPORT_TOOLS_FROM_FILE',
  IMPORT_TOOLS_MODAL_ID: 'IMPORT_TOOLS_MODAL_ID',
};

export const MODAL = {
  ATTACH_TOOL_PICKER_MODAL_ID: 'ATTACH_TOOL_PICKER_MODAL_ID',
  TOOL_PICKER_MODAL_ID: 'TOOL_PICKER_MODAL_ID',
  CABLE_PICKER_MODAL_ID: 'CABLE_PICKER_MODAL_ID',
  TOOL_ASSEMBLY_TOOL_PICKER_MODAL_ID: 'TOOL_ASSEMBLY_TOOL_PICKER_MODAL_ID',
};

export const CableType = {
  MONO: 1,
  SWAB: 2,
  SLICKLINE: 3,
  MULTICONDUCTOR: 4,
  COAX: 5,
  ELINE: 6,
};

export const ExifOrientation = {
  DEGREES_0: 1, // the correct orientation, no adjustment is required
  DEGREES_90_MIRRORED: 6, // image is on its side - clockwise
  DEGREES_90: 8, // image is on its side - counterclockwise
};

// maps to ToolGroupCategory in backend
export const ToolCategoryType = {
  MWL: 1,
  ThirdParty: 2,
  EWL: 3,
  Assemblies: 4,
};

export const ToolType = {
  TOOL: 0,
  ASSEMBLY: 1,
  ATTACHMENT: 2,
  ASSEMBLY_TOOL: 10,
};

export const ThirdPartyLabel = '3rd party';

export const MissingSerialNoModal = {
  TITLE: 'Missing serial numbers',
  CONTENT:
    'All tools needs to have serial numbers in order to verify the tool string.',
  BUTTON: 'Close',
};
