export const PROJECT_WELLBORE_ACTIONS = {
  RECEIVE_PROJECT_WELLBORE: 'RECEIVE_PROJECT_WELLBORE',
  RECEIVE_PROJECT_WELLBORE_TRAJECTORY: 'RECEIVE_PROJECT_WELLBORE_TRAJECTORY',

  GET_PROJECT_WELLBORE: 'GET_PROJECT_WELLBORE',
  PROJECT_WELLBORE_ON_LOAD: 'PROJECT_WELLBORE_ON_LOAD',
  GET_PROJECT_WELLBORE_TRAJECTORY: 'GET_PROJECT_WELLBORE_TRAJECTORY',
  SYNC_PROJECT_WELLBORE_TRAJECTORY_WITH_LATEST:
    'SYNC_PROJECT_WELLBORE_TRAJECTORY_WITH_LATEST',
  UPDATE_PROJECT_WELLBORE_CONFIRMATION_DIALOG:
    'UPDATE_PROJECT_WELLBORE_CONFIRMATION_DIALOG',
};
