import { useMemo } from 'react';
import Add from '@material-ui/icons/Add';
import { Box, Grid, Button } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import TemplateTableRow from 'features/projects/tasks/components/createTask/TemplateTableRow';
import { alpha } from '@material-ui/core/styles';

const TemplateTasksTable = ({
  classes,
  templateTasks,
  selectTemplateTask,
  TableRowComponent = TemplateTableRow,
}) => {
  const displayExpandColumn = TableRowComponent === TemplateTableRow;

  const initialState = useMemo(
    () => ({
      hiddenColumns: displayExpandColumn ? [] : ['expansionIcon'],
    }),
    [displayExpandColumn],
  );

  const columns = useMemo(
    () => [
      {
        id: 'expansionIcon',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: () => (
          <TableRowActionsCell>
            <ExpandMoreIcon />
          </TableRowActionsCell>
        ),
      },
      {
        xs: 12,
        Header: 'Name',
        accessor: (template) => template.get('title'),
      },
      {
        id: 'actions',
        Cell: ({ row }) => {
          const template = row.original;

          return (
            <Grid container item xs justify="flex-end">
              <Button
                size="small"
                color="primary"
                variant="contained"
                aria-label="Add"
                className={classes.addBtn}
                onClick={(event) => {
                  event.stopPropagation();
                  selectTemplateTask(template);
                }}
              >
                Add
                <Box component={Add} fontSize="small" marginLeft={0.5} />
              </Button>
            </Grid>
          );
        },
      },
    ],
    [classes, selectTemplateTask],
  );

  return (
    <Table
      columns={columns}
      items={templateTasks}
      noItemsMessage={'No items'}
      initialState={initialState}
      TableRowComponent={TableRowComponent}
    />
  );
};

const styles = (theme) => ({
  addBtn: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.5),
    },
  },
});

export default withStyles(styles)(TemplateTasksTable);
