import { useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { between } from 'app/components/WellboreTrajectoryDetailed3DView/Utils';
import { createTextSprite } from 'app/components/WellboreContextualization/RenderNippleText';
import {
  checkIntersection,
  createNewNipple,
} from 'app/components/WellboreContextualization/RenderNipples';

const DrawNipples = ({ nippleRef, nippleTextRef }) => {
  const { scene } = useThree();

  useEffect(() => {
    scene.traverse((cylinder) => {
      if (!cylinder.name.startsWith('trajectory')) return;

      nippleRef.current.forEach((nipple) => {
        if (
          between(
            nipple.key,
            cylinder.data.measuredDepthStart,
            cylinder.data.measuredDepthEnd,
          )
        ) {
          const nippleInScene = scene.getObjectByName(
            `nipple-${nipple.value.top}`,
          );
          if (!nippleInScene) {
            const diff =
              nipple.value.top -
              (cylinder.data.measuredDepthStart +
                cylinder.data.measuredDepthEnd) /
                2;
            const newNipple = createNewNipple(nipple.value);
            newNipple.name = nipple.value.name;
            newNipple.position.y += nipple.value.length / 2;
            cylinder.add(newNipple);
            newNipple.translateY(diff);
            newNipple.rotateX(Math.PI / 2);
            cylinder.updateMatrixWorld();

            const text = createTextSprite(nipple.value.spriteText, 40);
            newNipple.add(text);
            text.position.y += nipple.value.outerRadius + 20;
            if (nippleTextRef.current.length) {
              for (const existingSprite of nippleTextRef.current) {
                if (
                  checkIntersection(text, existingSprite) &&
                  text.name !== existingSprite.name
                ) {
                  text.position.set(
                    text.position.x,
                    text.position.y + existingSprite.scale.y / 2,
                    text.position.z,
                  );
                }
              }
            }
            nippleTextRef.current.push(text);
          }
        }
      });
    });
  }, [scene, nippleRef, nippleTextRef]);

  return null;
};

export default DrawNipples;
