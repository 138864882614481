import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import { fromJS, isSet, Set } from 'immutable';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import cookies from 'browser-cookies';
import isFunction from 'lodash/isFunction';
import { isMobile } from 'react-device-detect';

import scrollIntoView from 'scroll-into-view-if-needed';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import {
  BEARER_TOKEN_COOKIE_ID,
  MSAL_PREFIX,
  RiskLevels,
  RiskMatrix,
  RiskPriorityMatrix,
  THEME_COOKIE_ID,
  WORK_ITEM_STATUS,
} from 'app/app.constants';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import config from 'infrastructure/config';

export const isPromise = (argument) =>
  !_.isNil(argument) && _.isFunction(argument.then);

export const invokeIfFunction = (fn, ...args) => isFunction(fn) && fn(...args);

export const getInitials = (fullName = '') => {
  const names = fullName.split(' ');

  return names.length > 1
    ? [_.first(names) || '', _.last(names) || ''].reduce(
        (r, name) => r + _.upperCase(name.charAt(0)),
        '',
      )
    : _.upperCase(_.first(names).charAt(0));
};

export const getAvatar = (avatar) => {
  return avatar ? `data:image/png;base64,${avatar}` : null;
};

export const scrollSmoothlyIntoViewIfNeeded = (node) =>
  node &&
  scrollIntoView(node, {
    behavior: 'smooth',
    scrollMode: 'if-needed',
  });

export const scrollToTopSmoothly = (node) => {
  if (node) {
    if (node.scrollTo) {
      node.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      node.scrollTop = 0;
    }
  }
};

export const durationFromStartAndEndTime = (startTime, endTime = moment()) =>
  moment.isMoment(startTime) && moment.isMoment(endTime)
    ? moment.duration(endTime.diff(startTime))
    : null;

export const calcActivityDuration =
  (waitCodesDuration) =>
  (startTime, endTime = moment()) => {
    let result = durationFromStartAndEndTime(startTime, endTime);

    // Reduce activity duration for duration of wait codes (if any)
    return waitCodesDuration && result
      ? result.clone().subtract(waitCodesDuration)
      : result;
  };

export const renderContent = (Component, props) =>
  isFunction(Component) ? <Component {...props} /> : Component;

export const saveFile = (blob, filename) => FileSaver.saveAs(blob, filename);

export const getWorkItemStatusDisplayName = (status) => {
  switch (status) {
    case WORK_ITEM_STATUS.STARTED:
      return 'In Progress';
    case WORK_ITEM_STATUS.PAUSED:
      return 'Paused';
    case WORK_ITEM_STATUS.COMPLETED:
      return 'Completed';
    case WORK_ITEM_STATUS.SKIPPED:
      return 'Skipped';
    case WORK_ITEM_STATUS.FAILED:
      return 'Failed';
    case WORK_ITEM_STATUS.NOT_SET:
      return 'N/A';
    case WORK_ITEM_STATUS.PLANNED:
      return 'Planned';
    default:
      return '';
  }
};

const toCookieValueFromUserId = (userId) => `${userId}-${THEME_COOKIE_ID}`;

export const getCurrentThemeOrDefault = (userId, defaultTheme) => {
  if (userId) {
    return cookies.get(toCookieValueFromUserId(userId)) || defaultTheme;
  }

  return defaultTheme;
};

export const setCurrentTheme = (userId, theme) => {
  if (userId && theme) {
    cookies.set(toCookieValueFromUserId(userId), theme, {
      expires: 365,
    });
  }
};

export const getBearerToken = () => cookies.get(BEARER_TOKEN_COOKIE_ID);

export const eraseBearerToken = () => cookies.erase(BEARER_TOKEN_COOKIE_ID);

export const deleteMsalFromLocalStorage = () => {
  eraseBearerToken();

  Object.keys(localStorage)
    .filter((key) => key.startsWith(MSAL_PREFIX))
    .forEach((key) => localStorage.removeItem(key));
};

export const createSelector = createSelectorCreator(defaultMemoize, isEqual);

export const makeActionCreator = (action, ...argNames) => {
  const { payload: payloadFn } = action;

  return (...args) =>
    (dispatch) => {
      const payloadResult = payloadFn(...args);

      const payload = isFunction(payloadResult)
        ? dispatch(payloadResult)
        : payloadResult;

      const updatedAction = argNames.reduce(
        (sum, _curr, index) => ({
          ...sum,
          [argNames[index]]: args[index],
        }),
        {
          ...action,
          payload,
        },
      );

      dispatch(updatedAction);

      return payload;
    };
};

export const convertToSet = (items) => {
  if (isSet(items)) return items;

  return isArray(items) ? Set(items) : Set.of(items);
};

export const registerServiceWorker = () => {
  if (isMobile && 'serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker
        .register('/serviceWorker.js', {
          scope: '/',
        })
        .then(() => console.log('service worker registered'))
        .catch((err) =>
          console.log('service worker not registered! error: ', err),
        );
    });
  }
};

export const QuantityShape = PropTypes.shape({
  value: PropTypes.number,
  unit: PropTypes.string.isRequired,
  hasValue: PropTypes.bool.isRequired,
});

export const getQuantityUnitFromItems = (items, quantitySelector) => {
  if (!items) return null;
  if (!items.size) return null;

  // convert to Seq for lazy evaluation
  var quantity = items.toSeq().map(quantitySelector).first();

  if (!quantity) return null;

  return quantity.unit;
};

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getRichEditorText = (text, showText = false) => {
  if (!text) {
    return null;
  }

  if (!isValidJSON(text)) {
    return showText ? text : null;
  }

  const textObject = JSON.parse(text);
  return textObject.blocks && Array.isArray(textObject.blocks)
    ? textObject.blocks.map((block) => block.text).join(' ')
    : null;
};

export const RichText = (item, key) =>
  item?.get(key)
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(item?.get(key))))
    : EditorState.createEmpty();

export const validateTimeString = (timeString, notRequired = false) => {
  if (notRequired && (timeString === 0 || timeString === '0')) return true;
  if (
    timeString === 0 ||
    timeString === undefined ||
    Number.isNaN(timeString) ||
    timeString === '0' ||
    timeString === 'NaN'
  ) {
    return false;
  }
  return true;
};

export const sortRisks = (risks) => {
  if (!risks || typeof risks.toJS !== 'function') {
    return fromJS([]);
  }
  const risksArray = risks.toJS();
  risksArray.sort((a, b) => {
    const riskA = RiskMatrix[a.consequence][a.probability];
    const riskB = RiskMatrix[b.consequence][b.probability];

    const priorityA = RiskPriorityMatrix[a.consequence][a.probability];
    const priorityB = RiskPriorityMatrix[b.consequence][b.probability];

    if (riskA === riskB) {
      return priorityA - priorityB;
    }

    return RiskLevels[riskB] - RiskLevels[riskA];
  });
  return fromJS(risksArray);
};

export const formatDateTimeFromSeconds = (dateStr, timeInSeconds) => {
  const dateMoment = moment.utc(dateStr);
  dateMoment.startOf('day').add(timeInSeconds, 'seconds');
  return dateMoment.format('YYYY-MM-DDTHH:mm:ssZ');
};

const parseDateStringToMoment = (dateStr) => moment(dateStr);

export const extractTimeFromDate = (dateStr) => {
  const dateMoment = parseDateStringToMoment(dateStr);
  return dateMoment.format('hh:mm');
};

export const extractTimeIn24HourFormat = (dateStr) => {  
  const dateMoment = parseDateStringToMoment(dateStr);
  return dateMoment.format('HH:mm');
};

export const extractTimeIn24HourFormatUTC = (dateStr) => {
  const dateMoment = parseDateStringToMoment(dateStr).utc();
  return dateMoment.format('HH:mm');
};

export const editorStateToString = (editorState) => {
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  return JSON.stringify(rawContentState);
};

export const stringToEditorState = (str) => {
  const rawContentState = JSON.parse(str);
  const contentState = convertFromRaw(rawContentState);
  return EditorState.createWithContent(contentState);
};

export const EditorContentToStringWithTry = (editorContent) => {
  try {
    if (!editorContent) {
      console.error(
        'Error converting content state to raw JSON',
        editorContent,
      );
      return null;
    }

    const rawContent = editorStateToString(editorContent);
    return rawContent;
  } catch (error) {
    console.error('Error converting content state to raw JSON:', error);
    return null;
  }
};

export const getBrowserName = () => {
  const userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/edg/i)) {
    browserName = 'Edge';
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'Chrome';
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'Firefox';
  } else if (
    userAgent.match(/safari/i) &&
    !userAgent.match(/chrome|chromium|crios/i)
  ) {
    browserName = 'Safari';
  } else if (userAgent.match(/opr\//i)) {
    browserName = 'Opera';
  } else if (userAgent.match(/msie|trident/i)) {
    browserName = 'Internet Explorer';
  } else {
    browserName = 'Unknown';
  }

  return browserName;
};

export const formatDurationInSeconds = (durationInSeconds) => {
  const duration = durationInSeconds ?? 0;
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}h ${formattedMinutes}m`;
};

function acquireTokenThroughLogin(msalInstance, request) {
  return isMobile
    ? msalInstance.acquireTokenRedirect(request)
    : msalInstance.acquireTokenPopup(request);
}

export function acquireToken(msalInstance, saveIncomingPathCb) {
  const request = {
    scopes: [config.scope],
  };

  return msalInstance.acquireTokenSilent(request).catch(() => {
    saveIncomingPathCb();
    return acquireTokenThroughLogin(msalInstance, request);
  });
}
