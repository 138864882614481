import Agent from 'infrastructure/agent';
import lessonsMapper from 'features/projects/lessons/lessons.mappers';

export const allLessons = (projectId) =>
  Agent.get(`/project/${projectId}/lessons`);

export const lessonById = (projectId, lessonId) =>
  Agent.get(`/project/${projectId}/lessons/${lessonId}`);

export const updateLesson = (projectId, lesson) =>
  Agent.put(
    `/project/${projectId}/lessons/${lesson.id}`,
    lessonsMapper.Lesson.to(lesson),
  );

export const createLesson = (projectId, lesson) =>
  Agent.post(`/project/${projectId}/lessons`, lessonsMapper.Lesson.to(lesson));

export const deleteLesson = (projectId, lessonId) =>
  Agent.delete(`/project/${projectId}/lessons/${lessonId}`);

const lessonsService = {
  allLessons,
  lessonById,
  updateLesson,
  createLesson,
  deleteLesson,
};

export default lessonsService;
