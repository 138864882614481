import { useMemo } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button, Grid } from '@material-ui/core';

import { Table } from 'altus-ui-components';

const defaultInitialState = {
  pageSize: 50,
};

const AddSurfaceEquipmentInPlanningModalTable = ({
  tools,
  classes,
  onRemoveSurface,
  onSaveSurfaceEquipment,
  selectedSurfaceEquipment,
  setSelectedSurfaceEquipment,
  initialState = defaultInitialState,
  ...rest
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 2,
        Header: 'Item no.',
        accessor: (tool) => tool.get('m3ItemNumber'),
      },
      {
        xs: 2,
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
      {
        xs: 2,
        Header: 'Surface Eq. Group',
        accessor: (tool) => tool.getIn(['equipmentGroup', 'name']),
      },
      {
        xs: 2,
        Header: 'Weight (kg)',
        accessor: (tool) => tool.get('weight').toFixed(2),
      },
      {
        xs: 2,
        Header: 'Height (mm)',
        accessor: (tool) => tool.get('height').toFixed(2),
      },
      {
        xs: 2,
        Header: 'Length (mm)',
        accessor: (tool) => tool.get('length').toFixed(2),
      },
      {
        xs: 2,
        Header: 'Width (mm)',
        accessor: (tool) => tool.get('width').toFixed(2),
      },
      {
        xs: 2,
        id: 'action',
        Cell: ({ row }) => {
          const tool = row.original;
          return (
            <Grid className={classes?.actions}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                title="Add tool"
                onClick={() => onSaveSurfaceEquipment(tool)}
              >
                <AddIcon />
                Add
              </Button>
            </Grid>
          );
        },
      },
    ],
    [classes, onSaveSurfaceEquipment],
  );

  return (
    <Table
      usePagination
      items={tools}
      columns={columns}
      useGlobalFilter={false}
      initialState={initialState}
      {...rest}
    />
  );
};

export default AddSurfaceEquipmentInPlanningModalTable;
