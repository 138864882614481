import { memo } from 'react';
import { compose } from 'redux';
import { Grid } from '@material-ui/core';

import withStyles from '@material-ui/styles/withStyles';

import { TableHeaderRow } from 'altus-ui-components';
import SimulationResultsTableHeaderCell from 'features/projects/tasks/task/simulation/components/SimulationResultsTableHeaderCell';

const SimulationResultsTableHeader = ({ classes, headerGroups }) => (
  <Grid container direction="column" className={classes.headersContainer}>
    {headerGroups.map((headerGroup) => {
      const { headers, getHeaderGroupProps } = headerGroup;

      return (
        <TableHeaderRow
          columns={headers}
          {...getHeaderGroupProps()}
          classes={{ root: classes.headerRow }}
          TableHeaderCellComponent={SimulationResultsTableHeaderCell}
        />
      );
    })}
  </Grid>
);

const styles = (theme) => ({
  headersContainer: {
    top: 0,
    zIndex: 2,
    position: 'sticky',
    marginBottom: theme.spacing(0.5),
    borderBottom: `2px solid ${theme.palette.table.row.hover}`,
  },
  headerRow: {
    border: 'none',
    margin: 0,
  },
});

export default compose(memo, withStyles(styles))(SimulationResultsTableHeader);
