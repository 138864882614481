import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Grid, Divider } from '@material-ui/core';

import ReadOnlyField from 'app/components/ReadOnlyField';

const details = [
  {
    label: 'Type',
    accessor: (cable) => cable.get('typeName'),
    render: ({ value, label }) => (
      <ReadOnlyField margin="none" label={label} input={{ value }} />
    ),
  },
  {
    label: (cable) =>
      `Nominal Diameter (${cable?.getIn(['nominalDiameter', 'unit'], null)})`,
    accessor: (cable) =>
      `${cable?.getIn(['nominalDiameter', 'roundedValue'], null)}`,
    render: ({ value, label }) => (
      <ReadOnlyField margin="none" label={label} value={value} />
    ),
  },
  {
    label: (cable) =>
      `Weight in Air (${cable?.getIn(['weightInAir', 'unit'], null)})`,
    accessor: (cable) =>
      `${cable?.getIn(['weightInAir', 'roundedValue'], null)}`,
    render: ({ value }) => (
      <ReadOnlyField margin="none" label={value} value={value} />
    ),
  },
  {
    label: (cable) =>
      `Stretch coef.(${cable?.getIn(
        ['stretch', 'unit'],
        cable?.getIn(['stretchCoefficient', 'unit']),
      )})`,
    accessor: (cable) => {
      const weightKey = cable.get('stretch') ? 'stretch' : 'stretchCoefficient';
      const roundedValue = cable?.getIn([weightKey, 'roundedValue'], null);
      const unit = cable?.getIn([weightKey, 'unit']);
      return `${roundedValue} ${unit}`;
    },
    render: ({ value, label }) => (
      <ReadOnlyField margin="none" label={label} value={value} />
    ),
  },
  {
    label: (cable) =>
      `Breaking Strength (${cable?.getIn(['strength', 'unit'], 'kgf')})`,
    accessor: (cable) => `${cable?.getIn(['strength', 'roundedValue'], null)}`,
    render: ({ value }) => (
      <ReadOnlyField margin="none" label={value} value={value} />
    ),
  },
  {
    label: (cable) =>
      `Safe Working Load (${cable?.getIn(['safeWorkingLoad', 'unit'], 'kgf')})`,
    accessor: (cable) =>
      `${cable?.getIn(['safeWorkingLoad', 'roundedValue'], null)}`,
    render: ({ value }) => (
      <ReadOnlyField margin="none" label={value} value={value} />
    ),
  },
];

const CableDetails = ({ cable }) => (
  <Grid container>
    {details.map(({ accessor, render, label }, index) => (
      <Grid item xs={12} key={index}>
        {render({ value: accessor(cable), label })}
        <Divider />
      </Grid>
    ))}
  </Grid>
);

CableDetails.propTypes = {
  toolstring: PropTypes.instanceOf(Map),
};

export default CableDetails;
