import { compose } from 'redux';
import { connect } from 'react-redux';
import { useEffect, memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import { getActionDataStateFromState } from 'app/app.selectors';

import { onLoad } from 'features/facilities/projects/facilitiesProjects.actions';

import { useHeader } from 'app/hooks/useHeader';
import ProjectsByStatus from 'features/projects/components/ProjectsByStatus';
import { FACILITIES_PROJECTS_ACTIONS } from 'features/facilities/projects/facilitiesProjects.constants';
import { getFacilityProjectsFromState } from 'features/facilities/projects/facilitiesProjects.selectors';

const FacilitiesProjects = ({
  classes,
  projects,
  dataState,
  breadcrumb,
  facilityId,
  dispatchOnLoad,
}) => {
  useHeader({
    subTitle: breadcrumb,
  });

  useEffect(() => {
    dispatchOnLoad(facilityId);
  }, [facilityId, dispatchOnLoad]);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid item container justifyContent="center">
        {!projects.size ? (
          <Grid item xs md={8}>
            <Typography>No projects...</Typography>
          </Grid>
        ) : (
          <Grid item xs md={8}>
            <ProjectsByStatus projects={projects} />
          </Grid>
        )}
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(
    (state) => ({
      projects: getFacilityProjectsFromState(state),
      dataState: getActionDataStateFromState(
        state,
        FACILITIES_PROJECTS_ACTIONS.FACILITIES_PROJECTS_PAGE_LOADED,
      ),
    }),
    {
      dispatchOnLoad: onLoad,
    },
  ),
  memo,
  withStyles(styles),
)(FacilitiesProjects);
