import reportsService from 'services/reports.service';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import { ACTIONS } from 'features/projects/tasks/task/simulation/simulation.constants';
import simulationService from 'services/simulation.service';

export const requestSimulation = (projectId, taskId, simulationId) => ({
  taskId,
  projectId,
  simulationId,
  type: ACTIONS.REQUEST_SIMULATION,
});

export const submitCreateSimulation = (
  projectId,
  taskId,
  simulation,
  setSubmitting,
  setStatus,
) => ({
  projectId,
  taskId,
  simulation,
  setSubmitting,
  setStatus,
  type: ACTIONS.SUBMIT_CREATE_SIMULATION,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: 'The simulation was successfully created',
  },
});

export const requestDuplicateSimulation = (
  projectId,
  taskId,
  simulationId,
) => ({
  taskId,
  projectId,
  simulationId,
  type: ACTIONS.REQUEST_DUPLICATE_SIMULATION,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Copying simulation...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'The simulation was successfully copied',
  },
});

export const requestCreateUpdatePlannedSimulation = (projectId, taskId) => ({
  taskId,
  projectId,
  type: ACTIONS.REQUEST_CREATE_UPDATE_PLANNED_SIMULATION,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Creating an updated planned simulation...',
    [NOTIFICATION_VARIANTS.SUCCESS]:
      'The updated simulation was successfully created',
  },
});

export const requestUpdateSimulation = (
  projectId,
  taskId,
  simulationId,
  simulation,
  resetResults,
) => ({
  taskId,
  projectId,
  simulation,
  simulationId,
  resetResults,
  type: ACTIONS.REQUEST_UPDATE_SIMULATION,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating simulation...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'The simulation was successfully updated',
  },
});

export const requestDeleteSimulation = (
  projectId,
  taskId,
  simulationId,
  redirect,
) => ({
  taskId,
  projectId,
  simulationId,
  redirect,
  type: ACTIONS.REQUEST_DELETE_SIMULATION,
  confirmationDialog: {
    title: 'Delete simulation',
    confirmButtonText: 'Delete',
    description: 'Are you sure you want to delete this simulation? ',
  },
  notification: {
    success: 'The simulation was successfully deleted',
  },
});

export const requestRunSimulation = (projectId, taskId, simulationId) => ({
  taskId,
  projectId,
  simulationId,
  type: ACTIONS.REQUEST_RUN_SIMULATION,
  notification: {
    success: 'The simulation was successful',
    config: {
      error: {
        duration: null,
      },
    },
  },
});

export const requestSimulationsForTask = (projectId, taskId, states = []) => ({
  taskId,
  projectId,
  states,
  type: ACTIONS.REQUEST_SIMULATIONS_FOR_TASK,
});

export const requestSimulationResults = (projectId, taskId, simulationId) => ({
  taskId,
  projectId,
  simulationId,
  type: ACTIONS.REQUEST_SIMULATION_RESULTS,
});

export const loadSimulations = (projectId, taskId, states = []) => ({
  taskId,
  projectId,
  states,
  type: ACTIONS.LOAD_SIMULATIONS,
});

export const loadSimulation = (projectId, taskId, simulationId) => ({
  taskId,
  projectId,
  simulationId,
  type: ACTIONS.LOAD_SIMULATION,
});

export const receiveSimulation = (simulation) => ({
  payload: simulation,
  type: ACTIONS.RECEIVE_SIMULATION,
});

export const deleteSimulationResults = (projectId, taskId, simulationId) => ({
  payload: () =>
    simulationService.deleteSimulationResults(projectId, taskId, simulationId),
  type: ACTIONS.DELETE_SIMULATION_RESULTS,
});

export const deleteSimulationFluids = (projectId, taskId, simulationId) => ({
  payload: () =>
    simulationService.deleteSimulationFluids(projectId, taskId, simulationId),
  type: ACTIONS.DELETE_SIMULATION_FLUIDS,
});

export const deleteSimulation = (simulationId) => ({
  simulationId,
  type: ACTIONS.DELETE_SIMULATION,
});

export const receiveSimulationsForTask = (simulations) => ({
  payload: simulations,
  type: ACTIONS.RECEIVE_SIMULATIONS_FOR_TASK,
});

export const receiveSimulationResults = (simulationResults) => ({
  payload: simulationResults,
  type: ACTIONS.RECEIVE_SIMULATION_RESULTS,
});

export const requestToggleSimulationFavorite = (
  projectId,
  taskId,
  simulationId,
) => ({
  type: ACTIONS.REQUEST_TOGGLE_SIMULATION_FAVORITE,
  projectId,
  taskId,
  simulationId,
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Updating favorite...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'Simulation favorite updated succesfully',
  },
});

export const requestToggleSimulationPlanned = (
  projectId,
  taskId,
  simulationId,
  isCurrentlyPlanned,
) => {
  const newState = isCurrentlyPlanned ? 'unplanned' : 'planned';

  const actionWithoutConfirmation = {
    type: ACTIONS.REQUEST_TOGGLE_SIMULATION_PLANNED,
    projectId,
    taskId,
    simulationId,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: `Marking as ${newState}...`,
      [NOTIFICATION_VARIANTS.SUCCESS]: `Simulation succesfully marked as ${newState}`,
    },
  };

  if (!isCurrentlyPlanned) {
    return actionWithoutConfirmation;
  }

  return {
    ...actionWithoutConfirmation,
    confirmationDialog: {
      title: 'Unlock Simulation',
      confirmButtonText: 'Unlock',
      description: 'Are you sure you want to unlock this Simulation?',
    },
  };
};

export const requestWellboreSectionsForSimulation = (
  projectId,
  taskId,
  simulationId,
) => ({
  projectId,
  taskId,
  simulationId,
  type: ACTIONS.REQUEST_SIMULATION_WELLBORE_SECTIONS,
});

export const updateWellboreSectionForSimulation = (
  projectId,
  taskId,
  simulationId,
  wellboreSectionId,
  wellboreSection,
) => ({
  projectId,
  taskId,
  simulationId,
  wellboreSectionId,
  wellboreSection,
  type: ACTIONS.REQUEST_UPDATE_SIMULATION_WELLBORE_SECTION,
  notification: {
    success: 'Section successfully updated',
  },
});

export const requestSimulationFluids = (projectId, taskId, simulationId) => ({
  taskId,
  projectId,
  simulationId,
  type: ACTIONS.REQUEST_SIMULATION_FLUIDS,
});

export const requestCreateSimulationFluid = (
  projectId,
  taskId,
  simulationId,
  simulationFluid,
  setSubmitting,
  setStatus,
  onRedirect,
) => ({
  taskId,
  projectId,
  setStatus,
  onRedirect,
  simulationId,
  setSubmitting,
  payload: simulationFluid,
  type: ACTIONS.REQUEST_CREATE_SIMULATION_FLUID,
  notification: {
    success: 'Fluid successfully created',
  },
});

export const requestUpdateSimulationFluid = (
  projectId,
  taskId,
  simulationId,
  simulationFluidId,
  simulationFluid,
  setSubmitting,
  setStatus,
) => ({
  taskId,
  projectId,
  setStatus,
  simulationId,
  setSubmitting,
  simulationFluidId,
  payload: simulationFluid,
  type: ACTIONS.REQUEST_UPDATE_SIMULATION_FLUID,
  notification: {
    info: 'Updating fluid',
    success: 'Fluid successfully updated',
  },
});

export const requestDeleteSimulationFluid = (
  projectId,
  taskId,
  simulationId,
  simulationFluidId,
  onRedirect,
) => ({
  taskId,
  projectId,
  simulationId,
  simulationFluidId,
  onRedirect,
  type: ACTIONS.REQUEST_DELETE_SIMULATION_FLUID,
  confirmationDialog: {
    title: 'Delete simulation fluid',
    confirmButtonText: 'Delete',
    description: 'Are you sure you want to delete this fluid? ',
  },
  notification: {
    success: 'Fluid successfully deleted',
  },
});

export const receiveSimulationFluid = (simulationFluid) => ({
  payload: simulationFluid,
  type: ACTIONS.RECEIVE_SIMULATION_FLUID,
});

export const deleteSimulationFluid = (simulationFluidId) => ({
  simulationFluidId,
  type: ACTIONS.DELETE_SIMULATION_FLUID,
});

export const receiveSimulationFluids = (simulationFluids) => ({
  payload: simulationFluids,
  type: ACTIONS.RECEIVE_SIMULATION_FLUIDS,
});

export const downloadSimulationPdfReport =
  (taskId, projectId, simulationId, charts, collapseAssemblies) =>
  (dispatch) => {
    const payload = reportsService.downloadProjectSimulationReport(
      projectId,
      taskId,
      simulationId,
      charts,
      collapseAssemblies,
    );

    dispatch({
      type: ACTIONS.DOWNLOAD_SIMULATION_PDF_REPORT,
      payload,
    });
  };
