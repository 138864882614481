import { useMemo } from 'react';
import { Formik, Form, Field } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import { Grid, Fab, Typography } from '@material-ui/core';

import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';

const FormFields = {
  name: 'name',
};

const TaskToolstringEditorContentHeader = ({
  toolstring,
  onAddTools,
  onUpdateToolstring,
}) => {
  const initialValues = useMemo(
    () => ({
      [FormFields.name]: toolstring.get('name'),
    }),
    [toolstring],
  );

  return (
    <Grid item container alignItems="center">
      <Grid xs={3} item>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onUpdateToolstring}
        >
          <Form>
            <AutoSaveFormik>
              <Field
                fullWidth
                label="BHA name"
                name={FormFields.name}
                component={TextFieldFormik}
              />
            </AutoSaveFormik>
          </Form>
        </Formik>
      </Grid>
      <Grid
        xs
        item
        container
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h6">Add Tool</Typography>
        </Grid>
        <Grid item>
          <Fab
            size="medium"
            color="primary"
            title="Add tool"
            onClick={onAddTools}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TaskToolstringEditorContentHeader;
