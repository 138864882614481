import Agent from 'infrastructure/agent';
import mappers from 'mappers';

export const getSystemRoles = () => Agent.get('/authorization/system/role');

export const getSystemRole = (systemRoleId) =>
  Agent.get(`/authorization/system/role/${systemRoleId}`);

export const deleteSystemRole = (systemRoleId) =>
  Agent.delete(`/authorization/system/role/${systemRoleId}`);

export const createSystemRole = (systemRole) =>
  Agent.post('/authorization/system/role', systemRole);

export const updateSystemRole = (systemRoleId, systemRole) =>
  Agent.put(`/authorization/system/role/${systemRoleId}`, systemRole);

export const removeSystemRoleFromUser = (systemRoleId, userId) =>
  Agent.delete(`/authorization/system/role/${systemRoleId}/user/${userId}`);

export const addSystemRoleToUser = (systemRoleId, userId) =>
  Agent.post(
    `/authorization/system/role/${systemRoleId}/user/${userId}`,
    mappers.UserRole.to(systemRoleId, userId),
  );

export const getUsersAssignedToSystemRole = (systemRoleId) =>
  Agent.get(`/authorization/system/${systemRoleId}/user`);

export const getProjectRoles = () => Agent.get('/authorization/project/role');

export const getProjectRole = (projectRoleId) =>
  Agent.get(`/authorization/project/role/${projectRoleId}`);

export const deleteProjectRole = (projectRoleId) =>
  Agent.delete(`/authorization/project/role/${projectRoleId}`);

export const createProjectRole = (projectRole) =>
  Agent.post('/authorization/project/role', projectRole);

export const updateProjectRole = (projectRoleId, projectRole) =>
  Agent.put(`/authorization/project/role/${projectRoleId}`, projectRole);

export const getProjectRolePermissions = (roleId) =>
  Agent.get(`/authorization/project/role/${roleId}/permission`);

export const assignPermissionsToProjectRole = (roleId, rolePermissions) =>
  Agent.put(
    `/authorization/project/role/${roleId}/permissions`,
    rolePermissions,
  );

export const unassignPermissionsFromProjectRole = (roleId, permissionIds) =>
  Agent.put(
    `/authorization/project/role/${roleId}/permissions/delete`,
    permissionIds,
  );

export const getSystemRolePermissions = (roleId) =>
  Agent.get(`/authorization/system/role/${roleId}/systemPermissions`);

export const assignSystemPermissionsToSystemRole = (roleId, rolePermissions) =>
  Agent.put(
    `/authorization/system/role/${roleId}/systemPermissions`,
    rolePermissions,
  );

export const unassignSystemPermissionsFromSystemRole = (
  roleId,
  permissionIds,
) =>
  Agent.put(
    `/authorization/system/role/${roleId}/systemPermissions/delete`,
    permissionIds,
  );

export const getSystemRoleProjectPermissions = (roleId) =>
  Agent.get(`/authorization/system/role/${roleId}/projectPermissions`);

export const assignProjectPermissionsToSystemRole = (roleId, rolePermissions) =>
  Agent.put(
    `/authorization/system/role/${roleId}/projectPermissions`,
    rolePermissions,
  );

export const unassignProjectPermissionsFromSystemRole = (
  roleId,
  permissionIds,
) =>
  Agent.put(
    `/authorization/system/role/${roleId}/projectPermissions/delete`,
    permissionIds,
  );

export const getProjectPermissions = () =>
  Agent.get('/authorization/project/permission');

export const getSystemPermissions = () =>
  Agent.get('/authorization/system/permission');

const authorizationService = {
  getSystemRoles,
  getSystemRole,
  deleteSystemRole,
  getUsersAssignedToSystemRole,
  createSystemRole,
  updateSystemRole,
  removeSystemRoleFromUser,
  addSystemRoleToUser,

  getProjectRoles,
  getProjectRole,
  deleteProjectRole,
  createProjectRole,
  updateProjectRole,

  getProjectRolePermissions,
  assignPermissionsToProjectRole,
  unassignPermissionsFromProjectRole,

  getSystemRolePermissions,
  assignSystemPermissionsToSystemRole,
  unassignSystemPermissionsFromSystemRole,
  getSystemRoleProjectPermissions,
  assignProjectPermissionsToSystemRole,
  unassignProjectPermissionsFromSystemRole,

  getSystemPermissions,
  getProjectPermissions,
};

export default authorizationService;
