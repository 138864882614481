import Agent from 'infrastructure/agent';

export const getAllFluids = async (projectId, projectWellboreId) =>
  Agent.get(`/project/${projectId}/wellbore/${projectWellboreId}/fluids`);

export const deleteFluid = async (
  projectId,
  projectWellboreDetailId,
  fluidId,
) =>
  await Agent.delete(
    `/project/${projectId}/wellbore/${projectWellboreDetailId}/fluids/${fluidId}`,
  );

export const updateFluid = async (
  projectId,
  projectWellboreDetailId,
  fluidObj,
) => {
  await Agent.put(
    `/project/${projectId}/wellbore/${projectWellboreDetailId}/fluids/${fluidObj.fluidId}`,
    fluidObj,
  );
};

export const addFluid = async (projectId, projectWellboreId, fluidObj) =>
  await Agent.post(
    `/project/${projectId}/wellbore/${projectWellboreId}/fluids`,
    fluidObj,
  );

export const duplicateFluids = async (
  projectId,
  currentProjectWellboreId,
  newProjectWellboreId,
) =>
  await Agent.post(
    `/project/${projectId}/wellbore/${currentProjectWellboreId}/fluids/duplicate/${newProjectWellboreId}`,
  );

const getDefaultProjectFluid = (projectId, projectWellboreId) =>
  Agent.get(
    `/project/${projectId}/wellbore/${projectWellboreId}/fluids/default`,
  );

const projectFluidService = {
  getAllFluids,
  getDefaultProjectFluid,
  deleteFluid,
  updateFluid,
  addFluid,
  duplicateFluids,
};

export default projectFluidService;
