import { fromJS } from 'immutable';

import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import dataAcquisitionMapper from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.mappers';
import { DATA_ACQUISITION_ACTIONS } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';

const initialState = fromJS({
  data: EMPTY_MAP,
  explosives: EMPTY_MAP,
  greaseTypes: EMPTY_LIST,
  glycolTypes: EMPTY_LIST,
  debrisTypes: EMPTY_LIST,
  injectionMediums: EMPTY_LIST,
  productionMediums: EMPTY_LIST,
  frictionReducerTypes: EMPTY_LIST,
});

const dataAcquisitionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case DATA_ACQUISITION_ACTIONS.GET_DATA_ACQUISITION_BY_TASK: {
      if (action.error) return state;

      const { taskId } = action;
      return state.setIn(
        ['data', taskId.toString()],
        dataAcquisitionMapper.DataAcquisition.from(action.payload),
      );
    }

    case DATA_ACQUISITION_ACTIONS.DELETE_DATA_ACQUISITION: {
      if (action.error) return state;

      return state.deleteIn(['data']);
    }

    case DATA_ACQUISITION_ACTIONS.GET_ALL_DEBRIS_TYPES: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set('debrisTypes', fromJS(payload));
      }
      return state;
    }

    case DATA_ACQUISITION_ACTIONS.GET_ALL_GLYCOL_TYPES: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set('glycolTypes', fromJS(payload));
      }
      return state;
    }

    case DATA_ACQUISITION_ACTIONS.GET_ALL_GREASE_TYPES: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set('greaseTypes', fromJS(payload));
      }
      return state;
    }

    case DATA_ACQUISITION_ACTIONS.GET_ALL_PRODUCTION_MEDIUMS: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set('productionMediums', fromJS(payload));
      }
      return state;
    }

    case DATA_ACQUISITION_ACTIONS.GET_ALL_INJECTION_MEDIUMS: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set('injectionMediums', fromJS(payload));
      }
      return state;
    }

    case DATA_ACQUISITION_ACTIONS.GET_ALL_FRICTION_REDUCER_TYPES: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set('frictionReducerTypes', fromJS(payload));
      }
      return state;
    }

    case DATA_ACQUISITION_ACTIONS.GET_EXPLOSIVES: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set('explosives', fromJS(payload));
      }
      return state;
    }

    default:
      return state;
  }
};

export default dataAcquisitionReducer;
