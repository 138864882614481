import { compose } from 'redux';
import BlockIcon from '@material-ui/icons/Block';
import withStyles from '@material-ui/styles/withStyles';
import { Divider, Dialog, Grid, Button } from '@material-ui/core';

import { ModalActions, NoContentBasePage } from 'altus-ui-components';

import { EMPTY_MAP } from 'app/app.constants';
import TaskToolstringTotals from 'features/projects/tasks/task/toolstring/components/TaskToolstringTotals';
import TaskToolstringEditorAppBar from 'features/projects/tasks/task/toolstring/components/edit/TaskToolstringEditorAppBar';
import TaskToolstringEditorContent from 'features/projects/tasks/task/toolstring/components/edit/TaskToolstringEditorContent';

const TaskToolstringEditor = ({
  open,
  taskId,
  classes,
  onClose,
  onEnter,
  children,
  projectId,
  onAddTools,
  toolstring = EMPTY_MAP,
  toolstringId,
  templateEditor,
  toolConnectors,
  toolstringTools,
  onDuplicateItem,
  onAddToolAttachments,
  toolstringSections,
  onUpdateToolstring,
  maxAvailableHeight,
  onSortToolstringTool,
  updateItemProperties,
  onDeleteToolstringTool,
  onAddToolAssemblyTools,
  onDeleteToolAssemblyTool,
  onDeleteToolstringAttachment,
}) => {
  const hiddenColumns = ['serialNo'];
  if (toolstring.get('isPlanned')) {
    return (
      <Dialog
        open={open}
        fullScreen
        onClose={onClose}
        TransitionProps={{
          onEnter,
        }}
      >
        <TaskToolstringEditorAppBar
          onClose={onClose}
          toolstringName={toolstring.get('name')}
        />
        <NoContentBasePage
          header="BHA is locked"
          description="This BHA is locked. Unlock it from the simulations page or choose a different BHA."
          Icon={BlockIcon}
        />
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}
      TransitionProps={{
        onEnter,
      }}
    >
      <TaskToolstringEditorAppBar
        onClose={onClose}
        toolstringName={toolstring.get('name')}
      />
      <TaskToolstringEditorContent
        taskId={taskId}
        projectId={projectId}
        toolstring={toolstring}
        onAddTools={onAddTools}
        toolstringId={toolstringId}
        templateEditor={templateEditor}
        toolConnectors={toolConnectors}
        toolstringTools={toolstringTools}
        onDuplicateItem={onDuplicateItem}
        onAddToolAttachments={onAddToolAttachments}
        onDeleteToolstringAttachment={onDeleteToolstringAttachment}
        toolstringSections={toolstringSections}
        onUpdateToolstring={onUpdateToolstring}
        onSortToolstringTool={onSortToolstringTool}
        updateItemProperties={updateItemProperties}
        onDeleteToolstringTool={onDeleteToolstringTool}
        onAddToolAssemblyTools={onAddToolAssemblyTools}
        onDeleteToolAssemblyTool={onDeleteToolAssemblyTool}
        hiddenColumns={hiddenColumns}
      />
      <Divider variant="middle" />
      <ModalActions classes={{ dialogActionsRoot: classes.actions }}>
        <Grid
          item
          xl={6}
          lg={7}
          md={10}
          container
          justifyContent="space-between"
        >
          <TaskToolstringTotals
            toolstring={toolstring}
            maxAvailableHeight={maxAvailableHeight}
          />
        </Grid>
        <Grid container item xs justifyContent="flex-end">
          <Grid item>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={onClose}
              classes={{ root: classes.saveButton }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </ModalActions>
      {children}
    </Dialog>
  );
};

const styles = (theme) => ({
  actions: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  saveButton: {
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
});

export default compose(withStyles(styles))(TaskToolstringEditor);
