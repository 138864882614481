import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { EMPTY_MAP } from 'app/app.constants';
import { useHeader } from 'app/hooks/useHeader';

import LogoAndAppTitle from 'app/components/ApplicationHeader/LogoAndAppTitle';
import UserMenuContainer from 'app/components/ApplicationHeader/UserMenuContainer';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import ApplicationHeaderTitle from 'app/components/ApplicationHeader/ApplicationHeaderTitle';

import { getToolFromState } from 'features/equipment/equipment.selectors';

const EquipmentDetailsHeader = () => {
  const onClick = () => window.close();

  const tool = useSelector(getToolFromState);

  useHeader({
    title: tool === EMPTY_MAP ? '' : `${tool.get('name')}`,
  });

  return (
    <ApplicationHeaderBase>
      <Grid
        container
        spacing={2}
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs>
          <Switch>
            <Route>
              <LogoAndAppTitle onClick={onClick} />
            </Route>
          </Switch>
        </Grid>
        <Grid item xs={8}>
          <ApplicationHeaderTitle />
        </Grid>
        <Grid item xs>
          <Grid container justifyContent="flex-end" alignItems="center">
            <UserMenuContainer />
          </Grid>
        </Grid>
      </Grid>
    </ApplicationHeaderBase>
  );
};

export default EquipmentDetailsHeader;
