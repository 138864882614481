import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
// import { toProjectDetails } from 'utils/route.util';
import { useSelector } from 'react-redux';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';
// import MobileBackButton from 'app/components/Mobile/MobileBackButton';

const ProjectStatusHeader = ({
  classes,
  // projectId,
}) => {
  // const defaultPreviousPage = toProjectDetails(projectId);
  const status = useSelector(getExecuteStatusFromState);
  return (
    <Grid item xs={12} className={classes.container}>
      {/* <Grid item xs={2} className={classes.statusHeader}>
        <Grid item xs={2}>
          <MobileBackButton
            to={defaultPreviousPage}
            children=""
            className={classes.backButton}
          />
        </Grid>
      </Grid> */}
      <Grid item xs={10}>
        <Grid container className={classes.statusHeader}>
          <Grid item xs={12}>
            <Typography variant="h6">Project Status</Typography>
          </Grid>
          <Grid item xs={12} className={classes.nameContainer}>
            <Grid item xs={12}>
              <Typography variant="body2" align="left">
                {status.getIn(['current', 'taskNumber'])}.{' '}
                {status.getIn(['current', 'task', 'customTitle'])}
              </Typography>
            </Grid>
            {/* <Divider orientation="vertical" flexItem /> */}
            {status.getIn(['current', 'activity', 'name']) && (
              <Grid item xs={12}>
                <Typography variant="body2" align="left" color="textSecondary">
                  {`${status.getIn([
                    'current',
                    'activity',
                    'name',
                  ])} - ${status.getIn(['current', 'activity', 'code'])}`}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  statusHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
  },
  backButton: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(0),
  },
});

export default withStyles(styles)(ProjectStatusHeader);
