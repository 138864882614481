import { withStyles } from '@material-ui/core';
import { TableHeaderCell } from 'altus-ui-components';

const TableHeaderCellDense = ({ classes, ...rest }) => (
  <TableHeaderCell classes={{ root: classes.cell }} {...rest} />
);

const styles = (theme) => ({
  cell: {
    padding: `0 ${theme.spacing(0.5)}px 0 0`,
  },
});

export default withStyles(styles)(TableHeaderCellDense);
