import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BasePage, LoadingOverlay } from 'altus-ui-components';

import useDataState from 'app/hooks/useDataState';
import { ProjectPermission } from 'app/app.constants';
import withProjectPermission from 'app/components/withProjectPermission';
import { ACTIVITIES_ACTIONS } from 'features/projects/activities/activities.constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    basePageChildren: {
      paddingTop: theme.spacing(2.25),
    },
  }),
);

const ExecutionIndexContainer = () => {
  const classes = useStyles();
  const loadingState = useDataState(ACTIVITIES_ACTIONS.ACTIVITIES_INITIALIZED);

  return (
    <BasePage
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs wrap="nowrap">
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          <LoadingOverlay dataState={loadingState} />
          {loadingState.received && (
            <Typography>
              <i>There are currently no tasks or activities on this project</i>
            </Typography>
          )}
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default withProjectPermission(ProjectPermission.EXECUTE)(
  ExecutionIndexContainer,
);
