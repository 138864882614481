import { useMemo } from 'react';
import { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { sortableElement } from 'react-sortable-hoc';
import withStyles from '@material-ui/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  DragHandle,
  SortableTable,
  TableRowActionsCell,
} from 'altus-ui-components';

import {
  ProjectPermission,
  WORK_ITEM_STATUS as WorkItemStatusType,
} from 'app/app.constants';

import { toProjectTasks } from 'utils/route.util';
import NavigationLink from 'app/components/NavigationLink';
import RunNumber from 'features/projects/components/RunNumber';
import { formatQuantity } from 'utils/format.util';
import HasProjectPermission from 'app/components/HasProjectPermission';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import TaskTableRow from 'features/projects/tasks/components/TaskTableRow';
import { getRichEditorText } from 'utils/app.util';

const SortableTaskTableRow = sortableElement(TaskTableRow);

const TaskTable = ({
  tasks,
  classes,
  project,
  copyTask,
  projectId,
  sortTasks,
  deleteTask,
}) => {
  const copyTaskDisabled = useMemo(() => {
    if (tasks) {
      return (
        tasks
          .filter((t) => t.get('status') === WorkItemStatusType.COMPLETED)
          .toArray().length === tasks.toArray().length
      );
    }
    return true;
  }, [tasks]);

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const status = row.original.get('status');

          if (status === WorkItemStatusType.PLANNED) {
            return (
              <TableRowActionsCell>
                <HasProjectPermission
                  permissions={ProjectPermission.EDIT_TASKS}
                >
                  <DragHandle fontSize="small" />
                </HasProjectPermission>
              </TableRowActionsCell>
            );
          }

          return (
            <TableRowActionsCell>
              <WorkItemStatus status={status} />
            </TableRowActionsCell>
          );
        },
      },
      {
        id: 'runNumber',
        accessor: (task) => task.get('runNumber'),
        Header: <TableRowActionsCell>Run</TableRowActionsCell>,
        Cell: ({ value }) => (
          <TableRowActionsCell>
            <RunNumber>{value}</RunNumber>
          </TableRowActionsCell>
        ),
      },
      {
        xs: 2,
        Header: 'Title',
        accessor: (task) => task.get('title'),
        Cell: ({ row }) => {
          const task = row.original;

          return (
            <NavigationLink
              className={classes.taskTitleLink}
              to={toProjectTasks(projectId, task.get('id'))}
              onClick={(event) => {
                // prevent expanding the row
                event.stopPropagation();
              }}
            >
              <Typography noWrap variant="inherit">
                {task.get('title')}
              </Typography>
            </NavigationLink>
          );
        },
      },
      {
        xs: 2,
        Header: 'Target depth',
        accessor: (task) => task.get('targetDepth'),
        Cell: ({ value: targetDepth }) => formatQuantity(targetDepth),
      },
      {
        xs: 2,
        Header: 'Estimated',
        accessor: (task) => task.get('estimated'), //from DurationPlanned
      },
      {
        xs: 2,
        Header: 'Comments',
        accessor: (task) => getRichEditorText(task.get('comments')),
      },
      {
        id: 'expansionIcon',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: () => (
          <TableRowActionsCell>
            <ExpandMoreIcon />
          </TableRowActionsCell>
        ),
      },
    ],
    [classes, projectId],
  );

  const renderTableRowComponent = useCallback(
    ({ item, ...rest }) => {
      const isPlanned = item.get('status') === WorkItemStatusType.PLANNED;

      const TableRowComponent = isPlanned ? SortableTaskTableRow : TaskTableRow;

      return (
        <TableRowComponent
          {...rest}
          item={item}
          project={project}
          copyTask={copyTask}
          projectId={projectId}
          deleteTask={deleteTask}
          copyTaskDisabled={copyTaskDisabled}
        />
      );
    },
    [project, copyTask, projectId, deleteTask, copyTaskDisabled],
  );

  const onSortEnd = useCallback(
    (indices) => sortTasks(projectId, indices, tasks),
    [tasks, projectId, sortTasks],
  );

  return (
    <SortableTable
      useDragHandle
      items={tasks}
      columns={columns}
      useGlobalFilter={false}
      onItemSorted={onSortEnd}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

const styles = () => ({
  taskTitleLink: {
    display: 'inline',
  },
});

export default withStyles(styles)(TaskTable);
