import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import ProjectHelmet from 'app/components/ProjectHelmet';
import { getProjectById } from 'features/projects/projects.actions';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import DataExchangeBasePage from 'features/data-exchange/DataExchangeBasePage';

const LiveProjectContainer = ({ projectId, routes }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectById(projectId));
  }, [projectId, dispatch]);

  const project = useCurrentProject();

  return (
    <>
      <DataExchangeBasePage
        routes={routes}
        breadcrumb={project.get('fullTitle', projectId)}
      />
      <ProjectHelmet projectId={projectId} />
    </>
  );
};

export default LiveProjectContainer;
