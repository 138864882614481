import { replace } from 'connected-react-router/immutable';

import { toProjectWellboreDetail } from 'utils/route.util';
import { ACTIONS } from 'features/projects/wellbore/details/projectWellboreDetails.constants';

export const redirectToProjectWellboreDetail = ({
  projectId,
  projectWellboreDetailId,
}) => replace(toProjectWellboreDetail(projectId, projectWellboreDetailId));

export const createAndRedirectProjectWellboreDetailDraft = (
  projectId,
  projectWellboreDetailId,
) => ({
  projectId,
  projectWellboreDetailId,
  type: ACTIONS.CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT,
  confirmationDialog: {
    title: 'Create new draft',
    confirmButtonText: 'Create',
    description: 'Are you sure you want to create a new draft?',
  },
  notification: {
    success: 'Draft created successfully',
  },
});

export const createAndRedirectProjectWellboreDetailDraftAndSync = (
  projectId,
  projectWellboreDetailId,
) => ({
  projectId,
  projectWellboreDetailId,
  type: ACTIONS.CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT_AND_SYNC,
  confirmationDialog: {
    title: 'Create new draft and sync',
    confirmButtonText: 'Create',
    description: 'Are you sure you want to create a new draft?',
  },
  notification: {
    success: 'Draft created successfully',
  },
});

export const approveProjectWellboreDetailDraft = (
  projectId,
  projectWellboreDetailId,
) => ({
  projectId,
  projectWellboreDetailId,
  type: ACTIONS.APPROVE_PROJECT_WELLBORE_DETAIL_DRAFT,
  notification: {
    success: 'Draft approved successfully',
  },
});

export const cancelProjectWellboreDetailDraft = (
  projectId,
  projectWellboreDetailId,
) => ({
  projectId,
  projectWellboreDetailId,
  type: ACTIONS.CANCEL_PROJECT_WELLBORE_DETAIL_DRAFT,
  confirmationDialog: {
    title: 'Cancel draft',
    confirmButtonText: 'Cancel draft',
    description:
      'Are you sure you want to cancel this draft? Any changes will be lost',
  },
  notification: {
    success: 'Draft cancelled successfully',
  },
});

export const requestProjectWellboreDetailDraftApproval = (
  projectId,
  projectWellboreDetailId,
) => ({
  projectId,
  projectWellboreDetailId,
  type: ACTIONS.REQUEST_PROJECT_WELLBORE_DETAIL_DRAFT_APPROVAL,
  confirmationDialog: {
    confirmButtonText: 'Send for approval',
    title: 'Send for approval',
    description:
      'Are you sure? It cannot be cancelled after being sent for approval',
  },
});

export const editProjectWellboreDetailDraft = (
  projectId,
  projectWellboreDetailId,
) => ({
  projectId,
  projectWellboreDetailId,
  type: ACTIONS.EDIT_PROJECT_WELLBORE_DETAIL_DRAFT,
});

export const submitProjectWellboreDetailForm = (
  projectId,
  projectWellboreDetailId,
  wellboreDetail,
  setSubmitting,
  setStatus,
) => ({
  projectId,
  projectWellboreDetailId,
  payload: wellboreDetail,
  type: ACTIONS.SUBMIT_PROJECT_WELLBORE_DETAIL_FORM,
  setSubmitting,
  setStatus,
  notification: {
    info: 'Saving',
    success: 'Well details successfully saved',
  },
});

export const requestProjectWellboreDetail = (
  projectId,
  projectWellboreDetailId,
) => ({
  projectId,
  projectWellboreDetailId,
  type: ACTIONS.REQUEST_PROJECT_WELLBORE_DETAIL,
});

export const receiveProjectWellboreDetail = (projectWellboreDetail) => ({
  payload: projectWellboreDetail,
  type: ACTIONS.RECEIVE_PROJECT_WELLBORE_DETAIL,
});

export const setActiveWellboredetailId = (wellboreDetailId) => ({
  payload: wellboreDetailId,
  type: ACTIONS.SET_ACTIVE_WELLBOREDETAILSID,
});

export const deleteProjectWellboreDetail = (projectWellboreDetailId) => ({
  projectWellboreDetailId,
  type: ACTIONS.DELETE_PROJECT_WELLBORE_DETAIL,
});

export const requestProjectWellboreDetails = (projectId) => ({
  projectId,
  type: ACTIONS.REQUEST_PROJECT_WELLBORE_DETAILS,
});

export const receiveProjectWellboreDetails = (projectWellboreDetails) => ({
  payload: projectWellboreDetails,
  type: ACTIONS.RECEIVE_PROJECT_WELLBORE_DETAILS,
});

export const requestAddHostileFluidsToProjectWellboreDetail = (
  projectId,
  projectWellboreDetailId,
  hostileFluidsToAdd,
) => ({
  projectId,
  projectWellboreDetailId,
  payload: hostileFluidsToAdd,
  type: ACTIONS.REQUEST_ADD_HOSTILE_FLUIDS_TO_PROJECT_WELLBORE_DETAIL,
  notification: {
    success: 'Hostile fluid(s) added successfully',
  },
});

export const requestRemoveHostileFluidsFromProjectWellboreDetail = (
  projectId,
  projectWellboreDetailId,
  hostileFluidsToRemove,
) => ({
  projectId,
  projectWellboreDetailId,
  payload: hostileFluidsToRemove,
  type: ACTIONS.REQUEST_REMOVE_HOSTILE_FLUIDS_FROM_PROJECT_WELLBORE_DETAIL,
  notification: {
    success: 'Hostile fluid removed successfully',
  },
  confirmationDialog: {
    confirmButtonText: 'Remove',
    title: 'Remove hostile fluid',
    description: 'Are you sure you want to remove this?',
  },
});

export const clearProjectWellboreDetails = () => ({
  type: ACTIONS.CLEAR_PROJECT_WELLBORE_DETAILS,
});

export const requestLatestProjectWellboreDetail = (projectId) => ({
  projectId,
  type: ACTIONS.REQUEST_LATEST_PROJECT_WELLBORE_DETAIL,
});

export const getProjectWellboreDetailChangelog = (projectId) => ({
  projectId,
  type: ACTIONS.GET_PROJECT_WELLBORE_DETAIL_CHANGE_LOG,
});

export const loadProjectWellboreDetailPage = (
  projectId,
  projectWellboreDetailId,
) => ({
  projectId,
  projectWellboreDetailId,
  type: ACTIONS.LOAD_PROJECT_WELLBORE_DETAIL_PAGE,
});

export const loadProjectWellboreDetailsPage = (projectId) => ({
  projectId,
  type: ACTIONS.LOAD_PROJECT_WELLBORE_DETAILS_PAGE,
});

export const requestAddProjectWellboreDetailServices = (
  projectId,
  projectWellboreDetailId,
  wellboreDetailServiceTypes,
) => ({
  projectId,
  projectWellboreDetailId,
  payload: wellboreDetailServiceTypes,
  type: ACTIONS.REQUEST_ADD_PROJECT_WELLBORE_DETAIL_SERVICES,
  notification: {
    success: 'Service(s) added successfully',
  },
});

export const requestDeleteProjectWellboreDetailService = (
  projectId,
  projectWellboreDetailId,
  wellboreDetailServiceId,
) => ({
  projectId,
  projectWellboreDetailId,
  payload: wellboreDetailServiceId,
  type: ACTIONS.REQUEST_REMOVE_PROJECT_WELLBORE_DETAIL_SERVICE,
  notification: {
    success: 'Service removed successfully',
  },
  confirmationDialog: {
    confirmButtonText: 'Remove',
    title: 'Remove service',
    description: 'Are you sure you want to remove this?',
  },
});

export const requestSyncProjectWellboreDetailsWithLatest = (
  projectId,
  projectWellboreDetailId,
) => ({
  projectId,
  projectWellboreDetailId,
  type: ACTIONS.SYNC_PROJECT_WELLBORE_DETAILS_WITH_LATEST,
  notification: {
    success: 'Well details synced successfully',
  },
});
