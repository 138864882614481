import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import React from 'react';
import { IntegrityForm } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';

const NumericRuleDataFormComponent = () => {
  return (
    <Grid item xs={6} container>
      <Field
        type="number"
        label="Default Numeric Value"
        component={TextFieldFormik}
        name={IntegrityForm.VALUE}
      />
    </Grid>
  );
};

export default NumericRuleDataFormComponent;
