import { put, call } from 'redux-saga/effects';

import {
  addNotification,
  NOTIFICATION_VARIANTS,
} from 'altus-redux-middlewares';

import { asyncStart, asyncEnd, asyncEndError } from 'altus-datastate';

import { IN_DEV } from 'app/app.constants';

/**
 * Calls the saga taking care of dispatching the corresponding ASYNC_START
 * and ASYNC_END actions used by the altus-datastate library.
 *
 * Equivalent work is done by the promiseMiddleware in altus-redux-middlewares
 * when using redux-thunk.
 *
 * @param  {Generator} saga The saga to execute
 * @param  {Array<any>} args Arguments to be passed to the saga (with the resolved action as last argument)
 */
export function* callAsync(saga, ...args) {
  const action = args[args.length - 1];
  const { type, ...params } = action;

  try {
    yield put(asyncStart(type, params));

    yield call(saga, ...args);

    yield put(asyncEnd(type, params));
  } catch (error) {
    yield put(asyncEndError(type, params));

    if (error?.response && error?.response?.status === 403) {
      return yield put(
        addNotification({
          message: 'User does not have permission to do this.',
          duration: action?.notification?.config?.error?.duration,
          variant: NOTIFICATION_VARIANTS.ERROR,
        }),
      );
    }

    if (error?.response?.body?.hasErrors) {
      var message = error.response.body.errors[0];

      yield put(
        addNotification({
          message: message,
          duration: action?.notification?.config?.error?.duration,
          variant: NOTIFICATION_VARIANTS.ERROR,
        }),
      );
    }

    if (IN_DEV) {
      console.error(error);
    }
  }
}
