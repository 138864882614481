import { Field } from 'formik';
import Close from '@material-ui/icons/Close';
import { IconButton, InputAdornment } from '@material-ui/core';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';

const SearchTextFieldFormik = ({ form, field, InputProps, ...rest }) => {
  const { name } = field;
  const { setFieldValue } = form;

  return (
    <Field
      name={name}
      margin="none"
      label="Search"
      placeholder="Search..."
      component={TextFieldFormik}
      inputProps={{
        maxLength: 100,
      }}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              title="Clear"
              onClick={() => setFieldValue(name, undefined)}
            >
              <Close fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default SearchTextFieldFormik;
