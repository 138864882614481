import { useCallback } from 'react';

import TableRowExpandable from 'app/components/TableRowExpandable';
import ToolstringTemplateToolsTable from 'features/projects/tasks/task/toolstring/components/ToolstringTemplateToolsTable';

const ToolstringTemplateTableRow = ({ item, ...rest }) => {
  const renderDetails = useCallback(
    (isRowExpanded) => {
      return isRowExpanded ? (
        <ToolstringTemplateToolsTable
          templateId={item.get('id')}
          toolstringItems={item.get('toolsByToolstringToolId')}
        />
      ) : null;
    },
    [item],
  );

  return (
    <TableRowExpandable item={item} {...rest} renderDetails={renderDetails} />
  );
};

export default ToolstringTemplateTableRow;
