import moment from 'moment';
import groupBy from 'lodash/groupBy';
import isObject from 'lodash/isObject';
import { Map, fromJS } from 'immutable';
import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';

import { UNITS, WORK_ITEM_STATUS } from 'app/app.constants';
import tasksMappers from 'features/projects/tasks/tasks.mappers';
import { TimerType } from 'features/projects/activities/activities.constants';
import { formatValue } from 'utils/format.util';

export const timerTypeToDisplayName = (timerType) =>
  ({
    [TimerType.NPT]: 'Non-productive time',
    [TimerType.TRAC]: 'Tractor',
  }[timerType]);

const editorStateToString = (editorState) => {
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  return JSON.stringify(rawContentState);
};

const stringToEditorState = (str) => {
  const rawContentState = JSON.parse(str);
  const contentState = convertFromRaw(rawContentState);
  return EditorState.createWithContent(contentState);
};

const formatEstimatedTime = (estimatedTime) => {
  if (estimatedTime) {
    const [hours, minutes] = estimatedTime.split(':');
    const parsedHours = parseInt(hours, 10);

    if (parsedHours >= 24) {
      const days = Math.floor(parsedHours / 24);
      const remainingHours = parsedHours % 24;

      return `${days}.${remainingHours
        .toString()
        .padStart(2, '0')}:${minutes}:00`;
    } else {
      return `0.${hours.padStart(2, '0')}:${minutes}:00`;
    }
  }

  return estimatedTime;
};

const convertDurationToHoursMinutes = (durationString) => {
  const parts = durationString.split(':');
  var days = 0;
  var hours = 0;
  var minutes = parseInt(parts[1]) || 0;

  if (parts[0].includes('.')) {
    // has days
    days = parseInt(parts[0].split('.')[0]) || 0;
    hours = parseInt(parts[0].split('.')[1]) || 0;
  } else {
    // no days
    hours = parseInt(parts[0] || 0);
  }
  minutes = parseInt(parts[1]) || 0;

  let totalHours = days * 24 + hours;
  totalHours = totalHours < 10 ? `0${totalHours}` : totalHours;
  const hoursMinutesString = `${totalHours}:${minutes
    .toString()
    .padStart(2, '0')}`;
  return hoursMinutesString;
};

const activityToDTO = ({
  type,
  depth,
  title,
  comments,
  sequence,
  description,
  endTime,
  startTime,
  estimatedTime,
  parallelActivity,
  status = WORK_ITEM_STATUS.PLANNED,
  expectedTimeMet,
  failiureBarrier,
  failureActivateBarrier,
  failureActivateSecondBarrier,
  wellControlDrillSituations,
  participants,
  plannedStartTime,
  wellControlDrillResult,
}) => {
  const { name, id } = type;
  depth = isObject(depth)
    ? depth
    : {
        value: formatValue(depth),
        unit: UNITS.METER,
      };
  return {
    name: name,
    depth: depth,
    title: title,
    status: status,
    activityTypeId: id,
    sequence: sequence,
    comments: comments ? editorStateToString(comments) : null,
    description: description ? editorStateToString(description) : null,
    estimatedTime: formatEstimatedTime(estimatedTime),
    parallelActivity: parallelActivity
      ? editorStateToString(parallelActivity)
      : null,
    endTime: endTime ? moment(endTime).toISOString() : endTime,
    startTime: startTime ? moment(startTime).toISOString() : startTime,
    currentClientDate: new Date().toISOString(),
    expectedTimeMet: expectedTimeMet,
    failiureBarrier: failiureBarrier,
    failureActivateBarrier: failureActivateBarrier,
    failureActivateSecondBarrier: failureActivateSecondBarrier,
    wellControlDrillSituations: wellControlDrillSituations,
    participants: participants,
    plannedStartTime: plannedStartTime,
    wellControlDrillResult: wellControlDrillResult,
  };
};

const activityFromDTO = ({
  name,
  title,
  endTime,
  startTime,
  previousActivityStartTime,
  nextActivityStartTime,
  waitCodesDuration,
  nextStandardActivityStartTime,
  activityId,
  activityTypeId,
  estimatedTime,
  parallelActivity,
  description,
  comments,
  ...activity
}) => {
  estimatedTime = estimatedTime
    ? convertDurationToHoursMinutes(estimatedTime)
    : estimatedTime;
  parallelActivity = parallelActivity
    ? stringToEditorState(parallelActivity)
    : undefined;
  description = description ? stringToEditorState(description) : undefined;
  comments = comments ? stringToEditorState(comments) : undefined;

  return Map({
    id: activityId,
    name: title || name,
    type: Map({
      id: activityTypeId,
      name: name,
    }),
    startTime: startTime ? moment(startTime) : undefined,
    endTime: endTime ? moment(endTime) : undefined,
    previousActivityStartTime: previousActivityStartTime
      ? moment(previousActivityStartTime)
      : undefined,
    nextActivityStartTime: nextActivityStartTime
      ? moment(nextActivityStartTime)
      : undefined,
    nextStandardActivityStartTime: nextStandardActivityStartTime
      ? moment(nextStandardActivityStartTime)
      : undefined,
    waitCodesDuration: waitCodesDuration
      ? convertDurationToHoursMinutes(waitCodesDuration)
      : undefined,
    title: title,
    estimatedTime: estimatedTime,
    parallelActivity: parallelActivity,
    description: description,
    comments: comments,
    ...activity,
  });
};

const activityTypeFromDTO = ({
  code,
  name,
  displayText,
  description,
  activityTypeId,
  isWait,
}) => {
  return Map({
    name: displayText,
    id: activityTypeId,
    description,
    isWait: isWait,
    value: Map({
      code: code,
      name: name,
      id: activityTypeId,
    }),
  });
};

const initialActivity = () => {
  return Map({
    id: null,
    sequence: null,
    depth: null,
    code: null,
    name: null,
    description: null,
    startTime: null,
    endTime: null,
    status: WORK_ITEM_STATUS.PLANNED,
    type: Map(),
  });
};

const completeTaskToDto = (
  { taskId, comments, failureReason },
  status,
  continueWithContingencyTaskId,
) => ({
  currentTaskId: taskId,
  comments: comments ? editorStateToString(comments) : null,
  failReason: failureReason,
  status: status,
  continueWithContingencyTaskId: continueWithContingencyTaskId,
});

const completeActivityToDto = (taskId, activityId) => ({
  currentTaskId: taskId,
  currentActivityId: activityId,
});

const createActivityToDto = (taskId, activityId, activityTypeId) => ({
  currentTaskId: taskId,
  currentActivityId: activityId,
  activityTypeId: activityTypeId,
});

const endOperationToDto = ({ comments, failureReason }) => ({
  comments: comments ? editorStateToString(comments) : null,
  failReason: failureReason,
});

const timerFromDto = ({
  endTime,
  endTask,
  startTime,
  startTask,
  timerType,
  endActivity,
  startActivity,
  projectTimerId,
  ...timer
}) =>
  Map({
    id: projectTimerId,
    typeId: timerType,
    endTime: endTime ? moment(endTime) : undefined,
    startTime: startTime ? moment(startTime) : undefined,
    startTask: startTask
      ? tasksMappers.Task.from(startTask)
      : tasksMappers.Task.initial(),
    endTask: endTask
      ? tasksMappers.Task.from(endTask)
      : tasksMappers.Task.initial(),
    startActivity: startActivity
      ? activityFromDTO(startActivity)
      : initialActivity(),
    endActivity: endActivity ? activityFromDTO(endActivity) : initialActivity(),
    ...timer,
  });

const timerToDto = ({
  id,
  typeId,
  comment,
  endTime,
  projectId,
  startTime,
}) => ({
  comment: comment,
  timerType: typeId,
  projectTimerId: id,
  projectId: projectId,
  endTime: endTime ? moment(endTime).toISOString() : endTime,
  startTime: startTime ? moment(startTime).toISOString() : startTime,
});

const timersByTypeIdFromDto = (timers) => {
  const mapped = timers.map(timerFromDto);

  return fromJS(groupBy(mapped, (timer) => timer.get('typeId')));
};

const updateActivitySetOrRetrieveItemsToDto = ({
  activityId,
  toolId,
  toolStatus,
}) => {
  return {
    setOrRetrieveToolstringItemId: toolId,
    activityId: activityId,
    setOrRetrieveToolstringItemStatus: toolStatus,
  };
};

const activitiesMappers = {
  Activity: {
    initial: initialActivity,
    from: activityFromDTO,
    to: activityToDTO,
  },
  ActivityType: {
    from: activityTypeFromDTO,
  },
  CompleteTask: {
    to: completeTaskToDto,
  },
  CompleteActivity: {
    to: completeActivityToDto,
  },
  CreateActivity: {
    to: createActivityToDto,
  },
  EndOperation: {
    to: endOperationToDto,
  },
  Timer: {
    from: timerFromDto,
    to: timerToDto,
  },
  Timers: {
    from: timersByTypeIdFromDto,
  },
  UpdateActivitySetOrRetrieveItems: {
    to: updateActivitySetOrRetrieveItemsToDto,
  },
};

export default activitiesMappers;
