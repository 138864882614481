import { Field, Formik, Form } from 'formik';
import Close from '@material-ui/icons/Close';
import withStyles from '@material-ui/styles/withStyles';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { Grid, IconButton, InputAdornment } from '@material-ui/core';

import { Button } from 'app/components/withTooltip';
import { SystemPermission } from 'app/app.constants';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import HasSystemPermission from 'app/components/HasSystemPermission';

export const Filters = {
  TEXT_SEARCH: 'textSearch',
};

const shouldSubmit = ({ isValid }) => isValid;

const EquipmentTemplatesFilter = forwardRef(
  (
    {
      classes,
      onSubmit,
      initialValues,
      displayAddButton = true,
      toggleAddEquipmentTemplateModal,
    },
    ref,
  ) => {
    const formikRef = useRef();

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        formikRef.current?.submitForm();
      },
    }));
    return (
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        innerRef={formikRef}
        initialValues={initialValues}
      >
        <Form>
          <AutoSaveFormik timeout={1000} shouldSubmit={shouldSubmit}>
            <Grid container className={classes.root}>
              <Grid container spacing={2} alignItems="center">
                <Grid item container xs spacing={2} wrap="nowrap">
                  <Grid xs item>
                    <Field name={Filters.TEXT_SEARCH}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          label="Name"
                          placeholder="Search Equipment templates..."
                          inputProps={{
                            maxLength: 100,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() =>
                                    form.setFieldValue(
                                      Filters.TEXT_SEARCH,
                                      initialValues[Filters.TEXT_SEARCH],
                                    )
                                  }
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid xs item>
                    {displayAddButton ? (
                      <HasSystemPermission
                        permissions={[SystemPermission.ITEM_TAB_EDIT]}
                      >
                        <Grid item container>
                          <Button
                            color="primary"
                            variant="contained"
                            className={classes.editBtn}
                            onClick={() => {
                              toggleAddEquipmentTemplateModal(true);
                            }}
                          >
                            Add Equipment template
                          </Button>
                        </Grid>
                      </HasSystemPermission>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AutoSaveFormik>
        </Form>
      </Formik>
    );
  },
);

const styles = {
  root: {
    marginBottom: 15,
    paddingTop: 15,
  },
  leftIcon: {
    marginRight: 10,
  },
};

export default withStyles(styles)(EquipmentTemplatesFilter);
