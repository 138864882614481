import _isNil from 'lodash/isNil';

import {
  SimulationDirection,
  SimulationResultSeries,
} from 'features/projects/tasks/task/simulation/simulation.constants';
import { getQuantityUnitFromItems } from 'utils/app.util';
import themes, { defaultThemeType } from 'layout/themes';

const theme = themes[defaultThemeType];

export const getSimulationChartSeries = (
  simulationResults,
  operationLoadPercent,
  direction,
  simulationResultDirection = undefined,
  suffix = simulationResultDirection !== undefined
    ? `BHA v${simulationResultDirection + 1}`
    : undefined,
) =>
  [
    {
      index: 0,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.SURFACE_WEIGHT_RIH,
        simulationResultDirection,
      ),
      visible: true,
      color: theme.altus.components.SimulationDashboard.series.SurfaceWeightRIH,
      name: getConcatenatedNameResult('Surface Weight RIH', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'surfaceTension']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'surfaceTension', 'value']))
        .toArray(),
    },
    {
      index: 1,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.SURFACE_WEIGHT_POOH,
        simulationResultDirection,
      ),
      visible: true,
      color:
        theme.altus.components.SimulationDashboard.series.SurfaceWeightPOOH,
      name: getConcatenatedNameResult('Surface Weight POOH', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'surfaceTension']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'surfaceTension', 'value']))
        .toArray(),
    },
    {
      index: 2,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.SURFACE_WEIGHT_SAFE_OPERATING_LOAD,
        simulationResultDirection,
      ),
      visible: true,
      name: getConcatenatedNameResult(
        operationLoadPercent
          ? `SW at Safe Operating Load (${operationLoadPercent}%)`
          : 'SW at Safe Operating Load',
        suffix,
      ),
      color:
        theme.altus.components.SimulationDashboard.series.SWSafeOperatingLoad,
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'workingLimit']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'workingLimit', 'value']))
        .toArray(),
    },
    {
      index: 3,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.COMPRESSION_LIMIT,
        simulationResultDirection,
      ),
      visible: false,
      name: getConcatenatedNameResult('Compression Limit', suffix),
      color: theme.altus.components.SimulationDashboard.series.CompressionLimit,
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'compressionLimit']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'compressionLimit', 'value']))
        .toArray(),
    },
    {
      index: 4,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.WEAK_POINT_SAFETY_FACTOR,
        simulationResultDirection,
      ),
      visible: false,
      color: theme.altus.components.SimulationDashboard.series.WeakPoint,
      name: getConcatenatedNameResult('Weak Point with SF', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'weakPointLowSF']),
        ),
      },
      dashStyle: 'dot',
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'weakPointLowSF', 'value']))
        .toArray(),
    },
    {
      index: 5,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.WEAK_POINT,
        simulationResultDirection,
      ),
      visible: false,
      color: theme.altus.components.SimulationDashboard.series.WeakPoint,
      name: getConcatenatedNameResult('Weak Point', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'weakPointLow']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'weakPointLow', 'value']))
        .toArray(),
    },
    {
      index: 7,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.MAX_SET_DOWN,
        simulationResultDirection,
      ),
      visible: true,
      color: theme.altus.components.SimulationDashboard.series.MaxSetDown,
      name: getConcatenatedNameResult('Max. Set Down force', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'maxSetDown']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'maxSetDown', 'value']))
        .toArray(),
    },
    {
      index: 8,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.MAX_PICKUP_FORCE_POOH,
        simulationResultDirection,
      ),
      visible: true,
      color:
        theme.altus.components.SimulationDashboard.series.MaxPickupForcePOOH,
      name: getConcatenatedNameResult('Max. Pick-up Force POOH', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'maxPickupWeight']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'maxPickupWeight', 'value']))
        .toArray(),
    },
    {
      index: 9,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.MIN_TRACTOR_PULL,
        simulationResultDirection,
      ),
      visible: true,
      color: theme.altus.components.SimulationDashboard.series.MinTractorPull,
      name: getConcatenatedNameResult('Minimum Tractor Pull', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'tractorForce']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'tractorForce', 'value']))
        .toArray(),
    },
    {
      index: 10,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.CABLE_HEAD_TENSION_RIH,
        simulationResultDirection,
      ),
      visible: true,
      color:
        theme.altus.components.SimulationDashboard.series.CableHeadTensionRIH,
      name: getConcatenatedNameResult('Cable Head Tension RIH', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'cableHeadTension']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'cableHeadTension', 'value']))
        .toArray(),
    },
    {
      index: 11,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.CABLE_HEAD_TENSION_POOH,
        simulationResultDirection,
      ),
      visible: true,
      color:
        theme.altus.components.SimulationDashboard.series.CableHeadTensionPOOH,
      name: getConcatenatedNameResult('Cable Head Tension POOH', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'cableHeadTension']),
        ),
      },
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'cableHeadTension', 'value']))
        .toArray(),
    },
    {
      index: 13,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.SW_JAR_ACTIVATION,
        simulationResultDirection,
      ),
      visible: false,
      color: theme.altus.components.SimulationDashboard.series.JarActivation,
      name: getConcatenatedNameResult('SW at Jar Activation', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'surfaceWeightJarFiringLow']),
        ),
      },
      data: simulationResults
        .map((x) =>
          x.getIn(['pullOutOfHole', 'surfaceWeightJarFiringLow', 'value']),
        )
        .toArray(),
    },
    {
      index: 15,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.CHT_JAR_ACTIVATION,
        simulationResultDirection,
      ),
      visible: false,
      color: theme.altus.components.SimulationDashboard.series.JarActivation,
      name: getConcatenatedNameResult('CHT at Jar Activation', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'cableHeadTensionJarFiringLow']),
        ),
      },
      data: simulationResults
        .map((x) =>
          x.getIn(['pullOutOfHole', 'cableHeadTensionJarFiringLow', 'value']),
        )
        .toArray(),
    },
    {
      index: 17,
      direction: SimulationDirection.RUN_IN_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.STRETCH_RIH,
        simulationResultDirection,
      ),
      visible: true,
      color: theme.altus.components.SimulationDashboard.series.StretchRIH,
      name: getConcatenatedNameResult('Stretch RIH', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['runInHole', 'stretch']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['runInHole', 'stretch', 'value']))
        .toArray(),
    },
    {
      index: 18,
      direction: SimulationDirection.PULL_OUT_OF_HOLE,
      id: createAlternativeSeriesId(
        SimulationResultSeries.STRETCH_POOH,
        simulationResultDirection,
      ),
      visible: true,
      color: theme.altus.components.SimulationDashboard.series.StretchPOOH,
      name: getConcatenatedNameResult('Stretch POOH', suffix),
      custom: {
        unit: getQuantityUnitFromItems(simulationResults, (result) =>
          result.getIn(['pullOutOfHole', 'stretch']),
        ),
      },
      events: toggleCompressionEvents,
      data: simulationResults
        .map((x) => x.getIn(['pullOutOfHole', 'stretch', 'value']))
        .toArray(),
    },
  ].filter((series) => _isNil(direction) || series.direction === direction);

const createAlternativeSeriesId = (
  simulationResultSeries,
  simulationResultDirection,
) => {
  return simulationResultDirection
    ? simulationResultSeries + 50 + simulationResultDirection
    : simulationResultSeries;
};

const getConcatenatedNameResult = (labelName, suffix) =>
  `${labelName} ${suffix ? ` [${suffix}]` : ''}`;

export const getSimulationCompressionDepthSeries = (
  id,
  direction,
  simulationResults,
  key,
) => {
  const compressionDepth = getSimulationCompressionDepth(simulationResults);
  const dir =
    direction === SimulationDirection.PULL_OUT_OF_HOLE
      ? 'pullOutOfHole'
      : 'runInHole';

  if (_isNil(compressionDepth?.[dir]) || !compressionDepth[dir].hasValue) {
    return null;
  }

  return {
    zIndex: 2,
    direction,
    id,
    visible: true,
    color: theme.palette.secondary.cssGray,
    name: 'Compression Depth',
    showInLegend: false,
    custom: {
      tooltip: false,
      unit: getQuantityUnitFromItems(simulationResults, (result) =>
        result.getIn([dir, key]),
      ),
    },
    marker: {
      symbol: 'triangle',
      enabled: false,
    },
    data: simulationResults
      .toArray()
      .filter((x) => x[0] >= compressionDepth[dir].value)
      .map((x, i) => ({
        x: x[0],
        y: x[1].getIn([dir, key, 'value']),
        marker: { enabled: i === 0, fillColor: theme.palette.error.main },
      })),
  };
};

// there is a copy of this function in simulation.selectors.js due to jest test failing
export const getSimulationCompressionDepth = (simulationResults) => {
  const runInHole = simulationResults
    ?.find((result) => result.get('isCompressionDepthRunInHole'))
    ?.get('measuredDepth');

  const pullOutOfHole = simulationResults
    ?.find((result) => result.get('isCompressionDepthPullOutOfHole'))
    ?.get('measuredDepth');

  if (_isNil(runInHole) && _isNil(pullOutOfHole)) {
    return undefined;
  }

  return { runInHole, pullOutOfHole };
};

// Helper to toggle show/hide compression series based on related series
const toggleCompressionEvents = {
  hide: (series) => {
    const compressionSeries = series?.target?.chart?.series?.find(
      (s) =>
        s.options.id ===
        series.target.options.id + SimulationResultSeries.COMPRESSION_DEPTH,
    );
    if (!_isNil(compressionSeries)) {
      compressionSeries.hide();
    }
  },
  show: (series) => {
    const compressionSeries = series?.target?.chart?.series?.find(
      (s) =>
        s.options.id ===
        series.target.options.id + SimulationResultSeries.COMPRESSION_DEPTH,
    );
    if (!_isNil(compressionSeries)) {
      compressionSeries.show();
    }
  },
};
