import moment from 'moment';
import { compose } from 'redux';
import Highcharts from 'highcharts';
import { alpha } from '@material-ui/core/styles';
import { useMemo, useEffect, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import HighchartsReact from 'highcharts-react-official';

import { useToggle } from 'altus-hooks';

import {
  requestSimulation,
  receiveSimulationResults,
} from 'features/projects/tasks/task/simulation/simulation.actions';

import {
  getSimulationFromState,
  createSimulationResultsByDirectionSelector,
} from 'features/projects/tasks/task/simulation/simulation.selectors';

import {
  EMPTY_MAP,
  EMPTY_LIST,
  UNITS as Units,
  TIME_UNITS as TimeUnits,
} from 'app/app.constants';

import { formatValue } from 'utils/format.util';
import { useHeader } from 'app/hooks/useHeader';
import projectService from 'services/project.service';
import { getQuantityUnitFromItems } from 'utils/app.util';
import simulationService from 'services/simulation.service';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { receiveWellboreTrajectory } from 'features/wells/wellbore.actions';
import { getWellboreTrajectoryFromState } from 'features/wells/wellbore.selectors';
import DashboardHeader from 'features/projects/dashboard/components/DashboardHeader';
import { SimulationDirection } from 'features/projects/tasks/task/simulation/simulation.constants';
import { getSimulationChartSeries } from 'features/projects/tasks/task/simulation/simulation.utils';
import { useTheme } from '@material-ui/core/styles';

const chartContainerProps = {
  style: {
    height: '100%',
  },
};

const requestTimestampsByUser = (initial) => {
  const startTimeRIH = initial;

  const endTimeRIH = startTimeRIH
    .clone()
    .add(window.prompt('Run In Hole duration (hrs):', 1), TimeUnits.HOUR);

  const startTimePOOH = endTimeRIH
    .clone()
    .add(window.prompt('Duration at bottom (hrs):', 0.5), TimeUnits.HOUR);

  const endTimePOOH = startTimePOOH
    .clone()
    .add(window.prompt('Pull Out Of Hole duration (hrs):', 2), TimeUnits.HOUR);

  return {
    startTimeRIH: startTimeRIH.format(),
    endTimeRIH: endTimeRIH.format(),
    startTimePOOH: startTimePOOH.format(),
    endTimePOOH: endTimePOOH.format(),
  };
};

const SimulationDashboardContainer = ({ taskId, projectId, simulationId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const project = useSelector(getCurrentProject);

  const [isDirectionHighlighted, toggleDirectionHighlight] = useToggle();

  const simulation = useSelector((state) =>
    getSimulationFromState(state, simulationId),
  );

  useHeader({
    title: project.get('title'),
    subTitle: simulation?.get('name'),
  });

  const wellboreId = project.get('wellboreId');

  const [isMultisetSimulation, setIsMultisetSimulation] = useState(false);

  const simulationResultSelector = useMemo(
    () => createSimulationResultsByDirectionSelector(simulationId),
    [simulationId],
  );

  // when it is simulation with set/retreive tool
  const simulationResultsRihSelector = useMemo(
    () =>
      createSimulationResultsByDirectionSelector(
        simulationId,
        SimulationDirection.RUN_IN_HOLE,
      ),
    [simulationId],
  );
  const simulationResultsPoohSelector = useMemo(
    () =>
      createSimulationResultsByDirectionSelector(
        simulationId,
        SimulationDirection.PULL_OUT_OF_HOLE,
      ),
    [simulationId],
  );

  const simulationResults = useSelector(simulationResultSelector) ?? EMPTY_MAP;

  // when it is simulation with set/retreive tool
  const simulationResultsRihSimulationDirection =
    useSelector(simulationResultsRihSelector) ?? EMPTY_MAP;
  const simulationResultsPoohSimulationDirection =
    useSelector(simulationResultsPoohSelector) ?? EMPTY_MAP;

  useEffect(() => {
    if (
      simulationResultsRihSimulationDirection !== EMPTY_MAP &&
      simulationResultsPoohSimulationDirection !== EMPTY_MAP &&
      simulationResults === EMPTY_MAP
    ) {
      setIsMultisetSimulation(true);
    }
  }, [
    simulationResults,
    simulationResultsRihSimulationDirection,
    simulationResultsPoohSimulationDirection,
  ]);

  const wellboreTrajectorySelector = useMemo(
    () => getWellboreTrajectoryFromState(wellboreId),
    [wellboreId],
  );

  const wellboreTrajectory =
    useSelector(wellboreTrajectorySelector) ?? EMPTY_MAP;

  const simulationResultsRIH = useMemo(
    () =>
      simulationResults
        .mapKeys((_, result) => result.getIn(['runInHole', 'timestamp']))
        .sortBy((_, timestamp) => timestamp),
    [simulationResults],
  );

  const simulationResultsPOOH = useMemo(
    () =>
      simulationResults
        .mapKeys((_, result) => result.getIn(['pullOutOfHole', 'timestamp']))
        .sortBy((_, timestamp) => timestamp),
    [simulationResults],
  );

  const simulationResultsRIHSimulationRIH = useMemo(
    () =>
      simulationResultsRihSimulationDirection
        .mapKeys((_, result) => result.getIn(['runInHole', 'timestamp']))
        .sortBy((_, timestamp) => timestamp),
    [simulationResultsRihSimulationDirection],
  );

  const simulationResultsPOOHSimulationRIH = useMemo(
    () =>
      simulationResultsRihSimulationDirection
        .mapKeys((_, result) => result.getIn(['pullOutOfHole', 'timestamp']))
        .sortBy((_, timestamp) => timestamp),
    [simulationResultsRihSimulationDirection],
  );

  const simulationResultsRIHSimulationPOOH = useMemo(
    () =>
      simulationResultsPoohSimulationDirection
        .mapKeys((_, result) => result.getIn(['runInHole', 'timestamp']))
        .sortBy((_, timestamp) => timestamp),
    [simulationResultsPoohSimulationDirection],
  );

  const simulationResultsPOOHSimulationPOOH = useMemo(
    () =>
      simulationResultsPoohSimulationDirection
        .mapKeys((_, result) => result.getIn(['pullOutOfHole', 'timestamp']))
        .sortBy((_, timestamp) => timestamp),
    [simulationResultsPoohSimulationDirection],
  );

  const wellboreTrajectoryPointsRIH = useMemo(
    () =>
      wellboreTrajectory
        .get('trajectoryPoints', EMPTY_LIST)
        .toMap()
        .mapKeys((_, point) => point.get('timestampRunInHole'))
        .sortBy((_, timestamp) => timestamp),
    [wellboreTrajectory],
  );

  const wellboreTrajectoryPointsPOOH = useMemo(
    () =>
      wellboreTrajectory
        .get('trajectoryPoints', EMPTY_LIST)
        .toMap()
        .mapKeys((_, point) => point.get('timestampPullOutOfHole'))
        .sortBy((_, timestamp) => timestamp),
    [wellboreTrajectory],
  );

  const depthUnit = getQuantityUnitFromItems(
    isMultisetSimulation
      ? simulationResultsRihSimulationDirection
      : simulationResults,
    (result) => result.get('measuredDepth'),
  );

  const forceUnit = getQuantityUnitFromItems(
    isMultisetSimulation
      ? simulationResultsRihSimulationDirection
      : simulationResults,
    (result) => result.get('downHoleSetDownForce'),
  );

  const series = useMemo(
    () => [
      {
        yAxis: 1,
        name: 'Measured Depth',
        color: theme.altus.components.ContextualizedWell.trajectory.depth,
        unit: simulation?.getIn(['calculateFromDepth', 'unit']),
        data: isMultisetSimulation
          ? simulationResultsRIHSimulationRIH
              .concat(simulationResultsPOOHSimulationRIH)
              .concat(simulationResultsRIHSimulationPOOH)
              .concat(simulationResultsPOOHSimulationPOOH)
              .map((x) => x.getIn(['measuredDepth', 'value']))
              .toArray()
          : simulationResultsRIH
              .concat(simulationResultsPOOH)
              .map((x) => x.getIn(['measuredDepth', 'value']))
              .toArray(),
      },
      // single simulation result charts
      ...(!isMultisetSimulation
        ? getSimulationChartSeries(
            simulationResultsRIH,
            null,
            SimulationDirection.RUN_IN_HOLE,
          )
        : []),
      ...(!isMultisetSimulation
        ? getSimulationChartSeries(
            simulationResultsPOOH,
            simulation?.get('workingLimitSafetyFactor'),
            SimulationDirection.PULL_OUT_OF_HOLE,
          )
        : []),
      // multiple simulation results charts
      ...(isMultisetSimulation
        ? getSimulationChartSeries(
            simulationResultsRIHSimulationRIH,
            null,
            SimulationDirection.RUN_IN_HOLE,
            SimulationDirection.RUN_IN_HOLE, // simulation direction result
          )
        : []),
      ...(isMultisetSimulation
        ? getSimulationChartSeries(
            simulationResultsPOOHSimulationRIH,
            simulation?.get('workingLimitSafetyFactor'),
            SimulationDirection.PULL_OUT_OF_HOLE,
            SimulationDirection.RUN_IN_HOLE, // simulation direction result
          )
        : []),
      ...(isMultisetSimulation
        ? getSimulationChartSeries(
            simulationResultsRIHSimulationPOOH,
            null,
            SimulationDirection.RUN_IN_HOLE,
            SimulationDirection.PULL_OUT_OF_HOLE, // simulation direction result
          )
        : []),
      ...(isMultisetSimulation
        ? getSimulationChartSeries(
            simulationResultsPOOHSimulationPOOH,
            simulation?.get('workingLimitSafetyFactor'),
            SimulationDirection.PULL_OUT_OF_HOLE,
            SimulationDirection.PULL_OUT_OF_HOLE, // simulation direction result
          )
        : []),
      {
        yAxis: 2,
        unit: Units.DEG,
        name: 'Dogleg Severity',
        visible: false,
        color: theme.altus.components.SimulationDashboard.series.yAxis,
        data: wellboreTrajectoryPointsRIH
          .concat(wellboreTrajectoryPointsPOOH)
          .map((x) => x.get('dogLegSeverity'))
          .toArray(),
      },
    ],
    [
      simulation,
      isMultisetSimulation,
      simulationResultsRIH,
      simulationResultsPOOH,
      simulationResultsRIHSimulationRIH,
      simulationResultsPOOHSimulationRIH,
      simulationResultsRIHSimulationPOOH,
      simulationResultsPOOHSimulationPOOH,
      wellboreTrajectoryPointsRIH,
      wellboreTrajectoryPointsPOOH,
      theme,
    ],
  );

  const plotBands = useMemo(() => {
    if (!isDirectionHighlighted) return [];

    if (isMultisetSimulation) {
      return [
        {
          color: alpha(
            theme.altus.components.SimulationDashboard.plotBands.RIH,
            0.1,
          ),
          from: simulationResultsRIH.keySeq().first(),
          to: simulationResultsRIH.keySeq().last(),
          label: {
            text: 'RIH',
          },
        },
        {
          color: alpha(
            theme.altus.components.SimulationDashboard.plotBands.POOH,
            0.1,
          ),
          from: simulationResultsPOOH.keySeq().first(),
          to: simulationResultsPOOH.keySeq().last(),
          label: {
            text: 'POOH',
          },
        },
      ];
    }

    return [
      {
        color: alpha(
          theme.altus.components.SimulationDashboard.plotBands.RIH,
          0.1,
        ),
        from: simulationResultsRIHSimulationRIH.keySeq().first(),
        to: simulationResultsRIHSimulationRIH.keySeq().last(),
        label: {
          text: 'RIH [BHA v1]',
        },
      },
      {
        color: alpha(
          theme.altus.components.SimulationDashboard.plotBands.POOH,
          0.1,
        ),
        from: simulationResultsPOOHSimulationRIH.keySeq().first(),
        to: simulationResultsPOOHSimulationRIH.keySeq().last(),
        label: {
          text: 'POOH [BHA v1]',
        },
      },
      {
        color: alpha(
          theme.altus.components.SimulationDashboard.plotBands.RIH,
          0.1,
        ),
        from: simulationResultsRIHSimulationPOOH.keySeq().first(),
        to: simulationResultsRIHSimulationPOOH.keySeq().last(),
        label: {
          text: 'RIH [BHA v1]',
        },
      },
      {
        color: alpha(
          theme.altus.components.SimulationDashboard.plotBands.POOH,
          0.1,
        ),
        from: simulationResultsPOOHSimulationPOOH.keySeq().first(),
        to: simulationResultsPOOHSimulationPOOH.keySeq().last(),
        label: {
          text: 'POOH [BHA v1]',
        },
      },
    ];
  }, [
    isMultisetSimulation,
    simulationResultsRIH,
    simulationResultsPOOH,
    simulationResultsRIHSimulationRIH,
    simulationResultsPOOHSimulationRIH,
    simulationResultsRIHSimulationPOOH,
    simulationResultsPOOHSimulationPOOH,
    isDirectionHighlighted,
    theme,
  ]);

  const options = useMemo(
    () => ({
      chart: {
        spacing: 25,
        alignTicks: false,
        backgroundColor: theme.palette.secondary.darkGrey,
      },
      series,
      legend: {
        shadow: false,
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        backgroundColor: 'transparent',
      },
      tooltip: {
        shared: true,
        crosshairs: true,
        formatter() {
          let format = '';

          this.points.forEach((point) => {
            const { series, y } = point;
            const { color, name, userOptions } = series;
            const { unit } = userOptions;
            const currentUnit = unit ?? forceUnit;
            const formattedValue = formatValue(y, currentUnit);
            format += `<br /><span style="color: ${color}">${name}: ${formattedValue}</span>`;
          });

          return format;
        },
      },
      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },
      title: {
        text: null,
      },
      time: {
        useUTC: false,
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%e. %b',
          year: '%b',
        },
        gridLineWidth: 1,
        gridLineDashStyle: 'ShortDash',
        gridLineColor: theme.palette.secondary.main,
        title: {
          text: 'Time',
        },
        plotBands,
        max: isMultisetSimulation
          ? simulationResultsPOOHSimulationPOOH.keySeq().last()
          : simulationResultsPOOH.keySeq().last(),
      },
      yAxis: [
        {
          allowDecimals: false,
          gridLineDashStyle: 'ShortDash',
          gridLineColor: theme.palette.secondary.main,
          title: {
            text: `Forces (${forceUnit})`,
          },
        },
        {
          reversed: true,
          opposite: true,
          gridLineWidth: 0,
          allowDecimals: false,
          title: {
            text: `Measured Depth (${depthUnit})`,
          },
        },
        {
          opposite: true,
          gridLineWidth: 0,
          allowDecimals: false,
          title: {
            text: 'Dogleg Angle (deg)',
          },
        },
      ],
    }),
    [
      series,
      forceUnit,
      depthUnit,
      plotBands,
      isMultisetSimulation,
      simulationResultsPOOH,
      simulationResultsPOOHSimulationPOOH,
      theme,
    ],
  );

  useEffect(() => {
    dispatch(requestSimulation(projectId, taskId, simulationId));
  }, [taskId, projectId, simulationId, dispatch]);

  useEffect(() => {
    if (!wellboreId) return;

    const timestamps = requestTimestampsByUser(moment());

    simulationService
      .getSimulationResultsByTime(projectId, taskId, simulationId, timestamps)
      .then((results) => dispatch(receiveSimulationResults(results)));

    projectService
      .getWellboreTrajectoryByProjectByTime(projectId, timestamps)
      .then((trajectoryPoints) => {
        dispatch(receiveWellboreTrajectory(wellboreId, { trajectoryPoints }));
      });
  }, [taskId, dispatch, wellboreId, projectId, simulationId]);

  return (
    <>
      <DashboardHeader projectId={projectId} />
      <Grid container justifyContent="flex-end" component={Box} padding={2}>
        <Grid item>
          <Button
            size="small"
            variant="contained"
            onClick={toggleDirectionHighlight}
            color={isDirectionHighlighted ? 'primary' : 'default'}
            style={{
              textTransform: 'none',
            }}
          >
            Highlight RIH/POOH
          </Button>
        </Grid>
      </Grid>
      <HighchartsReact
        options={options}
        highcharts={Highcharts}
        containerProps={chartContainerProps}
      />
    </>
  );
};

export default compose()(SimulationDashboardContainer);
