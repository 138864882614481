import { PROJECT_STATUS as ProjectStatus } from 'app/app.constants';

export const Filters = {
  FACILITY_ID: 'facilityId',
  TEXT_SEARCH: 'textSearch',
  ORGANIZATION_ID: 'organizationId',
  PROJECT_STATUS: 'projectStatus',
};
export const pageSize = 5;
export const initialPage = 1;

export const filterReport = {
  projectStatuses: [ProjectStatus.REPORT],
};
export const filterPlanAndExecute = {
  projectStatuses: [ProjectStatus.PLAN, ProjectStatus.EXECUTE],
};
