import { OrderedMap } from 'immutable';

import mappers from 'mappers';
import { EMPTY_MAP } from 'app/app.constants';
import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';

const initialState = EMPTY_MAP;

const projectReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case PROJECTS_ACTIONS.RECEIVE_PROJECT: {
      if (error) return state;

      return state.set(
        payload.projectId.toString(),
        mappers.Project.from(payload),
      );
    }

    case PROJECTS_ACTIONS.RECEIVE_PROJECTS: {
      if (error) return state;

      return OrderedMap(
        payload.map((project) => [
          project.projectId.toString(),
          mappers.Project.from(project),
        ]),
      );
    }

    case PROJECTS_ACTIONS.CLEAR_PROJECTS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default projectReducer;
