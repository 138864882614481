import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getInitials } from 'utils/app.util';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { UserAvatar } from 'altus-ui-components';

const ProjectUser = ({ user, classes, contained, organization = true }) => {
  const title = user.get('title');
  const name = user.get('name') || user.get('fullName');
  const organizationName = user.get('organizationName');

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      className={classNames({
        [classes.contained]: !!contained,
      })}
    >
      <Grid item className={classes.avatar}>
        <UserAvatar initials={getInitials(name)} avatar={user.get('avatar')} />
      </Grid>
      <Grid xs item zeroMinWidth>
        <Typography variant="body2" noWrap>
          {name}
        </Typography>
        {organization ? (
          <Typography
            noWrap
            variant="body2"
            color="textSecondary"
            className={classes.organization}
          >
            {!!title && !!organizationName
              ? `${title} at ${organizationName}`
              : `${title || organizationName}`}
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  contained: {
    padding: theme.spacing(2),
    border: `1px dotted ${theme.palette.action.disabled}`,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  organization: {
    fontSize: theme.typography.caption.fontSize,
  },
});

ProjectUser.propTypes = {
  contained: PropTypes.bool,
  user: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    avatar: PropTypes.string,
    fullName: PropTypes.string,
    organizationName: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(ProjectUser);
