import CloseIcon from '@material-ui/icons/Close';
import Settings from '@material-ui/icons/Settings';
import withStyles from '@material-ui/styles/withStyles';
import { Box, Grid, Drawer, IconButton, Typography } from '@material-ui/core';

import { useToggle } from 'altus-hooks';

import NavigationLink from 'app/components/NavigationLink';
import ProjectDashboardsList from 'features/projects/dashboard/components/ProjectDashboardsList';
import ProjectDashboardsModalContainer from 'features/projects/dashboard/components/ProjectDashboardsModal';
import { useSystemPermissions } from 'app/hooks/authorization/useSystemPermissions';
import { SystemPermission } from 'app/app.constants';

const ProjectDashboardsDrawer = ({
  isOpen,
  classes,
  project,
  projectId,
  toggleDrawer,
  projectDashboardId,
  taskId = 0,
}) => {
  const title = project?.get('title');
  const wellboreName = project?.get('wellboreName');
  const facilityName = project?.get('facilityName');

  const [isModalOpen, toggleModal] = useToggle();

  const { userPermissions } = useSystemPermissions();

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={toggleDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Grid container justifyContent="flex-end">
        <Grid item>
          <IconButton title="Close" onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1" noWrap>
            {`${facilityName} - ${wellboreName}`}
          </Typography>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Typography variant="subtitle1" noWrap paragraph>
            {`${projectId}. - ${title}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs>
        <ProjectDashboardsList projectId={projectId} taskId={taskId} />
      </Grid>
      <Grid item>
        {userPermissions.includes(
          SystemPermission.DASHBOARD_CONFIGURATION_ACCESS,
        ) && (
          <Grid
            container
            component={Box}
            paddingBottom={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <NavigationLink
              onClick={toggleModal}
              component={Typography}
              className={classes.configurationLink}
            >
              Dashboard Configuration
            </NavigationLink>
            <Box component={Settings} marginLeft={0.5} />
          </Grid>
        )}
      </Grid>
      <ProjectDashboardsModalContainer
        project={project}
        isOpen={isModalOpen}
        projectId={projectId}
        toggleModal={toggleModal}
        projectDashboardId={projectDashboardId}
      />
    </Drawer>
  );
};

const styles = (theme) => ({
  drawerPaper: {
    width: '15%',
    minWidth: 300,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  configurationLink: {
    cursor: 'pointer',
  },
});

export default withStyles(styles)(ProjectDashboardsDrawer);
