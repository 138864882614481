import { compose } from 'redux';
import { List, isImmutable } from 'immutable';
import { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import ToolAssemblyCreatorTable from 'features/equipment/toolAssemblies/components/ToolAssemblyCreatorTable';
import TaskToolstringPreview from 'features/projects/tasks/task/toolstring/components/TaskToolstringPreview';
import ToolAssemlyInformationContainer from 'features/equipment/toolAssemblies/components/ToolAssemlyInformationContainer';
import ToolstringItemHighlightProvider from 'features/projects/tasks/task/toolstring/components/ToolstringItemHighlightProvider';

const ToolAssemblyCreator = ({
  classes,
  selectedTools,
  onDuplicateItem,
  toolAssemblyInfo,
  toolAssemblyName,
  setToolAssemblyInfo,
  setToolAssemblyName,
  onSortToolstringItem,
  onDeleteToolstringItem,
  toggleToolAssemblyItemModal,
}) => {
  const createToolstringSections = useCallback((selectedTools) => {
    if (selectedTools.length > 0) {
      const length = 1;
      const items = List(selectedTools).toJS();
      const obj = {
        length,
        items,
      };
      return [obj];
    } else {
      if (isImmutable(selectedTools)) {
        const length = 1;
        const items = selectedTools.toJS();
        const obj = {
          length,
          items,
        };
        return [obj];
      }
    }
  }, []);

  return (
    <Grid container className={classes.container}>
      <ToolstringItemHighlightProvider>
        <Grid item container xs={12} className={classes.content}>
          <Grid item container xs={2} className={classes.toolstringPreview}>
            <TaskToolstringPreview
              editable={false}
              toolAssemblyCreator={true}
              toolstringSections={createToolstringSections(selectedTools)}
            />
          </Grid>
          <Grid item container xs={8}>
            <ToolAssemblyCreatorTable
              toolstringTools={selectedTools}
              onDuplicateItem={onDuplicateItem}
              onSortToolstringTool={onSortToolstringItem}
              onDeleteToolstringTool={onDeleteToolstringItem}
              onDeleteToolAssemblyTool={onDeleteToolstringItem}
            />
          </Grid>
          <Grid item container xs={2} className={classes.information}>
            <ToolAssemlyInformationContainer
              toolAssemblyInfo={toolAssemblyInfo}
              toolAssemblyName={toolAssemblyName}
              setToolAssemblyInfo={setToolAssemblyInfo}
              setToolAssemblyName={setToolAssemblyName}
              toggleToolAssemblyItemModal={toggleToolAssemblyItemModal}
            />
          </Grid>
        </Grid>
      </ToolstringItemHighlightProvider>
    </Grid>
  );
};

const styles = (theme) => ({
  toolstringPreview: {
    height: '100%',
  },
  container: {
    height: '100%',
  },
  content: {
    height: '100%',
  },
  header: {
    height: '10%',
  },
  information: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(ToolAssemblyCreator);
