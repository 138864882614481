import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { invokeIfFunction } from 'utils/app.util';

const CardNavigationItemHeader = ({ title, classes, renderHeaderAction }) => (
  <Grid container justifyContent="space-between" className={classes.root}>
    <Grid item xs={11}>
      <Typography variant="subtitle1" noWrap>
        <strong>{title}</strong>
      </Typography>
    </Grid>
    <Grid item xs={1}>
      {invokeIfFunction(renderHeaderAction)}
    </Grid>
  </Grid>
);

CardNavigationItemHeader.defaultProps = {
  title: '-',
};

CardNavigationItemHeader.propTypes = {
  title: PropTypes.string,
  renderHeaderAction: PropTypes.func,
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
});

export default withStyles(styles)(CardNavigationItemHeader);
