import { compose } from 'redux';
import Add from '@material-ui/icons/Add';
import AddIcon from '@material-ui/icons/Add';
import { useEffect, useCallback, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { Grid, Fab, Button } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { useModal } from 'altus-modal';
import { BasePage } from 'altus-ui-components';

import {
  sortActivities,
  selectActivity,
  createActivity,
  getProjectStatus,
  refreshProjectStatus,
  toggleCreateActivityModal,
} from 'features/projects/activities/activities.actions';

import {
  getTaskFromState,
  getPlannedToolstringToolsWithStatusFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';

import { getActionDataStateFromState } from 'app/app.selectors';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { ProjectPermission, WORK_ITEM_STATUS } from 'app/app.constants';
import { TASK_MODALS } from 'features/projects/tasks/task/task.constants';
import { useProjectPermissions } from 'app/hooks/authorization/useProjectPermissions';
import {
  loadAndFilterPlannedToolstring,
  onLoad,
} from 'features/projects/tasks/task/activities/taskActivities.actions';
import { CREATE_ACTIVITY_MODE } from 'features/projects/activities/activities.constants';
import { useTaskActivities } from 'features/projects/activities/hooks/useTaskActivities';
import TaskActivitiesTable from 'features/projects/tasks/task/activities/TaskActivitiesTable';
import { TASK_ACTIVITIES_ACTIONS } from 'features/projects/tasks/task/activities/taskActivities.constants';
import CreateActivityModalContainer from 'features/projects/activities/components/CreateActivityModalContainer';
import { EditActivityModalContainerWithNotification } from 'features/projects/activities/components/editActivity/EditActivityModalContainerWithNotification';
import SetAndRetrieveItemModalContainer from 'features/projects/tasks/task/activities/SetAndRetrieveItem/SetAndRetrieveItemModalContainer';
import { checkIfNotASubActivity } from 'features/projects/tasks/task/activities/activityUtils';
import { TaskType } from 'features/projects/tasks/tasks.constants';

import useHubConnection from 'features/projects/dashboard/hooks/useHubConnection';
import config from 'infrastructure/config';
import { ProjectExecutionHub } from 'utils/activity.util';

const TaskActivitiesContainer = ({
  task,
  taskId,
  classes,
  projectId,
  dataState,
  dispatchOnLoad,
  dispatchSortActivities,
  dispatchSelectActivity,
  dispatchCreateActivity,
  dispatchRefreshProjectStatus,
  dispatchToggleCreateActivityModal,
  dispatchLoadAndFilterPlannedToolstring,
}) => {
  const activities = useTaskActivities(taskId);

  const filteredTaskActivities = useMemo(() => {
    if (activities) {
      return activities
        .filter((x) => checkIfNotASubActivity(x))

        .sortBy((activity) => activity.get('sequence'))
        .map((activity, index) => activity.set('sequence', index + 1));
    }
    return activities;
  }, [activities]);

  const [connection, connectionState] = useHubConnection(
    `${config.dashboardHubsBaseUrl}/project-execution`,
  );

  useEffect(() => {
    if (connectionState === 'Connected') {
      connection.on(
        ProjectExecutionHub.SignalProjectReload,
        (argProjectId, argTaskId) =>
          dispatchRefreshProjectStatus(argProjectId, argTaskId),
      );
      connection.invoke(ProjectExecutionHub.RegisterOnProject, projectId);
    }
  }, [
    connectionState,
    connection,
    dispatchRefreshProjectStatus,
    projectId,
    taskId,
  ]);

  useEffect(() => {
    dispatchOnLoad(projectId, taskId);
    if (task.get('type') !== TaskType.SURFACE)
      dispatchLoadAndFilterPlannedToolstring(projectId, taskId);
  }, [
    taskId,
    projectId,
    dispatchOnLoad,
    dispatchLoadAndFilterPlannedToolstring,
    task,
  ]);

  const [setRetrieveItemModal, toggleSetRetrieveItemModal] = useModal(
    TASK_MODALS.SET_RETRIEVE_ITEM_MODAL,
  );

  const onRowClick = useCallback(
    (activity) =>
      dispatchSelectActivity(filteredTaskActivities.toList().indexOf(activity)),
    [filteredTaskActivities, dispatchSelectActivity],
  );
  const onSortEnd = useCallback(
    (indices) => dispatchSortActivities(projectId, taskId, indices),
    [taskId, projectId, dispatchSortActivities],
  );

  const { hasPermission } = useProjectPermissions(
    ProjectPermission.EDIT_ACTIVITIES,
  );

  const plannedToolstringToolsWithStatus = useSelector(
    getPlannedToolstringToolsWithStatusFromState,
  );

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs justifyContent="center">
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <TaskActivitiesTable
            onSortEnd={onSortEnd}
            activities={filteredTaskActivities}
            displayComments={false} // TODO later, this requires some logic to not trigger the Edit Activity
            onRowClick={hasPermission ? onRowClick : undefined}
          />
        </Grid>
        <Grid item xs={2} container justifyContent="flex-end">
          {task.get('type') !== TaskType.SURFACE ? (
            <Button
              size="medium"
              variant="text"
              disabled={!plannedToolstringToolsWithStatus.size}
              className={classes.setItem}
              onClick={toggleSetRetrieveItemModal}
            >
              <AddIcon fontSize="small" />
              Set/Retrieve an item
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <HasProjectPermission permissions={ProjectPermission.CREATE_ACTIVITY}>
        <Fab
          color="primary"
          title={'Add activity to this task'}
          className={classes.createActivityBtn}
          disabled={task.get('status') > WORK_ITEM_STATUS.STARTED}
          onClick={() =>
            dispatchToggleCreateActivityModal(CREATE_ACTIVITY_MODE.NORMAL)
          }
        >
          <Add />
        </Fab>
        <CreateActivityModalContainer
          showWaitingActivities={false}
          createActivity={() => dispatchCreateActivity(taskId)}
        />
      </HasProjectPermission>
      <HasProjectPermission permissions={ProjectPermission.EDIT_ACTIVITIES}>
        <EditActivityModalContainerWithNotification
          taskId={taskId}
          projectId={projectId}
          activities={filteredTaskActivities?.toList()}
          connection={connection}
        />
      </HasProjectPermission>
      {setRetrieveItemModal && (
        <SetAndRetrieveItemModalContainer
          taskId={taskId}
          projectId={projectId}
          activities={Object.keys(filteredTaskActivities.toJS()).map(
            (key) => filteredTaskActivities.toJS()[key],
          )}
          open={setRetrieveItemModal}
          toggleModal={toggleSetRetrieveItemModal}
          plannedToolstringToolsWithStatus={plannedToolstringToolsWithStatus.toJS()}
          task={task}
        />
      )}
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  task: getTaskFromState(state),
  dataState: getActionDataStateFromState(
    state,
    TASK_ACTIVITIES_ACTIONS.TASK_ACTIVITIES_PAGE_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoad,
  dispatchSortActivities: sortActivities,
  dispatchCreateActivity: createActivity,
  dispatchSelectActivity: selectActivity,
  dispatchGetProjectStatus: getProjectStatus,
  dispatchRefreshProjectStatus: refreshProjectStatus,
  dispatchToggleCreateActivityModal: toggleCreateActivityModal,
  dispatchLoadAndFilterPlannedToolstring: loadAndFilterPlannedToolstring,
};

const styles = (theme) => ({
  createActivityBtn: {
    zIndex: 2,
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
  setItem: {
    color: theme.palette.primary.newLight,
    height: 'fit-content',
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(TaskActivitiesContainer);
