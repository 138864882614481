import { useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';

import { EMPTY_LIST } from 'app/app.constants';
import { projectStatusToString } from 'features/projects/projects.mappers';
import WellDashboardProjectsTable from './WellDashboardProjectsTable';

const WellDashboardProjectByStatus = ({
  projects = EMPTY_LIST,
  zoomInAndOutByProjectId,
  isArchiveProject,
  setArchiveProjects,
}) => {
  const projectsByStatus = useMemo(
    () =>
      projects
        .groupBy((project) => project.get('status'))
        .sortBy((_, status) => status),
    [projects],
  );

  return projectsByStatus.keySeq().map((status) => {
    const title = projectStatusToString(status);

    return (
      <Grid key={status} container component={Box} marginBottom={4}>
        <WellDashboardProjectsTable
          title={title}
          projects={projectsByStatus.get(status, EMPTY_LIST)}
          zoomInAndOutByProjectId={zoomInAndOutByProjectId}
          isArchiveProject={isArchiveProject}
          setArchiveProjects={setArchiveProjects}
        />
      </Grid>
    );
  });
};

export default WellDashboardProjectByStatus;
