import { Grid, Button, Typography } from '@material-ui/core';

const AddToolModalHeader = ({ closeModal, title }) => (
  <Grid container alignItems="center">
    <Grid item xs>
      <Typography variant="h6">{title}</Typography>
    </Grid>
    <Grid container item xs justifyContent="flex-end" spacing={2}>
      <Grid item>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Close
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

export default AddToolModalHeader;
