import { memo } from 'react';
import { compose } from 'redux';
import { Grid } from '@material-ui/core';

import {
  formatToolstringTotalLength,
  formatToolstringTotalWeight,
} from 'features/projects/tasks/task/toolstring/toolstring.util';

import { EMPTY_MAP } from 'app/app.constants';
import ReadOnlyField from 'app/components/ReadOnlyField';
import { formatValue, formatQuantity } from 'utils/format.util';

const TaskToolstringTotals = ({
  toolstring = EMPTY_MAP,
  maxAvailableHeight,
}) => (
  <>
    <Grid item xs={2}>
      <ReadOnlyField
        margin="none"
        label="Total Length"
        value={formatToolstringTotalLength(toolstring.get('totalLength'))}
      />
    </Grid>
    <Grid item xs={2}>
      <ReadOnlyField
        margin="none"
        size="small"
        label="Rig Up Height"
        value={formatQuantity(maxAvailableHeight)}
        // TODO check in which unit should this be
      />
    </Grid>
    <Grid item xs={2}>
      <ReadOnlyField
        margin="none"
        label="Total Weight"
        value={formatToolstringTotalWeight(toolstring.get('totalWeight'))}
      />
    </Grid>
    <Grid item xs={2}>
      <ReadOnlyField
        margin="none"
        label="Max OD"
        value={formatValue(
          toolstring.getIn(['maxOD', 'value']),
          toolstring.getIn(['maxOD', 'unit']),
        )}
        //TODO should be formatFaction for inches
      />
    </Grid>
    <Grid item xs={2}>
      <ReadOnlyField
        margin="none"
        label="Maximum Force"
        value={`${
          toolstring.getIn(['totalForce', 'value'])?.toFixed(0) ?? '0'
        } ${toolstring.getIn(['totalForce', 'unit'])}`}
      />
    </Grid>
    <Grid item xs={2}>
      <ReadOnlyField
        margin="none"
        label="Min Yield"
        value={`${
          toolstring.getIn(['minYield', 'value'])?.toFixed(0) ?? '0'
        } ${toolstring.getIn(['minYield', 'unit'])}`}
      />
    </Grid>
  </>
);

export default compose(memo)(TaskToolstringTotals);
