import Agent from 'infrastructure/agent';
import { saveFile } from 'utils/app.util';

export const getAllDocuments = (projectId) =>
  Agent.get(`/project/${projectId}/files`);

export const deleteFile = (projectId, fileId) =>
  Agent.delete(`/project/${projectId}/files/${fileId}`);

export const downloadFile = (projectId, fileId) => {
  return Agent.file
    .get(`/project/${projectId}/files/${fileId}/download`)
    .then(([file, filename]) => saveFile(file, filename));
};

export const uploadFile = (projectId, file, meta) =>
  Agent.file.post(`/project/${projectId}/files`, file, meta);

export const updateFile = (projectId, fileId, file) =>
  Agent.put(`/project/${projectId}/files/${fileId}`, file);

export const getAllChecklistsAndProcedures = (projectId) =>
  Agent.get(`/project/${projectId}/files/proceduresandchecklists/get`);

const projectFileService = {
  downloadFile,
  uploadFile,
  deleteFile,
  getAllDocuments,
  updateFile,
  getAllChecklistsAndProcedures,
};

export default projectFileService;
