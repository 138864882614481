import { compose } from 'redux';
import { PureComponent } from 'react';
import { reduxForm } from 'redux-form/immutable';
import { Dialog, DialogContent, Button } from '@material-ui/core';

import { LoadingButton } from 'altus-ui-components';
import { withModal, ModalHeader, ModalActions } from 'altus-ui-components';

import {
  CREATE_PROJECT_ROLE_FORM,
  CREATE_PROJECT_ROLE_MODAL_ID,
} from 'authorization/authorization.constants';

import RoleForm from 'features/settings/accessRoles/RoleForm';

class CreateProjectRoleModal extends PureComponent {
  onClose = () => {
    const { reset, toggleModal } = this.props;

    reset();
    toggleModal();
  };

  render() {
    const { open, valid, pristine, onSubmit, submitting, handleSubmit } =
      this.props;
    const submit = handleSubmit(onSubmit(this.onClose));

    return (
      <Dialog open={open} fullWidth onClose={this.onClose}>
        <ModalHeader title="Create Project Role" />
        <DialogContent>
          <RoleForm
            submit={submit}
            isProtected={false}
            form={CREATE_PROJECT_ROLE_FORM}
          />
        </DialogContent>
        <ModalActions>
          <Button onClick={this.onClose}>Close</Button>
          <LoadingButton
            type="submit"
            color="primary"
            disabled={pristine || !valid || submitting}
            loading={submitting}
            variant="contained"
            onClick={submit}
          >
            Create
          </LoadingButton>
        </ModalActions>
      </Dialog>
    );
  }
}

export default compose(
  withModal(CREATE_PROJECT_ROLE_MODAL_ID),
  reduxForm({
    form: CREATE_PROJECT_ROLE_FORM.ID,
    initialValues: {
      [CREATE_PROJECT_ROLE_FORM.NAME]: '',
      [CREATE_PROJECT_ROLE_FORM.DESCRIPTION]: '',
    },
  }),
)(CreateProjectRoleModal);
