import { useMemo } from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { Box, IconButton, Typography, LinearProgress } from '@material-ui/core';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Tooltip from '@material-ui/core/Tooltip';

import { useToggle } from 'altus-hooks';
import { TableRow, UserAvatar } from 'altus-ui-components';

import {
  formatDate,
  formatDuration,
  formatEstimatedDate,
  formatSource,
} from 'utils/format.util';

import Counter from 'app/components/Counter';
import { Grid } from 'app/components/withTooltip';
import { toProjectDetails } from 'utils/route.util';
import { durationFromStartAndEndTime } from 'utils/app.util';
import DashboardIcon from 'app/components/Icons/DashboardIcon';
import { Format, PROJECT_STATUS as ProjectStatus } from 'app/app.constants';
import ProjectTaskServiceChips from 'app/components/ProjectTaskServiceChips';
import ProjectTableRowDetail from 'features/projects/components/ProjectTableRowDetail';
import ProjectMapMarker from 'features/projects/components/WellMapLocations/ProjectMapMarker';
import ProjectDashboardsDrawer from 'features/projects/dashboard/components/ProjectDashboardsDrawer';

const ProjectTableRow = ({
  classes,
  item: project,
  displayLocationMarker,
  zoomInAndOutByProjectId,
  isArchiveProject = false,
  setArchiveProjects,
  ...rest
}) => {
  const title = project.get('title');
  const projectId = project.get('id') || project.get('projectId');
  const status = project.get('status');
  const endTime = project.get('endTime');
  const progress = project.get('progress');
  const startTime = project.get('startTime');
  const source = project.get('source');
  const taskServices = project.get('taskServices');
  const initialOwner = project.get('owners').first();
  const [isOpen, toggleDrawer] = useToggle();

  const isExecuteOrReport = status > ProjectStatus.PLAN;

  const tableRowClasses = useMemo(
    () => ({
      root: classes.tableRowRoot,
      rootBackground: classes.tableRowRootBackground,
    }),
    [classes],
  );

  return (
    <TableRow classes={tableRowClasses} {...rest}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs className={classes.projectDetails}>
          <Grid
            container
            spacing={2}
            component={Link}
            alignItems="center"
            to={toProjectDetails(projectId)}
          >
            {displayLocationMarker && (
              <Grid item xs={1}>
                <ProjectMapMarker status={status} />
              </Grid>
            )}
            <Grid item xs={1}>
              <Typography color="textPrimary" variant="body2">
                {project.get('projectNumber')}
              </Typography>
            </Grid>
            <Grid item container xs zeroMinWidth>
              <Grid item xs={12}>
                <Typography noWrap color="textPrimary" variant="body2">
                  {title || <i>No objective set...</i>}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <ProjectTableRowDetail
                  title="Field:"
                  value={project.get('fieldName')}
                />
              </Grid>
              <Grid item xs={4}>
                <ProjectTableRowDetail
                  title="Facility:"
                  value={project.get('facilityName')}
                />
              </Grid>
              <Grid item xs={4}>
                <ProjectTableRowDetail
                  title="Well:"
                  value={project.get('wellboreName')}
                />
              </Grid>
              {!!taskServices.size && (
                <Grid container component={Box} marginTop={0.5}>
                  <ProjectTaskServiceChips taskServices={taskServices} />
                </Grid>
              )}
            </Grid>
            <Grid item className={classes.metaRow}>
              {
                {
                  [ProjectStatus.PLAN]: (
                    <ProjectTableRowDetail
                      title="Planned:"
                      value={formatEstimatedDate(project.get('estimatedStart'))}
                    />
                  ),
                  [ProjectStatus.EXECUTE]: (
                    <ProjectTableRowDetail
                      title="Started:"
                      value={formatDate(startTime, Format.date)}
                    />
                  ),
                  [ProjectStatus.REPORT]: (
                    <ProjectTableRowDetail
                      title="Completed:"
                      value={formatDate(endTime, Format.date)}
                    />
                  ),
                }[status]
              }
              {isExecuteOrReport && (
                <Counter
                  isTimerActive={status === ProjectStatus.EXECUTE}
                  initialDuration={
                    endTime
                      ? durationFromStartAndEndTime(startTime, endTime)
                      : durationFromStartAndEndTime(startTime)
                  }
                  renderValue={({ elapsedTime }) => (
                    <ProjectTableRowDetail
                      title="Duration:"
                      value={formatDuration(elapsedTime)}
                    />
                  )}
                />
              )}
              <ProjectTableRowDetail
                title="Data Source:"
                value={formatSource(source)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              {isExecuteOrReport && (
                <>
                  <ProjectDashboardsDrawer
                    isOpen={isOpen}
                    project={project}
                    projectId={projectId}
                    toggleDrawer={toggleDrawer}
                  />
                  <IconButton color="default" onClick={toggleDrawer}>
                    <DashboardIcon />
                  </IconButton>
                </>
              )}
            </Grid>
            {isArchiveProject ? (
              <Tooltip title="Restore Project">
                <UnarchiveIcon
                  onClick={() => setArchiveProjects(projectId, false)}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            ) : (
              initialOwner && (
                <UserAvatar
                  avatar={initialOwner.get('avatar')}
                  initials={initialOwner.get('initials')}
                  title={`Owner: ${initialOwner.get('name')}`}
                />
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      {status === ProjectStatus.EXECUTE && (
        <LinearProgress
          title={`Progress: ${progress}%`}
          value={progress}
          variant="determinate"
          className={classes.linearProgres}
        />
      )}
    </TableRow>
  );
};

const styles = (theme) => ({
  tableRowRoot: {
    height: 'auto',
    maxHeight: 'none',
  },
  tableRowRootBackground: {
    position: 'relative',
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1.5)}px`,
  },
  metaRow: {
    display: 'inline-grid',
  },
  linearProgres: {
    height: '2px',
    position: 'absolute',
    width: '100%',
    bottom: 1,
    left: 0,
  },
  projectDetails: {
    overflow: 'auto',
  },
});

export default compose(withStyles(styles))(ProjectTableRow);
