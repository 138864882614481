import { Map } from 'immutable';

const smLocationFromDto = (smLocation) => {
  return Map({
    ...smLocation,
  });
};

const smlocationMappers = {
  SMLocation: {
    from: smLocationFromDto,
  },
};

export default smlocationMappers;
