import { BasePage } from 'altus-ui-components';
import useOperations from 'features/data-exchange/hooks/useOperations';
import LiveProjectsTable from 'features/data-exchange/live-projects/components/LiveProjectsTable';
import LiveProjectsFilterContainer from 'features/data-exchange/live-projects/components/LiveProjectsFilterContainer';
import usePagedSearch from 'app/hooks/usePagedSearch';
import { connect } from 'react-redux';
import { getOperationsPageParamsFromState } from 'features/operations/operations.selectors';

const LiveProjectsContainer = ({
  breadcrumb,
  pageParams,
  initialPagedSearchFilters,
  initialTableState,
  initialFilterValues,
  filter,
}) => {
  const { dataState, operations, searchOperations, updateProjectStatus } =
    useOperations(filter);

  const { onPageChange, onFiltersChange } = usePagedSearch(
    searchOperations,
    initialPagedSearchFilters,
    true,
  );

  return (
    <BasePage dataState={dataState} title={breadcrumb}>
      <LiveProjectsFilterContainer
        filter={filter}
        onSubmit={onFiltersChange}
        initialValues={initialFilterValues}
      />
      <LiveProjectsTable
        operations={operations}
        updateProjectStatus={updateProjectStatus}
        initialState={initialTableState}
        onPageChange={onPageChange}
        pageCount={pageParams.get('pageCount')}
      />
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  pageParams: getOperationsPageParamsFromState(state),
});

const mapDispatchToProps = {
  // dispatchOnLoad: searchOperations,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LiveProjectsContainer);
