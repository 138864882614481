import { useMemo } from 'react';
import { Field } from 'redux-form/immutable';
import { MenuItem } from '@material-ui/core';

import TextField from 'app/components/form/TextField';
import { EMPTY_LIST, EMPTY_STRING } from 'app/app.constants';
import { TaskType } from 'features/projects/tasks/tasks.constants';

const TaskTypeSelect = ({ taskTypes = EMPTY_LIST, ...rest }) => {
  const taskTypesById = useMemo(
    () => taskTypes.toMap().mapKeys((_, taskType) => taskType.get('id')),
    [taskTypes],
  );

  return (
    <Field select required label="Type" component={TextField} {...rest}>
      {Object.values(TaskType).map((taskType) => (
        <MenuItem key={taskType} value={taskType}>
          {taskTypesById.getIn([taskType, 'name'], EMPTY_STRING)}
        </MenuItem>
      ))}
    </Field>
  );
};

export default TaskTypeSelect;
