import isNil from 'lodash/isNil';
import convert from 'convert-units';
import findKey from 'lodash/findKey';

import {
  UNITS,
  CUSTOM_UNITS,
  METERS_PER_FEET,
  CONVERT_ALIASES,
  CONVERT_SYSTEMS,
  UNITS_BY_CATEGORY,
  CUSTOM_UNIT_SYSTEM,
} from 'app/app.constants';

const CUSTOM_UNIT_CONVERSION = {
  [UNITS.M_MIN]: {
    [CONVERT_SYSTEMS.IMPERIAL]: {
      [UNITS.FT_MIN]: (meters) => meters / METERS_PER_FEET,
    },
  },
  [UNITS.FT_MIN]: {
    [CONVERT_SYSTEMS.METRIC]: {
      [UNITS.M_MIN]: (feet) => feet * METERS_PER_FEET,
    },
  },
};

const getSystemByMeasure = (measure, settings, system) => {
  return system === CUSTOM_UNIT_SYSTEM ? settings[measure] : system;
};

export const getConversion = (from, system, settings) => {
  const defaultConversion = [from, (value) => value];

  try {
    const { measure } = CUSTOM_UNITS[from];
    const toSystem = getSystemByMeasure(measure, settings, system);
    const conversions = CUSTOM_UNIT_CONVERSION[from][toSystem];

    return conversions ? Object.entries(conversions)[0] : defaultConversion;
  } catch (e) {
    return defaultConversion;
  }
};

export const getCorrectedUnit = (unit) =>
  findKey(CONVERT_ALIASES, (aliases) => aliases.includes(unit)) || unit;

export const getUnitAlias = (unit) =>
  CONVERT_ALIASES[unit] ? CONVERT_ALIASES[unit][0] : unit;

export const convertUnit = ({ settings, value, from, to, system }) => {
  if ([value, from].some((param) => isNil(param))) {
    return [value, from];
  }

  let convertFrom = from;
  let toSystem = system;

  try {
    convertFrom = getCorrectedUnit(convertFrom);

    const { measure } = convert().describe(convertFrom);

    toSystem = getSystemByMeasure(measure, settings, system);

    const convertTo = to || UNITS_BY_CATEGORY[measure][toSystem];

    const convertedValue = convertTo
      ? convert(value).from(convertFrom).to(convertTo)
      : value;

    const convertedUnit = getUnitAlias(convertTo);

    return [convertedValue, convertedUnit];
  } catch (e) {
    const [conversionUnit, conversionFunc] = getConversion(
      convertFrom,
      system,
      settings,
    );

    return conversionFunc
      ? [conversionFunc(value), conversionUnit]
      : [value, from];
  }
};
