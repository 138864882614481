import { createSelector } from 'utils/app.util';

const getWellboreSectionSelector = (state) =>
  state.getIn(['entity', 'wellboreSection']);

export const createWellboreSectionsForWellboreSelector = (wellboreId) =>
  createSelector(getWellboreSectionSelector, (wellboreSections) =>
    wellboreSections
      .filter((section) => section?.get('wellboreId'))
      .groupBy((section) => section.get('wellboreId').toString())
      .get(wellboreId.toString()),
  );
