import { compose } from 'redux';
import formatFileSize from 'filesize';
import { connect } from 'react-redux';
import toJSComponent from 'with-immutable-props-to-js';
import { reduxForm, Field, formValueSelector } from 'redux-form/immutable';

import {
  Grid,
  Button,
  MenuItem,
  Typography,
  DialogActions,
  InputAdornment,
  DialogContentText,
} from '@material-ui/core';

import {
  MODAL,
  EMPTY_STRING,
  UPLOAD_DOCUMENT_FORM,
  MAX_UPLOAD_FILE_SIZE,
} from 'app/app.constants';

import { required } from 'utils/validation.util';
import TextField from 'app/components/form/TextField';
import LoadingButton from 'app/components/LoadingButton';
import ModalContainer from 'app/components/Modal/ModalContainer';
import { useIsInternalCategoryToShow } from 'app/hooks/useIsInternalCategoryToDisplay';

const UploadDocumentModal = ({
  valid,
  files = [],
  children,
  submitting,
  uploadFiles,
  handleSubmit,
  dialogContentText,
  rejectedFiles = [],
  availableFileCategories,
  enableFileCategories = true,
}) => {
  const isShowCategory = useIsInternalCategoryToShow();

  return (
    <ModalContainer
      height="auto"
      minWidth={600}
      TriggerComponent={children}
      modalId={MODAL.UPLOAD_FILE}
      title={files.length > 1 ? 'Upload documents' : 'Upload document'}
      Actions={({ toggleModal }) => (
        <DialogActions>
          <Button onClick={toggleModal} color="default">
            Cancel
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            submitting={submitting}
            disabled={!valid || !files.length}
            onClick={handleSubmit(uploadFiles)}
          >
            {`Upload (${files.length})`}
          </LoadingButton>
        </DialogActions>
      )}
    >
      <DialogContentText>{dialogContentText}</DialogContentText>
      <br />
      <form onSubmit={handleSubmit}>
        {rejectedFiles.map(({ file, errors }, index) => {
          const initialError = errors[0];

          const errorMessage =
            initialError?.code === 'file-too-large'
              ? `File is larger than ${formatFileSize(MAX_UPLOAD_FILE_SIZE)}`
              : EMPTY_STRING;

          return (
            <Grid container key={index}>
              <Typography nowrap paragraph variant="caption" color="error">
                {`${file.name} cannot be uploaded. ${errorMessage}`}
              </Typography>
            </Grid>
          );
        })}
        {files.map((_, index) => {
          return (
            <Grid key={index} container spacing={2}>
              <Grid item xs={enableFileCategories ? 6 : 12}>
                <Field
                  autoFocus
                  required
                  label="Filename"
                  component={TextField}
                  validate={[required]}
                  name={`${UPLOAD_DOCUMENT_FORM.FILES}.[${index}].${UPLOAD_DOCUMENT_FORM.META_NAME}`}
                  InputProps={{
                    endAdornment: (
                      <Field
                        name={`${UPLOAD_DOCUMENT_FORM.FILES}.[${index}].${UPLOAD_DOCUMENT_FORM.META_EXTENSION}`}
                        component={({ input }) => (
                          <InputAdornment position="end">
                            {input.value}
                          </InputAdornment>
                        )}
                      />
                    ),
                  }}
                />
              </Grid>
              {enableFileCategories && (
                <Grid item xs={6}>
                  <Field
                    select
                    required
                    label="Category"
                    validate={[required]}
                    component={TextField}
                    name={`${UPLOAD_DOCUMENT_FORM.FILES}.[${index}].${UPLOAD_DOCUMENT_FORM.META_CATEGORY}`}
                  >
                    {availableFileCategories
                      .filter((cat) => isShowCategory(cat))
                      .map((category) => (
                        <MenuItem key={category.id} value={category.value}>
                          <em>{category.name}</em>
                        </MenuItem>
                      ))}
                  </Field>
                </Grid>
              )}
            </Grid>
          );
        })}
      </form>
    </ModalContainer>
  );
};

export default compose(
  connect((state) => {
    const selector = formValueSelector(UPLOAD_DOCUMENT_FORM.ID);

    return {
      files: selector(state, UPLOAD_DOCUMENT_FORM.FILES),
    };
  }),
  toJSComponent,
  reduxForm({
    form: UPLOAD_DOCUMENT_FORM.ID,
  }),
)(UploadDocumentModal);
