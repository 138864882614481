import { compose } from 'redux';
import { Box } from '@material-ui/core';
import withTheme from '@material-ui/styles/withTheme';

import config from 'infrastructure/config/index';
import { ENVIRONMENTS as Environments } from 'app/app.constants';

const ApplicationEnvironmentTopbar = ({ theme }) => {
  switch (config.environmentName) {
    case Environments.PRD:
      return null;
    default:
      return (
        <Box
          borderTop={theme.spacing(1)}
          borderColor={theme.palette.environment[config.environmentName]}
        />
      );
  }
};

export default compose(withTheme)(ApplicationEnvironmentTopbar);
