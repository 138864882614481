import moment from 'moment';
import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import {
  getTimersByTypeId,
  createProjectActivitiesSelector,
} from 'features/projects/activities/activities.selectors';

import {
  getAllTasksFromState,
  getTotalPlannedDuration,
  getTotalElapsedDuration,
} from 'features/projects/tasks/tasks.selectors';

import { useHeader } from 'app/hooks/useHeader';
import { formatDuration } from 'utils/format.util';
import { durationFromStartAndEndTime } from 'utils/app.util';
import ReportTable from 'features/projects/report/ReportTable';
import { getActionDataStateFromState } from 'app/app.selectors';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { onLoad, onUnload } from 'features/projects/report/report.actions';
import { REPORT_ACTIONS } from 'features/projects/report/report.constants';
import { TimerType } from 'features/projects/activities/activities.constants';
import ReportContainerHeader from 'features/projects/report/ReportContainerHeader';
import { getProjectNonProductiveTimeFromState } from 'features/projects/npt/npt.selectors';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import { alpha } from '@material-ui/core/styles';

const ReportContainer = ({
  npts,
  tasks,
  dataState,
  projectId,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  totalElapsedDuration,
  totalPlannedDuration,
}) => {
  useHeader({ subTitle: breadcrumb });

  useEffect(() => {
    dispatchOnLoad(projectId);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload, projectId]);

  const activitiesSelector = useMemo(
    () => createProjectActivitiesSelector(projectId),
    [projectId],
  );

  const activities = useSelector(activitiesSelector);

  const project = useCurrentProject();

  const nptTimers = useCallback(() => {
    var nptTimeMs = 0;
    npts.map((npt) => (nptTimeMs += npt.get('duration').asSeconds()));
    return moment.duration(nptTimeMs, 'seconds');
  }, [npts]);

  const timersByType = {
    [TimerType.TRAC]: useSelector((state) =>
      getTimersByTypeId(state, TimerType.TRAC),
    ),
  };

  const isCompleted = (task) => {
    return !!(task.get('startTime') && task.get('endTime'));
  };

  const estimatedDuration = (task) => {
    return task.get('durationPlanned')
      ? moment.duration(task.get('durationPlanned'))
      : null;
  };

  const spentDuration = useCallback((task) => {
    return isCompleted(task)
      ? durationFromStartAndEndTime(task.get('startTime'), task.get('endTime'))
      : null;
  }, []);

  const diffDuration = useCallback(
    (task) => {
      return isCompleted(task) && estimatedDuration(task)
        ? moment.duration(
            spentDuration(task).clone().subtract(estimatedDuration(task)).abs(),
          )
        : null;
    },
    [spentDuration],
  );

  return (
    <BasePage dataState={dataState}>
      <Grid container item xs wrap="nowrap">
        <Grid item xs={2} />
        <Grid item xs={8}>
          <ReportContainerHeader
            totalNpt={nptTimers()}
            activities={activities}
            timersByType={timersByType}
            totalElapsedDuration={totalElapsedDuration}
            totalPlannedDuration={totalPlannedDuration}
          />
          <ReportTable
            tasks={tasks}
            diffDuration={diffDuration}
            spentDuration={spentDuration}
            formatDuration={formatDuration}
            estimatedDuration={estimatedDuration}
            projectId={projectId}
            project={project}
          />
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  tasks: getAllTasksFromState(state),
  npts: getProjectNonProductiveTimeFromState(state),
  totalElapsedDuration: getTotalElapsedDuration(state),
  totalPlannedDuration: getTotalPlannedDuration(state),
  dataState: getActionDataStateFromState(
    state,
    REPORT_ACTIONS.PROJECT_REPORT_PAGE_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoad,
  dispatchOnUnload: onUnload,
};

const styles = (theme) => ({
  emptyIcon: {
    fontSize: 200,
    color: theme.palette.action.disabled,
  },
  progressBarLess: {
    backgroundColor: theme.altus.status.report,
  },
  progressBarDisabled: {
    backgroundColor: theme.palette.grey[700],
  },
  progressBarMore: {
    backgroundColor: theme.palette.error.main,
  },
  timeDifference: {
    color: theme.palette.error.main,
  },
  header: {
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      background: 'transparent',
    },
  },
  expansionPanelDetailsRoot: {
    padding: '15px 24px',
  },
  paper: {
    '&:hover $expandIcon': {
      backgroundColor: alpha(theme.palette.common.white, 0.5),
    },
  },
  expandIcon: {},
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ReportContainer);
