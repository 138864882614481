import { fromJS, List } from 'immutable';

import mappers from 'mappers';
import { EMPTY_LIST } from 'app/app.constants';
import teamMappers from 'features/projects/team/team.mappers';
import { TEAM_ACTIONS } from 'features/projects/team/team.constants';

const initialState = fromJS({
  members: EMPTY_LIST,
  allAvailableUsers: EMPTY_LIST,
  allAvailableFieldUsers: EMPTY_LIST,
  fieldCrews: EMPTY_LIST,
  crews: EMPTY_LIST,
  qualifications: EMPTY_LIST,
  teamProjectRoles: EMPTY_LIST,
  data: mappers.Project.initial(),
});

const teamReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TEAM_ACTIONS.DELETE_PROJECT_MEMBER: {
      if (action.error) return state;

      const currentMembers = state.get('members');
      return state.set(
        'members',
        currentMembers.filterNot(
          (member) =>
            member.getIn(['project', 'memberId']) === action.projectMemberId,
        ),
      );
    }

    case TEAM_ACTIONS.RECEIVE_PROJECT_MEMBER:
    case TEAM_ACTIONS.GET_PROJECT_MEMBER: {
      if (action.error || !action.payload) return state;

      const { projectMemberId } = action.payload;

      return state.update('members', (projectMembers) => {
        const index = projectMembers.findIndex(
          (projectMember) =>
            projectMember.getIn(['project', 'memberId']) === projectMemberId,
        );

        return projectMembers.set(
          index,
          teamMappers.ProjectMember.from(action.payload),
        );
      });
    }

    case TEAM_ACTIONS.GET_ALL_AVAILABLE_USERS: {
      if (action.error) return state;

      return state.set(
        'allAvailableUsers',
        List(action.payload).map(mappers.User.from),
      );
    }

    case TEAM_ACTIONS.GET_ALL_AVAILABLE_FIELD_CREW_USERS: {
      if (action.error) return state;

      return state.set(
        'allAvailableFieldUsers',
        List(action.payload).map(mappers.User.from),
      );
    }

    case TEAM_ACTIONS.RECEIVE_PROJECT_MEMBERS: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set(
          'members',
          List(payload).map(teamMappers.ProjectMember.from),
        );
      }
      return state;
    }

    case TEAM_ACTIONS.RECEIVE_PROJECT_FIELD_CREWS: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set(
          'fieldCrews',
          List(payload).map(teamMappers.ProjectFieldCrew.from),
        );
      }
      return state;
    }

    case TEAM_ACTIONS.DELETE_PROJECT_FIELD_CREW: {
      if (action.error) return state;

      const currentMembers = state.get('fieldCrews');
      return state.set(
        'fieldCrews',
        currentMembers.filterNot(
          (member) =>
            member.get('projectMemberFieldCrewId') === action.projectMemberId,
        ),
      );
    }

    case TEAM_ACTIONS.GET_PROJECT_FIELD_QUALIFICATIONS: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set('qualifications', fromJS(payload));
      }
      return state;
    }

    case TEAM_ACTIONS.GET_PROJECT_FIELD_CREWS: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set('crews', fromJS(payload));
      }
      return state;
    }

    case TEAM_ACTIONS.RECEIVE_TEAM_PROJECT_ROLES: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        return state.set('teamProjectRoles', fromJS(payload));
      }
      return state;
    }

    default:
      return state;
  }
};

export default teamReducer;
