import { compose } from 'redux';
import { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import withStyles from '@material-ui/styles/withStyles';

import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { TOOLSTRING_FORMS as FORM } from 'features/projects/tasks/task/toolstring/toolstring.constants';

const TaskToolstringComments = ({
  classes,
  toolstring,
  onUpdateToolstring,
}) => {
  const toolstringJS = useMemo(() => toolstring.toJS(), [toolstring]);

  return (
    <Grid xs item container classes={{ root: classes.root }}>
      <Formik
        onSubmit={onUpdateToolstring}
        initialValues={toolstringJS}
        enableReinitialize
      >
        <Form>
          <AutoSaveFormik>
            <Field
              fullWidth
              maxRows={25}
              margin="none"
              label="Comments"
              multiline={true}
              component={TextFieldFormik}
              placeholder="Add a comment..."
              inputProps={{ maxLength: 1000 }}
              disabled={toolstring.get('isPlanned')}
              name={FORM.EDIT_TOOLSTRING_FORM.TOOLSTRING_COMMENT}
              InputProps={{
                classes: {
                  input: classes.field,
                },
              }}
            />
          </AutoSaveFormik>
        </Form>
      </Formik>
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  field: {
    '&:disabled': {
      color: theme.palette.text.primary,
    },
  },
});

export default compose(withStyles(styles))(TaskToolstringComments);
