import * as THREE from 'three';
import ThreeJsUtils from 'app/threeJs/ThreeJsUtils';
import { PUBLIC_ASSETS_URL } from 'app/app.constants';
import {
  between,
  convertMetric,
} from 'app/components/WellboreTrajectoryDetailed3DView/Utils';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { WellboreSectionType } from 'features/wells/sections/wellboreSection.constants';
import {
  checkIntersection,
  createNewNipple,
} from 'app/components/WellboreContextualization/RenderNipples';
import { createTextSprite } from 'app/components/WellboreContextualization/RenderNippleText';
import themes, { defaultThemeType } from 'layout/themes';

const theme = themes[defaultThemeType];

const upVector = new THREE.Vector3(0, 1, 0);
const startVector = new THREE.Vector3();
const endVector = new THREE.Vector3();
const TrajectoryCylinderPrefix = 'trajectory-cylinder';
const src = `${PUBLIC_ASSETS_URL}/textures/envMap.hdr`;

const cylinderList = [];
const nippleList = [];
const existingSprites = [];

const loaderRGBE = new RGBELoader();
loaderRGBE.setDataType(THREE.UnsignedByteType);
const envMap = loaderRGBE.load(src);
envMap.encoding = THREE.sRGBEncoding;
envMap.minFilter = THREE.NearestFilter;
envMap.magFilter = THREE.LinearFilter;
envMap.wrapS = THREE.ClampToEdgeWrapping;
envMap.wrapT = THREE.ClampToEdgeWrapping;
const loader = new THREE.TextureLoader();
const metalAOMap = loader.load(
  `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_ambientOcclusion.jpg`,
);
const metalDispMap = loader.load(
  `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_height.png`,
);
const metalNormalMap = loader.load(
  `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_normal.jpg`,
);
const metalMap = loader.load(
  `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_basecolor.jpg`,
);
const metalRoughnessMap = loader.load(
  `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_roughness.jpg`,
);
const metalMetalnessMap = loader.load(
  `${PUBLIC_ASSETS_URL}/textures/metal/Metal_006_metallic.jpg`,
);

const Materials = {
  maximumWorkingDepth: new THREE.MeshBasicMaterial({
    color:
      theme.altus.components.ContextualizedWell.trajectory.maximumWorkingDepth,
  }),
  targetDepth: new THREE.MeshBasicMaterial({
    color: theme.altus.components.ContextualizedWell.trajectory.targetDepth,
  }),
  dhsv: new THREE.MeshBasicMaterial({
    color: theme.altus.components.ContextualizedWell.trajectory.dhsv,
  }),
  currentDepth: new THREE.MeshStandardMaterial({
    metalness: 1,
    roughness: 0.5,
    color: theme.altus.components.ContextualizedWell.trajectory.depth,
    map: metalMap,
    aoMap: metalAOMap,
    displacementMap: metalDispMap,
    normalMap: metalNormalMap,
    roughnessMap: metalRoughnessMap,
    metalnessMap: metalMetalnessMap,
    envMapIntensity: 4,
  }),
  grid: new THREE.MeshBasicMaterial({
    color: theme.palette.secondary.darkgrey,
  }),
  base: new THREE.MeshStandardMaterial({
    color: theme.palette.grey[100],
    side: THREE.DoubleSide,
    metalness: 1,
    roughness: 0.4,
    map: metalMap,
    aoMap: metalAOMap,
    displacementMap: metalDispMap,
    normalMap: metalNormalMap,
    roughnessMap: metalRoughnessMap,
    metalnessMap: metalMetalnessMap,
    envMapIntensity: 4,
  }),
  cable: new THREE.MeshStandardMaterial({
    color: theme.altus.components.ContextualizedWell.trajectory.base,
    roughness: 0,
    metalness: 1,
    side: THREE.DoubleSide,
    envMapIntensity: 2,
  }),
};

const WellGraphAndGuagesTrajectoryScene = ({
  zoom,
  theme,
  maxTVD,
  domElement,
  maxNorthEast,
  hideAxisLabels,
  trajectoryPoints,
  hideGrid = false,
  disableOrbitControls = false,
  displayCardinalPoints,
  depthsOfInterest = [],
  wellboreSections,
}) => {
  const scene = new THREE.Scene();
  const cylinderArray = [];

  const light = new THREE.AmbientLight(0x000000, 1);
  scene.add(light);

  const renderer = window.renderer;
  const pmremGenerator = new THREE.PMREMGenerator(renderer);
  pmremGenerator.compileEquirectangularShader();
  const envMapPMREM = createPMREM(pmremGenerator, envMap);
  scene.environment = envMapPMREM;

  const { camera, orbitControls } = setupCamera({
    domElement,
    maxNorthEast: maxNorthEast * 1.33,
    maxTVD,
    zoom,
    disableOrbitControls,
    scene,
    trajectoryPoints,
  });

  if (!hideGrid) {
    const {
      grid,
      gridLength,
      tickInterval: gridTickInterval,
    } = ThreeJsUtils.createGrid({
      max: maxNorthEast < 100 ? maxNorthEast * 100 : maxNorthEast,
    });
    scene.add(grid);

    if (displayCardinalPoints) {
      const cardinalPointsLabels = createCardinalPointsLabels({
        gridLength,
        theme,
      });

      cardinalPointsLabels.forEach((label) => {
        scene.add(label);
      });
    }

    const yAxis = createYAxis({ maxTVD });
    scene.add(yAxis);

    if (!hideAxisLabels) {
      const yAxisLabels = createYAxisLabels({
        maxTVD,
        theme,
        tickInterval: gridTickInterval,
      });
      yAxisLabels.forEach((label) => {
        scene.add(label);
      });
    }
  }

  trajectoryPoints.forEach((previousPoint, index) => {
    const currentPoint = trajectoryPoints.get(index + 1);

    // Bottom reached
    if (!currentPoint) return false;

    wellboreSections.forEach((value) => {
      if (
        between(
          convertMetric(
            previousPoint.getIn(['measuredDepth', 'value']),
            previousPoint.getIn(['measuredDepth', 'unit']),
          ),
          convertMetric(
            value.getIn(['top', 'value']),
            value.getIn(['top', 'unit']),
          ),
          convertMetric(
            value.getIn(['bottom', 'value']),
            value.getIn(['bottom', 'unit']),
          ),
        ) &&
        value.get('type') !== WellboreSectionType.OPEN_HOLE
      ) {
        cylinderList.push({
          key: convertMetric(
            previousPoint.getIn(['measuredDepth', 'value']),
            previousPoint.getIn(['measuredDepth', 'unit']),
          ),
          value: {
            previousPoint,
            maxTVD,
            currentPoint,
            index,
            thetaStart: 0,
            radius:
              convertMetric(
                value.getIn(['innerDiameter', 'value']),
                value.getIn(['innerDiameter', 'unit']),
              ) * 100,
          },
        });
      }
      for (const nippleValue of value.get('wellboreSectionNipples').values()) {
        if (
          nippleValue.get('isNipple') &&
          between(
            convertMetric(
              nippleValue.getIn(['top', 'value']),
              nippleValue.getIn(['top', 'unit']),
            ),
            convertMetric(
              previousPoint.getIn(['measuredDepth', 'value']),
              previousPoint.getIn(['measuredDepth', 'unit']),
            ),
            convertMetric(
              currentPoint.getIn(['measuredDepth', 'value']),
              currentPoint.getIn(['measuredDepth', 'unit']),
            ),
          )
        ) {
          const index = nippleList.findIndex(
            (nip) =>
              nip.key ===
              convertMetric(
                nippleValue.getIn(['top', 'value']),
                nippleValue.getIn(['top', 'unit']),
              ),
          );

          if (index === -1) {
            nippleList.push({
              key: convertMetric(
                nippleValue.getIn(['top', 'value']),
                nippleValue.getIn(['top', 'unit']),
              ),
              value: {
                startVector,
                endVector,
                length: convertMetric(
                  nippleValue.get('length').roundedValue,
                  nippleValue.get('length').unit,
                ),
                radius:
                  convertMetric(
                    nippleValue.get('innerDiameter').roundedValue,
                    nippleValue.get('innerDiameter').unit,
                  ) * 100,
                outerRadius:
                  convertMetric(
                    value.get('innerDiameter').roundedValue,
                    value.get('innerDiameter').unit,
                  ) * 99,
                thetaStart: 0,
                name: `nipple-${convertMetric(
                  nippleValue.getIn(['top', 'value']),
                  nippleValue.getIn(['top', 'unit']),
                )}`,
                material: Materials.cable,
                spriteText: nippleValue.get('type'),
                top: convertMetric(
                  nippleValue.getIn(['top', 'value']),
                  nippleValue.getIn(['top', 'unit']),
                ),
              },
            });
          }
        }
      }
    });

    cylinderList.forEach((cylinder) => {
      const object = createTrajectoryCylinder(cylinder.value);
      scene.add(object);
      cylinderArray.push(object);
    });

    cylinderArray.forEach((cylinder) => {
      if (!cylinder.name.startsWith('trajectory')) return;

      nippleList.forEach((nipple) => {
        if (
          between(
            nipple.key,
            cylinder.data.measuredDepthStart,
            cylinder.data.measuredDepthEnd,
          )
        ) {
          const nippleInScene = scene.getObjectByName(
            `nipple-${nipple.value.top}`,
          );
          if (!nippleInScene) {
            const diff =
              nipple.value.top -
              (cylinder.data.measuredDepthStart +
                cylinder.data.measuredDepthEnd) /
                2;
            const newNipple = createNewNipple(nipple.value);
            newNipple.name = nipple.value.name;
            newNipple.position.y += nipple.value.length / 2;
            cylinder.add(newNipple);
            newNipple.translateY(diff);
            newNipple.rotateX(Math.PI / 2);
            cylinder.updateMatrixWorld();

            const text = createTextSprite(nipple.value.spriteText, 40);
            newNipple.add(text);
            text.position.y += nipple.value.outerRadius + 20;
            if (existingSprites) {
              for (const existingSprite of existingSprites) {
                if (
                  checkIntersection(text, existingSprite) &&
                  text.name !== existingSprite.name
                ) {
                  text.position.set(
                    text.position.x,
                    text.position.y + existingSprite.scale.y / 2,
                    text.position.z,
                  );
                }
              }
            }
            existingSprites.push(text);
          }
        }
      });
    });

    const marker = maybeCreateMarker({
      previousPoint,
      currentPoint,
      depthsOfInterest,
    });

    if (marker) {
      scene.add(marker);
    }
  });

  return { scene, camera, domElement, orbitControls };
};

const setupCamera = ({
  domElement,
  maxNorthEast,
  maxTVD,
  zoom = 2,
  disableOrbitControls = false,
}) => {
  const clientWidth = domElement.clientWidth;
  const clientHeight = domElement.clientHeight;

  const camera = new THREE.PerspectiveCamera(
    50,
    clientWidth / clientHeight,
    0.1,
    (maxNorthEast + maxTVD) * 4,
  );

  camera.up = upVector;
  camera.position.z = zoom * maxNorthEast;
  camera.position.y = 2 * maxTVD;

  const orbitControls = new OrbitControls(camera, domElement);

  orbitControls.maxDistance =
    maxNorthEast < 100
      ? (zoom + 2) * maxNorthEast * 100
      : (zoom + 2) * maxNorthEast;
  orbitControls.enablePan = true;
  orbitControls.screenSpacePanning = true;
  orbitControls.enableKeys = false;
  orbitControls.enabled = !disableOrbitControls;

  return { camera, orbitControls };
};

const createYAxis = ({ maxTVD }) => {
  startVector.set(0, 0, 0);
  endVector.set(0, maxTVD, 0);

  const yLine = ThreeJsUtils.createCylinder({
    v1: startVector,
    v2: endVector,
    material: Materials.grid,
    radius: 3,
  });

  return yLine;
};

const createYAxisLabels = ({ maxTVD, theme, tickInterval }) => {
  const yTicks = ThreeJsUtils.getTicks({ max: maxTVD, tickInterval });
  const { fontFamily } = theme;

  return yTicks.map((tick) => {
    const yAxisLabel = ThreeJsUtils.createTextSprite({
      text: tick,
      fontFamily,
    });

    yAxisLabel.position.set(-50, maxTVD - tick, -50);

    return yAxisLabel;
  });
};

const createCardinalPointsLabels = ({ gridLength, theme }) => {
  const { fontFamily } = theme;
  const middle = gridLength / 2.0;

  const northLabel = ThreeJsUtils.createTextSprite({ text: 'N', fontFamily });
  northLabel.position.z = -middle;

  const southLabel = ThreeJsUtils.createTextSprite({ text: 'S', fontFamily });
  southLabel.position.z = middle;
  southLabel.position.x = 0;

  const westLabel = ThreeJsUtils.createTextSprite({ text: 'W', fontFamily });
  westLabel.position.z = 0;
  westLabel.position.x = -middle;

  const eastLabel = ThreeJsUtils.createTextSprite({ text: 'E', fontFamily });
  eastLabel.position.z = 0;
  eastLabel.position.x = middle;

  return [northLabel, southLabel, westLabel, eastLabel];
};

const createTrajectoryCylinder = ({
  previousPoint,
  currentPoint,
  maxTVD,
  thetaStart,
  radius,
}) => {
  startVector.set(
    convertMetric(
      previousPoint.getIn(['eastWest', 'value']),
      previousPoint.getIn(['eastWest', 'unit']),
    ),
    maxTVD -
      convertMetric(
        previousPoint.getIn(['verticalDepth', 'value']),
        previousPoint.getIn(['verticalDepth', 'unit']),
      ),
    convertMetric(
      previousPoint.getIn(['northSouth', 'value']),
      previousPoint.getIn(['northSouth', 'unit']),
    ),
  );

  endVector.set(
    convertMetric(
      currentPoint.getIn(['eastWest', 'value']),
      currentPoint.getIn(['eastWest', 'unit']),
    ),
    maxTVD -
      convertMetric(
        currentPoint.getIn(['verticalDepth', 'value']),
        currentPoint.getIn(['verticalDepth', 'unit']),
      ),
    convertMetric(
      currentPoint.getIn(['northSouth', 'value']),
      currentPoint.getIn(['northSouth', 'unit']),
    ),
  );

  const cylinder = ThreeJsUtils.createSemiCylinder({
    v1: startVector,
    v2: endVector,
    material: Materials.base,
    radius,
    thetaStart,
  });

  const measuredDepthStart = convertMetric(
    previousPoint.getIn(['measuredDepth', 'value']),
    previousPoint.getIn(['measuredDepth', 'unit']),
  );
  const measuredDepthEnd = convertMetric(
    currentPoint.getIn(['measuredDepth', 'value']),
    currentPoint.getIn(['measuredDepth', 'unit']),
  );
  cylinder.name = `${TrajectoryCylinderPrefix}-${measuredDepthStart}`;
  cylinder.data = {
    ...cylinder.data,
    measuredDepth: measuredDepthEnd,
    measuredDepthStart,
    measuredDepthEnd,
  };

  return cylinder;
};

const maybeCreateMarker = ({
  previousPoint,
  currentPoint,
  depthsOfInterest,
}) => {
  const depthOfInterest = depthsOfInterest.find(
    ({ depth }) =>
      depth &&
      depth >=
        convertMetric(
          previousPoint.getIn(['measuredDepth', 'value']),
          previousPoint.getIn(['measuredDepth', 'unit']),
        ) &&
      depth <=
        convertMetric(
          currentPoint.getIn(['measuredDepth', 'value']),
          currentPoint.getIn(['measuredDepth', 'unit']),
        ),
  );

  if (!depthOfInterest) return;

  const depthOfInterestCylinder = ThreeJsUtils.createCylinder({
    v1: startVector,
    v2: endVector,
    length: 10,
    radius: 80,
    material: depthOfInterest.material,
  });

  depthOfInterestCylinder.name = depthOfInterest.name;

  return depthOfInterestCylinder;
};

function createPMREM(pmremGenerator, texture) {
  const envMap = pmremGenerator.fromEquirectangular(texture).texture;
  texture.dispose();
  pmremGenerator.dispose();

  return envMap;
}

export { Materials, TrajectoryCylinderPrefix };

export default WellGraphAndGuagesTrajectoryScene;
