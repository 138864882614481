import useProjectDashboardHubConnection from 'features/projects/dashboard/hooks/useProjectDashboardHubConnection';
import CorrelationDashboard from 'features/projects/dashboard/components/dashboards/logging/components/CorrelationDashboard';
import LoggingDashboardContainer from 'features/projects/dashboard/components/dashboards/logging/LoggingDashboardContainer';

const CorrelationDashboardController = ({
  projectId,
  renderSlots,
  projectDashboardId,
}) => {
  const { curves, setExtremes, registerDataPointsHandler } =
    useProjectDashboardHubConnection(projectId, projectDashboardId);

  return renderSlots(
    <LoggingDashboardContainer
      curves={curves}
      projectId={projectId}
      setExtremes={setExtremes}
      component={CorrelationDashboard}
      registerDataPointsHandler={registerDataPointsHandler}
    />,
  );
};

export default CorrelationDashboardController;
