import { compose } from 'redux';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Dialog, Grid, Button, DialogContent } from '@material-ui/core';
import * as yup from 'yup';

import {
  Tab,
  Tabs,
  withModal,
  ModalHeader,
  ModalActions,
  LoadingButton,
  LoadingOverlay,
} from 'altus-ui-components';


import {
  submitCreateToolstringForTask,
  submitCreateToolstringFromTemplateForTask,
} from 'features/projects/tasks/task/toolstring/toolstring.actions';

import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { MODAL } from 'features/projects/tasks/task/toolstring/toolstring.constants';
import { TOOLSTRING_ACTIONS } from 'features/projects/tasks/task/toolstring/toolstring.constants';
import { getToolstringTemplatesFromState } from 'features/projects/tasks/task/toolstring/toolstring.selectors';
import CreateToolstringForm from 'features/projects/tasks/task/toolstring/components/create/CreateToolstringForm';
import ToolstringTemplatesTable from 'features/projects/tasks/task/toolstring/components/ToolstringTemplatesTable';
import { TOOLSTRING_FORMS as FORM } from 'features/projects/tasks/task/toolstring/toolstring.constants';

const BHA_OPTIONS = {
  NEW: 1,
  TEMPLATE: 2,
};

const bhaValidationSchema = yup.object().shape({
  [FORM.CREATE_TOOLSTRING_FORM.TOOLSTRING_NAME]: yup.string().required('Required'),        
});

class CreateToolstringModal extends PureComponent {
  state = {
    tab: BHA_OPTIONS.NEW,
  };

  onSubmitNew = (toolstring, { setSubmitting, setStatus }) => {
    const { taskId, projectId, dispatchCreateToolstringForTask } = this.props;

    dispatchCreateToolstringForTask(
      projectId,
      taskId,
      toolstring,
      setSubmitting,
      setStatus,
    );
  };

  onSubmitFromTemplate = (toolstringTemplateId) => {
    const { taskId, projectId, dispatchCreateToolstringFromTemplateForTask } =
      this.props;

    dispatchCreateToolstringFromTemplateForTask(
      projectId,
      taskId,
      toolstringTemplateId,
    );
  };

  render() {
    const {
      open,
      taskId,
      classes,
      projectId,
      dataState,
      toggleModal,
      bhaTemplates,
      dispatchGetTemplates,
      projectWellboreDetailId,
    } = this.props;

    return (
      <Dialog
        fullWidth
        open={open}
        maxWidth="xl"
        onClose={toggleModal}
        classes={{ paperScrollPaper: classes.root }}
        TransitionProps={{
          onEnter: dispatchGetTemplates,
        }}
      >
        <ModalHeader title="Add BHA" />
        <Tabs
          value={this.state.tab}
          indicatorColor="primary"
          onChange={(_event, value) => this.setState({ tab: value })}
          centered
        >
          <Tab label="Create new" value={BHA_OPTIONS.NEW} />
          <Tab
            label={`Template (${bhaTemplates.size})`}
            value={BHA_OPTIONS.TEMPLATE}
          />
        </Tabs>
        <Grid container justifyContent="center">
          <Grid item xs={8}>
            {this.state.tab === BHA_OPTIONS.TEMPLATE && (
              <DialogContent>
                <ToolstringTemplatesTable
                  templates={bhaTemplates}
                  onCreate={this.onSubmitFromTemplate}
                />
              </DialogContent>
            )}
          </Grid>
          <Grid item xs={6}>
            {this.state.tab === BHA_OPTIONS.NEW && (
              <Formik onSubmit={this.onSubmitNew} initialValues={{ name: '' }} validationSchema={bhaValidationSchema}>
                {({ handleSubmit, isSubmitting }) => (
                  <Form>
                    <DialogContent>
                      <CreateToolstringForm
                        taskId={taskId}
                        projectId={projectId}
                        projectWellboreDetailId={projectWellboreDetailId}
                      />
                    </DialogContent>
                    <ModalActions>
                      <Button onClick={toggleModal}>Cancel</Button>
                      <LoadingButton
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        loading={isSubmitting}
                      >
                        Create
                      </LoadingButton>
                    </ModalActions>
                  </Form>
                )}
              </Formik>
            )}
          </Grid>
        </Grid>
        <LoadingOverlay dataState={dataState} timeout={0} />
      </Dialog>
    );
  }
}

const styles = () => ({
  root: {
    height: '75vh',
  },
});

export default compose(
  withStyles(styles),
  withModal(MODAL.CREATE_TOOLSTRING_MODAL_ID),
  connect(
    (state) => ({
      bhaTemplates: getToolstringTemplatesFromState(state),
      dataState: getSummarizedDataStateFromState(
        state,
        TOOLSTRING_ACTIONS.REQUEST_TOOLSTRING_TEMPLATES,
      ),
    }),
    {
      dispatchCreateToolstringForTask: submitCreateToolstringForTask,
      dispatchCreateToolstringFromTemplateForTask:
        submitCreateToolstringFromTemplateForTask,
    },
  ),
)(CreateToolstringModal);
