import { Grid } from '@material-ui/core';
import { useCallback, useState } from 'react';

import { useToggle } from 'altus-hooks';
import { Table } from 'altus-ui-components';

import Counter from 'app/components/Counter';
import { EMPTY_LIST, Format } from 'app/app.constants';
import OperationTableRow from 'features/operations/OperationTableRow';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import { formatDate, formatDuration, formatQuantity } from 'utils/format.util';
import ProjectDashboardsDrawer from 'features/projects/dashboard/components/ProjectDashboardsDrawer';

const columns = [
  {
    xs: 1,
    id: 'projectId',
    Header: 'Project',
    accessor: (operation) => operation.getIn(['project', 'projectId']),
  },
  {
    xs: 1,
    id: 'facility',
    Header: 'Facility',
    accessor: (operation) => operation.getIn(['project', 'facilityName']),
  },
  {
    xs: 1,
    id: 'well',
    Header: 'Well',
    accessor: (operation) => operation.getIn(['project', 'wellboreName']),
  },
  {
    xs: 1,
    id: 'objective',
    Header: 'Objective',
    accessor: (operation) => operation.getIn(['project', 'title']),
  },
  {
    xs: 1,
    id: 'task',
    Header: 'Task/Run',
    accessor: (operation) =>
      operation.getIn(['projectExecutionStatus', 'currentTask', 'title']),
    Cell: ({ row }) => {
      const currentTask = row.original.getIn([
        'projectExecutionStatus',
        'currentTask',
      ]);

      if (!currentTask) return null;

      const projectStatus = row.original.getIn(['projectExecutionStatus']);

      return `${currentTask.title} (${projectStatus.currentTaskNumber}/${projectStatus.taskCount})`;
    },
  },
  {
    xs: 2,
    id: 'activity',
    Header: 'Activity',
    accessor: (operation) =>
      operation.getIn(['projectExecutionStatus', 'currentActivity', 'name']),
    Cell: ({ row }) => {
      const currentActivity = row.original.getIn([
        'projectExecutionStatus',
        'currentActivity',
      ]);

      if (!currentActivity) return null;

      const projectStatus = row.original.getIn(['projectExecutionStatus']);

      return (
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <WorkItemStatus status={currentActivity.status} />
          </Grid>
          <Grid item xs={10}>
            {currentActivity.name} ({projectStatus.currentActivityNumber}/
            {projectStatus.activityCount})
          </Grid>
        </Grid>
      );
    },
  },
  {
    xs: 1,
    id: 'speed',
    Header: 'Speed',
    accessor: (operation) => operation.getIn(['speed', 'roundedValue']),
    Cell: ({ row }) => formatQuantity(row.original.get('speed')),
  },
  {
    xs: 1,
    id: 'tension',
    Header: 'Tension',
    accessor: (operation) => operation.getIn(['tension', 'roundedValue']),
    Cell: ({ row }) => formatQuantity(row.original.get('tension')),
  },
  {
    xs: 1,
    id: 'depth',
    Header: 'Depth',
    accessor: (operation) => operation.getIn(['depth', 'roundedValue']),
    Cell: ({ row }) => formatQuantity(row.original.get('depth')),
  },
  {
    xs: 1,
    id: 'startDate',
    Header: 'Start Date',
    accessor: (operation) => operation.getIn(['project', 'startTime']),
    Cell: ({ value }) => formatDate(value, Format.date) || 'N/A',
  },
  {
    xs: 1,
    id: 'duration',
    Header: 'Duration',
    accessor: (operation) => operation.getIn(['project', 'duration']),
    Cell: ({ value }) => {
      if (!value) return 'N/A';

      return (
        <Counter
          isTimerActive
          initialDuration={value}
          renderValue={({ elapsedTime }) => formatDuration(elapsedTime)}
        />
      );
    },
  },
];

const OperationsTableView = ({ operations = EMPTY_LIST }) => {
  const [isDrawerOpened, toggleDrawer] = useToggle();
  const [currentProject, setCurrentProject] = useState();

  const onProjectSelected = useCallback(
    (row) => {
      setCurrentProject(row.get('project'));
      toggleDrawer();
    },
    [toggleDrawer],
  );

  return (
    <Grid item container>
      <Table
        stickyHeader
        columns={columns}
        useGlobalFilter={false}
        items={operations.valueSeq()}
        onRowClick={onProjectSelected}
        TableRowComponent={OperationTableRow}
      />
      <ProjectDashboardsDrawer
        isOpen={isDrawerOpened}
        project={currentProject}
        toggleDrawer={toggleDrawer}
        projectId={currentProject?.get('projectId')}
      />
    </Grid>
  );
};

export default OperationsTableView;
