import { withStyles } from '@material-ui/core';
import { TableHeaderCell } from 'altus-ui-components';

const SimulationResultsTableHeaderCell = ({ classes, ...rest }) => (
  <TableHeaderCell classes={{ root: classes.cell }} {...rest} />
);

const styles = () => ({
  cell: {
    justifyContent: 'center',
  },
});

export default withStyles(styles)(SimulationResultsTableHeaderCell);
