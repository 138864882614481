import { AutocompleteTextField } from 'app/components/form/AutocompleteTextField';
import { findM3ItemsBySerialNumber } from 'features/equipment/equipment.actions';
import { useCallback, useState } from 'react';
import AssetHistoryForm from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';

const AutoCompleteDrumSerialNumberAdapter = ({
  form,
  placeholder = '',
  keepChangeAfterOnBlur = false,
  InputProps,
  autoCompleteValue,
  setAutocompleteValue,
  ...props
}) => {
  const [m3ItemNumber, setM3ItemNumber] = useState(null);

  const setInitialFieldValue = useCallback(
    (form) => (setInputFieldValue) => {
      if (form?.values?.drumItemNumber) {
        setM3ItemNumber(form?.values?.drumItemNumber);
      }
      // for cables referenceNumber
      else if (form?.values?.referenceNumber) {
        setM3ItemNumber(form?.values?.referenceNumber);
      }

      if (form?.values?.serialNo) {
        setInputFieldValue(form?.values?.drumSerialNumber);
      }
    },
    [],
  );
  const getOptionLabel = useCallback(
    (selectedValue) => selectedValue?.serialNumber,
    [],
  );
  const valueSelectorFromAutocomplete = useCallback(
    (selectedValue) => selectedValue?.serialNumber,
    [],
  );
  return (
    <AutocompleteTextField
      form={form}
      disabled={props.disabled}
      keepChangeAfterOnBlur={keepChangeAfterOnBlur}
      autoCompleteValue={autoCompleteValue}
      setAutocompleteValue={setAutocompleteValue}
      formFieldName={AssetHistoryForm.CABLE_SPOOLING_DRUM_SERIAL_NUMBER}
      getOptionLabel={getOptionLabel}
      valueSelectorFromAutocomplete={valueSelectorFromAutocomplete}
      setInitialFieldValue={setInitialFieldValue(form)}
      fetchAutocompleteDataAction={(value) =>
        findM3ItemsBySerialNumber(m3ItemNumber, value)
      }
      placeholder={placeholder}
      id={'auto-complete-serial-number-m3'}
    />
  );
};

export default AutoCompleteDrumSerialNumberAdapter;
