import { useEffect, useState } from 'react';

export const useAutoScroll = () => {
  const [vh, setVh] = useState(window.innerHeight * 0.01);
  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight * 0.01);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight * 0.01);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [vh]);
  const maxHeight = window.innerHeight < 800 ? 45 : 65;
  return [maxHeight, vh];
};
