import { OrderedMap } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import documentMapper from 'features/facilities/documents/facilityDocuments.mappers';
import { FACILITY_DOCUMENTS_ACTIONS } from 'features/facilities/documents/facilityDocuments.constants';

const initialState = EMPTY_MAP;

const facilityDocumentsReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case FACILITY_DOCUMENTS_ACTIONS.RECEIVE_FACILITY_FILE: {
      if (error) return state;

      const { payload } = action;

      return state.set(
        payload.facilityFileId.toString(),
        documentMapper.Document.from(payload),
      );
    }

    case FACILITY_DOCUMENTS_ACTIONS.DELETE_FACILITY_FILE: {
      if (error) return state;

      const { facilityFileId } = action;

      return state.delete(facilityFileId.toString());
    }

    case FACILITY_DOCUMENTS_ACTIONS.RECEIVE_FACILITY_FILES: {
      if (error) return state;

      return OrderedMap(
        payload.map((file) => [
          file.facilityFileId.toString(),
          documentMapper.Document.from(file),
        ]),
      );
    }

    default:
      return state;
  }
};

export default facilityDocumentsReducer;
