import { createSelector } from 'utils/app.util';

const getWellboreRevisionSelector = (state) =>
  state.getIn(['entity', 'wellboreElement']);

export const getWellboreRevisionForWellboreSelector = (wellboreId) =>
  createSelector(getWellboreRevisionSelector, (wellboreElements) =>
    wellboreElements
      .filter((section) => section?.get('wellboreId'))
      .groupBy((section) => section.get('wellboreId').toString())
      .get(wellboreId.toString()),
  );
