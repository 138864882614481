import { List } from 'immutable';

export const hasEmptyIdItem = (data) => {
  // Recursive arrow function to check for empty ids
  const recursiveCheck = (items) =>
    items.some(
      (item) =>
        item.integrityId === '' ||
        item.integrityId === null ||
        (item.categories && recursiveCheck(item.categories)) ||
        (item.details && recursiveCheck(item.details)),
    );

  return recursiveCheck(data);
};

export const getCombinedTopLevelEntries = (data, targetId) => {
  // Helper function to recursively search for the target item or category
  function findTarget(entries) {
    for (const entry of entries) {
      // Check if this entry is the target item
      if (entry.integrityId === targetId) {
        return entry;
      }

      // If not, search in nested categories (if they exist)
      if (entry.categories && entry.categories.length > 0) {
        const found = findTarget(entry.categories);
        if (found) return found;
      }
    }
    return null; // Return null if not found
  }

  // Try to find the target item or category
  const targetItem = findTarget(data);

  if (!targetItem) return List(); // Return an empty Immutable List if not found

  // Combine details and categories of the found target
  const combinedEntries = [
    // this is actually rules
    ...(targetItem.details || []).map((detail) => ({
      integrityId: detail.integrityId,
      integrityDetailId: detail.integrityDetailId,
      title: detail.title,
      integrityDetailType: detail.integrityDetailType,
      userId: detail.userId,
      weight: detail.weight,
      score: detail.score,
      value: detail.value,
    })),
    ...(targetItem.categories || []).map((category) => ({
      integrityId: category.integrityId,
      title: category.title,
      weight: category.weight,
      integrityType: category.integrityType,
      userId: category.userId,
      parentId: category.parentId,
    })),
  ];

  return List(combinedEntries);
};

export const removeEmptyIdItems = (data) => {
  // Filter the items, removing those with an empty or null id
  const filteredData = data
    .filter((item) => item.integrityId !== '' && item.integrityId !== null)
    .map((item) => ({
      ...item,
      // Recursively clean categories and details
      categories: item.categories
        ? removeEmptyIdItems(item.categories).toArray()
        : [],
      details: item.details ? removeEmptyIdItems(item.details).toArray() : [],
    }));

  // Return an Immutable List of the filtered data
  return List(filteredData);
};

export const removeItemsByIntegrityId = (data, idToRemove) => {
  // Filter out items with the specific integrityId and also remove categories and details
  const filteredData = data
    .filter((item) => item.integrityId !== idToRemove) // Remove the item if it matches idToRemove
    .map((item) => ({
      ...item,
      // If categories or details exist, recursively clean them by removing items with idToRemove
      categories: item.categories
        ? removeItemsByIntegrityId(item.categories, idToRemove).toArray() // Convert List to array
        : [],
      details: item.details
        ? removeItemsByIntegrityId(item.details, idToRemove).toArray() // Convert List to array
        : [],
    }));

  // Return an Immutable List of the filtered data, top-level only
  return List(filteredData);
};

export const addItemToCategory = (data, targetId, newItem) => {
  // Ensure the new item has an empty or null id
  if (newItem.integrityId !== '' && newItem.integrityId !== null) {
    throw new Error('New item must have an empty or null id.');
  }

  // Helper function to recursively search and add item
  function recursiveAdd(categories) {
    for (const category of categories) {
      // If the target ID is found, add the new item to the categories array
      if (category.integrityId === targetId) {
        category.categories = category.categories || [];
        category.categories.push(newItem);
        return true; // Item added successfully
      }

      // Recursively search in nested categories
      if (category.categories && recursiveAdd(category.categories)) {
        return true;
      }
    }
    return false; // Target ID not found in this branch
  }

  // Start the recursive search and addition process
  recursiveAdd(data);

  return { data }; // Return the updated data structure and itemAdded flag
};

export const updateTitleForNullId = (list, newTitle) => {
  // Recursive function to traverse and update
  const updateRecursive = (obj) => {
    if (obj.integrityId === null) {
      // Update the object when id is null
      return { ...obj, title: newTitle };
    }

    // Check if it has categories and recursively update them
    if (obj.categories) {
      return {
        ...obj,
        categories: obj.categories.map(updateRecursive),
      };
    }

    // Check if it has rules and recursively update them
    if (obj.rules) {
      return {
        ...obj,
        rules: obj.rules.map(updateRecursive),
      };
    }

    // Return the object if no changes are needed
    return obj;
  };

  // Map through the Immutable List and update the necessary object
  return list.map(updateRecursive);
};
