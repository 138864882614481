import React, { useRef, useCallback, useEffect, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { alpha } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

HighchartsMore(Highcharts);

const TelemetryGauge = ({ registerDataPointsHandler }) => {
  const chartComponent = useRef(null);
  const theme = useTheme();

  const [options] = useState({
    chart: {
      type: 'gauge',
      backgroundColor: alpha(theme.palette.common.black, 0),
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: 'Q - Telemetry Rotation',
      style: {
        color: theme.palette.common.white,
        fontSize: 15,
      },
    },
    subtitle: {
      text: '0 °',
      style: {
        fontSize: 30,
        color: theme.palette.common.white,
        fontWeight: 'bold',
      },
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: {
        backgroundColor: alpha(theme.palette.common.black, 0),
        borderWidth: 0,
      },
    },
    // the value axis
    yAxis: {
      min: 0,
      max: 360,
      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: alpha(theme.palette.common.black, 0),
      lineColor: theme.palette.secondary.cssGray,
      lineWidth: 2,
      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: alpha(theme.palette.common.black, 0),
      labels: {
        enabled: false,
        step: 2,
        rotation: 'auto',
      },
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      tooltip: {
        visible: false,
      },
      dataLabels: {
        enabled: false,
      },
      gauge: {
        dial: {
          radius: '100%',
          backgroundColor: theme.palette.common.white,
          borderColor: alpha(theme.palette.common.black, 0),
          borderWidth: 1,
          baseWidth: 5,
          baseLength: '100%', // of radius
          rearLength: '0%',
        },
        pivot: {
          backgroundColor: theme.palette.common.white,
          radius: 7,
        },
      },
    },
    series: [
      {
        data: [0],
        dataLabels: {
          formatter: function () {
            return null;
          },
        },
        dial: {
          radius: '100%',
          backgroundColor: theme.palette.secondary.cssGray,
          borderColor: theme.palette.secondary.cssGray,
          baseWidth: 2,
          baseLength: '100%', // of radius
          rearLength: '0%',
        },
      },
      {
        data: [90],
        dataLabels: {
          formatter: function () {
            return null;
          },
        },
        dial: {
          radius: '100%',
          backgroundColor: theme.palette.secondary.cssGray,
          borderColor: theme.palette.secondary.cssGray,
          baseWidth: 2,
          baseLength: '100%', // of radius
          rearLength: '0%',
        },
      },
      {
        data: [180],
        dataLabels: {
          formatter: function () {
            return null;
          },
        },
        dial: {
          radius: '100%',
          backgroundColor: theme.palette.secondary.cssGray,
          borderColor: theme.palette.secondary.cssGray,
          baseWidth: 2,
          baseLength: '100%', // of radius
          rearLength: '0%',
        },
      },
      {
        data: [270],
        dataLabels: {
          formatter: function () {
            return null;
          },
        },
        dial: {
          radius: '100%',
          backgroundColor: theme.palette.secondary.cssGray,
          borderColor: theme.palette.secondary.cssGray,
          baseWidth: 2,
          baseLength: '100%', // of radius
          rearLength: '0%',
        },
      },
      {
        name: 'real_series',
        data: [],
        dataLabels: {
          formatter: function () {
            return null;
          },
        },
        symbol: 'square',
        lineWidth: 1,
      },
    ],
  });

  const updateSeriesData = useCallback((data) => {
    const chart = chartComponent.current.chart;

    const matchingSeries = chart.series.filter(
      (series) => series.name === 'real_series',
    );
    chart.setTitle(null, { text: `${Math.floor(data)} °` });
    matchingSeries[0].setData([Math.floor(data)]);

    chart.redraw();
    chart.reflow();
  }, []);

  const onReceiveData = useCallback(
    (data) => {
      updateSeriesData(data.qTelemetryRotation);
    },
    [updateSeriesData],
  );

  useEffect(() => {
    registerDataPointsHandler(onReceiveData);
  }, [onReceiveData, registerDataPointsHandler]);

  return (
    <HighchartsReact
      ref={chartComponent}
      highcharts={Highcharts}
      options={options}
      containerProps={{ style: { height: '100%', width: '100%' } }}
    />
  );
};

export default TelemetryGauge;
