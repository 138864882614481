import { createSelector } from 'utils/app.util';

export const getProjectDocumentsFromState = createSelector(
  (state) => state.getIn(['projects', 'documents', 'documents']),
  (documents) => documents,
);

export const getChecklistsAndProceduresFromState = createSelector(
  (state) => state.getIn(['projects', 'documents', 'checklistsAndProcedures']),
  (documents) => documents,
);
