import { compose } from 'redux';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import Launch from '@material-ui/icons/Launch';
import { Grid, Link } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import {
  onLoad,
  onUnload,
  deleteIncident,
  selectIncident,
} from 'features/projects/incidents/incidents.actions';

import { useHeader } from 'app/hooks/useHeader';
import { Fab } from 'app/components/withTooltip';
import { ProjectPermission } from 'app/app.constants';
import { getActionDataStateFromState } from 'app/app.selectors';
import HasProjectPermission from 'app/components/HasProjectPermission';
import IncidentsTable from 'features/projects/incidents/IncidentsTable';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { TASK_URLS } from 'features/projects/activities/activities.constants';
import { INCIDENTS_ACTIONS } from 'features/projects/incidents/incidents.constants';
import { getIncidentsFromState } from 'features/projects/incidents/incidents.selectors';
import EditIncidentModal from 'features/projects/incidents/components/EditIncidentModalContainer';
import CreateIncidentModal from 'features/projects/incidents/components/CreateIncidentModalContainer';

const IncidentsContainer = ({
  incidents,
  classes,
  project,
  dataState,
  projectId,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchSelectIncident,
  dispatchDeleteIncident,
}) => {
  useEffect(() => {
    dispatchOnLoad(projectId);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload, projectId]);

  useHeader({
    subTitle: breadcrumb,
  });

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs justifyContent="center">
        <Grid item xs={8}>
          <Grid
            item
            container
            justifyContent="flex-end"
            style={{ marginBottom: 10 }}
          >
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={TASK_URLS.REGISTER_INCIDENT_SYNERGY}
              title="Register Incident (SYNERGI)"
            >
              Register incident (synergi)
              <Launch />
            </Link>
          </Grid>
          <IncidentsTable
            incidents={incidents}
            projectId={projectId}
            editIncident={dispatchSelectIncident}
            deleteIncident={dispatchDeleteIncident}
          />
        </Grid>
      </Grid>
      <HasProjectPermission permissions={ProjectPermission.CREATE_INCIDENTS}>
        <CreateIncidentModal project={project.toJS()}>
          <Fab color="primary" title="New Incident" className={classes.create}>
            <AddIcon />
          </Fab>
        </CreateIncidentModal>
      </HasProjectPermission>
      <HasProjectPermission permissions={ProjectPermission.EDIT_INCIDENTS}>
        <EditIncidentModal project={project.toJS()} />
      </HasProjectPermission>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  incidents: getIncidentsFromState(state),
  dataState: getActionDataStateFromState(
    state,
    INCIDENTS_ACTIONS.PROJECT_INCIDENTS_PAGE_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoad,
  dispatchOnUnload: onUnload,
  dispatchSelectIncident: selectIncident,
  dispatchDeleteIncident: deleteIncident,
};

const styles = (theme) => ({
  create: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(IncidentsContainer);
