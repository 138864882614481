import { fromJS, List } from 'immutable';

import { EMPTY_LIST } from 'app/app.constants';
import { PROJECT_ASSET_TEST_ACTIONS } from 'features/projects/assets/assetTest.constants';
import assetTestMappers from 'features/projects/assets/assetTest.mappers';

const initialState = fromJS({
  data: EMPTY_LIST,
});

const assetTestReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PROJECT_ASSET_TEST_ACTIONS.PROJECT_ASSET_TEST_PAGE_LOADED: {
      if (action.error) return state;

      const result = state.set(
        'data',
        List(action.payload).map(assetTestMappers.ASSETTEST.from),
      );

      return result;
    }

    default:
      return state;
  }
};

export default assetTestReducer;
