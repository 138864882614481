import { compose } from 'redux';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Grid } from '@material-ui/core';
import { PureComponent } from 'react';
import { reduxForm } from 'redux-form/immutable';

import { BasePage } from 'altus-ui-components';

import {
  systemRoleFormOnChange,
  initializeSystemRoleForm,
  systemRoleAccessRightsOnLoad,
} from 'authorization/authorization.actions';

import {
  SYSTEM_ROLE_FORM,
  systemPermissionGroups,
} from 'authorization/authorization.constants';

import {
  getSystemRoleFromState,
  getSystemAndProjectPermissionsFromState,
  getSystemAndProjectRolePermissionsFromState,
} from 'authorization/authorization.selectors';

import { EMPTY_MAP, EMPTY_SET } from 'app/app.constants';
import RoleForm from 'features/settings/accessRoles/RoleForm';
import RoleAccessSummary from 'features/settings/accessRoles/RoleAccessSummary';

class SystemRoleOverview extends PureComponent {
  componentDidMount() {
    const { systemRoleId, dispatchOnLoad } = this.props;

    dispatchOnLoad(systemRoleId);
  }

  componentDidUpdate({ systemRole: previousSystemRole }) {
    const {
      initialized,
      systemRole: currentSystemRole,
      dispatchInitializeSystemRoleForm,
    } = this.props;

    if (!currentSystemRole) return;

    if (!initialized || currentSystemRole !== previousSystemRole) {
      dispatchInitializeSystemRoleForm(currentSystemRole);
    }
  }

  render() {
    const {
      systemRole,
      systemAndProjectPermissions,
      systemAndProjectRolePermissions,
    } = this.props;

    return (
      <BasePage>
        <Grid container item xs={12} md={6}>
          <Grid container direction="column">
            <RoleForm
              form={SYSTEM_ROLE_FORM}
              isProtected={systemRole.get('isProtected')}
            />
            <RoleAccessSummary
              permissions={systemAndProjectPermissions}
              permissionGroups={systemPermissionGroups}
              rolePermissions={systemAndProjectRolePermissions}
            />
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

SystemRoleOverview.defaultProps = {
  systemRole: EMPTY_MAP,
  systemAndProjectPermissions: EMPTY_SET,
  systemAndProjectRolePermissions: EMPTY_SET,
};

export default compose(
  connect(
    (state, { systemRoleId }) => ({
      systemRole: getSystemRoleFromState(state, systemRoleId),
      systemAndProjectPermissions: getSystemAndProjectPermissionsFromState(
        state,
      ),
      systemAndProjectRolePermissions: getSystemAndProjectRolePermissionsFromState(
        state,
        systemRoleId,
      ),
    }),
    {
      dispatchOnLoad: systemRoleAccessRightsOnLoad,
      dispatchInitializeSystemRoleForm: initializeSystemRoleForm,
    },
  ),
  reduxForm({
    form: SYSTEM_ROLE_FORM.ID,
    onChange: debounce(systemRoleFormOnChange, 1500),
  }),
)(SystemRoleOverview);
