import routePaths from 'app/routePaths';
import { useHeader } from 'app/hooks/useHeader';
import SystemRoutesContainer from 'app/components/SystemRoutesContainer';

const DataExchangeContainer = ({ routes }) => {
  useHeader({
    logoNavigation: routePaths.dataExchange.root,
  });

  return <SystemRoutesContainer routes={routes} />;
};

export default DataExchangeContainer;
