import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EMPTY_MAP } from 'app/app.constants';
import * as actions from 'features/projects/tasks/task/simulation/simulation.actions';
import {
  ACTIONS,
  SimulationState,
  SimulationDirection,
} from 'features/projects/tasks/task/simulation/simulation.constants';
import { getCurrentProject } from 'features/projects/projects.selectors';
import {
  getTaskByIdFromState,
  getTaskToolstringsFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';
import {
  getSimulationFromState,
  getSimulationsForTaskFromState,
  createSimulationResultsByDirectionSelector,
} from 'features/projects/tasks/task/simulation/simulation.selectors';
import useDataState from 'app/hooks/useDataState';

export const useInitializeExecutionSimulationContainer = (
  projectId,
  taskId,
  simulationId,
) => {
  const dispatch = useDispatch();
  const task = useSelector((state) => getTaskByIdFromState(state, taskId));
  const project = useSelector(getCurrentProject);
  const simulation = useSelector((state) =>
    getSimulationFromState(state, simulationId),
  );
  const simulations = useSelector(
    (state) => getSimulationsForTaskFromState(state, taskId) ?? EMPTY_MAP,
  );
  // when it is normal simulation
  const simulationResults =
    useSelector(createSimulationResultsByDirectionSelector(simulationId)) ??
    EMPTY_MAP;
  // when it is simulation with set/retreive tool
  const simulationResultsRih =
    useSelector(
      createSimulationResultsByDirectionSelector(
        simulationId,
        SimulationDirection.RUN_IN_HOLE,
      ),
    ) ?? EMPTY_MAP;
  const simulationResultsPooh =
    useSelector(
      createSimulationResultsByDirectionSelector(
        simulationId,
        SimulationDirection.PULL_OUT_OF_HOLE,
      ),
    ) ?? EMPTY_MAP;

  const toolStrings = useSelector((state) =>
    getTaskToolstringsFromState(state, taskId),
  );
  const loadingState = useDataState([
    ACTIONS.LOAD_SIMULATION,
    ACTIONS.REQUEST_UPDATE_SIMULATION,
    ACTIONS.DOWNLOAD_SIMULATION_PDF_REPORT,
  ]);
  const runSimulationState = useDataState(ACTIONS.REQUEST_RUN_SIMULATION);

  // Fetch simulations for task
  useEffect(() => {
    if (projectId) {
      dispatch(
        actions.loadSimulations(projectId, taskId, [
          SimulationState.PLANNED,
          SimulationState.UPDATED_PLANNED,
        ]),
      );
    }

    // Reset simulations list on unload
    return () => {
      dispatch(actions.receiveSimulationsForTask([]));
    };
  }, [projectId, taskId, dispatch]);

  // Fetch selected simulation
  useEffect(() => {
    if (projectId && taskId && simulationId) {
      dispatch(actions.loadSimulation(projectId, taskId, simulationId));
    }
  }, [projectId, taskId, simulationId, dispatch]);

  return {
    task,
    project,
    simulation,
    simulations,
    simulationResults,
    toolStrings,
    cable: simulation?.get('cable'),
    loadingState,
    runSimulationState,
    simulationResultsRih,
    simulationResultsPooh,
  };
};
