import { Link } from 'react-router-dom';

import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import withStyles from '@material-ui/styles/withStyles';

import routePaths from 'app/routePaths';
import { Button } from 'app/components/withTooltip';

const IntegrityActions = ({ classes, riskPrincipleType }) => {
  return (
    <Grid
      xs
      item
      container
      spacing={2}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item>
        <Link
          to={{
            pathname: routePaths.settings.integrity.new,
            state: { riskPrincipleType: riskPrincipleType },
          }}
          style={{ textDecoration: 'none' }}
        >
          <Button size="medium" variant="contained" color="primary">
            <AddIcon fontSize="small" className={classes.leftIcon} />
            ADD
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

export default withStyles(styles)(IntegrityActions);
