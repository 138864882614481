/*eslint no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]*/
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import Highcharts from 'highcharts';
import { useCallback, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';

import { updateSeriesData } from 'utils/dashboard.util';
import { convertCurvesToOffsetConfiguration } from 'features/projects/dashboard/components/dashboards/logging/helpers/common';
import { introduceOffsetOnX } from 'features/projects/dashboard/components/dashboards/logging/common/LoggingDashboard/helpers/utils';

const LoggingDashboard = ({
  data,
  chart,
  options,
  curves,
  registerDataPointsHandler,
}) => {
  const onReceiveInitialData = useCallback(
    (readings) => {
      const offsetConfiguration = convertCurvesToOffsetConfiguration(curves);
      let readingsWithOffset = introduceOffsetOnX(
        readings,
        offsetConfiguration,
      );
      updateSeriesData(chart, readingsWithOffset, (reading, serie) => {
        const data = reading.data;

        if (!data.length) return;

        // TODO: Sort data in backend if necessary
        const sorted = sortBy(data, ([_timestamp, depth, _value]) => depth);

        serie.setData(sorted, false);
      });
    },
    [chart, curves],
  );

  const onReceiveData = useCallback(
    (readings) => {
      const offsetConfiguration = convertCurvesToOffsetConfiguration(curves);
      let readingsWithOffset = introduceOffsetOnX(
        readings,
        offsetConfiguration,
      );
      updateSeriesData(chart, readingsWithOffset, (reading, serie) => {
        const data = reading.data[0];

        if (!data) return;

        serie.addPoint(data, false);
      });
    },
    [chart, curves],
  );

  useEffect(() => {
    registerDataPointsHandler(onReceiveInitialData, onReceiveData);
  }, [onReceiveData, onReceiveInitialData, registerDataPointsHandler]);

  useEffect(() => {
    if (!data) return;

    onReceiveInitialData(data);
  }, [data, onReceiveInitialData]);

  return (
    <HighchartsReact
      ref={chart}
      allowChartUpdate
      options={options}
      highcharts={Highcharts}
    />
  );
};

LoggingDashboard.propTypes = {
  registerDataPointsHandler: PropTypes.func.isRequired,
};

export default LoggingDashboard;
