import { Field } from 'formik';
import { compose } from 'redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import { memo } from 'react';

import { Box, Grid, Typography, CircularProgress } from '@material-ui/core';

import { hostileFluidToDisplayName } from 'features/projects/wellbore/details/projectWellboreDetails.mappers';

import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';

const HostileFluidsFields = ({
  disabled,
  isLoading,
  addedHostileFluids,
  availableHostileFluids = {},
}) => {
  if (isLoading) {
    return (
      <Grid
        container
        padding={2}
        component={Box}
        alignItems="center"
        justifyContent="center"
      >
        <Grid component={CircularProgress} item size={20} />
      </Grid>
    );
  }

  if (addedHostileFluids.isEmpty()) {
    return (
      <Grid
        container
        padding={2}
        component={Box}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption">No hostile fluids added</Typography>
      </Grid>
    );
  }

  return addedHostileFluids.entrySeq().map(([key, value]) => {
    const hostileFluidId = availableHostileFluids[key];

    const hostileFluidName = hostileFluidId
      ? hostileFluidToDisplayName(hostileFluidId)
      : key;

    const type = isNumber(value) ? 'number' : undefined;

    return (
      <Grid item xs={3} key={key}>
        <Field
          type={type}
          disabled={disabled}
          label={hostileFluidName}
          component={TextFieldFormik}
          name={`hostileFluids.${key}`}
        />
      </Grid>
    );
  });
};

HostileFluidsFields.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  availableHostileFluids: PropTypes.object,
  addedHostileFluids: PropTypes.instanceOf(Map).isRequired,
};

export default compose(memo)(HostileFluidsFields);
