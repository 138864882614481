/* eslint-disable */
/** disabling eslint to ignore exhaustive deps check as we don't need
 * those variables as dependencies since it does not affect the execution
 * of the hook.
 */
import * as THREE from 'three';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import { QuantityShape } from 'utils/app.util';
import ZoomIn from '@material-ui/icons/ZoomIn';
import { Box, Button } from '@material-ui/core';
import ZoomOut from '@material-ui/icons/ZoomOut';
import withStyles from '@material-ui/styles/withStyles';
import {
  ToolStringGroupName,
  renderCable,
} from 'app/components/WellGraphAndGuages/RenderCableAndBHAScene';
import { useThreeJsManager } from 'app/threeJs/ThreeJsManagerProvider';
import { useCallback, useEffect, useState, memo, useMemo } from 'react';
import WellGraphAndGuagesTrajectoryScene, {
  Materials,
  TrajectoryCylinderPrefix,
} from 'app/components/WellGraphAndGuages/WellGraphAndGuagesTrajectoryScene';
import { convertMetric } from 'app/components/WellboreTrajectoryDetailed3DView/Utils';
import {
  EMPTY_LIST,
  EMPTY_MAP,
  EMPTY_QUANTITY,
  PUBLIC_ASSETS_URL,
} from 'app/app.constants';
import NavigationHelpTooltip from 'app/components/WellboreContextualization/NavigationHelpTooltip';
import WellboreTrajectoryLegends from 'app/components/WellboreTrajectory/WellboreTrajectoryLegends';
import { useTheme } from '@material-ui/core/styles';

const WellGraphAndGuagesTrajectory = ({
  classes,
  sceneKey,
  hideAxisLabels,
  hideLegends = false,
  hideHelp = false,
  targetDepth,
  cDepth,
  hideGrid = false,
  disableOrbitControls = false,
  trajectory = EMPTY_MAP,
  wellboreDetail = EMPTY_MAP,
  wellboreSections,
  BHA_LENGTH,
  toolsByToolstringToolId,
}) => {
  const theme = useTheme();
  const [domElement, setDomElement] = useState();
  const [zoomEnabled, setZoomEnabled] = useState(false);
  const [camera, setCamera] = useState();
  const [controls, setControls] = useState();
  const [scene, setScene] = useState();
  const [cameraPosition, setCameraPosition] = useState();
  const [help, setHelp] = useState();
  const threeJsManager = useThreeJsManager();

  const maxTVD = trajectory.get('verticalDepth');
  const trajectoryPoints = trajectory.get('trajectoryPoints', EMPTY_LIST);
  const maxNorthEast = trajectory.get('maximumNorthEast');

  const rootRef = useCallback((node) => {
    if (node) {
      setDomElement(node);
    }
  }, []);

  const dhsvDepth = wellboreDetail.get('downHoleSafetyValveDepth');
  const maximumWorkingDepth = wellboreDetail.get('maximumWorkingDepth');

  const [currentDepth, setCurrentDepth] = useState(EMPTY_QUANTITY);

  useEffect(() => {
    setCurrentDepth(cDepth);
  }, [cDepth]);

  const depthsOfInterest = useMemo(
    () => [
      {
        depth: dhsvDepth?.value,
        name: 'DHSV',
        unit: dhsvDepth?.unit,
        material: Materials.dhsv,
        color: theme.altus.components.ContextualizedWell.trajectory.dhsv,
      },
      {
        depth: targetDepth?.value,
        name: 'Target depth',
        unit: targetDepth?.unit,
        material: Materials.targetDepth,
        color: theme.altus.components.ContextualizedWell.trajectory.targetDepth,
      },
      {
        depth: maximumWorkingDepth?.value,
        name: 'Max working depth',
        unit: maximumWorkingDepth?.unit,
        material: Materials.maximumWorkingDepth,
        color:
          theme.altus.components.ContextualizedWell.trajectory
            .maximumWorkingDepth,
      },
    ],
    [dhsvDepth, maximumWorkingDepth, targetDepth, theme],
  );

  const trajectoryScene = useMemo(() => {
    // The scene gets created only when we get the trajectory
    if (!domElement || !maxNorthEast || !trajectoryPoints.size || !maxTVD) {
      return;
    }

    return WellGraphAndGuagesTrajectoryScene({
      theme,
      domElement,
      hideAxisLabels,
      depthsOfInterest,
      trajectoryPoints,
      maxTVD: convertMetric(maxTVD.value, maxTVD.unit),
      maxNorthEast: convertMetric(maxNorthEast.value, maxNorthEast.unit),
      hideGrid,
      disableOrbitControls,
      wellboreSections,
    });
  }, [
    theme,
    maxTVD,
    hideGrid,
    domElement,
    maxNorthEast,
    hideAxisLabels,
    depthsOfInterest,
    trajectoryPoints,
    disableOrbitControls,
    wellboreSections,
  ]);

  useEffect(() => {
    if (!trajectoryScene || !threeJsManager) return;

    const { addScene, disposeScene } = threeJsManager;

    disposeScene(sceneKey);
    const { scene, camera, orbitControls } = trajectoryScene;
    setScene(scene);
    setCamera(camera);
    setControls(orbitControls);
    setCameraPosition(camera.position);
    addScene(sceneKey, trajectoryScene);

    // The scene will be disposed whenever this component gets unmounted.
    // For example, if an operation gets "filtered away", then this will be unmounted and the effect will cleanup the scene resources.
    return () => disposeScene(sceneKey);
  }, [threeJsManager, sceneKey, trajectoryScene]);

  useEffect(() => {
    if (
      !maxTVD ||
      !currentDepth.value ||
      !trajectoryPoints.size ||
      !threeJsManager
    )
      return;

    const { updateScene } = threeJsManager;

    const CableAndBHAUpdater = (sceneInfo) => {
      const { scene } = sceneInfo;

      let object = scene.getObjectByName(ToolStringGroupName);
      scene.remove(object);
      object = null;

      const toolstring = renderCable({
        trajectoryPoints,
        maxTVD,
        toolsByToolstringToolId,
        BHA_LENGTH,
        currentDepth,
      });

      scene.add(toolstring);
    };

    updateScene(sceneKey, CableAndBHAUpdater);
  }, [
    threeJsManager,
    sceneKey,
    currentDepth,
    trajectoryPoints,
    maxTVD,
    toolsByToolstringToolId,
    BHA_LENGTH,
  ]);

  useEffect(() => {
    // Then, whenever we get a new currentDepth, we just re-paint the necessary cylinders, but no need to recreate the scene.
    if (!threeJsManager) return;

    const { updateScene } = threeJsManager;

    const trajectoryColorUpdater = (sceneInfo) => {
      const { scene } = sceneInfo;

      scene.traverse((cylinder) => {
        // Each cylinder has a name property set with a prefix. We can use that prefix to get all of the trajectory cylinders in the scene.
        if (!cylinder.name.startsWith(TrajectoryCylinderPrefix)) return;

        // We also store what's the "measuredDepth" that the cylinder is representing. Based on that and the current depth, we choose
        // which color it should use.
        cylinder.material =
          cylinder.data.measuredDepth <=
          convertMetric(currentDepth?.value, currentDepth?.unit)
            ? Materials.currentDepth
            : Materials.base;
        cylinder.material.side = THREE.DoubleSide;
      });
    };

    updateScene(sceneKey, trajectoryColorUpdater);
  }, [threeJsManager, trajectoryScene, sceneKey, currentDepth]);

  const legends = useMemo(
    () => [
      {
        depth: currentDepth?.value,
        name: 'Current depth',
        unit: currentDepth?.unit,
        color: theme.altus.components.ContextualizedWell.trajectory.depth,
      },
      ...depthsOfInterest,
    ],
    [currentDepth, depthsOfInterest, theme],
  );

  useEffect(() => {
    if (!scene || !camera || !controls || !scene.getObjectByName('BHA')) {
      setZoomEnabled(false);
      return;
    }

    const object = scene.getObjectByName('BHA');
    if (zoomEnabled) {
      setCameraPosition(camera.position.clone());
      camera.position.set(
        object.position.x,
        object.position.y,
        object.position.z + 150,
      );
      camera.lookAt(object.position);
      controls.enabled = false;
    } else {
      camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
      if (object) camera.lookAt(object.position);
      controls.enabled = true;
      controls.update();
    }
  }, [zoomEnabled]);

  useEffect(() => {
    prepareHelpData();
  }, []);

  const prepareHelpData = () => {
    setHelp({
      title: 'HOW TO INTERACT WITH 3D WELL?',
      data: [
        {
          navType: '1- Dragging around the well:',
          content: 'Use simple dragging to go around the well',
          src: `${PUBLIC_ASSETS_URL}/images/navigation/navigate.png`,
        },
        {
          navType: '2- Zoom in and out:',
          content: (
            <ul>
              <li>
                Touchpad: Use two fingers and move them closer to zoom in
                further to zoom out
              </li>
              <li>
                Mouse: Scroll forward to zoom in and backward to zoom out.
              </li>
            </ul>
          ),
          src: `${PUBLIC_ASSETS_URL}/images/navigation/zoomIn.png`,
        },
        {
          navType: '3- Navigate up and down:',
          content:
            'Use control key and simple dragging to go up and down on the well.',
          src: `${PUBLIC_ASSETS_URL}/images/navigation/ctrl.png`,
        },
      ],
    });
  };

  useEffect(() => {
    if (
      scene &&
      camera &&
      currentDepth.hasValue &&
      zoomEnabled &&
      scene.getObjectByName('BHA')
    ) {
      const object = scene.getObjectByName('BHA');
      camera.position.set(
        object.position.x,
        object.position.y,
        object.position.z + 150,
      );
      camera.lookAt(object.position);
    }
  }, [scene, camera, currentDepth, zoomEnabled]);

  return (
    <>
      <Button
        className={classes.button}
        onClick={() => setZoomEnabled(!zoomEnabled)}
        variant={zoomEnabled ? 'contained' : 'outlined'}
      >
        {zoomEnabled ? <ZoomOut /> : <ZoomIn />}
      </Button>
      <Box
        ref={rootRef}
        className={classes.root}
        onClick={(event) => {
          // Do not propagate click events that happen on the scene (like when clicking to orbit around)
          if (!disableOrbitControls) {
            event.stopPropagation();
          }
        }}
      >
        {!hideHelp && (
          <Box className={classes.help}>
            {help && <NavigationHelpTooltip data={help} />}
          </Box>
        )}
        {!hideLegends && (
          <Box className={classes.legend}>
            <WellboreTrajectoryLegends legends={legends} />
          </Box>
        )}
      </Box>
    </>
  );
};

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'inline-block',
    position: 'relative',
  },
  legend: {
    position: 'absolute',
    left: theme.spacing(1),
    bottom: theme.spacing(1),
  },
  button: {
    position: 'absolute',
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    zIndex: 1,
  },
  help: {
    position: 'absolute',
    top: theme.spacing(5),
    left: theme.spacing(1),
  },
});

WellGraphAndGuagesTrajectory.propTypes = {
  sceneKey: PropTypes.number,
  targetDepth: QuantityShape,
  currentDepth: QuantityShape,
  hideLegends: PropTypes.bool,
  trajectory: PropTypes.instanceOf(Iterable),
  wellboreDetail: PropTypes.instanceOf(Iterable),
};

export default compose(memo, withStyles(styles))(WellGraphAndGuagesTrajectory);
