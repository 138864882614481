import makeStyles from '@material-ui/styles/makeStyles';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';

export const Status = {
  SUCCESS: 1,
  WARNING: 2,
  ERROR: 3,
};

const StatusIndicator = ({ title, status, color, ...rest }) => {
  const classes = useStyles({ status, color });

  return (
    <FiberManualRecord
      titleAccess={title}
      fontSize="medium"
      classes={{
        root: classes.root,
      }}
      {...rest}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fill: ({ status, color }) => {
      if (color) return color;

      switch (status) {
        case Status.SUCCESS:
          return theme.altus.components.SmLocation.statusIndicator.ping;
        case Status.WARNING:
          return theme.altus.components.SmLocation.statusIndicator.noPing;
        case Status.ERROR:
          return theme.altus.components.SmLocation.statusIndicator.error;
        default:
          return theme.altus.components.SmLocation.statusIndicator.unknown;
      }
    },
  },
}));

export default StatusIndicator;
