import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useCallback, useEffect, useRef, useMemo } from 'react';

import { updateSeriesData } from 'utils/dashboard.util';
import themes, { defaultThemeType } from 'layout/themes';
import DockManagerIFrame from 'features/projects/dashboard/components/DynamicDockManager/DockManagerIFrame';
import winchHighchartsTheme from 'features/projects/dashboard/components/dashboards/winch/winch.highcharts.theme';

const theme = themes[defaultThemeType];

const fontColor = theme.palette.common.white;
const capFontSize = theme.typography.caption.fontSize;

const validSetExtremesTriggers = ['navigator', 'rangeSelectorButton'];

const LoggingNavigatorChart = ({
  curves,
  onSetExtremes,
  registerDataPointsHandler,
}) => {
  const chartComponent = useRef(null);
  const onReceiveInitialData = useCallback(
    (readings) =>
      updateSeriesData(chartComponent, readings, (reading, serie) =>
        serie.setData(
          reading.data,
          false, // do not redraw, updateSeriesData will handle that
        ),
      ),
    [],
  );

  const onReceiveData = useCallback(
    (readings) =>
      updateSeriesData(chartComponent, readings, (reading, serie) => {
        const data = reading.data[0];

        if (!data) return;

        serie.addPoint(
          data,
          false, // do not redraw, updateSeriesData will handle that
        );
      }),
    [],
  );

  const chartOptions = useMemo(
    () =>
      Highcharts.merge(false, winchHighchartsTheme, {
        chart: {
          height: 250,
        },
        lang: {
          noData: null,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          line: {
            keys: ['x', 'depth', 'y'],
          },
        },
        series: curves.toArray().map((curve) => ({
          lineWidth: 0,
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
          id: curve.get('id'),
          name: curve.get('caption'),
          type: curve.get('type'),
          color: curve.get('color'),
          unit: curve.get('unit'),
        })),
        tooltip: {
          enabled: false,
        },
        yAxis: {
          height: 0,
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
        },
        navigator: {
          enabled: true,
          height: 160,
          xAxis: {
            ordinal: false,
            width: '95%',
            lineWidth: 1,
            tickInterval: 3_600_000, // 1 hour
          },
          yAxis: {
            softMin: 0,
            reversed: true,
            labels: {
              enabled: true,
              align: 'right',
            },
          },
          series: {
            fillOpacity: 0.0,
            lineWidth: 10,
          },
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false,
          },
          events: {
            setExtremes: (event) => {
              if (!validSetExtremesTriggers.includes(event.trigger)) return;

              const min = event.min > event.max ? event.dataMin : event.min;
              const max = event.max < event.min ? event.dataMax : event.max;

              onSetExtremes(min, max);
            },
          },
        },
        rangeSelector: {
          buttons: [
            {
              count: 1,
              type: 'minute',
              text: '1M',
            },
            {
              count: 5,
              type: 'minute',
              text: '5M',
            },
            {
              count: 10,
              type: 'minute',
              text: '10M',
            },
            {
              count: 1,
              type: 'hour',
              text: '1H',
            },
            {
              type: 'hour',
              count: 3,
              text: '3H',
            },
            {
              type: 'hour',
              count: 6,
              text: '6H',
            },
          ],
          floating: true,
          inputEnabled: false,
          buttonPosition: {
            align: 'right',
            x: -10,
            y: -180, // Negative y moves it up, positive y moves it down.
          },
          verticalAlign: 'bottom',
          labelStyle: {
            color: fontColor,
            fontSize: capFontSize,
          },
        },
      }),
    [curves, onSetExtremes],
  );

  useEffect(() => {
    registerDataPointsHandler(onReceiveInitialData, onReceiveData);
  }, [onReceiveData, onReceiveInitialData, registerDataPointsHandler]);

  return (
    <DockManagerIFrame>
      <HighchartsReact
        allowChartUpdate
        ref={chartComponent}
        highcharts={Highstock}
        options={chartOptions}
        constructorType="stockChart"
      />
    </DockManagerIFrame>
  );
};

LoggingNavigatorChart.propTypes = {
  onSetExtremes: PropTypes.func.isRequired,
  registerDataPointsHandler: PropTypes.func.isRequired,
};

export default LoggingNavigatorChart;
