export const LOGIN_PAGE_LOADED = 'LOGIN_PAGE_LOADED';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const START_LOGIN = 'START_LOGIN';

export const INTERNET_EXPLORER = 'IE';

export const DOWNLOAD_LINKS = {
  CHROME: 'https://www.google.com/chrome/',
  FIREFOX: 'https://www.mozilla.org/en-US/firefox/new/',
  EDGE: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
  SAFARI: 'https://support.apple.com/downloads/safari',
};
