import { compose } from 'redux';
import { Box, Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { useSelector, connect } from 'react-redux';
import { useEffect, useCallback, useRef, useState } from 'react';

import { useModal } from 'altus-modal';

import {
  onUnload,
  searchEquipmentTemplates,
} from 'features/equipment/equipment.actions';

import { EMPTY_STRING } from 'app/app.constants';
import { MODAL } from 'features/equipment/equipment.constants';
import { getEquipmentTemplatesFromState } from 'features/equipment/equipment.selectors';
import { Filters } from 'features/equipment/toolAssemblies/components/ToolAssembliesFilter';
import EquipmentTemplatesTable from 'features/equipment/equipmentTemplates/components/EquipmentTemplatesTable';
import EquipmentTemplatesFilter from 'features/equipment/equipmentTemplates/components/EquipmentTemplatesFilter';
import EditEquipmentTemplatesModalContainer from 'features/equipment/equipmentTemplates/editEquipmentTemplates/EditEquipmentTemplatesModalContainer';
import AddEquipmentTemplatesModalContainer from 'features/equipment/equipmentTemplates/addEquipmentTemplates/AddEquipmentTemplatesModalContainer';

const defaultFormValues = {
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
};

const EquipmentTemplatesContainer = ({
  classes,
  dispatchOnUnload,
  dispatchOnSearch,
}) => {
  useEffect(() => {
    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnUnload]);

  const [addEquipmentTemplateModal, toggleAddEquipmentTemplateModal] = useModal(
    MODAL.ADD_EQUIPMENT_TEMPLATE,
  );

  const [editEquipmentTemplateModal, toggleEditTemplateTemplateModal] =
    useModal(MODAL.EDIT_EQUIPMENT_TEMPLATE);

  const [initialFilterValues] = useState(defaultFormValues);

  const formRef = useRef(null);

  const submitForm = () => {
    formRef.current?.submitForm();
  };

  const [templateIdForEdit, setTemplateIdForEdit] = useState(null);
  const [templateForEdit, setTemplateForEdit] = useState(null);

  const equipmentTemplates = useSelector(getEquipmentTemplatesFromState);

  const handleSearch = useCallback(
    (filterValues) => {
      dispatchOnSearch({
        [Filters.TEXT_SEARCH]: filterValues[Filters.TEXT_SEARCH],
      });
    },
    [dispatchOnSearch],
  );

  const onEditTemplate = (templateId, template) => {
    setTemplateIdForEdit(templateId);
    setTemplateForEdit(template);
    toggleEditTemplateTemplateModal();
  };

  const handleModalToggle = () => {
    setTemplateIdForEdit(null);
    setTemplateForEdit(null);
    submitForm();
    toggleEditTemplateTemplateModal();
  };

  return (
    <Grid container>
      <Grid item xs={12} container component={Box} direction="column">
        <Grid item className={classes.filterContainer}>
          <EquipmentTemplatesFilter
            ref={formRef}
            onSubmit={handleSearch}
            initialValues={initialFilterValues}
            toggleAddEquipmentTemplateModal={toggleAddEquipmentTemplateModal}
          />
        </Grid>
        <Grid item container className={classes.tableContainer}>
          <EquipmentTemplatesTable
            equipmentTemplates={equipmentTemplates}
            onEditTemplate={onEditTemplate}
          />
        </Grid>
      </Grid>
      <AddEquipmentTemplatesModalContainer
        openModal={addEquipmentTemplateModal}
        toggleModal={toggleAddEquipmentTemplateModal}
      />
      {editEquipmentTemplateModal && (
        <EditEquipmentTemplatesModalContainer
          openModal={editEquipmentTemplateModal}
          toggleModal={handleModalToggle}
          templateId={templateIdForEdit}
          template={templateForEdit}
        />
      )}
    </Grid>
  );
};

const styles = () => ({
  filterContainer: {
    height: 'fit-content',
    width: '40%',
  },
  tableContainer: {
    flex: 1,
  },
});

export default compose(
  connect(() => ({}), {
    dispatchOnUnload: onUnload,
    dispatchOnSearch: searchEquipmentTemplates,
  }),
  withStyles(styles),
)(EquipmentTemplatesContainer);
