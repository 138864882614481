import { compose } from 'redux';
import isNil from 'lodash/isNil';
import classNames from 'classnames';
import { Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import Replay from '@material-ui/icons/Replay';
import SkipNext from '@material-ui/icons/SkipNext';
import LastPage from '@material-ui/icons/LastPage';
import WarningIcon from '@material-ui/icons/Warning';
import toJSComponent from 'with-immutable-props-to-js';
import withStyles from '@material-ui/styles/withStyles';
import { Fragment, useCallback, useContext, useEffect, useMemo } from 'react';

import {
  Grid,
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import { ModalHeader } from 'altus-ui-components';

import {
  TaskFailReason,
  COMPLETE_TASK_MODE,
  COMPLETE_TASK_FORM,
  ACTIVITIES_ACTIONS,
} from 'features/projects/activities/activities.constants';

import { required } from 'utils/validation.util';
import useDataState from 'app/hooks/useDataState';
import LoadingButton from 'app/components/LoadingButton';
import { completeTask } from 'features/projects/activities/activities.actions';
import DropDownButton from 'features/projects/activities/components/DropDownButton';
import FailureReasonFieldFormik from 'features/projects/activities/components/FailureReasonFieldFormik';
import { ExecutionRootContainerContext } from 'features/projects/execution/ExecutionRootContainer';
import EditorFormik from 'app/components/form/formik/EditorFormik';

const CompleteTaskModalContainerActions = ({
  tasks,
  valid,
  taskId,
  classes,
  nextTask,
  onSubmit,
  projectId,
  isSubmitting,
  objectivesMet,
}) => {
  const completeTaskDataState = useDataState(
    ACTIVITIES_ACTIONS.COMPLETE_TASK_DEFAULT,
  );

  const completeEndTaskDataState = useDataState(
    ACTIVITIES_ACTIONS.COMPLETE_TASK_AND_END_OPERATION,
  );

  const completeRepeatTaskDataState = useDataState(
    ACTIVITIES_ACTIONS.COMPLETE_AND_REPEAT_TASK,
  );

  const completeTaskWithContingencyDataState = useDataState(
    ACTIVITIES_ACTIONS.COMPLETE_TASK_WITH_CONTINGENCY,
  );

  const contingencyTasks = tasks
    .filter((task) => task.contingencyForTaskId)
    .filter((task) => task.contingencyForTaskId.toString() === taskId);

  const nonContingencyTasks = tasks.filter(
    (task) => task.contingencyForTaskId === null,
  );

  const lastNonContingencyTask = nonContingencyTasks.filter(
    (task) => task.id === nextTask?.contingencyForTaskId,
  );

  const indexOflastNonContingencyTask = nonContingencyTasks.indexOf(
    lastNonContingencyTask[0],
  );

  const nextNonContingencyTask =
    nonContingencyTasks[indexOflastNonContingencyTask + 1];

  const index = indexOflastNonContingencyTask + 1;

  return (
    <DialogActions className={classes.dialogActions}>
      <Grid container justifyContent="center" spacing={2}>
        <Typography variant="body1" className={classes.nextActionsHeader}>
          What happens next?
        </Typography>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            {!objectivesMet && (
              <Grid item>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !valid}
                  dataState={completeRepeatTaskDataState}
                  onClick={() => onSubmit(projectId, COMPLETE_TASK_MODE.REPEAT)}
                >
                  <Replay className={classes.leftIcon} />
                  Repeat task
                </LoadingButton>
              </Grid>
            )}
            {/* hide on end operation, when there are no more tasks  */}
            {nextTask?.sequence <= tasks.length && (
              <Grid item>
                <LoadingButton
                  color={objectivesMet ? 'primary' : 'default'}
                  variant="contained"
                  disabled={isSubmitting || !valid}
                  onClick={() => onSubmit(projectId)}
                  dataState={completeTaskDataState}
                >
                  <SkipNext className={classes.leftIcon} />
                  {nextTask?.title || 'Start next task'}
                </LoadingButton>
              </Grid>
            )}
            {/* show on end operation, when there are no more tasks  */}
            {!nextTask?.sequence && (
              <Grid item>
                <LoadingButton
                  variant="contained"
                  disabled={isSubmitting || !valid}
                  dataState={completeEndTaskDataState}
                  onClick={() =>
                    onSubmit(projectId, COMPLETE_TASK_MODE.END_OPERATION)
                  }
                >
                  Complete project
                  <LastPage className={classes.rightIcon} />
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Grid>
        {contingencyTasks[0] && (
          <>
            <Grid container item justifyContent="center" alignItems="center">
              <Typography>
                <i>or select contingency</i>
              </Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <DropDownButton
                  color="primary"
                  variant="contained"
                  options={contingencyTasks}
                  renderFn={(task) => task.title}
                  disabled={isSubmitting || !valid}
                  defaultOption={contingencyTasks[0]}
                  Icon={<SkipNext className={classes.leftIcon} />}
                  MainButtonProps={{
                    dataState: completeTaskWithContingencyDataState,
                  }}
                  onClick={(task) =>
                    onSubmit(
                      projectId,
                      COMPLETE_TASK_MODE.CONTINUE_WITH_CONTINGENCY,
                      task.id,
                    )
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
        {index > 0 && (
          <Grid item>
            <LoadingButton
              color={objectivesMet ? 'primary' : 'default'}
              variant="contained"
              disabled={isSubmitting || !valid}
              onClick={() =>
                onSubmit(
                  projectId,
                  COMPLETE_TASK_MODE.CONTINUE_WITH_CONTINGENCY,
                  nextNonContingencyTask.id,
                )
              }
              dataState={completeTaskDataState}
            >
              <SkipNext className={classes.leftIcon} />
              {nextNonContingencyTask.customTitle || 'Start next task'}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </DialogActions>
  );
};

const CompleteTaskForm = ({
  valid,
  tasks,
  values,
  taskId,
  classes,
  nextTask,
  projectId,
  currentTask,
  isSubmitting,
  setFieldValue,
  objectivesMet,
  setSubmitting,
}) => {
  const dispatch = useDispatch();
  const connection = useContext(ExecutionRootContainerContext);

  const tasksWithoutContingencies = tasks.filter(
    (task) => task.contingencyForTaskId === null,
  );

  const current = tasks.find((task) => task.taskId.toString() === taskId);

  if (current.contingencyCount) {
    var currentTaskindex = tasksWithoutContingencies.indexOf(current);
    nextTask = tasksWithoutContingencies[currentTaskindex + 1];
  }

  const dispatchCompleteTask = useCallback(
    (projectId, mode, contingencyTaskId) => {
      if (!values.objectivesMet) {
        values[COMPLETE_TASK_FORM.OBJECTIVES_MET] = objectivesMet;
      }
      dispatch(
        completeTask(
          projectId,
          mode,
          contingencyTaskId,
          values,
          connection,
          setSubmitting,
        ),
      );
    },
    [values, objectivesMet, dispatch, connection, setSubmitting],
  );

  const failureReason = values[COMPLETE_TASK_FORM.FAILURE_REASON];

  const commentsDisabled = objectivesMet
    ? isSubmitting
    : isSubmitting ||
      isNil(failureReason) ||
      failureReason === TaskFailReason.NONE;

  useEffect(() => {
    switch (failureReason) {
      case TaskFailReason.NONE:
        setFieldValue(COMPLETE_TASK_FORM.COMMENTS, null);
        break;
      default:
        break;
    }
  }, [setFieldValue, failureReason]);

  return (
    <Fragment>
      <DialogContent className={classes.dialogContent}>
        <Grid container justifyContent="center">
          <Grid container justifyContent="center">
            <Typography variant="h6">{currentTask.title}</Typography>
          </Grid>
          <Grid container justifyContent="center">
            <Typography
              variant="body1"
              className={classNames({
                [classes.objectivesMet]: objectivesMet,
                [classes.objectivesNotMet]: !objectivesMet,
              })}
            >
              {objectivesMet
                ? 'Completed successfully - Congratulations!'
                : 'Did not complete as planned'}
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <WarningIcon className={classes.warningIcon} />
            <Typography variant="body1" className={classes.warningIcon}>
              {'This will lock the Reporting and Data Acquisition'}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <Grid container justifyContent="center" spacing={0}>
              {!objectivesMet && (
                <Field
                  required
                  validate={required}
                  disabled={isSubmitting}
                  component={FailureReasonFieldFormik}
                  name={COMPLETE_TASK_FORM.FAILURE_REASON}
                />
              )}
              <Grid item xs={12}>
                <Field
                  autoFocus
                  label="Comments"
                  disabled={commentsDisabled}
                  component={EditorFormik}
                  name={COMPLETE_TASK_FORM.COMMENTS}
                  xs={12}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <CompleteTaskModalContainerActions
        tasks={tasks}
        valid={valid}
        taskId={taskId}
        classes={classes}
        nextTask={nextTask}
        projectId={projectId}
        isSubmitting={isSubmitting}
        objectivesMet={objectivesMet}
        onSubmit={dispatchCompleteTask}
      />
    </Fragment>
  );
};

const CompleteTaskModalContainer = ({
  tasks,
  isOpen,
  taskId,
  classes,
  nextTask,
  projectId,
  toggleModal,
  currentTask,
  objectivesMet,
}) => {
  const initialValues = useMemo(
    () => ({
      [COMPLETE_TASK_FORM.COMMENTS]: null,
      [COMPLETE_TASK_FORM.TASK_ID]: taskId,
      [COMPLETE_TASK_FORM.FAILURE_REASON]: TaskFailReason.NONE,
    }),
    [taskId],
  );

  const validate = useCallback((values) => {
    const errors = {};

    const comments = values[COMPLETE_TASK_FORM.COMMENTS];
    const failureReason = values[COMPLETE_TASK_FORM.FAILURE_REASON];

    switch (failureReason) {
      case TaskFailReason.NONE:
        return errors;
      default: {
        if (!comments) {
          errors[COMPLETE_TASK_FORM.COMMENTS] = required(comments);
        }
      }
    }
    return errors;
  }, []);

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={toggleModal}>
      <ModalHeader
        toggleModal={toggleModal}
        title={objectivesMet ? 'Objectives met' : 'Objectives NOT met'}
      />
      <Formik validateOnMount validate={validate} initialValues={initialValues}>
        {({ isValid, values, setFieldValue, isSubmitting, setSubmitting }) => (
          <CompleteTaskForm
            tasks={tasks}
            valid={isValid}
            values={values}
            taskId={taskId}
            classes={classes}
            nextTask={nextTask}
            projectId={projectId}
            currentTask={currentTask}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            objectivesMet={objectivesMet}
            setSubmitting={setSubmitting}
          />
        )}
      </Formik>
    </Dialog>
  );
};

const styles = (theme) => ({
  formFields: {
    marginTop: 50,
  },
  nextActionsHeader: {
    marginBottom: 8,
  },
  dialogActions: {
    padding: 10,
    overflow: 'hidden',
  },
  objectivesMet: {
    color: theme.altus.status.execute,
  },
  objectivesNotMet: {
    color: theme.palette.error.main,
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

export default compose(
  toJSComponent,
  withStyles(styles),
)(CompleteTaskModalContainer);
