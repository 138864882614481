import { OrderedMap } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import operationsMappers from 'features/operations/operations.mappers';
import { OPERATIONS_ACTIONS } from 'features/operations/operations.constants';

const initialState = EMPTY_MAP;

const cheetahJobReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case OPERATIONS_ACTIONS.RECEIVE_CHEETAH_JOB: {
      if (action.error) return state;

      return state.set(
        payload.id.toString(),
        operationsMappers.CheetahJob.from(payload),
      );
    }

    case OPERATIONS_ACTIONS.RECEIVE_CHEETAH_JOBS: {
      if (action.error) return state;

      return OrderedMap(
        payload.map((cheetahJob) => [
          cheetahJob.id.toString(),
          operationsMappers.CheetahJob.from(cheetahJob),
        ]),
      );
    }

    case OPERATIONS_ACTIONS.CLEAR_OPERATIONS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default cheetahJobReducer;
