import { compose } from 'redux';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import withStyles from '@material-ui/styles/withStyles';
import { isDesktop, MobileView } from 'react-device-detect';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';

import { BasePage } from 'altus-ui-components';
import { useReducerWithDataState } from 'altus-hooks';

import { useHeader } from 'app/hooks/useHeader';
import { Image } from 'app/components/withTooltip';
import operationService from 'services/operation.service';
import { EMPTY_MAP, PUBLIC_ASSETS_URL } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import cheetahJobReducer from 'features/operations/cheetahJob.reducer';
import { receiveCheetahJobs } from 'features/operations/operations.actions';
import { OPERATIONS_ACTIONS } from 'features/operations/operations.constants';
import { getOperationsFromState } from 'features/operations/operations.selectors';
import ProjectDashboardsList from 'features/projects/dashboard/components/ProjectDashboardsList';

const dashboardFilter = (route) => route.isMobile;

const OperationCarouselItem = ({
  classes,
  projectId,
  dataState,
  cheetahJobs,
}) => {
  if (dataState.isLoading()) return null;

  return (
    <Grid container spacing={2} className={classes.content}>
      <Grid item container xs={12} className={classes.firstContainer}>
        <Image
          className={classes.backgroundImage}
          src={`${PUBLIC_ASSETS_URL}/images/well/welltrajectory.png`}
        />
      </Grid>
      <ProjectDashboardsList
        dataState={dataState}
        projectId={projectId}
        filter={dashboardFilter}
        cheetahJobs={cheetahJobs}
      />
    </Grid>
  );
};

const OperationsOverviewContainer = ({
  classes,
  projectId,
  dataState,
  operations,
}) => {
  const selected = operations
    .keySeq()
    .findIndex((k) => k === projectId.toString());

  const [currentProjectId, setCurrentProjectId] = useState(projectId);

  const project = operations.getIn([currentProjectId.toString(), 'project']);

  const title = project?.get('title');
  const wellboreName = project?.get('wellboreName');
  const facilityName = project?.get('facilityName');

  useHeader({
    title: `${facilityName} - ${wellboreName}`,
    subTitle: `${currentProjectId}. - ${title}`,
  });

  const onIndexChange = useCallback(
    (index) => {
      setCurrentProjectId(operations.keySeq().get(index));
    },
    [operations],
  );

  const [getCheetahJobsByProject, onlineCheetahJobs, dispatch] =
    useReducerWithDataState(
      operationService.getCheetahJobsByProject,
      cheetahJobReducer,
      EMPTY_MAP,
    );

  useEffect(() => {
    getCheetahJobsByProject(currentProjectId).then((cheetahJobs) => {
      dispatch(receiveCheetahJobs(cheetahJobs));
    });
  }, [getCheetahJobsByProject, dispatch, currentProjectId]);

  return (
    <BasePage
      dataState={dataState}
      title={isDesktop ? 'This page is visible on Mobile only' : null}
    >
      <MobileView>
        <DeviceOrientation lockOrientation={'portrait'}>
          <Orientation orientation="portrait" alwaysRender={false}>
            <Carousel
              swipe={true}
              index={selected}
              autoPlay={false}
              onChange={onIndexChange}
              navButtonsAlwaysInvisible={true}
            >
              {operations
                .toList()
                .toJS() // required to make Carousel work
                .map((operation, index) => (
                  <OperationCarouselItem
                    key={index}
                    Item={operation} // required to make Carousel work
                    classes={classes}
                    dataState={dataState}
                    projectId={projectId}
                    cheetahJobs={onlineCheetahJobs}
                  />
                ))}
            </Carousel>
          </Orientation>
          <Orientation orientation="landscape" alwaysRender={false}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              direction="column"
            >
              <Grid item>
                <Image
                  className={classes.rotateContainer}
                  src={`${PUBLIC_ASSETS_URL}/images/mobile/rotate_portrait.png`}
                />
                <p style={{ textAlign: 'center' }}>Rotate the device</p>
              </Grid>
            </Grid>
          </Orientation>
        </DeviceOrientation>
      </MobileView>
    </BasePage>
  );
};

const styles = () => ({
  rotateContainer: {
    padding: 5,
  },
  backgroundImage: {
    width: '100%',
    zIndex: -1,
  },
  content: {
    minHeight: '100%',
  },
  firstContainer: {
    height: '28vh',
    position: 'relative',
    overflow: 'visible',
    justify: 'center',
  },
});

export default compose(
  connect((state) => ({
    operations: getOperationsFromState(state),
    dataState: getSummarizedDataStateFromState(
      state,
      OPERATIONS_ACTIONS.SEARCH_OPERATIONS,
    ),
  })),
  withStyles(styles),
)(OperationsOverviewContainer);
