import { fromJS } from 'immutable';

import mappers from 'mappers';
import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';
import { RISK_TABLE_ACTIONS } from 'features/projects/tasks/task/details/taskDetails.constants';

const initialState = fromJS({
  project: mappers.Project.initial(),
  fluids: mappers.ProjectFluids.initial(),
});

const projectDetailsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case PROJECTS_ACTIONS.RECEIVE_PROJECT: {
      if (action.error) return state;
      return state.set('project', mappers.Project.from(action.payload));
    }

    case PROJECTS_ACTIONS.RESET_PROJECT_RESOURCES: {
      return state.set('project', initialState.get('project'));
    }

    case PROJECTS_ACTIONS.GET_PROJECT_FLUIDS: {
      const mapped = mappers.ProjectFluids.from(action.payload);
      return state.set('fluids', mapped);
    }

    case RISK_TABLE_ACTIONS.RECEIVE_PROJECT_RISK_SECTIONS: {
      if (action.error) return state;

      return state.setIn(['project', 'projectRisk'], fromJS(action.payload));
    }

    case RISK_TABLE_ACTIONS.RECEIVE_TASK_RISK_BY_PROJECT: {
      if (action.error) return state;

      return state.setIn(['project', 'taskRisk'], fromJS(action.payload));
    }

    default:
      return state;
  }
};

export default projectDetailsReducer;
