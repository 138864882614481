import isEqual from 'lodash/isEqual';

import mappers from 'mappers';
import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';
import { ACTIVITIES_ACTIONS } from 'features/projects/activities/activities.constants';

const executeIntialState = mappers.Progress.initial();

const executeReducer = (state = executeIntialState, action) => {
  switch (action.type) {
    case ACTIVITIES_ACTIONS.GET_PROJECT_STATUS:
    case ACTIVITIES_ACTIONS.START_OPERATION:
    case ACTIVITIES_ACTIONS.END_OPERATION:
    case ACTIVITIES_ACTIONS.COMPLETE_TASK: {
      if (action.error) return state;

      const payload = mappers.Progress.from(action.payload);

      const currentProjectId = state.get('id');

      if (
        (currentProjectId && currentProjectId !== action.payload.projectId) ||
        isEqual(state, payload)
      ) {
        return state;
      }

      return payload;
    }

    case PROJECTS_ACTIONS.RESET_PROJECT_RESOURCES: {
      return executeIntialState;
    }

    case ACTIVITIES_ACTIONS.GET_PROJECT_STATUS_MOBILE: {
      if (action.error) return state;
      return mappers.Progress.from(action.payload);
    }

    default:
      return state;
  }
};

export default executeReducer;
