import { OrderedMap } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import dashboardMappers from 'features/projects/dashboard/dashboard.mapper';
import { DASHBOARDS_ACTIONS } from 'features/projects/dashboard/dashboard.constants';

const initialState = EMPTY_MAP;

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARDS_ACTIONS.RECEIVE_DASHBOARDS: {
      if (action.error) return state;

      return OrderedMap(
        action.payload.map((dashboard) => [
          dashboard.dashboardId.toString(),
          dashboardMappers.Dashboard.from(dashboard),
        ]),
      );
    }

    default:
      return state;
  }
};

export default dashboardReducer;
