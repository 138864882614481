import { Map, List } from 'immutable';

import mappers from 'mappers';
import { EMPTY_LIST } from 'app/app.constants';
import { FACILITIES_PROJECTS_ACTIONS as ACTIONS } from 'features/facilities/projects/facilitiesProjects.constants';

const initialState = Map({
  data: EMPTY_LIST,
});

const facilitiesProjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FACILITIES_PROJECTS_PAGE_LOADED: {
      if (action.error) return state;

      const [projects] = action.payload;
      return state.set('data', List(projects).map(mappers.Project.from));
    }

    default:
      return state;
  }
};

export default facilitiesProjectsReducer;
