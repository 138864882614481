import classNames from 'classnames';
import loadImage from 'blueimp-load-image';
import withStyles from '@material-ui/styles/withStyles';
import { useCallback, useEffect, useState, useRef } from 'react';

import { Image } from 'app/components/withTooltip';
import { PUBLIC_ASSETS_URL } from 'app/app.constants';
import { SCALES } from 'features/equipment/editToolImage/EditToolImageModal';

const ToolImageContainerWithLining = ({
  tool,
  style,
  title,
  ogTool,
  classes,
  toolName,
  className,
  ImageProps,
  scaleFactor,
  highlighted,
  orientation,
  toolImageUrl,
  TooltipProps,
  onHoverScale,
  onHoverDarken,
  containerStyle,
  assembly = false,
  handleSetValuesFromEdit,
}) => {
  const imageContainerRef = useRef(null);
  const [displayedImageSize, setDisplayedImageSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === imageContainerRef.current) {
          const { width, height } = entry.target.getBoundingClientRect();
          setDisplayedImageSize({ width, height });
        }
      }
    });

    if (imageContainerRef.current) {
      resizeObserver.observe(imageContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const defaultImage = `${PUBLIC_ASSETS_URL}/images/tools/placeholder${
    orientation ?? ''
  }.png`;

  const [image, setImage] = useState(defaultImage);

  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleHiddenImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    setImageDimensions({ width: naturalWidth, height: naturalHeight });
  };

  const setDefaultImage = useCallback(
    () => setImage(defaultImage),
    [defaultImage],
  );

  useEffect(() => {
    let isSubscribed = true;
    if (toolImageUrl) {
      loadImage(toolImageUrl, {
        orientation,
        crossOrigin: true,
      })
        .then((response) => {
          if (isSubscribed) {
            try {
              if (orientation) {
                response.image.toBlob((blob) => {
                  var imageUrl = URL.createObjectURL(blob);
                  if (isSubscribed) {
                    setImage(imageUrl);
                  }
                });
              } else {
                setImage(toolImageUrl);
              }
            } catch (error) {
              setDefaultImage();
            }
          }
        })
        .catch(() => isSubscribed && setDefaultImage());
    }
    return () => (isSubscribed = false);
  }, [orientation, setDefaultImage, toolImageUrl]);

  const containerRef = useRef(null);
  const [_, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const measureContainer = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setContainerSize({ width: clientWidth, height: clientHeight });
      }
    };

    measureContainer();
    window.addEventListener('resize', measureContainer);

    return () => {
      window.removeEventListener('resize', measureContainer);
    };
  }, []);

  const scaledHorizontalLineStyle = {
    height: '4px',
    backgroundColor: 'red',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `${tool.length.value * 1000}px`,
    maxWidth: '1000px',
  };
  const isToolODExceeding = tool.outerDiameter.value > 250;
  const limitedODValue = isToolODExceeding ? 250 : tool.outerDiameter.value;

  const scaledTopHorizontalLineStyle = {
    ...scaledHorizontalLineStyle,
    width: '12%',
    top: `calc(50% - ${limitedODValue / 2}px)`,
  };

  const scaledBottomHorizontalLineStyle = {
    ...scaledHorizontalLineStyle,
    width: '12%',
    top: `calc(50% + ${limitedODValue / 2}px)`,
  };

  const scaledInBetweenVerticalLineStyle = {
    width: '4px',
    height: `${limitedODValue}px`,
    backgroundColor: 'red',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const isToolLengthExceeding = tool.length.value * 1000 > 1000;

  const scaledVerticalLineStartStyle = {
    height: isToolODExceeding ? '250px' : `${tool.outerDiameter.value}px`,
    width: '4px',
    backgroundColor: 'red',
    position: 'absolute',
    left: isToolLengthExceeding
      ? 'calc(50% - 500px)'
      : `calc(50% - ${tool.length.value * 500}px)`,
    top: '50%',
    transform: 'translateY(-50%)',
  };

  const scaledVerticalLineEndStyle = {
    ...scaledVerticalLineStartStyle,
    left: isToolLengthExceeding
      ? 'calc(50% + 500px)'
      : `calc(50% + ${tool.length.value * 500}px)`,
  };

  const calculateScaledImageWidthForDisplay = () => {
    if (tool.length.value * 1000 > 1000) {
      const toolLength = tool.length.value * 1000;
      const ratio = toolLength / imageDimensions.height;
      const newWidth = 1000 / ratio;
      return newWidth;
    } else {
      return imageDimensions.height;
    }
  };

  const calculateScaledImageHeightForDisplay = () => {
    if (tool.outerDiameter.value > 250) {
      const toolWidth = tool.outerDiameter.value;
      const ratio = toolWidth / imageDimensions.width;
      const newWidth = 250 / ratio;
      return newWidth;
    } else {
      return imageDimensions.width;
    }
  };

  const imageContainerStyle = {
    width: `${calculateScaledImageWidthForDisplay()}px`,
    maxWidth: '1200px',
    minWidth: `${calculateScaledImageWidthForDisplay()}px`,
    height: `${calculateScaledImageHeightForDisplay()}px`,
    padding: 0,
    margin: 0,
    boxSizing: 'border-box',
    transform: `scale(${scaleFactor.width}, ${scaleFactor.height})`,
    transformOrigin: 'center center',
  };

  const labelStyle = {
    position: 'absolute',
    color: 'red',
    backgroundColor: 'transparent',
    top: `calc(50% - ${limitedODValue / 2}px - 10px)`,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    fontWeight: '700',
    zIndex: 10,
  };

  const secondLabelStyle = {
    ...labelStyle,
    top: `calc(50% + ${limitedODValue / 2}px + 10px)`,
    left: '50%',
  };

  const calculateScaledDimensions = () => {
    return {
      scaledWidth: displayedImageSize.width * scaleFactor.width,
      scaledHeight: displayedImageSize.height * scaleFactor.height,
    };
  };

  const { scaledWidth, scaledHeight } = calculateScaledDimensions();

  const calculateNewToolLengthRatio = () => {
    const actualLength = tool.length.value * 1000;
    const displayedLengthMax = 1000;
    if (actualLength > displayedLengthMax) {
      const ratio = displayedLengthMax / actualLength;
      const ratioAndScalingRatioHeight =
        scaledWidth / imageDimensions.height / ratio;
      const ratioedDimensions =
        scaledWidth.toFixed(2) * ratioAndScalingRatioHeight;
      const final = ratioedDimensions / (scaledWidth / imageDimensions.height);
      return final / imageDimensions.height;
    } else {
      return scaledWidth / imageDimensions.height;
    }
  };

  const prevToolLengthRatio = useRef();

  useEffect(
    () => {
      const newToolLengthRatio = calculateNewToolLengthRatio();
      if (newToolLengthRatio !== prevToolLengthRatio.current) {
        handleSetValuesFromEdit(SCALES.HORIZONTAL_SCALE, newToolLengthRatio);
        prevToolLengthRatio.current = newToolLengthRatio;
      }
    },
    // eslint-disable-next-line
    [
      scaledWidth,
      imageDimensions.height,
      tool.length.value,
      handleSetValuesFromEdit,
    ],
  );

  //DEBUG PURPOSES
  //   const calculateActualDimensionsAndRatioDisplay = () => {
  //     const actualLength = tool.length.value * 1000;
  //     const displayedLengthMax = 1000;
  //     if (actualLength > displayedLengthMax) {
  //       const ratio = displayedLengthMax / actualLength;
  //       const ratioAndScalingRatioHeight =
  //         scaledWidth / imageDimensions.height / ratio;
  //       const ratioedDimensions =
  //         scaledWidth.toFixed(2) * ratioAndScalingRatioHeight;
  //       const final = ratioedDimensions / (scaledWidth / imageDimensions.height);
  //       return (
  //         <>
  //           <p>Ratio size : {ratio}</p>
  //           <p>Checked width : {final}</p>
  //           <p>Ratio for image: {calculateNewToolLengthRatio()}</p>
  //           <p>
  //             New image size:{' '}
  //             {(final / imageDimensions.height) * imageDimensions.height}
  //           </p>
  //         </>
  //       );
  //     } else {
  //       return <p>{calculateNewToolLengthRatio()}</p>;
  //     }
  //   };

  const calculateNewToolWidthRatio = () => {
    const actualLength = tool.outerDiameter.value;
    const displayedLengthMax = 250;
    if (actualLength > displayedLengthMax) {
      const ratio = displayedLengthMax / actualLength;
      const ratioAndScalingRatioHeight =
        scaledHeight / imageDimensions.width / ratio;
      const ratioedDimensions =
        scaledHeight.toFixed(2) * ratioAndScalingRatioHeight;
      const final = ratioedDimensions / (scaledHeight / imageDimensions.width);
      return final / imageDimensions.width;
    } else {
      return scaledHeight / imageDimensions.width;
    }
  };

  const prevToolWidthRatio = useRef();

  useEffect(
    () => {
      const newToolWidthRatio = calculateNewToolWidthRatio();
      // Only update if the value has changed
      if (
        newToolWidthRatio !== prevToolWidthRatio.current &&
        scaleFactor.height !== 1
      ) {
        handleSetValuesFromEdit(SCALES.VERTICAL_SCALE, newToolWidthRatio);
        prevToolWidthRatio.current = newToolWidthRatio;
      }
    },
    // eslint-disable-next-line
    [
      scaleFactor.height,
      scaledHeight,
      imageDimensions.width,
      tool.outerDiameter.value,
      handleSetValuesFromEdit,
    ],
  );

  // DEBUG PURPOSES
  //   useEffect(() => {
  //     console.log(scaleFactor);
  //   }, [scaleFactor]);

  //   DEBUG PURPOSES
  //   const calculateActualDimensionsAndRatioHeightDisplay = () => {
  //     const actualLength = tool.outerDiameter.value;
  //     const displayedLengthMax = 250;
  //     if (actualLength > displayedLengthMax) {
  //       const ratio = displayedLengthMax / actualLength;
  //       const ratioAndScalingRatioHeight =
  //         scaledHeight / imageDimensions.width / ratio;
  //       const ratioedDimensions =
  //         scaledHeight.toFixed(2) * ratioAndScalingRatioHeight;
  //       const final = ratioedDimensions / (scaledHeight / imageDimensions.width);
  //       return (
  //         <>
  //           <p>Ratio size H : {ratio}</p>
  //           <p>Checked height : {final}</p>
  //           <p>Ratio for image H: {calculateNewToolWidthRatio()}</p>
  //           <p>
  //             New image size:{' '}
  //             {(final / imageDimensions.width) * imageDimensions.width}
  //           </p>
  //         </>
  //       );
  //     } else {
  //       return <p>{calculateNewToolWidthRatio()}</p>;
  //     }
  //   };

  return (
    <div
      ref={containerRef}
      style={containerStyle}
      className={classes.imageContainer}
    >
      {/* DEBUG PURPOSES */}
      {/* <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          color: 'white',
          backgroundColor: 'rgba(0,0,0,0.7)',
          padding: '10px',
          zIndex: 1000,
        }}
      >
        <p>
          Tool length and OD: {tool.length.value * 1000} x{' '}
          {tool.outerDiameter.value}
        </p>
        <p>
          Natural Image Size: {imageDimensions.height} x {imageDimensions.width}
        </p>
        <p>
          Displayed Image Size: {displayedImageSize.width} x{' '}
          {displayedImageSize.height}
        </p>
        <p>
          Scaled Dimensions: {scaledWidth.toFixed(2)} x{' '}
          {scaledHeight.toFixed(2)}
        </p>
        <p>
          Scaling ratio: {scaledWidth / imageDimensions.height} x{' '}
          {scaledHeight / imageDimensions.width}
        </p>
        <p>----------------------------</p>
        <p>{calculateActualDimensionsAndRatioDisplay()}</p>
        <p>----------------------------</p>
        <p>{calculateActualDimensionsAndRatioHeightDisplay()}</p>
        <p>----------------------------</p>
      </div> */}
      <img
        src={toolImageUrl}
        alt="hidden"
        onLoad={handleHiddenImageLoad}
        style={{ display: 'none' }}
      />
      <div ref={imageContainerRef} style={imageContainerStyle}>
        <Image
          src={image}
          style={{ ...style, objectFit: 'fill', height: '100%' }}
          title={title}
          alt={toolName}
          TooltipProps={TooltipProps}
          className={classNames(className, classes.root, {
            [classes.onHighlight]: highlighted && !assembly,
            [classes.onHoverScale]: onHoverScale,
            [classes.onHoverDarken]: onHoverDarken,
            [classes.onHighlightAssembly]: highlighted && assembly,
          })}
          {...ImageProps}
        />
      </div>

      <div style={scaledTopHorizontalLineStyle} />
      <div style={scaledHorizontalLineStyle} />
      <div style={scaledBottomHorizontalLineStyle} />
      <div style={scaledVerticalLineStartStyle} />
      <div style={scaledVerticalLineEndStyle} />
      <div style={scaledInBetweenVerticalLineStyle} />
      <div style={labelStyle}>
        OD - {ogTool.outerDiameter.roundedValue} {ogTool.outerDiameter.unit}
      </div>
      <div style={secondLabelStyle}>
        Length - {ogTool.length.roundedValue} {ogTool.length.unit}
      </div>
    </div>
  );
};

const styles = (theme) => ({
  imageContainer: {
    position: 'relative',
    display: 'flex',
    width: '80%',
    maxWidth: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90%',
  },
  root: {},
  onHighlight: {
    filter: 'brightness(120%)',
    transform: 'scale(2.0)',
    transition: 'transform .3s',
  },
  onHighlightAssembly: {
    filter: 'brightness(120%)',
    transition: 'transform .3s',
  },
  onHoverScale: {
    '&:hover': {
      filter: 'brightness(120%)',
      transform: 'scale(2.0)',
      transition: 'transform .3s',
    },
  },
  onHoverDarken: {
    '&:hover': {
      opacity: theme.palette.action.hoverOpacity,
    },
  },
});

export default withStyles(styles)(ToolImageContainerWithLining);
