import { fromJS } from 'immutable';
import { EMPTY_MAP } from 'app/app.constants';

export const mapChecklistsAndProcedures = (documents) => {
  if (documents.length === 0) return EMPTY_MAP;
  const proceduresAndChecklist = documents;

  const procedures = {
    name: 'Procedures',
    procedures: Object.values(proceduresAndChecklist).filter(
      (procedure) => procedure.procedureType === 1,
    ),
  };

  const checklists = {
    name: 'Checklists',
    procedures: Object.values(proceduresAndChecklist).filter(
      (procedure) => procedure.procedureType === 2,
    ),
  };

  const qhse = {
    name: 'QHSE',
    procedures: Object.values(proceduresAndChecklist).filter(
      (procedure) => procedure.procedureType === 3,
    ),
  };

  return fromJS([procedures, checklists, qhse]);
};

export const mergeArrayFilters = (activeFilter, departmentFilters) => {
  return fromJS([...new Set([...activeFilter, ...departmentFilters])]);
};
