import { useParams } from 'react-router-dom';

import useProject from 'features/projects/hooks/useProject';

/**
 * Hook to get the projectId from the current URL that were matched by the <Route path>, and then select the matching project from state.
 * The consumer needs to be in the context of a react-router Route component to work
 */
const useCurrentProject = () => {
  const { projectId } = useParams();

  return useProject(projectId);
};

export default useCurrentProject;
