import { compose } from 'redux';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import {
  clearWellbores,
  wellboreContainerOnLoad,
} from 'features/wells/wellbore.actions';

import routePaths from 'app/routePaths';
import RoutesContainer from 'app/components/RoutesContainer';
import NavigationAppBar from 'app/components/NavigationAppBar';

const WellboreContainer = ({
  routes,
  fieldId,
  wellboreId,
  dispatchOnLoad,
  dispatchOnUnload,
}) => {
  useEffect(() => {
    dispatchOnLoad(fieldId, wellboreId);

    return () => dispatchOnUnload();
  }, [fieldId, wellboreId, dispatchOnLoad, dispatchOnUnload]);

  return (
    <>
      <RoutesContainer routes={routes} />
      <Switch>
        <Route exact path={routePaths.wells.events} component={null} />
        <NavigationAppBar routes={routes} />
      </Switch>
    </>
  );
};

export default compose(
  connect(null, {
    dispatchOnUnload: clearWellbores,
    dispatchOnLoad: wellboreContainerOnLoad,
  }),
)(WellboreContainer);
