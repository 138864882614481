import { compose } from 'redux';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { Grid } from '@material-ui/core';

import { useRequest } from 'altus-hooks';

import { EMPTY_MAP } from 'app/app.constants';
import FormSection from 'app/components/form/FormSection';
import wellboreDetailsService from 'services/wellboreDetail.service';
import HostileFluidsFields from 'app/components/WellboreDetailsForm/HostileFluidsFields';

const HostileFluidsFormSection = ({ disabled, wellboreDetail }) => {
  const addedHostileFluids = useMemo(
    () =>
      wellboreDetail
        .get('hostileFluids', EMPTY_MAP)
        .filter((hostileFluid) => !isNil(hostileFluid)),
    [wellboreDetail],
  );

  const [availableHostileFluids, hostileFluidsDataState] = useRequest(
    wellboreDetailsService.getAvailableHostileFluids,
  );

  const isLoading = hostileFluidsDataState.isLoading();

  return (
    <FormSection title="Hostile Fluids">
      <Grid container>
        <Grid container spacing={2}>
          <HostileFluidsFields
            disabled={disabled}
            isLoading={isLoading}
            addedHostileFluids={addedHostileFluids}
            availableHostileFluids={availableHostileFluids}
          />
        </Grid>
      </Grid>
    </FormSection>
  );
};

export default compose()(HostileFluidsFormSection);
