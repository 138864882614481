import { Field } from 'formik';
import { InputAdornment } from '@material-ui/core';

import { required } from 'utils/validation.util';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';

import { FluidFormFields as FormFields } from 'utils/fluids/constants.utils';

const BinghamPlasticLiquidFormSection = ({ disabled }) => (
  <>
    <Field
      required
      type="number"
      destroyOnUnmount
      disabled={disabled}
      validate={required}
      label="Plastic Viscosity (PV)"
      component={QuantityTextFieldFormik}
      name={FormFields.PLASTIC_VISCOSITY}
    />
    <Field
      required
      type="number"
      destroyOnUnmount
      disabled={disabled}
      validate={required}
      label="Yield Point (YP)"
      component={TextFieldFormik}
      name={FormFields.YIELD_POINT}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">lbf/100ft²</InputAdornment>
        ),
      }}
    />
  </>
);

export default BinghamPlasticLiquidFormSection;
