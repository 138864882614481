import { compose } from 'redux';
import { memo, useMemo } from 'react';
import CheckBox from '@material-ui/icons/CheckBox';
import withStyles from '@material-ui/styles/withStyles';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';

import {
  Box,
  Grid,
  Button,
  Dialog,
  Typography,
  IconButton,
  DialogContent,
} from '@material-ui/core';

import {
  Table,
  UserAvatar,
  LoadingButton,
  TableRowActionsCell,
} from 'altus-ui-components';

import { useMultiselect } from 'altus-hooks';

import BasePageDense from 'app/components/BasePageDense';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';

const initialState = {
  pageSize: 25,
  sortBy: [
    {
      id: 'name',
      desc: false,
    },
  ],
};

const CreateProjectMembersModal = ({
  users,
  isOpen,
  title,
  project,
  classes,
  dataState,
  toggleModal,
  projectMembers,
  addUsersToProject,
}) => {
  const { isSelected, toggleSelect, selectedItems, clearSelection } =
    useMultiselect();

  const columns = useMemo(
    () => [
      {
        id: 'avatar',
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const user = row.original;

          return (
            <TableRowActionsCell>
              <UserAvatar
                avatar={user.get('avatar')}
                initials={user.get('initials')}
              />
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 4,
        id: 'name',
        Header: 'Name',
        accessor: (user) => user.get('name'),
      },
      {
        xs: 4,
        Header: 'Job title',
        accessor: (user) => user.get('title'),
      },
      {
        xs: 4,
        Header: 'Organization',
        accessor: (user) => user.get('organizationName'),
      },
      {
        id: 'actions',
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const user = row.original;

          const isProjectMember = projectMembers.has(user.get('id').toString());

          if (isProjectMember) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <IconButton
                size="small"
                title={isSelected(user) ? 'Remove' : 'Select to add'}
                color="default"
                variant="outlined"
                onClick={() => toggleSelect(user)}
              >
                {isSelected(user) ? <CheckBox /> : <CheckBoxOutlineBlank />}
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
    ],
    [projectMembers, toggleSelect, isSelected],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      PaperProps={{
        style: {
          maxHeight: '70%',
          height: '70%',
        },
      }}
      open={isOpen}
      onClose={toggleModal}
      TransitionProps={{
        onExited: clearSelection,
      }}
    >
      <ApplicationHeaderBase>
        <Grid container alignItems="center">
          <Grid
            xs
            item
            container
            zeroMinWidth
            alignItems="left"
            direction="column"
            className={classes.dialogActionsHeader}
          >
            <Typography noWrap variant="body2" color="textSecondary">
              {project.get('title')}
            </Typography>
            <Typography item component={Typography} variant="h6">
              {title}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button onClick={toggleModal}>Cancel</Button>
            </Grid>
            <Grid item>
              <LoadingButton
                color="primary"
                variant="contained"
                dataState={dataState}
                disabled={selectedItems.isEmpty()}
                onClick={() => addUsersToProject(selectedItems)}
              >
                {`Add (${selectedItems.size})`}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </ApplicationHeaderBase>
      <BasePageDense dataState={dataState}>
        <DialogContent>
          <Grid
            container
            component={Box}
            paddingTop={3}
            paddingBottom={1}
            justifyContent="center"
          >
            <Grid item xs={11}>
              <Table
                usePagination
                items={users}
                columns={columns}
                initialState={initialState}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </BasePageDense>
    </Dialog>
  );
};

const styles = (theme) => ({
  dialogActionsRoot: {
    padding: theme.spacing(3),
    background: theme.altus.background.header,
  },
});

export default compose(withStyles(styles), memo)(CreateProjectMembersModal);
