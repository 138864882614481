import taskService from 'services/task.service';

import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import { DATA_ACQUISITION_ACTIONS } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import DataAcquisitionMappers from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.mappers';

export const getDataAcquisitionForTask = (projectId, taskId) => (dispatch) => {
  dispatch(getDataAcquisition(projectId, taskId)).then(() => {
    return Promise.all([
      dispatch(getDebrisTypes(projectId, taskId)),
      dispatch(getGreaseTypes(projectId, taskId)),
      dispatch(getGlycolTypes(projectId, taskId)),
      dispatch(getFrictionReducerTypes(projectId, taskId)),
      dispatch(getInjectionMediums(projectId, taskId)),
      dispatch(getProductionMediums(projectId, taskId)),
    ]);
  });
};

export const getDataAcquisition = (projectId, taskId) => (dispatch) => {
  const payload = taskService.getDataAcquisitionForTask(projectId, taskId);
  dispatch({
    type: DATA_ACQUISITION_ACTIONS.GET_DATA_ACQUISITION_BY_TASK,
    payload,
    taskId,
  });

  return payload;
};

export const updateDataAcquisition =
  (projectId, taskId, values) => (dispatch) => {
    const requestObj = DataAcquisitionMappers.DataAcquisition.to(values);

    const payload = taskService
      .updateDataAcquisitionForTask(
        projectId,
        taskId,
        requestObj.dataAcquisitionId,
        requestObj,
      )
      .then(() => dispatch(getDataAcquisition(projectId, taskId)));

    dispatch({
      taskId: taskId,
      payload,
      type: DATA_ACQUISITION_ACTIONS.UPDATE_DATA_ACQUISITION,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'Data Acquisition was successfully updated',
      },
    });

    return payload;
  };

export const removeDataAcquisitionForTask = () => (dispatch) => {
  dispatch({
    type: DATA_ACQUISITION_ACTIONS.DELETE_DATA_ACQUISITION,
  });
};

export const getDebrisTypes = (projectId, taskId) => (dispatch) => {
  const payload = taskService.getDebrisTypes(projectId, taskId);

  dispatch({
    type: DATA_ACQUISITION_ACTIONS.GET_ALL_DEBRIS_TYPES,
    payload,
  });

  return payload;
};

export const getGreaseTypes = (projectId, taskId) => (dispatch) => {
  const payload = taskService.getGreaseTypes(projectId, taskId);

  dispatch({
    type: DATA_ACQUISITION_ACTIONS.GET_ALL_GREASE_TYPES,
    payload,
  });

  return payload;
};

export const getGlycolTypes = (projectId, taskId) => (dispatch) => {
  const payload = taskService.getGlycolTypes(projectId, taskId);

  dispatch({
    type: DATA_ACQUISITION_ACTIONS.GET_ALL_GLYCOL_TYPES,
    payload,
  });

  return payload;
};

export const getFrictionReducerTypes = (projectId, taskId) => (dispatch) => {
  const payload = taskService.getFrictionReducerTypes(projectId, taskId);

  dispatch({
    type: DATA_ACQUISITION_ACTIONS.GET_ALL_FRICTION_REDUCER_TYPES,
    payload,
  });

  return payload;
};

export const getInjectionMediums = (projectId, taskId) => (dispatch) => {
  const payload = taskService.getInjectionMediums(projectId, taskId);

  dispatch({
    type: DATA_ACQUISITION_ACTIONS.GET_ALL_INJECTION_MEDIUMS,
    payload,
  });

  return payload;
};

export const getProductionMediums = (projectId, taskId) => (dispatch) => {
  const payload = taskService.getProductionMediums(projectId, taskId);

  dispatch({
    type: DATA_ACQUISITION_ACTIONS.GET_ALL_PRODUCTION_MEDIUMS,
    payload,
  });

  return payload;
};

export const getExplosives = (projectId, taskId) => (dispatch) => {
  const payload = taskService.getExplosives(projectId, taskId);

  dispatch({
    type: DATA_ACQUISITION_ACTIONS.GET_EXPLOSIVES,
    payload,
  });

  return payload;
};

export const updateExplosives = (projectId, taskId, data) => (dispatch) => {
  const payload = taskService
    .updateExplosives(projectId, taskId, data)
    .then(() => dispatch(getExplosives(projectId, taskId)));

  dispatch({
    payload,
    type: DATA_ACQUISITION_ACTIONS.UPDATE_EXPLOSIVES,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Updating explosives...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Explosives were successfully updated',
    },
  });

  return payload;
};
