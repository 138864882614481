import Agent from 'infrastructure/agent';

const searchFacilities = (filter) => Agent.get('/facilities/search', filter);

const getOperators = (filter) => Agent.get('/facilities/operators', filter);

const getFacilityById = (facilityId) => Agent.get(`/facilities/${facilityId}`);

const getFacilityImage = (facilityId) =>
  Agent.get(`/facilities/image/${facilityId}`);

const getFacilityLocation = (facilityId) =>
  Agent.get(`/facilities/position/${facilityId}`);

const getFacilityDecks = (facilityId) =>
  Agent.get(`/facilities/decks/${facilityId}`);

const facilitiesService = {
  getOperators,
  getFacilityById,
  searchFacilities,
  getFacilityImage,
  getFacilityLocation,
  getFacilityDecks,
};

export default facilitiesService;
