import { PROJECT_ASSET_TEST_ACTIONS } from 'features/projects/assets/assetTest.constants';
import assetHistoryService from 'services/assetTest.service';

export const onAssetTestLoad = (projectId) => (dispatch) =>
  dispatch({
    type: PROJECT_ASSET_TEST_ACTIONS.PROJECT_ASSET_TEST_PAGE_LOADED,
    payload: () => assetHistoryService.GetAssetHistoryByProjectId(projectId),
  });

export const onAssetTestUnload = () => (dispatch) =>
  dispatch({
    type: PROJECT_ASSET_TEST_ACTIONS.PROJECT_ASSET_TEST_PAGE_UNLOADED,
  });
