import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import FlashOn from '@material-ui/icons/FlashOn';
import { Switch, Route } from 'react-router-dom';

import {
  getCurrentSubPage,
  getApplicationHeaderFromState,
} from 'app/app.selectors';

import { toWellbore } from 'utils/route.util';
import { useHeader } from 'app/hooks/useHeader';
import routePaths, { pages } from 'app/routePaths';
import BackButton from 'app/components/BackButton';
import useWellbore from 'features/wells/hooks/useWellbore';
import MenuIcon from 'app/components/ApplicationHeader/MenuIcon';
import LogoAndAppTitle from 'app/components/ApplicationHeader/LogoAndAppTitle';
import UserMenuContainer from 'app/components/ApplicationHeader/UserMenuContainer';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import ApplicationHeaderTitle from 'app/components/ApplicationHeader/ApplicationHeaderTitle';

const WellboreHeader = ({ fieldId, wellboreId }) => {
  const { wellbore } = useWellbore(wellboreId);

  const subPage = useSelector(getCurrentSubPage);
  const applicationHeader = useSelector(getApplicationHeaderFromState);

  const logoNavigation = applicationHeader.get('logoNavigation');

  const defaultPreviousPage = toWellbore(fieldId, wellboreId);

  const wellboreName = wellbore.get('name') ?? '';
  const fieldName = wellbore.get('fieldName') ?? '';

  useHeader({
    logoNavigation: routePaths.wells.root,
    title: `${fieldName} - ${wellboreName}`,
  });

  return (
    <ApplicationHeaderBase>
      <Grid
        container
        spacing={2}
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs>
          <Switch>
            <Route path={routePaths.wells.events}>
              <BackButton to={defaultPreviousPage} />
            </Route>
            <Route>
              <LogoAndAppTitle to={logoNavigation} />
            </Route>
          </Switch>
        </Grid>
        <Grid item xs={8}>
          <ApplicationHeaderTitle />
        </Grid>
        <Grid item xs>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <MenuIcon
                subPage={subPage}
                Icon={<FlashOn />}
                previousPage={defaultPreviousPage}
                defaultPreviousPage={defaultPreviousPage}
                page={{
                  id: pages.wells.events,
                  to: toWellbore(fieldId, wellboreId, routePaths.wells.events),
                }}
              />
            </Grid>
            <Grid item>
              <UserMenuContainer />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ApplicationHeaderBase>
  );
};

export default compose()(WellboreHeader);
