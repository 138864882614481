import withStyles from '@material-ui/styles/withStyles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { Button as NavigationButton } from 'app/components/withNavigation';

const styles = {
  backButtonIcon: {
    marginRight: 5,
  },
};

const BackButton = ({ to, classes, children, ...rest }) => (
  <NavigationButton
    to={to}
    color="secondary"
    variant="contained"
    className={classes.backButton}
    {...rest}
  >
    <ChevronLeft className={classes.backButtonIcon} />
    {children}
  </NavigationButton>
);

BackButton.defaultProps = {
  children: 'Back',
};

export default withStyles(styles)(BackButton);
