import classNames from 'classnames';
import { PureComponent } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Button, MenuItem } from '@material-ui/core';

import { invokeIfFunction } from 'utils/app.util';
import DropdownMenu from 'app/components/DropdownMenu';
import LoadingButton from 'app/components/LoadingButton';

class DropDownButton extends PureComponent {
  constructor(props) {
    super(props);

    const { defaultOption } = props;

    this.state = {
      active: defaultOption,
    };
  }

  render() {
    const {
      Icon,
      classes,
      onClick,
      options,
      renderFn,
      defaultOption,
      MainButtonProps,
      HelperButtonProps,
      ...rest
    } = this.props;

    return (
      <Grid item>
        <LoadingButton
          className={classNames({
            [classes.mainButton]: !!options[1],
          })}
          onClick={() => invokeIfFunction(onClick, this.state.active)}
          {...rest}
          {...MainButtonProps}
        >
          {Icon}
          {this.state.active ? (
            <span>{renderFn(this.state.active)}</span>
          ) : (
            <span>&nbsp;</span>
          )}
        </LoadingButton>
        {options[1] && (
          <DropdownMenu
            Trigger={
              <Button
                className={classes.helperButton}
                {...rest}
                {...HelperButtonProps}
              >
                <ExpandMore />
              </Button>
            }
          >
            {options.map((option, index) => (
              <MenuItem
                onClick={() =>
                  this.setState({
                    active: option,
                  })
                }
                key={index}
                selected={this.state.active === option}
              >
                {renderFn(option)}
              </MenuItem>
            ))}
          </DropdownMenu>
        )}
      </Grid>
    );
  }
}

DropDownButton.defaultProps = {
  options: [],
};

export default withStyles({
  mainButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  helperButton: {
    padding: 6,
    minWidth: 'auto',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
})(DropDownButton);
