import withStyles from '@material-ui/styles/withStyles';
import { Typography, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Table, TableRowActionsCell } from 'altus-ui-components';

import {
  EMPTY_LIST,
  RiskConsequenceEnum,
  RiskMatrix,
  RiskProbabilityEnum,
  RiskStateEnum,
} from 'app/app.constants';
import NavigationLink from 'app/components/NavigationLink';
import { toProjectTasks } from 'utils/route.util';
import { getRichEditorText, sortRisks } from 'utils/app.util';
import { RiskSectionFormFields } from 'features/projects/components/RiskSectionsTable';

const ProjectRiskTableRowDetails = ({
  classes,
  item = EMPTY_LIST,
  projectId,
}) => {
  const riskProbabilities = Object.values(RiskProbabilityEnum);
  const riskConsequences = Object.values(RiskConsequenceEnum);
  const riskState = Object.values(RiskStateEnum);

  const columns = [
    {
      xs: 0,
      id: 'empty',
      Header: <TableRowActionsCell minItems={1} />,
      Cell: () => (
        <TableRowActionsCell>
          <ExpandMoreIcon style={{ display: 'none' }} />
        </TableRowActionsCell>
      ),
    },
    {
      xs: 10,
      id: 'riskDescription',
      Header: 'Risk',
      Cell: ({ row }) => {
        const task = row.original;
        return (
          <NavigationLink
            target="_blank"
            className={classes.taskTitleLink}
            to={toProjectTasks(projectId, task.get('taskId'))}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Typography noWrap variant="inherit">
              {getRichEditorText(task.get('description'))}
            </Typography>
          </NavigationLink>
        );
      },
    },
    {
      xs: 10,
      id: 'riskMitigation',
      Header: 'Mitigation',
      accessor: (row) => getRichEditorText(row?.get('mitigation')),
    },
    {
      xs: 3,
      id: 'rating',
      Header: 'Rating',
      Cell: ({ row }) => {
        const task = row.original;
        const consequenceId = task?.get(RiskSectionFormFields.CONSEQUENCE);
        const probabilityId = task?.get(RiskSectionFormFields.PROBABILITY);
        const color = RiskMatrix[consequenceId][probabilityId] ?? 'red';

        return (
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="left"
            style={{ height: '100%' }}
          >
            <div
              style={{
                backgroundColor: color,
                borderRadius: '50%',
                width: '25px',
                height: '25px',
              }}
            ></div>
          </Grid>
        );
      },
    },
    {
      xs: 5,
      id: 'consequence',
      Header: 'Consequence',
      Cell: ({ row }) => {
        const task = row.original;
        return (
          <Typography noWrap variant="inherit">
            {
              riskConsequences.find(
                (risk) => risk.id === task.get('consequence'),
              ).name
            }
          </Typography>
        );
      },
    },
    {
      xs: 5,
      id: 'probability',
      Header: 'Probability',
      Cell: ({ row }) => {
        const task = row.original;
        return (
          <Typography noWrap variant="inherit">
            {
              riskProbabilities.find(
                (risk) => risk.id === task.get('probability'),
              ).name
            }
          </Typography>
        );
      },
    },
    {
      xs: 4,
      id: 'state',
      Header: 'State',
      Cell: ({ row }) => {
        const task = row.original;
        return (
          <Grid
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ height: '100%' }}
          >
            <Typography noWrap variant="inherit">
              {riskState.find((risk) => risk.id === task.get('state')).name}
            </Typography>
          </Grid>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      items={sortRisks(item?.get('risks'))}
      noItemsMessage="No risk sections added yet..."
      stickyHeader
      disableSortBy
      useGlobalFilter={false}
    />
  );
};

const styles = () => ({
  taskTitleLink: {
    display: 'inline',
  },
});

export default withStyles(styles)(ProjectRiskTableRowDetails);
