export const Styles = (theme) => ({
  content: {
    padding: `0px ${theme.spacing(3)}px`,
  },
  header: {
    padding: theme.spacing(3),
  },
  actions: {
    paddingTop: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
});
