import { compose } from 'redux';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { toTaskToolstring, toTaskToolstringEdit } from 'utils/route.util';

import {
  getToolstringFromState,
  getToolstringToolsFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';

import { formatFraction } from 'utils/format.util';
import MetricImperial from 'app/components/MetricImperial';
import { ProjectPermission, UNITS } from 'app/app.constants';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { ExifOrientation } from 'features/projects/tool/tool.constants';
import ToolDetails from 'features/projects/tool/components/ToolDetails';
import { Button as NavigationButton } from 'app/components/withNavigation';
import ToolImageContainer from 'features/projects/tool/components/ToolImageContainer';

class TaskTableRowDetailsToolstring extends PureComponent {
  render() {
    const {
      taskId,
      classes,
      projectId,
      toolstring,
      toolstringId,
      toolstringItems,
    } = this.props;

    if (!toolstringId) return null;

    return (
      <Grid container className={classes.expansionPanelDetailsSection}>
        <HasProjectPermission permission={ProjectPermission.EDIT_TOOLSTRING}>
          <NavigationButton
            to={toTaskToolstringEdit(projectId, taskId, toolstringId)}
            style={{
              position: 'absolute',
              top: 4,
              right: 4,
            }}
            size="small"
            title="Edit toolstring"
          >
            Edit
          </NavigationButton>
        </HasProjectPermission>

        <Grid item>
          <Typography noWrap variant="subtitle2">
            {toolstring.get('name') ?? 'Toolstring'}
          </Typography>
        </Grid>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          className={classes.toolstringContainer}
        >
          {!toolstringItems.size && (
            <Typography>
              <i>No tools added yet</i>
            </Typography>
          )}
          {!!toolstringItems.size && (
            <Link
              className={classes.toolstringLinkContainer}
              to={toTaskToolstring(projectId, taskId, toolstringId)}
            >
              {toolstringItems.map((toolstringItem) => {
                return (
                  <Grid
                    item
                    className={classes.toolstringTool}
                    key={toolstringItem.get('toolstringItemId')}
                  >
                    <ToolImageContainer
                      onHoverDarken
                      toolName={toolstringItem.get('name')}
                      toolImageUrl={toolstringItem.get('imageUrl')}
                      title={<ToolDetails tool={toolstringItem.toJS()} />}
                      orientation={ExifOrientation.DEGREES_90}
                      TooltipProps={{
                        placement: 'bottom',
                      }}
                      ImageProps={{
                        style: {
                          maxWidth: '100%',
                        },
                      }}
                    />
                  </Grid>
                );
              })}
            </Link>
          )}
        </Grid>
        {!!toolstringItems.size && (
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        Cable:
                      </Typography>
                    </Grid>
                    <Grid item variant="body1" color="default">
                      {toolstring.getIn(['cable', 'name']) ?? <i>N/A</i>}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        Total Length:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <MetricImperial
                        to={UNITS.METER}
                        from={UNITS.METER}
                        value={toolstring.get('totalLength')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item>
                      <Typography variant="caption" color="textSecondary">
                        Max OD:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <MetricImperial
                        to={UNITS.INCHES}
                        from={UNITS.INCHES}
                        value={toolstring?.get('maxOD')}
                        formatFn={(value) => formatFraction(value?.value, value?.unit ?? '"')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

const styles = () => ({
  expansionPanelDetailsSection: {
    marginBottom: 6,
    position: 'relative',
    padding: '12px',
    border: '1px solid rgba(255, 255, 255, 0.06)',
  },
  toolstringContainer: {
    padding: '15px 0',
  },
  noToolstring: {
    padding: 10,
    cursor: 'pointer',
    border: '1px dashed #6a6a6a',
    marginBottom: 6,
    '&:hover': {
      padding: 9,
      border: '2px dashed #6a6a6a',
    },
  },
  toolstringLinkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolstringTool: {
    alignItems: 'center',
    display: 'flex',
  },
});

export default compose(
  connect((state, { toolstringId }) => {
    return {
      toolstring: getToolstringFromState(state, toolstringId),
      toolstringItems: getToolstringToolsFromState(state, toolstringId),
    };
  }),
  // toJSComponent,
  withStyles(styles),
)(TaskTableRowDetailsToolstring);
