import { Grid } from '@material-ui/core';
import ReadOnlyField from 'app/components/ReadOnlyField';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const CableTorsionDrawerComponent = ({ assetHistory, classes, keyEvent }) => (
  <>
    <Grid item xs={6}>
      <Grid className={classes.marginLabel}>
        <CustomReadOnlyField
          margin="none"
          label="Number of twists"
          className={classes.marginLabel}
        >
          {assetHistory.getIn([keyEvent, 'wrap'])}
          {assetHistory.get('wrap')}
        </CustomReadOnlyField>
      </Grid>
    </Grid>
  </>
);

export default CableTorsionDrawerComponent;
