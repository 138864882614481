import React from 'react';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Close';
import { TextField, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const DEFAULT_INPUT_VALUE = '';
export const AutocompleteTextField = ({
  autoCompleteValue,
  setAutocompleteValue,
  form,
  formFieldName,
  setInitialFieldValue,
  valueSelectorFromAutocomplete,
  getOptionLabel,
  fetchAutocompleteDataAction,
  placeholder,
  keepChangeAfterOnBlur = false,
  id,
  disabled = false,
  clearInputValue = null,
}) => {
  const theme = useTheme();
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(DEFAULT_INPUT_VALUE);
  const [autoCompleteLengthError, setAutoCompleteLengthError] = useState('');
  const dispatch = useDispatch();

  const { getFieldMeta } = form;
  const { touched, error, value } = getFieldMeta(formFieldName);

  const onInputChange = (event, newInputValue) => {
    if (event?.type === 'blur' && keepChangeAfterOnBlur) {
      setInputValue(value === null ? DEFAULT_INPUT_VALUE : value);
      return;
    }

    setInputValue(newInputValue);
  };

  useEffect(() => {
    setInitialFieldValue(setInputValue);
  }, [setInitialFieldValue, setInputValue]);

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(autoCompleteValue ? [autoCompleteValue] : []);
      return undefined;
    }

    if (inputValue.length > 0) {
      if (
        autoCompleteValue &&
        valueSelectorFromAutocomplete(autoCompleteValue) === inputValue
      )
        return undefined;
      fetchAutocompleteDataAction(inputValue).then((response) => {
        if (active) {
          let newOptions = [];

          if (autoCompleteValue) {
            newOptions = [autoCompleteValue];
          }

          if (response) {
            newOptions = [...newOptions, ...response];
          }

          setOptions(newOptions);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [
    dispatch,
    inputValue,
    autoCompleteValue,
    fetchAutocompleteDataAction,
    valueSelectorFromAutocomplete,
  ]);

  return (
    <Autocomplete
      disabled={disabled}
      disableClearable
      value={autoCompleteValue}
      options={options}
      id={id}
      inputValue={inputValue}
      filterOptions={(x) => x}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={autoCompleteLengthError}
            placeholder={placeholder}
            InputLabelProps={{
              style: {
                color:
                  theme.altus.components.AutocompleteTextField.InputLabelColor,
              },
            }}
            error={!!error && touched}
            helperText={touched ? error : null}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {inputValue && (
                    <IconButton
                      onClick={() => {
                        setInputValue('');
                        setAutocompleteValue(null);
                        form.setFieldValue(formFieldName, '');

                        if (clearInputValue) {
                          clearInputValue();
                        }
                      }}
                      size="small"
                      disabled={disabled}
                      style={{
                        position: 'absolute',
                        right: '25px',
                        zIndex: '100',
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            onChange={(event) => {
              if (event.target.value.length && event.target.value.length < 1) {
                setAutoCompleteLengthError('Min 1 character required');
              } else {
                setAutoCompleteLengthError('');
              }
            }}
          />
        );
      }}
      // Invoked when text field is changed (i.e. user entering search value)
      onInputChange={(event, newInputValue) =>
        onInputChange(event, newInputValue)
      }
      // Invoked when text field is changed (i.e. user entering search value)
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setAutocompleteValue(newValue);
        const newFormFieldValue = valueSelectorFromAutocomplete(newValue);
        form.setFieldValue(formFieldName, newFormFieldValue);
      }}
    />
  );
};
