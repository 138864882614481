import { useCallback, useMemo } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import { Box, CardActions } from '@material-ui/core';

import { FavoriteButton } from 'altus-ui-components';
import CardNavigationItem from 'app/components/CardNavigationItem/CardNavigationItem';
import { PlannedIndicator } from 'features/projects/execution/components/PlannedIndicator';

const SimulationCardNavigationItem = ({
  to,
  onDelete,
  simulation,
  onDuplicate,
  simulationId,
  toggleFavorite,
}) => {
  const data = useMemo(
    () => [
      {
        key: 'BHA',
        value: simulation.getIn(['toolstring', 'name']) || 'No BHA',
      },
      {
        key: 'Cable',
        value: simulation.getIn(['cable', 'name']) || 'No Cable',
      },
    ],
    [simulation],
  );

  const renderHeaderAction = useCallback(() => {
    return (
      <FavoriteButton
        isFavorite={simulation.get('isFavorite')}
        onClick={() => toggleFavorite(simulationId)}
      />
    );
  }, [simulation, simulationId, toggleFavorite]);

  // TODO: we need to disable some actions buttons based on permissions.
  // Will do this later, since in this case we will probably need to refactor or add
  // another HasProjectPermission component with a render method that provides multiple permissions verification
  const actions = useMemo(
    () =>
      [
        {
          Icon: DuplicateIcon,
          title: 'Duplicate',
          onClick: () => onDuplicate(simulationId),
        },
        {
          Icon: DeleteIcon,
          title: 'Delete',
          disabled: simulation.get('isPlanned'),
          onClick: () => onDelete(simulationId),
        },
      ].filter((action) => !!action.onClick),
    [onDuplicate, onDelete, simulation, simulationId],
  );

  return (
    <Box padding={1}>
      <CardNavigationItem
        to={to}
        data={data}
        actions={actions}
        title={simulation.get('name')}
        renderHeaderAction={renderHeaderAction}
      >
        <CardActions>
          <PlannedIndicator
            simulation={simulation}
            hidden={!simulation.get('isPlanned')}
          />
        </CardActions>
      </CardNavigationItem>
    </Box>
  );
};

export default SimulationCardNavigationItem;
