import SearchIcon from '@material-ui/icons/Search';

import { NoContentBasePage } from 'altus-ui-components';

const NoSimulationSelectedPage = () => (
  <NoContentBasePage
    header="Select a Simulation"
    description="Please choose a Simulation or create a new one from the left navigation menu"
    Icon={SearchIcon}
  />
);

export default NoSimulationSelectedPage;
