import PropTypes from 'prop-types';

import { TextField as MuiTextField } from '@material-ui/core';

const TextFieldV2 = ({ margin, fullwidth, ...rest }) => {
  return <MuiTextField margin={margin} fullwidth={fullwidth} {...rest} />;
};

TextFieldV2.propTypes = {
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
};

TextFieldV2.defaultProps = {
  margin: 'normal',
  fullWidth: true,
};

export default TextFieldV2;
