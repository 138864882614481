import { Field } from 'formik';
import { MenuItem } from '@material-ui/core';

import { required } from 'utils/validation.util';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import {
  fluidTypeToString,
  multiphaseTypeToString,
  rheologyModelToString,
} from 'utils/fluids/mappers.utils';
import {
  FluidType,
  FluidFormFields as FormFields,
  MultiphaseType,
  RheologyModel,
} from 'utils/fluids/constants.utils';

const FluidFluidIdentification = ({ disabled, fluidType, autoFocus }) => (
  <>
    <Field
      required
      type="number"
      label="End Depth"
      destroyOnUnmount
      disabled={disabled}
      validate={required}
      autoFocus={autoFocus}
      name={FormFields.DEPTH}
      component={QuantityTextFieldFormik}
      useRoundedValue={true}
    />
    <Field
      required
      label="Name"
      destroyOnUnmount
      disabled={disabled}
      validate={required}
      name={FormFields.NAME}
      component={TextFieldFormik}
    />
    <Field
      select
      required
      destroyOnUnmount
      label="Fluid Type"
      disabled={disabled}
      validate={required}
      component={TextFieldFormik}
      name={FormFields.FLUID_TYPE}
    >
      {Object.values(FluidType).map((fluidType) => (
        <MenuItem key={fluidType} value={fluidType}>
          {fluidTypeToString(fluidType)}
        </MenuItem>
      ))}
    </Field>
    {fluidType === FluidType.LIQUID && (
      <Field
        select
        required
        destroyOnUnmount
        disabled={disabled}
        validate={required}
        label="Rheology Model"
        component={TextFieldFormik}
        name={FormFields.RHEOLOGY_MODEL}
      >
        {Object.values(RheologyModel).map((rheologyModel) => (
          <MenuItem key={rheologyModel} value={rheologyModel}>
            {rheologyModelToString(rheologyModel)}
          </MenuItem>
        ))}
      </Field>
    )}
    {fluidType === FluidType.MULTIPHASE && (
      <Field
        select
        required
        destroyOnUnmount
        disabled={disabled}
        validate={required}
        label="Multiphase Type"
        component={TextFieldFormik}
        name={FormFields.MULTIPHASE_TYPE}
      >
        {Object.values(MultiphaseType).map((multiphaseType) => (
          <MenuItem key={multiphaseType} value={multiphaseType}>
            {multiphaseTypeToString(multiphaseType)}
          </MenuItem>
        ))}
      </Field>
    )}
  </>
);

export default FluidFluidIdentification;
