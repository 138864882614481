import { compose } from 'redux';
import { useState, useCallback } from 'react';
import { arrayMoveImmutable } from 'array-move';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  addNotification,
  NOTIFICATION_VARIANTS,
} from 'altus-redux-middlewares';

import {
  MODAL,
  EQUIPMENT_ACTIONS,
} from 'features/equipment/equipment.constants';

import {
  getSummarizedDataStateFromState,
  getCurrentClientOrganizationIdFromStateForAssembly,
} from 'app/app.selectors';

import { useModal } from 'altus-modal';
import BasePage from 'app/components/BasePageDense';
import { saveToolAssembly } from 'features/equipment/equipment.actions';
import { ToolCategoryType } from 'features/projects/tool/tool.constants';
import ToolPickerModalContainer from 'features/projects/tool/components/ToolPickerModalContainer';
import ToolAssembliesModal from 'features/equipment/toolAssemblies/components/ToolAssembliesModal';

const toolstringItemCategories = [
  ToolCategoryType.MWL,
  ToolCategoryType.EWL,
  ToolCategoryType.ThirdParty,
];

const ToolAssembliesModalContainer = ({
  openModal,
  dataState,
  toggleModal,
}) => {
  const dispatch = useDispatch();

  const [selectedTools, setSelectedTools] = useState([]);
  const [toolAssemblyName, setToolAssemblyName] = useState('');
  const [toolAssemblyInfo, setToolAssemblyInfo] = useState(null);

  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromStateForAssembly,
  );

  const [toolAssemblyItemModalOpen, toggleToolAssemblyItemModal] = useModal(
    MODAL.ADD_TOOL_TO_TOOL_ASSEMBLY,
  );

  const onUpdateAssemblyName = (formValue) => {
    setToolAssemblyName(formValue);
  };

  const handleToggleModal = () => {
    setSelectedTools([]);
    setToolAssemblyName('');
    setToolAssemblyInfo(null);
    toggleModal();
  };

  const onAddTool = (tool) => {
    setSelectedTools((prevArray) => [...prevArray, tool]);
    dispatch(
      addNotification({
        message: 'Tool added to assembly.',
        variant: NOTIFICATION_VARIANTS.SUCCESS,
      }),
    );
  };

  const onSortToolstringItem = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) return;

      const tools = [...selectedTools];
      const newTools = arrayMoveImmutable(tools, oldIndex, newIndex);
      setSelectedTools(newTools);
    },
    [selectedTools],
  );

  const onDeleteToolstringItem = useCallback(
    (toolAssemblyTool) => {
      const tools = [...selectedTools];
      const toolIndex = tools.indexOf(toolAssemblyTool);
      tools.splice(toolIndex, 1);
      setSelectedTools(tools);
    },
    [selectedTools],
  );

  const onDuplicateItem = useCallback(
    (tool) => {
      const tools = [...selectedTools];
      const toolIndex = tools.indexOf(tool);
      tools.push(tool);
      const lastToolIndex = tools.lastIndexOf(tool);
      const newTools = arrayMoveImmutable(tools, lastToolIndex, toolIndex + 1);
      setSelectedTools(newTools);
    },
    [selectedTools],
  );

  const onSaveToolAssembly = useCallback(() => {
    const tools = selectedTools.map((tool) => {
      return tool.get('id');
    });
    const toolAssemblyToSave = {
      name: toolAssemblyName,
      description: toolAssemblyInfo,
      organizationId: currentClientOrganizationId,
      toolAssemblyToolsIds: tools,
    };
    dispatch(saveToolAssembly(toolAssemblyToSave));
  }, [
    dispatch,
    selectedTools,
    toolAssemblyInfo,
    toolAssemblyName,
    currentClientOrganizationId,
  ]);

  return (
    <BasePage dataState={dataState}>
      <ToolAssembliesModal
        open={openModal}
        toggleModal={handleToggleModal}
        selectedTools={selectedTools}
        onDuplicateItem={onDuplicateItem}
        toolAssemblyInfo={toolAssemblyInfo}
        toolAssemblyName={toolAssemblyName}
        onSaveToolAssembly={onSaveToolAssembly}
        setToolAssemblyName={onUpdateAssemblyName}
        setToolAssemblyInfo={setToolAssemblyInfo}
        onUpdateAssemblyName={onUpdateAssemblyName}
        onSortToolstringItem={onSortToolstringItem}
        onDeleteToolstringItem={onDeleteToolstringItem}
        toggleToolAssemblyItemModal={toggleToolAssemblyItemModal}
      />
      <ToolPickerModalContainer
        onAddTool={onAddTool}
        open={toolAssemblyItemModalOpen}
        onToggleModal={toggleToolAssemblyItemModal}
        enabledToolCategoryTypes={toolstringItemCategories}
      />
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    EQUIPMENT_ACTIONS.SAVE_TOOL_ASSEMBLY,
  ),
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ToolAssembliesModalContainer,
);
