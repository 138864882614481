import { compose } from 'redux';
import { Box, Grid } from '@material-ui/core';
import { useSelector, connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { useEffect, useMemo, useCallback, useState } from 'react';

import {
  getToolGroupsFromState,
  getToolAssembliesFromState,
} from 'features/equipment/equipment.selectors';

import {
  onUnload,
  onLoadToolAssemblies,
  searchToolAssemblies,
} from 'features/equipment/equipment.actions';

import {
  MODAL,
  EQIPMENT_CATEGORY_IDS,
} from 'features/equipment/equipment.constants';

import { useModal } from 'altus-modal';
import { EMPTY_STRING } from 'app/app.constants';
import { getCurrentClientOrganizationIdFromState } from 'app/app.selectors';
import ToolAssembliesModalContainer from './components/ToolAssembliesModalContainer';
import { Filters } from 'features/equipment/toolAssemblies/components/ToolAssembliesFilter';
import ToolAssembliesTable from 'features/equipment/toolAssemblies/components/ToolAssembliesTable';
import ToolAssembliesFilter from 'features/equipment/toolAssemblies/components/ToolAssembliesFilter';
import EditToolAssembliesModalContainer from 'features/equipment/toolAssemblies/EditToolAssembliesModalContainer';

const defaultFormValues = {
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
  [Filters.SERIAL_SEARCH]: EMPTY_STRING,
};

const ToolAssembliesContainer = ({
  classes,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchOnSearch,
}) => {
  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromState,
  );

  useEffect(() => {
    dispatchOnLoad(EQIPMENT_CATEGORY_IDS.TOOL_ASSEMBLIES);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload]);

  const [addToolAssemblyModal, toggleAddToolAssemblyModal] = useModal(
    MODAL.ADD_TOOL_ASSEMBLY,
  );

  const [editToolAssemblyModal, toggleEditToolAssemblyModal] = useModal(
    MODAL.EDIT_TOOL_ASSEMBLY,
  );

  const initialFilterValues = useMemo(
    () => ({
      ...defaultFormValues,
      organizationId: currentClientOrganizationId,
    }),
    [currentClientOrganizationId],
  );

  const tools = useSelector(getToolAssembliesFromState);
  const toolGroups = useSelector(getToolGroupsFromState);

  const handleSearch = useCallback(
    (filterValues) => {
      dispatchOnSearch({
        [Filters.TEXT_SEARCH]: filterValues[Filters.TEXT_SEARCH],
        [Filters.SERIAL_SEARCH]: filterValues[Filters.SERIAL_SEARCH],
        categoryId: EQIPMENT_CATEGORY_IDS.TOOL_ASSEMBLIES,
      });
    },
    [dispatchOnSearch],
  );

  const [toolId, setTooldId] = useState(null);

  const onEditTool = (toolId) => {
    setTooldId(toolId);
    toggleEditToolAssemblyModal();
  };

  return (
    <Grid container>
      <Grid item xs={12} container component={Box} direction="column">
        <Grid item className={classes.filterContainer}>
          <ToolAssembliesFilter
            toolGroups={toolGroups}
            onSubmit={handleSearch}
            initialValues={initialFilterValues}
            toggleAddToolAssemblyModal={toggleAddToolAssemblyModal}
            currentClientOrganizationId={currentClientOrganizationId}
          />
        </Grid>
        <Grid item container className={classes.tableContainer}>
          <ToolAssembliesTable tools={tools} onEditTool={onEditTool} />
        </Grid>
        <ToolAssembliesModalContainer
          openModal={addToolAssemblyModal}
          toggleModal={toggleAddToolAssemblyModal}
        />
        {toolId ? (
          <EditToolAssembliesModalContainer
            openModal={editToolAssemblyModal}
            toggleModal={toggleEditToolAssemblyModal}
            toolId={toolId}
            setTooldId={setTooldId}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  filterContainer: {
    height: 'fit-content',
    width: '40%',
  },
  tableContainer: {
    flex: 1,
  },
});

export default compose(
  connect(() => ({}), {
    dispatchOnLoad: onLoadToolAssemblies,
    dispatchOnUnload: onUnload,
    dispatchOnSearch: searchToolAssemblies,
  }),
  withStyles(styles),
)(ToolAssembliesContainer);
