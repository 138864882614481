import { compose } from 'redux';
import { useCallback } from 'react';
import Chip from '@material-ui/core/Chip';
import withStyles from '@material-ui/styles/withStyles';
import { Box, Grid, Typography } from '@material-ui/core';

import EditorStateViewer from 'app/components/EditorStateViewer';
import TableRowExpandable from 'app/components/TableRowExpandable';

const NPTTableRow = ({ item, classes, ...rest }) => {
  const renderDetails = useCallback(
    () => (
      <Grid container component={Box} paddingLeft={3} paddingRight={3}>
        <Grid container component={Box} paddingTop={3} paddingBottom={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Description:</Typography>
          </Grid>
          <Grid item xs={12}>
            {item.get('description') ? (
              <EditorStateViewer editorState={item.get('description')} />
            ) : (
              <Typography variant="caption" color="textSecondary">
                <i>N/A</i>
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container component={Box} paddingTop={3} paddingBottom={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Departments:</Typography>
          </Grid>
          <Grid item xs={12}>
            <div component="ul" className={classes.list}>
              {item.get('departments').map((dep) => {
                return (
                  <li key={dep.get('id')}>
                    <Chip label={dep.get('name')} className={classes.chip} />
                  </li>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Grid>
    ),
    [item, classes.chip, classes.list],
  );

  return (
    <TableRowExpandable
      {...rest}
      renderDetails={renderDetails}
      hasLightBackground={true}
    />
  );
};
const styles = (theme) => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
});
export default compose(withStyles(styles))(NPTTableRow);
