import { Link } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import { Table, TableRow } from 'altus-ui-components';

import { toFacility } from 'utils/route.util';
import { facilityTypeToString } from 'mappers';
import { SystemPermission } from 'app/app.constants';
import { useSystemPermissions } from 'app/hooks/authorization/useSystemPermissions';
import { facilityStatusToString } from 'features/facilities/facility.mappers';

const FacilitiesTable = ({ facilities }) => {
  const { userPermissions } = useSystemPermissions();

  const columns = useMemo(
    () => [
      {
        xs: 4,
        Header: 'Name',
        accessor: (facility) => facility.get('name'),
      },
      {
        xs: 4,
        Header: 'Type',
        accessor: (facility) => facilityTypeToString(facility.get('type')),
      },
      {
        xs: 4,
        Header: 'Operator',
        accessor: (facility) => facility.get('operator'),
      },
      {
        xs: 4,
        Header: 'Status',
        accessor: (facility) => facilityStatusToString(facility.get('status')),
      },
    ],
    [],
  );

  const renderTableRowComponent = useCallback(
    ({ item, ...rest }) =>
      !userPermissions.includes(SystemPermission.MANAGE_WELLS) ? (
        <TableRow {...rest} />
      ) : (
        <Link to={toFacility(item.get('facilityId'))}>
          <TableRow {...rest} />
        </Link>
      ),
    [userPermissions],
  );

  return (
    <Table
      stickyHeader
      // manualPagination
      columns={columns}
      items={facilities}
      useGlobalFilter={false}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

export default FacilitiesTable;
