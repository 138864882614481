import PropTypes from 'prop-types';
// import { useState, useEffect } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

import { getSummarizedDataState } from 'altus-datastate';

import { EMPTY_MAP } from 'app/app.constants';
import useProjectWellboreTrajectory from 'features/wells/hooks/useProjectWellboreTrajectory';
import useLatestProjectWellboreDetail from 'features/projects/hooks/useLatestProjectWellboreDetails';
import WellGraphAndGuagesTrajectory from 'app/components/WellGraphAndGuages/WellGraphAndGuagesTrajectory';

const ProjectWellGraphTrajectoryContainer = ({
  projectId,
  targetDepth,
  children = null,
  currentDepth,
  ...rest
}) => {
  /** Mocking the current depth simulation for gradual change
   * Keep these lines commented while pushing to dev
   */
  // const [currentDepth, setCurrentDepth] = useState({
  //   unit: 'm',
  //   value: 1200,
  //   hasValue: true,
  // });
  // useEffect(() => {
  //   if (currentDepth && currentDepth.value <= 3500) {
  //     setTimeout(() => setCurrentDepth({ ...currentDepth, value: currentDepth.value + 30 }), 1000)
  //   } else {
  //     setCurrentDepth({ ...currentDepth, value: 1203 })
  //   }
  // }, [currentDepth])

  const [latestWellboreDetails = EMPTY_MAP, wellDetailsDataState] =
    useLatestProjectWellboreDetail(projectId);

  const [trajectory = EMPTY_MAP, trajectoryDataState] =
    useProjectWellboreTrajectory(projectId);

  const dataState = getSummarizedDataState([
    wellDetailsDataState,
    trajectoryDataState,
  ]);

  if (dataState.isLoading()) {
    return (
      <Grid
        xs
        item
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (!trajectory.size) return children;

  return (
    <WellGraphAndGuagesTrajectory
      trajectory={trajectory}
      wellboreDetail={latestWellboreDetails}
      cDepth={currentDepth}
      {...rest}
    />
  );
};

ProjectWellGraphTrajectoryContainer.propTypes = {
  children: PropTypes.node,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ProjectWellGraphTrajectoryContainer;
