import classNames from 'classnames';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import toJSComponent from 'with-immutable-props-to-js';
import withStyles from '@material-ui/styles/withStyles';

import { anyLoadingDataState } from 'altus-datastate';

import { renderContent } from 'utils/app.util';
import LoadingDimmer from 'app/components/LoadingDimmer';
import { setSubPage, setHeaderValues } from 'app/app.actions';

const styles = (theme) => ({
  root: {
    minHeight: 0,
    position: 'relative',
  },
  scrollIndividually: {
    overflow: 'auto',
  },
  subRoot: {
    height: '100%',
    overflow: 'auto',
  },
  gridItem: {
    height: 'fit-content',
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  stickyGridItem: {
    [theme.breakpoints.up('md')]: {
      position: 'sticky',
      top: 0,
    },
  },
  leftRoot: {},
  rightRoot: {},
  mainRoot: {},
});

class BasePage extends PureComponent {
  componentDidMount() {
    const {
      title,
      subPage,
      subTitle,
      dispatchSetSubPage,
      dispatchSetHeaderValues,
    } = this.props;

    dispatchSetSubPage(subPage);

    dispatchSetHeaderValues({ title, subTitle });
  }

  componentDidUpdate(prevProps) {
    const { subTitle, title, dispatchSetHeaderValues } = this.props;
    const { title: newTitle, subTitle: newSubTitle } = prevProps;

    if (newTitle !== title) {
      dispatchSetHeaderValues({ title });
    }
    if (newSubTitle !== subTitle) {
      dispatchSetHeaderValues({ subTitle });
    }
  }

  componentWillUnmount() {
    const { dispatchSetSubPage } = this.props;

    dispatchSetSubPage();
  }

  render() {
    const {
      classes,
      children,
      dataState,
      RootProps,
      subRootRef,
      loadingText,
      LeftContent,
      RootContent,
      RightContent,
      SubRootProps,
      MainRootProps,
      RootContentProps,
      LeftRootProps: { sticky: leftContentSticky, ...LeftRootProps },
      RightRootProps: { sticky: rightContentSticky, ...RightRootProps },
      LeftContentProps,
      RightContentProps,
    } = this.props;
    const isLoading = anyLoadingDataState(dataState);

    return (
      <Grid xs item container className={classes.root} {...RootProps}>
        {isLoading && <LoadingDimmer text={loadingText} />}
        <>
          {renderContent(RootContent, RootContentProps)}
          <Grid
            item
            container
            ref={subRootRef}
            className={classes.subRoot}
            {...SubRootProps}
          >
            <Grid
              item
              xs={12}
              md={2}
              container
              {...LeftRootProps}
              className={classNames(classes.leftRoot, classes.gridItem, {
                [classes.stickyGridItem]: leftContentSticky,
              })}
            >
              {renderContent(LeftContent, LeftContentProps)}
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              container
              {...MainRootProps}
              className={classNames(classes.mainRoot, classes.gridItem)}
            >
              {renderContent(children)}
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              container
              {...RightRootProps}
              className={classNames(classes.rightRoot, classes.gridItem, {
                [classes.stickyGridItem]: rightContentSticky,
              })}
            >
              {renderContent(RightContent, RightContentProps)}
            </Grid>
          </Grid>
        </>
      </Grid>
    );
  }
}

BasePage.defaultProps = {
  loadingText: 'Loading',
  subRootRef: () => {},
  LeftRootProps: {},
  RightRootProps: {},
  MainRootProps: {},
};

const mapDispatchToProps = {
  dispatchSetSubPage: setSubPage,
  dispatchSetHeaderValues: setHeaderValues,
};

export default connect(
  null,
  mapDispatchToProps,
)(toJSComponent(withStyles(styles)(BasePage)));
