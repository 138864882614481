import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, AppBar, Typography } from '@material-ui/core';

import BackButton from 'app/components/BackButton';
import { IconButton } from 'app/components/withTooltip';

const styles = () => ({
  appBar: {
    position: 'relative',
    padding: 10,
    boxShadow: 'none',
  },
  title: {
    flex: 1,
  },
  rightToolbarItem: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

const ModalAppBarFullscreen = ({
  title,
  classes,
  project,
  closeModal,
  onCancelClick,
  displayBackBtn,
  hideCloseButton,
  fullScreenSubTitle,
  fullScreenHideSubTitle,
}) => {
  return (
    <AppBar className={classes.appBar} color="default">
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={4}>
              {displayBackBtn && (
                <BackButton onClick={onCancelClick || closeModal} />
              )}
            </Grid>
            <Grid item xs={4} align="center">
              <Grid container alignItems="center">
                {!fullScreenHideSubTitle && (
                  <Grid item xs={12}>
                    <Typography
                      noWrap
                      color="textSecondary"
                      variant="subtitle1"
                      className={classes.title}
                    >
                      {fullScreenSubTitle || (project && project.title)}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography variant="h6">{title}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              className={classes.rightToolbarItem}
              align="right"
            >
              {!hideCloseButton && (
                <IconButton
                  disableRipple
                  title="Close"
                  color="inherit"
                  onClick={onCancelClick || closeModal}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default withStyles(styles)(ModalAppBarFullscreen);
