import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import { memo, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { EMPTY_LIST } from 'app/app.constants';
import { invokeIfFunction } from 'utils/app.util';
import ToolDetails from 'features/projects/tool/components/ToolDetails';
import ToolImageContainer from 'features/projects/tool/components/ToolImageContainer';
import ToolstringPreviewEmpty from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewEmpty';
import ToolstringPreviewBreakpoint from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewBreakpoint';
import { useHighlightedToolstringItem } from 'features/projects/tasks/task/toolstring/components/ToolstringItemHighlightProvider';

const ToolstringPreviewImages = ({
  classes,
  toolstringItems = EMPTY_LIST,
  hideToolDetails,
  hideBreakpoints,
  onToggleBreakpoint,
}) => {
  const { isItemHighlighted, setHighlightedItemId } =
    useHighlightedToolstringItem();

  if (!toolstringItems.size) {
    return <ToolstringPreviewEmpty />;
  }

  const toggleableBreakpoints = !!onToggleBreakpoint;

  return (
    <Grid
      item
      container
      wrap="nowrap"
      direction="column"
      classes={{ root: classes.root }}
    >
      {toolstringItems.valueSeq().map((toolstringTool, index) => {
        const isLastTool = index === toolstringItems.size - 1;
        const showBreakpoint = !isLastTool && !hideBreakpoints;
        const tooltip = <ToolDetails tool={toolstringTool.toJS()} />;
        const hasBreakpoint = toolstringTool.get('hasEndBreakpoint');
        const toolstringItemId = toolstringTool.get('toolstringItemId');

        /* Put the mouse events in the grid so that the hoverable area is larger */
        if (
          toolstringTool.get('toolAssemblyTools') &&
          toolstringTool.get('toolAssemblyTools').size
        ) {
          const tools = toolstringTool.get('toolAssemblyTools');

          return tools.map((tool, toolIndex) => (
            <Fragment key={toolIndex}>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="flex-end"
                className={classes.image}
                onMouseEnter={() =>
                  invokeIfFunction(setHighlightedItemId, toolstringItemId)
                }
                onMouseLeave={() => invokeIfFunction(setHighlightedItemId)}
              >
                <ToolImageContainer
                  onHoverScale
                  toolName={tool.get('name')}
                  toolImageUrl={tool.get('imageUrl')}
                  title={!hideToolDetails ? tooltip : undefined}
                  highlighted={isItemHighlighted(toolstringItemId)}
                  style={{ maxHeight: '100%' }}
                />
              </Grid>
            </Fragment>
          ));
        } else
          return (
            <Fragment key={index}>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="flex-end"
                className={classes.image}
                onMouseEnter={() =>
                  invokeIfFunction(setHighlightedItemId, toolstringItemId)
                }
                onMouseLeave={() => invokeIfFunction(setHighlightedItemId)}
              >
                <ToolImageContainer
                  onHoverScale
                  toolName={toolstringTool.get('name')}
                  toolImageUrl={toolstringTool.get('imageUrl')}
                  title={!hideToolDetails ? tooltip : undefined}
                  highlighted={isItemHighlighted(toolstringItemId)}
                  style={{
                    maxHeight: '100%',
                  }}
                />
              </Grid>
              {showBreakpoint && (
                <ToolstringPreviewBreakpoint
                  hasBreakpoint={hasBreakpoint}
                  toggleable={toggleableBreakpoints}
                  onToggleBreakpoint={() => onToggleBreakpoint(toolstringTool)}
                />
              )}
            </Fragment>
          );
      })}
    </Grid>
  );
};

const styles = () => ({
  root: {
    overflow: 'hidden',
  },
  image: {
    overflow: 'hidden',
    position: 'relative',
  },
});

ToolstringPreviewImages.propTypes = {
  hideToolDetails: PropTypes.bool,
  hideBreakpoints: PropTypes.bool,
  onToggleBreakpoint: PropTypes.func,
  toolstringItems: PropTypes.instanceOf(Iterable),
};

export default compose(memo, withStyles(styles))(ToolstringPreviewImages);
