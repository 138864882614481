import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { reduxForm } from 'redux-form/immutable';
import toJSComponent from 'with-immutable-props-to-js';
import withStyles from '@material-ui/styles/withStyles';

import { NotRequestedDataState } from 'altus-datastate';

import {
  EDIT_ACTIVITY_FORM,
  ACTIVITIES_ACTIONS,
  EDIT_ACTIVITY_MODAL_ID,
} from 'features/projects/activities/activities.constants';

import {
  getActivityPdf,
  onEnterEditActivityModal,
} from 'features/projects/activities/activities.actions';

import {
  getActionDataStateFromState,
  getFormValuesFromState,
} from 'app/app.selectors';

import ModalContainer from 'app/components/Modal/ModalContainer';
import { validateEditActivityForm } from 'features/projects/activities/activities.validation';
import { getAutosaveDataStateFromState } from 'features/projects/activities/activities.selectors';
import EditActivityForm from 'features/projects/activities/components/editActivity/EditActivityForm';
import EditActivityModalActions from 'features/projects/activities/components/editActivity/EditActivityModalActions';

class EditActivityModalContainer extends PureComponent {
  onNext = ({ goNext }) => {
    const { dirty, handleSubmit, taskId, projectId } = this.props;

    return dirty ? handleSubmit(projectId, taskId, true, goNext) : goNext();
  };

  onPrevious = ({ goPrevious }) => {
    const { dirty, handleSubmit, taskId, projectId } = this.props;

    return dirty
      ? handleSubmit(projectId, taskId, true, goPrevious)
      : goPrevious();
  };

  passResetIntoHandleSubmit = (...args) => {
    const { handleSubmit, reset } = this.props;
    handleSubmit(...args, reset);
  };

  render() {
    const {
      valid,
      taskId,
      classes,
      disabled,
      projectId,
      submitting,
      activities,
      handleSubmit,
      displayComments,
      autosaveDataState,
      dispatchDeleteActivity,
      dispatchDownloadActivityReport,
      updateActivityDataState,
      activityTypeId,
      activityForm,
      dispatchOnEnterEditActivityModal,
    } = this.props;

    return (
      <ModalContainer
        NextBtnProps={{
          disabled: updateActivityDataState.isLoading(),
        }}
        PreviousBtnProps={{
          disabled: updateActivityDataState.isLoading(),
        }}
        items={activities}
        title="Edit activity"
        nextLabel="Next activity"
        modalId={EDIT_ACTIVITY_MODAL_ID}
        onNext={this.onNext}
        onPrevious={this.onPrevious}
        previousLabel="Previous activity"
        onEnter={dispatchOnEnterEditActivityModal}
        classes={{
          content: classes.content,
        }}
        childrenProps={{
          valid,
          taskId,
          handleSubmit: this.passResetIntoHandleSubmit,
          autosaveDataState,
        }}
        ActionsProps={{
          valid,
          taskId,
          projectId,
          submitting,
          handleSubmit: this.passResetIntoHandleSubmit,
          activityTypeId,
          autosaveDataState,
          dispatchDeleteActivity,
          dispatchDownloadActivityReport,
        }}
        Actions={EditActivityModalActions}
      >
        <EditActivityForm
          valid={valid}
          taskId={taskId}
          disabled={disabled}
          handleSubmit={handleSubmit}
          activityTypeId={activityTypeId}
          activityForm={activityForm}
          displayComments={displayComments}
          dispatchDeleteActivity={dispatchDeleteActivity}
          autosaveDataState={autosaveDataState}
        />
      </ModalContainer>
    );
  }
}

EditActivityModalContainer.defaultProps = {
  updateActivityDataState: NotRequestedDataState,
};

const mapStateToProps = (state) => {
  const activityForm = getFormValuesFromState(state, EDIT_ACTIVITY_FORM.ID);

  const activityId =
    activityForm && activityForm.get(EDIT_ACTIVITY_FORM.ACTIVITY_ID);

  const activityTypeId = activityForm && activityForm.get('code');
  return {
    autosaveDataState: getAutosaveDataStateFromState(state),
    activityTypeId: activityTypeId,
    activityForm: activityForm,
    updateActivityDataState: activityId
      ? getActionDataStateFromState(state, {
          type: ACTIVITIES_ACTIONS.UPDATE_ACTIVITY,
          id: activityId,
        })
      : undefined,
  };
};

const mapDispatchToProps = {
  dispatchOnEnterEditActivityModal: onEnterEditActivityModal,
  dispatchDownloadActivityReport: getActivityPdf,
};

const styles = () => ({
  content: {
    height: 'auto',
    padding: '25px 25px 75px 25px',
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  toJSComponent(
    reduxForm({
      validate: validateEditActivityForm,
      form: EDIT_ACTIVITY_FORM.ID,
    })(withStyles(styles)(EditActivityModalContainer)),
  ),
);
// TODO convert to function from class
