import moment from 'moment';
import last from 'lodash/last';
import initial from 'lodash/initial';
import { fromJS, Map } from 'immutable';

export const initialDocument = () => {
  return {
    id: null,
    name: '',
    category: 1,
    extension: null,
    project: { id: null },
    wellbore: { id: null },
    created: null,
    modified: null,
  };
};

export const toFileFromDTO = ({
  facilityFileId,
  name,
  category,
  projectId,
  extension,
  created,
  modified,
  taskId,
  fullName,
  createdByUserId,
  categoryName,
  editDocumentUrl,
  comments,
  facilityId,
  fileId,
}) => {
  return fromJS({
    id: facilityFileId,
    name: name,
    category: category,
    extension: extension,
    project: { id: projectId },
    created: moment(created),
    modified: modified,
    taskId: taskId,
    fullName: fullName,
    createdByUserId,
    fileId,
    projectId,
    categoryName,
    editDocumentUrl,
    comments,
    facilityId,
    facilityFileId,
  });
};

const initialMetaFromFilename = ({ name }, initialCategory) => {
  const parts = name.split('.');

  return Map({
    name: initial(parts).join('.'),
    category: initialCategory.id,
    extension: `.${last(parts)}`,
  });
};

const documentMappers = {
  Document: {
    initial: initialDocument,
    from: toFileFromDTO,
    Meta: {
      initial: initialMetaFromFilename,
    },
  },
  toFileFromDTO,
  initialDocument,
};

export default documentMappers;
