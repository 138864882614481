import { createSelector } from 'utils/app.util';

export const getFacilityOperatorsFromState = createSelector(
  (state) => state.getIn(['entity', 'facilities', 'facilities', 'operators']),
  (operators) => operators,
);

export const getFacilitiesPageParamsFromState = createSelector(
  (state) => state.getIn(['entity', 'facilities', 'facilities', 'pageParams']),
  (facilities) => facilities,
);

export const getFacilitiesPageResultsFromState = createSelector(
  (state) => state.getIn(['entity', 'facilities', 'facilities', 'pageResults']),
  (facilities) => facilities,
);

export const getFacilityByIdFromState = createSelector(
  (state) =>
    state.getIn(['entity', 'facilities', 'facilities', 'facilityById']),
  (facility) => facility,
);

export const getFacilityByDeckFromState = createSelector(
  (state) => state.getIn(['entity', 'facilities', 'facilities', 'decks']),
  (facility) => facility,
);
