import { compose } from 'redux';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { Grid, Button } from '@material-ui/core';

import { NavTabsContainer, DetailsHeader } from 'altus-ui-components';

import {
  getSystemRole,
  deleteSystemRoleAskConfirmation,
} from 'authorization/authorization.actions';

import { EMPTY_MAP } from 'app/app.constants';
import SystemRoutesContainer from 'app/components/SystemRoutesContainer';
import { getSystemRoleFromState } from 'authorization/authorization.selectors';

class SystemRoleContainer extends PureComponent {
  componentDidMount() {
    const { systemRoleId, dispatchGetSystemRole } = this.props;

    dispatchGetSystemRole(systemRoleId);
  }

  render() {
    const {
      routes,
      systemRole,
      systemRoleId,
      dispatchDeleteSystemRole,
    } = this.props;

    const displayDeleteBtn = !systemRole.get('isProtected');

    return (
      <Grid xs item container direction="column">
        <DetailsHeader
          title={systemRole.get('name')}
          subtitle={systemRole.get('description')}
        >
          {displayDeleteBtn && (
            <Grid
              container
              justifyContent="flex-end"
              onClick={() => dispatchDeleteSystemRole(systemRole)}
            >
              <Button color="secondary" variant="contained">
                Delete
              </Button>
            </Grid>
          )}
        </DetailsHeader>
        <NavTabsContainer routes={routes} />
        <SystemRoutesContainer routes={routes} key={systemRoleId} />
      </Grid>
    );
  }
}

SystemRoleContainer.defaultProps = {
  systemRole: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { systemRoleId }) => ({
      systemRole: getSystemRoleFromState(state, systemRoleId),
    }),
    {
      dispatchGetSystemRole: getSystemRole,
      dispatchDeleteSystemRole: deleteSystemRoleAskConfirmation,
    },
  ),
)(SystemRoleContainer);
