import moment from 'moment';
import { Map, List } from 'immutable';
import { WORK_ITEM_STATUS } from 'app/app.constants';

export const situationFromDto = ({
  id,
  wellControlDrillCategoryId,
  title,
  ...situation
}) =>
  Map({
    id,
    wellControlDrillCategoryId,
    title,
    ...situation,
  });

export const categoryFromDto = ({
  id,
  title,
  wellControlDrillSituations,
  ...category
}) =>
  Map({
    id,
    title,
    situations: List(wellControlDrillSituations.map(situationFromDto)),
    ...category,
  });

const activityToDTO = ({
  endTime,
  startTime,
  status = WORK_ITEM_STATUS.PLANNED,
  ...activity
}) => {
  return {
    status: status,
    endTime: endTime ? moment(endTime).toISOString() : endTime,
    startTime: startTime ? moment(startTime).toISOString() : startTime,
    ...activity,
  };
};

const wellControlMappers = {
  situation: {
    from: situationFromDto,
  },
  category: {
    from: categoryFromDto,
  },
  activity: {
    to: activityToDTO,
  },
};

export default wellControlMappers;
