import { combineReducers } from 'redux-immutable';

import nptReducer from 'features/projects/npt/npt.reducer';
import teamReducer from 'features/projects/team/team.reducer';
import planReducer from 'features/projects/tasks/plan.reducer';
import projectsReducer from 'features/projects/projects.reducer';
import progressReducer from 'features/projects/progress.reducer';
import eventsReducer from 'features/projects/events/events.reducer';
import reportReducer from 'features/projects/report/report.reducer';
import detailsReducer from 'features/projects/details/details.reducer';
import lessonsReducer from 'features/projects/lessons/lessons.reducer';
import incidentsReducer from 'features/projects/incidents/incidents.reducer';
import assetTestReducer from 'features/projects/assets/assetTest.reducer';

import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';
import documentsReducer from 'features/projects/documents/documents.reducer';
import activitiesReducer from 'features/projects/activities/activities.reducer';
import projectWellboreReducer from 'features/projects/wellbore/projectWellbore.reducer';

const projectReducers = combineReducers({
  activities: activitiesReducer,
  data: projectsReducer,
  details: detailsReducer,
  wellbore: projectWellboreReducer,
  documents: documentsReducer,
  events: eventsReducer,
  lessons: lessonsReducer,
  incidents: incidentsReducer,
  assetTests: assetTestReducer,
  report: reportReducer,
  plan: planReducer,
  npt: nptReducer,
  team: teamReducer,
  progress: progressReducer,
});

const projectReducer = (state, action) => {
  if (action.type === PROJECTS_ACTIONS.PROJECT_PAGES_UNLOADED) {
    state = undefined;
  }

  return projectReducers(state, action);
};

export default projectReducer;
