/* eslint jsx-a11y/iframe-has-title: "off"  */
import { createPortal } from 'react-dom';
import { useRef, useEffect, useState } from 'react';

const DockManagerIFrame = ({ children, url }) => {
  const ref = useRef();
  const [document, setDocument] = useState();

  useEffect(() => {
    if (!ref.current) return;

    setDocument(
      ref.current.contentDocument ?? ref.current.contentWindow.document,
    );
  }, []);

  return (
    <iframe
      src={url}
      ref={ref}
      seamless
      frameBorder="0"
      className="dockManagerFrame"
    >
      {document && createPortal(children, document.body)}
    </iframe>
  );
};

export default DockManagerIFrame;
