import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';
import { MenuItem } from '@material-ui/core';

import { EMPTY_LIST } from 'app/app.constants';
import ReadOnlyField from 'app/components/ReadOnlyField';
import TextFieldV2 from 'app/components/form/TextFieldV2';

const TaskSimulationToolstringSelect = ({
  disabled,
  onToolstringChange,
  selectedToolstringId,
  toolstrings = EMPTY_LIST,
}) => {
  if (disabled) {
    return (
      <ReadOnlyField
        margin="none"
        label="BHA"
        value={
          toolstrings
            .find((x) => x.get('id') === selectedToolstringId)
            ?.get('name') || 'No BHA selected'
        }
      />
    );
  }

  return (
    <TextFieldV2
      select
      required
      label="BHA"
      margin="none"
      placeholder="Select BHA"
      onChange={onToolstringChange}
      value={
        toolstrings.isEmpty() || !selectedToolstringId
          ? ''
          : selectedToolstringId
      }
    >
      {toolstrings.map((toolstring) => (
        <MenuItem key={toolstring.get('id')} value={toolstring.get('id')}>
          {toolstring.get('name')}
        </MenuItem>
      ))}
    </TextFieldV2>
  );
};

TaskSimulationToolstringSelect.propTypes = {
  disabled: PropTypes.bool,
  onToolstringChange: PropTypes.func.isRequired,
  toolstrings: PropTypes.instanceOf(Collection),
  selectedToolstringId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default compose()(TaskSimulationToolstringSelect);
