import { push } from 'connected-react-router/immutable';
import { put, call, takeEvery } from 'redux-saga/effects';

import { toggleModal } from 'altus-modal';

import {
  getTaskById,
  receiveTaskToolstring,
  receiveTaskToolstrings,
  receiveTaskToolstringSections,
  receiveToolstringAssemblyTool,
  receiveToolstringItem,
  receiveToolstringTemplates,
  receiveToolstringToolsUpdatedSequences,
  redirectToTaskToolstringEdit,
} from 'features/projects/tasks/task/toolstring/toolstring.actions';

import {
  MODAL,
  TOOLSTRING_ACTIONS,
} from 'features/projects/tasks/task/toolstring/toolstring.constants';

import {
  receiveBhaTemplateForEditing,
  receiveBhaTemplateSectionsForEditing,
} from 'features/equipment/equipment.actions';

import toolService from 'services/tool.service';
import { callAsync } from 'app/sagas/helperSagas';
import { invokeIfFunction } from 'utils/app.util';
import { toTaskToolstring } from 'utils/route.util';
import { FormikFormStatus } from 'app/app.constants';
import toolstringService from 'services/toolstring.service';
import simulationService from 'services/simulation.service.js';
import taskService from 'services/task.service';
import { ToolType } from 'features/projects/tool/tool.constants';
import { EQUIPMENT_ACTIONS } from 'features/equipment/equipment.constants';
import { SimulationState } from 'features/projects/tasks/task/simulation/simulation.constants';

export function* requestToolstringsForTaskAsync(action) {
  const { projectId, taskId } = action;

  const toolstrings = yield call(
    toolstringService.getToolStringsForTask,
    projectId,
    taskId,
  );

  yield put(receiveTaskToolstrings(projectId, taskId, toolstrings));

  return toolstrings;
}

export function* requestToolstringForTaskAsync(action) {
  const { projectId, taskId, toolstringId, isMobilise } = action;

  const toolstring = yield call(
    toolstringService.getToolString,
    projectId,
    taskId,
    toolstringId,
    isMobilise,
  );

  yield put(receiveTaskToolstring(projectId, taskId, toolstring));

  yield call(requestToolstringSectionsAsync, {
    projectId,
    taskId,
    toolstringId,
  });

  return toolstring;
}

export function* requestTaskToolstringsPageOnLoadAsync(action) {
  const { projectId, taskId } = action;

  yield put(getTaskById(projectId, taskId)); // TODO: convert to saga
  const toolstrings = yield call(requestToolstringsForTaskAsync, {
    projectId,
    taskId,
  });

  return toolstrings;
}

export function* requestToggleToolstringFavoriteAsync(action) {
  const { projectId, taskId, toolstringId } = action;

  const toolstring = yield call(
    toolstringService.toggleToolstringFavorite,
    projectId,
    taskId,
    toolstringId,
  );

  yield put(receiveTaskToolstring(projectId, taskId, toolstring));

  return toolstring;
}

export function* requestDuplicateToolstringAndRedirectAsync(action) {
  const { projectId, taskId, toolstringId } = action;

  const duplicatedToolstring = yield call(
    toolstringService.duplicateToolstring,
    projectId,
    taskId,
    toolstringId,
  );

  yield put(receiveTaskToolstring(projectId, taskId, duplicatedToolstring));

  yield put(
    push(
      toTaskToolstring(projectId, taskId, duplicatedToolstring.toolStringId),
    ),
  );

  return duplicatedToolstring;
}

export function* requestDeleteToolstringAndRedirectAsync(action) {
  const {
    taskId,
    projectId,
    activeToolstringId,
    toolstringId: toolstringIdToDelete,
  } = action;

  yield call(
    toolstringService.deleteToolString,
    projectId,
    taskId,
    toolstringIdToDelete,
  );

  const [firstToolstring] = yield call(requestToolstringsForTaskAsync, {
    projectId,
    taskId,
  });

  if (toolstringIdToDelete.toString() === activeToolstringId.toString()) {
    yield put(
      push(toTaskToolstring(projectId, taskId, firstToolstring?.toolStringId)),
    );
  }
}

export function* requestCreateToolstringFromTemplateForTaskAsync(action) {
  const { projectId, taskId, toolstringTemplateId } = action;

  const createdToolstring = yield call(
    toolstringService.createToolstringFromTemplate,
    projectId,
    taskId,
    toolstringTemplateId,
  );

  yield put(receiveTaskToolstring(projectId, taskId, createdToolstring));

  yield put(
    redirectToTaskToolstringEdit(
      projectId,
      taskId,
      createdToolstring.toolStringId,
    ),
  );

  return createdToolstring;
}

export function* submitCreateToolstringFromTemplateForTaskAsync(action) {
  yield put(toggleModal({ modalId: MODAL.CREATE_TOOLSTRING_MODAL_ID }));
  yield call(requestCreateToolstringFromTemplateForTaskAsync, action);
}

export function* requestCreateToolstringForTaskAsync(action) {
  const { projectId, taskId, toolstring } = action;

  const createdToolstring = yield call(
    toolstringService.createToolString,
    projectId,
    taskId,
    toolstring,
  );

  yield put(receiveTaskToolstring(projectId, taskId, createdToolstring));

  yield put(
    redirectToTaskToolstringEdit(
      projectId,
      taskId,
      createdToolstring.toolStringId,
    ),
  );

  return createdToolstring;
}

export function* submitCreateToolstringForTaskAsync(action) {
  const { setSubmitting, setStatus } = action;

  try {
    yield call(requestCreateToolstringForTaskAsync, action);
    yield put(toggleModal({ modalId: MODAL.CREATE_TOOLSTRING_MODAL_ID }));
    invokeIfFunction(setStatus, FormikFormStatus.SUCCESS);
  } catch (error) {
    invokeIfFunction(setStatus, FormikFormStatus.ERROR);
    throw error;
  } finally {
    invokeIfFunction(setSubmitting, false);
  }
}

export function* requestUpdateToolstringForTaskAsync(action) {
  const { toolstring, toolstringId, projectId, taskId } = action;

  const updatedToolstring = yield call(
    toolstringService.updateToolString,
    projectId,
    taskId,
    toolstringId,
    toolstring,
  );

  yield put(receiveTaskToolstring(projectId, taskId, updatedToolstring));

  return updatedToolstring;
}

export function* requestUpdateTemplateAsync(action) {
  const { toolstring, templateId } = action;

  yield call(toolService.updateTemplate, templateId, toolstring);

  const updatedToolstring = yield call(requestGetTemplateForEditingAsync, {
    templateId,
  });

  return updatedToolstring;
}

export function* requestCreateToolstringToolAsync(action) {
  const { projectId, taskId, toolstringId, tool } = action;

  const createdToolstringTool = yield call(
    toolstringService.createToolstringItem,
    projectId,
    taskId,
    toolstringId,
    tool,
  );

  yield put(
    receiveToolstringItem(
      projectId,
      taskId,
      toolstringId,
      createdToolstringTool,
    ),
  );

  return createdToolstringTool;
}

export function* requestCreateTemplateToolstringToolAsync(action) {
  const { toolstringId, tool } = action;

  const createdToolstringTool = yield call(
    toolService.createTemplateItem,
    toolstringId,
    tool,
  );

  return createdToolstringTool;
}

export function* requestUpdateToolstringItemAsync(action) {
  const { projectId, taskId, toolstringId, toolstringItem } = action;

  const updateToolstringItem = yield call(
    toolstringItem.type === ToolType.ASSEMBLY_TOOL
      ? toolstringService.updateToolstringAssemblyTool
      : toolstringService.updateToolstringItem,
    projectId,
    taskId,
    toolstringId,
    toolstringItem,
  );

  yield call(requestToolstringForTaskAsync, {
    projectId,
    taskId,
    toolstringId,
  });

  return updateToolstringItem;
}

export function* requestUpdatePlannedToolstringItemAsync(action) {
  const { projectId, taskId, toolstringId, toolstringItem } = action;

  const updateToolstringItem = yield call(
    toolstringItem.type === ToolType.ASSEMBLY_TOOL
      ? toolstringService.updateToolstringAssemblyTool
      : toolstringService.updatePlannedToolstringItem,
    projectId,
    taskId,
    toolstringId,
    toolstringItem,
  );

  yield call(requestToolstringForTaskAsync, {
    projectId,
    taskId,
    toolstringId,
  });

  return updateToolstringItem;
}

export function* requestUpdateNotPlannedToolstringItemAsync(action) {
  const { projectId, taskId, toolstringId, toolstringItem } = action;

  const updateToolstringItem = yield call(
    toolstringItem.type === ToolType.ASSEMBLY_TOOL
      ? toolstringService.updateToolstringNotPlannedAssemblyTool
      : toolstringService.updatePlannedToolstringItem,
    projectId,
    taskId,
    toolstringId,
    toolstringItem,
  );

  yield call(requestToolstringForTaskAsync, {
    projectId,
    taskId,
    toolstringId,
  });

  return updateToolstringItem;
}

export function* requestUpdateSerialNumbersFromTaskAsync(action) {
  const { projectId, fromTaskId, toTaskId, toToolStringId } = action;

  const updateToolstring = yield call(
    taskService.updateSerialNumbersFromTask,
    projectId,
    fromTaskId,
    toTaskId,
    toToolStringId,
  );

  yield put(receiveTaskToolstring(projectId, toTaskId, updateToolstring));

  yield call(requestToolstringSectionsAsync, {
    projectId: projectId,
    taskId: toTaskId,
    toolstringId: toToolStringId,
  });

  return updateToolstring;
}

export function* requestDuplicateToolstringItemAsync(action) {
  const { projectId, taskId, toolstringId, tool } = action;

  const duplicatedToolstringTool = yield call(
    toolstringService.duplicateToolstringItem,
    projectId,
    taskId,
    toolstringId,
    tool,
  );

  yield call(requestToolstringForTaskAsync, {
    projectId,
    taskId,
    toolstringId,
  });

  return duplicatedToolstringTool;
}

export function* requestDuplicateTemplateItemAsync(action) {
  const { templateId, tool } = action;

  yield call(toolService.duplicateTemplateItem, templateId, tool);

  const updatedToolstring = yield call(requestGetTemplateForEditingAsync, {
    templateId,
  });

  return updatedToolstring;
}

export function* requestToggleToolstringItemBreakpointAsync(action) {
  const { projectId, taskId, toolstringId, toolstringItemId } = action;

  const updatedToolstringItem = yield call(
    toolstringService.toggleToolstringItemBreakpoint,
    projectId,
    taskId,
    toolstringId,
    toolstringItemId,
  );

  yield put(
    receiveToolstringItem(
      projectId,
      taskId,
      toolstringId,
      updatedToolstringItem,
    ),
  );

  yield call(requestToolstringSectionsAsync, {
    projectId,
    taskId,
    toolstringId,
  });

  return updatedToolstringItem;
}

export function* requestDeleteToolstringToolAsync(action) {
  const { projectId, taskId, toolstringId, toolstringItemId } = action;

  yield call(
    toolstringService.deleteToolstringItem,
    projectId,
    taskId,
    toolstringId,
    toolstringItemId,
  );

  const updatedToolstring = yield call(requestToolstringForTaskAsync, {
    projectId,
    taskId,
    toolstringId,
  });

  return updatedToolstring;
}

export function* requestDeleteTemplateToolAsync(action) {
  const { toolstringItemId, templateId } = action;

  yield call(toolService.deleteTemplateItem, templateId, toolstringItemId);

  const updatedToolstring = yield call(requestGetTemplateForEditingAsync, {
    templateId,
  });

  return updatedToolstring;
}

export function* requestDeleteTemplateToolAssemblyToolAsync(action) {
  const { toolAssemblyToolId, templateId } = action;

  yield call(
    toolService.deleteTemplateToolAssemblyTool,
    templateId,
    toolAssemblyToolId,
  );

  const updatedToolstring = yield call(requestGetTemplateForEditingAsync, {
    templateId,
  });

  return updatedToolstring;
}

export function* requestGetTemplateForEditingAsync(action) {
  const { templateId } = action;

  const template = yield call(toolService.getTemplate, templateId);

  yield put(receiveBhaTemplateForEditing(template));

  const sections = yield call(requestGetTemplateSectionsForEditingAsync, {
    templateId,
  });

  yield put(receiveBhaTemplateSectionsForEditing(sections));

  return template;
}

export function* requestGetTemplateSectionsForEditingAsync(action) {
  const { templateId } = action;

  const sections = yield call(toolService.getTemplateSections, templateId);

  return sections;
}

export function* requestDeleteToolstringAssemblyToolAsync(action) {
  const {
    projectId,
    taskId,
    toolstringId,
    toolstringItemId,
    toolAssemblyToolId,
  } = action;

  yield call(
    toolstringService.deleteToolstringAssemblyTool,
    projectId,
    taskId,
    toolstringId,
    toolstringItemId,
    toolAssemblyToolId,
  );

  const updatedToolstring = yield call(requestToolstringForTaskAsync, {
    projectId,
    taskId,
    toolstringId,
  });

  return updatedToolstring;
}

export function* requestSortToolstringToolsAsync(action) {
  const { projectId, taskId, toolstringId, sortedTool, affectedTool } = action;

  const sortedToolSequence = sortedTool.get('sequence');
  const affectedToolSequence = affectedTool.get('sequence');
  const sortedToolstringToolId = sortedTool.get('toolstringItemId');

  const updatedToolstringToolSequences = yield call(
    toolstringService.updateToolstringItemSequence,
    projectId,
    taskId,
    toolstringId,
    sortedToolstringToolId,
    {
      oldSequenceNumber: sortedToolSequence,
      newSequenceNumber: affectedToolSequence,
    },
  );

  yield put(
    receiveToolstringToolsUpdatedSequences(
      toolstringId,
      updatedToolstringToolSequences,
    ),
  );

  yield call(requestToolstringSectionsAsync, {
    projectId,
    taskId,
    toolstringId,
  });

  return updatedToolstringToolSequences;
}

export function* requestSortToolstringAttachmentsAsync(action) {
  const {
    projectId,
    taskId,
    toolstringId,
    ownerId,
    sortedAttachment,
    affectedAttachment,
  } = action;

  const toolstringItem = yield call(
    toolstringService.updateToolStringAttachmentSequence,
    projectId,
    taskId,
    toolstringId,
    ownerId,
    sortedAttachment.get('toolstringItemId'),
    {
      oldSequenceNumber: sortedAttachment.get('sequence'),
      newSequenceNumber: affectedAttachment.get('sequence'),
    },
  );

  yield put(
    receiveToolstringItem(projectId, taskId, toolstringId, toolstringItem),
  );

  yield call(requestToolstringSectionsAsync, {
    projectId,
    taskId,
    toolstringId,
  });

  return toolstringItem;
}

export function* requestSortTemplateToolsAsync(action) {
  const { templateId, sortedTool, affectedTool } = action;

  const sortedToolSequence = sortedTool.get('sequence');
  const affectedToolSequence = affectedTool.get('sequence');
  const sortedToolstringToolId = sortedTool.get('toolstringItemId');

  yield call(
    toolService.updateTemplateItemSequence,
    templateId,
    sortedToolstringToolId,
    {
      oldSequenceNumber: sortedToolSequence,
      newSequenceNumber: affectedToolSequence,
    },
  );

  const updatedToolstring = yield call(requestGetTemplateForEditingAsync, {
    templateId,
  });

  return updatedToolstring;
}

export function* requestSortToolstringAssemblyToolsAsync(action) {
  const {
    projectId,
    taskId,
    toolstringId,
    toolstringItemId,
    sortedToolstringAssemblyTool,
    affectedToolstringAssemblyTool,
  } = action;

  const sortedToolSequence = sortedToolstringAssemblyTool.get('sequence');
  const affectedToolSequence = affectedToolstringAssemblyTool.get('sequence');

  const sortedToolAssemblyToolId =
    sortedToolstringAssemblyTool.get('toolAssemblyToolId');

  const toolstringItem = yield call(
    toolstringService.updateToolstringAssemblyToolSequence,
    projectId,
    taskId,
    toolstringId,
    toolstringItemId,
    sortedToolAssemblyToolId,
    {
      oldSequenceNumber: sortedToolSequence,
      newSequenceNumber: affectedToolSequence,
    },
  );

  yield put(
    receiveToolstringItem(projectId, taskId, toolstringId, toolstringItem),
  );

  yield call(requestToolstringSectionsAsync, {
    projectId,
    taskId,
    toolstringId,
  });

  return toolstringItem;
}

export function* requestSortTemplateAssemblyToolsAsync(action) {
  const {
    templateId,
    sortedToolstringAssemblyTool,
    affectedToolstringAssemblyTool,
  } = action;

  const sortedToolSequence = sortedToolstringAssemblyTool.get('sequence');
  const affectedToolSequence = affectedToolstringAssemblyTool.get('sequence');

  const sortedToolAssemblyToolId =
    sortedToolstringAssemblyTool.get('toolAssemblyToolId');

  yield call(
    toolService.updateTemplateAssemblyToolSequence,
    templateId,
    sortedToolAssemblyToolId,
    {
      oldSequenceNumber: sortedToolSequence,
      newSequenceNumber: affectedToolSequence,
    },
  );

  const updatedToolstring = yield call(requestGetTemplateForEditingAsync, {
    templateId,
  });

  return updatedToolstring;
}

export function* requestCreateToolstringAssemblyToolAsync(action) {
  const { tool, projectId, taskId, toolstringId, toolstringItemId } = action;

  const toolstringAssemblyTool = yield call(
    toolstringService.createToolstringAssemblyTool,
    projectId,
    taskId,
    toolstringId,
    toolstringItemId,
    tool,
  );

  yield put(
    receiveToolstringAssemblyTool(
      projectId,
      taskId,
      toolstringId,
      toolstringItemId,
      toolstringAssemblyTool,
    ),
  );

  return toolstringAssemblyTool;
}

export function* requestToolstringSectionsAsync(action) {
  const { projectId, taskId, toolstringId } = action;

  const sections = yield call(
    toolstringService.getToolstringSections,
    projectId,
    taskId,
    toolstringId,
  );

  yield put(
    receiveTaskToolstringSections(projectId, taskId, toolstringId, sections),
  );

  return sections;
}

export function* requestToolstringTemplatesAsync() {
  const templates = yield call(toolstringService.getAllToolstringTemplates);

  yield put(receiveToolstringTemplates(templates));

  return templates;
}

export function* requestCreateToolAttachment(action) {
  const { projectId, taskId, toolstringId, owner, tool } = action;

  return yield call(
    toolstringService.createToolAttachment,
    projectId,
    taskId,
    toolstringId,
    owner,
    tool,
  );
}

export function* requestDeleteToolstringAttachmentAsync(action) {
  const { projectId, taskId, toolstringId, toolstringItemId, ownerId } = action;

  yield call(
    toolstringService.deleteToolstringAttachment,
    projectId,
    taskId,
    toolstringId,
    ownerId,
    toolstringItemId,
  );

  return yield call(requestToolstringForTaskAsync, {
    projectId,
    taskId,
    toolstringId,
  });
}

export function* requestCreateToolstringTemplateAttachment(action) {
  const { toolstringId, owner, tool } = action;

  return yield call(
    toolstringService.createToolstringTemplateAttachment,
    toolstringId,
    owner,
    tool,
  );
}

export function* requestDeleteToolstringTemplateAttachmentAsync(action) {
  const { toolstringId, ownerId, toolstringItemId } = action;

  const templateId = toolstringId;

  yield call(
    toolstringService.deleteToolstringTemplateAttachment,
    toolstringId,
    ownerId,
    toolstringItemId,
  );

  const updatedToolstring = yield call(requestGetTemplateForEditingAsync, {
    templateId,
  });

  return updatedToolstring;
}

export function* requestSortToolstringTemplateAttachmentsAsync(action) {
  const { toolstringId, ownerId, sortedAttachment, affectedAttachment } =
    action;

  const templateId = toolstringId;

  const toolstringItem = yield call(
    toolstringService.updateToolStringTemplateAttachmentSequence,
    toolstringId,
    ownerId,
    sortedAttachment.get('toolstringItemId'),
    {
      oldSequenceNumber: sortedAttachment.get('sequence'),
      newSequenceNumber: affectedAttachment.get('sequence'),
    },
  );

  yield call(requestGetTemplateForEditingAsync, {
    templateId,
  });

  return toolstringItem;
}

export function* requestUpdateToolStringToolStatus(action) {
  const { projectId, taskId, toolstringId, toolstringItemId, status } = action;

  return yield call(
    toolstringService.updateToolStringToolStatus,
    projectId,
    taskId,
    toolstringId,
    toolstringItemId,
    status,
  );
}

function* requestRedirectToBhaEdit(action) {
  const { projectId, taskId, toolstringId } = action;
  const simulations = yield call(
    simulationService.getSimulationsForTask,
    projectId,
    taskId,
    [SimulationState.PLANNED],
  );
  for (const currentSimulation of simulations) {
    yield call(
      simulationService.toggleSimulationPlanned,
      projectId,
      taskId,
      currentSimulation.simulationId,
    );
  }

  yield put(
    push(`/projects/${projectId}/plan/${taskId}/bha/${toolstringId}/edit`),
  );
}

export default function* root() {
  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_GET_TOOLSTRINGS_FOR_TASK,
    callAsync,
    requestToolstringsForTaskAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_GET_TOOLSTRING_FOR_TASK,
    callAsync,
    requestToolstringForTaskAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_TASK_TOOLSTRINGS_PAGE_ON_LOAD,
    callAsync,
    requestTaskToolstringsPageOnLoadAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_DUPLICATE_TOOLSTRING_FOR_TASK_AND_REDIRECT,
    callAsync,
    requestDuplicateToolstringAndRedirectAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_FOR_TASK_AND_REDIRECT,
    callAsync,
    requestDeleteToolstringAndRedirectAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_TOGGLE_TOOLSTRING_FAVORITE,
    callAsync,
    requestToggleToolstringFavoriteAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_CREATE_TOOLSTRING_FOR_TASK,
    callAsync,
    requestCreateToolstringForTaskAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.SUBMIT_CREATE_TOOLSTRING_FOR_TASK,
    callAsync,
    submitCreateToolstringForTaskAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_CREATE_TOOLSTRING_FOR_TASK_FROM_TEMPLATE,
    callAsync,
    requestCreateToolstringFromTemplateForTaskAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.SUBMIT_CREATE_TOOLSTRING_FROM_TEMPLATE_FOR_TASK,
    callAsync,
    submitCreateToolstringFromTemplateForTaskAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_UPDATE_TOOLSTRING_FOR_TASK,
    callAsync,
    requestUpdateToolstringForTaskAsync,
  );

  yield takeEvery(
    EQUIPMENT_ACTIONS.REQUEST_UPDATE_TEMPLATE_FOR_EDITING,
    callAsync,
    requestUpdateTemplateAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_CREATE_TOOLSTRING_TOOL,
    callAsync,
    requestCreateToolstringToolAsync,
  );

  yield takeEvery(
    EQUIPMENT_ACTIONS.CREATE_TEMPLATE_TOOL,
    callAsync,
    requestCreateTemplateToolstringToolAsync,
  );

  yield takeEvery(
    EQUIPMENT_ACTIONS.REQUEST_BHA_TEMPLATE_FOR_EDITING,
    callAsync,
    requestGetTemplateForEditingAsync,
  );

  yield takeEvery(
    EQUIPMENT_ACTIONS.REQUEST_BHA_TEMPLATE_SECTIONS_FOR_EDITING,
    callAsync,
    requestGetTemplateSectionsForEditingAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_UPDATE_TOOLSTRING_ITEM,
    callAsync,
    requestUpdateToolstringItemAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_UPDATE_PLANNED_TOOLSTRING_ITEM,
    callAsync,
    requestUpdatePlannedToolstringItemAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_UPDATE_NOT_PLANNED_TOOLSTRING_ITEM,
    callAsync,
    requestUpdateNotPlannedToolstringItemAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_UPDATE_SERIAL_NUMBERS_FROM_TASK,
    callAsync,
    requestUpdateSerialNumbersFromTaskAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_DUPLICATE_TOOLSTRING_ITEM,
    callAsync,
    requestDuplicateToolstringItemAsync,
  );

  yield takeEvery(
    EQUIPMENT_ACTIONS.REQUEST_DUPLICATE_TEMPLATE_ITEM,
    callAsync,
    requestDuplicateTemplateItemAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_ITEM,
    callAsync,
    requestDeleteToolstringToolAsync,
  );

  yield takeEvery(
    EQUIPMENT_ACTIONS.DELETE_TEMPLATE_TOOL,
    callAsync,
    requestDeleteTemplateToolAsync,
  );

  yield takeEvery(
    EQUIPMENT_ACTIONS.DELETE_TEMPLATE_TOOLASSEMBLY_TOOL,
    callAsync,
    requestDeleteTemplateToolAssemblyToolAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_TOOLS,
    callAsync,
    requestSortToolstringToolsAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_ATTACHMENTS,
    callAsync,
    requestSortToolstringAttachmentsAsync,
  );

  yield takeEvery(
    EQUIPMENT_ACTIONS.REQUEST_SORT_TEMPLATE_TOOLS,
    callAsync,
    requestSortTemplateToolsAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_TOGGLE_TOOLSTRING_ITEM_BREAKPOINT,
    callAsync,
    requestToggleToolstringItemBreakpointAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_ASSEMBLY_TOOLS,
    callAsync,
    requestSortToolstringAssemblyToolsAsync,
  );

  yield takeEvery(
    EQUIPMENT_ACTIONS.REQUEST_SORT_TEMPLATE_ASSEMBLY_TOOLS,
    callAsync,
    requestSortTemplateAssemblyToolsAsync,
  );

  takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_TOOLSTRING_SECTIONS,
    callAsync,
    requestToolstringSectionsAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_CREATE_TOOLSTRING_ASSEMBLY_TOOL,
    callAsync,
    requestCreateToolstringAssemblyToolAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_ASSEMBLY_TOOL,
    callAsync,
    requestDeleteToolstringAssemblyToolAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_TOOLSTRING_TEMPLATES,
    callAsync,
    requestToolstringTemplatesAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_ATTACH_TOOL,
    callAsync,
    requestCreateToolAttachment,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_ATTACHMENT,
    callAsync,
    requestDeleteToolstringAttachmentAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.UDPATE_TOOLSTRING_TOOL_STATUS,
    callAsync,
    requestUpdateToolStringToolStatus,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_ATTACH_TOOL_TO_TEMPLATE,
    callAsync,
    requestCreateToolstringTemplateAttachment,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_TEMPLATE_ATTACHMENT,
    callAsync,
    requestDeleteToolstringTemplateAttachmentAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_TEMPLATE_ATTACHMENTS,
    callAsync,
    requestSortToolstringTemplateAttachmentsAsync,
  );

  yield takeEvery(
    TOOLSTRING_ACTIONS.REQUEST_REDIRECT_TO_BHA_EDIT,
    callAsync,
    requestRedirectToBhaEdit,
  );
}
