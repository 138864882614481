import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import DataAcquisitionContainer from 'features/projects/tasks/task/dataAcquisition/DataAcquisitionContainer';
import {
  getDataAcquisitionForTask,
  removeDataAcquisitionForTask,
  updateDataAcquisition,
} from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.actions';
import {
  getDataAcquisitionFromState,
  getDebrisTypesFromState,
  getFrictionReducerTypesFromState,
  getGlycolTypesFromState,
  getGreaseTypesFromState,
  getInjectionMediumsFromState,
  getProductionMediumsFromState,
} from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.selectors';
import { DATA_ACQUISITION_FORM } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import { getActivityTaskByTaskId } from 'features/projects/activities/activities.selectors';

const DataAcquisitionDataFetchWrapper = ({
  taskId,
  projectId,
  debrisTypes,
  greaseTypes,
  glycolTypes,
  dataAcquisition,
  injectionMediums,
  productionMediums,
  loadDataAcquisition,
  frictionReducerTypes,
  changeDataAcquisition,
  unloadDataAcquisition,
}) => {
  const task = useSelector((state) => getActivityTaskByTaskId(taskId)(state));

  useEffect(() => {
    loadDataAcquisition(projectId, taskId);

    return () => unloadDataAcquisition();
  }, [projectId, taskId, loadDataAcquisition, unloadDataAcquisition]);
  return dataAcquisition && dataAcquisition.size > 0 ? (
    <DataAcquisitionContainer
      task={task}
      taskId={taskId}
      projectId={projectId}
      debrisTypes={debrisTypes}
      greaseTypes={greaseTypes}
      glycolTypes={glycolTypes}
      dataAcquisition={dataAcquisition}
      injectionMediums={injectionMediums}
      productionMediums={productionMediums}
      frictionReducerTypes={frictionReducerTypes}
      changeDataAcquisition={changeDataAcquisition}
    />
  ) : null;
};

const mapStateToProps = (state, { taskId }) => ({
  dataAcquisition: getDataAcquisitionFromState(state, taskId),
  greaseTypes: getGreaseTypesFromState(state),
  debrisTypes: getDebrisTypesFromState(state),
  glycolTypes: getGlycolTypesFromState(state),
  injectionMediums: getInjectionMediumsFromState(state),
  productionMediums: getProductionMediumsFromState(state),
  frictionReducerTypes: getFrictionReducerTypesFromState(state),
});

const mapDispatchToProps = {
  changeDataAcquisition: updateDataAcquisition,
  loadDataAcquisition: getDataAcquisitionForTask,
  unloadDataAcquisition: removeDataAcquisitionForTask,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: DATA_ACQUISITION_FORM.EDIT_FORM_ID,
  }),
)(DataAcquisitionDataFetchWrapper);
