import { useEffect, useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import { Format } from 'app/app.constants';
import { formatDate } from 'utils/format.util';

const DateTimePickerFormik = ({
  form,
  field,
  classes,
  margin = 'normal',
  DateTimePickerProps,
  format = Format.time,
  destroyOnUnmount = false,
  inputVariant = 'standard',
  disableFuture = false,
  ...rest
}) => {
  const { value, name, onBlur } = field;
  const { getFieldMeta, setFieldValue } = form;

  useEffect(
    () => () => {
      if (destroyOnUnmount) {
        // clear value and unregister field
        setFieldValue(name, undefined);
      }
    },
    [name, setFieldValue, destroyOnUnmount],
  );

  const { error } = getFieldMeta(name);

  const onChangeHandler = useCallback(
    (value) => {
      return setFieldValue(name, formatDate(value));
    },
    [setFieldValue, name],
  );

  return (
    <KeyboardDateTimePicker
      autoOk
      fullWidth
      onBlur={onBlur}
      ampm={false}
      value={value}
      format={format}
      margin={margin}
      onChange={onChangeHandler}
      inputVariant={inputVariant}
      helperText={error ? error : format}
      FormHelperTextProps={{
        error: !!error,
      }}
      classes={{ root: classes.customTextField }}
      disableFuture={disableFuture}
      InputProps={{
        style: {
          fontSize: 14,
        },
      }}
      {...DateTimePickerProps}
      {...rest}
    />
  );
};

const styles = () => ({
  customTextField: {
    '& input::placeholder': {
      fontSize: '10',
    },
  },
  root: {
    '& .MuiFormLabel-root': {
      fontSize: '10',
    },
  },
});

export default withStyles(styles)(DateTimePickerFormik);
