export const ACTIVITIES_ACTIONS = {
  EDIT_TIMER: 'EDIT_TIMER',
  CREATE_TIMER: 'CREATE_TIMER',
  END_OPERATION: 'END_OPERATION',
  INITATE_COMPLETE_TASK: 'INITATE_COMPLETE_TASK',
  COMPLETE_TASK: 'COMPLETE_TASK',
  GET_ALL_TASKS: 'GET_ALL_TASKS',
  INITIATE_PAUSE_ACTIVITY: 'INITIATE_PAUSE_ACTIVITY',
  PAUSE_ACTIVITY: 'PAUSE_ACTIVITY',
  INITIATE_SORT_ACTIVITIES: 'INITIATE_SORT_ACTIVITIES',
  INITIATE_SORT_ACTIVITIES_NO_NOTIFICATION:
    'INITIATE_SORT_ACTIVITIES_NO_NOTIFICATION',
  BACKEND_SORT_ACTIVITIES: 'BACKEND_SORT_ACTIVITIES',
  BACKEND_SORT_ACTIVITIES_NO_NOTIFICATION:
    'BACKEND_SORT_ACTIVITIES_NO_NOTIFICATION',
  SORT_ACTIVITIES: 'SORT_ACTIVITIES',
  UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
  INITIATE_UPDATE_ACTIVITY: 'INITIATE_UPDATE_ACTIVITY',
  INITIATE_UPDATE_ACTIVITY_CONFIRMATION:
    'INITIATE_UPDATE_ACTIVITY_CONFIRMATION',
  INITIATE_UPDATE_ACTIVITY_TIMES: 'INITIATE_UPDATE_ACTIVITY_TIMES',
  INITIATE_UPDATE_ACTIVITY_FORM_WITH_NOTIFICATION:
    'INITIATE_UPDATE_ACTIVITY_FORM_WITH_NOTIFICATION',
  INITIATE_UPDATE_ACTIVITY_FORM_WITHOUT_NOTIFICATION:
    'INITIATE_UPDATE_ACTIVITY_FORM_WITHOUT_NOTIFICATION',
  START_OPERATION: 'START_OPERATION',
  START_OPERATION_INITIATE: 'START_OPERATION_INITIATE',
  CONFIRM_ACTIVITY_GO_BACK: 'CONFIRM_ACTIVITY_GO_BACK',
  CONFIRMED_ACTIVITY_GO_BACK: 'CONFIRMED_ACTIVITY_GO_BACK',
  ACTIVITY_GO_BACK: 'ACTIVITY_GO_BACK',
  INITIATE_COMPLETE_ACTIVITY: 'INITIATE_COMPLETE_ACTIVITY',
  COMPLETE_ACTIVITY: 'COMPLETE_ACTIVITY',
  GET_PROJECT_STATUS: 'GET_PROJECT_STATUS',
  QUICK_ADD_ACTIVITY: 'QUICK_ADD_ACTIVITY',
  STOP_PROJECT_TIMER: 'STOP_PROJECT_TIMER',
  GET_PROJECT_TIMERS: 'GET_PROJECT_TIMERS',
  START_PROJECT_TIMER: 'START_PROJECT_TIMER',
  CANCEL_PROJECT_TIMER: 'CANCEL_PROJECT_TIMER',
  ADD_ACTIVITY_DEFAULT: 'ADD_ACTIVITY_DEFAULT',
  ACTIVITY_LIST_LOADED: 'ACTIVITY_LIST_LOADED',
  ACTIVITY_LIST_UNLOADED: 'ACTIVITY_LIST_UNLOADED',
  COMPLETE_TASK_DEFAULT: 'COMPLETE_TASK_DEFAULT',
  COMPLETE_TASK_WITH_CONTINGENCY: 'COMPLETE_TASK_WITH_CONTINGENCY',
  ACTIVITIES_SELECT_TASK: 'ACTIVITIES_SELECT_TASK',
  GET_ALL_ACTIVITY_TYPES: 'GET_ALL_ACTIVITY_TYPES',
  ACTIVITIES_INITIALIZED: 'ACTIVITIES_INITIALIZED',
  CONFIRM_DELETE_ACTIVITY: 'CONFIRM_DELETE_ACTIVITY',
  DELETE_ACTIVITY_SUCCESS_DIALOG: 'DELETE_ACTIVITY_SUCCESS_DIALOG',
  INITIATE_CONFIRM_DELETE_ACTIVITY_WITH_NOTIFICATION:
    'INITIATE_CONFIRM_DELETE_ACTIVITY_WITH_NOTIFICATION',
  INITIATE_CONFIRM_DELETE_ACTIVITY_WITHOUT_NOTIFICATION:
    'INITIATE_CONFIRM_DELETE_ACTIVITY_WITHOUT_NOTIFICATION',
  COMPLETE_AND_REPEAT_TASK: 'COMPLETE_AND_REPEAT_TASK',
  GET_PROJECT_TIMERS_ACTIVE: 'GET_PROJECT_TIMERS_ACTIVE',
  GET_PROJECT_TIMERS_STOPPED: 'GET_PROJECT_TIMERS_STOPPED',
  GET_WAITING_ACTIVITY_TYPES: 'GET_WAITING_ACTIVITY_TYPES',
  COMPLETE_ACTIVITY_ABORT_TASK: 'COMPLETE_ACTIVITY_ABORT_TASK',
  INITIATE_CREATE_ACTIVITY_WITH_NOTIFICATION:
    'INITIATE_CREATE_ACTIVITY_WITH_NOTIFICATION',
  INITIATE_CREATE_ACTIVITY_WITHOUT_NOTIFICATION:
    'INITIATE_CREATE_ACTIVITY_WITHOUT_NOTIFICATION',
  PROJECT_ACTIVITIES_ADD_ACTIVITY: 'PROJECT_ACTIVITIES_ADD_ACTIVITY',
  COMPLETE_TASK_AND_END_OPERATION: 'COMPLETE_TASK_AND_END_OPERATION',
  ACTIVITIES_UNINITIALIZED: 'ACTIVITIES_UNINITIALIZED',
  GET_POINT_IN_TIME_ACTIVITY_TYPES: 'GET_POINT_IN_TIME_ACTIVITY_TYPES',
  CREATE_NEW_POINT_IN_TIME_ACTIVITY: 'CREATE_NEW_POINT_IN_TIME_ACTIVITY',
  INITIATE_CREATE_NEW_POINT_IN_TIME_ACTIVITY:
    'INITIATE_CREATE_NEW_POINT_IN_TIME_ACTIVITY',
  PROJECT_ACTIVITIES_DELETE_ACTIVITY: 'PROJECT_ACTIVITIES_DELETE_ACTIVITY',
  PROJECT_ACTIVITIES_DUPLICATE_ACTIVITY:
    'PROJECT_ACTIVITIES_DUPLICATE_ACTIVITY',
  GET_PROJECT_ACTIVITIES: 'GET_PROJECT_ACTIVITIES',
  GET_TASK_ACTIVITIES: 'GET_TASK_ACTIVITIES',
  GET_ALL_PROJECT_ACTIVITIES: 'GET_ALL_PROJECT_ACTIVITIES',
  SEND_GO_BACK_ACTIVITY_NOTIFICATION: 'SEND_GO_BACK_ACTIVITY_NOTIFICATION',
  SEND_COMPLETED_ACTIVITY_NOTIFICATION: 'SEND_COMPLETED_ACTIVITY_NOTIFICATION',
  SEND_ACTIVITY_ADDED_NOTIFICATION: 'SEND_ACTIVITY_ADDED_NOTIFICATION',
  SEND_ACTIVITY_UPDATED_NOTIFICATION: 'SEND_ACTIVITY_UPDATED_NOTIFICATION',
  SEND_ACTIVITY_DELETED_NOTIFICATION: 'SEND_ACTIVITY_DELETED_NOTIFICATION',
  SEND_ACTIVITY_PAUSED_NOTIFICATION: 'SEND_ACTIVITY_PAUSED_NOTIFICATION',
  SENT_SUBACTIVITY_ADDED_NOTIFICATION: 'SENT_SUBACTIVITY_ADDED_NOTIFICATION',
  SEND_ACTIVITY_SEQUENCE_UPDATED_NOTIFICATION:
    'SEND_ACTIVITY_SEQUENCE_UPDATED_NOTIFICATION',
  ESTABLISHING_REAL_TIME_CONNECTION_FAILED:
    'ESTABLISHING_REAL_TIME_CONNECTION_FAILED',
  REFRESH_PROJECT_STATUS: 'REFRESH_PROJECT_STATUS',
  UPDATE_ACTIVITY_SET_OR_RETRIEVE_ITEMS:
    'UPDATE_ACTIVITY_SET_OR_RETRIEVE_ITEMS',
  DELETE_ACTIVITY_SET_OR_RETRIEVE_ITEMS:
    'DELETE_ACTIVITY_SET_OR_RETRIEVE_ITEMS',
  DOWNLOAD_ACTIVITY_PDF: 'DOWNLOAD_ACTIVITY_PDF',
  GET_PROJECT_STATUS_MOBILE: 'GET_PROJECT_STATUS_MOBILE',
};

export const EDIT_ACTIVITY_MODAL_ID = 'editActivity';
export const CREATE_ACTIVITY_MODAL_ID = 'ADD_ACTIVITY_MODAL_ID';
export const COMPLETE_TASK_MODAL_ID = 'COMPLETE_TASK_MODAL_ID';
export const END_OPERATION_MODAL_ID = 'END_OPERATION_MODAL_ID';
// export const TIMER_LIST_MODAL_ID = 'TIMER_LIST_MODAL_ID';
export const EDIT_TIMER_ENTRY_MODAL_ID = 'EDIT_TIMER_ENTRY_MODAL_ID';
export const CREATE_TIMER_ENTRY_MODAL_ID = 'CREATE_TIMER_ENTRY_MODAL_ID';

export const CREATE_ACTIVITY_MODE = {
  NORMAL: 1,
  QUICK: 2,
};

export const CREATE_ACTIVITY_FORM = {
  ID: 'createActivity',
  TYPE: 'type',
  TITLE: 'title',
  DEPTH: 'depth',
  DESCRIPTION: 'description',
  MODE: 'mode',
  SEQUENCE: 'sequence',
  ESTIMATED_TIME: 'estimatedTime',
  PARALLEL_ACTIVITY: 'parallelActivity',
};

export const EDIT_ACTIVITY_FORM = {
  ID: 'editActivity',
  ACTIVITY_ID: 'id',
  TYPE_NAME: 'type.name',
  CODE: 'code',
  TITLE: 'title',
  DEPTH: 'depth',
  DESCRIPTION: 'description',
  COMMENTS: 'comments',
  PREVIOUS_ACTIVITY_START_TIME: 'previousActivityStartTime',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  EDITED_DURATION: 'editedDuration',
  STATUS: 'status',
  IS_POINT_IN_TIME: 'isPointInTime',
  WELL_CONTROL_DRILL_TYPE_ID: 'WCD',
  ESTIMATED_TIME: 'estimatedTime',
  PARALLEL_ACTIVITY: 'parallelActivity',
};

export const COMPLETE_TASK_FORM = {
  ID: 'completeTask',
  COMMENTS: 'comments',
  FAILURE_REASON: 'failureReason',
  OBJECTIVES_MET: 'objectivesMet',
  TASK_ID: 'taskId',
};

export const END_OPERATION_FORM = {
  ID: 'endOperation',
  COMMENTS: 'comments',
  FAILURE_REASON: 'failureReason',
  IS_SUCCESSFUL: 'isProjectSuccessful',
};

export const EDIT_TIMER_FORM = {
  ID: 'editTimer',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  EDITED_DURATION: 'editedDuration',
  COMMENT: 'comment',
};

export const CREATE_TIMER_FORM = {
  ID: 'createTimer',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  DURATION: 'duration',
  COMMENT: 'comment',
  TIMER_TYPE: 'typeId',
};

export const STATE = {
  ROOT: 'activities',
  DATA: 'data',
  EXECUTE: 'execute',
  DATA_TASKS: 'tasks',
  DATA_ACTIVITIES: 'activities',
  TIMERS: 'timersByTimerTypeId',
  DATA_SELECTED_TASK_ID: 'selectedTaskId',
  DATA_ACTIVITY_TYPES: 'activityTypes',
  DATA_ACTIVITY_TYPES_WAITING: 'activityTypesWaiting',
  DATA_ACTIVITY_TYPES_POINT_IN_TIME: 'activityTypesPointInTime',
  IS_TIMER_ACTIVE: 'isTimerActive',
};

export const COMPLETE_TASK_MODE = {
  DEFAULT: 1,
  REPEAT: 2,
  END_OPERATION: 3,
  CONTINUE_WITH_CONTINGENCY: 4,
};

export const OBJECTIVES_NOT_MET_REASONS = [
  { id: 0, text: 'Misrun (NPT)' },
  { id: 1, text: 'Well Conditions' },
  { id: 2, text: 'Rig Conditions' },
  { id: 3, text: '3rd Party' },
];

export const ACTIVITY_BUTTONS = {
  WAIT: 1,
  STOP: 2,
  EVENT: 3,
  ADD_NEW: 4,
  UNDO: 5,
};

export const TimerType = {
  TRAC: 2,
};

export const TASK_URLS = {
  REGISTER_OBS_CARD_URL: 'http://obscard.altus.no/',
  ADD_COMPLAINT_URL: 'https://itemsearch.qdom.global/',
  REGISTER_INCIDENT_SYNERGY:
    ' https://altus.synergilife.dnv.com/select/casetype',
};

export const TaskFailReason = {
  NONE: 0,
  MISRUN: 1,
  WELL_CONDITIONS: 2,
  RIG_CONDITIONS: 3,
  THIRD_PARTY: 4,
};

export const TRAC_SUBACTIVITY_CODE = 'TRAC';
