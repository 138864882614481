import { compose } from 'redux';
import takeLast from 'lodash/last';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import { EMPTY_QUANTITY } from 'app/app.constants';
import ThreeJsManagerProvider from 'app/threeJs/ThreeJsManagerProvider';
import { getProjectWellbore } from 'features/projects/wellbore/projectWellbore.actions';
import { getTargetDepthFromState } from 'features/projects/activities/activities.selectors';
import ProjectWellboreTrajectoryContainer from 'app/components/WellboreTrajectory/ProjectWellboreTrajectoryContainer';

const DashboardWellboreTrajectoryContainer = ({
  curve,
  projectId,
  registerDataPointsHandler,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectWellbore(projectId));
  }, [dispatch, projectId]);

  const targetDepth = useSelector(getTargetDepthFromState);

  const [currentDepth, setCurrentDepth] = useState(EMPTY_QUANTITY);

  const onReceiveInitialData = useCallback(
    (readings) => {
      if (!curve) return;

      const reading = readings.find(
        (reading) => reading.name === curve.get('name'),
      );

      var latestDepthData = takeLast(reading.data);

      if (!latestDepthData) return;

      const [_timestamp, _depth, value] = latestDepthData;

      setCurrentDepth({
        hasValue: true,
        unit: curve.get('unit'),
        value: value,
      });
    },
    [curve, setCurrentDepth],
  );

  const onReceiveData = useCallback(
    (readings) => {
      if (!curve) return;

      const reading = readings.find(
        (reading) => reading.name === curve.get('name'),
      );

      if (!reading) return;
      if (!reading.data[0]) return;

      const [_timestamp, _depth, value] = reading.data[0];

      setCurrentDepth({
        hasValue: true,
        unit: curve.get('unit'),
        value: value,
      });
    },
    [curve],
  );

  useEffect(() => {
    registerDataPointsHandler(onReceiveInitialData, onReceiveData);
  }, [onReceiveData, onReceiveInitialData, registerDataPointsHandler]);

  return (
    <ThreeJsManagerProvider>
      <ProjectWellboreTrajectoryContainer
        projectId={projectId}
        targetDepth={targetDepth}
        currentDepth={currentDepth}
      >
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2">Well Trajectory Not Available</Typography>
        </Grid>
      </ProjectWellboreTrajectoryContainer>
    </ThreeJsManagerProvider>
  );
};

DashboardWellboreTrajectoryContainer.propTypes = {
  curveId: PropTypes.number,
  registerDataPointsHandler: PropTypes.func.isRequired,
};

export default compose()(DashboardWellboreTrajectoryContainer);
