import { compose } from 'redux';
import { useMemo, useCallback } from 'react';
import { alpha } from '@material-ui/core/styles';
import withStyles from '@material-ui/styles/withStyles';

import {
  Table,
  TableRow,
  TableHeaderRow,
  TableRowActionsCell,
} from 'altus-ui-components';

import CheetahJobStatusIndicator from 'features/data-exchange/components/CheetahJobStatusIndicator';

const LiveProjectsCheetahJobsTable = ({ classes, cheetahJobs }) => {
  const columns = useMemo(
    () => [
      {
        id: 'status',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          return (
            <TableRowActionsCell container>
              <CheetahJobStatusIndicator cheetahJob={row.original} />
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 2,
        Header: 'ID/UNIT',
        accessor: (cheetahJob) => cheetahJob.get('displayName'),
      },
      {
        xs: 2,
        Header: 'Data Source',
        accessor: (cheetahJob) => cheetahJob.get('sourceName'),
      },
      {
        xs: 2,
        id: 'wellbore-placeholder',
        accessor: () => null,
      },
      {
        xs: 2,
        Header: 'Sensors',
        accessor: (cheetahJob) => cheetahJob.get('sensorCount'),
      },
      {
        xs: 2,
        Header: 'Master',
        accessor: () => 'N/A',
      },
      {
        xs: 2,
        Header: 'Status',
        accessor: (cheetahJob) => cheetahJob.get('statusName'),
      },
      {
        xs: 2,
        id: 'actions-placeholder',
        accessor: () => null,
      },
    ],
    [],
  );

  const renderTableHeaderRowComponent = useCallback(
    (props) => (
      <TableHeaderRow
        {...props}
        classes={{
          root: classes.tableHeaderRowRoot,
        }}
      />
    ),
    [classes],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <TableRow
        {...props}
        classes={{
          root: classes.tableRowRoot,
        }}
      />
    ),
    [classes],
  );

  return (
    <Table
      columns={columns}
      items={cheetahJobs}
      useGlobalFilter={false}
      TableRowComponent={renderTableRowComponent}
      TableHeaderRowComponent={renderTableHeaderRowComponent}
    />
  );
};

const styles = (theme) => ({
  tableHeaderRowRoot: {
    marginBottom: 0,
  },
  tableRowRoot: {
    '&:hover': {
      background: alpha(theme.altus.components.TableRow.hoverColor, 0.2),
    },
  },
});

export default compose(withStyles(styles))(LiveProjectsCheetahJobsTable);
