import { compose } from 'redux';
import { Grid, Box } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { TableRow } from 'altus-ui-components';

import { EMPTY_LIST } from 'app/app.constants';
import ProjectTaskServiceChips from 'app/components/ProjectTaskServiceChips';

const OperationTableRow = ({
  classes,
  item: operation,
  children: cells,
  ...rest
}) => {
  const taskServices = operation.getIn(['project', 'taskServices'], EMPTY_LIST);

  return (
    <TableRow
      {...rest}
      item={operation}
      classes={{
        root: classes.tableRowRoot,
      }}
    >
      <Grid container component={Box} paddingTop={1} paddingBottom={1}>
        {cells}
        {!!taskServices.size && (
          <Grid
            container
            paddingTop={1}
            component={Box}
            paddingLeft={2}
            paddingRight={2}
          >
            <ProjectTaskServiceChips taskServices={taskServices} />
          </Grid>
        )}
      </Grid>
    </TableRow>
  );
};

const styles = () => ({
  tableRowRoot: {
    height: 'auto',
    flexWrap: 'wrap',
    maxHeight: 'none',
  },
});

export default compose(withStyles(styles))(OperationTableRow);
