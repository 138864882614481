import { compose } from 'redux';
import { connect } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Tooltip, IconButton } from '@material-ui/core';

import { formatDate } from 'utils/format.util';
import ReadOnlyField from 'app/components/ReadOnlyField';
import { requestUpdateToolstring } from 'features/projects/tasks/task/toolstring/toolstring.actions';

const TaskToolstringDetailsHeader = ({ classes, toolstring }) => {
  const cableName = toolstring.getIn(['cable', 'name']);

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item xs container alignItems="center" spacing={2}>
        <Grid item>
          <ReadOnlyField
            margin="none"
            label="Cable"
            value={cableName || 'No Cable'}
          />
        </Grid>
        {!cableName && (
          <Grid item xs>
            <Tooltip title="Add Cable to BHA in the Run Simulator">
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Grid item xs container justifyContent="flex-end">
        <Grid item classes={{ root: classes.field }}>
          <ReadOnlyField
            margin="none"
            label="Created by"
            value={toolstring.get('createdByUserFullName')}
          />
        </Grid>
        <Grid item classes={{ root: classes.field }}>
          <ReadOnlyField
            margin="none"
            label="Modified"
            value={formatDate(toolstring.get('modified'))}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0px`,
  },
  field: {
    paddingLeft: theme.spacing(4),
  },
});

export default compose(
  connect(null, {
    dispatchUpdateToolString: requestUpdateToolstring,
  }),
  withStyles(styles),
)(TaskToolstringDetailsHeader);
