import { compose } from 'redux';
import { change } from 'redux-form/immutable';
import { useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { reduxForm, Field, formValueSelector } from 'redux-form/immutable';

import {
  Grid,
  Dialog,
  Button,
  TextField,
  DialogContent,
  InputAdornment,
} from '@material-ui/core';

import {
  ModalHeader,
  ModalActions,
  LoadingButton,
  AutocompleteField,
} from 'altus-ui-components';

import { useModal } from 'altus-modal';

import {
  CREATE_ACTIVITY_MODE,
  CREATE_ACTIVITY_FORM,
  CREATE_ACTIVITY_MODAL_ID,
} from 'features/projects/activities/activities.constants';

import { required } from 'utils/validation.util';
import { getActivityTypes } from 'features/projects/projects.actions';
import { getActivityTypes as getActivityTypesFromState } from 'features/projects/activities/activities.selectors';
import TimeSpanField from 'app/components/form/TimeSpanField';
import EditorReduxForm from 'app/components/form/EditorField';
import { EditorState, convertFromRaw } from 'draft-js';
import QuantityTextFieldReduxForm from 'app/components/form/redux-form/QuantityTextFieldReduxForm';

const getActivityTypeOptionLabel = (activityType) => activityType.get('name');

const CreateActivityModalContainer = ({
  mode,
  valid,
  dispatch,
  submitting,
  handleSubmit,
  dispatchOnLoad,
  selectedActivityType,
  createActivity,
  showWaitingActivities = true,
}) => {
  useEffect(() => {
    dispatchOnLoad();
  }, [dispatchOnLoad]);

  const renderActivityTypeInput = useCallback((props) => {
    props.InputProps.startAdornment = (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    );

    return <TextField required autoFocus label="Activity" {...props} />;
  }, []);

  useEffect(() => {
    if (!selectedActivityType) return;

    let description;

    if (selectedActivityType.get('description')) {
      const rawContentState = JSON.parse(selectedActivityType.get('description'));
      const newEditorState = EditorState.createWithContent(
        convertFromRaw(rawContentState),
      );
      description = newEditorState;
    }

    dispatch(
      change(
        CREATE_ACTIVITY_FORM.ID,
        CREATE_ACTIVITY_FORM.DESCRIPTION,
        description,
      ),
    );
  }, [dispatch, selectedActivityType]);

  const currentActivityTypes = useSelector(getActivityTypesFromState);

  const activityTypes = showWaitingActivities
    ? currentActivityTypes
    : currentActivityTypes.filter(
      (activityType) => !activityType?.get('isWait'),
    );

  const [isOpen, toggleModal] = useModal(CREATE_ACTIVITY_MODAL_ID);

  const isQuickMode = mode === CREATE_ACTIVITY_MODE.QUICK; // user should not enter Title, Description or Target depth

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm" onClose={toggleModal}>
      <ModalHeader title="Add activity" toggleModal={toggleModal} />
      <DialogContent>
        <form
          id={CREATE_ACTIVITY_FORM.ID}
          onSubmit={handleSubmit(createActivity())}
        >
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Field
                    format={null} // Required for autocomplete to work
                    validate={[required]}
                    options={activityTypes}
                    disableCloseOnSelect={false}
                    component={AutocompleteField}
                    name={CREATE_ACTIVITY_FORM.TYPE}
                    noOptionsText="No activities found"
                    renderInput={renderActivityTypeInput}
                    getOptionLabel={getActivityTypeOptionLabel}
                  />
                  {!isQuickMode && (
                    <>
                      <Field
                        maxRows={5}
                        label="Description"
                        xs={12}
                        disabled={!selectedActivityType}
                        component={EditorReduxForm}
                        name={CREATE_ACTIVITY_FORM.DESCRIPTION}
                      />
                      <Grid container spacing={2}>
                        <Grid item container xs={5}>
                          <Field
                            label="Depth"
                            disabled={!selectedActivityType}
                            component={QuantityTextFieldReduxForm}
                            name={CREATE_ACTIVITY_FORM.DEPTH}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <Field
                            label="Estimated duration"
                            disabled={!selectedActivityType}
                            name={CREATE_ACTIVITY_FORM.ESTIMATED_TIME}
                            component={TimeSpanField}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} container>
                        <Grid item container xs={12}>
                          <Field
                            label="Parallel Activities / Information"
                            disabled={!selectedActivityType}
                            name={CREATE_ACTIVITY_FORM.PARALLEL_ACTIVITY}
                            component={EditorReduxForm}
                            xs={12}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <ModalActions>
        <Button size="small" color="default" onClick={toggleModal}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          size="small"
          color="primary"
          variant="contained"
          loading={submitting}
          form={CREATE_ACTIVITY_FORM.ID}
          disabled={!valid || submitting}
          onClick={handleSubmit(createActivity())}
        >
          Create
        </LoadingButton>
      </ModalActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  const selector = formValueSelector(CREATE_ACTIVITY_FORM.ID);

  return {
    mode: selector(state, CREATE_ACTIVITY_FORM.MODE),
    selectedActivityType: selector(state, CREATE_ACTIVITY_FORM.TYPE),
  };
};

export default compose(
  connect(mapStateToProps, {
    dispatchOnLoad: getActivityTypes,
  }),
  reduxForm({
    form: CREATE_ACTIVITY_FORM.ID,
  }),
)(CreateActivityModalContainer);
