import { Map } from 'immutable';

const DashboardMapper = {
  from: Map,
};

const CurveMapper = (curve) => {
  return Map({
    ...curve,
  });
};

const dashboardMappers = {
  Dashboard: DashboardMapper,
  Curve: {
    from: CurveMapper,
  },
};

export default dashboardMappers;
