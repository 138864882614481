import { combineReducers } from 'redux-immutable';

import toolstringReducer from 'features/projects/tasks/task/toolstring/toolstring.reducer';
import dataAcquisitionReducer from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.reducer';
import taskFluidsReducer from './dataAcquisition/components/Fluids/taskFluids.reducer';

export default combineReducers({
  toolstring: toolstringReducer,
  dataAcquisition: dataAcquisitionReducer,
  fluids: taskFluidsReducer,
});
