import Agent from 'infrastructure/agent';
import { measurementPreferencesToDto } from 'mappers';
import { projectToDTO } from 'features/projects/projects.mappers';

const createProject = (project) => Agent.post('/project', project);

const updateProject = (projectId, project) =>
  Agent.put(`/project/${projectId}`, projectToDTO(project));

const getProjectStatus = (projectId) =>
  Agent.get(`/project/${projectId}/execute/status`);

const getProjectStatusLite = (projectId) =>
  Agent.get(`/project/${projectId}/execute/status/lite`);

const getCurrentProjectMember = (projectId) =>
  Agent.get(`/project/${projectId}/members/current`);

const searchProjects = (filter) => Agent.get('/project/search', filter);

const deleteProject = (projectId) => Agent.delete(`/project/${projectId}`);

const updateMeasurementPreference = (projectId, units) =>
  Agent.put(
    `/project/${projectId}/preference/measurement`,
    measurementPreferencesToDto(units),
  );

const getWellboreByProject = (projectId) =>
  Agent.get(`/project/${projectId}/wellbore`);

const getWellboreTrajectoryByProject = (projectId) =>
  Agent.get(`/project/${projectId}/wellbore/trajectory`);

const syncProjectWellboreTrajectoryWithLatest = (projectId) =>
  Agent.post(`/project/${projectId}/wellbore/trajectory/sync`);

const getWellboreTrajectoryByProjectByTime = (projectId, query) =>
  Agent.get(`/project/${projectId}/wellbore/trajectory/bytime`, query);

const updateProjectExternalId = (projectId, externalId) =>
  Agent.post(`/project/${projectId}/external-reference`, { externalId });

const getFacilityDecksByProjectId = (projectId) =>
  Agent.get(`/project/decks/${projectId}`);

const archiveProject = (projectId, isArchive) =>
  Agent.put(`/project/${projectId}/archive/${isArchive}`);

const getAllProjectsGantt = (filter) =>
  Agent.get('/project/allprojectsgantt', filter);

const getAllProjectNonProductiveTimes = (projectId) =>
  Agent.get(`/project/${projectId}/npt`);

const createProjectNonProductiveTime = (projectId, projectNonProductiveTime) =>
  Agent.post(`/project/${projectId}/npt`, projectNonProductiveTime);

export const updateProjectNonProductiveTime = (
  projectId,
  projectNonProductiveTime,
) =>
  Agent.put(
    `/project/${projectId}/npt/${projectNonProductiveTime.projectNonProductiveTimeId}`,
    projectNonProductiveTime,
  );

export const deleteProjectNonProductiveTime = (
  projectId,
  projectNonProductiveTimeId,
) => Agent.delete(`/project/${projectId}/npt/${projectNonProductiveTimeId}`);

const projectService = {
  createProject,
  deleteProject,
  updateProject,
  searchProjects,
  getProjectStatus,
  getProjectStatusLite,
  getWellboreByProject,
  updateProjectExternalId,
  updateMeasurementPreference,
  getWellboreTrajectoryByProject,
  getWellboreTrajectoryByProjectByTime,
  syncProjectWellboreTrajectoryWithLatest,
  getCurrentProjectMember,
  getFacilityDecksByProjectId,
  archiveProject,
  getAllProjectsGantt,
  getAllProjectNonProductiveTimes,
  createProjectNonProductiveTime,
  updateProjectNonProductiveTime,
  deleteProjectNonProductiveTime,
};

export default projectService;
