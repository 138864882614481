import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import Highstock from 'highcharts/highstock';
import { isMobileOnly } from 'react-device-detect';
import HighchartsReact from 'highcharts-react-official';
import { useCallback, useEffect, useRef, useState } from 'react';

import { updateSeriesData } from 'utils/dashboard.util';
import { maxTimeSpan } from 'features/projects/dashboard/dashboard.constants';
import DockManagerIFrame from 'features/projects/dashboard/components/DynamicDockManager/DockManagerIFrame';
import winchHighchartsTheme from 'features/projects/dashboard/components/dashboards/winch/winch.highcharts.theme';
import _first from 'lodash/first';
import _last from 'lodash/last';
import { useTheme } from '@material-ui/core/styles';

const { backgroundColor, defaultFontSize } = winchHighchartsTheme.style;

const containerProps = {
  style: {
    width: '100%',
    height: '100%',
  },
};

const validSetExtremesTriggers = ['navigator', 'rangeSelectorButton'];

const DashboardLineChart = ({
  curves,
  onSetExtremes,
  registerDataPointsHandler,
}) => {
  const theme = useTheme();
  const chartComponent = useRef(null);
  const start = useRef(null);
  const end = useRef(null);

  const onReceiveInitialData = useCallback(
    (readings) =>
      updateSeriesData(chartComponent, readings, (reading, serie) => {
        const s = _first(reading.data);
        const e = _last(reading.data);

        if (start.current === null || s?.[0] < start.current?.[0]) {
          start.current = s;
        }

        if (end.current === null || e?.[0] > end.current?.[0]) {
          end.current = e;
        }

        serie.setData(
          [start.current, ...reading.data, end.current],
          false, // do not redraw, updateSeriesData will handle that
        );
      }),
    [],
  );

  const onReceiveData = useCallback(
    (readings) =>
      updateSeriesData(chartComponent, readings, (reading, serie) => {
        const data = reading.data[0];
        if (!data || end.current === null || data[0] <= end.current[0]) return;

        serie.addPoint(
          data,
          false, // do not redraw, updateSeriesData will handle that
        );
      }),
    [],
  );

  const [chartOptions] = useState(
    Highcharts.merge(false, winchHighchartsTheme, {
      legend: {
        itemStyle: {
          fontSize: defaultFontSize,
        },
      },
      series: curves.toArray().map((curve) => ({
        id: curve.get('id'),
        name: curve.get('caption'),
        type: curve.get('type'),
        color: curve.get('color'),
        unit: curve.get('unit'),
        // When using dual or multiple y axes, this number defines which yAxis the particular series is connected to.
        yAxis: curve.get('id').toString(),
      })),
      yAxis: curves.toArray().map((curve) => ({
        id: curve.get('id').toString(),
        title: {
          text: curve.get('caption'),
          style: {
            color: backgroundColor,
            fontSize: defaultFontSize,
          },
          x: -30,
        },
        labels: {
          align: 'center',
          style: {
            color: curve.get('color'),
            fontSize: defaultFontSize,
            fontWeight: 'normal',
          },
          formatter: function () {
            return this.value;
          },
        },
        resize: {
          enabled: true,
        },
        lineWidth: 1,
        lineColor: curve.get('color'),
        gridLineColor: theme.palette.secondary.darkGrey,
        minRange: 1,
        softMin: curve.get('minValue'),
        softMax: curve.get('maxValue'),
        tickInterval: isMobileOnly ? curve.get('maxValue') / 10 : null,
        gridLineWidth: 1,
        opposite: true,
        margin: -15,
      })),
      xAxis: {
        events: {
          setExtremes: (event) => {
            if (!validSetExtremesTriggers.includes(event.trigger)) return;

            const eventMin = event.min ?? event.dataMin;
            const eventMax = event.max ?? event.dataMax;

            if (eventMax - eventMin > maxTimeSpan) {
              const min = eventMax - maxTimeSpan;
              const max = eventMax;

              return onSetExtremes({
                fromTimestamp: min,
                toTimestamp: max,
              }).then(onReceiveInitialData);
            }

            return onSetExtremes({
              fromTimestamp: eventMin,
              toTimestamp: eventMax,
            }).then(onReceiveInitialData);
          },
          afterSetExtremes: (event) => {
            const chart = chartComponent.current.chart;

            const eventMin = event.min ?? event.dataMin;
            const eventMax = event.max ?? event.dataMax;

            chart.xAxis.forEach((axis) => {
              if (eventMax - eventMin > maxTimeSpan) {
                var min = eventMax - maxTimeSpan;
                var max = eventMax;
                axis.setExtremes(min, max);
              }
            });
          },
        },
      },
      rangeSelector: {
        enabled: true,
        selected: 2, // The index of the button to appear pre-selected
        buttons: [
          {
            count: 1,
            type: 'minute',
            text: '1M',
          },
          {
            count: 5,
            type: 'minute',
            text: '5M',
          },
          {
            count: 10,
            type: 'minute',
            text: '10M',
          },
          {
            count: 1,
            type: 'hour',
            text: '1H',
          },
          {
            type: 'hour',
            count: 3,
            text: '3H',
          },
          {
            type: 'hour',
            count: 6,
            text: '6H',
          },
        ],
      },
    }),
  );

  useEffect(() => {
    registerDataPointsHandler(onReceiveInitialData, onReceiveData);
  }, [onReceiveData, onReceiveInitialData, registerDataPointsHandler]);

  // Use DockManagerIFrame to enable dragging of navigator within the context of DockManager
  return (
    <DockManagerIFrame>
      <HighchartsReact
        allowChartUpdate
        ref={chartComponent}
        highcharts={Highstock}
        options={chartOptions}
        constructorType="stockChart"
        containerProps={containerProps}
      />
    </DockManagerIFrame>
  );
};

DashboardLineChart.propTypes = {
  onSetExtremes: PropTypes.func.isRequired,
  registerDataPointsHandler: PropTypes.func.isRequired,
};

export default DashboardLineChart;
