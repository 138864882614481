import { Field } from 'formik';
import { MenuItem } from '@material-ui/core';
import * as colors from '@material-ui/core/colors';

import { required } from 'utils/validation.util';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import StatusIndicatorWithText from 'features/projects/dashboard/components/StatusIndicatorWithText';

const availableColors = [
  colors.purple[400],
  colors.orange[400],
  colors.pink[400],
  colors.green[400],
  colors.orange[600],
  colors.pink[100],
  colors.cyan[400],
  colors.yellow[400],
];

const ProjectDashboardCurveColorField = ({ field, caption }) => {
  const { name, value } = field;

  const isValidColor = availableColors.includes(value);

  return (
    <Field
      select
      name={name}
      validate={required}
      component={TableRowTextFieldFormik}
    >
      {/* Make sure there is always one selected option, even though the value is not available for selection */}
      {!isValidColor && (
        <MenuItem value={value} disabled>
          <StatusIndicatorWithText color={value}>
            {caption}
          </StatusIndicatorWithText>
        </MenuItem>
      )}
      {availableColors.map((color) => (
        <MenuItem key={color} value={color}>
          <StatusIndicatorWithText color={color}>
            {caption}
          </StatusIndicatorWithText>
        </MenuItem>
      ))}
    </Field>
  );
};

export default ProjectDashboardCurveColorField;
