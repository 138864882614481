import { Grid, Typography } from '@material-ui/core';

const TableHeaderTitleCell = ({ title, subTitle, justifyContent, ...rest }) => (
  <>
    {title && (
      <Grid container justifyContent={justifyContent}>
        <Typography variant="body2" noWrap title={title} {...rest}>
          <b>{title}</b>
        </Typography>
      </Grid>
    )}
    {subTitle && (
      <Grid container justifyContent={justifyContent}>
        <Typography variant="caption" noWrap title={subTitle} {...rest}>
          {subTitle}
        </Typography>
      </Grid>
    )}
  </>
);

export default TableHeaderTitleCell;
