import { compose } from 'redux';
import { Chip } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { EMPTY_LIST } from 'app/app.constants';

const ProjectTaskServiceChips = ({
  classes,
  size = 'small',
  taskServices = EMPTY_LIST,
  ...rest
}) =>
  taskServices.map((taskService, index) => (
    <Chip
      key={index}
      size={size}
      color="primary"
      className={classes.chip}
      label={taskService.get('name')}
      {...rest}
    />
  ));

const styles = (theme) => ({
  chip: {
    marginLeft: 0,
    margin: theme.spacing(0.5),
  },
});

export default compose(withStyles(styles))(ProjectTaskServiceChips);
