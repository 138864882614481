import Agent from 'infrastructure/agent';

export const getAllFluids = async (wellboreId) =>
  Agent.get(`/wellbore/details/${wellboreId}/fluids`);

export const deleteFluid = async (wellboreId, fluidId) =>
  await Agent.delete(`/wellbore/details/${wellboreId}/fluids/${fluidId}`);

const getDefaultFluid = (wellboreId) =>
  Agent.get(`/wellbore/details/${wellboreId}/fluids/default`);

export const updateFluid = async (wellboreId, fluidObj) => {
  await Agent.put(
    `/wellbore/details/${wellboreId}/fluids?wellboreFluidId=${fluidObj.fluidId}`,
    fluidObj,
  );
};

export const addFluid = async (wellboreId, fluidObj) =>
  await Agent.post(`/wellbore/details/${wellboreId}/fluids`, fluidObj);

const wellboreFluidService = {
  getAllFluids,
  deleteFluid,
  updateFluid,
  addFluid,
  getDefaultFluid,
};

export default wellboreFluidService;
