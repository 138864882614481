import { compose } from 'redux';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router';

import {
  requestProjectWellboreDetail,
  requestProjectWellboreDetails,
} from 'features/projects/wellbore/details/projectWellboreDetails.actions';

import routePaths from 'app/routePaths';
import { useHeader } from 'app/hooks/useHeader';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import ProjectRoutesContainer from 'app/components/ProjectRoutesContainer';
import ProjectWellboreHeader from 'features/projects/wellbore/ProjectWellboreHeader';
import ProjectWellboreDetailStatusHeader from 'features/projects/wellbore/details/ProjectWellboreDetailStatusHeader';

const ProjectWellboreContainer = ({
  routes,
  projectId,
  breadcrumb,
  dispatchRequestProjectWellboreDetails,
}) => {
  useHeader({
    subTitle: breadcrumb,
  });
  const match = useRouteMatch({
    path: routePaths.projectWellbore.details.match,
  });

  const projectWellboreDetailId = match?.params?.projectWellboreDetailId;

  useEffect(
    () => dispatchRequestProjectWellboreDetails(projectId),
    [dispatchRequestProjectWellboreDetails, projectId],
  );

  const project = useCurrentProject();
  return (
    <>
      <ProjectWellboreDetailStatusHeader
        projectId={projectId}
        currentProjectWellboreDetailId={projectWellboreDetailId}
      />
      <ProjectWellboreHeader routes={routes} project={project} />
      <ProjectRoutesContainer routes={routes} projectId={projectId} />
    </>
  );
};

export default compose(
  connect(null, {
    dispatchRequestProjectWellboreDetail: requestProjectWellboreDetail,
    dispatchRequestProjectWellboreDetails: requestProjectWellboreDetails,
  }),
)(ProjectWellboreContainer);
