import { compose } from 'redux';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDropzone } from 'react-dropzone';
import { useSelector, connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { useCallback, useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';

import { BasePage } from 'altus-ui-components';

import {
  getCurrentUserFromState,
  getAllOrganizationsFromState,
  getSummarizedDataStateFromState,
  getAvailableFileCategoriesFromState,
} from 'app/app.selectors';

import {
  onLoad,
  updateFile,
  deleteFile,
  uploadFiles,
  downloadFile,
} from 'features/projects/documents/documents.actions';

import {
  EMPTY_SET,
  ProjectPermission,
  MAX_UPLOAD_FILE_SIZE,
} from 'app/app.constants';

import { setFile } from 'app/app.actions';
import { Fab } from 'app/components/withTooltip';
import UploadDocumentModal from 'app/components/UploadDocumentModal';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { DOCUMENTS_ACTIONS } from 'features/projects/documents/documents.constants';
import { useProjectPermissions } from 'app/hooks/authorization/useProjectPermissions';
import { ALTUS_ORGANIZATION_CODE } from 'app/hooks/useIsInternalCategoryToDisplay/constants';
import { getProjectDocumentsFromState } from 'features/projects/documents/documents.selectors';
import DocumentsTable from 'features/projects/documents/newDocumentsContainer/components/DocumentsTable';
import DocumentsCategoryFilter from 'features/projects/documents/newDocumentsContainer/components/DocumentsCategoryFilter';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import SearchTextFieldFormik from 'app/components/form/formik/SearchTextFieldFormik';

export const Filters = {
  TEXT_SEARCH: 'textSearch',
};

const DocumentsContainerNew = ({
  classes,
  dataState,
  projectId,
  dispatchOnLoad,
  dispatchDropFiles,
  dispatchDeleteFile,
  dispatchUpdateFile,
  dispatchUploadFiles,
  dispatchDownloadFile,
}) => {
  const [filter, setFilter] = useState(EMPTY_SET);
  const [filteredDocuments, setFilteredDocuments] = useState(EMPTY_SET);
  const [searchTerm, setSearchTerm] = useState('');
  const [emtpyFiltering, setEmtpyFiltering] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);

  const { hasPermission: hasUploadDocumentPermission } = useProjectPermissions(
    ProjectPermission.UPLOAD_DOCUMENTS,
  );

  useEffect(() => {
    dispatchOnLoad(projectId);
  }, [dispatchOnLoad, projectId]);

  const user = useSelector(getCurrentUserFromState);
  const documents = useSelector(getProjectDocumentsFromState);
  const availableOrganizations = useSelector(getAllOrganizationsFromState);
  const documentCategories = useSelector(getAvailableFileCategoriesFromState);

  const applyFilters = useCallback(() => {
    setEmtpyFiltering(false);
    const filterIds = filter.map((f) => f.get('id'));
    const filtered = documents.filter((document) => {
      const documentCategory = documentCategories.get(document.get('category'));
      return filterIds.includes(documentCategory.get('id'));
    });

    if (searchTerm) {
      const filteredDocumentsByName =
        filtered?.size === 0 ? documents : filtered;
      const filteredBySearchTerm = filteredDocumentsByName.filter(
        (document) => {
          return document
            .get('name')
            ?.toLowerCase()
            ?.includes(searchTerm?.toLowerCase());
        },
      );
      setFilteredDocuments(filteredBySearchTerm);
      setEmtpyFiltering(filteredBySearchTerm?.size === 0 ? true : false);
      setFilterApplied(true);
      return;
    }

    setEmtpyFiltering(filtered?.size > 0 ? true : false);
    setFilteredDocuments(filtered);
    setFilterApplied(false);
  }, [filter, documents, searchTerm, documentCategories]);

  const clearFilters = () => {
    setFilter(EMPTY_SET);
    setFilteredDocuments(EMPTY_SET);
    setFilterApplied(false);
  };

  const checkIfUserOrgIsAltus = (user, organizations) => {
    const currentOrg = Array.from(organizations).find(
      (org) => org.get('id') === user.get('organizationId'),
    );

    if (currentOrg?.get('code') === ALTUS_ORGANIZATION_CODE) {
      return true;
    } else {
      return false;
    }
  };

  const downloadFile = useCallback(
    (fileId, file) => dispatchDownloadFile(projectId, fileId, file),
    [dispatchDownloadFile, projectId],
  );

  const deleteFile = useCallback(
    (fileId) => dispatchDeleteFile(projectId, fileId),
    [dispatchDeleteFile, projectId],
  );

  const updateFile = useCallback(
    (file) => dispatchUpdateFile(projectId, file.fileId, file),
    [dispatchUpdateFile, projectId],
  );

  const onDrop = useCallback(
    (files) => dispatchDropFiles(files),
    [dispatchDropFiles],
  );

  const uploadFiles = useCallback(
    (files) => dispatchUploadFiles(projectId)(files),
    [dispatchUploadFiles, projectId],
  );

  const { isDragActive, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      maxSize: MAX_UPLOAD_FILE_SIZE,
      noDrag: !hasUploadDocumentPermission,
    });

  useEffect(() => {
    applyFilters();
  }, [applyFilters, searchTerm]);

  const onSubmit = (formValues) => {
    if (
      !formValues[Filters.TEXT_SEARCH] &&
      searchTerm &&
      !filteredDocuments.size
    ) {
      setFilterApplied(false);
    }

    if (
      searchTerm !== formValues[Filters.TEXT_SEARCH] ||
      !formValues[Filters.TEXT_SEARCH]
    ) {
      setSearchTerm(formValues[Filters.TEXT_SEARCH]);
    }
  };

  const initialValues = {
    [Filters.TEXT_SEARCH]: '',
  };

  const shouldSubmit = ({ isValid }) => isValid;

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
        root: classNames({
          [classes.dragOver]: isDragActive,
        }),
      }}
    >
      <Grid container direction="row" spacing={4} {...getRootProps()}>
        <Grid item xs={2}>
          <DocumentsCategoryFilter
            filter={filter}
            clearFilters={clearFilters}
            setFilter={setFilter}
            documents={documents}
            documentCategories={documentCategories}
            setFilterApplied={setFilterApplied}
            isAltus={checkIfUserOrgIsAltus(user, availableOrganizations)}
            searchTerm={searchTerm}
          />
        </Grid>
        <Grid item container xs={10} direction="column">
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={initialValues}
          >
            <Form>
              <AutoSaveFormik timeout={500} shouldSubmit={shouldSubmit}>
                <Field
                  name={Filters.TEXT_SEARCH}
                  component={SearchTextFieldFormik}
                />
              </AutoSaveFormik>
            </Form>
          </Formik>
          <DocumentsTable
            user={user}
            filteredDocuments={filteredDocuments}
            documents={documents}
            deleteFile={deleteFile}
            updateFile={updateFile}
            downloadFile={downloadFile}
            emtpyFiltering={emtpyFiltering}
            documentCategories={documentCategories}
            filterApplied={filterApplied}
            useGlobalFilter={false}
          />
        </Grid>
        <HasProjectPermission permissions={ProjectPermission.UPLOAD_DOCUMENTS}>
          <UploadDocumentModal
            uploadFiles={uploadFiles}
            rejectedFiles={fileRejections}
            availableFileCategories={documentCategories.valueSeq()}
            dialogContentText="Set meta data and upload files to project"
          />
          <Fab
            onClick={open}
            color="primary"
            title="Upload documents"
            className={classes.create}
          >
            <AddIcon />
          </Fab>
        </HasProjectPermission>
      </Grid>
      <input {...getInputProps()} />
    </BasePage>
  );
};

const styles = (theme) => ({
  create: {
    zIndex: 2,
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  dragOver: {
    opacity: '0.3',
    border: '5px gray dashed',
  },
  basePageChildren: {
    flexDirection: 'row',
    paddingTop: theme.spacing(2.25),
  },
});

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    DOCUMENTS_ACTIONS.DOWNLOAD_PROJECT_FILE,
    DOCUMENTS_ACTIONS.UPLOAD_PROJECT_FILE,
    DOCUMENTS_ACTIONS.UPDATE_PROJECT_FILE,
    DOCUMENTS_ACTIONS.DELETE_PROJECT_FILE,
    DOCUMENTS_ACTIONS.PROJECT_DOCUMENTS_PAGE_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoad,
  dispatchDropFiles: setFile,
  dispatchDeleteFile: deleteFile,
  dispatchUpdateFile: updateFile,
  dispatchUploadFiles: uploadFiles,
  dispatchDownloadFile: downloadFile,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(DocumentsContainerNew);
