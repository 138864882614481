export const TASK_ACTIONS = {
  TASK_PAGE_LOADED: 'TASK_PAGE_LOADED',
};

export const TASK_MODALS = {
  ADD_CABLE_MODAL: 'ADD_CABLE_MODAL',
  SURFACE_EQUIPMENT_MODAL: 'SURFACE_EQUIPMENT_MODAL',
  SET_RETRIEVE_ITEM_MODAL: 'SET_RETRIEVE_ITEM_MODAL',
};

export const DATA_AQUISITION_ACTIVE_TAB = {
  WELL: 'WELL',
  CABLE: 'CABLE',
  TOOLS: 'TOOLS',
  CONSUMABLES: 'CONSUMABLES',
};
