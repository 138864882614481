import classNames from 'classnames';
import { connect } from 'react-redux';
import toJSComponent from 'with-immutable-props-to-js';
import withStyles from '@material-ui/styles/withStyles';
import { reduxForm, Field, formValueSelector } from 'redux-form/immutable';
import { Grid, Typography, DialogActions, Button } from '@material-ui/core';

import {
  ACTIVITIES_ACTIONS,
  END_OPERATION_FORM,
  END_OPERATION_MODAL_ID,
} from 'features/projects/activities/activities.constants';

import { required } from 'utils/validation.util';
import LoadingButton from 'app/components/LoadingButton';
import { getActionDataStateFromState } from 'app/app.selectors';
import ModalContainer from 'app/components/Modal/ModalContainer';
import { endOperation } from 'features/projects/activities/activities.actions';
import FailureReasonField from 'features/projects/activities/components/FailureReasonField';
import EditorReduxForm from 'app/components/form/EditorField';

const EndOperationModalContainer = ({
  valid,
  classes,
  projectId,
  dataState,
  isProjectSuccessful,
  dispatchEndOperation,
}) => {
  const submitting = dataState.isLoading();

  return (
    <ModalContainer
      title={
        isProjectSuccessful
          ? 'Project Objective achieved'
          : 'Project Objective NOT achieved'
      }
      height="auto"
      maxWidth="sm"
      modalId={END_OPERATION_MODAL_ID}
      classes={{
        content: classes.content,
      }}
      Actions={({ toggleModal }) => (
        <DialogActions className={classes.dialogActions}>
          <Grid container item justifyContent="space-between" spacing={2}>
            <Grid item xs={3} />

            <Grid item container justifyContent="center" xs={6}>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={() => dispatchEndOperation(projectId)}
                disabled={submitting || !valid}
                className={classes.completeBtn}
              >
                <Grid container direction="column">
                  <Grid item>Complete project</Grid>
                  <Grid item className={classes.completeBtnSubText}>
                    Changes Project status to Report
                  </Grid>
                </Grid>
              </LoadingButton>
            </Grid>
            <Grid
              item
              xs={3}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button onClick={toggleModal}>Cancel</Button>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    >
      <form>
        <Grid container justifyContent="center">
          <Grid container justifyContent="center">
            <Typography
              variant="body1"
              className={classNames({
                [classes.projectSuccessful]: isProjectSuccessful,
                [classes.projectNotSuccessful]: !isProjectSuccessful,
              })}
            >
              {isProjectSuccessful
                ? 'Completed successfully - Congratulations!'
                : 'Did not complete as planned'}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.formFields}>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12}>
                <Field
                  autoFocus
                  label="Comment"
                  disabled={submitting}
                  component={EditorReduxForm}
                  name={END_OPERATION_FORM.COMMENTS}
                  xs={12}
                />
              </Grid>
              {!isProjectSuccessful && (
                <Field
                  required
                  validate={[required]}
                  disabled={submitting}
                  component={FailureReasonField}
                  name={END_OPERATION_FORM.FAILURE_REASON}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </ModalContainer>
  );
};

const styles = (theme) => ({
  content: {
    width: 'auto',
  },
  formFields: {
    marginTop: 50,
  },
  subHeader: {
    marginBottom: 10,
  },
  nextActionsHeader: {
    marginBottom: 8,
  },
  dialogActions: {
    padding: 10,
  },
  projectSuccessful: {
    color: theme.altus.status.execute,
  },
  projectNotSuccessful: {
    color: theme.palette.error.main,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  completeBtn: {
    textTransform: 'none',
  },
  completeBtnSubText: {
    fontSize: '0.65rem',
    fontWeight: 400,
  },
});

export default connect(
  (state) => ({
    isProjectSuccessful: formValueSelector(END_OPERATION_FORM.ID)(
      state,
      END_OPERATION_FORM.IS_SUCCESSFUL,
    ),
    dataState: getActionDataStateFromState(
      state,
      ACTIVITIES_ACTIONS.END_OPERATION,
    ),
  }),
  {
    dispatchEndOperation: endOperation,
  },
)(
  toJSComponent(
    reduxForm({
      form: END_OPERATION_FORM.ID,
    })(withStyles(styles)(EndOperationModalContainer)),
  ),
);
