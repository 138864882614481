import { useCurrentUser } from 'app/hooks/useCurrentUser';
import { usePermissions } from 'app/hooks/authorization/usePermissions';

export const useSystemPermissions = (permissions) => {
  const currentUser = useCurrentUser();

  const userPermissions = currentUser.get('permissions');

  const { hasPermission, permissions: systemPermissions } = usePermissions(
    userPermissions,
    permissions,
  );

  return { hasPermission, userPermissions, systemPermissions, currentUser };
};
