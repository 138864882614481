import { DASHBOARDS_ACTIONS } from 'features/projects/dashboard/dashboard.constants';

export const requestDashboards = () => ({
  type: DASHBOARDS_ACTIONS.REQUEST_DASHBOARDS,
});

export const receiveDashboards = (dashboards) => ({
  payload: dashboards,
  type: DASHBOARDS_ACTIONS.RECEIVE_DASHBOARDS,
});

export const requestProjectDashboard = (projectId, projectDashboardId) => ({
  projectId,
  projectDashboardId,
  type: DASHBOARDS_ACTIONS.REQUEST_PROJECT_DASHBOARD,
});

export const requestProjectDashboards = (projectId) => ({
  projectId,
  type: DASHBOARDS_ACTIONS.REQUEST_PROJECT_DASHBOARDS,
});

export const receiveProjectDashboards = (projectId, projectDashboards) => ({
  projectId,
  payload: projectDashboards,
  type: DASHBOARDS_ACTIONS.RECEIVE_PROJECT_DASHBOARDS,
});

export const receiveProjectDashboardCurves = (
  projectDashboardId,
  projectDashboardCurves,
) => ({
  projectDashboardId,
  payload: projectDashboardCurves,
  type: DASHBOARDS_ACTIONS.RECEIVE_PROJECT_DASHBOARD_CURVES,
});

export const receiveProjectDashboard = (
  projectId,
  projectDashboardId,
  projectDashboard,
) => ({
  projectId,
  projectDashboardId,
  payload: projectDashboard,
  type: DASHBOARDS_ACTIONS.RECEIVE_PROJECT_DASHBOARD,
});

export const toggleProjectDashboard = (projectId, dashboardId, callback) => ({
  callback,
  projectId,
  dashboardId,
  type: DASHBOARDS_ACTIONS.TOGGLE_PROJECT_DASHBOARD,
  notification: {
    success: 'Project dashboard updated successfully',
  },
});

export const toggleProjectDashboards = (projectId, callback) => ({
  callback,
  projectId,
  type: DASHBOARDS_ACTIONS.TOGGLE_PROJECT_DASHBOARDS,
  notification: {
    success: 'Project dashboards updated successfully',
  },
});

export const updateProjectDashboard = (
  projectId,
  projectDashboardId,
  projectDashboard,
) => ({
  projectId,
  projectDashboardId,
  payload: projectDashboard,
  type: DASHBOARDS_ACTIONS.UPDATE_PROJECT_DASHBOARD,
  notification: {
    success: 'Project dashboard updated successfully',
  },
});
