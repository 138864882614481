import { Map, List } from 'immutable';
import { useCallback, useMemo } from 'react';

import { useRequest } from 'altus-hooks';
import { getSummarizedDataState } from 'altus-datastate';

import mappers from 'mappers';
import projectDashboardService from 'services/projectDashboard.service';
import { CheetahJobSource } from 'features/projects/dashboard/dashboard.constants';

const useProjectDashboardSimulation = (
  projectId,
  projectDashboardId,
  simulationId,
) => {
  const getProjectDashboardSimulationCurves = useCallback(() => {
    if (!simulationId) return;

    return projectDashboardService
      .getProjectDashboardCurvesBySource(
        projectId,
        projectDashboardId,
        CheetahJobSource.SIMULATION,
      )
      .then((curves) => List(curves).map(Map));
  }, [projectId, simulationId, projectDashboardId]);

  const getProjectDashboardSimulations = useCallback(
    () =>
      projectDashboardService
        .getProjectDashboardSimulations(projectId, projectDashboardId)
        .then((simulations) => List(simulations).map(mappers.Simulation.from)),
    [projectId, projectDashboardId],
  );

  const [
    projectDashboardSimulationCurves,
    getProjectDashboardSimulationCurvesDataState,
  ] = useRequest(getProjectDashboardSimulationCurves);

  const [simulations, getProjectDashboardSimulationsDataState] = useRequest(
    getProjectDashboardSimulations,
  );

  const dataState = useMemo(
    () =>
      getSummarizedDataState([
        getProjectDashboardSimulationsDataState,
        getProjectDashboardSimulationCurvesDataState,
      ]),
    [
      getProjectDashboardSimulationsDataState,
      getProjectDashboardSimulationCurvesDataState,
    ],
  );

  return {
    dataState,
    simulations,
    projectDashboardSimulationCurves,
  };
};

export default useProjectDashboardSimulation;
