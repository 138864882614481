import { connect } from 'react-redux';
import toJSComponent from 'with-immutable-props-to-js';
import { reduxForm, Field } from 'redux-form/immutable';
import { Button, Grid, DialogActions } from '@material-ui/core';

import {
  updateIncident,
  onEditIncidentModalLoaded,
} from 'features/projects/incidents/incidents.actions';

import {
  MODAL,
  EDIT_INCIDENT_FORM,
} from 'features/projects/incidents/incidents.constants';

import { required } from 'utils/validation.util';
import TextField from 'app/components/form/TextField';
import LoadingButton from 'app/components/LoadingButton';
import ModalContainer from 'app/components/Modal/ModalContainer';
import { getIncidentsFromState } from 'features/projects/incidents/incidents.selectors';
import EditorReduxForm from 'app/components/form/EditorField';

const EditIncidentModalContainer = ({
  valid,
  incidents,
  children,
  submitting,
  handleSubmit,
  dispatchOnLoad,
}) => (
  <ModalContainer
    items={incidents}
    title="Edit Incident"
    onEnter={dispatchOnLoad}
    TriggerComponent={children}
    modalId={MODAL.EDIT_INCIDENT}
    onNext={({ goNext }) => goNext()}
    onPrevious={({ goPrevious }) => goPrevious()}
    Actions={({ toggleModal }) => (
      <DialogActions>
        <Button size="small" onClick={toggleModal}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          size="small"
          color="primary"
          disabled={!valid}
          variant="contained"
          onClick={handleSubmit}
          submitting={submitting}
          form={EDIT_INCIDENT_FORM.ID}
        >
          Save and close
        </LoadingButton>
      </DialogActions>
    )}
  >
    <form id={EDIT_INCIDENT_FORM.ID} onSubmit={handleSubmit}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Field
            required
            validate={[required]}
            component={TextField}
            label="Case Number"
            name={EDIT_INCIDENT_FORM.CASE_NUMBER}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            required
            autoFocus
            label="Title"
            component={TextField}
            validate={[required]}
            name={EDIT_INCIDENT_FORM.TITLE}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Description"
            component={EditorReduxForm}
            name={EDIT_INCIDENT_FORM.DESCRIPTION}
            inputProps={{ maxLength: 100 }}
            xs={12}
          />
        </Grid>
      </Grid>
    </form>
  </ModalContainer>
);

const mapStateToProps = (state) => ({
  incidents: getIncidentsFromState(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(updateIncident(ownProps.project.id)),
  dispatchOnLoad: (incident) => dispatch(onEditIncidentModalLoaded(incident)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  toJSComponent(
    reduxForm({
      form: EDIT_INCIDENT_FORM.ID,
    })(EditIncidentModalContainer),
  ),
);
