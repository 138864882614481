import moment from 'moment';
import { compose } from 'redux';
import Add from '@material-ui/icons/Add';
import { useCallback, useEffect, useState } from 'react';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Grid, Box, Tabs, Tab, Divider } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { useModal } from 'altus-modal';
import { BasePage, NoContentBasePage } from 'altus-ui-components';

import {
  MODAL,
  ACTIONS,
} from 'features/projects/mobilisation/projectMobilisation.constants';

import {
  createStringTestReport,
  requestStringReportsForTask,
  requestGetToolstringItemCopies,
  requestProjectMobilisationTasks,
  validateToolstringItemCopiesSerialNumbers,
} from 'features/projects/mobilisation/projectMobilisation.actions';

import {
  getToolstringItemCopiesFromState,
  getProjectMobilisationTasksFromState,
  getStringTestReportsForTaskFromState,
} from 'features/projects/mobilisation/projectMobilisation.selectors';

import {
  getCurrentUserFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';

import { useHeader } from 'app/hooks/useHeader';
import { EMPTY_STRING } from 'app/app.constants';
import { Button } from 'app/components/withTooltip';
import MobilisationTasksMenu from 'features/projects/mobilisation/MobilisationTasksMenu';
import StringTestEventContainer from 'features/projects/mobilisation/StringTestEventContainer';
import StringTestEventModalContainer from 'features/projects/mobilisation/StringTestEventModalContainer';
import ExecutionToolStringContainer from 'features/projects/execution/tabs/toolstring/ExecutionToolStringContainer';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
    },
    tabsContainer: {
      overflow: 'hidden',
      height: 'calc(100% - 20px)',
      width: '100vw',
    },
    basePageChildren: {
      paddingTop: theme.spacing(2.25),
    },
    leftMenu: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    tab: {
      backgroundColor: 'transparent !important',
    },
    divider: {
      marginBottom: theme.spacing(1.25),
    },
  }),
);

const checkSerialNumbers = (tools) => {
  let flag = true;

  for (let tool of tools) {
    if (tool.toolAssemblyTools.length > 0) {
      for (let subTool of tool.toolAssemblyTools) {
        if (subTool.serialNo === EMPTY_STRING || subTool.serialNo === null) {
          flag = false;
          break;
        }
        for (let attachedTool of subTool.attachedTools) {
          if (
            attachedTool.serialNo === EMPTY_STRING ||
            attachedTool.serialNo === null
          ) {
            flag = false;
            break;
          }
        }
      }
    }
    if (tool.attachedTools.length > 0) {
      for (let attachedTool of tool.attachedTools) {
        if (
          attachedTool.serialNo === EMPTY_STRING ||
          attachedTool.serialNo === null
        ) {
          flag = false;
          break;
        }
      }
    }
    if (
      tool.toolAssemblyTools.length === 0 &&
      (tool.serialNo === EMPTY_STRING || tool.serialNo === null)
    ) {
      flag = false;
      break;
    }
  }

  return flag;
};

const formatCurrentDateTime = () => {
  const currentMoment = moment.utc();
  return currentMoment.format('YYYY-MM-DDTHH:mm:ssZ');
};

const MobilisationContainer = ({ breadcrumb, dataState, projectId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useHeader({ subTitle: breadcrumb });

  const [eventModal, toggleEventModal] = useModal(
    MODAL.STRING_TEST_REPORT_EVENT,
  );

  const [activeTab, setActiveTab] = useState(null);
  const [selectedTaskExternalId, setSelectedTaskExternalId] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null); // TASK ID, NOT WHOLE OBJECT
  const [serialNumbersDone, setSerialNumbersDone] = useState(false);
  const [selectedToolstringId, setSelectedToolstringId] = useState(null);
  const [selectedStringTestReport, setSelectedStringTestReport] =
    useState(null);

  const isLastInList = useCallback(
    (allStringTestReports, selectedStringTestReportItem) => {
      const equalValue =
        allStringTestReports.last() === selectedStringTestReportItem;

      if (!equalValue && !allStringTestReports?.size) {
        return serialNumbersDone;
      }

      return equalValue;
    },
    [serialNumbersDone],
  );

  useEffect(() => {
    dispatch(requestProjectMobilisationTasks(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (selectedToolstringId && selectedTask) {
      dispatch(requestStringReportsForTask(selectedTask, selectedToolstringId));
    }
  }, [selectedTask, dispatch, projectId, selectedToolstringId]);

  useEffect(() => {
    if (selectedToolstringId) {
      dispatch(
        requestGetToolstringItemCopies(selectedTask, selectedToolstringId),
      );
    }
  }, [dispatch, selectedTask, selectedToolstringId]);

  const user = useSelector(getCurrentUserFromState);
  const tasks = useSelector(getProjectMobilisationTasksFromState);
  const toolstringItemCopies = useSelector(getToolstringItemCopiesFromState);
  const stringTestReports = useSelector(getStringTestReportsForTaskFromState);

  useEffect(() => {
    if (tasks.size) {
      setSelectedTask(tasks.first().get('taskId'));
      setSelectedTaskExternalId(tasks.first().get('externalId'));
    }
  }, [tasks]);

  useEffect(() => {
    if (stringTestReports.size) {
      setActiveTab(stringTestReports.last().get('stringTestId'));
      setSelectedStringTestReport(stringTestReports.last());
    } else {
      setSelectedStringTestReport(undefined);
    }
  }, [stringTestReports]);

  const handleTaskSelection = (value) => {
    setSelectedTask(value.taskId);
    setSelectedTaskExternalId(value.externalId);
    setSelectedStringTestReport(null);
  };

  const handleTabs = (event, value) => {
    setActiveTab(value);
    setSelectedStringTestReport(
      stringTestReports.find((report) => report.get('stringTestId') === value),
    );
  };

  const createDraftStringTestReport = useCallback(() => {
    const userId = user.get('id');
    const toolstringId = selectedToolstringId;
    const taskId = selectedTask;
    const timeCreated = formatCurrentDateTime();

    const stringTestReport = {
      result: 0,
      time: timeCreated,
      userId,
      toolstringId,
      taskId,
      testType: 'StringTest',
      isMobilize: true,
    };

    dispatch(
      createStringTestReport(projectId, taskId, stringTestReport, false),
    );
  }, [dispatch, user, selectedToolstringId, selectedTask, projectId]);

  const validateToolstringItemCopiesSerials = useCallback(() => {
    const toolstringItemCopiesIds = toolstringItemCopies.map(
      (toolstringItemCopy) => toolstringItemCopy.get('toolstringItemCopyId'),
    );

    dispatch(
      validateToolstringItemCopiesSerialNumbers(toolstringItemCopiesIds.toJS()),
    );
  }, [toolstringItemCopies, dispatch]);

  const hiddenColums = [
    'yieldValue',
    'botC',
    'topC',
    'supplier',
    'hideAssemblySerialNumber',
    'status',
    'actions',
  ];

  const disabledSerialNumbers = () => {
    const id = selectedStringTestReport?.get('externalTaskId');

    if (selectedTaskExternalId === id) {
      return selectedStringTestReport?.get('result') ? true : false;
    }

    return false;
  };

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs wrap="nowrap" className={classes.root}>
        <Grid
          item
          xs={2}
          component={Box}
          className={classes.leftMenu}
          paddingRight={2}
          paddingTop={0.5}
        >
          <MobilisationTasksMenu
            tasks={tasks}
            selectedTask={selectedTask}
            handleTaskSelection={handleTaskSelection}
          />
        </Grid>
        <Grid item container xs={10} component={Box}>
          <Grid item container xs={11} justifyContent="flex-end">
            {isLastInList(stringTestReports, selectedStringTestReport) ? (
              <Button
                color="primary"
                variant="contained"
                disabled={
                  selectedStringTestReport?.get('result') === 0 ||
                  selectedStringTestReport === null ||
                  selectedStringTestReport === undefined
                }
                onClick={() => createDraftStringTestReport()}
                className={classes.divider}
              >
                <Box component={Add} marginRight={0.5} />
                Add new string test
              </Button>
            ) : null}
          </Grid>
          {stringTestReports.size ? (
            <Grid item container xs={11}>
              <Tabs value={activeTab} onChange={handleTabs}>
                {stringTestReports.map((report, index) => (
                  <Tab
                    label={`String Test ${index + 1}`}
                    value={report.get('stringTestId')}
                    className={classes.tab}
                  />
                ))}
              </Tabs>
              <Divider className={classes.divider} />
              {selectedStringTestReport &&
              selectedStringTestReport.get('result') !== 0 ? (
                <StringTestEventContainer
                  projectId={projectId}
                  taskId={selectedTask}
                  selectedStringTestReport={selectedStringTestReport}
                />
              ) : null}
            </Grid>
          ) : null}
          <Grid container item xs={11} justifyContent="flex-end">
            {selectedTask &&
            tasks.size &&
            !selectedStringTestReport?.get('result') ? (
              <Button
                color="primary"
                variant="contained"
                disabled={
                  !serialNumbersDone ||
                  selectedStringTestReport?.get('stringTestReportEventId')
                }
                onClick={() => validateToolstringItemCopiesSerials()}
              >
                <Box component={PlaylistPlayIcon} marginRight={0.5} />
                Report string test
              </Button>
            ) : null}
          </Grid>
          <Grid container item xs={12} className={classes.tabsContainer}>
            {selectedTask ? (
              <ExecutionToolStringContainer
                hideDragHandle={true}
                mobilisation={true}
                taskId={selectedTask}
                projectId={projectId}
                hideExecutionOptions={true}
                externalHiddenColumns={hiddenColums}
                checkSerialNumbers={checkSerialNumbers}
                setSerialNumbersDone={setSerialNumbersDone}
                setSelectedToolstringId={setSelectedToolstringId}
                selectedStringTestReport={selectedStringTestReport}
                tasks={tasks}
                disabledSerialNumbers={disabledSerialNumbers()}
              />
            ) : (
              <NoContentBasePage
                header="No task selected"
                description="Please select a task from the list"
              />
            )}
          </Grid>
        </Grid>
        <StringTestEventModalContainer
          secondaryStringTestReports={stringTestReports.size ? true : false}
          toggleModal={toggleEventModal}
          isOpen={eventModal}
          taskId={selectedTask}
          projectId={projectId}
          selectedToolstringId={selectedToolstringId}
          selectedStringTestReport={selectedStringTestReport}
        />
      </Grid>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    ACTIONS.CREATE_STRING_TEST_REPORT,
    ACTIONS.REQUEST_STRING_TEST_REPORT_EVENT,
    ACTIONS.REQUEST_PROJECT_MOBILISATION_TASKS,
    ACTIONS.RECEIVE_PROJECT_MOBILISATION_TASKS,
    ACTIONS.REQUEST_STRING_TEST_REPORTS_FOR_TASK,
    ACTIONS.RECEIVE_STRING_TEST_REPORTS_FOR_TASK,
    ACTIONS.RECEIVE_PROJECT_MOBILISATION_TOOLSTRINGS,
  ),
});

export default compose(connect(mapStateToProps))(MobilisationContainer);
