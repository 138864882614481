import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  toggleProjectDashboard,
  updateProjectDashboard,
  toggleProjectDashboards,
  requestProjectDashboard,
  requestProjectDashboards,
} from 'features/projects/dashboard/dashboard.actions';

import { getActionDataStateFromState } from 'app/app.selectors';
import { DASHBOARDS_ACTIONS } from 'features/projects/dashboard/dashboard.constants';
import { createProjectDashboardsSelector } from 'features/projects/dashboard/dashboard.selectors';

const useProjectDashboards = (projectId) => {
  const dispatch = useDispatch();

  const dispatchRequestProjectDashboard = useCallback(
    (projectDashboardId) =>
      dispatch(requestProjectDashboard(projectId, projectDashboardId)),
    [dispatch, projectId],
  );

  const dispatchRequestProjectDashboards = useCallback(
    () => dispatch(requestProjectDashboards(projectId)),
    [dispatch, projectId],
  );

  const dispatchToggleProjectDashboard = useCallback(
    (dashboardId, callback) =>
      dispatch(toggleProjectDashboard(projectId, dashboardId, callback)),
    [dispatch, projectId],
  );

  const dispatchToggleProjectDashboards = useCallback(
    (callback) => dispatch(toggleProjectDashboards(projectId, callback)),
    [dispatch, projectId],
  );

  const dispatchUpdateProjectDashboard = useCallback(
    (projectDashboardId, projectDashboard) =>
      dispatch(
        updateProjectDashboard(projectId, projectDashboardId, projectDashboard),
      ),
    [dispatch, projectId],
  );

  const projectDashboardsSelector = useMemo(
    () => createProjectDashboardsSelector(projectId),
    [projectId],
  );

  const dataState = useSelector((state) =>
    getActionDataStateFromState(
      state,
      DASHBOARDS_ACTIONS.REQUEST_PROJECT_DASHBOARDS,
    ),
  );

  const toggleProjectDashboardDataState = useSelector((state) =>
    getActionDataStateFromState(
      state,
      DASHBOARDS_ACTIONS.TOGGLE_PROJECT_DASHBOARD,
    ),
  );

  const toggleProjectDashboardsDataState = useSelector((state) =>
    getActionDataStateFromState(
      state,
      DASHBOARDS_ACTIONS.TOGGLE_PROJECT_DASHBOARDS,
    ),
  );

  const updateProjectDashboardDataState = useSelector((state) =>
    getActionDataStateFromState(
      state,
      DASHBOARDS_ACTIONS.UPDATE_PROJECT_DASHBOARD,
    ),
  );

  const projectDashboards = useSelector(projectDashboardsSelector);

  return {
    dataState,
    projectDashboards,
    toggleProjectDashboardDataState,
    updateProjectDashboardDataState,
    toggleProjectDashboardsDataState,
    updateProjectDashboard: dispatchUpdateProjectDashboard,
    toggleProjectDashboard: dispatchToggleProjectDashboard,
    toggleProjectDashboards: dispatchToggleProjectDashboards,
    requestProjectDashboard: dispatchRequestProjectDashboard,
    requestProjectDashboards: dispatchRequestProjectDashboards,
  };
};

export default useProjectDashboards;
