import { compose } from 'redux';
import isNil from 'lodash/isNil';
import { connect, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { useState, useEffect, useCallback } from 'react';

import { Alert } from 'altus-ui-components';

import {
  cancelProjectWellboreDetailDraft,
  approveProjectWellboreDetailDraft,
  requestProjectWellboreDetailDraftApproval,
} from 'features/projects/wellbore/details/projectWellboreDetails.actions';

import {
  getProjectWellboreDetailFromState,
  getProjectWellboreDetailsForProjectFromState,
} from 'features/projects/wellbore/details/projectWellboreDetails.selectors';
import projectWellboreDetailsService from 'services/projectWellboreDetail.service';

import { EMPTY_MAP, ProjectWellboreDetailStatus } from 'app/app.constants';
import { ProjectWellboreDetailMapper } from 'features/projects/wellbore/details/projectWellboreDetails.mappers';
import ProjectWellboreDetailStatusHeaderActions from 'features/projects/wellbore/details/ProjectWellboreDetailStatusHeaderActions';

const projectWellboreStatusToString = {
  [ProjectWellboreDetailStatus.DRAFT]: 'Well Details - Needs approval',
  [ProjectWellboreDetailStatus.REQUIRES_APPROVAL]: 'Pending approval',
};

const ProjectWellboreDetailStatusHeader = ({
  classes,
  projectId,
  currentProjectWellboreDetailId,
  projectWellboreDetails = EMPTY_MAP,
  dispatchCancelProjectWellboreDetailDraft,
  dispatchApproveProjectWellboreDetailDraft,
  dispatchRequestProjectWellboreDetailDraftApproval,
}) => {
  const [latestProjectWellboreDetail, setLatestProjectWellboreDetail] =
    useState(EMPTY_MAP);

  if (latestProjectWellboreDetail && !currentProjectWellboreDetailId) {    
    currentProjectWellboreDetailId ??= latestProjectWellboreDetail.get('projectWellboreDetailId')
  }

  useEffect(
    () =>
      // If the well detail id in the path changes, re-fetch the latest well details in case it changed
      projectWellboreDetailsService
        .getLatestProjectWellboreDetail(projectId)
        .then((response) =>
          setLatestProjectWellboreDetail(
            ProjectWellboreDetailMapper.from(response),
          ),
        ),
    [projectId, currentProjectWellboreDetailId],
  );

  const currentProjectWellboreDetail = useSelector((state) =>
    currentProjectWellboreDetailId
      ? getProjectWellboreDetailFromState(state, currentProjectWellboreDetailId)
      : undefined,
  );

  // Show the banner based on the current path's well details, and fallback to the latest well details if not
  const projectWellboreDetail =
    currentProjectWellboreDetail || latestProjectWellboreDetail;

  const status = projectWellboreDetail.get('status');

  const onCancelDraft = useCallback(() => {
    dispatchCancelProjectWellboreDetailDraft(
      projectWellboreDetail.get('projectId'),
      projectWellboreDetail.get('projectWellboreDetailId'),
    );
  }, [dispatchCancelProjectWellboreDetailDraft, projectWellboreDetail]);

  const onApproveDraft = useCallback(() => {
    dispatchApproveProjectWellboreDetailDraft(
      projectWellboreDetail.get('projectId'),
      projectWellboreDetail.get('projectWellboreDetailId'),
    );
  }, [dispatchApproveProjectWellboreDetailDraft, projectWellboreDetail]);

  const onRequestDraftApproval = useCallback(() => {
    dispatchRequestProjectWellboreDetailDraftApproval(
      projectWellboreDetail.get('projectId'),
      projectWellboreDetail.get('projectWellboreDetailId'),
    );
  }, [
    dispatchRequestProjectWellboreDetailDraftApproval,
    projectWellboreDetail,
  ]);

  if (status === ProjectWellboreDetailStatus.APPROVED) {
    return null;
  }

  if (isNil(status)) return null;

  // Simple validation - hide Cancel btn if there is no other ProjectWellboreDetails
  const canCancel = projectWellboreDetails.delete(
    projectWellboreDetail.get('projectWellboreDetailId'),
  );

  return (
    <Alert
      Icon={null}
      type={null}
      classes={{
        root: classes[`root-${status}`],
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs />
        <Grid item>
          <Typography>{projectWellboreStatusToString[status]}</Typography>
        </Grid>
        <Grid container xs item justifyContent="flex-end">
          <Grid item>
            <Grid container spacing={1}>
              <ProjectWellboreDetailStatusHeaderActions
                canCancel={canCancel}
                onCancelDraft={onCancelDraft}
                onApproveDraft={onApproveDraft}
                projectWellboreDetail={projectWellboreDetail}
                onRequestDraftApproval={onRequestDraftApproval}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Alert>
  );
};

const styles = (theme) => ({
  root: {
    background: theme.altus.statusColor[ProjectWellboreDetailStatus.DRAFT],
  },
  [`root-${ProjectWellboreDetailStatus.DRAFT}`]: {
    background: theme.altus.statusColor[ProjectWellboreDetailStatus.DRAFT],
  },
  [`root-${ProjectWellboreDetailStatus.REQUIRES_APPROVAL}`]: {
    background:
      theme.altus.statusColor[ProjectWellboreDetailStatus.REQUIRES_APPROVAL],
  },
});

export default compose(
  connect(
    (state, { projectId }) => ({
      projectWellboreDetails: getProjectWellboreDetailsForProjectFromState(
        state,
        projectId,
      ),
    }),
    {
      dispatchCancelProjectWellboreDetailDraft:
        cancelProjectWellboreDetailDraft,
      dispatchApproveProjectWellboreDetailDraft:
        approveProjectWellboreDetailDraft,
      dispatchRequestProjectWellboreDetailDraftApproval:
        requestProjectWellboreDetailDraftApproval,
    },
  ),
  withStyles(styles),
)(ProjectWellboreDetailStatusHeader);
