const integrityIntegritiesFromDTO = ({ ...integrities }) => {
  return {
    ...integrities,
  };
};

const integrityIntegritiesToDTO = ({ ...integrities }) => {
  return {
    ...integrities,
  };
};

const integrityWellDataCreateDTO = (wellboreId, { ...integrities }) => {
  return {
    wellId: wellboreId,
    integrityId: integrities.integrityId,
    integrityDetailType: integrities.integrityDetailType,
    value: integrities.value,
  };
};

const integrityManagementMappers = {
  integrities: {
    from: integrityIntegritiesFromDTO,
    to: integrityIntegritiesToDTO,
    create: integrityWellDataCreateDTO,
  },
};

export default integrityManagementMappers;
