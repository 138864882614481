import { getAllDepartments, getAllServices } from 'app/app.actions';
import { getAllTasks } from 'features/projects/tasks/tasks.actions';
import { TASK_ACTIONS } from 'features/projects/tasks/task/task.constants';
import { getTaskById } from 'features/projects/tasks/task/toolstring/toolstring.actions';

export const taskContainerOnLoad = (projectId, taskId) => (dispatch) => {
  const payload = Promise.all([
    dispatch(getAllTasks(projectId)),
    dispatch(getTaskById(projectId, taskId)),
    dispatch(getAllServices()),
    dispatch(getAllDepartments()),
  ]);

  dispatch({
    type: TASK_ACTIONS.TASK_PAGE_LOADED,
    payload,
  });

  return payload;
};
