import React, { useMemo } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

import { Table } from 'altus-ui-components';

import { EMPTY_MAP } from 'app/app.constants';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';

const defaultInitialState = {
  pageSize: 50,
};

const WellboreTrajectoryTableView = ({
  unit,
  trajectoryPoints = EMPTY_MAP,
  initialState = defaultInitialState,
  ...rest
}) => {
  const columns = useMemo(
    () => [
      {
        xs: true,
        id: 'measuredDepth',
        accessor: (point) => point.getIn(['measuredDepth', 'value']),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Measured Depth" unit={unit} />
        ),
      },
      {
        xs: true,
        id: 'inclination',
        Header: 'Inclination',
        accessor: (point) => point.get('inclination'),
      },
      {
        xs: true,
        id: 'azimuth',
        Header: 'Azimuth',
        accessor: (point) => point.get('azimuth'),
      },
      {
        xs: true,
        id: 'verticalDepth',
        accessor: (point) => point.getIn(['verticalDepth', 'value']),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Vertical Depth" unit={unit} />
        ),
        Cell: ({ value }) => Math.round(value),
      },
    ],
    [unit],
  );

  if (!trajectoryPoints.size) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid component={CircularProgress} item size={20} />
      </Grid>
    );
  }

  return (
    <Table
      stickyHeader
      usePagination
      columns={columns}
      useGlobalFilter={false}
      items={trajectoryPoints}
      initialState={initialState}
      noItemsMessage="No trajectory loaded."
      {...rest}
    />
  );
};

export default React.memo(WellboreTrajectoryTableView);
