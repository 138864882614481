const DEFAULT_MIN_VALUE = 0;

export const ARM = {
  MAX_VALUE: 150,
  MIN_VALUE: DEFAULT_MIN_VALUE,
};

export const BITRPM = {
  MAX_VALUE: 200,
  MIN_VALUE: DEFAULT_MIN_VALUE,
};

export const DDRRPM = {
  MAX_VALUE: 5000,
  MIN_VALUE: DEFAULT_MIN_VALUE,
};

export const TORQUE = {
  MAX_VALUE: 120,
  MIN_VALUE: DEFAULT_MIN_VALUE,
};

export const WHEEL = {
  MAX_VALUE: 150,
  MIN_VALUE: DEFAULT_MIN_VALUE,
};

export const WOP = {
  // THIS IS WOB
  MAX_VALUE: 600,
  MIN_VALUE: 0,
};

export const WOPRPM = {
  MAX_VALUE: 200,
  MIN_VALUE: DEFAULT_MIN_VALUE,
};

export const QTELROT = {
  MAX_VALUE: 360,
  MIN_VALUE: DEFAULT_MIN_VALUE,
};

// chart gradients

export const TORQUE_GRADIENT = [
  {
    from: 0,
    to: 35,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.9, 'red'],
        [1, 'green'],
      ],
    },
  },
  {
    from: 35,
    to: 80,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.9, 'green'],
        [1, 'red'],
      ],
    },
  },
  {
    from: 80,
    to: 120,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.1, 'red'],
        [1, 'red'],
      ],
    },
  },
];

export const WOB_GRADIENT = [
  {
    from: 0,
    to: 20,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.8, 'red'],
        [1, 'green'],
      ],
    },
  },
  {
    from: 20,
    to: 200,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.9, 'green'],
        [1, 'yellow'],
      ],
    },
  },
  {
    from: 200,
    to: 350,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.9, 'yellow'],
        [1, 'red'],
      ],
    },
  },
  {
    from: 350,
    to: 600,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.1, 'red'],
        [1, 'red'],
      ],
    },
  },
];

export const BITRPM_GRADIENT = [
  {
    from: 0,
    to: 84,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.9, 'red'],
        [1, 'yellow'],
      ],
    },
  },
  {
    from: 84,
    to: 140,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.9, 'yellow'],
        [1, 'green'],
      ],
    },
  },
  {
    from: 140,
    to: 200,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.1, 'green'],
        [1, 'green'],
      ],
    },
  },
];

export const TRACWHEEL_GRADIENT = [
  {
    from: 0,
    to: 150,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [1, 'green'],
        [1, 'green'],
      ],
    },
  },
];

export const TRACARM_GRADIENT = [
  {
    from: 0,
    to: 140,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.9, 'green'],
        [1, 'red'],
      ],
    },
  },
  {
    from: 140,
    to: 150,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.9, 'red'],
        [1, 'red'],
      ],
    },
  },
];

export const DDRRPM_GRADIENT = [
  {
    from: 0,
    to: 2000,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.9, 'red'],
        [1, 'yellow'],
      ],
    },
  },
  {
    from: 2000,
    to: 4000,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.9, 'yellow'],
        [1, 'green'],
      ],
    },
  },
  {
    from: 4000,
    to: 5000,
    color: {
      linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
      stops: [
        [0.1, 'green'],
        [1, 'green'],
      ],
    },
  },
];
