import { withStyles } from '@material-ui/core';
import { TableHeaderCell } from 'altus-ui-components';

const DocumentsTableHeaderCell = ({ classes, ...rest }) => (
  <TableHeaderCell
    classes={{ columnTitle: classes.cell, root: classes.root }}
    {...rest}
  />
);

const styles = () => ({
  root: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  cell: {
    width: '100%',
  },
});

export default withStyles(styles)(DocumentsTableHeaderCell);
