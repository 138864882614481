import { compose } from 'redux';
import { memo, useCallback } from 'react';
import { Grid, Button, Box } from '@material-ui/core';

const AddEquipmentTemplatesModalFooter = ({
  selectedTools,
  equipmentTemplateInfo,
  onSaveEquipmentTemplate,
  isEditMode,
}) => {
  const checkSaveDisabled = useCallback(() => {
    if (
      equipmentTemplateInfo !== null &&
      equipmentTemplateInfo.name !== '' &&
      selectedTools.length > 0
    ) {
      return false;
    }
    return true;
  }, [selectedTools, equipmentTemplateInfo]);

  return (
    <Grid
      item
      container
      justifyContent="spaceBetween"
      alignItems="center"
      component={Box}
    >
      <Grid
        item
        container
        justifyContent="spaceBetween"
        xs={6}
        alignItems="center"
      ></Grid>
      <Grid item container justifyContent="flex-end" xs={6}>
        <Button
          color="primary"
          variant="contained"
          disabled={checkSaveDisabled()}
          onClick={onSaveEquipmentTemplate}
        >
          {`${isEditMode ? 'Update' : 'Save'}`}
        </Button>
      </Grid>
    </Grid>
  );
};

export default compose(memo)(AddEquipmentTemplatesModalFooter);
