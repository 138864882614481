import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import Replay from '@material-ui/icons/Replay';
import withStyles from '@material-ui/styles/withStyles';

import { Button } from 'app/components/withTooltip';
import { toggleEndOperationModal } from 'features/projects/activities/activities.actions';
import EndOperationModalContainer from 'features/projects/activities/components/EndOperationModalContainer';

const styles = (theme) => ({
  completeTask: {
    marginTop: 30,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    textTransform: 'none',
  },
});

const EndOperation = ({
  goBack,
  classes,
  projectId,
  dispatchToggleEndOperationModal,
}) => {
  return (
    <Grid item className={classes.completeTask}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => dispatchToggleEndOperationModal(true)}
          >
            <Check className={classes.leftIcon} />
            Project Objective achieved
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => dispatchToggleEndOperationModal(false)}
          >
            <Close className={classes.leftIcon} />
            Project Objective NOT achieved
          </Button>
        </Grid>
        <Grid item>
          <Button title="Go back" variant="contained" onClick={goBack}>
            <Replay />
          </Button>
        </Grid>
      </Grid>
      <EndOperationModalContainer projectId={projectId} />
    </Grid>
  );
};

export default connect(null, {
  dispatchToggleEndOperationModal: toggleEndOperationModal,
})(withStyles(styles)(EndOperation));
