import React from 'react';
import { stateToHTML } from 'draft-js-export-html';

const EditorStateViewer = ({ editorState }) => {
  const contentState = editorState.getCurrentContent();
  const html = stateToHTML(contentState);
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default EditorStateViewer;
