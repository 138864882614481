import React, { useEffect } from 'react';
import { IntegrityForm } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { Grid } from '@material-ui/core';
import { useFormikContext, Field } from 'formik';

const GeographyRuleDataFormComponent = () => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    // Set SRID value once on mount
    setFieldValue(`${IntegrityForm.VALUE}.${IntegrityForm.SRID}`, 4326);
  }, [setFieldValue]);

  return (
    <>
      <Grid item xs={6} container>
        <Field
          type="number"
          label="Latitude"
          component={TextFieldFormik}
          name={`${IntegrityForm.VALUE}.${IntegrityForm.LATITUDE}`}
        />
      </Grid>
      <Grid item xs={6} container>
        <Field
          type="number"
          label="Longitude"
          component={TextFieldFormik}
          name={`${IntegrityForm.VALUE}.${IntegrityForm.LONGITUDE}`}
        />
      </Grid>
    </>
  );
};

export default GeographyRuleDataFormComponent;
