import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import EventIcon from '@material-ui/icons/FlashOn';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import { formatDate } from 'utils/format.util';

const styles = (theme) => ({
  eventItem: {
    backgroundColor: theme.palette.background.paper,
  },
  centerRow: { justifyContent: 'center', display: 'inline-flex' },
  eventlist: {
    width: '100%',
  },
  author: {
    color: theme.palette.text.secondary,
    textAlign: 'right',
  },
  eventText: {
    color: theme.palette.text.primary,
    fontSize: '1rem',
  },
  created: {
    textAlign: 'right',
    fontSize: 10,
  },
  emptyIcon: {
    fontSize: 150,
    color: theme.palette.action.disabled,
  },
  noEvents: { marginTop: '15vh' },
});

const EventList = (props) => {
  const { classes, events, currentUser, kind } = props;

  if (events.length === 0) {
    return (
      <Grid item xs={12} align="center" className={classes.noEvents}>
        <EventIcon className={classes.emptyIcon} />
        <Typography variant="h5">
          There are currently no events for this {kind}
        </Typography>
      </Grid>
    );
  }
  return (
    <List className={classes.eventlist}>
      {events.map((event) => {
        return (
          <ListItem key={`event-${event.id}`} divider>
            <ListItemIcon>
              <ListItemIcon>
                <EventIcon color="action" />
              </ListItemIcon>
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="h6">{`${
                  event.author.id === currentUser.id ? 'You' : event.author.name
                } ${event.text}`}</Typography>
              }
            />
            <ListItemText
              classes={{
                primary: classes.author,
                secondary: classes.created,
              }}
              secondary={formatDate(event.created)}
            />
            <ListItemAvatar>
              {event.author.avatar ? (
                <Avatar src={event.author.avatar} />
              ) : (
                <Avatar>{event.author.initials}</Avatar>
              )}
            </ListItemAvatar>
          </ListItem>
        );
      })}
    </List>
  );
};

export default withStyles(styles)(EventList);
