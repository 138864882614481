import { compose } from 'redux';
import { Box } from '@material-ui/core';
import { PUBLIC_ASSETS_URL } from 'app/app.constants';
import withStyles from '@material-ui/styles/withStyles';
import React, { memo, useCallback, useState, useEffect } from 'react';
import NavigationHelpTooltip from 'app/components/WellboreContextualization/NavigationHelpTooltip';
import WellboreTrajectoryLegends from 'app/components/WellboreTrajectory/WellboreTrajectoryLegends';
import ThreeFiberSceneWrapper from 'app/components/WellboreContextualization/ThreeFiberSceneWrapper';
import WellboreContextTrajectory from 'app/components/WellboreContextualization/WellboreContextTrajectory';
import { useViewMode } from 'app/hooks/useViewMode';

const WellboreContext3DContainer = ({
  classes,
  disableOrbitControls,
  hideLegends,
  hideHelp,
  trajectoryRef,
  nippleRef,
  groundLayerRef,
  depthMarkerRef,
  maxNE,
  maxTVD,
  depthsOfInterest,
}) => {
  const [domElement, setDomElement] = useState();
  const viewMode = useViewMode();
  const [help, setHelp] = useState();
  const rootRef = useCallback((node) => {
    if (node) {
      setDomElement(node);
    }
  }, []);

  useEffect(() => {
    prepareHelpData();
    // eslint-disable-next-line
  }, []);

  const prepareHelpData = () => {
    setHelp({
      title:
        viewMode === 'mobile'
          ? 'How to interact with 3D well?'
          : 'HOW TO INTERACT WITH 3D WELL?',
      data: [
        {
          navType: '1. Dragging around the well:',
          content: 'Use simple dragging to go around the well',
          src: `${PUBLIC_ASSETS_URL}/images/navigation/navigate.png`,
        },
        {
          navType: '2. Zoom in and out:',
          content:
            viewMode === 'mobile' ? (
              'Use two fingers and move them closer to zoom in further to zoom out'
            ) : (
              <ul>
                <li>
                  Touchpad: Use two fingers and move them closer to zoom in
                  further to zoom out
                </li>
                <li>
                  Mouse: Scroll forward to zoom in and backward to zoom out.
                </li>
              </ul>
            ),
          src: `${PUBLIC_ASSETS_URL}/images/navigation/zoomIn.png`,
        },
        {
          navType: '3. Navigate up and down:',
          content:
            viewMode === 'mobile'
              ? 'Use simple dragging with two fingers to go up and down the well.'
              : 'Use control key and simple dragging to go up and down on the well.',
          src:
            viewMode === 'mobile'
              ? `${PUBLIC_ASSETS_URL}/images/navigation/upDown.png`
              : `${PUBLIC_ASSETS_URL}/images/navigation/ctrl.png`,
        },
      ],
    });
  };
  return (
    <>
      <ThreeFiberSceneWrapper
        maxNE={maxNE}
        maxTVD={maxTVD}
        domElement={domElement}
      >
        <WellboreContextTrajectory
          trajectoryRef={trajectoryRef}
          nippleRef={nippleRef}
          groundLayerRef={groundLayerRef}
          depthMarkerRef={depthMarkerRef}
        />
      </ThreeFiberSceneWrapper>
      <Box
        ref={rootRef}
        className={classes.root}
        onClick={(event) => {
          // Do not propagate click events that happen on the scene (like when clicking to orbit around)
          if (!disableOrbitControls) {
            event.stopPropagation();
          }
        }}
      >
        {!hideHelp && (
          <Box className={classes.help}>
            {help && <NavigationHelpTooltip data={help} />}
          </Box>
        )}
        {!hideLegends && (
          <Box className={classes.legend}>
            <WellboreTrajectoryLegends legends={depthsOfInterest} />
          </Box>
        )}
      </Box>
    </>
  );
};

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'inline-block',
    position: 'relative',
  },
  legend: {
    position: 'absolute',
    left: theme.spacing(1),
    bottom: theme.spacing(1),
  },
  help: {
    position: 'absolute',
    top: theme.spacing(5),
  },
});

export default compose(memo, withStyles(styles))(WellboreContext3DContainer);
