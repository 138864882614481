import { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';

import { useModal } from 'altus-modal';
import { TableRow } from 'altus-ui-components';

import {
  TASKS_CREATE_TASK_MODE,
  TASKS_CREATE_TASK_MODAL_ID,
} from 'features/projects/tasks/tasks.constants';

import { EMPTY_LIST } from 'app/app.constants';
import TextFieldV2 from 'app/components/form/TextFieldV2';
import ProjectTaskServiceChips from 'app/components/ProjectTaskServiceChips';
import CreateTaskModalContainer from 'features/projects/tasks/components/createTask/CreateTaskModalContainer';

const ProjectTaskServicesField = ({
  label,
  input,
  projectId,
  templateTasks,
  ...rest
}) => {
  const { value = EMPTY_LIST, name } = input;

  const renderValue = useCallback(
    () => (
      // wrap the ProjectTaskServiceChips in a Grid to make them wrap on new lines if necessary
      <Grid container>
        <ProjectTaskServiceChips taskServices={value} />
      </Grid>
    ),
    [value],
  );

  const [_, toggleModal] = useModal(TASKS_CREATE_TASK_MODAL_ID);

  const serviceTemplateTasks = useMemo(
    () =>
      templateTasks.filter((templateTask) => !!templateTask.get('serviceId')),
    [templateTasks],
  );

  return (
    <>
      <TextFieldV2
        select
        name={name}
        label={label}
        value={value.toArray()}
        SelectProps={{
          open: false,
          renderValue,
          multiple: true,
          onOpen: toggleModal,
        }}
        {...rest}
      >
        {EMPTY_LIST.toArray()}
      </TextFieldV2>
      {/* Since there are currently 1 Task per Service, we can reuse the CreateTaskModalContainer to create 
        a Task directly instead of adding a Service that will add a Task.
        
        TODO: Consider adding a proper Service selection modal
        */}
      <CreateTaskModalContainer
        displayMenu={false}
        title="Add Sub Objectives"
        projectId={projectId}
        templateTasks={serviceTemplateTasks}
        FromTemplateTableRowComponent={TableRow}
        defaultMode={TASKS_CREATE_TASK_MODE.FROM_TEMPLATE}
      />
    </>
  );
};

export default ProjectTaskServicesField;
