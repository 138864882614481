import { Grid } from '@material-ui/core';

import OperationCardItem from 'features/operations/OperationCardItem';
import ThreeJsManagerProvider from 'app/threeJs/ThreeJsManagerProvider';

const OperationsCardsView = ({ operations, scrollContainerRef }) => (
  <Grid item container spacing={2}>
    <ThreeJsManagerProvider>
      {operations.valueSeq().map((operation) => (
        <OperationCardItem
          operation={operation}
          key={operation.get('projectId')}
          scrollContainerRef={scrollContainerRef}
        />
      ))}
    </ThreeJsManagerProvider>
  </Grid>
);

export default OperationsCardsView;
