export const requestDeleteFluidGeneric = (
  actionType,
  fluidOriginAsObj,
  fluidId,
) => ({
  ...fluidOriginAsObj,
  fluidId,
  type: actionType,
  confirmationDialog: {
    title: 'Delete fluid',
    confirmButtonText: 'Delete',
    description: 'Are you sure you want to delete this fluid? ',
  },
  notification: {
    info: 'Deleting...',
    success: 'Fluid successfully deleted',
  },
});
