import { Link } from 'react-router-dom';
import { createElement } from 'react';

import {
  Fab as MuiFab,
  Grid as MuiGrid,
  Paper as MuiPaper,
  Button as MuiButton,
  IconButton as MuiIconButton,
  StepButton as MuiStepButton,
  Typography as MuiTypography,
} from '@material-ui/core';

export const withNavigation =
  (WrappedComponent) =>
  ({
    to,
    replace,
    linkClassName,
    linkComponent = Link,
    target,
    ...passThroughProps
  }) => {
    const styles = linkClassName
      ? undefined
      : { display: 'contents', color: 'inherit' };

    return to ? (
      createElement(
        linkComponent,
        {
          to,
          replace,
          style: styles,
          href: to,
          target,
          className: linkClassName,
        },
        <WrappedComponent {...passThroughProps} />,
      )
    ) : (
      <WrappedComponent {...passThroughProps} />
    );
  };

export default withNavigation;

export const Fab = withNavigation(MuiFab);
export const Image = withNavigation('img');
export const Grid = withNavigation(MuiGrid);
export const Paper = withNavigation(MuiPaper);
export const Button = withNavigation(MuiButton);
export const IconButton = withNavigation(MuiIconButton);
export const StepButton = withNavigation(MuiStepButton);
export const Typography = withNavigation(MuiTypography);
