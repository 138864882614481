import { useDispatch } from 'react-redux';
import { useMemo, useCallback, useEffect } from 'react';
import { replace } from 'connected-react-router/immutable';
import { Box, MenuItem, TextField } from '@material-ui/core';

import { BasePage } from 'altus-ui-components';
import { getSummarizedDataState } from 'altus-datastate';

import { toCheetahJobCurves } from 'utils/route.util';
import { EMPTY_LIST, EMPTY_STRING } from 'app/app.constants';
import useCheetahJobs from 'features/data-exchange/hooks/useCheetahJobs';
import useCheetahJobCurves from 'features/data-exchange/hooks/useCheetahJobCurves';
import CheetahJobCurvesTable from 'features/data-exchange/live-projects/components/CheetahJobCurvesTable';

const CheetahJobCurvesContainer = ({
  path,
  source,
  projectId,
  breadcrumb,
  cheetahJobId = EMPTY_STRING,
}) => {
  const dispatch = useDispatch();

  const setCheetahJobId = useCallback(
    (cheetahJobId) =>
      dispatch(
        replace(toCheetahJobCurves(projectId, cheetahJobId, null, path)),
      ),
    [path, projectId, dispatch],
  );

  const filter = useMemo(
    () => ({
      source,
      projectId,
    }),
    [source, projectId],
  );

  const {
    cheetahJobs,
    searchCheetahJobs,
    dataState: cheetahJobsDataState,
  } = useCheetahJobs(filter);

  useEffect(() => {
    searchCheetahJobs();
  }, [searchCheetahJobs]);

  useEffect(() => {
    const initialCheetahJob = cheetahJobs.first();

    if (cheetahJobId) return;
    if (!initialCheetahJob) return;

    setCheetahJobId(initialCheetahJob.get('id'));
  }, [cheetahJobs, cheetahJobId, setCheetahJobId]);

  // prevent console warning if the selected cheetahJob has not arrived yet
  var selectedCheetahJobId = cheetahJobs.has(cheetahJobId)
    ? cheetahJobId
    : EMPTY_STRING;

  const [cheetahJobCurves = EMPTY_LIST, cheetahJobCurvesDataState] =
    useCheetahJobCurves(cheetahJobId);

  const dataState = useMemo(
    () =>
      getSummarizedDataState([cheetahJobsDataState, cheetahJobCurvesDataState]),
    [cheetahJobsDataState, cheetahJobCurvesDataState],
  );

  return (
    <BasePage title={breadcrumb} dataState={dataState}>
      <TextField
        select
        label="Select job"
        value={selectedCheetahJobId}
        onChange={(event) => setCheetahJobId(event.target.value)}
      >
        {cheetahJobs.valueSeq().map((cheetahJob) => (
          <MenuItem key={cheetahJob.get('id')} value={cheetahJob.get('id')}>
            {cheetahJob.get('displayName')}
          </MenuItem>
        ))}
      </TextField>
      <Box marginBottom={2} />
      <CheetahJobCurvesTable cheetahJobCurves={cheetahJobCurves} />
    </BasePage>
  );
};

export default CheetahJobCurvesContainer;
