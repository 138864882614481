import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TableRow } from 'altus-ui-components';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const SimulationFluidTableRow = ({ navigateToFluid, ...rest }) => {
  const classes = useStyles();
  return (
    <Link replace className={classes.root} to={navigateToFluid()}>
      <TableRow {...rest} />
    </Link>
  );
};

export default SimulationFluidTableRow;
