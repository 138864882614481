import { useMemo } from 'react';

import { EMPTY_SET } from 'app/app.constants';
import { hasOneOrMorePermissions } from 'utils/authorization.util';

export const usePermissions = (
  userPermissions = EMPTY_SET,
  permissions = EMPTY_SET,
) => {
  const hasPermission = useMemo(
    () => hasOneOrMorePermissions(userPermissions, permissions),
    [permissions, userPermissions],
  );

  return { hasPermission, userPermissions, permissions };
};
