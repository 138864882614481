import React from 'react';
import { useRef } from 'react';
import RenderLayer from 'app/components/WellboreContextualization/RenderLayer';
import DrawNipples from 'app/components/WellboreContextualization/DrawNipples';
import DrawCylinder from 'app/components/WellboreContextualization/DrawCylinder';

const WellboreContextTrajectory = ({
  trajectoryRef,
  nippleRef,
  groundLayerRef,
  depthMarkerRef,
}) => {
  const nippleTextRef = useRef([]);
  return (
    <>
      <RenderLayer layerRef={trajectoryRef} />
      <RenderLayer layerRef={groundLayerRef} />
      <DrawNipples nippleRef={nippleRef} nippleTextRef={nippleTextRef} />
      {depthMarkerRef.current.map((trajectory, index) => (
        <DrawCylinder
          key={index}
          name={trajectory.value.name}
          measuredDepth={trajectory.key}
          v1={trajectory.value.startVector}
          v2={trajectory.value.endVector}
          radius={trajectory.value.radius}
          length={trajectory.value.length}
          isHalf={false}
          material={trajectory.value.material}
        />
      ))}
    </>
  );
};

export default WellboreContextTrajectory;
