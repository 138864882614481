import { Field } from 'formik';
import { useMemo, useCallback } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, MenuItem, IconButton, InputAdornment } from '@material-ui/core';

import { Table } from 'altus-ui-components';

import { formatDate } from 'utils/format.util';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { EMPTY_MAP, Format, ProjectPermission } from 'app/app.constants';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import DocumentsTableHeaderCell from 'features/projects/documents/newDocumentsContainer/components/DocumentsTableHeaderCell';

const DocumentsTable = ({
  user,
  documents,
  deleteFile,
  updateFile,
  downloadFile,
  filteredDocuments,
  emptpyFiltering = false,
  documentCategories = EMPTY_MAP,
  useGlobalFilter = true,
  filterApplied,
  ...rest
}) => {
  const currentDocs =
    filteredDocuments.size > 0 && !filterApplied
      ? filteredDocuments
      : documents;
  const documentFiltering =
    !emptpyFiltering && !filterApplied ? currentDocs : filteredDocuments;

  const columns = useMemo(
    () => [
      {
        xs: 3,
        Header: 'Document',
        aaccessor: (document) => document.get('fullName', null),
        Cell: ({ row }) => {
          return (
            <Field
              name="name"
              component={TableRowTextFieldFormik}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {row.original.get('extension')}
                  </InputAdornment>
                ),
              }}
            />
          );
        },
      },
      {
        xs: 2,
        Header: 'Category',
        accessor: (document) => document.get('categoryName', null),
        Cell: () => {
          return (
            <Field select name="category" component={TableRowTextFieldFormik}>
              {documentCategories.valueSeq().map((category) => (
                <MenuItem key={category.get('id')} value={category.get('id')}>
                  {category.get('name')}
                </MenuItem>
              ))}
            </Field>
          );
        },
      },
      {
        xs: 3,
        Header: 'Uploaded',
        accessor: (document) =>
          formatDate(document.get('created'), Format.time),
      },
      {
        xs: 2,
        Header: 'Uploaded by',
        accessor: (document) => document.getIn(['createdByUser', 'fullName']),
      },
      {
        xs: 2,
        id: 'actions',
        Cell: ({ row }) => {
          return (
            <Grid item container justifyContent="flex-end">
              <HasProjectPermission
                permissions={ProjectPermission.DELETE_ALL_DOCUMENTS}
              >
                <IconButton
                  title="Delete"
                  onClick={() => deleteFile(row.original.get('fileId'))}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </HasProjectPermission>
              <HasProjectPermission
                permissions={ProjectPermission.DOWNLOAD_DOCUMENTS}
              >
                <IconButton
                  title="Download"
                  onClick={() =>
                    downloadFile(row.original.get('fileId'), row.original)
                  }
                >
                  <SaveIcon fontSize="small" />
                </IconButton>
              </HasProjectPermission>
              <IconButton
                component="a"
                target="_blank"
                href={row.original.get('editDocumentUrl')}
                disabled={!row.original.get('editDocumentUrl')}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Grid>
          );
        },
      },
    ],
    [documentCategories, deleteFile, downloadFile],
  );

  const renderTableRowComponent = useCallback(
    (props) => <EditableTableRowFormik {...props} onSubmit={updateFile} />,
    [updateFile],
  );

  return (
    <Table
      usePagination
      columns={columns}
      items={documentFiltering}
      useGlobalFilter={useGlobalFilter}
      TableRowComponent={renderTableRowComponent}
      TableHeaderCellComponent={DocumentsTableHeaderCell}
      {...rest}
    />
  );
};

export default DocumentsTable;
