import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';

import { useToggle } from 'altus-hooks';
import { MultiSelectModal } from 'altus-ui-components';

import { EMPTY_LIST } from 'app/app.constants';
import { Typography } from 'app/components/withTooltip';
import TextFieldV2 from 'app/components/form/TextFieldV2';

const defaultValidate = () => true;

const defaultGetSubmitLabel = (selectedItems) =>
  selectedItems.size ? `Select (${selectedItems.size})` : 'Select';

const MultiSelectTextFieldFormik = ({
  label,
  field,
  items,
  getKey,
  getName,
  disabled,
  validate = defaultValidate,
  getSubmitLabel = defaultGetSubmitLabel,
}) => {
  const { setFieldValue } = useFormikContext();
  const { value = EMPTY_LIST, name } = field;

  const [isOpen, toggleModal] = useToggle();

  const itemsById = items.toMap().mapKeys((_, item) => getKey(item));

  const renderValue = useCallback(
    () => (
      <Typography variant="body2">
        {value.size ? `${value.size} selected` : <i>All</i>}
      </Typography>
    ),
    [value],
  );

  const initialSelectedItems = useMemo(
    () => value.map((itemId) => itemsById.get(itemId)).toSet(),
    [value, itemsById],
  );

  const onSubmit = useCallback(
    (selectedItems) => {
      setFieldValue(name, selectedItems.map(getKey));
      toggleModal();
    },
    [setFieldValue, toggleModal, getKey, name],
  );

  return (
    <>
      <TextFieldV2
        select
        name={name}
        label={label}
        disabled={disabled}
        value={value.toArray()}
        SelectProps={{
          open: false,
          displayEmpty: true,
          renderValue,
          multiple: true,
          onOpen: toggleModal,
        }}
      >
        {EMPTY_LIST.toArray()}
      </TextFieldV2>
      <MultiSelectModal
        open={isOpen}
        items={items}
        title={label}
        getName={getName}
        onSubmit={onSubmit}
        validate={validate}
        onClose={toggleModal}
        key={initialSelectedItems}
        getSubmitLabel={getSubmitLabel}
        initialSelectedItems={initialSelectedItems}
      />
    </>
  );
};

export default MultiSelectTextFieldFormik;
