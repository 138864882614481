import { Grid } from '@material-ui/core';
import { useResizeDetector } from 'react-resize-detector';

const DynamicDockManagerSlot = ({ index, children }) => {
  // When the DockManager is rendering the slots, there seems to be a delay before they get the correct width and height
  // Highcharts elements are mounted before the correct width and height is set, therefore they will not scale correctly
  const { ref, width, height } = useResizeDetector();

  const displayContent = !!width && !!height;

  return (
    <Grid ref={ref} className="content1" slot={`content${index}`}>
      {displayContent && children}
    </Grid>
  );
};

export default DynamicDockManagerSlot;
