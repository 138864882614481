import { fromJS, Map, OrderedMap } from 'immutable';

import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import toolMapper from 'features/projects/tool/tool.mappers';
import tasksMapper from 'features/projects/tasks/tasks.mappers';
import { TASK_ACTIONS } from 'features/projects/tasks/tasks.constants';
import toolstringMapper from 'features/projects/tasks/task/toolstring/toolstring.mappers';
import { TOOLSTRING_ACTIONS } from 'features/projects/tasks/task/toolstring/toolstring.constants';
import { RISK_TABLE_ACTIONS } from 'features/projects/tasks/task/details/taskDetails.constants';
import { TASK_ACTIVITIES_ACTIONS } from 'features/projects/tasks/task/activities/taskActivities.constants';

const initialState = fromJS({
  data: Map({
    toolstringsByTaskId: EMPTY_MAP,
    toolstringSectionsByToolstringId: EMPTY_MAP,
    toolstringById: EMPTY_MAP,
    task: tasksMapper.Task.initial(),
    plannedToolstringToolsWithStatus: EMPTY_LIST,
  }),
});

const toolstringReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TASK_ACTIONS.RECEIVE_TASK: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'task'],
        tasksMapper.Task.from(action.payload),
      );
    }

    case RISK_TABLE_ACTIONS.RECEIVE_TASK_RISK_SECTIONS: {
      if (action.error) return state;

      return state.setIn(['data', 'task', 'taskRisk'], fromJS(action.payload));
    }

    case TOOLSTRING_ACTIONS.RECEIVE_TOOLSTRING_TEMPLATES: {
      if (action.error) return state;

      const { payload } = action;
      return state.setIn(
        ['data', 'toolstringTemplates'],
        Map(
          payload.map((toolstring) => [
            toolstring.toolStringId.toString(),
            toolstringMapper.Toolstring.from(toolstring),
          ]),
        ),
      );
    }

    case TOOLSTRING_ACTIONS.RECEIVE_TASK_TOOLSTRINGS:
    case TOOLSTRING_ACTIONS.GET_TOOLSTRINGS_FOR_TASK: {
      if (action.error) return state;

      const { payload, taskId } = action;
      return state.setIn(
        ['data', 'toolstringsByTaskId', taskId.toString()],
        OrderedMap(
          payload.map((toolstring) => [
            toolstring.toolStringId.toString(),
            toolstringMapper.Toolstring.from(toolstring),
          ]),
        ),
      );
    }

    case TOOLSTRING_ACTIONS.RECEIVE_TASK_TOOLSTRING:
    case TOOLSTRING_ACTIONS.GET_TOOLSTRING_FOR_TASK:
    case TOOLSTRING_ACTIONS.CREATE_TOOLSTRING_FOR_TASK: {
      if (action.error || !action.payload) return state;

      const toolstring = toolstringMapper.Toolstring.from(action.payload);

      return state
        .setIn(
          ['data', 'toolstringById', toolstring.get('id').toString()],
          toolstring,
        )
        .setIn(
          [
            'data',
            'toolstringsByTaskId',
            toolstring.get('taskId').toString(),
            toolstring.get('id').toString(),
          ],
          toolstring,
        );
    }

    case TOOLSTRING_ACTIONS.UPDATE_TOOLSTRING_FOR_TASK: {
      if (action.error) return state;

      const { _toolStringTools, ...simplePayload } = action.payload;
      const simpleToolstring = toolstringMapper.Toolstring.from(simplePayload);
      const toolstring = toolstringMapper.Toolstring.from(action.payload);
      return state
        .setIn(
          [
            'data',
            'toolstringsByTaskId',
            action.payload.taskId.toString(),
            simpleToolstring.get('id').toString(),
          ],
          simpleToolstring,
        )
        .setIn(
          ['data', 'toolstringById', toolstring.get('id').toString()],
          toolstring,
        );
    }
    case TOOLSTRING_ACTIONS.DELETE_TOOLSTRING_FOR_TASK: {
      if (action.error) return state;

      const { toolstringId } = action;
      return state.deleteIn([
        'data',
        'toolstringById',
        toolstringId.toString(),
      ]);
    }

    case TOOLSTRING_ACTIONS.RECEIVE_TOOLSTRING_TOOLS_UPDATED_SEQUENCES: {
      if (action.error) return state;

      const { toolstringId } = action;
      action.payload.forEach((sequence) => {
        state = state.updateIn(
          [
            'data',
            'toolstringById',
            toolstringId.toString(),
            'toolsByToolstringToolId',
            sequence.itemId.toString(),
            'sequence',
          ],
          () => sequence.newSequenceNumber,
        );
      });
      return state;
    }

    case TOOLSTRING_ACTIONS.RECEIVE_TOOLSTRING_SECTIONS: {
      if (action.error) return state;

      const { toolstringId, payload } = action;
      return state.setIn(
        ['data', 'toolstringSectionsByToolstringId', toolstringId.toString()],
        toolstringMapper.ToolstringSections.from(payload),
      );
    }

    case TOOLSTRING_ACTIONS.GET_CABLES: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'cables'],
        toolMapper.CableSearch.from(action.payload),
      );
    }

    case TOOLSTRING_ACTIONS.GET_BHA_TEMPLATES: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'bhaTemplates'],
        toolMapper.BhaTemplatesSearch.from(action.payload),
      );
    }

    case TOOLSTRING_ACTIONS.RECEIVE_TOOLSTRING_ITEM: {
      if (action.error) return state;

      const { toolstringId, payload: toolstringItem } = action;

      return state.setIn(
        [
          'data',
          'toolstringById',
          toolstringId.toString(),
          'toolsByToolstringToolId',
          toolstringItem.toolstringItemId.toString(),
        ],
        toolstringMapper.ToolstringItem.from(toolstringItem),
      );
    }

    case TOOLSTRING_ACTIONS.RECEIVE_TOOLSTRING_ASSEMBLY_TOOL: {
      const { toolstringId, toolstringItemId, payload } = action;

      return state.updateIn(
        [
          'data',
          'toolstringById',
          toolstringId.toString(),
          'toolsByToolstringToolId',
          toolstringItemId.toString(),
          'toolAssemblyTools',
        ],
        (toolAssemblyTools) => {
          const toolstringAssemblyTool =
            toolstringMapper.ToolAssemblyTool.from(payload);

          const toolIndex = toolAssemblyTools.findIndex(
            (toolAssemblyTool) =>
              toolAssemblyTool.get('toolAssemblyToolId') ===
              toolstringAssemblyTool.get('toolAssemblyToolId'),
          );

          return toolIndex > 0
            ? toolAssemblyTools.set(toolIndex, toolstringAssemblyTool)
            : toolAssemblyTools.push(toolstringAssemblyTool);
        },
      );
    }

    case TASK_ACTIVITIES_ACTIONS.TASK_ACTIVITIES_RECEIVE_PLANNED_TOOLSTRING: {
      if (action.error) return state;

      const toolstring = action.payload;
      const filteredTools = toolstring.toolStringItems.filter(
        (tool) => tool.toolStatus !== 0,
      );

      return state.setIn(
        ['data', 'plannedToolstringToolsWithStatus'],
        fromJS(filteredTools),
      );
    }

    case TASK_ACTIVITIES_ACTIONS.TASK_ACTIVITIES_RECEIVE_NO_TOOLSTRING: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'plannedToolstringToolsWithStatus'],
        EMPTY_LIST,
      );
    }

    case TOOLSTRING_ACTIONS.ADD_ASSETS_TO_TOOLSTRING: {
      if (action.error) return state;

      let toolstringsByTaskId = state.getIn(['data', 'toolstringsByTaskId']);
      let toolstringsByTaskIdToJS = toolstringsByTaskId.toJS();

      const serialNumberMap = action.payload.stringTestReport.assets.reduce(
        (map, asset) => {
          map[asset.itemNumber] = asset.serialNumber;
          return map;
        },
        {},
      );

      let toolsByToolstringToolId =
        toolstringsByTaskIdToJS[action.payload.taskId][
          action.payload.toolstringId
        ].toolsByToolstringToolId;

      let updatedToolsByToolstringToolId = Object.entries(
        toolsByToolstringToolId,
      ).reduce((result, [toolId, tool]) => {
        if (serialNumberMap[tool.m3ItemNumber]) {
          result[toolId] = {
            ...tool,
            serialNo: serialNumberMap[tool.m3ItemNumber],
          };
        } else {
          result[toolId] = tool;
        }
        return result;
      }, {});

      toolstringsByTaskIdToJS[action.payload.taskId][
        action.payload.toolstringId
      ].toolsByToolstringToolId = updatedToolsByToolstringToolId;

      return state.setIn(
        ['data', 'toolstringsByTaskId'],
        fromJS(toolstringsByTaskIdToJS),
      );
    }

    default:
      return state;
  }
};

export default toolstringReducer;
