import { anyLoadingDataState, LoadingDataState } from 'altus-datastate';

import LoadingDimmer from 'app/components/LoadingDimmer';

const withDataState =
  (WrappedComponent) =>
  ({
    loadingText = 'Loading',
    renderWhileLoading = false,
    dataState = LoadingDataState,
    LoadingDimmerProps,
    innerRef,
    ...rest
  }) => {
    const isLoading = anyLoadingDataState(dataState);

    if (!isLoading) {
      return <WrappedComponent ref={innerRef} {...rest} />;
    }

    return (
      <>
        <LoadingDimmer text={loadingText} {...LoadingDimmerProps} />
        {!!renderWhileLoading && <WrappedComponent ref={innerRef} {...rest} />}
      </>
    );
  };

export default withDataState;
