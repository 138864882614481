export const CheetahJobSource = {
  OTHER: 0,
  CHEETAH: 1,
  WARRIOR: 2,
  SM_LOCATION: 3,
  LWI: 4,
  SIMULATION: 5,
  WITSML: 6,
};

export const WarriorDashboardType = {
  CALIPER: 'caliper',
  CORRELATION: 'correlation',
  WINCH: 'winch',
  CALIPER_NEW: 'calipernew',
  CORRELATION_NEW: 'correlationnew',
};

export const maxTimeSpan = 172800000;

export const DASHBOARDS_ACTIONS = {
  RECEIVE_DASHBOARDS: 'RECEIVE_DASHBOARDS',
  REQUEST_DASHBOARDS: 'REQUEST_DASHBOARDS',
  TOGGLE_PROJECT_DASHBOARD: 'TOGGLE_PROJECT_DASHBOARD',
  UPDATE_PROJECT_DASHBOARD: 'UPDATE_PROJECT_DASHBOARD',
  TOGGLE_PROJECT_DASHBOARDS: 'TOGGLE_PROJECT_DASHBOARDS',
  RECEIVE_PROJECT_DASHBOARD: 'RECEIVE_PROJECT_DASHBOARD',
  REQUEST_PROJECT_DASHBOARD: 'REQUEST_PROJECT_DASHBOARD',
  RECEIVE_PROJECT_DASHBOARDS: 'RECEIVE_PROJECT_DASHBOARDS',
  REQUEST_PROJECT_DASHBOARDS: 'REQUEST_PROJECT_DASHBOARDS',
  RECEIVE_PROJECT_DASHBOARD_CURVES: 'RECEIVE_PROJECT_DASHBOARD_CURVES',
};

export const DashboardName = {
  IFRAME: 'IFrameDashboard',
  CALIPER: 'Caliper',
  CORRELATION: 'Correlation',
  PRIME_STROKER: 'PrimeStroker',
  BEAGLE_STROKER: 'BeagleStroker',
  WINCH_SURVEY_GAUGES: 'WinchSurveyGauges',
  WINCH_GRAPH_GAUGES: 'WinchGraphGauges',
  BHA_GRAPH_GAUGES: 'BhaGraphAndGauge',
  WELL_GRAPH_GAUGES: 'WellGraphAndGauges',
  WINCH_COMBINED: 'WinchCombined',
  METAL_MILLING: 'MetalMilling',
};

export const ProjectDashboardFormFields = {
  URL: 'url',
  UNIT: 'unit',
  COLOR: 'color',
  SOURCE: 'source',
  OFFSET: 'offset',
  MIN_VALUE: 'minValue',
  MAX_VALUE: 'maxValue',
  DASHBOARD_ID: 'dashboardId',
  SIMULATION_ID: 'simulationId',
  CHEETAH_JOB_ID: 'cheetahJobId',
  PROJECT_DASHBOARD_ID: 'projectDashboardId',
  PROJECT_DASHBOARD_CURVES: 'projectDashboardCurves',
  PROJECT_DASHBOARD_SIMULATION_CURVES: 'projectDashboardSimulationCurves',
};
