import { Format } from 'app/app.constants';
import { fromJS } from 'immutable';
import moment from 'moment';

import { extractTimeFromDate } from 'utils/app.util';
import { formatDate } from 'utils/format.util';

export const assetTestDTO = (assetTest) => {
  const { time } = assetTest;
  return fromJS({
    ...assetTest,
    date: formatDate(moment(time), Format.date_dayfirst),
    time: extractTimeFromDate(time),
  });
};

const assetTestMappers = {
  ASSETTEST: {
    from: assetTestDTO,
  },
};

export default assetTestMappers;
