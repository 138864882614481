import { Grid } from '@material-ui/core';
import { Field } from 'redux-form/immutable';

import { required } from 'utils/validation.util';
import TextField from 'app/components/form/TextField';
import TextAreaField from 'app/components/form/TextAreaField';
import ReadOnlyFieldReduxForm from 'app/components/form/redux-form/ReadOnlyFieldReduxForm';

const RoleForm = ({ submit, form, isProtected = true }) => {
  return (
    <form id={form.ID} onSubmit={submit}>
      <Grid container direction="column">
        <Grid item>
          <RoleNameField isProtected={isProtected} name={form.NAME} />
        </Grid>
        <Grid item>
          <Field
            label="Description"
            name={form.DESCRIPTION}
            component={TextAreaField}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const RoleNameField = ({ name, isProtected }) => {
  if (isProtected)
    return (
      <Field name={name} label="Name" component={ReadOnlyFieldReduxForm} />
    );

  return (
    <Field
      required
      autoFocus
      fullWidth
      name={name}
      label="Name"
      validate={[required]}
      component={TextField}
    />
  );
};

export default RoleForm;
