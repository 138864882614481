import { Field, Formik } from 'formik';
import { Grid, Box } from '@material-ui/core';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { EMPTY_STRING } from 'app/app.constants';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import { OPERATIONS_VIEWS } from 'features/operations/operations.constants';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import SearchTextFieldFormik from 'app/components/form/formik/SearchTextFieldFormik';

export const Filters = {
  FACILITY_ID: 'facilityId',
  DEPARTMENT_ID: 'departmentId',

  FIELD_ID: 'fieldId',
  TEXT_SEARCH: 'textSearch',
  ORGANIZATION_ID: 'organizationId',
};

export const initialFilters = {
  [Filters.FACILITY_ID]: EMPTY_STRING,
  [Filters.FIELD_ID]: EMPTY_STRING,
  [Filters.DEPARTMENT_ID]: EMPTY_STRING,
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
  [Filters.ORGANIZATION_ID]: EMPTY_STRING,
};

const getFieldId = (field) => field.get('id');
const getFieldName = (field) => field.get('name');
const getFacilityId = (facility) => facility.get('id');
const getFacilityName = (facility) => facility.get('name');

const getDepartmentId = (department) => department.get('id');
const getDepartmentName = (department) => department.get('name');

const OperationFilter = ({
  fields,
  filters,
  onSubmit,
  facilities,
  departments,
  selectedView,
  onViewChange,
}) => (
  <Formik enableReinitialize onSubmit={onSubmit} initialValues={filters}>
    <AutoSaveFormik timeout={1000}>
      <Grid
        container
        wrap="nowrap"
        paddingTop={2}
        component={Box}
        paddingBottom={2}
        alignItems="center"
      >
        <Grid item container xs={12} md={10} lg={8} spacing={2}>
          <Grid item xs={3}>
            <Field
              name={Filters.TEXT_SEARCH}
              component={SearchTextFieldFormik}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectTextFieldFormik
              label="Field"
              margin="none"
              items={fields}
              name={Filters.FIELD_ID}
              getItemValue={getFieldId}
              getItemName={getFieldName}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectTextFieldFormik
              margin="none"
              label="Facility"
              items={facilities}
              name={Filters.FACILITY_ID}
              getItemValue={getFacilityId}
              getItemName={getFacilityName}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectTextFieldFormik
              margin="none"
              label="Department"
              items={departments}
              name={Filters.DEPARTMENT_ID}
              getItemValue={getDepartmentId}
              getItemName={getDepartmentName}
            />
          </Grid>
        </Grid>
        <Grid item container xs justifyContent="flex-end">
          <Grid item>
            <ToggleButtonGroup
              exclusive
              value={selectedView}
              onChange={onViewChange}
            >
              <ToggleButton value={OPERATIONS_VIEWS.TILES}>
                <ViewComfyIcon />
              </ToggleButton>
              <ToggleButton value={OPERATIONS_VIEWS.LIST}>
                <ViewAgendaIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Grid>
    </AutoSaveFormik>
  </Formik>
);

export default OperationFilter;
