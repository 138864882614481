import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import FlashOn from '@material-ui/icons/FlashOn';
import { Grid, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { Switch, Route, generatePath } from 'react-router-dom';

import { useToggle } from 'altus-hooks';

import {
  getCurrentSubPage,
  getApplicationHeaderFromState,
} from 'app/app.selectors';

import routePaths, { pages } from 'app/routePaths';
import BackButton from 'app/components/BackButton';
import { toProjectDetails } from 'utils/route.util';
import { PUBLIC_ASSETS_URL } from 'app/app.constants';
import ProjectHelmet from 'app/components/ProjectHelmet';
import useProjectHeader from 'app/hooks/useProjectHeader';
import DashboardIcon from 'app/components/Icons/DashboardIcon';
import MenuIcon from 'app/components/ApplicationHeader/MenuIcon';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import LogoAndAppTitle from 'app/components/ApplicationHeader/LogoAndAppTitle';
import ProjectStatusText from 'features/projects/components/ProjectStatusText';
import UserMenuContainer from 'app/components/ApplicationHeader/UserMenuContainer';
import ProjectLinearProgress from 'features/projects/components/ProjectLinearProgress';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import ApplicationHeaderTitle from 'app/components/ApplicationHeader/ApplicationHeaderTitle';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';
import ProjectDashboardsDrawer from 'features/projects/dashboard/components/ProjectDashboardsDrawer';

const ProjectHeader = ({ projectId, classes }) => {
  useProjectHeader();

  const project = useCurrentProject();
  const subPage = useSelector(getCurrentSubPage);
  const projectStatus = useSelector(getExecuteStatusFromState);
  const applicationHeader = useSelector(getApplicationHeaderFromState);

  const logoNavigation = applicationHeader.get('logoNavigation');

  const defaultPreviousPage = toProjectDetails(projectId);

  const [isOpen, toggleDrawer] = useToggle();

  const routes = useMemo(
    () =>
      [
        {
          title: 'Events',
          subPage: pages.events,
          path: routePaths.events,
          key: routePaths.events,
          Icon: <FlashOn />,
        },
        {
          title: 'Teams',
          key: 'teams',
          render: () => (
            <IconButton
              target="_blank"
              component="a"
              href={project.get('teamUrl')}
              className={classes.teamButton}
            >
              <img
                className={classes.teamImage}
                alt="Teams url"
                src={`${PUBLIC_ASSETS_URL}/images/teams/icon.png`}
              />
            </IconButton>
          ),
          hidden: !project.get('teamUrl'),
        },
      ].filter((route) => !route.hidden),
    [project, classes],
  );

  return (
    <>
      <ProjectHelmet projectId={projectId} />
      <ApplicationHeaderBase>
        <Grid
          container
          spacing={2}
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs>
            <Switch>
              <Route path={routePaths.events}>
                <BackButton to={defaultPreviousPage} />
              </Route>
              <Route>
                <LogoAndAppTitle to={logoNavigation} />
              </Route>
            </Switch>
          </Grid>
          <Grid item xs={8}>
            <ApplicationHeaderTitle />
          </Grid>
          <Grid item xs>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <ProjectStatusText status={projectStatus.get('status')} />
              </Grid>
              <Grid item>
                <ProjectDashboardsDrawer
                  project={project}
                  projectId={projectId}
                  isOpen={isOpen}
                  toggleDrawer={toggleDrawer}
                />
                <IconButton color="default" onClick={toggleDrawer}>
                  <DashboardIcon />
                </IconButton>
              </Grid>
              {routes.map((route) => {
                const {
                  path,
                  key,
                  Icon,
                  subPage: routeSubPage,
                  render,
                } = route;

                if (render)
                  return (
                    <Grid item key={key}>
                      {render()}
                    </Grid>
                  );

                const url = generatePath(path, { projectId });

                return (
                  <Grid item key={key}>
                    <MenuIcon
                      Icon={Icon}
                      subPage={subPage}
                      previousPage={defaultPreviousPage}
                      defaultPreviousPage={defaultPreviousPage}
                      page={{
                        to: url,
                        id: routeSubPage,
                      }}
                    />
                  </Grid>
                );
              })}
              <Grid item>
                <UserMenuContainer />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ApplicationHeaderBase>
      <ProjectLinearProgress />
    </>
  );
};

const styles = (theme) => ({
  teamImage: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  teamButton: {
    marginRight: 5,
  },
});

export default withStyles(styles)(ProjectHeader);
