import { Field } from 'formik';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { Add, Close, ExpandMore, ExpandLess } from '@material-ui/icons';
import { IconButton, Grid, Button, Box, MenuItem } from '@material-ui/core';

import { useToggle } from 'altus-hooks';
import { Table, TableRowActionsCell } from 'altus-ui-components';

import { required } from 'utils/validation.util';
import {
  EMPTY_LIST,
  EMPTY_MAP,
  ProjectWellboreDetailStatus,
} from 'app/app.constants';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';
import {
  WellboreSectionType,
  MINUSKEY,
} from 'features/wells/sections/wellboreSection.constants';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import { wellboreSectionTypeToString } from 'features/wells/sections/wellboreSection.mappers';
import CreateWellboreSectionRow from 'features/wells/sections/components/CreateWellboreSectionRow';
import CreateWellboreSectionNippleRow from 'features/wells/sections/components/CreateWellboreSectionNippleRow';

export const WellboreSectionFormFields = {
  TOP: 'top',
  TYPE: 'type',
  BOTTOM: 'bottom',
  LENGTH: 'length',
  INNER_DIAMETER: 'innerDiameter',
  FRICTION_FACTOR_IN: 'frictionFactorIn',
  FRICTION_FACTOR_OUT: 'frictionFactorOut',
};

const getSubRows = (row) => {
  if (row.original) return;

  return row.get('wellboreSectionNipples', EMPTY_LIST).valueSeq();
};

const WellboreSectionsTable = ({
  createWellboreSection,
  updateWellboreSection,
  deleteWellboreSection,
  defaultWellboreSection,
  createWellboreSectionNipple,
  updateWellboreSectionNipple,
  deleteWellboreSectionNipple,
  wellboreSections = EMPTY_MAP,
  projectWellboreDetail,
}) => {
  const [isCreateMode, toggleCreateMode] = useToggle();
  const [currentWellboreSections, setCurrentWellboreSections] = useState(null);
  const [wellboreSectionIdForCreate, setWellboreSectionIdForCreate] =
    useState();

  useEffect(() => {
    setCurrentWellboreSections(wellboreSections);
  }, [wellboreSections]);

  var lengthUnit = defaultWellboreSection.getIn(['top', 'unit']);

  const isApproved = !projectWellboreDetail
    ? false
    : projectWellboreDetail.get('status') ===
      ProjectWellboreDetailStatus.APPROVED;

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (!row.canExpand) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <IconButton {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableRowActionsCell>
          );
        },
        Footer: () => <TableRowActionsCell minItems={1} />,
      },
      {
        xs: 3,
        id: 'section',
        Header: 'Section',
        accessor: (_, index, row) => {
          if (row.id.includes('.')) {
            const indexes = row.id.split('.');
            return `Nipple ${Number(indexes[0]) + 1}.${Number(indexes[1]) + 1}`;
          }

          return index + 1;
        },
      },
      {
        xs: 4,
        Header: 'Type',
        Cell: ({ row }) => {
          const isNipple = row.original.get('isNipple');

          if (isNipple) {
            return (
              <Field
                validate={required}
                disabled={isApproved}
                component={TableRowTextFieldFormik}
                name={WellboreSectionFormFields.TYPE}
              />
            );
          }

          return (
            <Field
              select
              validate={required}
              component={TableRowTextFieldFormik}
              disabled={isApproved}
              name={WellboreSectionFormFields.TYPE}
            >
              {Object.values(WellboreSectionType).map((type) => (
                <MenuItem key={type} value={type}>
                  {wellboreSectionTypeToString(type)}
                </MenuItem>
              ))}
            </Field>
          );
        },
      },
      {
        xs: 2,
        id: 'top',
        Header: () => (
          <QuantityTableHeaderTitleCell title="Top" unit={lengthUnit} />
        ),
        Cell: (row) => {
          let disableCell = true;

          if (row) {
            disableCell = row.row.id === '0';

            if (projectWellboreDetail) {
              disableCell = isApproved;
            }
          }

          return (
            <Field
              type="number"
              disabled={disableCell}
              validate={required}
              component={TableRowTextFieldFormik}
              name={WellboreSectionFormFields.TOP}
              TextFieldComponent={QuantityTextFieldFormik}
              InputProps={{
                endAdornment: null,
              }}
              onKeyPress={(event) => {
                if (event?.key === MINUSKEY) {
                  event.preventDefault();
                }
              }}
            />
          );
        },
      },
      {
        xs: 2,
        id: 'bottom',
        Header: () => (
          <QuantityTableHeaderTitleCell title="Bottom" unit={lengthUnit} />
        ),
        Cell: ({ row }) => {
          const isNipple = row.original.get('isNipple');

          if (isNipple) return null;

          return (
            <Field
              useRoundedValue={true}
              type="number"
              validate={required}
              disabled={isApproved}
              component={TableRowTextFieldFormik}
              name={WellboreSectionFormFields.BOTTOM}
              TextFieldComponent={QuantityTextFieldFormik}
              InputProps={{
                endAdornment: null,
              }}
              onKeyPress={(event) => {
                if (event?.key === MINUSKEY) {
                  event.preventDefault();
                }
              }}
            />
          );
        },
      },
      {
        xs: 2,
        id: 'length',
        Header: () => (
          <QuantityTableHeaderTitleCell title="Length" unit={lengthUnit} />
        ),
        Cell: ({ row }) => {
          const isNipple = row.original.get('isNipple');

          if (!isNipple) return null;

          return (
            <Field
              type="number"
              disabled={isApproved}
              validate={required}
              component={TableRowTextFieldFormik}
              name={WellboreSectionFormFields.LENGTH}
              TextFieldComponent={QuantityTextFieldFormik}
              InputProps={{
                endAdornment: null,
              }}
              onKeyPress={(event) => {
                if (event?.key === MINUSKEY) {
                  event.preventDefault();
                }
              }}
            />
          );
        },
      },
      {
        xs: 2,
        id: 'innerDiameter',
        Header: () => (
          <QuantityTableHeaderTitleCell
            title="Hole Dia."
            unit={defaultWellboreSection.getIn(['innerDiameter', 'unit'])}
          />
        ),
        Cell: () => (
          <Field
            useRoundedValue={true}
            type="number"
            disabled={isApproved}
            validate={required}
            component={TableRowTextFieldFormik}
            TextFieldComponent={QuantityTextFieldFormik}
            name={WellboreSectionFormFields.INNER_DIAMETER}
            InputProps={{
              endAdornment: null,
            }}
            onKeyPress={(event) => {
              if (event?.key === MINUSKEY) {
                event.preventDefault();
              }
            }}
          />
        ),
      },
      {
        xs: 2,
        Header: 'Friction in',
        Cell: ({ row }) => {
          const isNipple = row.original.get('isNipple');

          if (isNipple) return null;

          return (
            <Field
              type="number"
              disabled={isApproved}
              validate={required}
              component={TableRowTextFieldFormik}
              name={WellboreSectionFormFields.FRICTION_FACTOR_IN}
              onKeyPress={(event) => {
                if (event?.key === MINUSKEY) {
                  event.preventDefault();
                }
              }}
            />
          );
        },
      },
      {
        xs: 2,
        Header: 'Friction out',
        Cell: ({ row }) => {
          const isNipple = row.original.get('isNipple');

          if (isNipple) return null;

          return (
            <Field
              type="number"
              validate={required}
              disabled={isApproved}
              component={TableRowTextFieldFormik}
              name={WellboreSectionFormFields.FRICTION_FACTOR_OUT}
              onKeyPress={(event) => {
                if (event?.key === MINUSKEY) {
                  event.preventDefault();
                }
              }}
            />
          );
        },
      },
      {
        id: 'actions',
        Header: <TableRowActionsCell minItems={2} />,
        Footer: <TableRowActionsCell minItems={2} />,
        Cell: ({ row }) => (
          <TableRowActionsCell minItems={2}>
            {row.depth === 0 && createWellboreSectionNipple && !isApproved ? (
              <IconButton
                title="Add Nipple Section"
                disabled={isCreateMode}
                onClick={() => {
                  setWellboreSectionIdForCreate(
                    row.original.get('wellboreSectionId'),
                  );
                  toggleCreateMode();
                }}
              >
                <Add fontSize="small" />
              </IconButton>
            ) : null}
            {deleteWellboreSection && !isApproved && (
              <IconButton
                title="Delete"
                disabled={isCreateMode}
                onClick={() => {
                  row.depth === 0
                    ? deleteWellboreSection(
                        row.original.get('wellboreSectionId'),
                      )
                    : deleteWellboreSectionNipple(
                        row.original.get('wellboreSectionId'),
                        row.original.get('wellboreSectionNippleId'),
                      );
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            )}
          </TableRowActionsCell>
        ),
      },
    ],
    [
      lengthUnit,
      isCreateMode,
      toggleCreateMode,
      deleteWellboreSection,
      defaultWellboreSection,
      createWellboreSectionNipple,
      deleteWellboreSectionNipple,
      isApproved,
      projectWellboreDetail,
    ],
  );

  const renderTableRowComponent = useCallback(
    (props) => {
      const onSubmit = props.item.get('isNipple')
        ? updateWellboreSectionNipple
        : updateWellboreSection;

      return <EditableTableRowFormik {...props} onSubmit={onSubmit} />;
    },
    [updateWellboreSection, updateWellboreSectionNipple],
  );

  const initialState = useMemo(
    () => ({
      expanded: wellboreSections.toArray().reduce(
        (result, _wellboreSection, index) => ({
          ...result,
          [index]: true,
        }),
        {},
      ),
    }),
    [wellboreSections],
  );

  return (
    <>
      <Table
        useExpanded
        stickyHeader
        disableSortBy
        columns={columns}
        useGlobalFilter={false}
        items={wellboreSections}
        getSubRows={getSubRows}
        initialState={initialState}
        noItemsMessage="No sections added yet..."
        displayNoItemsMessage={!isCreateMode}
        TableRowComponent={renderTableRowComponent}
      />
      {isCreateMode && !wellboreSectionIdForCreate && !isApproved && (
        <CreateWellboreSectionRow
          columns={columns}
          toggleCreateMode={toggleCreateMode}
          createWellboreSection={createWellboreSection}
          defaultWellboreSection={defaultWellboreSection}
          wellboreSection={currentWellboreSections}
        />
      )}
      {isCreateMode && wellboreSectionIdForCreate && !isApproved && (
        <CreateWellboreSectionNippleRow
          columns={columns}
          toggleCreateMode={toggleCreateMode}
          wellboreSectionId={wellboreSectionIdForCreate}
          createWellboreSectionNipple={createWellboreSectionNipple}
          wellboreSection={currentWellboreSections}
        />
      )}
      {createWellboreSection && !isApproved && (
        <Grid container justifyContent="flex-end">
          <Box p={1}>
            <Button
              size="small"
              disabled={isCreateMode}
              onClick={() => {
                setWellboreSectionIdForCreate();
                toggleCreateMode();
              }}
            >
              Add section
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default WellboreSectionsTable;
