import { compose } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { useCallback, useEffect } from 'react';
import { Grid, Typography, MenuItem } from '@material-ui/core';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';

import { useRequest } from 'altus-hooks';
import { LoadingOverlay } from 'altus-ui-components';

import {
  loadSimulationParametersPage,
  requestUpdateSimulationParameter,
  requestCreateSimulationParameter,
  requestDeleteSimulationParameter,
} from 'features/projects/tasks/task/simulation/parameters/simulationParameter.actions';

import { EMPTY_MAP } from 'app/app.constants';
import simulationService from 'services/simulation.service';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import simulationMappers from 'features/projects/tasks/task/simulation/simulation.mappers';
import {
  SimulationDirection,
  FlowPath,
} from 'features/projects/tasks/task/simulation/simulation.constants';
import { requestUpdateSimulation } from 'features/projects/tasks/task/simulation/simulation.actions';
import { getSimulationFromState } from 'features/projects/tasks/task/simulation/simulation.selectors';
import { ACTIONS } from 'features/projects/tasks/task/simulation/parameters/simulationParameter.constants';
import { SimulationFormFields as FormFields } from 'features/projects/tasks/task/simulation/simulation.constants';
import SimulationParametersTable from 'features/projects/tasks/task/simulation/parameters/components/SimulationParametersTable';
import { createSimulationParametersSelector } from 'features/projects/tasks/task/simulation/parameters/simulationParameter.selectors';
import { flowPathToString } from 'features/projects/tasks/task/simulation/simulation.mappers';

const TaskSimulationParametersContainer = ({
  taskId,
  dataState,
  projectId,
  simulationId,
  dispatchOnLoad,
  simulation = EMPTY_MAP,
  simulationParametersRIH,
  simulationParametersPOOH,
  dispatchUpdateSimulation,
  dispatchRequestDeleteSimulationParameter,
  dispatchRequestCreateSimulationParameter,
  dispatchRequestUpdateSimulationParameter,
}) => {
  const getDefaultSimulationParameter = useCallback(
    () =>
      simulationService
        .getDefaultSimulationParameter(projectId, taskId, simulationId)
        .then((simulationParameter) =>
          simulationMappers.SimulationParameter.from(simulationParameter),
        ),
    [taskId, projectId, simulationId],
  );

  const [defaultSimulationParameter = EMPTY_MAP] = useRequest(
    getDefaultSimulationParameter,
  );

  useEffect(
    () => dispatchOnLoad(projectId, taskId, simulationId),
    [projectId, taskId, simulationId, dispatchOnLoad],
  );

  const onSubmit = useCallback(
    (simulation) =>
      dispatchUpdateSimulation(projectId, taskId, simulationId, simulation),
    [projectId, taskId, simulationId, dispatchUpdateSimulation],
  );

  const deleteSimulationParameter = useCallback(
    (simulationParameterId) =>
      dispatchRequestDeleteSimulationParameter(
        projectId,
        taskId,
        simulationId,
        simulationParameterId,
      ),
    [projectId, taskId, simulationId, dispatchRequestDeleteSimulationParameter],
  );

  const updateSimulationParameter = useCallback(
    (simulationParameter, { setSubmitting, setStatus }) =>
      dispatchRequestUpdateSimulationParameter(
        projectId,
        taskId,
        simulationId,
        simulationParameter.simulationParameterId,
        simulationParameter,
        setSubmitting,
        setStatus,
      ),
    [projectId, taskId, simulationId, dispatchRequestUpdateSimulationParameter],
  );

  const createSimulationParameter = useCallback(
    (simulationParameter, callback, { setSubmitting, setStatus }) =>
      dispatchRequestCreateSimulationParameter(
        projectId,
        taskId,
        simulationId,
        simulationParameter,
        setSubmitting,
        setStatus,
        callback,
      ),
    [projectId, taskId, simulationId, dispatchRequestCreateSimulationParameter],
  );

  const initialValues = simulation.toJS();
  const isPlanned = simulation.get('isPlanned');

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <Form>
        <AutoSaveFormik>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Field
                select
                required
                disabled={isPlanned}
                component={TextFieldFormik}
                label="Flow Path"
                name={FormFields.FLOW_PATH}
              >
                {Object.values(FlowPath).map((type) => (
                  <MenuItem key={type} value={type}>
                    {flowPathToString(type)}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={8} />
            {[
              {
                title: 'RIH',
                items: simulationParametersRIH,
                direction: SimulationDirection.RUN_IN_HOLE,
                noItemsMessage: 'No RIH parameters added yet...',
              },
              {
                title: 'POOH',
                items: simulationParametersPOOH,
                direction: SimulationDirection.PULL_OUT_OF_HOLE,
                noItemsMessage: 'No POOH parameters added yet...',
              },
            ].map(({ items, title, direction, noItemsMessage }) => (
              <Grid item xs={12} key={direction}>
                <Typography gutterBottom>{title}</Typography>
                <SimulationParametersTable
                  disabled={isPlanned}
                  direction={direction}
                  simulationParameters={items}
                  noItemsMessage={noItemsMessage}
                  deleteSimulationParameter={deleteSimulationParameter}
                  updateSimulationParameter={updateSimulationParameter}
                  createSimulationParameter={createSimulationParameter}
                  defaultSimulationParameter={defaultSimulationParameter}
                />
              </Grid>
            ))}
            <LoadingOverlay dataState={dataState} />
          </Grid>
        </AutoSaveFormik>
      </Form>
    </Formik>
  );
};

export default compose(
  connect(
    (_initialState, { simulationId }) => {
      const RIHParametersSelector = createSimulationParametersSelector(
        simulationId,
        SimulationDirection.RUN_IN_HOLE,
      );

      const POOHParametersSelector = createSimulationParametersSelector(
        simulationId,
        SimulationDirection.PULL_OUT_OF_HOLE,
      );

      return (state) => ({
        simulationParametersRIH: RIHParametersSelector(state),
        simulationParametersPOOH: POOHParametersSelector(state),
        simulation: getSimulationFromState(state, simulationId),
        dataState: getSummarizedDataStateFromState(
          state,
          ACTIONS.LOAD_SIMULATION_PARAMETERS_PAGE,
          ACTIONS.REQUEST_CREATE_SIMULATION_PARAMETER,
          ACTIONS.REQUEST_DELETE_SIMULATION_PARAMETER,
        ),
      });
    },
    {
      dispatchOnLoad: loadSimulationParametersPage,
      dispatchRequestDeleteSimulationParameter:
        requestDeleteSimulationParameter,
      dispatchRequestCreateSimulationParameter:
        requestCreateSimulationParameter,
      dispatchRequestUpdateSimulationParameter:
        requestUpdateSimulationParameter,
      dispatchUpdateSimulation: requestUpdateSimulation,
    },
  ),
)(TaskSimulationParametersContainer);
