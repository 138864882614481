export const TASKS_CREATE_TASK_MODE = {
  NEW: 0,
  FROM_TEMPLATE: 1,
};

export const TaskType = {
  SUB_SURFACE: 0,
  SURFACE: 1,
};

export const TASK_VIEWS = {
  LIST: 'LIST',
  CHART: 'CHART',
};

export const MOBILE_TASK_VIEWS = {
  ACTIVITIES: 0,
  BHA: 1,
};

export const CONTINGENCY_RUN_NUMBER = 'C';

export const TASKS_CREATE_TASK_MODAL_ID = 'createTask';

export const TASKS_EDIT_TASK_MODAL_ID = 'TASKS_EDIT_TASK_MODAL_ID';

// Action types
export const TASK_ACTIONS = {
  EDIT_TASK: 'EDIT_TASK',
  COPY_TASK: 'COPY_TASK',
  SORT_TASKS: 'SORT_TASKS',
  CREATE_TASK: 'CREATE_TASK',
  UPDATE_TASK: 'UPDATE_TASK',
  DELETE_TASK: 'DELETE_TASK',
  RECEIVE_TASK: 'RECEIVE_TASK',
  GET_ALL_TASKS: 'GET_ALL_TASKS',
  GET_ALL_ACTIVITIES: 'GET_ALL_ACTIVITIES',
  UPDATE_TASK_DETAILS: 'UPDATE_TASK_DETAILS',
  CONFIRM_DELETE_TASK: 'CONFIRM_DELETE_TASK',
  UPDATE_TASK_SERVICES: 'UPDATE_TASK_SERVICES',
  EDIT_TASK_MODAL_LOADED: 'EDIT_TASK_MODAL_LOADED',
  ADD_TASKS_FROM_TEMPLATE: 'ADD_TASKS_FROM_TEMPLATE',
  PROJECT_UPDATE_PROGRESS: 'PROJECT_UPDATE_PROGRESS',
  EDIT_TASK_MODAL_UNLOADED: 'EDIT_TASK_MODAL_UNLOADED',
  CREATE_TASK_MODAL_LOADED: 'CREATE_TASK_MODAL_LOADED',
  PROJECT_TASKS_PAGE_LOADED: 'PROJECT_TASKS_PAGE_LOADED',
  PROJECT_TASKS_PAGE_UNLOADED: 'PROJECT_TASKS_PAGE_UNLOADED',

  // FLUID ACTIONS
  INITIATE_GET_TASK_FLUIDS: 'INITIATE_GET_TASK_FLUIDS',
  GET_TASK_FLUIDS: 'GET_TASK_FLUIDS',
  INITIATE_DELETE_TASK_FLUID: 'INITIATE_DELETE_TASK_FLUID',
  DELETE_TASK_FLUID: 'DELETE_TASK_FLUID',
  INITIATE_UPDATE_TASK_FLUID: 'INITIATE_UPDATE_TASK_FLUID',
  UPDATE_TASK_FLUID: 'UPDATE_TASK_FLUID',
  INITIATE_CREATE_TASK_FLUID: 'INITIATE_CREATE_TASK_FLUID',
  CREATE_TASK_FLUID: 'CREATE_TASK_FLUID',
};

export const TASK_STATE = {
  TASKS: 'tasks',
  TASKS_TEMPLATE: 'template',
  TEMPLATE: 'template',
  TEMPLATE_DATA: 'data',
  ACTIVE: 'active',
  ACTIVE_DATA: 'data',
  ACTIVE_DIRECTION: 'direction',
  DATA: 'data',
  CREATE_TASK_MODE: 'createTaskMode',
  ACTIVE_TASKS: 'activeTasks',
  FILES: 'files',
  CABLES: 'cables',
  SURFACE_EQUIPMENT: 'surfaceEquipment',
  TASK_RISK: 'taskRisk',
};

export const TASK_FORMS = {
  NEW_TASK: {
    TASK_TYPE: 'type',
    FORM_ID: 'newTask',
    TASK_TITLE: 'title',
    TASK_DESCRIPTION: 'description',
    TASK_DEPARTMENTS: 'departments',
    TASK_SERVICES: 'services',
    TASK_TARGET_DEPTH: 'targetDepth',
    TASK_OBJECTIVE: 'objective',
    TASK_PLANNED_DURATION: {
      HOURS: 'durationPlanned.hours',
      MINUTES: 'durationPlanned.minutes',
    },
  },
  EDIT_TASK: {
    FORM_ID: 'editTask',
    TASK_CUSTOM_TITLE: 'customTitle',
    TASK_TYPE: 'type',
    TASK_CONTINGENCY_FOR_TASK: 'contingencyForTaskId',
    TASK_CONTINGENCIES: 'taskContingencies',
    TASK_DESCRIPTION: 'description',
    TASK_COMMENTS: 'comments',
    TASK_DEPARTMENTS: 'departments',
    TASK_SERVICES: 'services',
    TASK_TARGET_DEPTH: 'targetDepth',
    TASK_FILES: 'addedFiles',
    TASK_OBJECTIVE: 'objective',
    TASK_PLANNED_DURATION: {
      HOURS: 'durationPlanned.hours',
      MINUTES: 'durationPlanned.minutes',
    },
  },
};

export const TASK_SURFACE_EQUIPMENT_ACTIONS = {
  SAVE_CABLE: 'SAVE_CABLE',
  DELETE_CABLE: 'DELETE_CABLE',
  CABLES_LOADED: 'CABLES_LOADED_TASK_PLANNING',
  PAGE_LOADED: 'PAGE_LOADED_TASK_SURFACE_EQUIPMENT',
  SAVE_CABLE_SERIAL_NUMBER: 'SAVE_CABLE_SERIAL_NUMBER',
  SURFACE_EQUIPMENT_LOADED: 'SURFACE_EQUIPMENT_LOADED_TASK',
  SAVE_SURFACE_EQUIPMENT_TEMPLATE: 'SAVE_SURFACE_EQUIPMENT_TEMPLATE',
  SAVE_SURFACE_EQUIPMENT: 'SAVE_SURFACE_EQUIPMENT_TASK_SURFACE_EQUIPMENT',
  SAVE_SURFACE_SERIAL_NUMBER: 'SAVE_SURFACE_SERIAL_NUMBER',
  UPDATE_TASK_SURFACE_EQUIPMENT_SERIALS_FROM_TO:
    'UPDATE_TASK_SURFACE_EQUIPMENT_SERIALS_FROM_TO',
  UPDATE_TASK_CABLE_SERIALS_FROM_TO: ' UPDATE_TASK_CABLE_SERIALS_FROM_TO',
};
