import Agent from 'infrastructure/agent';

const getProjectDashboard = (projectId, projectDashboardId) =>
  Agent.get(`/project/${projectId}/dashboard/${projectDashboardId}`);

const getProjectDashboardCurves = (projectId, projectDashboardId) =>
  Agent.get(`/project/${projectId}/dashboard/${projectDashboardId}/curves`);

const getProjectDashboardSimulations = (projectId, projectDashboardId) =>
  Agent.get(`/project/${projectId}/dashboard/${projectDashboardId}/simulation`);

const getProjectDashboardCurvesBySource = (
  projectId,
  projectDashboardId,
  source,
) =>
  Agent.get(
    `/project/${projectId}/dashboard/${projectDashboardId}/curves/${source}`,
  );

const getProjectDashboards = (projectId) =>
  Agent.get(`/project/${projectId}/dashboard`);

const toggleProjectDashboard = (projectId, dashboardId) =>
  Agent.post(`/project/${projectId}/dashboard/toggle/${dashboardId}`);

const toggleProjectDashboards = (projectId) =>
  Agent.post(`/project/${projectId}/dashboard/toggle`);

const getProjectDashboardCheetahJobCurves = (
  projectId,
  projectDashboardId,
  cheetahJobId,
) =>
  Agent.get(
    `/project/${projectId}/dashboard/${projectDashboardId}/cheetah/${cheetahJobId}/curves`,
  );

const updateProjectDashboard = (
  projectId,
  projectDashboardId,
  projectDashboard,
) =>
  Agent.put(
    `/project/${projectId}/dashboard/${projectDashboardId}`,
    projectDashboard,
  );

const projectDashboardService = {
  getProjectDashboard,
  getProjectDashboards,
  toggleProjectDashboard,
  updateProjectDashboard,
  toggleProjectDashboards,
  getProjectDashboardCurves,
  getProjectDashboardSimulations,
  getProjectDashboardCurvesBySource,
  getProjectDashboardCheetahJobCurves,
};

export default projectDashboardService;
