import { toRoot } from 'utils/route.util';
import Home from '@material-ui/icons/Home';
import Button from '@material-ui/core/Button';
import Refresh from '@material-ui/icons/Refresh';
import { IN_DEV, MODAL } from 'app/app.constants';
import withStyles from '@material-ui/styles/withStyles';
import DialogActions from '@material-ui/core/DialogActions';
import ModalContainer from 'app/components/Modal/ModalContainer';
import DialogContentText from '@material-ui/core/DialogContentText';

const AppFailure = ({ classes, errorMessage }) => {
  return (
    <ModalContainer
      open
      height="auto"
      minWidth={600}
      hideCloseButton
      disableEscapeKeyDown
      disableBackdropClick
      TransitionComponent={undefined} // Disable animation
      modalId={MODAL.APPLICATION_FAILURE}
      title="Oooops, a critical error ocurred"
      classes={{
        backdrop: classes.backdrop,
      }}
      Actions={() => (
        <DialogActions>
          <Button
            href={toRoot()}
            component="a"
            color="default"
            variant="contained"
          >
            <Home className={classes.leftIcon} />
            Home
          </Button>
          <br />
          <Button
            component="a"
            color="primary"
            variant="contained"
            href={window.location.pathname}
          >
            <Refresh />
          </Button>
        </DialogActions>
      )}
    >
      {IN_DEV && (
        <DialogContentText>
          <i>{errorMessage}</i>
        </DialogContentText>
      )}
      {!IN_DEV && (
        <>
          <DialogContentText>Sorry ...</DialogContentText>
          <DialogContentText>It's not you.</DialogContentText>
          <DialogContentText>It's us</DialogContentText>
        </>
      )}
    </ModalContainer>
  );
};

const styles = {
  rightIcon: {
    marginLeft: 10,
  },
  leftIcon: {
    marginRight: 10,
  },
  backdrop: {
    background: undefined,
  },
};

export default withStyles(styles)(AppFailure);
