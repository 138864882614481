import { call, put, takeEvery } from 'redux-saga/effects';
import activitiesService from 'services/activities.service';
import { replace } from 'connected-react-router/immutable';
import { toExecutionSection } from 'utils/route.util';
import {
  getAllTask,
  sendProjectChangedNotification,
} from 'features/projects/activities/sagas/common.sagas';
import {
  ACTIVITIES_ACTIONS as ACTIONS,
  ACTIVITIES_ACTIONS,
} from 'features/projects/activities/activities.constants';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import { callAsync } from 'app/sagas/helperSagas';

function* confirmGoBack(action) {
  const { projectId, connection, previousCurrentTaskId } = action;
  yield put({
    type: ACTIONS.CONFIRMED_ACTIVITY_GO_BACK,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Going back first...',
    },
    confirmationDialog: {
      description:
        'Undo and go back to the previous activity? Time tracked on the ongoing activity will be added to the previous ',
      title: 'Are you sure?',
      confirmButtonText: 'Yes, undo',
    },
    projectId,
    connection,
    previousCurrentTaskId,
  });
}

function* fetchAllActivitiesForTask(projectId, previousCurrentTaskId) {
  const allActivities = yield call(
    activitiesService.allActivitiesForTask,
    projectId,
    previousCurrentTaskId,
  );
  yield put({
    taskId: previousCurrentTaskId,
    payload: allActivities,
    type: ACTIVITIES_ACTIONS.GET_TASK_ACTIVITIES,
  });
}

function* goBack(action) {
  const { projectId, connection, previousCurrentTaskId } = action;
  const status = yield call(activitiesService.goBack, projectId);
  const { currentTask: newCurrentTask } = status;

  if (newCurrentTask && newCurrentTask.taskId !== previousCurrentTaskId) {
    yield put(
      replace(
        toExecutionSection(projectId, newCurrentTask.taskId, 'activities'),
      ),
    );
  }

  // If we are going back in the first activity of the first task,
  // we want to fetch the activities of the previousCurrenTaskId because newCurrentTask.taskId will be null
  const taskId = newCurrentTask?.taskId ?? previousCurrentTaskId;

  // If going back to in activity and task then refresh previous task
  // And refresh tasks (to update current task to new task)
  if (String(newCurrentTask?.taskId) !== String(previousCurrentTaskId)) {
    yield call(fetchAllActivitiesForTask, projectId, previousCurrentTaskId);
    yield call(getAllTask, projectId);
  }

  yield call(sendProjectChangedNotification, connection, projectId, taskId);
  yield call(fetchAllActivitiesForTask, projectId, taskId);

  yield put({
    type: ACTIVITIES_ACTIONS.GET_PROJECT_STATUS,
    payload: status,
  });
}

export function* rootGoBack() {
  yield takeEvery(ACTIONS.CONFIRM_ACTIVITY_GO_BACK, callAsync, confirmGoBack);
  yield takeEvery(ACTIONS.CONFIRMED_ACTIVITY_GO_BACK, callAsync, goBack);
}
