import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { BasePage } from 'altus-ui-components';
import { useLocation } from 'react-router-dom';
import { RISK_PRINCIPLE_TYPES } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import AddIntegrityHeader from 'features/settings/integrityManagement/integrities/AddIntegrityHeader';
import AddIntegrityContent from 'features/settings/integrityManagement/integrities/AddIntegrityContent';

const AddIntegrityContainer = () => {
  const location = useLocation();
  const {
    riskPrincipleType = RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE,
    editIntegrity = undefined,
  } = location.state || {};

  return (
    <BasePage>
      <Grid item container spacing={2} xs={12} m={2}>
        <Grid container direction="column">
          <Box mt={2} mb={2}>
            <AddIntegrityHeader
              title={`${editIntegrity ? 'Edit' : 'New'} Integrity`}
            />
          </Box>
          <AddIntegrityContent
            riskPrincipleType={riskPrincipleType}
            editIntegrity={editIntegrity}
          />
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default AddIntegrityContainer;
