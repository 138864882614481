import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { toWellbore } from 'utils/route.util';
import { EMPTY_MAP } from 'app/app.constants';
import { invokeIfFunction } from 'utils/app.util';
import ReadOnlyField from 'app/components/ReadOnlyField';

const projectDetails = [
  {
    label: 'Project',
    accessor: (project) => project.get('id'),
  },
  {
    label: 'Client',
    accessor: (project) => project.get('operator'),
  },
  {
    label: 'Field',
    accessor: (project) => project.get('fieldName'),
  },
  {
    label: 'Facility',
    accessor: (project) => project.get('facilityName'),
  },
  {
    label: 'Well',
    accessor: (project) => project.get('wellboreName'),
    to: (project) =>
      toWellbore(project.get('fieldId'), project.get('wellboreId')),
    target: '_blank',
  },
];

const TaskSimulationDetails = ({
  classes,
  task = EMPTY_MAP,
  project = EMPTY_MAP,
}) => (
  <Grid
    item
    container
    wrap="nowrap"
    alignItems="center"
    justifyContent="space-between"
    className={classes.root}
  >
    {projectDetails.map(({ accessor, to, label, target }, index) => (
      <Grid item key={index} className={classes.field}>
        <ReadOnlyField
          margin="none"
          label={label}
          target={target}
          value={accessor(project)}
          to={to ? invokeIfFunction(to, project) : undefined}
        />
      </Grid>
    ))}
    <Grid item className={classes.field}>
      <ReadOnlyField label="Run" margin="none" value={task.get('title')} />
    </Grid>
  </Grid>
);

const styles = (theme) => ({
  root: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  field: {
    paddingRight: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(TaskSimulationDetails);
