import React from 'react';
import { compose } from 'redux';
import { BasePage } from 'altus-ui-components';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography } from '@material-ui/core';
import {
  getCurrentClientOrganizationIdFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';
import { connect, useSelector } from 'react-redux';
import { useHeader } from 'app/hooks/useHeader';
import { getCurrentProject } from 'features/projects/projects.selectors';
import {
  getAllTasksFromState,
  getTaskTemplates,
} from 'features/projects/tasks/tasks.selectors';
import { useEffect } from 'react';
import { TASK_ACTIONS } from 'features/projects/tasks/tasks.constants';
import { getAllTasks } from 'features/projects/activities/activities.actions';
import { tasksContainerOnUnload } from 'features/projects/tasks/tasks.actions';
import ExecuteTasksList from 'features/mobile/projects/execute/ExecuteTasksList';
import { onLoad } from 'features/projects/details/details.actions';
import {
  getProjectResources,
  onLoad as onStatusLoad,
} from 'features/projects/projects.actions';

const MobileProjectExecuteContainer = ({
  tasks,
  classes,
  project,
  dataState,
  projectId,
  dispatchOnLoad,
  dispatchOnLoadStatus,
  dispatchGetProjectResources,
  dispatchOnUnload,
  dispatchGetTasks,
  dispatchCopyTask,
  dispatchSortTasks,
  dispatchDeleteTask,
  totalElapsedDuration,
  totalPlannedDuration,
}) => {
  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromState,
  );
  useEffect(() => {
    dispatchOnLoad(projectId);
    dispatchGetTasks(projectId);
    dispatchOnLoadStatus(currentClientOrganizationId);
    dispatchGetProjectResources(projectId);

    return () => dispatchOnUnload();
  }, [
    projectId,
    dispatchGetTasks,
    dispatchOnLoad,
    dispatchOnUnload,
    dispatchOnLoadStatus,
    currentClientOrganizationId,
    dispatchGetProjectResources,
  ]);

  useHeader({
    subTitle: `Execution ${tasks?.size ? `(${tasks?.size})` : ''}`,
  });

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <div>
        <Grid item xs={12} className={classes.content}>
          <Typography variant="h6">Tasks</Typography>
        </Grid>
        <Grid item xs={12}>
          <ExecuteTasksList
            tasks={tasks}
            project={project}
            projectId={projectId}
            copyTask={dispatchCopyTask}
            sortTasks={dispatchSortTasks}
            deleteTask={dispatchDeleteTask}
            totalPlannedDuration={totalPlannedDuration}
            totalElapsedDuration={totalElapsedDuration}
          />
        </Grid>
      </div>
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    padding: 0,
  },
  content: {
    padding: theme.spacing(2),
  },
});

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  tasks: getAllTasksFromState(state),
  templateTasks: getTaskTemplates(state),
  dataState: getSummarizedDataStateFromState(state, TASK_ACTIONS.GET_ALL_TASKS),
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetTasks: (projectId) => dispatch(getAllTasks(projectId)),
    dispatchOnUnload: tasksContainerOnUnload,
    dispatchOnLoad: (projectId) => dispatch(onLoad(projectId)),
    dispatchOnLoadStatus: onStatusLoad,
    dispatchGetProjectResources: (projectId) =>
      dispatch(getProjectResources(projectId)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(MobileProjectExecuteContainer);
