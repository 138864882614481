import { Field } from 'formik';
import { fromJS } from 'immutable';
import { Grid } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';

import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import { WELL_TAB_FIELDS } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import { useAutoScroll } from 'features/projects/tasks/task/dataAcquisition/hooks/useScrollable';
import { FluidsFormSectionDataAcquistion } from 'app/components/FluidsFormSection';

const flowPaths = fromJS([
  { id: 1, name: 'Flow Up' },
  { id: 2, name: 'Flow Down' },
]);

const WellTabContainer = ({
  taskId,
  projectId,
  disabled,
  fieldMarginClass,
  injectionMediums,
  productionMediums,
}) => {
  const [maxHeight, vh] = useAutoScroll();
  return (
    <div
      style={{
        maxHeight: `${maxHeight * vh}px`,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <Grid container spacing={2}>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Wellhead Pressure"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={WELL_TAB_FIELDS.WELLHEAD_PRESSURE}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Reservoir Pressure"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={WELL_TAB_FIELDS.RESERVOIR_PRESSURE}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Reservoir depth TVD"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={WELL_TAB_FIELDS.RESERVOIR_DEPTH}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Reservoir Temperature"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={WELL_TAB_FIELDS.RESERVOIR_TEMPERATURE}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Field
              type="number"
              fullWidth
              disabled={disabled}
              component={TextFieldFormik}
              name={WELL_TAB_FIELDS.CARBON_DIOXIDE}
              label="CO2"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="H2S"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={WELL_TAB_FIELDS.HYDROGEN_SULFIDE}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Surface Temperature"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={WELL_TAB_FIELDS.SURFACE_TEMPERATURE}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <SelectTextFieldFormik
              label="Flow Path up/down"
              margin="none"
              disabled={disabled}
              displayEmpty={false}
              items={flowPaths}
              className={fieldMarginClass}
              name={WELL_TAB_FIELDS.FLOW_PATH}
              getItemValue={(item) => item.get('id')}
              getItemName={(item) => item.get('name')}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectTextFieldFormik
              label="Injection Medium"
              margin="none"
              disabled={disabled}
              displayEmpty={false}
              items={injectionMediums}
              className={fieldMarginClass}
              name={WELL_TAB_FIELDS.INJECTION_MEDIUM}
              getItemValue={(item) => item.get('id')}
              getItemName={(item) => item.get('name')}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectTextFieldFormik
              label="Production Medium"
              margin="none"
              disabled={disabled}
              displayEmpty={false}
              items={productionMediums}
              className={fieldMarginClass}
              name={WELL_TAB_FIELDS.PRODUCTION_MEDIUM}
              getItemValue={(item) => item.get('id')}
              getItemName={(item) => item.get('name')}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Gas Rate"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={WELL_TAB_FIELDS.GAS_RATE}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Liquid Rate"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={WELL_TAB_FIELDS.LIQUID_RATE}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Injection Rate"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={WELL_TAB_FIELDS.INJECTION_RATE}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={3}>
            <Field
              type="number"
              label="Injection Production Volume"
              disabled={disabled}
              component={QuantityTextFieldFormik}
              name={WELL_TAB_FIELDS.INJECTION_PRODUCTION_VOLUME}
              placeholder="0"
              useRoundedValue={true}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={9}>
            <FluidsFormSectionDataAcquistion
              disabled={disabled}
              parentFluidObjectId={taskId}
              projectId={projectId}
              isArchive={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default WellTabContainer;
