import * as THREE from 'three';
import { Cylinder } from '@react-three/drei';
import React, { useEffect, useRef } from 'react';

const objectUp = new THREE.Object3D().up;
const reusableVector = new THREE.Vector3();
const reusableMatrix = new THREE.Matrix4();

const orientationMultiplier = new THREE.Matrix4().set(
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  -1,
  0,
  0,
  0,
  0,
  0,
  1,
);
const DrawCylinder = ({
  v1,
  v2,
  length,
  material,
  radius = 30,
  name,
  measuredDepthStart,
  measuredDepthEnd,
  thetaStart = 0,
  isHalf = true,
}) => {
  material.side = THREE.DoubleSide;
  const cylinderRef = useRef();
  const direction = reusableVector.subVectors(v1, v2);

  const args = isHalf
    ? [
        radius,
        radius,
        length ?? direction.length(),
        16,
        1,
        true,
        thetaStart,
        Math.PI,
      ]
    : [radius, radius, length ?? direction.length(), 16, 1];

  useEffect(() => {
    reusableMatrix.lookAt(v1, v2, objectUp);
    reusableMatrix.multiply(orientationMultiplier);
  }, [v1, v2]);

  useEffect(() => {
    if (!name) return;
    cylinderRef?.current?.applyMatrix4(reusableMatrix);
    cylinderRef?.current?.position.set(
      (v1.x + v2.x) / 2,
      (v1.y + v2.y) / 2,
      (v1.z + v2.z) / 2,
    );
  }, [cylinderRef, v1, v2, name]);

  useEffect(() => {
    if (!name) {
      return;
    }
    cylinderRef.current.data = {
      measuredDepthStart,
      measuredDepthEnd,
    };
  }, [cylinderRef, measuredDepthStart, measuredDepthEnd, name]);

  return (
    <Cylinder
      ref={cylinderRef}
      name={name || ''}
      position={[(v1.x + v2.x) / 2, (v1.y + v2.y) / 2, (v1.z + v2.z) / 2]}
      args={args}
      material={material}
    ></Cylinder>
  );
};

export default DrawCylinder;
