import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { defineCustomElements } from '@infragistics/igniteui-dockmanager/loader';

import 'features/projects/dashboard/components/DynamicDockManager/styles.css';
import DynamicDockManagerSlot from 'features/projects/dashboard/components/DynamicDockManager/DynamicDockManagerSlot';

defineCustomElements();

const renderSlots = (...slots) => {
  if (!slots) return null;

  return slots.map((children, index) => (
    <DynamicDockManagerSlot key={index} index={index}>
      {children}
    </DynamicDockManagerSlot>
  ));
};

const DynamicDockManager = ({ layout, component, ...rest }) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;

    ref.current.layout = layout;
  }, [layout]);

  return (
    <Grid component="igc-dockmanager" ref={ref}>
      <Grid component={component} renderSlots={renderSlots} {...rest} />
    </Grid>
  );
};

DynamicDockManager.propTypes = {
  layout: PropTypes.object.isRequired,
  component: PropTypes.elementType.isRequired,
};

export default compose(React.memo)(DynamicDockManager);
