import { fromJS, List } from 'immutable';

import { EMPTY_LIST } from 'app/app.constants';
import IncidentsMapper from 'features/projects/incidents/incidents.mappers';
import { INCIDENTS_ACTIONS } from 'features/projects/incidents/incidents.constants';

const initialState = fromJS({
  data: EMPTY_LIST,
});

const incidentsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case INCIDENTS_ACTIONS.PROJECT_INCIDENTS_PAGE_LOADED: {
      if (action.error) return state;

      return state.set(
        'data',
        List(action.payload).map(IncidentsMapper.Incident.from),
      );
    }
    case INCIDENTS_ACTIONS.CREATE_INCIDENT: {
      if (action.error) return state;
      return state.update('data', (incidents) =>
        incidents.push(IncidentsMapper.Incident.from(action.payload)),
      );
    }
    case INCIDENTS_ACTIONS.UPDATE_INCIDENT: {
      if (action.error) return state;

      const { incidentId } = action;

      return state.update('data', (incidents) => {
        const index = incidents.findIndex(
          (incident) => incident.get('id') === incidentId,
        );

        return incidents.set(
          index,
          IncidentsMapper.Incident.from(action.payload),
        );
      });
    }
    case INCIDENTS_ACTIONS.DELETE_INCIDENT: {
      if (action.error) return state;
      return state.update('data', (incidents) =>
        incidents.filterNot((incident) => incident.get('id') === action.id),
      );
    }
    default:
      return state;
  }
};

export default incidentsReducer;
