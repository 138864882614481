import moment from 'moment';
import { Map, Set } from 'immutable';

import { EMPTY_SET } from 'app/app.constants';
import { getInitials, getAvatar } from 'utils/app.util';
import { toPermissionIdFromDto, toRoleIdFromDto } from 'mappers';

const toProjectMemberRoleDTO = (roleId, projectMemberId) => {
  return {
    roleId,
    projectMemberId,
  };
};

const toProjectFieldCrewRoleDTO = (roleId, projectMemberFieldCrewId) => {
  return {
    roleId,
    projectMemberFieldCrewId,
  };
};

export const toProjectMemberFromDTO = ({
  roles,
  userId,
  fullName,
  jobTitle,
  projectId,
  userAvatar,
  permissions,
  projectMemberId,
  organizationName,
  teamProjectRoleId,
}) => {
  return Map({
    userId,
    projectId,
    projectMemberId,
    teamProjectRoleId,
    user: {
      id: userId,
      name: fullName,
      initials: getInitials(fullName),
      avatar: getAvatar(userAvatar),
      title: jobTitle,
      organizationName: organizationName,
    },
    project: {
      id: projectId,
      memberId: projectMemberId,
    },
    roles: roles
      ? Set(roles.map(toRoleIdFromDto))
      : initialProjectMember.get('roles'),
    permissions: permissions
      ? Set(permissions.map(toPermissionIdFromDto))
      : initialProjectMember.get('permissions'),
  });
};

export const toProjectFieldCrewFromDTO = ({
  roles,
  userId,
  fullName,
  jobTitle,
  projectId,
  userAvatar,
  permissions,
  projectMemberFieldCrewId,
  organizationName,
  crewId,
  qualificationId,
  shiftTypeId,
  safetyDelegate,
  to,
  from,
}) => {
  return Map({
    userId,
    projectId,
    projectMemberFieldCrewId,
    to: to ? moment(to) : null,
    from: from ? moment(from) : null,
    crewId,
    qualificationId,
    shiftTypeId,
    safetyDelegate,
    user: {
      id: userId,
      name: fullName,
      initials: getInitials(fullName),
      avatar: getAvatar(userAvatar),
      title: jobTitle,
      organizationName: organizationName,
    },
    project: {
      id: projectId,
      memberId: projectMemberFieldCrewId,
    },
    roles: roles
      ? Set(roles.map(toRoleIdFromDto))
      : initialProjectFieldCrew.get('roles'),
    permissions: permissions
      ? Set(permissions.map(toPermissionIdFromDto))
      : initialProjectFieldCrew.get('permissions'),
  });
};

const toProjectMemberFromDTOById = (projectMembers) =>
  projectMembers.reduce(
    (usersById, member) => ({
      ...usersById,
      [member.userId]: toProjectMemberFromDTO(member),
    }),
    {},
  );

const toProjectFieldCrewFromDTOById = (fieldCrews) =>
  fieldCrews.reduce(
    (usersById, member) => ({
      ...usersById,
      [member.userId]: toProjectFieldCrewFromDTO(member),
    }),
    {},
  );

export const toProjectFieldCrewDTO = ({
  roles,
  permissions,
  ...projectFieldCrew
}) => {
  return {
    roles: [],
    permissions: [],

    ...projectFieldCrew,
  };
};

const initialProjectMember = Map({
  roles: EMPTY_SET,
  permissions: EMPTY_SET,
});

const initialProjectFieldCrew = Map({
  roles: EMPTY_SET,
  permissions: EMPTY_SET,
});

const teamMappers = {
  ProjectMember: {
    initial: initialProjectMember,
    from: toProjectMemberFromDTO,
    fromByUserId: toProjectMemberFromDTOById,
  },
  ProjectFieldCrew: {
    initial: initialProjectFieldCrew,
    from: toProjectFieldCrewFromDTO,
    fromByUserId: toProjectFieldCrewFromDTOById,
    to: toProjectFieldCrewDTO,
  },
  ProjectMemberRole: {
    to: toProjectMemberRoleDTO,
  },
  ProjectFieldCrewRole: {
    to: toProjectFieldCrewRoleDTO,
  },
};

export default teamMappers;
