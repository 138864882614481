import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { Grid, Card, CardContent, Divider } from '@material-ui/core';

import { scrollSmoothlyIntoViewIfNeeded } from 'utils/app.util';
import CardNavigationItemData from 'app/components/CardNavigationItem/CardNavigationItemData';
import CardNavigationItemHeader from 'app/components/CardNavigationItem/CardNavigationItemHeader';
import CardNavigationItemActions from 'app/components/CardNavigationItem/CardNavigationItemActions';

const CardNavigationItem = ({
  to,
  data,
  title,
  actions,
  classes,
  renderHeaderAction,
  selected = null,
  children,
}) => {
  const ref = useRef(null);
  const isMatch = !!useRouteMatch(to);
  const isSelected = selected ?? isMatch;

  useEffect(() => {
    if (isSelected) {
      scrollSmoothlyIntoViewIfNeeded(ref.current);
    }
  });

  return (
    <NavLink ref={ref} to={to}>
      <Card
        raised
        classes={{
          root: classNames(classes.root, {
            [classes.rootSelected]: isSelected,
          }),
        }}
      >
        <CardNavigationItemHeader
          title={title}
          renderHeaderAction={renderHeaderAction}
        />
        <Divider />
        <CardContent>
          <CardNavigationItemData data={data} />
        </CardContent>
        <Divider />
        <Grid container item xs={12} justifyContent="space-between">
          {children}
          <CardNavigationItemActions
            actions={actions}
            classes={{ root: classes.actions }}
          />
        </Grid>
      </Card>
    </NavLink>
  );
};

CardNavigationItem.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  renderHeaderAction: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      onClick: PropTypes.func.isRequired,
      title: PropTypes.string.isRequired,
      Icon: PropTypes.elementType.isRequired,
    }),
  ).isRequired,
};

const styles = (theme) => ({
  root: {
    '&:hover': {
      background: theme.palette.action.hover,
    },
  },
  rootSelected: {
    background: theme.palette.action.selected,
  },
  actions: {},
});

export default compose(withStyles(styles))(CardNavigationItem);
