import { useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Table, TableRowActionsCell } from 'altus-ui-components';

const SelectedTemplateTasksTable = ({
  deselectTemplateTask,
  selectedTemplateTasks,
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 12,
        Header: 'Selected items',
        accessor: (template) => template.get('title'),
      },
      {
        id: 'actions',
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => (
          <TableRowActionsCell minItems={2}>
            <IconButton
              title="Delete"
              onClick={() => deselectTemplateTask(row.original)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </TableRowActionsCell>
        ),
      },
    ],
    [deselectTemplateTask],
  );

  return (
    <Table
      columns={columns}
      useGlobalFilter={false}
      items={selectedTemplateTasks}
      noItemsMessage={'No items selected'}
    />
  );
};

export default SelectedTemplateTasksTable;
