import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { EMPTY_MAP } from 'app/app.constants';
import {
  SimulationDynamicToolParameterFluidSelectionTable,
  SimulationDynamicToolParameterJarActivationTable,
} from './components';
import { useInitializeTaskSimulationDynamicToolParametersContainer } from './hooks/useInitializeTaskSimulationDynamicToolParametersContainer';
import { DynamicToolParameter } from './simulationDynamicToolParameter.constants';
import { getSimulationFromState } from '../simulation.selectors';

const TaskSimulationDynamicToolParametersContainer = ({
  projectId,
  taskId,
  simulationId,
}) => {
  const parameters = useInitializeTaskSimulationDynamicToolParametersContainer(
    projectId,
    taskId,
    simulationId,
  );

  const simulation =
    useSelector((state) => getSimulationFromState(state, simulationId)) ??
    EMPTY_MAP;
  const isPlanned = simulation.get('isPlanned');
  const forceUnit = simulation.getIn(['stuffingBoxFriction', 'unit']);

  return (
    <Grid container padding={1} spacing={5}>
      <Grid item xs={12}>
        <SimulationDynamicToolParameterJarActivationTable
          disabled={isPlanned}
          forceUnit={forceUnit}
          data={parameters.get(DynamicToolParameter.JAR_ACTIVATION)}
        />
      </Grid>
      <Grid item xs={12}>
        <SimulationDynamicToolParameterFluidSelectionTable
          disabled={isPlanned}
          data={parameters.get(DynamicToolParameter.FLUID_SELECTION)}
        />
      </Grid>
    </Grid>
  );
};

export default TaskSimulationDynamicToolParametersContainer;
