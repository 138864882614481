import moment from 'moment';
import last from 'lodash/last';
import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';
import initial from 'lodash/initial';
import { Map, List, fromJS, Set, OrderedMap } from 'immutable';

import {
  DIRECTION,
  EMPTY_SET,
  EMPTY_MAP,
  EMPTY_LIST,
  FacilityType,
  EMPTY_STRING,
  PROJECT_STATUS,
  CONVERT_SYSTEMS,
  CONVERT_MEASURES,
  OrganizationType,
  CUSTOM_UNIT_SYSTEM,
  USE_PROJECT_DEFAULT_UNIT_SYSTEM,
} from 'app/app.constants';

import { getInitials, getAvatar } from 'utils/app.util';
import tasksMapper from 'features/projects/tasks/tasks.mappers';
import operationsMappers from 'features/operations/operations.mappers';
import activitiesMapper from 'features/projects/activities/activities.mappers';
import { CheetahJobSource } from 'features/projects/dashboard/dashboard.constants';
import simulationMappers from 'features/projects/tasks/task/simulation/simulation.mappers';

const toLowerCase = (name = EMPTY_STRING) =>
  // Can't use _.startCase because it removes norwegian special characters ('Å' => 'A')
  name
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const cheetahJobSourceToString = (cheetahJobSource) =>
  ({
    [CheetahJobSource.LWI]: 'LWI',
    [CheetahJobSource.OTHER]: 'Other',
    [CheetahJobSource.CHEETAH]: 'Cheetah',
    [CheetahJobSource.WARRIOR]: 'Warrior',
    [CheetahJobSource.SM_LOCATION]: 'Winch',
    [CheetahJobSource.SIMULATION]: 'Simulation',
    [CheetahJobSource.WITSML]: 'Witsml',
  }[cheetahJobSource]);

export const facilityTypeToString = (facilityType) =>
  ({
    [FacilityType.FIXED]: 'Fixed',
    [FacilityType.MOBILE]: 'Mobile',
  }[facilityType]);

export const toPermissionIdFromDto = ({ permissionId }) => permissionId;

export const toRoleIdFromDto = ({ roleId }) => roleId;

export const toServiceIdFromDto = ({ serviceId }) => serviceId;

export const winchSpeedToDirection = (speed) =>
  speed === 0
    ? DIRECTION.STATIONARY
    : speed > 0
    ? DIRECTION.DOWN
    : DIRECTION.UP;

// Winch/Smartmonitor data
const winchmonitor = () => {
  return {
    md: 0,
    tension: 0,
    speed: 0,
    timestamp: null,
    direction: DIRECTION.STATIONARY,
  };
};

// Wellbore data
const wellbore = () => {
  return {
    id: null,
    name: '',
    status: null,
    waterdepth: null,
    tvd: null,
    md: null,
    well: { name: null },
    trajectory: [],
    field: { id: null, name: null },
    elevation: null,
    location: null,
    entered: null,
    completed: null,
    created: null,
    modified: null,
    limit: {
      northEast: null,
      tvd: null,
    },
  };
};

const wellboreFromDTO = ({
  wellboreName,
  wellboreId,
  verticalDepth,
  measuredDepth,
  kellyBushingElevation,
  completedDate,
  enteredDate,
  fieldName,
  wellboreDetailId,
  wellboreDetail,
  trajectoryUpdated,
  ...wellbore
}) => {
  return Map({
    id: wellboreId,
    name: wellboreName,
    tvd: verticalDepth,
    md: measuredDepth,
    elevation: kellyBushingElevation,
    entered: enteredDate,
    completed: completedDate,
    wellboreId,
    wellboreDetailId,
    // if the wellboreDetailId is set, we expect wellboreDetail to be found in the wellboreDetail.reducer
    wellboreDetail:
      !wellboreDetailId && wellboreDetail
        ? WellboreDetailMapper.from(wellboreDetail)
        : undefined,
    fieldName: fieldName,
    trajectoryUpdated: trajectoryUpdated
      ? moment(trajectoryUpdated)
      : undefined,
    ...wellbore,
  });
};

// Project data
const initialProject = () => {
  return {
    id: null,
    wellbore: wellbore(),
    referenceNumber: null,
    name: '',
    description: '',
    departments: [],
    status: PROJECT_STATUS.PLAN,
    field: { id: null, name: '' },
    facility: { id: null, name: '' },
    planned: { start: null, end: null },
    actual: { start: null, end: null },
    progress: 0,
    created: null,
    modified: null,
    lastSeen: null,
    estimated: {
      start: ['', '', ''],
      end: ['', '', ''],
    },
    units: {},
    projectMembers: [],
    organization: { id: null, name: '' },
    services: EMPTY_SET,
    teamUrl: null,
    projectRisk: [],
    taskRisk: [],
  };
};

const DepartmentMapper = {
  from: ({ departmentId, name }) =>
    Map({
      id: departmentId,
      name: name,
    }),
  to: ({ id: departmentId, name }) => ({
    departmentId,
    name,
  }),
};

const ServiceMapper = {
  from: ({ serviceId, ...service }) =>
    Map({
      serviceId,
      id: serviceId,
      ...service,
    }),
};

export const measurementPreferenceFromDto = (measurementPreference) =>
  ({
    0: CONVERT_SYSTEMS.METRIC,
    1: CONVERT_SYSTEMS.METRIC,
    2: CONVERT_SYSTEMS.IMPERIAL,
    64: CUSTOM_UNIT_SYSTEM,
    65: USE_PROJECT_DEFAULT_UNIT_SYSTEM,
  }[measurementPreference]);

export const measurementPreferencesToDto = ({
  [CONVERT_MEASURES.LENGTH]: length,
  [CONVERT_MEASURES.MASS]: mass,
  [CONVERT_MEASURES.PRESSURE]: pressure,
  [CONVERT_MEASURES.VOLUME]: volume,
  [CONVERT_MEASURES.VOLUME_FLOW_RATE]: volumeFlowRate,
  [CONVERT_MEASURES.SPEED]: speed,
  [CONVERT_MEASURES.TEMPERATURE]: temperature,
  [CONVERT_MEASURES.DEPTH]: depth,
  [CONVERT_MEASURES.FORCE]: force,
  [CONVERT_MEASURES.BIG_FORCE]: bigForce,
  [CONVERT_MEASURES.TOOL_DIAMETER]: toolDiameter,
  [CONVERT_MEASURES.TUBULAR_DIAMETER]: tubularDiameter,
  [CONVERT_MEASURES.GAS_FLOW_RATE]: gasFlowRate,
  [CONVERT_MEASURES.LIQUID_RATE]: liquidRate,
  [CONVERT_MEASURES.GAS_DENSITY]: gasDensity,
  [CONVERT_MEASURES.LIQUID_DENSITY]: liquidDensity,
  [CONVERT_MEASURES.WEIGHT_IN_AIR]: weightInAir,
  [CONVERT_MEASURES.STRETCH_COEFFICIENT]: stretchCoefficient,
}) => ({
  length: measurementPreferenceToDto(length),
  mass: measurementPreferenceToDto(mass),
  volume: measurementPreferenceToDto(volume),
  volumeFlowRate: measurementPreferenceToDto(volumeFlowRate),
  temperature: measurementPreferenceToDto(temperature),
  pressure: measurementPreferenceToDto(pressure),
  speed: measurementPreferenceToDto(speed),
  depth: measurementPreferenceToDto(depth),
  force: measurementPreferenceToDto(force),
  bigForce: measurementPreferenceToDto(bigForce),
  toolDiameter: measurementPreferenceToDto(toolDiameter),
  tubularDiameter: measurementPreferenceToDto(tubularDiameter),
  gasFlowRate: measurementPreferenceToDto(gasFlowRate),
  liquidRate: measurementPreferenceToDto(liquidRate),
  gasDensity: measurementPreferenceToDto(gasDensity),
  liquidDensity: measurementPreferenceToDto(liquidDensity),
  weightInAir: measurementPreferenceToDto(weightInAir),
  stretchCoefficient: measurementPreferenceToDto(stretchCoefficient),
});

export const toMeasurementPreferencesFromDto = (measurementPreference) => {
  const {
    mass,
    speed,
    depth,
    force,
    length,
    volume,
    pressure,
    bigForce,
    temperature,
    toolDiameter,
    volumeFlowRate,
    tubularDiameter,
    gasFlowRate,
    liquidRate,
    gasDensity,
    liquidDensity,
    weightInAir,
    stretchCoefficient,
  } = measurementPreference;

  return {
    [CONVERT_MEASURES.LENGTH]: measurementPreferenceFromDto(length),
    [CONVERT_MEASURES.MASS]: measurementPreferenceFromDto(mass),
    [CONVERT_MEASURES.PRESSURE]: measurementPreferenceFromDto(pressure),
    [CONVERT_MEASURES.VOLUME]: measurementPreferenceFromDto(volume),
    [CONVERT_MEASURES.VOLUME_FLOW_RATE]:
      measurementPreferenceFromDto(volumeFlowRate),
    [CONVERT_MEASURES.SPEED]: measurementPreferenceFromDto(speed),
    [CONVERT_MEASURES.TEMPERATURE]: measurementPreferenceFromDto(temperature),
    [CONVERT_MEASURES.DEPTH]: measurementPreferenceFromDto(depth),
    [CONVERT_MEASURES.FORCE]: measurementPreferenceFromDto(force),
    [CONVERT_MEASURES.BIG_FORCE]: measurementPreferenceFromDto(bigForce),
    [CONVERT_MEASURES.TOOL_DIAMETER]:
      measurementPreferenceFromDto(toolDiameter),
    [CONVERT_MEASURES.TUBULAR_DIAMETER]:
      measurementPreferenceFromDto(tubularDiameter),
    [CONVERT_MEASURES.GAS_FLOW_RATE]: measurementPreferenceFromDto(gasFlowRate),
    [CONVERT_MEASURES.LIQUID_RATE]: measurementPreferenceFromDto(liquidRate),
    [CONVERT_MEASURES.GAS_DENSITY]: measurementPreferenceFromDto(gasDensity),
    [CONVERT_MEASURES.LIQUID_DENSITY]:
      measurementPreferenceFromDto(liquidDensity),
    [CONVERT_MEASURES.WEIGHT_IN_AIR]: measurementPreferenceFromDto(weightInAir),
    [CONVERT_MEASURES.STRETCH_COEFFICIENT]:
      measurementPreferenceFromDto(stretchCoefficient),
  };
};

export const measurementPreferenceToDto = (measurementSystem) =>
  ({
    [CONVERT_SYSTEMS.METRIC]: 0,
    [CONVERT_SYSTEMS.METRIC]: 1,
    [CONVERT_SYSTEMS.IMPERIAL]: 2,
    [CUSTOM_UNIT_SYSTEM]: 64,
    [USE_PROJECT_DEFAULT_UNIT_SYSTEM]: 65,
  }[measurementSystem]);

const initialFluids = () => fromJS({});
const mapFunctionProjectFluids = ({ projectFluidId, ...rest }) => ({
  ...{ fluidId: projectFluidId },
  ...rest,
});
const mapFunctionWellboreFluids = ({ wellboreFluidId, ...rest }) => ({
  ...{ fluidId: wellboreFluidId },
  ...rest,
});
const mapFunctionTaskFluids = ({ dataAcquisitionFluidId, ...rest }) => ({
  ...{ fluidId: dataAcquisitionFluidId },
  ...rest,
});
const baseFluidsFromDtoFn = (fluidsDto, mapToCorrectShapeFn) => {
  const fluidsInCorrectShape = fluidsDto.map(mapToCorrectShapeFn);
  return fluidsInCorrectShape.reduce((acc, curr) => {
    return acc.set(`${curr.fluidId}`, fromJS(curr));
  }, Map({}));
};

const projectFluidsFromDTO = (fluidsDto) =>
  baseFluidsFromDtoFn(fluidsDto, mapFunctionProjectFluids);
const wellboreFluidsFromDTO = (fluidsDto) =>
  baseFluidsFromDtoFn(fluidsDto, mapFunctionWellboreFluids);
const taskFluidsFromDTO = (fluidsDto) =>
  baseFluidsFromDtoFn(fluidsDto, mapFunctionTaskFluids);

const projectFromDTO = ({
  created,
  departments,
  endTime,
  estimatedEnd,
  estimatedStart,
  lastSeen,
  modified,
  percentComplete,
  plannedEndDate,
  plannedStartDate,
  projectId,
  taskServices,
  startTime,
  duration,
  measurementPreference,
  measurementSystem = CONVERT_SYSTEMS.METRIC.id,
  owners,
  wellboreLocation,
  ...project
}) => {
  const parseEstimatedDate = (estimated = '--') =>
    (estimated.length ? estimated : '--').split('-').reduce((sum, curr) => {
      var converted = parseInt(curr, 10);
      return sum.push(isNaN(converted) ? '' : converted);
    }, List());

  return fromJS({
    id: projectId,
    startTime: startTime ? moment(startTime) : null,
    endTime: endTime ? moment(endTime) : null,
    departments: List(departments).map(DepartmentMapper.from),
    estimatedStart: parseEstimatedDate(estimatedStart),
    estimatedEnd: parseEstimatedDate(estimatedEnd),
    duration: duration ? moment.duration(duration) : undefined,
    planned: {
      start: plannedStartDate,
      end: plannedEndDate,
    },
    created: created ? moment(created) : undefined,
    modified: modified ? moment(modified) : undefined,
    lastSeen: lastSeen ? moment(lastSeen) : undefined,
    progress: percentComplete,
    unit: measurementPreferenceFromDto(measurementSystem),
    units: measurementPreference
      ? toMeasurementPreferencesFromDto(measurementPreference)
      : Map(),
    location: wellboreLocation,
    owners: List(owners).map(UserMapper.from),
    taskServices: fromJS(taskServices),
    projectId,
    ...project,
  });
};

// User
const initialUser = Map({
  id: null,
  account: null,
  name: null,
  initials: null,
  avatar: null,
  title: null,
  mail: null,
  mobilePhone: null,
  organization: {
    name: null,
  },
  role: {
    roleId: null,
  },
  permissions: EMPTY_SET,
  roles: EMPTY_SET,
});

const userToDto = ({ id, unit }) => ({
  userId: id,
  measurementSystem: measurementPreferenceToDto(unit),
});

const userFromDTO = ({
  roles,
  userId,
  avatar,
  jobTitle,
  fullName,
  permissions,
  measurementSystem,
  projectPermissions,
  ...user
}) => {
  return Map({
    userId,
    id: userId,
    name: fullName,
    title: jobTitle,
    avatar: getAvatar(avatar),
    unit: measurementPreferenceFromDto(measurementSystem),
    projectPermissions: Set(projectPermissions).map(toPermissionIdFromDto),
    isMainVendor: user.organizationType === OrganizationType.MAIN_VENDOR,
    roles: roles ? Set(roles).map(toRoleIdFromDto) : initialUser.get('roles'),
    permissions: permissions
      ? Set(permissions).map(toPermissionIdFromDto)
      : initialUser.get('permissions'),
    ...user,
  });
};

const toProjectMember = ({ id }) => {
  return Map({
    projectMemberId: 0,
    userId: id,
  });
};

// UserRole
const userRoleToDto = (roleId, userId) => {
  return {
    roleId,
    userId,
  };
};

// Event
const event = () => {
  return Map({
    id: null,
    text: '',
    author: { id: null },
    modified: null,
    created: null,
  });
};

const eventFromDTO = ({
  eventId,
  text,
  userId,
  userFullName,
  userAvatar,
  modified,
  created,
}) => {
  return Map({
    type: eventId,
    id: eventId,
    text: text,
    author: {
      id: userId,
      name: userFullName,
      initials: getInitials(userFullName),
      avatar: getAvatar(userAvatar),
    },
    modified: moment(modified),
    created: moment(created),
  });
};

// Progress
const progress = () => {
  return fromJS({
    id: null,
    progress: 0,
    total: {
      tasks: 0,
      activities: 0,
    },
    previous: {},
    current: {
      taskNumber: 0,
      activityNumber: null,
    },
    next: {},
  });
};

const progressFromDTO = ({
  canGoBack,
  currentTaskPercentComplete,
  previousActivity,
  projectId,
  percentComplete,
  currentTaskNumber,
  taskCount,
  currentActivityNumber,
  activityCount,
  currentTask,
  currentActivity,
  nextActivity,
  nextTask,
  status,
  simulationsRunCount,
  activeTask,
  ...rest
}) => {
  return fromJS({
    id: projectId, // TODO Remove
    projectId: projectId,
    status: status,
    simulationsRunCount,
    progress: percentComplete,
    currentTaskProgress: currentTaskPercentComplete,
    total: {
      tasks: taskCount,
      activities: activityCount,
    },
    previous: {
      canGoBack,
      activity: isNil(previousActivity)
        ? undefined
        : activitiesMapper.Activity.from(previousActivity),
    },
    current: {
      taskNumber: currentTaskNumber,
      task: isNil(currentTask) ? undefined : tasksMapper.Task.from(currentTask),
      activityNumber: currentActivityNumber,
      activity: isNil(currentActivity)
        ? undefined
        : activitiesMapper.Activity.from(currentActivity),
    },
    next: {
      activity: isNil(nextActivity)
        ? undefined
        : activitiesMapper.Activity.from(nextActivity),
      task: isNil(nextTask) ? undefined : tasksMapper.Task.from(nextTask),
    },
    activeTask: isNil(activeTask)
      ? undefined
      : tasksMapper.Task.from(activeTask),
    ...rest,
  });
};

const organization = () =>
  Map({
    id: null,
    name: '',
    type: null,
  });

const organizationFromDTO = ({
  organizationId,
  displayName,
  ...organization
}) =>
  Map({
    id: organizationId,
    name: displayName,
    ...organization,
  });

const FieldMapper = {
  from: ({ fieldId, name, projects, wells, ...rest }) =>
    Map({
      fieldId,
      id: fieldId, // to avoid too many breaking changes
      // Can't use _.startCase because it removes norwegian special characters ('Å' => 'A')
      name: toLowerCase(name),
      projects: List(projects).map(mappers.Project.from),
      wells: List(wells).map(mappers.Wellbore.from),
      ...rest,
    }),
};

const facilityFromDTO = ({ id, name, ...facility }) => {
  return Map({
    id: id,
    name: name,
    value: id,
    ...facility,
  });
};

const operatorFromDTO = ({ facilityId, name, ...operator }) => {
  return Map({
    id: facilityId,
    name: name,
    ...operator,
  });
};

// Sequence

const sequence = ({ itemId, oldSequenceNumber, newSequenceNumber }) => {
  return {
    itemId: itemId,
    oldSequenceNumber: oldSequenceNumber,
    newSequenceNumber: newSequenceNumber,
  };
};

const sequenceToDto = (itemId, { oldSequenceNumber, newSequenceNumber }) => {
  return {
    itemId: itemId,
    oldSequenceNumber: oldSequenceNumber,
    newSequenceNumber: newSequenceNumber,
  };
};

const fileCategoryFromDTO = ({ id, name }) => {
  return Map({
    id: id,
    name: name,
    value: id,
  });
};

const initialMetaFromFilename = ({ name }, category) => {
  const parts = name.split('.');

  return Map({
    name: initial(parts).join('.'),
    category: category,
    extension: `.${last(parts)}`,
  });
};

const userPreferenceFromDto = ({
  receiveDailyDigestEmail,
  userPreferenceId,
}) => ({
  id: userPreferenceId,
  receiveDailyDigestEmail: receiveDailyDigestEmail,
});

const userPreferenceToDto = ({ receiveDailyDigestEmail, id }) => ({
  userPreferenceId: id,
  receiveDailyDigestEmail: receiveDailyDigestEmail,
});

const taskTypeFromDto = ({ id, name }) =>
  Map({
    id: id,
    name: name,
    value: id,
  });

const trajectoryPointFromDto = ({ ew, md, ns, vd, ...trajectoryPoint }) =>
  Map({
    ...trajectoryPoint,
    measuredDepth: md,
    verticalDepth: vd,
    northSouth: ns,
    eastWest: ew,
  });

const trajectoryFromDto = ({
  trajectoryPoints,
  verticalDepth,
  measuredDepth,
  maximumNorthEast,
  maximumDogLeg,
  maximumDogLegDepth,
  maximumInclination,
  maximumInclinationDepth,
  imported,
  ...trajectory
}) =>
  Map({
    trajectoryPoints: trajectoryPoints
      ? List(trajectoryPoints.map(trajectoryPointFromDto))
      : EMPTY_LIST,
    verticalDepth,
    measuredDepth,
    maximumNorthEast,
    maximumDogLeg,
    maximumDogLegDepth,
    maximumInclination,
    maximumInclinationDepth,
    imported: imported ? moment(imported) : undefined,
    ...trajectory,
  });

const WellboreDetailMapper = {
  from: ({
    services,
    hostileFluids,
    additionalParameters,
    ...wellboreDetail
  }) =>
    Map({
      services: services ? List(services).map(Map) : EMPTY_LIST,
      hostileFluids: hostileFluids ? Map(hostileFluids) : EMPTY_MAP,
      additionalParameters: additionalParameters
        ? OrderedMap(additionalParameters)
        : EMPTY_MAP,
      ...wellboreDetail,
    }),
};

const PageParamsMapper = {
  from: ({ ...pageParams }) => Map({ ...pageParams }),
};

const ObjectiveMapper = {
  from: ({ ...objective }) =>
    Map({
      ...objective,
    }),
};

const UserMapper = {
  initial: initialUser,
  from: userFromDTO,
  to: userToDto,
  toProjectMember,
};

const CheetahJobCurveMapper = {
  from: ({ ...cheetahJobCurve }) =>
    Map({
      ...cheetahJobCurve,
    }),
};

const ProjectDashboardMapper = {
  from: ({ ...projectDashboard }) =>
    Map({
      ...projectDashboard,
    }),
};

const equipmentAssetFromDTO = ({
  equipmentId,
  name,
  description,
  m3ItemNumber,
  m3ToolAssets,
}) => {
  return Map({
    equipmentId,
    name,
    m3ItemNumber,
    description,
    m3ToolAssets: List(m3ToolAssets).map((item) =>
      Map({
        decodedSerialNumber: item.escapedSerialNumber,
        serialNumber: item.serialNumber,
        status: item.status,
        location: item.location,
        warehouse: item.warehouse,
        installation: item.installation,
        itemNumber: item.itemNumber,
        lastTransaction: item.lastTransaction
          ? moment(item.lastTransaction)
          : undefined,
        customerAddress: item.customerAddress
          ? Map({ name: item.customerAddress.name })
          : {},
      }),
    ),
  });
};

const mappers = {
  Document: {
    Meta: {
      initial: initialMetaFromFilename,
    },
  },
  User: UserMapper,
  Wellbore: {
    initial: () => Map(wellbore()),
    from: wellboreFromDTO,
  },
  Project: {
    initial: initialProject,
    from: projectFromDTO,
  },
  WellboreFluids: {
    from: wellboreFluidsFromDTO,
  },
  ProjectFluids: {
    initial: initialFluids,
    from: projectFluidsFromDTO,
  },
  TaskFluids: {
    from: taskFluidsFromDTO,
  },
  Event: {
    initial: event,
    from: eventFromDTO,
  },
  Progress: {
    initial: progress,
    from: progressFromDTO,
  },
  Organization: {
    initial: organization,
    from: organizationFromDTO,
  },
  Field: FieldMapper,
  Facility: {
    from: facilityFromDTO,
  },
  Operator: {
    from: operatorFromDTO,
  },
  WinchMonitor: {
    initial: winchmonitor,
  },
  UserRole: {
    to: userRoleToDto,
  },
  Sequence: {
    from: sequence,
    to: sequenceToDto,
  },
  Department: DepartmentMapper,
  Service: ServiceMapper,
  WellboreDetail: WellboreDetailMapper,
  MeasurementPreference: {
    from: measurementPreferenceFromDto,
  },
  MeasurementPreferences: {
    from: toMeasurementPreferencesFromDto,
  },
  FileCategory: {
    from: fileCategoryFromDTO,
  },
  UserPreference: {
    from: userPreferenceFromDto,
    to: userPreferenceToDto,
  },
  TaskType: {
    from: taskTypeFromDto,
  },
  Trajectory: {
    from: trajectoryFromDto,
  },
  TrajectoryPoint: {
    from: trajectoryPointFromDto,
  },
  equipmentAsset: {
    from: equipmentAssetFromDTO,
  },
  Objective: ObjectiveMapper,
  ProjectDashboard: ProjectDashboardMapper,
  CheetahJobCurve: CheetahJobCurveMapper,
  PageParams: PageParamsMapper,
  ...operationsMappers,
  ...simulationMappers,
};

export default mappers;
