import { useMemo, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';

import { Table, TableRowActionsCell } from 'altus-ui-components';
import { useToggle } from 'altus-hooks';

import { ActivityDuration } from 'app/components/Counter';
import { formatDate, formatDuration } from 'utils/format.util';
import { EMPTY_LIST, WORK_ITEM_STATUS } from 'app/app.constants';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import ActivityCommentModal from 'features/projects/tasks/task/activities/ActivityCommentModal';
import TaskActivityDrawer from 'features/projects/tasks/task/activities/TaskActivityDrawer';

const TaskActivitiesTableWithDrawer = ({
  displayComments,
  activities = EMPTY_LIST,
  ...rest
}) => {
  const [isOpen, toggle] = useToggle();
  const [activity, setActivity] = useState(null);
  const initialState = useMemo(
    () => ({
      hiddenColumns: displayComments ? [] : ['comments'],
    }),
    [displayComments],
  );

  const columns = useMemo(
    () => [
      {
        id: 'status',
        xs: 1,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const activity = row.original;

          return (
            <TableRowActionsCell>
              <WorkItemStatus
                status={activity.get('status')}
                isWaiting={activity.get('isWait')}
                isPointInTime={activity.get('isPointInTime')}
              />
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 9,
        Header: 'Activity',
        Cell: ({ row }) => {
          const activity = row.original;
          if (!activity.get('name')) return null;
          return (
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Typography variant="subtitle2">
                  {activity.get('name')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" color="textSecondary">
                  {'•'}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" color="textSecondary">{`${
                  activity.get('subActivities').size
                } sub-activities`}</Typography>
              </Grid>
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Started',
        accessor: (activity) => formatDate(activity.get('startTime')),
      },
      {
        xs: 2,
        Header: 'Duration',
        Cell: ({ row }) => {
          const activity = row.original;

          if (activity.get('isPointInTime')) return null;

          const isTimerActive =
            activity.get('status') === WORK_ITEM_STATUS.STARTED;

          return (
            <ActivityDuration
              isTimerActive={isTimerActive}
              key={activity.get('startTime')}
              endTime={
                activity.get('isWait')
                  ? activity.get('endTime')
                  : activity.get('nextStandardActivityStartTime') ??
                    activity.get('endTime')
              }
              startTime={activity.get('startTime')}
              waitCodesDuration={activity.get('waitCodesDuration')}
              calculateDurationFromStartAndEndtime
              renderValue={({ elapsedTime }) => formatDuration(elapsedTime)}
            />
          );
        },
      },
      {
        id: 'comments',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => (
          <TableRowActionsCell minItems={1}>
            <ActivityCommentModal activity={row.original} />
          </TableRowActionsCell>
        ),
      },
    ],
    [],
  );

  return (
    <>
      <Table
        columns={columns}
        items={activities ?? EMPTY_LIST}
        initialState={initialState}
        stickyHeader
        onRowClick={(activity) => {
          setActivity(activity);
          toggle();
        }}
        {...rest}
      />
      {activity && (
        <TaskActivityDrawer
          isOpen={isOpen}
          toggleDrawer={toggle}
          activity={activity}
          displayComments={displayComments}
        />
      )}
    </>
  );
};

export default TaskActivitiesTableWithDrawer;
