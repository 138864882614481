import { compose } from 'redux';
import { useMemo } from 'react';
import { Field, Formik } from 'formik';
import { Grid, MenuItem } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { EMPTY_LIST } from 'app/app.constants';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import SearchTextFieldFormik from 'app/components/form/formik/SearchTextFieldFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';

const Filters = {
  FACILITY_ID: 'facilityId',
  TEXT_SEARCH: 'textSearch',
  ORGANIZATION_ID: 'organizationId',
  IS_ONLINE: 'isOnline',
};

const shouldSubmit = ({ isValid }) => isValid;

const CheetahJobFilter = ({
  filter,
  classes,
  onSubmit,
  facilities = EMPTY_LIST,
  organizations = EMPTY_LIST,
}) => {
  const initialValues = useMemo(
    () => ({
      [Filters.FACILITY_ID]: '',
      [Filters.TEXT_SEARCH]: '',
      ...filter,
    }),
    [filter],
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <AutoSaveFormik timeout={1000} shouldSubmit={shouldSubmit}>
        <Grid container className={classes.root}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container xs spacing={2} wrap="nowrap">
              <Grid xs item>
                <Field
                  name={Filters.TEXT_SEARCH}
                  component={SearchTextFieldFormik}
                />
              </Grid>
              <Grid xs item>
                <Field
                  select
                  margin="none"
                  label="Status"
                  name={Filters.IS_ONLINE}
                  component={TextFieldFormik}
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="">
                    <i>All</i>
                  </MenuItem>
                  <MenuItem value={true}>Online</MenuItem>
                  <MenuItem value={false}>Offline</MenuItem>
                </Field>
              </Grid>
              <Grid xs item>
                <SelectTextFieldFormik
                  label="Client"
                  margin="none"
                  items={organizations}
                  name={Filters.ORGANIZATION_ID}
                  getItemValue={(organization) => organization.get('id')}
                  getItemName={(organization) => organization.get('name')}
                />
              </Grid>
              <Grid xs item>
                <SelectTextFieldFormik
                  margin="none"
                  label="Facility"
                  items={facilities}
                  name={Filters.FACILITY_ID}
                  getItemValue={(facility) => facility.get('id')}
                  getItemName={(facility) => facility.get('name')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AutoSaveFormik>
    </Formik>
  );
};

const styles = {
  root: {
    paddingTop: 15,
    marginBottom: 15,
  },
};

export default compose(withStyles(styles))(CheetahJobFilter);
