import { useMemo, useCallback, useState } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { Table, TableRowActionsCell } from 'altus-ui-components';
import { testResultIcons } from 'features/equipment/assets/components/AssetHistoryTableRowDetails';
import GreenIcon from 'assets/Green.svg';
import { useToggle } from 'altus-hooks';
import MobileStringVerificationDrawerContainer from 'features/mobile/projects/execute/taskToolstring/MobileStringVerificationDrawerContainer';
import { EMPTY_LIST } from 'app/app.constants';

const handleResultIcon = (testResult) => {
  return testResultIcons[testResult] || GreenIcon;
};

const MobileStringVerificationList = ({
  classes,
  stringVerificationResults,
  toolstringTools = EMPTY_LIST,
}) => {
  const [isDrawerOpen, toggleDrawerOpen] = useToggle(false);
  const [selectedResult, setSelectedResult] = useState(EMPTY_LIST);

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 2,
        Cell: ({ row }) => {
          const result = row.original;
          return (
            <TableRowActionsCell>
              <img
                src={handleResultIcon(
                  result.getIn(['stringTestResult', 'result']),
                )}
                alt="Status icon"
                style={{ marginRight: '8px' }}
              />
            </TableRowActionsCell>
          );
        },
      },
    ],
    [],
  );

  const createRow = (result) => (
    <Grid
      container
      onClick={() => {
        setSelectedResult(result);
        toggleDrawerOpen();
      }}
    >
      <Grid item xs={12} className={classes.rowSize}>
        <Grid item xs={1}>
          <img
            src={handleResultIcon(result.getIn(['stringTestResult', 'result']))}
            alt="Status icon"
            style={{ marginRight: '8px' }}
          />
        </Grid>
        <Grid item xs={10} className={classes.content}>
          <Grid item xs={12}>
            <Typography className={classes.fontMedium} variant="body2">
              {`String Verification ${result.getIn([
                'stringTestResult',
                'sequence',
              ])}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <ChevronRightIcon />
        </Grid>
      </Grid>
    </Grid>
  );

  const renderTableRowComponent = useCallback(
    ({ item }) => {
      return createRow(item);
    },
    // eslint-disable-next-line
    [classes],
  );

  return (
    <>
      <MobileStringVerificationDrawerContainer
        open={isDrawerOpen}
        toggleOpen={toggleDrawerOpen}
        stringVerification={selectedResult}
        toolstringTools={toolstringTools}
      />
      <Table
        displayHeaders={false}
        items={stringVerificationResults}
        columns={columns}
        useGlobalFilter={false}
        TableRowComponent={renderTableRowComponent}
      />
    </>
  );
};

const styles = (theme) => {
  return {
    fontMedium: {
      fontSize: 'medium',
    },
    rowSize: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: `${theme.palette.primary.newLight}26`,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: theme.spacing(2),
    },
  };
};

export default withStyles(styles)(MobileStringVerificationList);
