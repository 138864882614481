import { fromJS } from 'immutable';

import mappers from 'mappers';
import { createSelector } from 'utils/app.util';

const initialProject = fromJS(mappers.Project.initial());

const getWellboreSectionSelector = (state) =>
  state.getIn(['entity', 'wellboreSection']);

const getProjectSelector = (state) => state.getIn(['entity', 'project']);

export const getProjectsFromState = createSelector(
  getProjectSelector,
  (projects) => projects,
);

export const getFacilitiesFromState = createSelector(
  (state) => state.getIn(['projects', 'data', 'data', 'facilities']),
  (facilities) => facilities,
);

export const getDepartmentsFromState = createSelector(
  (state) => state.getIn(['projects', 'data', 'data', 'departments']),
  (departments) => departments,
);

export const getProjectStatusesFromState = createSelector(
  (state) => state.getIn(['projects', 'data', 'data', 'statuses']),
  (statuses) => statuses,
);

export const getGanttProjectsFromState = createSelector(
  (state) => state.getIn(['entity', 'ganttProjects']),
  (statuses) => statuses,
);

export const getCurrentProject = createSelector(
  (state) => state.getIn(['projects', 'details', 'project']),
  (project) => project,
);

export const createProjectSelector = (projectId) =>
  createSelector(
    getProjectSelector,
    (projects) => projects.get(projectId?.toString()) ?? initialProject, // fallback to initialProject for now to not break anything
  );

export const getCurrentProjectIdFromState = createSelector(
  getCurrentProject,
  (project) => (project ? project.get('id') : undefined),
);

export const createProjectWellboreSectionsSelector = (projectId) =>
  createSelector(getWellboreSectionSelector, (wellboreSections) =>
    wellboreSections
      .filter((section) => section?.get('projectId'))
      .groupBy((section) => section.get('projectId').toString())
      .get(projectId.toString()),
  );

export const getProjectFluidsSelector = createSelector(
  (state) => state.getIn(['projects', 'details', 'fluids']),
  (fluids) => fluids,
);

export const getProjectRiskSectionSelector = (state) => {
  var task = state.getIn(['projects', 'details', 'project', 'projectRisk']);
  return task;
};

export const createProjectRiskSectionsSelector = () =>
  createSelector(getProjectRiskSectionSelector, (sections) => {
    return sections;
  });

export const getTaskRiskSectionByProjectSelector = (state) => {
  var task = state.getIn(['projects', 'details', 'project', 'taskRisk']);
  return task;
};

export const createTaskRiskSectionByProjectSelector = () =>
  createSelector(getTaskRiskSectionByProjectSelector, (sections) => {
    return sections;
  });
