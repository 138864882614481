import { Grid, Typography } from '@material-ui/core';

import DocumentsFilterItem from 'features/projects/documents/components/DocumentsFilterItem';
import { useIsInternalCategoryToShow } from '../../../../app/hooks/useIsInternalCategoryToDisplay';

const DocumentsFilter = ({
  filter,
  setFilter,
  documents,
  documentCategories,
}) => {
  const isShowCategory = useIsInternalCategoryToShow();
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1">Filter by category</Typography>
      </Grid>
      {documentCategories
        .valueSeq()
        .filter((cat) => isShowCategory(cat))
        .map((category) => {
          const disabled = !documents
            .map((document) => document.get('category'))
            .includes(category.get('id'));

          return (
            <DocumentsFilterItem
              filter={filter}
              disabled={disabled}
              category={category}
              setFilter={setFilter}
              key={category.get('id')}
            />
          );
        })}
    </Grid>
  );
};

export default DocumentsFilter;
