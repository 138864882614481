import React from 'react';

import { styled } from '@material-ui/styles';
import { SwipeableDrawer as Drawer } from '@material-ui/core';

const drawerBleeding = 0;
const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.mobile.Drawer.background.dark,
}));

const Puller = styled('div')(({ theme }) => ({
  width: 130,
  height: '0.5rem',
  backgroundColor: theme.mobile.Drawer.puller.main,
  borderRadius: 5,
  position: 'absolute',
  top: 12,
  left: 'calc(50% - 65px)',
}));

const MobileDrawerBase = ({ children, open, toggleOpen }) => {
  return (
    <Drawer
      open={open}
      anchor="bottom"
      onClose={() => toggleOpen(false)}
      disableSwipeToOpen={false}
      variant="temporary"
      ModalProps={{
        keepMounted: false,
      }}
      PaperProps={{
        square: false,
        style: {
          overflow: 'hidden',
          backgroundColor: 'transparent',
          borderRadius: '25px 25px 0 0',
        },
      }}
    >
      <StyledBox
        sx={{
          position: 'absolute',
          top: -drawerBleeding,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: 'visible',
          right: 0,
          left: 0,
        }}
      >
        <Puller />
      </StyledBox>
      <StyledBox>{children}</StyledBox>
    </Drawer>
  );
};

export default MobileDrawerBase;
