import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createSMLocation,
  updateSMLocation,
  deleteSMLocation,
  getAllSMlocations,
} from 'features/data-exchange/winch/smlocation.actions';

import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { SMLOCATION_CONFIG_ACTIONS } from 'features/data-exchange/winch/smlocation.constants';
import { getSMLocationsFromState } from 'features/data-exchange/winch/smlocation.selectors';

const useSMLocations = () => {
  const dispatch = useDispatch();

  const dispatchGetSMLocations = useCallback(
    () => dispatch(getAllSMlocations()),
    [dispatch],
  );

  const dispatchCreateSMLocation = useCallback(
    (smLocation) => dispatch(createSMLocation(smLocation)),
    [dispatch],
  );

  const dispatchUpdateSMLocation = useCallback(
    (smLocation) => dispatch(updateSMLocation(smLocation)),
    [dispatch],
  );

  const dispatchDeleteSMLocation = useCallback(
    (smLocationId) => dispatch(deleteSMLocation(smLocationId)),
    [dispatch],
  );

  const dataState = useSelector((state) =>
    getSummarizedDataStateFromState(
      state,
      SMLOCATION_CONFIG_ACTIONS.GET_SMLOCATIONS,
    ),
  );

  const smLocations = useSelector((state) => getSMLocationsFromState(state));

  return {
    dataState,
    smLocations,
    getSMLocations: dispatchGetSMLocations,
    createSMLocation: dispatchCreateSMLocation,
    updateSMLocation: dispatchUpdateSMLocation,
    deleteSMLocation: dispatchDeleteSMLocation,
  };
};

export default useSMLocations;
