import DashboardLineChart from 'features/projects/dashboard/components/charts/DashboardLineChart';
import useProjectDashboardHubConnection from 'features/projects/dashboard/hooks/useProjectDashboardHubConnection';
import DashboardGaugeChartsContainer from 'features/projects/dashboard/components/charts/DashboardGaugeChartsContainer';
import ProjectExecutionStatusContainer from 'features/projects/dashboard/components/ProjectExecutionStatusContainer/ProjectExecutionStatusContainer';

const WinchLineAndGaugeDashboardController = ({
  projectId,
  renderSlots,
  projectDashboardId,
}) => {
  const { curves, setExtremes, registerDataPointsHandler } =
    useProjectDashboardHubConnection(projectId, projectDashboardId);

  return renderSlots(
    <DashboardLineChart
      curves={curves}
      onSetExtremes={setExtremes}
      registerDataPointsHandler={registerDataPointsHandler}
    />,
    <DashboardGaugeChartsContainer
      curves={curves}
      registerDataPointsHandler={registerDataPointsHandler}
    />,
    <ProjectExecutionStatusContainer projectId={projectId} />,
  );
};

export default WinchLineAndGaugeDashboardController;
