import { Field } from 'formik';
import classNames from 'classnames';
import Close from '@material-ui/icons/Close';
import { useCallback, useMemo } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Table, TableRowActionsCell } from 'altus-ui-components';
import { Box, Grid, Button, IconButton } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { useToggle } from 'altus-hooks';

import { EMPTY_MAP } from 'app/app.constants';
import { required } from 'utils/validation.util';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import CreateSimulationParameterRow from 'features/projects/tasks/task/simulation/parameters/components/CreateSimulationParameterRow';

const SimulationParametersTable = ({
  classes,
  disabled,
  direction,
  noItemsMessage,
  createSimulationParameter,
  deleteSimulationParameter,
  updateSimulationParameter,
  defaultSimulationParameter,
  simulationParameters = EMPTY_MAP,
}) => {
  const [isCreateMode, toggleCreateMode] = useToggle();

  const lengthUnit = defaultSimulationParameter.getIn(['endDepth', 'unit']);
  const forceUnit = defaultSimulationParameter.getIn(['tractorForce', 'unit']);

  const columns = useMemo(
    () => [
      {
        xs: 3,
        id: 'startDepth',
        Header: (
          <QuantityTableHeaderTitleCell title="Start Depth" unit={lengthUnit} />
        ),
        accessor: (_parameter, index, _row, rows) => {
          // display the previous endDepth as the startDepth of this parameter
          var previousParameter = rows[index - 1];

          return (
            previousParameter?.original.getIn(['endDepth', 'roundedValue']) ?? 0
          );
        },
      },
      {
        xs: 3,
        id: 'endDepth',
        Header: (
          <QuantityTableHeaderTitleCell title="End Depth" unit={lengthUnit} />
        ),
        accessor: (parameter) => parameter.get(['endDepth', 'roundedValue']),
        Cell: () => (
          <Field
            type="number"
            name="endDepth"
            validate={required}
            disabled={disabled}
            component={TableRowTextFieldFormik}
            TextFieldComponent={QuantityTextFieldFormik}
            InputProps={{
              endAdornment: null,
            }}
          />
        ),
      },
      {
        xs: 3,
        id: 'cableSpeed',
        Header: (
          <QuantityTableHeaderTitleCell
            title="Cable Speed"
            unit={defaultSimulationParameter.getIn(['cableSpeed', 'unit'])}
          />
        ),
        accessor: (parameter) => parameter.get(['cableSpeed', 'roundedValue']),
        Cell: () => (
          <Field
            type="number"
            name="cableSpeed"
            validate={required}
            disabled={disabled}
            component={TableRowTextFieldFormik}
            TextFieldComponent={QuantityTextFieldFormik}
            InputProps={{
              endAdornment: null,
            }}
          />
        ),
      },
      {
        xs: 3,
        id: 'wellheadPressure',
        Header: (
          <QuantityTableHeaderTitleCell
            title="Wellhead Pressure"
            unit={defaultSimulationParameter.getIn([
              'wellheadPressure',
              'unit',
            ])}
          />
        ),
        accessor: (parameter) =>
          parameter.get(['wellheadPressure', 'roundedValue']),
        Cell: () => (
          <Field
            type="number"
            validate={required}
            name="wellheadPressure"
            disabled={disabled}
            component={TableRowTextFieldFormik}
            TextFieldComponent={QuantityTextFieldFormik}
            InputProps={{
              endAdornment: null,
            }}
          />
        ),
      },
      {
        xs: 3,
        id: 'liquidRate',
        Header: (
          <QuantityTableHeaderTitleCell
            title="Liquid Rate"
            unit={defaultSimulationParameter.getIn(['liquidRate', 'unit'])}
          />
        ),
        accessor: (parameter) => parameter.get(['liquidRate', 'roundedValue']),
        Cell: () => (
          <Field
            type="number"
            name="liquidRate"
            validate={required}
            disabled={disabled}
            component={TableRowTextFieldFormik}
            TextFieldComponent={QuantityTextFieldFormik}
            InputProps={{
              endAdornment: null,
            }}
          />
        ),
      },
      {
        xs: 3,
        id: 'gasRate',
        Header: (
          <QuantityTableHeaderTitleCell
            title="Gas Rate"
            unit={defaultSimulationParameter.getIn(['gasRate', 'unit'])}
          />
        ),
        accessor: (parameter) => parameter.get(['gasRate', 'roundedValue']),
        Cell: () => (
          <Field
            type="number"
            name="gasRate"
            validate={required}
            disabled={disabled}
            component={TableRowTextFieldFormik}
            TextFieldComponent={QuantityTextFieldFormik}
            InputProps={{
              endAdornment: null,
            }}
          />
        ),
      },
      {
        xs: 3,
        id: 'tractorForce',
        Header: (
          <QuantityTableHeaderTitleCell
            title="Tractor Force"
            unit={forceUnit}
          />
        ),
        accessor: (parameter) =>
          parameter.get(['tractorForce', 'roundedValue']),
        Cell: () => (
          <Field
            type="number"
            name="tractorForce"
            validate={required}
            disabled={disabled}
            component={TableRowTextFieldFormik}
            TextFieldComponent={QuantityTextFieldFormik}
            InputProps={{
              endAdornment: null,
            }}
          />
        ),
      },
      {
        xs: 3,
        id: 'axialForceOnEnd',
        Header: (
          <QuantityTableHeaderTitleCell
            title="Axial Force On End"
            unit={forceUnit}
          />
        ),
        accessor: (parameter) =>
          parameter.get(['axialForceOnEnd', 'roundedValue']),
        Cell: () => (
          <Field
            type="number"
            name="axialForceOnEnd"
            validate={required}
            disabled={disabled}
            component={TableRowTextFieldFormik}
            TextFieldComponent={QuantityTextFieldFormik}
            InputProps={{
              endAdornment: null,
            }}
          />
        ),
      },
      {
        id: 'actions',
        Header: <TableRowActionsCell minItems={2} />,
        Footer: <TableRowActionsCell minItems={2} />,
        Cell: ({ row }) => {
          if (row.index === 0) {
            return <TableRowActionsCell minItems={2} />;
          }

          return (
            <TableRowActionsCell minItems={2}>
              <IconButton
                title="Delete"
                onClick={() =>
                  deleteSimulationParameter(
                    row.original.get('simulationParameterId'),
                  )
                }
              >
                <Close fontSize="small" />
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
    ],
    [
      disabled,
      forceUnit,
      lengthUnit,
      deleteSimulationParameter,
      defaultSimulationParameter,
    ],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <EditableTableRowFormik {...props} onSubmit={updateSimulationParameter} />
    ),
    [updateSimulationParameter],
  );

  return (
    <>
      <Grid
        className={classNames({
          [classes.disabled]: disabled,
        })}
      >
        <Table
          disableSortBy
          columns={columns}
          useGlobalFilter={false}
          items={simulationParameters}
          noItemsMessage={noItemsMessage}
          displayNoItemsMessage={!isCreateMode}
          TableRowComponent={renderTableRowComponent}
        />
      </Grid>
      <CreateSimulationParameterRow
        columns={columns}
        direction={direction}
        isCreateMode={isCreateMode}
        toggleCreateMode={toggleCreateMode}
        simulationParameters={simulationParameters}
        createSimulationParameter={createSimulationParameter}
      />
      <Grid container justifyContent="flex-end">
        <Box p={1}>
          <Button
            size="small"
            disabled={isCreateMode}
            onClick={toggleCreateMode}
          >
            <Box component={AddCircleOutlineIcon} marginRight={0.5} />
            Add
          </Button>
        </Box>
      </Grid>
    </>
  );
};

const styles = () => ({
  disabled: {
    opacity: 0.3,
  },
});

export default withStyles(styles)(SimulationParametersTable);
