import { compose } from 'redux';
import { List } from 'immutable';
import { fromJS } from 'immutable';
import { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import TaskToolstringPreview from 'features/projects/tasks/task/toolstring/components/TaskToolstringPreview';
import EquipmentTemplatesCreatorTable from 'features/equipment/equipmentTemplates/addEquipmentTemplates/EquipmentTemplatesCreatorTable';
import ToolstringItemHighlightProvider from 'features/projects/tasks/task/toolstring/components/ToolstringItemHighlightProvider';
import AddEquipmentTemplatesInformationContainer from 'features/equipment/equipmentTemplates/addEquipmentTemplates/AddEquipmentTemplatesInformationContainer';

const EquipmentTemplatesCreator = ({
  classes,
  selectedTools,
  onSortItem,
  onDeleteItem,
  onDuplicateItem,
  toggleAddEquipmentToEquipmentTemplateModal,
  setEquipmentTemplateInfo,
  equipmentTemplateInfo,
}) => {
  const createToolstringSections = useCallback((selectedTools) => {
    if (selectedTools.length > 0) {
      const length = 1;
      const items = List(selectedTools).toJS();
      const obj = {
        length,
        items,
      };
      return [obj];
    }
  }, []);

  return (
    <Grid container className={classes.container}>
      <ToolstringItemHighlightProvider>
        <Grid item container xs={12} className={classes.content}>
          <Grid item container xs={2} className={classes.toolstringPreview}>
            <TaskToolstringPreview
              editable={false}
              toolAssemblyCreator={true}
              toolstringSections={createToolstringSections(selectedTools)}
            />
          </Grid>
          <Grid item container xs={8}>
            <EquipmentTemplatesCreatorTable
              onSortItem={onSortItem}
              onDeleteItem={onDeleteItem}
              onDuplicateItem={onDuplicateItem}
              templateEquipments={fromJS(selectedTools)}
            />
          </Grid>
          <Grid item container xs={2} className={classes.information}>
            <AddEquipmentTemplatesInformationContainer
              toggleAddEquipmentToEquipmentTemplateModal={
                toggleAddEquipmentToEquipmentTemplateModal
              }
              setEquipmentTemplateInfo={setEquipmentTemplateInfo}
              equipmentTemplateInfo={equipmentTemplateInfo}
            />
          </Grid>
        </Grid>
      </ToolstringItemHighlightProvider>
    </Grid>
  );
};

const styles = (theme) => ({
  toolstringPreview: {
    height: '100%',
  },
  container: {
    height: '100%',
  },
  content: {
    height: '100%',
  },
  header: {
    height: '10%',
  },
  information: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(EquipmentTemplatesCreator);
