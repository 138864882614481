import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';

import LoadingButton from 'app/components/LoadingButton';
import { getActionDataStateFromState } from 'app/app.selectors';
import { startOperationInitiate } from 'features/projects/activities/activities.actions';
import { ACTIVITIES_ACTIONS } from 'features/projects/activities/activities.constants';

const styles = (theme) => ({
  startOperation: {
    marginTop: 30,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  positiveButton: {
    color: theme.palette.tertiary.contrastText,
    background: theme.palette.tertiary.main,
    '&:hover': {
      background: theme.palette.tertiary.dark,
    },
  },
});

const StartOperation = ({
  classes,
  dataState,
  projectId,
  taskId,
  dispatchStartOperation,
}) => {
  return (
    <Grid item className={classes.startOperation}>
      <Grid container justifyContent="center">
        <Grid item>
          <LoadingButton
            variant="contained"
            className={classes.positiveButton}
            onClick={() => dispatchStartOperation(projectId, taskId)}
            dataState={dataState}
          >
            <PlayCircleFilled className={classes.leftIcon} />
            Start operation
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default connect(
  (state) => ({
    dataState: getActionDataStateFromState(
      state,
      ACTIVITIES_ACTIONS.START_OPERATION,
    ),
  }),
  {
    dispatchStartOperation: startOperationInitiate,
  },
)(withStyles(styles)(StartOperation));
