import { fromJS } from 'immutable';

import {
  getInitials,
  getAvatar,
  editorStateToString,
  stringToEditorState,
} from 'utils/app.util';

const lesson = () => {
  return {
    id: null,
    title: null,
    description: null,
    recommended: { actions: null },
    author: { id: null },
    created: null,
    modified: null,
  };
};

const lessonFromDTO = ({
  lessonId,
  title,
  description,
  recommendedActions,
  createdByUserId,
  createdByUserFullName,
  createdByUserAvatar,
  created,
  modified,
}) => {
  return fromJS({
    id: lessonId,
    title: title,
    description: description ? stringToEditorState(description) : null,
    recommended: {
      actions: recommendedActions
        ? stringToEditorState(recommendedActions)
        : null,
    },
    author: {
      id: createdByUserId,
      name: createdByUserFullName,
      initials: getInitials(createdByUserFullName),
      avatar: getAvatar(createdByUserAvatar),
    },
    created: created,
    modified: modified,
  });
};

const lessonToDTO = ({ title, description, recommended }) => {
  return {
    title: title,
    description: description ? editorStateToString(description) : null,
    recommendedActions: recommended.actions
      ? editorStateToString(recommended.actions)
      : null,
  };
};

const lessonMappers = {
  Lesson: {
    initial: lesson,
    from: lessonFromDTO,
    to: lessonToDTO,
  },
};

export default lessonMappers;
