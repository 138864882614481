import { KeyboardDateTimePicker } from '@material-ui/pickers';

import { Format } from 'app/app.constants';

const DateTimePickerField = ({
  meta,
  input,
  margin = 'normal',
  DateTimePickerProps,
  format = Format.time,
  inputVariant = 'standard',
}) => {
  const { onChange, value } = input;

  return (
    <KeyboardDateTimePicker
      autoOk
      fullWidth
      ampm={false}
      value={value}
      format={format}
      margin={margin}
      onChange={onChange}
      inputVariant={inputVariant}
      helperText={meta.error ? meta.error : format}
      FormHelperTextProps={{
        error: !!meta.error,
      }}
      {...DateTimePickerProps}
    />
  );
};

export default DateTimePickerField;
