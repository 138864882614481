export const ADD_TEAM_MEMBERS_MODAL_ID = 'ADD_TEAM_MEMBERS_MODAL_ID';

export const TEAM_ACTIONS = {
  GET_PROJECT_MEMBER: 'GET_PROJECT_MEMBER',
  ADD_PROJECT_MEMBERS: 'ADD_PROJECT_MEMBERS',
  DELETE_PROJECT_MEMBER: 'DELETE_PROJECT_MEMBER',
  GET_ALL_PROJECT_MEMBERS: 'GET_ALL_PROJECT_MEMBERS',
  GET_ALL_AVAILABLE_USERS: 'GET_ALL_AVAILABLE_USERS',
  PROJECT_MEMBER_PAGE_LOADED: 'PROJECT_MEMBER_PAGE_LOADED',
  PROJECT_TEAM_PAGE_UNLOADED: 'PROJECT_TEAM_PAGE_UNLOADED',
  ASSIGN_PROJECT_MEMBER_TO_ROLE: 'ASSIGN_PROJECT_MEMBER_TO_ROLE',
  RECEIVE_PROJECT_MEMBER: 'RECEIVE_PROJECT_MEMBER',
  RECEIVE_PROJECT_MEMBERS: 'RECEIVE_PROJECT_MEMBERS',
  GET_PROJECT_FIELD_CREW: 'GET_PROJECT_FIELD_CREW',
  GET_PROJECT_FIELD_MEMBER_CREWS: 'GET_PROJECT_FIELD_MEMBER_CREWS',
  RECEIVE_PROJECT_FIELD_CREW: 'RECEIVE_PROJECT_FIELD_CREW',
  RECEIVE_PROJECT_FIELD_CREWS: 'RECEIVE_PROJECT_FIELD_CREWS',
  GET_ALL_AVAILABLE_FIELD_CREW_USERS: 'GET_ALL_AVAILABLE_FIELD_CREW_USERS',
  DELETE_PROJECT_FIELD_CREW: 'DELETE_PROJECT_FIELD_CREW',
  GET_PROJECT_FIELD_QUALIFICATIONS: 'GET_PROJECT_FIELD_QUALIFICATIONS',
  GET_PROJECT_FIELD_CREWS: 'GET_PROJECT_FIELD_CREWS',
  UPDATE_PROJECT_FIELD_CREW: 'UPDATE_PROJECT_FIELD_CREW',
  PROJECT_FIELD_CREW_PAGE_LOADED: 'PROJECT_FIELD_CREW_PAGE_LOADED',
  ADD_PROJECT_FIELD_CREW: 'ADD_PROJECT_FIELD_CREW',
  ASSIGN_PROJECT_FIELD_CREW_TO_ROLE: 'ASSIGN_PROJECT_FIELD_CREW_TO_ROLE',
  GET_TEAM_PROJECT_ROLES: 'GET_TEAM_PROJECT_ROLES',
  RECEIVE_TEAM_PROJECT_ROLES: 'RECEIVE_TEAM_PROJECT_ROLES',
  UPDATE_MEMBER_TEAM_PROJECT_ROLE: 'UPDATE_MEMBER_TEAM_PROJECT_ROLE',
};

export const TEAM_ACTIVE_TAB = {
  ONSHORE_TEAM: 'ONSHORE_TEAM',
  FIELD_CREW: 'FIELD_CREW',
};

/**ENUMS */
export const ShiftType = {
  SUB_SURFACE: 0,
  SURFACE: 1,
};
