import { lighten } from '@material-ui/core/styles';

export const styles = (theme) => {
  const background = lighten(
    theme.altus.components.SortableListRow.background,
    0.05,
  );

  return {
    underline: {
      '&::before': {
        display: 'none',
      },
    },
    input: {
      '&:focus': {
        background,
      },
      '&:hover': {
        background,
      },
      '&:disabled': {
        background: 'initial',
      },
      background,
      padding: 10,
      ...theme.typography.body2,
    },
    error: {
      '& $input': {
        background,
      },
    },
  };
};
