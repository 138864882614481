import { Typography } from '@material-ui/core';

import makeStyles from '@material-ui/styles/makeStyles';

import { projectStatusToString } from 'features/projects/projects.mappers';

const ProjectStatusText = ({ status }) => {
  const classes = useStyles(status);

  return (
    <Typography variant="subtitle1" className={classes.root}>
      {projectStatusToString(status)}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  root: (status) => ({
    color: theme.palette.status[status],
  }),
}));

export default ProjectStatusText;
