import { Set } from 'immutable';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import isFunction from 'lodash/isFunction';

import { EMPTY_SET } from 'app/app.constants';
import { invokeIfFunction } from 'utils/app.util';
import { usePermissions } from 'app/hooks/authorization/usePermissions';

const HasPermission = ({
  render,
  children,
  permissions = EMPTY_SET,
  userPermissions = EMPTY_SET,
}) => {
  const renderContent = useCallback(
    (hasPermission) => {
      if (render) return invokeIfFunction(render, hasPermission);

      if (isFunction(children)) return children(hasPermission);

      if (!hasPermission) return null;

      if (children) return children;

      return null;
    },
    [render, children],
  );

  const { hasPermission } = usePermissions(userPermissions, permissions);

  return renderContent(hasPermission);
};

HasPermission.propTypes = {
  render: PropTypes.func,
  userPermissions: PropTypes.instanceOf(Set).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  permissions: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Set),
    PropTypes.arrayOf(PropTypes.number),
  ]),
};

export default compose()(HasPermission);
