import { useCallback } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import TableRowExpandable from 'app/components/TableRowExpandable';
import EditorStateViewer from 'app/components/EditorStateViewer';

const IncidentTableRow = ({ item, ...rest }) => {
  const renderDetails = useCallback(
    () => (
      <Grid container component={Box} paddingLeft={3} paddingRight={3}>
        <Grid container component={Box} paddingTop={3} paddingBottom={3}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Description:</Typography>
          </Grid>
          <Grid item xs={12}>
            {item.get('description') ? (
              <EditorStateViewer editorState={item.get('description')} />
            ) : (
              <Typography variant="caption" color="textSecondary">
                <i>N/A</i>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    ),
    [item],
  );

  return (
    <TableRowExpandable
      {...rest}
      renderDetails={renderDetails}
      hasLightBackground={true}
    />
  );
};

export default IncidentTableRow;
