import moment from 'moment';
import { isImmutable } from 'immutable';

import { durationFromStartAndEndTime } from 'utils/app.util';

export const getTotalElapsedTime = (timers) => {
  return timers.reduce((sum, timer) => {
    const { startTime, endTime } = isImmutable(timer) ? timer.toJS() : timer;

    const duration = endTime
      ? durationFromStartAndEndTime(startTime, endTime)
      : durationFromStartAndEndTime(startTime);

    return sum.add(duration);
  }, moment.duration());
};

export const getTotalElapsedTimeFromDurations = (timers) => {
  return timers.reduce((sum, timer) => {
    const { duration } = isImmutable(timer) ? timer.toJS() : timer;

    return sum.add(duration);
  }, moment.duration());
};

export const ProjectExecutionHub = {
  RegisterOnProject: 'RegisterOnProject',
  RelayProjectChange: 'RelayProjectChange',
  SignalProjectReload: 'SignalProjectReload',
};
