import { useMemo } from 'react';

import { RouteContainer } from 'altus-ui-components';

import routePaths from 'app/routePaths';
import WellboreHeader from 'features/wells/WellboreHeader';
import TaskHeader from 'features/projects/tasks/task/TaskHeader';
import OperationsHeader from 'features/operations/OperationsHeader';
import ProjectHeader from 'features/projects/components/ProjectHeader';
import FacilityHeader from 'features/facilities/components/FacilityHeader';
import EquipmentDetailsHeader from 'features/equipment/details/EquipmentDetailsHeader';
import HomeApplicationHeader from 'app/components/ApplicationHeader/HomeApplicationHeader';
import DefaultApplicationHeader from 'app/components/ApplicationHeader/DefaultApplicationHeader';

const ApplicationHeaderContainer = () => {
  const routes = useMemo(
    () => [
      {
        path: routePaths.plan.task.match,
        component: TaskHeader,
      },
      {
        path: routePaths.project,
        component: ProjectHeader,
      },
      {
        path: routePaths.wells.index,
        component: WellboreHeader,
      },
      {
        path: routePaths.facilities.index,
        component: FacilityHeader,
      },
      {
        path: routePaths.equipment.index,
        component: EquipmentDetailsHeader,
      },
      {
        path: routePaths.login,
        component: null,
      },
      {
        path: [routePaths.operations.dashboards],
        component: OperationsHeader,
      },
      {
        path: [
          routePaths.projects,
          routePaths.wells.root,
          routePaths.operations.root,
          routePaths.facilities.root,
          routePaths.equipment.root,
        ],
        component: HomeApplicationHeader,
      },
    ],
    [],
  );

  return (
    <RouteContainer
      routes={routes}
      MissingRouteComponent={DefaultApplicationHeader}
    />
  );
};

export default ApplicationHeaderContainer;
