import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import Settings from '@material-ui/icons/Settings';
import { MobileView, BrowserView, isDesktop } from 'react-device-detect';

import { Logo } from 'altus-ui-components';

import { getApplicationHeaderFromState } from 'app/app.selectors';

import routePaths from 'app/routePaths';
import { useHeader } from 'app/hooks/useHeader';
import { SystemPermission } from 'app/app.constants';
import { useCurrentUser } from 'app/hooks/useCurrentUser';
import MenuIcon from 'app/components/ApplicationHeader/MenuIcon';
import HasSystemPermission from 'app/components/HasSystemPermission';
import LogoAndAppTitle from 'app/components/ApplicationHeader/LogoAndAppTitle';
import UserMenuContainer from 'app/components/ApplicationHeader/UserMenuContainer';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import ApplicationHeaderTitle from 'app/components/ApplicationHeader/ApplicationHeaderTitle';
import ApplicationHeaderBackButton from 'app/components/ApplicationHeader/ApplicationHeaderBackButton';

const OperationsHeader = () => {
  const currentUser = useCurrentUser();
  const applicationHeader = useSelector(getApplicationHeaderFromState);

  const logoNavigation = applicationHeader.get('logoNavigation');

  useHeader({
    logoNavigation: routePaths.operations.root,
    title: isDesktop && currentUser?.get('organizationName'),
  });

  return (
    <ApplicationHeaderBase>
      <Grid
        container
        spacing={2}
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs>
          <BrowserView renderWithFragment>
            <LogoAndAppTitle to={logoNavigation} />
          </BrowserView>
          <MobileView renderWithFragment>
            <Grid item container align="flex-start" alignItems="center">
              <Switch>
                <Route exact path={routePaths.operations.root}>
                  <Logo to={null} />
                </Route>
                <Route component={ApplicationHeaderBackButton} />
              </Switch>
            </Grid>
          </MobileView>
        </Grid>
        <Grid item xs={8}>
          <ApplicationHeaderTitle />
        </Grid>
        <Grid xs item>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <BrowserView>
                <HasSystemPermission
                  permissions={[
                    SystemPermission.MANAGE_USER_ACCESS,
                    SystemPermission.MANAGE_SYSTEM_ROLES,
                    SystemPermission.MANAGE_PROJECT_ROLES,
                  ]}
                >
                  <MenuIcon
                    title="Settings"
                    Icon={<Settings />}
                    to={routePaths.settings.root}
                  />
                </HasSystemPermission>
              </BrowserView>
            </Grid>
            <Grid item>
              <Route
                exact
                path={routePaths.operations.root}
                render={({ match }) =>
                  match || isDesktop ? <UserMenuContainer /> : null
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ApplicationHeaderBase>
  );
};

export default OperationsHeader;
