import CloudOff from '@material-ui/icons/CloudOff';

import { NoContentBasePage } from 'altus-ui-components';

import OperationsTableView from 'features/operations/OperationsTableView';
import OperationsCardsView from 'features/operations/OperationsCardsView';
import { OPERATIONS_VIEWS } from 'features/operations/operations.constants';

const OperationsViews = ({
  operations,
  dataState,
  selectedView,
  scrollContainerRef,
}) => {
  if (!operations.size && dataState.isSuccess()) {
    return (
      <NoContentBasePage
        Icon={CloudOff}
        header="No Live Operations"
        description="No live operations found. Please try again later or change your search"
      />
    );
  }
  return (
    <>
      {selectedView === OPERATIONS_VIEWS.TILES && (
        <OperationsCardsView
          operations={operations}
          scrollContainerRef={scrollContainerRef}
        />
      )}
      {selectedView === OPERATIONS_VIEWS.LIST && (
        <OperationsTableView operations={operations} />
      )}
    </>
  );
};

export default OperationsViews;
