import { useMemo } from 'react';

import { Table } from 'altus-ui-components';

import { formatValue } from 'utils/format.util';

const WellboreElementsTable = ({ wellboreElements }) => {
  const columns = useMemo(
    () => [
      {
        id: 'mdTop',
        xs: 2,
        accessor: (item) =>
          formatValue(item.getIn(['mdTop', 'value']), '', 0.01),
        Header: 'MD Top (m)',
      },
      {
        id: 'angle',
        xs: 2,
        accessor: (item) =>
          formatValue(item.getIn(['angle', 'value']), '', 0.01),
        Header: 'Angle (deg)',
      },
      {
        id: 'tvdTop',
        xs: 2,
        accessor: (item) =>
          formatValue(item.getIn(['tvdTop', 'value']), '', 0.01),
        Header: 'TVD Top (m)',
      },
      {
        id: 'length',
        xs: 2,
        accessor: (item) =>
          formatValue(item.getIn(['length', 'value']), '', 0.01),
        Header: 'Length (m)',
      },
      {
        id: 'maxOD',
        xs: 2,
        accessor: (item) =>
          formatValue(item.getIn(['maxOD', 'value']), '', 0.01),
        Header: 'Max OD (m)',
      },
      {
        id: 'minId',
        xs: 2,
        accessor: (item) =>
          formatValue(item.getIn(['minID', 'value']), '', 0.01),
        Header: 'Min ID (m)',
      },
      {
        id: 'driftId',
        xs: 2,
        accessor: (item) =>
          formatValue(item.getIn(['driftID', 'value']), '', 0.01),
        Header: 'Drift ID (m)',
      },
      {
        id: 'matlSpec',
        xs: 4,
        accessor: (item) => item.get('matlSpec'),
        Header: 'Matl. Spec.',
      },
      {
        id: 'description',
        xs: 4,
        accessor: (item) => item.get('description'),
        Header: 'Description',
      },
      {
        id: 'comments',
        xs: 4,
        accessor: (item) => item.get('comments'),
        Header: 'Comments',
      },
    ],
    [],
  );

  return wellboreElements ? (
    <Table
      stickyHeader
      disableSortBy
      columns={columns}
      useGlobalFilter={false}
      items={wellboreElements}
      noItemsMessage="No elements loaded yet..."
    />
  ) : null;
};
export default WellboreElementsTable;
