import withStyles from '@material-ui/styles/withStyles';
import { Typography, LinearProgress, Grid, Fade } from '@material-ui/core';

const SimulationLinearProgress = ({ classes, runSimulationDataState }) => {
  if (!runSimulationDataState.isLoading()) {
    return null;
  }

  return (
    <Fade in>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes.root}
      >
        <Grid container direction="column" alignItems="center">
          <Grid container item justifyContent="center">
            <Grid item xs={6}>
              <LinearProgress
                variant="indeterminate"
                className={classes.progress}
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography color="secondary" variant="caption">
              Simulating run... please wait
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
};

const styles = (theme) => ({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    zIndex: theme.zIndex.modal,
    color: theme.palette.common.white,
    background: `${theme.palette.common.black}0004d`,
  },
  progress: {
    height: theme.spacing(2),
  },
});

export default withStyles(styles)(SimulationLinearProgress);
