import { Field, Formik, Form } from 'formik';
import Close from '@material-ui/icons/Close';
import withStyles from '@material-ui/styles/withStyles';

import { Grid, IconButton, InputAdornment } from '@material-ui/core';

import { Button } from 'app/components/withTooltip';
import { SystemPermission } from 'app/app.constants';
import HasSystemPermission from 'app/components/HasSystemPermission';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';

export const Filters = {
  TEXT_SEARCH: 'textSearch',
  TOOL_GROUPS: 'toolGroupIds',
  SERIAL_SEARCH: 'serialSearch',
};

const shouldSubmit = ({ isValid }) => isValid;

const getKey = (item) => item.get('id');
const getName = (item) => item.get('name')?.toUpperCase();

const MwlToolsFilter = ({
  classes,
  onSubmit,
  toolGroups,
  initialValues,
  toggleAddToolModal,
}) => (
  <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues}>
    <Form>
      <AutoSaveFormik timeout={1000} shouldSubmit={shouldSubmit}>
        <Grid container className={classes.root}>
          <Grid container spacing={2} alignItems="center">
            <Grid item container xs spacing={2} wrap="nowrap">
              <Grid xs item>
                <Field name={Filters.TEXT_SEARCH}>
                  {({ form, ...formik }) => (
                    <TextFieldFormik
                      form={form}
                      margin="none"
                      label="Name"
                      placeholder="Search Tool..."
                      inputProps={{
                        maxLength: 100,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              title="Remove"
                              onClick={() =>
                                form.setFieldValue(
                                  Filters.TEXT_SEARCH,
                                  initialValues[Filters.TEXT_SEARCH],
                                )
                              }
                            >
                              <Close fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...formik}
                    />
                  )}
                </Field>
              </Grid>
              <Grid xs item>
                <Field name={Filters.SERIAL_SEARCH}>
                  {({ form, ...formik }) => (
                    <TextFieldFormik
                      form={form}
                      margin="none"
                      label="Serial number"
                      placeholder="Search Tool By Serial number..."
                      inputProps={{
                        maxLength: 100,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              title="Remove"
                              onClick={() =>
                                form.setFieldValue(
                                  Filters.SERIAL_SEARCH,
                                  initialValues[Filters.SERIAL_SEARCH],
                                )
                              }
                            >
                              <Close fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...formik}
                    />
                  )}
                </Field>
              </Grid>
              <Grid xs item>
                <SelectTextFieldFormik
                  getItemName={getName}
                  getItemValue={getKey}
                  margin="none"
                  label="Tool Group"
                  items={toolGroups}
                  name={Filters.TOOL_GROUPS}
                />
              </Grid>
              <Grid xs item>
                <HasSystemPermission
                  permissions={[SystemPermission.ITEM_TAB_EDIT]}
                >
                  <Grid item container>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.editBtn}
                      onClick={() => toggleAddToolModal(true)}
                    >
                      Add tool
                    </Button>
                  </Grid>
                </HasSystemPermission>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AutoSaveFormik>
    </Form>
  </Formik>
);

const styles = {
  root: {
    marginBottom: 15,
    paddingTop: 15,
  },
  leftIcon: {
    marginRight: 10,
  },
};

export default withStyles(styles)(MwlToolsFilter);
