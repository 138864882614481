import { fromJS } from 'immutable';
import { useCallback } from 'react';

import { useRequest } from 'altus-hooks';

import appService from 'services/app.service';

const useTaskFailReasons = () => {
  const getTaskFailReasons = useCallback(
    () => appService.getTaskFailReasons().then(fromJS),
    [],
  );

  return useRequest(getTaskFailReasons);
};

export default useTaskFailReasons;
