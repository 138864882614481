import { Map, OrderedMap, List } from 'immutable';

import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import documentMapper from 'features/projects/documents/documents.mappers';
import { DOCUMENTS_ACTIONS } from 'features/projects/documents/documents.constants';

const initialState = Map({
  documents: EMPTY_MAP,
  checklistsAndProcedures: EMPTY_LIST,
});

const documentsReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case DOCUMENTS_ACTIONS.RECEIVE_PROJECT_FILE: {
      if (error) return state;

      const { payload } = action;
      const newDocument = documentMapper.Document.from(payload);
      const newDocumentsMap = state
        .get('documents')
        .set(payload.fileId.toString(), newDocument);

      return state.set('documents', newDocumentsMap);
    }

    case DOCUMENTS_ACTIONS.DELETE_PROJECT_FILE: {
      if (error) return state;

      const { fileId } = action;
      const newDocumentsMap = state.get('documents').delete(fileId.toString());

      return state.set('documents', newDocumentsMap);
    }

    case DOCUMENTS_ACTIONS.RECEIVE_PROJECT_FILES: {
      if (error) return state;
      return state.setIn(
        ['documents'],
        OrderedMap(
          payload.map((file) => [
            file.fileId.toString(),
            documentMapper.Document.from(file),
          ]),
        ),
      );
    }

    case DOCUMENTS_ACTIONS.RECEIVE_CHECKLISTS_AND_PROCEDURES: {
      if (error) return state;

      return state.setIn(
        ['checklistsAndProcedures'],
        List(payload.map((file) => documentMapper.Checklist.from(file))),
      );
    }

    default:
      return state;
  }
};

export default documentsReducer;
