import { Map, List } from 'immutable';

import mappers from 'mappers';

const operationFromDto = ({ cheetahJobs = [], project, ...operation }) =>
  Map({
    project: mappers.Project.from(project),
    cheetahJobs: List(cheetahJobs.map(cheetahJobFromDto)),
    ...operation,
  });

const cheetahJobFromDto = ({ ...cheetahJob }) =>
  Map({
    ...cheetahJob,
  });

const operationsMappers = {
  Operation: {
    from: operationFromDto,
  },
  CheetahJob: {
    from: cheetahJobFromDto,
  },
};

export default operationsMappers;
