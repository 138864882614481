import { Map } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import simulationMappers from 'features/projects/tasks/task/simulation/simulation.mappers';
import { ACTIONS } from 'features/projects/tasks/task/simulation/dynamicToolParameters/simulationDynamicToolParameter.constants';

const initialState = EMPTY_MAP;

const simulationDynamicToolParameterReducer = (
  state = initialState,
  action,
) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.RECEIVE_SIMULATION_TOOL_PARAMETER: {
      if (error) return state;

      return state.set(
        payload.simulationDynamicToolParameterId.toString(),
        simulationMappers.SimulationDynamicToolParameter.from(payload),
      );
    }

    case ACTIONS.RECEIVE_SIMULATION_TOOL_PARAMETERS: {
      if (error) return state;

      return Map(
        payload.map((parameter) => [
          parameter.simulationDynamicToolParameterId.toString(),
          simulationMappers.SimulationDynamicToolParameter.from(parameter),
        ]),
      );
    }

    default:
      return state;
  }
};

export default simulationDynamicToolParameterReducer;
