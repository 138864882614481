import { memo } from 'react';
import { compose } from 'redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Form, Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';

import { Box, Grid, Button, Tooltip } from '@material-ui/core';

import { FavoriteButton, LoadingButton } from 'altus-ui-components';

import { ACTIONS } from 'features/projects/tasks/task/simulation/simulation.constants';

import EditableTitle from 'app/components/EditableTitle';
import { getActionDataStateFromState } from 'app/app.selectors';
import {
  EMPTY_MAP,
  WORK_ITEM_STATUS,
  ProjectPermission,
} from 'app/app.constants';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { requestRunSimulation } from 'features/projects/tasks/task/simulation/simulation.actions';
import { hasPlannedSimulationForTaskSelection } from 'features/projects/tasks/task/simulation/simulation.selectors';
import { PlannedIndicator } from 'features/projects/execution/components/PlannedIndicator';

const FormFields = {
  name: 'name',
};

const SimulationHeader = ({
  task,
  classes,
  actions = [],
  onNameChange,
  togglePlanned,
  toggleFavorite,
  simulation = EMPTY_MAP,
  isEnableSimulationRunButton = true,
}) => {
  const dispatch = useDispatch();
  const taskId = simulation.get('taskId');
  const projectId = simulation.get('projectId');
  const simulationId = simulation.get('simulationId');
  const isPlanned = simulation.get('isPlanned', false);
  const canResimulate = simulation.get('canResimulate', false);
  const runSimulationDataState = useSelector((state) =>
    getActionDataStateFromState(state, ACTIONS.REQUEST_RUN_SIMULATION),
  );
  const hasExistingPlanned = useSelector((state) =>
    taskId ? hasPlannedSimulationForTaskSelection(state, taskId) : false,
  );

  const taskStatus = task?.get('status');
  const disableEditing = taskStatus && taskStatus >= WORK_ITEM_STATUS.COMPLETED;

  return (
    <Grid
      item
      container
      padding={2}
      component={Box}
      alignItems="center"
      justifyContent="space-between"
    >
      <FavoriteButton
        fontSize="large"
        onClick={toggleFavorite}
        isFavorite={simulation.get('isFavorite')}
      />
      <Grid item xs>
        <Formik
          enableReinitialize
          initialValues={{
            [FormFields.name]: simulation.get('name') || '',
          }}
          onSubmit={onNameChange}
        >
          <Form>
            <AutoSaveFormik>
              <Field name={FormFields.name}>
                {({ field }) => (
                  <EditableTitle disabled={isPlanned} {...field} />
                )}
              </Field>
            </AutoSaveFormik>
          </Form>
        </Formik>
      </Grid>
      <Grid item>
        <PlannedIndicator
          simulation={simulation}
          onToggle={togglePlanned}
          hidden={simulation?.get('isUpdatedPlanned', false)}
          isDisabled={
            (!simulation?.get('isPlanned') && hasExistingPlanned) ||
            disableEditing ||
            !isEnableSimulationRunButton
          }
        />
      </Grid>
      {actions.map(
        ({ Icon, onClick, title, disabled, disabledTooltip }, index) => (
          <Tooltip
            key={index}
            title={disabled ? disabledTooltip || title : title}
          >
            <div>
              <Button
                key={index}
                onClick={onClick}
                disabled={disabled}
                startIcon={<Icon />}
                classes={{
                  label: classes.label,
                }}
              >
                {title}
              </Button>
            </div>
          </Tooltip>
        ),
      )}
      {isEnableSimulationRunButton && (
        <HasProjectPermission permissions={ProjectPermission.CREATE_SIMULATION}>
          <Tooltip
            title={
              !canResimulate ? 'Change parameters or unlock to resimulate' : ''
            }
          >
            <Grid item>
              <LoadingButton
                color="primary"
                variant="contained"
                disabled={!canResimulate}
                loading={runSimulationDataState.isLoading()}
                onClick={() =>
                  dispatch(
                    requestRunSimulation(projectId, taskId, simulationId),
                  )
                }
              >
                Simulate Run
              </LoadingButton>
            </Grid>
          </Tooltip>
        </HasProjectPermission>
      )}
    </Grid>
  );
};

const styles = (theme) => ({
  label: {
    // this will prevent uppercase on action labels
    textTransform: 'none',
  },
  checkedRadio: {
    '& svg:last-child': {
      color: theme.altus.components.SimulationDashboard.header.checked,
    },
  },
});

SimulationHeader.propTypes = {
  simulation: PropTypes.instanceOf(Map),
  toggleFavorite: PropTypes.func.isRequired,
};

export default compose(memo, withStyles(styles))(SimulationHeader);
