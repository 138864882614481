import React from 'react';
import { Typography } from '@material-ui/core';
import MobileProjectsListContainer from 'app/components/Mobile/MobileProjectsListContainer';
import MobileDrawerBase from 'app/components/Mobile/MobileDrawerBase';

const MobileProjectListDrawer = ({ projectsListOpen, toggleProjectsList }) => {
  return (
    <MobileDrawerBase open={projectsListOpen} toggleOpen={toggleProjectsList}>
      <Typography
        variant="h6"
        align="left"
        style={{ marginTop: '2rem', marginLeft: '1rem' }}
      >
        Select Project
      </Typography>
      <MobileProjectsListContainer
        isDrawer={true}
        toggleProjectsList={toggleProjectsList}
      />
    </MobileDrawerBase>
  );
};

export default MobileProjectListDrawer;
