import moment from 'moment';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import {
  renderContent,
  invokeIfFunction,
  durationFromStartAndEndTime,
  calcActivityDuration,
} from 'utils/app.util';

import { TIME_UNITS } from 'app/app.constants';

class Counter extends PureComponent {
  state = {
    elapsedTime: moment.duration(),
    incrementIntervalId: undefined,
  };

  clearCurrentInterval(callback) {
    const { incrementIntervalId } = this.state;

    clearInterval(incrementIntervalId);
    invokeIfFunction(callback);
  }

  setIncrementIntervalId = (incrementIntervalId) => {
    if (this._isMounted) {
      this.setState({
        incrementIntervalId,
      });
    }
  };

  startIncrementTimer() {
    const { unit, precision } = this.props;

    return setInterval(() => {
      const { elapsedTime } = this.state;

      if (this._isMounted) {
        this.setState({
          elapsedTime: moment.duration(elapsedTime).add(precision, unit),
        });
      }
    }, moment.duration({ [unit]: precision }).asMilliseconds());
  }

  componentDidMount() {
    this._isMounted = true;

    const {
      endTime,
      startTime,
      isTimerActive,
      initialDuration,
      calculateDurationFromStartAndEndtime,
      calculateDurationFromStartAndEndtimeFn,
    } = this.props;

    this.setState({
      elapsedTime: calculateDurationFromStartAndEndtime
        ? calculateDurationFromStartAndEndtimeFn(startTime, endTime)
        : initialDuration,
    });

    this.setIncrementIntervalId(
      isTimerActive ? this.startIncrementTimer() : undefined,
    );
  }

  componentDidUpdate(prevProps) {
    const {
      endTime,
      startTime,
      isTimerActive,
      initialDuration,
      calculateDurationFromStartAndEndtime,
      calculateDurationFromStartAndEndtimeFn,
    } = this.props;

    if (prevProps.startTime !== startTime || prevProps.endTime !== endTime) {
      this.setState(({ elapsedTime }) => ({
        elapsedTime: calculateDurationFromStartAndEndtime
          ? calculateDurationFromStartAndEndtimeFn(startTime, endTime)
          : elapsedTime,
      }));
    }

    // Start
    if (!prevProps.isTimerActive && isTimerActive) {
      this.setIncrementIntervalId(this.startIncrementTimer());
    }

    // Stop
    if (prevProps.isTimerActive && !isTimerActive) {
      this.clearCurrentInterval(this.setIncrementIntervalId);
      this.setState(({ elapsedTime }) => ({
        elapsedTime: calculateDurationFromStartAndEndtime
          ? elapsedTime
          : initialDuration,
      }));
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.clearCurrentInterval();
  }

  render() {
    const { renderValue } = this.props;
    const { elapsedTime } = this.state;

    return renderValue
      ? renderContent(renderValue, {
          elapsedTime: elapsedTime ? elapsedTime.clone().abs() : elapsedTime,
        })
      : null;
  }
}

Counter.propTypes = {
  renderValue: PropTypes.func.isRequired,
  unit: PropTypes.oneOf(Object.values(TIME_UNITS)),
  calculateDurationFromStartAndEndtime: PropTypes.bool,
};

Counter.defaultProps = {
  precision: 1,
  unit: TIME_UNITS.MINUTE,
  initialDuration: moment.duration(),
  calculateDurationFromStartAndEndtime: false,
};

const CounterFactory = (calcActivityDurationFn) => (props) => {
  const { waitCodesDuration, ...rest } = props;

  return (
    <Counter
      {...rest}
      calculateDurationFromStartAndEndtimeFn={calcActivityDurationFn(
        waitCodesDuration,
      )}
    />
  );
};

export default CounterFactory(() => durationFromStartAndEndTime);
export const ActivityDuration = CounterFactory(calcActivityDuration);
