import classNames from 'classnames';
import withStyles from '@material-ui/styles/withStyles';

import {
  InputLabel,
  FormControl,
  FormHelperText,
  TextareaAutosize,
} from '@material-ui/core';

const getHelperText = (value, maxLength, maxLengthWarning) => {
  if (value.length >= maxLengthWarning && value.length < maxLength)
    return `${maxLength - value.length} character(s) left`;
  else if (value.length === maxLength)
    return 'Max number of characters reached';
};

const TextAreaFieldV2 = ({
  meta,
  label,
  margin,
  classes,
  required,
  inputRef,
  maxLength,
  fullWidth,
  spellCheck,
  minRows,
  maxRows,
  maxLengthWarning,
  value,
  defaultValue,
  ...rest
}) => {
  const helperText = getHelperText(
    value || defaultValue || '',
    maxLength,
    maxLengthWarning,
  );

  return (
    <FormControl
      margin={margin}
      required={required}
      fullWidth={fullWidth}
      classes={{
        fullWidth: classes.formControlFullWidth,
      }}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <TextareaAutosize
        value={value}
        ref={inputRef}
        minRows={minRows}
        maxRows={maxRows}
        maxLength={maxLength}
        spellCheck={spellCheck}
        defaultValue={defaultValue}
        className={classNames(classes.root, {
          [classes.rootNoLabel]: !label,
        })}
        {...rest}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

TextAreaFieldV2.defaultProps = {
  minRows: 5,
  maxRows: 10,
  fullWidth: true,
  margin: 'normal',
  spellCheck: false,
};

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: 22,
    borderRadius: 3,
    fontSize: '1rem',
    resize: 'vertical',
    background: 'transparent',
    fontFamily: theme.typography.fontFamily,
    '&:disabled': {
      color: theme.palette.text.disabled,
    },
    '&:not(:disabled)': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.text.secondary,
    },
    '&:focus:not(:disabled)': {
      borderColor: theme.palette.text.secondary,
    },
    '&:active:not(:disabled)': {
      borderColor: theme.palette.text.secondary,
    },
  },
  rootNoLabel: {
    marginTop: 0,
  },
  formControlFullWidth: {
    minWidth: '100%',
  },
});

export default withStyles(styles)(TextAreaFieldV2);
