import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { replace } from 'connected-react-router/immutable';

import {
  DETAILS_ACTIONS,
  EDIT_PROJECT_FORM,
  EDIT_PROJECT_UNITS_MODAL_ID,
} from 'features/projects/details/details.constants';

import {
  getFormValueFromState,
  getFormValuesFromState,
} from 'app/app.selectors';

import {
  toggleModal,
  getAllServices,
  getAllDepartments,
} from 'app/app.actions';

import {
  updateProject,
  receiveProject,
  getProjectById,
} from 'features/projects/projects.actions';

import service from 'services/app.service';
import { projectsBase } from 'app/routePaths';
import projectService from 'services/project.service';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import { getAllTasks } from 'features/projects/activities/activities.actions';
import { RISK_TABLE_ACTIONS } from 'features/projects/tasks/task/details/taskDetails.constants';

export const submitUpdateProject = debounce((dispatch, getState) => {
  const state = getState();
  const form = state.get('form').toJS();
  const { values, initial } = form[EDIT_PROJECT_FORM.FORM_ID];
  const { id: projectId } = initial;
  const payload = Promise.resolve()
    .then(() => {
      const { services: _ignore1, ...initialNoServices } = initial;
      const { services: _ignore2, ...valuesNoServices } = values;
      if (!isEqual(initialNoServices, valuesNoServices)) {
        return dispatch(updateProject(projectId, values));
      } else {
        return service.getProjectById(projectId);
      }
    })
    .then((project) => dispatch(receiveProject(project)))
    .then(() => dispatch(getAllTasks(projectId)));

  dispatch({
    payload,
    type: DETAILS_ACTIONS.UPDATE_PROJECT,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Saving changes...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The project was successfully updated',
    },
  });

  return payload;
}, 1000);

export const updateProjectExternalId = (projectId) => (dispatch, getState) => {
  const externalId = getFormValueFromState(
    getState(),
    EDIT_PROJECT_FORM.FORM_ID,
    EDIT_PROJECT_FORM.EXTERNAL_ID,
  );

  const payload = projectService
    .updateProjectExternalId(projectId, externalId)
    .then((project) => dispatch(receiveProject(project)));

  dispatch({
    payload,
    type: DETAILS_ACTIONS.UPDATE_EXTERNAL_ID,
  });

  return payload;
};

export const onLoad = (projectId) => (dispatch) =>
  dispatch({
    type: DETAILS_ACTIONS.PROJECT_DETAILS_PAGE_LOADED,
    payload: () =>
      Promise.all([
        dispatch(getAllServices()),
        dispatch(getAllDepartments()),
        // Needed for updating Owners and TaskServices
        // TODO: Check if this can be skipped by using for example takeLeading from redux-saga
        dispatch(getProjectById(projectId)),
      ]),
  });

export const onUnload = () => (dispatch) =>
  dispatch({ type: DETAILS_ACTIONS.PROJECT_DETAILS_PAGE_UNLOADED });

export const deleteProject = (projectId) => (dispatch) =>
  dispatch({
    type: DETAILS_ACTIONS.DELETE_PROJECT,
    payload: () =>
      projectService
        .deleteProject(projectId)
        .then(() => dispatch(replace(`/${projectsBase}`))),
    confirmationDialog: {
      title: 'Delete project',
      confirmButtonText: 'Delete',
      description: 'Are you sure you want to delete this project?',
    },
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The project was successfully deleted',
    },
  });

export const updateProjectMeasurementPreference =
  (projectId, values) => (dispatch, getState) => {
    const valuesJS = values.toJS();
    const project = getFormValuesFromState(
      getState(),
      EDIT_PROJECT_FORM.FORM_ID,
    ).toJS();

    const { system } = valuesJS;

    const payload = projectService
      .updateMeasurementPreference(projectId, valuesJS)
      .then(() =>
        dispatch(
          updateProject(projectId, {
            ...project,
            unit: system,
          }),
        ),
      )
      .then(() =>
        dispatch(
          toggleModal({
            modalId: EDIT_PROJECT_UNITS_MODAL_ID,
          }),
        ),
      )
      .then((response) => {
        window.location.reload();
        return response;
      });

    dispatch({
      payload,
      type: DETAILS_ACTIONS.UPDATE_PROJECT_MEASUREMENT_PREFERENCES,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'Project measurement preferences successfully updated',
      },
    });

    return payload;
  };

export const requestProjectRiskSections = (taskId) => (dispatch) => {
  dispatch({
    taskId,
    type: RISK_TABLE_ACTIONS.REQUEST_PROJECT_RISK_SECTIONS,
  });
};

export const requestTaskRiskByProject = (projectId) => (dispatch) => {
  dispatch({
    projectId,
    type: RISK_TABLE_ACTIONS.REQUEST_TASK_RISK_BY_PROJECT,
  });
};

export const requestCreateProjectSection =
  (taskId, risk, riskSection, formik, callback) => (dispatch) => {
    const { setStatus, setSubmitting } = formik;

    dispatch({
      callback,
      setStatus,
      taskId,
      risk,
      setSubmitting,
      payload: riskSection,
      type: RISK_TABLE_ACTIONS.REQUEST_CREATE_PROJECT_RISK_SECTION,
      notification: {
        success: 'Risk successfully created',
      },
    });
  };

export const requestUpdateProjectSection = (taskId, risk) => (dispatch) => {
  dispatch({
    taskId,
    risk,
    payload: risk,
    type: RISK_TABLE_ACTIONS.REQUEST_UPDATE_PROJECT_RISK_SECTION,
    notification: {
      success: 'Risk successfully updated',
    },
  });
};

export const requestDeleteProjectSection = (riskId, taskId) => (dispatch) => {
  dispatch({
    riskId,
    taskId,
    type: RISK_TABLE_ACTIONS.REQUEST_DELETE_PROJECT_RISK_SECTION,
    confirmationDialog: {
      title: 'Delete risk',
      confirmButtonText: 'Delete',
      description: 'Are you sure you want to delete this risk? ',
    },
    notification: {
      success: 'Risk was successfully deleted',
    },
  });
};

export const deleteProjectRiskSection = (RiskSectionId) => ({
  RiskSectionId,
  type: RISK_TABLE_ACTIONS.DELETE_PROJECT_RISK_SECTION,
});

export const receiveProjectRiskSection = (sections) => ({
  payload: sections,
  type: RISK_TABLE_ACTIONS.RECEIVE_PROJECT_RISK_SECTION,
});

export const receiveProjectRiskSections = (sections) => ({
  payload: sections,
  type: RISK_TABLE_ACTIONS.RECEIVE_PROJECT_RISK_SECTIONS,
});

export const receiveTaskRiskByProject = (sections) => ({
  payload: sections,
  type: RISK_TABLE_ACTIONS.RECEIVE_TASK_RISK_BY_PROJECT,
});

export const archiveProject = (projectId, isArchive) => (dispatch) => {
  dispatch({
    type: DETAILS_ACTIONS.ARCHIVE_SINGLE_PROJECT,
    payload: () =>
      projectService
        .archiveProject(projectId, isArchive)
        .then(() => dispatch(onLoad(projectId))),
    confirmationDialog: {
      title: isArchive ? 'Archive project?' : 'Restore project?',
      confirmButtonText: isArchive ? 'Archive' : 'Restore',
      description: isArchive
        ? 'Are you sure you want to archive project?'
        : 'Are you sure you want to restore project?',
    },
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: isArchive
        ? `The project ${projectId} has been archived.`
        : `Project ${projectId} was restored.`,
    },
  });
};
