import { Suspense } from 'react';

import { Canvas } from '@react-three/fiber';

import DrillModel from 'features/projects/dashboard/components/dashboards/metalMilling/DrillModel/DrillModel';

const DrillModelContainer = ({ registerDataPointsHandler }) => {
  return (
    <Canvas orthographic camera={{ position: [0, 0, 200], zoom: 2 }}>
      <Suspense fallback={null}>
        <pointLight color="white" intensity={0.1} position={[0, 500, 0]} />
        <pointLight color="white" intensity={1} position={[-50, 0, 500]} />
        <pointLight color="white" intensity={0.1} position={[-50, 500, 500]} />
        <pointLight color="white" intensity={0.1} position={[-50, -500, 500]} />

        <DrillModel registerDataPointsHandler={registerDataPointsHandler} />
      </Suspense>
    </Canvas>
  );
};

export default DrillModelContainer;
