import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHeader } from 'app/hooks/useHeader';
import routePaths from 'app/routePaths';
import MobileApplicationHeaderBase from 'app/components/ApplicationHeader/Mobile/MobileApplicationHeaderBase';
import MobileUserMenuContainer from 'app/components/ApplicationHeader/Mobile/MobileUserMenuContainer';

const MobileProjectsListHeader = ({ breadcrumb }) => {
  useHeader({
    title: breadcrumb,
    logoNavigation: routePaths.projects,
  });
  return (
    <MobileApplicationHeaderBase>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography noWrap variant="h5" align="left">
              {'Select Project'}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <MobileUserMenuContainer />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MobileApplicationHeaderBase>
  );
};

export default MobileProjectsListHeader;
