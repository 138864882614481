import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { TOOLSTRING_FORMS as FORM } from 'features/projects/tasks/task/toolstring/toolstring.constants';

const CreateToolstringForm = () => (
  <Grid container direction="column">
    <Grid item>
      <Field
        required
        autoFocus
        fullWidth
        label="Name"
        margin="normal"
        component={TextFieldFormik}
        name={FORM.CREATE_TOOLSTRING_FORM.TOOLSTRING_NAME}
      />
    </Grid>
  </Grid>
);

export default CreateToolstringForm;
