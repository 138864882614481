import { isNil } from 'lodash';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { Dialog, CircularProgress, Box, Grid, Button } from '@material-ui/core';

import { ModalActions } from 'altus-ui-components';

import {
  updateTemplate,
  sortTemplateTools,
  deleteTemplateTool,
  duplicateTemplateItem,
  sortTemplateAssemblyTools,
  deleteTemplateToolAssemblyTool,
} from 'features/equipment/equipment.actions';

import { ToolType } from 'features/projects/tool/tool.constants';
import TaskToolstringTotals from 'features/projects/tasks/task/toolstring/components/TaskToolstringTotals';
import TaskToolstringEditorAppBar from 'features/projects/tasks/task/toolstring/components/edit/TaskToolstringEditorAppBar';
import TaskToolstringEditorContent from 'features/projects/tasks/task/toolstring/components/edit/TaskToolstringEditorContent';

const EditTemplateContent = ({
  open,
  classes,
  onClose,
  onAddTools,
  toolstring,
  loadingState,
  toolstringId,
  toolConnectors,
  toolstringTools,
  toolstringSections,
  onAddToolAttachments,
  onSortToolAttachments,
  onDeleteToolAttachments,
}) => {
  const dispatch = useDispatch();
  const hiddenColumns = ['serialNo'];

  const onDuplicateItem = (tool) => {
    dispatch(duplicateTemplateItem(toolstringId, tool));
  };

  const onSortToolstringTool = ({ oldIndex, newIndex, collection, nodes }) => {
    if (oldIndex === newIndex) return;
    const type = nodes?.[oldIndex]?.node?.getAttribute('data-tool-type');
    const toolType = Number(type);

    // tool  assembly tools
    if (!isNil(collection) && isNil(type)) {
      const toolstringAssembly = toolstringTools.get(collection);

      const toolAssemblyTools = toolstringAssembly.get('toolAssemblyTools');

      const sortedToolstringAssemblyTool = toolAssemblyTools.get(oldIndex);
      const affectedToolstringAssemblyTool = toolAssemblyTools.get(newIndex);

      dispatch(
        sortTemplateAssemblyTools(
          toolstringId,
          toolstringAssembly.get('toolstringItemId'),
          sortedToolstringAssemblyTool,
          affectedToolstringAssemblyTool,
        ),
      );
    } else {
      if (toolType === ToolType.TOOL || toolType === ToolType.ASSEMBLY) {
        const sortedToolstringItem = toolstringTools.get(oldIndex);
        const affectedToolstringItem = toolstringTools.get(newIndex);

        dispatch(
          sortTemplateTools(
            toolstringId,
            sortedToolstringItem,
            affectedToolstringItem,
          ),
        );
      } else if (toolType === ToolType.ATTACHMENT) {
        const owner = toolstringTools.get(collection);
        const sortedToolStringAttachment = owner.getIn([
          'attachedTools',
          oldIndex,
        ]);
        const affectedToolStringAttachment = owner.getIn([
          'attachedTools',
          newIndex,
        ]);

        onSortToolAttachments(
          owner.get('toolstringItemId'),
          sortedToolStringAttachment,
          affectedToolStringAttachment,
        );
      } else {
        throw new Error(`Unknown tool type ${toolType}`);
      }
    }
  };

  const updateItemProperties = () => {};

  const onDeleteToolstringTool = (tool) => {
    dispatch(deleteTemplateTool(tool.get('toolstringItemId'), toolstringId));
  };

  const onAddToolAssemblyTools = () => {};

  const onDeleteToolAssemblyTool = (tool) => {
    dispatch(
      deleteTemplateToolAssemblyTool(
        tool.get('toolAssemblyToolId'),
        toolstringId,
      ),
    );
  };

  const onUpdateToolstring = (fields) => {
    dispatch(updateTemplate(toolstringId, fields));
  };

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      <TaskToolstringEditorAppBar
        onClose={onClose}
        toolstringName={toolstring.get('name')}
      />
      {loadingState.isLoading() ? (
        <Box
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TaskToolstringEditorContent
            taskId={1}
            projectId={1}
            templateEditor={true}
            toolstring={toolstring}
            onAddTools={onAddTools}
            toolstringId={toolstringId}
            toolConnectors={toolConnectors}
            toolstringTools={toolstringTools}
            onDuplicateItem={onDuplicateItem}
            toolstringSections={toolstringSections}
            onUpdateToolstring={onUpdateToolstring}
            onSortToolstringTool={onSortToolstringTool}
            updateItemProperties={updateItemProperties}
            onDeleteToolstringTool={onDeleteToolstringTool}
            onAddToolAssemblyTools={onAddToolAssemblyTools}
            onDeleteToolAssemblyTool={onDeleteToolAssemblyTool}
            onAddToolAttachments={onAddToolAttachments}
            onDeleteToolstringAttachment={onDeleteToolAttachments}
            hiddenColumns={hiddenColumns}
            showBreakPoint={false}
          />
          <ModalActions classes={{ dialogActionsRoot: classes.actions }}>
            <Grid
              item
              xl={6}
              lg={7}
              md={10}
              container
              justifyContent="space-between"
            >
              <TaskToolstringTotals
                toolstring={toolstring}
                maxAvailableHeight={123}
              />
            </Grid>
            <Grid container item xs justifyContent="flex-end">
              <Grid item>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={onClose}
                  disabled={toolstring.get('toolStringItems')?.size <= 1}
                  classes={{ root: classes.saveButton }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </ModalActions>
        </>
      )}
    </Dialog>
  );
};

const styles = (theme) => ({
  actions: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
  },
  saveButton: {
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
});

export default compose(withStyles(styles))(EditTemplateContent);
