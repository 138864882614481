import { compose } from 'redux';
import React, { memo, useCallback, useState } from 'react';

import { useRequest } from 'altus-hooks';

import { EMPTY_MAP, UnitSystem } from 'app/app.constants';
import WellboreTrajectoryPreviewModal from 'features/wells/overview/components/WellboreTrajectoryPreviewModal';

const WellboreTrajectoryPreviewModalContainer = ({
  fieldId,
  onClose,
  wellbore,
  dataState,
  wellboreId,
  trajectoryFile,
  getTrajectoryPreview,
  uploadWellboreTrajectory,
}) => {
  const [isOpen, setModalOpen] = useState(false);
  const [unitSystem, setUnitSystem] = useState(UnitSystem.METRIC);

  const handleOnClose = useCallback(() => {
    onClose();
    setModalOpen(false);
    setUnitSystem(UnitSystem.METRIC);
  }, [onClose]);

  const handleGetTrajectoryPreview = useCallback(() => {
    if (!trajectoryFile) {
      return Promise.resolve();
    }

    setModalOpen(true);

    return getTrajectoryPreview(fieldId, wellboreId, trajectoryFile).catch(
      handleOnClose,
    );
  }, [
    fieldId,
    wellboreId,
    handleOnClose,
    trajectoryFile,
    getTrajectoryPreview,
  ]);

  const [trajectoryPreview = EMPTY_MAP] = useRequest(
    handleGetTrajectoryPreview,
  );

  const handleOnTrajectoryUpload = useCallback(() => {
    uploadWellboreTrajectory(
      fieldId,
      wellboreId,
      trajectoryFile,
      unitSystem,
    ).then(handleOnClose);
  }, [
    fieldId,
    wellboreId,
    unitSystem,
    handleOnClose,
    trajectoryFile,
    uploadWellboreTrajectory,
  ]);

  return (
    <WellboreTrajectoryPreviewModal
      open={isOpen}
      unitSystem={unitSystem}
      onClose={handleOnClose}
      wellName={wellbore.get('name')}
      submitting={dataState.isLoading()}
      onUnitSystemChange={setUnitSystem}
      onSubmit={handleOnTrajectoryUpload}
      trajectoryPreview={trajectoryPreview}
      fieldName={wellbore.get('fieldName')}
    />
  );
};

export default compose(memo)(WellboreTrajectoryPreviewModalContainer);
