import { connect } from 'react-redux';
import { PureComponent } from 'react';
import toJSComponent from 'with-immutable-props-to-js';

import {
  getCurrentUserFromState,
  getActionDataStateFromState,
} from 'app/app.selectors';

import { pages } from 'app/routePaths';
import EventList from 'app/components/EventList';
import BasePage from 'app/components/NewBasePage';
import { onLoad } from 'features/wells/events/wellEvents.actions';
import { getWellEventsFromState } from 'features/wells/wellbore.selectors';
import { WELL_EVENTS_PAGE_LOADED } from 'features/wells/events/wellEvents.constants';

class WellEventsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, fieldId, wellboreId } = this.props;

    dispatchOnLoad(fieldId, wellboreId);
  }

  render() {
    const { dataState, events, currentUser } = this.props;

    return (
      <BasePage
        subTitle="Events"
        dataState={dataState}
        subPage={pages.wells.events}
        loadingText="Loading events"
      >
        <EventList kind="well" currentUser={currentUser} events={events} />
      </BasePage>
    );
  }
}

export default connect(
  (state) => ({
    events: getWellEventsFromState(state),
    currentUser: getCurrentUserFromState(state),
    dataState: getActionDataStateFromState(state, WELL_EVENTS_PAGE_LOADED),
  }),
  {
    dispatchOnLoad: onLoad,
  },
)(toJSComponent(WellEventsContainer));
