import classNames from 'classnames';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, Chip, Box } from '@material-ui/core';

import { LoadingOverlay } from 'altus-ui-components';

import { EMPTY_LIST } from 'app/app.constants';
import useTaskFailReasons from 'features/projects/hooks/useTaskFailReasons';

const FailureReasonFieldFormik = ({
  form,
  field,
  classes,
  disabled,
  required,
}) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const [taskFailReasons = EMPTY_LIST, dataState] = useTaskFailReasons();

  const title = `Please select reason ${required ? '*' : ''}`;

  return (
    <Grid item xs={12}>
      <Typography variant="body2">{title}</Typography>
      <Typography variant="caption">(Select one)</Typography>
      <Grid container spacing={2} component={Box} paddingTop={2}>
        {taskFailReasons.map((reason) => {
          const reasonId = reason.get('id');

          return (
            <Grid key={reasonId} item>
              <Chip
                clickable={!disabled}
                label={reason.get('name')}
                color={value === reasonId ? 'primary' : 'default'}
                onClick={
                  disabled ? undefined : () => setFieldValue(name, reasonId)
                }
                classes={{
                  root: classNames({
                    [classes.disabledChip]: disabled,
                  }),
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      <LoadingOverlay dataState={dataState} />
    </Grid>
  );
};

const styles = () => ({
  disabledChip: {
    opacity: 0.7,
  },
});

export default withStyles(styles)(FailureReasonFieldFormik);
