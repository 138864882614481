import { EMPTY_MAP } from 'app/app.constants';
import { ACTIONS } from 'features/wells/elements/wellboreElement.constants';
import wellboreElementMappers from 'features/wells/elements/wellboreElement.mappers';

const initialState = EMPTY_MAP;

const wellboreElementReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.REQUEST_GET_WELLBORE_ELEMENTS:
    case ACTIONS.REQUEST_GET_WELLBORE_ELEMENTS_FROM_WELLCOM: {
      if (error || payload === null) return state;

      return state.set(
        payload.wellboreId.toString(),
        wellboreElementMappers.WellboreRevision.from(payload),
      );
    }

    default:
      return state;
  }
};

export default wellboreElementReducer;
