import { useMemo } from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import { Typography, LinearProgress } from '@material-ui/core';
import { TableRow } from 'altus-ui-components';
import { Grid } from 'app/components/withTooltip';
import { toProjectDetails } from 'utils/route.util';
import { PROJECT_STATUS as ProjectStatus } from 'app/app.constants';

const ProjectsTableRowMobileView = ({
  classes,
  item: project,
  toggleProjectsList,
  displayLocationMarker,
  zoomInAndOutByProjectId,
  setArchiveProjects,
  ...rest
}) => {
  const title = project.get('title');
  const projectId = project.get('id');
  const status = project.get('status');
  const progress = project.get('progress');

  const tableRowClasses = useMemo(
    () => ({
      root: classes.tableRowRoot,
      rootBackground: classes.tableRowRootBackground,
    }),
    [classes],
  );

  return (
    <TableRow classes={tableRowClasses} {...rest}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs className={classes.projectDetails}>
          <Grid
            container
            spacing={2}
            component={Link}
            alignItems="center"
            onClick={() =>
              toggleProjectsList ? toggleProjectsList(false) : null
            }
            to={toProjectDetails(projectId)}
          >
            <Grid item container xs zeroMinWidth spacing={0.8}>
              <Grid item xs={12}>
                <Typography
                  // noWrap
                  variant="h6"
                  align="left"
                  color="textPrimary"
                >
                  {`${
                    project.get('projectNumber')
                      ? project.get('projectNumber')
                      : ''
                  } ${title || <i>No objective set...</i>}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  noWrap
                  variant="body2"
                  align="left"
                  color="textSecondary"
                >
                  {`${project.get('fieldName')}  •  ${project.get(
                    'wellboreName',
                  )}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {status === ProjectStatus.EXECUTE && (
        <LinearProgress
          title={`Progress: ${progress}%`}
          value={progress}
          variant="determinate"
          className={classes.linearProgres}
        />
      )}
    </TableRow>
  );
};

const styles = (theme) => ({
  tableRowRoot: {
    height: 'auto',
    maxHeight: 'none',
    minHeight: '5.2rem',
  },
  tableRowRootBackground: {
    position: 'relative',
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1.5)}px`,
  },
  metaRow: {
    display: 'inline-grid',
  },
  linearProgres: {
    height: '2px',
    position: 'absolute',
    width: '100%',
    bottom: 1,
    left: 0,
  },
  projectDetails: {
    overflow: 'auto',
  },
});

export default compose(withStyles(styles))(ProjectsTableRowMobileView);
