import Agent from 'infrastructure/agent';

const getProjectWellboreDetails = (projectId) =>
  Agent.get(`/project/${projectId}/wellbore/details`);

const getProjectWellboreDetail = (projectId, projectWellboreDetailId) =>
  Agent.get(
    `/project/${projectId}/wellbore/details/${projectWellboreDetailId}`,
  );

const getLatestProjectWellboreDetail = (projectId) =>
  Agent.get(`/project/${projectId}/wellbore/details/latest`);

const approveDraft = (projectId, projectWellboreDetailId) =>
  Agent.put(
    `/project/${projectId}/wellbore/details/${projectWellboreDetailId}/approve`,
  );

const requestApproval = (projectId, projectWellboreDetailId) =>
  Agent.put(
    `/project/${projectId}/wellbore/details/${projectWellboreDetailId}/request-approval`,
  );

const createDraft = (projectId) =>
  Agent.post(`/project/${projectId}/wellbore/details`);

const addHostileFluidsToProjectWellboreDetails = (
  projectId,
  projectWellboreDetailId,
  hostileFluidsToAdd,
) =>
  Agent.post(
    `/project/${projectId}/wellbore/details/${projectWellboreDetailId}/hostile-fluids`,
    hostileFluidsToAdd,
  );

const removeHostileFluidsFromProjectWellboreDetails = (
  projectId,
  projectWellboreDetailId,
  hostileFluidsToRemove,
) =>
  Agent.put(
    `/project/${projectId}/wellbore/details/${projectWellboreDetailId}/hostile-fluids/delete`,
    hostileFluidsToRemove,
  );

const addProjectWellboreDetailServices = (
  projectId,
  projectWellboreDetailId,
  wellboreDetailServiceTypes,
) =>
  Agent.post(
    `/project/${projectId}/wellbore/details/${projectWellboreDetailId}/services`,
    wellboreDetailServiceTypes,
  );

  const syncProjectWellboreDetailsWithLatest = (
    projectId,
    projectWellboreDetailId,
  ) =>
    Agent.post(
      `/project/${projectId}/wellbore/details/${projectWellboreDetailId}/sync`,
    );

const deleteProjectWellboreDetailService = (
  projectId,
  projectWellboreDetailId,
  wellboreDetailServiceId,
) =>
  Agent.put(
    `/project/${projectId}/wellbore/details/${projectWellboreDetailId}/services/${wellboreDetailServiceId}/delete`,
  );

const updateWellboreDetail = (
  projectId,
  projectWellboreDetailId,
  wellboreDetail,
) =>
  Agent.put(
    `/project/${projectId}/wellbore/details/${projectWellboreDetailId}`,
    wellboreDetail,
  );

const cancelProjectWellboreDetailDraft = (projectId, projectWellboreDetailId) =>
  Agent.delete(
    `/project/${projectId}/wellbore/details/${projectWellboreDetailId}`,
  );

const getProjectWellboreDetailChangelog = (projectId) =>
  Agent.get(`/project/${projectId}/wellbore/details/changelog`);

const projectWellboreDetailService = {
  createDraft,
  approveDraft,
  requestApproval,
  updateWellboreDetail,
  getProjectWellboreDetail,
  getProjectWellboreDetails,
  getLatestProjectWellboreDetail,
  cancelProjectWellboreDetailDraft,
  addProjectWellboreDetailServices,
  getProjectWellboreDetailChangelog,  
  deleteProjectWellboreDetailService,
  syncProjectWellboreDetailsWithLatest,
  addHostileFluidsToProjectWellboreDetails,
  removeHostileFluidsFromProjectWellboreDetails,
};

export default projectWellboreDetailService;
