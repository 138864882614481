import { isMobile } from 'react-device-detect';

import createAuthService from 'altus-auth';

import config from 'infrastructure/config';

config.redirectUrl = isMobile
  ? `${config.redirectUrl}/operations`
  : `${config.redirectUrl}/static/blank.html`;

const AuthSingleton = (() => {
  // Instance stores a reference to the Singleton
  let instance;

  const createService = async () => {
    return await createAuthService(config, (msalCb, response) => {
      if (response) {
        msalCb.setActiveAccount(response.account);
      }
    });
  };

  async function init() {
    return await createService();
  }

  return {
    // Get the Singleton instance if one exists
    // or create one if it doesn't
    getMsalInstance: async () => {
      if (!instance) {
        instance = await init();
      }

      return instance;
    },
  };
})();

export default AuthSingleton;
