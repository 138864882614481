import { compose } from 'redux';
import { Box, Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import FormSectionHeader from 'app/components/form/FormSectionHeader';

const FormSection = ({ title, classes, children, renderActions }) => (
  <Grid container direction="column">
    <FormSectionHeader title={title} renderActions={renderActions} />
    <Box component={Grid} container className={classes.content} p={2}>
      {children}
    </Box>
  </Grid>
);

const styles = (theme) => ({
  content: {
    backgroundColor: theme.palette.grey[800],
  },
});

export default compose(withStyles(styles))(FormSection);
