import { compose } from 'redux';
import { useEffect } from 'react';
import Error from '@material-ui/icons/Error';
import { connect, useSelector } from 'react-redux';

import { LoadingDataState } from 'altus-datastate';
import { NoContentBasePage } from 'altus-ui-components';

import { getActionDataStateFromState } from 'app/app.selectors';
import { ACTIONS } from 'features/projects/wellbore/details/projectWellboreDetails.constants';
import { loadProjectWellboreDetailsPage } from 'features/projects/wellbore/details/projectWellboreDetails.actions';
import ProjectWellboreDetailFormContainer from 'features/projects/wellbore/details/ProjectWellboreDetailFormContainer';
import { getProjectWellboreDetailIdFromState } from 'features/projects/wellbore/details/projectWellboreDetails.selectors';

const ProjectWellboreDetailsContainer = ({
  projectId,
  dataState,
  activeWellboreDetailId,
  dispatchLoadProjectWellboreDetailsPage,
}) => {
  useEffect(() => {
    dispatchLoadProjectWellboreDetailsPage(projectId);
  }, [dispatchLoadProjectWellboreDetailsPage, projectId]);

  const wellboreDetailsId = useSelector(getProjectWellboreDetailIdFromState);

  if (dataState.isError() || !wellboreDetailsId) {
    return (
      <NoContentBasePage
        Icon={Error}
        header="An error occurred"
        description="An error occurred while getting the Well Details"
      />
    );
  }

  return (
    <ProjectWellboreDetailFormContainer
      projectId={projectId}
      projectWellboreDetailId={
        activeWellboreDetailId ? activeWellboreDetailId : wellboreDetailsId
      }
    />
  );
};

ProjectWellboreDetailsContainer.defaultProps = {
  dataState: LoadingDataState,
};

export default compose(
  connect(
    (state) => ({
      dataState: getActionDataStateFromState(
        state,
        ACTIONS.LOAD_PROJECT_WELLBORE_DETAILS_PAGE,
        ACTIONS.CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT,
        ACTIONS.CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT_AND_SYNC,
      ),
    }),
    {
      dispatchLoadProjectWellboreDetailsPage: loadProjectWellboreDetailsPage,
    },
  ),
)(ProjectWellboreDetailsContainer);
