import { React, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Grid, Tabs, Tab, withStyles, Divider, Box } from '@material-ui/core';

import { PROJECT_LESSON_REPORT_ACTIVE_TAB } from 'features/equipment/equipment.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import ReportContainer from 'features/projects/report/ReportContainer';
import NPTReportContainer from 'features/projects/npt/NPTReportContainer';
import LessonsContainer from 'features/projects/lessons/LessonsContainer';
import IncidentsContainer from 'features/projects/incidents/IncidentsContainer';
import AssetTestContainer  from 'features/projects/assets/AssetTestContainer';

const ProjectReportLessonContainer = ({
  classes,
  lessons,
  incidents,
  project,
  dataState,
  projectId,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchSelectLesson,
  dispatchDeleteLesson,
  dispatchSelectIncident,
  dispatchDeleteIncident,
  tasks,
  totalElapsedDuration,
  totalPlannedDuration,
}) => {
  const [activeTab, setActiveTab] = useState(
    PROJECT_LESSON_REPORT_ACTIVE_TAB.PROJECT_REPORT,
  );

  const activeTabHandler = (activeTab) => {
    switch (activeTab) {
      default:
        return null;
      case PROJECT_LESSON_REPORT_ACTIVE_TAB.PROJECT_REPORT:
        return (
          <ReportContainer
            tasks={tasks}
            dataState={dataState}
            projectId={projectId}
            breadcrumb={breadcrumb}
            dispatchOnLoad={dispatchOnLoad}
            dispatchOnUnload={dispatchOnUnload}
            totalElapsedDuration={totalElapsedDuration}
            totalPlannedDuration={totalPlannedDuration}
          />
        );

      case PROJECT_LESSON_REPORT_ACTIVE_TAB.PROJECT_LESSONS:
        return (
          <LessonsContainer
            lessons={lessons}
            classes={classes}
            project={project}
            dataState={dataState}
            projectId={projectId}
            breadcrumb={breadcrumb}
            dispatchOnLoad={dispatchOnLoad}
            dispatchOnUnload={dispatchOnUnload}
            dispatchSelectLesson={dispatchSelectLesson}
            dispatchDeleteLesson={dispatchDeleteLesson}
          />
        );
      case PROJECT_LESSON_REPORT_ACTIVE_TAB.PROJECT_INCIDENTS:
        return (
          <IncidentsContainer
            incidents={incidents}
            classes={classes}
            project={project}
            dataState={dataState}
            projectId={projectId}
            breadcrumb={breadcrumb}
            dispatchOnLoad={dispatchOnLoad}
            dispatchOnUnload={dispatchOnUnload}
            dispatchSelectIncident={dispatchSelectIncident}
            dispatchDeleteIncident={dispatchDeleteIncident}
          />
        );
      case PROJECT_LESSON_REPORT_ACTIVE_TAB.PROJECT_NON_PRODUCTIVE_TIME:
        return (
          <NPTReportContainer
            classes={classes}
            project={project}
            dataState={dataState}
            projectId={projectId}
            breadcrumb={breadcrumb}
            dispatchOnLoad={dispatchOnLoad}
            dispatchOnUnload={dispatchOnUnload}
          />
        );
      case PROJECT_LESSON_REPORT_ACTIVE_TAB.PROJECT_ASSET_TESTS:
        return (
          <AssetTestContainer
            classes={classes}
            project={project}
            dataState={dataState}
            projectId={projectId}
            breadcrumb={breadcrumb}
            dispatchOnLoad={dispatchOnLoad}
            dispatchOnUnload={dispatchOnUnload}
          />
        );
    }
  };

  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleTabs}
        className={classes.tabsContainer}
      >
        <Tab
          label="Report"
          value={PROJECT_LESSON_REPORT_ACTIVE_TAB.PROJECT_REPORT}
          className={classes.tab}
        />
        <Tab
          label="Lessons"
          value={PROJECT_LESSON_REPORT_ACTIVE_TAB.PROJECT_LESSONS}
          className={classes.tab}
        />
        <Tab
          label="Incidents (Synergi)"
          value={PROJECT_LESSON_REPORT_ACTIVE_TAB.PROJECT_INCIDENTS}
          className={classes.tab}
        />
        <Tab
          label="NPT"
          value={PROJECT_LESSON_REPORT_ACTIVE_TAB.PROJECT_NON_PRODUCTIVE_TIME}
          className={classes.tab}
        />
        <Tab
          label="Tests"
          value={PROJECT_LESSON_REPORT_ACTIVE_TAB.PROJECT_ASSET_TESTS}
          className={classes.tab}
        />
      </Tabs>
      <Divider className={classes.divider} />
      <Box className={classes.box}>
        <Grid container className={classes.contentContainer}>
          <Grid item container className={classes.tabPanel} spacing={1}>
            {activeTabHandler(activeTab)}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const styles = (theme) => ({
  contentContainer: {
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  box: {
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  tabsContainer: {
    paddingLeft: '12%',
    borderRadius: 3,
  },
  tab: {
    backgroundColor: 'transparent !important',
  },


  tabPanel: {
    flex: 1,
    padding: theme.spacing(1),
    backgroundColor: 'transparent',
  },
});

export default compose(
  connect((state) => ({
    dataState: getSummarizedDataStateFromState(state),
  })),
  withStyles(styles),
)(ProjectReportLessonContainer);
