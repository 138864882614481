import { useSelector } from 'react-redux';
import {
  getAllOrganizationsFromState,
  getCurrentUserFromState,
} from 'app/app.selectors';
import { ALTUS_ORGANIZATION_CODE } from 'app/hooks/useIsInternalCategoryToDisplay/constants';

const hydrateUserOrganization = (userOrganizationId, organizations) =>
  Array.from(organizations.values()).find(
    (x) => x.get('id') === userOrganizationId,
  );
const isCategoryInternal = (category) => {
  // Because sometimes category name is map and sometimes property.
  const categoryName = category.name || category?.get('name');
  return categoryName === 'Internal';
};
const isUserInAltusOrganization = (userOrganization) =>
  userOrganization?.get('code') === ALTUS_ORGANIZATION_CODE;

/**
 * Higher order function. Return function that gives false if category is internal and user
 * is not of ALTUS organization. Otherwise, true.
 *
 * First expression will check if category is 'Internal'. If not function will evaluate to true and return
 * (since categories other than internal are visible for all users).
 * Second expression will evaluate to true if user organization is 'ALTUS'. Hence, if category is 'Internal'
 * return value will match to if user belongs to 'ALTUS' organization.
 *
 * @param {Object} userOrganization
 * @return {function} Function that takes category (as Map) and returns true if category to be displayed.
 */
const createCheckToShowCategoryFn = (userOrganization) => (category) => {
  return (
    !isCategoryInternal(category) || isUserInAltusOrganization(userOrganization)
  );
};

export const useIsInternalCategoryToShow = () => {
  const user = useSelector(getCurrentUserFromState);
  const organizations = useSelector(getAllOrganizationsFromState);

  const userOrganization = hydrateUserOrganization(
    user.get('organizationId'),
    organizations,
  );

  return createCheckToShowCategoryFn(userOrganization);
};
