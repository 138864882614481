import { compose } from 'redux';
import { PureComponent } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import {
  NavTabsContainer,
  BasePageHeader,
  RouteContainer,
} from 'altus-ui-components';

import SystemRoutesContainer from 'app/components/SystemRoutesContainer';

class AccessRolesContainer extends PureComponent {
  renderContent = (routes) => {
    const { path } = this.props;

    const indexRoute = routes[0];

    return (
      <>
        <NavTabsContainer routes={routes} />
        <Switch>
          {indexRoute && <Redirect exact from={path} to={indexRoute.path} />}
          <RouteContainer routes={routes} />
        </Switch>
      </>
    );
  };

  render() {
    const { Icon, breadcrumb, routes } = this.props;

    return (
      <>
        <BasePageHeader title={breadcrumb} Icon={Icon} />
        <SystemRoutesContainer routes={routes} render={this.renderContent} />
      </>
    );
  }
}

export default compose()(AccessRolesContainer);
