import { OrderedMap } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import { SMLOCATION_CONFIG_ACTIONS as ACTIONS } from 'features/data-exchange/winch/smlocation.constants';
import smlocationMappers from 'features/data-exchange/winch/smlocation.mappers';

const initialState = EMPTY_MAP;

const smLocationReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.DELETE_SMLOCATION: {
      if (error) return state;

      const { smLocationId } = action;

      return state.delete(smLocationId.toString());
    }

    case ACTIONS.RECEIVE_SMLOCATION: {
      if (error) return state;

      return state.set(
        payload.smLocationId.toString(),
        smlocationMappers.SMLocation.from(payload),
      );
    }

    case ACTIONS.RECEIVE_SMLOCATIONS: {
      if (error) return state;

      return OrderedMap(
        payload.map((smLocation) => [
          smLocation.smLocationId.toString(),
          smlocationMappers.SMLocation.from(smLocation),
        ]),
      );
    }

    default:
      return state;
  }
};

export default smLocationReducer;
