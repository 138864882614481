import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';

const CableBreakTestFormComponent = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12} container>
          <Field
            type="number"
            label="Force"
            component={QuantityTextFieldFormik}
            name={AssetHistoryForm.CABLE_BREAK_TEST_FORCE}
            placeholder="0"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CableBreakTestFormComponent;
