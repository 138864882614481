import { compose } from 'redux';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';
import { useHeader } from 'app/hooks/useHeader';

import { getActionDataStateFromState } from 'app/app.selectors';
import { getFacilityDecks } from 'features/facilities/facilities.actions';
import { FACILITIES_ACTIONS } from 'features/facilities/facilities.constants';
import { getFacilityByDeckFromState } from 'features/facilities/facilities.selectors';
import FacilityDetailsTable from 'features/facilities/details/component/FacilityDetailsTable';

const FacilityDetailsContainer = ({
  classes,
  breadcrumb,
  facilityId,
  dispatchOnLoad,
  dataState,
}) => {
  useHeader({
    subTitle: breadcrumb,
  });

  useEffect(() => {
    dispatchOnLoad(facilityId);
  }, [dispatchOnLoad, facilityId]);

  const facilityDeck = useSelector(getFacilityByDeckFromState);

  return (
    <BasePage dataState={dataState} title={breadcrumb}>
      <Grid container xs={12} item className={classes.container}>
        <Grid item xs={6} container className={classes.facilityContainer}>
          <Grid className={classes.facilityItemContainer}>
            <FacilityDetailsTable
              noItemsMessage="No items..."
              decks={facilityDeck}
            />
          </Grid>
        </Grid>
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  container: {
    flexDirection: 'row',
    marginTop: theme.spacing(2.25),
  },
  facilityContainer: {
    flexDirection: 'row',
    padding: theme.spacing(2.25),
  },
  positionContainer: {
    padding: theme.spacing(2.25),
  },
  facilityItemContainer: {
    width: '100%',
    height: '50%',
    marginTop: '10%',
  },
});

export default compose(
  connect(
    (state) => ({
      dataState: getActionDataStateFromState(
        state,
        FACILITIES_ACTIONS.GET_FACILITY_DECKS,
      ),
    }),
    {
      dispatchOnLoad: getFacilityDecks,
    },
  ),
  withStyles(styles),
)(FacilityDetailsContainer);
