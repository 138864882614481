import React from 'react';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ReadOnlyField from 'app/components/ReadOnlyField';
import { BOP_LEAK_TYPE, BOP_RAM } from 'features/equipment/equipment.constants';
import EditorStateViewer from 'app/components/EditorStateViewer';
import { RichText } from 'utils/app.util';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

function getRamNameById(id) {
  const ram = BOP_RAM.find((ram) => ram.id === String(id));
  return ram ? ram.name : '-';
}

function getLeakTypeNameById(id) {
  const type = BOP_LEAK_TYPE.find((type) => type.id === String(id));
  return type ? type.name : '-';
}

const BopTestDrawerComponent = ({ assetHistory, classes }) => {
  const currentComment = assetHistory.getIn(['bopTest', 'comment']);

  return (
    <>
      <Grid item xs={6}>
        <Grid className={classes.marginLabel}>
          <CustomReadOnlyField margin="none" label="Bop Ram">
            {getRamNameById(assetHistory.getIn(['bopTest', 'bopRam']))}
          </CustomReadOnlyField>
        </Grid>
        <Grid className={classes.marginLabel}>
          <CustomReadOnlyField margin="none" label="Description">
            {currentComment ? (
              <EditorStateViewer
                editorState={RichText(
                  assetHistory.get('bopTest'),
                  'bopLeakTypeDescription',
                )}
              />
            ) : (
              '-'
            )}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid className={classes.marginLabel}>
          <CustomReadOnlyField margin="none" label="Leak type">
            {getLeakTypeNameById(
              assetHistory.getIn(['bopTest', 'bopLeakType']),
            )}
          </CustomReadOnlyField>
        </Grid>
      </Grid>

      <Grid item xs={12}></Grid>
    </>
  );
};

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: '4vh',
  },
  marginLabel: {
    marginTop: '4vh',
  },
  marginStrand: {
    marginBottom: '2vh',
  },
});

export default withStyles(styles)(BopTestDrawerComponent);
