import { getAllFileCategories, toggleModal } from 'app/app.actions';
import { NOTIFICATION_VARIANTS, MODAL } from 'app/app.constants';
import { FIELDS_ACTIONS } from './fields.constants';
import fieldService from 'services/field.service';

export const onLoad = (fieldId) => (dispatch) =>
  dispatch({
    type: FIELDS_ACTIONS.FIELD_DOCUMENTS_PAGE_LOADED,
    payload: () =>
      Promise.all([
        dispatch(getAllFileCategories()),
        fieldService
          .getAllDocuments(fieldId)
          .then((files) => dispatch(receiveProjectFiles(files))),
      ]),
  });

export const onUnload = () => (dispatch) =>
  dispatch({ type: FIELDS_ACTIONS.FIELD_DOCUMENTS_PAGE_UNLOADED });

export const deleteFile = (fieldId, fieldFileId) => (dispatch) => {
  dispatch({
    fieldFileId,
    type: FIELDS_ACTIONS.DELETE_FIELD_DOCUMENT,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Deleting...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The file was successfully deleted',
    },
    confirmationDialog: {
      title: 'Delete document',
      confirmButtonText: 'Delete',
      description: 'Are you sure you want to delete this document?',
    },
    payload: () => fieldService.deleteFile(fieldId, fieldFileId),
  });
};

export const downloadFile = (fieldId, fieldFileId, file) => (dispatch) => {
  const payload = fieldService.downloadFile(fieldId, fieldFileId);

  dispatch({
    fieldFileId,
    type: FIELDS_ACTIONS.DOWNLOAD_FIELD_DOCUMENT,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: `Downloading ${file.get('name')}...`,
    },
    payload,
  });

  return payload;
};

export const uploadFiles =
  (fieldId) => (dispatch) => (uploadDocumentFormValues) => {
    const { files } = uploadDocumentFormValues.toJS();

    const payload = Promise.all(
      files.map(({ file, meta }) =>
        fieldService
          .uploadFile(fieldId, file, meta)
          .then((projectFile) => dispatch(receiveProjectFile(projectFile))),
      ),
    ).then((response) => {
      dispatch(toggleModal({ modalId: MODAL.UPLOAD_FILE }));
      return response;
    });

    dispatch({
      type: FIELDS_ACTIONS.UPLOAD_FIELD_DOCUMENT,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: `The ${
          files.length > 1 ? 'files' : 'file'
        } ${files.length > 1 ? 'were' : 'was'} successfully uploaded`,
      },
      payload,
    });

    return payload;
  };

export const uploadFileModalOnUnload = () => (dispatch) =>
  dispatch({ type: FIELDS_ACTIONS.UPLOAD_FIELD_DOCUMENT_MODAL_UNLOADED });

export const updateFile = (fieldId, fieldFileId, file) => (dispatch) => {
  const payload = fieldService
    .updateFile(fieldId, fieldFileId, file)
    .then((projectFile) => receiveProjectFile(projectFile));

  dispatch({
    payload,
    fieldFileId,
    type: FIELDS_ACTIONS.UPDATE_FIELD_DOCUMENT,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'File was successfully updated',
    },
  });

  return payload;
};

export const receiveProjectFiles = (fieldFiles) => ({
  payload: fieldFiles,
  type: FIELDS_ACTIONS.GET_FIELD_DOCUMENTS,
});

export const receiveProjectFile = (fieldFile) => ({
  payload: fieldFile,
  type: FIELDS_ACTIONS.GET_FIELD_DOCUMENT,
});
