import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  createTaskActivitiesSelector,
  createTaskAllActivitiesSelector,
} from 'features/projects/activities/activities.selectors';

export const useTaskActivities = (taskId) => {
  const activitiesSelector = useMemo(
    () => createTaskActivitiesSelector(taskId),
    [taskId],
  );

  const allActivitiesSelector = useMemo(
    () => createTaskAllActivitiesSelector(taskId),
    [taskId],
  );

  const activities = useSelector(activitiesSelector);
  const allActivities = useSelector(allActivitiesSelector);

  return activities || allActivities;
};
