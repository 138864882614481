import { useLocation } from 'react-router';
import routePaths from 'app/routePaths';
import { BasePageHeader } from 'altus-ui-components';
import { NavTabsContainer } from 'altus-ui-components';
import SystemRoutesContainer from 'app/components/SystemRoutesContainer';

const IntegrityManagementContainer = ({ Icon, breadcrumb, routes }) => {
  const location = useLocation();

  const newRoutes = [
    routePaths.settings.integrity.new,
    routePaths.settings.integrity.newRule,
    routePaths.settings.integrity.newCategory,
  ];

  const isAddNewRoute = newRoutes.includes(location.pathname);

  return (
    <>
      {!isAddNewRoute && <BasePageHeader title={breadcrumb} Icon={Icon} />}
      {!isAddNewRoute && <NavTabsContainer routes={routes} />}
      <SystemRoutesContainer routes={routes} />
    </>
  );
};

export default IntegrityManagementContainer;
