import { Map, OrderedMap } from 'immutable';

export const wellboreRevisionFromDto = ({ wellboreElements, ...rest }) =>
  Map({
    wellboreElements: wellboreElementsFromDto(wellboreElements),
    ...rest,
  });

export const wellboreElementsFromDto = (wellboreElements) =>
  OrderedMap(
    wellboreElements.map((wellboreElement) => [
      wellboreElement.wellboreElementId.toString(),
      wellboreElementFromDto(wellboreElement),
    ]),
  );

export const wellboreElementFromDto = ({ ...rest }) =>
  Map({
    ...rest,
  });

const wellboreElementMappers = {
  WellboreRevision: {
    from: wellboreRevisionFromDto,
  },
  WellboreElement: {
    from: wellboreElementFromDto,
  },
  WellboreElements: {
    from: wellboreElementsFromDto,
  },
};

export default wellboreElementMappers;
