import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import RunNumber from 'features/projects/components/RunNumber';

const useStyles = makeStyles((theme) =>
  createStyles({
    taskButton: {
      borderColor: theme.palette.divider,
      borderRadius: 0,
      textTransform: 'none',
      marginTop: 2,
      padding: theme.spacing(1.4),
      border: '1px solid transparent',
      cursor: 'pointer',
    },
    taskButtonSelected: {
      borderColor: theme.palette.divider,
      backgroundColor: theme.altus.components.Mobilisation.tasksMenu.selected,
      borderRadius: 0,
      textTransform: 'none',
      marginTop: 2,
      padding: theme.spacing(1.4),
      border: '1px solid transparent',
      cursor: 'pointer',
    },
  }),
);

const MobilisationTasksMenuItem = ({
  task,
  selectedTask,
  handleTaskSelection,
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      xs={12}
      key={task.get('id')}
      spacing={1}
      alignItems="center"
      className={
        selectedTask === task.get('taskId')
          ? classes.taskButtonSelected
          : classes.taskButton
      }
      onClick={() => {
        const values = {
          taskId: task.get('taskId'),
          externalId: task.get('externalId'),
        };
        return handleTaskSelection(values);
      }}
    >
      <Grid item>
        <RunNumber size={24}>{task.get('runNumber')}</RunNumber>
      </Grid>
      <Grid item xs>
        <Typography
          align="left"
          variant="body2"
          color="textPrimary"
          className={classes.title}
        >
          {task.get('customTitle')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MobilisationTasksMenuItem;
