import { CSG } from 'app/components/WellboreTrajectoryDetailed3DView/CSGMesh.js';
import React, { useEffect, useRef } from 'react';
import { compose } from 'redux';
import * as THREE from 'three';
import themes, { defaultThemeType } from 'layout/themes';

const theme = themes[defaultThemeType];

const objectUp = new THREE.Object3D().up;
const reusableMatrix = new THREE.Matrix4();
const orientationMultiplier = new THREE.Matrix4().set(
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  -1,
  0,
  0,
  0,
  0,
  0,
  1,
);

const Pipe = ({
  startVector = new THREE.Vector3(),
  endVector = new THREE.Vector3(),
  radius,
  outerRadius,
  position,
  length,
}) => {
  const cylinderRef = useRef();

  const curve = new THREE.CatmullRomCurve3(
    [startVector, new THREE.Vector3(0, 0, 0), endVector],
    false,
    'catmullrom',
    0.5,
  );

  const position1 = curve.getPointAt(0.5);

  const arcShape = new THREE.Shape().absarc(
    0,
    0,
    outerRadius,
    0,
    Math.PI,
    true,
  );

  const innerShape = new THREE.Shape();
  innerShape.absarc(0, 0, -radius, 0, Math.PI);

  const outerGeometry = new THREE.ExtrudeBufferGeometry(arcShape, {
    steps: 1,
    depth: length,
    bevelEnabled: false,
  });

  const innerGeometry = new THREE.ExtrudeBufferGeometry(innerShape, {
    steps: 1,
    depth: length,
    bevelEnabled: false,
  });

  const bspA = CSG.fromGeometry(outerGeometry, 0);
  const bspB = CSG.fromGeometry(innerGeometry, 1);

  // Subtract one bsp from the other via .subtract... other supported modes are .union and .intersect

  let bspResult = bspA.subtract(bspB);
  bspResult = CSG.toGeometry(bspResult);

  useEffect(() => {
    reusableMatrix.lookAt(startVector, endVector, objectUp);
    reusableMatrix.multiply(orientationMultiplier);
  }, [startVector, endVector]);

  return (
    <mesh
      ref={cylinderRef}
      name="PIPE"
      position={[position[0] + position1.x, position1.y, position1.z]}
      rotation={[-Math.PI / 2, Math.PI / 2, -Math.PI]}
      geometry={bspResult}
    >
      <meshStandardMaterial
        color={theme.altus.components.ContextualizedWell.metal.main}
        roughness={0.2}
        metalness={1}
        side={THREE.DoubleSide}
        envMapIntensity={3}
      />
    </mesh>
  );
};

export default compose()(Pipe);
