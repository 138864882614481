export const FIELDS_ACTIONS = {
  GET_FIELDS_WITH_ORGANIZATIONS: 'GET_FIELDS_WITH_ORGANIZATIONS',
  FIELD_WITH_ORGANIZATIONS_PAGE_UNLOADED:
    'FIELD_WITH_ORGANIZATIONS_PAGE_UNLOADED',
  GET_FIELD_DOCUMENTS: 'GET_FIELD_DOCUMENTS',
  GET_FIELD_DOCUMENT: 'GET_FIELD_DOCUMENT',
  FIELD_DOCUMENTS_PAGE_LOADED: 'FIELD_DOCUMENTS_PAGE_LOADED',
  FIELD_DOCUMENTS_PAGE_UNLOADED: 'FIELD_DOCUMENTS_PAGE_UNLOADED',
  DELETE_FIELD_DOCUMENT: 'DELETE_FIELD_DOCUMENT',
  UPLOAD_FIELD_DOCUMENT: 'UPLOAD_FIELD_DOCUMENT',
  UPLOAD_FIELD_DOCUMENT_MODAL_UNLOADED: 'UPLOAD_FIELD_DOCUMENT_MODAL_UNLOADED',
  UPDATE_FIELD_DOCUMENT: 'UPDATE_FIELD_DOCUMENT',
  DOWNLOAD_FIELD_DOCUMENT: 'DOWNLOAD_FIELD_DOCUMENT',
};

export const FIELD_STATUS = {
  UNKNOWN: { id: 0, description: 'Unknown' },
  PRODUCING: { id: 1, description: 'Producing' },
  PRODUCTION_UNLIKELY: { id: 2, description: 'Production Unlikely' },
  PRODUCTION_LIKELY: { id: 3, description: 'Production Likely' },
  PRODUCTION_APPROVED: { id: 4, description: 'Production Approved' },
  PRODUCTION_IN_CLARIFICATION_PHASE: {
    id: 5,
    description: 'Production In Clarification Phase',
  },
  PRODUCTION_NOT_EVALUATED: { id: 6, description: 'Production Not Evaluated' },
  PRODUCTION_DECIDED: { id: 7, description: 'Production Decided' },
  INCLUDED_IN_OTHER_DISCOVERY: {
    id: 8,
    description: 'Included In Other Discovery',
  },
  SHUT_DOWN: { id: 9, description: 'Shut Down' },
};

export const getStatusById = (id) => {
  if (!id) {
    return FIELD_STATUS.UNKNOWN.description;
  }

  const result = Object.values(FIELD_STATUS).find(
    (status) => status.id === id,
  )?.description;

  if (!result) {
    return FIELD_STATUS.UNKNOWN.description;
  }

  return result;
};

export const IMPORT_SOURCE = {
  MANUAL: { id: 0, description: 'Manual' },
  NPD: { id: 1, description: 'Npd' },
  OGA: { id: 2, description: 'Oga' },
  MARINE_TRAFFIC_POSITION: { id: 3, description: 'MarineTrafficPosition' },
  MARINE_TRAFFIC_IMAGE: { id: 4, description: 'MarineTrafficImage' },
  ENS: { id: 5, description: 'Ens' },
  EXCEL: { id: 6, description: 'Excel' },
  UNKNOWN: { id: 6, description: 'Unknown' },
};

export const getImportSourceById = (id) => {
  if (!id) {
    return IMPORT_SOURCE.UNKNOWN.description;
  }

  const result = Object.values(IMPORT_SOURCE).find(
    (source) => source.id === id,
  ).description;

  if (!result) {
    return IMPORT_SOURCE.UNKNOWN.description;
  }

  return result;
};

export const FIELD_DETAILS_ACTIVE_TABS = {
  OVERVIEW: 'FIELD_OVERVIEW_TAB',
  DETAILS: 'FIELD_DETAILS_TAB',
};
