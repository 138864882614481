import { OrderedMap } from 'immutable';

import mappers from 'mappers';
import { EMPTY_MAP } from 'app/app.constants';
import { DASHBOARDS_ACTIONS } from 'features/projects/dashboard/dashboard.constants';

const initialState = EMPTY_MAP;

const projectDashboardReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case DASHBOARDS_ACTIONS.RECEIVE_PROJECT_DASHBOARD: {
      if (error) return state;

      const { projectId, projectDashboardId } = action;

      return state.setIn(
        [projectId.toString(), projectDashboardId.toString()],
        mappers.ProjectDashboard.from(payload),
      );
    }

    case DASHBOARDS_ACTIONS.RECEIVE_PROJECT_DASHBOARDS: {
      if (error) return state;

      const { projectId } = action;

      return state.set(
        projectId.toString(),
        OrderedMap(
          payload.map((projectDashboard) => [
            projectDashboard.projectDashboardId.toString(),
            mappers.ProjectDashboard.from(projectDashboard),
          ]),
        ),
      );
    }

    default:
      return state;
  }
};

export default projectDashboardReducer;
