import { useSelector } from 'react-redux';
import history from 'infrastructure/history';
import { isMobileSafari } from 'react-device-detect';
import { Grid, IconButton } from '@material-ui/core';
import { BrowserView, isDesktop, MobileView } from 'react-device-detect';

import {
  Close,
  InfoOutlined,
  AddBoxOutlined,
  CheckBoxOutlineBlank,
  TrendingDownOutlined,
  FeaturedVideoOutlined,
  HorizontalSplitOutlined,
} from '@material-ui/icons';

import { useToggle } from 'altus-hooks';
import { Logo } from 'altus-ui-components';

import routePaths from 'app/routePaths';
import { useHeader } from 'app/hooks/useHeader';
import DashboardIcon from 'app/components/Icons/DashboardIcon';
import { getApplicationHeaderFromState } from 'app/app.selectors';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import LogoAndAppTitle from 'app/components/ApplicationHeader/LogoAndAppTitle';
import ProjectStatusText from 'features/projects/components/ProjectStatusText';
import ProjectLinearProgress from 'features/projects/components/ProjectLinearProgress';
import FullScreenButton from 'features/projects/dashboard/components/FullScreenButton';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import ApplicationHeaderTitle from 'app/components/ApplicationHeader/ApplicationHeaderTitle';
import ProjectDashboardsDrawer from 'features/projects/dashboard/components/ProjectDashboardsDrawer';

// Hide the Actions for now as it is not relevant for May 1st 2021 release
const displayActions = false;

const DashboardHeader = ({ projectId, projectDashboardId }) => {
  const project = useCurrentProject();
  const applicationHeader = useSelector(getApplicationHeaderFromState);

  useHeader({
    title: isDesktop ? project.get('fullTitle') : undefined,
  });

  const logoNavigation = applicationHeader.get('logoNavigation');

  const [isOpen, toggleDrawer] = useToggle();

  return (
    <>
      <ApplicationHeaderBase>
        <Grid
          container
          spacing={2}
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs>
            <MobileView renderWithFragment>
              <Logo rootUrl={logoNavigation ?? routePaths.root} />
            </MobileView>
            <BrowserView renderWithFragment>
              <LogoAndAppTitle to={logoNavigation} />
            </BrowserView>
          </Grid>
          <Grid item xs={8}>
            <ApplicationHeaderTitle />
          </Grid>
          <Grid item xs>
            <Grid container alignItems="center" justifyContent="flex-end">
              <BrowserView renderWithFragment>
                <Grid item>
                  <ProjectStatusText status={project.get('status')} />
                </Grid>
                <Grid item>
                  {!isMobileSafari ? <FullScreenButton /> : null}
                </Grid>
              </BrowserView>
              {displayActions && (
                <BrowserView renderWithFragment>
                  <Grid item>
                    <Grid item>
                      {!isMobileSafari ? <FullScreenButton /> : null}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <IconButton disabled color="default" title="Pool">
                      <CheckBoxOutlineBlank />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton disabled color="default" title="Swimlane">
                      <HorizontalSplitOutlined />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton disabled color="default" title="Editor">
                      <AddBoxOutlined />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton disabled color="default" title="Info">
                      <InfoOutlined />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton disabled color="default" title="Simulation">
                      <FeaturedVideoOutlined />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton disabled color="default" title="Trend">
                      <TrendingDownOutlined />
                    </IconButton>
                  </Grid>
                </BrowserView>
              )}
              <Grid item>
                {!!projectDashboardId && (
                  <BrowserView renderWithFragment>
                    <ProjectDashboardsDrawer
                      isOpen={isOpen}
                      project={project}
                      projectId={projectId}
                      toggleDrawer={toggleDrawer}
                      projectDashboardId={projectDashboardId}
                    />
                    <IconButton color="default" onClick={toggleDrawer}>
                      <DashboardIcon />
                    </IconButton>
                  </BrowserView>
                )}
                <MobileView renderWithFragment>
                  <IconButton
                    title="Close"
                    color="inherit"
                    onClick={() => history.goBack()}
                    aria-label="Close"
                  >
                    <Close />
                  </IconButton>
                </MobileView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ApplicationHeaderBase>
      <ProjectLinearProgress />
    </>
  );
};

export default DashboardHeader;
