import PropTypes from 'prop-types';

import { invokeIfFunction } from 'utils/app.util';
import TextAreaFieldV2 from 'app/components/form/TextAreaFieldV2';

const defaultFormat = (value) => value || '';

const TextAreaFieldFormik = ({
  field,
  minRows = 3,
  maxRows = 5,
  format = defaultFormat,
  ...rest
}) => {
  const { value, onChange, name } = field;

  const formattedValue = format ? invokeIfFunction(format, value) : value;

  return (
    <TextAreaFieldV2
      name={name}
      minRows={minRows}
      maxRows={maxRows}
      onChange={onChange}
      value={formattedValue}
      {...rest}
    />
  );
};

TextAreaFieldFormik.propTypes = {
  format: PropTypes.func,
};

export default TextAreaFieldFormik;
