import ThreeJsManager from 'app/threeJs/ThreeJsManager';

import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';

// Components outside the provider will get {} when using the context.
const ThreeJsContext = createContext({});

// Components inside the ThreeJsContext will share the same canvas, which will be transparent and take the whole viewport.
// We 'escape' the React world by using a plain JS function called the ThreeJsManager, which is in charge of keeping track of the scenes
// and, more importantly, running the 'animation loop'.
// The ThreeJSManager is accessed by the children using the context API, and can then call for example addScene() or disposeScene().
// When adding a scene, the manager expects not only the THREE.Scene instance, but also the dom element that will tell where in the viewport
// that scene should be rendered.
// Ref.: https://threejsfundamentals.org/threejs/lessons/threejs-multiple-scenes.html
export const useThreeJsManager = () => {
  const manager = useContext(ThreeJsContext);

  return manager;
};

const ThreeJsManagerProvider = ({ children }) => {
  const [manager, setManager] = useState();

  const canvasRef = useCallback((canvasNode) => {
    if (canvasNode) {
      setManager(ThreeJsManager(canvasNode));
    }
  }, []);

  useEffect(() => () => manager?.dispose(), [manager]);

  return (
    <ThreeJsContext.Provider value={manager}>
      <canvas
        ref={canvasRef}
        style={{
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'block',
          position: 'fixed',
          pointerEvents: 'none',
        }}
      />
      {children}
    </ThreeJsContext.Provider>
  );
};

export default ThreeJsManagerProvider;
