import { Field } from 'formik';
import { InputAdornment } from '@material-ui/core';

import { required } from 'utils/validation.util';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';

import { FluidFormFields as FormFields } from 'utils/fluids/constants.utils';

const MultiphaseLiquidFormSection = ({ disabled }) => (
  <>
    <Field
      required
      type="number"
      destroyOnUnmount
      disabled={disabled}
      validate={required}
      label="Oil Gravity"
      component={TextFieldFormik}
      name={FormFields.OIL_GRAVITY}
      InputProps={{
        endAdornment: <InputAdornment position="end">deg API</InputAdornment>,
      }}
    />
    <Field
      required
      type="number"
      destroyOnUnmount
      disabled={disabled}
      validate={required}
      label="Water Fraction"
      component={TextFieldFormik}
      name={FormFields.WATER_FRACTION}
    />
    <Field
      required
      type="number"
      destroyOnUnmount
      disabled={disabled}
      validate={required}
      label="Water Density"
      destroyOnUnmount={false}
      name={FormFields.WATER_DENSITY}
      component={QuantityTextFieldFormik}
    />
  </>
);

export default MultiphaseLiquidFormSection;
