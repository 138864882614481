import { OrderedMap } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import wellboreFileMapper from 'features/wells/wellboreFiles.mappers';
import { WELLBORE_FILE_ACTIONS } from 'features/wells/wellboreFiles.constants';

const initialState = EMPTY_MAP;

const wellboreFileReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case WELLBORE_FILE_ACTIONS.RECEIVE_WELLBORE_FILE: {
      if (error) return state;

      const { payload } = action;

      return state.set(
        payload.fileId.toString(),
        wellboreFileMapper.WellboreFile.from(payload),
      );
    }

    case WELLBORE_FILE_ACTIONS.DELETE_WELLBORE_FILE: {
      if (error) return state;

      const { wellboreFileId } = action;

      return state.delete(wellboreFileId.toString());
    }

    case WELLBORE_FILE_ACTIONS.RECEIVE_WELLBORE_FILES: {
      if (error) return state;

      return OrderedMap(
        payload.map((wellboreFile) => [
          wellboreFile.fileId.toString(),
          wellboreFileMapper.WellboreFile.from(wellboreFile),
        ]),
      );
    }

    default:
      return state;
  }
};

export default wellboreFileReducer;
