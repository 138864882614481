import { Grid, Typography } from '@material-ui/core';

import { UNITS } from 'app/app.constants';
import MetricImperial from 'app/components/MetricImperial';

export const ToolDetails = ({ tool }) => (
  <Grid container spacing={2}>
    <Grid item xs>
      <Grid container>
        <Grid item>
          <Typography variant="subtitle2">{tool.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="caption">Length:</Typography>
            </Grid>
            <Grid item>
              <MetricImperial
                variant="caption"
                to={UNITS.METER}
                from={UNITS.METER}
                value={tool.length.value}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="caption">Weight:</Typography>
            </Grid>
            <Grid item>
              <MetricImperial
                variant="caption"
                to={UNITS.KILO}
                from={UNITS.KILO}
                value={tool.weight.value}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="caption">Outer diameter:</Typography>
            </Grid>
            <Grid item>
              <MetricImperial
                variant="caption"
                value={tool.outerDiameter.value}
                from={UNITS.INCHES}
                to={UNITS.INCHES}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="caption">Type:</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">{tool.toolGroupName}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default ToolDetails;
