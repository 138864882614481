import withStyles from '@material-ui/styles/withStyles';
import { Grid, InputLabel, Typography, FormControl } from '@material-ui/core';

import NavigationLink from 'app/components/NavigationLink';

const ReadOnlyField = ({
  to,
  value,
  label,
  classes,
  children,
  margin = 'normal',
  variant = 'subtitle1',
  TextComponent = to ? NavigationLink : Typography,
  ...rest
}) => {
  return (
    <FormControl fullWidth margin={margin}>
      <InputLabel>{label}</InputLabel>
      <Grid container className={classes.container}>
        {value && (
          <TextComponent
            to={to}
            variant={variant}
            className={classes.text}
            {...rest}
          >
            {value}
          </TextComponent>
        )}
        {children}
      </Grid>
    </FormControl>
  );
};

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing(2), // push content below InputLabel
  },
  text: {
    padding: '2px 0',
    whiteSpace: 'pre-wrap',
    fontWeight: theme.typography.fontWeightMedium,
  },
});

export default withStyles(styles)(ReadOnlyField);
