import { useMemo, useCallback } from 'react';
import { Typography, Grid, LinearProgress } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { formatQuantity } from 'utils/format.util';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import { WORK_ITEM_STATUS } from 'app/app.constants';
import { useSelector } from 'react-redux';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';
import { toExecutionSection } from 'utils/route.util';
import { Button as NavigationButton } from 'app/components/withNavigation';

const ExecuteTasksList = ({ tasks, classes, projectId }) => {
  const projectStatus = useSelector(getExecuteStatusFromState);
  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 2,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const status = row.original.get('status');
          return (
            <TableRowActionsCell>
              <WorkItemStatus isMobile={true} status={status} />
            </TableRowActionsCell>
          );
        },
      },
    ],
    [],
  );

  const renderTableRowComponent = useCallback(
    ({ item, ...rest }) => {
      return (
        <NavigationButton
          to={toExecutionSection(projectId, item.get('taskId'))}
          className={classes.button}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Grid container {...rest}>
            <Grid item xs={12} className={classes.rowSize}>
              <Grid item xs={1}>
                <WorkItemStatus isMobile={true} status={item.get('status')} />
              </Grid>
              <Grid item xs={10} className={classes.taskContent}>
                <Grid item xs={12}>
                  <Typography
                    noWrap
                    className={classes.fontMedium}
                    variant="body2"
                  >
                    {item.get('runNumber')}. {item.get('title')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    noWrap
                    className={classes.fontSmaller}
                    variant="body2"
                    color="textSecondary"
                  >
                    {`TD: ${formatQuantity(
                      item.get('targetDepth'),
                    )}  •  Est. Duration: ${item.get('estimated')}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <ChevronRightIcon />
              </Grid>
            </Grid>
            {item.get('status') === WORK_ITEM_STATUS.STARTED && (
              <LinearProgress
                title={`Progress: ${projectStatus.get('currentTaskProgress')}%`}
                value={projectStatus.get('currentTaskProgress') ?? 0}
                variant="determinate"
                style={{
                  height: '2px',
                  width: '100%',
                }}
              />
            )}
          </Grid>
        </NavigationButton>
      );
    },
    [classes, projectStatus, projectId],
  );

  return (
    <Table
      displayHeaders={false}
      items={tasks}
      columns={columns}
      useGlobalFilter={false}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

const styles = (theme) => {
  return {
    fontMedium: {
      fontSize: 'medium',
    },
    fontSmaller: {
      fontSize: 'smaller',
    },
    rowSize: {
      display: 'flex',
      flexDirection: 'row',
      height: theme.spacing(10),
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.table.row.main,
    },
    taskContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: theme.spacing(2),
    },
    button: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: theme.spacing(0),
      textTransform: 'none',
    },
  };
};

export default withStyles(styles)(ExecuteTasksList);
