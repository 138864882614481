import { useEffect, useState } from 'react';

export function useHandleToolbarHeight() {
  const [toolbarHeight, setToolbarHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const toolbar = document.querySelector('.rdw-editor-toolbar');
      if (toolbar) {
        const height = toolbar.offsetHeight;
        setToolbarHeight(height);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    // Clean up function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return toolbarHeight;
}
