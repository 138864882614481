import { Table } from 'altus-ui-components';

import { EMPTY_MAP } from 'app/app.constants';

const FluidEditorHeader = (props) => {
  const columns = [
    {
      xs: 6,
      Header: 'Fluid Identification',
    },
    {
      xs: 6,
      Header: 'Surface Properties',
    },
  ];

  return <Table items={EMPTY_MAP} columns={columns} {...props} />;
};

export default FluidEditorHeader;
