import { compose } from 'redux';
import { connect } from 'react-redux';
import Add from '@material-ui/icons/Add';
import { Route, Switch } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { useCallback, useContext, useMemo } from 'react';

import { BasePage } from 'altus-ui-components';

import {
  onLoad,
  onUnload,
  selectActivity,
  getProjectStatus,
  toggleCreateActivityModal,
  sortActivitiesWithNotification,
} from 'features/projects/activities/activities.actions';

import {
  getAllTasks,
  getExecuteStatusFromState,
} from 'features/projects/activities/activities.selectors';

import {
  EMPTY_MAP,
  PROJECT_STATUS,
  WORK_ITEM_STATUS,
  ProjectPermission,
} from 'app/app.constants';

import {
  ACTIVITIES_ACTIONS,
  CREATE_ACTIVITY_MODE,
} from 'features/projects/activities/activities.constants';

import routePaths from 'app/routePaths';
import { Fab } from 'app/components/withTooltip';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import HasProjectPermission from 'app/components/HasProjectPermission';
import withProjectPermission from 'app/components/withProjectPermission';
import ActivityList from 'features/projects/activities/components/ActivityList';
import { useProjectPermissions } from 'app/hooks/authorization/useProjectPermissions';
import { useTaskActivities } from 'features/projects/activities/hooks/useTaskActivities';
import ActivityListHeader from 'features/projects/activities/components/ActivityListHeader';
import { useRealTimeNotifications } from 'features/projects/activities/hooks/useRealTimeNotifications';
import CreateActivityModalContainer from 'features/projects/activities/components/CreateActivityModalContainer';
import { ExecutionRootContainerContext } from '../../ExecutionRootContainer';
import { EditActivityModalContainerWithNotification } from 'features/projects/activities/components/editActivity/EditActivityModalContainerWithNotification';

const CreateActivityContainer = ({
  execute,
  classes,
  selectedTask,
  displayCompleteTaskBtn,
  dispatchToggleCreateActivityModal,
}) => {
  const status = execute.get('status');

  const disabled =
    status === PROJECT_STATUS.REPORT ||
    displayCompleteTaskBtn ||
    (selectedTask && selectedTask.get('status') > WORK_ITEM_STATUS.STARTED);

  return (
    <HasProjectPermission permissions={ProjectPermission.CREATE_ACTIVITY}>
      <Fab
        color="primary"
        disabled={disabled}
        className={classes.create}
        title={disabled ? undefined : 'Add activity to this task'}
        onClick={() =>
          dispatchToggleCreateActivityModal(CREATE_ACTIVITY_MODE.NORMAL)
        }
      >
        <Add />
      </Fab>
    </HasProjectPermission>
  );
};

const ExecutionActivitiesContainer = ({
  tasks,
  taskId,
  execute,
  classes,
  dataState,
  projectId,
  dispatchSort,
  dispatchSelectActivity,
  dispatchToggleCreateActivityModal,
}) => {
  const status = execute.get('status');

  const selectedTask = useMemo(
    () =>
      tasks
        .toMap()
        .mapKeys((_, task) => task.get('taskId').toString())
        .get(taskId?.toString()),
    [tasks, taskId],
  );

  const connection = useContext(ExecutionRootContainerContext);

  const activities = useTaskActivities(taskId) ?? EMPTY_MAP;

  const onSortEnd = useCallback(
    (indices) => dispatchSort(projectId, taskId, indices, connection),
    [taskId, projectId, dispatchSort, connection],
  );

  const [
    goBack,
    completeActivity,
    createActivity,
    deleteActivity,
    updateActivity,
    updateActivityTimes,
    pauseActivity,
    createNewPointInTimeActivity,
    duplicateActivity,
  ] = useRealTimeNotifications(projectId, taskId, connection);

  const nextActivity = execute.getIn(['next', 'activity']);
  const currentActivity = execute.getIn(['current', 'activity']);
  const previousActivity = execute.getIn(['previous', 'activity']);
  const currentTaskId = execute.getIn(['current', 'task', 'id']) ?? 0;

  const displayCompleteTaskBtn =
    execute.get('currentTaskProgress') === 100 &&
    ((!currentActivity && previousActivity && !nextActivity) ||
      activities.size === 0) &&
    parseInt(taskId) === currentTaskId;

  const { hasPermission: hasReadActivityCommentsPermission } =
    useProjectPermissions(ProjectPermission.READ_ACTIVITY_COMMENTS_EXECUTE);

  const activitiesLoading = dataState.isLoading();

  const disabled = useMemo(() => {
    const taskStatus = selectedTask?.get('status');
    return taskStatus && taskStatus >= WORK_ITEM_STATUS.COMPLETED;
  }, [selectedTask]);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs wrap="nowrap">
        <Grid item xs={12} component={Box}>
          <Switch>
            <Route exact path={routePaths.execution.tabs.activities}>
              <ActivityListHeader />
              <ActivityList
                key={taskId}
                lockAxis="y"
                useDragHandle
                taskId={taskId}
                goBack={goBack}
                execute={execute}
                lockToContainerEdges
                projectId={projectId}
                projectStatus={status}
                onSortEnd={onSortEnd}
                activities={activities}
                activitiesLoading={activitiesLoading}
                selectActivity={dispatchSelectActivity}
                completeActivity={completeActivity}
                toggleCreateActivityModal={dispatchToggleCreateActivityModal}
                createNewPointInTimeActivity={createNewPointInTimeActivity}
                updateActivity={updateActivity}
                pauseActivity={pauseActivity}
                updateActivityTimes={updateActivityTimes}
                duplicateActivity={duplicateActivity}
              />
            </Route>
          </Switch>
          <Route exact path={routePaths.execution.tabs.activities}>
            <CreateActivityModalContainer
              createActivity={createActivity}
              showWaitingActivities={false}
            />
            <EditActivityModalContainerWithNotification
              projectId={projectId}
              taskId={taskId}
              disabled={disabled}
              activities={activities.toList()}
              displayComments={hasReadActivityCommentsPermission}
              dispatchDeleteActivity={deleteActivity}
              connection={connection}
            />
            <CreateActivityContainer
              execute={execute}
              classes={classes}
              selectedTask={selectedTask}
              displayCompleteTaskBtn={displayCompleteTaskBtn}
              dispatchToggleCreateActivityModal={
                dispatchToggleCreateActivityModal
              }
            />
          </Route>
        </Grid>
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => {
  return {
    create: {
      zIndex: 2,
      position: 'absolute',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
    },
    stickyHeader: {
      zIndex: 2,
      position: 'sticky',
      top: 0,
    },
    registerObsCardBtn: {
      textTransform: 'none',
    },
    basePageChildren: {
      overflow: 'auto',
      padding: '0px',
      marginTop: '6px',
    },
  };
};

const mapStateToProps = (state) => {
  return {
    tasks: getAllTasks(state),
    execute: getExecuteStatusFromState(state),
    dataState: getSummarizedDataStateFromState(
      state,
      ACTIVITIES_ACTIONS.GET_TASK_ACTIVITIES,
      ACTIVITIES_ACTIONS.ACTIVITIES_INITIALIZED,
      ACTIVITIES_ACTIONS.UPDATE_ACTIVITY,
      ACTIVITIES_ACTIONS.INITIATE_PAUSE_ACTIVITY,
      ACTIVITIES_ACTIONS.CONFIRMED_ACTIVITY_GO_BACK,
    ),
  };
};

const mapDispatchToProps = {
  dispatchOnLoad: onLoad,
  dispatchOnUnload: onUnload,
  dispatchSort: sortActivitiesWithNotification,
  dispatchSelectActivity: selectActivity,
  dispatchToggleCreateActivityModal: toggleCreateActivityModal,
  dispatchGetProjectStatus: getProjectStatus,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withProjectPermission(ProjectPermission.EXECUTE),
)(ExecutionActivitiesContainer);
