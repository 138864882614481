import { useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';

import { LoadingOverlay } from 'altus-ui-components';

import { getSummarizedDataStateFromState } from 'app/app.selectors';
import TaskChart from 'features/projects/tasks/components/TaskChart';
import { ACTIVITIES_ACTIONS } from 'features/projects/activities/activities.constants';
import { getAllProjectActivities } from 'features/projects/activities/activities.actions';
import { createProjectAllActivitiesSelector } from 'features/projects/activities/activities.selectors';

const TasksChartContainer = ({
  tasks,
  projectId,
  dataState,
  dispatchGetActivities,
  showActivities,
  showDefaultButtons,
  showNavigatorChart,
  chartBackgroundColor,
}) => {
  useEffect(() => {
    dispatchGetActivities(projectId);
  }, [dispatchGetActivities, projectId]);

  const activitiesSelector = useMemo(
    () => createProjectAllActivitiesSelector(projectId),
    [projectId],
  );

  const activities = useSelector(activitiesSelector);

  return (
    <>
      <TaskChart
        tasks={tasks}
        activities={activities}
        showActivities={showActivities}
        showDefaultButtons={showDefaultButtons}
        showNavigatorChart={showNavigatorChart}
        chartBackgroundColor={chartBackgroundColor}
      />
      <LoadingOverlay dataState={dataState} />
    </>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    ACTIVITIES_ACTIONS.GET_ALL_PROJECT_ACTIVITIES,
  ),
});

const mapDispatchToProps = {
  dispatchGetActivities: getAllProjectActivities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TasksChartContainer);
