import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { useState, useEffect, useCallback } from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import {
  Grid,
  Badge,
  Dialog,
  IconButton,
  DialogContent,
} from '@material-ui/core';

import { Button } from 'app/components/withTooltip';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { editToolImage } from 'features/equipment/equipment.actions';
import { ExifOrientation } from 'features/projects/tool/tool.constants';
import { EQUIPMENT_ACTIONS } from 'features/equipment/equipment.constants';
import { convertToPreferredUnits } from 'features/equipment/editToolImage/editToolImage.helpers';
import EditToolImageModalHeader from 'features/equipment/editToolImage/EditToolImageModalHeader';
import ToolImageContainerWithLining from 'features/equipment/editToolImage/ToolImageContainerWithLining';

export const SCALES = {
  VERTICAL_SCALE: 'VERTICAL_SCALE',
  HORIZONTAL_SCALE: 'HORIZONTAL_SCALE',
};

const EditToolImageModal = ({ tool, open, toggleModal, classes }) => {
  const dispatch = useDispatch();
  const [scaleFactor, setScaleFactor] = useState({ width: 1, height: 1 });
  const [scaleAttempted, setScaleAttempred] = useState(false);

  const handleScale = (widthFactor, heightFactor) => {
    setScaleFactor((prevScale) => ({
      width: prevScale.width * widthFactor,
      height: prevScale.height * heightFactor,
    }));
    setScaleAttempred(true);
  };

  useEffect(() => {
    setScaleFactor(() => ({
      width: 1,
      height: 1,
    }));
  }, [open]);

  const [convertedTool, setConvertedTool] = useState(tool.toJS());

  useEffect(() => {
    const toolWithConvertedUnits = convertToPreferredUnits(tool.toJS());
    setConvertedTool(toolWithConvertedUnits);
  }, [tool]);

  const [valuesFromEdit, setValuesFromEdit] = useState({
    horizontalScale: 1,
    verticalScale: 1,
    rotation: 'None',
  });

  // DEBUG PURPOSES
  // useEffect(() => {
  //   console.log(valuesFromEdit);
  // }, [valuesFromEdit]);

  const handleSetValuesFromEdit = (scale, value) => {
    setValuesFromEdit((prev) => {
      let updatedState = { ...prev };

      if (scale === SCALES.HORIZONTAL_SCALE) {
        updatedState.verticalScale = value;
      } else if (scale === SCALES.VERTICAL_SCALE) {
        updatedState.horizontalScale = value;
      }
      return updatedState;
    });
  };

  const handleEdit = useCallback(
    (values) => {
      dispatch(editToolImage(values, tool.get('toolImageId')));
    },
    [dispatch, tool],
  );

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="xl"
      onClose={toggleModal}
      classes={{ paperScrollPaper: classes.root }}
    >
      <EditToolImageModalHeader toggleModal={toggleModal} />
      <DialogContent className={classes.contentRoot}>
        <Grid item container xs={12} className={classes.content}>
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="flex-end"
            className={classes.topArrows}
          >
            <Grid item>
              <Badge
                badgeContent="10x"
                color="primary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <IconButton onClick={() => handleScale(0.48, 1)}>
                  <ArrowBackIcon />
                </IconButton>
              </Badge>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleScale(0.98, 1)}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleScale(1.02, 1)}>
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Badge
                badgeContent="10x"
                color="primary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <IconButton onClick={() => handleScale(1.9, 1)}>
                  <ArrowForwardIcon />
                </IconButton>
              </Badge>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.toolContainer}>
            <Grid item xs={1}></Grid>
            <Grid
              item
              container
              xs={10}
              justifyContent="center"
              alignItems="center"
              className={classes.toolImageWrapper}
            >
              <Grid
                item
                container
                xs={12}
                className={classes.toolImageContainer}
              >
                <ToolImageContainerWithLining
                  toolImageUrl={tool.get('imageUrl')}
                  orientation={ExifOrientation.DEGREES_90_MIRRORED}
                  style={{
                    width: '100%',
                    maxWidth: '100%',
                  }}
                  scaleFactor={scaleFactor}
                  tool={convertedTool}
                  ogTool={tool.toJS()}
                  handleSetValuesFromEdit={handleSetValuesFromEdit}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={1}
              direction="column"
              alignItems="flex-start"
            >
              <Grid item>
                <Badge
                  badgeContent="10x"
                  color="primary"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <IconButton onClick={() => handleScale(1, 1.9)}>
                    <ArrowBackIcon style={{ transform: 'rotate(90deg)' }} />
                  </IconButton>
                </Badge>
              </Grid>
              <Grid item>
                <IconButton onClick={() => handleScale(1, 1.02)}>
                  <ArrowBackIcon style={{ transform: 'rotate(90deg)' }} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={() => handleScale(1, 0.98)}>
                  <ArrowBackIcon style={{ transform: 'rotate(-90deg)' }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Badge
                  badgeContent="10x"
                  color="primary"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <IconButton onClick={() => handleScale(1, 0.48)}>
                    <ArrowBackIcon style={{ transform: 'rotate(-90deg)' }} />
                  </IconButton>
                </Badge>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button
                color="secondary"
                variant="text"
                className={classes.cancelBtn}
                onClick={() => {
                  toggleModal();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                className={classes.saveBtn}
                onClick={() => {
                  handleEdit(valuesFromEdit);
                }}
                disabled={!scaleAttempted}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const styles = (theme) => ({
  contentRoot: {
    width: '100%',
  },
  toolImageWrapper: {
    height: '100%',
  },
  toolImageContainer: {
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.darkGrey,
    height: '100%',
    width: '70%',
    maxWidth: '100%',
  },
  content: {
    height: '100%',
  },
  root: {
    height: '90%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toolContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
    height: '40%',
  },
  saveBtn: {
    height: 'fit-content',
  },
  cancelBtn: {
    color: theme.palette.primary.newLight,
    height: 'fit-content',
  },
});

export default compose(
  connect((state) => ({
    dataState: getSummarizedDataStateFromState(
      state,
      EQUIPMENT_ACTIONS.CREATE_TOOL,
    ),
  })),
  withStyles(styles),
)(EditToolImageModal);
