import classNames from 'classnames';
import { isImmutable } from 'immutable';
import { Field } from 'redux-form/immutable';
import withStyles from '@material-ui/styles/withStyles';
import { MenuItem, Checkbox, Chip } from '@material-ui/core';

import TextField from 'app/components/form/TextField';
import RunNumber from 'features/projects/components/RunNumber';

const TaskMultipleContingenciesSelect = ({
  task,
  input,
  tasks,
  label,
  classes,
  disabled,
  required,
}) => {
  const { value, name } = input;

  const valueJS = (isImmutable(value) ? value.toJS() : value) || [];

  const filteredTasks = tasks.filter((element) => {
    return (
      element.status !== 32 && element.status !== 33 && element.status !== 33
    );
  });

  return (
    <Field
      select
      name={name}
      input={{
        ...input,
        value: valueJS,
      }}
      label={label}
      disabled={disabled}
      component={TextField}
      SelectProps={{
        classes: {
          selectMenu: classes.selectMenu,
        },
        multiple: true,
        displayEmpty: !required,
        renderValue: () => {
          return valueJS.map((taskId) => {
            const task = tasks.find((task) => task.id === taskId);

            return (
              <Chip className={classes.chip} key={taskId} label={task.title} />
            );
          });
        },
      }}
    >
      {filteredTasks.map(({ id, title, runNumber, sequence }) => {
        const disabled = sequence <= task.sequence;

        return (
          <MenuItem disabled={disabled} key={id} value={id}>
            <Checkbox
              classes={{
                root: classNames({
                  [classes.checkboxHidden]: disabled,
                }),
              }}
              color="default"
              checked={valueJS.some((departmentId) => departmentId === id)}
            />
            <RunNumber
              size={25}
              classes={{
                root: classes.runNumber,
              }}
            >
              {runNumber}
            </RunNumber>
            {title}
          </MenuItem>
        );
      })}
    </Field>
  );
};

export default withStyles((theme) => ({
  chip: {
    margin: 4,
    marginLeft: 0,
    height: 27,
  },
  selectMenu: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  runNumber: {
    marginRight: theme.spacing(1.5),
  },
  checkboxHidden: {
    visibility: 'hidden',
  },
}))(TaskMultipleContingenciesSelect);
