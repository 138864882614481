import { compose } from 'redux';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, AppBar, Button, Typography } from '@material-ui/core';

const TaskToolstringEditorAppBar = ({ classes, onClose, toolstringName }) => (
  <AppBar
    key="app-bar"
    color="default"
    position="static"
    classes={{
      root: classes.root,
    }}
  >
    <Grid container alignItems="center">
      <Grid item container xs justifyContent="flex-start" alignItems="center">
        <Grid item fontSize="large" component={EditIcon} />
        <Grid
          item
          variant="h6"
          component={Typography}
          classes={{ root: classes.toolstringName }}
        >
          <i>{toolstringName}</i>
        </Grid>
      </Grid>
      <Grid item container xs zeroMinWidth justifyContent="center">
        <Grid item component={Typography} variant="h6">
          EDITING BHA
        </Grid>
      </Grid>
      <Grid item container xs justifyContent="flex-end">
        <Grid
          item
          color="secondary"
          component={Button}
          variant="contained"
          onClick={onClose}
        >
          Exit BHA Editor
        </Grid>
      </Grid>
    </Grid>
  </AppBar>
);

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
  toolstringName: {
    paddingLeft: theme.spacing(1),
  },
});

TaskToolstringEditorAppBar.propTypes = {
  toolstringName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(TaskToolstringEditorAppBar);
