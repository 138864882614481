import { compose } from 'redux';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { memo, useState, useRef } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Fullscreen, FullscreenExit } from '@material-ui/icons';
import { Grid, Typography, IconButton } from '@material-ui/core';

import { useFullscreen } from 'altus-hooks';
import { Tab, Tabs } from 'altus-ui-components';

import { EMPTY_MAP } from 'app/app.constants';
import { simulationDashboardRoutes } from 'app/routes';
import DashboardIcon from 'app/components/Icons/DashboardIcon';
import ProjectRoutesContainer from 'app/components/ProjectRoutesContainer';
import SimulationResultsChart from 'features/projects/tasks/task/simulation/components/SimulationResultsChart';
import SimulationResultsTable from 'features/projects/tasks/task/simulation/components/SimulationResultsTable';
import {
  SimulationCharts,
  SimulationDirection,
} from 'features/projects/tasks/task/simulation/simulation.constants';
import { createSimulationResultsByDepthAndDirectionSelector } from 'features/projects/tasks/task/simulation/simulation.selectors';

const tabs = SimulationCharts.map(({ title, ...rest }) => ({
  title,
  label: title,
  ...rest,
}));

const SimulationResultsContainer = ({
  taskId,
  classes,
  projectId,
  simulation,
  simulationId,
}) => {
  const fullScreenRef = useRef(null);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [isFullscreen, toggleFullscreen] = useFullscreen(fullScreenRef);
  // table tabs
  const [activeTableTab, setActiveTableTab] = useState(
    SimulationDirection.RUN_IN_HOLE,
  );

  // for regular simulations without Set/Retreive
  const simulationResultsByDepth =
    useSelector(
      createSimulationResultsByDepthAndDirectionSelector(simulationId),
    ) ?? EMPTY_MAP;

  // for simulations with Set/Retreive
  const simulationResultsByDepthRih =
    useSelector(
      createSimulationResultsByDepthAndDirectionSelector(
        simulationId,
        SimulationDirection.RUN_IN_HOLE,
      ),
    ) ?? EMPTY_MAP;
  const simulationResultsByDepthPooh =
    useSelector(
      createSimulationResultsByDepthAndDirectionSelector(
        simulationId,
        SimulationDirection.PULL_OUT_OF_HOLE,
      ),
    ) ?? EMPTY_MAP;

  if (
    !simulationResultsByDepth.size &&
    !simulationResultsByDepthRih.size &&
    !simulationResultsByDepthPooh.size
  ) {
    return (
      <Grid item xs container justifyContent="center" alignItems="center">
        <Typography
          align="center"
          variant="body2"
          className={classes.noResultsMessage}
        >
          Click <b>Simulate Run</b> to display the results...
        </Typography>
      </Grid>
    );
  }

  const workingLimitSafetyFactor = simulation?.get('workingLimitSafetyFactor');

  const activeTableTabHandler = (activeTableTab) => {
    switch (activeTableTab) {
      default:
        return null;
      case SimulationDirection.RUN_IN_HOLE:
        return (
          <SimulationResultsTable
            simulationResults={simulationResultsByDepthRih}
          />
        );
      case SimulationDirection.PULL_OUT_OF_HOLE:
        return (
          <SimulationResultsTable
            simulationResults={simulationResultsByDepthPooh}
          />
        );
    }
  };

  return (
    <Grid item xs container>
      <Grid container direction="column" spacing={2}>
        <Grid item container direction="column" ref={fullScreenRef}>
          <Grid container direction="row">
            <Grid item xs={10}>
              <Tabs
                variant="scrollable"
                disableUnderline
                value={activeTab}
                onChange={(_, tab) => setActiveTab(tab)}
              >
                {tabs.map((tab, index) => (
                  <Tab key={index} value={tab} label={tab.label} />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs container justifyContent="flex-end">
              <Grid item>
                <IconButton
                  color="default"
                  onClick={toggleFullscreen}
                  title={isFullscreen ? 'Exit fullscreen' : 'Fullscreen'}
                >
                  {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
                </IconButton>
              </Grid>
              <ProjectRoutesContainer
                routes={simulationDashboardRoutes}
                render={(validRoutes) =>
                  validRoutes.map((route) => (
                    <Grid item key={route.path}>
                      <IconButton
                        color="default"
                        target="_blank"
                        component={Link}
                        title="Go to dashboard"
                        to={generatePath(route.path, {
                          taskId,
                          projectId,
                          simulationId,
                        })}
                      >
                        <DashboardIcon />
                      </IconButton>
                    </Grid>
                  ))
                }
              />
            </Grid>
          </Grid>
          <Grid item xs container direction="column">
            <Grid
              item
              xs={12}
              container
              className={classNames(classes.chart, {
                [classes.chartRegular]: !isFullscreen,
                [classes.chartFullscreen]: isFullscreen,
              })}
            >
              <SimulationResultsChart
                simulationResults={simulationResultsByDepth}
                simulationResultsRih={simulationResultsByDepthRih}
                simulationResultsPooh={simulationResultsByDepthPooh}
                operationLoadPercent={workingLimitSafetyFactor}
                tab={activeTab}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container>
          {/* Multiple result set */}
          {simulationResultsByDepthRih !== EMPTY_MAP &&
            simulationResultsByDepthPooh !== EMPTY_MAP &&
            simulationResultsByDepth === EMPTY_MAP && (
              <Grid item container direction="column">
                <Tabs
                  disableUnderline
                  value={activeTableTab}
                  onChange={(_, tab) => setActiveTableTab(tab)}
                >
                  <Tab label="BHA v1" value={SimulationDirection.RUN_IN_HOLE} />
                  <Tab
                    label="BHA v2"
                    value={SimulationDirection.PULL_OUT_OF_HOLE}
                  />
                </Tabs>
                <Grid item>{activeTableTabHandler(activeTableTab)}</Grid>
              </Grid>
            )}

          {/* Single result set */}
          {simulationResultsByDepthRih === EMPTY_MAP &&
            simulationResultsByDepthPooh === EMPTY_MAP &&
            simulationResultsByDepth !== EMPTY_MAP && (
              <SimulationResultsTable
                simulationResults={simulationResultsByDepth}
              />
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  noResultsMessage: {
    color: theme.palette.text.hint,
  },
  chart: {
    overflow: 'hidden',
    '& > div': {
      width: '100%',
      overflow: 'hidden',
    },
  },
  chartFullscreen: {
    '& > div': {
      height: '100%',
    },
  },
  chartRegular: {
    '& > div': {
      height: 750,
    },
  },
});

export default compose(memo, withStyles(styles))(SimulationResultsContainer);
