import { compose } from 'redux';
import React, { memo } from 'react';

import {
  Box,
  Grid,
  Radio,
  Dialog,
  Button,
  Divider,
  RadioGroup,
  Typography,
  FormControl,
  DialogContent,
  FormControlLabel,
} from '@material-ui/core';

import { ModalHeader, LoadingButton } from 'altus-ui-components';

import { formatQuantity } from 'utils/format.util';
import { UNITS, UnitSystem } from 'app/app.constants';
import withStyles from '@material-ui/styles/withStyles';
import ReadOnlyField from 'app/components/ReadOnlyField';
import WellboreTrajectoryTableView from 'app/components/WellboreTrajectory/WellboreTrajectoryTableView';

const WellboreTrajectoryPreviewModal = ({
  open,
  classes,
  onClose,
  wellName,
  onSubmit,
  fieldName,
  submitting,
  unitSystem,
  trajectoryPreview,
  onUnitSystemChange,
}) => {
  const unit = unitSystem === UnitSystem.METRIC ? UNITS.METER : UNITS.FEET;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onClose}
      classes={{ paperScrollPaper: classes.root }}
    >
      <ModalHeader title="Import Survey" toggleModal={onClose} />
      <DialogContent>
        <Grid container className={classes.content} spacing={2}>
          <Grid xs={9} item container direction="column">
            <Grid item container alignItems="center" className={classes.header}>
              <Grid xs={8} item>
                <Typography variant="h6">{`${fieldName} - ${wellName}`}</Typography>
              </Grid>
              <Grid item xs>
                <ReadOnlyField
                  margin="none"
                  size="small"
                  label="Measured Depth"
                  value={formatQuantity(trajectoryPreview.get('measuredDepth'))}
                />
              </Grid>
              <Grid item xs>
                <ReadOnlyField
                  margin="none"
                  size="small"
                  label="Vertical Depth"
                  value={formatQuantity(trajectoryPreview.get('verticalDepth'))}
                />
              </Grid>
            </Grid>
            <Grid xs item container className={classes.tableContainer}>
              <WellboreTrajectoryTableView
                unit={unit}
                trajectoryPoints={trajectoryPreview.get('trajectoryPoints')}
              />
            </Grid>
          </Grid>
          <Grid xs={3} item container direction="column">
            <Grid item container alignItems="center" className={classes.header}>
              <Grid item component={Typography} variant="h6">
                Unit System
              </Grid>
            </Grid>
            <Divider />
            <Grid item component={Box} paddingBottom={2} paddingTop={2}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={unitSystem}
                  onChange={(event) =>
                    onUnitSystemChange(Number(event.target.value))
                  }
                >
                  <FormControlLabel
                    label="Metric"
                    control={<Radio color="primary" size="small" />}
                    value={UnitSystem.METRIC}
                  />
                  <FormControlLabel
                    label="Imperial"
                    control={<Radio color="primary" size="small" />}
                    value={UnitSystem.IMPERIAL}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                Selecting the survey's correct unit of measure is imperative to
                ensure correct functionality.
              </Typography>
            </Grid>
            <Grid
              xs
              item
              container
              spacing={1}
              alignContent="flex-end"
              justifyContent="flex-end"
            >
              <Grid item>
                <Button onClick={onClose}>Cancel</Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  loading={submitting}
                  disabled={!trajectoryPreview.size || submitting}
                >
                  Import
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const styles = (theme) => ({
  root: {
    height: '75vh',
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  header: {
    minHeight: theme.spacing(6),
  },
});

export default compose(
  memo,
  withStyles(styles),
)(WellboreTrajectoryPreviewModal);
