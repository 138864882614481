import { withStyles } from '@material-ui/core';
import { TableCell } from 'altus-ui-components';

const SimulationResultsTableCell = ({ classes, ...rest }) => (
  <TableCell classes={{ root: classes.cell }} {...rest} />
);

const styles = () => ({
  cell: {
    textAlign: 'center',
  },
});

export default withStyles(styles)(SimulationResultsTableCell);
