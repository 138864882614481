import { compose } from 'redux';
import { Chip } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import { useModal } from 'altus-modal';
import { MultiSelectModal } from 'altus-ui-components';

import { EMPTY_LIST } from 'app/app.constants';
import TextFieldV2 from 'app/components/form/TextFieldV2';

const modalId = 'SERVICE_SELECT_MODAL_ID';

const validate = () => true;

const getSubmitLabel = (selectedItems) =>
  selectedItems.size ? `Save (${selectedItems.size})` : 'Save';

const getServiceName = (department) => department.get('name');

const ServiceSelectField = ({ label, input, classes, disabled, services }) => {
  const { onChange, value = EMPTY_LIST, name } = input;

  const [isOpen, toggleModal] = useModal(modalId);

  const renderValue = useCallback(
    () =>
      value
        .sortBy((service) => service.get('name'))
        .map((service) => (
          <Chip
            color="primary"
            className={classes.chip}
            key={service.get('id')}
            label={service.get('name')}
          />
        )),
    [value, classes],
  );

  const initialSelectedItems = useMemo(
    () =>
      value
        .map((service) => service.get('id'))
        .map((serviceId) => services.get(serviceId.toString()))
        .toSet(),
    [value, services],
  );

  const onSubmit = useCallback(
    (selectedServices) => {
      onChange(selectedServices);
      toggleModal();
    },
    [onChange, toggleModal],
  );

  return (
    <>
      <TextFieldV2
        select
        name={name}
        label={label}
        disabled={disabled}
        value={value.toArray()}
        SelectProps={{
          open: false,
          renderValue,
          multiple: true,
          onOpen: toggleModal,
        }}
      >
        {EMPTY_LIST.toArray()}
      </TextFieldV2>
      <MultiSelectModal
        open={isOpen}
        items={services}
        onSubmit={onSubmit}
        validate={validate}
        onClose={toggleModal}
        title="Sub Objective"
        getName={getServiceName}
        getSubmitLabel={getSubmitLabel}
        initialSelectedItems={initialSelectedItems}
      />
    </>
  );
};

const styles = (theme) => ({
  chip: {
    height: 27,
    marginLeft: 0,
    margin: theme.spacing(0.5),
  },
});

export default compose(withStyles(styles))(ServiceSelectField);
