import { call, takeEvery, put } from 'redux-saga/effects';
import { callAsync } from 'app/sagas/helperSagas';
import {
  requestSimulationDynamicToolParameters,
  receiveSimulationDynamicToolParameters,
  receiveSimulationDynamicToolParameter,
} from 'features/projects/tasks/task/simulation/dynamicToolParameters/simulationDynamicToolParameter.actions';
import { FormikFormStatus } from 'app/app.constants';
import simulationService from 'services/simulation.service';
import { ACTIONS } from 'features/projects/tasks/task/simulation/dynamicToolParameters/simulationDynamicToolParameter.constants';
import { requestSimulationAsync } from '../simulation.sagas';

export function* requestSimulationDynamicToolParametersAsync(action) {
  const { projectId, taskId, simulationId } = action;

  const simulationDynamicToolParameters = yield call(
    simulationService.getSimulationDynamicToolParameters,
    projectId,
    taskId,
    simulationId,
  );

  yield put(
    receiveSimulationDynamicToolParameters(simulationDynamicToolParameters),
  );

  return simulationDynamicToolParameters;
}

export function* requestUpdateSimulationDynamicToolParameterAsync(action) {
  const {
    taskId,
    payload,
    projectId,
    setStatus,
    simulationId,
    setSubmitting,
    simulationDynamicToolParameterId,
    refreshParameters,
  } = action;

  try {
    const simulationDynamicToolParameter = yield call(
      simulationService.updateSimulationDynamicToolParameter,
      projectId,
      taskId,
      simulationId,
      simulationDynamicToolParameterId,
      payload,
    );

    // If refresh parameters is set to true - fetch all parameters for the simulation to refresh the client
    // This can be used when updating a single parameter causes other parameters to change
    // Otherwise update the current parameter with the updated result
    if (refreshParameters) {
      yield put(
        requestSimulationDynamicToolParameters(projectId, taskId, simulationId),
      );
    } else {
      yield put(
        receiveSimulationDynamicToolParameter(simulationDynamicToolParameter),
      );
    }

    // Request simulation - as updating a tool parameter will reset the simulation status to outdated
    yield call(requestSimulationAsync, action);

    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);

    return simulationDynamicToolParameter;
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);

    throw error;
  }
}

export default function* root() {
  yield takeEvery(
    ACTIONS.REQUEST_SIMULATION_TOOL_PARAMETERS,
    callAsync,
    requestSimulationDynamicToolParametersAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_UPDATE_SIMULATION_TOOL_PARAMETER,
    callAsync,
    requestUpdateSimulationDynamicToolParameterAsync,
  );
}
