import { compose } from 'redux';
import EditIcon from '@material-ui/icons/Edit';
import { Grid, Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import withStyles from '@material-ui/styles/withStyles';

import { Fab } from 'app/components/withNavigation';
import { toTaskToolstringEdit } from 'utils/route.util';
import ToolstringPreviewImages from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewImages';
import ToolstringPreviewDetails from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewDetails';
import ToolstringPreviewContainer from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewContainer';

const TaskSimulationToolstringPreview = ({
  taskId,
  classes,
  canEdit,
  projectId,
  dataState,
  toolstring,
  toolstringId,
  isDataVerified,
  toolstringTools: toolstringItems,
  paddingTopPreview,
}) => (
  <ToolstringPreviewContainer
    dataState={dataState}
    paddingTopPreview={paddingTopPreview}
  >
    <ToolstringPreviewDetails toolstring={toolstring} />

    <Grid item className={classes.editIconAnchor}>
      {!!canEdit && !!toolstringId && (
        <Fab
          size="small"
          color="primary"
          title="Edit BHA"
          className={classes.editIcon}
          to={toTaskToolstringEdit(projectId, taskId, toolstringId)}
        >
          <EditIcon fontSize="small" />
        </Fab>
      )}
      {!isDataVerified && (
        <Tooltip title="There are unverified tools in this BHA">
          <WarningIcon className={classes.warningIcon} />
        </Tooltip>
      )}
    </Grid>

    <ToolstringPreviewImages toolstringItems={toolstringItems} />
  </ToolstringPreviewContainer>
);

const styles = (theme) => ({
  editIconAnchor: {
    position: 'relative',
  },
  editIcon: {
    right: 0,
    zIndex: 10,
    position: 'absolute',
    top: theme.spacing(1),
  },
  warningIcon: {
    left: 0,
    zIndex: 10,
    position: 'absolute',
    top: theme.spacing(1),
    color: theme.palette.warning.main,
  },
});

export default compose(withStyles(styles))(TaskSimulationToolstringPreview);
