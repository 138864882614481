import { useMemo, useCallback, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import { EMPTY_MAP } from 'app/app.constants';
import { useDispatch, useSelector } from 'react-redux';
import { createTaskActivitiesSelector } from 'features/projects/activities/activities.selectors';
// import { toExecutionSection } from 'utils/route.util';
// import { Button as NavigationButton } from 'app/components/withNavigation';
import { getTaskActivities } from 'features/projects/activities/activities.actions';

const MobileExecutionActivities = ({ classes, projectId, taskId }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTaskActivities(projectId, taskId));
  }, [taskId, dispatch, projectId]);
  const activitiesSelector = useMemo(
    () => createTaskActivitiesSelector(taskId),
    [taskId],
  );

  const taskActivities = useSelector(activitiesSelector);
  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 2,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const status = row.original.get('status');
          return (
            <TableRowActionsCell>
              <WorkItemStatus isMobile={true} status={status} />
            </TableRowActionsCell>
          );
        },
      },
    ],
    [],
  );

  const renderTableRowComponent = useCallback(
    ({ item, ...rest }) => {
      return (
        // <NavigationButton
        //     to={toExecutionSection(projectId)}
        //     className={classes.button}
        //     onClick={(event) => {
        //         event.stopPropagation();
        //     }}
        // >
        <Grid container {...rest}>
          <Grid item xs={12} className={classes.rowSize}>
            <Grid item xs={1}>
              {item.get('isWait') ? (
                <WorkItemStatus
                  isMobile={true}
                  status={item.get('status')}
                  isWaiting
                />
              ) : (
                <WorkItemStatus isMobile={true} status={item.get('status')} />
              )}
            </Grid>
            <Grid item xs={11} className={classes.taskContent}>
              <Grid item xs={12}>
                <Typography
                  noWrap
                  className={classes.fontMedium}
                  variant="body2"
                >
                  {item.getIn(['type', 'name'])}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  noWrap
                  className={classes.fontSmaller}
                  variant="body2"
                  color="textSecondary"
                >
                  {`Est. Duration: ${item.get('estimatedTime')}`}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid item xs={1}>
                                <ChevronRightIcon />
                            </Grid> */}
          </Grid>
        </Grid>
        // </NavigationButton>
      );
    },
    [
      classes,
      // projectId,
    ],
  );

  return (
    <Table
      displayHeaders={false}
      items={taskActivities ?? EMPTY_MAP}
      columns={columns}
      useGlobalFilter={false}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

const styles = (theme) => {
  return {
    fontMedium: {
      fontSize: 'medium',
    },
    fontSmaller: {
      fontSize: 'smaller',
    },
    rowSize: {
      display: 'flex',
      flexDirection: 'row',
      height: theme.spacing(10),
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.background.default,
    },
    taskContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: theme.spacing(2),
    },
    button: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: theme.spacing(0),
    },
  };
};

export default withStyles(styles)(MobileExecutionActivities);
