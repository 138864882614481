import { Grid, Typography } from '@material-ui/core';

import {
  formatToolstringTotalLength,
  formatToolstringTotalWeight,
} from 'features/projects/tasks/task/toolstring/toolstring.util';

import { formatValue } from 'utils/format.util';

const ToolstringDataValue = ({ value, label, renderValue }) => (
  <Grid container item justifyContent="space-between">
    <Grid item>
      <Typography color="textSecondary">{`${label}: `}</Typography>
    </Grid>
    <Grid item>
      {!value && (
        <Typography color="textSecondary">
          <i>N/A</i>
        </Typography>
      )}
      {!!value && <Typography>{renderValue(value)}</Typography>}
    </Grid>
  </Grid>
);

const ToolstringPreviewDetails = ({ toolstring }) => {
  const dataValues = [
    {
      value: toolstring.get('totalLength'),
      label: 'Total length',
      renderValue: (value) => formatToolstringTotalLength(value),
    },
    {
      value: toolstring.get('totalWeight'),
      label: 'Total weight',
      renderValue: (value) => formatToolstringTotalWeight(value),
    },
    {
      value: toolstring.get('maxOD'),
      label: 'Max OD',
      renderValue: (value) => formatValue(value.value, value.unit),
      //TODO should be formatFaction for inches
    },
    {
      value: toolstring.get('totalVolume'),
      label: 'Disp. Vol.',
      renderValue: (value) => formatValue(value.value, value.unit),
      //TODO was Units.M_3, should be converted
    },
  ];

  return (
    <Grid container item>
      {dataValues.map((dataValue, index) => (
        <ToolstringDataValue {...dataValue} key={index} />
      ))}
    </Grid>
  );
};

export default ToolstringPreviewDetails;
