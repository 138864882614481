import { select, call, put, takeEvery } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import { getFormFromState } from 'app/app.selectors';
import {
  ACTIVITIES_ACTIONS as ACTIONS,
  ACTIVITIES_ACTIONS,
  EDIT_ACTIVITY_FORM,
  EDIT_ACTIVITY_MODAL_ID,
} from 'features/projects/activities/activities.constants';
import activitiesService from 'services/activities.service';
import {
  getAllActivitiesForTask,
  Identity,
  sendProjectChangedNotification,
} from 'features/projects/activities/sagas/common.sagas';
import { invokeIfFunction } from 'utils/app.util';
import { APP_ACTIONS, NOTIFICATION_VARIANTS } from 'app/app.constants';
import { callAsync } from 'app/sagas/helperSagas';
import { addErrorMessageDuration } from 'features/projects/activities/sagas/utils';

export const toggleModal = ({
  modalId,
  force = false,
  activeIndex,
  payload,
  ...extra
}) => {
  return {
    type: APP_ACTIONS.TOGGLE_MODAL,
    modalId,
    force,
    payload,
    activeIndex,
    ...extra,
  };
};

function* updateActivityFormBase(action, notificationFn) {
  const actionExtended = addErrorMessageDuration(action, 5000);
  const {
    projectId,
    taskId,
    autosave,
    reset: resetForm,
    callback,
    connection,
  } = actionExtended;

  const { values, initial } = (yield select(
    getFormFromState,
    EDIT_ACTIVITY_FORM.ID,
  )).toJS();
  try {
    if (!isEqual(initial, values) || !autosave) {
      const payload = yield call(
        activitiesService.updateActivity,
        projectId,
        taskId,
        values,
      );
      yield call(getAllActivitiesForTask, projectId, taskId);
      if (!autosave) {
        yield put(toggleModal({ modalId: EDIT_ACTIVITY_MODAL_ID }));
      } else {
        invokeIfFunction(callback);
      }
      yield call(notificationFn, connection, projectId, taskId);
      yield put({
        autosave,
        activityId: initial.id,
        type: ACTIVITIES_ACTIONS.INITIATE_UPDATE_ACTIVITY_CONFIRMATION,
        notification: {
          [NOTIFICATION_VARIANTS.SUCCESS]:
            'The activity was successfully updated',
        },
        payload,
      });
    }
  } catch (exc) {
    resetForm();
    throw exc;
  }
}

function* updateActivityFormWithNotification(action) {
  yield call(updateActivityFormBase, action, sendProjectChangedNotification);
}

function* updateActivityFormWithoutNotification(action) {
  yield call(updateActivityFormBase, action, Identity);
}

function* emitUpdateActivity(action) {
  const { autosave, activityId, payload } = action;
  yield put({
    autosave,
    activityId,
    type: ACTIVITIES_ACTIONS.UPDATE_ACTIVITY,
    payload,
  });
}

export function* rootUpdateActivityFormSaga() {
  yield takeEvery(
    ACTIONS.INITIATE_UPDATE_ACTIVITY_FORM_WITH_NOTIFICATION,
    callAsync,
    updateActivityFormWithNotification,
  );
  yield takeEvery(
    ACTIONS.INITIATE_UPDATE_ACTIVITY_FORM_WITHOUT_NOTIFICATION,
    callAsync,
    updateActivityFormWithoutNotification,
  );
  yield takeEvery(
    ACTIONS.INITIATE_UPDATE_ACTIVITY_CONFIRMATION,
    callAsync,
    emitUpdateActivity,
  );
}
