import React from 'react';
import { FieldArray, Field } from 'formik';
import {
  TextField,
  Button,
  Grid,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { IntegrityForm as FormFields } from '../helpers/integrityManagement.constants';
import withStyles from '@material-ui/styles/withStyles';

const PicklistOptionsFormComponent = ({ classes }) => {
  return (
    <Grid item xs={12}>
      <FieldArray name={FormFields.PICKLIST_OPTIONS}>
        {({ push, remove, form }) => (
          <Grid container spacing={2} className={classes.pickListContainer}>
            {form.values[FormFields.PICKLIST_OPTIONS].map((_option, index) => (
              <Grid
                item
                xs={12}
                key={index}
                container
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={5}>
                  <Field
                    name={`${FormFields.PICKLIST_OPTIONS}[${index}].title`}
                    as={TextField}
                    label="Picklist Option"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name={`${FormFields.PICKLIST_OPTIONS}[${index}].score`}
                    as={TextField}
                    label="Score"
                    select
                    fullWidth
                  >
                    {[...Array(11).keys()].map((value) => (
                      <MenuItem key={value} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => remove(index)}>
                    <CancelIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                onClick={() => push({ title: '', score: '' })}
                className={classes.addButton}
              >
                + Picklist Option
              </Button>
            </Grid>
          </Grid>
        )}
      </FieldArray>
    </Grid>
  );
};

const styles = (theme) => ({
  addButton: {
    color: theme.palette.primary.newLight,
  },
  pickListContainer: {
    paddingTop: theme.spacing(2),
  },
});

export default withStyles(styles)(PicklistOptionsFormComponent);
