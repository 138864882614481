import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsMore from 'highcharts/highcharts-more.js';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge.js';
import HighchartsNoDataToDisplay from 'highcharts/modules/no-data-to-display';

import highchartsTheme from 'layout/highcharts.theme';

export const initializeHighcharts = (highcharts) => {
  // initialize modules
  HighchartsMore(highcharts);
  HighchartsExporting(highcharts);
  HighchartsSolidGauge(highcharts);
  HighchartsNoDataToDisplay(highcharts);

  // Note: Boost should be the last module included because it overrides standard Highcharts functionality
  HighchartsBoost(highcharts);

  // set default options from theme
  highcharts.setOptions(highchartsTheme);
};
