import _ from 'lodash';
import { compose } from 'redux';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Info from '@material-ui/icons/Info';
import PieChart from '@material-ui/icons/PieChart';
import { createElement, PureComponent } from 'react';
import CategoryIcon from '@material-ui/icons/Category';
import withStyles from '@material-ui/styles/withStyles';
import { generatePath, Link, matchPath } from 'react-router-dom';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';

import {
  Fab,
  Step,
  Stepper,
  StepLabel,
  Typography,
  IconButton,
  StepConnector,
} from '@material-ui/core';

import routePaths from 'app/routePaths';
import { PROJECT_STATUS, ProjectPermission } from 'app/app.constants';
import ProjectRoutesContainer from 'app/components/ProjectRoutesContainer';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';

class ProjectSteps extends PureComponent {
  renderStepper = (routes) => {
    const { pathname, classes, projectId } = this.props;

    return (
      <Stepper
        square
        nonLinear
        elevation={2}
        alternativeLabel
        classes={{
          root: classes.stepperRoot,
        }}
        connector={
          <StepConnector
            classes={{
              line: classes.stepConnectorLine,
              alternativeLabel: classes.stepConnectorAlternativeLabel,
            }}
          />
        }
      >
        {routes.map((route) => {
          const { exact, Icon, path, disabled, title } = route;

          const url = generatePath(path, { projectId });

          const matchesPath = !!matchPath(pathname, {
            path,
            exact,
          });

          return (
            <Step
              key={path}
              className={classNames({
                [classes.stepDisabled]: disabled,
              })}
            >
              <StepLabel
                classes={{
                  label: classes.stepLabelLabel,
                  alternativeLabel: classes.stepLabelAlternativeLabel,
                }}
                StepIconComponent={() =>
                  createElement(
                    matchesPath ? Fab : IconButton,
                    {
                      disabled,
                      className: classes.stepButton,
                      to: disabled ? undefined : url,
                      component: disabled ? undefined : Link,
                      color: matchesPath ? 'primary' : 'default',
                    },
                    <Icon />,
                  )
                }
                optional={
                  <Typography
                    variant="body2"
                    to={disabled ? undefined : url}
                    component={disabled ? undefined : Link}
                    color={disabled ? 'textSecondary' : 'textPrimary'}
                  >
                    {_.capitalize(title)}
                  </Typography>
                }
              />
            </Step>
          );
        })}
      </Stepper>
    );
  };

  render() {
    const { projectStatus } = this.props;

    const status = projectStatus.get('status');

    const projectRoutes = [
      {
        exact: true,
        Icon: Info,
        path: routePaths.details,
        title: 'Project Details',
      },
      {
        Icon: FormatListBulleted,
        path: routePaths.plan.index,
        title: 'Plan',
      },
      {
        Icon: CategoryIcon,
        title: 'Mobilize',
        path: routePaths.mobilize,
      },
      // {
      //   Icon: PlayCircleFilled,
      //   path: routePaths.execute.match,
      //   title: 'Execute',
      //   projectPermissions: ProjectPermission.EXECUTE,
      // },
      {
        Icon: PlayCircleFilled,
        path: '/projects/:projectId/execution',
        title: 'Execute',
        projectPermissions: ProjectPermission.EXECUTE,
      },
      {
        Icon: PieChart,
        path: routePaths.report,
        title: 'Report',
        disabled: status < PROJECT_STATUS.EXECUTE,
      },
    ];

    return (
      <ProjectRoutesContainer
        routes={projectRoutes}
        render={this.renderStepper}
      />
    );
  }
}

const styles = (theme) => ({
  stepConnectorAlternativeLabel: {
    top: '50%',
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
  stepDisabled: {
    color: 'red',
    cursor: 'pointer',
    pointerEvents: 'none',
  },
  stepButton: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  stepperRoot: {
    zIndex: 1,
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    backgroundColor: theme.altus.background.navigation,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(15)}px`,
  },
  stepConnectorLine: {
    opacity: 0.5,
    border: `thin solid ${theme.palette.grey[700]}`,
  },
  stepLabelLabel: {
    '&$stepLabelAlternativeLabel': {
      marginTop: theme.spacing(0.25),
    },
  },
  stepLabelAlternativeLabel: {}, // needed to make stepLabelLabel work
});

export default compose(
  connect((state) => ({
    projectStatus: getExecuteStatusFromState(state),
  })),
  withStyles(styles),
)(ProjectSteps);
