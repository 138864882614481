const splitParts = (str) => {
  // Here I have split the string into parts of digits and non-digits to compare the complex wellname
  return str.match(/(\d+|\D+)/g).map((part) => {
    // converting numeric strings to numbers for proper comparison
    return isNaN(part) ? part : parseInt(part, 10);
  });
};

export const naturalSort = (a, b) => {
  let aParts = splitParts(a);
  let bParts = splitParts(b);

  // I had to compare each part of the strings to have a proper sort order
  while (aParts.length && bParts.length) {
    let aPart = aParts.shift();
    let bPart = bParts.shift();

    if (typeof aPart !== typeof bPart) {
      return typeof aPart === 'number' ? -1 : 1;
    }

    if (aPart !== bPart) {
      return aPart < bPart ? -1 : 1;
    }
  }

  return aParts.length - bParts.length;
};
