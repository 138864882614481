import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentProjectMemberFromState } from 'app/app.selectors';
import { usePermissions } from 'app/hooks/authorization/usePermissions';
import { getRequiredProjectPermissions } from 'utils/authorization.util';
import { getCurrentUserProjectPermissionsFromState } from 'authorization/authorization.selectors';

export const useProjectPermissions = (permissions) => {
  const currentProjectMember = useSelector(getCurrentProjectMemberFromState);

  const userPermissions = useSelector(
    getCurrentUserProjectPermissionsFromState,
  );

  const requiredPermissions = useMemo(
    () => getRequiredProjectPermissions(permissions),
    [permissions],
  );

  const { hasPermission, permissions: projectPermissions } = usePermissions(
    userPermissions,
    requiredPermissions,
  );

  return {
    hasPermission,
    userPermissions,
    projectPermissions,
    currentProjectMember,
  };
};
