import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useRequest } from 'altus-hooks';

import projectWellboreDetailsService from 'services/projectWellboreDetail.service';
import { setActiveWellboredetailId } from 'features/projects/wellbore/details/projectWellboreDetails.actions';

const useLatestProjectWellboreDetailsWithId = (projectId) => {
  const dispatch = useDispatch();
  const getLatestProjectWellboreDetail = useCallback(
    () =>
      projectWellboreDetailsService
        .getLatestProjectWellboreDetail(projectId)
        .then((projectWellboreDetail) => {
          dispatch(
            setActiveWellboredetailId(
              projectWellboreDetail.projectWellboreDetailId,
            ),
          );
          return projectWellboreDetail.projectWellboreDetailId;
        }),
    [projectId, dispatch],
  );

  return useRequest(getLatestProjectWellboreDetail);
};

export default useLatestProjectWellboreDetailsWithId;
