import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background['paper-elevation-2'],
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.mobile.Dialog.buttons.close.main,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.mobile.Dialog.content.background.main,
  },
}))(MuiDialogContent);

const DialogButton = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText,
  },
}))(Button);

const MobileDialogBase = ({
  open,
  toggleModal,
  title,
  content,
  buttonText,
  buttonAction,
  displayCloseIcon = true,
}) => {
  return (
    <Dialog
      onClose={toggleModal}
      aria-labelledby="mobile-dialog-title"
      open={open}
    >
      <DialogTitle
        id="mobile-dialog-title"
        onClose={displayCloseIcon ? toggleModal : null}
      >
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{content}</Typography>
        <DialogButton
          size="large"
          variant="contained"
          fullWidth={true}
          autoFocus
          onClick={buttonAction}
        >
          {buttonText}
        </DialogButton>
      </DialogContent>
      {/* <DialogActions>
            </DialogActions> */}
    </Dialog>
  );
};

export default MobileDialogBase;
