import React from 'react';
import BopTestFormComponent from 'features/equipment/assets/components/BopTestFormComponent';

export const BopTestCustomComponent = () => (
  <>
    <BopTestFormComponent />
  </>
);

export default BopTestCustomComponent;
