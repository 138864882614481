import { compose } from 'redux';
import { useEffect } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { connect, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';

import { BasePage } from 'altus-ui-components';
import { getSummarizedDataStateFromState } from 'app/app.selectors';

import { FIELDS_ACTIONS } from 'features/fields/fields.constants';
import {
  onLoadFieldWithOrganizations,
  onUnloadPage,
} from 'features/fields/field.action';

import { getFieldWithOrganizationsFromState } from 'features/fields/field.selectors';
import { useHeader } from 'app/hooks/useHeader';
import FieldInfoContent from 'features/fields/overview/FieldInfoContent';
import { EMPTY_LIST } from 'app/app.constants';
import WellDetailsTable from 'features/fields/overview/WellDetailsTable';
import ProjectsByStatus from 'features/projects/components/ProjectsByStatus';

const getWellboreSubRows = (row) => {
  if (row.original) return;

  return row.get('wellbores', EMPTY_LIST)?.valueSeq();
};

const FieldOverviewContainer = ({
  fieldId,
  classes,
  dataState,
  dispatchOnLoad,
  dispatchOnUnload,
}) => {
  useHeader({
    title: '',
    subTitle: 'Field Overview',
    logoNavigation: '/wells',
  });
  const displayDataText = (data) => {
    if (dataState.isLoading()) {
      return 'Loading...';
    }

    if (!data || data === undefined) {
      return 'No Data';
    } else {
      return data;
    }
  };

  useEffect(() => {
    dispatchOnLoad(fieldId);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload, fieldId]);

  const field = useSelector(getFieldWithOrganizationsFromState(fieldId));

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid item container xs className={classes.container}>
        <Grid item container className={classes.bottomContainer}>
          {field ? (
            <>
              <Grid item container xs={12} className={classes.content}>
                <Grid item container xs={4}>
                  <FieldInfoContent
                    displayDataText={displayDataText}
                    field={field}
                  />
                </Grid>
                <Grid item container xs={8}>
                  <WellDetailsTable
                    getSubRows={getWellboreSubRows}
                    wellboresByField={field.get('wells')}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                className={classes.projectsContainer}
              >
                <Grid item container xs={12}>
                  <Typography variant="body2" className={classes.projects}>
                    Project History
                  </Typography>
                </Grid>
                {!field.get('projects')?.size ? (
                  <Grid item xs>
                    <Typography>No projects...</Typography>
                  </Grid>
                ) : (
                  <Grid item xs>
                    <ProjectsByStatus projects={field.get('projects')} />
                  </Grid>
                )}
              </Grid>
            </>
          ) : (
            <Typography variant="h6">No field details available.</Typography>
          )}
        </Grid>
      </Grid>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    FIELDS_ACTIONS.GET_FIELDS_WITH_ORGANIZATIONS,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoadFieldWithOrganizations,
  dispatchOnUnload: onUnloadPage,
};

const styles = (theme) => ({
  basePageChildren: {
    flexDirection: 'column',
    padding: 0,
  },
  container: {
    flexDirection: 'column',
    overflow: 'auto',
    padding: theme.spacing(2.25),
  },
  content: {
    height: 'fit-content',
    flexDirection: 'row',
  },
  bottomContainer: {
    dispay: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  projectsContainer: {
    width: '100%',
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.tabs.background.light,
  },
  projects: {
    fontSize: '2rem',
    paddingBottom: theme.spacing(2),
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(FieldOverviewContainer);
