import Agent from 'infrastructure/agent';

const getAllSMLocations = () => Agent.get('/smlocation/config/all');

const createSMLocation = (name) => Agent.post('/smlocation/config/new', name);

const updateSMLocation = (smLocation) =>
  Agent.put('/smlocation/config/update', smLocation);

const deleteSMLocation = (smLocationId) =>
  Agent.delete(`/smlocation/config/delete/${smLocationId}`);

const smLocationService = {
  getAllSMLocations,
  createSMLocation,
  updateSMLocation,
  deleteSMLocation,
};

export default smLocationService;
