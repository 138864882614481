import Agent from 'infrastructure/agent';
import teamMapper from 'features/projects/team/team.mappers';

export const allAvailableUsers = (projectId) =>
  Agent.get(`/project/${projectId}/members/available`);

export const addProjectMembers = (projectId, userIds) =>
  Agent.post(`/project/${projectId}/members`, userIds);

export const assignProjectMemberToRole = (projectId, projectMemberId, roleId) =>
  Agent.put(
    `/project/${projectId}/members/${projectMemberId}/role`,
    teamMapper.ProjectMemberRole.to(roleId, projectMemberId),
  );

export const deleteProjectMember = (projectId, projectMemberId) =>
  Agent.delete(`/project/${projectId}/members/${projectMemberId}`);

export const getProjectMember = (projectId, projectMemberId) =>
  Agent.get(`/project/${projectId}/members/${projectMemberId}`);

// TODOA call field crew controller

export const getFieldCrew = (projectId) =>
  Agent.get(`/project/${projectId}/fieldCrew`);

export const allAvailableFieldCrewUsers = (projectId) =>
  Agent.get(`/project/${projectId}/fieldCrew/available`);

export const addProjectFieldCrews = (projectId, userIds) =>
  Agent.post(`/project/${projectId}/fieldCrew`, userIds);

export const deleteProjectFieldCrew = (projectId, projectMemberId) =>
  Agent.delete(`/project/${projectId}/fieldCrew/${projectMemberId}`);

export const getQualifications = (projectId) =>
  Agent.get(`/project/${projectId}/fieldCrew/qualifications`);

export const getCrews = (projectId) =>
  Agent.get(`/project/${projectId}/fieldCrew/crews`);

export const updateProjectFieldCrew = (projectId, projectMemberId, fieldCrew) =>
  Agent.put(
    `/project/${projectId}/fieldCrew/${projectMemberId}`,
    teamMapper.ProjectFieldCrew.to(fieldCrew),
  );

export const assignProjectFieldCrewToRole = (
  projectId,
  projectMemberFieldCrewId,
  roleId,
) =>
  Agent.put(
    `/project/${projectId}/fieldCrew/${projectMemberFieldCrewId}/role`,
    teamMapper.ProjectFieldCrewRole.to(roleId, projectMemberFieldCrewId),
  );

export const getTeamProjectRoles = (projectId) =>
  Agent.get(`/project/${projectId}/members/teamProjectRoles`);

export const assignProjectMemberTeamRole = (
  projectId,
  projectMemberId,
  projectTeamRole,
) =>
  Agent.put(
    `/project/${projectId}/members/${projectMemberId}/teamRole`,
    projectTeamRole,
  );

const teamService = {
  getProjectMember,
  addProjectMembers,
  addProjectFieldCrews,
  allAvailableUsers,
  allAvailableFieldCrewUsers,
  deleteProjectMember,
  deleteProjectFieldCrew,
  assignProjectMemberToRole,
  assignProjectMemberTeamRole,
  getCrews,
  getQualifications,
  getFieldCrew,
  updateProjectFieldCrew,
  getTeamProjectRoles,
  assignProjectFieldCrewToRole,
};

export default teamService;
