import _isNil from 'lodash/isNil';
import { call, takeEvery, put, all } from 'redux-saga/effects';
import { replace, push } from 'connected-react-router/immutable';

import { toggleModal } from 'altus-modal';

import {
  deleteSimulation,
  receiveSimulation,
  deleteSimulationFluid,
  receiveSimulationFluids,
  receiveSimulationResults,
  receiveSimulationsForTask,
  deleteSimulationResults,
  deleteSimulationFluids,
} from 'features/projects/tasks/task/simulation/simulation.actions';

import {
  receiveWellboreSection,
  receiveWellboreSections,
} from 'features/wells/sections/wellboreSection.actions';

import { callAsync } from 'app/sagas/helperSagas';
import { invokeIfFunction } from 'utils/app.util';
import { FormikFormStatus } from 'app/app.constants';
import simulationService from 'services/simulation.service';
import { toExecutionSimulation, toTaskSimulation } from 'utils/route.util';
import { MODAL } from 'features/projects/tasks/task/simulation/simulation.constants';
import { ACTIONS } from 'features/projects/tasks/task/simulation/simulation.constants';
import { requestToolstringsForTaskAsync } from 'features/projects/tasks/task/toolstring/toolstring.sagas';

export function* requestSimulationAsync(action) {
  const { projectId, taskId, simulationId } = action;

  const simulation = yield call(
    simulationService.getSimulation,
    projectId,
    taskId,
    simulationId,
  );

  yield put(receiveSimulation(simulation));

  return simulation;
}

export function* requestToggleSimulationFavoriteAsync(action) {
  const { projectId, taskId, simulationId } = action;

  const simulation = yield call(
    simulationService.toggleSimulationFavorite,
    projectId,
    taskId,
    simulationId,
  );

  yield put(receiveSimulation(simulation));

  return simulation;
}

export function* requestToggleSimulationPlannedAsync(action) {
  const { projectId, taskId, simulationId } = action;

  const simulation = yield call(
    simulationService.toggleSimulationPlanned,
    projectId,
    taskId,
    simulationId,
  );

  yield put(receiveSimulation(simulation));

  return simulation;
}

export function* submitCreateSimulationAndRedirectAsync(action) {
  const { projectId, taskId, simulation, setStatus, setSubmitting } = action;

  try {
    const createdSimulation = yield call(
      simulationService.createSimulation,
      projectId,
      taskId,
      simulation,
    );

    yield put(receiveSimulation(createdSimulation));

    yield put(
      push(toTaskSimulation(projectId, taskId, createdSimulation.simulationId)),
    );

    yield put(toggleModal({ modalId: MODAL.CREATE_SIMULATION_MODAL }));

    invokeIfFunction(setStatus, FormikFormStatus.SUCCESS);
    return createdSimulation;
  } catch (error) {
    invokeIfFunction(setStatus, FormikFormStatus.ERROR);
    throw error;
  } finally {
    invokeIfFunction(setSubmitting, false);
  }
}

export function* requestUpdateSimulationAsync(action) {
  const { projectId, taskId, simulationId, simulation, resetResults } = action;

  const updatedSimulation = yield call(
    simulationService.updateSimulation,
    projectId,
    taskId,
    simulationId,
    simulation,
  );

  if (resetResults) {
    yield put(deleteSimulationResults(projectId, taskId, simulationId));
    yield put(deleteSimulationFluids(projectId, taskId, simulationId));
  }

  yield put(receiveSimulation(updatedSimulation));

  return updatedSimulation;
}

export function* requestDuplicateSimulationAndRedirectAsync(action) {
  const { projectId, taskId, simulationId } = action;

  const simulation = yield call(
    simulationService.duplicateSimulation,
    projectId,
    taskId,
    simulationId,
  );

  yield put(receiveSimulation(simulation));

  yield put(push(toTaskSimulation(projectId, taskId, simulation.simulationId)));

  return simulation;
}

export function* requestCreateUpdatedPlannedSimulationAndRedirectAsync(action) {
  const { projectId, taskId } = action;

  const simulation = yield call(
    simulationService.createUpdatedPlannedSimulation,
    projectId,
    taskId,
  );

  yield put(receiveSimulation(simulation));

  yield put(
    push(
      toExecutionSimulation(
        projectId,
        taskId,
        simulation.simulationId,
        'sections',
      ),
    ),
  );

  return simulation;
}

export function* requestDeleteSimulationAndRedirectAsync(action) {
  const { taskId, projectId, simulationId, redirect = null } = action;

  yield call(
    simulationService.deleteSimulation,
    projectId,
    taskId,
    simulationId,
  );

  yield put(deleteSimulation(simulationId));

  if (redirect) {
    yield put(replace(redirect));
  }
}

export function* requestSimulationsForTaskAsync(action) {
  const { projectId, taskId, states } = action;
  const simulations = yield call(
    simulationService.getSimulationsForTask,
    projectId,
    taskId,
    states,
  );

  yield put(receiveSimulationsForTask(simulations));

  return simulations;
}

export function* requestRunSimulationAsync(action) {
  const { projectId, taskId, simulationId } = action;

  const response = yield call(
    simulationService.runSimulation,
    projectId,
    taskId,
    simulationId,
  );

  yield put(receiveSimulationResults(response));

  // Get the updated simulation state after executing the simulator
  yield call(requestSimulationAsync, action);

  return response.payload;
}

export function* requestSimulationResultsAsync(action) {
  const { projectId, taskId, simulationId } = action;

  const simulationResults = yield call(
    simulationService.getSimulationResults,
    projectId,
    taskId,
    simulationId,
  );

  yield put(receiveSimulationResults(simulationResults));

  return simulationResults;
}

export function* loadSimulationsAsync(action) {
  yield all([
    call(requestSimulationsForTaskAsync, action),
    call(requestToolstringsForTaskAsync, action),
  ]);
}

export function* loadSimulationAsync(action) {
  yield all([
    call(requestSimulationAsync, action),
    call(requestSimulationResultsAsync, action),
  ]);
}

export function* requestWellboreSectionsForSimulationAsync(action) {
  const { projectId, taskId, simulationId } = action;

  const simulationWellboreSections = yield call(
    simulationService.getWellboreSectionsForSimulation,
    projectId,
    taskId,
    simulationId,
  );

  yield put(receiveWellboreSections(simulationWellboreSections));

  return simulationWellboreSections;
}

export function* requestUpdateWellboreSectionForSimulationAsync(action) {
  const {
    projectId,
    taskId,
    simulationId,
    wellboreSectionId,
    wellboreSection,
  } = action;

  const updatedWellboreSection = yield call(
    simulationService.updateSimulationWellboreSection,
    projectId,
    taskId,
    simulationId,
    wellboreSectionId,
    wellboreSection,
  );

  yield call(requestSimulationAsync, action);

  yield put(receiveWellboreSection(updatedWellboreSection));

  return updatedWellboreSection;
}

export function* requestSimulationFluidsAsync(action) {
  const { projectId, taskId, simulationId } = action;

  const simulationFluids = yield call(
    simulationService.getSimulationFluids,
    projectId,
    taskId,
    simulationId,
  );

  yield put(receiveSimulationFluids(simulationFluids));

  return simulationFluids;
}

export function* requestCreateSimulationFluidAndRedirectAsync(action) {
  const {
    taskId,
    payload,
    projectId,
    setStatus,
    simulationId,
    setSubmitting,
    onRedirect,
  } = action;

  try {
    const simulationFluid = yield call(
      simulationService.createSimulationFluid,
      projectId,
      taskId,
      simulationId,
      payload,
    );

    // refresh fluids to get correct order
    yield call(requestSimulationFluidsAsync, action);

    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);

    if (!_isNil(onRedirect)) {
      yield put(push(onRedirect(simulationFluid.simulationFluidId)));
    }

    yield call(requestSimulationAsync, action);

    return simulationFluid;
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);

    throw error;
  }
}

export function* requestUpdateSimulationFluidAsync(action) {
  const {
    taskId,
    payload,
    projectId,
    setStatus,
    simulationId,
    setSubmitting,
    simulationFluidId,
  } = action;

  try {
    const simulationFluid = yield call(
      simulationService.updateSimulationFluid,
      projectId,
      taskId,
      simulationId,
      simulationFluidId,
      payload,
    );

    // refresh fluids to get correct order
    yield call(requestSimulationFluidsAsync, action);

    yield call(requestSimulationAsync, action);

    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);

    return simulationFluid;
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);

    throw error;
  }
}

export function* requestDeleteSimulationFluidAsync(action) {
  const { taskId, projectId, simulationId, simulationFluidId, onRedirect } =
    action;

  yield call(
    simulationService.deleteSimulationFluid,
    projectId,
    taskId,
    simulationId,
    simulationFluidId,
  );

  if (!_isNil(onRedirect)) {
    yield put(replace(onRedirect()));
  }

  yield call(requestSimulationAsync, action);

  yield put(deleteSimulationFluid(simulationFluidId));
}

export default function* root() {
  yield takeEvery(
    ACTIONS.REQUEST_SIMULATION,
    callAsync,
    requestSimulationAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_TOGGLE_SIMULATION_FAVORITE,
    callAsync,
    requestToggleSimulationFavoriteAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_TOGGLE_SIMULATION_PLANNED,
    callAsync,
    requestToggleSimulationPlannedAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_SIMULATIONS_FOR_TASK,
    callAsync,
    requestSimulationsForTaskAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_SIMULATION_RESULTS,
    callAsync,
    requestSimulationResultsAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_DELETE_SIMULATION,
    callAsync,
    requestDeleteSimulationAndRedirectAsync,
  );

  yield takeEvery(
    ACTIONS.SUBMIT_CREATE_SIMULATION,
    callAsync,
    submitCreateSimulationAndRedirectAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_UPDATE_SIMULATION,
    callAsync,
    requestUpdateSimulationAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_DUPLICATE_SIMULATION,
    callAsync,
    requestDuplicateSimulationAndRedirectAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_CREATE_UPDATE_PLANNED_SIMULATION,
    callAsync,
    requestCreateUpdatedPlannedSimulationAndRedirectAsync,
  );

  yield takeEvery(ACTIONS.LOAD_SIMULATIONS, callAsync, loadSimulationsAsync);

  yield takeEvery(ACTIONS.LOAD_SIMULATION, callAsync, loadSimulationAsync);

  yield takeEvery(
    ACTIONS.REQUEST_SIMULATION_WELLBORE_SECTIONS,
    callAsync,
    requestWellboreSectionsForSimulationAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_UPDATE_SIMULATION_WELLBORE_SECTION,
    callAsync,
    requestUpdateWellboreSectionForSimulationAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_RUN_SIMULATION,
    callAsync,
    requestRunSimulationAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_SIMULATION_FLUIDS,
    callAsync,
    requestSimulationFluidsAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_CREATE_SIMULATION_FLUID,
    callAsync,
    requestCreateSimulationFluidAndRedirectAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_UPDATE_SIMULATION_FLUID,
    callAsync,
    requestUpdateSimulationFluidAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_DELETE_SIMULATION_FLUID,
    callAsync,
    requestDeleteSimulationFluidAsync,
  );
}
