import { HubConnectionState } from '@microsoft/signalr';
import { useEffect, useContext, useCallback } from 'react';

import { ReceivedDataState } from 'altus-datastate';

import config from 'infrastructure/config';
import useStream from 'features/projects/dashboard/hooks/useStream';
import useHubConnection from 'features/projects/dashboard/hooks/useHubConnection';
import { ProjectDashboardContext } from 'features/projects/dashboard/ProjectDashboardContainer';

const metalMillingHubUrl = `${config.dashboardHubsBaseUrl}/metal-milling`;

const MetalMillingHub = {
  StartFetchingMetalMillingData: 'StartFetchingMetalMillingData',
};

const useMetalMillingHubConnection = (projectId, projectDashboardId) => {
  const setDataState = useContext(ProjectDashboardContext);

  const [connection, connectionState] = useHubConnection(
    metalMillingHubUrl,
    setDataState,
  );

  const createStream = useCallback(
    (connection) =>
      Promise.resolve(
        connection.stream(
          MetalMillingHub.StartFetchingMetalMillingData,
          projectId,
          projectDashboardId,
        ),
      ),
    [projectId, projectDashboardId],
  );

  const stream = useStream(connection, connectionState, createStream);

  const registerDataPointsHandler = useCallback(
    (callback) => {
      if (!stream) return;

      stream.subscribe({
        next: callback,
        error: console.error,
      });
    },
    [stream],
  );

  useEffect(() => {
    // Metal milling dashboard doesnt need any initial data - set dataState as received as soon as connection is connected
    if (connectionState === HubConnectionState.Connected) {
      setDataState(ReceivedDataState);
    }
  }, [setDataState, connectionState]);

  return {
    registerDataPointsHandler,
  };
};

export default useMetalMillingHubConnection;
