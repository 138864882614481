import { useDispatch } from 'react-redux';
import Error from '@material-ui/icons/Error';
import { replace } from 'connected-react-router';
import { useEffect, useMemo, useState, createContext } from 'react';

import { usePrevious } from 'altus-hooks';
import { NoContentBasePage } from 'altus-ui-components';
import { LoadingDataState, getSummarizedDataState } from 'altus-datastate';

import routePaths from 'app/routePaths';
import { useHeader } from 'app/hooks/useHeader';
import ProjectHelmet from 'app/components/ProjectHelmet';
import BasePageDense from 'app/components/BasePageDense';
import DashboardHeader from 'features/projects/dashboard/components/DashboardHeader';
import useProjectDashboard from 'features/projects/dashboard/hooks/useProjectDashboard';
import ProjectDashboardDockManager from 'features/projects/dashboard/components/dashboards/configurableDashboard/ProjectDashboardDockManager';

export const ProjectDashboardContext = createContext();

const ProjectDashboardContainer = ({ projectId, projectDashboardId }) => {
  const [dataState, setDataState] = useState(LoadingDataState);

  const dispatch = useDispatch();

  const {
    projectDashboard,
    requestProjectDashboard,
    dataState: projectDashboardDataState,
  } = useProjectDashboard(projectId, projectDashboardId);

  useEffect(() => {
    requestProjectDashboard();
  }, [projectDashboardId, requestProjectDashboard]);

  useHeader({
    logoNavigation: '/operations',
    subTitle: projectDashboard?.get('title'),
  });

  const previousProjectDashboard = usePrevious(projectDashboard);

  useEffect(() => {
    // Navigate away from dashboard if toggling it off
    if (previousProjectDashboard && !projectDashboard) {
      dispatch(replace(routePaths.root));
    }
  }, [dispatch, projectDashboard, previousProjectDashboard]);

  const summarizedDataState = useMemo(
    () => getSummarizedDataState([dataState, projectDashboardDataState]),
    [dataState, projectDashboardDataState],
  );

  if (projectDashboardDataState.isError()) {
    return (
      <NoContentBasePage
        Icon={Error}
        header="An error occurred"
        description="An error occurred while loading the dashboard"
      />
    );
  }

  return (
    <>
      <DashboardHeader
        projectId={projectId}
        projectDashboardId={projectDashboardId}
      />
      <BasePageDense dataState={summarizedDataState}>
        <ProjectHelmet
          projectId={projectId}
          title={projectDashboard?.get('title')}
        />
        <ProjectDashboardContext.Provider value={setDataState}>
          <ProjectDashboardDockManager
            projectId={projectId}
            setDataState={setDataState}
            projectDashboard={projectDashboard}
            projectDashboardId={projectDashboardId}
          />
        </ProjectDashboardContext.Provider>
      </BasePageDense>
    </>
  );
};

export default ProjectDashboardContainer;
