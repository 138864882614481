import PropTypes from 'prop-types';

import { TextField as MuiTextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  textfieldRoot: {
    flex: '1 1 auto',
    '& .MuiInputBase-root': {
      flex: '1 1 auto',
    },
    '& .MuiInputBase-multiline': {
      flex: '1 1 auto',
    },
  },
});

const TextFieldOutlined = ({ margin, fullwidth, ...rest }) => {

  const classes = useStyles();
  return (
    <MuiTextField
      variant="outlined"
      margin={margin}
      fullwidth={fullwidth}
      className={classes.textfieldRoot}
      {...rest}
    />
  );
};

TextFieldOutlined.propTypes = {
  margin: PropTypes.string,
  fullWidth: PropTypes.bool,
};

TextFieldOutlined.defaultProps = {
  margin: 'normal',
  fullWidth: true,
};

export default TextFieldOutlined;
