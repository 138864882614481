import React from 'react';
import { Box, Typography } from '@material-ui/core';

const IntegrityRiskMatrixValueComponent = ({ color, value }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="40px"
      height="40px"
      maxWidth="60px"
      maxHeight="60px"
      borderRadius="50%"
      border={`5px solid ${color}`}
    >
      <Typography color="#fff" fontSize={16}>
        {value}
      </Typography>
    </Box>
  );
};

export default IntegrityRiskMatrixValueComponent;
