import { withStyles } from '@material-ui/core';
import { TableCell } from 'altus-ui-components';

const RiskTableCellDense = ({ classes, column, ...rest }) => {
  const columnPadding = ['risk', 'mitigation'].includes(column?.id)
    ? classes.noPadding
    : classes.cell;
  return <TableCell classes={{ root: columnPadding }} {...rest} />;
};

const styles = () => ({
  cell: {},
  noPadding: {
    padding: '0 2px !important',
  },
});

export default withStyles(styles)(RiskTableCellDense);
