import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { toExecutionSection } from 'utils/route.util';
import MobileBackButton from 'app/components/Mobile/MobileBackButton';

const ExecutionDetailsHeader = ({ classes, projectId, task }) => {
  const defaultPreviousPage = toExecutionSection(projectId);
  return (
    <Grid item xs={12} className={classes.container}>
      <Grid item xs={2} className={classes.statusHeader}>
        <MobileBackButton
          to={defaultPreviousPage}
          children=""
          className={classes.backButton}
        />
      </Grid>
      <Grid item xs={10} className={classes.statusHeader}>
        <Typography variant="h6">{`${task.get('runNumber')}. ${task.get(
          'customTitle',
        )}`}</Typography>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexBasis: '0',
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  statusHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
  },
  backButton: {
    padding: theme.spacing(0),
  },
});

export default withStyles(styles)(ExecutionDetailsHeader);
