import {
  PROJECTS_ACTIONS,
  PROJECTS_ACTIONS as ACTIONS,
} from 'features/projects/projects.constants';
import { requestDeleteFluidGeneric } from 'utils/fluids/actions.base';

export const requestGetProjectFluids = (
  projectId,
  projectWellboreDetailId,
) => ({
  projectId,
  projectWellboreDetailId,
  type: ACTIONS.INITIATE_GET_PROJECT_FLUIDS,
});

export const getProjectFluids = (fluids) => ({
  type: PROJECTS_ACTIONS.GET_PROJECT_FLUIDS,
  payload: fluids,
});

export const requestUpdateFluid = (
  projectId,
  projectWellboreDetailId,
  fluidObj,
  setSubmitting,
  setStatus,
) => ({
  projectId,
  projectWellboreDetailId,
  fluidObj,
  setSubmitting,
  setStatus,
  type: ACTIONS.INITIATE_UPDATE_PROJECT_FLUID,
  notification: {
    info: 'Updating fluid...',
    success: 'Fluid successfully updated',
  },
});

export const updateFluid = (fluidId, projectWellboreDetailId) => ({
  type: PROJECTS_ACTIONS.UPDATE_PROJECT_FLUID,
  payload: {
    fluidId,
    projectWellboreDetailId,
  },
});

export const requestDeleteFluid = (
  projectId,
  projectWellboreDetailId,
  fluidId,
) => {
  return requestDeleteFluidGeneric(
    ACTIONS.INITIATE_DELETE_PROJECT_FLUID,
    { projectId, projectWellboreDetailId },
    fluidId,
  );
};

export const deleteFluid = (projectWellboreDetailId, fluidId) => ({
  type: PROJECTS_ACTIONS.DELETE_PROJECT_FLUID,
  payload: {
    fluidId,
    projectWellboreDetailId,
  },
});

export const requestCreateFluid = (
  projectId,
  projectWellboreDetailId,
  fluidObj,
  setSubmitting,
  setStatus,
) => ({
  type: PROJECTS_ACTIONS.INITIATE_CREATE_PROJECT_FLUID,
  projectId,
  projectWellboreDetailId,
  fluidObj,
  setSubmitting,
  setStatus,
  notification: {
    info: 'Adding fluid...',
    success: 'Fluid successfully created',
  },
});

export const createFluid = (projectWellboreDetailId, fluidObj) => ({
  type: PROJECTS_ACTIONS.CREATE_PROJECT_FLUID,
  payload: {
    projectWellboreDetailId,
    fluidObj,
  },
});
