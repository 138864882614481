import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import withStyles from '@material-ui/styles/withStyles';

import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';

export const Filters = {
  TEXT_SEARCH: 'textSearch',
  FIELD_ID: 'fieldId',
  WELL_STATUS: 'wellboreStatus',
  ORGANIZATION_ID: 'organizationId',
};

const shouldSubmit = ({ isValid }) => isValid;

const WellboresFilter = ({
  fields,
  classes,
  onSubmit,
  wellStatuses,
  initialValues,
}) => (
  <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues}>
    <Form>
      <AutoSaveFormik timeout={1000} shouldSubmit={shouldSubmit}>
        <Grid container className={classes.root}>
          <Grid xs item container justifyContent="flex-end">
            <Grid item xs>
              <Field
                fullWidth
                label="Field/Well name"
                margin="none"
                name={Filters.TEXT_SEARCH}
                component={TextFieldFormik}
                placeholder="Search..."
              />
            </Grid>
            <Grid item xs={3} classes={{ root: classes.filterSpacing }}>
              <SelectTextFieldFormik
                label="Field"
                margin="none"
                items={fields}
                emptyText="All fields"
                name={Filters.FIELD_ID}
                getItemValue={(field) => field.get('fieldId')}
                getItemName={(field) => field.get('name')}
              />
            </Grid>
            <Grid item xs={3} classes={{ root: classes.filterSpacing }}>
              <SelectTextFieldFormik
                label="Status"
                margin="none"
                items={wellStatuses}
                name={Filters.WELL_STATUS}
                getItemValue={(status) => status.get('id')}
                getItemName={(status) => status.get('name')}
              />
            </Grid>
          </Grid>
        </Grid>
      </AutoSaveFormik>
    </Form>
  </Formik>
);

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  filterSpacing: {
    paddingLeft: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(WellboresFilter);
