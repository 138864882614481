import { BasePage } from 'altus-ui-components';

import useCheetahJobs from 'features/data-exchange/hooks/useCheetahJobs';
import CheetahJobsTable from 'features/data-exchange/components/CheetahJobsTable';
import { CheetahJobSource } from 'features/projects/dashboard/dashboard.constants';
import CheetahJobFilterContainer from 'features/data-exchange/components/CheetahJobFilterContainer';

const filter = {
  isCompleted: true,
  source: CheetahJobSource.SM_LOCATION,
};

const ArchivedWinchContainer = ({ breadcrumb }) => {
  const { dataState, cheetahJobs, searchCheetahJobs } = useCheetahJobs(filter);

  return (
    <BasePage dataState={dataState} title={breadcrumb}>
      <CheetahJobFilterContainer filter={filter} onSubmit={searchCheetahJobs} />
      <CheetahJobsTable
        disabled
        cheetahJobs={cheetahJobs}
        cheetahJobSource={filter.source}
      />
    </BasePage>
  );
};

export default ArchivedWinchContainer;
