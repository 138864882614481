export const INCIDENTS_ACTIONS = {
  CREATE_INCIDENT: 'CREATE_INCIDENT',
  UPDATE_INCIDENT: 'UPDATE_INCIDENT',
  DELETE_INCIDENT: 'DELETE_INCIDENT',
  PROJECT_INCIDENTS_PAGE_LOADED: 'PROJECT_INCIDENTS_PAGE_LOADED',
  PROJECT_INCIDENTS_PAGE_UNLOADED: 'PROJECT_INCIDENTS_PAGE_UNLOADED',
};

export const MODAL = {
  EDIT_INCIDENT: 'EDIT_INCIDENT_MODAL_ID',
  CREATE_INCIDENT: 'CREATE_INCIDENT_MODAL_ID',
};

export const CREATE_INCIDENT_FORM = {
  ID: 'createIncident',
  TITLE: 'title',
  DESCRIPTION: 'description',
  CASE_NUMBER: 'caseNumber',
};

export const EDIT_INCIDENT_FORM = {
  ID: 'editIncident',
  TITLE: 'title',
  DESCRIPTION: 'description',
  CASE_NUMBER: 'caseNumber',
};

export const SYNERGY_CASE_URL = 'https://altus.synergilife.dnv.com/case/';
