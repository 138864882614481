import { compose } from 'redux';
import { memo, useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Icon from '@material-ui/icons/Build';
import { withStyles } from '@material-ui/core/styles';

import { Box, Grid, Typography, CircularProgress } from '@material-ui/core';

import {
  getToolstringToolsFromState,
  getToolstringSectionsFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';

import {
  receiveTaskToolstring,
  requestGetTaskToolstring,
  requestSortToolstringTools,
  requestTaskToolstringSections,
  requestUpdateNotPlannedToolstringItem,
} from 'features/projects/tasks/task/toolstring/toolstring.actions';

import { TOOLSTRING_ACTIONS } from 'features/projects/tasks/task/toolstring/toolstring.constants';

import toolstringService from 'services/toolstring.service';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import {
  getStringVerificationsForToolstring,
  validateToolStringSerialNumbersForMobile,
} from 'features/equipment/equipment.actions';
import MobileToolstringActionsContainer from 'features/mobile/projects/execute/taskToolstring/MobileToolstringActionsContainer';
import MobileToolstringListContiner from './MobileToolstringListContiner';
import { MissingSerialNoModal } from 'features/projects/tool/tool.constants';
import { useModal } from 'altus-modal';
import MobileDialogBase from 'app/components/Mobile/Dialog/MobileDialogBase';
import {
  ASSET_HISTORY_EVENT_TYPES,
  ASSET_HISTORY_EVENT_TYPES_ITEM,
  ASSET_HISTORY_TEST_TYPE,
  ASSET_HISTORY_TEST_TYPES,
  EquipmentType,
  MODAL,
} from 'features/equipment/equipment.constants';
import CreateAssetHistoryModalContainer from 'features/equipment/assets/components/CreateAssetHistoryModalContainer';
import { List, fromJS } from 'immutable';
import groupBy from 'lodash/groupBy';
import MobileStringVerificationList from 'features/mobile/projects/execute/taskToolstring/MobileStringVerificationList';

const MobileExecutionBHATabContainer = ({
  taskId,
  projectId,
  dataState,
  classes,
  toolstringId,
  toolstringTools,
  toolstringSections,
  dispatchGetToolstring,
  dispatchToolstringSections,
  dispatchUpdateToolstringItem,
  dispatchGetStringVerificationsForToolstring,
}) => {
  const dispatch = useDispatch();

  const [toolStringId, setToolStringId] = useState();
  const [stringVerificationResults, setStringVerificationResults] =
    useState(null);
  const [open, toggleModal] = useModal(MODAL.MISSING_SERIAL_NUMBER);
  const [isOpen, toggleAssetHistoryModal] = useModal(
    MODAL.ADD_ASSET_HISTORY_BHA,
  );
  const openModal = (event) => {
    event?.preventDefault();
    toggleAssetHistoryModal();
  };
  toolstringId = toolStringId;
  toolstringSections = useSelector((state) =>
    getToolstringSectionsFromState(state, toolStringId),
  );
  toolstringTools = useSelector((state) =>
    getToolstringToolsFromState(state, toolStringId),
  );

  const assetHistoryEventTypes = List([
    ASSET_HISTORY_EVENT_TYPES.find(
      (item) => item.id === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST),
    ),
  ]).filter((item) => item);

  const testTypesItems = List([
    ASSET_HISTORY_TEST_TYPES.find(
      (item) => item.id === String(ASSET_HISTORY_TEST_TYPE.STRING_TEST),
    ),
  ]).filter((item) => item);

  const isLoading = dataState?.isLoading();

  const getPlannedToolstring = useCallback(
    () =>
      toolstringService
        .getPlannedToolstring(projectId, taskId)
        .then((toolstring) => {
          setToolStringId(toolstring?.toolStringId);
          dispatch(receiveTaskToolstring(projectId, taskId, toolstring));
          dispatchToolstringSections(
            projectId,
            taskId,
            toolstring?.toolStringId,
          );
          dispatchGetToolstring(projectId, taskId, toolstring?.toolStringId);
        }),
    [
      dispatch,
      dispatchGetToolstring,
      dispatchToolstringSections,
      projectId,
      taskId,
    ],
  );

  const validateToolstringSerialNumbers = () => {
    dispatch(validateToolStringSerialNumbersForMobile(toolstringId));
  };

  useEffect(() => {
    getPlannedToolstring(projectId, taskId);
  }, [getPlannedToolstring, projectId, taskId]);

  useEffect(() => {
    dispatchGetStringVerificationsForToolstring(taskId, toolStringId).then(
      (response) => {
        if (response && !isOpen) {
          const results = transformData(
            groupBy(
              response,
              (verificationResult) =>
                verificationResult.stringTest.stringTestId,
            ),
          );
          setStringVerificationResults(results);
        }
      },
    );
  }, [
    dispatchGetStringVerificationsForToolstring,
    taskId,
    toolStringId,
    isOpen,
  ]);

  const transformData = (data) => {
    const result = {};
    let sequenceCounter = 1;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const items = data[key];
        const stringTestResult = {
          ...items[0].stringTest,
          sequence: sequenceCounter++,
        };
        const assets = items.map((item) => item.asset);

        result[key] = {
          stringTestResult: stringTestResult,
          assets: assets,
        };
      }
    }

    return fromJS(Object.values(result));
  };

  const updateItemProperties = useCallback(
    (toolstringItem) => {
      dispatchUpdateToolstringItem(
        projectId,
        taskId,
        toolstringId,
        toolstringItem,
      );
    },
    [projectId, taskId, toolstringId, dispatchUpdateToolstringItem],
  );

  return (
    <>
      <CreateAssetHistoryModalContainer
        toggleModal={openModal}
        isOpen={isOpen}
        equipmentType={EquipmentType.BHA}
        getAssetAfterRefresh={false}
        assetHistoryEventTypes={assetHistoryEventTypes}
        testTypesItems={testTypesItems}
        toolstringId={toolstringId}
        taskId={taskId}
        defaultEventValue={String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST)}
        defaultTestTypeValue={String(ASSET_HISTORY_TEST_TYPE.STRING_TEST)}
        modalTitle="String Verification"
      />
      {!toolstringTools || !toolstringTools.size ? (
        <Grid item xs container justifyContent="center" alignItems="center">
          <Grid
            item
            xl={4}
            lg={6}
            md={8}
            sm={10}
            xs={12}
            className={classes.container}
          >
            <Grid container spacing={2}>
              {Icon && (
                <Grid container item xs={12} justifyContent="center">
                  <Icon className={classes.icon} />
                </Grid>
              )}
              <Grid container item xs={12} justifyContent="center">
                <Typography variant="h6">{'Tool String'}</Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Typography nowrap variant="body2" align="justify" paragraph>
                  {'No tool string has been planned for this task.'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : isLoading ? (
        <Grid
          container
          component={Box}
          alignItems="center"
          justifyContent="center"
          padding={2}
          direction="column"
        >
          <Grid item>
            <CircularProgress size={20} />
          </Grid>
          <Grid item>
            <Typography>Loading...</Typography>
          </Grid>
        </Grid>
      ) : (
        <>
          {stringVerificationResults && stringVerificationResults.size > 0 && (
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <MobileStringVerificationList
                stringVerificationResults={stringVerificationResults}
                toolstringTools={toolstringTools}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              mt={2}
              pl={2}
              pr={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <MobileToolstringActionsContainer
                toolstringSections={toolstringSections}
                validateToolstringSerialNumbers={
                  validateToolstringSerialNumbers
                }
              />
            </Box>
          </Grid>
          <Grid
            pt={2}
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <MobileToolstringListContiner
              toolstringTools={toolstringTools}
              updateItemProperties={updateItemProperties}
            />
          </Grid>
          <MobileDialogBase
            open={open}
            toggleModal={toggleModal}
            title={MissingSerialNoModal.TITLE}
            content={MissingSerialNoModal.CONTENT}
            buttonAction={toggleModal}
            buttonText={MissingSerialNoModal.BUTTON}
            displayCloseIcon={false}
          />
        </>
      )}
    </>
  );
};

const styles = (theme) => {
  return {
    root: {
      padding: 0,
    },
    fontMedium: {
      fontSize: 'medium',
    },
    fontSmaller: {
      fontSize: 'smaller',
    },
    rowSize: {
      display: 'flex',
      flexDirection: 'row',
      height: theme.spacing(10),
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.table.row.main,
    },
    taskContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: theme.spacing(2),
    },
    button: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: theme.spacing(0),
    },
    container: {
      padding: theme.spacing(6),
    },
    icon: {
      fontSize: theme.spacing(8),
    },
  };
};

const mapStateToProps = (state) => {
  return {
    dataState: getSummarizedDataStateFromState(
      state,
      TOOLSTRING_ACTIONS.REQUEST_GET_TOOLSTRING_FOR_TASK,
      TOOLSTRING_ACTIONS.REQUEST_TOOLSTRING_SECTIONS,
      TOOLSTRING_ACTIONS.REQUEST_GET_TOOLSTRING_FOR_TASK,
      TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_TOOLS,
    ),
  };
};

const mapDispatchToProps = {
  dispatchGetToolstring: requestGetTaskToolstring,
  dispatchSortToolstringTools: requestSortToolstringTools,
  dispatchToolstringSections: requestTaskToolstringSections,
  dispatchUpdateToolstringItem: requestUpdateNotPlannedToolstringItem,
  dispatchGetStringVerificationsForToolstring:
    getStringVerificationsForToolstring,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  memo,
)(MobileExecutionBHATabContainer);
