import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReadOnlyField from 'app/components/ReadOnlyField';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const CableContinuityTestDrawerComponent = ({ assetHistory, classes }) => {
  const section = assetHistory.get('cableContinuityTest');
  return (
    <>
      {!assetHistory && !section ? null : (
        <Grid item xs={12} className={classes.paperMargin}>
          <CustomReadOnlyField
            margin="none"
            label="Resistance"
            className={classes.marginLabel}
          >
            {section.get('resistance')} ohm
          </CustomReadOnlyField>
        </Grid>
      )}
    </>
  );
};

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: '4vh',
  },
  marginLabel: {
    marginTop: '4vh',
  },
  marginStrand: {
    marginBottom: '2vh',
  },
});

export default withStyles(styles)(CableContinuityTestDrawerComponent);
