import { compose } from 'redux';
import { useMemo } from 'react';
import moment from 'moment';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';
import { IconButton, Typography, Grid } from '@material-ui/core';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { Format, EMPTY_LIST } from 'app/app.constants';
import WellboreTableRow from 'features/wells/components/WellboreTableRow';

const WellDetailsTable = ({
  classes,
  wellboresByField = EMPTY_LIST,
  ...rest
}) => {
  const columns = useMemo(
    () => [
      {
        id: 'placeholder',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: <TableRowActionsCell minItems={1} />,
      },
      {
        xs: 2,
        id: 'name',
        Header: 'Name',
        accessor: (well) => well.get('name'),
        Cell: ({ value }) => {
          return value;
        },
      },
      {
        xs: 2,
        id: 'lastProject',
        Header: 'Last Project',
        accessor: (well) => well.get('lastProject'),
        Cell: ({ row, value }) => {
          return (
            <Typography variant="inherit" component="span">
              {!row.original.get('isWell')
                ? value
                  ? moment(value).format(Format.date)
                  : '-'
                : ''}
            </Typography>
          );
        },
      },
      {
        xs: 3,
        id: 'mainObjective',
        Header: 'Main Obj. (Last Project)',
        accessor: (well) => well.get('lastMainOjectiveProject'),
        Cell: ({ row, value }) => {
          return (
            <Typography variant="inherit" component="span">
              {!row.original.get('isWell') ? (value ? value : '-') : ''}
            </Typography>
          );
        },
      },
      {
        xs: 1,
        id: 'riskScore',
        Header: 'Risk Score',
        Cell: ({ row }) => {
          return (
            <Typography variant="inherit" component="span">
              {!row.original.get('isWell') ? '-' : ''}
            </Typography>
          );
        },
      },
      {
        xs: 2,
        id: 'reLog',
        Header: 'Suggested Integrity Re-Log',
        Cell: ({ row }) => {
          return (
            <Typography variant="inherit" component="span">
              {!row.original.get('isWell') ? '-' : ''}
            </Typography>
          );
        },
      },
      {
        id: 'expander',
        Cell: ({ row }) => {
          if (!row.canExpand) return null;

          return (
            <IconButton
              {...row.getToggleRowExpandedProps()}
              title="Toggle wells"
            >
              {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          );
        },
      },
    ],
    [],
  );

  return (
    <Grid item container className={classes.container}>
      <Grid item container xs={6}>
        <Typography variant="body2" className={classes.wellInfo}>
          Wells
        </Typography>
      </Grid>
      <Table
        useExpanded
        usePagination
        subrowOffset={3}
        columns={columns}
        useGlobalFilter={false}
        items={wellboresByField}
        paginateExpandedRows={false}
        TableRowComponent={WellboreTableRow}
        noItemsMessage="No matching wells..."
        {...rest}
      />
    </Grid>
  );
};

const styles = (theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(3),
    marginLeft: theme.spacing(3),
    backgroundColor: theme.palette.tabs.background.light,
  },
  wellInfo: {
    fontSize: '2rem',
    paddingBottom: theme.spacing(2),
  },
  surveyUploadedDescription: {
    color: theme.palette.secondary.mainLight,
  },
  wellHeader: {
    color: theme.palette.common.white,
  },
});

export default compose(withStyles(styles))(WellDetailsTable);
