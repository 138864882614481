import isNil from 'lodash/isNil';
import classNames from 'classnames';
import { connect } from 'react-redux';
import toJSComponent from 'with-immutable-props-to-js';
import withStyles from '@material-ui/styles/withStyles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { cloneElement, Fragment, PureComponent } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import {
  isModalOpen,
  getRouterParamsFromState,
  getModalDirectionFromState,
  getActiveModalIndexByModalId,
} from 'app/app.selectors';

import themes from 'layout/themes';
import { toggleModal } from 'app/app.actions';
import Transition from 'app/components/Modal/Transition';
import ModalAppBar from 'app/components/Modal/ModalAppBar';
import { KEYS, APP_ACTIONS, THEMES } from 'app/app.constants';
import { invokeIfFunction, renderContent } from 'utils/app.util';
import { getCurrentProject } from 'features/projects/projects.selectors';
import ModalAppBarFullscreen from 'app/components/Modal/ModalAppBarFullscreen';

class ModalContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.toggleModal = this.toggleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onRightLeftKeyDown = this.onRightLeftKeyDown.bind(this);
  }

  toggleModal() {
    const { modalId, toggleModal } = this.props;
    toggleModal({ modalId });
  }

  closeModal() {
    const { modalId, closeModal } = this.props;
    closeModal(modalId);
  }

  onRightLeftKeyDown({ item, index }) {
    const { items, onNext, onPrevious } = this.props;

    return (event) => {
      switch (event.key) {
        case KEYS.ARROW_LEFT: {
          if (index > 0) {
            onPrevious({ item, index });
          }
          break;
        }
        case KEYS.ARROW_RIGHT: {
          if (index !== items.length - 1) {
            onNext({ item, index });
          }
          break;
        }
        default:
          break;
      }
    };
  }

  isModalOpen = (index) => {
    const { routeParams, modalId, activeIndex, open, isModalOpen } = this.props;

    const matchesActiveIndexRouteParam =
      this.allItems.length > 1 ? routeParams.index === index.toString() : true;

    const matchesModalIdRouteParams = !isNil(routeParams[modalId]);

    return (
      (this.allItems.length > 1
        ? isModalOpen && index === activeIndex
        : isModalOpen) ||
      !!open ||
      (matchesModalIdRouteParams && matchesActiveIndexRouteParam)
    );
  };

  render() {
    const {
      DialogProps,
      open,
      items,
      title,
      height,
      onNext,
      project,
      classes,
      modalId,
      Actions,
      onEnter,
      maxWidth,
      minWidth,
      children,
      nextLabel,
      direction,
      onPrevious,
      closeModal,
      fullScreen,
      isModalOpen,
      activeIndex,
      onSaveClick,
      NextBtnProps,
      toggleModal,
      CustomAppBar,
      BackdropProps,
      previousLabel,
      dialogClasses,
      onCancelClick,
      displayBackBtn,
      hideCloseButton,
      saveButtonTitle,
      PreviousBtnProps,
      dispatchOnNext,
      TriggerComponent,
      dispatchOnPrevious,
      fullScreenSubTitle,
      onClickDialogActions,
      nextPreviousDirection,
      fullScreenHideSubTitle,
      fullscreenHideCancelBtn,
      routeParams,
      ActionsProps,
      CustomAppBarProps,
      childrenProps,
      FullscreenCancelBtnProps,
      TransitionProps,
      onExit,
      ...rest
    } = this.props;

    this.allItems = Array.isArray(items) ? items : [items];

    return this.allItems.map((item, index) => {
      const modalOpen = this.isModalOpen(index);

      return (
        <Fragment key={index}>
          {TriggerComponent &&
            cloneElement(TriggerComponent, {
              onClick: this.toggleModal,
            })}

          <Dialog
            classes={{
              paperScrollPaper: classNames({
                [classes.paperScrollPaper]: !fullScreen,
              }),
              root: classNames({
                [classes.root]: !fullScreen,
              }),
              ...dialogClasses,
            }}
            maxWidth={maxWidth}
            onClose={this.closeModal}
            fullScreen={!!fullScreen}
            TransitionComponent={Transition}
            open={modalOpen}
            TransitionProps={{
              onEnter: () => invokeIfFunction(onEnter, item),
              onRightLeftKeyDown: this.onRightLeftKeyDown,
              noOfItems: this.allItems.length,
              nextPreviousDirection,
              dispatchOnPrevious,
              PreviousBtnProps,
              dispatchOnNext,
              previousLabel,
              NextBtnProps,
              activeIndex,
              fullScreen,
              onPrevious,
              nextLabel,
              direction,
              modalId,
              onNext,
              index,
              item,
              onExit,
              ...TransitionProps,
            }}
            BackdropProps={{
              classes: {
                root: classes.backdrop,
              },
              ...BackdropProps,
            }}
            {...DialogProps}
            {...rest}
          >
            <MuiThemeProvider theme={themes[THEMES.DARK]}>
              {CustomAppBar && (
                <CustomAppBar
                  title={title}
                  toggleModal={this.toggleModal}
                  {...CustomAppBarProps}
                />
              )}
              {!CustomAppBar && (
                <>
                  {fullScreen && (
                    <ModalAppBarFullscreen
                      title={title}
                      project={project}
                      closeModal={this.closeModal}
                      onCancelClick={onCancelClick}
                      displayBackBtn={displayBackBtn}
                      hideCloseButton={hideCloseButton}
                      fullScreenSubTitle={fullScreenSubTitle}
                      fullScreenHideSubTitle={fullScreenHideSubTitle}
                    />
                  )}
                  {!fullScreen && (
                    <ModalAppBar
                      title={title}
                      closeModal={this.closeModal}
                      onCancelClick={onCancelClick}
                      hideCloseButton={hideCloseButton}
                    />
                  )}
                </>
              )}
            </MuiThemeProvider>
            <DialogContent
              className={classes.content}
              style={{
                height: fullScreen ? undefined : height,
                minWidth: fullScreen ? undefined : minWidth,
                maxWidth: fullScreen ? undefined : maxWidth,
              }}
            >
              {renderContent(children, { item, index, ...childrenProps })}
            </DialogContent>

            {Actions && (
              <>
                {fullScreen && (
                  <DialogActions
                    onClick={onClickDialogActions}
                    classes={{
                      root: fullScreen ? classes.actionsFullscreen : undefined,
                    }}
                  >
                    <Actions
                      item={item}
                      index={index}
                      toggleModal={this.toggleModal}
                      {...ActionsProps}
                    />
                    {!fullscreenHideCancelBtn && (
                      <Button
                        size="large"
                        {...FullscreenCancelBtnProps}
                        className={classes.cancelButton}
                        onClick={onCancelClick || this.closeModal}
                      >
                        Cancel
                      </Button>
                    )}
                  </DialogActions>
                )}
                {!fullScreen && (
                  <Actions
                    item={item}
                    index={index}
                    toggleModal={this.toggleModal}
                    {...ActionsProps}
                  />
                )}
              </>
            )}
          </Dialog>
        </Fragment>
      );
    });
  }
}

ModalContainer.defaultProps = {
  items: [{}],
};

const mapStateToProps = (initialState, initialProps) => (state) => {
  const { modalId } = initialProps;

  return {
    project: getCurrentProject(state),
    isModalOpen: isModalOpen(state, modalId),
    routeParams: getRouterParamsFromState(state),
    nextPreviousDirection: getModalDirectionFromState(state),
    activeIndex: getActiveModalIndexByModalId(state, modalId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleModal: (params) => dispatch(toggleModal(params)),
    closeModal: (modalId) => dispatch(toggleModal({ modalId, value: false })),
    dispatchOnNext: ({ modalId, onNext, ...params }) => {
      invokeIfFunction(onNext, {
        params,
        goNext: () =>
          dispatch({
            type: APP_ACTIONS.MODAL_NEXT,
            modalId,
          }),
      });
    },
    dispatchOnPrevious: ({ modalId, onPrevious, ...params }) =>
      invokeIfFunction(onPrevious, {
        params,
        goPrevious: () =>
          dispatch({
            type: APP_ACTIONS.MODAL_PREVIOUS,
            modalId,
          }),
      }),
  };
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: theme.altus.components.ModalContainer.content.padding,
    height: '60vh',
    minWidth: 400,
    background: theme.palette.background.default,
  },
  relative: {
    position: 'relative',
    outline: 'none',
  },
  actionsFullscreen: {
    backgroundColor: theme.altus.background.navigation,
    height: '12vh',
    margin: 0,
    justifyContent: 'center',
  },
  cancelButton: {
    position: 'absolute',
    right: 15,
  },
  backdrop: {
    backgroundColor: theme.altus.background.backdrop,
  },
  paperScrollPaper: {
    maxHeight: 'calc(100vh - 96px)',
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJSComponent(withStyles(styles)(ModalContainer)));
