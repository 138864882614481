import { compose } from 'redux';
import { useMemo, memo } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { LoadingDataState } from 'altus-datastate';
import { ApplicationBaseContent } from 'altus-ui-components';

import { APP_ACTIONS } from 'app/app.constants';
import RoutesContainer from 'app/components/RoutesContainer';
import BreadcrumbHeader from 'app/components/BreadcrumbHeader';
import { getActionDataStateFromState } from 'app/app.selectors';
import ApplicationSidebar from 'app/components/ApplicationHeader/ApplicationSidebar';

const renderBreadcrumbs = () => <Grid item component={BreadcrumbHeader} />;

const ApplicationSidebarContainer = ({
  routes,
  classes,
  dataState = LoadingDataState,
}) => {
  const baseContentClasses = useMemo(
    () => ({
      scrollContainer: classes.applicationBaseContentScrollContainer,
    }),
    [classes],
  );

  return (
    <Grid item xs container wrap="nowrap">
      <ApplicationSidebar routes={routes} />
      <ApplicationBaseContent
        classes={baseContentClasses}
        renderTopContent={renderBreadcrumbs}
        // Fix to avoid re-mounting ApplicationBaseContent children when dataState changes
        dataState={dataState.requested ? dataState : LoadingDataState}
      >
        <RoutesContainer routes={routes} />
      </ApplicationBaseContent>
    </Grid>
  );
};

const styles = () => ({
  applicationBaseContentScrollContainer: {
    overflow: 'hidden',
  },
});

export default compose(
  connect((state) => ({
    dataState: getActionDataStateFromState(state, APP_ACTIONS.GET_CURRENT_USER),
  })),
  memo,
  withStyles(styles),
)(ApplicationSidebarContainer);
