import { compose } from 'redux';
import { isImmutable } from 'immutable';
import { useMemo, useCallback } from 'react';
import SaveIcon from '@material-ui/icons/GetApp';
import OpenInNewIcon from '@material-ui/icons/Launch';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, IconButton, Link } from '@material-ui/core';

import { Table, TableRow } from 'altus-ui-components';

import { EMPTY_MAP } from 'app/app.constants';
import DocumentsTableHeaderCell from 'features/projects/documents/newDocumentsContainer/components/DocumentsTableHeaderCell';

const getSubRows = (row) => {
  if (isImmutable(row)) {
    return row.get('procedures')?.toArray();
  }
};

const defaultInitialState = {
  pageSize: 50,
};

const ProceduresTable = ({
  classes,
  procedures = EMPTY_MAP,
  filteredDocuments,
  ...rest
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 9,
        id: 'name',
        Header: 'Procedures',
        Cell: ({ row }) => {
          const document = row.original;
          if (!row.canExpand)
            return (
              <Grid className={classes.tableRowRoot}>
                <Typography variant="body2">{document.get('name')}</Typography>
              </Grid>
            );

          return <Typography variant="h6">{document.get('name')}</Typography>;
        },
      },
      {
        id: 'expander',
        xs: 3,
        disableSortBy: true,
        Cell: ({ row }) => {
          const procedure = row.original;
          if (!row.canExpand)
            if (procedure.get('documentLink') === 1) {
              return (
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={procedure.get('link')}
                  >
                    <IconButton title="Download">
                      <SaveIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Grid>
              );
            } else {
              return (
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={procedure.get('link')}
                  >
                    <IconButton title="Open in new tab">
                      <OpenInNewIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </Grid>
              );
            }
          return (
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <IconButton
                {...row.getToggleRowExpandedProps({
                  ...row.getToggleRowExpandedProps({
                    style: {
                      paddingLeft: `${1}rem`,
                    },
                  }),
                })}
              >
                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
          );
        },
      },
    ],
    [classes.tableRowRoot],
  );

  const renderTableRowComponent = useCallback(
    (props) => {
      const procedure = props.row.original;
      if (!props.row.canExpand && props.row.subRows?.length === 0) return null;
      if (!props.row.canExpand) {
        return (
          <Link
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
            href={procedure.get('link')}
          >
            <TableRow {...props} />
          </Link>
        );
      } else {
        return <TableRow {...props} />;
      }
    },
    [classes],
  );

  return (
    <Grid item container direction="column">
      <Grid xs item container wrap="nowrap" direction="column">
        <Grid item>
          <Table
            useExpanded
            subrowOffset={0}
            items={procedures}
            usePagination
            columns={columns}
            useGlobalFilter={false}
            getSubRows={getSubRows}
            initialState={defaultInitialState}
            noItemsMessage="No procedures found"
            TableRowComponent={renderTableRowComponent}
            TableHeaderCellComponent={DocumentsTableHeaderCell}
            {...rest}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  rowActionCell: {
    paddingRght: '10px',
  },
  mainDiv: {
    transform: `rotate(${90}deg)`,
  },
  tableRowRoot: {
    paddingLeft: '20px',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
});

export default compose(withStyles(styles))(ProceduresTable);
