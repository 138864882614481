import { useMemo } from 'react';
import { compose } from 'redux';
import _isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { List, isImmutable } from 'immutable';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Button, IconButton, Tooltip } from '@material-ui/core';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { formatValue } from 'utils/format.util';
import { SystemPermission } from 'app/app.constants';
import TableCellDense from 'app/components/Table/TableCellDense';
import HasSystemPermission from 'app/components/HasSystemPermission';
import { UNIT_OF_MEASURE } from 'features/equipment/equipment.constants';
import TableHeaderCellDense from 'app/components/Table/TableHeaderCellDense';
import { getUnitsOfMeasurePreference } from 'features/equipment/equipment.selectors';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';

const defaultInitialState = {
  pageSize: 50,
};

const getSubRows = (row) =>
  isImmutable(row)
    ? row.get('templateEquipments')?.toArray().reverse()
    : row.original?.templateEquipments?.toArray().reverse();

const EquipmentTemplatesTable = ({
  classes,
  onEditTemplate,
  addMode = false,
  equipmentTemplates,
  onSaveSurfaceEquipmentTemplate,
  ...rest
}) => {
  const initialState = useMemo(
    () => ({
      ...defaultInitialState,
      sortBy: [
        {
          id: 'sequence',
          desc: false,
        },
      ],
    }),
    [],
  );

  const unitsOfMeasurePreference = useSelector(getUnitsOfMeasurePreference);
  const weightUnit =
    unitsOfMeasurePreference === UNIT_OF_MEASURE.IMPERIAL ? 'lbs' : 'kg';

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 1,
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (!row.canExpand) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <IconButton
                {...row.getToggleRowExpandedProps({
                  ...row.getToggleRowExpandedProps({
                    style: {
                      paddingLeft: `${1}rem`,
                    },
                  }),
                })}
              >
                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 1,
        id: 'dataVerified',
        Cell: ({ row }) => {
          const equipment = row.original;
          if (row.canExpand) {
            const templateEquipments = equipment.get('templateEquipments');
            if (_isNil(templateEquipments)) {
              return null;
            }
            const hasNullDataVerified = templateEquipments?.some(
              (eq) =>
                eq.get('verifiedData') === null ||
                eq.get('verifiedData') === false,
            );
            if (hasNullDataVerified) {
              return (
                <TableRowActionsCell minItems={0}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Tooltip title="Unverified tool">
                      <WarningIcon className={classes.warningIcon} />
                    </Tooltip>
                  </Grid>
                </TableRowActionsCell>
              );
            } else {
              return null;
            }
          } else if (!equipment.get('verifiedData')) {
            return (
              <TableRowActionsCell minItems={0}>
                <Grid container justifyContent="center" alignItems="center">
                  <Tooltip title="Unverified tool">
                    <WarningIcon className={classes.warningIcon} />
                  </Tooltip>
                </Grid>
              </TableRowActionsCell>
            );
          } else return null;
        },
      },
      // Hide images for now
      // {
      //   xs: 4,
      //   id: 'image',
      //   Header: 'Image',
      //   Cell: ({ row }) => {
      //     const { original: equipment } = row;
      //     const templateEquipments = equipment.get('templateEquipments');
      //     if (templateEquipments && templateEquipments.size > 0) {
      //       return (
      //         <Grid container className={classes.toolContainer}>
      //           {templateEquipments.reverse().map((item) => {
      //             var equipmentImage = item
      //               .get('surfaceEquipment')
      //               ?.get('equipmentImage');
      //             var imageUrl = null;
      //             if (Object.keys(equipmentImage).length > 0) {
      //               imageUrl = equipmentImage.get('imageUrl');
      //             }
      //             return (
      //               <ToolImageContainer
      //                 onHoverDarken
      //                 title={item.get('name')}
      //                 toolName={item.get('name')}
      //                 toolImageUrl={imageUrl}
      //                 orientation={ExifOrientation.DEGREES_90_MIRRORED}
      //                 style={{
      //                   maxHeight: '50px',
      //                   maxWidth: `${100 / templateEquipments.size}%`,
      //                 }}
      //               />
      //             );
      //           })}
      //         </Grid>
      //       );
      //     }
      //     var equipmentImage = equipment
      //       .get('surfaceEquipment')
      //       ?.get('equipmentImage');
      //     var imageUrl = null;
      //     if (Object.keys(equipmentImage).length > 0) {
      //       imageUrl = equipmentImage.get('imageUrl');
      //     }
      //     return (
      //       <ToolImageTableCell
      //         onHoverDarken
      //         {...row.getToggleRowExpandedProps({
      //           style: {
      //             paddingLeft: `${row.depth}rem`,
      //           },
      //         })}
      //         title={equipment.get('name')}
      //         toolName={equipment.get('name')}
      //         toolImageUrl={imageUrl}
      //         orientation={ExifOrientation.DEGREES_90_MIRRORED}
      //       />
      //     );
      //   },
      // },
      {
        xs: 2,
        id: 'toolItemNo',
        Header: 'Item no.',
        Cell: ({ row }) => {
          if (row.canExpand) return null;

          const { original: equipment } = row;
          var se = equipment.get('surfaceEquipment');
          if (se) {
            return se.get('m3ItemNumber');
          }

          return null;
        },
      },
      {
        xs: 3,
        id: 'name',
        Header: 'Name',
        accessor: (template) => template.get('name'),
      },
      {
        xs: 3,
        id: 'description',
        Header: 'Description',
        accessor: (template) => template.get('description'),
      },
      {
        xs: 3,
        id: 'surfaceEquipmentGroup',
        Header: 'Surface Eq. Group',
        accessor: (template) => template.get('group'),
      },
      {
        xs: 2,
        id: 'weight_kg',
        accessor: (tool) =>
          formatValue(tool.getIn(['weight', 'value']), '', 0.01),
        Header: (
          <QuantityTableHeaderTitleCell title="Weight" unit={weightUnit} />
        ),
      },
      {
        xs: 1,
        id: 'editTemplate',
        Cell: ({ row }) => {
          const { original: template } = row;

          if (row.canExpand) {
            if (addMode) {
              return (
                <Grid className={classes?.actions}>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    title="Add template"
                    onClick={() => onSaveSurfaceEquipmentTemplate(template)}
                  >
                    <AddIcon />
                    Add
                  </Button>
                </Grid>
              );
            } else {
              return (
                <HasSystemPermission
                  permissions={[SystemPermission.ITEM_TAB_EDIT]}
                >
                  <Grid item container justifyContent="flex-end">
                    <IconButton>
                      <EditIcon
                        onClick={() =>
                          onEditTemplate(template.get('id'), template)
                        }
                      />
                    </IconButton>
                  </Grid>
                </HasSystemPermission>
              );
            }
          }
          return null;
        },
      },
    ],
    [
      addMode,
      weightUnit,
      onEditTemplate,
      onSaveSurfaceEquipmentTemplate,
      classes.warningIcon,
      classes?.actions,
    ],
  );

  return (
    <Grid item container direction="column">
      <Grid xs item container wrap="nowrap" direction="column">
        <Grid item>
          <Table
            useExpanded
            stickyHeader
            items={equipmentTemplates}
            usePagination
            columns={columns}
            useGlobalFilter={false}
            getSubRows={getSubRows}
            initialState={initialState}
            noItemsMessage="No Equipment Templates found. Try changing the filters"
            TableCellComponent={TableCellDense}
            TableHeaderCellComponent={TableHeaderCellDense}
            {...rest}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

EquipmentTemplatesTable.propTypes = {
  templates: PropTypes.instanceOf(List),
};

const styles = (theme) => ({
  rowActionCell: {
    paddingRght: '10px',
  },
  toolContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
});

export default compose(withStyles(styles))(EquipmentTemplatesTable);
