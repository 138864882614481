import { useMemo } from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Typography, Chip } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { TableRow } from 'altus-ui-components';

import {
  formatDate,
  formatDuration,
  formatEstimatedDate,
} from 'utils/format.util';

import Counter from 'app/components/Counter';
import { Grid } from 'app/components/withTooltip';
import { toProjectDetails } from 'utils/route.util';
import { durationFromStartAndEndTime } from 'utils/app.util';
import { Format, PROJECT_STATUS as ProjectStatus } from 'app/app.constants';
import ProjectTableRowDetail from 'features/projects/components/ProjectTableRowDetail';

const WellDashboardProjectTableRow = ({
  classes,
  item: project,
  zoomInAndOutByProjectId,
  setArchiveProjects,
  ...rest
}) => {
  const title = project.get('title');
  const projectId = project.get('id');
  const status = project.get('status');
  const endTime = project.get('endTime');
  const startTime = project.get('startTime');

  const isExecuteOrReport = status > ProjectStatus.PLAN;

  const tableRowClasses = useMemo(
    () => ({
      root: classes.tableRowRoot,
      rootBackground: classes.tableRowRootBackground,
    }),
    [classes],
  );
  return (
    <TableRow classes={tableRowClasses} {...rest}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs className={classes.projectDetails}>
          <Grid
            container
            spacing={2}
            component={Link}
            alignItems="center"
            to={toProjectDetails(projectId)}
          >
            <Grid item>
              <Typography color="textPrimary" variant="body2">
                {project.get('projectNumber')}
              </Typography>
            </Grid>
            <Grid item container xs zeroMinWidth direction="row">
              <Grid item xs={4}>
                <Typography noWrap color="textPrimary" variant="body2">
                  {title || <i>No objective set...</i>}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <ProjectTableRowDetail
                  title="Facility:"
                  value={project.get('facilityName')}
                />
              </Grid>
            </Grid>
            <Grid item xs={2} className={classes.metaRow}>
              {
                {
                  [ProjectStatus.PLAN]: (
                    <ProjectTableRowDetail
                      title="Planned:"
                      value={formatEstimatedDate(project.get('estimatedStart'))}
                    />
                  ),
                  [ProjectStatus.EXECUTE]: (
                    <ProjectTableRowDetail
                      title="Started:"
                      value={formatDate(startTime, Format.date)}
                    />
                  ),
                  [ProjectStatus.REPORT]: (
                    <ProjectTableRowDetail
                      title="Completed:"
                      value={formatDate(endTime, Format.date)}
                    />
                  ),
                }[status]
              }
              {isExecuteOrReport && (
                <Counter
                  isTimerActive={status === ProjectStatus.EXECUTE}
                  initialDuration={
                    endTime
                      ? durationFromStartAndEndTime(startTime, endTime)
                      : durationFromStartAndEndTime(startTime)
                  }
                  renderValue={({ elapsedTime }) => (
                    <ProjectTableRowDetail
                      title="Duration:"
                      value={formatDuration(elapsedTime)}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              {project.get('isArchive') && (
                <Chip
                  label="Archived"
                  classes={{
                    root: classes.chipRoot,
                  }}
                ></Chip>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TableRow>
  );
};

const styles = (theme) => ({
  tableRowRoot: {
    height: 'auto',
    maxHeight: 'none',
  },
  tableRowRootBackground: {
    position: 'relative',
    padding: `${theme.spacing(1.25)}px ${theme.spacing(1.5)}px`,
  },
  metaRow: {
    display: 'inline-grid',
  },
  linearProgres: {
    height: '2px',
    position: 'absolute',
    width: '100%',
    bottom: 1,
    left: 0,
  },
  projectDetails: {
    overflow: 'auto',
  },
  chipRoot: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.black,
  },
});

export default compose(withStyles(styles))(WellDashboardProjectTableRow);
