import { compose } from 'redux';
import { connect } from 'react-redux';
import { useState, useCallback } from 'react';
import { Grid, Tabs, Tab, Divider, Box, Button } from '@material-ui/core';

import { useMultiselect } from 'altus-hooks';

import { LoadingButton } from 'altus-ui-components';

import FieldCrew from 'features/projects/team/components/FieldCrew';
import { addUsersToProject } from 'features/projects/team/team.actions';
import OnshoreTeam from 'features/projects/team/components/OnshoreTeam';
import { TEAM_ACTIVE_TAB } from 'features/projects/team/team.constants';

const TeamContainer = ({
  classes,
  dataState,
  projectId,
  breadcrumb,
  projectRoles,
  dispatchOnLoad,
  dispatchOnUnload,
  projectMembersByUserId,
  dispatchAddUsersToProject,
  dispatchDeleteProjectMember,
  dispatchAssignProjectMemberToRole,
}) => {
  const [activeTab, setActiveTab] = useState(TEAM_ACTIVE_TAB.ONSHORE_TEAM);

  const [isAddMode, setAddMode] = useState(false);
  const { isSelected, toggleSelect, selectedItems, clearSelection } =
    useMultiselect();

  const handleTabs = (_, value) => {
    setActiveTab(value);
  };

  const changeAddMode = useCallback(
    (isAdd) => {
      if (isAdd) {
        clearSelection();
      }
      setAddMode(isAdd);
    },
    [clearSelection],
  );

  const addSelectedUsersToProject = useCallback(
    (users) =>
      dispatchAddUsersToProject(projectId, users).then(changeAddMode(false)),
    [projectId, changeAddMode, dispatchAddUsersToProject],
  );

  const activeTabHandler = (activeTab) => {
    switch (activeTab) {
      default:
        return null;
      case TEAM_ACTIVE_TAB.ONSHORE_TEAM:
        return (
          <OnshoreTeam
            classes={classes}
            dataState={dataState}
            projectId={projectId}
            isAddMode={isAddMode}
            isSelected={isSelected}
            breadcrumb={breadcrumb}
            projectRoles={projectRoles}
            toggleSelect={toggleSelect}
            changeAddMode={changeAddMode}
            selectedItems={selectedItems}
            dispatchOnLoad={dispatchOnLoad}
            dispatchOnUnload={dispatchOnUnload}
            projectMembersByUserId={projectMembersByUserId}
            dispatchDeleteProjectMember={dispatchDeleteProjectMember}
            dispatchAssignProjectMemberToRole={
              dispatchAssignProjectMemberToRole
            }
          />
        );
      case TEAM_ACTIVE_TAB.FIELD_CREW:
        return (
          <FieldCrew
            classes={classes}
            dataState={dataState}
            projectId={projectId}
            isAddMode={isAddMode}
            isSelected={isSelected}
            breadcrumb={breadcrumb}
            projectRoles={projectRoles}
            toggleSelect={toggleSelect}
            changeAddMode={changeAddMode}
            selectedItems={selectedItems}
            dispatchOnLoad={dispatchOnLoad}
            dispatchOnUnload={dispatchOnUnload}
            projectMembersByUserId={projectMembersByUserId}
            dispatchDeleteProjectMember={dispatchDeleteProjectMember}
            dispatchAssignProjectMemberToRole={
              dispatchAssignProjectMemberToRole
            }
          />
        );
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Tabs value={activeTab} onChange={handleTabs}>
            <Tab
              label="Onshore Team"
              value={TEAM_ACTIVE_TAB.ONSHORE_TEAM}
              className={classes.tab}
            />
            <Tab
              label="Field Crew"
              value={TEAM_ACTIVE_TAB.FIELD_CREW}
              className={classes.tab}
            />
          </Tabs>
        </Grid>
        {isAddMode ? (
          <Grid
            container
            item
            xs={4}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button onClick={() => changeAddMode(false)}>Cancel</Button>
            <LoadingButton
              color="primary"
              variant="contained"
              dataState={dataState}
              disabled={selectedItems.isEmpty()}
              onClick={() => addSelectedUsersToProject(selectedItems)}
            >
              {`Add (${selectedItems.size})`}
            </LoadingButton>
          </Grid>
        ) : null}
      </Grid>

      <Divider className={classes.divider} />
      <Box className={classes.box}>
        <Grid container className={classes.contentContainer}>
          <Grid item container className={classes.tabPanel} spacing={1}>
            {activeTabHandler(activeTab)}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default compose(
  connect(() => ({}), {
    dispatchAddUsersToProject: addUsersToProject,
  }),
)(TeamContainer);
