import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  searchOperations,
  updateProjectStatus,
} from 'features/operations/operations.actions';

import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { OPERATIONS_ACTIONS } from 'features/operations/operations.constants';
import { createOperationsSelector } from 'features/operations/operations.selectors';

const useOperations = (initialFilter) => {
  const dispatch = useDispatch();

  const dispatchSearchOperations = useCallback(
    (filter) => dispatch(searchOperations(filter)),
    [dispatch],
  );

  const dispatchUpdateProjectStatus = useCallback(
    (projectId, status) => dispatch(updateProjectStatus(projectId, status)),
    [dispatch],
  );

  const selector = useMemo(
    () => createOperationsSelector(initialFilter),
    [initialFilter],
  );

  const operations = useSelector(selector);

  const dataState = useSelector((state) =>
    getSummarizedDataStateFromState(
      state,
      OPERATIONS_ACTIONS.SEARCH_OPERATIONS,
      OPERATIONS_ACTIONS.UPDATE_PROJECT_CHEETAH_JOB_STATUS,
    ),
  );

  return {
    dataState,
    operations,
    searchOperations: dispatchSearchOperations,
    updateProjectStatus: dispatchUpdateProjectStatus,
  };
};

export default useOperations;
