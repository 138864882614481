import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Button } from '@material-ui/core';

import {
  ProjectPermission,
  ProjectWellboreDetailStatus,
} from 'app/app.constants';

import HasProjectPermission from 'app/components/HasProjectPermission';

const ProjectWellboreDetailStatusHeaderActions = ({
  classes,
  canCancel,
  onCancelDraft,
  onApproveDraft,
  projectWellboreDetail,
  onRequestDraftApproval,
}) => {
  const status = projectWellboreDetail.get('status');

  const cancelDraftBtn = (
    <HasProjectPermission permissions={ProjectPermission.EDIT_WELL_DETAILS}>
      {!!canCancel && (
        <Grid item>
          <Button
            variant="text"
            color="secondary"
            disableElevation
            classes={{
              text: classes.cancelButtonText,
            }}
            onClick={onCancelDraft}
          >
            Cancel
          </Button>
        </Grid>
      )}
    </HasProjectPermission>
  );

  const approveButton = (
    <Grid item>
      <Button
        variant="contained"
        color="default"
        disableElevation
        onClick={onApproveDraft}
      >
        Approve
      </Button>
    </Grid>
  );

  const requestApprovalButton = (
    <HasProjectPermission permissions={ProjectPermission.EDIT_WELL_DETAILS}>
      <Grid item>
        <Button
          disableElevation
          variant="contained"
          color="default"
          onClick={onRequestDraftApproval}
        >
          Send for approval
        </Button>
      </Grid>
    </HasProjectPermission>
  );

  const approvalButtons = (
    <HasProjectPermission permissions={ProjectPermission.APPROVE_WELL_DETAILS}>
      {(hasPermission) =>
        hasPermission ? approveButton : requestApprovalButton
      }
    </HasProjectPermission>
  );

  switch (status) {
    case ProjectWellboreDetailStatus.DRAFT:
      return (
        <>
          {cancelDraftBtn}
          {approvalButtons}
        </>
      );
    case ProjectWellboreDetailStatus.REQUIRES_APPROVAL:
      return (
        <HasProjectPermission
          permissions={ProjectPermission.APPROVE_WELL_DETAILS}
        >
          {approveButton}
        </HasProjectPermission>
      );
    default:
      return null;
  }
};

const styles = (theme) => ({
  cancelButtonText: {
    color: theme.palette.common.black,
  },
});

export default compose(withStyles(styles))(
  ProjectWellboreDetailStatusHeaderActions,
);
