import { PureComponent } from 'react';

import { OverviewContainer } from 'altus-ui-components';

import SystemRoutesContainer from 'app/components/SystemRoutesContainer';

class SettingsOverviewContainer extends PureComponent {
  renderContent = (routes) => {
    const { parentBreadcrumb, Icon } = this.props;
    return (
      <OverviewContainer
        Icon={Icon}
        parentRoutes={routes}
        parentBreadcrumb={parentBreadcrumb}
      />
    );
  };

  render() {
    const { parentRoutes } = this.props;

    return (
      <SystemRoutesContainer
        routes={parentRoutes}
        render={this.renderContent}
      />
    );
  }
}

export default SettingsOverviewContainer;
