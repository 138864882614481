import facilityService from 'services/facility.service';
import { FACILITIES_ACTIONS } from 'features/facilities/facilities.constants';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';

export const onLoad = (organizationId) => (dispatch) => {
  const payload = dispatch(getOperators(organizationId));

  dispatch({
    type: FACILITIES_ACTIONS.FACILITIES_PAGE_LOADED,
    payload: payload,
  });
};

export const getOperators = (organizationId) => (dispatch) => {
  const filter = {
    organizationId,
  };

  dispatch({
    type: FACILITIES_ACTIONS.GET_OPERATORS,
    payload: () => facilityService.getOperators(filter),
  });
};

export const updateFacilitiesFilter = (filter) => (dispatch) => {
  const payload = facilityService
    .searchFacilities(filter)
    .then((wellboresPage) => dispatch(receiveFacilitiesPage(wellboresPage)));

  dispatch({
    filter,
    payload,
    type: FACILITIES_ACTIONS.UPDATE_FACILITIES_FILTER,
  });

  return payload;
};

export const receiveFacilitiesPage = (facilities) => ({
  payload: facilities,
  type: FACILITIES_ACTIONS.RECEIVE_FACILITIES_PAGE,
});

export const getFacilityById = (facilityId) => (dispatch) => {
  dispatch({
    type: FACILITIES_ACTIONS.GET_FACILITY_BY_ID,
    payload: () => facilityService.getFacilityById(facilityId),
  });
};

export const getFacilityImage = (facilityId) => (dispatch) => {
  const payload = facilityService.getFacilityImage(facilityId).catch(() => {
    dispatch({
      hideable: true,
      notification: {
        message: 'Image not available.',
        variant: NOTIFICATION_VARIANTS.ERROR,
      },
      type: FACILITIES_ACTIONS.GET_FACILITY_IMAGE,
      payload,
    });
  });

  return payload;
};

export const getFacilityLocation = (facilityId) => (dispatch) => {
  const payload = facilityService.getFacilityLocation(facilityId);

  dispatch({
    type: FACILITIES_ACTIONS.GET_FACILITY_LOCATION,
    payload,
  });

  return payload;
};

export const getFacilityDecks = (facilityId) => (dispatch) => {
  const payload = facilityService.getFacilityDecks(facilityId);

  dispatch({
    type: FACILITIES_ACTIONS.GET_FACILITY_DECKS,
    payload,
  });

  return payload;
};
