import { fromJS } from 'immutable';
import { EMPTY_LIST } from 'app/app.constants';
import { TASK_ACTIONS as ACTIONS } from 'features/projects/tasks/tasks.constants';
import mappers from 'mappers';

const initialState = fromJS({
  data: EMPTY_LIST,
});

const taskFluidsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS.GET_TASK_FLUIDS: {
      if (action.error) return state;
      const { payload } = action;

      if (payload) {
        const mapped = mappers.TaskFluids.from(payload);
        return state.set('data', fromJS(mapped));
      }
      return state;
    }
    default:
      return state;
  }
};

export default taskFluidsReducer;
