import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import config from 'infrastructure/config';
import history from 'infrastructure/history';

let appInsights = null;
try {
  var reactPlugin = new ReactPlugin();
  if (config.appInsightsInstrumentationKey) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: config.appInsightsInstrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: history,
          },
        },
      },
    });
  }
} catch (e) {
  console.error(e);
}

export default appInsights;
