import { Map, List, OrderedMap } from 'immutable';

import mappers from 'mappers';
import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import { FACILITIES_ACTIONS as ACTIONS } from 'features/facilities/facilities.constants';

const initialState = Map({
  facilityById: EMPTY_MAP,
  operators: EMPTY_LIST,
  pageParams: EMPTY_MAP,
  pageResults: EMPTY_MAP,
});

const facilitiesReducer = (state = initialState, action = {}) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.RECEIVE_FACILITIES_PAGE: {
      if (error) return state;

      const { results: facilities, ...pageParams } = payload;

      return state
        .set('pageParams', mappers.PageParams.from(pageParams))
        .set(
          'pageResults',
          OrderedMap(
            facilities.map((facility) => [
              facility.facilityId.toString(),
              mappers.Facility.from(facility),
            ]),
          ),
        );
    }

    case ACTIONS.GET_OPERATORS: {
      if (action.error) return state;

      return state.set('operators', List(action.payload));
    }

    case ACTIONS.GET_FACILITY_BY_ID: {
      if (action.error) return state;

      return state.set('facilityById', Map(action.payload));
    }

    case ACTIONS.GET_FACILITY_DECKS: {
      if (action.error) return state;
      return state.set('decks', List(action.payload));
    }

    default:
      return state;
  }
};

export default facilitiesReducer;
