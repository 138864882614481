import { put, call, takeEvery } from 'redux-saga/effects';

import {
  setActiveWellboredetailId,
  deleteProjectWellboreDetail,
  receiveProjectWellboreDetail,
  receiveProjectWellboreDetails,
} from 'features/projects/wellbore/details/projectWellboreDetails.actions';

import { callAsync } from 'app/sagas/helperSagas';
import { invokeIfFunction } from 'utils/app.util';
import { FormikFormStatus } from 'app/app.constants';
import projectWellboreDetailsService from 'services/projectWellboreDetail.service';
import projectWellboreSectionService from 'services/projectWellboreSection.service';

import { ACTIONS } from 'features/projects/wellbore/details/projectWellboreDetails.constants';
import projectFluidService from 'services/projectFluid.service';

export function* requestCreateProjectWellboreDetailDraftAsync(action) {
  const { projectId, projectWellboreDetailId: currentProjectWellboreDetailId } =
    action;

  const projectWellboreDetailDraft = yield call(
    projectWellboreDetailsService.createDraft,
    projectId,
  );

  yield call(
    projectFluidService.duplicateFluids,
    projectId,
    currentProjectWellboreDetailId,
    projectWellboreDetailDraft.projectWellboreDetailId,
  );

  yield put(receiveProjectWellboreDetail(projectWellboreDetailDraft));

  return projectWellboreDetailDraft;
}

export function* requestCreateProjectWellboreDetailDraftAndSyncAsync(action) {
  const { projectId, projectWellboreDetailId: currentProjectWellboreDetailId } =
    action;

  const projectWellboreDetailDraft = yield call(
    projectWellboreSectionService.syncAndCreateDraftProjectWellboreSectionsWithLatest,
    projectId,
  );

  yield call(
    projectFluidService.duplicateFluids,
    projectId,
    currentProjectWellboreDetailId,
    projectWellboreDetailDraft.projectWellboreDetailId,
  );
  yield put(receiveProjectWellboreDetail(projectWellboreDetailDraft));

  return projectWellboreDetailDraft;
}

export function* requestCancelProjectWellboreDetailDraftAsync(action) {
  const { projectId, projectWellboreDetailId } = action;

  yield call(
    projectWellboreDetailsService.cancelProjectWellboreDetailDraft,
    projectId,
    projectWellboreDetailId,
  );
}

export function* cancelProjectWellboreDetailDraftAndRedirectAsync(action) {
  const { projectWellboreDetailId } = action;

  yield call(requestCancelProjectWellboreDetailDraftAsync, action);

  const lastProjectWellboreDetail = yield call(
    requestLatestProjectWellDetailAsync,
    action,
  );

  yield put(
    setActiveWellboredetailId(
      lastProjectWellboreDetail.projectWellboreDetailId,
    ),
  );

  yield put(deleteProjectWellboreDetail(projectWellboreDetailId));
}

export function* createAndRedirectToProjectWellboreDetailAsync(action) {
  const projectWellboreDetailDraft = yield call(
    requestCreateProjectWellboreDetailDraftAsync,
    action,
  );

  yield put(
    setActiveWellboredetailId(
      projectWellboreDetailDraft.projectWellboreDetailId,
    ),
  );

  return projectWellboreDetailDraft;
}

export function* createAndRedirectToProjectWellboreDetailAndSyncAsync(action) {
  const projectWellboreDetailDraft = yield call(
    requestCreateProjectWellboreDetailDraftAndSyncAsync,
    action,
  );

  yield put(
    setActiveWellboredetailId(
      projectWellboreDetailDraft.projectWellboreDetailId,
    ),
  );

  return projectWellboreDetailDraft;
}

export function* requestApproveProjectWellboreDetailDraftAsync(action) {
  const { projectId, projectWellboreDetailId } = action;

  const projectWellboreDetail = yield call(
    projectWellboreDetailsService.approveDraft,
    projectId,
    projectWellboreDetailId,
  );

  yield put(receiveProjectWellboreDetail(projectWellboreDetail));

  return projectWellboreDetail;
}

export function* requestProjectWellboreDetailDraftApprovalAsync(action) {
  const { projectId, projectWellboreDetailId } = action;

  const projectWellboreDetail = yield call(
    projectWellboreDetailsService.requestApproval,
    projectId,
    projectWellboreDetailId,
  );

  yield put(receiveProjectWellboreDetail(projectWellboreDetail));

  return projectWellboreDetail;
}

export function* requestLatestProjectWellDetailAsync(action) {
  const { projectId } = action;

  const projectWellboreDetail = yield call(
    projectWellboreDetailsService.getLatestProjectWellboreDetail,
    projectId,
  );

  yield put(receiveProjectWellboreDetail(projectWellboreDetail));

  return projectWellboreDetail;
}

export function* requestProjectWellDetailsAsync(action) {
  const { projectId } = action;

  const projectWellboreDetails = yield call(
    projectWellboreDetailsService.getProjectWellboreDetails,
    projectId,
  );

  yield put(receiveProjectWellboreDetails(projectWellboreDetails));

  return projectWellboreDetails;
}

export function* requestAddHostileFluidsToProjectWellboreDetailAsync(action) {
  const {
    projectId,
    projectWellboreDetailId,
    payload: hostileFluidsToAdd,
  } = action;

  const projectWellboreDetail = yield call(
    projectWellboreDetailsService.addHostileFluidsToProjectWellboreDetails,
    projectId,
    projectWellboreDetailId,
    hostileFluidsToAdd,
  );

  yield put(receiveProjectWellboreDetail(projectWellboreDetail));

  return projectWellboreDetail;
}

export function* requestRemoveHostileFluidsFromProjectWellboreDetailAsync(
  action,
) {
  const {
    projectId,
    projectWellboreDetailId,
    payload: hostileFluidsToRemove,
  } = action;

  const projectWellboreDetail = yield call(
    projectWellboreDetailsService.removeHostileFluidsFromProjectWellboreDetails,
    projectId,
    projectWellboreDetailId,
    hostileFluidsToRemove,
  );

  yield put(receiveProjectWellboreDetail(projectWellboreDetail));

  return projectWellboreDetail;
}

export function* requestAddProjectWellboreDetailServicesAsync(action) {
  const {
    projectId,
    projectWellboreDetailId,
    payload: wellboreDetailServiceTypes,
  } = action;

  const projectWellboreDetail = yield call(
    projectWellboreDetailsService.addProjectWellboreDetailServices,
    projectId,
    projectWellboreDetailId,
    wellboreDetailServiceTypes,
  );

  yield put(receiveProjectWellboreDetail(projectWellboreDetail));

  return projectWellboreDetail;
}

export function* requestDeleteProjectWellboreDetailServiceAsync(action) {
  const {
    projectId,
    projectWellboreDetailId,
    payload: wellboreDetailServiceId,
  } = action;

  const projectWellboreDetail = yield call(
    projectWellboreDetailsService.deleteProjectWellboreDetailService,
    projectId,
    projectWellboreDetailId,
    wellboreDetailServiceId,
  );

  yield put(receiveProjectWellboreDetail(projectWellboreDetail));

  return projectWellboreDetail;
}

export function* requestProjectWellDetailAsync(action) {
  const { projectId, projectWellboreDetailId } = action;

  const projectWellboreDetail = yield call(
    projectWellboreDetailsService.getProjectWellboreDetail,
    projectId,
    projectWellboreDetailId,
  );

  yield put(receiveProjectWellboreDetail(projectWellboreDetail));

  return projectWellboreDetail;
}

export function* requestProjectWellboreDetailChangelogAsync(action) {
  const { projectId } = action;

  const changelog = yield call(
    projectWellboreDetailsService.getProjectWellboreDetailChangelog,
    projectId,
  );

  return changelog;
}

export function* loadProjectWellboreDetailsPageAsync(action) {
  const lastProjectWellboreDetail = yield call(
    requestLatestProjectWellDetailAsync,
    action,
  );

  return lastProjectWellboreDetail;
}

export function* requestUpdateWellboreDetailAsync(action) {
  const { projectId, projectWellboreDetailId, payload } = action;

  const projectWellboreDetail = yield call(
    projectWellboreDetailsService.updateWellboreDetail,
    projectId,
    projectWellboreDetailId,
    payload,
  );

  return projectWellboreDetail;
}

export function* submitWellboreDetailFormAsync(action) {
  const { setSubmitting, setStatus } = action;

  try {
    yield call(requestUpdateWellboreDetailAsync, action);
    invokeIfFunction(setStatus, FormikFormStatus.SUCCESS);
  } catch (error) {
    invokeIfFunction(setStatus, FormikFormStatus.ERROR);
    throw error;
  } finally {
    invokeIfFunction(setSubmitting, false); // if your onSubmit function is synchronous, then you need to call setSubmitting(false) on your own
  }
}

export function* syncProjectWellboreDetailsWithLatestAsync(action) {
  const { projectId, projectWellboreDetailId } = action;

  const projectWellboreDetail = yield call(
    projectWellboreDetailsService.syncProjectWellboreDetailsWithLatest,
    projectId,
    projectWellboreDetailId,
  );

  yield put(receiveProjectWellboreDetail(projectWellboreDetail));

  yield put(
    setActiveWellboredetailId(projectWellboreDetail.projectWellboreDetailId),
  );

  return projectWellboreDetail;
}

export default function* root() {
  yield takeEvery(
    ACTIONS.CREATE_PROJECT_WELLBORE_DETAIL_DRAFT,
    callAsync,
    requestCreateProjectWellboreDetailDraftAsync,
  );

  yield takeEvery(
    ACTIONS.CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT,
    callAsync,
    createAndRedirectToProjectWellboreDetailAsync,
  );

  yield takeEvery(
    ACTIONS.CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT_AND_SYNC,
    callAsync,
    createAndRedirectToProjectWellboreDetailAndSyncAsync,
  );

  yield takeEvery(
    ACTIONS.APPROVE_PROJECT_WELLBORE_DETAIL_DRAFT,
    callAsync,
    requestApproveProjectWellboreDetailDraftAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_PROJECT_WELLBORE_DETAIL_DRAFT_APPROVAL,
    callAsync,
    requestProjectWellboreDetailDraftApprovalAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_PROJECT_WELLBORE_DETAILS,
    callAsync,
    requestProjectWellDetailsAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_PROJECT_WELLBORE_DETAIL,
    callAsync,
    requestProjectWellDetailAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_LATEST_PROJECT_WELLBORE_DETAIL,
    callAsync,
    requestLatestProjectWellDetailAsync,
  );

  yield takeEvery(
    ACTIONS.GET_PROJECT_WELLBORE_DETAIL_CHANGE_LOG,
    callAsync,
    requestProjectWellboreDetailChangelogAsync,
  );

  yield takeEvery(
    ACTIONS.LOAD_PROJECT_WELLBORE_DETAILS_PAGE,
    callAsync,
    loadProjectWellboreDetailsPageAsync,
  );

  yield takeEvery(
    ACTIONS.SUBMIT_PROJECT_WELLBORE_DETAIL_FORM,
    callAsync,
    submitWellboreDetailFormAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_ADD_HOSTILE_FLUIDS_TO_PROJECT_WELLBORE_DETAIL,
    callAsync,
    requestAddHostileFluidsToProjectWellboreDetailAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_REMOVE_HOSTILE_FLUIDS_FROM_PROJECT_WELLBORE_DETAIL,
    callAsync,
    requestRemoveHostileFluidsFromProjectWellboreDetailAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_ADD_PROJECT_WELLBORE_DETAIL_SERVICES,
    callAsync,
    requestAddProjectWellboreDetailServicesAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_REMOVE_PROJECT_WELLBORE_DETAIL_SERVICE,
    callAsync,
    requestDeleteProjectWellboreDetailServiceAsync,
  );

  yield takeEvery(
    ACTIONS.CANCEL_PROJECT_WELLBORE_DETAIL_DRAFT,
    callAsync,
    cancelProjectWellboreDetailDraftAndRedirectAsync,
  );

  yield takeEvery(
    ACTIONS.SYNC_PROJECT_WELLBORE_DETAILS_WITH_LATEST,
    callAsync,
    syncProjectWellboreDetailsWithLatestAsync,
  );
}
