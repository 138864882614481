import { compose } from 'redux';
import { useEffect, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDropzone } from 'react-dropzone';
import { connect, useSelector } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { BasePage } from 'altus-ui-components';
import {
  getSummarizedDataStateFromState,
  getAvailableFileCategoriesFromState,
} from 'app/app.selectors';
import {
  EMPTY_SET,
  ProjectPermission,
  MAX_UPLOAD_FILE_SIZE,
} from 'app/app.constants';
import { FIELDS_ACTIONS } from 'features/fields/fields.constants';
import {
  deleteFile,
  downloadFile,
  onLoad,
  onUnload,
  updateFile,
  uploadFiles,
} from 'features/fields/fieldDocuments.actions';
import { setFile } from 'app/app.actions';
import { useHeader } from 'app/hooks/useHeader';
import { Fab } from 'app/components/withTooltip';
import UploadDocumentModal from 'app/components/UploadDocumentModal';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { useProjectPermissions } from 'app/hooks/authorization/useProjectPermissions';
import { getFieldDocumentsFromState } from 'features/fields/field.selectors';
import FieldDocumentsTable from 'features/fields/documents/FieldDocumentsTable';

const FieldDocumentsContainer = ({
  classes,
  dataState,
  fieldId,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchDropFiles,
  dispatchDeleteFile,
  dispatchUpdateFile,
  dispatchUploadFiles,
  dispatchDownloadFile,
}) => {
  useHeader({ subTitle: breadcrumb });

  useEffect(() => {
    dispatchOnLoad(fieldId);

    return () => dispatchOnUnload();
  }, [fieldId, dispatchOnLoad, dispatchOnUnload]);

  const { hasPermission: hasUploadDocumentPermission } = useProjectPermissions(
    ProjectPermission.UPLOAD_DOCUMENTS,
  );

  const [filter, setFilter] = useState(EMPTY_SET);

  const documents = useSelector(getFieldDocumentsFromState);
  const documentCategories = useSelector(getAvailableFileCategoriesFromState);

  const filteredDocuments = useMemo(() => {
    if (filter.isEmpty()) return documents;

    return documents.filter((document) => {
      const documentCategory = documentCategories.get(document.get('category'));

      return filter.includes(documentCategory);
    });
  }, [filter, documents, documentCategories]);

  useEffect(() => {
    if (filteredDocuments?.isEmpty()) {
      setFilter(EMPTY_SET);
    }

    // clear filter if deleting the last document to prevent a filter being active when no documents match it
  }, [filteredDocuments]);

  const downloadFile = useCallback(
    (fieldFileId, file) => dispatchDownloadFile(fieldId, fieldFileId, file),
    [fieldId, dispatchDownloadFile],
  );

  const deleteFile = useCallback(
    (fieldFileId) => dispatchDeleteFile(fieldId, fieldFileId),
    [fieldId, dispatchDeleteFile],
  );

  const updateFile = useCallback(
    (file) => dispatchUpdateFile(fieldId, file.fieldFileId, file),
    [fieldId, dispatchUpdateFile],
  );

  const uploadFiles = useCallback(
    (files) => dispatchUploadFiles(fieldId)(files),
    [fieldId, dispatchUploadFiles],
  );

  const onDrop = useCallback(
    (files) => dispatchDropFiles(files),
    [dispatchDropFiles],
  );

  const { isDragActive, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      maxSize: MAX_UPLOAD_FILE_SIZE,
      noDrag: !hasUploadDocumentPermission,
    });

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
        root: classNames({
          [classes.dragOver]: isDragActive,
        }),
      }}
    >
      <Grid item container xs {...getRootProps()}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <FieldDocumentsTable
            updateFile={updateFile}
            deleteFile={deleteFile}
            downloadFile={downloadFile}
            documents={filteredDocuments}
            documentCategoriesById={documentCategories}
            hasUploadDocumentPermission={hasUploadDocumentPermission}
            noItemsMessage="Upload one or more documents simultaneously by using the + button or drag them here"
          />
        </Grid>
        <Grid item xs={2}>
          <HasProjectPermission
            permissions={ProjectPermission.UPLOAD_DOCUMENTS}
          >
            <UploadDocumentModal
              uploadFiles={uploadFiles}
              enableFileCategories={false}
              rejectedFiles={fileRejections}
              availableFileCategories={documentCategories.valueSeq()}
              dialogContentText="Set meta data and upload files to project"
            />
            <Fab
              onClick={open}
              color="primary"
              title="Upload documents"
              className={classes.create}
            >
              <AddIcon />
            </Fab>
          </HasProjectPermission>
        </Grid>
      </Grid>
      <input {...getInputProps()} />
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    FIELDS_ACTIONS.DOWNLOAD_FIELD_DOCUMENT,
    FIELDS_ACTIONS.UPDATE_FIELD_DOCUMENT,
    FIELDS_ACTIONS.UPDATE_FIELD_DOCUMENT,
    FIELDS_ACTIONS.DELETE_FIELD_DOCUMENT,
    FIELDS_ACTIONS.FIELD_DOCUMENTS_PAGE_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoad,
  dispatchOnUnload: onUnload,
  dispatchDropFiles: setFile,
  dispatchDeleteFile: deleteFile,
  dispatchUpdateFile: updateFile,
  dispatchUploadFiles: uploadFiles,
  dispatchDownloadFile: downloadFile,
};

const styles = (theme) => ({
  create: {
    zIndex: 2,
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  dragOver: {
    opacity: '0.3',
    border: '5px gray dashed',
  },
  basePageChildren: {
    flexDirection: 'row',
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(FieldDocumentsContainer);
