import { compose } from 'redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { useMemo, useCallback } from 'react';
import Cancel from '@material-ui/icons/Cancel';
import Check from '@material-ui/icons/Check';
import withStyles from '@material-ui/styles/withStyles';
import { IconButton, Grid } from '@material-ui/core';
import { MenuItem, Select } from '@material-ui/core';

import { TableRow, TableCell } from 'altus-ui-components';

import { invokeIfFunction, isValidJSON } from 'utils/app.util';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import EditorFormik from 'app/components/form/formik/EditorFormik';
import { RiskSectionFormFields } from 'features/projects/components/RiskSectionsTable';
import {
  RiskConsequenceEnum,
  RiskMatrix,
  RiskProbabilityEnum,
  RiskStateEnum,
} from 'app/app.constants';

const CreateRiskSectionRow = ({
  classes,
  columns,
  toggleCreateMode,
  createRiskSection,
  defaultRiskSection,
}) => {
  const consequences = Object.values(RiskConsequenceEnum);
  const probabilities = Object.values(RiskProbabilityEnum);
  const states = Object.values(RiskStateEnum);
  const descriptionEditorState = !defaultRiskSection?.get('description')
    ? EditorState.createEmpty()
    : isValidJSON(defaultRiskSection?.get('description'))
      ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(defaultRiskSection?.get('description'))),
      )
      : EditorState.createEmpty();

  const mitigationEditorState = !defaultRiskSection?.get('mitigation')
    ? EditorState.createEmpty()
    : isValidJSON(defaultRiskSection?.get('mitigation'))
      ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(defaultRiskSection?.get('mitigation'))),
      )
      : EditorState.createEmpty();

  const initialValues = useMemo(
    () => ({
      [RiskSectionFormFields.RISK]: descriptionEditorState,
      [RiskSectionFormFields.MITIGATION]: mitigationEditorState,
      [RiskSectionFormFields.DESCRIPTION]: descriptionEditorState,
      [RiskSectionFormFields.CONSEQUENCE]: RiskConsequenceEnum.Major.id,
      [RiskSectionFormFields.PROBABILITY]: RiskProbabilityEnum.VeryLikely.id,
      [RiskSectionFormFields.STATE]: RiskStateEnum.Open.id,
    }),
    [descriptionEditorState, mitigationEditorState],
  );
  const onSubmit = useCallback(
    (riskSection, formik) => {
      const payload = {
        [RiskSectionFormFields.MITIGATION]: JSON.stringify(
          convertToRaw(riskSection.mitigation.getCurrentContent()),
        ),
        [RiskSectionFormFields.DESCRIPTION]: JSON.stringify(
          convertToRaw(riskSection.description.getCurrentContent()),
        ),
        [RiskSectionFormFields.RISK]: JSON.stringify(
          convertToRaw(riskSection.description.getCurrentContent()),
        ),
        [RiskSectionFormFields.CONSEQUENCE]: riskSection.consequence,
        [RiskSectionFormFields.PROBABILITY]: riskSection.probability,
        [RiskSectionFormFields.STATE]: riskSection.state,
      };
      createRiskSection(payload, formik, toggleCreateMode);
      formik.resetForm({ values: initialValues });
      toggleCreateMode();
    },
    [createRiskSection, toggleCreateMode, initialValues],
  );

  const [toolbarHeight, setToolbarHeight] = useState(0);
  const [color, setColor] = useState('red');
  const [probabilityId, setProbability] = useState(1);
  const [consequenceId, setConsequence] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      const toolbar = document.querySelector('.rdw-editor-toolbar');
      if (toolbar) {
        const height = toolbar.offsetHeight;
        setToolbarHeight(height);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderCell = (index, render) => {
    const column = columns[index];
    const paddingColumns = [0, 1];
    const cellClasses = paddingColumns.includes(index)
      ? classes.noPadding
      : classes.cell;

    return (
      <TableCell item xs={column.xs} classes={{ root: cellClasses }}>
        {invokeIfFunction(render, column)}
      </TableCell>
    );
  };

  const handleChange = (event, formik) => {
    const { name, value } = event.target;

    if (name === RiskSectionFormFields.CONSEQUENCE) {
      setConsequence(value);
      setColor(RiskMatrix[value][probabilityId]);
    }

    if (name === RiskSectionFormFields.PROBABILITY) {
      setProbability(value);
      setColor(RiskMatrix[consequenceId][value]);
    }

    formik.handleChange(event);
  };

  return (
    <Formik validateOnMount onSubmit={onSubmit} initialValues={initialValues}>
      {({ isValid, isSubmitting, handleSubmit, dirty, ...formik }) => (
        <Form>
          <TableRow
            classes={{
              root: classes.tableRowRoot,
              rootStriped: classes.tableRowRoot,
            }}
          >
            {renderCell(0, () => (
              <Field name={RiskSectionFormFields.RISK}>
                {({ form, ...formik }) => (
                  <EditorFormik
                    form={form}
                    {...formik}
                    toolbar={{
                      options: ['inline', 'list'],
                    }}
                    toolbarOnFocus
                    xs={12}
                    toolbarHeight={toolbarHeight}
                    editorXS={12}
                    generalMarginTop={2}
                    editorMarginTop={0}
                    marginBottom={5}
                    isRisk={true}
                  />
                )}
              </Field>
            ))}
            {renderCell(1, () => (
              <Field name={RiskSectionFormFields.MITIGATION}>
                {({ form, ...formik }) => (
                  <EditorFormik
                    form={form}
                    {...formik}
                    toolbar={{
                      options: ['inline', 'list'],
                    }}
                    toolbarOnFocus
                    toolbarHeight={toolbarHeight}
                    xs={12}
                    editorXS={12}
                    generalMarginTop={2}
                    editorMarginTop={0}
                    marginBottom={5}
                    isRisk={true}
                  />
                )}
              </Field>
            ))}
            {renderCell(
              2,
              () => (
                <Grid
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div
                    style={{
                      backgroundColor: color,
                      borderRadius: '50%',
                      width: '25px',
                      height: '25px',
                      marginLeft: '3vh',
                    }}
                  ></div>
                </Grid>
              ),
              'rating',
              'rating',
              'rating',
            )}
            {renderCell(
              3,
              () => (
                <Grid container xs={12}>
                  <Field
                    as={Select}
                    name={RiskSectionFormFields.CONSEQUENCE}
                    id="consequence"
                    onChange={(event) => handleChange(event, formik)}
                  >
                    {consequences.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              ),
              'consequence',
              'Consequence',
              'consequence',
            )}

            {renderCell(
              4,
              () => (
                <Grid container>
                  <Field
                    as={Select}
                    name={RiskSectionFormFields.PROBABILITY}
                    id="probability"
                    onChange={(event) => handleChange(event, formik)}
                  >
                    {probabilities.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              ),
              'probability',
              'Probability',
              'probability',
            )}

            {renderCell(
              5,
              () => (
                <Grid container>
                  <Field
                    as={Select}
                    name={RiskSectionFormFields.STATE}
                    id="state"
                  >
                    {states.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              ),
              'state',
              'State',
              'state',
            )}

            <TableCell
              xs={1}
              style={{ display: 'grid' }}
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                title="Save"
                onClick={handleSubmit}
                className={classes.saveButton}
                disabled={!isValid || isSubmitting || !dirty}
              >
                <Check fontSize="small" />
              </IconButton>
              <IconButton
                title="Cancel"
                onClick={toggleCreateMode}
                className={classes.cancelButton}
              >
                <Cancel fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        </Form>
      )}
    </Formik>
  );
};

const styles = (theme) => ({
  saveButton: {
    padding: '1px !important',
    width: '20px',
    height: '18px',
    margin: '0 0 5px 0px',
    background: theme.palette.success.dark,
    '&:hover': {
      background: theme.palette.success.main,
    },
  },
  cancelButton: {
    padding: '0 0',
    width: '20px',
    height: '18px',
  },
  tableRowRoot: {
    height: 'fit-content',
    minHeight: '7.2rem',
    maxHeight: 'fit-content',
    display: 'flex !important',
    alignItems: 'stretch',
    flex: '1 1 auto',
    '&:hover': {
      background: theme.altus.components.SortableListRow.background,
    },
    '& [class*="TableCell-root"]': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  },
  cell: {},
  noPadding: {
    padding: '0 2px !important',
  },
});

export default compose(withStyles(styles))(CreateRiskSectionRow);
