import { compose } from 'redux';
import { connect } from 'react-redux';

import { getTaskFromState } from 'features/projects/tasks/task/toolstring/toolstring.selectors';

const TaskBreadcrumb = ({ task }) => task?.get('title') ?? '...';

export default compose(
  connect((state, { match }) => ({
    task: getTaskFromState(state, match.params.taskId),
  })),
)(TaskBreadcrumb);
