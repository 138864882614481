import { compose } from 'redux';
import { useEffect } from 'react';
import { Field, Formik } from 'formik';
import { connect, useDispatch } from 'react-redux';
import PeopleIcon from '@material-ui/icons/People';
import withStyles from '@material-ui/styles/withStyles';
import {
  Grid,
  Divider,
  TextField,
  Typography,
  MenuItem,
} from '@material-ui/core';

import { useModal } from 'altus-modal';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

import {
  PARTICIPANTSMODAL,
  SITUATIONSMODAL,
} from 'features/wellControlDrillActivity/constants';

import { Button } from 'app/components/withTooltip';
import SwitchFormik from 'app/components/form/formik/SwitchFormik';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { TEAM_ACTIONS } from 'features/projects/team/team.constants';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { onLoadProjectFieldCrew } from 'features/projects/team/team.actions';
import DateTimePickerFormik from 'app/components/form/formik/DateTimePickerFormik';
import { ACTIVITIES_ACTIONS } from 'features/projects/activities/activities.constants';
import { updateActivityWcd } from 'features/wellControlDrillActivity/wellControl.actions';
import { WELL_CONTROL_ACTIONS } from 'features/wellControlDrillActivity/wellControl.constants';
import { deleteActivityNoNotification } from 'features/projects/activities/activities.actions';
import SituationsModalContainer from 'features/wellControlDrillActivity/SituationsModalContainer';
import AddParticipantsModalContainer from 'features/wellControlDrillActivity/AddParticipantsModalContainer';
import { EditorContentToStringWithTry, isValidJSON } from 'utils/app.util';
import EditorFormik from 'app/components/form/formik/EditorFormik';
import { WellControlDrillResult } from 'app/app.constants';

const FormFields = {
  PROJECT_ID: 'projectId',
  TASK_ID: 'taskId',
  ACTIVITY_ID: 'id',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  EVALUATION: 'comments',
  SELECTED_SITUATIONS: 'wellControlDrillSituations',
  PARTICIPANTS: 'participants',
  TITLE: 'title',
  NAME: 'name',
  ACTIVITY_TYPE_ID: 'activityTypeId',
  FAILURE_BARRIER: 'failiureBarrier',
  FAILURE_ACTIVATE_BARRIER: 'failureActivateBarrier',
  FAILURE_ACTIVATE_SECOND_BARRIER: 'failureActivateSecondBarrier',
  MEET_EXPECTED_TIME: 'expectedTimeMet',
  DESCRIPTION: 'description',
  PARALLELACTIVITY: 'parallelActivity',
  WELL_CONTROL_DRILL_RESULT: 'wellControlDrillResult',
};

const WellControlDrillActivityContainer = ({
  classes,
  dataState,
  activityForm,
  dispatchFieldCrewOnLoad,
}) => {
  const activityCommentsEditorState = !activityForm?.comments
    ? // This fix reffers to bug #8451, when comment is NULL in DB it affects form behaviour
      null
    : isValidJSON(
        JSON.stringify(
          convertToRaw(activityForm?.comments?.getCurrentContent()),
        ),
      )
    ? EditorState.createWithContent(
        convertFromRaw(
          JSON.parse(
            JSON.stringify(
              convertToRaw(activityForm?.comments?.getCurrentContent()),
            ),
          ),
        ),
      )
    : null;

  const initialFormValues = {
    [FormFields.PROJECT_ID]: activityForm.projectId,
    [FormFields.TASK_ID]: activityForm.taskId,
    [FormFields.ACTIVITY_ID]: activityForm.id,
    [FormFields.START_TIME]: activityForm.activityStartTime,
    [FormFields.END_TIME]: activityForm.activityEndTime,
    [FormFields.EVALUATION]: activityCommentsEditorState,
    [FormFields.SELECTED_SITUATIONS]: activityForm.wellControlDrillSituations,
    [FormFields.PARTICIPANTS]: activityForm.participants,
    [FormFields.TITLE]: activityForm.title,
    [FormFields.NAME]: activityForm.type.name,
    [FormFields.ACTIVITY_TYPE_ID]: activityForm.type.id,
    [FormFields.FAILURE_BARRIER]: activityForm.failiureBarrier,
    [FormFields.FAILURE_ACTIVATE_BARRIER]: activityForm.failureActivateBarrier,
    [FormFields.FAILURE_ACTIVATE_SECOND_BARRIER]:
      activityForm.failureActivateSecondBarrier,
    [FormFields.MEET_EXPECTED_TIME]: activityForm.expectedTimeMet,
    [FormFields.DESCRIPTION]: EditorContentToStringWithTry(
      activityForm?.description,
    ),
    [FormFields.PARALLELACTIVITY]: EditorContentToStringWithTry(
      activityForm?.parallelActivity,
    ),
    [FormFields.WELL_CONTROL_DRILL_RESULT]: activityForm.wellControlDrillResult,
  };
  const dispatch = useDispatch();

  const handleClick = (values) => {
    const rawContentState = convertToRaw(values?.comments?.getCurrentContent());
    const evaluationStringified = JSON.stringify(rawContentState);
    const updatedValues = {
      ...values,
      comments: evaluationStringified,
    };

    dispatch(updateActivityWcd(updatedValues));
  };

  const handleDelete = () => {
    dispatch(
      deleteActivityNoNotification(
        activityForm.projectId,
        activityForm.taskId,
        activityForm.id,
      ),
    );
  };

  useEffect(() => {
    dispatchFieldCrewOnLoad(activityForm.projectId);
  }, [dispatchFieldCrewOnLoad, activityForm.projectId]);

  const [isOpenParticipants, toggleModalParticipants] =
    useModal(PARTICIPANTSMODAL);

  const [isOpenSituations, toggleModalSituations] = useModal(SITUATIONSMODAL);

  return (
    <Formik enableReinitialize initialValues={initialFormValues}>
      {({ values }) => (
        <>
          <Grid item container className={classes.contentContainer}>
            <Grid
              container
              className={classes.content}
              direction="column"
              spacing={5}
            >
              <Grid item container>
                <Grid item container xs={6} direction="column">
                  <Typography variant="caption" color="textSecondary">
                    Code
                  </Typography>
                  <Typography variant="h6">WCD</Typography>
                </Grid>
                <Grid item container xs={6} direction="column">
                  <Typography variant="caption" color="textSecondary">
                    Name
                  </Typography>
                  <Typography variant="h6">Well control drill</Typography>
                </Grid>
              </Grid>
              <Grid item container>
                <Field name={FormFields.TITLE}>
                  {({ form, ...formik }) => (
                    <TextFieldFormik
                      form={form}
                      {...formik}
                      label="Title"
                      fullWidth
                      InputProps={{
                        endAdornment: <PeopleIcon />,
                      }}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item container direction="row">
                <Grid container xs={4} direction="column" spacing={3}>
                  <Grid item container>
                    <TextField
                      defaultValue="5"
                      fullWidth
                      select
                      label="Situations"
                      SelectProps={{
                        open: false,
                        displayEmpty: true,
                        onOpen: toggleModalSituations,
                        renderValue: () =>
                          `${values.wellControlDrillSituations.length} selected`,
                      }}
                    />
                  </Grid>
                  <Grid item container>
                    <TextField
                      fullWidth
                      select
                      label="Participants"
                      SelectProps={{
                        open: false,
                        displayEmpty: true,
                        onOpen: toggleModalParticipants,
                        renderValue: () =>
                          `${values.participants.length} selected`,
                      }}
                    />
                  </Grid>
                  <Grid item container>
                    <Field name={FormFields.START_TIME}>
                      {({ form, ...formik }) => (
                        <DateTimePickerFormik
                          form={form}
                          {...formik}
                          label="Started"
                          margin=""
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item container>
                    <Field
                      select
                      destroyOnUnmount
                      label="Result"
                      component={TextFieldFormik}
                      name={FormFields.WELL_CONTROL_DRILL_RESULT}
                    >
                      <MenuItem key="" value=""></MenuItem>
                      {WellControlDrillResult.map((result) => (
                        <MenuItem key={result.id} value={result.id}>
                          {result.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container xs={1}></Grid>
                <Grid item container xs={7} direction="column" spacing={2}>
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item container xs={8}>
                      <Typography>
                        Did the test include failure of barrier?
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      xs={4}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="caption">No</Typography>
                      <Field name={FormFields.FAILURE_BARRIER}>
                        {({ form, ...formik }) => (
                          <SwitchFormik form={form} {...formik} />
                        )}
                      </Field>
                      <Typography variant="caption">Yes</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item container xs={8}>
                      <Typography>
                        Did the test include failure to activate barrier?
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      xs={4}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="caption">No</Typography>
                      <Field name={FormFields.FAILURE_ACTIVATE_BARRIER}>
                        {({ form, ...formik }) => (
                          <SwitchFormik form={form} {...formik} />
                        )}
                      </Field>
                      <Typography variant="caption">Yes</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item container xs={8}>
                      <Typography>
                        Did the test include failure to activate second barrier?
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      xs={4}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="caption">No</Typography>
                      <Field name={FormFields.FAILURE_ACTIVATE_SECOND_BARRIER}>
                        {({ form, ...formik }) => (
                          <SwitchFormik form={form} {...formik} />
                        )}
                      </Field>
                      <Typography variant="caption">Yes</Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Grid item container xs={8}>
                      <Typography>
                        Did the test meet expected time for securing the
                        situation?
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      xs={4}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="caption">No</Typography>
                      <Field name={FormFields.MEET_EXPECTED_TIME}>
                        {({ form, ...formik }) => (
                          <SwitchFormik form={form} {...formik} />
                        )}
                      </Field>
                      <Typography variant="caption">Yes</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container>
                <Field name={FormFields.EVALUATION}>
                  {({ form, ...formik }) => (
                    <EditorFormik
                      form={form}
                      toolbar={{
                        options: ['inline', 'list'],
                      }}
                      label="Evaluation"
                      maxLength={1000}
                      minHeight={7}
                      maxHeight={7}
                      minRows={10}
                      labelMarginLeft={8}
                      margin="none"
                      xs={12}
                      placeholder={''}
                      {...formik}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              className={classes.footer}
            >
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => handleDelete()}
                >
                  Delete
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleClick(values)}
                >
                  Save and close
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Field name={FormFields.PARTICIPANTS}>
            {({ form, ...formik }) => (
              <AddParticipantsModalContainer
                dataState={dataState}
                form={form}
                {...formik}
                isOpen={isOpenParticipants}
                toggleModal={toggleModalParticipants}
              />
            )}
          </Field>
          <Field name={FormFields.SELECTED_SITUATIONS}>
            {({ form, ...formik }) => (
              <SituationsModalContainer
                dataState={dataState}
                form={form}
                {...formik}
                isOpen={isOpenSituations}
                toggleModal={toggleModalSituations}
              />
            )}
          </Field>
        </>
      )}
    </Formik>
  );
};

const styles = (theme) => ({
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    justifyContent: 'center',
    borderRadius: 3,
    flexDirection: 'column',
  },
  header: {
    backgroundColor: theme.palette.secondary.dark,
    padding: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footer: {
    padding: theme.spacing(2),
  },
});

export default compose(
  connect(
    (state) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        WELL_CONTROL_ACTIONS.GET_ALL_CATEGORIES_AND_SITUATIONS,
        WELL_CONTROL_ACTIONS.UPDATE_WELL_CONTROLL_DRILL_ACTIVITY,
        TEAM_ACTIONS.PROJECT_FIELD_CREW_PAGE_LOADED,
        ACTIVITIES_ACTIONS.GET_TASK_ACTIVITIES,
        ACTIVITIES_ACTIONS.CONFIRM_DELETE_ACTIVITY,
      ),
    }),
    {
      dispatchFieldCrewOnLoad: onLoadProjectFieldCrew,
    },
  ),
  withStyles(styles),
)(WellControlDrillActivityContainer);
