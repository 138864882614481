import { Grid, Button, Typography } from '@material-ui/core';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';

export const CreateTaskModalHeader = ({ title, subTitle, onClose }) => (
  <ApplicationHeaderBase>
    <Grid container alignItems="center">
      <Grid item xs />
      <Grid
        xs
        item
        container
        zeroMinWidth
        alignItems="center"
        direction="column"
      >
        <Typography noWrap variant="body2" color="textSecondary">
          {subTitle}
        </Typography>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item container xs justifyContent="flex-end">
        <Button color="secondary" variant="contained" onClick={onClose}>
          Close
        </Button>
      </Grid>
    </Grid>
  </ApplicationHeaderBase>
);

export default CreateTaskModalHeader;
