import { compose } from 'redux';
import classNames from 'classnames';
import Add from '@material-ui/icons/Add';
import { useState, useCallback } from 'react';
import { reduxForm } from 'redux-form/immutable';
import { connect, useSelector } from 'react-redux';
import ViewList from '@material-ui/icons/ViewList';
import withStyles from '@material-ui/styles/withStyles';

import {
  Box,
  Grid,
  Dialog,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  DialogActions,
  List as MuiList,
} from '@material-ui/core';

import { useModal } from 'altus-modal';
import { BasePage, LoadingButton } from 'altus-ui-components';

import {
  TASK_FORMS,
  TASK_ACTIONS,
  TASKS_CREATE_TASK_MODE,
  TASKS_CREATE_TASK_MODAL_ID,
} from 'features/projects/tasks/tasks.constants';

import {
  createTask,
  addTasksFromTemplate,
  createTaskModalContainerOnLoad,
} from 'features/projects/tasks/tasks.actions';

import {
  getAvailableServicesFromState,
  getSummarizedDataStateFromState,
  getAvailableDepartmentsFromState,
} from 'app/app.selectors';

import { EMPTY_LIST } from 'app/app.constants';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import CreateTaskForm from 'features/projects/tasks/components/createTask/CreateTaskForm';
import TemplateTasksTable from 'features/projects/tasks/components/createTask/TemplateTasksTable';
import CreateTaskModalHeader from 'features/projects/tasks/components/createTask/CreateTaskModalHeader';
import SelectedTemplateTasksTable from 'features/projects/tasks/components/createTask/SelectedTemplateTasksTable';

const { NEW_TASK } = TASK_FORMS;

const options = [
  [TASKS_CREATE_TASK_MODE.NEW, Add, 'Create new'],
  [TASKS_CREATE_TASK_MODE.FROM_TEMPLATE, ViewList, 'From template'],
];

const CreateTaskModalContainer = ({
  title,
  valid,
  classes,
  projectId,
  dataState,
  handleSubmit,
  templateTasks,
  dispatchOnLoad,
  displayMenu = true,
  dispatchCreateTask,
  dispatchAddTasksFromTemplate,
  FromTemplateTableRowComponent,
  defaultMode = TASKS_CREATE_TASK_MODE.NEW,
}) => {
  const createTask = handleSubmit(dispatchCreateTask(projectId));

  const project = useCurrentProject();

  const services = useSelector(getAvailableServicesFromState);
  const departments = useSelector(getAvailableDepartmentsFromState);

  const [isOpen, toggleModal] = useModal(TASKS_CREATE_TASK_MODAL_ID);

  const cleanListAndClose = () => {    
    toggleModal();
    setSelectedTemplateTasks(EMPTY_LIST);
  }

  const [createMode, setCreateMode] = useState(defaultMode);

  const [selectedTemplateTasks, setSelectedTemplateTasks] =
    useState(EMPTY_LIST);

  const selectTemplateTask = useCallback(
    (item) =>
      setSelectedTemplateTasks((currentSelectedItems) =>
        currentSelectedItems.push(item),
      ),
    [],
  );

  const deselectTemplateTask = useCallback(
    (item) =>
      setSelectedTemplateTasks((currentSelectedItems) =>
        currentSelectedItems.delete(currentSelectedItems.indexOf(item)),
      ),
    [],
  );

  const creatingFromTemplate =
    createMode === TASKS_CREATE_TASK_MODE.FROM_TEMPLATE;

  const isLoading = dataState.isLoading();

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={toggleModal}
      TransitionProps={{
        onEnter: dispatchOnLoad,
      }}
    >
      <CreateTaskModalHeader
        title={title}
        onClose={toggleModal}
        subTitle={project.get('fullTitle')}
      />
      <BasePage
        classes={{
          children: classes.basePageChildren,
        }}
      >
        <Grid item container xs>
          {displayMenu && (
            <Grid item xs={3} component={Box} paddingRight={2}>
              <MuiList component="nav">
                {options.map(([mode, Icon, title, disabled]) => (
                  <ListItem
                    button
                    key={mode}
                    disabled={disabled}
                    onClick={() => setCreateMode(mode)}
                    className={classNames(classes.listItem, {
                      [classes.selectedMode]: createMode === mode,
                    })}
                  >
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={title} />
                  </ListItem>
                ))}
              </MuiList>
            </Grid>
          )}
          <Grid item xs={6}>
            {creatingFromTemplate && (
              <TemplateTasksTable
                templateTasks={templateTasks}
                selectTemplateTask={selectTemplateTask}
                TableRowComponent={FromTemplateTableRowComponent}
              />
            )}
            {!creatingFromTemplate && (
              <CreateTaskForm services={services} departments={departments} />
            )}
          </Grid>
          <Grid item xs={displayMenu ? 3 : 6} component={Box} paddingLeft={2}>
            {creatingFromTemplate && (
              <SelectedTemplateTasksTable
                deselectTemplateTask={deselectTemplateTask}
                selectedTemplateTasks={selectedTemplateTasks}
              />
            )}
          </Grid>
        </Grid>
      </BasePage>
      <DialogActions
        classes={{
          root: classes.dialogActionsRoot,
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <Button onClick={cleanListAndClose}>Cancel</Button>
          </Grid>
          {creatingFromTemplate && (
            <Grid item>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={isLoading}
                disabled={
                  !valid || isLoading || selectedTemplateTasks.isEmpty()
                }
                onClick={() => {
                  dispatchAddTasksFromTemplate(projectId, selectedTemplateTasks).then(
                    setSelectedTemplateTasks(EMPTY_LIST),
                  );
                }
                }
              >
                {`Add (${selectedTemplateTasks.size})`}
              </LoadingButton>
            </Grid>
          )}
          {!creatingFromTemplate && (
            <Grid item>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={createTask}
                loading={isLoading}
                disabled={!valid || isLoading}
              >
                Create
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  services: getAvailableServicesFromState(state),
  departments: getAvailableDepartmentsFromState(state),
  dataState: getSummarizedDataStateFromState(
    state,
    TASK_ACTIONS.CREATE_TASK,
    TASK_ACTIONS.ADD_TASKS_FROM_TEMPLATE,
  ),
});

const mapDispatchToProps = {
  dispatchCreateTask: createTask,
  dispatchOnLoad: createTaskModalContainerOnLoad,
  dispatchAddTasksFromTemplate: addTasksFromTemplate,
};

const styles = (theme) => ({
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectedMode: {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
  dialogActionsRoot: {
    padding: theme.spacing(3),
    background: theme.altus.background.header,
  },
});

export default compose(
  reduxForm({
    form: NEW_TASK.FORM_ID,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CreateTaskModalContainer);
