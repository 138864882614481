import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import {
  getImportSourceById,
  getStatusById,
} from 'features/fields/fields.constants';

const FieldInfoContent = ({ classes, displayDataText, field }) => {
  return (
    <>
      <Grid item container className={classes.container}>
        <Grid item xs={12} sx={{ height: 'fit-content' }}>
          <Typography variant="body2" className={classes.fieldInfo}>
            Field Information
          </Typography>
        </Grid>
        <Grid item container className={classes.bottomText}>
          <Grid item container xs={12} className={classes.bottomTextLine}>
            <Grid item container xs={6}>
              <Typography variant="h5">Name</Typography>
            </Grid>
            <Grid item container xs={6}>
              <Typography variant="h5">
                {displayDataText(field.get('name'))}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.bottomTextLine}>
            <Grid item container xs={6}>
              <Typography variant="h5">Status</Typography>
            </Grid>
            <Grid item container xs={6}>
              <Typography variant="h5">
                {getStatusById(field.get('status'))}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.bottomTextLine}>
            <Grid item container xs={6}>
              <Typography variant="h5">Npd Field Id</Typography>
            </Grid>
            <Grid item container xs={6}>
              <Typography variant="h5">
                {displayDataText(field.get('npdFieldId'))}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.bottomTextLine}>
            <Grid item container xs={6}>
              <Typography variant="h5">Import source</Typography>
            </Grid>
            <Grid item container xs={6}>
              <Typography variant="h5">
                {getImportSourceById(field.get('importSource'))}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.bottomTextLine}>
            <Grid item container xs={6}>
              <Typography variant="h5">Country code Iso 3166</Typography>
            </Grid>
            <Grid item container xs={6}>
              <Typography variant="h5">
                {displayDataText(field.get('countryCodeIso3166'))}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} className={classes.bottomTextLine}>
            <Grid item container xs={6}>
              <Typography variant="h5">Organizations</Typography>
            </Grid>
            <Grid item container xs={6}>
              {field.get('organizations')?.size
                ? field
                    .get('organizations')
                    .map((organization) => (
                      <Typography variant="h5">
                        {displayDataText(organization.get('displayName'))}
                      </Typography>
                    ))
                : '-'}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.tabs.background.light,
  },
  fieldInfo: {
    fontSize: '2rem',
  },
  bottomText: {
    height: 'fit-content',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderRadius: 3,
    padding: theme.spacing(3),
  },
  bottomTextLine: {
    height: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    borderBottom: `2px dotted ${theme.palette.secondary.main}`,
  },
});

export default withStyles(styles)(FieldInfoContent);
