import { useCallback } from 'react';

import { useRequest } from 'altus-hooks';

import { EMPTY_MAP } from 'app/app.constants';
import projectWellboreSectionService from 'services/projectWellboreSection.service';
import wellboreSectionsMappers from 'features/wells/sections/wellboreSection.mappers';

const useDefaultProjectWellboreSection = (projectId) => {
  const getDefaultProjectWellboreSection = useCallback(
    () =>
      projectWellboreSectionService
        .getDefaultProjectWellboreSection(projectId)
        .then((wellboreSection) =>
          wellboreSectionsMappers.WellboreSection.from(wellboreSection),
        ),
    [projectId],
  );

  const [defaultProjectWellboreWellboreSection = EMPTY_MAP] = useRequest(
    getDefaultProjectWellboreSection,
  );

  return defaultProjectWellboreWellboreSection;
};

export default useDefaultProjectWellboreSection;
