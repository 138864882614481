import { Map } from 'immutable';
import {
  CABLE_TAB_FIELDS,
  TOOLS_TAB_FIELDS,
} from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';

const editorStateToString = (editorState) => {
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  return JSON.stringify(rawContentState);
};

const stringToEditorState = (str) => {
  const rawContentState = JSON.parse(str);
  const contentState = convertFromRaw(rawContentState);
  return EditorState.createWithContent(contentState);
};

const DataAcquisitionMapper = {
  from: (dataAcquisition) => {
    const {
      [CABLE_TAB_FIELDS.COMMENTS]: comments,
      [TOOLS_TAB_FIELDS.DESCRIPTION]: description,
      ...restDataAcquisition
    } = dataAcquisition;
    restDataAcquisition[CABLE_TAB_FIELDS.COMMENTS] = comments
      ? stringToEditorState(comments)
      : undefined;
    restDataAcquisition[TOOLS_TAB_FIELDS.DESCRIPTION] = description
      ? stringToEditorState(description)
      : undefined;
    return Map({
      ...restDataAcquisition,
    });
  },
  to: (dataAcquisition) => {
    const {
      [CABLE_TAB_FIELDS.COMMENTS]: comments,
      [TOOLS_TAB_FIELDS.DESCRIPTION]: description,
      ...restDataAcquisition
    } = dataAcquisition;

    restDataAcquisition[CABLE_TAB_FIELDS.COMMENTS] = comments
      ? editorStateToString(comments)
      : null;
    restDataAcquisition[TOOLS_TAB_FIELDS.DESCRIPTION] = description
      ? editorStateToString(description)
      : null;

    return {
      ...restDataAcquisition,
    };
  },
};

const dataAcquisitionMappers = {
  DataAcquisition: DataAcquisitionMapper,
};

export default dataAcquisitionMappers;
