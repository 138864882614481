// import ProjectBreadcrumb from "features/projects/components/ProjectBreadcrumb";
import ListIcon from '@material-ui/icons/List';
import BallotIcon from '@material-ui/icons/Ballot';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import MobileProjectOverviewContainer from 'app/components/Mobile/MobileProjectOverviewContainer';
import ProjectStatusContainer from 'features/mobile/projects/project/ProjectStatusContainer';
import MobileProjectExecuteContainer from 'app/components/Mobile/MobileProjectExecuteContainer';
import MobileTaskDetailsContainer from 'app/components/Mobile/MobileTaskDetailsContainer';

export const projectRoutes = [
  {
    exact: true,
    path: '/projects/:projectId',
    breadcrumb: 'Project',
    Icon: <BallotIcon />,
    routes: [
      {
        exact: true,
        path: '/projects/:projectId/status',
        breadcrumb: 'Project Status',
        component: ProjectStatusContainer,
      },
    ],
    component: ProjectStatusContainer,
  },
];

export const planRoutes = [
  {
    exact: true,
    path: '/plans',
    breadcrumb: 'Plan',
    Icon: <ListIcon />,
    routes: [],
    component: MobileProjectOverviewContainer,
    disabled: true,
    hidden: true,
  },
];

export const executionRoutes = [
  {
    exact: true,
    path: '/projects/:projectId/execution',
    breadcrumb: 'Execute',
    Icon: <PlayCircleFilledIcon />,
    routes: [
      {
        exact: true,
        path: '/projects/:projectId/execution/:taskId/:section',
        routes: [],
        component: MobileTaskDetailsContainer,
      },
    ],
    component: MobileProjectExecuteContainer,
  },
  {
    exact: true,
    path: '/projects/:projectId/execution/:taskId/:section',
    routes: [],
    component: MobileTaskDetailsContainer,
    hidden: true,
  },
];

const mobileRoutes = [...projectRoutes, ...planRoutes, ...executionRoutes];

export default mobileRoutes;
