import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';

import { invokeIfFunction } from 'utils/app.util';
import { PUBLIC_ASSETS_URL } from 'app/app.constants';

const DashboardImage = ({ name, title, classes, renderImage, ...rest }) => {
  const src = `${PUBLIC_ASSETS_URL}/images/dashboard/${name}.png`;

  if (renderImage) return invokeIfFunction(renderImage, src);

  return <img src={src} alt={title} className={classes.root} {...rest} />;
};

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
  },
});

export default compose(withStyles(styles))(DashboardImage);
