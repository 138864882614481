import { createContext, useState, useContext, useCallback } from 'react';

// Components outside the provider will get [] when using the context.
const ToolstringItemHighlightContext = createContext([]);

export const useHighlightedToolstringItem = () => {
  const [highlightedItemId, setHighlightedItemId] = useContext(
    ToolstringItemHighlightContext,
  );

  const isItemHighlighted = useCallback(
    (itemId) => highlightedItemId === itemId,
    [highlightedItemId],
  );

  return {
    isItemHighlighted,
    highlightedItemId,
    setHighlightedItemId,
  };
};

const ToolstringItemHighlightProvider = ({ children }) => {
  const highlightedItemState = useState();

  return (
    <ToolstringItemHighlightContext.Provider value={highlightedItemState}>
      {children}
    </ToolstringItemHighlightContext.Provider>
  );
};

export default ToolstringItemHighlightProvider;
