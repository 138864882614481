import {
  getProjectTimers,
  getAllProjectActivities,
} from 'features/projects/activities/activities.actions';

import { getAllDepartments } from 'app/app.actions';
import activitiesService from 'services/activities.service';
import { getAllTasks } from 'features/projects/tasks/tasks.actions';
import { REPORT_ACTIONS } from 'features/projects/report/report.constants';
import { getAllProjectNonProductives } from 'features/projects/npt/npt.actions';

export const onLoad = (projectId) => (dispatch) => {
  dispatch({
    type: REPORT_ACTIONS.PROJECT_REPORT_PAGE_LOADED,
    payload: () =>
      Promise.all([
        dispatch(getAllTasks(projectId)),
        dispatch(getProjectTimers(projectId)),
        dispatch(getAllProjectActivities(projectId)),
        dispatch(getAllProjectNonProductives(projectId)),
        dispatch(getAllDepartments()),
      ]),
  });
};

export const onUnload = () => (dispatch) =>
  dispatch({
    type: REPORT_ACTIONS.PROJECT_REPORT_PAGE_UNLOADED,
  });

export const getTaskActivities = (projectId, taskId) => (dispatch) => {
  const payload = activitiesService.allActivitiesForTask(projectId, taskId);

  dispatch({
    taskId,
    type: REPORT_ACTIONS.PROJECT_REPORT_GET_ACTIVITIES,
    payload,
  });

  return payload;
};
