import { isMobile } from 'react-device-detect';

import {
  LOGIN_PAGE_LOADED,
  LOGIN_PAGE_UNLOADED,
  START_LOGIN,
} from 'features/login/login.constants';

import { pages } from 'app/routePaths';
import Agent from 'infrastructure/agent';
import { toRoot } from 'utils/route.util';
import config from 'infrastructure/config';
import authSingleton from 'services/auth.service';
import {
  autoLogin,
  newAppVersionNotificationAction,
  onLoad as onLoadApp,
  setSubPage,
} from 'app/app.actions';
import { deleteMsalFromLocalStorage } from 'utils/app.util';
import projectsService from 'services/project.service';
import { ACTIVITIES_ACTIONS } from 'features/projects/activities/activities.constants';
import {
  getTaskActivities,
  setProjectStatus,
} from 'features/projects/activities/activities.actions';
import { APP_VERSION, APP_VERSION_STORAGE_KEY } from 'app/app.constants';

export const onLoad = () => (dispatch) => {
  dispatch(setSubPage(pages.login));
  dispatch({ type: LOGIN_PAGE_LOADED });
  dispatch(onLoadApp());
};

export const onUnload = () => (dispatch) =>
  dispatch({ type: LOGIN_PAGE_UNLOADED });

const loginPopup = () => (dispatch) => {
  const payload = authSingleton
    .getMsalInstance()
    .then((msalInstance) => {
      return msalInstance
        .loginPopup({ scopes: [config.scope], prompt: 'select_account' })
        .then((resp) => {
          msalInstance.setActiveAccount(resp.account);
          return resp;
        })
        .then((resp) => {
          const previousAppVersion = localStorage.getItem(
            APP_VERSION_STORAGE_KEY,
          );
          if (
            !isMobile &&
            (!previousAppVersion || previousAppVersion !== APP_VERSION)
          ) {
            dispatch(newAppVersionNotificationAction);
          }
          return resp;
        });
    })
    .then(async () => {
      try {
        const throttledRefreshTokenFn = await Agent.throttledRefreshToken();
        await throttledRefreshTokenFn.cancel();
      } catch (e) {
        throw Error('Exception in refreshing access token');
      }
      return dispatch(autoLogin());
    });

  dispatch({
    type: START_LOGIN,
    payload,
  });

  return payload;
};

export const refreshProjectStatus = (projectId, taskId) => (dispatch) => {
  projectsService.getProjectStatus(projectId).then((status) => {
    return dispatch(
      getTaskActivities(projectId, taskId, { silent: true }),
    ).then(() => dispatch(setProjectStatus(status)));
  });

  dispatch({
    type: ACTIVITIES_ACTIONS.REFRESH_PROJECT_STATUS,
  });
};

const loginRedirect = () => () => {
  authSingleton
    .getMsalInstance()
    .then((msalInstance) =>
      msalInstance.loginRedirect(
        { scopes: [config.scope] },
        '&prompt=select_account',
      ),
    );
};

export const login = (callback) => (dispatch) => {
  isMobile
    ? dispatch(loginRedirect(callback))
    : dispatch(loginPopup()).catch(() => {
        deleteMsalFromLocalStorage();
      });
};

export const changeUser = () => (dispatch) => {
  dispatch(loginPopup()).then(() => {
    window.location = toRoot();
  });
};
