import React from 'react';
import { compose } from 'redux';

import {
  DefaultLayout,
  DashboardLayout,
} from 'features/projects/dashboard/components/dashboards/configurableDashboard/layouts';

import { DashboardName } from 'features/projects/dashboard/dashboard.constants';
import DynamicDockManager from 'features/projects/dashboard/components/DynamicDockManager/DynamicDockManager';
import IFrameDashboardController from 'features/projects/dashboard/components/dashboards/configurableDashboard/controllers/IFrameDashboardController';
import GenericDashboardController from 'features/projects/dashboard/components/dashboards/configurableDashboard/controllers/GenericDashboardController';
import CaliperDashboardController from 'features/projects/dashboard/components/dashboards/configurableDashboard/controllers/CaliperDashboardController';
import CorrelationDashboardController from 'features/projects/dashboard/components/dashboards/configurableDashboard/controllers/CorrelationDashboardController';
import MetalMillingDashboardController from 'features/projects/dashboard/components/dashboards/configurableDashboard/controllers/MetalMillingDashboardController';
import WinchCombinedDashboardController from 'features/projects/dashboard/components/dashboards/configurableDashboard/controllers/WinchCombinedDashboardController';
import WinchLineAndGaugeDashboardController from 'features/projects/dashboard/components/dashboards/configurableDashboard/controllers/WinchLineAndGaugeDashboardController';
import WinchWellboreAndGaugeDashboardController from 'features/projects/dashboard/components/dashboards/configurableDashboard/controllers/WinchWellboreAndGaugeDashboardController';
import BHAGraphAndGuagesController from 'features/projects/dashboard/components/dashboards/configurableDashboard/controllers/BHAGraphAndGuagesController';
import WellGraphAndGuagesController from 'features/projects/dashboard/components/dashboards/configurableDashboard/controllers/WellGraphAndGuagesController';

const DashboardComponent = {
  [DashboardName.IFRAME]: IFrameDashboardController,
  [DashboardName.CALIPER]: CaliperDashboardController,
  [DashboardName.CORRELATION]: CorrelationDashboardController,
  [DashboardName.METAL_MILLING]: MetalMillingDashboardController,
  [DashboardName.WINCH_COMBINED]: WinchCombinedDashboardController,
  [DashboardName.WINCH_GRAPH_GAUGES]: WinchLineAndGaugeDashboardController,
  [DashboardName.WINCH_SURVEY_GAUGES]: WinchWellboreAndGaugeDashboardController,
  [DashboardName.BHA_GRAPH_GAUGES]: BHAGraphAndGuagesController,
  [DashboardName.WELL_GRAPH_GAUGES]: WellGraphAndGuagesController,
};

const ProjectDashboardDockManager = ({
  projectId,
  setDataState,
  projectDashboard,
  projectDashboardId,
}) => {
  if (!projectDashboard) return null;

  const name = projectDashboard.get('name');
  const url = projectDashboard.get('url');

  const layout = DashboardLayout[name] ?? DefaultLayout;
  const component = DashboardComponent[name] ?? GenericDashboardController;

  return (
    <DynamicDockManager
      url={url}
      layout={layout}
      component={component}
      projectId={projectId}
      setDataState={setDataState}
      projectDashboardId={projectDashboardId}
    />
  );
};

export default compose(React.memo)(ProjectDashboardDockManager);
