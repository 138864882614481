import PropTypes from 'prop-types';
import classNames from 'classnames';
import loadImage from 'blueimp-load-image';
import withStyles from '@material-ui/styles/withStyles';
import { useCallback, useEffect, useState } from 'react';

import { Image } from 'app/components/withTooltip';
import { PUBLIC_ASSETS_URL } from 'app/app.constants';
import { ExifOrientation } from 'features/projects/tool/tool.constants';

const ToolImageContainer = ({
  style,
  title,
  classes,
  toolName,
  className,
  ImageProps,
  highlighted,
  orientation,
  toolImageUrl,
  TooltipProps,
  onHoverScale,
  onHoverDarken,
  assembly = false,
}) => {
  const defaultImage = `${PUBLIC_ASSETS_URL}/images/tools/placeholder${
    orientation ?? ''
  }.png`;

  const [image, setImage] = useState(defaultImage);

  const setDefaultImage = useCallback(
    () => setImage(defaultImage),
    [defaultImage],
  );

  useEffect(() => {
    let isSubscribed = true;
    if (toolImageUrl) {
      loadImage(toolImageUrl, {
        orientation,
        crossOrigin: true,
      })
        .then((response) => {
          if (isSubscribed) {
            try {
              if (orientation) {
                response.image.toBlob((blob) => {
                  if (blob) {
                    var imageUrl;
                    try {
                      imageUrl = URL.createObjectURL(blob);
                    } catch {
                      setDefaultImage();
                    }
                    if (isSubscribed) {
                      setImage(imageUrl);
                    }
                  } else {
                    setDefaultImage();
                  }
                });
              } else {
                setImage(toolImageUrl);
              }
            } catch {
              setDefaultImage();
            }
          }
        })
        .catch(() => {
          isSubscribed && setDefaultImage();
        });
    }
    return () => (isSubscribed = false);
  }, [orientation, setDefaultImage, toolImageUrl]);

  return (
    <Image
      src={image}
      style={style}
      title={title}
      alt={toolName}
      TooltipProps={TooltipProps}
      className={classNames(className, classes.root, {
        [classes.onHighlight]: highlighted && !assembly,
        [classes.onHoverScale]: onHoverScale,
        [classes.onHoverDarken]: onHoverDarken,
        [classes.onHighlightAssembly]: highlighted && assembly,
      })}
      {...ImageProps}
    />
  );
};

const styles = (theme) => ({
  root: {},
  onHighlight: {
    filter: 'brightness(120%)',
    transform: 'scale(2.0)',
    transition: 'transform .3s',
  },
  onHighlightAssembly: {
    filter: 'brightness(120%)',
    transition: 'transform .3s',
  },
  onHoverScale: {
    '&:hover': {
      filter: 'brightness(120%)',
      transform: 'scale(2.0)',
      transition: 'transform .3s',
    },
  },
  onHoverDarken: {
    '&:hover': {
      opacity: theme.palette.action.hoverOpacity,
    },
  },
});

ToolImageContainer.propTypes = {
  title: PropTypes.node,
  toolName: PropTypes.string,
  highlighted: PropTypes.bool,
  onHoverScale: PropTypes.bool,
  onHoverDarken: PropTypes.bool,
  toolImageUrl: PropTypes.string,
  orientation: PropTypes.oneOf(Object.values(ExifOrientation)),
};

export default withStyles(styles)(ToolImageContainer);
