import { OrderedMap } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import simulationMappers from 'features/projects/tasks/task/simulation/simulation.mappers';
import { ACTIONS } from 'features/projects/tasks/task/simulation/parameters/simulationParameter.constants';

const initialState = EMPTY_MAP;

const simulationParameterReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.DELETE_SIMULATION_PARAMETER: {
      if (error) return state;

      const { simulationParameterId } = action;

      return state.delete(simulationParameterId.toString());
    }

    case ACTIONS.RECEIVE_SIMULATION_PARAMETER: {
      if (error) return state;

      return state.set(
        payload.simulationParameterId.toString(),
        simulationMappers.SimulationParameter.from(payload),
      );
    }

    case ACTIONS.RECEIVE_SIMULATION_PARAMETERS: {
      if (error) return state;

      return OrderedMap(
        payload.map((simulationParameter) => [
          simulationParameter.simulationParameterId.toString(),
          simulationMappers.SimulationParameter.from(simulationParameter),
        ]),
      );
    }

    default:
      return state;
  }
};

export default simulationParameterReducer;
