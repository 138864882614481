import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { getApplicationHeaderFromState } from 'app/app.selectors';

import { useHeader } from 'app/hooks/useHeader';
import LogoAndAppTitle from 'app/components/ApplicationHeader/LogoAndAppTitle';
import UserMenuContainer from 'app/components/ApplicationHeader/UserMenuContainer';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import ApplicationHeaderTitle from 'app/components/ApplicationHeader/ApplicationHeaderTitle';

const DefaultApplicationHeader = () => {
  const applicationHeader = useSelector(getApplicationHeaderFromState);

  const logoNavigation = applicationHeader.get('logoNavigation');

  useHeader({
    logoNavigation: '/',
  });

  return (
    <ApplicationHeaderBase>
      <Grid
        container
        spacing={2}
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs>
          <LogoAndAppTitle to={logoNavigation} />
        </Grid>
        <Grid item xs={8}>
          <ApplicationHeaderTitle />
        </Grid>
        <Grid item xs>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <UserMenuContainer />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ApplicationHeaderBase>
  );
};

export default compose()(DefaultApplicationHeader);
