import Agent from 'infrastructure/agent';
import wellControlMappers from './wellControl.mapper';

const getWellControlDrillCategoriesAndSituations = (textSearch) =>
  Agent.get(`/wellcontroldrill?textSearch=${textSearch}`);

const updateActivityWcd = (projectId, taskId, activity) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/activities/${activity.id}`,
    wellControlMappers.activity.to(activity),
  );

const wellControlService = {
  updateActivityWcd,
  getWellControlDrillCategoriesAndSituations,
};

export default wellControlService;
