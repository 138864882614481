import { Field } from 'formik';

import { required } from 'utils/validation.util';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';

import { FluidFormFields as FormFields } from 'utils/fluids/constants.utils';

const NewtonianLiquidFormSection = ({ disabled }) => (
  <Field
    required
    type="number"
    destroyOnUnmount
    disabled={disabled}
    validate={required}
    label="Newtonian Viscosity"
    component={QuantityTextFieldFormik}
    name={FormFields.NEWTONIAN_VISCOSITY}
    useRoundedValue={true}
  />
);

export default NewtonianLiquidFormSection;
