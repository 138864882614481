import { List } from 'immutable';
import { useCallback } from 'react';

import { useRequest } from 'altus-hooks';

import mappers from 'mappers';
import operationService from 'services/operation.service';

const useCheetahJobCurves = (cheetahJobId) => {
  const getCheetahJobCurves = useCallback(() => {
    if (!cheetahJobId) return;

    return operationService
      .getCheetahJobCurves(cheetahJobId)
      .then((cheetahJobCurves) =>
        List(cheetahJobCurves).map(mappers.CheetahJobCurve.from),
      );
  }, [cheetahJobId]);

  return useRequest(getCheetahJobCurves);
};

export default useCheetahJobCurves;
