import PropTypes from 'prop-types';

import { Typography, Grid } from '@material-ui/core';

const CardNavigationItemData = ({ data }) => (
  <Grid container>
    {data.map(({ key, value }) => (
      <Grid container item xs={12} key={key} justifyContent="space-between">
        <Grid item xs={4}>
          <Typography variant="caption">
            <strong>{`${key}: `}</strong>
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="caption">{value}</Typography>
        </Grid>
      </Grid>
    ))}
  </Grid>
);

CardNavigationItemData.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
};

CardNavigationItemData.defaultProps = {
  data: [],
};

export default CardNavigationItemData;
