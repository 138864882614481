import { Link } from 'react-router-dom';
import Launch from '@material-ui/icons/Launch';
import { Box, Link as MuiLink } from '@material-ui/core';

const NavigationLink = ({ target, children, component = Link, ...rest }) => {
  const displayIcon = target === '_blank';

  return (
    <MuiLink component={component} target={target} {...rest}>
      {children}
      {displayIcon && (
        <Box component={Launch} marginLeft={0.5} fontSize="inherit" />
      )}
    </MuiLink>
  );
};

export default NavigationLink;
