import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import routePaths from 'app/routePaths';
import {
  IntegrityForm,
  INTEGRITY_TYPE_ENUM,
  INTEGRITY_MANAGEMENT_ACTIONS,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import integrityManagementService from 'features/settings/integrityManagement/helpers/integrityManagement.service';

export const loadIntegrityManagementIntegrities = () => (dispatch) => {
  const payload =
    integrityManagementService.getIntegrityManagementIntegrities();
  dispatch({
    type: INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES,
    payload,
  });
  return payload;
};

export const deleteIntegrityManagementIntegrity =
  (integrityId) => (dispatch) => {
    dispatch({
      integrityId,
      type: INTEGRITY_MANAGEMENT_ACTIONS.DELETE_INTEGRITY_MANAGEMENT_INTEGRITY,
      confirmationDialog: {
        title: 'Delete integrity',
        confirmButtonText: 'Delete',
        description: 'Are you sure you want to delete this integrity?',
      },
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Deleting integrity',
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Integrity successfully Deleted!',
        [NOTIFICATION_VARIANTS.ERROR]:
          'Cannot delete category with category / rule',
      },
      payload: () => {
        return integrityManagementService
          .deleteIntegrityManagementIntegrity(integrityId)
          .then(() => {
            dispatch(loadIntegrityManagementIntegrities());
          });
      },
    });
  };

export const addNewIntegrityManagementIntegrity =
  (formValues) => (dispatch) => {
    const { ...integrity } = formValues;

    const integrityType =
      integrity[IntegrityForm.INTEGRITY_TYPE] === INTEGRITY_TYPE_ENUM.CATEGORY
        ? 'Category'
        : 'Rule';

    const payload = integrityManagementService
      .addNewIntegrityManagementIntegrity(integrity)
      .then(() => dispatch(loadIntegrityManagementIntegrities()));

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.ADD_NEW_INTEGRITY_MANAGEMENT_INTEGRITY,
      notification: {
        [NOTIFICATION_VARIANTS.ERROR]: `Error creating ${integrityType}.`,
        [NOTIFICATION_VARIANTS.INFO]: `Adding new ${integrityType}...`,
        [NOTIFICATION_VARIANTS.SUCCESS]: `New ${integrityType} successfully added!`,
      },
      payload,
    });
  };

export const updateIntegrityManagementIntegrity =
  (formValues) => (dispatch) => {
    const { ...integrity } = formValues;

    const integrityType =
      integrity[IntegrityForm.INTEGRITY_TYPE] === INTEGRITY_TYPE_ENUM.CATEGORY
        ? 'Category'
        : 'Rule';

    const payload = integrityManagementService
      .updateIntegrityManagementIntegrity(integrity)
      .then(() => dispatch(loadIntegrityManagementIntegrities()));

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.UPDATE_INTEGRITY_MANAGEMENT_INTEGRITY,
      notification: {
        [NOTIFICATION_VARIANTS.ERROR]: `Error updating ${integrityType}.`,
        [NOTIFICATION_VARIANTS.INFO]: `Updating ${integrityType}...`,
        [NOTIFICATION_VARIANTS.SUCCESS]: `${integrityType} successfully added!`,
      },
      payload,
    });
  };

export const loadIntegrityManagementIntegritiesForWell =
  (externalWellboreId) => (dispatch) => {
    const payload =
      integrityManagementService.getIntegrityManagementIntegritiesForWell(
        externalWellboreId,
      );
    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES_FOR_WELL,
      payload,
    });
    return payload;
  };

export const addNewIntegrityManagementWellData =
  (wellboreId, formValues, toggleModal) => (dispatch) => {
    const { ...integrity } = formValues;

    const payload = integrityManagementService
      .addNewIntegrityWellData(wellboreId, integrity)
      .then(() => {
        return dispatch(
          loadIntegrityManagementIntegritiesForWell(wellboreId),
        ).then(() => {
          toggleModal();
        });
      });

    dispatch({
      type: INTEGRITY_MANAGEMENT_ACTIONS.ADD_NEW_INTEGRITY_MANAGEMENT_RULE_DATA,
      notification: {
        [NOTIFICATION_VARIANTS.ERROR]: 'Error updating rule data.',
        [NOTIFICATION_VARIANTS.INFO]: 'Updating rule data.',
        [NOTIFICATION_VARIANTS.SUCCESS]: 'New rule data successfully added!',
      },
      payload,
    });
  };
