import { useSelector } from 'react-redux';
import {
  getActionDataStateFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';

const useDataState = (actions) =>
  useSelector((state) =>
    Array.isArray(actions)
      ? getSummarizedDataStateFromState(state, ...actions)
      : getActionDataStateFromState(state, actions),
  );

export default useDataState;
