import _isNil from 'lodash/isNil';
import { OrderedMap } from 'immutable';
import { createSelector } from 'reselect';

const getSimulationResultSelector = (state) =>
  state.getIn(['entity', 'simulationResult'], OrderedMap());

const getSimulationsSelector = (state) => state.getIn(['entity', 'simulation']);

const getSimulationFluidSelector = (state) =>
  state.getIn(['entity', 'simulationFluid']);

const getWellboreSectionSelector = (state) =>
  state.getIn(['entity', 'wellboreSection']);

export const getSimulationFromState = createSelector(
  (state, simulationId) =>
    state.getIn(['entity', 'simulation', simulationId?.toString()]),
  (simulation) => simulation,
);

export const getSimulationsForTaskFromState = createSelector(
  getSimulationsSelector,
  (_, taskId) => taskId,
  (simulations, taskId) =>
    simulations
      .groupBy((simulation) => simulation.get('taskId').toString())
      .get(taskId.toString()),
);

export const getSelectedCablesForTaskFromState = createSelector(
  getSimulationsForTaskFromState,
  (simulations) =>
    simulations?.map((simulation) => simulation.get('cable')).toList(),
);

export const hasPlannedSimulationForTaskSelection = createSelector(
  getSimulationsForTaskFromState,
  (simulations) => simulations?.some((s) => s.get('isPlanned')),
);

export const createSimulationResultsByDirectionSelector = (
  simulationId,
  direction = null,
) =>
  createSelector([getSimulationResultSelector], (simulationResultEntity) =>
    simulationResultEntity
      ?.get(_isNil(direction) ? 'null' : direction)
      ?.filter(
        (result) =>
          result.get('simulationId')?.toString() === simulationId?.toString(),
      ),
  );

export const createSimulationResultsByDepthAndDirectionSelector = (
  simulationId,
  direction = null,
) =>
  createSelector(
    createSimulationResultsByDirectionSelector(simulationId, direction),
    (simulationResults) =>
      simulationResults?.mapKeys((_key, result) =>
        result.getIn(['measuredDepth', 'value']),
      ),
  );

export const createSimulationWellboreSectionsSelector = (simulationId) =>
  createSelector(getWellboreSectionSelector, (wellboreSections) =>
    wellboreSections
      .filter(
        (section) => section?.get('simulationId') === Number(simulationId),
      )
      .groupBy((section) => section.get('simulationId').toString())
      .get(simulationId.toString()),
  );

export const createSimulationFluidsSelector = (simulationId) =>
  createSelector(getSimulationFluidSelector, (simulationFluids) =>
    simulationFluids
      .filter((fluid) => fluid.get('simulationId') === Number(simulationId))
      .groupBy((fluid) => fluid.get('simulationId').toString())
      .get(simulationId.toString()),
  );

// added this utils code here because the tests were failing
// since jest cannot parse scss files and the utils file imports the theme file
const getSimulationCompressionDepth = (simulationResults) => {
  const runInHole = simulationResults
    ?.find((result) => result.get('isCompressionDepthRunInHole'))
    ?.get('measuredDepth');

  const pullOutOfHole = simulationResults
    ?.find((result) => result.get('isCompressionDepthPullOutOfHole'))
    ?.get('measuredDepth');

  if (_isNil(runInHole) && _isNil(pullOutOfHole)) {
    return undefined;
  }

  return { runInHole, pullOutOfHole };
};

export const createSimulationCompressionDepthByDirectionSelector = (
  simulationId,
  direction = null,
) =>
  createSelector(
    createSimulationResultsByDirectionSelector(simulationId, direction),
    getSimulationCompressionDepth,
  );

export const createSimulationResultsCountSelector = () =>
  createSelector(
    getSimulationResultSelector,
    (simulationResults) => simulationResults?.size || 0,
  );
