import { Formik } from 'formik';
import { useMemo } from 'react';

import { TableRow } from 'altus-ui-components';

import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';

const EditableTableRowFormik = ({
  item,
  timeout,
  onSubmit,
  TableRowComponent = TableRow,
  ...rest
}) => {
  const initialValues = useMemo(() => item.toJS(), [item]);

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <AutoSaveFormik timeout={timeout}>
        <TableRowComponent item={item} {...rest} />
      </AutoSaveFormik>
    </Formik>
  );
};

export default EditableTableRowFormik;
