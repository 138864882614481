import { combineReducers } from 'redux-immutable';

import facilitiesReducer from 'features/facilities/facilities.reducer';
import facilitiesProjectsReducer from 'features/facilities/projects/facilitiesProjects.reducer';
import facilityDocumentsReducer from 'features/facilities/documents/facilityDocuments.reducer';

const facilityReducer = combineReducers({
  facilities: facilitiesReducer,
  facilityProjects: facilitiesProjectsReducer,
  facilityDocuments: facilityDocumentsReducer,
});

export default facilityReducer;
