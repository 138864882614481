import { call, takeEvery, put } from 'redux-saga/effects';

import {
  deleteSimulationParameter,
  receiveSimulationParameters,
} from 'features/projects/tasks/task/simulation/parameters/simulationParameter.actions';

import { callAsync } from 'app/sagas/helperSagas';
import { FormikFormStatus } from 'app/app.constants';
import simulationService from 'services/simulation.service';
import { requestSimulationAsync } from 'features/projects/tasks/task/simulation/simulation.sagas';
import { ACTIONS } from 'features/projects/tasks/task/simulation/parameters/simulationParameter.constants';

export function* requestSimulationParametersAsync(action) {
  const { projectId, taskId, simulationId } = action;

  const simulationParameters = yield call(
    simulationService.getSimulationParameters,
    projectId,
    taskId,
    simulationId,
  );

  yield put(receiveSimulationParameters(simulationParameters));

  return simulationParameters;
}

export function* requestCreateSimulationParameterAsync(action) {
  const {
    taskId,
    payload,
    callback,
    projectId,
    setStatus,
    simulationId,
    setSubmitting,
  } = action;

  try {
    const simulationParameter = yield call(
      simulationService.createSimulationParameter,
      projectId,
      taskId,
      simulationId,
      payload,
    );

    // refresh parameters to get correct order
    yield call(requestSimulationParametersAsync, action);

    yield call(requestSimulationAsync, action);

    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);

    if (callback) {
      yield call(callback);
    }

    return simulationParameter;
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);

    throw error;
  }
}

export function* requestUpdateSimulationParameterAsync(action) {
  const {
    taskId,
    payload,
    projectId,
    setStatus,
    simulationId,
    setSubmitting,
    simulationParameterId,
  } = action;

  try {
    const simulationParameter = yield call(
      simulationService.updateSimulationParameter,
      projectId,
      taskId,
      simulationId,
      simulationParameterId,
      payload,
    );

    // refresh parameters to get correct order
    yield call(requestSimulationParametersAsync, action);

    yield call(requestSimulationAsync, action);

    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);

    return simulationParameter;
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);

    throw error;
  }
}

export function* requestDeleteSimulationParameterAsync(action) {
  const { taskId, projectId, simulationId, simulationParameterId } = action;

  yield call(
    simulationService.deleteSimulationParameter,
    projectId,
    taskId,
    simulationId,
    simulationParameterId,
  );

  yield put(deleteSimulationParameter(simulationParameterId));

  yield call(requestSimulationAsync, action);
}

export function* loadSimulationParametersPageAsync(action) {
  yield call(requestSimulationParametersAsync, action);
}

export default function* root() {
  yield takeEvery(
    ACTIONS.LOAD_SIMULATION_PARAMETERS_PAGE,
    callAsync,
    loadSimulationParametersPageAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_SIMULATION_PARAMETERS,
    callAsync,
    requestSimulationParametersAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_CREATE_SIMULATION_PARAMETER,
    callAsync,
    requestCreateSimulationParameterAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_DELETE_SIMULATION_PARAMETER,
    callAsync,
    requestDeleteSimulationParameterAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_UPDATE_SIMULATION_PARAMETER,
    callAsync,
    requestUpdateSimulationParameterAsync,
  );
}
