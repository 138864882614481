export const states = {
  INITIAL: 'INITIAL',
  API_IN_PROGRESS: 'API_IN_PROGRESS',
  FINAL: 'FINAL',
};
export const actionTypes = {
  API_STARTED: 'API_IN_PROGRESS',
  API_FINISHED: 'API_FINISHED',
};
export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.API_STARTED:
      return states.API_IN_PROGRESS;
    case actionTypes.API_FINISHED:
      return states.FINAL;
    default:
      throw new Error('Unexpected action in archived paginations...');
  }
};
