export const WELLBORE_FILE_ACTIONS = {
  UPDATE_WELLBORE_FILE: 'UPDATE_WELLBORE_FILE',
  UPLOAD_WELLBORE_FILE: 'UPLOAD_WELLBORE_FILE',
  DELETE_WELLBORE_FILE: 'DELETE_WELLBORE_FILE',
  RECEIVE_WELLBORE_FILE: 'RECEIVE_WELLBORE_FILE',
  RECEIVE_WELLBORE_FILES: 'RECEIVE_WELLBORE_FILES',
  DOWNLOAD_WELLBORE_FILE: 'DOWNLOAD_WELLBORE_FILE',
  UPLOAD_FILE_MODAL_UNLOADED: 'UPLOAD_FILE_MODAL_UNLOADED',
  SET_DOCUMENT_CATEGORY_FILTER: 'SET_DOCUMENT_CATEGORY_FILTER',
  WELLBORE_FILES_PAGE_LOADED: 'WELLBORE_FILES_PAGE_LOADED',
  WELLBORE_FILES_PAGE_UNLOADED: 'WELLBORE_FILES_PAGE_UNLOADED',
};
