import { compose } from 'redux';
import { useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import TableRowExpandable from 'app/components/TableRowExpandable';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import LiveProjectsCheetahJobsTable from 'features/data-exchange/live-projects/components/LiveProjectsCheetahJobsTable';

const LiveProjectsTableRow = ({ item, classes, ...rest }) => {
  const cheetahJobs = item.get('cheetahJobs');

  const renderDetails = useCallback(
    () => <LiveProjectsCheetahJobsTable cheetahJobs={cheetahJobs} />,
    [cheetahJobs],
  );

  return (
    <EditableTableRowFormik
      {...rest}
      item={item}
      renderDetails={renderDetails}
      isExpanded={!!cheetahJobs.size}
      TableRowComponent={TableRowExpandable}
      classes={{
        detailsRoot: classes.detailsRoot,
        rootExpanded: classes.rootExpanded,
        tableRowExpanded: classes.tableRowExpanded,
      }}
    />
  );
};

const styles = (theme) => ({
  detailsRoot: {
    paddingTop: 0,
  },
  rootExpanded: {
    marginBottom: theme.spacing(2),
  },
  tableRowExpanded: {
    background: theme.altus.components.TableRow.hoverColor,
  },
});

export default compose(withStyles(styles))(LiveProjectsTableRow);
