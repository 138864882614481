import { compose } from 'redux';
import { FastField as Field } from 'formik';
import withStyles from '@material-ui/styles/withStyles';
import { styles } from 'app/components/form/formik/helpers/tablerow.textfield.styles';

import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';

const TableRowTextFieldFormik = ({
  name,
  classes,
  InputProps,
  TextFieldComponent = TextFieldFormik,
  keepChangeAfterOnBlur = false,
  ...rest
}) => {
  return (
    <Field
      name={name}
      margin="none"
      helperText={null}
      component={TextFieldComponent}
      keepChangeAfterOnBlur={keepChangeAfterOnBlur}
      InputProps={{
        classes: {
          input: classes.input,
          error: classes.error,
          underline: classes.underline,
        },
        ...InputProps,
      }}
      {...rest}
    />
  );
};

export default compose(withStyles(styles))(TableRowTextFieldFormik);
