import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { alpha } from '@material-ui/core/styles';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, Chip } from '@material-ui/core';

import { invokeIfFunction } from 'utils/app.util';

const CreateProjectModalListItem = ({
  item,
  classes,
  onClick,
  disabled,
  visibleItem,
}) => {
  const { setFieldValue } = useFormikContext();

  const { names, getItemLabel, getItemId, itemDisabledTitle } = visibleItem;
  const { id, search } = names;

  const itemId = getItemId(item);
  const itemLabel = getItemLabel(item);

  const onSelectItem = useCallback(() => {
    // order of setFieldValue here matters, form does not validate properly if the id is set before the label
    setFieldValue(search, itemLabel);
    setFieldValue(id, itemId);

    invokeIfFunction(onClick, item);
  }, [setFieldValue, itemId, onClick, id, search, item, itemLabel]);

  const chipLabel = useMemo(
    () => <Typography>{itemLabel}</Typography>,
    [itemLabel],
  );

  return (
    <Grid
      item
      xs={12}
      className={classes.chipContainer}
      title={disabled ? itemDisabledTitle : itemLabel}
    >
      <Chip
        label={chipLabel}
        disabled={disabled}
        onClick={onSelectItem}
        className={classNames({
          [classes.chipDisabled]: disabled,
        })}
      />
    </Grid>
  );
};

const styles = (theme) => ({
  chipContainer: {
    margin: `${theme.spacing(0.75)}px 0`,
  },
  chipDisabled: {
    '&.Mui-disabled': {
      background: alpha(theme.palette.grey[900], 0.5),
    },
  },
});

export default withStyles(styles)(CreateProjectModalListItem);
