import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReadOnlyField from 'app/components/ReadOnlyField';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const CableWrapTestDrawerComponent = ({ assetHistory, classes }) => {
  const section = assetHistory.getIn(['cableWrapTest', 'strandsPassed']);
  return (
    <>
      {!assetHistory && !section ? null : (
        <Grid item xs={12} className={classes.paperMargin}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomReadOnlyField
                margin="none"
                label="Pass X"
                className={classes.marginLabel}
              >
                {section.get('item1')}
              </CustomReadOnlyField>
            </Grid>
            <Grid item xs={6}>
              <CustomReadOnlyField
                margin="none"
                label="Out of Y"
                className={classes.marginLabel}
              >
                {section.get('item2')}
              </CustomReadOnlyField>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: '4vh',
  },
  marginLabel: {
    marginTop: '4vh',
  },
  marginStrand: {
    marginBottom: '2vh',
  },
});

export default withStyles(styles)(CableWrapTestDrawerComponent);
