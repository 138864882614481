import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { BrowserView, MobileView } from 'react-device-detect';

import { useToggle } from 'altus-hooks';

import routePaths from 'app/routePaths';
import { toProjectDashboard } from 'utils/route.util';
import OperationCard from 'features/operations/OperationCard';
import ProjectDashboardsDrawer from 'features/projects/dashboard/components/ProjectDashboardsDrawer';

const OperationCardItem = ({ classes, operation, scrollContainerRef }) => {
  const [isOpen, toggle] = useToggle();

  const project = operation.get('project');

  const projectId = project?.get('id');

  const url = toProjectDashboard(
    `${routePaths.operations.dashboards}/:projectId`,
    projectId ? projectId : 0,
  );

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} className={classes.root}>
      <BrowserView>
        <OperationCard
          onClick={toggle}
          operation={operation}
          scrollContainerRef={scrollContainerRef}
        />
        <ProjectDashboardsDrawer
          isOpen={isOpen}
          project={project}
          toggleDrawer={toggle}
          projectId={projectId}
        />
      </BrowserView>
      <MobileView>
        <Link to={url}>
          <OperationCard
            operation={operation}
            scrollContainerRef={scrollContainerRef}
          />
        </Link>
      </MobileView>
    </Grid>
  );
};

const styles = () => ({
  root: {
    minWidth: 'min(500px, 100%)',
  },
});

export default compose(withStyles(styles))(OperationCardItem);
