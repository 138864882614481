import React from 'react';
import { useCallback } from 'react';

import { EMPTY_LIST } from 'app/app.constants';
import TableRowExpandable from 'app/components/TableRowExpandable';
import TaskTableRowDetails from 'features/projects/tasks/components/TaskTableRowDetails';

const TaskTableRow = ({
  project,
  copyTask,
  onExpand,
  projectId,
  item: task,
  toolstring,
  deleteTask,
  activities = EMPTY_LIST,
  copyTaskDisabled,
  ...rest
}) => {
  const taskId = task.get('taskId');

  const renderDetails = useCallback(
    (isExpanded) => (
      <TaskTableRowDetails
        task={task}
        taskId={taskId}
        project={project}
        onExpand={onExpand}
        copyTask={copyTask}
        projectId={projectId}
        isExpanded={isExpanded}
        deleteTask={deleteTask}
        activities={activities}
        copyTaskDisabled={copyTaskDisabled}
      />
    ),
    [
      task,
      taskId,
      project,
      copyTask,
      onExpand,
      projectId,
      deleteTask,
      activities,
      copyTaskDisabled,
    ],
  );

  return <TableRowExpandable {...rest} renderDetails={renderDetails} />;
};

export default TaskTableRow;
