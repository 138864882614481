import { compose } from 'redux';
import { connect } from 'react-redux';

import { EMPTY_LIST } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { TOOL_ACTIONS } from 'features/projects/tool/tool.constants';
import { getCablesFromState } from 'features/projects/tool/tool.selector';
import CablePickerModal from 'features/projects/tool/components/CablePickerModal';
import { requestCablesByProject } from 'features/projects/tool/tool.actions';

const CablePickerModalContainer = ({
  open,
  cables,
  toggleModal,
  onSelectCable,
  dispatchOnLoad,
  loadingDataState,
  projectId,
}) => (
  <CablePickerModal
    open={open}
    cables={cables}
    onClose={toggleModal}
    onEnter={() => dispatchOnLoad(projectId)}
    onSelectCable={onSelectCable}
    loading={loadingDataState.isLoading()}
  />
);

CablePickerModalContainer.defaultProps = {
  cables: EMPTY_LIST,
};

export default compose(
  connect(
    (state) => ({
      cables: getCablesFromState(state),
      loadingDataState: getSummarizedDataStateFromState(
        state,
        TOOL_ACTIONS.REQUEST_CABLES_BY_PROJECT,
      ),
    }),
    {
      dispatchOnLoad: (projectId) => requestCablesByProject(projectId),
    },
  ),
)(CablePickerModalContainer);
