import { compose } from 'redux';
import { connect } from 'react-redux';
import { memo, useCallback, useState, useEffect } from 'react';

import { useModal } from 'altus-modal';
import { LoadingOverlay } from 'altus-ui-components';
import { getSummarizedDataState } from 'altus-datastate';

import {
  requestUpdateToolstring,
  redirectToTaskToolstring,
  requestGetTaskToolstring,
  requestSortToolstringTools,
  requestCreateToolstringTool,
  requestDeleteToolstringTool,
  requestUpdateToolstringItem,
  requestTaskToolstringSections,
  requestDuplicateToolstringItem,
  requestSortToolstringAssemblyTools,
  requestCreateToolstringAssemblyTool,
  requestDeleteToolstringAssemblyTool,
  requestCreateToolAttachment,
  requestDeleteToolstringAttachment,
  requestSortToolstringAttachments,
} from 'features/projects/tasks/task/toolstring/toolstring.actions';

import {
  getToolstringFromState,
  getToolstringToolsFromState,
  getToolstringSectionsFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';

import { EMPTY_MAP } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { requestToolConnectors } from 'features/projects/tool/tool.actions';
import {
  MODAL,
  ToolCategoryType,
  ToolType,
} from 'features/projects/tool/tool.constants';
import { getToolConnectorsFromState } from 'features/projects/tool/tool.selector';
import { TOOLSTRING_ACTIONS } from 'features/projects/tasks/task/toolstring/toolstring.constants';
import ToolPickerModalContainer from 'features/projects/tool/components/ToolPickerModalContainer';
import UseFacilityProjectDecks from 'features/projects/hooks/useFacilityProjectDecks';
import TaskToolstringEditor from 'features/projects/tasks/task/toolstring/components/edit/TaskToolstringEditor';

const toolstringItemCategories = [
  ToolCategoryType.MWL,
  ToolCategoryType.EWL,
  ToolCategoryType.ThirdParty,
  ToolCategoryType.Assemblies,
];

const toolstringAssemblyToolCategories = [
  ToolCategoryType.MWL,
  ToolCategoryType.EWL,
  ToolCategoryType.ThirdParty,
];

const TaskToolstringEditorContainer = ({
  taskId,
  projectId,
  dataState,
  toolstringId,
  toolstringTools,
  toolstringSections,
  toolstring = EMPTY_MAP,
  toolConnectors = EMPTY_MAP,
  dispatchGetToolstring,
  dispatchUpdateToolstring,
  dispatchGetToolConnectors,
  dispatchToolstringSections,
  dispatchSortToolstringTools,
  dispatchCreateToolstringTool,
  dispatchDeleteToolstringTool,
  dispatchUpdateToolstringItem,
  dispatchDuplicateToolstringItem,
  dispatchRedirectToTaskToolstring,
  dispatchSortToolstringAssemblyTools,
  dispatchDeleteToolstringAssemblyTool,
  dispatchCreateToolstringAssemblyTool,
  dispatchCreateToolstringAttachment,
  dispatchDeleteToolstringAttachment,
  dispatchSortToolstringAttachments,
}) => {
  const [latestWellboreDetail = EMPTY_MAP, projectWellboreDetailsDataState] =
    UseFacilityProjectDecks(projectId);

  const summarizedDataState = getSummarizedDataState(
    dataState,
    projectWellboreDetailsDataState,
  );

  const [facilityDeckValue, setFacilityDeckValue] = useState(null);

  useEffect(() => {
    if (
      Array.isArray(latestWellboreDetail) &&
      latestWellboreDetail?.length > 0
    ) {
      setFacilityDeckValue(latestWellboreDetail[0]?.maximumRigupHeight, 'N/A');
    }
  }, [facilityDeckValue, latestWellboreDetail]);

  const [selectedToolstringAssembly, setSelectedToolstringAssembly] =
    useState();

  const [selectedToolAttachmentOwner, setSelectedToolAttachmentOwner] =
    useState();

  const [toolstringItemModalOpen, toggleToolstringItemModal] = useModal(
    MODAL.TOOL_PICKER_MODAL_ID,
  );

  const [toolstringAssemblyToolModalOpen, toggleToolAssemblyToolModal] =
    useModal(MODAL.TOOL_ASSEMBLY_TOOL_PICKER_MODAL_ID);

  const [toolstringAttachToolModalOpen, toggleToolstringAttachToolModalOpen] =
    useModal(MODAL.ATTACH_TOOL_PICKER_MODAL_ID);

  const onAddToolstringAssemblyTools = useCallback(
    (toolstringAssembly) => {
      toggleToolAssemblyToolModal();

      // we need to keep track of which ToolstringAssembly triggered the ToolPickerModalContainer
      setSelectedToolstringAssembly(toolstringAssembly);
    },
    [toggleToolAssemblyToolModal],
  );

  const onEnter = useCallback(() => {
    dispatchGetToolstring(projectId, taskId, toolstringId);
    dispatchToolstringSections(projectId, taskId, toolstringId);
    dispatchGetToolConnectors();
  }, [
    projectId,
    taskId,
    toolstringId,
    dispatchGetToolstring,
    dispatchGetToolConnectors,
    dispatchToolstringSections,
  ]);

  const onClose = useCallback(() => {
    dispatchRedirectToTaskToolstring(projectId, taskId, toolstringId);
  }, [projectId, taskId, toolstringId, dispatchRedirectToTaskToolstring]);

  const onAddToolstringItem = useCallback(
    (tool) => {
      dispatchCreateToolstringTool(projectId, taskId, toolstringId, tool);
    },
    [projectId, taskId, toolstringId, dispatchCreateToolstringTool],
  );

  const onAddToolstringAssemblyTool = useCallback(
    (tool) => {
      dispatchCreateToolstringAssemblyTool(
        projectId,
        taskId,
        toolstringId,
        selectedToolstringAssembly.get('toolstringItemId'),
        tool,
      );
    },
    [
      projectId,
      taskId,
      toolstringId,
      selectedToolstringAssembly,
      dispatchCreateToolstringAssemblyTool,
    ],
  );

  const onDuplicateItem = useCallback(
    (tool) => {
      dispatchDuplicateToolstringItem(projectId, taskId, toolstringId, tool);
    },
    [projectId, taskId, toolstringId, dispatchDuplicateToolstringItem],
  );

  const onAddToolAttachments = (tool) => {
    toggleToolstringAttachToolModalOpen();
    setSelectedToolAttachmentOwner(tool);
  };

  const onAttachTool = (tool) =>
    dispatchCreateToolstringAttachment(
      projectId,
      taskId,
      toolstringId,
      selectedToolAttachmentOwner,
      tool,
    );

  const updateItemProperties = useCallback(
    (toolstringItem) => {
      dispatchUpdateToolstringItem(
        projectId,
        taskId,
        toolstringId,
        toolstringItem,
      );
    },
    [projectId, taskId, toolstringId, dispatchUpdateToolstringItem],
  );

  const onExitAddTool = useCallback(() => {
    dispatchGetToolstring(projectId, taskId, toolstringId);
  }, [projectId, taskId, toolstringId, dispatchGetToolstring]);

  const onUpdateToolstring = useCallback(
    (values) => {
      dispatchUpdateToolstring(projectId, taskId, toolstringId, values);
    },
    [projectId, taskId, toolstringId, dispatchUpdateToolstring],
  );

  const onDeleteToolstringItem = useCallback(
    (toolstringTool) => {
      dispatchDeleteToolstringTool(
        projectId,
        taskId,
        toolstringId,
        toolstringTool.get('toolstringItemId'),
      );
    },
    [projectId, taskId, toolstringId, dispatchDeleteToolstringTool],
  );

  const onDeleteToolAssemblyTool = useCallback(
    (toolAssemblyTool) => {
      dispatchDeleteToolstringAssemblyTool(
        projectId,
        taskId,
        toolstringId,
        toolAssemblyTool.get('toolstringItemId'),
        toolAssemblyTool.get('toolAssemblyToolId'),
      );
    },
    [projectId, taskId, toolstringId, dispatchDeleteToolstringAssemblyTool],
  );

  const onDeleteToolstringAttachment = (toolStringAttachment) =>
    dispatchDeleteToolstringAttachment(
      projectId,
      taskId,
      toolstringId,
      toolStringAttachment.get('attachmentOwnerId'),
      toolStringAttachment.get('toolstringItemId'),
    );

  const onSortToolstringItem = useCallback(
    (item) => {
      const { oldIndex, newIndex, collection, nodes } = item;
      const type = nodes?.[oldIndex]?.node?.getAttribute('data-tool-type');

      if (oldIndex === newIndex || type === undefined) return;

      const toolType = Number(type);
      if (toolType === ToolType.TOOL || toolType === ToolType.ASSEMBLY) {
        const sortedToolstringItem = toolstringTools.get(oldIndex);
        const affectedToolstringItem = toolstringTools.get(newIndex);

        dispatchSortToolstringTools(
          projectId,
          taskId,
          toolstringId,
          sortedToolstringItem,
          affectedToolstringItem,
        );
      } else if (toolType === ToolType.ASSEMBLY_TOOL) {
        const toolstringAssembly = toolstringTools.get(collection);
        const toolAssemblyTools = toolstringAssembly.get('toolAssemblyTools');

        const sortedToolstringAssemblyTool = toolAssemblyTools.get(oldIndex);
        const affectedToolstringAssemblyTool = toolAssemblyTools.get(newIndex);

        dispatchSortToolstringAssemblyTools(
          projectId,
          taskId,
          toolstringId,
          toolstringAssembly.get('toolstringItemId'),
          sortedToolstringAssemblyTool,
          affectedToolstringAssemblyTool,
        );
      } else if (toolType === ToolType.ATTACHMENT) {
        const owner = toolstringTools.get(collection);
        const sortedToolStringAttachment = owner.getIn([
          'attachedTools',
          oldIndex,
        ]);
        const affectedToolStringAttachment = owner.getIn([
          'attachedTools',
          newIndex,
        ]);

        dispatchSortToolstringAttachments(
          projectId,
          taskId,
          toolstringId,
          owner.get('toolstringItemId'),
          sortedToolStringAttachment,
          affectedToolStringAttachment,
        );
      } else {
        throw new Error(`Unknown tool type ${toolType}`);
      }
    },
    [
      toolstringTools,
      dispatchSortToolstringTools,
      projectId,
      taskId,
      toolstringId,
      dispatchSortToolstringAssemblyTools,
      dispatchSortToolstringAttachments,
    ],
  );

  return (
    <TaskToolstringEditor
      open
      taskId={taskId}
      onEnter={onEnter}
      onClose={onClose}
      projectId={projectId}
      dataState={dataState}
      toolstring={toolstring}
      toolstringId={toolstringId}
      toolConnectors={toolConnectors}
      toolstringTools={toolstringTools}
      onDuplicateItem={onDuplicateItem}
      onAddToolAttachments={onAddToolAttachments}
      onAddTools={toggleToolstringItemModal}
      toolstringSections={toolstringSections}
      onUpdateToolstring={onUpdateToolstring}
      onSortToolstringTool={onSortToolstringItem}
      updateItemProperties={updateItemProperties}
      onDeleteToolstringTool={onDeleteToolstringItem}
      onDeleteToolAssemblyTool={onDeleteToolAssemblyTool}
      onAddToolAssemblyTools={onAddToolstringAssemblyTools}
      onDeleteToolstringAttachment={onDeleteToolstringAttachment}
      maxAvailableHeight={facilityDeckValue}
    >
      <ToolPickerModalContainer
        projectId={projectId}
        open={toolstringItemModalOpen}
        onExit={onExitAddTool}
        onAddTool={onAddToolstringItem}
        onToggleModal={toggleToolstringItemModal}
        enabledToolCategoryTypes={toolstringItemCategories}
      />
      <ToolPickerModalContainer
        onExit={onExitAddTool}
        projectId={projectId}
        open={toolstringAssemblyToolModalOpen}
        onAddTool={onAddToolstringAssemblyTool}
        onToggleModal={toggleToolAssemblyToolModal}
        enabledToolCategoryTypes={toolstringAssemblyToolCategories}
      />
      <ToolPickerModalContainer
        onExit={onExitAddTool}
        projectId={projectId}
        open={toolstringAttachToolModalOpen}
        onAddTool={onAttachTool}
        onToggleModal={toggleToolstringAttachToolModalOpen}
        enabledToolCategoryTypes={toolstringItemCategories}
      />
      <LoadingOverlay timeout={0} dataState={summarizedDataState} />
    </TaskToolstringEditor>
  );
};

export default compose(
  connect(
    (state, { toolstringId }) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_TOOLS,
        TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_ITEM,
        TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_ASSEMBLY_TOOL,
        TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_ATTACHMENT,
        TOOLSTRING_ACTIONS.REQUEST_GET_TOOLSTRING_FOR_TASK,
        TOOLSTRING_ACTIONS.REQUEST_DUPLICATE_TOOLSTRING_ITEM,
        TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_ASSEMBLY_TOOLS,
      ),
      toolConnectors: getToolConnectorsFromState(state),
      toolstring: getToolstringFromState(state, toolstringId),
      toolstringTools: getToolstringToolsFromState(state, toolstringId),
      toolstringSections: getToolstringSectionsFromState(state, toolstringId),
    }),
    {
      dispatchGetToolstring: requestGetTaskToolstring,
      dispatchGetToolConnectors: requestToolConnectors,
      dispatchUpdateToolstring: requestUpdateToolstring,
      dispatchSortToolstringTools: requestSortToolstringTools,
      dispatchToolstringSections: requestTaskToolstringSections,
      dispatchUpdateToolstringItem: requestUpdateToolstringItem,
      dispatchDeleteToolstringTool: requestDeleteToolstringTool,
      dispatchCreateToolstringTool: requestCreateToolstringTool,
      dispatchRedirectToTaskToolstring: redirectToTaskToolstring,
      dispatchDuplicateToolstringItem: requestDuplicateToolstringItem,
      dispatchSortToolstringAssemblyTools: requestSortToolstringAssemblyTools,
      dispatchCreateToolstringAssemblyTool: requestCreateToolstringAssemblyTool,
      dispatchDeleteToolstringAssemblyTool: requestDeleteToolstringAssemblyTool,
      dispatchCreateToolstringAttachment: requestCreateToolAttachment,
      dispatchDeleteToolstringAttachment: requestDeleteToolstringAttachment,
      dispatchSortToolstringAttachments: requestSortToolstringAttachments,
    },
  ),
  memo,
)(TaskToolstringEditorContainer);
