import smLocationService from 'services/smLocation.service';

import { SMLOCATION_CONFIG_ACTIONS } from 'features/data-exchange/winch/smlocation.constants';

export const receiveSMLocations = (smLocations) => ({
  type: SMLOCATION_CONFIG_ACTIONS.RECEIVE_SMLOCATIONS,
  payload: smLocations,
});

export const receiveSMlocation = (smLocation) => ({
  type: SMLOCATION_CONFIG_ACTIONS.RECEIVE_SMLOCATION,
  payload: smLocation,
});

export const getAllSMlocations = () => (dispatch) => {
  const payload = smLocationService
    .getAllSMLocations()
    .then((smLocations) => dispatch(receiveSMLocations(smLocations)));

  dispatch({
    type: SMLOCATION_CONFIG_ACTIONS.GET_SMLOCATIONS,
    payload,
  });

  return payload;
};

export const createSMLocation = (smLocation) => (dispatch) => {
  const payload = smLocationService
    .createSMLocation(smLocation)
    .then(() => dispatch(getAllSMlocations()));

  dispatch({
    payload,
    type: SMLOCATION_CONFIG_ACTIONS.CREATE_SMLOCATION,
    notification: {
      success: 'Winch configuration created',
    },
  });

  return payload;
};

export const updateSMLocation = (smLocation) => (dispatch) => {
  const payload = smLocationService.updateSMLocation(smLocation);

  dispatch({
    payload,
    type: SMLOCATION_CONFIG_ACTIONS.UPDATE_SMLOCATION,
    notification: {
      success: 'Winch configuration updated successfully',
    },
  });

  return payload;
};

export const deleteSMLocation = (smLocationId) => (dispatch) => {
  const payload = smLocationService
    .deleteSMLocation(smLocationId)
    .then(() => dispatch(getAllSMlocations()));

  dispatch({
    payload,
    type: SMLOCATION_CONFIG_ACTIONS.DELETE_SMLOCATION,
    notification: {
      success: 'Winch configuration deleted',
    },
  });

  return payload;
};
