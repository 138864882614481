import { useState, useEffect } from 'react';
import { Grid, Box, Divider } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ExportPdfIcon from '@material-ui/icons/PictureAsPdfOutlined';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  NavTabsContainer,
  BasePage,
  LoadingOverlay,
} from 'altus-ui-components';
import { useModal } from 'altus-modal';

import withProjectPermission from 'app/components/withProjectPermission';
import { ProjectPermission } from 'app/app.constants';
import RoutesContainer from 'app/components/RoutesContainer';
import { toExecutionSimulation } from 'utils/route.util';
import * as actions from 'features/projects/tasks/task/simulation/simulation.actions';
import { MODAL as SIMULATION_MODAL } from 'features/projects/tasks/task/simulation/simulation.constants';
import SimulationHeader from 'features/projects/tasks/task/simulation/components/SimulationHeader';
import SimulationProjectInformation from 'features/projects/tasks/task/simulation/components/TaskSimulationDetails';
import ProjectRoutesContainer from 'app/components/ProjectRoutesContainer';
import SimulationResultsContainer from 'features/projects/tasks/task/simulation/components/SimulationResultsContainer';
import TaskSimulationCableSelect from 'features/projects/tasks/task/simulation/components/TaskSimulationCableSelect';
import TaskSimulationToolstringSelect from 'features/projects/tasks/task/simulation/components/TaskSimulationToolstringSelect';
import TaskSimulationToolstringContainer from 'features/projects/tasks/task/simulation/components/TaskSimulationToolstringContainer';
import SimulationExportModalContainer from 'features/projects/tasks/task/simulation/components/SimulationExportModalContainer';
import { useInitializeExecutionSimulationContainer } from 'features/projects/execution/hooks/useInitializeExecutionSimulationContainer';
import RunSimulationProgressBar from '../../../tasks/task/simulation/components/SimulationLinearProgress';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
    },
    menuContainer: {
      overflow: 'auto',
    },
    section: {
      marginBottom: theme.spacing(2),
      border: `2px solid ${theme.palette.divider}`,
    },
    toolStringContainer: {
      height: 'calc(100vh - 265px)',
    },
  }),
);

const ExecutionSimulationContainer = (props) => {
  const { projectId, taskId, simulationId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSimulationExportModalOpen, toggleSimulationExportModal] = useModal(
    SIMULATION_MODAL.SIMULATION_EXPORT_MODAL_ID,
  );
  const {
    task,
    project,
    simulation,
    simulations,
    simulationResults,
    toolStrings,
    cable,
    loadingState,
    runSimulationState,
  } = useInitializeExecutionSimulationContainer(
    projectId,
    taskId,
    simulationId,
  );

  // => null Not possible in execution
  const onTogglePlanned = () => null;
  const onUpdateSimulation = () => null;
  const toggleCablePickerModal = () => null;
  const onToolStringChange = () => null;

  const [toolDataIsVerified, setToolDataIsVerified] = useState(null);
  const [enableSimulationButton, setEnableSimulationButton] = useState(false);

  useEffect(() => {
    if (simulation) {
      setEnableSimulationButton(simulation.get('isUpdatedPlanned'));
    }
  }, [simulation]);

  const onToggleFavorite = () =>
    dispatch(
      actions.requestToggleSimulationFavorite(projectId, taskId, simulationId),
    );

  // Return default index container when no data
  if (simulations.size === 0 && simulationId === undefined) {
    return <RoutesContainer {...props} />;
  }

  // Redirect to first simulation when no selected simulation
  if (simulations.size > 0 && simulationId === undefined) {
    return (
      <Redirect
        from={props.path}
        to={toExecutionSimulation(
          projectId,
          taskId,
          simulations.first().get('simulationId'),
        )}
      />
    );
  }

  // Return the selected simulation
  return (
    <>
      <Grid xs container item wrap="nowrap" className={classes.root}>
        <Grid container item xs direction="column">
          <LoadingOverlay timeout={250} dataState={loadingState} />
          <RunSimulationProgressBar
            runSimulationDataState={runSimulationState}
          />
          <SimulationHeader
            actions={
              enableSimulationButton
                ? [
                    {
                      Icon: ExportPdfIcon,
                      title: 'Export',
                      disabledTooltip:
                        'Re-simulate with latest changes to export',
                      disabled: simulationResults.isEmpty(),
                      onClick: toggleSimulationExportModal,
                    },
                  ]
                : []
            }
            task={task}
            project={project}
            simulation={simulation}
            togglePlanned={onTogglePlanned}
            toggleFavorite={onToggleFavorite}
            toolDataIsVerified={toolDataIsVerified}
            onNameChange={onUpdateSimulation}
            isEnableSimulationRunButton={enableSimulationButton}
          />
          <SimulationProjectInformation project={project} task={task} />
          <BasePage>
            <Grid item padding={2} component={Box} className={classes.section}>
              <NavTabsContainer variant="scrollable" routes={props.routes} />
              <Box paddingBottom={3} paddingTop={3}>
                <ProjectRoutesContainer routes={props.routes} />
              </Box>
            </Grid>
            <Grid container item xs className={classes.section}>
              <SimulationResultsContainer
                taskId={taskId}
                projectId={projectId}
                simulation={simulation}
                simulationId={simulationId}
              />
            </Grid>
          </BasePage>
        </Grid>
        <Divider orientation="vertical" flexItem />
      </Grid>
      <Grid
        item
        xs={2}
        container
        padding={2}
        wrap="nowrap"
        component={Box}
        direction="column"
        className={classes.toolStringContainer}
      >
        <Grid item container component={Box} marginBottom={2}>
          <TaskSimulationCableSelect
            disabled
            selectedCable={cable}
            onClick={toggleCablePickerModal}
          />
        </Grid>
        <Grid item container component={Box} paddingBottom={2}>
          <TaskSimulationToolstringSelect
            disabled
            toolstrings={toolStrings}
            selectedToolstringId={simulation?.get('toolstringId')}
            onToolstringChange={onToolStringChange}
          />
        </Grid>
        <TaskSimulationToolstringContainer
          taskId={taskId}
          projectId={projectId}
          simulation={simulation}
          simulationId={simulationId}
          toolstringId={simulation?.get('toolstringId')}
          toolDataIsVerified={toolDataIsVerified}
          setToolDataIsVerified={setToolDataIsVerified}
        />
      </Grid>
      <SimulationExportModalContainer
        taskId={taskId}
        projectId={projectId}
        simulationId={simulationId}
        open={isSimulationExportModalOpen}
        toggleModal={toggleSimulationExportModal}
      />
    </>
  );
};

export default withProjectPermission(ProjectPermission.EXECUTE)(
  ExecutionSimulationContainer,
);
