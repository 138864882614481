import eventsService from 'services/events.service';
import { APP_ACTIONS } from 'app/app.constants';

export const onLoad = (projectId) => (dispatch) =>
  dispatch({
    type: APP_ACTIONS.EVENTS_PAGE_LOADED,
    payload: () => dispatch(getEvents(projectId)),
  });

export const getEvents = (projectId) => (dispatch) => {
  const payload = eventsService.allEvents(projectId);

  dispatch({
    type: APP_ACTIONS.EVENTS_LOADED,
    payload,
  });

  return payload;
};

export const onUnload = () => (dispatch) =>
  dispatch({ type: APP_ACTIONS.EVENTS_PAGE_UNLOADED });

export const markEventsAsSeen = (projectId) => (dispatch) =>
  dispatch({
    type: APP_ACTIONS.EVENTS_MARK_AS_SEEN,
    payload: () => eventsService.markEventsAsSeen(projectId),
  });
