import * as Yup from 'yup';

import {
  WELL_TAB_FIELDS,
  TOOLS_TAB_FIELDS,
  CABLE_TAB_FIELDS,
  EXPLOSIVES_FIELDS,
  CONSUMABLES_TAB_FIELDS,
} from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import { validateTimeString } from 'utils/app.util';

const valueErrorMessage = 'Value must be greater than or equal to 0';
const formatErrorMessage = 'Must be integer';
const timeErrorMessage = 'Invalid time format, it should be in format HH:MM';

export const validationSchema = Yup.object().shape({
  [`${WELL_TAB_FIELDS.WELLHEAD_PRESSURE}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${WELL_TAB_FIELDS.RESERVOIR_PRESSURE}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${WELL_TAB_FIELDS.RESERVOIR_DEPTH}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${WELL_TAB_FIELDS.CARBON_DIOXIDE}`]: Yup.number().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value >= 0,
  ),
  [`${WELL_TAB_FIELDS.HYDROGEN_SULFIDE}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${WELL_TAB_FIELDS.GAS_RATE}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${WELL_TAB_FIELDS.LIQUID_RATE}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${WELL_TAB_FIELDS.INJECTION_RATE}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${WELL_TAB_FIELDS.INJECTION_PRODUCTION_VOLUME}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${CABLE_TAB_FIELDS.FLOWTUBE}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${CABLE_TAB_FIELDS.MAX_TENSION}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${CABLE_TAB_FIELDS.WEAK_POINT_BUILD}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${CABLE_TAB_FIELDS.DISTANCE_WINCH_TO_FIRST_SHAVE}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${CABLE_TAB_FIELDS.LOWER_WINCH_TO_FIRST_SHAVE}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${CABLE_TAB_FIELDS.LOWER_TOUPPER_SHEAVE_WHEEL}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${TOOLS_TAB_FIELDS.DISTANCE_LOGGED}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${TOOLS_TAB_FIELDS.DEPTH_OF_CUT}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${TOOLS_TAB_FIELDS.METERS_TRACTORED}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${TOOLS_TAB_FIELDS.JARING_STROKES}`]: Yup.number()
    .integer('Must be integer')
    .nullable()
    .test('is-valid-value', valueErrorMessage, (value) => value >= 0),
  [`${TOOLS_TAB_FIELDS.DEBRIS_VOLUME_COLLECTED}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${TOOLS_TAB_FIELDS.NOGO_SIZE}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${TOOLS_TAB_FIELDS.DISTANCE_MILLED}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${TOOLS_TAB_FIELDS.MILLING_SPEED}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${TOOLS_TAB_FIELDS.ROTATION_SPEED}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [TOOLS_TAB_FIELDS.TIME_LOGGED]: Yup.string()
    .nullable()
    .notRequired()
    .test('is-valid-time', timeErrorMessage, (value) =>
      validateTimeString(value, true),
    ),
  [TOOLS_TAB_FIELDS.TIME_TRACTORED]: Yup.string()
    .nullable()
    .notRequired()
    .test('is-valid-time', timeErrorMessage, (value) =>
      validateTimeString(value, true),
    ),
  [TOOLS_TAB_FIELDS.JARING_TIME]: Yup.string()
    .nullable()
    .notRequired()
    .test('is-valid-time', timeErrorMessage, (value) =>
      validateTimeString(value, true),
    ),
  [`${CONSUMABLES_TAB_FIELDS.GREASE_CONSUMPTION}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${CONSUMABLES_TAB_FIELDS.FRICTION_REDUCER_CONSUMPTION}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${CONSUMABLES_TAB_FIELDS.GLYCOL_CONSUMPTION}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${CONSUMABLES_TAB_FIELDS.HYDRAULIC_FUID_CONSUMPTION}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
});

export const explosivesValidationSchema = Yup.object().shape({
  [`${EXPLOSIVES_FIELDS.CONSUMPTION}`]: Yup.object().test(
    'is-valid-value',
    valueErrorMessage,
    (value) => value.value >= 0,
  ),
  [`${EXPLOSIVES_FIELDS.CONSUMPTION}`]: Yup.object().test(
    'is-valid-format-value',
    formatErrorMessage,
    (value) => {
      const number = Number(value.value);
      return Number.isInteger(number);
    },
  ),
  [`${EXPLOSIVES_FIELDS.BOOSTER_BATCH_NUMBER}`]: Yup.number()
    .nullable()
    .integer('Must be integer')
    .test('is-valid-value', valueErrorMessage, (value) => value >= 0),
  [`${EXPLOSIVES_FIELDS.CHARGE_BATCH_NUMBER}`]: Yup.number()
    .nullable()
    .integer('Must be integer')
    .test('is-valid-value', valueErrorMessage, (value) => value >= 0),
  [`${EXPLOSIVES_FIELDS.CORD_BATCH_NUMBER}`]: Yup.number()
    .nullable()
    .integer('Must be integer')
    .test('is-valid-value', valueErrorMessage, (value) => value >= 0),
  [`${EXPLOSIVES_FIELDS.CUTTER_BATCH_NUMBER}`]: Yup.number()
    .nullable()
    .integer('Must be integer')
    .test('is-valid-value', valueErrorMessage, (value) => value >= 0),
  [`${EXPLOSIVES_FIELDS.IGNITER_BATCH_NUMBER}`]: Yup.number()
    .nullable()
    .integer('Must be integer')
    .test('is-valid-value', valueErrorMessage, (value) => value >= 0),
  [`${EXPLOSIVES_FIELDS.NUMBER_OF_BOOSTERS_USED}`]: Yup.number()
    .nullable()
    .integer('Must be integer')
    .test('is-valid-value', valueErrorMessage, (value) => value >= 0),
  [`${EXPLOSIVES_FIELDS.NUMBER_OF_CHARGERS_USED}`]: Yup.number()
    .nullable()
    .integer('Must be integer')
    .test('is-valid-value', valueErrorMessage, (value) => value >= 0),
  [`${EXPLOSIVES_FIELDS.NUMBER_OF_IGNITERS_USED}`]: Yup.number()
    .nullable()
    .integer('Must be integer')
    .test('is-valid-value', valueErrorMessage, (value) => value >= 0),
});
