import React from 'react';
import DrawCylinder from 'app/components/WellboreContextualization/DrawCylinder';

const RenderLayer = ({ layerRef }) => {
  return (
    <>
      {layerRef.current.map((trajectory, index) => (
        <DrawCylinder
          key={index}
          name={trajectory.value.name}
          measuredDepthStart={trajectory.value.measuredDepthStart}
          measuredDepthEnd={trajectory.value.measuredDepthEnd}
          v1={trajectory.value.startVector}
          v2={trajectory.value.endVector}
          radius={trajectory.value.radius}
          length={trajectory.value.length}
          isHalf={true}
          material={trajectory.value.material}
        />
      ))}
    </>
  );
};

export default RenderLayer;
