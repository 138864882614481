import { compose } from 'redux';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import {
  getIntegrityManagementIntegritiesFromState,
  getIntegrityManagementIntegritiesFromStateByRiskPrinciple,
} from 'features/settings/integrityManagement/helpers/integrityManagement.selectors';
import {
  INTEGRITY_TYPE_ENUM,
  RISK_PRINCIPLE_TYPES,
  INTEGRITY_MANAGEMENT_ACTIONS,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import {
  deleteIntegrityManagementIntegrity,
  loadIntegrityManagementIntegrities,
} from 'features/settings/integrityManagement/helpers/integrityManagement.actions';
import { Grid } from '@material-ui/core';
import IntegrityActions from 'features/settings/integrityManagement/integrities/IntegrityActions';
import IntegritiesTable from 'features/settings/integrityManagement/integrities/IntegritiesTable';
import routePaths from 'app/routePaths';

const RiskPrincipleContainer = ({
  classes,
  dataState,
  riskPrincipleType,
  filteredIntegrities,
  dispatchLoadIntegrityManagementIntegrities,
  dispatchDeleteIntegrityManagementIntegrity,
}) => {
  useEffect(() => {
    dispatchLoadIntegrityManagementIntegrities();
  }, [dispatchLoadIntegrityManagementIntegrities]);

  const deleteIntegrity = (integrityId, integrityType) => {
    if (integrityType !== INTEGRITY_TYPE_ENUM.RISK_PRINCIPLE) {
      dispatchDeleteIntegrityManagementIntegrity(integrityId);
    }
  };

  return (
    <BasePage dataState={dataState}>
      <Grid item container spacing={2} className={classes.tableContainer}>
        <IntegrityActions riskPrincipleType={riskPrincipleType} />
        <IntegritiesTable
          integrities={filteredIntegrities}
          deleteIntegrity={deleteIntegrity}
          riskPrincipleType={riskPrincipleType}
        />
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  tableContainer: {
    paddingTop: theme.spacing(2),
  },
});

const mapStateToProps = (state, ownProps) => {
  const { location } = ownProps;

  // Determine riskPrincipleType based on location.pathname
  let riskPrincipleType = null;
  if (location?.pathname?.includes(routePaths.settings.integrity.consequence)) {
    riskPrincipleType = RISK_PRINCIPLE_TYPES.WELL_CONSEQUENCE;
  } else {
    riskPrincipleType = RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE;
  }

  return {
    riskPrincipleType, // Add to props
    filteredIntegrities:
      getIntegrityManagementIntegritiesFromStateByRiskPrinciple(
        state,
        riskPrincipleType,
      ),
    integrities: getIntegrityManagementIntegritiesFromState(state),
    dataState: getSummarizedDataStateFromState(
      state,
      INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES,
      INTEGRITY_MANAGEMENT_ACTIONS.DELETE_INTEGRITY_MANAGEMENT_INTEGRITY,
    ),
  };
};

const mapDispatchToProps = {
  dispatchLoadIntegrityManagementIntegrities:
    loadIntegrityManagementIntegrities,
  dispatchDeleteIntegrityManagementIntegrity:
    deleteIntegrityManagementIntegrity,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(RiskPrincipleContainer);
