import { fromJS, Map, List } from 'immutable';

import { TOOL_ACTIONS } from 'features/projects/tool/tool.constants';

import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import toolMapper from 'features/projects/tool/tool.mappers';
import mappers from 'mappers';

const initialState = fromJS({
  data: Map({
    cables: EMPTY_MAP,
    toolGroups: EMPTY_MAP,
    toolTopConnectors: EMPTY_MAP,
    toolBottomConnectors: EMPTY_MAP,
    toolSuppliers: EMPTY_MAP,
    toolImages: EMPTY_LIST,
    toolProjectHistory: EMPTY_LIST,
    toolImports: Map({
      tools: EMPTY_LIST,
      errors: EMPTY_LIST,
    }),
  }),
  search: EMPTY_MAP,
});

const toolReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOOL_ACTIONS.DELETE_TOOL_IMAGE:
    case TOOL_ACTIONS.RECEIVE_TOOL_IMAGES: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'toolImages'],
        List(action.payload).map(toolMapper.ToolImage.from),
      );
    }

    case TOOL_ACTIONS.RECEIVE_TOOLS_SEARCH: {
      if (action.error) return state;

      return state.setIn(
        ['search'],
        toolMapper.ToolSearch.from(action.payload),
      );
    }

    case TOOL_ACTIONS.RECEIVE_TOOL_GROUPS: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'toolGroups'],
        toolMapper.ToolSearchFilter.from(action.payload),
      );
    }

    case TOOL_ACTIONS.RECEIVE_TOOL_CONNECTORS: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'toolConnectors'],
        toolMapper.ToolSearchFilter.from(action.payload),
      );
    }

    case TOOL_ACTIONS.RECEIVE_TOOL_TOP_CONNECTORS: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'toolTopConnectors'],
        toolMapper.ToolSearchFilter.from(action.payload),
      );
    }

    case TOOL_ACTIONS.RECEIVE_TOOL_BOTTOM_CONNECTORS: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'toolBottomConnectors'],
        toolMapper.ToolSearchFilter.from(action.payload),
      );
    }

    case TOOL_ACTIONS.RECEIVE_TOOL_SUPPLIERS: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'toolSuppliers'],
        toolMapper.ToolSearchFilter.from(action.payload),
      );
    }

    case TOOL_ACTIONS.IMPORT_TOOLS_FROM_FILE: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'toolImports'],
        toolMapper.ToolImport.from(action.payload),
      );
    }

    case TOOL_ACTIONS.RECEIVE_CABLES: {
      if (action.error) return state;

      return state.setIn(
        ['data', 'cables'],
        Map(
          action.payload.map((cable) => [
            cable.cableId.toString(),
            toolMapper.Cable.from(cable),
          ]),
        ),
      );
    }

    case TOOL_ACTIONS.RECEIVE_TOOL_PROJECT_HISTORY: {
      if (action.error) return state;
      const [projects] = action.payload;
      return state.setIn(
        ['data', 'toolProjectHistory'],
        List(projects).map(mappers.Project.from),
      );
    }

    default:
      return state;
  }
};

export default toolReducer;
