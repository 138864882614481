import { all, call, delay, fork, put, takeEvery } from 'redux-saga/effects';

import {
  getTaskActivities,
  getTaskActivitiesDispatchOnly,
  setProjectStatusAsPlainAction,
  startOperation,
} from 'features/projects/activities/activities.actions';
import {
  ACTIVITIES_ACTIONS,
  ACTIVITIES_ACTIONS as ACTIONS,
} from 'features/projects/activities/activities.constants';
import activitiesService from 'services/activities.service';
import { callAsync } from 'app/sagas/helperSagas';
import { addNotification } from 'altus-redux-middlewares';
import { FormikFormStatus, NOTIFICATION_VARIANTS } from 'app/app.constants';
import taskService from 'services/task.service';
import {
  dispatchGetProjectStatus,
  sendProjectChangedNotification,
} from './sagas/common.sagas';
import { rootCreateActivity } from './sagas/create.activity.saga';
import { rootSortActivities } from 'features/projects/activities/sagas/sort.activities.saga';
import { rootDeleteActivitySaga } from 'features/projects/activities/sagas/delete.activity.saga';
import { rootGoBack } from 'features/projects/activities/sagas/goback.activity.saga';
import { rootUpdateActivityFormSaga } from './sagas/update.activity.form.saga';
import { completeTask } from './sagas/complete.task.saga';
import { invokeIfFunction } from 'utils/app.util';
import { addErrorMessageDuration } from 'features/projects/activities/sagas/utils';
import reportsService from 'services/reports.service';

const UPDATE_ACTIVITIES_TRIGGER_ACTIONS = [
  ACTIONS.PAUSE_ACTIVITY,
  ACTIONS.SORT_ACTIVITIES,
  ACTIONS.COMPLETE_ACTIVITY,
  ACTIONS.QUICK_ADD_ACTIVITY,
  ACTIONS.ADD_ACTIVITY_DEFAULT,
  ACTIONS.CONFIRM_DELETE_ACTIVITY,
  ACTIONS.COMPLETE_ACTIVITY_ABORT_TASK,
  ACTIONS.CREATE_NEW_POINT_IN_TIME_ACTIVITY,
];

function* getTaskActivitiesAsync(action) {
  const { taskId, projectId, skipTracking } = action;

  // use skipTracking to only fetch the activities when the promise is complete
  if (!skipTracking) return;

  // do not fetch the activities if taskId or projectId is missing from action
  if (!taskId || !projectId) return;

  return yield put(getTaskActivities(projectId, taskId));
}

export function* startOperationAsync(action) {
  const nextProjectStatus = yield call(
    activitiesService.startOperation,
    action.projectId,
  );

  yield put(setProjectStatusAsPlainAction(nextProjectStatus));

  const allActivities = yield call(
    activitiesService.allActivitiesForTask,
    action.projectId,
    action.taskId,
  );
  yield put(getTaskActivitiesDispatchOnly(action.taskId, allActivities));
  return yield put(startOperation(allActivities));
}

function* handleEstablishingConnectionError() {
  yield put(
    addNotification({
      message:
        'Unable to establish real-time connection with server. Will refresh in 5 seconds...',
      duration: 5000,
      variant: NOTIFICATION_VARIANTS.ERROR,
    }),
  );
  yield delay(5000);
  window.location.reload();
}

function* createNewPointInTimeActivity(action) {
  const { projectId, taskId, activityId, activityTypeId, connection } = action;
  const activity = yield call(
    activitiesService.createNewPointInTimeActivity,
    projectId,
    taskId,
    activityId,
    activityTypeId,
  );
  const notificationPayload = yield call(
    activitiesService.sendProjectChangedNotification,
    connection,
    projectId,
    taskId,
  );
  yield put({
    payload: notificationPayload,
    type: ACTIVITIES_ACTIONS.SENT_SUBACTIVITY_ADDED_NOTIFICATION,
  });

  yield put({
    taskId,
    payload: activity,
    projectId,
    skipTracking: true, // In order to trigger saga defined on UPDATE_ACTIVITIES_TRIGGER_ACTIONS (see below)
    type: ACTIVITIES_ACTIONS.CREATE_NEW_POINT_IN_TIME_ACTIVITY,
  });
}

function* updateActivity(action) {
  const { projectId, taskId, activity, connection } = action;
  const updatedActivity = yield call(
    activitiesService.updateActivity,
    projectId,
    taskId,
    activity,
  );
  yield call(sendProjectChangedNotification, connection, projectId, taskId);
  yield put({
    activityId: activity.id,
    type: ACTIVITIES_ACTIONS.UPDATE_ACTIVITY,
    payload: updatedActivity,
  });
  return updatedActivity;
}

function* getActivityPdf(action) {
  const { projectId, taskId, activityId } = action;
  const updatedActivity = yield call(
    reportsService.downloadActivityReport,
    projectId,
    taskId,
    activityId,
  );
  return updatedActivity;
}

function* updateActivityTimes(action) {
  const actionExtended = addErrorMessageDuration(action, 5000);
  const { projectId, taskId, autosave, values, formikPrimitives, connection } =
    actionExtended;

  try {
    const updatedActivity = yield call(
      activitiesService.updateActivity,
      projectId,
      taskId,
      values,
    );
    const allActivities = yield call(
      activitiesService.allActivitiesForTask,
      projectId,
      taskId,
    );
    yield put({
      taskId,
      payload: allActivities,
      type: ACTIVITIES_ACTIONS.GET_TASK_ACTIVITIES,
    });
    yield call(sendProjectChangedNotification, connection, projectId, taskId);

    yield put({
      autosave,
      type: ACTIVITIES_ACTIONS.UPDATE_ACTIVITY,
      payload: updatedActivity,
    });
    return updatedActivity;
  } catch (exc) {
    yield call(getTaskActivitiesAsync, {
      taskId,
      projectId,
      skipTracking: true,
    });
    const { resetForm, setStatus, setSubmitting } = formikPrimitives;
    invokeIfFunction(resetForm);
    setSubmitting(false);
    setStatus(FormikFormStatus.ERROR);
    throw exc;
  }
}

export function* completeActivity(action) {
  const { projectId, taskId, activityId, connection } = action;
  const status = yield call(
    activitiesService.completeActivity,
    projectId,
    taskId,
    activityId,
  );
  const { nextTask, currentTask, nextActivity, currentActivity } = status;

  const projectComplete = [
    nextTask,
    currentTask,
    nextActivity,
    currentActivity,
  ].every((item) => !item);

  if (projectComplete) {
    yield all([call(getAllTask, projectId), call(getProjectTimers, projectId)]);
    yield call(dispatchGetProjectStatus, status);
  } else {
    yield call(dispatchGetProjectStatus, status);
  }

  yield call(sendProjectChangedNotification, connection, projectId, taskId);

  yield put({
    taskId,
    payload: status,
    projectId,
    skipTracking: true,
    type: ACTIVITIES_ACTIONS.COMPLETE_ACTIVITY,
  });

  return status;
}

function* getProjectTimers(projectId) {
  const payload = yield call(activitiesService.getAllProjectTimers, projectId);
  yield put({
    payload,
    type: ACTIVITIES_ACTIONS.GET_ALL_TASKS,
  });
  return payload;
}

function* getAllTask(projectId) {
  const payload = yield call(taskService.getAllTasks, projectId);
  yield put({
    payload,
    type: ACTIVITIES_ACTIONS.GET_ALL_TASKS,
  });
  return payload;
}

function* pauseActivity(action) {
  const { projectId, taskId, activityId, activityTypeId, connection } = action;
  const status = yield call(
    activitiesService.pauseActivity,
    projectId,
    taskId,
    activityId,
    activityTypeId,
  );
  yield call(dispatchGetProjectStatus, status);
  yield call(sendProjectChangedNotification, connection, projectId, taskId);

  yield put({
    taskId,
    payload: status,
    projectId,
    skipTracking: true,
    type: ACTIVITIES_ACTIONS.PAUSE_ACTIVITY,
  });
  return status;
}

export default function* root() {
  yield takeEvery(UPDATE_ACTIVITIES_TRIGGER_ACTIONS, getTaskActivitiesAsync);
  yield takeEvery(
    ACTIONS.ESTABLISHING_REAL_TIME_CONNECTION_FAILED,
    handleEstablishingConnectionError,
  );
  yield takeEvery(
    ACTIONS.INITIATE_CREATE_NEW_POINT_IN_TIME_ACTIVITY,
    callAsync,
    createNewPointInTimeActivity,
  );
  yield takeEvery(ACTIONS.INITIATE_UPDATE_ACTIVITY, callAsync, updateActivity);
  yield takeEvery(ACTIONS.DOWNLOAD_ACTIVITY_PDF, callAsync, getActivityPdf);
  yield takeEvery(
    ACTIONS.INITIATE_UPDATE_ACTIVITY_TIMES,
    callAsync,
    updateActivityTimes,
  );
  yield takeEvery(
    ACTIONS.INITIATE_COMPLETE_ACTIVITY,
    callAsync,
    completeActivity,
  );
  yield takeEvery(ACTIONS.INITIATE_PAUSE_ACTIVITY, callAsync, pauseActivity);
  yield takeEvery(
    ACTIONS.START_OPERATION_INITIATE,
    callAsync,
    startOperationAsync,
  );
  yield takeEvery(ACTIONS.INITATE_COMPLETE_TASK, callAsync, completeTask);

  yield fork(rootUpdateActivityFormSaga);
  yield fork(rootGoBack);
  yield fork(rootDeleteActivitySaga);
  yield fork(rootCreateActivity);
  yield fork(rootSortActivities);
}
