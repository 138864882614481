export const LESSONS_ACTIONS = {
  CREATE_LESSON: 'CREATE_LESSON',
  UPDATE_LESSON: 'UPDATE_LESSON',
  DELETE_LESSON: 'DELETE_LESSON',
  PROJECT_LESSONS_PAGE_LOADED: 'PROJECT_LESSONS_PAGE_LOADED',
  PROJECT_LESSONS_PAGE_UNLOADED: 'PROJECT_LESSONS_PAGE_UNLOADED',
};

export const MODAL = {
  EDIT_LESSON: 'EDIT_LESSON_MODAL_ID',
  CREATE_LESSON: 'CREATE_LESSON_MODAL_ID',
};

export const CREATE_LESSON_FORM = {
  ID: 'createLesson',
  TITLE: 'title',
  DESCRIPTION: 'description',
  RECOMMENDED_ACTIONS: 'recommended.actions',
};

export const EDIT_LESSON_FORM = {
  ID: 'editLesson',
  TITLE: 'title',
  DESCRIPTION: 'description',
  RECOMMENDED_ACTIONS: 'recommended.actions',
};
