import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from 'altus-modal';

import {
  addTemplateTool,
  getBhaTemplateForEditing,
  unloadBhaTemplateForEditing,
} from 'features/equipment/equipment.actions';

import {
  getTemplateForEditFromState,
  getTemplateSectionsForEditFromState,
  getTemplateToolstringToolsFromState,
} from 'features/equipment/equipment.selectors';

import {
  MODAL,
  EQUIPMENT_ACTIONS,
} from 'features/equipment/equipment.constants';

import {
  requestCreateToolstringTemplateAttachment,
  requestDeleteToolstringTemplateAttachment,
  requestSortToolstringTemplateAttachments,
} from 'features/projects/tasks/task/toolstring/toolstring.actions';

import useDataState from 'app/hooks/useDataState';
import {
  MODAL as TOOL_MODAL,
  ToolCategoryType,
} from 'features/projects/tool/tool.constants';
import { requestToolConnectors } from 'features/projects/tool/tool.actions';
import { getToolConnectorsFromState } from 'features/projects/tool/tool.selector';
import ToolPickerModalContainer from 'features/projects/tool/components/ToolPickerModalContainer';
import EditTemplateContent from 'features/equipment/bhaTemplates/editBhaTemplates/EditTemplateContent';

const toolstringItemCategories = [
  ToolCategoryType.MWL,
  ToolCategoryType.EWL,
  ToolCategoryType.ThirdParty,
  ToolCategoryType.Assemblies,
];

const toolstringAttachmentCategories = [
  ToolCategoryType.MWL,
  ToolCategoryType.EWL,
  ToolCategoryType.ThirdParty,
];

const EditTemplateContainer = ({ open, templateId, onClose }) => {
  const dispatch = useDispatch();

  const loadingState = useDataState([
    EQUIPMENT_ACTIONS.CREATE_TEMPLATE_TOOL,
    EQUIPMENT_ACTIONS.REQUEST_BHA_TEMPLATE_FOR_EDITING,
    EQUIPMENT_ACTIONS.REQUEST_BHA_TEMPLATE_SECTIONS_FOR_EDITING,
  ]);

  const [toolstringItemModalOpen, toggleToolstringItemModal] = useModal(
    MODAL.EDIT_BHA_ADD_TOOL_MODAL,
  );

  const templateToEdit = useSelector(getTemplateForEditFromState);
  const templateSectionsToEdit = useSelector(
    getTemplateSectionsForEditFromState,
  );
  const toolConnectors = useSelector(getToolConnectorsFromState);
  const toolstringTools = useSelector(getTemplateToolstringToolsFromState);

  useEffect(() => {
    dispatch(getBhaTemplateForEditing(templateId));
    dispatch(requestToolConnectors());

    return () => {
      dispatch(unloadBhaTemplateForEditing());
    };
  }, [dispatch, templateId]);

  const onAddToolstringItem = (tool) => {
    dispatch(addTemplateTool(templateId, tool));
  };

  const onExitAddTool = () => {
    dispatch(getBhaTemplateForEditing(templateId));
  };

  const [selectedToolAttachmentOwner, setSelectedToolAttachmentOwner] =
    useState();

  const [toolstringAttachToolModalOpen, toggleToolstringAttachToolModalOpen] =
    useModal(TOOL_MODAL.ATTACH_TOOL_PICKER_MODAL_ID);

  const onAddToolAttachments = (tool) => {
    toggleToolstringAttachToolModalOpen();
    setSelectedToolAttachmentOwner(tool);
  };

  const onAttachTool = (tool) =>
    dispatch(
      requestCreateToolstringTemplateAttachment(
        templateId,
        selectedToolAttachmentOwner,
        tool,
      ),
    );

  const onDeleteTemplateAttachment = (toolStringAttachment) =>
    dispatch(
      requestDeleteToolstringTemplateAttachment(
        templateId,
        toolStringAttachment.get('attachmentOwnerId'),
        toolStringAttachment.get('toolstringItemId'),
      ),
    );

  const onSortTemplateAttachments = (
    ownerToolstringItemId,
    sortedToolStringAttachment,
    affectedToolStringAttachment,
  ) =>
    dispatch(
      requestSortToolstringTemplateAttachments(
        templateId,
        ownerToolstringItemId,
        sortedToolStringAttachment,
        affectedToolStringAttachment,
      ),
    );

  return (
    <>
      <EditTemplateContent
        open={open}
        onClose={onClose}
        toolstringId={templateId}
        loadingState={loadingState}
        toolstring={templateToEdit}
        toolConnectors={toolConnectors}
        toolstringTools={toolstringTools}
        onAddTools={toggleToolstringItemModal}
        toolstringSections={templateSectionsToEdit}
        onAddToolAttachments={onAddToolAttachments}
        onDeleteToolAttachments={onDeleteTemplateAttachment}
        onSortToolAttachments={onSortTemplateAttachments}
      />
      <ToolPickerModalContainer
        projectId={1}
        open={toolstringItemModalOpen}
        onExit={onExitAddTool}
        onAddTool={onAddToolstringItem}
        onToggleModal={toggleToolstringItemModal}
        enabledToolCategoryTypes={toolstringItemCategories}
      />
      <ToolPickerModalContainer
        projectId={1}
        open={toolstringAttachToolModalOpen}
        onExit={onExitAddTool}
        onAddTool={onAttachTool}
        onToggleModal={toggleToolstringAttachToolModalOpen}
        enabledToolCategoryTypes={toolstringAttachmentCategories}
      />
    </>
  );
};

export default EditTemplateContainer;
