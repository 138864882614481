import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { lighten } from '@material-ui/core/styles';
import withStyles from '@material-ui/styles/withStyles';

import {
  Grid,
  Card,
  MenuItem,
  CardMedia,
  Typography,
  CardContent,
  CardActionArea,
} from '@material-ui/core';

import { LoadingOverlay } from 'altus-ui-components';

import { EMPTY_MAP } from 'app/app.constants';
import { toProjectDashboardV2 } from 'utils/route.util';
import DashboardImage from 'features/projects/dashboard/components/DashboardImage';
import useProjectDashboards from 'features/projects/dashboard/hooks/useProjectDashboards';

const ProjectDashboardsList = ({ classes, projectId, taskId }) => {
  const {
    dataState,
    requestProjectDashboards,
    projectDashboards = EMPTY_MAP,
  } = useProjectDashboards(projectId);

  useEffect(requestProjectDashboards, [requestProjectDashboards]);

  if (!projectDashboards.size && dataState.isSuccess()) {
    return (
      <Grid
        item
        xs
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <Typography variant="body2">None Available</Typography>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" wrap="nowrap">
      {projectDashboards.valueSeq().map((projectDashboard) => {
        const name = projectDashboard.get('name');
        const title = projectDashboard.get('title');

        const url = toProjectDashboardV2(
          projectId,
          projectDashboard.get('projectDashboardId'),
          taskId,
        );

        return (
          <MenuItem
            to={url}
            key={name}
            disableGutters
            component={Link}
            title={title}
            target={isMobile ? undefined : '_blank'}
            classes={{
              root: classes.menuItemRoot,
            }}
          >
            <Card container component={Grid} className={classes.card}>
              <CardActionArea>
                <DashboardImage
                  name={name}
                  renderImage={(src) => (
                    <CardMedia image={src} className={classes.cardMedia} />
                  )}
                />
                <CardContent>
                  <Typography variant="subtitle1">{title}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </MenuItem>
        );
      })}
      <LoadingOverlay dataState={dataState} />
    </Grid>
  );
};

const styles = (theme) => ({
  cardMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  card: {
    background: lighten(theme.palette.background.paper, 0.1),
  },
  menuItemRoot: {
    padding: 0,
    marginBottom: theme.spacing(1.5),
  },
});

export default withStyles(styles)(ProjectDashboardsList);
