import { createSelector } from 'utils/app.util';

export const getToolImagesFromState = createSelector(
  (state) => state.getIn(['tool', 'data', 'toolImages']),
  (toolImages) => toolImages,
);

export const getToolImportsFromState = createSelector(
  (state) => state.getIn(['tool', 'data', 'toolImports']),
  (toolImports) => toolImports,
);

export const getToolsSearchResultFromState = createSelector(
  (state) => state.getIn(['tool', 'search']),
  (tools) => tools.toList(),
);

export const getCablesFromState = createSelector(
  (state) => state.getIn(['tool', 'data', 'cables']),
  (cables) => cables.toList(),
);

export const getToolGroupsFromState = createSelector(
  (state) => state.getIn(['tool', 'data', 'toolGroups']),
  (toolGroups) => toolGroups,
);

export const getToolConnectorsFromState = createSelector(
  (state) => state.getIn(['tool', 'data', 'toolConnectors']),
  (toolConnectors) => toolConnectors,
);

export const getToolTopConnectorsFromState = createSelector(
  (state) => state.getIn(['tool', 'data', 'toolTopConnectors']),
  (toolTopConnectors) => toolTopConnectors,
);

export const getToolSuppliersFromState = createSelector(
  (state) => state.getIn(['tool', 'data', 'toolSuppliers']),
  (toolSuppliers) => toolSuppliers,
);

export const getToolBottomConnectorsFromState = createSelector(
  (state) => state.getIn(['tool', 'data', 'toolBottomConnectors']),
  (toolBottomConnectors) => toolBottomConnectors,
);

export const getToolProjectHistoryFromState = createSelector(
  (state) => state.getIn(['tool', 'data', 'toolProjectHistory']),
  (projects) => projects,
);
