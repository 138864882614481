import CreateIcon from '@material-ui/icons/Create';
import { Grid, Box, Typography, CircularProgress } from '@material-ui/core';

import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
} from '@material-ui/lab';

import { LoadingDataState, NotRequestedDataState } from 'altus-datastate';

import { EMPTY_LIST } from 'app/app.constants';
import { formatDate } from 'utils/format.util';
import ProjectWellboreDetailChangelogItem from 'features/projects/wellbore/details/components/ProjectWellboreDetailChangelogItem';

const ProjectWellboreDetailChangelog = ({
  project,
  dataState,
  projectWellboreDetails,
}) => {
  const fieldName = project.get('fieldName');
  const wellboreName = project.get('wellboreName');
  const creationDate = formatDate(project.get('created'));

  if (dataState.isLoading() || dataState === NotRequestedDataState) {
    return (
      <Grid
        container
        padding={3}
        component={Box}
        justifyContent="center"
        alignItems="center"
      >
        <Grid component={CircularProgress} item size={40} color="secondary" />
      </Grid>
    );
  }

  if (!projectWellboreDetails.size) {
    return (
      <Grid xs item container padding={3} component={Box} direction="column">
        <Grid item xs component={Typography} variant="h6">
          No revisions
        </Grid>
        <Grid
          item
          xs
          component={Typography}
          variant="subtitle2"
          color="textSecondary"
        >
          At least one Wellbore Details needs to be approved
        </Grid>
      </Grid>
    );
  }

  return (
    <Timeline>
      {projectWellboreDetails.valueSeq().map((projectWellboreDetail, index) => (
        <ProjectWellboreDetailChangelogItem
          projectWellboreDetail={projectWellboreDetail}
          change={projectWellboreDetails.size - index}
          key={projectWellboreDetail.get('projectWellboreDetailId')}
        />
      ))}
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot>
            <CreateIcon />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <Grid container>
            <Grid item>
              <Typography variant="subtitle2">Project created</Typography>
              <Typography variant="caption" component="p">
                {`${fieldName} - ${wellboreName}`}
              </Typography>
              <Typography variant="caption" color="textSecondary" component="p">
                {creationDate}
              </Typography>
            </Grid>
          </Grid>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

ProjectWellboreDetailChangelog.defaultProps = {
  dataState: LoadingDataState,
  projectWellboreDetails: EMPTY_LIST,
};

export default ProjectWellboreDetailChangelog;
