import { compose } from 'redux';
import { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Paper, Typography } from '@material-ui/core';

import {
  hasAllPermissions,
  hasOneOrMorePermissions,
} from 'utils/authorization.util';

class RoleAccessSummary extends PureComponent {
  isPermissionGroupVisible = (permissionGroup) => {
    const { rolePermissions } = this.props;
    const { hidden, permissions } = permissionGroup;

    if (hidden) return false;

    return hasOneOrMorePermissions(rolePermissions, permissions);
  };

  getGroupPermissionsSummary = (groupPermissions) => {
    const { rolePermissions, permissions } = this.props;

    const hasFullAccess = hasAllPermissions(rolePermissions, groupPermissions);

    if (hasFullAccess) return 'Full Access';

    return groupPermissions
      .filter((permissionId) => rolePermissions.has(permissionId))
      .map((permissionId) =>
        permissions.getIn([permissionId.toString(), 'name']),
      )
      .filter(Boolean)
      .join(', ');
  };

  renderPermissionGroup = (permissionGroup, groupIndex) => {
    const { classes } = this.props;
    const { title, permissions, permissionGroups } = permissionGroup;

    if (permissionGroups) {
      // render visible sub groups instead
      return this.renderVisiblePermissionGroups(permissionGroups);
    }

    return (
      <Grid container key={groupIndex} className={classes.permissionGroupRoot}>
        <Grid container>
          <Typography variant="body2">{title}</Typography>
        </Grid>
        <Typography variant="caption" color="textSecondary">
          <i>{this.getGroupPermissionsSummary(permissions)}</i>
        </Typography>
      </Grid>
    );
  };

  renderVisiblePermissionGroups = (permissionGroups) =>
    permissionGroups
      .filter(this.isPermissionGroupVisible)
      .map(this.renderPermissionGroup);

  render() {
    const { classes, permissionGroups, rolePermissions } = this.props;

    const visiblePermissionGroups = permissionGroups.filter(
      this.isPermissionGroupVisible,
    );

    const hasAnyPermission =
      !rolePermissions.isEmpty() && !!visiblePermissionGroups.length;

    return (
      <Paper elevation={0}>
        <Grid
          container
          direction="column"
          className={classes.permissionGroupsContainer}
        >
          <Typography>Access Rights Summary</Typography>
          {!hasAnyPermission && (
            <Grid className={classes.permissionGroupRoot}>
              <Typography variant="caption" color="textSecondary">
                <i>No permissions assigned yet</i>
              </Typography>
            </Grid>
          )}
          {hasAnyPermission &&
            this.renderVisiblePermissionGroups(permissionGroups)}
        </Grid>
      </Paper>
    );
  }
}

const styles = (theme) => ({
  permissionGroupsContainer: {
    padding: theme.spacing(3),
  },

  permissionGroupRoot: {
    paddingTop: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(RoleAccessSummary);
