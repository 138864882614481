import { useMemo } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button, Grid } from '@material-ui/core';

import { Table } from 'altus-ui-components';
import { cableTypeToString } from 'features/projects/tool/tool.mappers';

const defaultInitialState = {
  pageSize: 50,
};

const AddCablesModalTable = ({
  cables,
  classes,
  onAddCable,
  onRemoveCable,
  selectedCables,
  setSelectedCables,
  initialState = defaultInitialState,
  ...rest
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 2,
        Header: 'Item no.',
        accessor: (cable) => cable.get('partNo'),
      },
      {
        xs: 2,
        Header: 'Cable',
        accessor: (cable) => cable.get('name'),
      },
      {
        xs: 2,
        Header: 'Type',
        accessor: (cable) => cableTypeToString(cable.get('type')),
      },
      {
        xs: 2,
        Header: 'Nominal Dia.',
        accessor: (cable) =>
          `${cable?.getIn(
            ['nominalDiameter', 'roundedValue'],
            null,
          )} ${cable?.getIn(['nominalDiameter', 'unit'])}`,
      },
      {
        xs: 2,
        Header: 'Weight in Air',
        accessor: (cable) =>
          `${cable?.getIn(
            ['weightInAir', 'roundedValue'],
            null,
          )} ${cable?.getIn(['weightInAir', 'unit'])}`,
      },
      {
        xs: 2,
        Header: 'Stretch',
        accessor: (cable) =>
          `${cable?.getIn(['stretch', 'roundedValue'], null)} ${cable?.getIn([
            'stretch',
            'unit',
          ])}`,
      },
      {
        xs: 2,
        Header: 'Breaking Strength',
        accessor: (cable) =>
          `${cable?.getIn(['strength', 'roundedValue'], null)} ${cable?.getIn([
            'strength',
            'unit',
          ])}`,
      },
      {
        xs: 2,
        id: 'action',
        Cell: ({ row }) => {
          const cable = row.original;
          return (
            <Grid className={classes.actions}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                title="Add cable"
                onClick={() => onAddCable(cable)}
              >
                <AddIcon />
                Add
              </Button>
            </Grid>
          );
        },
      },
    ],
    [classes, onAddCable],
  );

  return (
    <Table
      usePagination
      items={cables}
      columns={columns}
      useGlobalFilter={false}
      initialState={initialState}
      {...rest}
    />
  );
};

export default AddCablesModalTable;
