import { Box, Grid, withStyles } from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import TelemetryGauge from 'features/projects/dashboard/components/dashboards/metalMilling/TelemetryGauge';
import MetalMillingROP from 'features/projects/dashboard/components/dashboards/metalMilling/MetalMillingROP';
import DrillModelContainer from 'features/projects/dashboard/components/dashboards/metalMilling/DrillModelContainer';
import MetalMillingChartsContainer from 'features/projects/dashboard/components/dashboards/metalMilling/MetalMillingChartsContainer';

const MetalMillingDashBoardContainer = ({
  classes,
  registerDataPointsHandler,
}) => (
  <Grid
    container
    wrap="nowrap"
    direction="column"
    justifyContent="space-between"
  >
    <Grid item component={Box} padding={3} xs={3} className={classes.container}>
      <MetalMillingROP registerDataPointsHandler={registerDataPointsHandler} />
    </Grid>
    <Grid
      xs={3}
      item
      container
      padding={5}
      component={Box}
      alignItems="center"
      borderTop="2px solid gray"
      borderBottom="2px solid gray"
      className={classes.container}
    >
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        <DrillModelContainer
          registerDataPointsHandler={registerDataPointsHandler}
        />
      </Grid>
      <Grid item xs={3}>
        <ArrowRightIcon className={classes.icon} />
        <ArrowRightIcon className={classes.icon} />
        <ArrowRightIcon className={classes.icon} />
      </Grid>
    </Grid>
    <Grid item component={Box} padding={3} xs={3} className={classes.container}>
      <MetalMillingChartsContainer
        registerDataPointsHandler={registerDataPointsHandler}
      />
    </Grid>
    <Grid item component={Box} padding={3} xs={3} className={classes.container}>
      <TelemetryGauge registerDataPointsHandler={registerDataPointsHandler} />
    </Grid>
  </Grid>
);

const styles = (theme) => ({
  icon: {
    fill: theme.altus.components.Dashboards.metalMilling.icon,
    fontSize: theme.typography.h2.fontSize,
  },
  container: {
    maxWidth: 'none',
  },
});

export default withStyles(styles)(MetalMillingDashBoardContainer);
