import { compose } from 'redux';
import { useCallback, useState } from 'react';
import { arrayMoveImmutable } from 'array-move';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  addNotification,
  NOTIFICATION_VARIANTS,
} from 'altus-redux-middlewares';

import { useModal } from 'altus-modal';

import {
  MODAL,
  EQUIPMENT_ACTIONS,
} from 'features/equipment/equipment.constants';

import {
  getSummarizedDataStateFromState,
  getCurrentClientOrganizationIdFromStateForAssembly,
} from 'app/app.selectors';

import BasePage from 'app/components/BasePageDense';
import { saveBhaTemplate } from 'features/equipment/equipment.actions';
import { ToolCategoryType } from 'features/projects/tool/tool.constants';
import ToolPickerModalContainer from 'features/projects/tool/components/ToolPickerModalContainer';
import AddBhaTemplatesModal from 'features/equipment/bhaTemplates/addBhaTemplates/AddBhaTemplatesModal';

const TOOL_TYPES = {
  Individual: 1,
  Assembly: 2,
};

const toolstringItemCategories = [
  ToolCategoryType.MWL,
  ToolCategoryType.EWL,
  ToolCategoryType.ThirdParty,
  ToolCategoryType.Assemblies,
];

const AddBhaTemplatesModalContainer = ({
  openModal,
  dataState,
  toggleModal,
}) => {
  const dispatch = useDispatch();

  const [selectedTools, setSelectedTools] = useState([]);
  const [bhaTemplateInfo, setBhaTemplateInfo] = useState(null);

  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromStateForAssembly,
  );

  const [toolAssemblyItemModalOpen, toggleToolAssemblyItemModal] = useModal(
    MODAL.ADD_TOOL_TO_TOOL_ASSEMBLY,
  );

  const onAddTool = useCallback(
    (tool) => {
      setSelectedTools((prevArray) => [...prevArray, tool]);
      dispatch(
        addNotification({
          message: 'Tool added to assembly.',
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        }),
      );
    },
    [dispatch],
  );

  const onSortToolstringItem = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) return;

      const tools = [...selectedTools];
      const newTools = arrayMoveImmutable(tools, oldIndex, newIndex);
      setSelectedTools(newTools);
    },
    [selectedTools],
  );

  const onDeleteToolstringItem = useCallback(
    (toolAssemblyTool) => {
      const tools = [...selectedTools];
      const toolIndex = tools.indexOf(toolAssemblyTool);
      tools.splice(toolIndex, 1);
      setSelectedTools(tools);
    },
    [selectedTools],
  );

  const onDuplicateItem = useCallback(
    (tool) => {
      const tools = [...selectedTools];
      const toolIndex = tools.indexOf(tool);
      tools.push(tool);
      const lastToolIndex = tools.lastIndexOf(tool);
      const newTools = arrayMoveImmutable(tools, lastToolIndex, toolIndex + 1);
      setSelectedTools(newTools);
    },
    [selectedTools],
  );

  const onSaveBhaTemplate = useCallback(() => {
    const sortedTools = selectedTools.map((tool) => {
      if (tool.get('toolAssemblyTools').size === 0) {
        return {
          id: tool.get('id'),
          type: TOOL_TYPES.Individual,
        };
      } else {
        return {
          id: tool.get('id'),
          type: TOOL_TYPES.Assembly,
        };
      }
    });
    const bhaToSave = {
      name: bhaTemplateInfo.name,
      description: bhaTemplateInfo.description,
      m3ItemNumber: bhaTemplateInfo.m3ItemNumber,
      organizationId: currentClientOrganizationId,
      tools: sortedTools,
    };
    dispatch(saveBhaTemplate(bhaToSave));
  }, [selectedTools, dispatch, bhaTemplateInfo, currentClientOrganizationId]);

  return (
    <BasePage dataState={dataState}>
      <AddBhaTemplatesModal
        open={openModal}
        dataState={dataState}
        toggleModal={toggleModal}
        toggleAddToolAssemblyToBhaModal={toggleToolAssemblyItemModal}
        onSortToolstringItem={onSortToolstringItem}
        onDeleteToolstringItem={onDeleteToolstringItem}
        onDuplicateItem={onDuplicateItem}
        selectedTools={selectedTools}
        bhaTemplateInfo={bhaTemplateInfo}
        setBhaTemplateInfo={setBhaTemplateInfo}
        onSaveBhaTemplate={onSaveBhaTemplate}
      />
      <ToolPickerModalContainer
        onAddTool={onAddTool}
        open={toolAssemblyItemModalOpen}
        onToggleModal={toggleToolAssemblyItemModal}
        enabledToolCategoryTypes={toolstringItemCategories}
      />
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    EQUIPMENT_ACTIONS.SAVE_BHA_TEMPLATE,
  ),
});

export default compose(connect(mapStateToProps))(AddBhaTemplatesModalContainer);
