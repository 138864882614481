import { useCallback } from 'react';

import { useRequest } from 'altus-hooks';

import mappers from 'mappers';
import projectWellboreDetailsService from 'services/projectWellboreDetail.service';

const useLatestProjectWellboreDetail = (projectId) => {
  const getLatestProjectWellboreDetail = useCallback(
    () =>
      projectWellboreDetailsService
        .getLatestProjectWellboreDetail(projectId)
        .then((projectWellboreDetail) =>
          projectWellboreDetail.wellboreDetail
            ? mappers.WellboreDetail.from(projectWellboreDetail.wellboreDetail)
            : undefined,
        ),
    [projectId],
  );

  return useRequest(getLatestProjectWellboreDetail);
};

export default useLatestProjectWellboreDetail;
