import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { ModalActions } from 'altus-ui-components';
import EditIcon from '@material-ui/icons/Edit';
import { Styles } from 'features/projects/tasks/task/toolstring/common/styles';

const GotoBhaEditModal = ({
  taskId,
  projectId,
  toolstringId,
  open,
  onClose,
  redirectToBhaEditAndCloseModal,
  classes,
}) => {
  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <Grid className={classes.header}>
        <Grid container item xs alignItems="center">
          <Typography variant="h6">Edit BHA</Typography>
        </Grid>
      </Grid>
      <DialogContent className={classes.content}>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title}>
              This will un-plan the BHA and Simulation and open the Edit BHA
              window
            </Typography>
          </CardContent>
        </Card>
      </DialogContent>
      <ModalActions classes={{ dialogActionsRoot: classes.actions }}>
        <Grid container item xs justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button onClick={onClose}>Cancel</Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                redirectToBhaEditAndCloseModal(taskId, projectId, toolstringId);
              }}
            >
              BHA edit
              <Box component={EditIcon} marginLeft={0.5} />
            </Button>
          </Grid>
        </Grid>
      </ModalActions>
    </Dialog>
  );
};

export default compose(withStyles(Styles))(GotoBhaEditModal);
