import isNil from 'lodash/isNil';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createElement } from 'react';
import { Typography } from '@material-ui/core';
import toJSComponent from 'with-immutable-props-to-js';

import { renderContent } from 'utils/app.util';
import { formatValue } from 'utils/format.util';
import { convertUnit } from 'utils/conversion.util';
import { getCurrentUserFromState } from 'app/app.selectors';
import { USE_PROJECT_DEFAULT_UNIT_SYSTEM } from 'app/app.constants';
import { getCurrentProject } from 'features/projects/projects.selectors';

const MetricImperial = ({
  to,
  from,
  value,
  dispatch,
  formatFn,
  component,
  renderValue,
  defaultValue,
  userMeasurementSystem,
  projectMeasurementSystem,
  userMeasurementPreferences,
  projectMeasurementPreferences,
  ...rest
}) => {
  if ([value, from].some((param) => isNil(param))) {
    return defaultValue ? createElement(component, rest, defaultValue) : null;
  }

  const system =
    userMeasurementSystem === USE_PROJECT_DEFAULT_UNIT_SYSTEM
      ? projectMeasurementSystem
      : userMeasurementSystem;

  const settings =
    userMeasurementSystem === USE_PROJECT_DEFAULT_UNIT_SYSTEM
      ? projectMeasurementPreferences
      : userMeasurementPreferences;

  const [result_value, result_unit] = convertUnit({
    settings,
    system,
    value,
    from,
    to,
  });

  return renderValue
    ? renderContent(renderValue, {
        convertedValue: result_value,
        convertedUnit: result_unit,
      })
    : createElement(component, rest, formatFn(result_value, result_unit));
};

MetricImperial.defaultProps = {
  component: Typography,
  formatFn: (...params) => formatValue(...params, 0.001),
};

MetricImperial.propTypes = {
  value: PropTypes.number,
  from: PropTypes.string.isRequired,
};

export default connect((state) => ({
  projectMeasurementPreferences: getCurrentProject(state).get('units'),
  projectMeasurementSystem: getCurrentProject(state).get('unit'),
  userMeasurementPreferences: getCurrentUserFromState(state).get('units'),
  userMeasurementSystem: getCurrentUserFromState(state).get('unit'),
}))(toJSComponent(MetricImperial));
