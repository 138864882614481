import { Grid } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import { Typography } from 'app/components/withNavigation';

const ToolstringPreviewEmpty = () => (
  <Grid
    xs
    item
    container
    justifyContent="center"
    direction="column"
    alignItems="center"
  >
    <Grid item>
      <WarningIcon fontSize="large" />
    </Grid>
    <Grid item>
      <Typography variant="subtitle2">
        <i>No tools added yet</i>
      </Typography>
    </Grid>
  </Grid>
);

export default ToolstringPreviewEmpty;
