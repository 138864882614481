import { compose } from 'redux';
import { memo, useCallback } from 'react';
import { Grid, Button, Box } from '@material-ui/core';

const AddBhaTemplatesModalFooter = ({
  bhaTemplateInfo,
  selectedTools,
  onSaveBhaTemplate,
}) => {
  const checkSaveDisabled = useCallback(() => {
    if (
      bhaTemplateInfo !== null &&
      bhaTemplateInfo.name !== '' &&
      selectedTools.length > 0
    ) {
      return false;
    }
    return true;
  }, [selectedTools, bhaTemplateInfo]);

  return (
    <Grid
      item
      container
      justifyContent="spaceBetween"
      alignItems="center"
      component={Box}
    >
      <Grid
        item
        container
        justifyContent="spaceBetween"
        xs={6}
        alignItems="center"
      ></Grid>
      <Grid item container justifyContent="flex-end" xs={6}>
        <Button
          color="primary"
          variant="contained"
          disabled={checkSaveDisabled()}
          onClick={onSaveBhaTemplate}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default compose(memo)(AddBhaTemplatesModalFooter);
