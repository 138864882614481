import classNames from 'classnames';
import { createElement } from 'react';
import Close from '@material-ui/icons/Close';
import { Fab as MuiFab, IconButton as MuiIconButton } from '@material-ui/core';

import withStyles from '@material-ui/styles/withStyles';

import withNavigation from 'app/components/withNavigation';

const Fab = withNavigation(MuiFab);
const IconButton = withNavigation(MuiIconButton);

const MenuIcon = ({
  page,
  Icon,
  classes,
  subPage,
  previousPage,
  defaultPreviousPage,
  ...rest
}) => {
  if (!page) {
    return (
      <div>
        <IconButton className={classes.topMenuIcon} {...rest}>
          {Icon}
        </IconButton>
      </div>
    );
  }

  const { id, to } = page;

  return (
    <div>
      {createElement(
        subPage === id ? Fab : IconButton,
        {
          to:
            subPage === id
              ? previousPage
                ? previousPage
                : defaultPreviousPage
              : to,
          className: classNames(classes.topMenuIcon, {
            [classes.topMenuButton]: subPage === id,
          }),
          color: subPage === id ? 'primary' : 'default',
          ...rest,
        },
        subPage === id ? <Close /> : Icon,
      )}
    </div>
  );
};

const styles = {
  topMenuIcon: {
    marginRight: 5,
  },
  topMenuButton: {
    height: 48,
    width: 48,
  },
};

export default withStyles(styles)(MenuIcon);
