import operationService from 'services/operation.service';
import { OPERATIONS_ACTIONS } from 'features/operations/operations.constants';

export const receiveOperations = (operations) => ({
  type: OPERATIONS_ACTIONS.RECEIVE_OPERATIONS,
  payload: operations,
});

export const receiveOperationsLiveProjects = (operations) => ({
  type: OPERATIONS_ACTIONS.RECEIVE_OPERATIONS_LIVE_PROJECTS,
  payload: operations,
});

export const receiveOperation = (operation) => ({
  type: OPERATIONS_ACTIONS.RECEIVE_OPERATION,
  payload: operation,
});

export const receiveCheetahJobs = (cheetahJobs) => ({
  type: OPERATIONS_ACTIONS.RECEIVE_CHEETAH_JOBS,
  payload: cheetahJobs,
});

export const receiveCheetahJob = (cheetahJob) => ({
  type: OPERATIONS_ACTIONS.RECEIVE_CHEETAH_JOB,
  payload: cheetahJob,
});

export const clearOperations = {
  type: OPERATIONS_ACTIONS.CLEAR_OPERATIONS,
};

export const searchOnlineOperations = (filter) => (dispatch) => {
  const payload = operationService
    .searchOnlineOperationsLite(filter)
    .then((operations) => dispatch(receiveOperations(operations)));

  dispatch({
    filter,
    type: OPERATIONS_ACTIONS.SEARCH_OPERATIONS,
    payload,
  });

  return payload;
};

export const searchOperations = (filter) => (dispatch) => {
  const payload = operationService
    .searchOperationsLiveProject(filter)
    .then((operations) => dispatch(receiveOperationsLiveProjects(operations)));

  dispatch({
    filter,
    type: OPERATIONS_ACTIONS.SEARCH_OPERATIONS,
    payload,
  });

  return payload;
};

export const searchCheetahJobs = (query) => (dispatch) => {
  const payload = operationService
    .searchCheetahJobs(query)
    .then((operations) => dispatch(receiveCheetahJobs(operations)));

  dispatch({
    type: OPERATIONS_ACTIONS.SEARCH_CHEETAH_JOBS,
    payload,
  });

  return payload;
};

export const updateCheetahJob = (cheetahJobId, cheetahJob) => (dispatch) => {
  const payload = operationService
    .updateCheetahJob(cheetahJobId, cheetahJob)
    .then((cheetahJob) => dispatch(receiveCheetahJob(cheetahJob)));

  dispatch({
    payload,
    type: OPERATIONS_ACTIONS.UPDATE_CHEETAH_JOB,
    notification: {
      success: 'Job updated successfully',
    },
  });

  return payload;
};

export const updateProjectStatus = (projectId, status) => (dispatch) => {
  const payload = operationService
    .updateProjectStatus(projectId, status)
    .then((operation) => dispatch(receiveOperation(operation)));

  dispatch({
    payload,
    type: OPERATIONS_ACTIONS.UPDATE_PROJECT_CHEETAH_JOB_STATUS,
    notification: {
      success: 'Project status updated successfully',
    },
  });

  return payload;
};
