import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { MenuItem, TextField, Typography } from '@material-ui/core';

import { EMPTY_MAP, ProjectPermission, ProjectRoles } from 'app/app.constants';
import { useProjectPermissions } from 'app/hooks/authorization/useProjectPermissions';

const NO_ROLE_SELECTED = '';

const ProjectMemberAccessCell = ({
  classes,
  projectRoles,
  projectMember,
  hasOtherOwners,
  assignProjectMemberToRole,
}) => {
  const { hasPermission } = useProjectPermissions(
    ProjectPermission.MANAGE_TEAM_MEMBERS_ACCESS,
  );

  const initialRoleId = projectMember.get('roles').first();

  if (!hasPermission) {
    const initialRole = initialRoleId
      ? projectRoles.get(initialRoleId.toString(), EMPTY_MAP)
      : EMPTY_MAP;

    return <Typography variant="body2">{initialRole.get('name')}</Typography>;
  }

  // avoid console warning if projectRoles are not finished loading
  const value =
    initialRoleId && projectRoles.has(initialRoleId.toString())
      ? initialRoleId
      : NO_ROLE_SELECTED;

  const projectMemberIsOwner = projectMember
    .get('roles')
    .has(ProjectRoles.OWNER);

  return (
    <TextField
      select
      fullWidth
      margin="none"
      value={value}
      disabled={projectMemberIsOwner && !hasOtherOwners}
      InputProps={{
        classes: {
          root: classes.inputRoot,
        },
      }}
      onChange={(event) =>
        assignProjectMemberToRole(projectMember, event.target.value)
      }
    >
      {projectRoles.valueSeq().map((role) => (
        <MenuItem
          key={role.get('roleId')}
          value={role.get('roleId')}
          title={role.get('description')}
        >
          {role.get('name')}
        </MenuItem>
      ))}
    </TextField>
  );
};

const styles = (theme) => ({
  inputRoot: {
    fontSize: theme.typography.body2.fontSize,
  },
});

export default compose(withStyles(styles))(ProjectMemberAccessCell);
