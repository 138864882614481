import { fromJS } from 'immutable';
import Edit from '@material-ui/icons/Edit';
import { Field, Form, Formik } from 'formik';
import { SortableElement } from 'react-sortable-hoc';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import { useMemo, useState, useCallback, useEffect, useContext } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';

import {
  validationSchemaForCompletedItem,
  validationSchemaForCurrentItem,
} from 'features/projects/tasks/task/activities/ActivityListItem/helpers/validation';

import { ActivityDuration } from 'app/components/Counter';
import PaperListItem from 'app/components/PaperListItem';
import { formatDate, formatDuration } from 'utils/format.util';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import HasProjectPermission from 'app/components/HasProjectPermission';
import {
  ProjectPermission,
  WORK_ITEM_STATUS,
  PROJECT_STATUS,
} from 'app/app.constants';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import WorkItemSortHandle from 'features/projects/components/WorkItemSortHandle';
import DateTimePickerFormik from 'app/components/form/formik/DateTimePickerFormik';
import ActivityDetails from 'features/projects/activities/components/ActivityDetails';
import { CREATE_ACTIVITY_MODE } from 'features/projects/activities/activities.constants';
import {
  addStyles,
  isDepthChanged,
} from 'features/projects/tasks/task/activities/ActivityListItem/helpers/utils';
import { FormFields } from 'features/projects/tasks/task/activities/ActivityListItem/helpers/constants';
import ActivityChildComponent from 'features/projects/tasks/task/activities/ActivityListItem/components/ActivityChildComponent';
import EditorFormik from 'app/components/form/formik/EditorFormik';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';

import { FormFieldEndTime } from 'features/projects/tasks/task/activities/ActivityListItem/components/formFieldEndTime';
import { formatToolstringToolStatusString } from 'features/projects/tasks/task/toolstring/toolstring.util';
import ActivityDetailsBottomBar from 'features/projects/activities/components/ActivityDetails/ActivityDetailsBottomBar';
import { ExecutionRootContainerContext } from 'features/projects/execution/ExecutionRootContainer';
import { useRealTimeNotifications } from 'features/projects/activities/hooks/useRealTimeNotifications';

const ActivityListItemFactory = (validationSchema) => {
  const ActivityListItem = ({
    index = 0,
    allActivities,
    classes,
    onClick,
    activity,
    canEditComments,
    childrenActivities,
    displayDescription,
    updateActivityTimes,
    next,
    taskId,
    goBack,
    previous,
    projectId,
    projectStatus,
    toggleCreateActivityModal,
    completeActivity,
    createNewPointInTimeActivity,
    pauseActivity,
    onChildDescriptionChanged,
    duplicateActivity,
    isCurrentActivity,
    onActivityChanged,
  }) => {
    const [expanded, setExpanded] = useState(true);
    const [rawParallelActivityJson, setRawParallelActivityJson] = useState();
    const [rawActivityDescriptionJson, setRawActivityDescriptionJson] =
      useState();
    const [rawActivityCommentsJson, setRawActivityCommentsJson] = useState();
    const [parallelActivityEditorState, setParallelActivityEditorState] =
      useState(EditorState.createEmpty());
    const [activityDescriptionEditorState, setActivityDescriptionEditorState] =
      useState(EditorState.createEmpty());
    const [activityCommentsEditorState, setActivityCommentsEditorState] =
      useState(EditorState.createEmpty());

    const handleChange = () => {
      setExpanded(!expanded);
    };

    const connection = useContext(ExecutionRootContainerContext);

    const [, , , deleteActivity] = useRealTimeNotifications(
      projectId,
      taskId,
      connection,
    );

    const isInPlanning = activity.status === WORK_ITEM_STATUS.PLANNED;
    let previousDateTime =
      allActivities?.get(index)?.get('endTimeTime') ??
      allActivities?.get(index)?.get('startTime');

    if (index > 0) {
      const startOrEndDate =
        allActivities?.get(index - 1)?.get('endTime') ??
        allActivities?.get(index - 1)?.get('startTime');
      previousDateTime = startOrEndDate;
    }

    const previousEndDate = previousDateTime;

    const updateContent = useCallback(
      (content, setContent, setEditorState, editorState) => {
        // 8343 restored && !setContent due to non-refreshing fields
        if (activity[content]?.getCurrentContent() && setContent) {
          const rawEditorState = convertToRaw(editorState.getCurrentContent());
          if (JSON.stringify(rawEditorState) !== JSON.stringify(setContent)) {
            const rawContentState = convertToRaw(
              activity[content].getCurrentContent(),
            );
            const newEditorState = EditorState.createWithContent(
              convertFromRaw(rawContentState),
            );
            setEditorState(newEditorState);
          }
        }
      },
      [activity],
    );

    const updateValues = (values, key, setRawJson) => {
      const JSONValue = JSON.stringify(
        convertToRaw(values[key].getCurrentContent()),
      );
      setRawJson(JSONValue);
    };

    const initialFormValues = useMemo(() => {
      const {
        [FormFields.PARALLEL_ACTIVITY]: parallel,
        [FormFields.START_TIME]: start,
        [FormFields.END_TIME]: end,
        [FormFields.DESCRIPTION]: description,
        [FormFields.PREVIOUS_ACTIVITY_START_TIME]: prevStart,
        [FormFields.NEXT_ACTIVITY_START_TIME]: nextStart,
        [FormFields.COMMENTS]: comments,
        ...restActivity
      } = activity;
      return {
        [FormFields.PARALLEL_ACTIVITY]: parallelActivityEditorState,
        [FormFields.START_TIME]: formatDate(activity.startTime),
        [FormFields.END_TIME]: formatDate(activity.endTime),
        previousEndTime: formatDate(previousEndDate),
        [FormFields.DESCRIPTION]: activityDescriptionEditorState,
        [FormFields.PREVIOUS_ACTIVITY_START_TIME]: formatDate(
          activity.previousActivityStartTime,
        ),
        [FormFields.NEXT_ACTIVITY_START_TIME]: formatDate(
          activity.nextActivityStartTime,
        ),
        [FormFields.COMMENTS]: activityCommentsEditorState,
        ...restActivity,
      };
    }, [
      activity,
      previousEndDate,
      parallelActivityEditorState,
      activityDescriptionEditorState,
      activityCommentsEditorState,
    ]);

    const onSubmit = (values, formikCallbacks) => {
      let hasChanged = false;
      const updated = { ...initialFormValues };

      const newEditorValue = JSON.stringify(
        convertToRaw(values.parallelActivity.getCurrentContent()),
      );
      const initialEditorValue = JSON.stringify(
        convertToRaw(initialFormValues.parallelActivity.getCurrentContent()),
      );

      if (newEditorValue !== initialEditorValue) {
        updateValues(values, 'parallelActivity', setRawParallelActivityJson);
        updated.parallelActivity = values.parallelActivity;
        setParallelActivityEditorState(values.parallelActivity);
        hasChanged = true;
      }

      if (
        JSON.stringify(convertToRaw(values.description.getCurrentContent())) !==
        JSON.stringify(
          convertToRaw(initialFormValues.description.getCurrentContent()),
        )
      ) {
        updateValues(values, 'description', setRawActivityDescriptionJson);
        setActivityDescriptionEditorState(values.description);
        updated.description = values.description;
        hasChanged = true;
      }

      if (
        JSON.stringify(convertToRaw(values.comments.getCurrentContent())) !==
        JSON.stringify(
          convertToRaw(initialFormValues.comments.getCurrentContent()),
        )
      ) {
        updateValues(values, 'comments', setRawActivityCommentsJson);
        setActivityCommentsEditorState(values.comments);
        updated.comments = values.comments;
        hasChanged = true;
      }

      if (isDepthChanged(values.depth, activity.depth)) {
        updated.depth = values.depth;
        hasChanged = true;
      }

      if (
        formatDate(activity.startTime) !== values.startTime ||
        formatDate(activity.endTime) !== values.endTime
      ) {
        activity.startTime = values.startTime;
        activity.endTime = values.endTime;
        updateActivityTimes(
          activity.projectId,
          activity.taskId,
          false,
          activity,
          formikCallbacks,
        );
      }

      if (hasChanged) {
        onActivityChanged(updated);
      }
    };

    useEffect(() => {
      const newRawParallelActivityJson = activity.parallelActivity
        ? convertToRaw(activity.parallelActivity.getCurrentContent())
        : activity.parallelActivity;
      const newRawActivityDescriptionJson = activity.description
        ? convertToRaw(activity.description.getCurrentContent())
        : activity.description;
      const newRawActivityCommentsJson = activity.comments
        ? convertToRaw(activity.comments.getCurrentContent())
        : activity.comments;

      if (
        JSON.stringify(rawParallelActivityJson) !==
          JSON.stringify(newRawParallelActivityJson) &&
        activity.parallelActivity
      ) {
        setRawParallelActivityJson(newRawParallelActivityJson);
        updateContent(
          'parallelActivity',
          newRawParallelActivityJson,
          setParallelActivityEditorState,
          parallelActivityEditorState,
        );
      }

      if (
        JSON.stringify(rawActivityDescriptionJson) !==
          JSON.stringify(newRawActivityDescriptionJson) &&
        activity.description
      ) {
        setRawActivityDescriptionJson(newRawActivityDescriptionJson);
        updateContent(
          'description',
          newRawActivityDescriptionJson,
          setActivityDescriptionEditorState,
          activityDescriptionEditorState,
        );
      }

      if (
        JSON.stringify(rawActivityCommentsJson) !==
          JSON.stringify(newRawActivityCommentsJson) &&
        activity.comments
      ) {
        setRawActivityCommentsJson(newRawActivityCommentsJson);
        updateContent(
          'comments',
          newRawActivityCommentsJson,
          setActivityCommentsEditorState,
          activityCommentsEditorState,
        );
      }
      // eslint-disable-next-line
    }, [
      activity,
      rawParallelActivityJson,
      rawActivityDescriptionJson,
      rawActivityCommentsJson,
    ]);

    const onDuplicateActivity = () => {
      const values = {
        description: activity.description,
        mode: CREATE_ACTIVITY_MODE.QUICK,
        sequence: activity.sequence + 1,
        type: {
          description: activity.description,
          id: activity.type.id,
          name: activity.name,
          value: {
            code: activity.code,
            id: activity.type.id,
            name: activity.name,
          },
        },
      };
      duplicateActivity(fromJS(values));
    };

    const lastChildActivitySequence = Math.max(
      ...childrenActivities.map((child) => child.sequence),
    );

    return (
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form>
            <PaperListItem
              classes={{
                paper: classes.paper,
                expandIconHidden: classes.expandIconHidden,
                expansionPanelExpanded: classes.expansionPanelExpanded,
                expansionPanelDetailsRoot: classes.expansionPanelDetailsRoot,
                paperExpandable: classes.paperExpandable,
              }}
            >
              <AutoSaveFormik timeout={2000}>
                <Grid container justifyContent="center">
                  <Grid item container xs={12} alignItems="center">
                    <Grid item xs={1} className={classes.statusIconContainer}>
                      {activity.status === WORK_ITEM_STATUS.PLANNED ? (
                        <HasProjectPermission
                          permissions={ProjectPermission.EDIT_ACTIVITIES}
                        >
                          <WorkItemSortHandle disabled={!isInPlanning} />
                        </HasProjectPermission>
                      ) : (
                        <WorkItemStatus
                          status={activity.status}
                          isWaiting={activity.isWait}
                          isPointInTime={activity.isPointInTime}
                        />
                      )}
                    </Grid>
                    <Grid item container xs={2} alignItems="center" spacing={2}>
                      <Grid item>
                        <Typography noWrap variant="body2">
                          {activity.name}
                        </Typography>
                      </Grid>
                      {activity.setOrRetrieveToolstringItemStatus !== 0 && (
                        <Grid item>
                          <Chip
                            label={formatToolstringToolStatusString(
                              activity.setOrRetrieveToolstringItemStatus,
                            )}
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={3} className={classes.datePickerContainer}>
                      {activity.startTime && (
                        <Field name={FormFields.START_TIME}>
                          {({ form, ...formik }) => (
                            <DateTimePickerFormik
                              disableFuture={true}
                              form={form}
                              {...formik}
                            />
                          )}
                        </Field>
                      )}
                      {!activity.isPointInTime &&
                        activity.isWait &&
                        activity.endTime && <FormFieldEndTime />}
                    </Grid>
                    <Grid item container xs={2} alignItems="center">
                      {!activity.isPointInTime && (
                        <ActivityDuration
                          key={activity.startTime}
                          endTime={
                            activity.isWait ||
                            projectStatus === PROJECT_STATUS.REPORT
                              ? activity.endTime
                              : activity.nextStandardActivityStartTime
                          }
                          startTime={activity.startTime}
                          waitCodesDuration={activity.waitCodesDuration}
                          calculateDurationFromStartAndEndtime
                          isTimerActive={
                            activity.status === WORK_ITEM_STATUS.STARTED
                          }
                          renderValue={({ elapsedTime }) => (
                            <Typography variant="body2">
                              {formatDuration(elapsedTime)}
                            </Typography>
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item container xs={2} alignItems="center">
                      <Field
                        type="number"
                        component={QuantityTextFieldFormik}
                        name={FormFields.DEPTH}
                        disabled={!isCurrentActivity}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {activity?.depth?.unit}
                            </InputAdornment>
                          ),
                        }}
                        className={classes.textFieldHighlight}
                      />
                    </Grid>
                    <Grid item container xs={2} justifyContent="flex-end">
                      <IconButton
                        title="Edit"
                        onClick={onClick}
                        classes={{
                          root: classes.iconButtonRoot,
                        }}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                      <IconButton
                        title="Duplicate"
                        onClick={onDuplicateActivity}
                        classes={{
                          root: classes.iconButtonRoot,
                        }}
                      >
                        <DuplicateIcon fontSize="small" />
                      </IconButton>
                      {childrenActivities.find(
                        (child) => child.parentActivityId === activity.id,
                      ) && (
                        <IconButton
                          title="Expand"
                          onClick={handleChange}
                          classes={{
                            root: classes.iconButtonRoot,
                          }}
                        >
                          {expanded ? (
                            <ExpandLessIcon fontSize="small" />
                          ) : (
                            <ExpandMoreIcon fontSize="small" />
                          )}
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container xs={10}>
                    {displayDescription && (
                      <Grid
                        item
                        container
                        xs={12}
                        spacing={1}
                        style={{ paddingBottom: '30px' }}
                      >
                        <Grid
                          item
                          xs={6}
                          style={{
                            display: 'flex',
                            flex: '1 1 auto',
                          }}
                        >
                          <Field name={FormFields.DESCRIPTION}>
                            {({ form, ...formik }) => (
                              <EditorFormik
                                disabled={!isCurrentActivity}
                                form={form}
                                {...formik}
                                toolbar={{
                                  options: ['inline', 'list'],
                                }}
                                toolbarOnFocus
                                label="Description"
                                minHeight={7}
                                maxHeight={7}
                                labelMarginLeft={8}
                                xs={12}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{ display: 'flex', flex: '1 1 auto' }}
                        >
                          <Field name={FormFields.PARALLEL_ACTIVITY}>
                            {({ form, ...formik }) => (
                              <EditorFormik
                                disabled={!isCurrentActivity}
                                form={form}
                                {...formik}
                                toolbar={{
                                  options: ['inline', 'list'],
                                }}
                                toolbarOnFocus
                                label="Parallel Activity / Information"
                                minHeight={7}
                                maxHeight={7}
                                labelMarginLeft={8}
                                xs={12}
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {expanded && (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.childrenContainer}
                    >
                      {childrenActivities.map((child) => {
                        if (child.parentActivityId === activity.id) {
                          if (child.sequence === lastChildActivitySequence) {
                            return (
                              <ActivityChildComponent
                                classes={classes}
                                child={child}
                                onClick={() =>
                                  deleteActivity(
                                    projectId,
                                    taskId,
                                    child.id,
                                    child.index,
                                    true,
                                  )
                                }
                                index={child.index}
                                onChildDescriptionChanged={
                                  onChildDescriptionChanged
                                }
                                updateActivityTimes={updateActivityTimes}
                                isLastSubActivity={true}
                                isCurrentActivity={isCurrentActivity}
                              />
                            );
                          }
                          return (
                            <ActivityChildComponent
                              classes={classes}
                              child={child}
                              onClick={() =>
                                deleteActivity(
                                  projectId,
                                  taskId,
                                  child.id,
                                  child.index,
                                  true,
                                )
                              }
                              index={child.index}
                              onChildDescriptionChanged={
                                onChildDescriptionChanged
                              }
                              updateActivityTimes={updateActivityTimes}
                              isCurrentActivity={isCurrentActivity}
                            />
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Grid>
                  )}
                  <Grid item container xs={12}>
                    {isCurrentActivity && (
                      <Grid item container className={classes.actions}>
                        <ActivityDetails
                          next={next}
                          taskId={taskId}
                          goBack={goBack}
                          previous={previous}
                          projectId={projectId}
                          activity={activity}
                          toggleCreateActivityModal={toggleCreateActivityModal}
                          createNewPointInTimeActivity={
                            createNewPointInTimeActivity
                          }
                          pauseActivity={pauseActivity}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container xs={10}>
                    {canEditComments && (
                      <HasProjectPermission
                        permissions={
                          ProjectPermission.READ_ACTIVITY_COMMENTS_EXECUTE
                        }
                      >
                        <HasProjectPermission
                          permissions={ProjectPermission.EDIT_ACTIVITIES}
                          render={(hasPermission) => (
                            <Grid item xs={6}>
                              <Field name={FormFields.COMMENTS}>
                                {({ form, ...formik }) => (
                                  <EditorFormik
                                    disabled={!hasPermission}
                                    form={form}
                                    {...formik}
                                    toolbar={{
                                      options: ['inline', 'list'],
                                    }}
                                    toolbarOnFocus
                                    label="Internal Comments"
                                    minHeight={7}
                                    maxHeight={7}
                                    labelMarginLeft={8}
                                    xs={12}
                                  />
                                )}
                              </Field>
                            </Grid>
                          )}
                        />
                      </HasProjectPermission>
                    )}
                  </Grid>
                  <Grid item container xs={10}>
                    {isCurrentActivity && (
                      <ActivityDetailsBottomBar
                        next={next.toJS()}
                        taskId={taskId}
                        projectId={projectId}
                        activity={activity}
                        toggleCreateActivityModal={toggleCreateActivityModal}
                        completeActivity={completeActivity}
                      />
                    )}
                  </Grid>
                </Grid>
              </AutoSaveFormik>
            </PaperListItem>
          </Form>
        )}
      </Formik>
    );
  };
  return ActivityListItem;
};

export const UnSortableActivityListItemCompleted = addStyles(
  ActivityListItemFactory(validationSchemaForCompletedItem),
);
export const UnSortableActivityListItemCurrent = addStyles(
  ActivityListItemFactory(validationSchemaForCurrentItem),
);
const SortableListItem = addStyles(
  ActivityListItemFactory(validationSchemaForCompletedItem),
);
export default SortableElement(SortableListItem);
