import { Field } from 'formik';
import { useMemo, useState } from 'react';
import { fromJS } from 'immutable';
import { useCallback } from 'react';
import { Grid, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import { Table } from 'altus-ui-components';
import { useModal } from 'altus-modal';

import { WORK_ITEM_STATUS } from 'app/app.constants';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import TableRowAutocompleteTextFieldFormik from 'app/components/form/formik/TableRowAutocompleteTextFieldFormik';
import { cableTypeToString } from 'features/projects/tool/tool.mappers';
import { TASK_URLS } from 'features/projects/activities/activities.constants';
import {
  ASSET_HISTORY_EQUIPMENT_TYPE,
  ASSET_HISTORY_TEST_TYPES,
  EquipmentType,
  MODAL,
} from 'features/equipment/equipment.constants';
import CreateAssetHistoryModalContainer from 'features/equipment/assets/components/CreateAssetHistoryModalContainer';

const defaultInitialState = {
  pageSize: 50,
};

const TableCellWithFallback = ({ value, fallback = '' }) => (
  <span>{value || fallback}</span>
);

const AddedCablesInExecuteTable = ({
  task,
  classes,
  onSubmit,
  cablesWithSerialNo,
  initialState = defaultInitialState,
  ...rest
}) => {
  const taskStatus = task?.get('status');
  const [isOpen, toggleModal] = useModal(MODAL.ADD_ASSET_HISTORY_TEST);
  const [serialNumber, setSerialNumber] = useState();
  const [cableId, setCableId] = useState();

  const openModal = (event) => {
    event?.preventDefault();
    toggleModal();
  };

  const DropdownMenu = ({ externalToolId, serialNumber }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const showOptions = externalToolId && serialNumber;
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const complaintMenuItem = () => {
      return (
        <MenuItem
          href={TASK_URLS.ADD_COMPLAINT_URL}
          onClick={() => {
            handleClose();
            window.open(
              TASK_URLS.ADD_COMPLAINT_URL,
              '_blank',
              'noopener,noreferrer',
            );
          }}
        >
          <OpenInNewIcon style={{ paddingRight: '5px' }} />
          Add Complaint
        </MenuItem>
      );
    };

    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        {!showOptions ? (
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            {complaintMenuItem()}
          </Menu>
        ) : (
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setSerialNumber(serialNumber);
                setCableId(externalToolId);
                handleClose();
                openModal();
              }}
            >
              {<PlaylistAddIcon style={{ paddingRight: '5px' }} />} Add Event to
              Asset
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                window.open(
                  `/cable/${externalToolId}/assethistory/${serialNumber}`,
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            >
              {<OpenInNewIcon style={{ paddingRight: '5px' }} />}
              Open Asset History
            </MenuItem>
            {complaintMenuItem()}
          </Menu>
        )}
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        xs: 2,
        Header: 'Item no.',
        accessor: (cable) =>
          cable.get('partNo')
            ? cable.get('partNo')
            : cable.get('manufacturerPartNo'),
        Cell: ({ value }) => <TableCellWithFallback value={value} />,
      },
      {
        xs: 3,
        id: 'serialNo',
        Header: 'Serial No.',
        accessor: (cable) => cable.get('serialNo'),
        Cell: ({ value }) => {
          if (taskStatus >= WORK_ITEM_STATUS.COMPLETED) {
            return <TableCellWithFallback value={value} />;
          }

          return (
            <Field
              type="string"
              name="individualSerialNumber"
              placeholder={'Serial no.'}
              component={TableRowAutocompleteTextFieldFormik}
              keepChangeAfterOnBlur={true}
            />
          );
        },
      },
      {
        xs: 2,
        Header: 'Cable',
        accessor: (cable) => cable.get('name'),
        Cell: ({ value }) => <TableCellWithFallback value={value} />,
      },
      {
        xs: 2,
        Header: 'Type',
        accessor: (cable) => cableTypeToString(cable.get('type')),
        Cell: ({ value }) => <TableCellWithFallback value={value} />,
      },
      {
        xs: 2,
        Header: 'Nominal Dia.',
        accessor: (cable) =>
          `${cable?.getIn(
            ['nominalDiameter', 'roundedValue'],
            null,
          )} ${cable?.getIn(['nominalDiameter', 'unit'])}`,
        Cell: ({ value }) => <TableCellWithFallback value={value} />,
      },
      {
        xs: 2,
        Header: 'Weight in Air',
        accessor: (cable) => {
          const weightKey = cable.get('weight') ? 'weight' : 'weightInAir';
          const roundedValue = cable?.getIn([weightKey, 'roundedValue'], null);
          const unit = cable?.getIn([weightKey, 'unit']);
          return `${roundedValue} ${unit}`;
        },
        Cell: ({ value }) => <TableCellWithFallback value={value} />,
      },
      {
        xs: 2,
        Header: 'Stretch',
        accessor: (cable) => {
          const weightKey = cable.get('stretchCoefficient');
          const roundedValue = cable?.getIn([weightKey, 'roundedValue'], null);
          const unit = cable?.getIn([weightKey, 'unit']);
          if (!roundedValue && !unit) {
            return '';
          }
          return `${roundedValue} ${unit}`;
        },
        Cell: ({ value }) => <TableCellWithFallback value={value} />,
      },
      {
        xs: 2,
        Header: 'Breaking Strength',
        accessor: (cable) =>
          `${cable?.getIn(['strength', 'roundedValue'], null)} ${cable?.getIn([
            'strength',
            'unit',
          ])}`,
        Cell: ({ value }) => <TableCellWithFallback value={value} />,
      },
      {
        xs: 2,
        id: 'action',
        Cell: ({ row }) => {
          const cable = row.original;
          const externalToolId = cable.get('id');
          const serialNumber = cable.get('serialNo');

          return serialNumber ? (
            <Grid>
              <DropdownMenu
                externalToolId={externalToolId}
                serialNumber={serialNumber}
              />
            </Grid>
          ) : null;
        },
      },
    ],
    [taskStatus],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <EditableTableRowFormik {...props} onSubmit={onSubmit} timeout={5} />
    ),
    [onSubmit],
  );

  return (
    <>
      <Table
        stickyHeader
        usePagination
        items={fromJS(cablesWithSerialNo)}
        columns={columns}
        initialState={initialState}
        useGlobalFilter={false}
        TableRowComponent={renderTableRowComponent}
        {...rest}
      />
      <CreateAssetHistoryModalContainer
        toggleModal={openModal}
        isOpen={isOpen}
        serialNumber={serialNumber}
        externalToolId={cableId}
        equipmentType={EquipmentType.CABLE}
        getAssetAfterRefresh={false}
        testTypesItems={ASSET_HISTORY_TEST_TYPES.filter(
          (item) =>
            item.equipmentType === ASSET_HISTORY_EQUIPMENT_TYPE.CABLE ||
            item.equipmentType === ASSET_HISTORY_EQUIPMENT_TYPE.ALL,
        )}
      />
    </>
  );
};

export default AddedCablesInExecuteTable;
