import takeRight from 'lodash/takeRight';
import Stop from '@material-ui/icons/Stop';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import { formatValue } from 'utils/format.util';

const getLegendIcon = (data = []) => {
  if (!data.length) {
    return Stop;
  }

  try {
    if (data.length >= 2) {
      const [point = [], last = []] = takeRight(data, 2);

      if (last[1] > point[1]) {
        return ArrowDropUp;
      } else if (last[1] < point[1]) {
        return ArrowDropDown;
      }
      return Stop;
    }
    return Stop;
  } catch (error) {
    return Stop;
  }
};

const WellboreTrajectoryLegends = ({ legends, classes }) => (
  <Grid container>
    {legends
      .filter((legend) => legend.depth)
      .map(({ name, unit, color, data: values, depth }) => {
        const Icon = getLegendIcon(values);

        return (
          <Grid item key={`${name}${unit}`} xs={12}>
            <Grid container alignItems="center">
              <Grid
                item
                className={classes.legendIcon}
                style={{
                  color: Array.isArray(color) ? color[0] : color,
                }}
              >
                <Icon fontSize="small" />
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="caption" color="textPrimary">
                      {`${name} (${formatValue(depth, unit, 0.1)})`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
  </Grid>
);

const styles = () => ({
  legendIcon: {
    display: 'flex',
  },
});

export default withStyles(styles)(WellboreTrajectoryLegends);
