import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { EditorState, convertFromRaw } from 'draft-js';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import RedIcon from 'assets/Red.svg';
import GreenIcon from 'assets/Green.svg';
import OrangeIcon from 'assets/Orange.svg';
import EditorStateViewer from 'app/components/EditorStateViewer';
import ProjectUser from 'features/projects/components/ProjectUser';
import { STRING_TEST_REPORT_EVENT_TYPE_ENUM } from 'features/projects/mobilisation/projectMobilisation.constants';

const getStatusString = (eventType) => {
  switch (eventType) {
    case STRING_TEST_REPORT_EVENT_TYPE_ENUM.PASS:
      return 'Pass';
    case STRING_TEST_REPORT_EVENT_TYPE_ENUM.WARNING:
      return 'Warning';
    case STRING_TEST_REPORT_EVENT_TYPE_ENUM.FAIL:
      return 'Fail';
    default:
      return 'Invalid event type';
  }
};

const getStatusIcon = (eventType) => {
  switch (eventType) {
    case STRING_TEST_REPORT_EVENT_TYPE_ENUM.PASS:
      return GreenIcon;
    case STRING_TEST_REPORT_EVENT_TYPE_ENUM.WARNING:
      return OrangeIcon;
    case STRING_TEST_REPORT_EVENT_TYPE_ENUM.FAIL:
      return RedIcon;
    default:
      return OrangeIcon;
  }
};

const formatDateString = (dateString) => {
  return moment.utc(dateString).format('YYYY/M/D HH:mm');
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.table.row.main,
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(1.25),
      padding: theme.spacing(2.25),
    },
  }),
);

const StringTestEventContainer = ({ selectedStringTestReport = null }) => {
  const classes = useStyles();

  const comments = selectedStringTestReport.get('comment')
    ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(selectedStringTestReport.get('comment'))),
      )
    : EditorState.createEmpty();

  if (selectedStringTestReport === null) return null;
  return (
    <Grid item xs={12} container className={classes.root}>
      <Grid item xs={9} container spacing={4} alignItems="center">
        <Grid item container xs={3} direction="row" alignItems="center">
          <Typography>Result:</Typography>
          <img
            src={getStatusIcon(selectedStringTestReport.get('result'))}
            alt="Event Type"
          />
          <Typography>
            {getStatusString(selectedStringTestReport.get('result'))}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>
            Date: {formatDateString(selectedStringTestReport.get('time'))}
          </Typography>
        </Grid>
        <Grid item container xs={6} alignItems="center" spacing={1}>
          <Grid item>
            <Typography>Comment: </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <EditorStateViewer editorState={comments} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} container justifyContent="flex-end" alignItems="center">
        <Grid item>
          <ProjectUser
            user={selectedStringTestReport.get('user')}
            organization={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StringTestEventContainer;
