import { isIPv4 } from 'is-ip';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';

import { EMPTY_STRING } from 'app/app.constants';

export const required = (value) => {
  const isQuantity = !!value?.unit; // workaround to check if it's a quantity object.

  if (isQuantity) return requiredQuantity(value);

  const isInvalid = isNumber(value) ? isNil(value) : !value;

  return isInvalid ? 'Required' : undefined;
};

export const requiredQuantity = (quantity) => {
  const isInvalid = isNil(quantity?.value) || quantity.value === EMPTY_STRING;

  return isInvalid ? 'Required' : undefined;
};

export const createMaxLengthValidator = (maxLength) => (value) => {
  if (isNil(value)) return undefined;

  return value.length > maxLength ? `Max ${maxLength} characters` : undefined;
};

export const validateIPAddress = (value) => {
  if (!value) return undefined;

  return isIPv4(value) ? undefined : 'Invalid IP address';
};
