import { useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { EMPTY_LIST } from 'app/app.constants';
import PaperListItem from 'app/components/PaperListItem';
import TableRowExpandable from 'app/components/TableRowExpandable';

const TemplateTableRow = ({ item, ...rest }) => {
  const renderDetails = useCallback(
    () => (
      <Grid container>
        <PaperListItem transparent>
          <Typography>Activities</Typography>
        </PaperListItem>
        {item.get('activities', EMPTY_LIST).map((activity, index) => (
          <PaperListItem key={index}>
            <Grid container>
              <Grid item>
                <Typography>{activity.get('name')}</Typography>
              </Grid>
            </Grid>
          </PaperListItem>
        ))}
      </Grid>
    ),
    [item],
  );
  return <TableRowExpandable {...rest} renderDetails={renderDetails} />;
};

export default TemplateTableRow;
