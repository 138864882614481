import { compose } from 'redux';
import { InputAdornment } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import TextFieldV2 from 'app/components/form/TextFieldV2';

const TextFieldCustomSelect = ({
  value,
  onClick,
  classes,
  disabled,
  InputProps,
  ...rest
}) => {
  const handleOnClick = () => !disabled && onClick();

  return (
    <TextFieldV2
      value={value}
      onChange={null}
      disabled={disabled}
      onClick={handleOnClick}
      InputProps={{
        classes: {
          root: classes.inputHover,
          input: classes.inputHover,
        },
        endAdornment: (
          <InputAdornment position="end">
            <ArrowDropDownIcon />
          </InputAdornment>
        ),
        ...InputProps,
      }}
      {...rest}
    />
  );
};

const styles = () => ({
  inputHover: {
    '&:hover:not(:disabled)': {
      cursor: 'pointer',
    },
  },
});

export default compose(withStyles(styles))(TextFieldCustomSelect);
