import { compose } from 'redux';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

const ProjectTableRowDetail = ({ title, value, classes }) => (
  <Typography noWrap variant="body2">
    <Typography
      component="span"
      variant="inherit"
      color="textSecondary"
      className={classes.title}
    >
      {title}
    </Typography>
    <Typography color="textPrimary" component="span" variant="inherit">
      <b>{value}</b>
    </Typography>
  </Typography>
);

const styles = (theme) => ({
  title: {
    paddingRight: theme.spacing(1),
  },
});

export default compose(withStyles(styles))(ProjectTableRowDetail);
