export const ACTIONS = {
  REQUEST_CREATE_WELLBORE_SECTION: 'REQUEST_CREATE_WELLBORE_SECTION',
  REQUEST_UPDATE_WELLBORE_SECTION: 'REQUEST_UPDATE_WELLBORE_SECTION',
  REQUEST_DELETE_WELLBORE_SECTION: 'REQUEST_DELETE_WELLBORE_SECTION',
  REQUEST_WELLBORE_SECTIONS: 'REQUEST_WELLBORE_SECTIONS',
  REQUEST_CREATE_WELLBORE_SECTION_NIPPLE:
    'REQUEST_CREATE_WELLBORE_SECTION_NIPPLE',
  REQUEST_UPDATE_WELLBORE_SECTION_NIPPLE:
    'REQUEST_UPDATE_WELLBORE_SECTION_NIPPLE',
  REQUEST_DELETE_WELLBORE_SECTION_NIPPLE:
    'REQUEST_DELETE_WELLBORE_SECTION_NIPPLE',

  DELETE_WELLBORE_SECTION: 'DELETE_WELLBORE_SECTION',
  RECEIVE_WELLBORE_SECTION: 'RECEIVE_WELLBORE_SECTION',
  RECEIVE_WELLBORE_SECTIONS: 'RECEIVE_WELLBORE_SECTIONS',
  DELETE_WELLBORE_SECTION_NIPPLE: 'DELETE_WELLBORE_SECTION_NIPPLE',
  RECEIVE_WELLBORE_SECTION_NIPPLES: 'RECEIVE_WELLBORE_SECTION_NIPPLES',

  UPDATE_WELLBORE_SECTION_SIMULATION: 'UPDATE_WELLBORE_SECTION_SIMULATION',
};

export const WellboreSectionType = {
  TUBING: 0,
  CASING: 1,
  LINER: 2,
  OPEN_HOLE: 3,
  DRILLPIPE: 4,
  RISER: 5,
};

export const MINUSKEY = '-';
