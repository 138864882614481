import { useSelector, useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { EMPTY_MAP, PROJECT_STATUS } from 'app/app.constants';
import StartOperation from 'features/projects/activities/components/StartOperation';
import EndOperation from 'features/projects/activities/components/EndOperation';
import CompleteTask from 'features/projects/activities/components/CompleteTask';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';
import { useTaskActivities } from 'features/projects/activities/hooks/useTaskActivities';
import * as actions from 'features/projects/activities/activities.actions';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      background: theme.palette.background.paper,
      opacity: 0.9,
      width: '100%',
      left: 0,
      bottom: '0px',
      position: 'absolute',
      transition: 'height 250ms',
      height: '0px',
    },
    active: {
      height: '100px',
    },
  }),
);

export const TaskExecution = ({ tasks, projectId, taskId, connection }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const execute = useSelector(getExecuteStatusFromState);
  const activities = useTaskActivities(taskId) ?? EMPTY_MAP;

  const status = execute.get('status');
  const progress = execute.get('progress');
  const nextTask = execute.getIn(['next', 'task']);
  const currentTask = execute.getIn(['current', 'task']);
  const nextActivity = execute.getIn(['next', 'activity']);
  const currentActivity = execute.getIn(['current', 'activity']);
  const previousActivity = execute.getIn(['previous', 'activity']);
  const currentTaskId = execute.getIn(['current', 'task', 'id']) ?? 0;

  const displayStartOperationBtn =
    !currentActivity &&
    !nextActivity &&
    !currentTask &&
    !nextTask &&
    execute.get('currentTaskProgress') === 0 &&
    progress === 0;

  const displayCompleteTaskBtn =
    execute.get('currentTaskProgress') === 100 &&
    ((!currentActivity && previousActivity && !nextActivity) ||
      activities.size === 0) &&
    parseInt(taskId) === currentTaskId;

  // kopaj ovde
  const displayEndOperationBtn =
    !currentActivity &&
    !nextActivity &&
    !currentTask &&
    !nextTask &&
    progress === 100 &&
    status === PROJECT_STATUS.EXECUTE;

  const active =
    displayStartOperationBtn ||
    displayEndOperationBtn ||
    displayCompleteTaskBtn;

  return (
    <div className={classNames(classes.root, { [classes.active]: active })}>
      <Grid>
        {displayStartOperationBtn && (
          <StartOperation projectId={projectId} taskId={taskId} />
        )}
        {displayEndOperationBtn && (
          <EndOperation
            goBack={() =>
              dispatch(actions.goBack(projectId, taskId, connection))
            }
            projectId={projectId}
          />
        )}
        {displayCompleteTaskBtn && (
          <CompleteTask
            tasks={tasks}
            taskId={taskId}
            nextTask={nextTask}
            projectId={projectId}
            goBack={() =>
              dispatch(actions.goBack(projectId, taskId, connection))
            }
            currentTask={currentTask}
          />
        )}
      </Grid>
    </div>
  );
};
