import { compose } from 'redux';
import { connect } from 'react-redux';
import { PureComponent } from 'react';

import { UserAvatar, SortableList, BasePage } from 'altus-ui-components';

import { EMPTY_LIST } from 'app/app.constants';
import { getUsersAssignedToSystemRole } from 'authorization/authorization.actions';
import { getUsersAssignedToSystemRoleFromState } from 'authorization/authorization.selectors';

class SystemRoleUsers extends PureComponent {
  componentDidMount() {
    const { dispatchGetUsersAssignedToSystemRole, systemRoleId } = this.props;
    dispatchGetUsersAssignedToSystemRole(systemRoleId);
  }

  columns = [
    {
      title: 'User name',
      getSortProperty: (user) => user.get('name'),
    },
  ];

  renderIcon = (user) => (
    <UserAvatar avatar={user.get('avatar')} initials={user.get('initials')} />
  );

  getKey = (user) => user.get('id');

  render() {
    const { users } = this.props;

    return (
      <BasePage>
        <SortableList
          items={users}
          displaySearchField
          getKey={this.getKey}
          defaultSortColumn={0}
          columns={this.columns}
          renderIcon={this.renderIcon}
        />
      </BasePage>
    );
  }
}
SystemRoleUsers.defaultProps = {
  users: EMPTY_LIST,
};
export default compose(
  connect(
    (state, { systemRoleId }) => {
      const getUsersAssignedToSystemRoleSelector =
        getUsersAssignedToSystemRoleFromState(systemRoleId);

      return {
        users: getUsersAssignedToSystemRoleSelector(state),
      };
    },
    {
      dispatchGetUsersAssignedToSystemRole: getUsersAssignedToSystemRole,
    },
  ),
)(SystemRoleUsers);
