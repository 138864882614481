import Agent from 'infrastructure/agent';
import { saveFile } from 'utils/app.util';

const getSimulationReportAvailableCharts = (projectId) =>
  Agent.get(`/reports/simulation/available-charts/${projectId}`);

const downloadProjectToolstringReport = (
  projectId,
  taskId,
  toolstringId,
  collapseAssemblies = false,
) =>
  Agent.file
    .get(
      `/reports/project/${projectId}/task/${taskId}/toolstring/${toolstringId}/download`,
      { collapseAssemblies: collapseAssemblies },
    )
    .then(([file, filename]) => saveFile(file, filename));

const downloadProjectSimulationReport = (
  projectId,
  taskId,
  simulationId,
  chartsToInclude = [],
  collapseAssemblies = false,
) =>
  Agent.file
    .get(
      `/reports/project/${projectId}/task/${taskId}/simulation/${simulationId}/download`,
      { chartTypes: chartsToInclude, collapseAssemblies: collapseAssemblies },
    )
    .then(([file, filename]) => saveFile(file, filename));

const downloadActivityReport = (projectId, taskId, activityId) =>
  Agent.file
    .get(
      `/reports/project/${projectId}/task/${taskId}/activity/${activityId}/download`,
    )
    .then(([file, filename]) => saveFile(file, filename));

const reportsService = {
  getSimulationReportAvailableCharts,
  downloadProjectToolstringReport,
  downloadProjectSimulationReport,
  downloadActivityReport,
};

export default reportsService;
