import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Grid } from '@material-ui/core';
import { createElement, PureComponent } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import withStyles from '@material-ui/styles/withStyles';

import {
  activityListOnLoad,
  activityListOnUnload,
} from 'features/projects/activities/activities.actions';

import { WORK_ITEM_STATUS } from 'app/app.constants';
import { styles } from 'features/projects/activities/components/ActivityList/helpers/styles';
import { selectElementByActivityStatus } from 'features/projects/activities/components/ActivityList/helpers/utils';

class ActivityList extends PureComponent {
  constructor(props) {
    super(props);

    const { projectId, taskId, updateActivity } = props;

    this.updateActivity = debounce(
      (activity) => updateActivity(projectId, taskId, activity),
      500,
    );
  }

  componentDidMount() {
    const { projectId, taskId, dispatchOnLoad } = this.props;

    dispatchOnLoad(projectId, taskId);
  }

  componentWillUnmount() {
    const { dispatchOnUnload } = this.props;

    dispatchOnUnload();
  }

  render() {
    const {
      taskId,
      goBack,
      execute,
      classes,
      projectId,
      projectStatus,
      activities,
      selectActivity,
      completeActivity,
      toggleCreateActivityModal,
      createNewPointInTimeActivity,
      pauseActivity,
      updateActivityTimes,
      duplicateActivity,
    } = this.props;

    const next = execute.get('next');
    const previous = execute.get('previous');

    const currentActivity = execute.getIn(['current', 'activity']);
    const allActivities = activities.toList();

    const parentActivities = activities.filter(
      (activity) => activity.get('isPointInTime') === false,
    );

    const childrenActivities = activities
      .toList()
      .toJS()
      .map((activity, index) => {
        if (activity.isPointInTime === true) {
          return { ...activity, index };
        }
        return null;
      })
      .filter((activity) => activity !== null);

    return (
      <Grid item container className={classes.activitiesList}>
        {activities
          .toList()
          .toJS()
          .map((activity, index) => {
            const isCurrentActivity =
              currentActivity?.get('id') === activity.id;

            if (
              parentActivities
                .toList()
                .toJS()
                .find((parentActivity) => parentActivity.id === activity.id)
            ) {
              return createElement(
                selectElementByActivityStatus(activity.status),
                {
                  index,
                  activity,
                  allActivities,
                  childrenActivities: childrenActivities,
                  canEditComments: isCurrentActivity,
                  key: activity.id,
                  onClick: (event, providedIndex) =>
                    selectActivity(
                      providedIndex !== undefined ? providedIndex : index,
                    ),
                  onActivityChanged: (updated) => {
                    this.updateActivity(updated);
                  },
                  onChildDescriptionChanged: (description, child) => {
                    this.updateActivity({
                      ...child,
                      description,
                    });
                  },
                  displayComments: isCurrentActivity,
                  displayDescription: true,
                  isCurrentActivity: isCurrentActivity,
                  disabled: activity.status !== WORK_ITEM_STATUS.PLANNED,
                  classes: {
                    expansionPanelExpanded: classes.expansionPanelExpanded,
                    expansionPanelDetailsRoot:
                      classes.expansionPanelDetailsRoot,
                  },
                  updateActivityTimes,
                  next: next,
                  taskId: taskId,
                  goBack: goBack,
                  previous: previous,
                  projectId: projectId,
                  projectStatus: projectStatus,
                  // eslint-disable-next-line
                  activity: activity,
                  toggleCreateActivityModal: toggleCreateActivityModal,
                  completeActivity: completeActivity,
                  createNewPointInTimeActivity: createNewPointInTimeActivity,
                  pauseActivity: pauseActivity,
                  duplicateActivity: duplicateActivity,
                },
              );
            } else return null;
          })}
      </Grid>
    );
  }
}

export default connect(null, {
  dispatchOnLoad: activityListOnLoad,
  dispatchOnUnload: activityListOnUnload,
})(SortableContainer(withStyles(styles)(ActivityList)));
