import { compose } from 'redux';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { UserAvatar } from 'altus-ui-components';

import { getCurrentUserFromState } from 'app/app.selectors';

class ApplicationSidebarTopContent extends PureComponent {
  render() {
    const { classes, currentUser } = this.props;

    return (
      <Grid item className={classes.sidebarTopContainer}>
        <Grid
          container
          item
          justifyContent="center"
          className={classes.userAvatar}
        >
          <UserAvatar
            avatar={currentUser.get('avatar')}
            initials={currentUser.get('initials')}
          />
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          variant="caption"
          color="secondary"
          component={Typography}
          className={classes.employeeName}
        >
          {currentUser.get('name')}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          variant="caption"
          color="secondary"
          component={Typography}
          className={classes.employeeOrganizationName}
        >
          {currentUser.getIn(['organization', 'name'])}
        </Grid>
      </Grid>
    );
  }
}

const styles = (theme) => ({
  sidebarTopContainer: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  employeeName: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  employeeOrganizationName: {
    fontSize: '0.7rem',
  },
  userAvatar: {
    margin: `${theme.spacing(1)}px 0`,
  },
});

export default compose(
  connect((state) => ({
    currentUser: getCurrentUserFromState(state),
  })),
  withStyles(styles),
)(ApplicationSidebarTopContent);
