import { useState, useCallback } from 'react';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, InputAdornment } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Field, Formik } from 'formik';
import Close from '@material-ui/icons/Close';

import { EMPTY_STRING } from 'app/app.constants';
import { updateFilter } from 'features/projects/projects.actions';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';

export const Filters = {
  PROJECT_STATUS: 'projectStatus',
  FACILITY_ID: 'facilityId',
  FIELD_ID: 'fieldId',
  TEXT_SEARCH: 'textSearch',
  ORGANIZATION_ID: 'organizationId',
  GET_LOCATIONS: 'getLocations',
  IS_ARCHIVE: 'isArchive',
};

const ARCHIVE_FILTERS = {
  TEXT_SEARCH: 'textSearch',
};

export const filterProjectOptions = ['Plan', 'Report', 'Execute'];

const ArchiveProjectFilter = ({
  goBackToProjects,
  updateProjectFilter,
  classes,
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState({
    Plan: false,
    Report: false,
  });

  const filterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value) => () => {
    const newSelected = { ...selected, [value]: !selected[value] };
    setSelected(newSelected);
    updateProjectFilter(newSelected);
  };

  const onArchiveTextSearchSubmit = useCallback(
    (formValues) => {
      const filterValues = {
        isArchive: true,
        getLocations: true,
        textSearch: formValues.textSearch,
      };
      dispatch(updateFilter(filterValues));
    },
    [dispatch],
  );

  const initialArchiveFilterValues = {
    [ARCHIVE_FILTERS.TEXT_SEARCH]: EMPTY_STRING,
  };

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Link
          href="#"
          color="inherit"
          underline="none"
          onClick={goBackToProjects}
        >
          <IconButton>
            <ChevronLeftIcon />
          </IconButton>
          <Typography component="span">PROJECTS</Typography>
        </Link>
      </Grid>
      <Grid item container xs={8}>
        <Grid item container className={classes.filterContainer}>
          <Grid item>
            <Formik
              enableReinitialize
              onSubmit={onArchiveTextSearchSubmit}
              initialValues={initialArchiveFilterValues}
            >
              <AutoSaveFormik>
                <Field name={ARCHIVE_FILTERS.TEXT_SEARCH}>
                  {({ form, ...formik }) => (
                    <TextFieldFormik
                      form={form}
                      {...formik}
                      label="Search"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              title="Remove"
                              onClick={() => {
                                form.setFieldValue(
                                  ARCHIVE_FILTERS.TEXT_SEARCH,
                                  initialArchiveFilterValues[
                                    ARCHIVE_FILTERS.TEXT_SEARCH
                                  ],
                                );
                                const value = {
                                  textSearch: '',
                                };
                                onArchiveTextSearchSubmit(value);
                              }}
                            >
                              <Close fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Field>
              </AutoSaveFormik>
            </Formik>
          </Grid>
          <Grid item>
            <Button onClick={filterClick} color="secondary" variant="contained">
              <FilterListIcon />
              <Box mr={1} />
              <Typography variant="body1">Filter</Typography>
            </Button>
          </Grid>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <FormGroup>
              {filterProjectOptions.map((option) => (
                <MenuItem key={option}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selected[option]}
                        onChange={handleToggle(option)}
                        name={option}
                      />
                    }
                    label={option}
                  />
                </MenuItem>
              ))}
            </FormGroup>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  root: {
    marginBottom: 15,
    paddingTop: 15,
  },
  leftIcon: {
    marginRight: 10,
  },
  viewToggle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '30px',
  },
};

export default compose(withStyles(styles))(ArchiveProjectFilter);
