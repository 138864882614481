import { Fragment } from 'react';
import ChatBubble from '@material-ui/icons/ChatBubble';

import {
  Box,
  Dialog,
  Button,
  IconButton,
  DialogContent,
} from '@material-ui/core';

import { useToggle } from 'altus-hooks';
import { ModalHeader, ModalActions } from 'altus-ui-components';
import EditorStateViewer from 'app/components/EditorStateViewer';
import { getRichEditorText } from 'utils/app.util';

const ActivityCommentModal = ({ activity }) => {
  const [isOpen, toggleModal] = useToggle();

  const comments = activity.get('comments');

  if (!comments || !getRichEditorText(comments)) return null;

  return (
    <Fragment>
      <IconButton
        title={comments}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          toggleModal();
        }}
      >
        <ChatBubble />
      </IconButton>
      <Dialog open={isOpen} onClose={toggleModal} fullWidth maxWidth="sm">
        <ModalHeader title={activity.get('name')} />
        <DialogContent>
          <Box paddingTop={2}>
            <EditorStateViewer editorState={comments} />
          </Box>
        </DialogContent>
        <ModalActions>
          <Button onClick={toggleModal}>Cancel</Button>
        </ModalActions>
      </Dialog>
    </Fragment>
  );
};

export default ActivityCommentModal;
