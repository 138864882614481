import { EMPTY_MAP } from 'app/app.constants';
import GoogleMap from 'app/components/GoogleMap';
import ProjectMapMarker from 'features/projects/components/WellMapLocations/ProjectMapMarker';

const CreateProjectModalMap = ({ wellbore = EMPTY_MAP }) => {
  const location = wellbore.get('location');

  return (
    <GoogleMap center={location}>
      {location && (
        <ProjectMapMarker
          lng={location.get('eastWest')}
          lat={location.get('northSouth')}
        />
      )}
    </GoogleMap>
  );
};

export default CreateProjectModalMap;
