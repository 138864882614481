import React from 'react';
import {
  SwipeableDrawer as Drawer,
  Grid,
  Typography,
  Divider,
  MenuItem,
  // Chip,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from 'app/components/withTooltip';
import withStyles from '@material-ui/styles/withStyles';
import withNavigation from 'app/components/withNavigation';
// import { invokeIfFunction } from 'utils/app.util';
import { toLogout, toPrivacyPolicy } from 'utils/route.util';
import ExitToApp from '@material-ui/icons/ExitToApp';
import NavigationLink from 'app/components/NavigationLink';
import { alpha } from '@material-ui/core/styles';

const NavigationMenuItem = withNavigation(MenuItem);

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.mobile.Drawer.background.main,
  minHeight: '100%',
  minWidth: '90%',
}));

const MobileUserMenuDrawer = ({ classes, open, toggleOpen, user, avatar }) => {
  return (
    <Drawer
      open={open}
      anchor="right"
      onClose={() => toggleOpen(false)}
      disableSwipeToOpen={true}
      variant="temporary"
      ModalProps={{
        keepMounted: false,
      }}
      PaperProps={{
        square: false,
        style: {
          overflow: 'hidden',
          backgroundColor: 'transparent',
          borderRadius: '25px 0 0 25px',
          minHeight: '100%',
          minWidth: '90%',
        },
      }}
    >
      <StyledBox>
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.closeIcon}>
            <IconButton
              title="Close"
              color="inherit"
              onClick={() => toggleOpen(false)}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} className={classes.nameContainer}>
            <Grid item xs={3}>
              {avatar(user, classes)}
            </Grid>
            <Grid item xs={9} className={classes.userDetails}>
              <Grid item xs={12}>
                <Typography variant="h6">{user.get('name')}</Typography>
              </Grid>
              {user.get('title') && (
                <Grid item xs={12}>
                  <Typography variant="body2">{user.get('name')}</Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  {user.get('mail')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          {/* {[
            {
              text: 'Unit Preferences',
              Icon: (
                <Chip
                  className={classes.chip}
                  key={'1'}
                  label={'Coming Soon'}
                />
              ),
            },
          ]
            .filter((x) => !x.hidden)
            .map((item) => {
              const { text, Icon, disabled, onClick, to } = item;

              return (
                <>
                  <NavigationMenuItem
                    to={to}
                    key={text}
                    disabled={disabled}
                    onClick={() => invokeIfFunction(onClick)}
                  >
                    <Grid container className={classes.navMenuItem}>
                      <Grid item xs>
                        <ListItemText
                          primaryTypographyProps={{
                            variant: 'h6',
                          }}
                        >
                          {text}
                        </ListItemText>
                      </Grid>
                      <Grid container item xs={2} justifyContent="center">
                        <ListItemIcon>{Icon}</ListItemIcon>
                      </Grid>
                    </Grid>
                  </NavigationMenuItem>
                  <Divider />
                </>
              );
            })} */}
          <NavigationLink
            className={classes.privacyPolicy}
            to={toPrivacyPolicy()}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Typography variant="body2">Privacy Policy</Typography>
          </NavigationLink>
          <Grid item xs={12} className={classes.logout}>
            <NavigationMenuItem to={toLogout()} key={'logout'}>
              <Grid container className={classes.nameContainer}>
                <Grid container item xs={2} justifyContent="center">
                  <ListItemIcon>{<ExitToApp />}</ListItemIcon>
                </Grid>
                <Grid item xs>
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'h6',
                    }}
                  >
                    {'Log Out'}
                  </ListItemText>
                </Grid>
              </Grid>
            </NavigationMenuItem>
          </Grid>
        </Grid>
      </StyledBox>
    </Drawer>
  );
};

const styles = (theme) => ({
  triggerIconRoot: {
    height: 70,
    width: 70,
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  userDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  navMenuItem: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logout: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: theme.mobile.Drawer.background.dark,
    width: '100%',
  },
  privacyPolicy: {
    color: theme.palette.common.white,
    position: 'absolute',
    bottom: theme.spacing(12),
    left: theme.spacing(2),
  },
  //remove this once we have the preferences feature
  chip: {
    height: 27,
    backgroundColor: alpha(theme.palette.common.white, 0.16),
  },
});

export default withStyles(styles)(MobileUserMenuDrawer);
