import { compose } from 'redux';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { lighten } from '@material-ui/core/styles';
import { Grid, Typography, Tabs, Tab, Divider } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import { getCable } from 'features/equipment/equipment.actions';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { getCableFromState } from 'features/equipment/equipment.selectors';
import {
  EQUIPMENT_ACTIONS,
  EquipmentType,
} from 'features/equipment/equipment.constants';
import EquipmentAssetsContainer from '../assets/EquipmentAssetsContainer';
import { onUnloadCable } from 'features/equipment/details/equipmentDetails.actions';
import { EQUIPMENT_DETAILS_ACTIVE_TABS } from './equipmentDetails.constants';
import { useHeader } from '../../../app/hooks/useHeader';

const CableDetails = ({
  equipmentId,
  classes,
  dataState,
  dispatchOnLoad,
  dispatchOnUnload,
}) => {
  const [activeTab, setActiveTab] = useState(
    EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS,
  );

  const headerSubtitleHandler = (activeTab) => {
    switch (activeTab) {
      case EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS:
        return 'Details';
      case EQUIPMENT_DETAILS_ACTIVE_TABS.ASSETS:
        return 'Assets';
      default:
        return 'Details';
    }
  };

  useHeader({
    subTitle: headerSubtitleHandler(activeTab),
    logoNavigation: '/',
  });

  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    dispatchOnLoad(equipmentId);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload, equipmentId]);

  const cable = useSelector(getCableFromState);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabs}
        className={classes.tabsContainer}
      >
        <Tab
          label="Details"
          value={EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS}
          className={classes.tab}
        />
        <Tab
          label="Assets"
          value={EQUIPMENT_DETAILS_ACTIVE_TABS.ASSETS}
          className={classes.tab}
        />
      </Tabs>
      <Divider className={classes.divider} />

      {activeTab === EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS && (
        <Grid item container xs className={classes.container}>
          <Grid xs={12} item container className={classes.topContainer}>
            <Grid item container xs={12} className={classes.topText}>
              <Typography variant="h6">
                {cable.get('manufacturer')} - {cable.get('name')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            xs={8}
            lg={4}
            item
            container
            className={classes.bottomContainer}
          >
            <Grid item container className={classes.bottomText}>
              <Grid item container xs={12} className={classes.bottomTextLine}>
                <Grid item container xs={6}>
                  <Typography variant="h5">Name:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  <Typography variant="h5">{cable.get('name')}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} className={classes.bottomTextLine}>
                <Grid item container xs={6}>
                  <Typography variant="h5">Manufacturer:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  <Typography variant="h5">
                    {cable.get('manufacturer')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} className={classes.bottomTextLine}>
                <Grid item container xs={6}>
                  <Typography variant="h5">Manufacturer Part No:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  <Typography variant="h5">
                    {cable.get('manufacturerPartNo')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} className={classes.bottomTextLine}>
                <Grid item container xs={6}>
                  <Typography variant="h5">Nominal Diameter:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  <Typography variant="h5">{`${cable?.getIn(
                    ['nominalDiameter', 'roundedValue'],
                    null,
                  )} ${cable?.getIn(['nominalDiameter', 'unit'])}`}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} className={classes.bottomTextLine}>
                <Grid item container xs={6}>
                  <Typography variant="h5">Stretch Coefficient:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  <Typography variant="h5">
                    {`${cable?.getIn(
                      ['stretchCoefficient', 'roundedValue'],
                      null,
                    )} ${cable?.getIn(['stretchCoefficient', 'unit'])}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12} className={classes.bottomTextLine}>
                <Grid item container xs={6}>
                  <Typography variant="h5">Breaking Strength:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  {cable.get('strength') ? (
                    <Typography variant="h5">
                      {cable.getIn(['strength', 'roundedValue'])}{' '}
                      {cable.getIn(['strength', 'unit'])}
                    </Typography>
                  ) : (
                    <Typography variant="h5">No data</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item container xs={12} className={classes.bottomTextLine}>
                <Grid item container xs={6}>
                  <Typography variant="h5">Safe Working Load:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  {cable.get('safeWorkingLoad') ? (
                    <Typography variant="h5">
                      {cable.getIn(['safeWorkingLoad', 'roundedValue'])}{' '}
                      {cable.getIn(['safeWorkingLoad', 'unit'])}
                    </Typography>
                  ) : (
                    <Typography variant="h5">No data</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item container xs={12} className={classes.bottomTextLine}>
                <Grid item container xs={6}>
                  <Typography variant="h5">Weight In Air:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  {cable.get('weightInAir') ? (
                    <Typography variant="h5">
                      {cable.getIn(['weightInAir', 'roundedValue'])}{' '}
                      {cable.getIn(['weightInAir', 'unit'])}
                    </Typography>
                  ) : (
                    <Typography variant="h5">No data</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid item container xs={12} className={classes.bottomTextLine}>
                <Grid item container xs={6}>
                  <Typography variant="h5">Is data verified:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  <Typography variant="h5">
                    {cable.get('isVerified') ? 'Yes' : 'No'}
                  </Typography>
                </Grid>
              </Grid>
              {cable.get('isVerified') && (
                <Grid item container xs={12} className={classes.bottomTextLine}>
                  <Grid item container xs={6}>
                    <Typography variant="h5">Data verified:</Typography>
                  </Grid>
                  <Grid item container xs={6}>
                    {cable.get('dataVerified') ? (
                      <Typography variant="h5">
                        {cable.get('dataVerified')}
                      </Typography>
                    ) : (
                      <Typography variant="h5">No data</Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
      {activeTab === EQUIPMENT_DETAILS_ACTIVE_TABS.ASSETS && (
        <Grid item container xs>
          <EquipmentAssetsContainer
            equipmentId={equipmentId}
            equipmentType={EquipmentType.CABLE}
          />
        </Grid>
      )}
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    flexDirection: 'column',
    padding: 0,
  },
  container: {
    overflow: 'auto',
    padding: theme.spacing(2.25),
  },
  topContainer: {
    flexDirection: 'row',
    height: '10%',
  },
  topText: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomContainer: {
    dispay: 'flex',
    flexDirection: 'row',
    height: '90%',
  },
  bottomText: {
    height: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    padding: theme.spacing(3),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  bottomTextLine: {
    height: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tab: {
    backgroundColor: 'transparent !important',
  },
});

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    EQUIPMENT_ACTIONS.LOAD_CABLE,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: getCable,
  dispatchOnUnload: onUnloadCable,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CableDetails);
