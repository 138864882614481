import { useState, useMemo, useCallback } from 'react';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import AddIcon from '@material-ui/icons/Add';
import LinkIcon from '@material-ui/icons/Link';
import { useToggle } from 'altus-hooks';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import ToolstringSerialNumberStatus from 'features/mobile/projects/execute/taskToolstring/ToolstringSerialNumberStatus';
import { Table, TableRowActionsCell } from 'altus-ui-components';
import { ToolType } from 'features/projects/tool/tool.constants';
import MobileDrawerBase from 'app/components/Mobile/MobileDrawerBase';
import MobileToolstringDrawerContent from 'features/mobile/projects/execute/taskToolstring/MobileToolstringDrawerContent';

export const getToolTypeIcon = (tool) => {
  const type = tool.get('type');
  switch (type) {
    case ToolType.ASSEMBLY:
      return <GroupWorkIcon />;
    case ToolType.TOOL:
      return tool.get('attachedTools').size > 0 ? <LinkIcon /> : null;
    default:
      return null;
  }
};

const MobileToolstringListContiner = ({
  classes,
  toolstringTools = EMPTY_LIST,
  updateItemProperties,
  assets = EMPTY_LIST,
  isStringVerified = false,
}) => {
  const [isExpanded, toggleExpanded] = useToggle(false);
  const [currentTool, setCurrentTool] = useState(EMPTY_MAP);
  const toggleDrawer = (tool) => {
    setCurrentTool(tool);
    toggleExpanded();
  };

  const getSerialNoString = (serialNo, itemNo = 0) => {
    if (!isStringVerified) return `• Serial No.: ${serialNo || ''}`;
    else {
      const assetSerialNo = assets
        .find((asset) => asset.get('itemNumber') === itemNo)
        ?.get('serialNumber');
      return `• Serial No.: ${assetSerialNo || ''}`;
    }
  };

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 2,
        Cell: ({ row }) => {
          const serialNo = row.original.get('serialNo');
          row.isExpanded = row.canExpand ? true : null;
          return (
            <TableRowActionsCell>
              <ToolstringSerialNumberStatus serialNumber={serialNo} />
            </TableRowActionsCell>
          );
        },
      },
    ],
    [],
  );

  const createRow = (tool, isSubRow = false) => (
    <Grid
      container
      onClick={() =>
        tool.get('type') !== ToolType.ASSEMBLY ? toggleDrawer(tool) : null
      }
    >
      <Grid
        item
        xs={12}
        className={!isSubRow ? classes.rowSize : classes.subRowSize}
      >
        {!isSubRow && (
          <Grid item xs={1}>
            {getToolTypeIcon(tool)}
          </Grid>
        )}
        {!isStringVerified && (
          <Grid item xs={1}>
            <ToolstringSerialNumberStatus serialNumber={tool.get('serialNo')} />
          </Grid>
        )}
        <Grid item xs={!isSubRow ? 9 : 10} className={classes.taskContent}>
          <Grid item xs={12}>
            <Typography className={classes.fontMedium} variant="body2">
              {tool.get('name')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              noWrap
              className={classes.fontSmaller}
              variant="body2"
              color="textSecondary"
            >
              {`Item No.: ${
                tool.get('m3ItemNumber') ? `#${tool.get('m3ItemNumber')}` : ''
              } ${
                (tool.get('type') !== ToolType.ASSEMBLY &&
                  getSerialNoString(
                    tool.get('serialNo'),
                    tool.get('m3ItemNumber'),
                  )) ||
                ''
              }`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          {tool.get('type') !== ToolType.ASSEMBLY && <AddIcon />}
        </Grid>
      </Grid>
    </Grid>
  );

  const renderTableRowComponent = useCallback(
    ({ item }) => {
      switch (item.get('type')) {
        case ToolType.TOOL:
          return (
            <Grid className={classes.darkBackground}>
              {createRow(item)}
              {item.get('attachedTools').size > 0
                ? item.get('attachedTools').map((tool) => createRow(tool, true))
                : null}
            </Grid>
          );
        case ToolType.ASSEMBLY:
          return (
            <Grid className={classes.darkBackground}>
              {createRow(item)}
              {item
                .get('toolAssemblyTools')
                .map((tool) => createRow(tool, true))}
            </Grid>
          );
        default:
          return null;
      }
    },
    // eslint-disable-next-line
    [classes],
  );

  return (
    <>
      {!isStringVerified && (
        <Grid
          container
          item
          xs={12}
          justifyContent="flex-start"
          className={classes.registerText}
        >
          <Typography variant="body2" align="left" paragraph>
            {'Register serial numbers for all tools.'}
          </Typography>
        </Grid>
      )}
      <Table
        useExpanded
        displayHeaders={false}
        items={toolstringTools}
        columns={columns}
        useGlobalFilter={false}
        TableRowComponent={renderTableRowComponent}
      />
      <MobileDrawerBase open={isExpanded} toggleOpen={toggleExpanded}>
        <MobileToolstringDrawerContent
          tool={currentTool}
          onSubmit={updateItemProperties}
          isStringVerified={isStringVerified}
        />
      </MobileDrawerBase>
    </>
  );
};

const styles = (theme) => {
  return {
    fontMedium: {
      fontSize: 'medium',
    },
    fontSmaller: {
      fontSize: 'smaller',
    },
    darkBackground: {
      background: theme.palette.background.default,
      marginTop: theme.spacing(0.5),
    },
    rowSize: {
      display: 'flex',
      flexDirection: 'row',
      height: theme.spacing(15),
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.background.default,
    },
    subRowSize: {
      display: 'flex',
      flexDirection: 'row',
      height: theme.spacing(15),
      marginTop: theme.spacing(0.5),
      padding: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
      background: theme.palette.background.paper,
      marginLeft: theme.spacing(4),
    },
    taskContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      paddingLeft: theme.spacing(2),
    },
    button: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: theme.spacing(0),
    },
    registerText: {
      paddingLeft: theme.spacing(2),
    },
  };
};

export default withStyles(styles)(MobileToolstringListContiner);
