import { compose } from 'redux';

import useWellbore from 'features/wells/hooks/useWellbore';

const WellboreBreadcrumb = ({ wellboreId }) => {
  const { wellbore } = useWellbore(wellboreId);

  return wellbore?.get('name') ?? '...';
};

export default compose()(WellboreBreadcrumb);
