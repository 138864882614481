import PropTypes from 'prop-types';
import ViewColumn from '@material-ui/icons/ViewColumn';
import ThreeDRotation from '@material-ui/icons/ThreeDRotation';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export const TrajectoryViewType = {
  THREE_D: 1,
  TABLE: 2,
};

const WellboreTrajectoryViewToggle = ({ viewType, setViewType }) => (
  <ToggleButtonGroup
    exclusive
    value={viewType}
    onChange={(_event, value) => {
      if (!value) return;

      setViewType(value);
    }}
  >
    <ToggleButton
      title="Display Survey as 3D model"
      value={TrajectoryViewType.THREE_D}
    >
      <ThreeDRotation />
    </ToggleButton>
    <ToggleButton title="Display Survey table" value={TrajectoryViewType.TABLE}>
      <ViewColumn />
    </ToggleButton>
  </ToggleButtonGroup>
);

WellboreTrajectoryViewToggle.propTypes = {
  viewType: PropTypes.oneOf(Object.values(TrajectoryViewType)).isRequired,
  setViewType: PropTypes.func.isRequired,
};

export default WellboreTrajectoryViewToggle;
