import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useMemo } from 'react';

import { Table, TableRow } from 'altus-ui-components';

import RedIcon from 'assets/Red.svg';
import GreenIcon from 'assets/Green.svg';
import OrangeIcon from 'assets/Orange.svg';
import { toEquipmentAssetHistory } from 'utils/route.util';

const statusRankings = {
  Template: 1,
  Preliminary: 1,
  'Config Struct': 1,
  Serviceable: 1,
  'Svceable Restr': 2,
  'Standy By': 2,
  Spare: 2,
};

const statusIcons = {
  Template: GreenIcon,
  Preliminary: GreenIcon,
  'Config Struct': GreenIcon,
  Serviceable: GreenIcon,
  'Svceable Restr': OrangeIcon,
  'Standy By': OrangeIcon,
  Spare: OrangeIcon,
  Unserviceable: RedIcon,
  Incident: RedIcon,
  'Scrap Proposal': RedIcon,
  Scrapped: RedIcon,
  Renamed: RedIcon,
};

const sort = (assets) =>
  assets.sort((a, b) => {
    const aStatus = a.get('status');
    const bStatus = b.get('status');
    const aRank = statusRankings[aStatus] || 3;
    const bRank = statusRankings[bStatus] || 3;

    if (aRank < bRank) {
      return -1;
    } else if (aRank > bRank) {
      return 1;
    } else if (aStatus < bStatus) {
      return -1;
    } else if (aStatus > bStatus) {
      return 1;
    } else {
      return 0;
    }
  });

const EquipmentAssetsTable = ({
  equipmentId,
  equipmentType,
  assets,
  ...rest
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 3,
        Header: 'Status',
        accessor: (asset) => asset.get('status'),
        Cell: ({ row }) => {
          const asset = row.original;
          const status = asset.get('status');
          const StatusIcon = statusIcons[status];

          return (
            <Grid container alignItems="center">
              {StatusIcon && <img src={StatusIcon} alt="Status icon" />}
              {status}
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Serial No',
        accessor: (asset) => asset.get('serialNumber'),
      },
      {
        xs: 3,
        Header: 'Warehouse',
        accessor: (asset) => asset.get('warehouse'),
      },
      {
        xs: 3,
        Header: 'Location',
        accessor: (asset) => asset.get('location'),
      },
      {
        xs: 3,
        Header: 'Installation',
        accessor: (asset) => asset.get('installation'),
      },
      {
        xs: 4,
        Header: 'Last transaction',
        Cell: ({ row }) => {
          const asset = row.original;

          const lastTransaction = asset.get('lastTransaction');
          const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          };

          const formattedDateTime = new Date(lastTransaction).toLocaleString(
            'en-GB',
            options,
          );

          return formattedDateTime;
        },
      },
    ],
    [],
  );

  const renderTableRowComponent = ({ item, ...rest }) => {
    return (
      <Link
        to={toEquipmentAssetHistory(
          equipmentId,
          equipmentType,
          item.get('serialNumber'),
        )}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TableRow {...rest} />
      </Link>
    );
  };

  return (
    <Table
      stickyHeader
      columns={columns}
      items={sort(assets)}
      useGlobalFilter={false}
      TableRowComponent={renderTableRowComponent}
      {...rest}
    />
  );
};

export default EquipmentAssetsTable;
