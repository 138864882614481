import PropTypes from 'prop-types';

import { Switch } from '@material-ui/core';

const SwitchFormik = ({ field, form, ...rest }) => {
  const { value, onChange, name } = field;

  return (
    <Switch
      name={name}
      checked={!!value}
      onChange={onChange}
      color="primary"
      {...rest}
    />
  );
};

SwitchFormik.propTypes = {
  format: PropTypes.func,
};

export default SwitchFormik;
