import { NOTIFICATION_VARIANTS } from 'altus-redux-middlewares';

import {
  getWellbore,
  receiveWellboreTrajectory,
} from 'features/wells/wellbore.actions';

import mappers from 'mappers';
import wellboreService from 'services/wellbore.service';
import { WELL_OVERVIEW_ACTIONS } from 'features/wells/overview/wellOverview.constants';

export const uploadWellboreTrajectory =
  (fieldId, wellboreId, file, fileUnitSystem) => (dispatch) => {
    const payload = wellboreService
      .uploadWellboreTrajectory(fieldId, wellboreId, file, {
        unitSystem: fileUnitSystem,
      })
      .then((trajectory) => {
        dispatch(receiveWellboreTrajectory(wellboreId, trajectory));
        return trajectory;
      })
      .then(() => dispatch(getWellbore(fieldId, wellboreId)));

    dispatch({
      payload,
      type: WELL_OVERVIEW_ACTIONS.UPLOAD_WELLBORE_TRAJECTORY,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'The trajectory was successfully uploaded',
      },
    });

    return payload;
  };

export const getTrajectoryPreview =
  (fieldId, wellboreId, trajectoryFile) => (dispatch) => {
    const payload = wellboreService
      .getWellboreTrajectoryPreview(fieldId, wellboreId, trajectoryFile)
      .then(mappers.Trajectory.from);

    dispatch({
      payload,
      type: WELL_OVERVIEW_ACTIONS.GET_WELLBORE_TRAJECTORY_PREVIEW,
    });

    return payload;
  };

export const getTrajectoryFromWellcomApi = (wellboreId) => (dispatch) => {
  const payload = wellboreService.getWellcomTrajectorySurvey(wellboreId);

  dispatch({
    payload,
    type: WELL_OVERVIEW_ACTIONS.GET_WELLCOM_TRAJECTORY_SURVEY,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Getting trajectory from Wellcom API...',
      [NOTIFICATION_VARIANTS.ERROR]: 'Failed to get trajectory details.',
    },
  });

  return payload;
};

export const downloadTrajectoryTemplateTxt = () => (dispatch) => {
  const payload = wellboreService.downloadTrajectoryTemplateTxt();

  dispatch({
    payload,
    type: WELL_OVERVIEW_ACTIONS.DOWNLOAD_TRAJECTORY_TEAMPLTE_TXT,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Downloading .txt template...',
      [NOTIFICATION_VARIANTS.ERROR]: 'Failed to download trajectory template.',
    },
  });

  return payload;
};

export const downloadTrajectoryTemplateCsv = () => (dispatch) => {
  const payload = wellboreService.downloadTrajectoryTemplateCsv();

  dispatch({
    payload,
    type: WELL_OVERVIEW_ACTIONS.DOWNLOAD_TRAJECTORY_TEAMPLTE_CSV,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Downloading .csv template...',
      [NOTIFICATION_VARIANTS.ERROR]: 'Failed to download trajectory template.',
    },
  });

  return payload;
};

export const downloadTrajectorySurveyTxt = (wellboreId) => (dispatch) => {
  const payload = wellboreService.downloadTrajectorySurveyTxt(wellboreId);

  dispatch({
    payload,
    type: WELL_OVERVIEW_ACTIONS.DOWNLOAD_TRAJECTORY_SURVEY_TXT,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Downloading .txt survey...',
      [NOTIFICATION_VARIANTS.ERROR]: 'Failed to download trajectory survey.',
    },
  });

  return payload;
};

export const downloadTrajectorySurveyCsv = (wellboreId) => (dispatch) => {
  const payload = wellboreService.downloadTrajectorySurveyCsv(wellboreId);

  dispatch({
    payload,
    type: WELL_OVERVIEW_ACTIONS.DOWNLOAD_TRAJECTORY_SURVEY_CSV,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Downloading .csv survey...',
      [NOTIFICATION_VARIANTS.ERROR]: 'Failed to download trajectory survey.',
    },
  });

  return payload;
};
