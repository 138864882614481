import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createProjectDashboardSelector,
  createProjectDashboardCurvesSelector,
} from 'features/projects/dashboard/dashboard.selectors';

import {
  requestProjectDashboard,
  updateProjectDashboard,
} from 'features/projects/dashboard/dashboard.actions';

import { EMPTY_LIST } from 'app/app.constants';
import { getActionDataStateFromState } from 'app/app.selectors';
import { DASHBOARDS_ACTIONS } from 'features/projects/dashboard/dashboard.constants';

const useProjectDashboard = (projectId, projectDashboardId) => {
  const dispatch = useDispatch();

  const dispatchRequestProjectDashboard = useCallback(
    () => dispatch(requestProjectDashboard(projectId, projectDashboardId)),
    [dispatch, projectId, projectDashboardId],
  );
  const dispatchUpdateProjectDashboard = useCallback(
    (projectDashboard) =>
      dispatch(
        updateProjectDashboard(projectId, projectDashboardId, projectDashboard),
      ),
    [dispatch, projectId, projectDashboardId],
  );

  const projectDashboardSelector = useMemo(
    () => createProjectDashboardSelector(projectId, projectDashboardId),
    [projectId, projectDashboardId],
  );

  const projectDashboardCurvesSelector = useMemo(
    () => createProjectDashboardCurvesSelector(projectDashboardId),
    [projectDashboardId],
  );

  const dataState = useSelector((state) =>
    getActionDataStateFromState(
      state,
      DASHBOARDS_ACTIONS.REQUEST_PROJECT_DASHBOARD,
    ),
  );

  const curves = useSelector(projectDashboardCurvesSelector);
  const projectDashboard = useSelector(projectDashboardSelector);

  return {
    dataState,
    projectDashboard,
    curves: curves ?? EMPTY_LIST,
    updateProjectDashboard: dispatchUpdateProjectDashboard,
    requestProjectDashboard: dispatchRequestProjectDashboard,
  };
};

export default useProjectDashboard;
