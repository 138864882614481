import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { compose } from 'redux';

const ProceduresAndChecklistsFilter = ({
  classes,
  companyFilters,
  serviceFilters,
  departmentFilters,
  setCompanyId,
  procedureTypes,
  setProcedureTypes,
  departments,
  setDepartments,
  user,
  setAllCompanyFilter,
  setAllDepartmentsFilter,
  setAllServicesFilter,
  services,
  setServices,
}) => {
  const companyFilterHandler = (filter) => {
    setCompanyId(user.get('organizationId'));
    setProcedureTypes((current) => {
      const index = current.indexOf(filter);
      if (index === -1) {
        return [...current, filter];
      } else {
        return [...current.slice(0, index), ...current.slice(index + 1)];
      }
    });
    setServices([]);
  };

  const servicesFilterHandler = (filter) => {
    setServices((current) => {
      const index = current.indexOf(filter);
      if (index === -1) {
        return [...current, filter];
      } else {
        return [...current.slice(0, index), ...current.slice(index + 1)];
      }
    });
    setProcedureTypes([]);
  };

  const departmentFilterHandler = (filter) => {
    setDepartments((current) => {
      const index = current.indexOf(filter);
      if (index === -1) {
        return [...current, filter];
      } else {
        return [...current.slice(0, index), ...current.slice(index + 1)];
      }
    });
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item container className={classes.title}>
        <Typography>Filtering options</Typography>
      </Grid>
      <Grid item xs={12} className={classes.category}>
        <FormControlLabel
          label={<Typography>All (Company)</Typography>}
          control={
            <Checkbox
              color="default"
              onClick={setAllCompanyFilter}
              checked={procedureTypes.length === 3}
            />
          }
        />
      </Grid>
      {companyFilters.map((filter) => {
        return (
          <Grid item xs={12} className={classes.subCategory}>
            <FormControlLabel
              label={<Typography>{filter.name}</Typography>}
              control={
                <Checkbox
                  color="default"
                  value={filter}
                  onClick={() => companyFilterHandler(filter.id)}
                  checked={procedureTypes.includes(filter.id)}
                />
              }
            />
          </Grid>
        );
      })}
      <Grid item xs={12} className={classes.category}>
        <FormControlLabel
          label={<Typography>All (By services)</Typography>}
          control={
            <Checkbox
              color="default"
              onClick={setAllServicesFilter}
              checked={services.length === 3}
            />
          }
        />
      </Grid>
      {serviceFilters.map((filter) => {
        return (
          <Grid item xs={12} className={classes.subCategory}>
            <FormControlLabel
              label={<Typography>{filter.name}</Typography>}
              control={
                <Checkbox
                  color="default"
                  value={filter.name}
                  onClick={() => servicesFilterHandler(filter.id)}
                  checked={services.includes(filter.id)}
                />
              }
            />
          </Grid>
        );
      })}
      <Grid item container className={classes.title}>
        <Typography>Filter by departments</Typography>
      </Grid>
      <Grid item xs={12} className={classes.category}>
        <FormControlLabel
          label={<Typography>All</Typography>}
          control={
            <Checkbox
              color="default"
              onClick={setAllDepartmentsFilter}
              checked={departments.length === 4}
            />
          }
        />
      </Grid>
      {departmentFilters.map((filter) => {
        return (
          <Grid item xs={12} className={classes.subCategory}>
            <FormControlLabel
              label={<Typography>{filter.name}</Typography>}
              control={
                <Checkbox
                  color="default"
                  value={filter}
                  onClick={() => departmentFilterHandler(filter.id)}
                  checked={departments.includes(filter.id)}
                />
              }
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const styles = (theme) => ({
  title: {
    backgroundColor: theme.palette.table.row.main,
    height: '70px',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  container: {
    gap: '2px',
  },
  category: {
    backgroundColor: theme.palette.table.row.main,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  subCategory: {
    backgroundColor: theme.palette.table.row.main,
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
});

export default compose(withStyles(styles))(ProceduresAndChecklistsFilter);
