import { memo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { EMPTY_MAP } from 'app/app.constants';
import useWellbore from 'features/wells/hooks/useWellbore';
import useWellboreTrajectory from 'features/wells/hooks/useWellboreTrajectory';
import WellboreTrajectory from 'app/components/WellboreTrajectory/WellboreTrajectory';

const WellboreTrajectoryContainer = ({
  fieldId,
  wellboreId,
  children = null,
  ...rest
}) => {
  const { wellboreDetail = EMPTY_MAP } = useWellbore(wellboreId);

  const trajectory = useWellboreTrajectory(fieldId, wellboreId);

  if (!trajectory.get('trajectoryPoints')?.size) return children;

  return (
    <WellboreTrajectory
      trajectory={trajectory}
      wellboreDetail={wellboreDetail}
      {...rest}
    />
  );
};

WellboreTrajectoryContainer.propTypes = {
  children: PropTypes.node,
  fieldId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wellboreId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default compose(memo)(WellboreTrajectoryContainer);
