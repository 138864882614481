import { createSelector } from 'utils/app.util';

export const getCurrentProjectWellboreFromState = createSelector(
  (state) => state.getIn(['projects', 'wellbore']),
  (projectWellbore) => projectWellbore,
);

export const getCurrentProjectWellboreTrajectoryFromState = createSelector(
  (state) => state.getIn(['projects', 'wellbore', 'trajectory']),
  (projectWellboreTrajectory) => projectWellboreTrajectory,
);
