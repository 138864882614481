import { call, put, select, takeEvery } from 'redux-saga/effects';
import activitiesService from 'services/activities.service';
import {
  getProjectStatus,
  Identity,
  sendProjectChangedNotification,
  toggleModal,
} from './common.sagas';
import {
  ACTIVITIES_ACTIONS as ACTIONS,
  ACTIVITIES_ACTIONS,
  CREATE_ACTIVITY_MODAL_ID,
  CREATE_ACTIVITY_MODE,
} from '../activities.constants';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import { callAsync } from 'app/sagas/helperSagas';

function* callCreateActivityService(
  actionType,
  sendNotificationFn,
  projectId,
  taskId,
  values,
  connection,
) {
  const payload = yield call(
    activitiesService.createActivity,
    projectId,
    taskId,
    values,
  );
  const status = yield call(getProjectStatus, projectId);
  yield call(sendNotificationFn, connection, projectId, taskId);
  yield put({
    taskId,
    payload: status,
    projectId,
    skipTracking: true,
    type: actionType,
  });
  return payload;
}

function* createActivity(action, sendNotificationFn) {
  const { taskId, connection, values } = action;
  const { id: projectId } = (yield select(getCurrentProject)).toJS();

  switch (values.get('mode')) {
    case CREATE_ACTIVITY_MODE.QUICK:
      yield call(
        callCreateActivityService,
        ACTIVITIES_ACTIONS.QUICK_ADD_ACTIVITY,
        sendNotificationFn,
        projectId,
        taskId,
        values.toJS(),
        connection,
      );
      break;

    default:
      yield call(
        callCreateActivityService,
        ACTIVITIES_ACTIONS.ADD_ACTIVITY_DEFAULT,
        sendNotificationFn,
        projectId,
        taskId,
        values.toJS(),
        connection,
      );
      break;
  }
  yield put({
    type: ACTIONS.PROJECT_ACTIVITIES_ADD_ACTIVITY,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'A new activity was successfully added',
    },
  });
}

function* duplicateActivity(action, sendNotificationFn) {
  const { taskId, connection, values } = action;
  const { id: projectId } = (yield select(getCurrentProject)).toJS();

  yield call(
    callCreateActivityService,
    ACTIVITIES_ACTIONS.QUICK_ADD_ACTIVITY,
    sendNotificationFn,
    projectId,
    taskId,
    values.toJS(),
    connection,
  );
}

function* createActivityWithNotification(action) {
  yield call(createActivity, action, sendProjectChangedNotification);
}

function* duplicateActivityWithNotification(action) {
  yield call(duplicateActivity, action, sendProjectChangedNotification);
}

function* createActivityWithoutNotification(action) {
  yield call(createActivity, action, Identity);
}

function* toggleActivityCreatedDialog() {
  yield call(toggleModal, { modalId: CREATE_ACTIVITY_MODAL_ID });
}

export function* rootCreateActivity() {
  yield takeEvery(
    ACTIONS.INITIATE_CREATE_ACTIVITY_WITH_NOTIFICATION,
    callAsync,
    createActivityWithNotification,
  );
  yield takeEvery(
    ACTIONS.INITIATE_CREATE_ACTIVITY_WITHOUT_NOTIFICATION,
    callAsync,
    createActivityWithoutNotification,
  );
  yield takeEvery(
    ACTIONS.PROJECT_ACTIVITIES_ADD_ACTIVITY,
    callAsync,
    toggleActivityCreatedDialog,
  );
  yield takeEvery(
    ACTIONS.PROJECT_ACTIVITIES_DUPLICATE_ACTIVITY,
    callAsync,
    duplicateActivityWithNotification,
  );
}
