import { Grid, Typography } from '@material-ui/core';

const TemperatureGradientField = ({ value, unit }) => (
  <Typography variant="body2">{`${value.toFixed(5)} ${unit}`}</Typography>
);

const SimulationTemperatureGradient = ({ simulation }) => {
  const temperatureGradient = simulation.get('temperatureGradient');

  if (!temperatureGradient) return null;

  const degreesUnit = simulation.getIn(['surfaceTemperature', 'unit']);
  const lengthUnit = simulation.getIn(['wellboreVerticalDepth', 'unit']);

  return (
    <Grid container item spacing={1}>
      <Grid item>
        <Typography variant="body2">
          <b>Temperature Gradient:</b>
        </Typography>
      </Grid>
      <Grid container item xs direction="column">
        <Grid item>
          <TemperatureGradientField
            value={temperatureGradient}
            unit={`${degreesUnit}/${lengthUnit}`}
          />
        </Grid>
        <Grid item>
          <TemperatureGradientField
            value={temperatureGradient * 100}
            unit={`${degreesUnit}/100${lengthUnit}`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SimulationTemperatureGradient;
