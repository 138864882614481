import { compose } from 'redux';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import SituationsModal from 'features/wellControlDrillActivity/SituationsModal';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { WELL_CONTROL_ACTIONS } from 'features/wellControlDrillActivity/wellControl.constants';
import { loadCategoriesAndSituations } from 'features/wellControlDrillActivity/wellControl.actions';
import { getWellControlCategoriesFromState } from 'features/wellControlDrillActivity/wellControl.selectors';
import { useCallback } from 'react';

const SituationsModalContainer = ({
  form,
  field,
  isOpen,
  dataState,
  toggleModal,
  dispatchOnLoad,
}) => {

  const onSearch = useCallback((txtSearch) => {
    dispatchOnLoad(txtSearch);
  }, [dispatchOnLoad]);
  
  useEffect(() => {
    onSearch('');
  }, [dispatchOnLoad, onSearch]);

  const categories = useSelector(getWellControlCategoriesFromState);

  return (
    <SituationsModal
      form={form}
      field={field}
      isOpen={isOpen}
      dataState={dataState}
      categories={categories}
      toggleModal={toggleModal}
      onSearch = {onSearch}
    />
  );
};

export default compose(
  connect(
    (state) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        WELL_CONTROL_ACTIONS.GET_ALL_CATEGORIES_AND_SITUATIONS,
      ),
    }),
    {
      dispatchOnLoad: loadCategoriesAndSituations,
    },
  ),
)(SituationsModalContainer);
