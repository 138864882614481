import { fromJS, Map, List } from 'immutable';

import mappers from 'mappers';
import { EMPTY_LIST } from 'app/app.constants';
import { WELL_PROJECT_HISTORY_PAGE_LOADED } from 'features/wells/projects/wellProjects.constants';

const initialState = fromJS({
  data: Map({
    projects: EMPTY_LIST,
  }),
});

const wellProjectsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case WELL_PROJECT_HISTORY_PAGE_LOADED: {
      if (action.error) return state;

      const [projects] = action.payload;

      return state.setIn(
        ['data', 'projects'],
        List(projects).map(mappers.Project.from),
      );
    }
    default:
      return state;
  }
};

export default wellProjectsReducer;
