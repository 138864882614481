import { compose } from 'redux';
import { memo, useState, useMemo, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { MenuItem } from '@material-ui/core';
import Icon from '@material-ui/icons/Build';
import Check from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import EditIcon from '@material-ui/icons/Edit';
import { useModal } from 'altus-modal';
import { List } from 'immutable';

import { DropdownMenu } from 'altus-ui-components';

import { addNotification } from 'altus-redux-middlewares';
import { Box, Grid, Divider, Typography, IconButton } from '@material-ui/core';

import { LoadingOverlay, NoContentBasePage } from 'altus-ui-components';

import {
  getToolstringFromState,
  getToolstringToolsFromState,
  getToolstringSectionsFromState,
  getTaskByIdFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';

import {
  receiveTaskToolstring,
  requestGetTaskToolstring,
  requestTaskToolstringSections,
  requestUpdateSerialNumbersFromTask,
  requestUpdateNotPlannedToolstringItem,
} from 'features/projects/tasks/task/toolstring/toolstring.actions';

import {
  TOOLSTRING_ACTIONS,
  TOOLSTRING_OPTION_ACTIONS,
  MODAL as ToolstringModal,
} from 'features/projects/tasks/task/toolstring/toolstring.constants';

import { TASK_ACTIONS } from 'features/projects/tasks/tasks.constants';

import {
  EMPTY_MAP,
  EMPTY_LIST,
  NOTIFICATION_VARIANTS,
  WORK_ITEM_STATUS,
} from 'app/app.constants';
import toolstringService from 'services/toolstring.service';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { getToolConnectorsFromState } from 'features/projects/tool/tool.selector';
import { getAllTasksFromState } from 'features/projects/tasks/tasks.selectors';
import { getAllTasksWithProjects } from 'features/projects/activities/activities.actions';

import TaskToolstringEditorContent from 'features/projects/tasks/task/toolstring/components/edit/TaskToolstringEditorContent';
import CreateAssetHistoryModalContainer from 'features/equipment/assets/components/CreateAssetHistoryModalContainer';
import {
  ASSET_HISTORY_EVENT_TYPES,
  ASSET_HISTORY_EVENT_TYPES_ITEM,
  ASSET_HISTORY_TEST_TYPE,
  ASSET_HISTORY_TEST_TYPES,
  EquipmentType,
  MODAL,
} from 'features/equipment/equipment.constants';
import { validateToolStringSerialNumbers } from 'features/equipment/equipment.actions';
import TaskToolstringExportModalContainer from 'features/projects/tasks/task/toolstring/TaskToolstringExportModalContainer';
import projectMobilisationService from 'features/projects/mobilisation/projectMobilisation.service';
import { requestUpdateToolstringItemCopy } from 'features/projects/mobilisation/projectMobilisation.actions';
import GotoBhaModalModalContainer from 'features/projects/tasks/task/toolstring/GotoBhaEditModalContainer';

const ExecutionToolStringContainer = ({
  taskId,
  classes,
  projectId,
  dataState,
  toolstringId,
  toolstringTools,
  toolstringSections,
  checkSerialNumbers,
  mobilisation = false,
  setSerialNumbersDone,
  externalHiddenColumns,
  dispatchGetToolstring,
  toolstring = EMPTY_MAP,
  setSelectedToolstringId,
  toolConnectors = EMPTY_MAP,
  dispatchToolstringSections,
  hideExecutionOptions = false,
  dispatchUpdateToolstringItem,
  selectedStringTestReport,
  hideDragHandle = false,
  disabledSerialNumbers = false,
  tasks = EMPTY_LIST,
  projectTasks = EMPTY_LIST,
  dispatchUpdateSerialNumbersFromTask,
  dispatchUpdateToolstringItemCopy,
}) => {
  const dispatch = useDispatch();
  const hiddenColumns = useMemo(
    () => [
      'yieldValue',
      'botC',
      'topC',
      'supplier',
      'hideAssemblySerialNumber',
    ],
    [],
  );
  const hideSerialNo = 'hideSerialNumbers';
  const [isOpen, toggleModal] = useModal(MODAL.ADD_ASSET_HISTORY_BHA);

  const currentToolStringId = useCallback(() => {
    if (selectedStringTestReport.get('taskId') === taskId) {
      return selectedStringTestReport.get('toolstringId');
    }

    var currentTask = tasks.find((task) => task.get('taskId') === taskId);
    const notPlannedToolString = currentTask
      ?.get('toolStrings')
      ?.filter((x) => x?.isPlanned)
      .pop();

    return (
      notPlannedToolString?.toolStringId ??
      selectedStringTestReport.get('toolstringId')
    );
  }, [selectedStringTestReport, taskId, tasks]);

  const [isToolstringExportModalOpen, toggleToolstringExportModal] = useModal(
    ToolstringModal.SIMULATION_EXPORT_MODAL_ID,
  );

  const [isRouteBhaEditModalOpen, toggleIsRouteBhaEditModalOpen] = useModal(
      MODAL.ROUTE_BHA_EDITOR,
  );

  const testTypesItems = List([
    ASSET_HISTORY_TEST_TYPES.find(
      (item) => item.id === String(ASSET_HISTORY_TEST_TYPE.STRING_TEST),
    ),
  ]).filter((item) => item);

  const assetHistoryEventTypes = List([
    ASSET_HISTORY_EVENT_TYPES.find(
      (item) => item.id === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST),
    ),
  ]).filter((item) => item);

  const openModal = (event) => {
    event?.preventDefault();
    toggleModal();
  };

  const showCustomActions = [TOOLSTRING_OPTION_ACTIONS.ASSET_HISTORY];
  const columns = 1;
  const paddingTopPreview = 0;
  const widthSerialColumn = 2;
  const [showEditor, setShowEditor] = useState(false);
  const [toolStringId, setToolStringId] = useState();
  const [selectLabel, setSelectLabel] = useState('Auto-fill Serial Numbers');

  toolstringId = toolStringId;
  toolConnectors = useSelector((state) => getToolConnectorsFromState(state));
  toolstring = useSelector((state) =>
    getToolstringFromState(state, toolStringId),
  );
  toolstringSections = useSelector((state) =>
    getToolstringSectionsFromState(state, toolStringId),
  );
  toolstringTools = useSelector((state) =>
    getToolstringToolsFromState(state, toolStringId),
  );
  const task = useSelector((state) => getTaskByIdFromState(state, taskId));

  const getPlannedToolstring = useCallback(
    () =>
      toolstringService
        .getPlannedToolstring(projectId, taskId, mobilisation)
        .then((toolstring) => {
          setToolStringId(toolstring?.toolStringId);
          if (mobilisation) setSelectedToolstringId(toolstring?.toolStringId);
          dispatch(receiveTaskToolstring(projectId, taskId, toolstring));
          dispatchToolstringSections(
            projectId,
            taskId,
            toolstring?.toolStringId,
          );
          dispatchGetToolstring(
            projectId,
            taskId,
            toolstring?.toolStringId,
            mobilisation,
          );
          setShowEditor(true);
        })
        .catch(() => {
          setShowEditor(false);
        }),
    [
      dispatch,
      dispatchGetToolstring,
      dispatchToolstringSections,
      projectId,
      taskId,
      mobilisation,
      setSelectedToolstringId,
    ],
  );

  useEffect(() => {
    if (mobilisation) {
      const check = checkSerialNumbers(toolstringTools.toJS());
      setSerialNumbersDone(check);
    }
  }, [mobilisation, toolstringTools, checkSerialNumbers, setSerialNumbersDone]);

  const getMobilisationToolstring = useCallback(
    () =>
      projectMobilisationService
        .getProjectMobilisationToolstringForTask(projectId, taskId)
        .then((toolstring) => {
          setToolStringId(toolstring?.toolStringId);
          setSelectedToolstringId(toolstring?.toolStringId);
          dispatch(receiveTaskToolstring(projectId, taskId, toolstring));
          dispatchToolstringSections(
            projectId,
            taskId,
            toolstring?.toolStringId,
          );
          dispatchGetToolstring(projectId, taskId, toolstring?.toolStringId);
          setShowEditor(true);
        })
        .catch(() => {
          setShowEditor(false);
        }),
    [
      dispatch,
      dispatchGetToolstring,
      dispatchToolstringSections,
      projectId,
      taskId,
      setSelectedToolstringId,
    ],
  );

  const getStringReportToolstring = useCallback(
    () =>
      toolstringService
        .getToolString(projectId, taskId, currentToolStringId())
        .then((toolstring) => {
          setToolStringId(toolstring?.toolStringId);
          setSelectedToolstringId(toolstring?.toolStringId);
          dispatch(receiveTaskToolstring(projectId, taskId, toolstring));
          dispatchToolstringSections(
            projectId,
            taskId,
            toolstring?.toolStringId,
          );
          dispatchGetToolstring(
            projectId,
            taskId,
            toolstring?.toolStringId,
            mobilisation,
          );
          setShowEditor(true);
        })
        .catch(() => {
          setShowEditor(false);
        }),
    [
      dispatch,
      mobilisation,
      dispatchGetToolstring,
      dispatchToolstringSections,
      projectId,
      taskId,
      setSelectedToolstringId,
      currentToolStringId,
    ],
  );

  const validateToolstringSerialNumbers = () => {
    dispatch(validateToolStringSerialNumbers(toolstringId));
  };

  useEffect(() => {
    if (mobilisation) {
      if (selectedStringTestReport) {
        getStringReportToolstring();
      } else {
        getPlannedToolstring(projectId, taskId);
      }
    } else getPlannedToolstring(projectId, taskId);
  }, [
    getPlannedToolstring,
    projectId,
    taskId,
    getMobilisationToolstring,
    mobilisation,
    getStringReportToolstring,
    selectedStringTestReport,
  ]);

  const updateItemProperties = useCallback(
    (toolstringItem) => {
      dispatchUpdateToolstringItem(
        projectId,
        taskId,
        toolstringId,
        toolstringItem,
      );
    },
    [projectId, taskId, toolstringId, dispatchUpdateToolstringItem],
  );

  const updateToolstringItemCopy = useCallback(
    (asset) => {
      dispatchUpdateToolstringItemCopy(taskId, toolstringId, projectId, asset);
    },
    [taskId, toolstringId, dispatchUpdateToolstringItemCopy, projectId],
  );

  useEffect(() => {
    const taskStatus = task?.get('status');
    if (taskStatus && taskStatus >= WORK_ITEM_STATUS.COMPLETED) {
      !hiddenColumns.includes(hideSerialNo) && hiddenColumns.push(hideSerialNo);
    } else {
      hiddenColumns.includes(hideSerialNo) &&
        hiddenColumns.splice(hiddenColumns.indexOf(hideSerialNo), 1);
    }
  }, [hiddenColumns, taskId, task, projectTasks, toolstringTools]);

  return (
    <>
      <CreateAssetHistoryModalContainer
        toggleModal={openModal}
        isOpen={isOpen}
        equipmentType={EquipmentType.BHA}
        getAssetAfterRefresh={false}
        assetHistoryEventTypes={assetHistoryEventTypes}
        testTypesItems={testTypesItems}
        toolstringId={toolstringId}
        taskId={taskId}
        defaultEventValue={String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST)}
        defaultTestTypeValue={String(ASSET_HISTORY_TEST_TYPE.STRING_TEST)}
        modalTitle="String Verification"
      />
      {!toolstringTools || !showEditor ? (
        <NoContentBasePage
          Icon={Icon}
          header="Tool String"
          description="No tool string has been planned for this task."
        />
      ) : (
        <>
          <Grid item xs={11}>
            <Box mt={2} ml={5} display="flex" alignItems="center">
              <Box display="flex" alignItems="center">
                {!hideExecutionOptions ? (
                  <Box mr={2}>
                    <Check className={classes.workItemCompleted} />
                  </Box>
                ) : null}
                <Typography
                  noWrap
                  variant="h6"
                  align="left"
                  color="textPrimary"
                >
                  {toolstring?.get('name')}
                </Typography>
              </Box>
              {/* className="editor-label" */}
              {!hideExecutionOptions ? (
                <Box display="flex" ml="auto">
                  <IconButton
                    title="Goto BHA editor"
                    onClick={toggleIsRouteBhaEditModalOpen}
                  >
                    <EditIcon />
                  </IconButton>
                  {projectTasks.size > 0 ? (
                    <DropdownMenu
                      Trigger={
                        <label style={{ marginTop: `20px` }}>
                          {selectLabel}
                        </label>
                      }
                    >
                      {projectTasks
                        .filter(
                          (task) =>
                            task.get('id').toString() !== taskId.toString(),
                        )
                        .map((fromTask) => (
                          <MenuItem
                            key={fromTask.get('id')}
                            title={fromTask.get('title')}
                            onClick={() => {
                              setSelectLabel(fromTask.get('title'));
                              dispatchUpdateSerialNumbersFromTask(
                                projectId,
                                fromTask.get('id'),
                                taskId,
                                toolstringId,
                                fromTask.get('title'),
                              );
                            }}
                          >
                            {fromTask.get('title')}
                          </MenuItem>
                        ))}
                    </DropdownMenu>
                  ) : (
                    ''
                  )}
                  <IconButton
                    title="Add String Verification"
                    onClick={() => validateToolstringSerialNumbers()}
                  >
                    <PlaylistPlayIcon />
                  </IconButton>

                  <IconButton
                    title="Export"
                    onClick={toggleToolstringExportModal}
                  >
                    <PictureAsPdf />
                  </IconButton>
                </Box>
              ) : null}
            </Box>
          </Grid>
          <TaskToolstringEditorContent
            hideDragHandle={hideDragHandle}
            className={classes.toolstringPreview}
            taskId={taskId}
            style={{ flexWrap: 'wrap' }}
            projectId={projectId}
            toolstring={toolstring}
            toolstringId={toolstringId}
            toolConnectors={toolConnectors}
            toolstringTools={toolstringTools}
            toolstringSections={toolstringSections}
            updateItemProperties={
              mobilisation ? updateToolstringItemCopy : updateItemProperties
            }
            showHeader={false}
            hiddenColumns={
              externalHiddenColumns ? externalHiddenColumns : hiddenColumns
            }
            hideBreakPoints={true}
            height="90%"
            columns={columns}
            paddingTopPreview={paddingTopPreview}
            widthSerialColumn={widthSerialColumn}
            showBreakPoint={false}
            showCustomActions={showCustomActions}
            disabledSerialNumbers={disabledSerialNumbers}
            disableToolStatus={true}
          />
          <Divider variant="middle" />
          <LoadingOverlay timeout={0} dataState={dataState} />
          <TaskToolstringExportModalContainer
            taskId={taskId}
            projectId={projectId}
            toolstringId={toolstringId}
            open={isToolstringExportModalOpen}
            toggleModal={toggleToolstringExportModal}
          />
          <GotoBhaModalModalContainer
            taskId={taskId}
            projectId={projectId}
            toolstringId={toolstringId}
            toggleModal={toggleIsRouteBhaEditModalOpen}
            open={isRouteBhaEditModalOpen}
          />
        </>
      )}
    </>
  );
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  toolstringContainer: {
    height: `calc(10% - ${theme.spacing(12)}px)`,
  },
  toolstringPreview: {
    height: '10%',
  },
  workItemCompleted: {
    color: theme.altus.status.execute,
  },
});

const mapStateToProps = (state) => {
  return {
    projectTasks: getAllTasksFromState(state),
    dataState: getSummarizedDataStateFromState(
      state,
      TOOLSTRING_ACTIONS.REQUEST_GET_TOOLSTRING_FOR_TASK,
      TOOLSTRING_ACTIONS.REQUEST_TOOLSTRING_SECTIONS,
      TOOLSTRING_ACTIONS.REQUEST_SORT_TOOLSTRING_TOOLS,
      TOOLSTRING_ACTIONS.DOWNLOAD_TOOLSTRING_PDF_REPORT,
      TOOLSTRING_ACTIONS.REQUEST_UPDATE_SERIAL_NUMBERS_FROM_TASK,
      TASK_ACTIONS.GET_ALL_TASKS,
    ),
  };
};

const getALlTAsks = (projectId) => getAllTasksWithProjects(projectId);

const mapDispatchToProps = {
  dispatchGetToolstring: requestGetTaskToolstring,
  dispatchToolstringSections: requestTaskToolstringSections,
  dispatchUpdateToolstringItem: requestUpdateNotPlannedToolstringItem,
  dispatchUpdateToolstringItemCopy: requestUpdateToolstringItemCopy,
  dispatchGetTasks: getALlTAsks,
  dispatchUpdateSerialNumbersFromTask: requestUpdateSerialNumbersFromTask,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  memo,
)(ExecutionToolStringContainer);
