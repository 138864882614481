import { Grid, Typography } from '@material-ui/core';

import { Alert } from 'altus-ui-components';
import { ProjectWellboreDetailStatus } from 'app/app.constants';
import { makeStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  root: {
    background: orange[600],
    padding: '0',
  },
  [`root-${ProjectWellboreDetailStatus.DRAFT}`]: {
    background: orange[600],
    height: '3vh',
    padding: '0',
  },
  boldText: {
    fontWeight: 'bold',
  },
}));

export const ArchiveAlert = ({ isArchive = false }) => {
  const classes = useStyles();

  if (!isArchive) {
    return null;
  }

  return (
    <Grid xs={12}>
      <Alert
        Icon={null}
        type={null}
        classes={{
          root: classes[`root-${ProjectWellboreDetailStatus.DRAFT}`],
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs />
          <Grid item>
            <Typography className={classes.boldText}>
              Project Archived
            </Typography>
          </Grid>
          <Grid container xs item justifyContent="flex-end">
            <Grid item>
              <Grid container spacing={1}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Alert>
    </Grid>
  );
};

export default ArchiveAlert;
