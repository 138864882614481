import { OrderedMap } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import { ACTIONS } from 'features/wells/sections/wellboreSection.constants';
import wellboreSectionMappers from 'features/wells/sections/wellboreSection.mappers';

const initialState = EMPTY_MAP;

const wellboreSectionReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.DELETE_WELLBORE_SECTION: {
      if (error) return state;

      const { wellboreSectionId } = action;

      return state.delete(wellboreSectionId.toString());
    }

    case ACTIONS.RECEIVE_WELLBORE_SECTION: {
      if (error) return state;

      return state.set(
        payload.wellboreSectionId.toString(),
        wellboreSectionMappers.WellboreSection.from(payload),
      );
    }

    case ACTIONS.RECEIVE_WELLBORE_SECTIONS: {
      if (error) return state;

      return OrderedMap(
        payload.map((wellboreSection) => [
          wellboreSection.wellboreSectionId.toString(),
          wellboreSectionMappers.WellboreSection.from(wellboreSection),
        ]),
      );
    }

    case ACTIONS.RECEIVE_WELLBORE_SECTION_NIPPLES: {
      if (error) return state;

      const { wellboreSectionId, wellboreSectionNipples } = payload;
      return state.setIn(
        [wellboreSectionId.toString(), 'wellboreSectionNipples'],
        wellboreSectionMappers.WellboreSectionNipples.from(
          wellboreSectionNipples,
        ),
      );
    }

    case ACTIONS.DELETE_WELLBORE_SECTION_NIPPLE: {
      if (error) return state;

      const { wellboreSectionId, wellboreSectionNippleId } = action;

      return state.deleteIn([
        wellboreSectionId.toString(),
        'wellboreSectionNipples',
        wellboreSectionNippleId.toString(),
      ]);
    }

    default:
      return state;
  }
};

export default wellboreSectionReducer;
