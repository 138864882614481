import { compose } from 'redux';
import { useMemo, useCallback } from 'react';
import { sortableElement } from 'react-sortable-hoc';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Chip, Typography } from '@material-ui/core';

import {
  TableRow,
  DragHandle,
  SortableTable,
  TableRowActionsCell,
} from 'altus-ui-components';

import { EMPTY_LIST, ProjectPermission } from 'app/app.constants';

import EditorStateViewer from 'app/components/EditorStateViewer';
import HasProjectPermission from 'app/components/HasProjectPermission';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import { WORK_ITEM_STATUS as WorkItemStatusType } from 'app/app.constants';
import { TOOL_STATUS } from 'features/projects/tasks/task/toolstring/toolstring.constants';
import ActivityCommentModal from 'features/projects/tasks/task/activities/ActivityCommentModal';
import { formatToolstringToolStatusString } from 'features/projects/tasks/task/toolstring/toolstring.util';

const SortableTableRow = sortableElement(TableRow);

const TaskActivitiesTable = ({
  onSortEnd,
  onRowClick,
  displayComments,
  activities = EMPTY_LIST,
  classes,
  ...rest
}) => {
  const initialState = useMemo(
    () => ({
      hiddenColumns: displayComments ? [] : ['comments'],
    }),
    [displayComments],
  );

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const status = row.original.get('status');

          if (onSortEnd && status === WorkItemStatusType.PLANNED) {
            return (
              <TableRowActionsCell>
                <HasProjectPermission
                  permissions={ProjectPermission.EDIT_ACTIVITIES}
                >
                  <DragHandle fontSize="small" />
                </HasProjectPermission>
              </TableRowActionsCell>
            );
          }

          return (
            <TableRowActionsCell>
              <WorkItemStatus status={status} />
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 3,
        Header: 'Activity',
        Cell: ({ row }) => {
          const activity = row.original;
          const itemStatus = activity.get('setOrRetrieveToolstringItemStatus');
          return (
            <Grid container spacing={2} className={classes.name}>
              <Grid item>
                <Typography>{activity.get('name')}</Typography>
              </Grid>
              {itemStatus !== TOOL_STATUS.NOT_ASSIGNED &&
              itemStatus !== TOOL_STATUS.NULL_ASSIGNED ? (
                <Grid item>
                  <Chip
                    label={formatToolstringToolStatusString(
                      activity.get('setOrRetrieveToolstringItemStatus'),
                    )}
                  />
                </Grid>
              ) : null}
            </Grid>
          );
        },
      },
      {
        xs: 6,
        Header: 'Description',
        Cell: ({ row }) => {
          const activity = row.original;
          if (!activity.get('description')) return null;
          return (
            <EditorStateViewer editorState={activity.get('description')} />
          );
        },
      },
      {
        xs: 4,
        Header: 'Parallel Activity / Information',
        Cell: ({ row }) => {
          const activity = row.original;
          if (!activity.get('parallelActivity')) return null;
          return (
            <EditorStateViewer editorState={activity.get('parallelActivity')} />
          );
        },
      },
      {
        xs: 2,
        Header: 'Estimated Duration',
        accessor: (activity) => activity.get('estimatedTime'),
      },
      {
        id: 'comments',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => (
          <TableRowActionsCell minItems={1}>
            <ActivityCommentModal activity={row.original} />
          </TableRowActionsCell>
        ),
      },
    ],
    [onSortEnd, classes.name],
  );

  const renderTableRowComponent = useCallback(
    ({ item, ...rest }) => {
      const isPlanned = item.get('status') === WorkItemStatusType.PLANNED;

      const TableRowComponent = isPlanned ? SortableTableRow : TableRow;

      return (
        <TableRowComponent
          {...rest}
          item={item}
          onRowClick={onRowClick}
          classes={{
            root: classes.tableRowRoot,
          }}
        />
      );
    },
    [onRowClick, classes.tableRowRoot],
  );

  return (
    <SortableTable
      useDragHandle
      columns={columns}
      items={activities}
      onItemSorted={onSortEnd}
      initialState={initialState}
      TableRowComponent={renderTableRowComponent}
      {...rest}
    />
  );
};

const styles = () => ({
  tableRowRoot: {
    height: 'fit-content',
    maxHeight: 'fit-content',
  },
  name: {
    flexDirection: 'column',
    padding: '5px',
  },
});

// export default TaskActivitiesTable;
export default compose(withStyles(styles))(TaskActivitiesTable);
