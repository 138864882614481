import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import Speed from '@material-ui/icons/Speed';
import Depth from '@material-ui/icons/Height';
import Tension from '@material-ui/icons/CompareArrows';
import { useTheme } from '@material-ui/core/styles';

const ProjectStatusOperationValues = ({ classes, operation }) => {
  const theme = useTheme();
  return (
    <Grid item container className={classes.operationContainer}>
      <Grid item xs={3} className={classes.row}>
        <Grid item xs={3}>
          <Speed style={{ color: theme.mobile.Icons.operationStatus.speed }} />
        </Grid>
        <Grid item xs={6} className={classes.operationValues}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Speed
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {operation.speed.value
                ? `${operation.speed.roundedValue} ${operation.speed.unit}`
                : 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} className={classes.row}>
        <Grid item xs={3}>
          <Depth style={{ color: theme.mobile.Icons.operationStatus.depth }} />
        </Grid>
        <Grid item xs={6} className={classes.operationValues}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Depth
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {operation.depth.value
                ? `${operation.depth.roundedValue} ${operation.depth.unit}`
                : 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} className={classes.row}>
        <Grid item xs={3}>
          <Tension
            style={{ color: theme.mobile.Icons.operationStatus.tension }}
          />
        </Grid>
        <Grid item xs={6} className={classes.operationValues}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Tension
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {operation.tension.value
                ? `${operation.tension.roundedValue} ${operation.tension.unit}`
                : 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: theme.spacing(1),
  },
  operationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingBottom: theme.spacing(2),
    opacity: '100%',
    zIndex: 3,
  },
  operationValues: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default withStyles(styles)(ProjectStatusOperationValues);
