import { compose } from 'redux';
import { Box, Grid } from '@material-ui/core';
import { useSelector, connect } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import { useModal } from 'altus-modal';
import { EMPTY_LIST, EMPTY_STRING } from 'app/app.constants';
import { getCurrentClientOrganizationIdFromState } from 'app/app.selectors';

import {
  getToolsFromState,
  getToolGroupsFromState,
  getToolSuppliersFromState,
} from 'features/equipment/equipment.selectors';

import {
  onLoadWithoutToolstrings as onLoad,
  onUnload,
  searchTools,
} from 'features/equipment/equipment.actions';

import {
  MODAL,
  EQIPMENT_CATEGORY_IDS,
} from 'features/equipment/equipment.constants';

import AddToolModalContainer from 'features/equipment/components/AddToolModalContainer';
import { Filters } from 'features/equipment/thirdPartyTools/components/ThirdPartyToolsFilter';
import ThirdPartyToolsTable from 'features/equipment/thirdPartyTools/components/ThirdPartyToolsTable';
import ThirdPartyToolsFilter from 'features/equipment/thirdPartyTools/components/ThirdPartyToolsFilter';

const defaultFormValues = {
  [Filters.TOOL_GROUPS]: EMPTY_LIST,
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
  [Filters.SUPPLIER_SEARCH]: EMPTY_STRING,
};

const ThirdPartyToolsContainer = ({
  classes,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchOnSearch,
}) => {
  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromState,
  );

  useEffect(() => {
    dispatchOnLoad(EQIPMENT_CATEGORY_IDS.THIRD_PARTY_TOOLS);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload]);

  const initialFilterValues = useMemo(
    () => ({
      ...defaultFormValues,
      organizationId: currentClientOrganizationId,
    }),
    [currentClientOrganizationId],
  );

  const [addToolModal, toggleAddToolModal] = useModal(MODAL.ADD_TOOL);

  const tools = useSelector(getToolsFromState);
  const toolGroups = useSelector(getToolGroupsFromState);
  const toolSuppliers = useSelector(getToolSuppliersFromState);

  const handleSearch = useCallback(
    (filterValues) => {
      dispatchOnSearch({
        [Filters.TEXT_SEARCH]: filterValues[Filters.TEXT_SEARCH],
        categoryId: EQIPMENT_CATEGORY_IDS.THIRD_PARTY_TOOLS,
        [Filters.TOOL_GROUPS]:
          filterValues[Filters.TOOL_GROUPS] === '' ||
          filterValues[Filters.TOOL_GROUPS] === EMPTY_LIST
            ? null
            : [filterValues[Filters.TOOL_GROUPS]],
        [Filters.SUPPLIER_SEARCH]: filterValues[Filters.SUPPLIER_SEARCH],
      });
    },
    [dispatchOnSearch],
  );

  return (
    <Grid container>
      <Grid item xs={12} container component={Box} direction="column">
        <Grid item className={classes.filterContainer}>
          <ThirdPartyToolsFilter
            toolGroups={toolGroups}
            onSubmit={handleSearch}
            toolSuppliers={toolSuppliers}
            initialValues={initialFilterValues}
            toggleAddToolModal={toggleAddToolModal}
            currentClientOrganizationId={currentClientOrganizationId}
          />
        </Grid>
        <Grid item container className={classes.tableContainer}>
          <ThirdPartyToolsTable
            tools={tools}
            toolCategory={EQIPMENT_CATEGORY_IDS.THIRD_PARTY_TOOLS}
          />
        </Grid>
        <AddToolModalContainer
          toolGroups={toolGroups}
          openModal={addToolModal}
          suppliers={toolSuppliers}
          toggleModal={toggleAddToolModal}
        />
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  filterContainer: {
    height: 'fit-content',
    width: '40%',
  },
  tableContainer: {
    flex: 1,
  },
});

export default compose(
  connect(() => ({}), {
    dispatchOnLoad: onLoad,
    dispatchOnUnload: onUnload,
    dispatchOnSearch: searchTools,
  }),
  withStyles(styles),
)(ThirdPartyToolsContainer);
