import { List } from 'immutable';
import { useState, useCallback } from 'react';

import { useRequest } from 'altus-hooks';

import mappers from 'mappers';
import { EMPTY_LIST } from 'app/app.constants';
import operationService from 'services/operation.service';
import LiveProjectsFilter from 'features/data-exchange/live-projects/components/LiveProjectsFilter';

const LiveProjectsFilterContainer = ({ filter, ...rest }) => {
  const [facilities, setFacilities] = useState(EMPTY_LIST);
  const [organizations, setOrganizations] = useState(EMPTY_LIST);

  const getOperationsFilter = useCallback(
    () =>
      operationService
        .getOperationsFilterLiveProject(filter)
        .then((filters) => {
          const { facilities, organizations } = filters;

          setFacilities(List(facilities.map(mappers.Facility.from)));
          setOrganizations(List(organizations.map(mappers.Organization.from)));
        }),
    [filter],
  );

  useRequest(getOperationsFilter);

  return (
    <LiveProjectsFilter
      filter={filter}
      facilities={facilities}
      organizations={organizations}
      {...rest}
    />
  );
};

export default LiveProjectsFilterContainer;
