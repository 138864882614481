import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createProjectSelector } from 'features/projects/projects.selectors';

/**
 * Hook that creates a memoized project selector and then selects a specified project from state
 */
const useProject = (projectId) => {
  const projectSelector = useMemo(
    () => createProjectSelector(projectId),
    [projectId],
  );

  return useSelector(projectSelector);
};

export default useProject;
