import { compose } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { initializeUserPreferences } from 'app/app.actions';
import { getCurrentUserFromState } from 'app/app.selectors';
import { Avatar as TooltipAvatar } from 'app/components/withTooltip';
import { useState } from 'react';
import MobileUserMenuDrawer from 'app/components/ApplicationHeader/Mobile/MobileUserMenuDrawer';

const Avatar = ({ user, classes, onClick }) => (
  <TooltipAvatar
    title={user.get('name')}
    src={user.get('avatar')}
    onClick={onClick}
    classes={{
      root: classes.triggerIconRoot,
    }}
  >
    {!user.get('avatar') && user.get('initials')}
  </TooltipAvatar>
);

const MobileUserMenuContainer = ({ user, classes }) => {
  const [toggleMenuDrawer, setToggleMenuDrawer] = useState(false);
  if (!user || (!user.get('avatar') && !user.get('initials'))) {
    return null;
  }

  const toggleDrawer = (toggleState = false) => {
    setToggleMenuDrawer(toggleState);
  };

  const setAvatar = (avatarUser = user, avatarClasses = classes) => {
    return <Avatar user={avatarUser} classes={avatarClasses} />;
  };

  return (
    <>
      <Avatar
        user={user}
        classes={classes}
        onClick={() => toggleDrawer(true)}
      />
      <MobileUserMenuDrawer
        open={toggleMenuDrawer}
        toggleOpen={toggleDrawer}
        user={user}
        avatar={setAvatar}
      />
    </>
  );
};

const styles = () => ({
  triggerIconRoot: {
    height: 40,
    width: 40,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
});

export default compose(
  connect(
    (state) => ({
      user: getCurrentUserFromState(state),
    }),
    {
      dispatchInitializeUserPreferences: initializeUserPreferences,
    },
  ),
  withStyles(styles),
)(MobileUserMenuContainer);
