import { compose } from 'redux';
import { useMemo, useState } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import ExportPdfIcon from '@material-ui/icons/PictureAsPdf';

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';

import { ModalActions } from 'altus-ui-components';
import { useMultiselect } from 'altus-hooks';

import { UnitSystem } from 'app/app.constants';
import SimulationExportModalChartSelect from 'features/projects/tasks/task/simulation/components/SimulationExportModalChartSelect';
import { SimulationChartsType } from 'features/projects/tasks/task/simulation/simulation.constants';

const SimulationExportModal = ({
  open,
  onClose,
  classes,
  onExport,
  availableCharts,
}) => {
  const unCheckItems = [SimulationChartsType.OVERVIEW];
  const [collapseAssemblies, setCollapseAssemblies] = useState(false);
  const toggleCollapseAssemblies = () => {
    setCollapseAssemblies(!collapseAssemblies);
  };
  const availableChartsSorted = useMemo(() => {
    return availableCharts
      ? availableCharts.sort((A, B) => A.chartType - B.chartType)
      : availableCharts;
  }, [availableCharts]);

  const {
    selectedItems: selectedCharts,
    isSelected: isChartSelected,
    toggleSelect: onToggleChartSelect,
  } = useMultiselect(availableChartsSorted);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      classes={{ paperScrollPaper: classes.root }}
    >
      <Grid className={classes.header}>
        <Grid container item xs alignItems="center">
          <Typography variant="h6">Export Simulation</Typography>
        </Grid>
      </Grid>
      <DialogContent className={classes.content}>
        <Card>
          <CardContent>
            <Typography className={classes.title}>Include Details</Typography>
            <Grid item container>
              <SimulationExportModalChartSelect
                isChartSelected={isChartSelected}
                availableCharts={availableChartsSorted}
                onToggleChartSelect={onToggleChartSelect}
                unCheckItems={unCheckItems}
              />
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.title}>
              Toolstring Details
            </Typography>
            <Grid item container>
              <ListItem dense button onClick={() => toggleCollapseAssemblies()}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    disableRipple
                    checked={collapseAssemblies}
                  />
                </ListItemIcon>
                <ListItemText primary={'Collapse Assemblies'} />
              </ListItem>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <ModalActions classes={{ dialogActionsRoot: classes.actions }}>
        <Grid container item xs justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button onClick={onClose}>Close</Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                onExport({
                  details: selectedCharts,
                  unitSystem: UnitSystem.METRIC,
                  collapseAssemblies,
                })
              }
              disabled={!selectedCharts.size}
            >
              Export
              <Box component={ExportPdfIcon} marginLeft={0.5} />
            </Button>
          </Grid>
        </Grid>
      </ModalActions>
    </Dialog>
  );
};

const styles = (theme) => ({
  root: {
    height: '65vh',
  },
  content: {
    padding: `0px ${theme.spacing(3)}px`,
  },
  header: {
    padding: theme.spacing(3),
  },
  actions: {
    paddingTop: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
  },
});

export default compose(withStyles(styles))(SimulationExportModal);
