import { convertToSet } from 'utils/app.util';
import { EMPTY_SET, ProjectPermission } from 'app/app.constants';

export const hasAllPermissions = (
  currentPermissions,
  permissions = EMPTY_SET,
) => {
  const permissionsSet = convertToSet(permissions);

  return currentPermissions.isSuperset(permissionsSet);
};

export const hasOneOrMorePermissions = (
  currentPermissions,
  permissions = EMPTY_SET,
) => {
  const permissionsSet = convertToSet(permissions);

  if (permissionsSet.isEmpty()) return true;

  return !permissionsSet.intersect(currentPermissions).isEmpty();
};

export const getRequiredProjectPermissions = (permissions) =>
  convertToSet(permissions).add(ProjectPermission.FULL_ACCESS);

export const hasRequiredProjectPermissions = (
  userProjectPermissions,
  permissions,
) =>
  hasOneOrMorePermissions(
    userProjectPermissions,
    getRequiredProjectPermissions(permissions),
  );
