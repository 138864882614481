import useMetalMillingHubConnection from 'features/projects/dashboard/hooks/useMetalMillingHubConnection';
import MetalMillingDashboardContainer from 'features/projects/dashboard/components/dashboards/metalMilling/MetalMillingDashboardContainer';

const MetalMillingDashboardController = ({
  projectId,
  renderSlots,
  projectDashboardId,
}) => {
  const { registerDataPointsHandler } = useMetalMillingHubConnection(
    projectId,
    projectDashboardId,
  );

  return renderSlots(
    <MetalMillingDashboardContainer
      registerDataPointsHandler={registerDataPointsHandler}
    />,
  );
};

export default MetalMillingDashboardController;
