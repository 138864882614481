import Agent from 'infrastructure/agent';
import mappers, { measurementPreferencesToDto } from 'mappers';
import { OrganizationType } from 'app/app.constants';

export const getCurrentUser = () => Agent.get('/user/current');

export const getMainVendorUsers = () =>
  Agent.get('/user/byorganizationtype', {
    organizationType: OrganizationType.MAIN_VENDOR,
  });

export const getUserMeasurementPreference = () =>
  Agent.get('/user/current/preference/measurement');

export const updateUserMeasurementPreference = (measurementPreferences) =>
  Agent.put(
    '/user/current/preference/measurement',
    measurementPreferencesToDto(measurementPreferences),
  );

export const updateUser = (user) =>
  Agent.put('/user/current', mappers.User.to(user));

export const allMembers = (projectId) =>
  Agent.get(`/project/${projectId}/members`);

export const getProjectById = (projectId) => Agent.get(`/project/${projectId}`);

export const markAsSeen = (projectId) =>
  Agent.post(`/project/${projectId}/seen`);

export const getFields = (filters) => Agent.get('/field', filters);

export const getFacilities = (filters) =>
  Agent.get('/search/filter/valueset/facility', filters);

export const allWellbores = (filter) => Agent.get('/search/wellbore', filter);

export const facilitiesByFieldId = () => Agent.get('/field/simple/facility');

export const wellboresByFieldId = (fieldId) =>
  Agent.get(`/field/simple/${fieldId}/wellbore`);

export const getAllDepartments = () => Agent.get('/template/departments');

export const getAllServices = () => Agent.get('/template/services');

export const getAllProjectStatuses = () =>
  Agent.get('/search/filter/valueset/projectstatus');

export const getAllFileCategories = () =>
  Agent.get('/search/filter/valueset/filecategory');

export const updateUserPreference = (userPreferences) =>
  Agent.put(
    '/user/current/preference/user',
    mappers.UserPreference.to(userPreferences),
  );

export const getUserPreferences = () => Agent.get('/user/current/preference');

export const getTaskTypes = () =>
  Agent.get('/search/filter/valueset/tasktypes');

const getTaskFailReasons = () =>
  Agent.get('/search/filter/valueset/taskfailreason');

const getAppFeatures = () => Agent.get('/features');

const getAllOrganizations = () => Agent.get('/organization');

const getOrganizationsByField = (fieldId) =>
  Agent.get(`/organization/byfield/${fieldId}`);

const getChangeLog = () => Agent.get('/features/changelog');

const getPrivacyPolicy = () => Agent.get('/privacypolicy');

const appService = {
  allMembers,
  markAsSeen,
  updateUser,
  getTaskTypes,
  getMainVendorUsers,
  allWellbores,
  getFacilities,
  getFields,
  getAllServices,
  getProjectById,
  getCurrentUser,
  getUserPreferences,
  getAllDepartments,
  wellboresByFieldId,
  facilitiesByFieldId,
  updateUserPreference,
  getAllProjectStatuses,
  getUserMeasurementPreference,
  updateUserMeasurementPreference,
  getAllFileCategories,
  getAppFeatures,
  getAllOrganizations,
  getOrganizationsByField,
  getTaskFailReasons,
  getChangeLog,
  getPrivacyPolicy,
};

export default appService;
