import LocationOn from '@material-ui/icons/LocationOn';
import withStyles from '@material-ui/styles/withStyles';

const ProjectMapMarker = ({ classes, isHovered }) => {
  return (
    <LocationOn
      color="inherit"
      className={`${classes.root} ${isHovered ? classes.zoomedIcon : ''}`}
    />
  );
};

const styles = (theme) => ({
  root: {
    fontSize: theme.typography.h4.fontSize,
    color: ({ status }) => theme.palette.status[status],
  },
  zoomedIcon: {
    transition: 'transform 0.2s ease-in-out',
    transform: 'scale(1.5)',
  },
});

export default withStyles(styles)(ProjectMapMarker);
