import React from 'react';
import { useSelector } from 'react-redux';

import { LoadingOverlay } from 'altus-ui-components';

import { getFieldCrewFromState } from 'features/projects/team/team.selectors';
import AddParticipantsModal from 'features/wellControlDrillActivity/AddParticipantsModal';

const AddParticipantsModalContainer = ({
  isOpen,
  toggleModal,
  form,
  field,
  dataState,
}) => {
  const users = useSelector(getFieldCrewFromState);

  return (
    <>
      <AddParticipantsModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        users={users}
        form={form}
        field={field}
      />
      <LoadingOverlay dataState={dataState} text={'Loading...'} />
    </>
  );
};

export default AddParticipantsModalContainer;
