import { BasePageHeader } from 'altus-ui-components';
import { NavTabsContainer } from 'altus-ui-components';
import SystemRoutesContainer from 'app/components/SystemRoutesContainer';

const WellboreIntegrityContainer = ({ Icon, breadcrumb, routes }) => {
  return (
    <>
      <BasePageHeader title={breadcrumb} Icon={Icon} />
      <NavTabsContainer routes={routes} />
      <SystemRoutesContainer routes={routes} />
    </>
  );
};

export default WellboreIntegrityContainer;
