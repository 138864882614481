import { createElement } from 'react';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

const RunNumber = ({ classes, children, component, size, ...rest }) => {
  return children
    ? createElement(
        component,
        {
          noWrap: true,
          color: 'textPrimary',
          className: classes.root,
          style: {
            height: `${size}px`,
            width: `${size}px`,
            lineHeight: `${size}px`,
          },
          ...rest,
        },
        children,
      )
    : null;
};

RunNumber.defaultProps = {
  size: 32,
  component: Typography,
  variant: 'body2',
};

export default withStyles((theme) => ({
  root: {
    borderColor: theme.palette.text.disabled,
    borderWidth: 1,
    borderRadius: '50%',
    borderStyle: 'solid',
    textAlign: 'center',
  },
}))(RunNumber);
