import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/styles/withStyles';

import { Grid, Typography } from 'app/components/withNavigation';

/**
 * Thumb icon to be used in the MUI Switch component for the Toolstring breakpoints.
 * Same as default thumb icon (taken from source code), but with the 'BR' text in it.
 * Ref.: https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Switch/Switch.js
 */
const BreakpointThumbIcon = ({ classes, darkText, color }) => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    classes={{
      root: classNames(classes.root, {
        [classes.rootPrimary]: color === 'primary',
      }),
    }}
  >
    <Grid
      item
      variant="caption"
      color="textSecondary"
      component={Typography}
      className={classNames(classes.thumbText, {
        [classes.thumbTextDark]: darkText,
      })}
    >
      <b>BR</b>
    </Grid>
  </Grid>
);

const styles = (theme) => ({
  root: {
    borderRadius: '50%',
    width: theme.spacing(2),
    height: theme.spacing(2),
    boxShadow: theme.shadows[1],
    backgroundColor: 'currentColor',
  },
  rootPrimary: {
    backgroundColor: theme.palette.primary.dark,
  },
  thumbText: {
    fontSize: theme.spacing(1),
  },
  thumbTextDark: {
    color: theme.palette.secondary.dark,
  },
});

BreakpointThumbIcon.defaultProps = {
  color: 'default',
};

BreakpointThumbIcon.propTypes = {
  darkText: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'primary']),
};

export default withStyles(styles)(BreakpointThumbIcon);
