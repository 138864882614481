import { fromJS, List } from 'immutable';

import mappers from 'mappers';
import { EMPTY_LIST } from 'app/app.constants';
import { WELL_EVENTS_PAGE_LOADED } from 'features/wells/events/wellEvents.constants';

const initialState = fromJS({
  data: EMPTY_LIST,
});

const wellEventsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case WELL_EVENTS_PAGE_LOADED:
      if (action.error) return state;
      return state.set('data', List(action.payload).map(mappers.Event.from));
    default:
      return state;
  }
};

export default wellEventsReducer;
