import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTaskActivities } from 'features/projects/activities/hooks/useTaskActivities';
import { getAllTasks } from 'features/projects/activities/activities.selectors';
import * as activitiesActions from 'features/projects/activities/activities.actions';
import { getSimulationsForTaskFromState } from 'features/projects/tasks/task/simulation/simulation.selectors';
import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import { useHeader } from 'app/hooks/useHeader';
import { WORK_ITEM_STATUS } from 'app/app.constants';

export const getPageTitle = (title, activities) => {
  if (!title) return '';

  if (!activities.isEmpty()) {
    const completedActivities = activities.filter(
      (activity) => activity.get('status') === WORK_ITEM_STATUS.COMPLETED,
    );

    return `${
      title ? `${title} (${completedActivities.size}/${activities.size})` : ''
    }`;
  }

  return title;
};
export const useInitializeExecutionRootContainer = (projectId, taskId) => {
  const dispatch = useDispatch();
  const tasks = useSelector(getAllTasks);
  const simulations = useSelector((state) =>
    !_isNil(taskId)
      ? getSimulationsForTaskFromState(state, taskId)?.valueSeq()
      : EMPTY_LIST,
  );
  const activities = useTaskActivities(taskId) ?? EMPTY_MAP;
  const title = tasks
    ?.find((t) => t.get('id')?.toString() === taskId)
    ?.get('title');

  // Fetch tasks and project timer/status
  useEffect(() => {
    if (projectId) {
      dispatch(activitiesActions.onLoad(projectId));
      return () => {
        return dispatch(activitiesActions.onUnload());
      };
    }
  }, [projectId, dispatch]);

  // Fetch activities for current task - to show in header/title
  useEffect(() => {
    if (projectId && taskId) {
      dispatch(activitiesActions.activityListOnLoad(projectId, taskId));
      return () => {
        return dispatch(activitiesActions.activityListOnUnload());
      };
    }
  }, [projectId, taskId, dispatch]);

  useHeader({
    subTitle: getPageTitle(title, activities),
  });

  return { tasks, simulations };
};
