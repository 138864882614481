import mappers from 'mappers';
import { EMPTY_MAP } from 'app/app.constants';
import { WELLS_ACTIONS as ACTIONS } from 'features/wells/wells.constants';

const initialState = EMPTY_MAP;

const wellboreTrajectoryReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.RECEIVE_WELLBORE_TRAJECTORY: {
      if (error) return state;

      const { wellboreId } = action;

      return state.set(wellboreId.toString(), mappers.Trajectory.from(payload));
    }

    default:
      return state;
  }
};

export default wellboreTrajectoryReducer;
