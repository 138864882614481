import React from 'react';
import { Field } from 'formik';

import { Grid } from '@material-ui/core';

import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';

export const CableTorsionTestFormComponent = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} container>
          <Field
            step="1"
            required
            type="number"
            destroyOnUnmount
            label="Number of twists"
            component={TextFieldFormik}
            name={AssetHistoryForm.CABLE_TORSION_WRAP}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CableTorsionTestFormComponent;
