import { TASK_ACTIONS as ACTIONS } from './tasks/tasks.constants';
import { requestDeleteFluidGeneric } from '../../utils/fluids/actions.base';

export const requestGetTaskFluids = (projectId, taskId) => ({
  projectId,
  taskId,
  type: ACTIONS.INITIATE_GET_TASK_FLUIDS,
});

export const storeTaskFluids = (fluids) => ({
  type: ACTIONS.GET_TASK_FLUIDS,
  payload: fluids,
});

export const requestDeleteTaskFluid = (projectId, taskId, fluidId) => {
  return requestDeleteFluidGeneric(
    ACTIONS.INITIATE_DELETE_TASK_FLUID,
    { projectId, taskId },
    fluidId,
  );
};

export const deletedTaskFluid = (fluidId) => ({
  type: ACTIONS.DELETE_TASK_FLUID,
  payload: {
    fluidId,
  },
});

export const requestUpdateTaskFluid = (
  projectId,
  taskId,
  fluidObj,
  setSubmitting,
  setStatus,
) => ({
  projectId,
  taskId,
  fluidObj,
  setSubmitting,
  setStatus,
  type: ACTIONS.INITIATE_UPDATE_TASK_FLUID,
  notification: {
    info: 'Updating fluid...',
    success: 'Fluid successfully updated',
  },
});

export const updatedTaskFluid = (fluidId, taskId) => ({
  type: ACTIONS.UPDATE_TASK_FLUID,
  payload: {
    fluidId,
    taskId,
  },
});

export const requestCreateTaskFluid = (
  projectId,
  taskId,
  fluidObj,
  setSubmitting,
  setStatus,
) => ({
  projectId,
  taskId,
  fluidObj,
  setSubmitting,
  setStatus,
  type: ACTIONS.INITIATE_CREATE_TASK_FLUID,
  notification: {
    info: 'Adding fluid...',
    success: 'Fluid successfully created',
  },
});

export const createTaskFluid = (fluidId, taskId) => ({
  type: ACTIONS.CREATE_TASK_FLUID,
  payload: {
    fluidId,
    taskId,
  },
});
