import { useEffect, useState, useCallback } from 'react';

import config from 'infrastructure/config';
import useStream from 'features/projects/dashboard/hooks/useStream';
import useHubConnection from 'features/projects/dashboard/hooks/useHubConnection';

const liveOperationsHubUrl = `${config.dashboardHubsBaseUrl}/live-operations`;

const LiveOperationsHub = {
  StreamLiveOperations: 'StreamLiveOperations',
};

const useLiveOperationsConnection = (
  operationsFilters,
  onReceiveOperations,
) => {
  const [_, setStreamSubscription] = useState();
  const [connection, connectionState] = useHubConnection(liveOperationsHubUrl);

  const createStream = useCallback(
    (connection) =>
      Promise.resolve(
        connection.stream(
          LiveOperationsHub.StreamLiveOperations,
          operationsFilters,
        ),
      ),
    [operationsFilters],
  );

  const startStreamSubscription = useCallback(
    (stream) =>
      setStreamSubscription((currentSubscription) => {
        // Already streaming, do nothing.
        if (currentSubscription) return currentSubscription;

        return stream.subscribe({
          next: onReceiveOperations,
          error: console.error,
        });
      }),
    [onReceiveOperations],
  );

  const stopStreamSubscription = useCallback(
    () =>
      setStreamSubscription((currentSubscription) => {
        if (currentSubscription) {
          currentSubscription.dispose();
        }

        return null;
      }),
    [],
  );

  useStream(
    connection,
    connectionState,
    createStream,
    startStreamSubscription,
    stopStreamSubscription,
  );

  useEffect(() => {
    // If the filters change, dispose the current streaming subscription and set it to null,
    // then resubscribe with the new filters.
    stopStreamSubscription();
  }, [operationsFilters, stopStreamSubscription]);
};

export default useLiveOperationsConnection;
