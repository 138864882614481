import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Button, Typography } from '@material-ui/core';

const SetAndRetrieveItemModalHeader = ({ title, closeModal, classes }) => (
  <Grid
    container
    alignItems="center"
    justifyContent="spaceBetween"
    className={classes.container}
  >
    <Grid item xs={4}></Grid>
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      xs={4}
      className={classes.title}
    >
      <Typography noWrap variant="body">
        Set / Retrieve item
      </Typography>
      <Typography variant="h6">{title}</Typography>
    </Grid>
    <Grid container item xs={4} justifyContent="flex-end" spacing={2}>
      <Grid item>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Close
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

const styles = (theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.secondary.dark,
  },
  title: {
    overflow: 'hidden',
    padding: `0 ${theme.spacing(2)}px`,
  },
});

export default compose(withStyles(styles))(SetAndRetrieveItemModalHeader);
