import React from 'react';
import { compose } from 'redux';
import MobileApplicationHeaderBase from 'app/components/ApplicationHeader/Mobile/MobileApplicationHeaderBase';
import MobileUserMenuContainer from 'app/components/ApplicationHeader/Mobile/MobileUserMenuContainer';
import { Grid } from '@material-ui/core';

const DefaultMobileAppHeader = () => {
  return (
    <MobileApplicationHeaderBase>
      <Grid
        container
        spacing={2}
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <MobileUserMenuContainer />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MobileApplicationHeaderBase>
  );
};

export default compose()(DefaultMobileAppHeader);
