import { compose } from 'redux';
import { connect } from 'react-redux';
import { useEffect, memo } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import { useHeader } from 'app/hooks/useHeader';
import { getActionDataStateFromState } from 'app/app.selectors';
import { onLoad } from 'features/wells/projects/wellProjects.actions';
import { getWellProjectsFromState } from 'features/wells/projects/wellProjects.selectors';
import { WELL_PROJECT_HISTORY_PAGE_LOADED } from 'features/wells/projects/wellProjects.constants';
import WellDashboardProjectByStatus from 'features/projects/components/WellDashboardProjectByStatus';
import CreateProjectFromWellboreModalContainer from 'features/wells/overview/components/CreateProjectFromWellboreModalContainer';
import { useTheme } from '@material-ui/core/styles';

const WellDashboardProjectHistory = ({
  fieldId,
  classes,
  projects,
  dataState,
  breadcrumb,
  wellboreId,
  dispatchOnLoad,
}) => {
  const theme = useTheme();
  useHeader({
    subTitle: breadcrumb,
  });

  useEffect(() => {
    dispatchOnLoad(wellboreId);
  }, [dispatchOnLoad, wellboreId]);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid item container justifyContent="center">
        {!projects.size ? (
          <Grid container xs={12} spacing={2}>
            <Grid item container justifyContent="flex-end">
              <CreateProjectFromWellboreModalContainer
                fieldId={fieldId}
                wellboreId={wellboreId}
              />
            </Grid>
            <Grid
              item
              container
              component={Box}
              xs={12}
              sx={{ backgroundColor: theme.palette.secondary.darkGrey }}
              justifyContent="center"
            >
              <Typography variant="h6">No projects...</Typography>
            </Grid>
          </Grid>
        ) : (
          <Grid item container xs={12} spacing={2}>
            <Grid item container justifyContent="flex-end">
              <CreateProjectFromWellboreModalContainer
                fieldId={fieldId}
                wellboreId={wellboreId}
              />
            </Grid>
            <WellDashboardProjectByStatus projects={projects} />
          </Grid>
        )}
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(
    (state) => ({
      projects: getWellProjectsFromState(state),
      dataState: getActionDataStateFromState(
        state,
        WELL_PROJECT_HISTORY_PAGE_LOADED,
      ),
    }),
    {
      dispatchOnLoad: onLoad,
    },
  ),
  memo,
  withStyles(styles),
)(WellDashboardProjectHistory);
