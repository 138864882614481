import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { alpha } from '@material-ui/core/styles';

import Counter from 'app/components/Counter';
import { TIME_UNITS } from 'app/app.constants';

const styles = (theme) => ({
  progress: {
    color: theme.altus.button.progress,
  },
  dimmer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    zIndex: '1200',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.common.black, 0.3),
  },
  dimmerContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
});

const LoadingDimmer = ({ classes, text, displaySpinner = true }) => (
  <div className={classes.dimmer}>
    <div className={classes.dimmerContent}>
      {displaySpinner && <CircularProgress className={classes.progress} />}
      <Typography variant="caption" color="textSecondary">
        <Counter
          isTimerActive
          precision={0.5}
          unit={TIME_UNITS.SECOND}
          calculateDurationFromStartAndEndtime
          renderValue={({ elapsedTime }) =>
            `${text}${
              elapsedTime ? '.'.repeat((elapsedTime.asSeconds() * 2) % 4) : ''
            }`
          }
        />
      </Typography>
    </div>
  </div>
);

LoadingDimmer.defaultProps = {
  text: 'Loading',
};

export default withStyles(styles)(LoadingDimmer);
