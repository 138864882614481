import { compose } from 'redux';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Grid } from '@material-ui/core';
import { PureComponent } from 'react';
import { reduxForm } from 'redux-form/immutable';

import { BasePage } from 'altus-ui-components';

import {
  projectRoleFormOnChange,
  initializeProjectRoleForm,
  projectRoleAccessRightsOnLoad,
} from 'authorization/authorization.actions';

import {
  PROJECT_ROLE_FORM,
  projectPermissionGroups,
} from 'authorization/authorization.constants';

import {
  getProjectRoleFromState,
  getProjectPermissionsFromState,
  getProjectRolePermissionsFromState,
} from 'authorization/authorization.selectors';

import { EMPTY_MAP, EMPTY_SET } from 'app/app.constants';
import RoleForm from 'features/settings/accessRoles/RoleForm';
import RoleAccessSummary from 'features/settings/accessRoles/RoleAccessSummary';

class ProjectRoleOverview extends PureComponent {
  componentDidMount() {
    const { projectRoleId, dispatchOnLoad } = this.props;

    dispatchOnLoad(projectRoleId);
  }
  componentDidUpdate({ projectRole: previousProjectRole }) {
    const {
      initialized,
      projectRole: currentProjectRole,
      dispatchInitializeProjectRoleForm,
    } = this.props;

    if (!currentProjectRole) return;

    if (!initialized || currentProjectRole !== previousProjectRole) {
      dispatchInitializeProjectRoleForm(currentProjectRole);
    }
  }

  render() {
    const {
      projectRole,
      projectPermissions,
      projectRolePermissions,
    } = this.props;

    return (
      <BasePage>
        <Grid container item xs={12} md={6}>
          <Grid container direction="column">
            <RoleForm
              form={PROJECT_ROLE_FORM}
              isProtected={projectRole.get('isProtected')}
            />
            <RoleAccessSummary
              permissions={projectPermissions}
              rolePermissions={projectRolePermissions}
              permissionGroups={projectPermissionGroups}
            />
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

ProjectRoleOverview.defaultProps = {
  projectRole: EMPTY_MAP,
  projectRolePermissions: EMPTY_SET,
};

export default compose(
  connect(
    (state, { projectRoleId }) => ({
      projectRole: getProjectRoleFromState(state, projectRoleId),
      projectPermissions: getProjectPermissionsFromState(state),
      projectRolePermissions: getProjectRolePermissionsFromState(
        state,
        projectRoleId,
      ),
    }),
    {
      dispatchInitializeProjectRoleForm: initializeProjectRoleForm,
      dispatchOnLoad: projectRoleAccessRightsOnLoad,
    },
  ),
  reduxForm({
    form: PROJECT_ROLE_FORM.ID,
    onChange: debounce(projectRoleFormOnChange, 1500),
  }),
)(ProjectRoleOverview);
