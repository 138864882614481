import moment from 'moment';
import { Map } from 'immutable';

import { createSelector } from 'utils/app.util';
import { getTotalElapsedTime } from 'utils/activity.util';
import { TASK_STATE } from 'features/projects/tasks/tasks.constants';

export const getAllTasksFromState = createSelector(
  (state) => state.getIn(['projects', 'plan', 'tasks', TASK_STATE.DATA]),
  (tasks) => tasks,
);

export const getTotalPlannedDuration = createSelector(
  getAllTasksFromState,
  (tasks) =>
    getTotalElapsedTime(
      tasks
        .filter((task) => task.get('durationPlanned'))
        .map((task) =>
          Map({
            startTime: moment(),
            endTime: moment().add(moment.duration(task.get('durationPlanned'))),
          }),
        ),
    ),
);

export const getTotalElapsedDuration = createSelector(
  getAllTasksFromState,
  (tasks) => getTotalElapsedTime(tasks),
);

export const getTaskTemplates = createSelector(
  (state) =>
    state.getIn([
      'projects',
      'plan',
      'tasks',
      TASK_STATE.TEMPLATE,
      TASK_STATE.TEMPLATE_DATA,
    ]),
  (taskTemplates) => taskTemplates,
);

export const getTaskActivitiesFromState = createSelector(
  (state, taskId) =>
    state
      .getIn(['projects', 'activities', 'data', 'activities'])
      .filter((activity) => activity.get('taskId') === taskId),
  (taskActivities) => taskActivities,
);

export const getTaskSurfaceEquipmentFromState = createSelector(
  (state) => state.getIn(['projects', 'plan', 'tasks', 'surfaceEquipment']),
  (surfaces) => surfaces,
);

export const getTaskById = (taskId) =>
  createSelector(getAllTasksFromState, (allTasks) => {
    return allTasks.find((task) => task.get('taskId') === parseInt(taskId));
  });

export const getTaskRiskSectionSelector = (state) =>
  state.getIn(['entity', 'toolString', 'data', 'task', 'taskRisk']);

export const createTaskRiskSectionsForWellboreSelector = () =>
  createSelector(getTaskRiskSectionSelector, (sections) => {
    return sections;
  });

export const getTaskFluidsSelector = createSelector(
  (state) => state.getIn(['projects', 'plan', 'task', 'fluids', 'data']),
  (fluids) => fluids,
);
