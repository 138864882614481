import { useMemo, useCallback } from 'react';
import Close from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

import { Table, UserAvatar, TableRowActionsCell } from 'altus-ui-components';

import { EMPTY_LIST, ProjectPermission } from 'app/app.constants';
import HasProjectPermission from 'app/components/HasProjectPermission';
import ProjectMemberAccessCell from 'features/projects/team/components/ProjectMemberAccessCell';
import TeamProjectRoleAccessCell from 'features/projects/team/components/TeamProjectRoleAccessCell';

const initialState = {
  sortBy: [
    {
      id: 'name',
      desc: false,
    },
  ],
};

const ProjectMembersTable = ({
  project,
  projectRoles,
  projectMembers,
  teamProjectRoles,
  deleteProjectMember,
  assignProjectMemberToRole,
  assignTeamProjectRoleChange,
}) => {
  const projectOwnersByUserId = useMemo(
    () =>
      project
        .get('owners', EMPTY_LIST)
        .toMap()
        .mapKeys((_, user) => user.get('userId')),
    [project],
  );

  const projectHasOtherOwners = useCallback(
    (projectMember) =>
      !projectOwnersByUserId.delete(projectMember.get('userId')).isEmpty(),
    [projectOwnersByUserId],
  );

  const columns = useMemo(
    () => [
      {
        id: 'avatar',
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const projectMember = row.original;

          return (
            <TableRowActionsCell>
              <UserAvatar
                avatar={projectMember.getIn(['user', 'avatar'])}
                initials={projectMember.getIn(['user', 'initials'])}
              />
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 4,
        id: 'name',
        Header: 'Name',
        accessor: (projectMember) =>
          projectMember.getIn(['user', 'name'], null),
      },
      {
        xs: 4,
        Header: 'Job title',
        accessor: (projectMember) => projectMember.getIn(['user', 'title']),
      },
      {
        xs: 4,
        Header: 'Organization',
        accessor: (projectMember) =>
          projectMember.getIn(['user', 'organizationName']),
      },
      {
        xs: 4,
        Header: 'Access',
        Cell: ({ row }) => {
          const projectMember = row.original;
          const hasOtherOwners = projectHasOtherOwners(projectMember);

          return (
            <ProjectMemberAccessCell
              projectRoles={projectRoles}
              projectMember={projectMember}
              hasOtherOwners={hasOtherOwners}
              assignProjectMemberToRole={assignProjectMemberToRole}
            />
          );
        },
      },
      {
        xs: 4,
        Header: 'Project role',
        Cell: ({ row }) => {
          const projectMember = row.original;
          const hasOtherOwners = projectHasOtherOwners(projectMember);

          return (
            <TeamProjectRoleAccessCell
              teamProjectRoles={teamProjectRoles}
              projectMember={projectMember}
              hasOtherOwners={hasOtherOwners}
              assignTeamProjectRoleChange={assignTeamProjectRoleChange}
            />
          );
        },
      },
      {
        id: 'actions',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const projectMember = row.original;
          const hasOtherOwners = projectHasOtherOwners(projectMember);

          return (
            <TableRowActionsCell>
              <HasProjectPermission
                permissions={ProjectPermission.DELETE_TEAM_MEMBERS}
              >
                <IconButton
                  title="Remove member"
                  disabled={!hasOtherOwners}
                  onClick={() =>
                    deleteProjectMember(
                      projectMember.get('projectMemberId'),
                      projectMember.getIn(['user', 'name'], null),
                    )
                  }
                >
                  <Close fontSize="small" />
                </IconButton>
              </HasProjectPermission>
            </TableRowActionsCell>
          );
        },
      },
    ],
    [
      projectRoles,
      teamProjectRoles,
      deleteProjectMember,
      projectHasOtherOwners,
      assignProjectMemberToRole,
      assignTeamProjectRoleChange,
    ],
  );

  return (
    <Table
      columns={columns}
      items={projectMembers}
      useGlobalFilter={false}
      initialState={initialState}
    />
  );
};

export default ProjectMembersTable;
