import { ACTIONS } from 'features/wells/sections/wellboreSection.constants';

export const requestWellboreSectionsForWellbore = (fieldId, wellboreId) => ({
  fieldId,
  wellboreId,
  type: ACTIONS.REQUEST_WELLBORE_SECTIONS,
});

export const requestCreateWellboreSection = (
  fieldId,
  wellboreId,
  wellboreSection,
  formik,
  callback,
) => {
  const { setStatus, setSubmitting } = formik;

  return {
    fieldId,
    callback,
    setStatus,
    wellboreId,
    setSubmitting,
    payload: wellboreSection,
    type: ACTIONS.REQUEST_CREATE_WELLBORE_SECTION,
    notification: {
      success: 'Section successfully created',
    },
  };
};

export const requestUpdateWellboreSection = (
  fieldId,
  wellboreId,
  wellboreSectionId,
  wellboreSection,
) => ({
  fieldId,
  wellboreId,
  wellboreSectionId,
  payload: wellboreSection,
  type: ACTIONS.REQUEST_UPDATE_WELLBORE_SECTION,
  notification: {
    success: 'Section successfully updated',
  },
});

export const requestDeleteWellboreSection = (
  fieldId,
  wellboreId,
  wellboreSectionId,
) => ({
  fieldId,
  wellboreId,
  wellboreSectionId,
  type: ACTIONS.REQUEST_DELETE_WELLBORE_SECTION,
  confirmationDialog: {
    title: 'Delete section',
    confirmButtonText: 'Delete',
    description: 'Are you sure you want to delete this section? ',
  },
  notification: {
    success: 'Section was successfully deleted',
  },
});

export const deleteWellboreSection = (wellboreSectionId) => ({
  wellboreSectionId,
  type: ACTIONS.DELETE_WELLBORE_SECTION,
});

export const receiveWellboreSection = (wellboreSection) => ({
  payload: wellboreSection,
  type: ACTIONS.RECEIVE_WELLBORE_SECTION,
});

export const receiveWellboreSections = (wellboreSections) => ({
  payload: wellboreSections,
  type: ACTIONS.RECEIVE_WELLBORE_SECTIONS,
});

export const receiveWellboreSectionNipples = (
  wellboreSectionId,
  wellboreSectionNipples,
) => ({
  payload: {
    wellboreSectionId: wellboreSectionId,
    wellboreSectionNipples: wellboreSectionNipples,
  },
  type: ACTIONS.RECEIVE_WELLBORE_SECTION_NIPPLES,
});

export const requestCreateWellboreSectionNipple = (
  fieldId,
  wellboreId,
  wellboreSectionId,
  wellboreSectionNipple,
  formik,
  callback,
) => {
  const { setStatus, setSubmitting } = formik;

  return {
    fieldId,
    callback,
    setStatus,
    wellboreId,
    wellboreSectionId,
    setSubmitting,
    payload: wellboreSectionNipple,
    type: ACTIONS.REQUEST_CREATE_WELLBORE_SECTION_NIPPLE,
    notification: {
      info: 'Saving...',
      success: 'Nipple section successfully created',
    },
  };
};

export const requestUpdateWellboreSectionNipple = (
  fieldId,
  wellboreId,
  wellboreSectionId,
  wellboreSectionNippleId,
  wellboreSectionNipple,
) => ({
  fieldId,
  wellboreId,
  wellboreSectionId,
  wellboreSectionNippleId,
  payload: wellboreSectionNipple,
  type: ACTIONS.REQUEST_UPDATE_WELLBORE_SECTION_NIPPLE,
  notification: {
    info: 'Saving...',
    success: 'Nipple section successfully updated',
  },
});

export const requestDeleteWellboreSectionNipple = (
  fieldId,
  wellboreId,
  wellboreSectionId,
  wellboreSectionNippleId,
) => {
  return {
    fieldId,
    wellboreId,
    wellboreSectionId,
    wellboreSectionNippleId,
    type: ACTIONS.REQUEST_DELETE_WELLBORE_SECTION_NIPPLE,
    confirmationDialog: {
      title: 'Delete nipple section',
      confirmButtonText: 'Delete',
      description: 'Are you sure you want to delete this nipple section? ',
    },
    notification: {
      info: 'Deleting...',
      success: 'Nipple section was successfully deleted',
    },
  };
};

export const deleteWellboreSectionNipple = (
  wellboreSectionId,
  wellboreSectionNippleId,
) => ({
  wellboreSectionId,
  wellboreSectionNippleId,
  type: ACTIONS.DELETE_WELLBORE_SECTION_NIPPLE,
});
