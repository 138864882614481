import { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import ImageFilled from '@material-ui/icons/Image';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import { withStyles } from '@material-ui/core/styles';
import MobileToolImageDrawer from 'features/mobile/projects/execute/taskToolstring/MobileToolImageDrawer';

const MobileToolstringActionsContainer = ({
  classes,
  toolstringSections,
  validateToolstringSerialNumbers,
}) => {
  const [toolImageDrawerIsOpen, toggleToolImageDrawer] = useState(false);
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Button
          fullWidth={true}
          size="large"
          variant="outlined"
          className={classes.outlinedButton}
          startIcon={<ImageFilled />}
          onClick={() => toggleToolImageDrawer(true)}
        >
          {'Drawing'}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth={true}
          size="large"
          variant="contained"
          className={classes.containedButton}
          startIcon={<PlaylistPlayIcon />}
          onClick={() => validateToolstringSerialNumbers()}
        >
          {'Verify BHA'}
        </Button>
      </Grid>
      <MobileToolImageDrawer
        toolstringSections={toolstringSections}
        open={toolImageDrawerIsOpen}
        toggleOpen={toggleToolImageDrawer}
      />
    </Grid>
  );
};

const styles = (theme) => {
  return {
    outlinedButton: {
      color: theme.palette.primary.newLight,
      borderColor: theme.palette.primary.newLight,
      minHeight: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
    containedButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
      minHeight: theme.spacing(8),
      marginBottom: theme.spacing(2),
    },
  };
};

export default withStyles(styles)(MobileToolstringActionsContainer);
