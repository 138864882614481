import { EMPTY_MAP } from 'app/app.constants';
import useProject from 'features/projects/hooks/useProject';

const ProjectBreadcrumb = ({ match }) => {
  const { projectId } = match.params;

  const project = useProject(projectId) ?? EMPTY_MAP;

  return project.get('fullTitle') ?? '...';
};

export default ProjectBreadcrumb;
