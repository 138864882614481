import { useMemo } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { useEffect, useRef } from 'react';

import {
  firstColumnWidth,
  secondColumnLeft,
  secondColumnWidth,
  getDefaultYAxisOptions,
} from 'features/projects/dashboard/components/dashboards/logging/helpers/logging.highcharts.theme';

import LoggingDashboard from 'features/projects/dashboard/components/dashboards/logging/common/LoggingDashboard';
import loggingDashboardHighchartsTheme from 'features/projects/dashboard/components/dashboards/logging/helpers/logging.highcharts.theme';
import { createNewEventWithOffset } from 'features/projects/dashboard/components/dashboards/logging/helpers/common';

const curveOptions = [
  {
    offset: 120,
    width: `${firstColumnWidth}%`,
  },
  {
    offset: 80,
    width: `${firstColumnWidth}%`,
  },
  {
    offset: 40,
    width: `${firstColumnWidth}%`,
  },
  {
    offset: 0,
    width: `${firstColumnWidth}%`,
  },
  {
    offset: 0,
    width: `${secondColumnWidth}%`,
    left: `${secondColumnLeft}%`,
  },
  {
    offset: 40,
    width: `${secondColumnWidth}%`,
    left: `${secondColumnLeft}%`,
  },
  {
    offset: 80,
    width: `${secondColumnWidth}%`,
    left: `${secondColumnLeft}%`,
  },
  {
    offset: 120,
    width: `${secondColumnWidth}%`,
    left: `${secondColumnLeft}%`,
  },
];

const CaliperDashboard = ({
  data,
  curves,
  depthUnit,
  measuredDepth,
  onSetExtremes,
  registerDataPointsHandler,
}) => {
  const chartComponent = useRef(null);
  useEffect(() => {
    const chart = chartComponent.current.chart;

    if (!measuredDepth) return;

    chart.xAxis[0].update({
      softMax: measuredDepth,
    });
  }, [measuredDepth]);

  const chartOptions = useMemo(
    () =>
      Highcharts.merge(false, loggingDashboardHighchartsTheme, {
        series: curves.toArray().map((curve) => ({
          id: curve.get('id'),
          name: curve.get('caption'),
          caption: curve.get('caption'),
          type: curve.get('type'),
          color: curve.get('color'),
          unit: curve.get('unit'),
          depthUnit,
          // When using dual or multiple y axes, this number defines which yAxis the particular series is connected to.
          yAxis: curve.get('id').toString(),
        })),
        xAxis: [
          {
            id: 0,
            events: {
              setExtremes: (event) => {
                const newEvent = createNewEventWithOffset(event, curves);
                onSetExtremes(newEvent.min, newEvent.max);
              },
            },
          },
          {
            id: 1,
            linkedTo: 0,
            opposite: true,
          },
        ],
        yAxis: curves
          .sortBy((curve) => curve.get('isDiameter'))
          .toArray()
          .map((curve, index) => ({
            ...getDefaultYAxisOptions(curve, index),
            ...curveOptions[index],
          })),
      }),
    [curves, onSetExtremes, depthUnit],
  );

  return (
    <LoggingDashboard
      data={data}
      chart={chartComponent}
      options={chartOptions}
      curves={curves}
      registerDataPointsHandler={registerDataPointsHandler}
    />
  );
};

CaliperDashboard.propTypes = {
  onSetExtremes: PropTypes.func.isRequired,
  registerDataPointsHandler: PropTypes.func.isRequired,
};

export default CaliperDashboard;
