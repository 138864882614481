import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';
import { useMemo } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import { Table } from 'altus-ui-components';

import { EMPTY_MAP } from 'app/app.constants';
import TableCellDense from 'app/components/Table/TableCellDense';
import TableHeaderCellDense from 'app/components/Table/TableHeaderCellDense';
import { getQuantityUnitFromItems } from 'utils/app.util';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';
import TableHeaderTitleCell from 'app/components/TableHeaderTitleCell';
import { Grid } from 'app/components/withNavigation';

const FacilityDetailsTable = ({
  classes,
  noItemsMessage,
  decks = EMPTY_MAP,
}) => {
  const lengthUnit = getQuantityUnitFromItems(
    decks,
    (result) => result.maximumRigupHeight,
  );

  const columns = useMemo(
    () => [
      {
        id: 'deckName',
        xs: 6,
        Header: () => (
          <TableHeaderTitleCell title="Deck Name" className={classes.rowCell} />
        ),
        accessor: (row) => {
          return <Grid className={classes.rowCell}>{row?.deckName}</Grid>;
        },
      },
      {
        id: 'maximumRigupHeight',
        xs: 4,
        Header: () => (
          <QuantityTableHeaderTitleCell
            title="Maximum Rig Up Height"
            unit={lengthUnit}
          />
        ),
        accessor: (row) => row?.maximumRigupHeight?.value,
      },
    ],
    [classes.rowCell, lengthUnit],
  );

  return (
    <Table
      columns={columns}
      items={decks}
      useGlobalFilter={false}
      noItemsMessage={noItemsMessage}
      TableCellComponent={TableCellDense}
      TableHeaderCellComponent={TableHeaderCellDense}
    />
  );
};

FacilityDetailsTable.propTypes = {
  documents: PropTypes.instanceOf(Collection),
};

const styles = () => ({
  rowCell: {
    paddingLeft: '10px',
  },
});

export default compose(withStyles(styles))(FacilityDetailsTable);
