import isEqual from 'lodash/isEqual';
import taskService from 'services/task.service';
import { initialize } from 'redux-form/immutable';
import { replace, push } from 'connected-react-router/immutable';

import { addNotification } from 'altus-redux-middlewares';

import {
  TASK_FORMS,
  TASK_ACTIONS,
} from 'features/projects/tasks/tasks.constants';

import {
  copyTask,
  deleteTask,
  receiveTask,
} from 'features/projects/tasks/tasks.actions';

import { getFormFromState } from 'app/app.selectors';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import { toProjectTasks } from 'utils/route.util';
import { getProjectById } from 'features/projects/projects.actions';
import { getAllTasksFromState } from 'features/projects/tasks/tasks.selectors';
import { getTaskRiskSectionSelector } from 'features/projects/tasks/tasks.selectors';

import { getTaskById } from 'features/projects/tasks/task/toolstring/toolstring.actions';
import { TASK_INFO_ACTIONS } from 'features/projects/tasks/task/details/taskDetails.constants';
import { RISK_TABLE_ACTIONS } from 'features/projects/tasks/task/details/taskDetails.constants';

const { EDIT_TASK } = TASK_FORMS;

export const initializeEditTaskForm = (task, tasks) => (dispatch) => {
  const taskContingencies = tasks
    .filter((t) => t.get('contingencyForTaskId') === task.get('taskId'))
    .map((t) => t.get('taskId'));

  dispatch(
    initialize(
      EDIT_TASK.FORM_ID,
      task.set('taskContingencies', taskContingencies),
    ),
  );
};

export const taskDetailsOnLoad = (projectId, taskId) => (dispatch) => {
  const payload = dispatch(getTaskById(projectId, taskId));

  dispatch({
    payload,
    type: TASK_INFO_ACTIONS.TASK_INFO_PAGE_LOADED,
  });

  return payload;
};

export const updateTaskServices =
  (projectId, taskId, services) => (dispatch) => {
    const payload = taskService
      .updateTaskServices(
        projectId,
        taskId,
        services.map((service) => service.get('id')),
      )
      .then((task) => dispatch(receiveTask(task)));

    dispatch({
      payload,
      type: TASK_ACTIONS.UPDATE_TASK_SERVICES,
      notification: {
        info: 'Saving...',
        success: 'The task was successfully updated',
      },
    });

    return payload;
  };

export const updateTaskDetails =
  (projectId, taskId, objective) => (dispatch, getState) => {
    const state = getState();
    const {
      values: task,
      initial: initialTask,
      syncErrors,
    } = getFormFromState(state, TASK_FORMS.EDIT_TASK.FORM_ID).toJS();
    task[EDIT_TASK.TASK_OBJECTIVE] = objective?.objective;
    task[EDIT_TASK.TASK_DESCRIPTION] = objective?.description;
    task[EDIT_TASK.TASK_COMMENTS] = objective?.comments;
    if (!isEqual(task, initialTask) && !syncErrors) {
      const tasks = getAllTasksFromState(state).toJS();

      const removeContingencies = initialTask[
        EDIT_TASK.TASK_CONTINGENCIES
      ].filter(
        (contingency) =>
          !task[EDIT_TASK.TASK_CONTINGENCIES].includes(contingency),
      );

      const addContingencies = task[EDIT_TASK.TASK_CONTINGENCIES].filter(
        (contingency) =>
          !initialTask[EDIT_TASK.TASK_CONTINGENCIES].includes(contingency),
      );

      var addContingenciesOk = true;
      var sequencingOk = true;
      var lastSequence = task.sequence;
      // prevent if current task is already contigency
      if (task.contingencyForTaskId > 0 && addContingencies.length > 0) {
        addContingenciesOk = false;
      }

      var savedContigencies = tasks.filter(
        (t) => t.contingencyForTaskId === task.taskId,
      );
      // prevent removing contigencies from middle
      if (savedContigencies.length > 0) {
        lastSequence = Math.max(...savedContigencies.map((x) => x.sequence));
        removeContingencies.forEach((contingencyTaskId) => {
          var remContigency = tasks.find((t) => t.id === contingencyTaskId);

          if (
            lastSequence >=
            remContigency.sequence + removeContingencies.length
          ) {
            sequencingOk = false;
          }
        });
      }

      // 1 prevent if contigency for adding already have its own contigency
      // 2 at the same time check if some tasks are skipped by sequence number
      addContingencies.forEach((contingencyTaskId) => {
        var newContigency = tasks.find((t) => t.id === contingencyTaskId);
        if (
          newContigency.contingencyCount > 0 ||
          newContigency.contingencyForTaskId > 0
        ) {
          addContingenciesOk = false;
        }

        lastSequence = lastSequence + 1;

        if (lastSequence !== newContigency.sequence) {
          sequencingOk = false;
        }
      });

      if (addContingenciesOk && sequencingOk) {
        dispatch({
          taskId,
          type: TASK_ACTIONS.UPDATE_TASK_DETAILS,
          notification: {
            [NOTIFICATION_VARIANTS.SUCCESS]:
              'The task was successfully updated',
            [NOTIFICATION_VARIANTS.INFO]: 'Saving...',
          },
          payload: () =>
            Promise.all([
              dispatch(updateTask(projectId, taskId, task)),
              ...removeContingencies.map((contingencyTaskId) =>
                dispatch(
                  updateTask(projectId, contingencyTaskId, {
                    ...tasks.find((t) => t.id === contingencyTaskId),
                    [EDIT_TASK.TASK_CONTINGENCY_FOR_TASK]: null,
                  }),
                ),
              ),
              ...addContingencies.map((contingencyTaskId) =>
                dispatch(
                  updateTask(projectId, contingencyTaskId, {
                    ...tasks.find((t) => t.id === contingencyTaskId),
                    [EDIT_TASK.TASK_CONTINGENCY_FOR_TASK]: taskId,
                  }),
                ),
              ),
            ]).then((response) => {
              const [Task] = response;
              dispatch(receiveTask(Task));
              dispatch(getProjectById(projectId));
              dispatch(
                receiveTaskRiskSections(getTaskRiskSectionSelector(state)),
              );
              return Task;
            }),
        });
      } else if (!addContingenciesOk) {
        dispatch({
          taskId,
          type: TASK_ACTIONS.UPDATE_TASK_DETAILS,
          payload: () => {
            taskService.getTaskById(projectId, taskId).then((task) => {
              dispatch(receiveTask(task));
              dispatch(
                addNotification({
                  message: 'Adding contigencies to contigency not allowed',
                  variant: NOTIFICATION_VARIANTS.ERROR,
                }),
              );
            });
            return task;
          },
        });
      } else if (!sequencingOk) {
        dispatch({
          taskId,
          type: TASK_ACTIONS.UPDATE_TASK_DETAILS,
          payload: () => {
            taskService.getTaskById(projectId, taskId).then((task) => {
              dispatch(receiveTask(task));
              dispatch(
                addNotification({
                  message:
                    'Please follow order when assigning or removing contigencies, skipping tasks not allowed',
                  variant: NOTIFICATION_VARIANTS.ERROR,
                }),
              );
            });
            return task;
          },
        });
      }
    }
  };

export const deleteTaskAndRedirectToTasks = (projectId, taskId) => (dispatch) =>
  dispatch(
    deleteTask(projectId, taskId, () =>
      dispatch(replace(toProjectTasks(projectId))),
    ),
  );

export const copyAndRedirectToTask = (projectId, taskId) => (dispatch) =>
  dispatch(copyTask(projectId, taskId)).then(({ taskId }) =>
    dispatch(push(toProjectTasks(projectId, taskId))),
  );

export const updateTask = (projectId, taskId, task) => (dispatch) => {
  const payload = taskService.updateTask(projectId, taskId, task);

  dispatch({
    taskId: taskId,
    type: TASK_ACTIONS.UPDATE_TASK,
    payload,
  });

  return payload;
};

export const requestTaskRiskSections = (taskId) => (dispatch) => {
  dispatch({
    taskId,
    type: RISK_TABLE_ACTIONS.REQUEST_TASK_RISK_SECTIONS,
  });
};

export const requestCreateRiskSection =
  (taskId, risk, riskSection, formik, callback) => (dispatch) => {
    const { setStatus, setSubmitting } = formik;

    dispatch({
      callback,
      setStatus,
      taskId,
      risk,
      setSubmitting,
      payload: riskSection,
      type: RISK_TABLE_ACTIONS.REQUEST_CREATE_TASK_RISK_SECTION,
      notification: {
        success: 'Risk successfully created',
      },
    });
  };

export const requestUpdateRiskSection = (taskId, risk) => (dispatch) => {
  dispatch({
    taskId,
    risk,
    payload: risk,
    type: RISK_TABLE_ACTIONS.REQUEST_UPDATE_TASK_RISK_SECTION,
    notification: {
      success: 'Risk successfully updated',
    },
  });
};

export const requestDeleteRiskSection = (riskId, taskId) => (dispatch) => {
  dispatch({
    riskId,
    taskId,
    type: RISK_TABLE_ACTIONS.REQUEST_DELETE_TASK_RISK_SECTION,
    confirmationDialog: {
      title: 'Delete risk',
      confirmButtonText: 'Delete',
      description: 'Are you sure you want to delete this risk? ',
    },
    notification: {
      success: 'Risk was successfully deleted',
    },
  });
};

export const deleteTaskRiskSection = (RiskSectionId) => ({
  RiskSectionId,
  type: RISK_TABLE_ACTIONS.DELETE_TASK_RISK_SECTION,
});

export const receiveTaskRiskSection = (sections) => ({
  payload: sections,
  type: RISK_TABLE_ACTIONS.RECEIVE_TASK_RISK_SECTION,
});

export const receiveTaskRiskSections = (sections) => ({
  payload: sections,
  type: RISK_TABLE_ACTIONS.RECEIVE_TASK_RISK_SECTIONS,
});
