import { compose } from 'redux';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import withStyles from '@material-ui/styles/withStyles';

import { EMPTY_MAP } from 'app/app.constants';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import { requestUpdateSimulation } from 'features/projects/tasks/task/simulation/simulation.actions';
import { getSimulationFromState } from 'features/projects/tasks/task/simulation/simulation.selectors';
import { SimulationFormFields as FormFields } from 'features/projects/tasks/task/simulation/simulation.constants';
import EditorFormik from 'app/components/form/formik/EditorFormik';

const TaskSimulationCommentContainer = ({
  taskId,
  classes,
  projectId,
  simulationId,
  simulation = EMPTY_MAP,
  dispatchUpdateSimulation,
}) => {
  const onSubmit = useCallback(
    (simulation) =>
      dispatchUpdateSimulation(projectId, taskId, simulationId, simulation),
    [projectId, taskId, simulationId, dispatchUpdateSimulation],
  );

  const initialValues = simulation.toJS();

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <Form style={{ display: 'flex' }}>
        <AutoSaveFormik timeout={2000}>
          <Field
            minRows={10}
            label="Comment"
            maxRows={null}
            name={FormFields.COMMENT}
            component={EditorFormik}
            disabled={simulation.get('isPlanned')}
            classes={{
              root: classes.textAreaField,
            }}
            toolbar={{
              options: ['inline', 'list'],
            }}
            toolbarOnFocus
            minHeight={10}
            maxHeight={10}
          />
        </AutoSaveFormik>
      </Form>
    </Formik>
  );
};

const styles = (theme) => ({
  textAreaField: {
    '&:disabled': {
      color: theme.palette.text.primary,
    },
  },
});

export default compose(
  connect(
    (state, { simulationId }) => ({
      simulation: getSimulationFromState(state, simulationId),
    }),
    {
      dispatchUpdateSimulation: requestUpdateSimulation,
    },
  ),
  withStyles(styles),
)(TaskSimulationCommentContainer);
