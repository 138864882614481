import React from 'react';
import * as d3Scale from 'd3-scale';
import YAxis from 'app/components/WellboreContextualization/YAxis';
import YAxisLabels from 'app/components/WellboreContextualization/YAxisLabels';
import themes, { defaultThemeType } from 'layout/themes';

const theme = themes[defaultThemeType];

const DefaultTickInterval = 500;
const Grid = ({ maxTVD, max, min = 0, tickInterval = DefaultTickInterval }) => {
  const xTicks = getTicks({ min, max: max + tickInterval, tickInterval });
  const numTicks = (xTicks.length - 1) * 2;
  const length = xTicks[xTicks.length - 1] * 2;
  return (
    <>
      <gridHelper
        args={[
          length,
          numTicks,
          theme.palette.secondary.light,
          theme.palette.secondary.darkGrey,
        ]}
      />
      <YAxisLabels maxTVD={maxTVD} tickInterval={DefaultTickInterval} />
      <YAxis maxTVD={maxTVD} />
    </>
  );
};
export const getTicks = ({
  max,
  min = 0,
  tickInterval = DefaultTickInterval,
}) => {
  const scale = d3Scale.scaleLinear().domain([min, max]);
  const ticks = scale.ticks(Math.ceil(max / tickInterval));

  return ticks;
};

export default Grid;
