import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, AppBar, Toolbar, Typography } from '@material-ui/core';

import { IconButton } from 'app/components/withTooltip';

const styles = () => ({
  title: {
    flex: 1,
  },
  relative: {
    position: 'relative',
    outline: 'none',
  },
});

const ModalAppBar = ({
  title,
  classes,
  closeModal,
  onCancelClick,
  hideCloseButton,
}) => {
  return (
    <AppBar className={classes.relative} color="default">
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.title}
            >
              {title}
            </Typography>
          </Grid>
          {!hideCloseButton && (
            <Grid item>
              <IconButton
                title="Close"
                color="inherit"
                onClick={onCancelClick || closeModal}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(ModalAppBar);
