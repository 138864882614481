import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Dialog, DialogContent } from '@material-ui/core';

import CablePickerModalTable from 'features/projects/tool/components/CablePickerModalTable';
import CablePickerModalHeader from 'features/projects/tool/components/CablePickerModalHeader';

const CablePickerModal = ({
  open,
  cables,
  onClose,
  onEnter,
  classes,
  loading,
  onSelectCable,
}) => (
  <Dialog
    fullWidth
    open={open}
    maxWidth="lg"
    onClose={onClose}
    classes={{ paperScrollPaper: classes.root }}
    TransitionProps={{
      onEnter,
    }}
  >
    <Grid className={classes.header}>
      <CablePickerModalHeader onClose={onClose} />
    </Grid>
    <DialogContent className={classes.content}>
      <Grid item container className={classes.table}>
        <CablePickerModalTable
          cables={cables}
          loading={loading}
          onSelectCable={onSelectCable}
        />
      </Grid>
    </DialogContent>
  </Dialog>
);

const styles = (theme) => ({
  root: {
    height: '75vh',
  },
  content: {
    padding: `0px ${theme.spacing(3)}px`,
  },
  header: {
    padding: theme.spacing(3),
  },
  table: {
    paddingBottom: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(CablePickerModal);
