import { connect } from 'react-redux';
import { Button, DialogActions } from '@material-ui/core';

import LoadingButton from 'app/components/LoadingButton';

import { NotRequestedDataState, anyLoadingDataState } from 'altus-datastate';

import { getActionDataStateFromState } from 'app/app.selectors';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { ProjectPermission } from 'app/app.constants';
import {
  ACTIVITIES_ACTIONS,
  EDIT_ACTIVITY_FORM,
} from 'features/projects/activities/activities.constants';

const EditActivityModalActions = ({
  valid,
  index,
  taskId,
  projectId,
  submitting,
  toggleModal,
  handleSubmit,
  editDataState,
  activityTypeId,
  item: activity,
  deleteDataState,
  autosaveDataState,
  downloadDataState,
  dispatchDeleteActivity,
  dispatchDownloadActivityReport,
}) => {
  const loading = anyLoadingDataState([
    deleteDataState,
    editDataState,
    downloadDataState,
  ]);

  const isAutosaving = autosaveDataState.isLoading();

  if (activityTypeId === EDIT_ACTIVITY_FORM.WELL_CONTROL_DRILL_TYPE_ID) {
    return null;
  }

  return (
    <DialogActions>
      <Button onClick={toggleModal}>Cancel</Button>
      <HasProjectPermission permissions={ProjectPermission.DELETE_ACTIVITY}>
        <LoadingButton
          size="small"
          variant="contained"
          loadingText="Deleting"
          dataState={deleteDataState}
          disabled={loading}
          onClick={() =>
            dispatchDeleteActivity(projectId, taskId, activity.id, index)
          }
        >
          Delete
        </LoadingButton>
      </HasProjectPermission>
      <LoadingButton
        size="small"
        type="submit"
        color="primary"
        variant="contained"
        loadingText="Saving"
        form={`edit-activity-form-${index}`}
        disabled={submitting || !valid || loading}
        onClick={() => handleSubmit(projectId, taskId, false)}
        dataState={isAutosaving ? NotRequestedDataState : editDataState}
      >
        Save
      </LoadingButton>
      <LoadingButton
        size="small"
        type="submit"
        color="primary"
        variant="contained"
        loadingText="Exporting..."
        form={`edit-activity-form-${index}`}
        disabled={submitting || !valid || loading}
        onClick={() =>
          dispatchDownloadActivityReport(projectId, taskId, activity.id)
        }
        dataState={isAutosaving ? NotRequestedDataState : editDataState}
      >
        Save and Export PDF
      </LoadingButton>
    </DialogActions>
  );
};

export default connect(
  (_, initialProps) => (state) => {
    const { id } = initialProps.item;

    return {
      editDataState: getActionDataStateFromState(state, {
        type: ACTIVITIES_ACTIONS.UPDATE_ACTIVITY,
        id,
      }),
      deleteDataState: getActionDataStateFromState(state, {
        type: ACTIVITIES_ACTIONS.CONFIRM_DELETE_ACTIVITY,
        id,
      }),
      downloadDataState: getActionDataStateFromState(state, {
        type: ACTIVITIES_ACTIONS.DOWNLOAD_ACTIVITY_PDF,
        id,
      }),
    };
  },
  null,
)(EditActivityModalActions);
