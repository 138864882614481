import CloseIcon from '@material-ui/icons/Close';
import { Grid, IconButton } from '@material-ui/core';

const EditToolImageModalHeader = ({ toggleModal }) => {
  return (
    <Grid item container justifyContent="flex-end" alignItems="center">
      <IconButton onClick={toggleModal}>
        <CloseIcon fontSize="large" />
      </IconButton>
    </Grid>
  );
};

export default EditToolImageModalHeader;
