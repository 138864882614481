import { Grid, Typography } from '@material-ui/core';

import { EMPTY_LIST } from 'app/app.constants';
import PaperListItem from 'app/components/PaperListItem';
import MobilisationTasksMenuItem from 'features/projects/mobilisation/MobilisationTasksMenuItem';

const MobilisationTasksMenu = ({
  selectedTask,
  tasks = EMPTY_LIST,
  handleTaskSelection,
}) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <PaperListItem isHeader>
          <Typography variant="subtitle2">Tasks</Typography>
        </PaperListItem>
      </Grid>
      <Grid xs={12} item container>
        {tasks.map((task, index) => {
          return (
            <MobilisationTasksMenuItem
              task={task}
              key={index}
              selectedTask={selectedTask}
              handleTaskSelection={handleTaskSelection}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

export default MobilisationTasksMenu;
