import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReadOnlyField from 'app/components/ReadOnlyField';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const Sil2TestDrawerComponent = ({ assetHistory, classes }) => {
  const section = assetHistory.getIn(['sil2Test', 'assetBop']);
  const bcs = assetHistory.getIn(['sil2Test', 'assetBcs']);
  const cabin = assetHistory.getIn(['sil2Test', 'assetCabin']);

  return (
    <>
      {!assetHistory && !section ? null : (
        <Grid item xs={12} className={classes.paperMargin}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid className={classes.marginLabel}>
                <CustomReadOnlyField
                  margin="none"
                  label="Serial Number BOP"
                  className={classes.marginLabel}
                >
                  {section.get('serialNumber')}
                </CustomReadOnlyField>
              </Grid>
              <Grid className={classes.marginLabel}>
                <CustomReadOnlyField
                  margin="none"
                  label="Serial Number BCS"
                  className={classes.marginLabel}
                >
                  {bcs.get('serialNumber')}
                </CustomReadOnlyField>
              </Grid>
              <Grid className={classes.marginLabel}>
                <CustomReadOnlyField
                  margin="none"
                  label="Serial Number Cabin"
                  className={classes.marginLabel}
                >
                  {cabin.get('serialNumber')}
                </CustomReadOnlyField>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classes.marginLabel}>
                <CustomReadOnlyField
                  margin="none"
                  label="Item Number BOP"
                  className={classes.marginLabel}
                >
                  {section.get('itemNumber')}
                </CustomReadOnlyField>
              </Grid>
              <Grid className={classes.marginLabel}>
                <CustomReadOnlyField
                  margin="none"
                  label="Item Number BCS"
                  className={classes.marginLabel}
                >
                  {bcs.get('itemNumber')}
                </CustomReadOnlyField>
              </Grid>
              <Grid className={classes.marginLabel}>
                <CustomReadOnlyField
                  margin="none"
                  label="Item Number Cabin"
                  className={classes.marginLabel}
                >
                  {cabin.get('itemNumber')}
                </CustomReadOnlyField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: '4vh',
  },
  marginLabel: {
    marginTop: '4vh',
  },
  marginStrand: {
    marginBottom: '2vh',
  },
});

export default withStyles(styles)(Sil2TestDrawerComponent);
