export const OPERATIONS_ACTIONS = {
  CLEAR_OPERATIONS: 'CLEAR_OPERATIONS',
  SEARCH_OPERATIONS: 'SEARCH_OPERATIONS',
  RECEIVE_OPERATION: 'RECEIVE_OPERATION',
  RECEIVE_OPERATIONS: 'RECEIVE_OPERATIONS',
  RECEIVE_OPERATIONS_LIVE_PROJECTS: 'RECEIVE_OPERATIONS_LIVE_PROJECTS',
  UPDATE_CHEETAH_JOB: 'UPDATE_CHEETAH_JOB',
  RECEIVE_CHEETAH_JOB: 'RECEIVE_CHEETAH_JOB',
  SEARCH_CHEETAH_JOBS: 'SEARCH_CHEETAH_JOBS',
  RECEIVE_CHEETAH_JOBS: 'RECEIVE_CHEETAH_JOBS',
  UPDATE_PROJECT_CHEETAH_JOB_STATUS: 'UPDATE_PROJECT_CHEETAH_JOB_STATUS',
};

export const OPERATIONS_VIEWS = {
  TILES: 'TILES',
  LIST: 'LIST',
};

export const STATE = {
  PAGE_PARAMS: 'pageParams',
  PAGE_RESULTS: 'pageResults',
};
