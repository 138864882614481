import { compose } from 'redux';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import { useHeader } from 'app/hooks/useHeader';
import { getActionDataStateFromState } from 'app/app.selectors';
import { EMPTY_LIST } from 'app/app.constants';
import { PROJECT_ASSET_TEST_ACTIONS } from 'features/projects/assets/assetTest.constants';

import {
  onAssetTestLoad,
  onAssetTestUnload,
} from 'features/projects/assets/assetTest.actions';
import { getAssetTestFromState } from 'features/projects/assets/assetTest.selectors';

import AssetHistoryTable from 'features/equipment/assets/components/AssetHistoryTable';

const AssetTestContainer = ({
  assetTests = EMPTY_LIST,
  classes,
  dataState,
  projectId,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
}) => {
  useEffect(() => {
    dispatchOnLoad(projectId);
    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload, projectId]);

  useHeader({
    subTitle: breadcrumb,
  });

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs justifyContent="center">
        <Grid item xs={8}>
          <AssetHistoryTable assets={assetTests} hiddenColumns={['project']} />
        </Grid>
      </Grid>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  assetTests: getAssetTestFromState(state),
  dataState: getActionDataStateFromState(
    state,
    PROJECT_ASSET_TEST_ACTIONS.PROJECT_ASSET_TEST_PAGE_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onAssetTestLoad,
  dispatchOnUnload: onAssetTestUnload,
};

const styles = (theme) => ({
  create: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AssetTestContainer);
