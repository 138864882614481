import { Grid } from '@material-ui/core';
import { useState, useCallback } from 'react';

import { EMPTY_LIST, EMPTY_STRING } from 'app/app.constants';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import CheetahJobProjectSelectModal from 'features/data-exchange/components/CheetahJobProjectSelectModal';

const CheetahJobProjectSelectField = ({
  form,
  field,
  disabled,
  cheetahJob,
}) => {
  const { name } = field;
  const { setFieldValue } = form;

  const [isOpen, setModalOpen] = useState(false);

  const onClose = useCallback(() => setModalOpen(false), []);

  const onSelectProject = useCallback(
    (project) => {
      setFieldValue(name, project.get('projectId'));
      onClose();
    },
    [name, onClose, setFieldValue],
  );

  const onClearProject = useCallback(() => {
    setFieldValue(name, null);
    onClose();
  }, [name, onClose, setFieldValue]);

  const renderValue = useCallback(
    () => cheetahJob.getIn(['project', 'projectId']),
    [cheetahJob],
  );

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <TableRowTextFieldFormik
        select
        name="projectId"
        disabled={disabled}
        value={EMPTY_STRING}
        SelectProps={{
          open: false,
          renderValue,
          displayEmpty: true,
          onOpen: () => setModalOpen(true),
        }}
      >
        {EMPTY_LIST.toArray()}
      </TableRowTextFieldFormik>
      <CheetahJobProjectSelectModal
        open={isOpen}
        onClose={onClose}
        onClearProject={onClearProject}
        onSelectProject={onSelectProject}
        cheetahJobSource={cheetahJob.get('source')}
      />
    </Grid>
  );
};

export default CheetahJobProjectSelectField;
