import { useEffect } from 'react';
import { compose } from 'redux';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';
import { useHeader } from 'app/hooks/useHeader';
import { getToolProjectHistoryFromState } from 'features/projects/tool/tool.selector';

import ProjectsByStatus from 'features/projects/components/ProjectsByStatus';
import { TOOL_ACTIONS } from 'features/projects/tool/tool.constants';
import { loadToolProjectHistory } from 'features/projects/tool/tool.actions';
import { getActionDataStateFromState } from 'app/app.selectors';

const ToolProjectHistoryContainer = ({
  equipmentId,
  classes,
  projects,
  dataState,
  breadcrumb,
  dispatchOnLoad,
}) => {
  useHeader({ subTitle: breadcrumb });
  useEffect(() => {
    dispatchOnLoad(equipmentId);
  }, [equipmentId, dispatchOnLoad]);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid item container justifyContent="center">
        {!projects?.size ? (
          <Grid item xs md={8}>
            <Typography>No projects...</Typography>
          </Grid>
        ) : (
          <Grid item xs md={8}>
            <ProjectsByStatus projects={projects} />
          </Grid>
        )}
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(
    (state) => ({
      projects: getToolProjectHistoryFromState(state),
      dataState: getActionDataStateFromState(
        state,
        TOOL_ACTIONS.RECEIVE_TOOL_PROJECT_HISTORY,
      ),
    }),
    {
      dispatchOnLoad: loadToolProjectHistory,
    },
  ),
  withStyles(styles),
)(ToolProjectHistoryContainer);
