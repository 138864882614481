import { fromJS, List } from 'immutable';

import { EMPTY_LIST } from 'app/app.constants';
import nptMappers from 'features/projects/npt/npt.mappers';
import { NPT_ACTIONS } from 'features/projects/npt/npt.constants';

const initialState = fromJS({
  data: EMPTY_LIST,
});

const nptReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case NPT_ACTIONS.PROJECT_NON_PRODUCTIVE_TIME_REPORT_LOADED: {
      if (action.error) return state;

      return state.set('data', List(action.payload).map(nptMappers.NPT.from));
    }
    case NPT_ACTIONS.EDIT_PROJECT_NON_PRODUCTIVE_TIME: {
      if (action.error) return state;

      const { projectNonProductiveTimeId } = action;

      return state.update('data', (npts) => {
        const index = npts.findIndex(
          (npt) =>
            npt.get('projectNonProductiveTimeId') ===
            projectNonProductiveTimeId,
        );

        return npts.set(index, nptMappers.NPT.from(action.payload));
      });
    }
    case NPT_ACTIONS.DELETE_PROJECT_NON_PRODUCTIVE_TIME: {
      if (action.error) return state;
      return state.update('data', (npts) =>
        npts.filterNot(
          (npt) =>
            npt.get('projectNonProductiveTimeId') ===
            action.projectNonProductiveTimeId,
        ),
      );
    }
    default:
      return state;
  }
};

export default nptReducer;
