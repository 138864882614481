import withStyles from '@material-ui/styles/withStyles';
import { Button, CircularProgress } from '@material-ui/core';

import { NotRequestedDataState } from 'altus-datastate';

const styles = (theme) => ({
  button: {
    color: theme.altus.button.progress,
    marginLeft: 15,
  },
});

const LoadingButton = ({
  loadingText,
  submitting,
  dataState,
  children,
  disabled,
  classes,
  btnRef,
  ...rest
}) => {
  const isLoading = dataState.isLoading() || submitting;

  return (
    <Button buttonRef={btnRef} disabled={isLoading || disabled} {...rest}>
      {isLoading ? loadingText || children : children}
      {isLoading && <CircularProgress className={classes.button} size={20} />}
    </Button>
  );
};

LoadingButton.defaultProps = {
  dataState: NotRequestedDataState,
};

export default withStyles(styles)(LoadingButton);
