import classNames from 'classnames';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, Chip } from '@material-ui/core';

import { EMPTY_LIST } from 'app/app.constants';
import useTaskFailReasons from 'features/projects/hooks/useTaskFailReasons';

// Obsolete, use FailureReasonFieldFormik instead
const FailureReasonField = ({ classes, input, disabled, required }) => {
  const { value, onChange } = input;

  const [taskFailReasons = EMPTY_LIST] = useTaskFailReasons();

  return (
    <Grid item xs={12}>
      <Typography variant="body1">{`Please select reason ${
        required ? '*' : ''
      }`}</Typography>
      <Typography variant="caption" className={classes.subHeader}>
        (Select one)
      </Typography>
      <Grid container spacing={2}>
        {taskFailReasons.map((reason) => {
          const reasonId = reason.get('id');

          return (
            <Grid key={reasonId} item>
              <Chip
                label={reason.get('name')}
                clickable={!disabled}
                color={value === reasonId ? 'primary' : 'default'}
                onClick={
                  disabled
                    ? undefined
                    : () => onChange(value === reasonId ? null : reasonId)
                }
                classes={{
                  root: classNames({
                    [classes.disabledChip]: disabled,
                  }),
                }}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  subHeader: {
    marginBottom: 10,
  },
  disabledChip: {
    opacity: 0.7,
  },
});

export default withStyles(styles)(FailureReasonField);
