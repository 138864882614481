import { compose } from 'redux';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';

import { BasePage } from 'altus-ui-components';

import { updateWellboreDetail } from 'features/wells/wellbore.actions';

import { useHeader } from 'app/hooks/useHeader';
import useWellbore from 'features/wells/hooks/useWellbore';
import { getActionDataStateFromState } from 'app/app.selectors';
import { WELLS_ACTIONS as WELLBORE_ACTIONS } from 'features/wells/wells.constants';
import { WellboreDetailsAndFluids } from 'app/components/WellboreDetailsForm/WellboreDetailsForm';

const WellboreDetailsContainer = ({
  fieldId,
  dataState,
  wellboreId,
  breadcrumb,
  dispatchUpdateWellboreDetail,
}) => {
  useHeader({
    subTitle: breadcrumb,
  });

  const { wellboreDetail } = useWellbore(wellboreId);

  const updateWellboreDetail = useCallback(
    (wellboreDetails) =>
      dispatchUpdateWellboreDetail(fieldId, wellboreId, wellboreDetails),
    [fieldId, wellboreId, dispatchUpdateWellboreDetail],
  );

  return (
    <BasePage dataState={dataState}>
      <Grid container justifyContent="center">
        <Grid container item xs={12} md={8}>
          <WellboreDetailsAndFluids
            onSubmit={updateWellboreDetail}
            wellboreDetail={wellboreDetail}
            wellboreId={wellboreId}
          />
        </Grid>
      </Grid>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getActionDataStateFromState(
    state,
    WELLBORE_ACTIONS.WELLBORE_CONTAINER_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchUpdateWellboreDetail: updateWellboreDetail,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  WellboreDetailsContainer,
);
