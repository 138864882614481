import { compose } from 'redux';
import { List } from 'immutable';
import { useState, useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Dialog, DialogContent, Button } from '@material-ui/core';

import { useReducerWithDataState } from 'altus-hooks';
import { ModalHeader, LoadingOverlay, ModalActions } from 'altus-ui-components';

import {
  clearProjects,
  receiveProjects,
} from 'features/projects/projects.actions';

import ProjectFilter, {
  Filters as ProjectFilters,
} from 'features/projects/components/ProjectFilter';

import mappers from 'mappers';
import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import operationService from 'services/operation.service';
import projectReducer from 'features/projects/project.entity.reducer';
import { toProjectStatusFromDto } from 'features/projects/projects.mappers';
import ProjectSelectModalTable from 'features/data-exchange/components/ProjectSelectModalTable';

const filters = [
  ProjectFilters.FACILITY_ID,
  ProjectFilters.TEXT_SEARCH,
  ProjectFilters.PROJECT_STATUS,
  ProjectFilters.ORGANIZATION_ID,
];

const CheetahJobProjectSelectModal = ({
  open,
  classes,
  onClose,
  onClearProject,
  onSelectProject,
  cheetahJobSource,
}) => {
  const [facilities, setFacilities] = useState(EMPTY_LIST);
  const [organizations, setOrganizations] = useState(EMPTY_LIST);
  const [projectStatuses, setProjectStatuses] = useState(EMPTY_LIST);

  const [getAvailableProjects, projects, dispatch, dataState] =
    useReducerWithDataState(
      operationService.getAvailableProjectsForCheetahJobs,
      projectReducer,
      EMPTY_MAP,
    );

  const dispatchClearProjects = useCallback(
    () => dispatch(clearProjects()),
    [dispatch],
  );

  const dispatchGetAvailableProjects = useCallback(
    (filter) =>
      getAvailableProjects(filter, cheetahJobSource).then((projects) =>
        dispatch(receiveProjects(projects)),
      ),
    [dispatch, getAvailableProjects, cheetahJobSource],
  );

  const onEnter = useCallback(() => {
    operationService
      .getCheetahJobsProjectFilter(cheetahJobSource)
      .then((filters) => {
        const { facilities, projectStatuses, organizations } = filters;

        setFacilities(List(facilities.map(mappers.Facility.from)));
        setOrganizations(List(organizations.map(mappers.Organization.from)));
        setProjectStatuses(List(projectStatuses.map(toProjectStatusFromDto)));
      });
  }, [cheetahJobSource]);

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="lg"
      onClose={onClose}
      classes={{
        paperScrollPaper: classes.root,
      }}
      TransitionProps={{
        onEnter,
        onExited: dispatchClearProjects,
      }}
    >
      <ModalHeader title="Select project" toggleModal={onClose} />
      <DialogContent className={classes.dialogContent}>
        <ProjectFilter
          filters={filters}
          facilities={facilities}
          statuses={projectStatuses}
          organizations={organizations}
          onSubmit={dispatchGetAvailableProjects}
        />
        <LoadingOverlay dataState={dataState} timeout={0} />
        <ProjectSelectModalTable
          projects={projects}
          onRowClick={onSelectProject}
        />
      </DialogContent>
      <ModalActions justifyContent="flex-start">
        <Button size="small" color="default" onClick={onClearProject}>
          Clear project
        </Button>
      </ModalActions>
    </Dialog>
  );
};

const styles = () => ({
  root: {
    height: '75vh',
  },
  dialogContent: {
    position: 'relative',
  },
});

export default compose(withStyles(styles))(CheetahJobProjectSelectModal);
