import mappers from 'mappers';
import { EMPTY_MAP } from 'app/app.constants';
import { PROJECT_WELLBORE_ACTIONS } from 'features/projects/wellbore/projectWellbore.constants';

const initialState = EMPTY_MAP;

const projectWellboreReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case PROJECT_WELLBORE_ACTIONS.RECEIVE_PROJECT_WELLBORE: {
      if (error) return state;

      return state.merge(mappers.Wellbore.from(payload));
    }

    case PROJECT_WELLBORE_ACTIONS.RECEIVE_PROJECT_WELLBORE_TRAJECTORY: {
      if (error) return state;

      return state.set('trajectory', mappers.Trajectory.from(payload));
    }

    default:
      return state;
  }
};

export default projectWellboreReducer;
