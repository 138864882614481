import { useEffect, memo, useCallback, useState, useMemo } from 'react';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { BasePage } from 'altus-ui-components';
import withStyles from '@material-ui/styles/withStyles';
import {
  getCurrentClientOrganizationIdFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';
import { getProjectsFromState } from 'features/projects/projects.selectors';
import { getFieldsFromState } from 'features/wells/wellbore.selectors';
import { Grid } from 'app/components/withTooltip';
import ProjectsByStatus from 'features/projects/components/ProjectsByStatus';
import {
  onLoad,
  onUnload,
  updateFilter,
} from 'features/projects/projects.actions';
import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';
import { PROJECT_STATUS } from 'app/app.constants';
import { Filters } from 'features/projects/components/ProjectFilter';
import { useHeader } from 'app/hooks/useHeader';

const allStatuses = [
  // PROJECT_STATUS.PLAN,
  // PROJECT_STATUS.REPORT,
  PROJECT_STATUS.EXECUTE,
];

const MobileProjectsListContainer = ({
  classes,
  dataState,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchUpdateFilter,
  isDrawer = false,
  toggleProjectsList,
}) => {
  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromState,
  );

  useHeader({
    subTitle: breadcrumb,
  });

  const filter = useMemo(
    () => ({
      [Filters.PROJECT_STATUS]: '',
      [Filters.FACILITY_ID]: '',
      [Filters.TEXT_SEARCH]: '',
      [Filters.GET_LOCATIONS]: true,
      [Filters.IS_ARCHIVE]: false,
      [Filters.PROJECT_STATUSES]: allStatuses,
      [Filters.GANTT_VIEW]: false,
    }),
    [],
  );

  const updateProjectFilter = useCallback(
    (newSelected) => {
      const { Plan, Report, Execute } = newSelected;
      let selectedStatus = [];

      if (!Plan && !Report && !Execute) {
        filter[Filters.PROJECT_STATUSES] = allStatuses;
        setCurrentFilter(filter);
        dispatchUpdateFilter(filter);
        return;
      }

      if (Plan) selectedStatus.push(PROJECT_STATUS.PLAN);
      if (Report) selectedStatus.push(PROJECT_STATUS.REPORT);
      if (Execute) selectedStatus.push(PROJECT_STATUS.EXECUTE);

      filter[Filters.PROJECT_STATUSES] = selectedStatus;
      setCurrentFilter(filter);
      dispatchUpdateFilter(filter);
    },
    [dispatchUpdateFilter, filter],
  );

  useEffect(() => {
    dispatchOnLoad(currentClientOrganizationId);
    updateProjectFilter({});

    return () => {
      dispatchOnUnload();
    };
  }, [
    dispatchOnLoad,
    dispatchOnUnload,
    currentClientOrganizationId,
    updateProjectFilter,
  ]);

  const [_currentFilter, setCurrentFilter] = useState();
  const currentFields = useSelector(getFieldsFromState);
  const currentProjects = useSelector(getProjectsFromState);

  const [_fields, setFields] = useState(currentFields);
  const [projects, setProjects] = useState(currentProjects);

  useEffect(() => {
    setFields(currentFields);
    setProjects(currentProjects);
  }, [currentFields, currentProjects, projects]);

  const renderProjectsTable = useCallback(() => {
    if (!projects.size && dataState.isLoading()) return null;

    if (!projects.size) {
      return (
        <Grid item xs container alignItems="center" justifyContent="center">
          No projects available
        </Grid>
      );
    }
    return (
      <ProjectsByStatus
        projects={projects}
        isMobileAppView={true}
        toggleProjectsList={toggleProjectsList}
      />
    );
  }, [projects, dataState, toggleProjectsList]);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: isDrawer ? classes.basePageDrawer : classes.basePageChildren,
      }}
    >
      {renderProjectsTable()}
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    padding: 0,
  },
  basePageDrawer: {
    minHeight: '100vh',
    height: '100%',
    padding: 0,
    paddingBottom: theme.spacing(6),
  },
  content: {
    top: 0,
    bottom: 0,
    overflow: 'auto',
    position: 'absolute',
  },
});

export default compose(
  connect(
    (state) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        PROJECTS_ACTIONS.PROJECTS_PAGE_LOADED,
      ),
    }),
    {
      dispatchOnLoad: onLoad,
      dispatchOnUnload: onUnload,
      dispatchUpdateFilter: updateFilter,
    },
  ),
  memo,
  withStyles(styles),
)(MobileProjectsListContainer);
