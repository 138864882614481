import { useCallback } from 'react';

import { useRequest } from 'altus-hooks';

import projectService from 'services/project.service';

const UseFacilityProjectDecks = (projectId) => {
    const getFacilityProjectDecks = useCallback(
        () =>
            projectService
                .getFacilityDecksByProjectId(projectId)
                .then((facility) =>
                    facility,
                ),
        [projectId],
    );

    return useRequest(getFacilityProjectDecks);
};

export default UseFacilityProjectDecks;