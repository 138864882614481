import { useMemo } from 'react';
import { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { SortableTable, TableRowActionsCell } from 'altus-ui-components';

import { EMPTY_LIST } from 'app/app.constants';

import ProjectRiskRow from 'features/projects/details/projectRisk/ProjectRiskRow';

const ProjectRiskByTaskTable = ({ items = EMPTY_LIST, projectId }) => {
  const columns = useMemo(
    () => [
      {
        xs: 0,
        id: 'expansionIcon',
        Header: <TableRowActionsCell minItems={0} />,
        Cell: () => (
          <TableRowActionsCell>
            <ExpandMoreIcon />
          </TableRowActionsCell>
        ),
      },
      {
        xs: 12,
        Header: 'Task',
        accessor: (task) => task.get('title'),
        Cell: ({ row }) => {
          const task = row.original;

          return (
            <Typography noWrap variant="inherit">
              {task.get('title')}
            </Typography>
          );
        },
      },
    ],
    [],
  );

  const renderTableRowComponent = useCallback(
    ({ item, ...rest }) => {
      const TableRowComponent = ProjectRiskRow;
      return <TableRowComponent {...rest} item={item} projectId={projectId} />;
    },
    [projectId],
  );

  return (
    <SortableTable
      useDragHandle
      items={items}
      columns={columns}
      useGlobalFilter={false}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

const styles = () => ({
  taskTitleLink: {
    display: 'inline',
  },
});

export default withStyles(styles)(ProjectRiskByTaskTable);
