import { compose } from 'redux';
import { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import { EMPTY_LIST, EMPTY_STRING } from 'app/app.constants';
import { getActionDataStateFromState } from 'app/app.selectors';

import {
  onLoadSurfaceEquipment,
  searchSurfaceEquipment,
  searchEquipmentTemplates,
} from 'features/equipment/equipment.actions';

import {
  getSurfaceEquipmentFromState,
  getEquipmentTemplatesFromState,
  getSurfaceEquipmentGroupsFromState,
} from 'features/equipment/equipment.selectors';

import { EQUIPMENT_ACTIONS } from 'features/equipment/equipment.constants';
import { Filters } from 'features/equipment/surfaceEquipment/components/SurfaceEquipmentFilter';
import AddSurfaceEquipmentInPlanningModal from 'features/projects/tasks/task/surfaceEquipment/components/surfaceEquipment/AddSurfaceEquipmentInPlanningModal';

const defaultFormValues = {
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
  [Filters.TOOL_GROUPS]: EMPTY_LIST,
  [Filters.SERIAL_SEARCH]: EMPTY_STRING,
};

const AddSurfaceEquipmentInPlanningModalContainer = ({
  tools,
  isOpen,
  classes,
  dataState,
  templates,
  toolGroups,
  toggleModal,
  dispatchOnLoad,
  onRemoveSurface,
  dispatchOnSearch,
  onSaveSurfaceEquipment,
  selectedSurfaceEquipment,
  dispatchOnSearchTemplates,
  setSelectedSurfaceEquipment,
  showTemplateSelection = false,
  onSaveSurfaceEquipmentTemplate,
}) => {
  const handleItemSearch = useCallback(
    (filterValues) => {
      dispatchOnSearch({
        [Filters.TEXT_SEARCH]: filterValues[Filters.TEXT_SEARCH],
        [Filters.SERIAL_SEARCH]: filterValues[Filters.SERIAL_SEARCH],
        [Filters.TOOL_GROUPS]:
          filterValues[Filters.TOOL_GROUPS] === '' ||
          filterValues[Filters.TOOL_GROUPS] === EMPTY_LIST
            ? null
            : [filterValues[Filters.TOOL_GROUPS]],
        getByStatus: true, // filter by un/servicable
      });
    },
    [dispatchOnSearch],
  );

  const handleTemplateSearch = useCallback(
    (filterValues) => {
      dispatchOnSearchTemplates({
        [Filters.TEXT_SEARCH]: filterValues[Filters.TEXT_SEARCH],
      });
    },
    [dispatchOnSearchTemplates],
  );

  useEffect(() => {
    dispatchOnLoad();
    dispatchOnSearchTemplates({
      [Filters.TEXT_SEARCH]: EMPTY_STRING,
    });
  }, [dispatchOnLoad, dispatchOnSearchTemplates]);

  return (
    <AddSurfaceEquipmentInPlanningModal
      tools={tools}
      isOpen={isOpen}
      classes={classes}
      dataState={dataState}
      toolGroups={toolGroups}
      toggleModal={toggleModal}
      equipmentTemplates={templates}
      onSubmitItem={handleItemSearch}
      onSubmitTemplate={handleTemplateSearch}
      showTemplateSelection={showTemplateSelection}
      onRemoveSurface={onRemoveSurface}
      initialValues={defaultFormValues}
      onSaveSurfaceEquipment={onSaveSurfaceEquipment}
      selectedSurfaceEquipment={selectedSurfaceEquipment}
      setSelectedSurfaceEquipment={setSelectedSurfaceEquipment}
      onSaveSurfaceEquipmentTemplate={onSaveSurfaceEquipmentTemplate}
    />
  );
};

export default compose(
  connect(
    (state) => ({
      tools: getSurfaceEquipmentFromState(state),
      templates: getEquipmentTemplatesFromState(state),
      toolGroups: getSurfaceEquipmentGroupsFromState(state),
      dataState: getActionDataStateFromState(
        state,
        EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_LOADED,
        EQUIPMENT_ACTIONS.EQUIPMENT_TEMPLATES_LOADED,
        EQUIPMENT_ACTIONS.RECEIVE_SURFACE_EQUIPMENT_GROUPS,
      ),
    }),
    {
      dispatchOnLoad: onLoadSurfaceEquipment,
      dispatchOnSearch: searchSurfaceEquipment,
      dispatchOnSearchTemplates: searchEquipmentTemplates,
    },
  ),
)(AddSurfaceEquipmentInPlanningModalContainer);
