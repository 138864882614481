export const DOCUMENTS_ACTIONS = {
  UPDATE_PROJECT_FILE: 'UPDATE_PROJECT_FILE',
  UPLOAD_PROJECT_FILE: 'UPLOAD_PROJECT_FILE',
  DELETE_PROJECT_FILE: 'DELETE_PROJECT_FILE',
  RECEIVE_PROJECT_FILE: 'RECEIVE_PROJECT_FILE',
  RECEIVE_PROJECT_FILES: 'RECEIVE_PROJECT_FILES',
  DOWNLOAD_PROJECT_FILE: 'DOWNLOAD_PROJECT_FILE',
  UPLOAD_FILE_MODAL_UNLOADED: 'UPLOAD_FILE_MODAL_UNLOADED',
  SET_DOCUMENT_CATEGORY_FILTER: 'SET_DOCUMENT_CATEGORY_FILTER',
  PROJECT_DOCUMENTS_PAGE_LOADED: 'PROJECT_DOCUMENTS_PAGE_LOADED',
  PROJECT_DOCUMENTS_PAGE_UNLOADED: 'PROJECT_DOCUMENTS_PAGE_UNLOADED',
  GET_ALL_CHECKLISTS_AND_PROCEDURES: 'GET_ALL_CHECKLISTS_AND_PROCEDURES',
  RECEIVE_CHECKLISTS_AND_PROCEDURES: 'RECEIVE_CHECKLISTS_AND_PROCEDURES',
};

export const CHECKLISTS_AND_PROCEDURES_FILTER_SOURCE = {
  COMPANY: 'COMPANY',
  SERVICE: 'SERVICE',
};

export const CHECKLISTS_AND_PROCEDURES_SOURCE = {
  COMPANY: 1,
  SERVICE: 2,
};

export const INTERNAL_CATEGORIES = {
  INTERNAL: 'Internal',
  CHECKLISTS: 'Checklists',
  PROCEDURES: 'Procedures',
};
