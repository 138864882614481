import { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  updateCheetahJob,
  searchCheetahJobs,
} from 'features/operations/operations.actions';

import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { OPERATIONS_ACTIONS } from 'features/operations/operations.constants';
import { createFilteredCheetahJobsSelector } from 'features/operations/cheetahJob.selectors';

const useCheetahJobs = (filter) => {
  const dispatch = useDispatch();

  const dispatchSearchCheetahJobs = useCallback(
    (filter) => dispatch(searchCheetahJobs(filter)),
    [dispatch],
  );

  const dispatchUpdateCheetahJob = useCallback(
    (cheetahJob) => dispatch(updateCheetahJob(cheetahJob.id, cheetahJob)),
    [dispatch],
  );

  const selector = useMemo(
    () => createFilteredCheetahJobsSelector(filter),
    [filter],
  );

  const dataState = useSelector((state) =>
    getSummarizedDataStateFromState(
      state,
      OPERATIONS_ACTIONS.UPDATE_CHEETAH_JOB,
      OPERATIONS_ACTIONS.SEARCH_CHEETAH_JOBS,
    ),
  );

  const cheetahJobs = useSelector(selector);

  return {
    dataState,
    cheetahJobs,
    updateCheetahJob: dispatchUpdateCheetahJob,
    searchCheetahJobs: dispatchSearchCheetahJobs,
  };
};

export default useCheetahJobs;
