import { useState } from 'react';
import { Field, useFormikContext, ErrorMessage } from 'formik';
import { MenuItem, IconButton } from '@material-ui/core';
import { DialogContentText } from '@material-ui/core';
import CommentIcon from '@material-ui/icons/Comment';

import {
  Dialog,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

import { EMPTY_STRING } from 'app/app.constants';
import { invokeIfFunction } from 'utils/app.util';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';

const SelectTextFieldFormik = ({
  name,
  items,
  label,
  getItemName,
  getItemValue,
  emptyText = 'All',
  displayEmpty = true,
  otherOption = -1,
  dialogFormName,
  iconsMapping,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const { setFieldValue, values } = useFormikContext();
  const showMoreDetails = values[name] !== otherOption || !dialogFormName;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{'Please provide description'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To continue, please enter the description here.
          </DialogContentText>
          <Field
            autoFocus
            margin="dense"
            name={dialogFormName}
            id={dialogFormName}
            label="Description"
            type="text"
            component={TextFieldFormik}
            fullWidth
          />
          <ErrorMessage name={dialogFormName}>
            {(msg) => <div style={{ color: 'red' }}>{msg}</div>}
          </ErrorMessage>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <Field
          select
          fullWidth={showMoreDetails}
          style={{ width: !showMoreDetails ? '90%' : '100%' }}
          name={name}
          label={label}
          component={TextFieldFormik}
          onChange={(event) => {
            if (event.target.value === otherOption) {
              handleOpen();
            }
            setFieldValue(name, event.target.value);
          }}
          SelectProps={{
            displayEmpty,
          }}
          {...rest}
        >
          {displayEmpty && (
            <MenuItem value={EMPTY_STRING}>
              <i>{emptyText}</i>
            </MenuItem>
          )}
          {items.valueSeq().map((item) => {
            const name = getItemName
              ? invokeIfFunction(getItemName, item)
              : item;
            const value = getItemValue
              ? invokeIfFunction(getItemValue, item)
              : item;
            return (
              <MenuItem key={value} value={value}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {iconsMapping && iconsMapping[name] && (
                    <img
                      src={iconsMapping[name]}
                      alt={`${name} icon`}
                      style={{ marginRight: '5px' }}
                    />
                  )}
                  {name}
                </div>
              </MenuItem>
            );
          })}
        </Field>

        {showMoreDetails ? null : (
          <Tooltip title="Show more details">
            <IconButton
              edge="end"
              onClick={handleOpen}
              style={{ alignSelf: 'flex-end', transform: 'translateY(27%)' }}
            >
              <CommentIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default SelectTextFieldFormik;
