import React from 'react';
import Add from '@material-ui/icons/Add';
import PlayArrow from '@material-ui/icons/PlayArrow';
import { connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import {
  ACTIVITIES_ACTIONS,
  CREATE_ACTIVITY_MODE,
} from 'features/projects/activities/activities.constants';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { ProjectPermission, WORK_ITEM_STATUS } from 'app/app.constants';
import { Button, Grid } from '@material-ui/core';
import LoadingButton from 'app/components/LoadingButton';
import { getActionDataStateFromState } from 'app/app.selectors';
import Done from '@material-ui/icons/Done';

const ActivityDetailsBottomBar = ({
  classes,
  toggleCreateActivityModal,
  next,
  completeActivityDataState,
  completeActivity,
  activity,
  projectId,
  taskId,
}) => {
  const completingActivity = completeActivityDataState.isLoading();
  const nextTask = next.task;
  const nextActivity = next.activity;
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      className={classes.rootContainer}
    >
      <Grid item>
        <Grid container spacing={1}>
          <HasProjectPermission permissions={ProjectPermission.CREATE_ACTIVITY}>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                disabled={completingActivity}
                color={'default'}
                onClick={() => {
                  toggleCreateActivityModal(
                    CREATE_ACTIVITY_MODE.QUICK,
                    activity.sequence,
                  );
                }}
              >
                <Add className={classes.leftIcon} />
                Add Activity
              </Button>
            </Grid>
          </HasProjectPermission>
          {nextActivity?.id && (
            <Grid item>
              <LoadingButton
                size="small"
                variant="contained"
                dataState={completeActivityDataState}
                color={'primary'}
                onClick={() => completeActivity(projectId, taskId, activity.id)}
              >
                <PlayArrow className={classes.leftIcon} />
                {nextActivity?.status === WORK_ITEM_STATUS.PAUSED
                  ? 'CONT.'
                  : 'START'}
                &nbsp;
                <i>
                  {nextTask?.id
                    ? nextTask?.type?.name
                    : nextActivity?.type?.name}
                </i>
              </LoadingButton>
            </Grid>
          )}
          {!nextActivity?.id && (
            <Grid item>
              <LoadingButton
                size="small"
                variant="contained"
                dataState={completeActivityDataState}
                color={'primary'}
                onClick={() => completeActivity(projectId, taskId, activity.id)}
              >
                <Done className={classes.leftIcon} />
                Complete
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  completeActivityDataState: getActionDataStateFromState(
    state,
    ACTIVITIES_ACTIONS.COMPLETE_ACTIVITY,
  ),
});

const styles = (theme) => {
  return {
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    chipRoot: {
      height: 'auto',
      padding: 5,
    },
    loadingContainer: {
      backgroundColor: 'transparent',
      height: '100%',
      position: 'absolute',
    },
    loadingProgress: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
    },
    startActivityButton: {
      textTransform: 'none',
    },
    rootContainer: {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
  };
};

export default connect(
  mapStateToProps,
  {},
)(withStyles(styles)(ActivityDetailsBottomBar));
