import { compose } from 'redux';
import { arrayMoveImmutable } from 'array-move';
import { useCallback, useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  addNotification,
  NOTIFICATION_VARIANTS,
} from 'altus-redux-middlewares';

import { useModal } from 'altus-modal';

import {
  MODAL,
  EQUIPMENT_ACTIONS,
} from 'features/equipment/equipment.constants';

import {
  getSummarizedDataStateFromState,
  getCurrentClientOrganizationIdFromStateForAssembly,
} from 'app/app.selectors';

import BasePage from 'app/components/BasePageDense';
import { updateEquipmentTemplate } from 'features/equipment/equipment.actions';
import { FormFields } from 'features/equipment/equipmentTemplates/addEquipmentTemplates/AddEquipmentTemplatesInformationContainer';
import AddSurfaceEquipmentInPlanningModalContainer from 'features/projects/tasks/task/surfaceEquipment/components/surfaceEquipment/AddSurfaceEquipmentInPlanningModalContainer';
import AddEquipmentTemplatesModal from 'features/equipment/equipmentTemplates/addEquipmentTemplates/AddEquipmentTemplatesModal';
import { EMPTY_MAP } from 'app/app.constants';

const EditEquipmentTemplatesModalContainer = ({
  openModal,
  dataState,
  toggleModal,
  templateId = null,
  template = EMPTY_MAP,
}) => {
  const dispatch = useDispatch();

  const [selectedTools, setSelectedTools] = useState([]);
  const [equipmentTemplateInfo, setEquipmentTemplateInfo] = useState(null);

  useEffect(() => {
    if (template) {
      setSelectedTools(
        template
          .get('templateEquipments')
          .map((templateEquipment) => templateEquipment.get('surfaceEquipment'))
          .toArray(),
      );
      setEquipmentTemplateInfo({
        [FormFields.name]: template.get('name'),
        [FormFields.description]: template?.get('description'),
      });
    }
  }, [template]);

  const [selectedSurfaceEquipment, setSelectedSurfaceEquipment] = useState([]);

  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromStateForAssembly,
  );

  const [isOpenAddSurfaceEquipment, toggleModalAddSurfaceEquipment] = useModal(
    MODAL.ADD_EQUIPMENT_TO_TEMPLATE,
  );

  const onAddItem = useCallback(
    (tool) => {
      setSelectedTools((prevArray) => [...prevArray, tool]);
      dispatch(
        addNotification({
          message: 'Equipment added to template.',
          variant: NOTIFICATION_VARIANTS.SUCCESS,
        }),
      );
    },
    [dispatch],
  );

  const onSortItem = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) return;

      const tools = [...selectedTools];
      const newTools = arrayMoveImmutable(tools, oldIndex, newIndex);
      setSelectedTools(newTools);
    },
    [selectedTools],
  );

  const onDeleteItem = useCallback(
    (templateEquipment) => {
      const tools = [...selectedTools];
      const toolIndex = tools.indexOf(templateEquipment);
      tools.splice(toolIndex, 1);
      setSelectedTools(tools);
    },
    [selectedTools],
  );

  const onDuplicateItem = useCallback(
    (tool) => {
      const tools = [...selectedTools];
      const toolIndex = tools.indexOf(tool);
      tools.push(tool);
      const lastToolIndex = tools.lastIndexOf(tool);
      const newTools = arrayMoveImmutable(tools, lastToolIndex, toolIndex + 1);
      setSelectedTools(newTools);
    },
    [selectedTools],
  );

  const onUpdateEquipmentTemplate = useCallback(() => {
    const sortedTools = selectedTools.map((tool) => tool.get('equipmentId'));
    const equipmentToSave = {
      equipmentTemplateId: templateId,
      name: equipmentTemplateInfo.name,
      description: equipmentTemplateInfo.description,
      organizationId: currentClientOrganizationId,
      templateEquipmentIds: sortedTools,
    };
    dispatch(updateEquipmentTemplate(equipmentToSave));
  }, [
    dispatch,
    selectedTools,
    templateId,
    equipmentTemplateInfo,
    currentClientOrganizationId,
  ]);

  return (
    <BasePage dataState={dataState}>
      <AddEquipmentTemplatesModal
        isEditMode={true}
        open={openModal}
        dataState={dataState}
        toggleModal={toggleModal}
        toggleAddEquipmentToEquipmentTemplateModal={
          toggleModalAddSurfaceEquipment
        }
        onSortItem={onSortItem}
        onDeleteItem={onDeleteItem}
        onDuplicateItem={onDuplicateItem}
        selectedTools={selectedTools}
        equipmentTemplateInfo={equipmentTemplateInfo}
        setEquipmentTemplateInfo={setEquipmentTemplateInfo}
        onSaveEquipmentTemplate={onUpdateEquipmentTemplate}
      />
      <AddSurfaceEquipmentInPlanningModalContainer
        dataState={dataState}
        isOpen={isOpenAddSurfaceEquipment}
        toggleModal={toggleModalAddSurfaceEquipment}
        onSaveSurfaceEquipment={onAddItem}
        selectedSurfaceEquipment={selectedSurfaceEquipment}
        setSelectedSurfaceEquipment={setSelectedSurfaceEquipment}
      />
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    EQUIPMENT_ACTIONS.SEARCH_EQUIPMENT,
    EQUIPMENT_ACTIONS.UPDATE_EQUIPMENT_TEMPLATE,
    EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_LOADED,
    EQUIPMENT_ACTIONS.RECEIVE_SURFACE_EQUIPMENT_GROUPS,
  ),
});

export default compose(connect(mapStateToProps))(
  EditEquipmentTemplatesModalContainer,
);
