import React from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';

const CableServiceMaintenanceFormComponent = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} container>
          <Field
            type="number"
            label="Cable Length"
            component={QuantityTextFieldFormik}
            name={AssetHistoryForm.CABLE_SERVICE_MAINTENANCE_CABLE_LENGTH}
            placeholder="0"
          />
        </Grid>
        <Grid item xs={6} container>
          <Field
            type="number"
            label="Maximum OD"
            component={QuantityTextFieldFormik}
            name={AssetHistoryForm.CABLE_SERVICE_MAINTENANCE_MAX_OD}
            placeholder="0"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CableServiceMaintenanceFormComponent;
