import { Box } from '@material-ui/core';

import { BasePageHeader, NavTabsContainer } from 'altus-ui-components';

import RoutesContainer from 'app/components/RoutesContainer';

const DataExchangeBasePage = ({ routes, breadcrumb }) => (
  <>
    <BasePageHeader title={breadcrumb} />
    <Box marginBottom={3}>
      <NavTabsContainer routes={routes} />
    </Box>
    <RoutesContainer routes={routes} />
  </>
);

export default DataExchangeBasePage;
