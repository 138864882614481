import { compose } from 'redux';
import { Formik, Form } from 'formik';
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { useCallback } from 'react';
import { Button, DialogContent } from '@material-ui/core';

import {
  ModalActions,
  ModalHeader,
  withModal,
  LoadingButton,
} from 'altus-ui-components';

import { MODAL } from 'features/projects/tasks/task/simulation/simulation.constants';
import { submitCreateSimulation } from 'features/projects/tasks/task/simulation/simulation.actions';
import CreateSimulationForm from 'features/projects/tasks/task/simulation/components/CreateSimulationForm';
import { getTaskFromState } from 'features/projects/tasks/task/toolstring/toolstring.selectors';

const CreateSimulationModal = ({
  task,
  open,
  taskId,
  projectId,
  toggleModal,
  projectWellboreDetailId,
  dispatchCreateSimulation,
}) => {
  const onSubmit = useCallback(
    (simulation, { setStatus, setSubmitting }) =>
      dispatchCreateSimulation(
        projectId,
        taskId,
        simulation,
        setSubmitting,
        setStatus,
      ),
    [dispatchCreateSimulation, projectId, taskId],
  );
  const initialName = `${task.get('title')} Simulation`;

  return (
    <Dialog open={open} fullWidth onClose={toggleModal}>
      <ModalHeader title="Create Simulation" />
      <Formik onSubmit={onSubmit} initialValues={{ name: initialName }}>
        {({ handleSubmit, isSubmitting }) => (
          <Form>
            <DialogContent>
              <CreateSimulationForm
                taskId={taskId}
                projectId={projectId}
                projectWellboreDetailId={projectWellboreDetailId}
              />
            </DialogContent>
            <ModalActions>
              <Button onClick={toggleModal}>Cancel</Button>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                Create
              </LoadingButton>
            </ModalActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default compose(
  withModal(MODAL.CREATE_SIMULATION_MODAL),
  connect(
    (state) => {
      return {
        task: getTaskFromState(state),
      };
    },
    {
      dispatchCreateSimulation: submitCreateSimulation,
    },
  ),
)(CreateSimulationModal);
