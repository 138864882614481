import { useSelector } from 'react-redux';
import { isDesktop } from 'react-device-detect';
import { Typography, Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { getApplicationHeaderFromState } from 'app/app.selectors';

const ApplicationHeaderTitle = ({ classes }) => {
  const applicationHeader = useSelector(getApplicationHeaderFromState);

  const title = applicationHeader.get('title');
  const subTitle = applicationHeader.get('subTitle');

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      {(isDesktop || title) && (
        <Grid item xs={12}>
          <Typography
            noWrap
            variant="body2"
            align="center"
            color="textSecondary"
          >
            {title ? title : <span>&nbsp;</span>}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography noWrap variant="h6" align="center">
          {subTitle ? subTitle : <span>&nbsp;</span>}
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  root: {
    overflow: 'hidden',
  },
});

export default withStyles(styles)(ApplicationHeaderTitle);
