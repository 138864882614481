import StatusIndicator, {
  Status,
} from 'features/data-exchange/components/StatusIndicator';

import { CheetahJobStatus } from 'app/app.constants';

const CheetahJobStatusIndicator = ({
  cheetahJob,
  status: statusOverride,
  ...rest
}) => {
  const status = cheetahJob.get('status');
  const title = cheetahJob.get('statusName');

  if (statusOverride) {
    return <StatusIndicator status={statusOverride} title={title} {...rest} />;
  }

  switch (status) {
    case CheetahJobStatus.STREAMING:
      return (
        <StatusIndicator status={Status.SUCCESS} title={title} {...rest} />
      );
    default:
      return <StatusIndicator title={title} {...rest} />;
  }
};

export default CheetahJobStatusIndicator;
