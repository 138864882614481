import { List } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import { createSelector } from 'utils/app.util';
import { getTotalElapsedTimeFromDurations } from 'utils/activity.util';
import { STATE } from 'features/projects/activities/activities.constants';
import { getCurrentProjectWellboreFromState } from 'features/projects/wellbore/projectWellbore.selectors';

export const getActivitiesFromState = createSelector(
  (state) => state.getIn(['entity', 'activity']),
  (activities) => activities,
);

export const getAllActivitiesFromState = createSelector(
  (state) => state.getIn(['entity', 'activity', 'allActivities']),
  (activities) => activities,
);

export const createTaskActivitiesSelector = (taskId) =>
  createSelector(getActivitiesFromState, (activitiesByTaskId) =>
    activitiesByTaskId.get(taskId?.toString()),
  );

export const createTaskAllActivitiesSelector = (taskId) =>
  createSelector(getAllActivitiesFromState, (activitiesByTaskId) =>
    activitiesByTaskId.get(taskId?.toString()),
  );

export const createProjectActivitiesSelector = (projectId) =>
  createSelector(getAllActivitiesFromState, (activitiesByTaskId) =>
    activitiesByTaskId
      .flatten(1)
      .groupBy((activity) => activity.get('projectId').toString())
      .get(projectId?.toString()),
  );

export const createProjectAllActivitiesSelector = (projectId) =>
  createSelector(getAllActivitiesFromState, (activitiesByTaskId) =>
    activitiesByTaskId
      .flatten(1)
      .groupBy((activity) => activity.get('projectId').toString())
      .get(projectId?.toString()),
  );

export const getActivityTypes = createSelector(
  (state) =>
    state.getIn([
      'projects',
      'activities',
      STATE.DATA,
      STATE.DATA_ACTIVITY_TYPES,
    ]),
  (activityTypes) => activityTypes,
);

export const getWaitingActivityTypes = createSelector(
  (state) =>
    state.getIn([
      'projects',
      'activities',
      'data',
      STATE.DATA_ACTIVITY_TYPES_WAITING,
    ]),
  (waitingActivityTypes) => waitingActivityTypes,
);

export const getPointInTimeActivityTypes = createSelector(
  (state) =>
    state.getIn([
      'projects',
      'activities',
      'data',
      STATE.DATA_ACTIVITY_TYPES_POINT_IN_TIME,
    ]),
  (pointIntimeActivities) => pointIntimeActivities,
);

export const getAllTasks = createSelector(
  (state) =>
    state.getIn(['projects', 'activities', STATE.DATA, STATE.DATA_TASKS]),
  (tasks) => tasks,
);

export const getActivityTaskByTaskId = (taskId) =>
  createSelector(getAllTasks, (allTasks) => {
    return allTasks.find((task) => task.get('taskId') === parseInt(taskId));
  });

export const getExecuteStatusFromState = createSelector(
  (state) => state.getIn(['projects', 'progress']),
  (status) => status,
);

export const getTimersByTypeId = createSelector(
  (state, timerTypeId) =>
    state.getIn([
      'projects',
      'activities',
      STATE.DATA,
      STATE.TIMERS,
      `${timerTypeId}`,
    ]),
  (timers) => timers || List(),
);

export const getTotalElapsedTimeFromState = createSelector(
  getTimersByTypeId,
  (timers) => getTotalElapsedTimeFromDurations(timers),
);

export const getAutosaveDataStateFromState = createSelector(
  (state) => state.getIn(['projects', 'activities', 'data', 'autosave']),
  (autosave) => autosave,
);

export const getActiveTimerTypeFromState = createSelector(
  (state) => state.getIn(['projects', 'activities', 'data', 'activeTimerType']),
  (activeTimerType) => activeTimerType,
);

export const getCurrentTaskFromState = createSelector(
  getExecuteStatusFromState,
  (execute) => execute.getIn(['current', 'task']),
);

export const getTargetDepthFromState = createSelector(
  getCurrentTaskFromState,
  getCurrentProjectWellboreFromState,
  (currentTask, wellbore = EMPTY_MAP) =>
    currentTask?.get('targetDepth').value ?? wellbore.get('md'),
);

export const isTimerActive = (id) =>
  createSelector(
    (state) => {
      const timers = getTimersByTypeId(state, id);

      return !!timers.find((timer) => !timer.get('endTime'));
    },
    (isTimerActive) => isTimerActive,
  );
