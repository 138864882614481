import { useCallback } from 'react';

import { useRequest } from 'altus-hooks';

import mappers from 'mappers';
import projectService from 'services/project.service';

const useProjectWellbore = (projectId) => {
  const getProjectWellbore = useCallback(
    () =>
      projectService
        .getWellboreByProject(projectId)
        .then(mappers.Wellbore.from),
    [projectId],
  );

  return useRequest(getProjectWellbore);
};

export default useProjectWellbore;
