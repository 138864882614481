import { compose } from 'redux';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import { CardActions, IconButton } from '@material-ui/core';

const CardNavigationItemActions = ({ classes, actions = [] }) => {
  if (!actions.length) {
    return null;
  }

  return (
    <CardActions classes={classes}>
      {actions?.map(({ Icon, onClick, title, disabled }, index) => (
        <IconButton
          key={index}
          size="small"
          title={title}
          component="span"
          disabled={disabled}
          onClick={(event) => {
            event.preventDefault();
            onClick();
          }}
        >
          <Icon fontSize="small" />
        </IconButton>
      ))}
    </CardActions>
  );
};

const styles = () => ({
  root: {
    justifyContent: 'flex-end',
  },
});

CardNavigationItemActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      onClick: PropTypes.func.isRequired,
      title: PropTypes.string.isRequired,
      Icon: PropTypes.elementType.isRequired,
    }),
  ),
};

export default compose(withStyles(styles))(CardNavigationItemActions);
