import React from 'react';
import { Grid } from '@material-ui/core';
import IntegritiesTableWeight from 'features/settings/integrityManagement/integrities/IntegritiesTableWeight';

const IntegrityWeightContainer = ({
  integrities,
  setIntegrities,
  integrityType,
}) => (
  <Grid container justifyContent="flex-start">
    <Grid item xs={6}>
      <IntegritiesTableWeight
        integrities={integrities}
        setIntegrities={setIntegrities}
        integrityType={integrityType}
      />
    </Grid>
  </Grid>
);

export default IntegrityWeightContainer;
