import React from 'react';
import { getTicks } from 'app/components/WellboreContextualization/Grid';
import TextSprite from 'app/components/WellboreContextualization/TextSprite';

const YAxisLabels = ({ maxTVD, tickInterval }) => {
  const yTicks = getTicks({ max: maxTVD, tickInterval });
  return yTicks.map((tick, index) => (
    <TextSprite key={index} text={tick} position={[-50, maxTVD - tick, -50]} />
  ));
};

export default YAxisLabels;
