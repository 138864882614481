import cloneDeep from 'lodash/cloneDeep';
import { SensorOffset } from 'features/projects/dashboard/components/dashboards/logging/helpers/constants';

const calcDepthIncludingOffset = (sensor, depth, offsetCfg) => {
  return offsetCfg[sensor.name]
    ? depth + offsetCfg[sensor.name]
    : depth + SensorOffset.DEFAULT;
};

export const introduceOffsetOnX = (readings, offsetCfg) => {
  return readings.map((originalSensor) => {
    const sensor = cloneDeep(originalSensor);
    sensor.data = sensor.data.map(([time, depth, value]) => [
      time,
      calcDepthIncludingOffset(sensor, depth, offsetCfg),
      value,
    ]);
    return sensor;
  });
};
