import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';

import { useRequest } from 'altus-hooks';
import { BasePage } from 'altus-ui-components';

import {
  requestCreateWellboreSection,
  requestUpdateWellboreSection,
  requestDeleteWellboreSection,
  requestCreateWellboreSectionNipple,
  requestUpdateWellboreSectionNipple,
  requestDeleteWellboreSectionNipple,
  requestWellboreSectionsForWellbore,
} from 'features/wells/sections/wellboreSection.actions';

import { EMPTY_MAP } from 'app/app.constants';
import { useHeader } from 'app/hooks/useHeader';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import wellboreSectionService from 'services/wellboreSection.service';
import { ACTIONS } from 'features/wells/sections/wellboreSection.constants';
import wellboreSectionsMappers from 'features/wells/sections/wellboreSection.mappers';
import WellboreSectionsTable from 'features/wells/sections/components/WellboreSectionsTable';
import { createWellboreSectionsForWellboreSelector } from 'features/wells/sections/wellboreSection.selectors';

const WellboreSectionsContainer = ({
  fieldId,
  dataState,
  breadcrumb,
  wellboreId,
  dispatchRequestCreateWellboreSection,
  dispatchRequestUpdateWellboreSection,
  dispatchRequestDeleteWellboreSection,
  dispatchRequestCreateWellboreSectionNipple,
  dispatchRequestUpdateWellboreSectionNipple,
  dispatchRequestDeleteWellboreSectionNipple,
  dispatchRequestWellboreSectionsForWellbore,
}) => {
  useHeader({
    subTitle: breadcrumb,
  });

  useEffect(() => {
    dispatchRequestWellboreSectionsForWellbore(fieldId, wellboreId);
  }, [fieldId, wellboreId, dispatchRequestWellboreSectionsForWellbore]);

  const getDefaultWellboreSection = useCallback(
    () =>
      wellboreSectionService
        .getDefaultWellboreSection(fieldId, wellboreId)
        .then((wellboreSection) =>
          wellboreSectionsMappers.WellboreSection.from(wellboreSection),
        ),
    [fieldId, wellboreId],
  );

  const [defaultWellboreSection = EMPTY_MAP] = useRequest(
    getDefaultWellboreSection,
  );

  const createWellboreSection = useCallback(
    (wellboreSection, formik, callback) =>
      dispatchRequestCreateWellboreSection(
        fieldId,
        wellboreId,
        wellboreSection,
        formik,
        callback,
      ),
    [fieldId, wellboreId, dispatchRequestCreateWellboreSection],
  );

  const updateWellboreSection = useCallback(
    (wellboreSection) => {
      const { wellboreSectionId } = wellboreSection;
      dispatchRequestUpdateWellboreSection(
        fieldId,
        wellboreId,
        wellboreSectionId,
        wellboreSection,
      );
    },
    [fieldId, wellboreId, dispatchRequestUpdateWellboreSection],
  );

  const deleteWellboreSection = useCallback(
    (wellboreSectionId) =>
      dispatchRequestDeleteWellboreSection(
        fieldId,
        wellboreId,
        wellboreSectionId,
      ),
    [fieldId, wellboreId, dispatchRequestDeleteWellboreSection],
  );

  const createWellboreSectionNipple = useCallback(
    (wellboreSectionNipple, formik, callback) => {
      const { wellboreSectionId } = wellboreSectionNipple;

      dispatchRequestCreateWellboreSectionNipple(
        fieldId,
        wellboreId,
        wellboreSectionId,
        wellboreSectionNipple,
        formik,
        callback,
      );
    },
    [fieldId, wellboreId, dispatchRequestCreateWellboreSectionNipple],
  );

  const updateWellboreSectionNipple = useCallback(
    (wellboreSectionNipple) => {
      const { wellboreSectionId, wellboreSectionNippleId } =
        wellboreSectionNipple;

      dispatchRequestUpdateWellboreSectionNipple(
        fieldId,
        wellboreId,
        wellboreSectionId,
        wellboreSectionNippleId,
        wellboreSectionNipple,
      );
    },
    [fieldId, wellboreId, dispatchRequestUpdateWellboreSectionNipple],
  );

  const deleteWellboreSectionNipple = useCallback(
    (wellboreSectionId, wellboreSectionNippleId) =>
      dispatchRequestDeleteWellboreSectionNipple(
        fieldId,
        wellboreId,
        wellboreSectionId,
        wellboreSectionNippleId,
      ),
    [fieldId, wellboreId, dispatchRequestDeleteWellboreSectionNipple],
  );

  const wellboreSectionsSelector = useMemo(
    () => createWellboreSectionsForWellboreSelector(wellboreId),
    [wellboreId],
  );

  const wellboreSections = useSelector(wellboreSectionsSelector);

  return (
    <BasePage title={breadcrumb} dataState={dataState}>
      <i>Basic Well Description for simulations</i>
      <br />
      <WellboreSectionsTable
        wellboreSections={wellboreSections}
        createWellboreSection={createWellboreSection}
        updateWellboreSection={updateWellboreSection}
        deleteWellboreSection={deleteWellboreSection}
        defaultWellboreSection={defaultWellboreSection}
        createWellboreSectionNipple={createWellboreSectionNipple}
        updateWellboreSectionNipple={updateWellboreSectionNipple}
        deleteWellboreSectionNipple={deleteWellboreSectionNipple}
      />
    </BasePage>
  );
};

export default compose(
  connect(
    (state) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        ACTIONS.REQUEST_WELLBORE_SECTIONS,
        ACTIONS.REQUEST_CREATE_WELLBORE_SECTION,
        ACTIONS.REQUEST_UPDATE_WELLBORE_SECTION,
        ACTIONS.REQUEST_UPDATE_WELLBORE_SECTION_NIPPLE,
        ACTIONS.REQUEST_DELETE_WELLBORE_SECTION_NIPPLE,
      ),
    }),
    {
      dispatchRequestCreateWellboreSection: requestCreateWellboreSection,
      dispatchRequestUpdateWellboreSection: requestUpdateWellboreSection,
      dispatchRequestDeleteWellboreSection: requestDeleteWellboreSection,
      dispatchRequestCreateWellboreSectionNipple:
        requestCreateWellboreSectionNipple,
      dispatchRequestUpdateWellboreSectionNipple:
        requestUpdateWellboreSectionNipple,
      dispatchRequestDeleteWellboreSectionNipple:
        requestDeleteWellboreSectionNipple,
      dispatchRequestWellboreSectionsForWellbore:
        requestWellboreSectionsForWellbore,
    },
  ),
)(WellboreSectionsContainer);
