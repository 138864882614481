import { createSelector } from 'reselect';
import { CHECKLISTS_AND_PROCEDURES_SOURCE } from 'features/projects/documents/documents.constants';

const getDocuments = (state) =>
  state.getIn(['projects', 'documents', 'checklistsAndProcedures']).toJS();

export const getFilteredChecklists = createSelector(
  [getDocuments],
  (documents) =>
    (companyId, procedureTypes, departments, searchTerm, services) => {
      let filteredDocuments = documents;

      if (companyId) {
        filteredDocuments = filteredDocuments.filter(
          (doc) => doc.organizationId === companyId,
        );
      }

      if (procedureTypes && procedureTypes.length > 0) {
        filteredDocuments = filteredDocuments.filter(
          (doc) =>
            procedureTypes.includes(doc.procedureType) &&
            doc?.source !== CHECKLISTS_AND_PROCEDURES_SOURCE.SERVICE,
        );
      }

      if (departments && departments.length > 0) {
        filteredDocuments = filteredDocuments.filter((doc) => {
          return doc.procedureDepartments.some((dept) =>
            departments.includes(dept),
          );
        });
      }

      if (services && services.length > 0) {
        filteredDocuments = filteredDocuments.filter(
          (doc) =>
            services.includes(doc.procedureType) &&
            doc.source === CHECKLISTS_AND_PROCEDURES_SOURCE.SERVICE,
        );
      }

      if (searchTerm && searchTerm.length > 0) {
        // Filter documents by name or keyword
        filteredDocuments = filteredDocuments.filter((doc) => {
          const nameMatch = doc.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
          const keywordMatch = doc.listKeywords.some((keyword) =>
            keyword.toLowerCase().includes(searchTerm.toLowerCase()),
          );
          return nameMatch || keywordMatch;
        });
      }

      return filteredDocuments;
    },
);
