import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import ReadOnlyField from 'app/components/ReadOnlyField';
import withStyles from '@material-ui/styles/withStyles';

import Counter from 'app/components/Counter';
import { formatDate, formatDuration } from 'utils/format.util';
import { WORK_ITEM_STATUS } from 'app/app.constants';
import EditorStateViewer from 'app/components/EditorStateViewer';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const TaskActivityDrawerContent = ({ classes, activity, displayComments }) => {
  const isTimerActive = activity.get('status') === WORK_ITEM_STATUS.STARTED;

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CustomReadOnlyField margin="none" label="Started">
            {activity.get('startTime') ? (
              formatDate(activity.get('startTime'))
            ) : (
              <Typography
                variant="body2"
                noWrap
                className={classes.disabledText}
              >
                {'Not Yet Started'}
              </Typography>
            )}
          </CustomReadOnlyField>
        </Grid>
        <Grid item xs={4}>
          <CustomReadOnlyField margin="none" label="Duration">
            {activity.get('startTime') ? (
              <Counter
                isTimerActive={isTimerActive}
                key={activity.get('startTime')}
                endTime={activity.get('endTime')}
                startTime={activity.get('startTime')}
                calculateDurationFromStartAndEndtime
                renderValue={({ elapsedTime }) => formatDuration(elapsedTime)}
              />
            ) : (
              <Typography
                variant="body2"
                noWrap
                className={classes.disabledText}
              >
                {'No Duration'}
              </Typography>
            )}
          </CustomReadOnlyField>
        </Grid>
        <Grid item xs={4}>
          <CustomReadOnlyField margin="none" label="Depth">
            {activity.getIn(['depth', 'value']) ? (
              `${activity.getIn(['depth', 'value'])}${activity.getIn([
                'depth',
                'unit',
              ])}`
            ) : (
              <Typography
                variant="body2"
                noWrap
                className={classes.disabledText}
              >
                {'N/A'}
              </Typography>
            )}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.activityField}>
        <Grid item xs={12}>
          <CustomReadOnlyField margin="none" label="Description">
            {activity.get('description') ? (
              <EditorStateViewer editorState={activity.get('description')} />
            ) : (
              <Typography
                variant="body2"
                noWrap
                className={classes.disabledText}
              >
                {'No Description'}
              </Typography>
            )}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.activityField}>
        <Grid item xs={12}>
          <CustomReadOnlyField margin="none" label="Parallel Activity">
            {activity.get('parallelActivity') ? (
              <EditorStateViewer
                editorState={activity.get('parallelActivity')}
              />
            ) : (
              <Typography
                variant="body2"
                noWrap
                className={classes.disabledText}
              >
                {'No Parallel Activity'}
              </Typography>
            )}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
      {displayComments && (
        <Grid container spacing={2} className={classes.activityField}>
          <Grid item xs={12}>
            <CustomReadOnlyField margin="none" label="Internal Comments">
              {activity.get('comments') ? (
                <EditorStateViewer editorState={activity.get('comments')} />
              ) : (
                <Typography
                  variant="body2"
                  noWrap
                  className={classes.disabledText}
                >
                  {'No Comments'}
                </Typography>
              )}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} className={classes.activityField}>
        <Grid item xs={12}>
          <Typography variant="h6" noWrap>
            {'Sub Activities'}
          </Typography>
          {activity.get('subActivities').size ? (
            activity.get('subActivities').map((subActivity) => (
              <Grid container className={classes.subActivityBox}>
                <Grid container className={classes.subActivityField}>
                  <Grid item xs={8}>
                    <Typography variant="subtitle1" noWrap>
                      {subActivity.get('name')}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" noWrap>
                      {formatDate(subActivity.get('startTime'))}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.activityField}>
                    <CustomReadOnlyField margin="none" label="Depth">
                      {subActivity.getIn(['depth', 'value']) ? (
                        <Typography variant="subtitle1" noWrap>
                          {`${subActivity.getIn([
                            'depth',
                            'value',
                          ])}${subActivity.getIn(['depth', 'unit'])}`}
                        </Typography>
                      ) : (
                        <Typography
                          variant="body2"
                          noWrap
                          className={classes.disabledText}
                        >
                          {'N/A'}
                        </Typography>
                      )}
                    </CustomReadOnlyField>
                  </Grid>
                  <Grid item xs={12} className={classes.activityField}>
                    <CustomReadOnlyField margin="none" label="Description">
                      {subActivity.get('description') ? (
                        <EditorStateViewer
                          editorState={subActivity.get('description')}
                        />
                      ) : (
                        <Typography
                          variant="body2"
                          noWrap
                          className={classes.disabledText}
                        >
                          {'No Description'}
                        </Typography>
                      )}
                    </CustomReadOnlyField>
                  </Grid>
                  <Grid item xs={12}>
                    {displayComments && (
                      <Grid
                        container
                        spacing={2}
                        className={classes.activityField}
                      >
                        <Grid item xs={12}>
                          <CustomReadOnlyField
                            margin="none"
                            label="Internal Comments"
                          >
                            {subActivity.get('comments') ? (
                              <EditorStateViewer
                                editorState={subActivity.get('comments')}
                              />
                            ) : (
                              <Typography
                                variant="body2"
                                noWrap
                                className={classes.disabledText}
                              >
                                {'No Comments'}
                              </Typography>
                            )}
                          </CustomReadOnlyField>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography variant="body2" noWrap className={classes.disabledText}>
              {'No Sub Activities'}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  activityField: {
    paddingTop: `${theme.spacing(3)}px`,
  },
  subActivityBox: {
    marginTop: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    border: `2px solid ${theme.palette.table.row.hover}`,
  },
  subActivityField: {
    padding: `${theme.spacing(1)}px`,
  },
  disabledText: {
    color: theme.palette.secondary.mainLight,
  },
});

export default withStyles(styles)(TaskActivityDrawerContent);
