import projectFileService from 'services/projectFile.service';
import { NOTIFICATION_VARIANTS, MODAL } from 'app/app.constants';
import {
  getAllDepartments,
  getAllFileCategories,
  toggleModal,
} from 'app/app.actions';
import { DOCUMENTS_ACTIONS } from 'features/projects/documents/documents.constants';

export const onLoad = (projectId) => (dispatch) =>
  dispatch({
    type: DOCUMENTS_ACTIONS.PROJECT_DOCUMENTS_PAGE_LOADED,
    payload: () =>
      Promise.all([
        dispatch(getAllFileCategories()),
        projectFileService
          .getAllDocuments(projectId)
          .then((files) => dispatch(receiveProjectFiles(files))),
      ]),
  });

export const onLoadChecklistsAndProcedures =
  (projectId) => async (dispatch) => {
    try {
      await Promise.all([
        dispatch(getAllDepartments()),
        dispatch({
          type: DOCUMENTS_ACTIONS.GET_ALL_CHECKLISTS_AND_PROCEDURES,
          payload: () =>
            projectFileService
              .getAllChecklistsAndProcedures(projectId)
              .then((files) => dispatch(receiveChecklistsAndProcedures(files))),
        }),
      ]);
    } catch (error) {
      console.error(
        'Error loading checklists, procedures, and departments',
        error,
      );
    }
  };

export const receiveChecklistsAndProcedures = (projectFiles) => ({
  payload: projectFiles,
  type: DOCUMENTS_ACTIONS.RECEIVE_CHECKLISTS_AND_PROCEDURES,
});

export const onUnload = () => (dispatch) =>
  dispatch({ type: DOCUMENTS_ACTIONS.PROJECT_DOCUMENTS_PAGE_UNLOADED });

export const deleteFile = (projectId, fileId) => (dispatch) => {
  dispatch({
    fileId,
    type: DOCUMENTS_ACTIONS.DELETE_PROJECT_FILE,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Deleting...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The file was successfully deleted',
    },
    confirmationDialog: {
      title: 'Delete document',
      confirmButtonText: 'Delete',
      description: 'Are you sure you want to delete this document?',
    },
    payload: () => projectFileService.deleteFile(projectId, fileId),
  });
};

export const downloadFile = (projectId, fileId, file) => (dispatch) => {
  const payload = projectFileService.downloadFile(projectId, fileId);

  dispatch({
    fileId,
    type: DOCUMENTS_ACTIONS.DOWNLOAD_PROJECT_FILE,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: `Downloading ${file.get('name')}...`,
    },
    payload,
  });

  return payload;
};

// TODO: Add error handling, for example if only 1 file upload fails
export const uploadFiles =
  (projectId) => (dispatch) => (uploadDocumentFormValues) => {
    const { files } = uploadDocumentFormValues.toJS();

    const payload = Promise.all(
      files.map(({ file, meta }) =>
        projectFileService
          .uploadFile(projectId, file, meta)
          .then((projectFile) => dispatch(receiveProjectFile(projectFile))),
      ),
    ).then((response) => {
      dispatch(toggleModal({ modalId: MODAL.UPLOAD_FILE }));
      return response;
    });

    dispatch({
      type: DOCUMENTS_ACTIONS.UPLOAD_PROJECT_FILE,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: `The ${
          files.length > 1 ? 'files' : 'file'
        } ${files.length > 1 ? 'were' : 'was'} successfully uploaded`,
      },
      payload,
    });

    return payload;
  };

export const uploadFileModalOnUnload = () => (dispatch) =>
  dispatch({ type: DOCUMENTS_ACTIONS.UPLOAD_FILE_MODAL_UNLOADED });

export const updateFile = (projectId, fileId, file) => (dispatch) => {
  const payload = projectFileService
    .updateFile(projectId, fileId, file)
    .then((response) => {
      dispatch(receiveProjectFile(response));
      return response;
    });

  dispatch({
    payload,
    fileId,
    type: DOCUMENTS_ACTIONS.UPDATE_PROJECT_FILE,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'File was successfully updated',
    },
  });

  return payload;
};

export const receiveProjectFiles = (projectFiles) => ({
  payload: projectFiles,
  type: DOCUMENTS_ACTIONS.RECEIVE_PROJECT_FILES,
});

export const receiveProjectFile = (projectFile) => ({
    payload: projectFile,
    type: DOCUMENTS_ACTIONS.RECEIVE_PROJECT_FILE,
});
