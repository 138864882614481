import { compose } from 'redux';
import { connect } from 'react-redux';
import { useState, useCallback, useEffect, useMemo } from 'react';

import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { getAllDepartments, getAllServices } from 'app/app.actions';

import {
  copyTask,
  sortTasks,
  deleteTask,
  tasksContainerOnUnload,
} from 'features/projects/tasks/tasks.actions';

import {
  getTaskTemplates,
  getTotalElapsedDuration,
  getTotalPlannedDuration,
} from 'features/projects/tasks/tasks.selectors';

import {
  TASK_VIEWS,
  TASK_ACTIONS,
} from 'features/projects/tasks/tasks.constants';

import TaskTable from 'features/projects/tasks/components/TaskTable';
import { getCurrentProject } from 'features/projects/projects.selectors';
import TasksChartContainer from 'features/projects/tasks/TasksChartContainer';
import { ACTIVITIES_ACTIONS } from 'features/projects/activities/activities.constants';
import TaskListRuntimeHeader from 'features/projects/tasks/components/TaskListRuntimeHeader';

const TaskTableChart = ({
  tasks,
  project,
  projectId,
  dispatchCopyTask,
  dispatchOnUnload,
  dispatchSortTasks,
  dispatchDeleteTask,
  dispatchGetServices,
  totalElapsedDuration,
  totalPlannedDuration,
  dispatchGetDepartments,
  defaultView = TASK_VIEWS.LIST,
  showToggleButtonOption,
  showActivities,
  showDefaultButtons,
  showNavigatorChart,
  chartBackgroundColor,
}) => {
  const [selectedView, setSelectedView] = useState(defaultView);
  const toggleViewChange = useCallback((_event, value) => {
    if (!value) return;

    setSelectedView(value);
  }, []);

  useEffect(() => {
    dispatchGetServices(projectId);
    dispatchGetDepartments(projectId);

    return () => dispatchOnUnload();
  }, [
    projectId,
    dispatchOnUnload,
    dispatchGetServices,
    dispatchGetDepartments,
  ]);

  /**
   * The showContingency flag check will only filter out
   * contingency tasks which are not started during execution.
   */
  const filteredTasks = useMemo(() => {
    if (tasks) {
      let lastNonContingencyEndTime = null;
      return tasks
        .map((task) => {
          if (task.get('runNumber') === 'C' && !task.get('startTime')) {
            return null;
          } else {
            if (lastNonContingencyEndTime !== null) {
              task = task.set('plannedStartTime', lastNonContingencyEndTime);
            }
            lastNonContingencyEndTime = task.get('plannedEndTime');
            return task;
          }
        })
        .filter((task) => task !== null);
    }
    return tasks;
  }, [tasks]);

  return (
    <>
      <TaskListRuntimeHeader
        selectedView={selectedView}
        onViewChange={toggleViewChange}
        totalPlannedDuration={totalPlannedDuration}
        totalElapsedDuration={tasks?.size ? totalElapsedDuration : undefined}
        showToggleButtonOption={showToggleButtonOption}
      />
      {selectedView === TASK_VIEWS.LIST ? (
        <TaskTable
          tasks={tasks}
          project={project}
          projectId={projectId}
          copyTask={dispatchCopyTask}
          sortTasks={dispatchSortTasks}
          deleteTask={dispatchDeleteTask}
        />
      ) : (
        <TasksChartContainer
          tasks={filteredTasks}
          projectId={projectId}
          showActivities={showActivities}
          showDefaultButtons={showDefaultButtons}
          showNavigatorChart={showNavigatorChart}
          chartBackgroundColor={chartBackgroundColor}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  templateTasks: getTaskTemplates(state),
  totalElapsedDuration: getTotalElapsedDuration(state),
  totalPlannedDuration: getTotalPlannedDuration(state),
  dataState: getSummarizedDataStateFromState(
    state,
    TASK_ACTIONS.COPY_TASK,
    TASK_ACTIONS.SORT_TASKS,
    TASK_ACTIONS.CONFIRM_DELETE_TASK,
    TASK_ACTIONS.PROJECT_TASKS_PAGE_LOADED,
    ACTIVITIES_ACTIONS.GET_TASK_ACTIVITIES,
  ),
});

const mapDispatchToProps = {
  dispatchCopyTask: copyTask,
  dispatchSortTasks: sortTasks,
  dispatchDeleteTask: deleteTask,
  dispatchGetServices: getAllServices,
  dispatchOnUnload: tasksContainerOnUnload,
  dispatchGetDepartments: getAllDepartments,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TaskTableChart,
);
