import { useMemo } from 'react';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { toCheetahJobCurves } from 'utils/route.util';
import NavigationLink from 'app/components/NavigationLink';
import { CheetahJobSource } from 'features/projects/dashboard/dashboard.constants';
import CheetahJobStatusIndicator from 'features/data-exchange/components/CheetahJobStatusIndicator';

const LiveProjectCheetahJobsTable = ({ projectId, cheetahJobs }) => {
  const columns = useMemo(
    () => [
      {
        id: 'status',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          return (
            <TableRowActionsCell container>
              <CheetahJobStatusIndicator cheetahJob={row.original} />
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 3,
        Header: 'ID/UNIT',
        accessor: (cheetahJob) => cheetahJob.get('displayName'),
        Cell: ({ row, value }) => {
          const cheetahJob = row.original;

          const source = cheetahJob.get('source');
          const cheetahJobId = cheetahJob.get('id');

          if (source === CheetahJobSource.OTHER) return value;

          return (
            <NavigationLink
              to={toCheetahJobCurves(projectId, cheetahJobId, source)}
            >
              {value}
            </NavigationLink>
          );
        },
      },
      {
        xs: 3,
        Header: 'Data Source',
        accessor: (cheetahJob) => cheetahJob.get('sourceName'),
      },
      {
        xs: 3,
        Header: 'Sensors',
        accessor: (cheetahJob) => cheetahJob.get('sensorCount'),
      },
      {
        xs: 3,
        Header: 'Master',
        accessor: () => 'N/A',
      },
      {
        xs: 3,
        Header: 'Status',
        accessor: (cheetahJob) => cheetahJob.get('statusName'),
      },
    ],
    [projectId],
  );

  return (
    <Table columns={columns} items={cheetahJobs} useGlobalFilter={false} />
  );
};

export default LiveProjectCheetahJobsTable;
