import React, { createContext, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid, Box, Tab, MenuList } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BasePage, NavTabsContainer, MenuHeader } from 'altus-ui-components';

import { ProjectPermission, WORK_ITEM_STATUS } from 'app/app.constants';
import RoutesContainer from 'app/components/RoutesContainer';
import withProjectPermission from 'app/components/withProjectPermission';
import { toExecutionSection } from 'utils/route.util';
import { SimulationMenuItem } from './components/SimulationMenuItem';
import { TasksMenu } from './components/TasksMenu';
import { useInitializeExecutionRootContainer } from './hooks/useInitializeExecutionRootContainer';
import { CreateUpdatedPlannedButton } from './components/CreateUpdatedPlannedButton';
import { TaskExecution } from './components/TaskExecution';

import { getAllDepartments } from 'app/app.actions';
import useHubConnection from 'features/projects/dashboard/hooks/useHubConnection';
import config from 'infrastructure/config';
import {
  getAllTasks,
  handleEstablishingConnectionError,
  refreshProjectStatus,
} from 'features/projects/activities/activities.actions';
import { ProjectExecutionHub } from 'utils/activity.util';

export const ExecutionRootContainerContext = createContext(null);

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
    },
    tabsContainer: {
      overflow: 'hidden',
      height: 'calc(100vh - 250px)',
      width: '100vw',
    },
    basePageChildren: {
      paddingTop: theme.spacing(2.25),
    },
    simulations: {
      marginTop: 25,
    },
    leftMenu: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    menuList: {
      width: '100%',
    },
  }),
);

const ExecutionRootContainer = (props) => {
  const { projectId, taskId, section } = props;
  const dispatch = useDispatch();
  const raiseConnectionError = useCallback(() => {
    dispatch(handleEstablishingConnectionError());
  }, [dispatch]);

  const dispatchRefreshProjectStatus = useCallback(
    (argProjectId, argTaskId) => {
      dispatch(refreshProjectStatus(argProjectId, argTaskId));
      dispatch(getAllTasks(argProjectId));
    },
    [dispatch],
  );

  const [connection, connectionState] = useHubConnection(
    `${config.dashboardHubsBaseUrl}/project-execution`,
    raiseConnectionError,
  );

  useEffect(() => {
    dispatch(getAllDepartments());
  }, [dispatch]);

  useEffect(() => {
    if (connectionState === 'Connected') {
      connection.on(
        ProjectExecutionHub.SignalProjectReload,
        (argProjectId, argTaskId) =>
          dispatchRefreshProjectStatus(argProjectId, argTaskId),
      );
      connection.invoke(ProjectExecutionHub.RegisterOnProject, projectId);
    }
  }, [connectionState, connection, dispatchRefreshProjectStatus, projectId]);

  const classes = useStyles();
  const { tasks, simulations } = useInitializeExecutionRootContainer(
    projectId,
    taskId,
  );

  // Return default index container when no data
  if (tasks.size === 0 && taskId === undefined) {
    return <RoutesContainer {...props} />;
  }

  // Redirect to first active (started) task when no selected task
  // Fail back to first task if none is started.
  if (tasks.size > 0 && taskId === undefined) {
    const activeTask = Array.from(tasks).find(
      (x) => x.get('status') === WORK_ITEM_STATUS.STARTED,
    );
    const currentTaskId = activeTask
      ? activeTask.get('id')
      : tasks.first().get('id');
    return (
      <Redirect
        from={props.path}
        to={toExecutionSection(projectId, currentTaskId)}
      />
    );
  }

  // Return the selected task
  return (
    <BasePage
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs wrap="nowrap" className={classes.root}>
        <Grid
          item
          xs={2}
          component={Box}
          className={classes.leftMenu}
          paddingRight={2}
          paddingTop={0.5}
        >
          <TasksMenu
            tasks={tasks}
            selected={taskId}
            onSelect={(id) => toExecutionSection(projectId, id, section)}
          />
          {section === 'simulation' && simulations?.size > 0 && (
            <Grid
              className={classes.simulations}
              container
              item
              xs
              wrap="nowrap"
            >
              <Grid container xs={12} item direction="column" wrap="nowrap">
                <MenuHeader title="Simulations" />
                <Grid item container>
                  <MenuList className={classes.menuList}>
                    {simulations?.map((simulation) => (
                      <SimulationMenuItem
                        key={simulation?.get('simulationId')}
                        simulation={simulation}
                      />
                    ))}
                  </MenuList>
                </Grid>
                <Grid
                  container
                  style={{ marginTop: 20 }}
                  item
                  justifyContent="space-around"
                >
                  <CreateUpdatedPlannedButton simulations={simulations} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={10} component={Box}>
          <NavTabsContainer routes={props.routes} TabComponent={Tab} />
          <Grid container item xs={12} className={classes.tabsContainer}>
            <ExecutionRootContainerContext.Provider value={connection}>
              <RoutesContainer {...props} />
            </ExecutionRootContainerContext.Provider>
          </Grid>
        </Grid>
      </Grid>
      <ExecutionRootContainerContext.Provider value={connection}>
        <TaskExecution
          tasks={tasks}
          projectId={projectId}
          taskId={taskId}
          connection={connection}
        />
      </ExecutionRootContainerContext.Provider>
    </BasePage>
  );
};

export default withProjectPermission(ProjectPermission.EXECUTE)(
  ExecutionRootContainer,
);
