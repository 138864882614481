import Agent from 'infrastructure/agent';
import wellboreSectionMappers from 'features/wells/sections/wellboreSection.mappers';
import simulationMappers from 'features/projects/tasks/task/simulation/simulation.mappers';

const createSimulation = (projectId, taskId, simulation) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/simulations`,
    simulationMappers.Simulation.to(simulation),
  );

const deleteSimulation = (projectId, taskId, simulationId) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}`,
  );

const getSimulation = (projectId, taskId, simulationId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}`,
  );

const getSimulationsForTask = (projectId, taskId, states) => {
  return Agent.get(`/project/${projectId}/tasks/${taskId}/simulations`, {
    states,
  });
};

const getSimulationResults = (projectId, taskId, simulationId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/results`,
  );

const getSimulationResultsByTime = (projectId, taskId, simulationId, query) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/results/bytime`,
    query,
  );

const updateSimulation = (projectId, taskId, simulationId, simulation) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}`,
    simulationMappers.Simulation.to(simulation),
  );

const duplicateSimulation = (projectId, taskId, simulationId) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/copy`,
  );

const createUpdatedPlannedSimulation = (projectId, taskId) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/simulations/updated-planned`,
  );

const toggleSimulationFavorite = (projectId, taskId, simulationId) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/favorite`,
  );

const toggleSimulationPlanned = (projectId, taskId, simulationId) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/planned`,
  );

const getSimulationParameters = (projectId, taskId, simulationId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/parameters`,
  );

const getDefaultSimulationParameter = (projectId, taskId, simulationId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/parameters/default`,
  );

const createSimulationParameter = (
  projectId,
  taskId,
  simulationId,
  simulationParameter,
) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/parameters`,
    simulationMappers.SimulationParameter.to(simulationParameter),
  );

const updateSimulationParameter = (
  projectId,
  taskId,
  simulationId,
  simulationParameterId,
  simulationParameter,
) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/parameters/${simulationParameterId}`,
    simulationMappers.SimulationParameter.to(simulationParameter),
  );

const deleteSimulationParameter = (
  projectId,
  taskId,
  simulationId,
  simulationParameterId,
) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/parameters/${simulationParameterId}`,
  );

const getWellboreSectionsForSimulation = (projectId, taskId, simulationId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/sections`,
  );

const updateSimulationWellboreSection = (
  projectId,
  taskId,
  simulationId,
  wellboreSectionId,
  wellboreSection,
) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/sections/${wellboreSectionId}`,
    wellboreSectionMappers.WellboreSection.to(wellboreSection),
  );

const runSimulation = (projectId, taskId, simulationId) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/run`,
  );

const getDefaultSimulationFluid = (projectId, taskId, simulationId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/fluids/default`,
  );

const getSimulationFluids = (projectId, taskId, simulationId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/fluids`,
  );

const createSimulationFluid = (
  projectId,
  taskId,
  simulationId,
  simulationFluid,
) =>
  Agent.post(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/fluids`,
    simulationMappers.SimulationFluid.to(simulationFluid),
  );

const updateSimulationFluid = (
  projectId,
  taskId,
  simulationId,
  simulationFluidId,
  simulationFluid,
) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/fluids/${simulationFluidId}`,
    simulationMappers.SimulationFluid.to(simulationFluid),
  );

const deleteSimulationFluid = (
  projectId,
  taskId,
  simulationId,
  simulationFluidId,
) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/fluids/${simulationFluidId}`,
  );

const deleteSimulationResults = (projectId, taskId, simulationId) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/delete/results`,
  );

const deleteSimulationFluids = (projectId, taskId, simulationId) =>
  Agent.delete(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/fluids/delete`,
  );

const getSimulationDynamicToolParameters = (projectId, taskId, simulationId) =>
  Agent.get(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/dynamictoolparameters`,
  );

const updateSimulationDynamicToolParameter = (
  projectId,
  taskId,
  simulationId,
  simulationDynamicToolParameterId,
  simulationDynamicToolParameter,
) =>
  Agent.put(
    `/project/${projectId}/tasks/${taskId}/simulations/${simulationId}/dynamictoolparameters/${simulationDynamicToolParameterId}`,
    simulationMappers.SimulationDynamicToolParameter.to(
      simulationDynamicToolParameter,
    ),
  );

const simulationService = {
  runSimulation,
  getSimulation,
  createSimulation,
  deleteSimulation,
  updateSimulation,
  getSimulationFluids,
  duplicateSimulation,
  createUpdatedPlannedSimulation,
  getSimulationResults,
  createSimulationFluid,
  updateSimulationFluid,
  deleteSimulationFluid,
  getSimulationsForTask,
  deleteSimulationFluids,
  getSimulationParameters,
  deleteSimulationResults,
  toggleSimulationPlanned,
  toggleSimulationFavorite,
  updateSimulationParameter,
  createSimulationParameter,
  deleteSimulationParameter,
  getDefaultSimulationFluid,
  getSimulationResultsByTime,
  getDefaultSimulationParameter,
  updateSimulationWellboreSection,
  getWellboreSectionsForSimulation,
  getSimulationDynamicToolParameters,
  updateSimulationDynamicToolParameter,
};

export default simulationService;
