import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';

import { getProjectStatus } from 'features/projects/activities/activities.actions';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';
import ProjectExecutionStatusTable from 'features/projects/dashboard/components/ProjectExecutionStatusContainer/ProjectExecutionStatusTable';

const ProjectExecutionStatusContainer = ({ projectId }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectStatus(projectId));
  }, [dispatch, projectId]);

  const projectStatus = useSelector(getExecuteStatusFromState);

  if (!projectStatus.get('id')) return null;

  const tasksProgress = projectStatus.get('progress');
  const totalTasks = projectStatus.getIn(['total', 'tasks']);
  const currentTask = projectStatus.getIn(['current', 'task']);
  const currentTaskProgress = projectStatus.get('currentTaskProgress');

  const activeTask = projectStatus.get('activeTask');
  const percentComplete = currentTask ? currentTaskProgress : tasksProgress;

  if (!activeTask)
    return (
      <Typography variant="h6" gutterBottom>
        No tasks scheduled
      </Typography>
    );

  return (
    <Grid container direction="column" component={Box} padding={2}>
      <Grid item>
        <Typography variant="h6" gutterBottom>
          {`${activeTask.get('title')} (${activeTask.get(
            'sequence',
          )}/${totalTasks})`}
        </Typography>
      </Grid>
      <Grid item component={Box} marginBottom={2}>
        <LinearProgress variant="determinate" value={percentComplete} />
      </Grid>
      <Grid item xs>
        <ProjectExecutionStatusTable status={projectStatus} />
      </Grid>
    </Grid>
  );
};

export default ProjectExecutionStatusContainer;
