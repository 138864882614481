import { useMemo } from 'react';

import { Table } from 'altus-ui-components';

const defaultInitialState = {
  pageSize: 50,
};

const CheetahJobCurvesTable = ({ cheetahJobCurves }) => {
  const columns = useMemo(
    () => [
      {
        xs: 4,
        Header: 'Caption',
        accessor: (cheetahJobCurve) => cheetahJobCurve.get('caption'),
      },
      {
        xs: 4,
        Header: 'Description',
        accessor: (cheetahJobCurve) => cheetahJobCurve.get('description'),
      },
      {
        xs: 2,
        Header: 'Min',
        accessor: (cheetahJobCurve) => cheetahJobCurve.get('minValue'),
      },
      {
        xs: 2,
        Header: 'Max',
        accessor: (cheetahJobCurve) => cheetahJobCurve.get('maxValue'),
      },
      {
        xs: 2,
        Header: 'Unit',
        accessor: (cheetahJobCurve) => cheetahJobCurve.get('unit'),
      },
    ],
    [],
  );

  return (
    <Table
      usePagination
      columns={columns}
      useGlobalFilter={false}
      items={cheetahJobCurves}
      initialState={defaultInitialState}
    />
  );
};

export default CheetahJobCurvesTable;
