export const FACILITY_DOCUMENTS_ACTIONS = {
  DOWNLOAD_FACILITY_FILE: 'DOWNLOAD_FACILITY_FILE',
  UPDATE_FACILITY_FILE: 'UPDATE_FACILITY_FILE',
  UPLOAD_FACILITY_FILE: 'UPLOAD_FACILITY_FILE',
  DELETE_FACILITY_FILE: 'DELETE_FACILITY_FILE',
  RECEIVE_FACILITY_FILE: 'RECEIVE_FACILITY_FILE',
  RECEIVE_FACILITY_FILES: 'RECEIVE_FACILITY_FILES',
  UPLOAD_FILE_MODAL_UNLOADED: 'UPLOAD_FILE_MODAL_UNLOADED',
  SET_DOCUMENT_CATEGORY_FILTER: 'SET_DOCUMENT_CATEGORY_FILTER',
  PROJECT_FACILITY_PAGE_LOADED: 'PROJECT_FACILITY_PAGE_LOADED',
  PROJECT_FACILITY_PAGE_UNLOADED: 'PROJECT_FACILITY_PAGE_UNLOADED',
};
