import React, { useState, useEffect, useCallback, useRef } from 'react';

import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBullet from 'highcharts/modules/bullet';
import { Grid, withStyles, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

HighchartsMore(Highcharts);
HighchartsBullet(Highcharts);

const MetalMillingChart = ({
  classes,
  type,
  value,
  target,
  min,
  max,
  chartGradient,
}) => {
  const chartComponent = useRef(null);
  const theme = useTheme();

  const updateSeriesData = useCallback((data) => {
    const chart = chartComponent.current.chart;

    const matchingSeries = chart.series.filter(
      (series) => series.name === 'value',
    );
    let dataToSet = {
      target: data,
    };
    matchingSeries[0].setData([dataToSet]);

    chart.redraw();
    chart.reflow();
  }, []);

  useEffect(() => {
    if (target) {
      updateSeriesData(target);
    }
  }, [target, updateSeriesData]);

  const [chartOptions] = useState({
    chart: {
      inverted: true,
      type: 'bullet',
      backgroundColor: '',
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
    },
    title: {
      text: '',
    },
    xAxis: {
      labels: {
        enabled: false,
        style: {
          color: theme.palette.common.white,
          fontSize: '50px',
        },
      },
    },
    tooltip: {
      enabled: true,
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      height: '50%',
      top: '25%',
      min: min,
      max: max,
      gridLineWidth: 0,
      plotBands: chartGradient,
      title: '',
    },
    series: [
      {
        name: 'value',
        data: [
          {
            target: target,
          },
        ],
        labels: {},
      },
    ],
    plotOptions: {
      series: {
        borderWidth: 0,
        color: theme.palette.common.black,
        targetOptions: {
          height: 5,
          width: '1000%',
          color: theme.palette.common.white,
        },
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
      align: 'right',
      verticalAlign: 'middle',
      layout: 'vertical',
      itemStyle: {
        fontSize: 40,
        color: theme.palette.common.white,
      },
      symbolPadding: 0,
      symbolWidth: 0,
      symbolHeight: 0,
      squareSymbol: false,
    },
  });

  return (
    <Grid container alignItems="center" justifyContent="center" direction="row">
      <Grid item xs={2}>
        <Typography align="center" className={classes.font}>
          {type}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <HighchartsReact
          ref={chartComponent}
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={{
            style: { width: '100%', height: 50, borderRadius: 5 },
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography align="center" className={classes.font}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  font: {
    fontWeight: 'bold',
  },
});

export default withStyles(styles)(MetalMillingChart);
