import { initialize } from 'redux-form/immutable';

import {
  MODAL,
  LESSONS_ACTIONS,
  EDIT_LESSON_FORM,
  CREATE_LESSON_FORM,
} from 'features/projects/lessons/lessons.constants';

import { toggleModal } from 'app/app.actions';
import lessonsService from 'services/lessons.service';
import lessonsMapper from 'features/projects/lessons/lessons.mappers';
import { getFormValuesFromState } from 'app/app.selectors';

export const selectLesson = (index) => (dispatch) =>
  dispatch(toggleModal({ modalId: MODAL.EDIT_LESSON, activeIndex: index }));

export const onEditLessonModalLoaded = (lesson) => (dispatch) =>
  dispatch(initialize(EDIT_LESSON_FORM.ID, lesson));

export const onCreateLessonModalLoaded = () => (dispatch) =>
  dispatch(initialize(CREATE_LESSON_FORM.ID, lessonsMapper.Lesson.initial()));

export const createLesson = (projectId) => (dispatch, getState) => {
  const lesson = getFormValuesFromState(
    getState(),
    CREATE_LESSON_FORM.ID,
  ).toJS();

  const payload = lessonsService
    .createLesson(projectId, lesson)
    .then((response) => {
      dispatch(
        toggleModal({
          modalId: MODAL.CREATE_LESSON,
        }),
      );
      return response;
    });

  dispatch({
    type: LESSONS_ACTIONS.CREATE_LESSON,
    payload,
  });

  return payload;
};

export const updateLesson = (projectId) => (dispatch, getState) => {
  const lesson = getFormValuesFromState(getState(), EDIT_LESSON_FORM.ID).toJS();

  const payload = lessonsService
    .updateLesson(projectId, lesson)
    .then((response) => {
      dispatch(toggleModal({ modalId: MODAL.EDIT_LESSON }));
      return response;
    });

  dispatch({
    lessonId: lesson.id,
    type: LESSONS_ACTIONS.UPDATE_LESSON,
    payload,
  });

  return payload;
};

export const onLoad = (projectId) => (dispatch) =>
  dispatch({
    type: LESSONS_ACTIONS.PROJECT_LESSONS_PAGE_LOADED,
    payload: () => lessonsService.allLessons(projectId),
  });

export const onUnload = () => (dispatch) =>
  dispatch({ type: LESSONS_ACTIONS.PROJECT_LESSONS_PAGE_UNLOADED });

export const deleteLesson = (projectId, lessonId) => (dispatch) => {
  dispatch({
    type: LESSONS_ACTIONS.DELETE_LESSON,
    payload: () => lessonsService.deleteLesson(projectId, lessonId),
    id: lessonId,
    confirmationDialog: {
      confirmButtonText: 'Delete',
      title: 'Delete lesson',
      description: 'Are you sure you want to delete this lesson?',
    },
  });
};
