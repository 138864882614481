import { put, call, takeEvery } from 'redux-saga/effects';
import { callAsync } from 'app/sagas/helperSagas';
import { FormikFormStatus } from 'app/app.constants';
import riskService from 'services/risk.service';
import { RISK_TABLE_ACTIONS } from 'features/projects/tasks/task/details/taskDetails.constants';
import {
  receiveTaskRiskSections,
  deleteTaskRiskSection,
} from 'features/projects/tasks/task/details/taskDetails.actions';
import {
  deleteProjectRiskSection,
  receiveProjectRiskSections,
  receiveTaskRiskByProject,
} from 'features/projects/details/details.actions';

export function* requestTaskRiskSectionsForRiskAsync(action) {
  const { taskId } = action;

  const sections = yield call(
    riskService.getTaskRiskSectionsForTaskRisk,
    taskId,
  );

  yield put(receiveTaskRiskSections(sections));

  return sections;
}

export function* requestCreateTaskRiskSectionAsync(action) {
  const { risk, setStatus, callback, taskId } = action;
  const { description, mitigation, consequence, probability, state } = risk;
  const payload = {
    id: taskId,
    description: description,
    mitigation: mitigation,
    consequence: consequence,
    probability: probability,
    state: state,
  };
  try {
    const riskSection = yield call(riskService.createTaskRiskSection, payload);

    yield call(requestTaskRiskSectionsForRiskAsync, action);

    if (callback) {
      yield call(callback);
    }
    return riskSection;
  } catch (error) {
    yield call(setStatus, FormikFormStatus.ERROR);
    throw error;
  }
}

export function* requestUpdateTaskRiskSectionAsync(action) {
  const { risk } = action;
  const payload = risk;

  const wellboreSection = yield call(
    riskService.updateTaskRiskSection,
    payload,
  );

  yield call(requestTaskRiskSectionsForRiskAsync, action);

  return wellboreSection;
}

export function* requestDeleteTaskRiskSectionAsync(action) {
  const { riskId } = action;

  yield call(riskService.deleteTaskRiskSection, riskId);

  yield put(deleteTaskRiskSection(riskId));

  yield call(requestTaskRiskSectionsForRiskAsync, action);
}

//PROJECT
export function* requestProjectRiskSectionsForRiskAsync(action) {
  const { taskId } = action;

  const sections = yield call(
    riskService.getProjectRiskSectionsForProjectRisk,
    taskId,
  );

  yield put(receiveProjectRiskSections(sections));

  return sections;
}

export function* requestTaskRiskSectionsByProjectAsync(action) {
  const { projectId } = action;

  const sections = yield call(
    riskService.getTaskRiskSectionsByProject,
    projectId,
  );

  yield put(receiveTaskRiskByProject(sections));

  return sections;
}

export function* requestCreateProjectRiskSectionAsync(action) {
  const { risk, setStatus, callback, taskId } = action;
  const { description, mitigation, probability, consequence, state } = risk;
  const payload = {
    id: taskId,
    description: description,
    mitigation: mitigation,
    probability: probability,
    consequence: consequence,
    state: state,
  };
  try {
    const riskSection = yield call(
      riskService.createProjectRiskSection,
      payload,
    );

    yield call(requestProjectRiskSectionsForRiskAsync, action);

    if (callback) {
      yield call(callback);
    }
    return riskSection;
  } catch (error) {
    yield call(setStatus, FormikFormStatus.ERROR);
    throw error;
  }
}

export function* requestUpdateProjectRiskSectionAsync(action) {
  const { risk } = action;
  const payload = risk;

  const wellboreSection = yield call(
    riskService.updateProjectRiskSection,
    payload,
  );

  yield call(requestProjectRiskSectionsForRiskAsync, action);

  return wellboreSection;
}

export function* requestDeleteProjectRiskSectionAsync(action) {
  const { riskId } = action;

  yield call(riskService.deleteProjectRiskSection, riskId);

  yield put(deleteProjectRiskSection(riskId));

  yield call(requestProjectRiskSectionsForRiskAsync, action);
}

export default function* root() {
  yield takeEvery(
    RISK_TABLE_ACTIONS.REQUEST_TASK_RISK_SECTIONS,
    callAsync,
    requestTaskRiskSectionsForRiskAsync,
  );

  yield takeEvery(
    RISK_TABLE_ACTIONS.REQUEST_CREATE_TASK_RISK_SECTION,
    callAsync,
    requestCreateTaskRiskSectionAsync,
  );

  yield takeEvery(
    RISK_TABLE_ACTIONS.REQUEST_UPDATE_TASK_RISK_SECTION,
    callAsync,
    requestUpdateTaskRiskSectionAsync,
  );

  yield takeEvery(
    RISK_TABLE_ACTIONS.REQUEST_DELETE_TASK_RISK_SECTION,
    callAsync,
    requestDeleteTaskRiskSectionAsync,
  );

  //PROJECT
  yield takeEvery(
    RISK_TABLE_ACTIONS.REQUEST_PROJECT_RISK_SECTIONS,
    callAsync,
    requestProjectRiskSectionsForRiskAsync,
  );

  yield takeEvery(
    RISK_TABLE_ACTIONS.REQUEST_CREATE_PROJECT_RISK_SECTION,
    callAsync,
    requestCreateProjectRiskSectionAsync,
  );

  yield takeEvery(
    RISK_TABLE_ACTIONS.REQUEST_UPDATE_PROJECT_RISK_SECTION,
    callAsync,
    requestUpdateProjectRiskSectionAsync,
  );

  yield takeEvery(
    RISK_TABLE_ACTIONS.REQUEST_DELETE_PROJECT_RISK_SECTION,
    callAsync,
    requestDeleteProjectRiskSectionAsync,
  );

  yield takeEvery(
    RISK_TABLE_ACTIONS.REQUEST_TASK_RISK_BY_PROJECT,
    callAsync,
    requestTaskRiskSectionsByProjectAsync,
  );
}
