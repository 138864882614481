import { Map, List } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { REPORT_ACTIONS } from 'features/projects/report/report.constants';
import activitiesMappers from 'features/projects/activities/activities.mappers';

const dataInitialState = Map({
  activities: List(),
});

const dataReducer = (state = dataInitialState, action) => {
  switch (action.type) {
    case REPORT_ACTIONS.PROJECT_REPORT_GET_ACTIVITIES: {
      if (action.error) return state;

      return state.update('activities', (activities) =>
        activities.concat(
          List(action.payload).map(activitiesMappers.Activity.from),
        ),
      );
    }
    default:
      return state;
  }
};

export default combineReducers({
  data: dataReducer,
});
