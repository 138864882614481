import { compose } from 'redux';
import { lighten } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { invokeIfFunction } from 'utils/app.util';

const FormSectionHeader = ({ title, classes, renderActions }) => (
  <Grid container alignItems="center" className={classes.root}>
    <Grid container spacing={1}>
      {renderActions && <Grid item>{invokeIfFunction(renderActions)}</Grid>}
      <Grid item>
        <Typography variant="h6">{title}</Typography>
      </Grid>
    </Grid>
  </Grid>
);

const styles = (theme) => ({
  root: {
    top: 0,
    zIndex: 2,
    position: 'sticky',
    background: lighten(theme.palette.grey[800], 0.04),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
  },
});

export default compose(withStyles(styles))(FormSectionHeader);
