import { useCallback, useEffect, useState } from 'react';
import { useRequest, useToggle } from 'altus-hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestCreateFluid,
  requestDeleteFluid,
  requestGetProjectFluids,
  requestUpdateFluid,
} from 'features/projects/project.actions.fluids';
import { getProjectFluidsSelector } from 'features/projects/projects.selectors';
import { EMPTY_MAP } from 'app/app.constants';
import FluidTableRow from 'app/components/FluidsFormSection/FluidTableRow';
import { Grid, Typography } from '@material-ui/core';
import FluidsTable from 'app/components/FluidsFormSection/FluidsTable';
import FluidEditor from 'app/components/FluidsFormSection/FluidEditor';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';
import { LoadingOverlay } from 'altus-ui-components';
import projectFluidService from 'services/projectFluid.service';
import FormSection from 'app/components/form/FormSection';
import { fromJS } from 'immutable';
import {
  requestCreateWellboreFluid,
  requestDeleteWellboreFluid,
  requestGetWellboreFluids,
  requestUpdateWellboreFluid,
} from 'features/wells/wellboreFluid.actions';
import { getWellboreFluidsSelector } from 'features/wells/wellbore.selectors';
import { WELLS_ACTIONS } from 'features/wells/wells.constants';
import wellboreFluidService from 'services/wellboreFluid.service';
import {
  requestCreateTaskFluid,
  requestDeleteTaskFluid,
  requestGetTaskFluids,
  requestUpdateTaskFluid,
} from 'features/projects/task.actions.fluids';
import { getTaskFluidsSelector } from 'features/projects/tasks/tasks.selectors';
import { TASK_ACTIONS } from 'features/projects/tasks/tasks.constants';
import taskService from 'services/task.service';
import { useIncludeProjectIdToArguments } from 'app/components/FluidsFormSection/hooks/useIncludeProjectIdToArguments';

/**
 * Component to manage fluids against project or wellbore.
 *
 * @param disabled
 * @param {number} parentFluidObjectId Id of the parent object of fluids (projectWellboreDetailsId or WellboreId)
 * @param dataStateActions
 * @param requestFluidsAction
 * @param requestDeleteFluidAction
 * @param requestCreateFluidAction
 * @param requestUpdateFluidAction
 * @param getFluidsSelector
 * @param getDefaultFluid
 * @param sectionTitle
 * @return JSX (component)
 */
function FluidsFormSection({
  disabled,
  parentFluidObjectId,
  dataStateActions,
  requestFluidsAction,
  requestDeleteFluidAction,
  requestCreateFluidAction,
  requestUpdateFluidAction,
  getFluidsSelector,
  getDefaultFluid,
  sectionTitle,
}) {
  const [fluidId, setFluidId] = useState(null);
  const [isCreateMode, toggleCreateMode] = useToggle();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestFluidsAction(parentFluidObjectId));
  }, [requestFluidsAction, parentFluidObjectId, dispatch]);
  const fluids = useSelector(getFluidsSelector) ?? EMPTY_MAP;
  const dataState = useSelector((state) =>
    getSummarizedDataStateFromState(state, ...dataStateActions),
  );

  const getDefaultProjectFluid = useCallback(
    () => getDefaultFluid(parentFluidObjectId).then(fromJS),
    [getDefaultFluid, parentFluidObjectId],
  );
  const [defaultProjectFluid = EMPTY_MAP] = useRequest(getDefaultProjectFluid);

  const renderTableRowComponent = useCallback(
    (props) => {
      const rowFluidId = props.item.get('fluidId').toString();
      return (
        <FluidTableRow
          {...props}
          isSelected={false}
          onRowClick={() => {
            setFluidId(rowFluidId);
            if (isCreateMode) {
              toggleCreateMode();
            }
          }}
        />
      );
    },
    [isCreateMode, toggleCreateMode],
  );

  const selectedFluid = fluids.get(fluidId);

  return (
    <>
      <FormSection title={sectionTitle}>
        <Grid container direction="column">
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography variant="subtitle2" gutterBottom>
                Fluids
              </Typography>
              <FluidsTable
                disabled={disabled}
                isCreateMode={isCreateMode}
                toggleCreateMode={() => {
                  toggleCreateMode();
                  setFluidId(null);
                }}
                deleteFluid={(fluidId) =>
                  dispatch(
                    requestDeleteFluidAction(parentFluidObjectId, fluidId),
                  )
                }
                fluids={fluids}
                defaultFluid={defaultProjectFluid}
                TableRowComponent={renderTableRowComponent}
              />
            </Grid>
            <Grid item xs={7}>
              <Typography variant="subtitle2" gutterBottom>
                Fluid Editor
              </Typography>
              <FluidEditor
                disabled={disabled}
                isCreateMode={isCreateMode}
                fluid={selectedFluid}
                toggleCreateMode={toggleCreateMode}
                fluidId={fluidId}
                defaultFluid={defaultProjectFluid}
                updateFluid={(updatedObj, { setSubmitting, setStatus }) =>
                  dispatch(
                    requestUpdateFluidAction(
                      parentFluidObjectId,
                      updatedObj,
                      setSubmitting,
                      setStatus,
                    ),
                  )
                }
                createFluid={(newFluid, { setSubmitting, setStatus }) => {
                  dispatch(
                    requestCreateFluidAction(
                      parentFluidObjectId,
                      newFluid,
                      setSubmitting,
                      setStatus,
                    ),
                  );
                  toggleCreateMode();
                }}
              />
            </Grid>
          </Grid>
          <LoadingOverlay dataState={dataState} />
        </Grid>
      </FormSection>
    </>
  );
}

export function FluidsFormSectionWellbore(props) {
  // Hide overlay on INITIATE_GET_WELLBORE_FLUIDS
  // because hostile fluids section already has overlay
  const dataStateActions = [
    // WELLS_ACTIONS.INITIATE_GET_WELLBORE_FLUIDS,
    WELLS_ACTIONS.INITIATE_CREATE_WELLBORE_FLUID,
    WELLS_ACTIONS.INITIATE_UPDATE_WELLBORE_FLUID,
    WELLS_ACTIONS.INITIATE_DELETE_WELLBORE_FLUID,
  ];
  return (
    <FluidsFormSection
      dataStateActions={dataStateActions}
      requestFluidsAction={requestGetWellboreFluids}
      requestDeleteFluidAction={requestDeleteWellboreFluid}
      requestCreateFluidAction={requestCreateWellboreFluid}
      requestUpdateFluidAction={requestUpdateWellboreFluid}
      getFluidsSelector={getWellboreFluidsSelector}
      getDefaultFluid={wellboreFluidService.getDefaultFluid}
      sectionTitle="Fluids"
      {...props}
    />
  );
}

export function FluidsFormSectionProject({ projectId, ...props }) {
  // Extend all actions with projectId
  const requestGetProjectFluidsExt = useIncludeProjectIdToArguments(
    requestGetProjectFluids,
    projectId,
  );
  const getDefaultProjectFluidExt = useIncludeProjectIdToArguments(
    projectFluidService.getDefaultProjectFluid,
    projectId,
  );
  const requestDeleteFluidExt = useIncludeProjectIdToArguments(
    requestDeleteFluid,
    projectId,
  );
  const requestCreateFluidExt = useIncludeProjectIdToArguments(
    requestCreateFluid,
    projectId,
  );
  const requestUpdateFluidExt = useIncludeProjectIdToArguments(
    requestUpdateFluid,
    projectId,
  );

  const dataStateActions = [
    PROJECTS_ACTIONS.INITIATE_GET_PROJECT_FLUIDS,
    PROJECTS_ACTIONS.INITIATE_CREATE_PROJECT_FLUID,
    PROJECTS_ACTIONS.INITIATE_DELETE_PROJECT_FLUID,
    PROJECTS_ACTIONS.INITIATE_UPDATE_PROJECT_FLUID,
  ];
  return (
    <FluidsFormSection
      dataStateActions={dataStateActions}
      requestFluidsAction={requestGetProjectFluidsExt}
      requestDeleteFluidAction={requestDeleteFluidExt}
      requestCreateFluidAction={requestCreateFluidExt}
      requestUpdateFluidAction={requestUpdateFluidExt}
      getFluidsSelector={getProjectFluidsSelector}
      getDefaultFluid={getDefaultProjectFluidExt}
      sectionTitle="Fluids"
      {...props}
    />
  );
}

export function FluidsFormSectionDataAcquistion({ projectId, ...props }) {
  const requestGetTaskFluidsFn = useIncludeProjectIdToArguments(
    requestGetTaskFluids,
    projectId,
  );
  const getDefaultFluidFn = useIncludeProjectIdToArguments(
    taskService.getDefaultTaskFluid,
    projectId,
  );
  const requestDeleteTaskFluidFn = useIncludeProjectIdToArguments(
    requestDeleteTaskFluid,
    projectId,
  );
  const requestUpdateTaskFluidFn = useIncludeProjectIdToArguments(
    requestUpdateTaskFluid,
    projectId,
  );
  const requestCreateTaskFluidFn = useIncludeProjectIdToArguments(
    requestCreateTaskFluid,
    projectId,
  );

  const dataStateActions = [
    TASK_ACTIONS.INITIATE_GET_TASK_FLUIDS,
    TASK_ACTIONS.INITIATE_DELETE_TASK_FLUID,
    TASK_ACTIONS.INITIATE_UPDATE_TASK_FLUID,
    TASK_ACTIONS.INITIATE_CREATE_TASK_FLUID,
  ];
  return (
    <FluidsFormSection
      dataStateActions={dataStateActions}
      requestFluidsAction={requestGetTaskFluidsFn}
      requestDeleteFluidAction={requestDeleteTaskFluidFn}
      requestCreateFluidAction={requestCreateTaskFluidFn}
      requestUpdateFluidAction={requestUpdateTaskFluidFn}
      getFluidsSelector={getTaskFluidsSelector}
      getDefaultFluid={getDefaultFluidFn}
      sectionTitle="Fluids"
      {...props}
    />
  );
}
