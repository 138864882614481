import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid, Divider } from '@material-ui/core';
import { useCallback, useEffect, useMemo } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import {
  Menu,
  withModal,
  MenuHeader,
  LoadingOverlay,
  RouteContainer,
} from 'altus-ui-components';

import {
  loadSimulations,
  requestDeleteSimulation,
  requestDuplicateSimulation,
  requestToggleSimulationFavorite,
  receiveSimulationsForTask,
} from 'features/projects/tasks/task/simulation/simulation.actions';

import { EMPTY_MAP } from 'app/app.constants';
import { toTaskSimulation } from 'utils/route.util';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import {
  MODAL,
  SimulationState,
} from 'features/projects/tasks/task/simulation/simulation.constants';
import { ACTIONS } from 'features/projects/tasks/task/simulation/simulation.constants';
import CreateSimulationModal from 'features/projects/tasks/task/simulation/components/CreateSimulationModal';
import { getSimulationsForTaskFromState } from 'features/projects/tasks/task/simulation/simulation.selectors';
import NoSimulationSelectedPage from 'features/projects/tasks/task/simulation/components/NoSimulationSelectedPage';
import SimulationCardNavigationItem from 'features/projects/tasks/task/simulation/components/SimulationCardNavigationItem';

const TaskSimulationsContainer = ({
  path,
  routes,
  taskId,
  classes,
  projectId,
  dataState,
  toggleModal,
  simulations,
  dispatchRequestDeleteSimulation,
  simulationId: currentSimulationId,
  dispatchRequestDuplicateSimulation,
  dispatchRequestToggleSimulationFavorite,
}) => {
  const menuItems = useMemo(() => simulations.valueSeq(), [simulations]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectId) {
      dispatch(
        loadSimulations(projectId, taskId, [
          SimulationState.NOT_COMPLETED,
          SimulationState.COMPLETED,
          SimulationState.OUTDATED,
          SimulationState.ERROR,
          SimulationState.PLANNED,
        ]),
      );
    }

    // Reset simulations list on unload
    return () => {
      dispatch(receiveSimulationsForTask([]));
    };
  }, [projectId, taskId, dispatch]);

  const onToggleFavorite = useCallback(
    (simulationId) =>
      dispatchRequestToggleSimulationFavorite(projectId, taskId, simulationId),
    [taskId, projectId, dispatchRequestToggleSimulationFavorite],
  );

  const onDeleteSimulation = useCallback(
    (simulationId) =>
      dispatchRequestDeleteSimulation(
        projectId,
        taskId,
        simulationId,
        simulationId === currentSimulationId
          ? toTaskSimulation(projectId, taskId)
          : null,
      ),
    [taskId, projectId, currentSimulationId, dispatchRequestDeleteSimulation],
  );

  const onDuplicateSimulation = useCallback(
    (simulationId) =>
      dispatchRequestDuplicateSimulation(projectId, taskId, simulationId),
    [taskId, projectId, dispatchRequestDuplicateSimulation],
  );

  const renderMenuItem = useCallback(
    (simulation) => {
      const simulationId = simulation.get('simulationId');
      const to = toTaskSimulation(projectId, taskId, simulationId);

      return (
        <SimulationCardNavigationItem
          key={simulation.get('simulationId')}
          to={to}
          simulation={simulation}
          simulationId={simulationId}
          onDelete={onDeleteSimulation}
          toggleFavorite={onToggleFavorite}
          onDuplicate={onDuplicateSimulation}
        />
      );
    },
    [
      taskId,
      projectId,
      onToggleFavorite,
      onDeleteSimulation,
      onDuplicateSimulation,
    ],
  );

  return (
    <Grid container item xs wrap="nowrap" className={classes.root}>
      <Grid container xs={2} item direction="column" wrap="nowrap">
        <MenuHeader title="Simulations" onAdd={toggleModal} />
        <Divider />
        <CreateSimulationModal projectId={projectId} taskId={taskId} />
        <Grid item container className={classes.menuContainer}>
          <Menu items={menuItems} renderMenuItem={renderMenuItem} />
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      {!!simulations.size && !currentSimulationId && (
        <Redirect
          from={path}
          to={toTaskSimulation(
            projectId,
            taskId,
            simulations.first().get('simulationId'),
          )}
        />
      )}
      <RouteContainer
        routes={routes}
        key={currentSimulationId}
        MissingRouteComponent={NoSimulationSelectedPage}
      />
      <LoadingOverlay dataState={dataState} timeout={0} />
    </Grid>
  );
};

const styles = () => ({
  root: {
    overflow: 'hidden',
  },
  menuContainer: {
    overflow: 'auto',
  },
});

TaskSimulationsContainer.defaultProps = {
  simulations: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { taskId }) => ({
      simulations: getSimulationsForTaskFromState(state, taskId),
      dataState: getSummarizedDataStateFromState(
        state,
        ACTIONS.LOAD_SIMULATIONS,
      ),
    }),
    {
      dispatchRequestDeleteSimulation: requestDeleteSimulation,
      dispatchRequestDuplicateSimulation: requestDuplicateSimulation,
      dispatchRequestToggleSimulationFavorite: requestToggleSimulationFavorite,
    },
  ),
  withModal(MODAL.CREATE_SIMULATION_MODAL),
  withStyles(styles),
)(TaskSimulationsContainer);
