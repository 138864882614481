import { call, select, put, takeEvery } from 'redux-saga/effects';

import { createTaskActivitiesSelector } from 'features/projects/activities/activities.selectors';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import {
  getProjectStatus,
  Identity,
  sendProjectChangedNotification,
} from 'features/projects/activities/sagas/common.sagas';
import {
  ACTIVITIES_ACTIONS as ACTIONS,
  ACTIVITIES_ACTIONS,
} from 'features/projects/activities/activities.constants';
import activitiesService from 'services/activities.service';
import { callAsync } from 'app/sagas/helperSagas';

export function* initiateSortActivities(action) {
  yield call(
    initiateSortActivitiesBase,
    action,
    ACTIVITIES_ACTIONS.BACKEND_SORT_ACTIVITIES,
  );
}

function* initiateSortActivitiesNoNotification(action) {
  yield call(
    initiateSortActivitiesBase,
    action,
    ACTIVITIES_ACTIONS.BACKEND_SORT_ACTIVITIES_NO_NOTIFICATION,
  );
}

function* initiateSortActivitiesBase(action, actionType) {
  const { projectId, taskId, oldIndex, newIndex, connection } = action;

  const activitiesSelector = createTaskActivitiesSelector(taskId);
  const activities = yield select(activitiesSelector);
  if (oldIndex === newIndex) return;
  const sortedActivty = activities.valueSeq().get(oldIndex);
  const affectedActivty = activities.valueSeq().get(newIndex);

  if (!sortedActivty || !affectedActivty) return;

  const itemSequence = {
    sortedItemId: sortedActivty.get('id'),
    affectedItemId: affectedActivty.get('id'),
  };

  yield put({
    projectId,
    taskId,
    oldIndex,
    newIndex,
    connection,
    itemSequence,
    sortedActivty,
    type: actionType,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Loading...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The activity was successfully updated',
      [NOTIFICATION_VARIANTS.ERROR]: 'Error while reordering activities!',
    },
  });
}

function* sortActivities(action) {
  yield call(sortActivitiesBase, action, sendProjectChangedNotification);
}

function* sortActivitiesNoNotification(action) {
  yield call(sortActivitiesBase, action, Identity);
}

function* sortActivitiesBase(action, notificationFn) {
  const {
    projectId,
    taskId,
    oldIndex,
    newIndex,
    itemSequence,
    sortedActivty,
    connection,
  } = action;

  const updatedSequences = yield call(
    activitiesService.updateActivitySequence,
    projectId,
    taskId,
    sortedActivty.get('id'),
    itemSequence,
  );
  yield call(getProjectStatus, projectId);
  yield call(notificationFn, connection, projectId, taskId);

  yield put({
    taskId,
    projectId,
    oldIndex,
    newIndex,
    skipTracking: true,
    payload: updatedSequences,
    type: ACTIVITIES_ACTIONS.SORT_ACTIVITIES,
  });
}

export function* rootSortActivities() {
  yield takeEvery(
    ACTIONS.INITIATE_SORT_ACTIVITIES,
    callAsync,
    initiateSortActivities,
  );
  yield takeEvery(
    ACTIONS.INITIATE_SORT_ACTIVITIES_NO_NOTIFICATION,
    callAsync,
    initiateSortActivitiesNoNotification,
  );
  yield takeEvery(ACTIONS.BACKEND_SORT_ACTIVITIES, callAsync, sortActivities);
  yield takeEvery(
    ACTIONS.BACKEND_SORT_ACTIVITIES_NO_NOTIFICATION,
    callAsync,
    sortActivitiesNoNotification,
  );
}
