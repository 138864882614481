import { EMPTY_LIST } from 'app/app.constants';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import { Table } from 'altus-ui-components';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field } from 'formik';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import { FluidSelectionType } from '../simulationDynamicToolParameter.constants';
import { fluidSelectionTypeToString } from '../../simulation.mappers';
import * as actions from '../simulationDynamicToolParameter.actions';

export const SimulationDynamicToolParameterFluidSelectionTable = ({
  data = EMPTY_LIST,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { projectId, taskId, simulationId } = useParams();

  const updateDynamicSimulationParameter = (
    parameter,
    { setSubmitting, setStatus },
  ) =>
    dispatch(
      actions.requestUpdateSimulationDynamicToolParameter(
        projectId,
        taskId,
        simulationId,
        parameter.simulationDynamicToolParameterId,
        parameter,
        setSubmitting,
        setStatus,
      ),
    );

  const columns = [
    {
      xs: 4,
      id: 'toolName',
      Header: 'Name',
      Cell: ({ row }) => row.original.get('toolName'),
    },
    {
      xs: 4,
      id: 'fluidSelectionRunInHole',
      Header: 'Run In Hole',
      Cell: () => {
        return (
          <Field
            select
            component={TableRowTextFieldFormik}
            name={'fluidSelectionRunInHole'}
            disabled={disabled}
          >
            {Object.values(FluidSelectionType).map((type) => (
              <MenuItem key={type} value={type}>
                {fluidSelectionTypeToString(type)}
              </MenuItem>
            ))}
          </Field>
        );
      },
    },
    {
      xs: 4,
      id: 'fluidSelectionPullOutOfHole',
      Header: 'Pull Out Of Hole',
      Cell: () => {
        return (
          <Field
            select
            component={TableRowTextFieldFormik}
            name={'fluidSelectionPullOutOfHole'}
            disabled={disabled}
          >
            {Object.values(FluidSelectionType).map((type) => (
              <MenuItem key={type} value={type}>
                {fluidSelectionTypeToString(type)}
              </MenuItem>
            ))}
          </Field>
        );
      },
    },
  ];

  const renderTableRowComponent = (props) => (
    <EditableTableRowFormik
      {...props}
      onSubmit={updateDynamicSimulationParameter}
    />
  );

  return (
    <Grid container padding={1}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Fluid Selection
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Table
          disableSortBy
          columns={columns}
          useGlobalFilter={false}
          items={data}
          noItemsMessage={'No Fluid Selection Tools in BHA'}
          displayNoItemsMessage
          TableRowComponent={renderTableRowComponent}
        />
      </Grid>
    </Grid>
  );
};
