import { useState, useEffect } from 'react';
import TextField from 'app/components/form/TextFieldV2';

const timeToSeconds = (time) => {
  if (time) {
    const [hours, minutes] = time.split(':');
    return (parseInt(hours, 10) * 60 + parseInt(minutes, 10)) * 60;
  }
  return 0;
};

const secondsToTime = (seconds) => {
  if (seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }
  return '';
};

const TimeSpanFormik = ({ field, form, ...props }) => {
  const { value, name } = field;
  const { setFieldValue } = form;

  const [formattedValue, setFormattedValue] = useState(
    secondsToTime(value || ''),
  );

  useEffect(() => {
    setFieldValue(name, timeToSeconds(formattedValue));
  }, [formattedValue, name, setFieldValue]);

  const handleKeyDown = (e) => {
    let newValue =
      e.key === 'Backspace' || e.key === 'Delete'
        ? formattedValue.slice(0, -1)
        : formattedValue + e.key;

    if (!e.key.match(/[0-9]|Backspace|Delete/)) {
      e.preventDefault();
      return;
    }

    if (newValue.length === 2 && formattedValue.length === 1) {
      newValue += ':';
    } else if (newValue.length === 2 && formattedValue.length === 3) {
      newValue = newValue.slice(0, -1);
    } else if (newValue.replace(/:/g, '').length > 4) {
      e.preventDefault();
      return;
    }

    setFormattedValue(newValue);
    e.preventDefault();
  };

  return (
    <TextField
      {...field}
      {...props}
      value={formattedValue}
      onKeyDown={handleKeyDown}
    />
  );
};

export default TimeSpanFormik;
