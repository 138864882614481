export const CREATE_PROJECT_MODAL_ID = 'CREATE_PROJECT_MODAL_ID';

export const PROJECTS_ACTIONS = {
  RECEIVE_PROJECT: 'RECEIVE_PROJECT',
  RECEIVE_PROJECTS: 'RECEIVE_PROJECTS',
  CREATE_PROJECT: 'CREATE_PROJECT',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  SEARCH_PROJECTS: 'SEARCH_PROJECTS',
  GET_PROJECT_BY_ID: 'GET_PROJECT_BY_ID',
  GET_ACTIVITY_TYPES: 'GET_ACTIVITY_TYPES',
  PROJECTS_PAGE_LOADED: 'PROJECTS_PAGE_LOADED',
  GET_PROJECT_RESOURCES: 'GET_PROJECT_RESOURCES',
  PROJECTS_PAGE_UNLOADED: 'PROJECTS_PAGE_UNLOADED',
  RESET_PROJECT_RESOURCES: 'RESET_PROJECT_RESOURCES',
  GET_PROJECT_STATUS_SILENT: 'GET_PROJECT_STATUS_SILENT',
  PROJECT_PAGES_UNLOADED: 'PROJECT_PAGES_UNLOADED',
  CLEAR_PROJECTS: 'CLEAR_PROJECTS',
  GET_CURRENT_PROJECT_MEMBER: 'GET_CURRENT_PROJECT_MEMBER',

  // FLUID ACTIONS
  INITIATE_GET_PROJECT_FLUIDS: 'INITIATE_GET_PROJECT_FLUIDS',
  GET_PROJECT_FLUIDS: 'GET_PROJECT_FLUIDS',
  INITIATE_UPDATE_PROJECT_FLUID: 'INITIATE_UPDATE_PROJECT_FLUID',
  UPDATE_PROJECT_FLUID: 'UPDATE_PROJECT_FLUID',
  INITIATE_CREATE_PROJECT_FLUID: 'INITIATE_CREATE_PROJECT_FLUID',
  CREATE_PROJECT_FLUID: 'CREATE_PROJECT_FLUID',
  INITIATE_DELETE_PROJECT_FLUID: 'INITIATE_DELETE_PROJECT_FLUID',
  DELETE_PROJECT_FLUID: 'DELETE_PROJECT_FLUID',
  ARCHIVE_PROJECT: 'ARCHIVE_PROJECT',
  GANTT_PROJECTS: 'GANTT_PROJECTS',
  RECEIVE_GANTT_PROJECTS: 'RECEIVE_GANTT_PROJECTS',
};

export const CreateProjectFormFields = {
  FIELD_ID: 'fieldId',
  FIELD_NAME: 'fieldName',
  WELLBORE_ID: 'wellboreId',
  WELLBORE_NAME: 'wellboreName',
  FACILITY_ID: 'facilityId',
  FACILITY_NAME: 'facilityName',
  ORGANIZATION_ID: 'organizationId',
  ORGANIZATION_NAME: 'organizationName',
  OBJECTIVE_ID: 'objectiveId',
  OBJECTIVE_NAME: 'objectiveName',
};

export const PROJECT_VIEWS = {
  TABLE_VIEW: 'TABLE_VIEW',
  CHART_VIEW: 'CHART_VIEW',
};
