export function addErrorMessageDuration(action, duration) {
  const notificationObj = {
    notification: {
      config: {
        error: {
          duration: duration,
        },
      },
    },
  };
  return {
    ...action,
    ...notificationObj,
  };
}
