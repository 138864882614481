import { compose } from 'redux';
import { useMemo } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';
import { Link } from 'react-router-dom';
import { IconButton, Typography } from '@material-ui/core';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { formatDate } from 'utils/format.util';
import { Format, EMPTY_LIST } from 'app/app.constants';
import WellboreTableRow from 'features/wells/components/WellboreTableRow';
import { toField } from 'utils/route.util';

const WellboresTable = ({
  classes,
  wellboresByField = EMPTY_LIST,
  ...rest
}) => {
  const columns = useMemo(
    () => [
      {
        id: 'placeholder',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: <TableRowActionsCell minItems={1} />,
      },
      {
        xs: 6,
        id: 'name',
        Header: 'Name',
        accessor: (wellOrField) => wellOrField.get('name'),
        Cell: ({ row, value }) => {
          const isField = row.original.get('isField');

          if (isField) {
            return (
              <Link
                to={toField(
                  row.original?.get('fieldId'),
                  row.original?.get('id'),
                )}
                className={classes.wellHeader}
              >
                <Typography variant="h6">{value}</Typography>
              </Link>
            );
          }

          return value;
        },
      },
      {
        xs: 6,
        Header: 'Survey',
        id: 'trajectoryUpdated',
        accessor: (wellOrField) => wellOrField.get('trajectoryUpdated'),
        Cell: ({ value }) => {
          if (!value) {
            return null;
          }

          return (
            <Typography noWrap variant="body2">
              <Typography
                variant="inherit"
                component="span"
                className={classes.surveyUploadedDescription}
              >
                Survey uploaded
              </Typography>
              &nbsp;
              <Typography variant="inherit" component="span">
                {formatDate(value, Format.date)}
              </Typography>
            </Typography>
          );
        },
      },
      {
        id: 'expander',
        Cell: ({ row }) => {
          if (!row.canExpand) return null;

          return (
            <IconButton
              {...row.getToggleRowExpandedProps()}
              title="Toggle wells"
            >
              {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          );
        },
      },
    ],
    [classes],
  );

  return (
    <Table
      useExpanded
      usePagination
      subrowOffset={3}
      manualPagination
      columns={columns}
      useGlobalFilter={false}
      items={wellboresByField}
      paginateExpandedRows={false}
      TableRowComponent={WellboreTableRow}
      noItemsMessage="No matching wells..."
      {...rest}
    />
  );
};

const styles = (theme) => ({
  surveyUploadedDescription: {
    color: theme.palette.secondary.mainLight,
  },
  wellHeader: {
    color: theme.palette.common.white,
  },
});

export default compose(withStyles(styles))(WellboresTable);
