import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Grid, Button, IconButton } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { Table, TableRowActionsCell, TableRow } from 'altus-ui-components';

import routePaths from 'app/routePaths';
import { EMPTY_MAP } from 'app/app.constants';
import { toTaskSimulation } from 'utils/route.util';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';

import { fluidTypeToString } from 'utils/fluids/mappers.utils';

const SimulationFluidsTable = ({
  taskId,
  disabled,
  projectId,
  simulationId,
  inPlanning,
  isCreateMode,
  toggleCreateMode,
  deleteSimulationFluid,
  defaultSimulationFluid,
  simulationFluids = EMPTY_MAP,
  TableRowComponent = TableRow,
}) => {
  const lengthUnit = defaultSimulationFluid.getIn(['endDepth', 'unit']);

  const columns = useMemo(
    () => [
      {
        xs: 4,
        id: 'endDepth',
        accessor: (simulationFluid) =>
          simulationFluid.getIn(['endDepth', 'roundedValue']),
        Header: () => (
          <QuantityTableHeaderTitleCell title="End Depth" unit={lengthUnit} />
        ),
      },
      {
        xs: 4,
        Header: 'Name',
        accessor: (simulationFluid) => simulationFluid.get('name'),
      },
      {
        xs: 4,
        Header: 'Type',
        accessor: (simulationFluid) => simulationFluid.get('fluidType'),
        Cell: ({ row }) => fluidTypeToString(row.original.get('fluidType')),
      },
      {
        id: 'actions',
        Header: <TableRowActionsCell minItems={1} />,
        Footer: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (disabled) return null;

          return (
            <TableRowActionsCell minItems={1}>
              <IconButton
                title="Delete"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  deleteSimulationFluid(row.original.get('simulationFluidId'));
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
    ],
    [disabled, lengthUnit, deleteSimulationFluid],
  );

  return (
    <Grid container direction="column">
      <Table
        disableSortBy
        columns={columns}
        useGlobalFilter={false}
        items={simulationFluids}
        TableRowComponent={TableRowComponent}
        noItemsMessage="No fluids added yet"
      />
      {!disabled && (
        <Grid container justifyContent="flex-end">
          <Box p={1}>
            <Button
              size="small"
              component={Link}
              disabled={isCreateMode}
              onClick={toggleCreateMode}
              to={toTaskSimulation(
                projectId,
                taskId,
                simulationId,
                inPlanning
                  ? routePaths.plan.task.simulation.fluids.root
                  : routePaths.execution.tabs.simulation.tabs.fluids.root,
              )}
            >
              <Box component={AddCircleOutlineIcon} marginRight={0.5} />
              Add
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default SimulationFluidsTable;
