import { compose } from 'redux';
import classNames from 'classnames';
import { Grid, Collapse } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { useToggle } from 'altus-hooks';
import { TableRow } from 'altus-ui-components';

import { invokeIfFunction } from 'utils/app.util';

const TableRowExpandable = ({
  classes,
  renderDetails,
  isExpanded: defaultIsExpanded = false,
  hasLightBackground = false,
  ...rest
}) => {
  const [isExpanded, toggleExpanded] = useToggle(defaultIsExpanded);

  return (
    <Grid
      container
      direction="column"
      className={classNames({
        [classes.rootExpanded]: isExpanded,
      })}
    >
      <TableRow
        {...rest}
        onRowClick={toggleExpanded}
        classes={{
          root: classNames({
            [classes.tableRowExpanded]: isExpanded,
          }),
        }}
      />
      <Collapse in={isExpanded} className={classes.collapseRoot}>
        <Grid
          container
          className={
            hasLightBackground
              ? classes.detailsRootLightBackground
              : classes.detailsRoot
          }
        >
          {invokeIfFunction(renderDetails, isExpanded)}
        </Grid>
      </Collapse>
    </Grid>
  );
};

const styles = (theme, paddingTop = 0, paddingBottom = 0) => ({
  tableRowExpanded: {
    marginBottom: 0,
  },
  detailsRoot: {
    paddingTop: theme.spacing(paddingTop),
    paddingBottom: theme.spacing(paddingBottom),
    background: theme.palette.table.row.main,
  },
  detailsRootLightBackground: {
    paddingTop: theme.spacing(paddingTop),
    paddingBottom: theme.spacing(paddingBottom),
    background: theme.palette.table.row.main,
  },
  collapseRoot: {
    width: '100%',
  },
  rootExpanded: {
    marginBottom: 2,
  },
});

export default compose(withStyles(styles))(TableRowExpandable);
