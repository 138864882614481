import Agent from 'infrastructure/agent';

export const GetAssetHistoryByProjectId = (projectId) => {    
  return Agent.get(`/assetHistory/project/${projectId}`);
};

const assetHistoryService = {
  GetAssetHistoryByProjectId,
};

export default assetHistoryService;
