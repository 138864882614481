import Info from '@material-ui/icons/Info';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Pause from '@material-ui/icons/Pause';
import toJSComponent from 'with-immutable-props-to-js';
import withStyles from '@material-ui/styles/withStyles';
import InProgressIcon from 'assets/in_progress_icon.svg';
import TaskCompletedIcon from 'assets/TaskCompletedIcon.svg';
import TaskPlannedIcon from 'assets/task_planned.svg';

import { WORK_ITEM_STATUS } from 'app/app.constants';

const styles = (theme) => {
  return {
    workItemCompleted: {
      color: theme.altus.status.execute,
    },
    workItemFailed: {
      color: theme.palette.error.main,
    },
    circularProgress: {
      display: 'flex',
      color: theme.altus.status.execute,
    },
  };
};

const WorkItemStatusContainer = ({
  status,
  classes,
  isWaiting,
  isPointInTime,
  isMobile = false,
}) => {
  if (!isMobile) {
    switch (status) {
      case WORK_ITEM_STATUS.STARTED:
        return <img src={InProgressIcon} alt="Work Item in Progress" />;
      case WORK_ITEM_STATUS.PAUSED:
        return <Pause color="action" />;
      case WORK_ITEM_STATUS.COMPLETED:
        if (isPointInTime) {
          return <Info color="action" />;
        }
        if (isWaiting) {
          return <Pause color="action" />;
        }
        return <Check className={classes.workItemCompleted} />;
      case WORK_ITEM_STATUS.SKIPPED:
        return <Close color="disabled" />;
      case WORK_ITEM_STATUS.FAILED:
        return <Close className={classes.workItemFailed} />;
      case WORK_ITEM_STATUS.NOT_SET:
      case WORK_ITEM_STATUS.PLANNED:
        return <Check color="disabled" />;
      default:
        return null;
    }
  } else {
    switch (status) {
      case WORK_ITEM_STATUS.STARTED:
        return <img src={InProgressIcon} alt="Work Item in Progress" />;
      case WORK_ITEM_STATUS.PAUSED:
        return <Pause color="action" />;
      case WORK_ITEM_STATUS.COMPLETED:
        if (isPointInTime) {
          return <Info color="action" />;
        }
        if (isWaiting) {
          return <Pause color="action" />;
        }
        return <img src={TaskCompletedIcon} alt="Work Item is Completed" />;
      case WORK_ITEM_STATUS.SKIPPED:
        return <Close color="disabled" />;
      case WORK_ITEM_STATUS.FAILED:
        return <Close className={classes.workItemFailed} />;
      case WORK_ITEM_STATUS.NOT_SET:
      case WORK_ITEM_STATUS.PLANNED:
        return <img src={TaskPlannedIcon} alt="Work Item is Planned" />;
      default:
        return null;
    }
  }
};

export default toJSComponent(withStyles(styles)(WorkItemStatusContainer));
