import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { invokeIfFunction } from 'utils/app.util';
import HasPermission from 'app/components/HasPermission';
import { useProjectPermissions } from 'app/hooks/authorization/useProjectPermissions';

const HasProjectPermission = ({ render, children, permissions }) => {
  const { userPermissions, projectPermissions, currentProjectMember } =
    useProjectPermissions(permissions);

  const renderContent = useCallback(
    (hasPermission) =>
      invokeIfFunction(
        render,
        hasPermission,
        userPermissions,
        projectPermissions,
        currentProjectMember,
      ),
    [render, userPermissions, projectPermissions, currentProjectMember],
  );

  return (
    <HasPermission
      permissions={projectPermissions}
      userPermissions={userPermissions}
      render={render ? renderContent : undefined}
    >
      {children}
    </HasPermission>
  );
};

HasProjectPermission.propTypes = {
  render: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  permissions: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Set),
    PropTypes.arrayOf(PropTypes.number),
  ]),
};

export default HasProjectPermission;
