import classNames from 'classnames';
import { useRef, useEffect, useState } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import {
  InputLabel,
  FormControl,
  FormHelperText,
  TextareaAutosize,
} from '@material-ui/core';

const styles = (theme) => ({
  root: {
    'resize': 'vertical',
    'width': '100%',
    'fontFamily': theme.typography.fontFamily,
    'fontSize': '1rem',
    'marginTop': 22,
    'borderRadius': 3,
    'background': 'transparent',
    'color': theme.palette.text.primary,
    'borderColor': theme.palette.text.secondary,
    '&:active': {
      borderColor: theme.palette.secondary.light,
    },
    '&:focus': {
      borderColor: theme.palette.secondary.light,
    },
  },
  rootNoLabel: {
    marginTop: 0,
  },
  formControlFullWidth: {
    minWidth: '100%',
  },
});

const getHelperText = (value, maxLength, maxLengthWarning) => {
  if (value.length >= maxLengthWarning && value.length < maxLength)
    return `${maxLength - value.length} character(s) left`;
  else if (value.length === maxLength)
    return 'Max number of characters reached';
};

const TextAreaField = ({
  meta,
  label,
  input,
  margin,
  classes,
  required,
  inputRef,
  maxLength,
  fullWidth,
  spellCheck,
  minRows,
  maxRows,
  disabled,
  maxLengthWarning,
  ...rest
}) => {
  const textareaRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const { value, defaultValue, onChange } = input;

  const handleChange = (e) => {
    const currentCursorPosition = textareaRef.current.selectionStart;
    setCursorPosition(currentCursorPosition);
    onChange(e);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.selectionStart = cursorPosition;
      textareaRef.current.selectionEnd = cursorPosition;
    }
  }, [value, cursorPosition]);

  const helperText = getHelperText(
    value || defaultValue || '',
    maxLength,
    maxLengthWarning,
  );

  return (
    <FormControl
      margin={margin}
      required={required}
      fullWidth={fullWidth}
      classes={{
        fullWidth: classes.formControlFullWidth,
      }}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <TextareaAutosize
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        ref={textareaRef}
        onChange={handleChange}
        maxLength={maxLength}
        spellCheck={spellCheck}
        minRows={minRows}
        maxRows={maxRows}
        className={classNames(classes.root, {
          [classes.rootNoLabel]: !label,
        })}
        {...rest}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

TextAreaField.defaultProps = {
  fullWidth: true,
  margin: 'normal',
  spellCheck: false,
  minRows: 5,
  maxRows: 10,
};

export default withStyles(styles)(TextAreaField);
