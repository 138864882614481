import fieldService from 'services/field.service';
import { FIELDS_ACTIONS } from 'features/fields/fields.constants';

export const onLoadFieldWithOrganizations = (fieldId) => (dispatch) => {
  return dispatch(getFieldWithOrganizations(fieldId));
};

export const getFieldWithOrganizations = (fieldId) => (dispatch) => {
  dispatch({
    type: FIELDS_ACTIONS.GET_FIELDS_WITH_ORGANIZATIONS,
    payload: () => fieldService.getField(fieldId),
  });
};

export const onUnloadPage = () => (dispatch) => {
    dispatch({ type: FIELDS_ACTIONS.FIELD_WITH_ORGANIZATIONS_PAGE_UNLOADED });
  };
