import withStyles from '@material-ui/styles/withStyles';
import ArrowBack from '@material-ui/icons/ArrowBackIos';

import history from 'infrastructure/history';

const ApplicationHeaderBackButton = ({ classes }) => (
  <ArrowBack
    onClick={() => history.goBack()}
    className={classes.arrowBackIcon}
  />
);

const styles = (theme) => ({
  arrowBackIcon: {
    marginLeft: theme.spacing(1), // same spacing as the Logo
  },
});

export default withStyles(styles)(ApplicationHeaderBackButton);
