import { compose } from 'redux';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import {
  getProjectPermissionsFromState,
  getProjectRolePermissionsFromState,
} from 'authorization/authorization.selectors';

import {
  projectRoleAccessRightsOnLoad,
  assignPermissionsToProjectRole,
  unassignPermissionsFromProjectRole,
} from 'authorization/authorization.actions';

import PermissionGroup from 'features/settings/accessRoles/PermissionGroup';
import { projectPermissionGroups } from 'authorization/authorization.constants';

class ProjectRoleAccessRights extends PureComponent {
  componentDidMount() {
    const { projectRoleId, dispatchOnLoad } = this.props;

    dispatchOnLoad(projectRoleId);
  }

  addPermissions = (permissionIds) => {
    const { projectRoleId, dispatchAssignProjectPermissionsToProjectRole } =
      this.props;

    dispatchAssignProjectPermissionsToProjectRole(projectRoleId, permissionIds);
  };

  removePermissions = (permissionIds) => {
    const { projectRoleId, dispatchUnassignProjectPermissionsFromProjectRole } =
      this.props;

    dispatchUnassignProjectPermissionsFromProjectRole(
      projectRoleId,
      permissionIds,
    );
  };

  render() {
    const { classes, projectPermissions, projectRolePermissions } = this.props;

    return (
      <BasePage classes={{ children: classes.basePageChildren }}>
        <Grid container item xs={6}>
          <Grid container direction="column">
            {projectPermissionGroups.map((permissionGroup, groupIndex) => {
              const {
                title,
                hidden,
                permissions,
                rootPermissionId,
                permissionGroups = [],
                showSubPermissions = false,
              } = permissionGroup;

              if (hidden) return null;

              const rootPermission = rootPermissionId
                ? projectPermissions.get(rootPermissionId.toString())
                : undefined;

              const groupDescription = rootPermission
                ? rootPermission.get('description')
                : undefined;

              return (
                <PermissionGroup
                  key={groupIndex}
                  groupName={title}
                  permissions={permissions}
                  groupDescription={groupDescription}
                  permissionsById={projectPermissions}
                  onAddPermissions={this.addPermissions}
                  showSubpermissions={showSubPermissions}
                  rolePermissions={projectRolePermissions}
                  onRemovePermissions={this.removePermissions}
                >
                  {permissionGroups.map((subPermissionGroup, subGroupIndex) => {
                    const { title, permissions } = subPermissionGroup;

                    return (
                      <PermissionGroup
                        groupName={title}
                        showSubpermissions
                        key={subGroupIndex}
                        permissions={permissions}
                        permissionsById={projectPermissions}
                        onAddPermissions={this.addPermissions}
                        rolePermissions={projectRolePermissions}
                        onRemovePermissions={this.removePermissions}
                      />
                    );
                  })}
                </PermissionGroup>
              );
            })}
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

const styles = (theme) => ({
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(
    (state, { projectRoleId }) => ({
      projectPermissions: getProjectPermissionsFromState(state),
      projectRolePermissions: getProjectRolePermissionsFromState(
        state,
        projectRoleId,
      ),
    }),
    {
      dispatchOnLoad: projectRoleAccessRightsOnLoad,
      dispatchAssignProjectPermissionsToProjectRole:
        assignPermissionsToProjectRole,
      dispatchUnassignProjectPermissionsFromProjectRole:
        unassignPermissionsFromProjectRole,
    },
  ),
  withStyles(styles),
)(ProjectRoleAccessRights);
