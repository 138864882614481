import React, { useEffect, memo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Grid } from '@material-ui/core';
import { APP_ACTIONS, EUROPE_MAP_CENTER_COORDS } from 'app/app.constants';
import GoogleMap from 'app/components/GoogleMap';
import { BasePage, LoadingOverlay } from 'altus-ui-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { useHeader } from 'app/hooks/useHeader';
import { isDesktop } from 'react-device-detect';
import { useCurrentUser } from 'app/hooks/useCurrentUser';
import { fetchChangeLog } from 'app/app.actions';
import useDataState from 'app/hooks/useDataState';
import { getChangeLog } from 'app/app.selectors';
import PropTypes from 'prop-types';

function ChangeLog({ classes, dispatchFetchChangeLog, markdown }) {
  const currentUser = useCurrentUser();
  useHeader({
    title: isDesktop && currentUser?.get('organizationName'),
    subTitle: 'Changelog',
  });
  useEffect(() => dispatchFetchChangeLog(), [dispatchFetchChangeLog]);
  const loadingState = useDataState([APP_ACTIONS.INITIATE_FETCH_CHANGE_LOG]);

  return (
    <>
      <BasePage
        classes={{
          children: classes.basePageChildren,
        }}
      >
        <GoogleMap center={EUROPE_MAP_CENTER_COORDS} />
        <LoadingOverlay dataState={loadingState} />
        {loadingState.received && (
          <Grid container className={classes.content} justifyContent="flex-end">
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <ReactMarkdown>{markdown}</ReactMarkdown>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        )}
      </BasePage>
    </>
  );
}

ChangeLog.propTypes = {
  dispatchFetchChangeLog: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    basePageChildren: PropTypes.shape({
      padding: PropTypes.number,
    }),
    content: PropTypes.shape({
      top: PropTypes.number,
      bottom: PropTypes.number,
      overflow: PropTypes.string,
      position: PropTypes.string,
    }),
  }),
  markdown: PropTypes.string,
};

const styles = () => ({
  basePageChildren: {
    padding: 0,
  },
  content: {
    top: 0,
    bottom: 0,
    overflow: 'auto',
    position: 'absolute',
  },
});

export default compose(
  connect(
    (state) => ({
      markdown: getChangeLog(state),
    }),
    {
      dispatchFetchChangeLog: fetchChangeLog,
    },
  ),
  memo,
  withStyles(styles),
)(ChangeLog);
