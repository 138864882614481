import { compose } from 'redux';
import { isImmutable } from 'immutable';
import DeleteIcon from '@material-ui/icons/Close';
import { memo, useMemo, useCallback } from 'react';
import { sortableElement } from 'react-sortable-hoc';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, IconButton, Typography } from '@material-ui/core';

import {
  DragHandle,
  SortableTable,
  TableRowActionsCell,
} from 'altus-ui-components';

import { EMPTY_MAP } from 'app/app.constants';
import TableCellDense from 'app/components/Table/TableCellDense';
import { ToolCategoryType } from 'features/projects/tool/tool.constants';
import TableHeaderCellDense from 'app/components/Table/TableHeaderCellDense';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import ToolstringToolsTableRow from 'features/projects/tasks/task/toolstring/components/ToolstringToolsTableRow';

const getSubRows = (row) =>
  isImmutable(row)
    ? row.get('toolAssemblyTools')?.toArray()
    : row.original?.toolAssemblyTools?.toArray();

const SortableFormikRow = sortableElement(EditableTableRowFormik);

const BhaTemplatesCreatorTable = ({
  classes,
  toolstringTools = EMPTY_MAP,
  onDuplicateItem,
  onSortToolstringTool,
  updateItemProperties,
  onDeleteToolstringItem,
}) => {
  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 2,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const actions = [
            {
              visible: !row.depth,
              content: <DragHandle key={0} />,
            },
            {
              visible: row.canExpand,
              content: (
                <IconButton {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              ),
            },
          ];

          return (
            <TableRowActionsCell minItems={1}>
              {actions
                .filter((action) => action.visible)
                .map((action) => action.content)}
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 2,
        id: 'toolItemNo',
        Header: 'Item No.',
        accessor: (tool) => tool.get('m3ItemNumber'),
        Cell: ({ value, row }) => (
          <Typography variant="subtitle2">
            {value
              ? row.original.get('toolGroupCategory') !==
                ToolCategoryType.ThirdParty
                ? `#${value}`
                : value
              : null}
          </Typography>
        ),
      },
      {
        xs: 4,
        id: 'toolName',
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
      {
        xs: 4,
        id: 'actions',
        Header: <TableRowActionsCell minItems={3} />,
        Cell: ({ row }) => {
          const { original: toolstringItem } = row;
          const actions = [
            {
              visible: row.depth === 0,
              content: (
                <IconButton
                  key={1}
                  title="Duplicate item"
                  onClick={() => onDuplicateItem(toolstringItem)}
                >
                  <DuplicateIcon />
                </IconButton>
              ),
            },
            {
              visible: row.depth === 0,
              content: (
                <IconButton
                  key={2}
                  title="Remove tool"
                  onClick={() => onDeleteToolstringItem(toolstringItem)}
                >
                  <DeleteIcon />
                </IconButton>
              ),
            },
          ];

          return (
            <Grid item container className={classes.actions}>
              {actions
                .filter((action) => action.visible)
                .map((action) => action.content)}
            </Grid>
          );
        },
      },
    ],
    [onDuplicateItem, onDeleteToolstringItem, classes],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <SortableFormikRow
        {...props}
        onSubmit={updateItemProperties}
        TableRowComponent={ToolstringToolsTableRow}
      />
    ),
    [updateItemProperties],
  );

  return (
    <Grid
      item
      container
      direction="column"
      xs={12}
      className={classes.scrollable}
    >
      <SortableTable
        useExpanded
        stickyHeader
        useDragHandle
        disableSortBy
        columns={columns}
        useGlobalFilter={false}
        items={toolstringTools}
        getSubRows={getSubRows}
        autoResetExpanded={false}
        onItemSorted={onSortToolstringTool}
        noItemsMessage="No tools in this BHA"
        TableRowComponent={renderTableRowComponent}
        TableCellComponent={TableCellDense}
        TableHeaderCellComponent={TableHeaderCellDense}
      />
    </Grid>
  );
};

const styles = () => ({
  scrollable: {
    overflowY: 'auto',
  },
  actions: {
    direction: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
});

export default compose(memo, withStyles(styles))(BhaTemplatesCreatorTable);
