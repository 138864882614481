import { Field } from 'formik';
import { compose } from 'redux';
import classNames from 'classnames';
import { Switch } from '@material-ui/core';
import { useCallback, useMemo } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import { Table, TableRow, TableRowActionsCell } from 'altus-ui-components';

import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import StatusIndicatorWithText from 'features/projects/dashboard/components/StatusIndicatorWithText';
import { ProjectDashboardFormFields as FormFields } from 'features/projects/dashboard/dashboard.constants';
import ProjectDashboardCurveColorField from 'features/projects/dashboard/components/ProjectDashboardCurveColorField';

export const getInitialSelectedProjectDashboardCurveRowIds = (
  projectDashboardCurves = EMPTY_LIST,
) => {
  if (!projectDashboardCurves.size) return EMPTY_MAP.toJS();

  return projectDashboardCurves
    .map((curve) => !!curve.get('projectDashboardId'))
    .reduce(
      (result, isSelected, index) => result.set(index, isSelected),
      EMPTY_MAP,
    )
    .toJS();
};

const ProjectDashboardCurvesTable = ({
  classes,
  fieldName,
  disabledFields,
  maxSelectedItems,
  ...rest
}) => {
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row, state, toggleRowSelected }) => {
          const { selectedRowIds } = state;

          const selectedRows = Object.values(selectedRowIds).filter(Boolean);

          const isSelected = !!selectedRowIds[row.id];

          const isExceedingMaxSelectedItems =
            maxSelectedItems > 0 && selectedRows.length >= maxSelectedItems;

          if (isExceedingMaxSelectedItems && !isSelected) {
            return <TableRowActionsCell minItems={1} />;
          }

          return (
            <TableRowActionsCell>
              <Switch
                disableRipple
                color="primary"
                checked={isSelected}
                onClick={() => toggleRowSelected(row.id)}
              />
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 4,
        Header: 'Caption',
        accessor: (projectDashboardCurve) =>
          projectDashboardCurve.getIn(['curve', 'caption']),
        Cell: ({ row, state }) => {
          const projectDashboard = row.original;

          const color = projectDashboard.getIn(['curve', 'color']);
          const caption = projectDashboard.getIn(['curve', 'caption']);

          const { selectedRowIds } = state;

          const isSelected = !!selectedRowIds[row.id];
          const isDisabled = disabledFields?.includes(FormFields.COLOR);

          if (isDisabled || !isSelected) {
            return (
              <StatusIndicatorWithText color={color}>
                {caption}
              </StatusIndicatorWithText>
            );
          }

          return (
            <Field
              select
              caption={caption}
              component={ProjectDashboardCurveColorField}
              name={`${fieldName}.${row.id}.${FormFields.COLOR}`}
            />
          );
        },
      },
      {
        xs: 4,
        Header: 'Description',
        accessor: (projectDashboardCurve) =>
          projectDashboardCurve.getIn(['curve', 'description']),
      },
      {
        xs: 2,
        Header: 'Offset',
        accessor: (projectDashboardCurve) =>
          projectDashboardCurve.getIn(['curve', 'offset']),
        Cell: ({ row, value, state }) => {
          const { selectedRowIds } = state;

          const isSelected = !!selectedRowIds[row.id];
          const isDisabled = disabledFields?.includes(FormFields.OFFSET);

          if (isDisabled || !isSelected) return value;

          return (
            <Field
              type="number"
              component={TableRowTextFieldFormik}
              name={`${fieldName}.${row.id}.${FormFields.OFFSET}`}
              validate={(value) => {
                const allowedRange = 100;
                return value > allowedRange || value < allowedRange * -1
                  ? `Offset should be in +${allowedRange} and -${allowedRange} range`
                  : undefined;
              }}
            />
          );
        },
      },
      {
        xs: 2,
        Header: 'Min',
        accessor: (projectDashboardCurve) =>
          projectDashboardCurve.getIn(['curve', 'minValue']),
        Cell: ({ row, value, state }) => {
          const { selectedRowIds } = state;

          const isSelected = !!selectedRowIds[row.id];
          const isDisabled = disabledFields?.includes(FormFields.MIN_VALUE);

          if (isDisabled || !isSelected) return value;

          return (
            <Field
              type="number"
              component={TableRowTextFieldFormik}
              name={`${fieldName}.${row.id}.${FormFields.MIN_VALUE}`}
            />
          );
        },
      },
      {
        xs: 2,
        Header: 'Max',
        accessor: (projectDashboardCurve) =>
          projectDashboardCurve.getIn(['curve', 'maxValue']),
        Cell: ({ row, value, state }) => {
          const { selectedRowIds } = state;

          const isSelected = !!selectedRowIds[row.id];
          const isDisabled = disabledFields?.includes(FormFields.MAX_VALUE);

          if (isDisabled || !isSelected) return value;

          return (
            <Field
              type="number"
              component={TableRowTextFieldFormik}
              name={`${fieldName}.${row.id}.${FormFields.MAX_VALUE}`}
            />
          );
        },
      },
      {
        xs: 2,
        Header: 'Unit',
        accessor: (projectDashboardCurve) =>
          projectDashboardCurve.getIn(['curve', 'unit']),
        Cell: ({ row, value, state }) => {
          const { selectedRowIds } = state;

          const isSelected = !!selectedRowIds[row.id];
          const isDisabled = disabledFields?.includes(FormFields.UNIT);

          if (isDisabled || !isSelected) return value;

          return (
            <Field
              component={TableRowTextFieldFormik}
              name={`${fieldName}.${row.id}.${FormFields.UNIT}`}
              inputProps={{
                maxLength: 50,
              }}
            />
          );
        },
      },
    ],
    [fieldName, disabledFields, maxSelectedItems],
  );

  const renderTableRowComponent = useCallback(
    ({ isSelected, ...rest }) => (
      <TableRow
        classes={{
          root: classNames({
            [classes.tableRowRootDisabled]: !isSelected,
          }),
        }}
        {...rest}
      />
    ),
    [classes],
  );

  return (
    <Table
      stickyHeader
      columns={columns}
      TableRowComponent={renderTableRowComponent}
      {...rest}
    />
  );
};

const styles = (theme) => ({
  tableRowRootDisabled: {
    opacity: theme.palette.action.disabledOpacity,
  },
});

export default compose(withStyles(styles))(ProjectDashboardCurvesTable);
