import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Dialog, DialogContent } from '@material-ui/core';

import ToolPickerModalTable from 'features/projects/tool/components/ToolPickerModalTable';
import ToolPickerModalHeader from 'features/projects/tool/components/ToolPickerModalHeader';
import ToolPickerModalFilters from 'features/projects/tool/components/ToolPickerModalFilters';

const ToolPickerModal = ({
  open,
  tools,
  onExit,
  onEnter,
  classes,
  loading,
  onSearch,
  onAddTool,
  suppliers,
  toolGroups,
  toggleModal,
  activeFilters,
  topConnectors,
  onRefreshTools,
  activeCategory,
  toolCategories,
  bottomConnectors,
  onChangeCategory,
  enabledToolCategoryTypes,
}) => (
  <Dialog
    fullWidth
    open={open}
    maxWidth="xl"
    onClose={toggleModal}
    classes={{ paperScrollPaper: classes.root }}
    TransitionProps={{
      onExit,
      onEnter,
    }}
  >
    <Grid className={classes.header}>
      <ToolPickerModalHeader
        loading={loading}
        onClose={toggleModal}
        onRefreshTools={onRefreshTools}
      />
    </Grid>
    <DialogContent className={classes.content}>
      <Grid item container className={classes.table}>
        <Grid item container className={classes.filters}>
          <ToolPickerModalFilters
            loading={loading}
            onSearch={onSearch}
            toolGroups={toolGroups}
            suppliers={suppliers}
            topConnectors={topConnectors}
            activeFilters={activeFilters}
            toolCategories={toolCategories}
            activeCategory={activeCategory}
            bottomConnectors={bottomConnectors}
            onChangeCategory={onChangeCategory}
            enabledToolCategoryTypes={enabledToolCategoryTypes}
          />
        </Grid>
        <ToolPickerModalTable
          tools={tools}
          loading={loading}
          onAddTool={onAddTool}
          activeCategory={activeCategory}
        />
      </Grid>
    </DialogContent>
  </Dialog>
);

const styles = (theme) => ({
  root: {
    height: '75vh',
  },
  content: {
    padding: `0px ${theme.spacing(3)}px`,
  },
  header: {
    padding: theme.spacing(3),
  },
  filters: {
    paddingBottom: theme.spacing(2),
  },
  table: {
    paddingBottom: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(ToolPickerModal);
