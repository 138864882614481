export const styles = (theme) => ({
  empty: {
    borderBottom: 'none!important',
    '& td': {
      paddingTop: 50,
      textAlign: 'center',
    },
  },
  loadingContainer: {
    height: '100%',
  },
  progress: {
    color: theme.palette.action.active,
  },
  expansionPanelExpanded: {
    justifyContent: 'center',
    display: 'flex',
    marginBottom: 0,
  },
  expansionPanelDetailsRoot: {
    padding: theme.spacing(2),
  },
  activitiesList: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      overflow: 'auto',
    },
  },
  childrenContainer: {
    marginTop: theme.spacing(2),
  },
});
