import { Box, Grid, Typography, Divider } from '@material-ui/core';

import FluidSurfaceProperties from 'app/components/FluidsFormSection/FluidForm/FluidSurfaceProperties';
import FluidFluidIdentification from 'app/components/FluidsFormSection/FluidForm/FluidFluidIdentification';
import { FluidFormFields as FormFields } from 'utils/fluids/constants.utils';

const FluidForm = ({ disabled, values, children, autoFocus }) => {
  const selectedFluidType = values[FormFields.FLUID_TYPE];
  const selectedRheologyModel = values[FormFields.RHEOLOGY_MODEL];
  const selectedMultiphaseType = values[FormFields.MULTIPHASE_TYPE];
  const selectedGasType = values[FormFields.GAS_TYPE];

  return (
    <>
      <Grid container wrap="nowrap">
        <Grid item xs={6} component={Box} paddingRight={2}>
          <FluidFluidIdentification
            disabled={disabled}
            autoFocus={autoFocus}
            fluidType={selectedFluidType}
          />
        </Grid>
        <Divider orientation="vertical" />
        <Grid item xs={6} component={Box} paddingLeft={2}>
          <FluidSurfaceProperties
            disabled={disabled}
            fluidType={selectedFluidType}
            rheologyModel={selectedRheologyModel}
            multiphaseType={selectedMultiphaseType}
            gasType={selectedGasType}
          />
        </Grid>
      </Grid>
      <Grid item container component={Box} paddingTop={3} paddingBottom={3}>
        <Typography variant="caption">
          Note — When a gas is selected as a fluid input, an SG of 1.0
          represents <b>Air</b>, however, when a liquid is selected as a fluid
          input, an SG of 1.0 represents <b>Fresh Water</b>.
        </Typography>
      </Grid>
      <Grid item container>
        {children}
      </Grid>
    </>
  );
};

export default FluidForm;
