import Check from '@material-ui/icons/Check';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import {
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
} from '@material-ui/lab';

import { formatDate } from 'utils/format.util';
import ProjectUser from 'features/projects/components/ProjectUser';

const ProjectWellboreDetailChangelogItem = ({
  classes,
  change,
  projectWellboreDetail,
}) => (
  <TimelineItem>
    <TimelineSeparator>
      <TimelineDot color="primary">
        <Check />
      </TimelineDot>
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent>
      <Grid container className={classes.root}>
        <Grid container item>
          <Typography variant="h6">{`Change ${change}`}</Typography>
        </Grid>
        <Grid container item>
          <Grid item xs={4}>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Approved by
            </Typography>
            <ProjectUser user={projectWellboreDetail.get('approvedBy')} />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" className={classes.subtitle}>
              Date and Time
            </Typography>
            <Typography variant="caption">
              {formatDate(projectWellboreDetail.get('approvalDate'))}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </TimelineContent>
  </TimelineItem>
);

const styles = (theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
  },
  subtitle: {
    paddingBottom: theme.spacing(0.5),
  },
});

export default withStyles(styles)(ProjectWellboreDetailChangelogItem);
