import { createSelector } from 'utils/app.util';
import { getWellboreDetailsFromState } from 'features/wells/wellbore.selectors';

export const getProjectWellboreDetailFromState = createSelector(
  (state, projectWellboreDetailId) =>
    state.getIn([
      'entity',
      'projectWellboreDetail',
      'details',
      projectWellboreDetailId?.toString(),
    ]),
  (projectWellboreDetail) => projectWellboreDetail,
);

export const getProjectWellboreDetailIdFromState = createSelector(
  (state) => state.getIn(['entity', 'projectWellboreDetail', 'details']),
  (details) => {
    const firstDetail = details && details.first();
    return firstDetail ? firstDetail.get('projectWellboreDetailId') : null;
  },
);

export const getActiveProjectWellboreDetailIdFromState = createSelector(
  (state) =>
    state.getIn(['entity', 'projectWellboreDetail', 'activeDetailsId']),
  (activeDetailsId) => activeDetailsId,
);

export const getProjectWellboreDetailsForProjectFromState = createSelector(
  (state) => state.getIn(['entity', 'projectWellboreDetail', 'details']),
  (_, projectId) => projectId,
  (projectWellboreDetails, projectId) =>
    projectWellboreDetails
      .sortBy((projectWellboreDetail) => -projectWellboreDetail.get('created'))
      .groupBy((projectWellboreDetail) =>
        projectWellboreDetail.get('projectId').toString(),
      )
      .get(projectId.toString()),
);

export const getWellboreDetailFromProjectWellboreDetailFromState =
  createSelector(
    getProjectWellboreDetailFromState,
    getWellboreDetailsFromState,
    (projectWellboreDetail, wellboreDetails) => {
      const wellboreDetailId = projectWellboreDetail?.get('wellboreDetailId');

      return wellboreDetailId
        ? wellboreDetails.get(wellboreDetailId.toString())
        : undefined;
    },
  );
