import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { Tooltip, withStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import { toCable } from 'utils/route.util';
import { Grid } from 'app/components/withTooltip';
import { Table, TableRow } from 'altus-ui-components';

import { cableTypeToString } from 'features/projects/tool/tool.mappers';
import { compose } from 'redux';

const CablesTable = ({ cables, classes, ...rest }) => {
  const columns = useMemo(
    () => [
      {
        xs: 1,
        id: 'dataVerified',
        Header: '',
        Cell: ({ row }) => {
          const tool = row.original;
          if (!tool.get('dataVerified')) {
            return (
              <Grid container justifyContent="center" alignItems="center">
                <Tooltip title="Unverified tool">
                  <WarningIcon className={classes.warningIcon} />
                </Tooltip>
              </Grid>
            );
          } else return null;
        },
      },
      {
        xs: 3,
        Header: 'M3 Item no.',
        accessor: (cable) => cable.get('referenceNumber'),
        Cell: ({ row }) => {
          const cable = row.original;

          return (
            <Grid title={<Typography variant="h6">Open tool</Typography>}>
              {cable.get('referenceNumber')}
            </Grid>
          );
        },
      },
      {
        xs: 3,
        Header: 'Name',
        accessor: (cable) => cable.get('name'),
      },
      {
        xs: 3,
        Header: 'Type',
        accessor: (cable) => cableTypeToString(cable.get('type')),
      },
      {
        xs: 3,
        Header: 'Manufacturer',
        accessor: (cable) => cable.get('manufacturer'),
      },
    ],
    [classes.warningIcon],
  );

  const renderTableRowComponent = ({ item, ...rest }) => {
    return (
      <Link
        to={toCable(item.get('id'))}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TableRow {...rest} />
      </Link>
    );
  };

  return (
    <Table
      stickyHeader
      usePagination
      columns={columns}
      items={cables}
      useGlobalFilter={false}
      TableRowComponent={renderTableRowComponent}
      {...rest}
    />
  );
};

const styles = (theme) => ({
  warningIcon: {
    color: theme.palette.warning.main,
  },
});

export default compose(withStyles(styles))(CablesTable);
