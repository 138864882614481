import { Link } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { Tooltip, withStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import { toTool } from 'utils/route.util';
import { Grid } from 'app/components/withTooltip';
import { Table, TableRow } from 'altus-ui-components';
import { ExifOrientation } from 'features/projects/tool/tool.constants';

import ToolImageTableCell from 'app/components/ToolImageTableCell';
import { compose } from 'redux';

const EwlToolsTable = ({ tools, toolCategory, classes, ...rest }) => {
  const columns = useMemo(
    () => [
      {
        xs: 1,
        id: 'dataVerified',
        Header: '',
        Cell: ({ row }) => {
          const tool = row.original;
          if (!tool.get('dataVerified')) {
            return (
              <Grid container justifyContent="center" alignItems="center">
                <Tooltip title="Unverified tool">
                  <WarningIcon className={classes.warningIcon} />
                </Tooltip>
              </Grid>
            );
          } else return null;
        },
      },
      {
        xs: 3,
        Header: 'Item no.',
        Cell: ({ row }) => {
          const tool = row.original;

          return (
            <Grid title={<Typography variant="h6">Open tool</Typography>}>
              {tool.get('m3ItemNumber')}
            </Grid>
          );
        },
      },
      {
        xs: 5,
        id: 'toolImage',
        Header: 'Image',
        Cell: ({ row }) => {
          const { original: tool } = row;

          return (
            <ToolImageTableCell
              onHoverDarken
              title={tool.get('name')}
              toolName={tool.get('name')}
              toolImageUrl={tool.get('imageUrl')}
              orientation={ExifOrientation.DEGREES_90_MIRRORED}
            />
          );
        },
      },
      {
        xs: 4,
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
    ],
    [classes.warningIcon],
  );

  const renderTableRowComponent = useCallback(
    ({ item, ...rest }) => (
      <Link
        to={toTool(item.get('id'), toolCategory)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TableRow {...rest} />
      </Link>
    ),
    [toolCategory],
  );

  return (
    <Table
      stickyHeader
      usePagination
      columns={columns}
      items={tools}
      useGlobalFilter={false}
      TableRowComponent={renderTableRowComponent}
      {...rest}
    />
  );
};

const styles = (theme) => ({
  warningIcon: {
    color: theme.palette.warning.main,
  },
});

export default compose(withStyles(styles))(EwlToolsTable);
