import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import EditActivityModalContainer from 'features/projects/activities/components/editActivity/EditActivityModalContainer';
import { updateActivityFormOnSubmitWithNotification } from 'features/projects/activities/activities.actions';
import { useRealTimeNotifications } from 'features/projects/activities/hooks/useRealTimeNotifications';

export const EditActivityModalContainerWithNotification = ({
  connection,
  taskId,
  projectId,
  ...props
}) => {
  const dispatch = useDispatch();
  const [, , , deleteActivity] = useRealTimeNotifications(
    projectId,
    taskId,
    connection,
  );

  const handleSubmitWithNotification = useCallback(
    (...args) =>
      dispatch(updateActivityFormOnSubmitWithNotification(connection)(...args)),
    [dispatch, connection],
  );

  const newProps = {
    handleSubmit: handleSubmitWithNotification,
    taskId: taskId,
    projectId: projectId,
    dispatchDeleteActivity: deleteActivity,
    ...props,
  };

  return <EditActivityModalContainer {...newProps} />;
};
