import { Grid } from '@material-ui/core';
import ReadOnlyField from 'app/components/ReadOnlyField';
import { toolAssetHistoryEventToString } from 'features/equipment/equipment.helpers';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const MaintenanceDetailComponent = ({
  assetHistory,
  classes,
  projectTitle,
}) => (
  <>
    <Grid item xs={6}>
      <CustomReadOnlyField margin="none" label="Event">
        {toolAssetHistoryEventToString(assetHistory.get('event'))}
      </CustomReadOnlyField>
      <Grid className={classes.marginLabel}></Grid>
    </Grid>
    <Grid item xs={6}>
      <CustomReadOnlyField margin="none" label="Project">
        {projectTitle}
      </CustomReadOnlyField>
      <Grid className={classes.marginLabel}></Grid>
    </Grid>
  </>
);

export default MaintenanceDetailComponent;
