import { useCallback } from 'react';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';

const DocumentFilterItem = ({ filter, category, disabled, setFilter }) => {
  const toggleCategoryFilter = useCallback(
    () =>
      setFilter((currentFilter) =>
        currentFilter.includes(category)
          ? currentFilter.remove(category)
          : currentFilter.add(category),
      ),
    [category, setFilter],
  );

  return (
    <Grid key={category.get('id')} item xs={12}>
      <FormControlLabel
        disabled={disabled}
        label={category.get('name')}
        control={
          <Checkbox
            color="default"
            value={category.name}
            onClick={toggleCategoryFilter}
            checked={filter.includes(category)}
          />
        }
      />
    </Grid>
  );
};

export default DocumentFilterItem;
