import { useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field, Formik } from 'formik';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TableRowAutocompleteTextFieldFormik from 'app/components/form/formik/TableRowAutocompleteTextFieldFormik';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { TASK_URLS } from 'features/projects/activities/activities.constants';

const MobileToolstringDrawerContent = ({
  classes,
  tool,
  onSubmit,
  timeout = 1500,
  isStringVerified,
}) => {
  const initialValues = useMemo(() => tool.toJS(), [tool]);
  return (
    <Grid className={classes.drawerContent} spacing={2}>
      <Grid container className={classes.drawerTitle}>
        <Grid item xs={12}>
          <Typography variant="h6">{tool.get('name')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography noWrap variant="body2" color="textSecondary">
            {'Item No.: '} <b>{`#${tool.get('m3ItemNumber')}`}</b>
            {' • Serial No.: '} <b>{`${initialValues['serialNo'] || ''}`}</b>
          </Typography>
        </Grid>
      </Grid>
      <Box className={classes.container}>
        <Box className={classes.drawerToolDetails}>
          <Typography noWrap variant="body2">
            {'Acc Length:'}{' '}
            <b>{`${tool.getIn(['accumulatedLength', 'roundedValue']) || ''} ${
              tool.getIn(['accumulatedLength', 'unit']) || ''
            }`}</b>
          </Typography>
          <Typography noWrap variant="body2">
            {'Length: '}{' '}
            <b>{`${tool.getIn(['length', 'roundedValue'])} ${tool.getIn([
              'length',
              'unit',
            ])}`}</b>
          </Typography>
          <Typography noWrap variant="body2">
            {'Weight: '}{' '}
            <b>{`${tool.getIn(['weight', 'roundedValue'])} ${tool.getIn([
              'weight',
              'unit',
            ])}`}</b>
          </Typography>
          <Typography noWrap variant="body2">
            {'Outer Diameter: '}{' '}
            <b>{`${tool.getIn(['outerDiameter', 'roundedValue'])} ${tool.getIn([
              'outerDiameter',
              'unit',
            ])}`}</b>
          </Typography>
        </Box>
      </Box>
      {!isStringVerified && (
        <Grid container className={classes.drawerToolDetails}>
          <Grid item xs={12}>
            <Typography noWrap variant="body1">
              {'Register Serial Number'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Formik
              enableReinitialize
              onSubmit={onSubmit}
              initialValues={initialValues}
            >
              <AutoSaveFormik timeout={timeout}>
                <Field
                  type="number"
                  name="serialNo"
                  placeholder={''}
                  component={TableRowAutocompleteTextFieldFormik}
                  keepChangeAfterOnBlur={true}
                />
              </AutoSaveFormik>
            </Formik>
          </Grid>
          <Grid item xs={12} className={classes.addComplaint}>
            <a
              className={classes.addComplaintUrl}
              href={TASK_URLS.ADD_COMPLAINT_URL}
            >
              <OpenInNewIcon style={{ paddingRight: '5px' }} />
              <Typography variant="body2">Add Complaint</Typography>
            </a>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const styles = (theme) => ({
  drawerContent: {
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(3),
  },
  drawerTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  drawerToolDetails: {
    paddingTop: theme.spacing(2),
  },
  addComplaint: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  addComplaintUrl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.light,
    textDecoration: 'underline',
  },
});

export default withStyles(styles)(MobileToolstringDrawerContent);
