import { Map, List } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { LoadingDataState, NotRequestedDataState } from 'altus-datastate';

import {
  STATE,
  ACTIVITIES_ACTIONS,
} from 'features/projects/activities/activities.constants';

import { APP_ACTIONS } from 'app/app.constants';
import tasksMappers from 'features/projects/tasks/tasks.mappers';
import activitiesMappers from 'features/projects/activities/activities.mappers';

const dataInitialState = Map({
  [STATE.DATA_TASKS]: List(),
  [STATE.TIMERS]: List(),
  [STATE.DATA_ACTIVITY_TYPES]: List(),
  [STATE.DATA_ACTIVITY_TYPES_WAITING]: List(),
  [STATE.DATA_ACTIVITY_TYPES_POINT_IN_TIME]: List(),
  autosave: NotRequestedDataState,
});

const dataReducer = (state = dataInitialState, action) => {
  switch (action.type) {
    case ACTIVITIES_ACTIONS.GET_ALL_TASKS: {
      if (action.error) return state;

      return state.set(
        STATE.DATA_TASKS,
        List(action.payload).map(tasksMappers.Task.from),
      );
    }
    case ACTIVITIES_ACTIONS.GET_ALL_ACTIVITY_TYPES: {
      if (action.error) return state;

      return state.set(
        STATE.DATA_ACTIVITY_TYPES,
        List(action.payload).map(activitiesMappers.ActivityType.from),
      );
    }
    case ACTIVITIES_ACTIONS.ACTIVITIES_UNINITIALIZED: {
      return dataInitialState
        .set(STATE.DATA_ACTIVITY_TYPES, state.get(STATE.DATA_ACTIVITY_TYPES))
        .set(
          STATE.DATA_ACTIVITY_TYPES_WAITING,
          state.get(STATE.DATA_ACTIVITY_TYPES_WAITING),
        )
        .set(
          STATE.DATA_ACTIVITY_TYPES_POINT_IN_TIME,
          state.get(STATE.DATA_ACTIVITY_TYPES_POINT_IN_TIME),
        );
    }

    case ACTIVITIES_ACTIONS.START_OPERATION:
    case ACTIVITIES_ACTIONS.COMPLETE_TASK:
    case ACTIVITIES_ACTIONS.ACTIVITY_GO_BACK: {
      if (action.error) return state;
      const { currentTask, previousTask } = action.payload;

      return state
        .update(STATE.DATA_TASKS, (tasks) => {
          if (!previousTask) return tasks;

          const index = tasks.findIndex(
            (task) => task.get('id') === previousTask.taskId,
          );
          return tasks.set(index, tasksMappers.Task.from(previousTask));
        })
        .update(STATE.DATA_TASKS, (tasks) => {
          if (!currentTask) return tasks;

          const index = tasks.findIndex(
            (task) => task.get('id') === currentTask.taskId,
          );
          return tasks.set(index, tasksMappers.Task.from(currentTask));
        });
    }

    case ACTIVITIES_ACTIONS.GET_WAITING_ACTIVITY_TYPES: {
      if (action.error) return state;

      return state.set(
        STATE.DATA_ACTIVITY_TYPES_WAITING,
        List(action.payload).map(activitiesMappers.ActivityType.from),
      );
    }

    case ACTIVITIES_ACTIONS.GET_POINT_IN_TIME_ACTIVITY_TYPES: {
      if (action.error) return state;

      return state.set(
        STATE.DATA_ACTIVITY_TYPES_POINT_IN_TIME,
        List(action.payload).map(activitiesMappers.ActivityType.from),
      );
    }

    case ACTIVITIES_ACTIONS.GET_PROJECT_TIMERS: {
      if (action.error) return state;

      return state.set(
        STATE.TIMERS,
        activitiesMappers.Timers.from(action.payload),
      );
    }

    case APP_ACTIONS.ASYNC_START: {
      const { subtype } = action;

      switch (subtype) {
        case ACTIVITIES_ACTIONS.UPDATE_ACTIVITY: {
          const { autosave } = action;

          return state.set(
            'autosave',
            autosave ? LoadingDataState : NotRequestedDataState,
          );
        }
        default:
          return state;
      }
    }

    case APP_ACTIONS.ASYNC_END: {
      const { subtype } = action;

      switch (subtype) {
        case ACTIVITIES_ACTIONS.UPDATE_ACTIVITY: {
          return state.set('autosave', NotRequestedDataState);
        }

        default:
          return state;
      }
    }

    default:
      return state;
  }
};

export default combineReducers({
  data: dataReducer,
});
