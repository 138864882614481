import PropTypes from 'prop-types';
import { useState, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { ExifOrientation } from 'features/projects/tool/tool.constants';
import Tool3DObject from 'features/projects/tool/components/Tool3DObject';
import Environment from 'app/components/WellboreTrajectoryDetailed3DView/Environment';
import Tool3DObjectModalView from 'features/projects/tool/components/Tool3DObjectModalView';

const Tool3DObjectContainer = ({
  title,
  tool3DObjectUrl,
  toolDiameter,
  toolLength,
}) => {
  const [viewTool3DModel, toggleViewTool3DModel] = useState(false);
  const cameraPosition =
    toolDiameter > toolLength ? 10 * toolDiameter : 2 * toolLength;

  const handleToggleViewTool3DModel = () => {
    toggleViewTool3DModel(!viewTool3DModel);
  };

  if (toolLength && toolDiameter)
    return (
      <>
        <Canvas camera={{ position: [0, 0, cameraPosition] }}>
          <Suspense fallback={null}>
            <Environment />
            <Tool3DObject
              tool3DObjectUrl={tool3DObjectUrl}
              toolDiameter={toolDiameter}
              toolLength={toolLength}
              handleToggleViewTool3DModel={handleToggleViewTool3DModel}
              cameraPosition={cameraPosition}
            />
          </Suspense>
        </Canvas>
        <Tool3DObjectModalView
          title={title}
          openModal={viewTool3DModel}
          toggleModal={handleToggleViewTool3DModel}
          tool3DObjectUrl={tool3DObjectUrl}
          toolDiameter={toolDiameter}
          toolLength={toolLength}
        />
      </>
    );

  return null;
};

Tool3DObjectContainer.propTypes = {
  title: PropTypes.node,
  toolName: PropTypes.string,
  highlighted: PropTypes.bool,
  onHoverScale: PropTypes.bool,
  onHoverDarken: PropTypes.bool,
  tool3DObjectUrl: PropTypes.string,
  orientation: PropTypes.oneOf(Object.values(ExifOrientation)),
};

export default Tool3DObjectContainer;
