import { Grid, Button, Typography } from '@material-ui/core';

const CablePickerModalHeader = ({ onClose }) => (
  <Grid container alignItems="center">
    <Grid item xs>
      <Typography variant="h6">Select Cable</Typography>
    </Grid>
    <Grid item>
      <Button color="secondary" variant="contained" onClick={onClose}>
        Close
      </Button>
    </Grid>
  </Grid>
);

export default CablePickerModalHeader;
