import { compose } from 'redux';
import { EMPTY_MAP } from 'app/app.constants';
import React, { memo, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import useWellbore from 'features/wells/hooks/useWellbore';

import ThreeJsManagerProvider from 'app/threeJs/ThreeJsManagerProvider';
import useWellboreTrajectory from 'features/wells/hooks/useWellboreTrajectory';
import { requestWellboreSectionsForWellbore } from 'features/wells/sections/wellboreSection.actions';
import { createWellboreSectionsForWellboreSelector } from 'features/wells/sections/wellboreSection.selectors';
import WellboreContextTrajectoryContainer from 'app/components/WellboreContextualization/WellboreContextTrajectoryContainer';

const WellboreContextTrajectory3DView = ({ fieldId, wellboreId }) => {
  const dispatch = useDispatch();

  const trajectory = useWellboreTrajectory(fieldId, wellboreId);

  useEffect(() => {
    dispatch(requestWellboreSectionsForWellbore(fieldId, wellboreId));
  }, [fieldId, wellboreId, dispatch]);

  const { wellbore, wellboreDetail = EMPTY_MAP } = useWellbore(wellboreId);

  const wellboreSections = useSelector(
    createWellboreSectionsForWellboreSelector(wellboreId),
  );

  return (
    <ThreeJsManagerProvider>
      <Grid item xs container>
        <WellboreContextTrajectoryContainer
          trajectory={trajectory}
          wellboreId={wellboreId}
          wellbore={wellbore}
          wellboreDetail={wellboreDetail}
          wellboreSections={wellboreSections}
          fieldId={fieldId}
        >
          <Grid container alignContent="center">
            <Grid item xs={12}>
              <Typography align="center" variant="body2">
                Well Trajectory Not Available
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" align="center">
                Click the plus button below or drag and drop a file into this
                window to upload a trajectory.
              </Typography>
            </Grid>
          </Grid>
        </WellboreContextTrajectoryContainer>
      </Grid>
    </ThreeJsManagerProvider>
  );
};

export default compose(memo)(WellboreContextTrajectory3DView);
