import { compose } from 'redux';
import { Formik, Form } from 'formik';
import { memo, useMemo } from 'react';

import { EMPTY_MAP } from 'app/app.constants';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import HostileFluidsFormSection from 'app/components/WellboreDetailsForm/HostileFluidsFormSection';
import WellPropertiesFormSection from 'app/components/WellboreDetailsForm/WellPropertiesFormSection';
import WellInformationFormSection from 'app/components/WellboreDetailsForm/WellInformationFormSection';

import {
  FluidsFormSectionProject,
  FluidsFormSectionWellbore,
} from 'app/components/FluidsFormSection';

const WellboreDetailsFormBase = ({
  onSubmit,
  disabled,
  wellboreDetail = EMPTY_MAP,
}) => {
  const initialValues = useMemo(() => wellboreDetail.toJS(), [wellboreDetail]);
  return (
    <>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        <AutoSaveFormik>
          <Form>
            <WellInformationFormSection disabled={disabled} />
            <WellPropertiesFormSection disabled={disabled} />
            <HostileFluidsFormSection
              disabled={disabled}
              wellboreDetail={wellboreDetail}
            />
          </Form>
        </AutoSaveFormik>
      </Formik>
    </>
  );
};

const _ProjectWellboreDetailsAndFluids = ({
  disabled,
  projectWellboreDetailId,
  isArchive,
  projectId,
  ...rest
}) => {
  return (
    <>
      <WellboreDetailsFormBase
        disabled={disabled}
        projectWellboreDetailId={projectWellboreDetailId}
        {...rest}
      />
      <FluidsFormSectionProject
        disabled={disabled}
        projectId={projectId}
        parentFluidObjectId={projectWellboreDetailId}
        isArchive={isArchive}
      />
    </>
  );
};

const _WellboreDetailsAndFluids = ({ wellboreId, isArchive, ...rest }) => {
  const disabled = false;
  return (
    <>
      <WellboreDetailsFormBase disabled={disabled} {...rest} />
      <FluidsFormSectionWellbore
        disabled={disabled}
        parentFluidObjectId={wellboreId}
      />
    </>
  );
};

export const ProjectWellboreDetailsAndFluids = compose(memo)(
  _ProjectWellboreDetailsAndFluids,
);
export const WellboreDetailsAndFluids = compose(memo)(
  _WellboreDetailsAndFluids,
);
