import * as yup from 'yup';
import { useMemo } from 'react';
import { compose } from 'redux';
import { Formik, Form, Field } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Fab, Typography } from '@material-ui/core';

import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import TextAreaFieldFormik from 'app/components/form/formik/TextAreaFieldFormik';

export const FormFields = {
  name: 'name',
  description: 'description',
};

const AddEquipmentTemplatesInformationContainer = ({
  classes,
  equipmentTemplateInfo,
  setEquipmentTemplateInfo,
  toggleAddEquipmentToEquipmentTemplateModal,
}) => {
  const initialValues = useMemo(
    () => ({
      [FormFields.name]: equipmentTemplateInfo
        ? equipmentTemplateInfo.name
        : '',
      [FormFields.description]: equipmentTemplateInfo
        ? equipmentTemplateInfo.description
        : '',
    }),
    [equipmentTemplateInfo],
  );

  const schema = yup.object().shape({
    name: yup.string().required('Required'),
  });

  const onSubmitForm = (formValue) => {
    setEquipmentTemplateInfo(formValue);
  };

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      className={classes.wrapper}
      spacing={4}
    >
      <Grid
        xs
        item
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h6">Add Equipment</Typography>
        </Grid>
        <Grid item>
          <Fab
            size="medium"
            color="primary"
            title="Add assembly"
            onClick={toggleAddEquipmentToEquipmentTemplateModal}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmitForm}
      >
        <Form>
          <AutoSaveFormik>
            <Grid
              item
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              className={classes.container}
            >
              <Field
                fullWidth
                label="Equipment template name"
                name={FormFields.name}
                component={TextFieldFormik}
              />
              <Field
                fullWidth
                label="Equipment template description"
                name={FormFields.description}
                component={TextAreaFieldFormik}
              />
            </Grid>
          </AutoSaveFormik>
        </Form>
      </Formik>
    </Grid>
  );
};

const styles = (theme) => ({
  container: {
    height: 'fit-content',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  wrapper: {
    height: 'fit-content',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(
  AddEquipmentTemplatesInformationContainer,
);
