import { compose } from 'redux';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { downloadToolstringPdfReport } from 'features/projects/tasks/task/toolstring/toolstring.actions';
import TaskToolstringExportModal from 'features/projects/tasks/task/toolstring/TaskToolstringExportModal';

const TaskToolstringExportModalContainer = ({
  open,
  taskId,
  projectId,
  toggleModal,
  toolstringId,
  dispatchDownloadToolstringPdfReport,
}) => {
  const onExportToolstring = useCallback(
    (exportSelection) => {
      dispatchDownloadToolstringPdfReport(
        projectId,
        taskId,
        toolstringId,
        exportSelection.collapseAssemblies,
      );
      toggleModal();
    },
    [
      taskId,
      projectId,
      toggleModal,
      toolstringId,
      dispatchDownloadToolstringPdfReport,
    ],
  );

  return (
    <TaskToolstringExportModal
      open={open}
      onClose={toggleModal}
      onExport={onExportToolstring}
    />
  );
};

export default compose(
  connect(null, {
    dispatchDownloadToolstringPdfReport: downloadToolstringPdfReport,
  }),
)(TaskToolstringExportModalContainer);
