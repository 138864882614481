import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Field, Fields, formValueSelector } from 'redux-form/immutable';

import {
  EDIT_ACTIVITY_FORM,
  ACTIVITIES_ACTIONS,
} from 'features/projects/activities/activities.constants';

import { WORK_ITEM_STATUS } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import AdjustTimeField from 'app/components/form/AdjustTimeField';
import AdjustDurationField from 'app/components/form/AdjustDurationField';
import ReadOnlyFieldReduxForm from 'app/components/form/redux-form/ReadOnlyFieldReduxForm';
import WellControlDrillActivityContainer from 'features/wellControlDrillActivity/WellControlDrillActivityContainer';
import TimeSpanField from 'app/components/form/TimeSpanField';
import EditorReduxForm from 'app/components/form/EditorField';
import QuantityTextFieldReduxForm from 'app/components/form/redux-form/QuantityTextFieldReduxForm';

const formSelector = formValueSelector(EDIT_ACTIVITY_FORM.ID);

const EditActivityForm = ({
  index,
  handleSubmit,
  disabled,
  displayComments,
  activityTypeId,
  activityForm,
}) => {
  if (activityTypeId === EDIT_ACTIVITY_FORM.WELL_CONTROL_DRILL_TYPE_ID) {
    return <WellControlDrillActivityContainer activityForm={activityForm} />;
  }
  return (
    <form id={`edit-activity-form-${index}`} onSubmit={handleSubmit}>
      <FormContent displayComments={displayComments} disabled={disabled} />
    </form>
  );
};

const ExecutionFields = ({
  isPointInTime,
  disabled,
  status,
  displayComments,
}) => (
  <>
    {isPointInTime && (
      <Grid item container xs={12} spacing={2}>
        <Fields
          component={AdjustTimeField}
          DateTimePickerProps={{
            margin: 'normal',
            label: 'Start time',
            disableFuture: true,
          }}
          names={[EDIT_ACTIVITY_FORM.START_TIME, EDIT_ACTIVITY_FORM.END_TIME]}
        />
      </Grid>
    )}
    {!isPointInTime && (
      <Grid item container xs={12} spacing={2}>
        <Fields
          disabled={status !== WORK_ITEM_STATUS.COMPLETED}
          durationProps={{
            disabled: status < WORK_ITEM_STATUS.COMPLETED,
          }}
          startTimeProps={{
            disabled: status < WORK_ITEM_STATUS.STARTED,
          }}
          endTimeProps={{
            disabled: status < WORK_ITEM_STATUS.COMPLETED,
          }}
          component={AdjustDurationField}
          names={[
            EDIT_ACTIVITY_FORM.START_TIME,
            EDIT_ACTIVITY_FORM.EDITED_DURATION,
            EDIT_ACTIVITY_FORM.END_TIME,
          ]}
        />
      </Grid>
    )}
    {displayComments && (
      <Grid item container xs={12} spacing={2}>
        <Field
          label="Internal Comments"
          maxLength={1000}
          disabled={disabled}
          component={EditorReduxForm}
          name={EDIT_ACTIVITY_FORM.COMMENTS}
          xs={12}
        />
      </Grid>
    )}
  </>
);

const FormContent = ({ displayComments, disabled }) => {
  const dataState = useSelector((state) =>
    getSummarizedDataStateFromState(
      state,
      ACTIVITIES_ACTIONS.UPDATE_ACTIVITY,
      ACTIVITIES_ACTIONS.CONFIRM_DELETE_ACTIVITY,
    ),
  );

  const status = useSelector((state) =>
    formSelector(state, EDIT_ACTIVITY_FORM.STATUS),
  );

  const isPointInTime = useSelector((state) =>
    formSelector(state, EDIT_ACTIVITY_FORM.IS_POINT_IN_TIME),
  );

  const disableEditing = dataState.isLoading() || disabled;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Field
              label="Code"
              component={ReadOnlyFieldReduxForm}
              name={EDIT_ACTIVITY_FORM.CODE}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Activity"
              component={ReadOnlyFieldReduxForm}
              name={EDIT_ACTIVITY_FORM.TYPE_NAME}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              label="Description"
              maxLength={2000}
              disabled={disableEditing}
              xs={12}
              component={EditorReduxForm}
              name={EDIT_ACTIVITY_FORM.DESCRIPTION}
            />
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item container xs={5}>
              <Field
                label="Depth"
                disabled={disableEditing}
                name={EDIT_ACTIVITY_FORM.DEPTH}
                component={QuantityTextFieldReduxForm}
              />
            </Grid>
            <Grid item xs={5}>
              <Field
                label="Estimated duration"
                disabled={disableEditing}
                name={EDIT_ACTIVITY_FORM.ESTIMATED_TIME}
                component={TimeSpanField}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item container xs={12}>
              <Field
                label="Parallel Activities / Information"
                disabled={disableEditing}
                name={EDIT_ACTIVITY_FORM.PARALLEL_ACTIVITY}
                component={EditorReduxForm}
                xs={12}
              />
            </Grid>
          </Grid>
          {status > WORK_ITEM_STATUS.PLANNED && (
            <ExecutionFields
              disabled={disableEditing}
              status={status}
              isPointInTime={isPointInTime}
              displayComments={displayComments}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditActivityForm;
