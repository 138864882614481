import { Field, useFormikContext } from 'formik';
import { MenuItem } from '@material-ui/core';

import { EMPTY_STRING } from 'app/app.constants';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { ProjectDashboardFormFields as FormFields } from 'features/projects/dashboard/dashboard.constants';

const ProjectDashboardSimulationSelectField = ({ simulations }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Field
      select
      margin="none"
      label="Select simulation"
      name={FormFields.SIMULATION_ID}
      onChange={(event) =>
        setFieldValue(FormFields.SIMULATION_ID, event.target.value)
      }
      component={TextFieldFormik}
      value={values?.[FormFields.SIMULATION_ID] ?? EMPTY_STRING}
    >
      <MenuItem key="empty" value={undefined}>
        No simulation
      </MenuItem>
      {simulations.map((simulation) => (
        <MenuItem
          key={simulation.get('simulationId')}
          value={simulation.get('simulationId')}
        >
          {simulation.get('name')}
        </MenuItem>
      ))}
    </Field>
  );
};

export default ProjectDashboardSimulationSelectField;
