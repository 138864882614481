import { OrderedMap } from 'immutable';

import mappers from 'mappers';
import { EMPTY_MAP } from 'app/app.constants';
import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';

const initialState = EMPTY_MAP;

const projectGanttReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case PROJECTS_ACTIONS.RECEIVE_GANTT_PROJECTS: {
      if (error) return state;
      const gantt = OrderedMap(
        payload.map((project) => [
          project.projectId.toString(),
          mappers.Project.from(project),
        ]),
      );

      state.setIn(['project', 'ganttProjects'], gantt);
      return gantt;
    }

    default:
      return state;
  }
};

export default projectGanttReducer;
