import { Grid, CircularProgress } from '@material-ui/core';
import { Html } from '@react-three/drei';
import React from 'react';

const Loader = () => {
  return (
    <Html center>
      <Grid
        xs
        item
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    </Html>
  );
};

export default Loader;
