import withStyles from '@material-ui/styles/withStyles';
import { styles } from 'features/projects/tasks/task/activities/ActivityListItem/helpers/styles';

export const addStyles = (Component) => withStyles(styles)(Component);

export const isDepthChanged = (newDepth, oldDepth) => {
    const {roundedValue, ...oldDepthWithoutRoundedValue} = oldDepth;
    const isSame = (newDepth.hasValue && oldDepthWithoutRoundedValue.hasValue) ?
        newDepth.unit === oldDepthWithoutRoundedValue.unit && newDepth.value === oldDepthWithoutRoundedValue.value :
        (!newDepth.hasValue && !oldDepthWithoutRoundedValue.hasValue);
    return !isSame;
}
