import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import Grid from 'app/components/WellboreContextualization/Grid';
import Environment from 'app/components/WellboreTrajectoryDetailed3DView/Environment';
import { useViewMode } from 'app/hooks/useViewMode';

const ThreeFiberSceneWrapper = ({
  maxNE,
  maxTVD,
  children = null,
  zoom = 2,
  domElement,
}) => {
  const viewMode = useViewMode();
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: viewMode === 'mobile' ? '70%' : '100%',
      }}
    >
      <Canvas
        camera={{
          near: 0.1,
          far: (maxNE + maxTVD) * 4,
          position: [0, 2 * maxTVD, 2 * maxNE * 1.33],
          fov: 75,
        }}
      >
        <Suspense fallback={null}>
          <Environment />
          <OrbitControls
            makeDefault
            domElement={domElement}
            enablePan={true}
            enableZoom={true}
            enableRotate={true}
            screenSpacePanning={true}
            maxDistance={
              maxNE < 100 ? (zoom + 2) * maxNE * 100 : (zoom + 2) * maxNE
            }
            enableDamping={true}
            keyPanSpeed={0.01}
            dampingFactor={1.0}
          />
          <Grid maxTVD={maxTVD} max={maxNE < 100 ? maxNE * 100 : maxNE} />
          {children}
        </Suspense>
      </Canvas>
    </div>
  );
};

export default ThreeFiberSceneWrapper;
