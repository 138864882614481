import { EMPTY_LIST } from 'app/app.constants';
import { createSelector } from 'utils/app.util';

const getSimulationParametersSelector = (state) =>
  state.getIn(['entity', 'simulationParameter']);

const groupBySimulationId = (simulationParameter) =>
  simulationParameter.get('simulationId').toString();

const groupBySimulationDirection = (simulationParameter) =>
  simulationParameter.get('direction');

export const createSimulationParametersSelector = (simulationId, direction) =>
  createSelector(getSimulationParametersSelector, (simulationParameters) =>
    simulationParameters
      .groupBy(groupBySimulationId)
      .get(simulationId.toString(), EMPTY_LIST)
      .groupBy(groupBySimulationDirection)
      .get(direction),
  );
