import {
  WELLS_ACTIONS,
  WELLS_ACTIONS as ACTIONS,
} from 'features/wells/wells.constants';
import { requestDeleteFluidGeneric } from 'utils/fluids/actions.base';

export const requestGetWellboreFluids = (wellboreId) => ({
  wellboreId,
  type: ACTIONS.INITIATE_GET_WELLBORE_FLUIDS,
});

export const getWellboreFluids = (fluids) => ({
  type: ACTIONS.GET_WELLBORE_FLUIDS,
  payload: fluids,
});

export const requestUpdateWellboreFluid = (
  wellboreId,
  fluidObj,
  setSubmitting,
  setStatus,
) => ({
  wellboreId,
  fluidObj,
  setSubmitting,
  setStatus,
  type: ACTIONS.INITIATE_UPDATE_WELLBORE_FLUID,
  notification: {
    info: 'Updating fluid...',
    success: 'Fluid successfully updated',
  },
});

export const updateFluid = (fluidId, wellboreId) => ({
  type: ACTIONS.UPDATE_WELLBORE_FLUID,
  payload: {
    fluidId,
    wellboreId,
  },
});

export const requestCreateWellboreFluid = (
  wellboreId,
  fluidObj,
  setSubmitting,
  setStatus,
) => ({
  type: ACTIONS.INITIATE_CREATE_WELLBORE_FLUID,
  wellboreId,
  fluidObj,
  setSubmitting,
  setStatus,
  notification: {
    info: 'Adding fluid...',
    success: 'Fluid successfully created',
  },
});

export const createFluid = (wellboreId, fluidObj) => ({
  type: ACTIONS.CREATE_WELLBORE_FLUID,
  payload: {
    wellboreId,
    fluidObj,
  },
});
export const requestDeleteWellboreFluid = (wellboreId, fluidId) => {
  return requestDeleteFluidGeneric(
    WELLS_ACTIONS.INITIATE_DELETE_WELLBORE_FLUID,
    { wellboreId },
    fluidId,
  );
};
