import moment from 'moment';
import { Map } from 'immutable';

import mappers from 'mappers';
import { ProjectWellboreDetailStatus } from 'app/app.constants';

export const ProjectWellboreDetailMapper = {
  from: ({
    status,
    created,
    approvedBy,
    approvalDate,
    wellboreDetail,
    ...projectWellboreDetail
  }) =>
    Map({
      status,
      created: moment(created),
      approvalDate: approvalDate ? moment(approvalDate) : undefined,
      approvedBy: approvedBy ? mappers.User.from(approvedBy) : undefined,
      revisionDate: moment(
        status === ProjectWellboreDetailStatus.APPROVED
          ? approvalDate
          : created,
      ),
      ...projectWellboreDetail,
    }),
};

export const WellborePurpose = {
  UNKNOWN: 0,
  INJECTION: 1,
  NOT_AVAILABLE: 2,
  OBSERVATION: 3,
  PRODUCTION: 4,
  SHALLOW_GAS: 5,
};

export const WellboreContent = {
  UNKNOWN: 0,
  CO2: 1,
  CUTTINGS: 2,
  DRY: 3,
  GAS: 4,
  GAS_CONDENSATE: 5,
  NOT_APPLICABLE: 6,
  NOT_AVAILABLE: 7,
  OIL: 8,
  OIL_GAS: 9,
  OIL_GAS_CONDENSATE: 10,
  WATER: 11,
  WATER_GAS: 12,
};

export const HostileFluids = {
  CARBON_DIOXIDE: 1,
  HYDROGEN_SULPHIDE: 2,
  CALCIUM_CHLORIDE: 3,
  CALCIUM_BROMIDE: 4,
  CESIUM_FORMATE: 5,
  POTASSIUM_FORMATE: 6,
  SODIUM_FORMATE: 7,
  ZINC_BROMIDE: 8,
};

export const AdditionalParameters = {
  SIBHP: 1,
  FBHP: 2,
  FWHP: 3,
  SIZE_OF_LOGGED_CASING_TUBING: 4,
  GAS_DENSITY: 5,
  OIL_DENSITY: 6,
  WATER_DENSITY: 7,
  FLUID_DENSITY: 8,
  TYPE_OF_FLUIDS: 9,
  WATER_CUT: 10,
  WATER_RATE: 11,
  OIL_RATE: 12,
  GAS_RATE: 13,
  GAS_LIQUID_RATE: 14,
  GUN_TYPE: 15,
  CHARGE_TYPE: 16,
  SHOOTING_IN_GAS_OR_LIQUID: 17,
  GAS_RATE_NEEDED_FOR_CORRELATION: 18,
  PERFORATION_INTERVALS_MD: 19,
  PERFORATION_INTERVALS_TVD: 20,
  DIFFERENCE_OF_PRESSURE_WHEN_PERFORATING: 21,
  DURATION: 22,
  SETTING_DEPTH_TVD: 23,
  SETTING_DEPTH_MD: 24,
  TEMPERATURE_AT_SETTING_DEPTH: 25,
  PRESSURE_AT_SETTING_DEPTH: 26,
  MAX_DP_AT_SETTING_DEPTH: 27,
  INNER_DIAMETER_AT_SETTING_DEPTH: 28,
  CASING_TUBING_SIZE: 29,
  CASING_TUBING_MATERIAL: 30,
  CASING_TUBING_WEIGHT: 31,
  INCLINATION_AT_SETTING_DEPTH: 33,
  ELEMENT_TO_BE_CUT_FBHP: 34,
  MATERIAL_TYPE: 35,
  OUTER_DIAMETER_TO_BE_CUT: 36,
  INNER_DIAMETER_TO_BE_CUT: 37,
  THICKNESS_FROM_ABOVE_OUTER_DIAMETER_INNER_DIAMETER: 38,
  MINIMUM_RESTRICTION_ABOVE_THE_CUT_POINT: 39,
  CUT_DEPTH_CORRELATION_DEPTH_IN_WELL: 40,
  PIPE_IN_TENSION_NEUTRAL_COMPRESSION: 41,
  AMOUNT_OF_TENSION_COMPRESSIONS: 42,
  DHSV_HOLD_OPEN_SLEEVE_INSTALLED: 43,
  DEPTH_DETERMINATION_METHOD: 44,
  NO_GO_MAXIMUM_OUTER_DIAMETER: 45,
  NO_GO_MINIMUM_OUTER_DIAMETER: 46,
  DISTANCE_FROM_NO_GO_TO_TOP_CUT_ZONE: 47,
  LENGTH_OF_CUT_ZONE: 48,
  WELL_FLUID_TYPE_INSIDE_TUBING: 49,
  WELL_FLUID_WEIGHT_INSIDE_TUBING: 50,
  WELL_FLUID_TYPE_INSIDE_ANNULUS: 51,
  WELL_FLUID_WEIGHT_INSIDE_ANNULUS: 52,
  PRESSURE_DIFFERENTIAL_BETWEEN_TUBING_AND_ANNULUS: 53,
  IS_COMMUNICATION_BETWEEN_TUBING_AND_ANNULUS_REQUIRED: 54,
  MONO_HEPTA: 55,
  DRAWING_WITH_SPACE_OUT_MEASUREMENT_PROVIDED: 56,
  DRAWING_WITH_ALL_INNER_DIAMETER_DIMENSIONS_DETAILED: 57,
  PUMPING_RATE: 58,
  BULLHEADING_DISPLACEMENT: 59,
  FLOW_DURING_OPERATION: 61,
  DATE: 62,
};

export const wellborePurposeToString = (wellborePurpose) =>
  ({
    [undefined]: '',
    [WellborePurpose.UNKNOWN]: 'Unknown',
    [WellborePurpose.INJECTION]: 'Injection',
    [WellborePurpose.NOT_AVAILABLE]: 'Not available',
    [WellborePurpose.OBSERVATION]: 'Observation',
    [WellborePurpose.PRODUCTION]: 'Production',
    [WellborePurpose.SHALLOW_GAS]: 'Shallow gas',
  }[wellborePurpose]);

export const wellboreContentToString = (wellboreContent) =>
  ({
    [undefined]: '',
    [WellboreContent.UNKNOWN]: 'Unknown',
    [WellboreContent.CO2]: 'CO2',
    [WellboreContent.CUTTINGS]: 'Cuttings',
    [WellboreContent.DRY]: 'Dry',
    [WellboreContent.GAS]: 'Gas',
    [WellboreContent.GAS_CONDENSATE]: 'Gas and condensate',
    [WellboreContent.NOT_APPLICABLE]: 'Not applicable',
    [WellboreContent.OIL]: 'Oil',
    [WellboreContent.OIL_GAS]: 'Oil and gas',
    [WellboreContent.OIL_GAS_CONDENSATE]: 'Oil, gas and condensate',
    [WellboreContent.WATER]: 'Water',
    [WellboreContent.WATER_GAS]: 'Water and gas',
  }[wellboreContent]);

export const hostileFluidToDisplayName = (hostileFluid) =>
  ({
    [HostileFluids.CARBON_DIOXIDE]: 'CO2 (%)',
    [HostileFluids.HYDROGEN_SULPHIDE]: 'H2S (ppm)',
    [HostileFluids.CALCIUM_CHLORIDE]: 'Calcium Chloride (%)',
    [HostileFluids.CALCIUM_BROMIDE]: 'Calcium Bromide',
    [HostileFluids.CESIUM_FORMATE]: 'Cesium Formate',
    [HostileFluids.POTASSIUM_FORMATE]: 'Potassium Formate',
    [HostileFluids.SODIUM_FORMATE]: 'Sodium Formate',
    [HostileFluids.ZINC_BROMIDE]: 'Zinc Bromide',
  }[hostileFluid]);

const projectWellboreDetailsMappers = {
  ProjectWellboreDetail: ProjectWellboreDetailMapper,
};

export default projectWellboreDetailsMappers;
