import { List } from 'immutable';

export const INTEGRITY_MANAGEMENT_ACTIONS = {
  GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES:
    'GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES',
  ADD_NEW_INTEGRITY_MANAGEMENT_INTEGRITY:
    'ADD_NEW_INTEGRITY_MANAGEMENT_INTEGRITY',
  DELETE_INTEGRITY_MANAGEMENT_INTEGRITY:
    'DELETE_INTEGRITY_MANAGEMENT_INTEGRITY',
  UPDATE_INTEGRITY_MANAGEMENT_INTEGRITY:
    'UPDATE_INTEGRITY_MANAGEMENT_INTEGRITY',
  GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES_FOR_WELL:
    'GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES_FOR_WELL',
  ADD_NEW_INTEGRITY_MANAGEMENT_RULE_DATA:
    'ADD_NEW_INTEGRITY_MANAGEMENT_RULE_DATA',
};

// check can we use the same ID
export const RISK_PRINCIPLE_TYPES = {
  LIKELIHOOD_OF_FAILURE: 'LIKELIHOOD OF FAILURE',
  WELL_CONSEQUENCE: 'WELL CONSEQUENCE',
};

export const INTEGRITY_TYPE_ENUM = {
  // NONE: 0, // not needed
  RULE: 1,
  CATEGORY: 2,
  RISK_PRINCIPLE: 3,
};

// used for selector when creating new rule or category
export const INTEGRITY_TYPE_SELECTOR = {
  RULE: 1,
  CATEGORY: 2,
};

export const RULE_TYPES_ENUM = {
  RULE_BOOL: 1,
  RULE_DOUBLE: 2,
  RULE_GEOGRAPHY: 3,
  RULE_TIME: 4,
  RULE_PICKLIST: 5,
};

export const BOOLEAN_DATA_VALUES_ENUM = {
  NO: { value: 0, label: 'No' },
  YES: { value: 1, label: 'Yes' },
};

export const INTEGRITY_MANAGEMENT_RULE_TYPES = List([
  { id: '1', code: 'RuleBool', name: 'Data Value (Yes/No)' },
  { id: '2', code: 'RuleDouble', name: 'Data Value (Numeric Value)' },
  { id: '3', code: 'RuleGeography', name: 'Geography' },
  { id: '4', code: 'RuleTime', name: 'Time/Age' },
  { id: '5', code: 'PickListOption', name: 'Picklist option' },
]);

export const INTEGRITY_MANAGEMENT_RULE_DATA_TYPE = List([
  { id: '1', name: 'Yes/No' },
  { id: '2', name: 'Numeric Value' },
]);

export const getRuleTypeString = (ruleType) => {
  return INTEGRITY_MANAGEMENT_RULE_TYPES.find(
    (rule) => Number(rule.id) === ruleType,
  )?.name;
};

export const IntegrityForm = {
  INTEGRITY_ID: 'integrityId', // for edit
  TITLE: 'title',
  WEIGHT: 'weight',
  // rule
  SCORE: 'score',
  RULE_TYPE: 'integrityDetailType',
  DEFAULT_VALUE_CHECKED: 'defaultValueRequired',
  VALUE: 'value',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  SRID: 'srid',
  PARENT_ID: 'parentId',
  PICKLIST_OPTIONS: 'picklistOptions',
  // category
  INTEGRITY_TYPE: 'integrityType',
  RELATED_INTEGRITIES: 'relatedIntegrities',
};

export const WellIntegrityRiskMatrix = [
  ['N/A', 'Very Likely', 'Likely', 'Possible', 'Unlikely', 'Very Unlikely'],
  ['Catastrophic', 'Red', 'Red', 'Red', 'Red', 'Yellow', 'Weight'],
  ['Critical', 'Red', 'Red', 'Red', 'Yellow', 'Yellow'],
  ['Major', 'Red', 'Yellow', 'Yellow', 'Yellow', 'Green'],
  ['Moderate', 'Yellow', 'Yellow', 'Green', 'Green', 'Green'],
  ['Minor', 'Yellow', 'Green', 'Green', 'Green', 'Green'],
];
