import { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { PUBLIC_ASSETS_URL } from 'app/app.constants';
import { Grid, Typography, IconButton } from '@material-ui/core';
import NavigationHelpTooltip from 'app/components/WellboreContextualization/NavigationHelpTooltip';

const Tool3DObjectModalHeader = ({ closeModal, title }) => {
  const [help, setHelp] = useState();

  useEffect(() => {
    prepareHelpData();
  }, []);

  const prepareHelpData = () => {
    setHelp({
      title: 'HOW TO INTERACT WITH 3D OBJECT?',
      data: [
        {
          navType: '1- Inspecting the 3D Object:',
          content: 'Use simple dragging to inspect the 3D Object',
          src: `${PUBLIC_ASSETS_URL}/images/navigation/navigate.png`,
        },
        {
          navType: '2- Zoom in and out:',
          content: (
            <ul>
              <li>
                Touchpad: Use two fingers and move them closer to zoom in
                further to zoom out
              </li>
              <li>
                Mouse: Scroll forward to zoom in and backward to zoom out.
              </li>
            </ul>
          ),
          src: `${PUBLIC_ASSETS_URL}/images/navigation/zoomIn.png`,
        },
      ],
    });
  };

  return (
    <Grid container alignItems="center">
      <Grid container item xs={2} justifyContent="flex-start">
        <Grid item>{help && <NavigationHelpTooltip data={help} />}</Grid>
      </Grid>
      <Grid container item xs={8} justifyContent="center">
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid container item xs={2} justifyContent="flex-end">
        <Grid item>
          <IconButton onClick={closeModal}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Tool3DObjectModalHeader;
