import ProceduresTable from './components/ProceduresTable';
import { BasePage } from 'altus-ui-components';
import { Grid } from '@material-ui/core';
import ProceduresAndChecklistsFilter from './components/ProceduresAndChecklistsFilter';
import { useEffect } from 'react';
import { compose } from 'redux';
import { useSelector, connect } from 'react-redux';
import { onLoadChecklistsAndProcedures } from '../documents.actions';
import {
  getAvailableDepartmentsFromState,
  getCurrentUserFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';

import {
  CHECKLISTS_AND_PROCEDURES_FILTER_SOURCE,
  DOCUMENTS_ACTIONS,
} from '../documents.constants';
import { mapChecklistsAndProcedures } from './newDocuments.helpers';
import { useState } from 'react';
import { getFilteredChecklists } from '../checklistsFilter.selectors';
import { Field, Formik, Form } from 'formik';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import SearchTextFieldFormik from 'app/components/form/formik/SearchTextFieldFormik';

export const Filters = {
  TEXT_SEARCH: 'textSearch',
};

const ProceduresAndChecklistsContainer = ({
  dispatchOnLoad,
  dataState,
  projectId,
}) => {
  useEffect(() => {
    dispatchOnLoad(projectId);
  }, [dispatchOnLoad, projectId]);

  const user = useSelector(getCurrentUserFromState);

  const [companyId, setCompanyId] = useState('');
  const [procedureTypes, setProcedureTypes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const setAllCompanyFilter = () => {
    if (procedureTypes.length === 3) {
      setProcedureTypes([]);
    } else {
      setProcedureTypes(companyFilters.map((filter) => filter.id));
    }
  };

  const setAllDepartmentsFilter = () => {
    if (departments.length === 4) {
      setDepartments([]);
    } else {
      setDepartments(departmentFilters.map((filter) => filter.id));
    }
  };

  const setAllServicesFilter = () => {
    if (services.length === 3) {
      setServices([]);
    } else {
      setProcedureTypes([]);
      setServices(serviceFilters.map((filter) => filter.id));
    }
  };

  const onSubmit = (formValues) => {
    setSearchTerm(formValues[Filters.TEXT_SEARCH]);
  };

  const initialValues = {
    [Filters.TEXT_SEARCH]: '',
  };

  const shouldSubmit = ({ isValid }) => isValid;

  const filteredChecklists = useSelector((state) => {
    const filterFn = getFilteredChecklists(state);
    return filterFn(
      companyId,
      procedureTypes,
      departments,
      searchTerm,
      services,
    );
  });

  const companyFilters = [
    {
      id: 1,
      name: 'Procedures',
      source: CHECKLISTS_AND_PROCEDURES_FILTER_SOURCE.COMPANY,
    },
    {
      id: 2,
      name: 'Checklists',
      source: CHECKLISTS_AND_PROCEDURES_FILTER_SOURCE.COMPANY,
    },
    {
      id: 3,
      name: 'QHSE',
      source: CHECKLISTS_AND_PROCEDURES_FILTER_SOURCE.COMPANY,
    },
  ];

  const departmentFiltersMap = useSelector(getAvailableDepartmentsFromState);

  const departmentFilters = departmentFiltersMap
    ? departmentFiltersMap
        .valueSeq()
        .toArray()
        .map((item) => item.toJS())
    : [];

  const serviceFilters = [
    {
      id: 1,
      name: 'Procedures',
      source: CHECKLISTS_AND_PROCEDURES_FILTER_SOURCE.SERVICE,
    },
    {
      id: 2,
      name: 'Checklists',
      source: CHECKLISTS_AND_PROCEDURES_FILTER_SOURCE.SERVICE,
    },
    {
      id: 3,
      name: 'QHSE',
      source: CHECKLISTS_AND_PROCEDURES_FILTER_SOURCE.SERVICE,
    },
  ];

  return (
    <BasePage dataState={dataState}>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={2}>
          <ProceduresAndChecklistsFilter
            companyFilters={companyFilters}
            departmentFilters={departmentFilters}
            serviceFilters={serviceFilters}
            setServices={setServices}
            services={services}
            setCompanyId={setCompanyId}
            procedureTypes={procedureTypes}
            setProcedureTypes={setProcedureTypes}
            departments={departments}
            setDepartments={setDepartments}
            user={user}
            setAllCompanyFilter={setAllCompanyFilter}
            setAllDepartmentsFilter={setAllDepartmentsFilter}
            setAllServicesFilter={setAllServicesFilter}
          />
        </Grid>
        <Grid item xs={10}>
          <Formik
            enableReinitialize
            onSubmit={onSubmit}
            initialValues={initialValues}
            shouldSubmit={shouldSubmit}
          >
            <Form>
              <AutoSaveFormik timeout={1000}>
                <Field
                  name={Filters.TEXT_SEARCH}
                  component={SearchTextFieldFormik}
                />
              </AutoSaveFormik>
            </Form>
          </Formik>
          <ProceduresTable
            procedures={mapChecklistsAndProcedures(filteredChecklists)}
          />
        </Grid>
      </Grid>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    DOCUMENTS_ACTIONS.GET_ALL_CHECKLISTS_AND_PROCEDURES,
    DOCUMENTS_ACTIONS.RECEIVE_CHECKLISTS_AND_PROCEDURES,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoadChecklistsAndProcedures,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ProceduresAndChecklistsContainer,
);
