import moment from 'moment';
import { formatDuration, formatDurationDiff } from 'utils/format.util';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Typography, Divider } from '@material-ui/core';
import { durationFromStartAndEndTime } from 'utils/app.util';
import { useCallback } from 'react';

const ProjectStatusDurationValues = ({ classes, projectStatus }) => {
  const estimatedDuration = (task) => {
    return task?.get('durationPlanned')
      ? moment.duration(task.get('durationPlanned'))
      : null;
  };

  const taskStartTime = (task) => {
    return task?.get('startTime')
      ? moment.duration(task.get('startTime'))
      : null;
  };
  const spentDuration = useCallback((task) => {
    return durationFromStartAndEndTime(task?.get('startTime'), moment());
  }, []);

  const diffDuration = useCallback(
    (task) => {
      return estimatedDuration(task)
        ? moment.duration(
            spentDuration(task).clone().subtract(estimatedDuration(task)).abs(),
          )
        : null;
    },
    [spentDuration],
  );
  const compareDurations = (task) => {
    const estDuration = estimatedDuration(task);
    const spntDuration = spentDuration(task);

    if (!estDuration || !spntDuration) return null; // Handle cases where durations are not available

    const estDurationMs = estDuration.asMilliseconds();
    const spntDurationMs = spntDuration.asMilliseconds();

    if (spntDurationMs > estDurationMs) {
      return classes.timeDifferenceMore;
    } else if (spntDurationMs <= estDurationMs) {
      return classes.timeDifferenceLess;
    }
  };
  return (
    <Grid item container className={classes.durationContainer}>
      <Grid item xs={3} className={classes.durationItem}>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            Time Planned
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            {estimatedDuration(projectStatus?.getIn(['current', 'task']))
              ? formatDuration(
                  estimatedDuration(projectStatus?.getIn(['current', 'task'])),
                )
              : 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item xs={3} className={classes.durationItem}>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            Time Spent
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            {taskStartTime(projectStatus?.getIn(['current', 'task']))
              ? formatDurationDiff(
                  taskStartTime(projectStatus?.getIn(['current', 'task'])),
                  moment.duration(moment()),
                )
              : 'N/A'}
          </Typography>
        </Grid>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item xs={3} className={classes.durationItem}>
        <Grid item xs={12}>
          <Typography variant="body2" color="textSecondary">
            Difference
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            className={compareDurations(
              projectStatus?.getIn(['current', 'task']),
            )}
          >
            {diffDuration(projectStatus?.getIn(['current', 'task']))
              ? formatDuration(
                  diffDuration(projectStatus?.getIn(['current', 'task'])),
                )
              : 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  durationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.mobile.Background.dark,
    opacity: '100%',
    zIndex: 3,
  },
  durationItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeDifferenceMore: {
    color: theme.palette.error.main,
  },
  timeDifferenceLess: {
    color: theme.palette.success.main,
  },
});

export default withStyles(styles)(ProjectStatusDurationValues);
