import { ACTIONS } from 'features/projects/tasks/task/simulation/dynamicToolParameters/simulationDynamicToolParameter.constants';
import { EMPTY_MAP } from 'app/app.constants';

export const requestSimulationDynamicToolParameters = (
  projectId,
  taskId,
  simulationId,
) => ({
  taskId,
  projectId,
  simulationId,
  type: ACTIONS.REQUEST_SIMULATION_TOOL_PARAMETERS,
});

export const requestUpdateSimulationDynamicToolParameter = (
  projectId,
  taskId,
  simulationId,
  simulationDynamicToolParameterId,
  simulationDynamicToolParameter,
  setSubmitting,
  setStatus,
  refreshParameters = false,
) => ({
  taskId,
  projectId,
  setStatus,
  simulationId,
  setSubmitting,
  simulationDynamicToolParameterId,
  refreshParameters,
  payload: simulationDynamicToolParameter,
  type: ACTIONS.REQUEST_UPDATE_SIMULATION_TOOL_PARAMETER,
  notification: {
    info: 'Updating tool parameter',
    success: 'Tool Parameter successfully updated',
  },
});

export const receiveSimulationDynamicToolParameter = (
  simulationDynamicToolParameter,
) => ({
  payload: simulationDynamicToolParameter,
  type: ACTIONS.RECEIVE_SIMULATION_TOOL_PARAMETER,
});

export const receiveSimulationDynamicToolParameters = (
  simulationDynamicToolParameters,
) => ({
  payload: simulationDynamicToolParameters ?? EMPTY_MAP,
  type: ACTIONS.RECEIVE_SIMULATION_TOOL_PARAMETERS,
});
