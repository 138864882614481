import { Switch } from 'react-router-dom';

import {
  settingsRoutes,
  dashboardRoutes,
  dataExchangeRoutes,
} from 'app/routes';

import routePaths from 'app/routePaths';
import ApplicationSidebarContainer from 'app/ApplicationSidebarContainer';
import ProjectRoutesContainer from 'app/components/ProjectRoutesContainer';

const AppContainerV2 = () => (
  <Switch>
    <ProjectRoutesContainer
      routes={dashboardRoutes}
      path={routePaths.dashboard.root}
    />
    <ApplicationSidebarContainer
      routes={settingsRoutes}
      path={routePaths.settings.root}
    />
    <ApplicationSidebarContainer
      routes={dataExchangeRoutes}
      path={routePaths.dataExchange.root}
    />
  </Switch>
);

export default AppContainerV2;
