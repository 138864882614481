import { fromJS, List } from 'immutable';

import mappers from 'mappers';
import { APP_ACTIONS, EMPTY_LIST } from 'app/app.constants';

const initialState = fromJS({
  data: EMPTY_LIST,
});

const eventsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case APP_ACTIONS.EVENTS_LOADED:
    case APP_ACTIONS.EVENTS_PAGE_LOADED:
      if (action.error) return state;
      return state.set('data', List(action.payload).map(mappers.Event.from));
    default:
      return state;
  }
};

export default eventsReducer;
