import React from 'react';
import { useEffect } from 'react';

import { Field, useFormikContext } from 'formik';

import { Grid } from '@material-ui/core';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import {
  BOP_LEAK_TYPE,
  BOP_RAM,
  TEST_ASSET_RESULT,
} from 'features/equipment/equipment.constants';
import EditorFormik from 'app/components/form/formik/EditorFormik';
import { editorStateToString, getRichEditorText } from 'utils/app.util';
import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';

const FormFields = AssetHistoryForm;

export const BopTestFormComponent = () => {
  const getId = (item) => item.id;
  const getName = (item) => item.name;
  const { values, setFieldValue } = useFormikContext();
  const isFail = String(TEST_ASSET_RESULT.FAIL) === values[FormFields.RESULT];
  const isOtherSelected =
    BOP_LEAK_TYPE.find((type) => type.name === 'Other')?.id ===
    values[FormFields.BOP_LEAK_TYPE];
  const bopLeakTypeDescription = values[FormFields.BOP_LEAK_TYPE_DESCRIPTION];

  useEffect(() => {
    if (bopLeakTypeDescription && isOtherSelected) {
      const json = editorStateToString(bopLeakTypeDescription);
      var value = getRichEditorText(json);
      setFieldValue(FormFields.BOP_LEAK_TYPE_DESCRIPTION_VALIDATION, value);
    }
  }, [bopLeakTypeDescription, isOtherSelected, setFieldValue]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={isFail ? 6 : 12}>
          <SelectTextFieldFormik
            getItemName={getName}
            placeholder="Select"
            label="Ram set"
            getItemValue={getId}
            items={BOP_RAM}
            name={FormFields.BOP_RAM}
            displayEmpty={false}
          />
        </Grid>
        <Grid item xs={6}>
          {isFail ? (
            <SelectTextFieldFormik
              getItemName={getName}
              placeholder="Select"
              label="Type of leak"
              getItemValue={getId}
              items={BOP_LEAK_TYPE}
              name={FormFields.BOP_LEAK_TYPE}
              displayEmpty={false}
            />
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12} container>
        {isOtherSelected && (
          <Field name={FormFields.BOP_LEAK_TYPE_DESCRIPTION}>
            {({ form, ...formik }) => {
              return (
                <>
                  <EditorFormik
                    form={form}
                    {...formik}
                    xs={12}
                    label="Description"
                  />
                  {/* Check for errors in BOP_LEAK_TYPE_DESCRIPTION_VALIDATION instead of BOP_LEAK_TYPE_DESCRIPTION */}
                  {form.errors[
                    FormFields.BOP_LEAK_TYPE_DESCRIPTION_VALIDATION
                  ] && (
                    <div style={{ color: 'red', marginTop: '1vh' }}>
                      {
                        form.errors[
                          FormFields.BOP_LEAK_TYPE_DESCRIPTION_VALIDATION
                        ]
                      }
                    </div>
                  )}
                </>
              );
            }}
          </Field>
        )}
      </Grid>
    </>
  );
};

export default BopTestFormComponent;
