import { compose } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Dialog, DialogContent } from '@material-ui/core';

import { LoadingOverlay } from 'altus-ui-components';
import { getSummarizedDataStateFromState } from 'app/app.selectors';

import AddToolForm from 'features/equipment/components/AddToolForm';
import { EQUIPMENT_ACTIONS } from 'features/equipment/equipment.constants';
import AddToolModalHeader from 'features/equipment/components/AddToolModalHeader';

const AddToolModal = ({
  open,
  tool,
  edit,
  title,
  classes,
  saveTool,
  onSubmit,
  validForm,
  dataState,
  suppliers,
  toolGroups,
  toggleModal,
  toolConnectors,
  requiredFields,
  initialFormValues,
  unitsOfMeasurePreference,
  selectedCustomUnitsOfMeasure,
}) => {
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={toggleModal}
        classes={{ paperScrollPaper: classes.root }}
      >
        <Grid className={classes.header}>
          <AddToolModalHeader
            closeModal={toggleModal}
            title={title ? title : 'Add 3rd party tool'}
          />
        </Grid>
        <DialogContent className={classes.content}>
          <AddToolForm
            edit={edit}
            tool={tool}
            saveTool={saveTool}
            onSubmit={onSubmit}
            validForm={validForm}
            toolGroups={toolGroups}
            toolSuppliers={suppliers}
            toolConnectors={toolConnectors}
            requiredFields={requiredFields}
            initialFormValues={initialFormValues}
            unitsOfMeasurePreference={unitsOfMeasurePreference}
            selectedCustomUnitsOfMeasure={selectedCustomUnitsOfMeasure}
          />
        </DialogContent>
        <LoadingOverlay dataState={dataState} text={'Saving tool...'} />
      </Dialog>
    </>
  );
};

const styles = (theme) => ({
  root: {
    height: 'fit-content',
  },
  content: {
    overflow: 'scroll-y',
  },
  header: {
    padding: theme.spacing(3),
  },
  footer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  filters: {
    paddingBottom: theme.spacing(2),
  },
  table: {
    paddingBottom: theme.spacing(2),
  },
});

export default compose(
  connect((state) => ({
    dataState: getSummarizedDataStateFromState(
      state,
      EQUIPMENT_ACTIONS.CREATE_TOOL,
    ),
  })),
  withStyles(styles),
)(AddToolModal);
