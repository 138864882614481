import { FormFields } from 'features/projects/tasks/task/activities/ActivityListItem/helpers/constants';
import { customEndDateValidation } from 'features/projects/tasks/task/activities/ActivityListItem/helpers/validation';
import DateTimePickerFormik from 'app/components/form/formik/DateTimePickerFormik';
import { useFormikContext, Field } from 'formik';

export const FormFieldEndTime = () => {
  // Grab values from Formik context
  const { values } = useFormikContext();
  return (
    <Field
      name={FormFields.END_TIME}
      validate={(endTime) => {
        try {
          // Pass in latest value of endTime into form values
          // Because form values from hook has old value of end time.
          // When updated pass it all to validation.
          customEndDateValidation.validateSync({ ...values, endTime });
        } catch (error) {
          return error.message;
        }
      }}
    >
      {({ form, ...formik }) => (
        <DateTimePickerFormik disableFuture={true} form={form} {...formik} />
      )}
    </Field>
  );
};
