import { Grid } from '@material-ui/core';
import ReadOnlyField from 'app/components/ReadOnlyField';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const CableBreakTestDrawerComponent = ({ assetHistory, classes, keyEvent }) => {
  const quantity = assetHistory.getIn([keyEvent, 'forceNewtonsQuantity']);

  return (
    <>
      <Grid item xs={6}>
        <Grid className={classes.marginLabel}>
          <CustomReadOnlyField
            margin="none"
            label="Force"
            className={classes?.marginLabel}
          >
            {quantity?.get('roundedValue')} {quantity?.get('unit')}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
    </>
  );
};

export default CableBreakTestDrawerComponent;
