import React from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Drawer } from '@material-ui/core';
import TaskActivityDrawerHeader from 'features/projects/tasks/task/activities/TaskActivityDrawerHeader';
import TaskActivityDrawerContent from 'features/projects/tasks/task/activities/TaskActivityDrawerContent';

const TaskActivityDrawer = ({
  isOpen,
  classes,
  activity,
  toggleDrawer,
  displayComments,
}) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={toggleDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Grid className={classes.drawerHeader}>
        <TaskActivityDrawerHeader
          title={activity.get('name')}
          toggleDrawer={toggleDrawer}
        />
      </Grid>
      <Grid className={classes.drawerContent}>
        <TaskActivityDrawerContent
          activity={activity}
          displayComments={displayComments}
        />
      </Grid>
    </Drawer>
  );
};

const styles = (theme) => ({
  drawerPaper: {
    width: '30%',
    minWidth: 300,
    background: theme.palette.background.default,
  },
  drawerHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    background: theme.palette.background.default,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.table.row.hover}`,
  },
  drawerContent: {
    padding: theme.spacing(2),
    overflowY: 'scroll-y',
    margin: `0 ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
  },
});

export default withStyles(styles)(TaskActivityDrawer);
