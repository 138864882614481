import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const MobileNavigationHelpTooltip = ({ classes, data }) => {
  return (
    <Grid className={classes.drawerContent} spacing={2}>
      <Typography variant="h6" className={classes.drawerTitle}>
        {data.title}
      </Typography>
      {data.data.map((help) => (
        <Box className={classes.container}>
          <Box className={classes.drawerNavType}>
            <Typography variant="h6">{help.navType}</Typography>
            <Typography variant="body2">{help.content}</Typography>
          </Box>
          <Box component="img" className={classes.image} src={help.src} />
        </Box>
      ))}
    </Grid>
  );
};

export default MobileNavigationHelpTooltip;
