import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useToggle } from 'altus-hooks';

import { BasePage, ModalActions, LoadingButton } from 'altus-ui-components';

import Close from '@material-ui/icons/Close';
import { Field, Formik, Form } from 'formik';
import withStyles from '@material-ui/styles/withStyles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Link,
} from '@material-ui/core';

import { useModal } from 'altus-modal';
import moment from 'moment';

import {
  ASSET_HISTORY_EQUIPMENT_TYPE,
  ASSET_HISTORY_MAINTENANCE_TYPES,
  ASSET_HISTORY_TEST_TYPES,
  EQUIPMENT_ACTIONS,
  EquipmentType,
  TEST_RESULT_TO_TOOLTIP_TITLE,
  TOOL_ASSET_EVENTS_ENUM,
} from 'features/equipment/equipment.constants';

import RedIcon from 'assets/Red.svg';
import GreenIcon from 'assets/Green.svg';
import OrangeIcon from 'assets/Orange.svg';
import { EMPTY_STRING, EMPTY_MAP } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import {
  exportCableHistory,
  onLoadAssetHistory,
  loadEquipmentAssets,
} from 'features/equipment/equipment.actions';
import AssetHistoryTable from 'features/equipment/assets/components/AssetHistoryTable';
import { MODAL } from 'features/equipment/equipment.constants';
import CreateAssetHistoryModalContainer from 'features/equipment/assets/components/CreateAssetHistoryModalContainer';
import { toolAssetHistoryEventToString } from 'features/equipment/equipment.helpers';
import CableTestDetailsDrawer from 'features/equipment/assets/components/drawer/cable-drawer/CableTestDetailsDrawer';
import {
  getAssetHistoryFromState,
  getEquipmentAssetsFromState,
  getCableTestDetailsFromState,
} from 'features/equipment/equipment.selectors';
import { useHeader } from 'app/hooks/useHeader';

const statusIcons = {
  Template: GreenIcon,
  Preliminary: GreenIcon,
  'Config Struct': GreenIcon,
  Serviceable: GreenIcon,
  'Svceable Restr': OrangeIcon,
  'Standy By': OrangeIcon,
  Spare: OrangeIcon,
  Unserviceable: RedIcon,
  Incident: RedIcon,
  'Scrap Proposal': RedIcon,
  Scrapped: RedIcon,
  Renamed: RedIcon,
  'Unknown Status': RedIcon,
  'Invalid Status': RedIcon,
};

const FORM_FIELDS = {
  SEARCH: 'textSearch',
};

const initialFormValues = {
  [FORM_FIELDS.SEARCH]: EMPTY_STRING,
};

const AssetHistoryContainer = ({
  classes,
  dataState,
  equipmentId,
  serialNumber,
  assetHistory,
  equipmentType,
  dispatchOnLoad,
  cableTestDetails,
  dispatchOnLoadEquipments,
  equipmentAsset = EMPTY_MAP,
}) => {
  const [searchedAssets, setSearchedAssets] = useState(null);
  const [serial, setSerial] = useState(null);
  const [isOpen, toggleModal] = useModal(MODAL.ADD_ASSET_HISTORY_TEST);
  const history = useHistory();
  const assetId = assetHistory?.get('events')?.first()?.get('assetId');
  const [cableTestDetailsIsOpen, setCableTestDetailsToggle] = useToggle();
  const cableDetail = cableTestDetails?.get('cable');

  // Filter test types based on equipment type
  const filterTypes = (ASSET_HISTORY_TYPE) => {
    let equipmentTypes = [ASSET_HISTORY_EQUIPMENT_TYPE.ALL];

    switch (equipmentType) {
      case EquipmentType.CABLE:
        equipmentTypes.push(ASSET_HISTORY_EQUIPMENT_TYPE.CABLE);
        break;
      case EquipmentType.SURFACE:
        equipmentTypes.push(ASSET_HISTORY_EQUIPMENT_TYPE.SURFACE);
        break;
      default:
        equipmentTypes.push(
          ASSET_HISTORY_EQUIPMENT_TYPE.BHA,
          ASSET_HISTORY_EQUIPMENT_TYPE.TOOL,
        );
    }

    return ASSET_HISTORY_TYPE.filter((item) =>
      equipmentTypes.includes(item.equipmentType),
    );
  };

  const testTypesItems = filterTypes(ASSET_HISTORY_TEST_TYPES);
  const maintenanceTypeItems = filterTypes(ASSET_HISTORY_MAINTENANCE_TYPES);

  const formatAndValidateDate = (date) => {
    if (!date) {
      return '-';
    }
    const formattedDate = moment(date).format('DD.MM.YYYY');
    return formattedDate !== '01.01.0001' ? formattedDate : '-';
  };

  const nextMaintenance = formatAndValidateDate(
    assetHistory?.get('nextMaintenance'),
  );
  const lastMaintenance = formatAndValidateDate(
    assetHistory?.get('lastMaintenance'),
  );

  useHeader({
    title: cableDetail
      ? `${cableDetail?.manufacturer} - ${cableDetail?.name}`
      : null,
    subTitle: `Serial No. ${serial ?? serialNumber}`,
  });

  const getTypographyStyles = (maintenanceDate) => {
    const isDefaultDate = maintenanceDate === '-';
    return {
      align: isDefaultDate ? 'center' : 'inherit',
      style: {
        width: '100%',
        justifyContent: isDefaultDate ? 'center' : 'inherit',
        display: 'flex',
      },
    };
  };

  const openModal = (event) => {
    event?.preventDefault();
    toggleModal();
  };

  const goBack = (event) => {
    event.preventDefault();
    const type =
      equipmentType === EquipmentType.SURFACE
        ? 'surface'
        : equipmentType === EquipmentType.CABLE
        ? 'cable'
        : 'equipment';

    if (type === 'equipment') {
      history.push(`/${type}/${equipmentId}/category/1`, {
        isArchive: true,
      });
    } else {
      history.push(`/${type}/${equipmentId}`, {
        isArchive: true,
      });
    }
  };

  const deepSearch = (data, keyword) => {
    const assets = data.events;
    let matchingAssets = [];

    for (let asset of assets) {
      const eventDescription = toolAssetHistoryEventToString(asset?.event);

      if (eventDescription?.toLowerCase()?.includes(keyword?.toLowerCase())) {
        matchingAssets.push(asset);
        continue;
      }

      for (let key in asset) {
        if (!['task', 'test', 'complaint', 'maintenance'].includes(key)) {
          if (
            key === 'doneBy' &&
            asset?.doneBy?.fullName
              ?.toLowerCase()
              ?.includes(keyword?.toLowerCase())
          ) {
            matchingAssets.push(asset);
          }
          if (
            typeof asset[key] === 'string' &&
            asset[key]?.toLowerCase()?.includes(keyword?.toLowerCase())
          ) {
            matchingAssets.push(asset);
            break;
          } else if (asset[key] === keyword) {
            matchingAssets.push(asset);
            break;
          }
        }

        const searchByDates = searchByDate(key, asset, keyword);

        if (searchByDates) {
          matchingAssets.push(searchByDates);
        }
      }

      const searchInTasks =
        asset.event === TOOL_ASSET_EVENTS_ENUM.RUN ||
        asset.event === TOOL_ASSET_EVENTS_ENUM.TASK;

      let searchArray = [];
      if (searchInTasks) {
        searchArray = asset.tasks || [];
      } else if (asset.event === TOOL_ASSET_EVENTS_ENUM.TEST) {
        searchArray = asset.test || [];

        const resultDescription =
          TEST_RESULT_TO_TOOLTIP_TITLE[asset?.test?.result];

        if (
          resultDescription &&
          resultDescription?.toLowerCase()?.includes(keyword.toLowerCase())
        ) {
          matchingAssets.push(asset);
          continue;
        }
      } else if (asset.event === TOOL_ASSET_EVENTS_ENUM.COMPLAINTS) {
        searchArray = asset.complaint || [];
      } else if (asset.event === TOOL_ASSET_EVENTS_ENUM.MAINTENANCE) {
        searchArray = asset.maintenance || [];
      } else {
        searchArray = asset.doneBy;
      }

      searchArray =
        searchArray !== null && searchArray !== undefined
          ? Array.isArray(searchArray)
            ? searchArray
            : [searchArray]
          : [];

      for (let item of searchArray) {
        for (let key in item) {
          if (
            typeof item[key] === 'string' &&
            item[key].toLowerCase().includes(keyword.toLowerCase())
          ) {
            if (!matchingAssets.includes(asset)) {
              matchingAssets.push(asset);
            }
            break;
          } else if (item[key] === keyword) {
            if (!matchingAssets.includes(asset)) {
              matchingAssets.push(asset);
            }
            break;
          }
        }
      }
    }

    return matchingAssets;
  };

  const searchByDate = (key, asset, keyword) => {
    if (key === 'startDate' || key === 'endDate') {
      let assetDate = new Date(asset[key]);
      let keywordDate = new Date(keyword);

      if (!isNaN(assetDate.getTime()) && !isNaN(keywordDate.getTime())) {
        if (
          assetDate.toISOString().split('T')[0] ===
          keywordDate.toISOString().split('T')[0]
        ) {
          return asset;
        }
      } else if (asset?.[key]?.toLowerCase().includes(keyword?.toLowerCase())) {
        return asset;
      }

      return null;
    }

    return null;
  };

  useEffect(() => {
    // first load all equipment assets
    dispatchOnLoadEquipments(equipmentId, equipmentType);
  }, [dispatchOnLoadEquipments, equipmentId, equipmentType]);

  useEffect(() => {
    // continue loading when fing seral number match
    if (
      equipmentAsset !== EMPTY_MAP &&
      equipmentAsset.get('m3ToolAssets').size > 0
    ) {
      var item = equipmentAsset
        .get('m3ToolAssets')
        .find((x) => x.get('decodedSerialNumber') === serialNumber);
      if (item !== null) {
        setSerial(item.get('serialNumber'));
        dispatchOnLoad(equipmentId, equipmentType, item.get('serialNumber'));
      }
    }
  }, [
    dispatchOnLoad,
    equipmentId,
    equipmentType,
    serialNumber,
    equipmentAsset,
  ]);

  const onSubmit = (values) => {
    setSearchedAssets(deepSearch(assetHistory.toJS(), values.textSearch));
  };

  const dispatch = useDispatch();

  const onExportCableHistory = () => {
    dispatch(exportCableHistory(equipmentId, serialNumber));
  };

  return (
    <BasePage dataState={dataState}>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialFormValues}
      >
        <Box className={classes.pageContainer}>
          <Form>
            <AutoSaveFormik>
              <Grid
                item
                container
                xs
                justifyContent="space-between"
                alignItems="center"
                className={classes.title}
              >
                <Grid item>
                  <Link
                    href="#"
                    color="inherit"
                    underline="none"
                    onClick={goBack}
                  >
                    <IconButton>
                      <ChevronLeftIcon />
                    </IconButton>
                    <Typography component="span">Back</Typography>
                  </Link>
                </Grid>

                <Grid item>
                  <Field name={FORM_FIELDS.SEARCH}>
                    {({ form, ...formik }) => (
                      <TextFieldFormik
                        form={form}
                        margin="none"
                        label="Name"
                        placeholder="Search..."
                        inputProps={{ maxLength: 100 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                size="small"
                                title="Reset"
                                onClick={() => {
                                  form.setFieldValue(
                                    FORM_FIELDS.SEARCH,
                                    initialFormValues[FORM_FIELDS.SEARCH],
                                  );
                                  setSearchedAssets(null);
                                }}
                              >
                                <Close fontSize="small" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        {...formik}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </AutoSaveFormik>
          </Form>
          <Grid
            container
            item
            xs
            direction="row"
            justifyContent="space-between"
            className={classes.title}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              style={{ marginBottom: '20px' }}
            >
              <Grid item>
                <Typography variant="h4">Asset history</Typography>
              </Grid>
              <Grid item>
                <Link
                  color="inherit"
                  style={{ cursor: 'pointer' }}
                  underline="none"
                  onClick={() => openModal()}
                >
                  <Typography style={{ fontSize: '1.10rem' }}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <AddIcon
                        fontSize="small"
                        style={{ marginRight: '5px' }}
                      />{' '}
                      ADD EVENT
                    </span>
                  </Typography>
                </Link>
              </Grid>
            </Grid>

            <Grid
              className={classes.headerItems}
              item
              container
              direction="column"
            >
              <Grid item style={{ paddingLeft: '10px' }}>
                <Typography variant="caption">Status</Typography>
              </Grid>
              <Grid item container direction="row" alignItems="center">
                <img
                  src={
                    statusIcons[assetHistory?.get('status') ?? 'Unknown Status']
                  }
                  alt="Status icon"
                />
                <Typography>
                  {assetHistory?.get('status') ?? 'Unknown Status'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              className={classes.headerItems}
              item
              container
              direction="column"
            >
              <Grid item>
                <Typography variant="caption">Total tasks</Typography>
              </Grid>
              <Grid item container style={{ paddingTop: '10px' }}>
                <Typography>{assetHistory?.get('totalTasks')}</Typography>
              </Grid>
            </Grid>
            <Grid
              className={classes.headerItems}
              item
              container
              direction="column"
            >
              <Grid item>
                <Typography variant="caption">Total projects</Typography>
              </Grid>
              <Grid item container style={{ paddingTop: '10px' }}>
                <Typography>{assetHistory?.get('totalProjects')}</Typography>
              </Grid>
            </Grid>
            <Grid
              className={classes.headerItems}
              item
              container
              direction="column"
            >
              <Grid item>
                <Typography variant="caption">Last maintenance</Typography>
              </Grid>
              <Grid item container style={{ paddingTop: '10px' }}>
                <Typography {...getTypographyStyles(lastMaintenance)}>
                  {lastMaintenance}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              className={classes.headerItems}
              item
              container
              direction="column"
            >
              <Grid item>
                <Typography variant="caption">Next maintenance</Typography>
              </Grid>
              <Grid item container style={{ paddingTop: '10px' }}>
                <Typography {...getTypographyStyles(nextMaintenance)}>
                  {nextMaintenance}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              {equipmentType === ASSET_HISTORY_EQUIPMENT_TYPE.CABLE ? (
                <ModalActions className={classes.gridBackground}>
                  <LoadingButton
                    type="button"
                    size={'large'}
                    color="default"
                    variant="contained"
                    onClick={setCableTestDetailsToggle}
                  >
                    MORE DETAILS
                  </LoadingButton>
                </ModalActions>
              ) : null}
            </Grid>
          </Grid>
          <Grid container item>
            <AssetHistoryTable
              searchedAssets={searchedAssets}
              assets={assetHistory?.get('events')}
            />
          </Grid>
        </Box>
      </Formik>

      <CreateAssetHistoryModalContainer
        toggleModal={openModal}
        isOpen={isOpen}
        serialNumber={serial ?? serialNumber}
        externalToolId={equipmentId}
        equipmentType={equipmentType}
        assetId={assetId}
        testTypesItems={testTypesItems}
        maintenanceTypeItems={maintenanceTypeItems}
      />

      {cableTestDetails && (
        <CableTestDetailsDrawer
          isOpen={cableTestDetailsIsOpen}
          toggleDrawer={setCableTestDetailsToggle}
          cableTestDetails={cableTestDetails}
          serialNumber={serial ?? serialNumber}
          cableDetail={cableDetail}
          exportCableHistory={onExportCableHistory}
        />
      )}
    </BasePage>
  );
};

const styles = {
  headerItems: {
    width: 'fit-content',
  },
  pageContainer: {
    paddingTop: '10px',
  },
  title: {
    marginBottom: '11px',
    flexDirection: 'row',
  },
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    EQUIPMENT_ACTIONS.LOAD_ASSET_HISTORY,
    EQUIPMENT_ACTIONS.EXPORT_CABLE_HISTORY,
  ),
  equipmentAsset: getEquipmentAssetsFromState(state),
  assetHistory: getAssetHistoryFromState(state),
  cableTestDetails: getCableTestDetailsFromState(state),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoadAssetHistory,
  dispatchOnLoadEquipments: loadEquipmentAssets,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AssetHistoryContainer);
