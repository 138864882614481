import Agent from 'infrastructure/agent';

const getAvailableHostileFluids = () =>
  Agent.get('/wellbore/details/hostile-fluids');

const getAvailableAdditionalParameters = () =>
  Agent.get('/wellbore/details/additional-parameters');

const getAvailableWellboreDetailServices = () =>
  Agent.get('/wellbore/details/services');

const updateWellboreDetail = (fieldId, wellboreId, wellboreDetail) =>
  Agent.put(`/field/${fieldId}/wellbore/${wellboreId}/details`, wellboreDetail);

const createWellboreDetail = (fieldId, wellboreId) =>
  Agent.post(`/field/${fieldId}/wellbore/${wellboreId}/details`);

const addHostileFluids = (fieldId, wellboreId, hostileFluidsToAdd) =>
  Agent.post(
    `/field/${fieldId}/wellbore/${wellboreId}/details/hostile-fluids`,
    hostileFluidsToAdd,
  );

const removeHostileFluids = (fieldId, wellboreId, hostileFluidsToRemove) =>
  Agent.put(
    `/field/${fieldId}/wellbore/${wellboreId}/details/hostile-fluids/delete`,
    hostileFluidsToRemove,
  );

const deleteWellboreDetailService = (
  fieldId,
  wellboreId,
  wellboreDetailServiceId,
) =>
  Agent.put(
    `/field/${fieldId}/wellbore/${wellboreId}/details/services/${wellboreDetailServiceId}/delete`,
  );

const addWellboreDetailServices = (
  fieldId,
  wellboreId,
  wellboreDetailServiceTypes,
) =>
  Agent.post(
    `/field/${fieldId}/wellbore/${wellboreId}/details/services`,
    wellboreDetailServiceTypes,
  );

const wellboreDetailsService = {
  addHostileFluids,
  removeHostileFluids,
  createWellboreDetail,
  updateWellboreDetail,
  getAvailableHostileFluids,
  addWellboreDetailServices,
  deleteWellboreDetailService,
  getAvailableAdditionalParameters,
  getAvailableWellboreDetailServices,
};

export default wellboreDetailsService;
