import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Dialog, DialogContent } from '@material-ui/core';

import { LoadingOverlay } from 'altus-ui-components';

import EquipmentTemplatesCreator from 'features/equipment/equipmentTemplates/addEquipmentTemplates/EquipmentTemplatesCreator';
import AddEquipmentTemplatesModalHeader from 'features/equipment/equipmentTemplates/addEquipmentTemplates/AddEquipmentTemplatesModalHeader';
import AddEquipmentTemplatesModalFooter from 'features/equipment/equipmentTemplates/addEquipmentTemplates/AddEquipmentTemplatesModalFooter';

const AddEquipmentTemplatesModal = ({
  open,
  classes,
  toggleModal,
  equipmentTemplateInfo,
  onSaveEquipmentTemplate,
  toggleAddEquipmentToEquipmentTemplateModal,
  onSortItem,
  onDeleteItem,
  onDuplicateItem,
  selectedTools,
  setEquipmentTemplateInfo,
  dataState,
  isEditMode = false,
}) => {
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={toggleModal}
        classes={{ paperScrollPaper: classes.root }}
      >
        <Grid className={classes.header}>
          <AddEquipmentTemplatesModalHeader
            closeModal={toggleModal}
            title={`${isEditMode ? 'Edit' : 'Add'} Equipment template`}
          />
        </Grid>
        <DialogContent className={classes.content}>
          <EquipmentTemplatesCreator
            toggleAddEquipmentToEquipmentTemplateModal={
              toggleAddEquipmentToEquipmentTemplateModal
            }
            onSortItem={onSortItem}
            onDeleteItem={onDeleteItem}
            selectedTools={selectedTools}
            onDuplicateItem={onDuplicateItem}
            equipmentTemplateInfo={equipmentTemplateInfo}
            setEquipmentTemplateInfo={setEquipmentTemplateInfo}
          />
        </DialogContent>
        <Grid className={classes.footer}>
          <AddEquipmentTemplatesModalFooter
            equipmentTemplateInfo={equipmentTemplateInfo}
            selectedTools={selectedTools}
            onSaveEquipmentTemplate={onSaveEquipmentTemplate}
            isEditMode={isEditMode}
          />
        </Grid>
        <LoadingOverlay dataState={dataState} />
      </Dialog>
    </>
  );
};

const styles = (theme) => ({
  root: {
    // height: 'fit-content',
  },
  content: {
    overflow: 'scroll-y',
  },
  header: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.dark,
  },
  footer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.dark,
  },
  filters: {
    paddingBottom: theme.spacing(2),
  },
  table: {
    paddingBottom: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(AddEquipmentTemplatesModal);
