import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import {
  selectProjectNonProductiveTime,
  deleteProjectNonProductiveTime,
  getAllProjectNonProductives,
  onProjectNonProductiveReportUnload,
} from 'features/projects/npt/npt.actions';

import {
  getActionDataStateFromState,
  getAvailableDepartmentsFromState,
} from 'app/app.selectors';

import { EMPTY_LIST } from 'app/app.constants';
import { useHeader } from 'app/hooks/useHeader';
import NPTTable from 'features/projects/npt/components/NPTTable';
import { NPT_ACTIONS } from 'features/projects/npt/npt.constants';
import { getCurrentProject } from 'features/projects/projects.selectors';
import EditNPTModalContainer from 'features/projects/npt/components/EditNPTModalContainer';
import { getProjectNonProductiveTimeFromState } from 'features/projects/npt/npt.selectors';

const NPTReportContainer = ({
  npts,
  classes,
  project,
  dataState,
  projectId,
  breadcrumb,
  departments,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchSelectNpt,
  dispatchDeleteNpt,
}) => {
  const [availableDepartments, setAvailableDepartments] = useState(
    departments ?? EMPTY_LIST,
  );

  useEffect(() => {
    const ids = [];
    project.get('departments').forEach((dep) => {
      ids.push(dep.get('id'));
    });
    setAvailableDepartments(
      departments.filter((department) => ids.includes(department.get('id'))),
    );
  }, [project, departments]);

  useHeader({
    subTitle: breadcrumb,
  });

  useEffect(() => {
    dispatchOnLoad(projectId);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload, projectId]);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs justifyContent="center">
        <Grid item xs={8}>
          <NPTTable
            npts={npts}
            project={project}
            projectId={projectId}
            editNpt={dispatchSelectNpt}
            deleteNpt={dispatchDeleteNpt}
          />
        </Grid>
      </Grid>
      <EditNPTModalContainer
        project={project.toJS()}
        projectId={projectId}
        availableDepartments={availableDepartments}
      />
    </BasePage>
  );
};
const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  departments: getAvailableDepartmentsFromState(state),
  npts: getProjectNonProductiveTimeFromState(state),
  dataState: getActionDataStateFromState(
    state,
    NPT_ACTIONS.PROJECT_NON_PRODUCTIVE_TIME_REPORT_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchSelectNpt: selectProjectNonProductiveTime,
  dispatchDeleteNpt: deleteProjectNonProductiveTime,
  dispatchOnLoad: getAllProjectNonProductives,
  dispatchOnUnload: onProjectNonProductiveReportUnload,
};

const styles = (theme) => ({
  create: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(NPTReportContainer);
