import { compose } from 'redux';
import { List } from 'immutable';
import _isNil from 'lodash/isNil';
import { Formik, Form } from 'formik';
import DoneAll from '@material-ui/icons/DoneAll';
import withStyles from '@material-ui/styles/withStyles';
import { useEffect, useCallback, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, IconButton } from '@material-ui/core';

import { useModal } from 'altus-modal';
import { BasePage } from 'altus-ui-components';

import {
  getSimulationsForTaskFromState,
  getSelectedCablesForTaskFromState,
} from 'features/projects/tasks/task/simulation/simulation.selectors';

import {
  copySerialsForSEFromAnotherTask,
  onLoadTaskSurfaceEquipment,
  saveCableSerialNumberToTask,
  saveSurfaceEquipmentSerialNumber,
} from 'features/projects/tasks/tasks.actions';

import {
  getAllTasksFromState,
  getTaskById,
  getTaskSurfaceEquipmentFromState,
} from 'features/projects/tasks/tasks.selectors';

import {
  ACTIONS,
  SimulationState,
} from 'features/projects/tasks/task/simulation/simulation.constants';

import {
  MODAL,
  EquipmentType,
  EQUIPMENT_CATEGORY_ENUM,
  ASSET_HISTORY_TEST_TYPE,
  ASSET_HISTORY_TEST_TYPES,
  ASSET_HISTORY_EVENT_TYPES,
  ASSET_HISTORY_EVENT_TYPES_ITEM,
} from 'features/equipment/equipment.constants';

import { EMPTY_LIST, ProjectPermission } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import withProjectPermission from 'app/components/withProjectPermission';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import * as actions from 'features/projects/tasks/task/simulation/simulation.actions';
import { TASK_SURFACE_EQUIPMENT_ACTIONS } from 'features/projects/tasks/tasks.constants';
import { validateSurfaceEquipmentSerialNumbers } from 'features/equipment/equipment.actions';
import { getSurfaceEquipmentCategoriesFromState } from 'features/equipment/equipment.selectors';
import CreateAssetHistoryModalContainer from 'features/equipment/assets/components/CreateAssetHistoryModalContainer';
import AddedCablesInExecuteTable from 'features/projects/tasks/task/surfaceEquipment/components/cables/AddedCablesInExecuteTable';
import AddedSurfaceEquipmentInExecuteTable from 'features/projects/tasks/task/surfaceEquipment/components/surfaceEquipment/AddedSurfaceEquipmentInExecuteTable';

export const EXECUTION_SE_FILTERS = {
  AUTOFILL_SERIALS_SELECT_FIELD: 'AUTOFILL_SERIALS_SELECT_FIELD',
};

const defaultFormValues = {
  [EXECUTION_SE_FILTERS.AUTOFILL_SERIALS_SELECT_FIELD]: EMPTY_LIST,
};

const getKey = (item) => item.get('taskId');
const getName = (item) => item.get('title')?.toUpperCase();

const ExecutionSurfaceEquipmentContainer = ({
  taskId,
  classes,
  projectId,
  dataState,
  dispatchOnLoad,
  surfaceEquipmentFromState,
}) => {
  const dispatch = useDispatch();

  const [priorTasks, setPriorTasks] = useState(EMPTY_LIST);

  const [isOpen, toggleModal] = useModal(MODAL.ADD_ASSET_HISTORY_SURFACE_SIL2);

  const allProjectTasks = useSelector(getAllTasksFromState);
  const task = useSelector((state) => getTaskById(taskId)(state));

  useEffect(() => {
    dispatchOnLoad(projectId, taskId);
  }, [taskId, dispatchOnLoad, projectId]);

  useEffect(() => {
    if (projectId) {
      dispatch(
        actions.loadSimulations(projectId, taskId, [
          SimulationState.PLANNED,
          SimulationState.UPDATED_PLANNED,
        ]),
      );
    }
  }, [projectId, taskId, dispatch]);

  useEffect(() => {
    const taskIdNumber = Number(taskId);

    const matchedTask = allProjectTasks.find(
      (task) => task.get('taskId') === taskIdNumber,
    );

    if (!matchedTask) {
      setPriorTasks(List());
      return;
    }

    setPriorTasks(
      allProjectTasks.filter(
        (task) =>
          task.get('sequence') < matchedTask.get('sequence') &&
          task.get('taskId') !== taskIdNumber,
      ),
    );
  }, [taskId, allProjectTasks]);

  const openModal = (event) => {
    event?.preventDefault();
    toggleModal();
  };

  const validateSil2SurfaceEquipmentSerialNumbers = () => {
    dispatch(validateSurfaceEquipmentSerialNumbers(taskId));
  };

  const testTypesItems = List([
    ASSET_HISTORY_TEST_TYPES.find(
      (item) => item.id === String(ASSET_HISTORY_TEST_TYPE.SIL2_TEST),
    ),
  ]).filter((item) => item);

  const assetHistoryEventTypes = List([
    ASSET_HISTORY_EVENT_TYPES.find(
      (item) => item.id === String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST),
    ),
  ]).filter((item) => item);

  const surfaceEquipmentCategories = surfaceEquipmentFromState.filter(
    (item) => !!item.get('serialNo'),
  );

  const sil2TestBopItems = surfaceEquipmentCategories.filter(
    (item) => item.get('equipmentCategory') === EQUIPMENT_CATEGORY_ENUM.BOP,
  );

  const sil2TestBcsItems = surfaceEquipmentCategories.filter(
    (item) => item.get('equipmentCategory') === EQUIPMENT_CATEGORY_ENUM.BCS,
  );

  const sil2TestCabinItems = surfaceEquipmentCategories.filter(
    (item) => item.get('equipmentCategory') === 2,
  );

  const simulations = useSelector((state) =>
    !_isNil(taskId)
      ? getSimulationsForTaskFromState(state, taskId)?.valueSeq()
      : EMPTY_LIST,
  );

  const selectedCables = useSelector((state) =>
    getSelectedCablesForTaskFromState(state, taskId),
  );

  const onSubmitCable = useCallback(
    (values) => {
      dispatch(saveCableSerialNumberToTask(projectId, taskId, values.serialNo));
    },
    [dispatch, projectId, taskId],
  );

  const onSubmitSurfaceEquipment = useCallback(
    (values) => {
      dispatch(
        saveSurfaceEquipmentSerialNumber(
          projectId,
          taskId,
          values.serialNo,
          values.taskSurfaceId,
        ),
      );
    },
    [dispatch, projectId, taskId],
  );

  const cablesWithSerialNo = selectedCables?.update(0, (cable) =>
    cable.set('serialNo', task?.get('individualSerialNumber')),
  );

  const onSubmitForm = useCallback(
    (formValues) => {
      dispatch(
        copySerialsForSEFromAnotherTask(
          projectId,
          taskId,
          formValues.AUTOFILL_SERIALS_SELECT_FIELD,
        ),
      );
    },
    [dispatch, projectId, taskId],
  );

  return (
    <>
      <CreateAssetHistoryModalContainer
        toggleModal={openModal}
        isOpen={isOpen}
        equipmentType={EquipmentType.SURFACE_EQUIPMENT}
        getAssetAfterRefresh={false}
        assetHistoryEventTypes={assetHistoryEventTypes}
        testTypesItems={testTypesItems}
        taskId={taskId}
        defaultEventValue={String(ASSET_HISTORY_EVENT_TYPES_ITEM.TEST)}
        defaultTestTypeValue={String(ASSET_HISTORY_TEST_TYPE.SIL2_TEST)}
        modalTitle="SIL2 Test"
        sil2TestBopItems={sil2TestBopItems}
        sil2TestBcsItems={sil2TestBcsItems}
        sil2TestCabinItems={sil2TestCabinItems}
      />
      <BasePage
        dataState={dataState}
        classes={{
          children: classes.basePageChildren,
        }}
      >
        <Formik
          enableReinitialize
          onSubmit={onSubmitForm}
          initialValues={defaultFormValues}
        >
          <Form>
            <AutoSaveFormik timeout={700}>
              <Grid container spacing={6} className={classes.container}>
                <Grid item container xs={11} justifyContent="flex-end">
                  <Grid xs={2} item>
                    <SelectTextFieldFormik
                      displayEmpty={false}
                      getItemName={getName}
                      getItemValue={getKey}
                      margin="none"
                      label="Auto-fill serial numbers"
                      items={priorTasks}
                      name={EXECUTION_SE_FILTERS.AUTOFILL_SERIALS_SELECT_FIELD}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={11}
                  className={classes.equipmentContainer}
                >
                  <Grid item container xs={12} justifyContent="space-between">
                    <Typography variant="h5">Cables</Typography>
                  </Grid>
                  {!simulations || !task || !cablesWithSerialNo ? (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.emptyEquipment}
                    >
                      <Typography variant="caption" color="textSecondary">
                        No simulations planned.
                      </Typography>
                    </Grid>
                  ) : (
                    <AddedCablesInExecuteTable
                      task={task}
                      onSubmit={onSubmitCable}
                      cablesWithSerialNo={cablesWithSerialNo}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs={11}
                  className={classes.equipmentContainer}
                >
                  <Grid item container xs={12} justifyContent="space-between">
                    <Typography variant="h5">Surface Equipment</Typography>

                    <IconButton
                      title="Add Sil2 Test"
                      onClick={() =>
                        validateSil2SurfaceEquipmentSerialNumbers()
                      }
                    >
                      <DoneAll />
                    </IconButton>
                  </Grid>
                  {!surfaceEquipmentFromState.size ? (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.emptyEquipment}
                    >
                      <Typography variant="caption" color="textSecondary">
                        No surface equipment added.
                      </Typography>
                    </Grid>
                  ) : (
                    <AddedSurfaceEquipmentInExecuteTable
                      task={task}
                      onSubmitSurfaceEquipment={onSubmitSurfaceEquipment}
                      selectedSurfaceEquipment={surfaceEquipmentFromState}
                      surfaceEquipmentCategories={surfaceEquipmentCategories}
                    />
                  )}
                </Grid>
              </Grid>
            </AutoSaveFormik>
          </Form>
        </Formik>
      </BasePage>
    </>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
  leftIcon: {
    marginRight: 10,
  },
  emptyEquipment: {
    border: `thin solid ${theme.palette.grey[700]}`,
    justifyContent: 'center',
    alignItems: 'center',
    height: '15vh',
  },
  equipmentContainer: {
    gap: theme.spacing(2.25),
  },
});

const mapStateToProps = (state) => ({
  surfaceEquipmentFromState: getTaskSurfaceEquipmentFromState(state),
  surfaceEquipmentCategoriesFromState:
    getSurfaceEquipmentCategoriesFromState(state),
  dataState: getSummarizedDataStateFromState(
    state,
    ACTIONS.LOAD_SIMULATIONS,
    TASK_SURFACE_EQUIPMENT_ACTIONS.PAGE_LOADED,
    TASK_SURFACE_EQUIPMENT_ACTIONS.CABLES_LOADED,
    TASK_SURFACE_EQUIPMENT_ACTIONS.SAVE_CABLE_SERIAL_NUMBER,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoadTaskSurfaceEquipment,
};

export default withProjectPermission(ProjectPermission.EXECUTE)(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
  )(ExecutionSurfaceEquipmentContainer),
);
