import { Field } from 'formik';
import { useMemo, useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { required } from 'utils/validation.util';
import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import { wellboreSectionTypeToString } from 'features/wells/sections/wellboreSection.mappers';

const getSubRows = (row) => {
  if (row.original) return;

  return row.get('wellboreSectionNipples', EMPTY_LIST).valueSeq();
};

const SimulationWellboreSectionsTable = ({
  updateWellboreSection,
  defaultWellboreSection,
  simulation = EMPTY_MAP,
  wellboreSections = EMPTY_MAP,
}) => {
  const lengthUnit = defaultWellboreSection.getIn(['top', 'unit']);

  const isSimulationPlanned = simulation.get('isPlanned');

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (!row.canExpand) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <IconButton {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 2,
        Header: 'Type',
        Cell: ({ row }) => {
          const isNipple = row.original.get('isNipple');

          const type = isNipple
            ? row.original.get('type')
            : wellboreSectionTypeToString(row.original.get('type'));

          return type ?? <i>N/A</i>;
        },
      },
      {
        xs: 2,
        id: 'section',
        Header: 'Section',
        accessor: (_, index, row) => {
          if (row.id.includes('.')) {
            const indexes = row.id.split('.');
            return `Nipple ${Number(indexes[0]) + 1}.${Number(indexes[1]) + 1}`;
          }

          return index + 1;
        },
      },
      {
        xs: 2,
        id: 'top',
        accessor: (wellboreSection) =>
          wellboreSection.getIn(['top', 'roundedValue']),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Top" unit={lengthUnit} />
        ),
      },
      {
        xs: 2,
        id: 'bottom',
        accessor: (wellboreSection) =>
          wellboreSection.getIn(['bottom', 'roundedValue'], null),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Bottom" unit={lengthUnit} />
        ),
      },
      {
        xs: 2,
        id: 'length',
        accessor: (wellboreSection) =>
          wellboreSection.getIn(['length', 'roundedValue'], null),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Length" unit={lengthUnit} />
        ),
      },
      {
        xs: 2,
        id: 'innerDiameter',
        accessor: (wellboreSection) =>
          wellboreSection.getIn(['innerDiameter', 'roundedValue'], null),
        Header: () => (
          <QuantityTableHeaderTitleCell
            title="Hole Dia."
            unit={defaultWellboreSection.getIn(['innerDiameter', 'unit'])}
          />
        ),
      },
      {
        xs: 2,
        Header: 'Friction in',
        accessor: (wellboreSection) =>
          wellboreSection.get('frictionFactorIn', null),
        Cell: ({ row, value }) => {
          const isNipple = row.original.get('isNipple');

          if (isNipple || isSimulationPlanned) {
            return value;
          }

          return (
            <Field
              autoFocus
              type="number"
              validate={required}
              name="frictionFactorIn"
              component={TableRowTextFieldFormik}
            />
          );
        },
      },
      {
        xs: 2,
        Header: 'Friction out',
        accessor: (wellboreSection) =>
          wellboreSection.get('frictionFactorOut', null),
        Cell: ({ row, value }) => {
          const isNipple = row.original.get('isNipple');

          if (isNipple || isSimulationPlanned) {
            return value;
          }

          return (
            <Field
              type="number"
              validate={required}
              name="frictionFactorOut"
              component={TableRowTextFieldFormik}
            />
          );
        },
      },
    ],
    [lengthUnit, defaultWellboreSection, isSimulationPlanned],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <EditableTableRowFormik {...props} onSubmit={updateWellboreSection} />
    ),
    [updateWellboreSection],
  );

  const initialState = useMemo(
    () => ({
      expanded: wellboreSections.toArray().reduce(
        (result, _wellboreSection, index) => ({
          ...result,
          [index]: true,
        }),
        {},
      ),
    }),
    [wellboreSections],
  );

  return (
    <Table
      useExpanded
      stickyHeader
      disableSortBy
      columns={columns}
      useGlobalFilter={false}
      items={wellboreSections}
      initialState={initialState}
      getSubRows={getSubRows}
      noItemsMessage="No sections added yet..."
      TableRowComponent={renderTableRowComponent}
    />
  );
};
export default SimulationWellboreSectionsTable;
