import { compose } from 'redux';
import { useMemo, useCallback } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

import { useReducerWithDataState } from 'altus-hooks';
import { withModal, ModalHeader } from 'altus-ui-components';

import {
  clearProjectWellboreDetails,
  receiveProjectWellboreDetails,
} from 'features/projects/wellbore/details/projectWellboreDetails.actions';

import { EMPTY_MAP } from 'app/app.constants';
import projectWellboreDetailService from 'services/projectWellboreDetail.service';
import projectWellboreDetailsReducer from 'features/projects/wellbore/details/projectWellboreDetails.reducer';
import { PROJECT_WELLBORE_DETAIL_CHANGELOG_MODAL_ID } from 'features/projects/wellbore/details/projectWellboreDetails.constants';
import ProjectWellboreDetailChangelog from 'features/projects/wellbore/details/components/ProjectWellboreDetailChangelog';

const ProjectWellboreDetailChangelogModal = ({
  open,
  project,
  projectId,
  toggleModal,
}) => {
  const [
    getProjectWellboreDetailChangelog,
    projectWellboreDetailsState,
    dispatch,
    dataState,
    clearDataState,
  ] = useReducerWithDataState(
    projectWellboreDetailService.getProjectWellboreDetailChangelog,
    projectWellboreDetailsReducer,
    EMPTY_MAP,
  );

  const onEntered = useCallback(
    () =>
      getProjectWellboreDetailChangelog(projectId).then((payload) =>
        dispatch(receiveProjectWellboreDetails(payload)),
      ),
    [dispatch, projectId, getProjectWellboreDetailChangelog],
  );

  const onExited = useCallback(() => {
    clearDataState();
    dispatch(clearProjectWellboreDetails());
  }, [dispatch, clearDataState]);

  const projectWellboreDetails = useMemo(
    () => projectWellboreDetailsState.get('details', EMPTY_MAP),
    [projectWellboreDetailsState],
  );

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      onClose={toggleModal}
      TransitionProps={{
        onExited,
        onEntered,
      }}
    >
      <ModalHeader title="Changelog" toggleModal={toggleModal} />
      <DialogContent>
        {dataState.isError() && <div>An error occurred. Try again.</div>}
        <ProjectWellboreDetailChangelog
          project={project}
          dataState={dataState}
          projectWellboreDetails={projectWellboreDetails}
        />
      </DialogContent>
    </Dialog>
  );
};

export default compose(withModal(PROJECT_WELLBORE_DETAIL_CHANGELOG_MODAL_ID))(
  ProjectWellboreDetailChangelogModal,
);
