import { compose } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';

import {
  Grid,
  Dialog,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';

import { LoadingOverlay } from 'altus-ui-components';
import { getSummarizedDataStateFromState } from 'app/app.selectors';

import { EQUIPMENT_ACTIONS } from 'features/equipment/equipment.constants';
import ToolAssemblyCreator from 'features/equipment/toolAssemblies/components/ToolAssemblyCreator';
import ToolAssembliesModalHeader from 'features/equipment/toolAssemblies/components/ToolAssembliesModalHeader';
import ToolAssembliesModalFooter from 'features/equipment/toolAssemblies/components/ToolAssembliesModalFooter';

const ToolAssembliesModal = ({
  open,
  edit,
  title,
  classes,
  isLoading = false,
  dataState,
  toggleModal,
  selectedTools,
  onDuplicateItem,
  toolAssemblyInfo,
  toolAssemblyName,
  onSaveToolAssembly,
  setToolAssemblyInfo,
  setToolAssemblyName,
  onSortToolstringItem,
  onUpdateAssemblyName,
  onDeleteToolstringItem,
  toggleToolAssemblyItemModal,
}) => {
  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={toggleModal}
        classes={{ paperScrollPaper: classes.root }}
      >
        <Grid className={classes.header}>
          <ToolAssembliesModalHeader
            closeModal={toggleModal}
            toolAssemblyName={toolAssemblyName}
            title={title ? title : 'Add tool assembly'}
          />
        </Grid>
        <DialogContent className={classes.content}>
          {isLoading ? (
            <Grid
              justifyContent="center"
              alignItems="center"
              className={classes.wrapper}
              container
            >
              <CircularProgress />
            </Grid>
          ) : (
            <ToolAssemblyCreator
              selectedTools={selectedTools}
              onDuplicateItem={onDuplicateItem}
              toolAssemblyInfo={toolAssemblyInfo}
              toolAssemblyName={toolAssemblyName}
              setToolAssemblyInfo={setToolAssemblyInfo}
              setToolAssemblyName={setToolAssemblyName}
              onSortToolstringItem={onSortToolstringItem}
              onUpdateAssemblyName={onUpdateAssemblyName}
              onDeleteToolstringItem={onDeleteToolstringItem}
              toggleToolAssemblyItemModal={toggleToolAssemblyItemModal}
            />
          )}
        </DialogContent>
        <Grid className={classes.footer}>
          <ToolAssembliesModalFooter
            edit={edit}
            selectedTools={selectedTools}
            toolAssemblyName={toolAssemblyName}
            onSaveToolAssembly={onSaveToolAssembly}
          />
        </Grid>
        <LoadingOverlay dataState={dataState} text={'Saving tool...'} />
      </Dialog>
    </>
  );
};

const styles = (theme) => ({
  root: {
    // height: 'fit-content',
  },
  content: {
    overflow: 'scroll-y',
  },
  header: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.dark,
  },
  footer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.dark,
  },
  filters: {
    paddingBottom: theme.spacing(2),
  },
  table: {
    paddingBottom: theme.spacing(2),
  },
  wrapper: {
    width: '100%',
    height: '100%',
  },
});

export default compose(
  connect((state) => ({
    dataState: getSummarizedDataStateFromState(
      state,
      EQUIPMENT_ACTIONS.SAVE_TOOL_ASSEMBLY,
    ),
  })),
  withStyles(styles),
)(ToolAssembliesModal);
