import { Grid, Link } from '@material-ui/core';
import ReadOnlyField from 'app/components/ReadOnlyField';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const TaskDetailDrawerComponent = ({ assetHistory }) => {
  const currentTask = assetHistory?.get('task');
  return (
    <>
      {currentTask === null ? null : (
        <>
          <Grid item xs={6}>
            <CustomReadOnlyField margin="none" label="Title">
              {currentTask.get('title') ? currentTask.get('title') : <>-</>}
            </CustomReadOnlyField>
          </Grid>
          <Grid item xs={6}>
            <CustomReadOnlyField margin="none" label="Run">
              {currentTask.get('runNumber') ? (
                <Link
                  target="_blank"
                  href={`/projects/${assetHistory.get(
                    'projectId',
                  )}/execution/${currentTask.get('taskId')}/activities`}
                >
                  {currentTask.get('runNumber')}
                </Link>
              ) : (
                <>-</>
              )}
            </CustomReadOnlyField>
          </Grid>
        </>
      )}
    </>
  );
};

export default TaskDetailDrawerComponent;
