import Agent from 'infrastructure/agent';
import wellboreSectionMappers from 'features/wells/sections/wellboreSection.mappers';

const getProjectWellboreSection = (projectId, wellboreSectionId) =>
  Agent.get(`/project/${projectId}/wellbore/section/${wellboreSectionId}`);

const getProjectWellboreSections = (projectId, wellboreDetailId) => {
  if (wellboreDetailId) {
    return Agent.get(
      `/project/${projectId}/wellbore/section/detail/${wellboreDetailId}`,
    );
  } else {
    return Agent.get(`/project/${projectId}/wellbore/section/detail`);
  }
};

const getDefaultProjectWellboreSection = (projectId) =>
  Agent.get(`/project/${projectId}/wellbore/section/default`);

const createProjectWellboreSection = (
  projectId,
  wellboreDetailId,
  wellboreSection,
) =>
  Agent.post(
    `/project/${projectId}/wellbore/section/detail/${wellboreDetailId}`,
    wellboreSectionMappers.WellboreSection.to(wellboreSection),
  );

const syncProjectWellboreSectionsWithLatest = (projectId, wellboreDetailId) =>
  Agent.post(`/project/${projectId}/wellbore/section/sync/${wellboreDetailId}`);

const syncAndCreateDraftProjectWellboreSectionsWithLatest = (projectId) =>
  Agent.post(`/project/${projectId}/wellbore/section/sync/create`);

const updateProjectWellboreSection = (
  projectId,
  wellboreSectionId,
  wellboreDetailId,
  wellboreSection,
) =>
  Agent.put(
    `/project/${projectId}/wellbore/section/${wellboreSectionId}/detail/${wellboreDetailId}`,
    wellboreSectionMappers.WellboreSection.to(wellboreSection),
  );

const deleteProjectWellboreSection = (
  projectId,
  wellboreSectionId,
  wellboreDetailId,
) =>
  Agent.delete(
    `/project/${projectId}/wellbore/section/${wellboreSectionId}/detail/${wellboreDetailId}`,
  );

const createProjectWellboreSectionNipple = (
  projectId,
  wellboreSectionId,
  wellboreSectionNipple,
) =>
  Agent.post(
    `/project/${projectId}/wellbore/section/${wellboreSectionId}/nipple`,
    wellboreSectionMappers.WellboreSectionNipple.to(wellboreSectionNipple),
  );

const updateProjectWellboreSectionNipple = (
  projectId,
  wellboreSectionId,
  wellboreDetailId,
  wellboreSectionNippleId,
  wellboreSectionNipple,
) =>
  Agent.put(
    `/project/${projectId}/wellbore/section/${wellboreSectionId}/detail/${wellboreDetailId}/nipple/${wellboreSectionNippleId}`,
    wellboreSectionMappers.WellboreSectionNipple.to(wellboreSectionNipple),
  );

const deleteProjectWellboreSectionNipple = (
  projectId,
  wellboreSectionId,
  wellboreDetailId,
  wellboreSectionNippleId,
) =>
  Agent.delete(
    `/project/${projectId}/wellbore/section/${wellboreSectionId}/detail/${wellboreDetailId}/nipple/${wellboreSectionNippleId}`,
  );

const projectWellboreSectionService = {
  getProjectWellboreSection,
  getProjectWellboreSections,
  updateProjectWellboreSection,
  createProjectWellboreSection,
  deleteProjectWellboreSection,
  getDefaultProjectWellboreSection,
  createProjectWellboreSectionNipple,
  updateProjectWellboreSectionNipple,
  deleteProjectWellboreSectionNipple,
  syncAndCreateDraftProjectWellboreSectionsWithLatest,
  syncProjectWellboreSectionsWithLatest,
};

export default projectWellboreSectionService;
