import * as yup from 'yup';
import moment from 'moment';

import {
  IntegrityForm,
  RULE_TYPES_ENUM,
  INTEGRITY_TYPE_ENUM,
} from './integrityManagement.constants';

export const IntegrityRulesYupValidator = [
  // validate information
  yup.object().shape({
    [IntegrityForm.TITLE]: yup.string().required('Rule Title is required'), // always required

    // validate integrity type sel.
    [IntegrityForm.INTEGRITY_TYPE]: yup
      .number()
      .required('Integrity Type is required'), // Ensure INTEGRITY_TYPE exists

    // if it is a rule validate that it is selected as well
    [IntegrityForm.RULE_TYPE]: yup
      .string()
      .nullable() // Allow the field to be nullable
      .when(IntegrityForm.INTEGRITY_TYPE, {
        is: (integrityType) =>
          integrityType === Number(INTEGRITY_TYPE_ENUM.RULE), // Validate RULE_TYPE only when INTEGRITY_TYPE is 'RULE'
        then: yup
          .string()
          .required('Rule Type is required')
          .test(
            'not-null',
            'Rule Type must not be null or empty',
            (value) => value != null && value.trim() !== '',
          ), // Check for non-null/empty
        otherwise: yup.string().notRequired(),
      }),

    // validate picklist
    [IntegrityForm.PICKLIST_OPTIONS]: yup
      .array()
      .when([IntegrityForm.RULE_TYPE, IntegrityForm.INTEGRITY_TYPE], {
        is: (ruleType, integrityType) =>
          Number(ruleType) === Number(RULE_TYPES_ENUM.RULE_PICKLIST) &&
          Number(integrityType) === Number(INTEGRITY_TYPE_ENUM.RULE),
        then: yup
          .array()
          .of(
            yup.object().shape({
              title: yup.string().required('Picklist Title is required'),
              score: yup
                .number()
                .required('Picklist Score is required')
                .min(0, 'Score must be at least 0')
                .max(10, 'Score must not exceed 10'),
            }),
          )
          .min(1, 'At least one valid Picklist Option is required')
          .test(
            'non-empty-object',
            'Each Picklist Option must have a title and score',
            (options) =>
              options.every(
                (option) => option.title && option.score !== undefined,
              ),
          ),
        otherwise: yup.array().notRequired(), // Skip validation if conditions aren't met
      }),

    // validate datetime
    [IntegrityForm.VALUE]: yup
      .string()
      .nullable() // Allow the field to be nullable
      .when(
        [
          IntegrityForm.RULE_TYPE,
          IntegrityForm.INTEGRITY_TYPE,
          IntegrityForm.DEFAULT_VALUE_CHECKED,
        ],
        {
          is: (ruleType, integrityType, defaultValueChecked) =>
            Number(ruleType) === Number(RULE_TYPES_ENUM.RULE_TIME) &&
            Number(integrityType) === Number(INTEGRITY_TYPE_ENUM.RULE) &&
            defaultValueChecked,
          then: yup
            .string()
            .required('Date and time are required')
            .test('valid-date', 'Invalid date format', (value) => {
              return moment(value, 'YYYY/MM/DD HH:mm', true).isValid();
            }),
          otherwise: yup.string().notRequired(),
        },
      ),

    [IntegrityForm.VALUE]: yup
      .object()
      .nullable() // Allow the field to be nullable
      .when(
        [
          IntegrityForm.RULE_TYPE,
          IntegrityForm.INTEGRITY_TYPE,
          IntegrityForm.DEFAULT_VALUE_CHECKED,
        ],
        {
          is: (ruleType, integrityType, defaultValueChecked) =>
            Number(ruleType) === Number(RULE_TYPES_ENUM.RULE_GEOGRAPHY) &&
            Number(integrityType) === Number(INTEGRITY_TYPE_ENUM.RULE) &&
            defaultValueChecked,
          then: yup
            .object()
            .shape({
              [IntegrityForm.LATITUDE]: yup
                .string()
                .required('Latitude is required'),
              [IntegrityForm.LONGITUDE]: yup
                .string()
                .required('Longitude is required'),
            })
            .required(''),
          otherwise: yup.object().notRequired(),
        },
      ),
  }),

  // validate location
  yup.object().shape({
    [IntegrityForm.PARENT_ID]: yup.string().required('Location is required'),
  }),

  // validate weight
  yup.object().shape({
    [IntegrityForm.WEIGHT]: yup
      .number()
      .positive('Weight must be a positive number')
      .required('Weight is required'),
  }),
];

export const IntegrityCategoriesYupValidator = [
  // validate information
  yup.object().shape({
    [IntegrityForm.TITLE]: yup.string().required('Title is required'),
  }),
  // validate location
  yup.object().shape({
    [IntegrityForm.PARENT_ID]: yup.string().required('Location is required'),
  }),
  // validate weight
  yup.object().shape({
    // we need to check is all in the same category weighting 100 % this will include new category
    [IntegrityForm.RELATED_INTEGRITIES]: yup
      .mixed()
      .test('total-weight', 'Total weight needs to be exactly 100%', (list) => {
        // Sum weights in the Immutable.js List
        const totalWeight = list?.reduce(
          (sum, item) => sum + (item.weight || 0),
          0,
        );
        return Math.abs(totalWeight - 100) < 0.000001;
      }),
  }),
];
