import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import { Box } from '@material-ui/core';
import useTheme from '@material-ui/styles/useTheme';
import withStyles from '@material-ui/styles/withStyles';
import { useCallback, useEffect, useState, memo, useMemo } from 'react';

import WellboreTrajectoryScene, {
  Materials,
  TrajectoryCylinderPrefix,
} from 'app/components/WellboreTrajectory/WellboreTrajectoryScene';

import { QuantityShape } from 'utils/app.util';
import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import { useThreeJsManager } from 'app/threeJs/ThreeJsManagerProvider';
import WellboreTrajectoryLegends from 'app/components/WellboreTrajectory/WellboreTrajectoryLegends';

const WellboreTrajectory = ({
  classes,
  sceneKey,
  hideAxisLabels,
  hideLegends,
  targetDepth,
  currentDepth,
  hideGrid = false,
  disableOrbitControls = false,
  trajectory = EMPTY_MAP,
  wellboreDetail = EMPTY_MAP,
}) => {
  const theme = useTheme();
  const [domElement, setDomElement] = useState();
  const threeJsManager = useThreeJsManager();

  const maxTVD = trajectory.get('verticalDepth');
  const trajectoryPoints = trajectory.get('trajectoryPoints', EMPTY_LIST);
  const maxNorthEast = trajectory.get('maximumNorthEast');

  const rootRef = useCallback((node) => {
    if (node) {
      setDomElement(node);
    }
  }, []);

  const dhsvDepth = wellboreDetail.get('downHoleSafetyValveDepth');
  const maximumWorkingDepth = wellboreDetail.get('maximumWorkingDepth');

  const depthsOfInterest = useMemo(
    () => [
      {
        depth: dhsvDepth?.value,
        name: 'DHSV',
        unit: dhsvDepth?.unit,
        material: Materials.dhsv,
        color: theme.altus.components.ContextualizedWell.trajectory.dhsv,
      },
      {
        depth: targetDepth?.value,
        name: 'Target depth',
        unit: targetDepth?.unit,
        material: Materials.targetDepth,
        color: theme.altus.components.ContextualizedWell.trajectory.targetDepth,
      },
      {
        depth: maximumWorkingDepth?.value,
        name: 'Max working depth',
        unit: maximumWorkingDepth?.unit,
        material: Materials.maximumWorkingDepth,
        color:
          theme.altus.components.ContextualizedWell.trajectory
            .maximumWorkingDepth,
      },
    ],
    [dhsvDepth, maximumWorkingDepth, targetDepth, theme],
  );

  const trajectoryScene = useMemo(() => {
    // The scene gets created only when we get the trajectory
    if (!domElement || !maxNorthEast || !trajectoryPoints.size || !maxTVD) {
      return;
    }

    return WellboreTrajectoryScene({
      theme,
      domElement,
      hideAxisLabels,
      depthsOfInterest,
      trajectoryPoints,
      maxTVD: maxTVD.value,
      maxNorthEast: maxNorthEast.value,
      hideGrid,
      disableOrbitControls,
    });
  }, [
    theme,
    maxTVD,
    hideGrid,
    domElement,
    maxNorthEast,
    hideAxisLabels,
    depthsOfInterest,
    trajectoryPoints,
    disableOrbitControls,
  ]);

  useEffect(() => {
    if (!trajectoryScene || !threeJsManager) return;

    const { addScene, disposeScene } = threeJsManager;

    disposeScene(sceneKey);
    addScene(sceneKey, trajectoryScene);

    // The scene will be disposed whenever this component gets unmounted.
    // For example, if an operation gets "filtered away", then this will be unmounted and the effect will cleanup the scene resources.
    return () => disposeScene(sceneKey);
  }, [threeJsManager, sceneKey, trajectoryScene]);

  useEffect(() => {
    // Then, whenever we get a new currentDepth, we just re-paint the necessary cylinders, but no need to recreate the scene.
    if (!threeJsManager) return;

    const { updateScene } = threeJsManager;

    const trajectoryColorUpdater = (sceneInfo) => {
      const { scene } = sceneInfo;

      scene.traverse((cylinder) => {
        // Each cylinder has a name property set with a prefix. We can use that prefix to get all of the trajectory cylinders in the scene.
        if (!cylinder.name.startsWith(TrajectoryCylinderPrefix)) return;

        // We also store what's the "measuredDepth" that the cylinder is representing. Based on that and the current depth, we choose
        // which color it should use.
        cylinder.material =
          cylinder.data.measuredDepth <= currentDepth?.value
            ? Materials.currentDepth
            : Materials.base;
      });
    };

    updateScene(sceneKey, trajectoryColorUpdater);
  }, [threeJsManager, trajectoryScene, sceneKey, currentDepth]);

  const legends = useMemo(
    () => [
      {
        depth: currentDepth?.value,
        name: 'Current depth',
        unit: currentDepth?.unit,
        color: theme.altus.components.ContextualizedWell.trajectory.depth,
      },
      ...depthsOfInterest,
    ],
    [currentDepth, depthsOfInterest, theme],
  );

  return (
    <Box
      ref={rootRef}
      className={classes.root}
      onClick={(event) => {
        // Do not propagate click events that happen on the scene (like when clicking to orbit around)
        if (!disableOrbitControls) {
          event.stopPropagation();
        }
      }}
    >
      {!hideLegends && (
        <Box className={classes.legend}>
          <WellboreTrajectoryLegends legends={legends} />
        </Box>
      )}
    </Box>
  );
};

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'inline-block',
    position: 'relative',
  },
  legend: {
    position: 'absolute',
    left: theme.spacing(1),
    bottom: theme.spacing(1),
  },
});

WellboreTrajectory.propTypes = {
  sceneKey: PropTypes.number,
  targetDepth: QuantityShape,
  currentDepth: QuantityShape,
  hideLegends: PropTypes.bool,
  trajectory: PropTypes.instanceOf(Iterable),
  wellboreDetail: PropTypes.instanceOf(Iterable),
};

export default compose(memo, withStyles(styles))(WellboreTrajectory);
