import { Grid } from '@material-ui/core';
import ReadOnlyField from 'app/components/ReadOnlyField';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const CableServiceMaintenanceDrawerComponent = ({
  assetHistory,
  classes,
  keyEvent,
}) => {
  const lengthQuantity = assetHistory.getIn([keyEvent, 'cableLengthQuantity']);
  const ODQuantity = assetHistory.getIn([keyEvent, 'maxODQuantity']);
  return (
    <>
      <Grid item xs={6}>
        <Grid className={classes.marginLabel}>
          <CustomReadOnlyField
            margin="none"
            label="Cable Length"
            className={classes?.marginLabel}
          >
            {lengthQuantity?.get('roundedValue')} {lengthQuantity?.get('unit')}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid className={classes.marginLabel}>
          <CustomReadOnlyField
            margin="none"
            label="Maximum OD"
            className={classes?.marginLabel}
          >
            {ODQuantity?.get('roundedValue')} {ODQuantity?.get('unit')}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
    </>
  );
};

export default CableServiceMaintenanceDrawerComponent;
