import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import { useEffect, useMemo, useRef } from 'react';

import {
  firstColumnWidth,
  secondColumnLeft,
  secondColumnWidth,
  getDefaultYAxisOptions,
} from 'features/projects/dashboard/components/dashboards/logging/helpers/logging.highcharts.theme';

import LoggingDashboard from 'features/projects/dashboard/components/dashboards/logging/common/LoggingDashboard';
import loggingDashboardHighchartsTheme from 'features/projects/dashboard/components/dashboards/logging/helpers/logging.highcharts.theme';
import { createNewEventWithOffset } from 'features/projects/dashboard/components/dashboards/logging/helpers/common';
import { useTheme } from '@material-ui/core/styles';

const CCLCurveName = 'CCL';

const curveOptions = [
  {
    offset: 0,
    width: `${firstColumnWidth}%`,
  },
  {
    offset: 40,
    width: `${firstColumnWidth}%`,
  },
  {
    offset: 0,
    left: `${firstColumnWidth + 1}%`,
    width: `${secondColumnWidth - secondColumnLeft - 2}%`,
  },
  {
    offset: 0,
    left: `${secondColumnLeft}%`,
    width: `${secondColumnWidth}%`,
  },
];

// Since we might have duplicated series, we need to differentiate between the series using the index
const generateYAxisId = (curve, index) => `${curve.get('id')}-${index}`;

const CorrelationDashboard = ({
  data,
  curves,
  measuredDepth,
  onSetExtremes,
  depthUnit,
  registerDataPointsHandler,
}) => {
  const chartComponent = useRef(null);
  const theme = useTheme();

  const CCLCurve = useMemo(
    () => curves.find((curve) => curve.get('name') === CCLCurveName),
    [curves],
  );

  const chartOptions = useMemo(() => {
    const allCurves = curves
      .update((curves) => (CCLCurve ? curves.push(CCLCurve) : curves)) // duplicate CCL curve if exists
      .sortBy((curve) => curve.get('name') === CCLCurveName);

    return Highcharts.merge(false, loggingDashboardHighchartsTheme, {
      series: allCurves.toArray().map((curve, index) => ({
        id: curve.get('id'),
        name: curve.get('caption'),
        caption: curve.get('caption'),
        type: curve.get('type'),
        color: curve.get('color'),
        unit: curve.get('unit'),
        depthUnit,
        // When using dual or multiple y axes, this number defines which yAxis the particular series is connected to.
        yAxis: generateYAxisId(curve, index),
      })),
      xAxis: [
        {
          id: 0,
          events: {
            setExtremes: (event) => {
              const newEvent = createNewEventWithOffset(event, curves);
              onSetExtremes(newEvent.min, newEvent.max);
            },
          },
        },
        {
          id: 1,
          linkedTo: 0,
          opposite: true,
        },
      ],
      yAxis: allCurves.toArray().map((curve, index) => ({
        ...getDefaultYAxisOptions(curve, index),
        ...curveOptions[index],
        id: generateYAxisId(curve, index),
      })),
    });
  }, [curves, CCLCurve, onSetExtremes, depthUnit]);

  useEffect(() => {
    const chart = chartComponent.current.chart;

    if (chart.yAxis[2]) {
      const CCLmin = CCLCurve.get('minValue') ?? 0;
      const CCLmax = CCLCurve.get('maxValue') ?? 0;

      chart.yAxis[2].update(
        {
          endOnTick: false,
          title: {
            y: -5,
          },
          labels: {
            enabled: false,
          },
          gridLineDashStyle: 'longdash',
          plotBands: [
            {
              from: CCLmin,
              to: CCLmax,
              thickness: '100%',
              color: theme.palette.secondary.darkGrey,
            },
          ],
          tickPositions: [CCLmin, CCLmax],
        },
        false, // do not redraw yet
      );
    }

    chart.redraw();
  }, [curves, CCLCurve, theme]);

  useEffect(() => {
    const chart = chartComponent.current.chart;

    if (!measuredDepth) return;

    chart.xAxis[0].update({
      softMax: measuredDepth,
    });
  }, [measuredDepth]);

  return (
    <LoggingDashboard
      data={data}
      curves={curves}
      chart={chartComponent}
      options={chartOptions}
      registerDataPointsHandler={registerDataPointsHandler}
    />
  );
};

CorrelationDashboard.propTypes = {
  onSetExtremes: PropTypes.func.isRequired,
  registerDataPointsHandler: PropTypes.func.isRequired,
};

export default CorrelationDashboard;
