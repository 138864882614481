import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EMPTY_LIST } from 'app/app.constants';
import { getActionDataStateFromState } from 'app/app.selectors';
import { requestDashboards } from 'features/projects/dashboard/dashboard.actions';
import { DASHBOARDS_ACTIONS } from 'features/projects/dashboard/dashboard.constants';
import { getDashboardsFromState } from 'features/projects/dashboard/dashboard.selectors';

const useDashboards = () => {
  const dispatch = useDispatch();

  const dispatchRequestDashboards = useCallback(
    () => dispatch(requestDashboards()),
    [dispatch],
  );

  const projectDashboards = useSelector(getDashboardsFromState);

  const dataState = useSelector((state) =>
    getActionDataStateFromState(state, DASHBOARDS_ACTIONS.REQUEST_DASHBOARDS),
  );

  return {
    dataState,
    dashboards: projectDashboards ?? EMPTY_LIST,
    requestDashboards: dispatchRequestDashboards,
  };
};

export default useDashboards;
