import { Grid } from '@material-ui/core';

import makeStyles from '@material-ui/styles/makeStyles';

import EditFluidForm from 'app/components/FluidsFormSection/FluidEditor/EditFluidForm';
import CreateFluidForm from 'app/components/FluidsFormSection/FluidEditor/CreateFluidForm';
import FluidEditorHeader from 'app/components/FluidsFormSection/FluidEditor/FluidEditorHeader';

const FluidEditor = ({
  disabled,
  isCreateMode,
  fluid,
  toggleCreateMode,
  fluidId,
  defaultFluid,
  updateFluid,
  createFluid,
}) => {
  const classes = useStyles();

  if (!isCreateMode && !fluidId) {
    return (
      <FluidEditorHeader
        displayNoItemsMessage
        isCreateMode={isCreateMode}
        noItemsMessage="No fluid selected — select a fluid to the left or click «Add» to add one"
      />
    );
  }

  return (
    <>
      <FluidEditorHeader displayNoItemsMessage={false} />
      <Grid container className={classes.root}>
        {isCreateMode && (
          <CreateFluidForm
            onCancel={toggleCreateMode}
            createFluid={createFluid}
            defaultFluid={defaultFluid}
          />
        )}
        {!isCreateMode && (
          <EditFluidForm
            disabled={disabled}
            isCreateMode={isCreateMode}
            fluid={fluid}
            toggleCreateMode={toggleCreateMode}
            updateFluid={updateFluid}
          />
        )}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    background: theme.altus.components.SortableListRow.background,
  },
}));

export default FluidEditor;
