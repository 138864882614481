import PropTypes from 'prop-types';
import { cloneElement, PureComponent, Children } from 'react';
import MoreVert from '@material-ui/icons/MoreVert';
import { Menu, IconButton } from '@material-ui/core';

import { invokeIfFunction } from 'utils/app.util';

class DropdownMenu extends PureComponent {
  state = { anchorEl: null };

  toggleMenu = () => {
    this.setState(({ open }) => ({ open: !open }));
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { children, Trigger, onClick } = this.props;

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <>
        {cloneElement(Trigger, {
          onClick: (event) => {
            this.handleClick(event);
            invokeIfFunction(onClick, event);
          },
        })}
        <Menu
          PaperProps={{
            style: {
              maxHeight: '50%',
            },
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
        >
          {Children.toArray(children).map((child) => {
            const { onClick } = child.props;

            return cloneElement(child, {
              onClick: () => {
                invokeIfFunction(onClick);
                this.handleClose();
              },
            });
          })}
        </Menu>
      </>
    );
  }
}

DropdownMenu.propTypes = {
  Trigger: PropTypes.element,
};

DropdownMenu.defaultProps = {
  Trigger: (
    <IconButton>
      <MoreVert fontSize="small" />
    </IconButton>
  ),
};

export default DropdownMenu;
