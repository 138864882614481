import { Field } from 'formik';
import { useMemo, useCallback, useState } from 'react';
import { fromJS } from 'immutable';

import { Grid, IconButton, Menu, MenuItem } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import MoreVert from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import { useModal } from 'altus-modal';
import { Table } from 'altus-ui-components';

import { WORK_ITEM_STATUS } from 'app/app.constants';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import TableRowAutocompleteTextFieldFormik from 'app/components/form/formik/TableRowAutocompleteTextFieldFormik';
import { TASK_URLS } from 'features/projects/activities/activities.constants';
import CreateAssetHistoryModalContainer from 'features/equipment/assets/components/CreateAssetHistoryModalContainer';
import {
  ASSET_HISTORY_EQUIPMENT_TYPE,
  ASSET_HISTORY_TEST_TYPES,
  EquipmentType,
  MODAL,
} from 'features/equipment/equipment.constants';

const defaultInitialState = {
  pageSize: 50,
};

const AddedSurfaceEquipmentInExecuteTable = ({
  task,
  tools,
  classes,
  selectedSurfaceEquipment,
  onSubmitSurfaceEquipment,
  setSelectedSurfaceEquipment,
  initialState = defaultInitialState,
  surfaceEquipmentCategories,
  ...rest
}) => {
  const taskStatus = task?.get('status');
  const [isOpen, toggleModal] = useModal(MODAL.ADD_ASSET_HISTORY_SURFACE);
  const [serialNumber, setSerialNumber] = useState();
  const [surfaceId, setSurfaceId] = useState();
  const openModal = (event) => {
    event?.preventDefault();
    toggleModal();
  };

  const DropdownMenu = ({ externalToolId, serialNumber }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const showOptions = externalToolId && serialNumber;
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const complaintMenuItem = () => {
      return (
        <MenuItem
          href={TASK_URLS.ADD_COMPLAINT_URL}
          onClick={() => {
            handleClose();
            window.open(
              TASK_URLS.ADD_COMPLAINT_URL,
              '_blank',
              'noopener,noreferrer',
            );
          }}
        >
          <OpenInNewIcon style={{ paddingRight: '5px' }} />
          Add Complaint
        </MenuItem>
      );
    };

    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        {!showOptions ? (
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            {complaintMenuItem()}
          </Menu>
        ) : (
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setSerialNumber(serialNumber);
                setSurfaceId(externalToolId);
                handleClose();
                openModal();
              }}
            >
              {<PlaylistAddIcon className={classes.iconPadding} />} Add Event to
              Asset
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                window.open(
                  `/surface/${externalToolId}/assethistory/${serialNumber}`,
                  '_blank',
                  'noopener,noreferrer',
                );
              }}
            >
              {<OpenInNewIcon className={classes.iconPadding} />}
              Open Asset History
            </MenuItem>
            {complaintMenuItem()}
          </Menu>
        )}
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        xs: 2,
        Header: 'Item no.',
        accessor: (tool) => tool.get('m3ItemNumber'),
      },
      {
        xs: 3,
        id: 'serialNo',
        Header: 'Serial No.',
        accessor: (tool) => tool.get('serialNo'),
        Cell: ({ value }) => {
          if (taskStatus >= WORK_ITEM_STATUS.COMPLETED) {
            return value;
          }

          return (
            <Field
              type="string"
              name="serialNo"
              placeholder={'Serial no.'}
              component={TableRowAutocompleteTextFieldFormik}
              keepChangeAfterOnBlur={true}
            />
          );
        },
      },
      {
        xs: 2,
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
      {
        xs: 2,
        Header: 'Surface Eq. Group',
        accessor: (tool) => tool.getIn(['equipmentGroup', 'name']),
      },
      {
        xs: 2,
        Header: 'Weight (kg)',
        accessor: (tool) =>
          tool.get('weight')
            ? tool.get('weight').toFixed(2)
            : tool.get('weight_kg').toFixed(2),
      },
      {
        xs: 2,
        Header: 'Height (mm)',
        accessor: (tool) =>
          tool.get('height')
            ? tool.get('height').toFixed(2)
            : tool.get('height_mm').toFixed(2),
      },
      {
        xs: 2,
        Header: 'Length (mm)',
        accessor: (tool) =>
          tool.get('length')
            ? tool.get('length').toFixed(2)
            : tool.get('length_mm').toFixed(2),
      },
      {
        xs: 2,
        Header: 'Width (mm)',
        accessor: (tool) =>
          tool.get('width')
            ? tool.get('width').toFixed(2)
            : tool.get('width_mm').toFixed(2),
      },
      {
        xs: 2,
        id: 'action',
        Cell: ({ row }) => {
          const surface = row.original;
          const externalToolId = surface.get('equipmentId');
          const serialNumber = surface.get('serialNo');

          return serialNumber ? (
            <Grid>
              <DropdownMenu
                externalToolId={externalToolId}
                serialNumber={serialNumber}
              />
            </Grid>
          ) : null;
        },
      },
    ],
    [taskStatus],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <EditableTableRowFormik
        {...props}
        onSubmit={onSubmitSurfaceEquipment}
        timeout={0}
      />
    ),
    [onSubmitSurfaceEquipment],
  );

  return (
    <>
      <Table
        stickyHeader
        usePagination
        items={fromJS(selectedSurfaceEquipment)}
        columns={columns}
        useGlobalFilter={false}
        initialState={initialState}
        TableRowComponent={renderTableRowComponent}
        {...rest}
      />
      <CreateAssetHistoryModalContainer
        toggleModal={openModal}
        isOpen={isOpen}
        serialNumber={serialNumber}
        externalToolId={surfaceId}
        equipmentType={EquipmentType.SURFACE}
        getAssetAfterRefresh={false}
        surfaceEquipmentCategories={surfaceEquipmentCategories}
        testTypesItems={ASSET_HISTORY_TEST_TYPES.filter(
          (item) =>
            item.equipmentType === ASSET_HISTORY_EQUIPMENT_TYPE.SURFACE ||
            item.equipmentType === ASSET_HISTORY_EQUIPMENT_TYPE.ALL,
        )}
      />
    </>
  );
};

const styles = (theme) => ({
  iconPadding: {
    paddingRight: theme.spacing(0.5),
  },
});

export default withStyles(styles)(AddedSurfaceEquipmentInExecuteTable);
