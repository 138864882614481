import Agent from 'infrastructure/agent';
import { saveFile } from 'utils/app.util';

export const getAllDocuments = (facilityId) =>
  Agent.get(`/facility/${facilityId}/files`);

export const deleteFile = (facilityId, fileId) =>
  Agent.delete(`/facility/${facilityId}/files/${fileId}`);

export const downloadFile = (facilityId, fileId) => {
  return Agent.file
    .get(`/facility/${facilityId}/files/${fileId}/download`)
    .then(([file, filename]) => saveFile(file, filename));
};

export const uploadFile = (facilityId, file, meta) =>
  Agent.file.post(`/facility/${facilityId}/files`, file, meta);

export const updateFile = (facilityId, fileId, file) =>
  Agent.put(`/facility/${facilityId}/files/${fileId}`, file);

const projectFileService = {
  downloadFile,
  uploadFile,
  deleteFile,
  getAllDocuments,
  updateFile,
};

export default projectFileService;
