import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  LogLevel,
  HubConnectionState,
  HubConnectionBuilder,
} from '@microsoft/signalr';

import {
  addNotification,
  NOTIFICATION_VARIANTS,
} from 'altus-redux-middlewares';

import { FailedDataState } from 'altus-datastate';

import authSingleton from 'services/auth.service';
import config from 'infrastructure/config';
import { invokeIfFunction } from 'utils/app.util';

const useHubConnection = (url, setDataState) => {
  const [connectionState, setConnectionState] = useState(
    HubConnectionState.Connecting,
  );

  const [connection] = useState(
    new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: async () => {
          const msalInstance = await authSingleton.getMsalInstance();
          const silentRequest = {
            scopes: [config.scope],
          };

          const token = await msalInstance.acquireTokenSilent(silentRequest);
          return token.accessToken;
        },
      })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build(),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    const handleConnectionStateUpdate = (callback) => {
      if (isMounted) {
        setConnectionState(connection?.state);
        invokeIfFunction(callback);
      }
    };

    connection.onreconnecting(() =>
      handleConnectionStateUpdate(() =>
        dispatch(
          addNotification({
            message: 'Connection lost. Trying to reconnect...',
            variant: NOTIFICATION_VARIANTS.INFO,
          }),
        ),
      ),
    );

    connection.onreconnected(() =>
      handleConnectionStateUpdate(() =>
        dispatch(
          addNotification({
            message: 'Reconnected successfully!',
            variant: NOTIFICATION_VARIANTS.SUCCESS,
          }),
        ),
      ),
    );

    connection.onclose(() =>
      handleConnectionStateUpdate(() =>
        dispatch(
          addNotification({
            message: 'Connection stopped',
            variant: NOTIFICATION_VARIANTS.ERROR,
          }),
        ),
      ),
    );

    connection
      .start()
      .then(handleConnectionStateUpdate)
      .catch(() => invokeIfFunction(setDataState, FailedDataState));

    return () => {
      connection.stop();
      isMounted = false;
    };
  }, [connection, setDataState, dispatch]);

  return [connection, connectionState];
};

export default useHubConnection;
