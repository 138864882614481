import { connect } from 'react-redux';
import toJSComponent from 'with-immutable-props-to-js';
import withStyles from '@material-ui/styles/withStyles';
import { DialogActions, Button } from '@material-ui/core';
import { reduxForm, formValueSelector } from 'redux-form/immutable';

import LoadingButton from 'app/components/LoadingButton';
import ModalContainer from 'app/components/Modal/ModalContainer';
import { MEASUREMENT_PREFERENCES_FORM } from 'app/app.constants';
import MeasurementPreferencesForm from 'features/projects/details/components/MeasurementPreferencesForm';

const styles = () => ({});

const UnitsModalContainer = ({
  valid,
  title,
  onEnter,
  modalId,
  classes,
  options,
  submitting,
  handleSubmit,
  descriptions,
  system: selectedSystem,
}) => (
  <ModalContainer
    height="auto"
    title={title}
    onEnter={onEnter}
    modalId={modalId}
    classes={{
      content: classes.content,
    }}
    Actions={({ toggleModal }) => (
      <DialogActions>
        <Button size="small" color="default" onClick={toggleModal}>
          Cancel
        </Button>
        <LoadingButton
          size="small"
          type="submit"
          color="primary"
          disabled={!valid}
          variant="contained"
          onClick={handleSubmit}
          submitting={submitting}
          form={MEASUREMENT_PREFERENCES_FORM.ID}
        >
          Save and close
        </LoadingButton>
      </DialogActions>
    )}
  >
    <MeasurementPreferencesForm
      name="system"
      options={options}
      descriptions={descriptions}
      selectedSystem={selectedSystem}
    />
  </ModalContainer>
);

UnitsModalContainer.defaultProps = {
  options: [],
};

const mapStateToProps = (state) => {
  const selector = formValueSelector(MEASUREMENT_PREFERENCES_FORM.ID);

  return {
    system: selector(state, 'system'),
  };
};

export default connect(
  mapStateToProps,
  null,
)(
  toJSComponent(
    reduxForm({
      form: MEASUREMENT_PREFERENCES_FORM.ID,
    })(withStyles(styles)(UnitsModalContainer)),
  ),
);
