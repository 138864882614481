import { compose } from 'redux';
import { isImmutable, fromJS } from 'immutable';
import { Chip } from '@material-ui/core';
import { useCallback, useMemo, useRef } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import { useModal } from 'altus-modal';
import { MultiSelectModal } from 'altus-ui-components';

import { EMPTY_LIST } from 'app/app.constants';
import TextFieldV2 from 'app/components/form/TextFieldV2';

const modalId = 'DEPARTMENT_SELECT_MODAL_ID';

const validate = () => true;

const getSubmitLabel = (selectedItems) =>
  selectedItems.size ? `Save (${selectedItems.size})` : 'Save';

const getDepartmentName = (department) => department.get('name');

const DepartmentSelectField = ({
  label,
  input,
  classes,
  disabled,
  departments,
}) => {
  const { onChange, value = EMPTY_LIST, name } = input;

  const [isOpen, toggleModal] = useModal(modalId);
  const departmentsRef = useRef(
    isImmutable(departments) ? departments : fromJS(departments),
  );

  const renderValue = useCallback(() => {
    return value
      .sortBy((department) => department.get('name'))
      .map((department) => (
        <Chip
          className={classes.chip}
          key={department.get('id')}
          label={department.get('name')}
        />
      ));
  }, [value, classes]);

  const initialSelectedItems = useMemo(() => {
    return value
      .map((department) => department.get('id'))
      .map((departmentId) =>
        departmentsRef.current.get(departmentId.toString()),
      )
      .toSet();
  }, [value]);

  const onSubmit = useCallback(
    (selectedDepartments) => {
      onChange(selectedDepartments);
      toggleModal();
    },
    [onChange, toggleModal],
  );

  return (
    <>
      <TextFieldV2
        select
        name={name}
        label={label}
        disabled={disabled}
        value={value.toArray()}
        SelectProps={{
          open: false,
          renderValue,
          multiple: true,
          onOpen: toggleModal,
        }}
      >
        {EMPTY_LIST.toArray()}
      </TextFieldV2>
      <MultiSelectModal
        open={isOpen}
        onSubmit={onSubmit}
        items={departmentsRef.current}
        validate={validate}
        onClose={toggleModal}
        title="Select departments"
        getName={getDepartmentName}
        getSubmitLabel={getSubmitLabel}
        initialSelectedItems={initialSelectedItems}
      />
    </>
  );
};

const styles = (theme) => ({
  chip: {
    height: 27,
    marginLeft: 0,
    margin: theme.spacing(0.5),
  },
});

export default compose(withStyles(styles))(DepartmentSelectField);
