import { NOTIFICATION_VARIANTS } from 'altus-redux-middlewares';

import { ACTIONS } from 'features/wells/elements/wellboreElement.constants';
import wellboreService from 'services/wellbore.service';

export const requestWellboreRevisionForWellbore =
  (fieldId, wellboreId) => (dispatch) => {
    const payload = wellboreService.getWellboreRevisionWithElements(
      fieldId,
      wellboreId,
    );

    dispatch({
      payload,
      type: ACTIONS.REQUEST_GET_WELLBORE_ELEMENTS,
    });

    return payload;
  };

export const requestWellboreRevisionFromWellcom =
  (fieldId, wellboreId) => (dispatch) => {
    const payload = wellboreService.getWellboreRevisionWithElementsFromWellcom(
      fieldId,
      wellboreId,
    );

    dispatch({
      payload,
      type: ACTIONS.REQUEST_GET_WELLBORE_ELEMENTS_FROM_WELLCOM,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]:
          'Getting revision and elements from Wellcom API...',
        [NOTIFICATION_VARIANTS.ERROR]: 'Failed to get revision elements.',
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'Rrevision elements retrieved successfully.',
      },
    });

    return payload;
  };
