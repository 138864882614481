import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import { Field } from 'redux-form/immutable';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import {
  CONVERT_SYSTEMS,
  CONVERT_MEASURES,
  UNITS_BY_CATEGORY,
  CUSTOM_UNIT_SYSTEM,
  CONVERT_MEASURES_NAMES,
  MEASUREMENT_PREFERENCES_FORM,
} from 'app/app.constants';

import { getUnitAlias } from 'utils/conversion.util';
import RadioGroupField from 'app/components/form/RadioGroupField';

const styles = () => ({
  topContainer: {
    paddingBottom: 25,
    borderBottom: '1px solid #616161',
  },
  bottomContainer: {
    paddingTop: 25,
  },
  bottomContainerDisabled: {
    opacity: 0.5,
  },
});

const MeasurementPreferencesForm = ({
  name,
  classes,
  options,
  namePrefix,
  descriptions,
  selectedSystem,
}) => {
  return (
    <form id={MEASUREMENT_PREFERENCES_FORM.FORM_ID}>
      <Grid container justifyContent="center">
        <Grid
          container
          item
          justifyContent="center"
          className={classes.topContainer}
        >
          <Grid container xs={12} item>
            <Grid container>
              <Typography>Measurement Units</Typography>
            </Grid>
            <Grid container>
              <Typography variant="caption" color="textSecondary">
                {descriptions[selectedSystem]}
              </Typography>
            </Grid>
            <Field name={name} options={options} component={RadioGroupField} />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={classNames(classes.bottomContainer, {
            [classes.bottomContainerDisabled]:
              selectedSystem !== CUSTOM_UNIT_SYSTEM,
          })}
        >
          <Grid container item>
            <Grid item xs={4} />
            {Object.values(CONVERT_SYSTEMS).map((system) => (
              <Grid key={system} item xs={4}>
                <Typography>{capitalize(system)}</Typography>
              </Grid>
            ))}
          </Grid>
          {Object.values(CONVERT_MEASURES).map((measure) => {
            return (
              <Grid
                item
                container
                key={measure}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4}>
                  <Typography>{CONVERT_MEASURES_NAMES[measure]}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field
                    name={`${namePrefix}${measure}`}
                    component={RadioGroupField}
                    options={Object.entries(UNITS_BY_CATEGORY[measure]).map(
                      ([system, unit]) => ({
                        disabled: selectedSystem !== CUSTOM_UNIT_SYSTEM,
                        value: system,
                        fullWidth: true,
                        label: getUnitAlias(unit),
                      }),
                    )}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </form>
  );
};

MeasurementPreferencesForm.defaultProps = {
  namePrefix: '',
};

export default withStyles(styles)(MeasurementPreferencesForm);
