import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import { List, fromJS, Map, OrderedMap } from 'immutable';

import mappers from 'mappers';
import { AUTHORIZATION_ACTIONS } from 'authorization/authorization.constants';
import { APP_ACTIONS, EMPTY_LIST, EMPTY_MAP, THEMES } from 'app/app.constants';

export const initialState = fromJS({
  appLoaded: false,
  applicationFailureReason: null,
  currentUser: mappers.User.initial,
  notification: EMPTY_MAP,
  pendingAction: Map({
    confirmation: Map({
      dataState: EMPTY_LIST,
    }),
  }),
  availableDepartments: EMPTY_LIST,
  availableOrganizations: EMPTY_LIST,
  availableServices: Map(),
  availableFileCategories: EMPTY_MAP,
  applicationHeader: Map({
    title: '',
    subTitle: '',
    uncheckedEvents: 0,
  }),
  theme: THEMES.DARK,
  featureFlags: EMPTY_MAP,
  users: EMPTY_MAP,
  currentClientOrganizationId: '',
});

const commonReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case APP_ACTIONS.SET_THEME: {
      return state.set('theme', action.theme);
    }

    case APP_ACTIONS.CONFIRMATION_DIALOG_PENDING: {
      return state.set('pendingAction', action.pendingAction);
    }
    case APP_ACTIONS.APP_LOAD: {
      return state.set('appLoaded', true);
    }
    case APP_ACTIONS.LOGIN: {
      const { error } = action;
      if (error) return state.set('appLoaded', true);

      return state.set('appLoaded', true);
    }

    case APP_ACTIONS.GET_CURRENT_USER: {
      if (action.error) return state;

      return state
        .set(
          'currentUser',
          action.payload
            ? mappers.User.from(action.payload)
            : mappers.User.initial,
        )
        .set(
          'currentUserTest',
          action.payload
            ? mappers.User.from(action.payload)
            : mappers.User.initial,
        );
    }

    case APP_ACTIONS.GET_MAIN_VENDOR_USERS: {
      if (action.error) return state;

      return state.set(
        'users',
        OrderedMap(
          action.payload.map((user) => [
            user.userId.toString(),
            mappers.User.from(user),
          ]),
        ),
      );
    }

    case AUTHORIZATION_ACTIONS.GET_USERS_ASSIGNED_TO_SYSTEM_ROLE: {
      if (action.error) return state;
      return state.update('users', (users) =>
        users.merge(
          OrderedMap(
            action.payload.map((user) => [
              user.userId.toString(),
              mappers.User.from(user),
            ]),
          ),
        ),
      );
    }

    case AUTHORIZATION_ACTIONS.REMOVE_SYSTEM_ROLE_FROM_USER: {
      if (action.error) return state;

      const { userId, systemRoleId } = action;

      return state.deleteIn([
        'users',
        userId.toString(),
        'roles',
        systemRoleId,
      ]);
    }

    case AUTHORIZATION_ACTIONS.ADD_SYSTEM_ROLE_TO_USER: {
      if (action.error) return state;

      const { userId, systemRoleId } = action;

      return state.updateIn(['users', userId.toString(), 'roles'], (roles) =>
        roles.add(systemRoleId),
      );
    }

    case APP_ACTIONS.UPDATE_USER_MEASUREMENT_PREFERENCES: {
      if (action.error) return state;

      const [units, user, userPreferences] = action.payload;

      return state
        .set('currentUser', mappers.User.from(user))
        .setIn(
          ['currentUser', 'units'],
          mappers.MeasurementPreferences.from(units),
        )
        .setIn(
          ['currentUser', 'preferences'],
          mappers.UserPreference.from(userPreferences),
        );
    }

    case APP_ACTIONS.GET_USER_PREFERENCES: {
      if (action.error) return state;

      const { measurementPreference, userPreference } = action.payload;

      return state
        .setIn(
          ['currentUser', 'units'],
          measurementPreference
            ? mappers.MeasurementPreferences.from(measurementPreference)
            : undefined,
        )
        .setIn(
          ['currentUser', 'preferences'],
          userPreference
            ? mappers.UserPreference.from(userPreference)
            : undefined,
        );
    }

    case APP_ACTIONS.EVENTS_MARK_AS_SEEN:
      return state.setIn(['applicationHeader', 'uncheckedEvents'], 0);

    case APP_ACTIONS.ADD_NOTIFICATION:
      if (
        (!action.force && isNil(state.getIn(['currentUser', 'id']))) ||
        !action.notification.message
      )
        return state;
      return state.set('notification', Map(action.notification));
    case APP_ACTIONS.REMOVE_NOTIFICATION:
      return state.set('notification', initialState.get('notification'));

    case APP_ACTIONS.GET_ALL_SERVICES: {
      if (action.error) return state;

      return state.set(
        'availableServices',
        OrderedMap(
          action.payload.map((service) => [
            service.serviceId.toString(),
            mappers.Service.from(service),
          ]),
        ),
      );
    }
    case APP_ACTIONS.GET_ALL_DEPARTMENTS: {
      if (action.error) return state;

      return state.set(
        'availableDepartments',
        OrderedMap(
          action.payload.map((department) => [
            department.departmentId.toString(),
            mappers.Department.from(department),
          ]),
        ),
      );
    }

    case APP_ACTIONS.GET_ALL_ORGANIZATIONS: {
      if (action.error) return state;

      return state.set(
        'availableOrganizations',
        List(action.payload).map(mappers.Organization.from),
      );
    }

    case APP_ACTIONS.SET_HEADER_VALUES: {
      const { logoNavigation, title, subTitle } = action;

      return state
        .updateIn(['applicationHeader', 'title'], (t) =>
          isString(title) ? title : t,
        )
        .updateIn(['applicationHeader', 'subTitle'], (st) =>
          isString(subTitle) ? subTitle : st,
        )
        .updateIn(['applicationHeader', 'logoNavigation'], (ln) =>
          isString(logoNavigation) ? logoNavigation : ln,
        );
    }
    case APP_ACTIONS.GET_ALL_FILE_CATEGORIES: {
      if (action.error) return state;

      const { payload } = action;

      return state.set(
        'availableFileCategories',
        OrderedMap(
          payload.map((category) => [
            category.id,
            mappers.FileCategory.from(category),
          ]),
        ),
      );
    }

    case APP_ACTIONS.GET_APPLICATION_FEATURES: {
      if (action.error) return state;

      return state.set('featureFlags', Map(action.payload));
    }

    case APP_ACTIONS.SET_APPLICATION_FAILURE: {
      const { payload: error } = action;

      return state.set('applicationFailureReason', error.message);
    }

    case APP_ACTIONS.SET_CURRENT_CLIENT_ORGANIZATION: {
      const { currentOrganizationId } = action;

      return state.set(
        'currentClientOrganizationId',
        currentOrganizationId.toString(),
      );
    }

    case APP_ACTIONS.STORE_CHANGE_LOG: {
      if (action.error) return state;
      const { payload } = action;

      return state.set('changeLog', payload);
    }

    default:
      return state;
  }
};

export default commonReducer;
