export const DETAILS_ACTIONS = {
  DELETE_PROJECT: 'DELETE_PROJECT',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  SIMULATE_DELETE: 'SIMULATE_DELETE',
  CONFIRM_DELETE_PROJECT: 'CONFIRM_DELETE_PROJECT',
  PROJECT_DETAILS_PAGE_LOADED: 'PROJECT_DETAILS_PAGE_LOADED',
  PROJECT_DETAILS_PAGE_UNLOADED: 'PROJECT_DETAILS_PAGE_UNLOADED',
  UPDATE_PROJECT_MEASUREMENT_PREFERENCES:
    'UPDATE_PROJECT_MEASUREMENT_PREFERENCES',
  UPDATE_EXTERNAL_ID: 'UPDATE_EXTERNAL_ID',
  ARCHIVE_SINGLE_PROJECT: 'ARCHIVE_SINGLE_PROJECT',
};

export const MAX_YEAR_DROPDOWN_RANGE = 3;
export const MIN_YEAR_DROPDOWN_RANGE = -2;

export const SERVICE_SELECT_MODAL_ID = 'SERVICE_SELECT_MODAL_ID';
export const EDIT_PROJECT_UNITS_MODAL_ID = 'EDIT_PROJECT_UNITS_MODAL_ID';

export const EDIT_PROJECT_FORM = {
  FORM_ID: 'editProject',
  ID: 'id',
  DEFAULT_UNIT: 'unit',
  REFERENCE: 'referenceNumber',
  WELLBORE: 'wellboreName',
  FIELD: 'fieldName',
  FACILITY: 'facilityName',
  DESCRIPTION: 'description',
  DEPARTMENTS: 'departments',
  FACILITY_NAME: 'facilityName',
  ESTIMATED_END: 'estimatedEnd',
  ESTIMATED_START: 'estimatedStart',
  OPERATOR: 'operator',
  ORGANIZATION_ID: 'organizationId',
  EXTERNAL_ID: 'externalId',
  EXTERNAL_NAME: 'externalName',
  TASK_SERVICES: 'taskServices',
  DISPLAY_REFERENCE_NUMBER: 'displayReferenceNumber',
  OBJECTIVE_DESCRIPTION: 'objective',
  BACKGROUND: 'background',
  TITLE: 'title',
  INTERNAL_INFORMATION: 'internalInformation',
};
