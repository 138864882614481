import * as THREE from 'three';
import * as d3Scale from 'd3-scale';
import * as MuiColors from '@material-ui/core/colors';
import { BHATool } from 'app/components/WellGraphAndGuages/RenderCableAndBHAScene';
import { convertMetric } from 'app/components/WellboreTrajectoryDetailed3DView/Utils';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

const DefaultCylinderRadius = 30;
const DefaultFontSize = 70;
const DefaultTickInterval = 500;

// Object instantiation is expensive, so reuse vectors, matrices and materials as much as posible.
const objectUp = new THREE.Object3D().up;
const reusableVector = new THREE.Vector3();
const reusableMatrix = new THREE.Matrix4();

const orientationMultiplier = new THREE.Matrix4().set(
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  -1,
  0,
  0,
  0,
  0,
  0,
  1,
);

const createCylinder = ({
  v1,
  v2,
  length,
  material,
  radius = DefaultCylinderRadius,
}) => {
  const direction = reusableVector.subVectors(v1, v2);

  reusableMatrix.lookAt(v1, v2, objectUp);
  reusableMatrix.multiply(orientationMultiplier);

  const geometry = new THREE.CylinderBufferGeometry(
    radius,
    radius,
    length ?? direction.length(),
    16,
    1,
  );

  const mesh = new THREE.Mesh(geometry, material);

  mesh.applyMatrix4(reusableMatrix);
  mesh.position.set((v1.x + v2.x) / 2, (v1.y + v2.y) / 2, (v1.z + v2.z) / 2);

  return mesh;
};

const createGrid = ({
  max,
  min = 0,
  axisColor = MuiColors.grey[700],
  gridColor = MuiColors.grey[800],
  tickInterval = DefaultTickInterval,
}) => {
  const xTicks = getTicks({ min, max: max + tickInterval, tickInterval });
  const numTicks = (xTicks.length - 1) * 2;
  const length = xTicks[xTicks.length - 1] * 2;

  const grid = new THREE.GridHelper(length, numTicks, axisColor, gridColor);

  return { grid, gridLength: length, numTicks, tickInterval };
};

const getTicks = ({ max, min = 0, tickInterval = DefaultTickInterval }) => {
  const scale = d3Scale.scaleLinear().domain([min, max]);
  const ticks = scale.ticks(Math.ceil(max / tickInterval));

  return ticks;
};

const createTextSprite = ({ text, fontFamily, fontSize = DefaultFontSize }) => {
  const scale = 60;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const texture = new THREE.Texture(canvas);

  context.font = `${fontSize}px ${fontFamily}`;
  context.fillStyle = MuiColors.blueGrey[100];
  context.fillText(text, 0, fontSize);

  texture.minFilter = THREE.LinearFilter;
  texture.needsUpdate = true;

  const sprite = new THREE.Sprite(new THREE.SpriteMaterial({ map: texture }));
  sprite.scale.set(scale * 10, scale * 5, 1.0);

  return sprite;
};

const createSemiCylinder = ({
  v1,
  v2,
  length,
  material,
  radius = DefaultCylinderRadius,
  thetaStart,
}) => {
  material.side = THREE.DoubleSide;
  const direction = reusableVector.subVectors(v1, v2);

  reusableMatrix.lookAt(v1, v2, objectUp);
  reusableMatrix.multiply(orientationMultiplier);

  const geometry = new THREE.CylinderBufferGeometry(
    radius,
    radius,
    length ?? direction.length(),
    16,
    1,
    true,
    thetaStart,
    Math.PI,
  );

  material.needsUpdate = true;
  const mesh = new THREE.Mesh(geometry, material);

  mesh.applyMatrix4(reusableMatrix);
  mesh.position.set((v1.x + v2.x) / 2, (v1.y + v2.y) / 2, (v1.z + v2.z) / 2);
  mesh.data = {
    startVector: v1,
    endVector: v2,
  };

  return mesh;
};

const createNipple = ({ v1, v2, length, material, radius, outerRadius }) => {
  material.side = THREE.DoubleSide;
  const direction = reusableVector.subVectors(v1, v2);

  reusableMatrix.lookAt(v1, v2, objectUp);
  reusableMatrix.multiply(orientationMultiplier);

  const arcShape = new THREE.Shape()
    .moveTo(0, 0)
    .absarc(0, 0, outerRadius, 0, Math.PI * 2);

  const holePath = new THREE.Path()
    .moveTo(0, 0)
    .absarc(0, 0, radius, 0, Math.PI * 2);

  arcShape.holes.push(holePath);

  const geometry = new THREE.ExtrudeBufferGeometry(arcShape, {
    depth: length ?? direction.length(),
    bevelEnabled: true,
    bevelSegments: 2,
    steps: 2,
    bevelSize: 1,
    bevelThickness: 1,
  });

  material.needsUpdate = true;
  const mesh = new THREE.Mesh(geometry, material);

  mesh.applyMatrix4(reusableMatrix);
  mesh.rotateX(Math.PI / 2);
  mesh.position.set((v1.x + v2.x) / 2, (v1.y + v2.y) / 2, (v1.z + v2.z) / 2);

  return mesh;
};

const createToolString = ({ v1, v2, material, toolsByToolstringToolId }) => {
  const tools = new THREE.Group();
  const toolObjects = new THREE.Group();
  const defaultToolObjects = new THREE.Group();
  const loader = new OBJLoader();
  let posX = 0;
  Object.values(toolsByToolstringToolId).forEach((tool, index) => {
    if (tool.sequence === index + 1) {
      posX = (
        posX -
        convertMetric(tool.length.roundedValue, tool.length.unit) / 2
      ).toFixed(2);
      if (tool.tool3DObjectUrl) {
        loader.load(
          tool.tool3DObjectUrl,
          (toolObj) => {
            toolObj.traverse((child) => {
              if (child.isMesh) {
                child.material = material;
              }
            });
            const boundingBox = new THREE.Box3().setFromObject(toolObj);

            // Compute the center of the bounding box
            const center = new THREE.Vector3();
            boundingBox.getCenter(center);

            // Translate all children (meshes) to re-center the group
            toolObj.children.forEach((child) => {
              child.position.sub(center);
            });
            toolObj.scale.set(0.001, 0.001, 0.001);
            toolObj.key = `${index}-${tool.sequence}`;
            toolObj.name = `${BHATool}-${posX}`;
            toolObj.position.set(
              convertMetric(tool.length.roundedValue, tool.length.unit) / 2,
              0,
              0,
            );
            toolObjects.add(toolObj);
          },
          undefined,
          (error) => {
            console.error('An error occurred:', error);
          },
        );
      } else {
        const geometry = new THREE.CylinderBufferGeometry(
          (convertMetric(
            tool.outerDiameter.roundedValue,
            tool.outerDiameter.unit,
          ) /
            2) *
            100,
          (convertMetric(
            tool.outerDiameter.roundedValue,
            tool.outerDiameter.unit,
          ) /
            2) *
            100,
          convertMetric(tool.length.roundedValue, tool.length.unit) * 100,
          16,
          1,
        );
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(0, posX, 0);
        mesh.name = `${BHATool}-${posX}`;
        defaultToolObjects.add(mesh);
      }
    }
  });
  reusableMatrix.lookAt(v1, v2, objectUp);
  reusableMatrix.multiply(orientationMultiplier);
  toolObjects.scale.set(100, 100, 100);
  toolObjects.rotateZ(Math.PI / 2);
  tools.add(defaultToolObjects);
  tools.add(toolObjects);
  tools.applyMatrix4(reusableMatrix);
  tools.position.set((v1.x + v2.x) / 2, (v1.y + v2.y) / 2, (v1.z + v2.z) / 2);

  return tools;
};

const ThreeJsUtils = {
  objectUp,
  getTicks,
  createGrid,
  createCylinder,
  reusableMatrix,
  reusableVector,
  createTextSprite,
  createSemiCylinder,
  createNipple,
  createToolString,

  DefaultFontSize,
  DefaultTickInterval,
  DefaultCylinderRadius,
};

export default ThreeJsUtils;
