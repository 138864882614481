import { initialize } from 'redux-form/immutable';

import {
  MODAL,
  INCIDENTS_ACTIONS,
  EDIT_INCIDENT_FORM,
  CREATE_INCIDENT_FORM,
} from 'features/projects/incidents/incidents.constants';

import { toggleModal } from 'app/app.actions';
import incidentsService from 'services/incidents.service';
import { getFormValuesFromState } from 'app/app.selectors';
import incidentsMapper from 'features/projects/incidents/incidents.mappers';

export const selectIncident = (index) => (dispatch) =>
  dispatch(toggleModal({ modalId: MODAL.EDIT_INCIDENT, activeIndex: index }));

export const onEditIncidentModalLoaded = (incident) => (dispatch) =>
  dispatch(initialize(EDIT_INCIDENT_FORM.ID, incident));

export const onCreateIncidentModalLoaded = () => (dispatch) =>
  dispatch(
    initialize(CREATE_INCIDENT_FORM.ID, incidentsMapper.Incident.initial()),
  );

export const createIncident = (projectId) => (dispatch, getState) => {
  const incident = getFormValuesFromState(
    getState(),
    CREATE_INCIDENT_FORM.ID,
  ).toJS();

  const payload = incidentsService
    .createIncident(projectId, incident)
    .then((response) => {
      dispatch(
        toggleModal({
          modalId: MODAL.CREATE_INCIDENT,
        }),
      );
      return response;
    });

  dispatch({
    type: INCIDENTS_ACTIONS.CREATE_INCIDENT,
    payload,
  });

  return payload;
};

export const updateIncident = (projectId) => (dispatch, getState) => {
  const incident = getFormValuesFromState(
    getState(),
    EDIT_INCIDENT_FORM.ID,
  ).toJS();

  const payload = incidentsService
    .updateIncident(projectId, incident)
    .then((response) => {
      dispatch(toggleModal({ modalId: MODAL.EDIT_INCIDENTS }));
      return response;
    });

  dispatch({
    incidentId: incident.id,
    type: INCIDENTS_ACTIONS.UPDATE_INCIDENT,
    payload,
  });

  return payload;
};

export const onLoad = (projectId) => (dispatch) =>
  dispatch({
    type: INCIDENTS_ACTIONS.PROJECT_INCIDENTS_PAGE_LOADED,
    payload: () => incidentsService.allIncidents(projectId),
  });

export const onUnload = () => (dispatch) =>
  dispatch({ type: INCIDENTS_ACTIONS.PROJECT_INCIDENTS_PAGE_UNLOADED });

export const deleteIncident = (projectId, incidentId) => (dispatch) => {
  dispatch({
    type: INCIDENTS_ACTIONS.DELETE_INCIDENT,
    payload: () => incidentsService.deleteIncident(projectId, incidentId),
    id: incidentId,
    confirmationDialog: {
      confirmButtonText: 'Delete',
      title: 'Delete Incident',
      description: 'Are you sure you want to delete this Incident?',
    },
  });
};
