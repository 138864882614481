import { all } from 'redux-saga/effects';

import toolSaga from 'features/projects/tool/tool.sagas';
import taskSaga from 'features/projects/tasks/task.sagas';
import activitySaga from 'features/projects/activities/activity.sagas';
import dashboardSaga from 'features/projects/dashboard/dashboard.sagas';
import wellboreSectionsSaga from 'features/wells/sections/wellboreSection.sagas';
import riskSaga from 'features/projects/risks.sagas';
import simulationSaga from 'features/projects/tasks/task/simulation/simulation.sagas';
import toolstringSaga from 'features/projects/tasks/task/toolstring/toolstring.sagas';
import projectWellboreDetailsSaga from 'features/projects/wellbore/details/projectWellboreDetails.sagas';
import simulationParametersSaga from 'features/projects/tasks/task/simulation/parameters/simulationParameter.sagas';
import projectSaga from 'features/projects/project.sagas';
import simulationDynamicToolParametersSaga from 'features/projects/tasks/task/simulation/dynamicToolParameters/simulationDynamicToolParameter.sagas';
import wellboreSagas from 'features/wells/wellbore.sagas';
import changeLogSaga from 'features/changelog/changelog-saga';
import projectMobilisationSaga from 'features/projects/mobilisation/projectMobilisation.sagas';

export default function* rootSaga() {
  yield all([
    toolSaga(),
    taskSaga(),
    activitySaga(),
    dashboardSaga(),
    toolstringSaga(),
    simulationSaga(),
    wellboreSectionsSaga(),
    simulationParametersSaga(),
    simulationDynamicToolParametersSaga(),
    projectWellboreDetailsSaga(),
    projectSaga(),
    riskSaga(),
    wellboreSagas(),
    changeLogSaga(),
    projectMobilisationSaga(),
  ]);
}
