import Agent from 'infrastructure/agent';

const searchOperations = (filter) => Agent.get('/operations/search', filter);

const searchOperationsLiveProject = (filter) =>
  Agent.get('/operations/search/liveproject', filter);

const searchOnlineOperations = (filter) =>
  Agent.get('/operations/search/online', filter);

const searchOnlineOperationsLite = (filter) =>
  Agent.get('/operations/search/online/lite', filter);

const getLiveOperationFields = (filters) =>
  Agent.get('/search/filter/valueset/operationField', filters);

const getLiveOperationFacilities = (filters) =>
  Agent.get('/search/filter/valueset/operationFacility', filters);

const getCheetahJobsByProject = (projectId) =>
  Agent.get(`/operations/byproject/${projectId}`);

const searchCheetahJobs = (query) =>
  Agent.get('/operations/cheetah/search', query);

const getCheetahJobsFilter = (query) =>
  Agent.get('/operations/cheetah/filter', query);

const updateCheetahJob = (cheetahJobId, cheetahJob) =>
  Agent.put(`/operations/cheetah/${cheetahJobId}`, cheetahJob);

const getCheetahJobCurves = (cheetahJobId) =>
  Agent.get(`/operations/cheetah/${cheetahJobId}/curves`);

const getAvailableProjectsForCheetahJobs = (filter, source) =>
  Agent.get(`/operations/cheetah/available-projects/${source}`, filter);

const getCheetahJobsProjectFilter = (source) =>
  Agent.get(`/operations/cheetah/available-projects/filter/${source}`);

const updateProjectStatus = (projectId, status) =>
  Agent.put(`/operations/${projectId}/status/${status}`);

const getOperationsFilter = (query) => Agent.get('/operations/filter', query);

const getOperationsFilterLiveProject = (query) =>
  Agent.get('/operations/filter/liveproject', query);

const operationService = {
  searchOperations,
  searchOperationsLiveProject,
  updateCheetahJob,
  searchCheetahJobs,
  updateProjectStatus,
  getOperationsFilter,
  getOperationsFilterLiveProject,
  getCheetahJobCurves,
  getCheetahJobsFilter,
  searchOnlineOperations,
  searchOnlineOperationsLite,
  getLiveOperationFields,
  getCheetahJobsByProject,
  getLiveOperationFacilities,
  getCheetahJobsProjectFilter,
  getAvailableProjectsForCheetahJobs,
};

export default operationService;
