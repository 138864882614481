import { Grid, Tooltip } from '@material-ui/core';
import ReadOnlyField from 'app/components/ReadOnlyField';
import {
  ASSET_HISTORY_TEST_TYPES_DICT,
  getEventKeyByValue,
} from 'features/equipment/equipment.constants';
import { toolAssetHistoryEventToString } from 'features/equipment/equipment.helpers';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const TestDetailComponent = ({
  assetHistory,
  classes,
  keyEvent,
  projectTitle,
  getTooltipTitle,
  handleResultIcon,
}) => {
  const currentEvent =
    keyEvent ?? getEventKeyByValue(assetHistory.get('event'));

  return (
    <>
      <Grid item xs={6}>
        <CustomReadOnlyField margin="none" label="Event">
          {toolAssetHistoryEventToString(assetHistory.get('event'))}
        </CustomReadOnlyField>
        <Grid className={classes.marginLabel}>
          <CustomReadOnlyField
            margin="none"
            label="Type of Test"
            className={classes.marginLabel}
          >
            {assetHistory.getIn([currentEvent, 'testType']) ? (
              ASSET_HISTORY_TEST_TYPES_DICT[
                assetHistory.getIn([currentEvent, 'testType'])
              ]
            ) : (
              <>-</>
            )}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <CustomReadOnlyField margin="none" label="Result">
          <Tooltip
            title={getTooltipTitle(
              assetHistory.getIn([currentEvent, 'result']),
            )}
          >
            <>
              {assetHistory.getIn([currentEvent, 'result']) ? (
                <img
                  src={handleResultIcon(
                    assetHistory.getIn([currentEvent, 'result']),
                  )}
                  alt="Status icon"
                  className={classes.iconStyle}
                />
              ) : null}
              {getTooltipTitle(assetHistory.getIn([currentEvent, 'result']))}
            </>
          </Tooltip>
        </CustomReadOnlyField>
        <Grid className={classes.marginLabel}>
          <CustomReadOnlyField margin="none" label="Project">
            {projectTitle}
          </CustomReadOnlyField>
        </Grid>
      </Grid>
    </>
  );
};

export default TestDetailComponent;
