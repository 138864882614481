import React, { useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';

import {
  ASSET_HISTORY_TEST_TYPES,
  ASSET_HISTORY_RESULTS,
} from 'features/equipment/equipment.constants';
import RedIcon from 'assets/Red.svg';
import GreenIcon from 'assets/Green.svg';
import OrangeIcon from 'assets/Orange.svg';
import { AssetTestType } from 'features/equipment/assets/components/CreateAssetHistoryModalContainer';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import { BopTestCustomComponent } from 'features/equipment/assets/components/BopTestCustomComponent';
import CableTorsionTestFormComponent from 'features/equipment/assets/components/equipmentTest/CableTorsionTestFormComponent';
import CableWrapStrandFormComponent from 'features/equipment/assets/components/equipmentTest/CableWrapStrandFormComponent';
import CableTortureStrandFormComponent from 'features/equipment/assets/components/equipmentTest/CableTortureStrandFormComponent';
import CableContinuityFormComponent from 'features/equipment/assets/components/equipmentTest/CableContinuityFormComponent';
import CableInsulationFormComponent from 'features/equipment/assets/components/equipmentTest/CableInsulationFormComponent';
import Sil2FormComponent from 'features/equipment/assets/components/equipmentTest/Sil2FormComponent';
import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';
import CableWrapBraidedMonoTestFormComponent from 'features/equipment/assets/components/equipmentTest/CableWrapBraidedMonoTestFormComponent';
import CableBreakTestFormComponent from 'features/equipment/assets/components/equipmentTest/CableBreakTestFormComponent';

const TestForm = ({
  testTypesItems = ASSET_HISTORY_TEST_TYPES.filter(
    (item) => item.showByDefault,
  ),
  defaultTestTypeValue,
  sil2TestBopItems,
  sil2TestBcsItems,
  sil2TestCabinItems,
  selectedCustomUnitsOfMeasure,
  unitsOfMeasurePreference,
  projectMeasurementSystem,
}) => {
  const getId = (item) => item.id;
  const getName = (item) => item.name;
  const resultIcons = {
    Pass: GreenIcon,
    Warning: OrangeIcon,
    Fail: RedIcon,
  };
  const { values, setFieldValue } = useFormikContext();
  const [strandInnerSections, setInnerStrandSections] = useState([]);
  const [cableWrapStrands, setCableWrapStrands] = useState([]);
  const [strandOuterSections, setOuterStrandSections] = useState([]);

  useEffect(() => {
    const combinedSections = [...strandInnerSections, ...strandOuterSections];
    setFieldValue('testStrands', combinedSections);
  }, [setFieldValue, strandInnerSections, strandOuterSections]);

  useEffect(() => {
    const combinedSections = [...cableWrapStrands];
    setFieldValue(AssetHistoryForm.CABLE_WRAP_TEST_STRAND, combinedSections);
  }, [setFieldValue, cableWrapStrands, strandOuterSections]);

  const selectedTestType = values[AssetHistoryForm.TYPE_OF_TEST];

  const testTypeComponents = React.useMemo(
    () => ({
      [AssetTestType.BOP_TEST]: {
        CustomComponent: BopTestCustomComponent,
      },
      [AssetTestType.CABLE_TORSION_TEST]: {
        CustomComponent: CableTorsionTestFormComponent,
      },
      [AssetTestType.CABLE_WRAP_TEST]: {
        CustomComponent: CableWrapStrandFormComponent,
      },
      [AssetTestType.CABLE_TORTURE_TEST]: {
        CustomComponent: CableTortureStrandFormComponent,
      },
      [AssetTestType.CABLE_CONTINUITY_TEST]: {
        CustomComponent: CableContinuityFormComponent,
      },
      [AssetTestType.CABLE_INSULATION_TEST]: {
        CustomComponent: CableInsulationFormComponent,
      },
      [AssetTestType.SIL2_TEST]: {
        CustomComponent: Sil2FormComponent,
      },
      [AssetTestType.CABLE_WRAP_BRAIDED_MONO_TEST]: {
        CustomComponent: CableWrapBraidedMonoTestFormComponent,
      },
      [AssetTestType.CABLE_BREAK_TEST]: {
        CustomComponent: CableBreakTestFormComponent,
      },
    }),
    [],
  );

  const SelectedComponent = testTypeComponents[selectedTestType] || {
    CustomComponent: () => null,
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid item>
              <SelectTextFieldFormik
                getItemName={getName}
                label="Result"
                getItemValue={getId}
                items={ASSET_HISTORY_RESULTS}
                name={AssetHistoryForm.RESULT}
                displayEmpty={false}
                iconsMapping={resultIcons}
                maxIconWidth={'0.8vw'}
                inputname={AssetHistoryForm.RESULT}
              />
              {defaultTestTypeValue ? null : (
                <SelectTextFieldFormik
                  getItemName={getName}
                  label="Type of Test"
                  getItemValue={getId}
                  items={testTypesItems}
                  name={AssetHistoryForm.TYPE_OF_TEST}
                  displayEmpty={false}
                  placeholder="Select"
                />
              )}
              <>
                <SelectedComponent.CustomComponent
                  sil2TestBopItems={sil2TestBopItems}
                  sil2TestBcsItems={sil2TestBcsItems}
                  sil2TestCabinItems={sil2TestCabinItems}
                  setInnerStrandSections={setInnerStrandSections}
                  strandInnerSections={strandInnerSections}
                  setOuterStrandSections={setOuterStrandSections}
                  strandOuterSections={strandOuterSections}
                  cableWrapStrands={cableWrapStrands}
                  setCableWrapStrands={setCableWrapStrands}
                  selectedCustomUnitsOfMeasure={selectedCustomUnitsOfMeasure}
                  unitsOfMeasurePreference={unitsOfMeasurePreference}
                  projectMeasurementSystem={projectMeasurementSystem}
                />
              </>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TestForm;
