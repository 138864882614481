import classNames from 'classnames';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = () => ({
  formControlLabelRoot: {
    flexGrow: 1,
    maxWidth: '100%',
    flexBasis: 0,
  },
});

const RadioGroupField = ({
  classes,
  options,
  input,
  label,
  variant = 'primary',
  size = 'medium',
  row = true, // Default to true for backward compatibility
}) => {
  const { onChange, value } = input;

  return (
    <FormControl fullWidth component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">
        <Typography>{label}</Typography>
      </FormLabel>
      <RadioGroup
        row={row}
        value={value}
        onChange={onChange}
        className={classes.group}
      >
        {options.map((option) => {
          const {
            name,
            value,
            label,
            onClick,
            fullWidth,
            disabled,
            className,
            frozen,
          } = option;

          return (
            <FormControlLabel
              key={value}
              value={value}
              label={label}
              disabled={disabled && !frozen}
              control={
                <Radio
                  name={name}
                  onClick={onClick}
                  color={variant}
                  size={size}
                  style={{
                    ...(frozen && {
                      backgroundColor: 'transparent',
                      cursor: 'default',
                      pointerEvents: 'none',
                    }),
                  }}
                />
              }
              classes={{
                root: classNames(className, {
                  [classes.formControlLabelRoot]: fullWidth,
                }),
              }}
              style={{
                ...(frozen && {
                  backgroundColor: 'transparent',
                  cursor: 'default',
                  pointerEvents: 'none',
                }),
              }}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

RadioGroupField.defaultProps = {
  options: [],
};

export default withStyles(styles)(RadioGroupField);
