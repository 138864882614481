import { Map } from 'immutable';

import { EMPTY_LIST } from 'app/app.constants';
import wellControlMappers from 'features/wellControlDrillActivity/wellControl.mapper';
import { WELL_CONTROL_ACTIONS } from 'features/wellControlDrillActivity/wellControl.constants';

const initialState = Map({
  categories: EMPTY_LIST,
  situations: EMPTY_LIST,
});

const wellControlReducer = (state = initialState, action = {}) => {
  const { type } = action;

  switch (type) {
    case WELL_CONTROL_ACTIONS.GET_ALL_CATEGORIES_AND_SITUATIONS: {
      if (action.error) return state;

      return state.set(
        'categories',
        action.payload.map((category) =>
          wellControlMappers.category.from(category),
        ),
      );
    }

    default:
      return state;
  }
};

export default wellControlReducer;
