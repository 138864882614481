import moment from 'moment';
import { compose } from 'redux';
import { useEffect, useState } from 'react';
import { lighten } from '@material-ui/core/styles';
import withStyles from '@material-ui/styles/withStyles';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Grid, Tabs, Tab, Divider, Typography } from '@material-ui/core';

import { useModal } from 'altus-modal';
import { BasePage } from 'altus-ui-components';
import { useHeader } from 'app/hooks/useHeader';
import { Button } from 'app/components/withTooltip';
import { getSummarizedDataStateFromState } from 'app/app.selectors';

import {
  onLoad,
  onUnload,
} from 'features/equipment/details/equipmentDetails.actions';

import {
  getToolFromState,
  getToolGroupsFromState,
  getToolSuppliersFromState,
} from 'features/equipment/equipment.selectors';

import {
  MODAL,
  EQUIPMENT_ACTIONS,
  EquipmentType,
} from 'features/equipment/equipment.constants';

import {
  EQUIPMENT_DETAILS_ACTIONS,
  EQUIPMENT_DETAILS_ACTIVE_TABS,
} from 'features/equipment/details/equipmentDetails.constants';

import { SystemPermission } from 'app/app.constants';
import { ThirdPartyLabel } from 'features/projects/tool/tool.constants';
import { getToolGroups } from 'features/equipment/equipment.actions';
import HasSystemPermission from 'app/components/HasSystemPermission';
import EquipmentAssetsContainer from '../assets/EquipmentAssetsContainer';
import M3ModalContainer from 'features/equipment/m3Components/M3ModalContainer';
import AddToolModalContainer from 'features/equipment/components/AddToolModalContainer';
import EquipmentDetailsSubHeader from 'features/equipment/components/EquipmentDetailsSubHeader';
import EditToolImageModal from 'features/equipment/editToolImage/EditToolImageModal';

const EquipmentDetailsContainer = ({
  equipmentId,
  classes,
  dataState,
  dispatchOnLoad,
  dispatchOnUnload,
}) => {
  const [activeTab, setActiveTab] = useState(
    EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS,
  );

  const headerSubtitleHandler = (activeTab) => {
    switch (activeTab) {
      case EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS:
        return 'Details';
      case EQUIPMENT_DETAILS_ACTIVE_TABS.ASSETS:
        return 'Assets';
      default:
        return 'Details';
    }
  };

  useHeader({
    subTitle: headerSubtitleHandler(activeTab),
    logoNavigation: '/',
  });

  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  const dispatch = useDispatch();

  const [editM3Tool, toggleEditM3Tool] = useModal(MODAL.EDIT_M3_TOOL);
  const [edit3rdPartyTool, toggleEdit3rdPartyTool] = useModal(
    MODAL.EDIT_3RDPARTY_TOOL,
  );
  const [editToolImage, toggleEditToolImage] = useModal(MODAL.EDIT_TOOL_IMAGE);

  const toolGroups = useSelector(getToolGroupsFromState);
  const toolSuppliers = useSelector(getToolSuppliersFromState);

  const displayDataText = (data) => {
    if (dataState.isLoading()) {
      return 'Loading...';
    }

    if (!data || data === undefined) {
      return 'No Data';
    } else {
      return data;
    }
  };

  const displayDateText = (date) => {
    if (dataState.isLoading()) {
      return 'Loading...';
    }

    if (!date || date === undefined) {
      return 'No Data';
    } else {
      const parsedDate = moment(date);
      const formattedDate = parsedDate.format('DD.MM.YYYY');
      return formattedDate;
    }
  };

  const tool = useSelector(getToolFromState);

  useEffect(() => {
    dispatchOnLoad(equipmentId);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload, equipmentId]);

  useEffect(() => {
    if (!tool.isEmpty()) {
      dispatch(getToolGroups(tool.get('toolCategoryId')));
    }
  }, [tool, dispatch]);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabs}
        className={classes.tabsContainer}
      >
        <Tab
          label="Details"
          value={EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS}
          className={classes.tab}
        />
        <Tab
          label="Assets"
          value={EQUIPMENT_DETAILS_ACTIVE_TABS.ASSETS}
          className={classes.tab}
        />
      </Tabs>
      <Divider className={classes.divider} />
      {activeTab === EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS && (
        <Grid item container xs className={classes.container}>
          <Grid item container className={classes.topContainer}>
            <EquipmentDetailsSubHeader
              equipment={tool}
              toggleModal={toggleEditToolImage}
            />
          </Grid>
          <Grid item container className={classes.bottomContainer}>
            {!tool.isEmpty() ? (
              <>
                <Grid item container className={classes.bottomText}>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Name:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(tool.get('name'))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Group:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(tool.get('toolGroupName'))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Outer diameter:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(
                          `${tool.getIn([
                            'outerDiameter',
                            'roundedValue',
                          ])} ${tool.getIn(['outerDiameter', 'unit'])}`,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Inner diameter:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(
                          `${tool.getIn([
                            'innerDiameter',
                            'roundedValue',
                          ])} ${tool.getIn(['innerDiameter', 'unit'])}`,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Length:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(
                          `${tool.getIn([
                            'length',
                            'roundedValue',
                          ])} ${tool.getIn(['length', 'unit'])}`,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Weight:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(
                          `${tool.getIn([
                            'weight',
                            'roundedValue',
                          ])} ${tool.getIn(['weight', 'unit'])}`,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Top Connector:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(
                          tool.get('topConnectorDescription') ?? 'No Data',
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Bottom Connector:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(
                          tool.get('bottomConnectorDescription') ?? 'No Data',
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Fishneck:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(
                          `${tool.getIn([
                            'fishNeck',
                            'roundedValue',
                          ])} ${tool.getIn(['fishNeck', 'unit'])}`,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Safe working load:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool.get('safeWorkingLoad')
                          ? displayDataText(
                              `${tool.getIn([
                                'safeWorkingLoad',
                                'roundedValue',
                              ])} ${tool.getIn(['safeWorkingLoad', 'unit'])}`,
                            )
                          : displayDataText(null)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Yield:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool.get('yieldValue')
                          ? displayDataText(
                              `${tool.getIn([
                                'yieldValue',
                                'roundedValue',
                              ])} ${tool.getIn(['yieldValue', 'unit'])}`,
                            )
                          : displayDataText(null)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Supplier:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(tool.get('supplier') ?? 'No Data')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        Supplier part number:
                      </Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(
                          tool.get('supplierPartNumber') ?? 'No Data',
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* TODO ADD WHEN EDIT TOOL IS AVAILABLE */}
                  {/* <Typography variant="h5">Last modification:</Typography> */}
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Description:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(tool.get('description') ?? 'No Data')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        Axial friction reduced to:
                      </Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool.get('axialFrictionReducedTo')
                          ? displayDataText(
                              `${tool.getIn([
                                'axialFrictionReducedTo',
                                'roundedValue',
                              ])} ${tool.getIn([
                                'axialFrictionReducedTo',
                                'unit',
                              ])}`,
                            )
                          : displayDataText(null)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Maximum OD:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool.get('maximumOD')
                          ? displayDataText(
                              `${tool.getIn([
                                'maximumOD',
                                'roundedValue',
                              ])} ${tool.getIn(['maximumOD', 'unit'])}`,
                            )
                          : displayDataText(null)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        Maximum standoff force:
                      </Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool.get('maximumStandoffForce')
                          ? displayDataText(
                              `${tool.getIn([
                                'maximumStandoffForce',
                                'roundedValue',
                              ])} ${tool.getIn([
                                'maximumStandoffForce',
                                'unit',
                              ])}`,
                            )
                          : displayDataText(null)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Minimum OD:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool.get('minimumOD')
                          ? displayDataText(
                              `${tool.getIn([
                                'minimumOD',
                                'roundedValue',
                              ])} ${tool.getIn(['minimumOD', 'unit'])}`,
                            )
                          : displayDataText(null)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        Minimum standoff force:
                      </Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool.get('minimumStandoffForce')
                          ? displayDataText(
                              `${tool.getIn([
                                'minimumStandoffForce',
                                'roundedValue',
                              ])} ${tool.getIn([
                                'minimumStandoffForce',
                                'unit',
                              ])}`,
                            )
                          : displayDataText(null)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Is knuckle joint:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool.get('isKnuckleJoint')
                          ? displayDataText(`${tool.get('isKnuckleJoint')}`)
                          : displayDataText(null)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        Weight above jarring point factor
                      </Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool.get('weightAboveJarringPointFactor')
                          ? displayDataText(
                              `${tool.getIn([
                                'weightAboveJarringPointFactor',
                                'value',
                              ])}`,
                            )
                          : displayDataText(null)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        Jar activation limit low:
                      </Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool.get('jarActivationLimitLow')
                          ? displayDataText(
                              `${tool.getIn([
                                'jarActivationLimitLow',
                                'roundedValue',
                              ])} ${tool.getIn([
                                'jarActivationLimitLow',
                                'unit',
                              ])}`,
                            )
                          : displayDataText(null)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        Jar activation limit high:
                      </Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool.get('jarActivationLimitHigh')
                          ? displayDataText(
                              `${tool.getIn([
                                'jarActivationLimitHigh',
                                'roundedValue',
                              ])} ${tool.getIn([
                                'jarActivationLimitHigh',
                                'unit',
                              ])}`,
                            )
                          : displayDataText(null)}
                      </Typography>
                    </Grid>
                  </Grid>
                  {tool.get('m3ItemNumber') !== ThirdPartyLabel && (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Is data verified:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            `${tool.get('isVerified') ? 'Yes' : 'No'}`,
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {tool.get('m3ItemNumber') !== ThirdPartyLabel &&
                    tool.get('isVerified') && (
                      <Grid
                        item
                        container
                        xs={12}
                        className={classes.bottomTextLine}
                      >
                        <Grid item container xs={6}>
                          <Typography variant="h5">Data verified:</Typography>
                        </Grid>
                        <Grid item container xs={6}>
                          <Typography variant="h5">
                            {tool.get('dataVerified')
                              ? displayDateText(`${tool.get('dataVerified')}`)
                              : displayDateText(null)}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
                {tool && tool.get('m3ItemNumber') === ThirdPartyLabel ? null : (
                  <Grid item container className={classes.bottomText}>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Description:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3Description') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Item number:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3ItemNumber') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Item group:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(tool.get('m3ITGR') ?? 'No Data')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Item type:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(tool.get('m3ItemType') ?? 'No Data')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Business area:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3BusinessArea') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Item status:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3ItemStatus') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          Item status description:
                        </Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3ItemStatusDescription') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Lot control method</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3LotControlMethod') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Measurement 1:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3Measurement1') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Measurement 2:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3Measurement2') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Measurement 3:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3Measurement3') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Entry date:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDateText(tool.get('m3EntryDate') ?? null)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Last edit date:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDateText(tool.get('m3ChangeDate') ?? null)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : null}
            {!tool.isEmpty() && tool.get('m3ItemNumber') === ThirdPartyLabel ? (
              <HasSystemPermission
                permissions={[SystemPermission.THIRD_PARTY_TAB_EDIT]}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.editBtn}
                  onClick={() => {
                    toggleEdit3rdPartyTool();
                  }}
                >
                  Edit tool
                </Button>
              </HasSystemPermission>
            ) : null}
            {!tool.isEmpty() && tool.get('m3ItemNumber') !== ThirdPartyLabel ? (
              <HasSystemPermission
                permissions={[SystemPermission.ITEM_TAB_EDIT]}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.editBtn}
                  onClick={() => {
                    toggleEditM3Tool();
                  }}
                >
                  Edit tool
                </Button>
              </HasSystemPermission>
            ) : null}
          </Grid>
          {!tool.isEmpty() ? (
            <>
              <M3ModalContainer
                edit={true}
                tool={tool}
                title="Edit tool"
                openModal={editM3Tool}
                toolGroups={toolGroups}
                suppliers={toolSuppliers}
                toolId={equipmentId}
                toggleModal={toggleEditM3Tool}
                existingTool={tool.toJS()}
                toolGroupId={tool.get('toolGroupId')}
              />
              <AddToolModalContainer
                edit={true}
                tool={tool}
                title="Edit tool"
                openModal={edit3rdPartyTool}
                toolGroups={toolGroups}
                suppliers={toolSuppliers}
                toolId={equipmentId}
                toggleModal={toggleEdit3rdPartyTool}
                existingTool={tool.toJS()}
                toolGroupId={tool.get('toolGroupId')}
              />
              <EditToolImageModal
                tool={tool}
                open={editToolImage}
                toggleModal={toggleEditToolImage}
              />
            </>
          ) : null}
        </Grid>
      )}
      {activeTab === EQUIPMENT_DETAILS_ACTIVE_TABS.ASSETS && (
        <EquipmentAssetsContainer
          equipmentId={equipmentId}
          equipmentType={EquipmentType.TOOL}
        />
      )}
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    EQUIPMENT_ACTIONS.SUPPLIERS_LOADED,
    EQUIPMENT_ACTIONS.TOOL_GROUPS_LOADED,
    EQUIPMENT_DETAILS_ACTIONS.PAGE_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoad,
  dispatchOnUnload: onUnload,
};

const styles = (theme) => ({
  basePageChildren: {
    flexDirection: 'column',
    padding: 0,
  },
  container: {
    flexDirection: 'column',
    overflow: 'auto',
    padding: theme.spacing(2.25),
  },
  topContainer: {
    flexDirection: 'row',
    height: '10%',
  },
  topText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
    maxHeight: '100%',
    paddingLeft: theme.spacing(3),
  },
  bottomContainer: {
    dispay: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '90%',
  },
  bottomText: {
    width: '48%',
    height: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    padding: theme.spacing(3),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  bottomTextLine: {
    height: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editBtn: {
    position: 'absolute',
    right: theme.spacing(5),
    bottom: theme.spacing(3),
    width: '200px',
    height: '50px',
  },
  tab: {
    backgroundColor: 'transparent !important',
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(EquipmentDetailsContainer);
