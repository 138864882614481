import { compose } from 'redux';
import { connect } from 'react-redux';
import { memo } from 'react';
import React from 'react';
import { ReceivedDataState, DataState } from 'altus-datastate';
import PropTypes from 'prop-types';

import { CircularProgress } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import ProjectsGanttChart from 'features/projects/projectsGanttChart/ProjectsGanttChart';
import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';

const ProjectsGanttChartContainer = ({ projects, dataState }) => {
  ProjectsGanttChartContainer.defaultProps = {
    dataState: ReceivedDataState,
  };

  ProjectsGanttChartContainer.propTypes = {
    children: PropTypes.node,
    dataState: PropTypes.instanceOf(DataState),
  };

  const projectsGroupedByField = projects
    .filter((project) => {
      const [startYear, startMonth, startDay] = project.get('estimatedStart');
      const [endYear, endMonth, endDay] = project.get('estimatedEnd');

      if (
        !startYear ||
        !startMonth ||
        !startDay ||
        !endYear ||
        !endMonth ||
        !endDay
      ) {
        return null;
      } else {
        return project;
      }
    })
    .groupBy((project) => project.get('externalFieldId'));

  const categories = Object.keys(projectsGroupedByField.toJS()).map(
    (fieldId) => {
      return projects
        .find(
          (project) => project.get('externalFieldId').toString() === fieldId,
        )
        .get('fieldName');
    },
  );

  return dataState?.isLoading() ? (
    <Grid
      container
      style={{ height: '80vh' }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
        <Typography variant="h4" style={{ marginTop: '20px' }}>
          Loading...
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <ProjectsGanttChart
      projects={projectsGroupedByField}
      categories={categories}
    />
  );
};

export default compose(
  connect((state) => ({
    dataState: getSummarizedDataStateFromState(
      state,
      PROJECTS_ACTIONS.RECEIVE_GANTT_PROJECTS,
      PROJECTS_ACTIONS.GANTT_PROJECTS,
    ),
  })),
  memo,
)(ProjectsGanttChartContainer);
