import { call, takeLatest, put } from 'redux-saga/effects';
import {
  APP_ACTIONS,
  APP_VERSION,
  APP_VERSION_STORAGE_KEY,
  NOTIFICATION_VARIANTS,
} from 'app/app.constants';
import { callAsync } from 'app/sagas/helperSagas';
import appService from 'services/app.service';
import { storeChangeLog } from 'app/app.actions';
import routePaths from 'app/routePaths';

function* logFetchChange() {
  const response = yield call(appService.getChangeLog);
  yield put(storeChangeLog(response.markdown));
}

function* newAppVersionNotification() {
  localStorage.setItem(APP_VERSION_STORAGE_KEY, APP_VERSION);
  yield put({
    type: APP_ACTIONS.SHOW_CHANGE_LOG_SEPARATE_TAB,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'The app version changed',
    },
    confirmationDialog: {
      confirmButtonText: 'View Changelog',
      title: "Discover What's New!",
      description:
        'Stay up-to-date with improvements and enhancements.\nThank you for choosing Digital Well Intervention!',
    },
  });
}

function* storeNewAppVersion() {
  window.open(routePaths.changelog, '_blank');
  yield;
}

export default function* rootCh() {
  yield takeLatest(
    APP_ACTIONS.INITIATE_FETCH_CHANGE_LOG,
    callAsync,
    logFetchChange,
  );

  yield takeLatest(
    APP_ACTIONS.NEW_APP_VERSION_NOTIFICATION,
    callAsync,
    newAppVersionNotification,
  );

  yield takeLatest(
    APP_ACTIONS.SHOW_CHANGE_LOG_SEPARATE_TAB,
    callAsync,
    storeNewAppVersion,
  );
}
