import { compose } from 'redux';
import { Set } from 'immutable';
import { useCallback } from 'react';
import { connect } from 'react-redux';

import { useRequest } from 'altus-hooks';
import { LoadingOverlay } from 'altus-ui-components';

import reportsService from 'services/reports.service';
import { downloadSimulationPdfReport } from 'features/projects/tasks/task/simulation/simulation.actions';
import SimulationExportModal from 'features/projects/tasks/task/simulation/components/SimulationExportModal';

const SimulationExportModalContainer = ({
  open,
  taskId,
  projectId,
  toggleModal,
  simulationId,
  dispatchDownloadSimulationPdfReport,
}) => {
  const getAvailableCharts = useCallback(
    () =>
      reportsService.getSimulationReportAvailableCharts(projectId).then(Set),
    [projectId],
  );

  const [availableCharts, dataState] = useRequest(getAvailableCharts);

  const onExportSimulation = useCallback(
    (exportSelection) => {
      dispatchDownloadSimulationPdfReport(
        taskId,
        projectId,
        simulationId,
        exportSelection.details.map((chart) => chart.chartType).toArray(),
        exportSelection.collapseAssemblies,
      );
      toggleModal();
    },
    [
      taskId,
      projectId,
      toggleModal,
      simulationId,
      dispatchDownloadSimulationPdfReport,
    ],
  );

  if (dataState.isLoading()) {
    return <LoadingOverlay dataState={dataState} />;
  }

  return (
    <SimulationExportModal
      open={open}
      onClose={toggleModal}
      key={availableCharts}
      onExport={onExportSimulation}
      availableCharts={availableCharts}
    />
  );
};

export default compose(
  connect(null, {
    dispatchDownloadSimulationPdfReport: downloadSimulationPdfReport,
  }),
)(SimulationExportModalContainer);
