import { compose } from 'redux';
import withTheme from '@material-ui/styles/withTheme';
import GoogleMapReact from 'google-map-react';

import { NORWAY_MAP_CENTER_COORDS } from 'app/app.constants';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { getFacilityLocation } from 'features/facilities/facilities.actions';
import FacilityMapLocation from './FacilityMapLocation';

export const GoogleMapFacilityOverview = ({
  theme,
  children,
  facilityId,
  ...rest
}) => {
  const dispatch = useDispatch();
  const [location, setLocation] = useState(null);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  useEffect(() => {
    dispatch(getFacilityLocation(facilityId)).then((payload) => {
      setLocation([payload.locationNorthSouth, payload.locationEastWest]);
      setLat(payload.locationNorthSouth);
      setLng(payload.locationEastWest);
    });
  }, [dispatch, facilityId]);

  return (
    <GoogleMapReact
      clusterRadius={1}
      defaultCenter={NORWAY_MAP_CENTER_COORDS}
      bootstrapURLKeys={{ key: 'AIzaSyDR7pPPfpL06KsgWs4B5JbAkqXGY4bqJXA' }}
      center={location}
      zoom={5}
      {...rest}
    >
      <FacilityMapLocation lat={lat} lng={lng} />
    </GoogleMapReact>
  );
};

export default compose(withTheme)(GoogleMapFacilityOverview);
