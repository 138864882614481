import { useCallback } from 'react';
import { Map, List } from 'immutable';

import { useRequest } from 'altus-hooks';

import projectDashboardService from 'services/projectDashboard.service';

const useProjectDashboardCheetahJobCurves = (
  projectId,
  projectDashboardId,
  cheetahJobId,
) => {
  const getProjectDashboardCheetahJobCurves = useCallback(() => {
    if (!cheetahJobId) return;

    return projectDashboardService
      .getProjectDashboardCheetahJobCurves(
        projectId,
        projectDashboardId,
        cheetahJobId,
      )
      .then((projectDashboardCheetahJobCurves) =>
        List(projectDashboardCheetahJobCurves).map(Map),
      );
  }, [cheetahJobId, projectId, projectDashboardId]);

  return useRequest(getProjectDashboardCheetahJobCurves);
};

export default useProjectDashboardCheetahJobCurves;
