import { PagedQueryParams } from 'app/app.constants';
import { useEffect, useMemo, useReducer } from 'react';
import { connect, useSelector } from 'react-redux';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { OPERATIONS_ACTIONS } from 'features/operations/operations.constants';
import LiveProjectsContainer from 'features/data-exchange/live-projects/LiveProjectsPaginated/components/LiveProjectsContainer';
import { searchOperations } from 'features/operations/operations.actions';
import { getOperationsPageParamsFromState } from 'features/operations/operations.selectors';
import {
  actionTypes,
  reducer,
  states,
} from 'features/data-exchange/live-projects/LiveProjectsPaginated/helpers/utils.reducer';
import {
  filterPlanAndExecute,
  filterReport,
  Filters,
  initialPage,
  pageSize,
} from 'features/data-exchange/live-projects/LiveProjectsPaginated/helpers/constants';

const LiveProjectsPaginatedContainerHoc = (filter) => {
  const LiveProjectsPaginated = ({ pageParams, dispatchOnLoad }) => {
    const [localState, localDispatch] = useReducer(reducer, states.INITIAL);

    const dataState = useSelector((state) =>
      getSummarizedDataStateFromState(
        state,
        OPERATIONS_ACTIONS.SEARCH_OPERATIONS,
      ),
    );

    const paginationState = useMemo(
      () => ({
        pageSize,
        pageIndex: (pageParams.get('currentPage') ?? initialPage) - 1,
      }),
      [pageParams],
    );

    const initialFilterValues = useMemo(
      () => ({
        ...filter,
        [Filters.FACILITY_ID]: '',
        [Filters.TEXT_SEARCH]: '',
      }),
      [],
    );

    const initialPagedSearchFilters = useMemo(
      () => ({
        [PagedQueryParams.PAGE]: paginationState.pageIndex + 1,
        [PagedQueryParams.PAGE_SIZE]: pageSize,
        ...initialFilterValues,
      }),
      [paginationState, initialFilterValues],
    );

    useEffect(() => {
      dispatchOnLoad(initialPagedSearchFilters);
      localDispatch({ type: actionTypes.API_STARTED });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (localState === states.API_IN_PROGRESS && dataState.received) {
        localDispatch({ type: actionTypes.API_FINISHED });
      }
    }, [dataState, localState, localDispatch]);

    if (localState !== states.FINAL) return null;
    return (
      <LiveProjectsContainer
        pageParams={pageParams}
        initialPagedSearchFilters={initialPagedSearchFilters}
        initialTableState={paginationState}
        initialFilterValues={initialFilterValues}
        filter={filter}
      />
    );
  };

  return LiveProjectsPaginated;
};

export const mapStateToPropsWrap = (state) => ({
  pageParams: getOperationsPageParamsFromState(state),
});

export const mapDispatchToPropsWrap = {
  dispatchOnLoad: searchOperations,
};

const mapProps = (comp) =>
  connect(mapStateToPropsWrap, mapDispatchToPropsWrap)(comp);

export const ArchivedLiveProjectsPaginatedContainer = mapProps(
  LiveProjectsPaginatedContainerHoc(filterReport),
);
export const AvailableLiveProjectsPaginatedContainer = mapProps(
  LiveProjectsPaginatedContainerHoc(filterPlanAndExecute),
);
