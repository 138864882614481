import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';

import { usePrevious } from 'altus-hooks';

import { PagedQueryParams } from 'app/app.constants';

const usePagedSearch = (onSubmit, initialFilters, preloadedState = false) => {
  const [filters, setCurrentFilters] = useState(initialFilters);
  const [isSkipNextSubmit, setIsSkipNextSubmit] = useState(preloadedState);

  const previousFilters = usePrevious(filters);

  const onPageChange = useCallback(({ pageIndex, pageSize }) => {
    setCurrentFilters((currentFilters) => ({
      ...currentFilters,
      [PagedQueryParams.PAGE]: pageIndex + 1,
      [PagedQueryParams.PAGE_SIZE]: pageSize,
    }));
  }, []);

  const onFiltersChange = useCallback(
    (updatedFilters) =>
      setCurrentFilters((currentFilters) => {
        const retValue = {
          ...currentFilters,
          ...updatedFilters,
          [PagedQueryParams.PAGE]: 1,
        };
        return retValue;
      }),
    [],
  );

  useEffect(() => {
    if (isEqual(previousFilters, filters)) return;

    if (isSkipNextSubmit) {
      setIsSkipNextSubmit(false);
    } else {
      onSubmit(filters);
    }
  }, [onSubmit, filters, previousFilters, isSkipNextSubmit]);

  return { onPageChange, onFiltersChange };
};

export default usePagedSearch;
