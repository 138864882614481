import React from 'react';
import { compose } from 'redux';

import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { ExifOrientation } from 'features/projects/tool/tool.constants';
import ToolImageContainer from 'features/projects/tool/components/ToolImageContainer';
import { convertMetric } from 'app/components/WellboreTrajectoryDetailed3DView/Utils';
import Tool3DObjectContainer from 'features/projects/tool/components/Tool3DObjectContainer';

const EquipmentDetailsSubHeader = ({ equipment, classes, toggleModal }) => {
  return (
    <>
      <Grid item container xs={7} className={classes.topText}>
        <Typography variant="h6">
          Item number: {equipment.get('m3ItemNumber')}
        </Typography>
        <Typography variant="h6">
          {equipment.get('description') ?? 'No description'}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={5}
        className={
          equipment.get('isImageEditable')
            ? classes.toolObjectEditable
            : classes.toolContainer
        }
      >
        <Grid
          item
          xs={6}
          container
          onClick={equipment.get('isImageEditable') ? toggleModal : null}
          className={classes.toolObject}
        >
          <ToolImageContainer
            onHoverDarken
            title={equipment.get('name')}
            toolName={equipment.get('name')}
            toolImageUrl={equipment.get('imageUrl')}
            orientation={ExifOrientation.DEGREES_90_MIRRORED}
            style={{
              maxHeight: '100%',
              maxWidth: '100%',
            }}
          />
        </Grid>
        <Grid item container xs={6} className={classes.toolObject}>
          <Tool3DObjectContainer
            onHoverDarken
            title={equipment.get('name')}
            toolName={equipment.get('name')}
            toolDiameter={convertMetric(
              equipment.getIn(['outerDiameter', 'roundedValue']),
              equipment.getIn(['outerDiameter', 'unit']),
            )}
            toolLength={convertMetric(
              equipment.getIn(['length', 'roundedValue']),
              equipment.getIn(['length', 'unit']),
            )}
            tool3DObjectUrl={equipment.get('tool3DObjectUrl')}
            orientation={ExifOrientation.DEGREES_90_MIRRORED}
          />
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    flexDirection: 'row',
    paddingTop: theme.spacing(2.25),
  },
  container: {
    flexDirection: 'column',
    overflow: 'auto',
  },
  topContainer: {
    flexDirection: 'row',
    height: '10%',
  },
  topText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
    '& > canvas': {
      objectFit: 'contain',
    },
    maxHeight: '100%',
    paddingLeft: theme.spacing(3),
  },
  toolObject: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
    '& > canvas': {
      objectFit: 'contain',
    },
    maxHeight: '100%',
    height: '100%',
    paddingLeft: theme.spacing(1),
  },
  toolObjectEditable: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
    '& > canvas': {
      objectFit: 'contain',
    },
    maxHeight: '100%',
    height: '100%',
    paddingLeft: theme.spacing(1),
    cursor: 'pointer',
  },
});

export default compose(withStyles(styles))(EquipmentDetailsSubHeader);
