import { Field } from 'formik';
import { compose } from 'redux';
import { useMemo, useCallback } from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import withStyles from '@material-ui/styles/withStyles';
import { MenuItem, Typography } from '@material-ui/core';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { toLiveProject } from 'utils/route.util';
import NavigationLink from 'app/components/NavigationLink';
import { PROJECT_STATUS as ProjectStatus } from 'app/app.constants';
import { projectStatusToString } from 'features/projects/projects.mappers';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import LiveProjectsTableRow from 'features/data-exchange/live-projects/components/LiveProjectsTableRow';

const defaultInitialState = {
  pageSize: 15,
};

const FormFields = {
  PROJECT_STATUS: 'projectStatus',
};

const LiveProjectsTable = ({
  classes,
  operations,
  updateProjectStatus,
  ...rest
}) => {
  const onSubmit = useCallback(
    (operation) =>
      updateProjectStatus(
        operation.projectId,
        operation[FormFields.PROJECT_STATUS],
      ),
    [updateProjectStatus],
  );

  const columns = useMemo(
    () => [
      {
        id: 'expansionIcon',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: () => (
          <TableRowActionsCell>
            <ExpandMore />
          </TableRowActionsCell>
        ),
      },
      {
        xs: 2,
        Header: 'Project',
        accessor: (operation) => operation.get('projectId'),
        Cell: ({ value: projectId }) => (
          <NavigationLink
            className={classes.projectIdLink}
            to={toLiveProject(projectId)}
            onClick={(event) => {
              // prevent expanding the row
              event.stopPropagation();
            }}
          >
            <Typography noWrap variant="inherit">
              {projectId}
            </Typography>
          </NavigationLink>
        ),
      },
      {
        xs: 2,
        Header: 'Client',
        accessor: (operation) =>
          operation.getIn(['project', 'organizationName']),
      },
      {
        xs: 2,
        Header: 'Facility',
        accessor: (operation) => operation.getIn(['project', 'facilityName']),
      },
      {
        xs: 2,
        Header: 'Well',
        accessor: (operation) => operation.getIn(['project', 'wellboreName']),
      },
      {
        xs: 2,
        Header: 'Objective',
        accessor: (operation) => operation.getIn(['project', 'title']),
      },

      {
        xs: 2,
        Header: 'Status',
        accessor: () => null,
      },
      {
        xs: 2,
        Header: 'Phase',
        Cell: () => (
          <Field
            select
            name={FormFields.PROJECT_STATUS}
            component={TableRowTextFieldFormik}
            onClick={(event) => event.stopPropagation()}
          >
            {Object.values(ProjectStatus).map((status) => (
              <MenuItem key={status} value={status}>
                {projectStatusToString(status)}
              </MenuItem>
            ))}
          </Field>
        ),
      },
    ],
    [classes],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <LiveProjectsTableRow {...props} timeout={0} onSubmit={onSubmit} />
    ),
    [onSubmit],
  );

  return (
    <Table
      usePagination
      manualPagination
      columns={columns}
      items={operations}
      useGlobalFilter={false}
      initialState={defaultInitialState}
      TableRowComponent={renderTableRowComponent}
      {...rest}
    />
  );
};

const styles = () => ({
  projectIdLink: {
    display: 'inline',
  },
});

export default compose(withStyles(styles))(LiveProjectsTable);
