import { EMPTY_MAP } from 'app/app.constants';
import { createSelector } from 'utils/app.util';

export const getToolGroupsFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'toolGroups']),
  (toolGroups) => toolGroups,
);

export const getToolsFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'tools']),
  (toolGroups) => toolGroups,
);

export const getToolAssembliesFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'toolAssemblies']),
  (toolAssemblies) => toolAssemblies,
);

export const getToolSuppliersFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'toolSuppliers']),
  (toolSuppliers) => toolSuppliers,
);

export const getCablesFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'cables']),
  (toolSuppliers) => toolSuppliers,
);

export const getToolFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'tool']),
  (tool) => tool,
);

export const getToolConnectorsFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'toolConnectors']),
  (toolConnectors) => toolConnectors,
);

export const getBhaTemplatesFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'bhaTemplates']),
  (tool) => tool,
);

export const getEquipmentTemplatesFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'equipmentTemplates']),
  (equipment) => equipment,
);

export const getUnitsOfMeasurePreference = createSelector(
  (state) => state.getIn(['common', 'currentUser', 'unit']),
  (currentUser) => currentUser,
);

export const getSurfaceEquipmentFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'surfaceEquipments']),
  (tool) => tool,
);

export const getSurfaceEquipmentCategoriesFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'surfaceEquipmentCategories']),
  (tool) => tool,
);

export const getSurfaceEquipmentByIdFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'surfaceEquipment']),
  (tool) => tool,
);

export const getSurfaceEquipmentGroupsFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'surfaceEquipmentGroups']),
  (toolGroups) => toolGroups,
);

export const getAssetHistoryFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'assetHistory']),
  (assetHistory) => assetHistory,
);

export const getCableFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'cable']),
  (tool) => tool,
);

export const getCableTestDetailsFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'cableTestDetails']),
  (tool) => tool,
);

export const getTemplateForEditFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'bhaTemplateToEdit']),
  (template) => template,
);

export const getTemplateSectionsForEditFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'bhaTemplateSectionsToEdit']),
  (templateSections) => templateSections,
);

export const getTemplateToolstringToolsFromState = createSelector(
  getTemplateForEditFromState,
  (toolstring) => {
    if (!toolstring || !toolstring.has('toolsByToolstringToolId')) {
      return EMPTY_MAP;
    }

    const toolStringItems = toolstring.get('toolsByToolstringToolId');
    if (!toolStringItems || typeof toolStringItems.toList !== 'function') {
      return EMPTY_MAP;
    }

    return toolStringItems.toList().sortBy((tool) => tool.get('sequence'));
  },
);

export const getEquipmentAssetsFromState = createSelector(
  (state) => state.getIn(['entity', 'equipment', 'assets']),
  (projects) => projects,
);
