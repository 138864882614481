import { connect } from 'react-redux';
import toJSComponent from 'with-immutable-props-to-js';
import { reduxForm, Field } from 'redux-form/immutable';
import { Button, Grid, DialogActions } from '@material-ui/core';

import {
  updateLesson,
  onEditLessonModalLoaded,
} from 'features/projects/lessons/lessons.actions';

import {
  MODAL,
  EDIT_LESSON_FORM,
} from 'features/projects/lessons/lessons.constants';

import { required } from 'utils/validation.util';
import TextField from 'app/components/form/TextField';
import LoadingButton from 'app/components/LoadingButton';
import ModalContainer from 'app/components/Modal/ModalContainer';
import { getLessonsFromState } from 'features/projects/lessons/lessons.selectors';
import EditorReduxForm from 'app/components/form/EditorField';

const EditLessonModalContainer = ({
  valid,
  lessons,
  children,
  submitting,
  handleSubmit,
  dispatchOnLoad,
}) => (
  <ModalContainer
    items={lessons}
    title="Edit lesson"
    onEnter={dispatchOnLoad}
    TriggerComponent={children}
    modalId={MODAL.EDIT_LESSON}
    onNext={({ goNext }) => goNext()}
    onPrevious={({ goPrevious }) => goPrevious()}
    Actions={({ toggleModal }) => (
      <DialogActions>
        <Button size="small" onClick={toggleModal}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          size="small"
          color="primary"
          disabled={!valid}
          variant="contained"
          onClick={handleSubmit}
          submitting={submitting}
          form={EDIT_LESSON_FORM.ID}
        >
          Save and close
        </LoadingButton>
      </DialogActions>
    )}
  >
    <form id={EDIT_LESSON_FORM.ID} onSubmit={handleSubmit}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Field
            required
            autoFocus
            label="Title"
            component={TextField}
            validate={[required]}
            name={EDIT_LESSON_FORM.TITLE}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            required
            label="Description"
            component={EditorReduxForm}
            validate={[required]}
            name={EDIT_LESSON_FORM.DESCRIPTION}
            xs={12}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={EditorReduxForm}
            label="Recommended actions"
            name={EDIT_LESSON_FORM.RECOMMENDED_ACTIONS}
            xs={12}
          />
        </Grid>
      </Grid>
    </form>
  </ModalContainer>
);

const mapStateToProps = (state) => ({
  lessons: getLessonsFromState(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(updateLesson(ownProps.project.id)),
  dispatchOnLoad: (lesson) => dispatch(onEditLessonModalLoaded(lesson)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  toJSComponent(
    reduxForm({
      form: EDIT_LESSON_FORM.ID,
    })(EditLessonModalContainer),
  ),
);
