import 'typeface-roboto';
import moment from 'moment';
import ReactDOM from 'react-dom';
import Highcharts from 'highcharts';
import { Provider } from 'react-redux';
import MomentUtils from '@date-io/moment';
import Highstock from 'highcharts/highstock';
import momentDurationFormat from 'moment-duration-format';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ConnectedRouter } from 'connected-react-router/immutable';

import 'index.scss';
import store from 'infrastructure/store';
import history from 'infrastructure/history';
import ApplicationRoot from 'app/ApplicationRoot';
import appInsights from 'infrastructure/appInsights';
import { registerServiceWorker } from 'utils/app.util';
import { initializeHighcharts } from 'utils/highcharts.util';
import LocationProvider from 'infrastructure/LocationProvider';
import { setGlobalLocation } from 'app/app.actions';

if (appInsights) {
  appInsights.loadAppInsights();
}

//Add moment.duration to moment
momentDurationFormat(moment);

initializeHighcharts(Highcharts);
initializeHighcharts(Highstock);

registerServiceWorker();

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ConnectedRouter history={history}>
        <LocationProvider
          setGlobalLocation={(path) => store.dispatch(setGlobalLocation(path))}
        >
          <ApplicationRoot />
        </LocationProvider>
      </ConnectedRouter>
    </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root'),
);
