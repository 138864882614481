import Add from '@material-ui/icons/Add';
import { IconButton, MenuItem } from '@material-ui/core';

import { DropdownMenu } from 'altus-ui-components';

const UserRoleSelector = ({ addRoleToUser, user, roles }) => {
  if (!roles.size) return null;

  const missingRoleIds = roles
    .map((role) => role.get('roleId'))
    .toOrderedSet()
    .subtract(user.get('roles'));

  if (!missingRoleIds.size) return null;

  return (
    <DropdownMenu
      Trigger={
        <IconButton title="Add">
          <Add fontSize="small" />
        </IconButton>
      }
    >
      {missingRoleIds
        .map((roleId) => roles.get(roleId.toString()))
        .map((role) => (
          <MenuItem
            key={role.get('roleId')}
            title={role.get('description')}
            onClick={() => addRoleToUser(role.get('roleId'), user.get('id'))}
          >
            {role.get('name')}
          </MenuItem>
        ))}
    </DropdownMenu>
  );
};

export default UserRoleSelector;
