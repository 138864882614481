import queryString from 'qs';

import {
  getSummarizedDataState,
  getActionDataStateSelector,
  getActionsDataStatesSelector,
} from 'altus-datastate';

import { createSelector } from 'utils/app.util';
import { OrganizationType } from 'app/app.constants';
import { getProjectMembersKeyedByUserIdFromState } from 'features/projects/team/team.selectors';

export const isClientOrganization = (organization) =>
  organization.get('type') !== OrganizationType.MAIN_VENDOR;

export const getCurrentUserFromState = createSelector(
  (state) => state.getIn(['common', 'currentUser']),
  (currentUser) => currentUser,
);

const getAllUsers = createSelector(
  (state) => state.getIn(['common', 'users']),
  (users) => users,
);

export const getAllUsersFromState = createSelector(getAllUsers, (users) =>
  users.toList(),
);

export const getUsersByUserIdFromState = createSelector(
  getAllUsers,
  (users) => users,
);

export const getModalFromState = createSelector(
  (state) => state.get('modal'),
  (modal) => modal,
);

export const getModalDirectionFromState = createSelector(
  getModalFromState,
  (modal) => modal.get('direction'),
);

export const getActiveModalIndexByModalId = createSelector(
  (state, modalId) =>
    state.getIn(['modal', 'openModals', modalId, 'activeIndex']),
  (activeIndex) => activeIndex,
);

export const isModalOpen = createSelector(
  (state, modalId) => !!state.getIn(['modal', 'openModals', modalId]),
  (open) => open,
);

export const getAvailableServicesFromState = createSelector(
  (state) => state.getIn(['common', 'availableServices']),
  (services) => services,
);

export const getAvailableDepartmentsFromState = createSelector(
  (state) => state.getIn(['common', 'availableDepartments']),
  (departments) => departments,
);

export const getAllOrganizationsFromState = createSelector(
  (state) => state.getIn(['common', 'availableOrganizations']),
  (organizations) => organizations,
);

export const getClientOrganizationsFromState = createSelector(
  getAllOrganizationsFromState,
  (organizations) => organizations.filter(isClientOrganization),
);

export const getActionDataStateFromState = createSelector(
  getActionDataStateSelector,
  (dataState) => dataState,
);

export const getSummarizedDataStateFromState = createSelector(
  getActionsDataStatesSelector,
  getSummarizedDataState,
);

export const getAvailableFileCategoriesFromState = createSelector(
  (state) => state.getIn(['common', 'availableFileCategories']),
  (fileCategories) => fileCategories,
);

export const getCurrentProjectMemberFromState = createSelector(
  getCurrentUserFromState,
  getProjectMembersKeyedByUserIdFromState,
  (currentUser, projectMembersByUserId) =>
    currentUser.get('id')
      ? projectMembersByUserId.get(currentUser.get('id').toString())
      : undefined,
);

export const getRouterFromState = createSelector(
  (state) => state.get('router'),
  (router) => router,
);

export const getCurrentSubPage = createSelector(getRouterFromState, (router) =>
  router.get('subPage'),
);

export const getRouterParamsFromState = createSelector(
  getRouterFromState,
  (router) =>
    queryString.parse(router.getIn(['location', 'search']), {
      ignoreQueryPrefix: true,
    }),
);

export const getPreviousLocationFromState = createSelector(
  getRouterFromState,
  (router) => router.get('previous'),
);

export const getCurrentLocationFromState = createSelector(
  getRouterFromState,
  (router) => router.getIn(['location', 'pathname']),
);

export const getCurrentThemeFromState = createSelector(
  (state) => state.getIn(['common', 'theme']),
  (theme) => theme,
);

export const getApplicationLoadedFromState = createSelector(
  (state) => state.getIn(['common', 'appLoaded']),
  (appLoaded) => appLoaded,
);

export const getFormValuesFromState = createSelector(
  (state, formId) => state.getIn(['form', formId, 'values']),
  (formValues) => formValues,
);

export const getFormValueFromState = createSelector(
  (state, formId, fieldId) => state.getIn(['form', formId, 'values', fieldId]),
  (formValue) => formValue,
);

export const getFormFromState = createSelector(
  (state, formId) => state.getIn(['form', formId]),
  (form) => form,
);

export const getNotificationFromState = createSelector(
  (state) => state.getIn(['common', 'notification']),
  (notification) => notification,
);

export const getApplicationHeaderFromState = createSelector(
  (state) => state.getIn(['common', 'applicationHeader']),
  (applicationHeader) => applicationHeader,
);

const featureFlagsSelector = (state) => state.getIn(['common', 'featureFlags']);

const featureFlagStatusSelector = (state, featureFlag) =>
  state.getIn(['common', 'featureFlags', featureFlag], true);

export const getFeatureFlagsFromState = createSelector(
  featureFlagsSelector,
  (featureFlags) => featureFlags,
);

export const getFeatureFlagStatusFromState = createSelector(
  featureFlagStatusSelector,
  (featureFlagStatus) => featureFlagStatus,
);

export const getCurrentClientOrganizationIdFromState = createSelector(
  (state) => state.getIn(['common', 'currentClientOrganizationId']),
  (currentClientId) => currentClientId,
);

export const getCurrentClientOrganizationFromState = createSelector(
  getCurrentClientOrganizationIdFromState,
  getClientOrganizationsFromState,
  (currentClientId, clientOrganizations) =>
    clientOrganizations.find(
      (organization) =>
        organization.get('id').toString() === currentClientId.toString(),
    ),
);

export const getCurrentClientOrganizationIdFromStateForAssembly =
  createSelector(
    (state) => state.getIn(['common', 'currentUser', 'organizationId']),
    (currentClientId) => currentClientId,
  );

export const getCurrentClientSelectedUom = createSelector(
  (state) => state.getIn(['common', 'currentUser', 'units']),
  (currentClientId) => currentClientId,
);

export const getChangeLog = createSelector(
  (state) => state.getIn(['common', 'changeLog']),
  (chLog) => chLog,
);

export const getPrivacyPolicy = createSelector(
  (state) => state.getIn(['common', 'privacyPolicy']),
  (privacyPolicy) => privacyPolicy,
);
