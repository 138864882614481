import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import { useViewMode } from 'app/hooks/useViewMode';
import MobileDrawerBase from 'app/components/Mobile/MobileDrawerBase';
import MobileNavigationHelpTooltip from 'app/components/Mobile/MobileNavigationHelpTooltip';
import { useState } from 'react';
import { alpha } from '@material-ui/core/styles';

const HtmlTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={className} placement="right-start" />
);

const NavigationHelpTooltip = ({ classes, data }) => {
  const viewMode = useViewMode();
  const [drawerOpen, setDrawerOpen] = useState(false);
  return viewMode === 'mobile' ? (
    <>
      <HelpIcon
        onClick={() => setDrawerOpen(true)}
        className={classes.MobileTooltipIcon}
      />
      <MobileDrawerBase open={drawerOpen} toggleOpen={setDrawerOpen}>
        <MobileNavigationHelpTooltip data={data} classes={classes} />
      </MobileDrawerBase>
    </>
  ) : (
    <HtmlTooltip
      className={classes.tooltip}
      title={
        <>
          <Typography className={classes.title}>{data.title}</Typography>
          {data.data.map((help) => (
            <Box className={classes.container}>
              <Box>
                <Typography className={classes.navType}>
                  {help.navType}
                </Typography>
                <Typography className={classes.content}>
                  {help.content}
                </Typography>
              </Box>
              <Box component="img" className={classes.image} src={help.src} />
            </Box>
          ))}
        </>
      }
    >
      <HelpIcon fontSize="small" className={classes.tooltipIcon} />
    </HtmlTooltip>
  );
};

const styles = (theme) => ({
  tooltipIcon: {
    '&:hover': {
      color: theme.palette.action.selected,
    },
  },
  MobileTooltipIcon: {
    position: 'absolute',
    top: theme.spacing(0),
    left: theme.spacing(2),
    zIndex: 2,
    fontSize: 'xx-large',
    '&:hover': {
      color: theme.palette.action.selected,
    },
  },
  tooltip: {
    backgroundColor:
      theme.altus.components.ContextualizedWell.tooltip.background.main,
    fontSize: theme.typography.pxToRem(12),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.typography.pxToRem(3),
  },
  title: {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(14),
  },
  navType: {
    color: 'inherit',
    fontSize: theme.typography.pxToRem(12),
  },
  content: {
    color: alpha(theme.palette.common.white, 0.7),
    fontSize: theme.typography.pxToRem(12),
  },
  drawerContent: {
    color: alpha(theme.palette.common.white, 0.7),
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(3),
  },
  drawerTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  drawerNavType: {
    paddingTop: theme.spacing(2),
  },
  image: {
    height: theme.typography.pxToRem(45),
    width: 'auto',
    marginLeft: theme.typography.pxToRem(10),
  },
});

export default withStyles(styles)(NavigationHelpTooltip);
