import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EMPTY_MAP } from 'app/app.constants';
import { getWellboreTrajectory } from 'features/wells/wellbore.actions';
import { getWellboreTrajectoryFromState } from 'features/wells/wellbore.selectors';

const useWellboreTrajectory = (fieldId, wellboreId) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (fieldId && wellboreId) {
      dispatch(getWellboreTrajectory(fieldId, wellboreId));
    }
  }, [dispatch, fieldId, wellboreId]);

  const wellboreTrajectorySelector = useMemo(
    () => getWellboreTrajectoryFromState(wellboreId),
    [wellboreId],
  );

  return useSelector(wellboreTrajectorySelector) ?? EMPTY_MAP;
};

export default useWellboreTrajectory;
