import React from 'react';
import { Field } from 'formik';

import { Grid } from '@material-ui/core';

import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';

const CableWrapStrandFormComponent = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} container>
          <Field
            type="number"
            destroyOnUnmount
            label="Pass X"
            component={TextFieldFormik}
            name={AssetHistoryForm.CABLE_WRAP_TEST_STRAND_PASS_X}
          />
        </Grid>
        <Grid item xs={6} container>
          <Field
            type="number"
            destroyOnUnmount
            label="Out of Y"
            component={TextFieldFormik}
            name={AssetHistoryForm.CABLE_WRAP_TEST_STRAND_OUT_Y}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CableWrapStrandFormComponent;
