import React from 'react';
import { Grid, Divider } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import GetAppIcon from '@material-ui/icons/GetApp';

import ReadOnlyField from 'app/components/ReadOnlyField';
import { formatDate } from 'utils/format.util';
import { Format } from 'app/app.constants';
import moment from 'moment';
import LoadingButton from 'app/components/LoadingButton';
import { drumTypeWhenNoCableSpoolingStorage } from 'features/equipment/assets/components/drawer/utils/constants';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const CableTestDetailsDrawerContent = ({
  classes,
  cableTestDetails,
  cableDetail,
  exportCableHistory,
}) => {
  const dataVerified = cableDetail?.dataVerified
    ? moment(cableDetail?.dataVerified)
    : '';

  const formatQuantity = (quantity) => {
    return quantity?.hasValue
      ? `${`${quantity?.roundedValue} ${quantity?.unit}`}`
      : '-';
  };

  const formatQuantityWithUnit = (quantity, unit) => {
    return quantity ? `${`${quantity} ${unit}`}` : '-';
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid>
            <CustomReadOnlyField margin="none" label="Name">
              {cableDetail?.name ?? '-'}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid>
            <CustomReadOnlyField margin="none" label="Nominal Diameter">
              {formatQuantity(cableDetail?.nominalDiameter)}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Manufacturer">
              {cableDetail?.manufacturer ?? '-'}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Manufacturer Part No.">
              {cableDetail?.manufacturerPartNo ?? '-'}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Stretch Coefficient">
              {formatQuantity(cableDetail?.stretchCoefficient)}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Breaking Strength">
              {formatQuantity(cableDetail?.strength)}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Safe Working Load">
              {formatQuantity(cableDetail?.safeWorkingLoad)}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Weight In Air">
              {formatQuantity(cableDetail?.weightInAir)}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Is Data Verified">
              {cableDetail?.dataVerified ? (
                <>
                  YES <br />
                  {formatDate(dataVerified, Format.input)}
                </>
              ) : (
                'NO'
              )}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Data Verified">
              {cableDetail?.dataVerified
                ? formatDate(dataVerified, Format.time)
                : '-'}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
      </Grid>

      {/* PART 2 */}
      <Divider className={classes.marginLabel} />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Current Length">
              {formatQuantity(cableTestDetails?.get('currentCableLength'))}
            </CustomReadOnlyField>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid className={classes.marginLabel}></Grid>
          <CustomReadOnlyField margin="none" label="Max Tension">
            {formatQuantity(cableTestDetails?.get('maxTension'))}
          </CustomReadOnlyField>
        </Grid>
        <Grid item xs={3}>
          <Grid className={classes.marginLabel}></Grid>
          <CustomReadOnlyField margin="none" label="Max Depth">
            {formatQuantity(cableTestDetails?.get('maxDepth'))}
          </CustomReadOnlyField>
        </Grid>
        <Grid item xs={3}>
          <Grid className={classes.marginLabel}></Grid>
          <CustomReadOnlyField margin="none" label="Max OD">
            {formatQuantity(cableTestDetails?.get('maxOD'))}
          </CustomReadOnlyField>
        </Grid>

        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Original Spooling Date">
              {cableTestDetails?.get('originalSpoolingDate')
                ? formatDate(
                    moment(cableTestDetails?.get('originalSpoolingDate')),
                    Format.time,
                  )
                : '-'}
            </CustomReadOnlyField>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Latest Spooling Date">
              {cableTestDetails?.get('latestSpoolingDate')
                ? formatDate(
                    moment(cableTestDetails?.get('latestSpoolingDate')),
                    Format.time,
                  )
                : '-'}
            </CustomReadOnlyField>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Latest Cable Resistance">
              {formatQuantityWithUnit(
                cableTestDetails?.get('latestCableResistance'),
                'ohm',
              )}
            </CustomReadOnlyField>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Total Number Of Runs">
              {cableTestDetails?.get('totalNumberRuns') ?? '-'}
            </CustomReadOnlyField>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField margin="none" label="Current Drum Type">
              {cableTestDetails?.get('currentDrumType') ??
                drumTypeWhenNoCableSpoolingStorage}
            </CustomReadOnlyField>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid className={classes.marginLabel}>
            <CustomReadOnlyField
              margin="none"
              label="Current Drum Serial Number"
            >
              {cableTestDetails?.get('drumSerial')
                ? cableTestDetails?.get('drumSerial')
                : '-'}
            </CustomReadOnlyField>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid className={classes.marginButton}>
            <LoadingButton
              type="button"
              size={'large'}
              color="primary"
              variant="contained"
              onClick={exportCableHistory}
            >
              <GetAppIcon className={classes.marginIcon} /> EXPORT CABLE HISTORY
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  activityField: {
    paddingTop: `${theme.spacing(3)}px`,
  },
  subActivityBox: {
    marginTop: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(2)}px`,
    border: `2px solid ${theme.palette.table.row.hover}`,
  },
  subActivityField: {
    padding: `${theme.spacing(1)}px`,
  },
  marginLabel: {
    marginTop: '4vh',
  },

  bottomLabel: {
    marginBottom: '4vh',
  },
  iconStyle: {
    height: '2.2vh',
    display: 'inline-block',
    verticalAlign: 'middle',
    objectFit: 'contain',
  },
  paper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: '4vh',
  },
  marginIcon: {
    marginRight: `${theme.spacing(2)}px`,
  },
  marginButton: {
    marginTop: `${theme.spacing(2)}px`,
  },
});

export default withStyles(styles)(CableTestDetailsDrawerContent);
