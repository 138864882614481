import { compose } from 'redux';
import { connect } from 'react-redux';
import GotoBhaEditModal from 'features/projects/tasks/task/toolstring/GotoBhaEditModal';
import { redirectToBhaEditAction } from 'features/projects/tasks/task/toolstring/toolstring.actions';
import { useCallback } from 'react';

const GotoBhaEditModalContainer = ({
  taskId,
  projectId,
  toolstringId,
  open,
  toggleModal,
  dispatchRedirectToBhaEditAction,
}) => {
  const redirectToBhaEditAndCloseModal = useCallback(() => {
    dispatchRedirectToBhaEditAction(taskId, projectId, toolstringId);
    toggleModal();
  }, [
    taskId,
    projectId,
    toolstringId,
    toggleModal,
    dispatchRedirectToBhaEditAction,
  ]);
  return (
    <GotoBhaEditModal
      taskId={taskId}
      projectId={projectId}
      toolstringId={toolstringId}
      open={open}
      onClose={toggleModal}
      redirectToBhaEditAndCloseModal={redirectToBhaEditAndCloseModal}
    />
  );
};

export default compose(
  connect(null, {
    dispatchRedirectToBhaEditAction: redirectToBhaEditAction,
  }),
)(GotoBhaEditModalContainer);
