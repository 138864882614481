import projectService from 'services/project.service';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';
import { PROJECT_WELLBORE_ACTIONS } from 'features/projects/wellbore/projectWellbore.constants';

export const projectWellboreOnLoad = (projectId) => (dispatch) => {
  const payload = Promise.all([
    dispatch(getProjectWellbore(projectId)),
    dispatch(getProjectWellboreTrajectory(projectId)),
  ]);

  dispatch({
    type: PROJECT_WELLBORE_ACTIONS.PROJECT_WELLBORE_ON_LOAD,
    payload,
  });

  return payload;
};

export const getProjectWellbore = (projectId) => (dispatch) => {
  const payload = projectService
    .getWellboreByProject(projectId)
    .then((wellbore) => {
      dispatch(receiveProjectWellbore(wellbore));

      return wellbore;
    });

  dispatch({
    payload,
    type: PROJECT_WELLBORE_ACTIONS.GET_PROJECT_WELLBORE,
  });

  return payload;
};

export const getProjectWellboreTrajectory = (projectId) => (dispatch) => {
  const payload = projectService
    .getWellboreTrajectoryByProject(projectId)
    .then((trajectory) => {
      dispatch(receiveProjectWellboreTrajectory(trajectory));

      return trajectory;
    });

  dispatch({
    payload,
    type: PROJECT_WELLBORE_ACTIONS.GET_PROJECT_WELLBORE_TRAJECTORY,
  });

  return payload;
};

export const receiveProjectWellbore = (wellbore) => ({
  payload: wellbore,
  type: PROJECT_WELLBORE_ACTIONS.RECEIVE_PROJECT_WELLBORE,
});

export const receiveProjectWellboreTrajectory = (wellboreTrajectory) => ({
  payload: wellboreTrajectory,
  type: PROJECT_WELLBORE_ACTIONS.RECEIVE_PROJECT_WELLBORE_TRAJECTORY,
});

export const syncProjectWellboreTrajectoryWithLatest =
  (projectId) => (dispatch) => {
    const payload = () =>
      projectService
        .syncProjectWellboreTrajectoryWithLatest(projectId)
        .then((trajectory) =>
          dispatch(receiveProjectWellboreTrajectory(trajectory)),
        );

    dispatch(
      askProjectWellboreUpdateConfirmation({
        onConfirm: () =>
          dispatch({
            payload,
            type: PROJECT_WELLBORE_ACTIONS.SYNC_PROJECT_WELLBORE_TRAJECTORY_WITH_LATEST,
            notification: {
              info: 'Updating...',
              success: 'Project survey successfully updated',
            },
          }),
      }),
    );

    return payload;
  };

export const askProjectWellboreUpdateConfirmation =
  ({ onConfirm, onCancel }) =>
  (dispatch, getState) => {
    const projectStatus = getExecuteStatusFromState(getState());
    const simulationsRunCount = projectStatus.get('simulationsRunCount', 0);

    if (!simulationsRunCount) {
      return onConfirm();
    }

    // If there are some run simulations in the project, ask for confirmation before dispatching the action.
    dispatch({
      type: PROJECT_WELLBORE_ACTIONS.UPDATE_PROJECT_WELLBORE_CONFIRMATION_DIALOG,
      confirmationDialog: {
        description: `Updating the project wellbore will clear the results of ${simulationsRunCount} simulation(s). Are you sure you want to proceed?`,
        title: 'Warning',
        onCancel,
        onConfirm,
      },
    });
  };
