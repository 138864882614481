import moment from 'moment';
import { useMemo, useCallback } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton, Typography, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { Table, UserAvatar, TableRowActionsCell } from 'altus-ui-components';

import LessonTableRow from 'features/projects/lessons/LessonTableRow';
import { ProjectPermission } from 'app/app.constants';
import HasProjectPermission from 'app/components/HasProjectPermission';

const LessonsTable = ({ lessons, projectId, editLesson, deleteLesson }) => {
  const columns = useMemo(
    () => [
      {
        xs: 3,
        Header: 'Title',
        accessor: (lesson) => lesson.get('title'),
      },
      {
        xs: 6,
        Header: 'Author',
        Cell: ({ row }) => {
          const lesson = row.original;
          return (
            <Grid container style={{ alignItems: 'center' }}>
              <Grid item xs={2}>
                <UserAvatar
                  avatar={lesson.getIn(['author', 'avatar'])}
                  initials={lesson.getIn(['author', 'initials'])}
                />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="subtitle2">
                  {lesson.getIn(['author', 'name'])}
                </Typography>
              </Grid>
            </Grid>
          );
        },
      },
      {
        xs: 3,
        Header: 'Created',
        accessor: (lesson) => moment(lesson.get('created')).calendar(),
      },
      {
        id: 'actions',
        Header: <TableRowActionsCell minItems={2} />,
        Cell: ({ row }) => {
          return (
            <TableRowActionsCell minItems={2}>
              <HasProjectPermission
                permissions={ProjectPermission.EDIT_LESSONS}
              >
                <IconButton title="Edit" onClick={() => editLesson(row.index)}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </HasProjectPermission>
              <HasProjectPermission
                permissions={ProjectPermission.DELETE_LESSONS}
              >
                <IconButton
                  title="Delete"
                  onClick={() =>
                    deleteLesson(projectId, row.original.get('id'))
                  }
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </HasProjectPermission>
            </TableRowActionsCell>
          );
        },
      },
      {
        id: 'expansionIcon',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: () => (
          <TableRowActionsCell>
            <ExpandMoreIcon />
          </TableRowActionsCell>
        ),
      },
    ],
    [projectId, editLesson, deleteLesson],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <LessonTableRow
        {...props}
        projectId={projectId}
        editLesson={editLesson}
        deleteLesson={deleteLesson}
      />
    ),
    [projectId, editLesson, deleteLesson],
  );

  return (
    <Table
      items={lessons}
      columns={columns}
      useGlobalFilter={false}
      noItemsMessage={'No lessons'}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

export default LessonsTable;
