import DashboardLineChart from 'features/projects/dashboard/components/charts/DashboardLineChart';
import useProjectDashboardHubConnection from 'features/projects/dashboard/hooks/useProjectDashboardHubConnection';

const GenericDashboardController = ({
  projectId,
  renderSlots,
  projectDashboardId,
}) => {
  const { curves, setExtremes, registerDataPointsHandler } =
    useProjectDashboardHubConnection(projectId, projectDashboardId);

  return renderSlots(
    <DashboardLineChart
      curves={curves}
      projectId={projectId}
      onSetExtremes={setExtremes}
      registerDataPointsHandler={registerDataPointsHandler}
    />,
  );
};

export default GenericDashboardController;
