import { compose } from 'redux';
import { Box, Grid } from '@material-ui/core';
import { useEffect, useCallback } from 'react';
import { useSelector, connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';

import { EMPTY_STRING } from 'app/app.constants';

import {
  getCables,
  onUnload,
  searchCables,
} from 'features/equipment/equipment.actions';

import CablesTable from 'features/equipment/cables/components/CablesTable';
import { getCablesFromState } from 'features/equipment/equipment.selectors';
import CablesFilter, {
  Filters,
} from 'features/equipment/cables/components/CablesFilter';

const defaultFormValues = {
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
  [Filters.SERIAL_SEARCH]: EMPTY_STRING,
};

const CablesContainer = ({
  classes,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchOnSearch,
}) => {
  const cables = useSelector(getCablesFromState);

  useEffect(() => {
    dispatchOnLoad();

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload]);

  const handleSearch = useCallback(
    (filterValues) => {
      dispatchOnSearch({
        [Filters.TEXT_SEARCH]: filterValues[Filters.TEXT_SEARCH],
        [Filters.SERIAL_SEARCH]: filterValues[Filters.SERIAL_SEARCH],
      });
    },
    [dispatchOnSearch],
  );

  return (
    <Grid container>
      <Grid item xs={12} container component={Box} direction="column">
        <Grid
          item
          container
          alignItems="center"
          className={classes.filterContainer}
        >
          <Grid item xs={12}>
            <CablesFilter
              onSubmit={handleSearch}
              initialValues={defaultFormValues}
            />
          </Grid>
        </Grid>
        <Grid item container className={classes.tableContainer}>
          <CablesTable cables={cables} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  filterContainer: {
    height: 'fit-content',
    width: '20%',
  },
  tableContainer: {
    flex: 1,
  },
});

export default compose(
  connect(() => ({}), {
    dispatchOnLoad: getCables,
    dispatchOnUnload: onUnload,
    dispatchOnSearch: searchCables,
  }),
  withStyles(styles),
)(CablesContainer);
