import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Button } from '@material-ui/core';

const SetAndRetrieveItemModalHeader = ({
  closeModal,
  classes,
  saveValuePairs,
}) => (
  <Grid
    container
    alignItems="center"
    justifyContent="spaceBetween"
    className={classes.container}
  >
    <Grid container item xs justifyContent="flex-end" spacing={2}>
      <Grid item>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Close
        </Button>
      </Grid>
      <Grid item>
        <Button color="Primary" variant="contained" onClick={saveValuePairs}>
          Save
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.secondary.dark,
  },
});

export default compose(withStyles(styles))(SetAndRetrieveItemModalHeader);
