import { FacilityStatus } from 'features/facilities/facilities.constants';

export const facilityStatusToString = (status) =>
  ({
    [FacilityStatus.Unknown]: 'Unknown',
    [FacilityStatus.Abandoned]: 'Abandoned',
    [FacilityStatus.Decommissioned]: 'Decommissioned',
    [FacilityStatus.Fabrication]: 'Fabrication',
    [FacilityStatus.Future]: 'Future',
    [FacilityStatus.InService]: 'InService',
    [FacilityStatus.Installation]: 'Installation',
    [FacilityStatus.LaidUp]: 'LaidUp',
    [FacilityStatus.Removed]: 'Removed',
  }[status]);
