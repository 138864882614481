import { Grid } from '@material-ui/core';

import GasFormSection from 'app/components/FluidsFormSection/FluidForm/FluidSurfaceProperties/GasFormSection';
import LiquidFormSection from 'app/components/FluidsFormSection/FluidForm/FluidSurfaceProperties/LiquidFormSection';
import MultiphaseFormSection from 'app/components/FluidsFormSection/FluidForm/FluidSurfaceProperties/MultiphaseFormSection';
import { FluidType } from 'utils/fluids/constants.utils';

const FluidSurfaceProperties = ({
  disabled,
  fluidType,
  rheologyModel,
  multiphaseType,
  gasType,
}) => {
  const formSections = {
    [FluidType.GAS]: GasFormSection,
    [FluidType.LIQUID]: LiquidFormSection,
    [FluidType.MULTIPHASE]: MultiphaseFormSection,
  };

  return (
    <Grid
      disabled={disabled}
      rheologyModel={rheologyModel}
      multiphaseType={multiphaseType}
      gasType={gasType}
      component={formSections[fluidType]}
    />
  );
};

export default FluidSurfaceProperties;
