import ReadOnlyField from 'app/components/ReadOnlyField';

const OperationCardQuantity = ({ label, quantity }) => {
  if (!quantity) return null;

  const { unit, roundedValue } = quantity;

  const value = roundedValue ? `${roundedValue} (${unit})` : 'N/A';

  return <ReadOnlyField variant="body2" value={value} label={label} />;
};

export default OperationCardQuantity;
