export const getTaskPageTitle = (currentTask, tasks) => {
  const sequence =
    tasks.findIndex((task) => currentTask?.get('id') === task.get('id')) + 1;

  return sequence > 0
    ? `${currentTask.get('title')} (${sequence}/${tasks.size})`
    : undefined;
};

export const getPreviousNextTask = (tasks, currentTaskId) => {
  return [-1, +1].map((sequence) => {
    const currentTaskIndex = tasks.findIndex(
      (task) => task.get('id').toString() === currentTaskId.toString(),
    );

    if (currentTaskIndex + sequence < 0) return null;

    return tasks.get(currentTaskIndex + sequence);
  });
};
