import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { BreadcrumbHeader as BreadcrumbHeaderBase } from 'altus-ui-components';

import routes from 'app/routes';
import routePaths from 'app/routePaths';

const BreadcrumbHeader = ({ classes }) => {
  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
    excludePaths: [
      routePaths.root,
      routePaths.dataExchange.liveProjects.project.winch.index,
      routePaths.dataExchange.liveProjects.project.cheetah.index,
      routePaths.dataExchange.liveProjects.project.warrior.index,
    ],
  });

  return (
    <BreadcrumbHeaderBase
      breadcrumbs={breadcrumbs}
      classes={{
        header: classes.header,
        breadcrumbText: classes.breadcrumbText,
      }}
    />
  );
};

const styles = (theme) => ({
  header: {
    backgroundColor: 'transparent',
  },
  breadcrumbText: {
    color: theme.palette.primary.contrastText,
  },
});

export default compose(withStyles(styles))(BreadcrumbHeader);
