import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { setHeaderValues } from 'app/app.actions';

export const useHeader = ({ title, subTitle, logoNavigation }) => {
  const dispatch = useDispatch();

  const dispatchSetHeaderValues = useCallback(
    (...params) => dispatch(setHeaderValues(...params)),
    [dispatch],
  );

  const setHeaderTitle = useCallback(
    (title) => dispatchSetHeaderValues({ title }),
    [dispatchSetHeaderValues],
  );

  const setHeaderSubTitle = useCallback(
    (subTitle) => dispatchSetHeaderValues({ subTitle }),
    [dispatchSetHeaderValues],
  );

  const setHeaderLogoNavigation = useCallback(
    (logoNavigation) => dispatchSetHeaderValues({ logoNavigation }),
    [dispatchSetHeaderValues],
  );

  useEffect(
    () => dispatchSetHeaderValues({ title, subTitle, logoNavigation }),
    [title, subTitle, logoNavigation, dispatchSetHeaderValues],
  );

  return {
    setHeaderTitle,
    setHeaderSubTitle,
    setHeaderLogoNavigation,
  };
};
