import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import {
  wellboreContentToString,
  wellborePurposeToString,
} from 'features/projects/wellbore/details/projectWellboreDetails.mappers';

import FormSection from 'app/components/form/FormSection';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import { WELLBORE_DETAIL_FORM as FORM } from 'features/projects/wellbore/details/projectWellboreDetails.constants';

const WellInformationSection = ({ disabled }) => (
  <FormSection title="Well Information">
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Field
          fullWidth
          disabled
          component={TextFieldFormik}
          name={FORM.WELLBORE_NAME}
          label="Well name"
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          disabled
          format={wellboreContentToString}
          name={FORM.WELLBORE_CONTENT}
          component={TextFieldFormik}
          label="Well content"
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          disabled
          label="Well purpose"
          format={wellborePurposeToString}
          component={TextFieldFormik}
          name={FORM.WELLBORE_PURPOSE}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          disabled
          type="number"
          label="Reference rig (RKB-MSL)"
          name={FORM.KELLY_BUSHING_ELEVATION}
          component={QuantityTextFieldFormik}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          disabled={disabled}
          label="Corrosion/Scale/BSS/Debris"
          component={TextFieldFormik}
          name={FORM.CORROSION_SCALE_BSS}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          fullWidth
          type="number"
          label="Down Hole Safety Valve depth - DHSV"
          disabled={disabled}
          component={QuantityTextFieldFormik}
          name={FORM.DOWN_HOLE_SAFETY_VALVE_DEPTH}
        />
      </Grid>
    </Grid>
  </FormSection>
);

export default WellInformationSection;
