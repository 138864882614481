import { compose } from 'redux';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getSystemRoleFromState } from 'authorization/authorization.selectors';

class SystemRoleBreadcrumb extends PureComponent {
  render() {
    const { systemRole } = this.props;

    return systemRole ? systemRole.get('name') : '...';
  }
}

export default compose(
  connect((state, { match }) => ({
    systemRole: getSystemRoleFromState(state, match.params.systemRoleId),
  })),
)(SystemRoleBreadcrumb);
