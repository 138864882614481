import React from 'react';
import { IntegrityForm as FormFields } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import { List } from 'immutable';

const SelectPicklistOptionComponent = () => {
  const { values } = useFormikContext();
  const getId = (item) => item.id;
  const getName = (item) => item.name;
  const picklistObject = values[FormFields.PICKLIST_OPTIONS];
  const items = picklistObject.map((picklist) => ({
    id: picklist.integrityDetailId,
    code: picklist.integrityId,
    name: picklist.title,
  }));

  return (
    <Grid item xs={6}>
      <SelectTextFieldFormik
        getItemName={getName}
        placeholder="Select"
        label="Picklist Options"
        getItemValue={getId}
        items={List(items)}
        name={FormFields.VALUE}
        displayEmpty={false}
      />
    </Grid>
  );
};

export default SelectPicklistOptionComponent;
