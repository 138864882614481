import PropTypes from 'prop-types';
import { useCallback } from 'react';

import { invokeIfFunction } from 'utils/app.util';
import HasPermission from 'app/components/HasPermission';
import { useSystemPermissions } from 'app/hooks/authorization/useSystemPermissions';

const HasSystemPermission = ({ render, children, permissions }) => {
  const { currentUser, userPermissions, systemPermissions } =
    useSystemPermissions(permissions);

  const renderContent = useCallback(
    (hasPermission) =>
      invokeIfFunction(
        render,
        hasPermission,
        userPermissions,
        systemPermissions,
        currentUser,
      ),
    [render, userPermissions, systemPermissions, currentUser],
  );

  return (
    <HasPermission
      permissions={systemPermissions}
      userPermissions={userPermissions}
      render={render ? renderContent : undefined}
    >
      {children}
    </HasPermission>
  );
};

HasSystemPermission.propTypes = {
  render: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  permissions: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Set),
    PropTypes.arrayOf(PropTypes.number),
  ]),
};

export default HasSystemPermission;
