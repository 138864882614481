import { Set } from 'immutable';
import { useMemo } from 'react';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import Counter from 'app/components/Counter';
import { formatDuration } from 'utils/format.util';
import { getWorkItemStatusDisplayName } from 'utils/app.util';
import { WORK_ITEM_STATUS as WorkItemStatus } from 'app/app.constants';
import WorkItemStatusIcon from 'features/projects/components/WorkItemStatus';

const maxActivityCount = 3;

const ProjectExecutionStatusTable = ({ status }) => {
  const totalActivities = status.getIn(['total', 'activities']);
  const currentActivityNumber = status.getIn(['current', 'activityNumber']);

  const items = useMemo(() => {
    const nextActivity = status.getIn(['next', 'activity']);
    const currentActivity = status.getIn(['current', 'activity']);
    const previousActivity = status.getIn(['previous', 'activity']);

    const taskActivities = status.getIn(['activeTask', 'activities']);

    const initialActivity = currentActivity ?? previousActivity ?? nextActivity;

    const activeTask = status.get('activeTask');

    if (!initialActivity) {
      return activeTask?.get('status') === WorkItemStatus.COMPLETED
        ? taskActivities.takeLast(maxActivityCount)
        : taskActivities.take(maxActivityCount);
    }

    const initialSequence = initialActivity.get('sequence');

    return taskActivities
      .reduce((result, _, index, items) => {
        const sequence = index + 1;
        const initialIndex = initialSequence - 1;

        // Immutable.js Collections will return the last element if getting by index < 0
        const prevIndex = Math.max(initialIndex - sequence, 0);

        var previous = prevIndex !== initialIndex ? items.get(prevIndex) : null;
        var next = items.get(initialIndex + sequence);

        return result
          .add(previous)
          .add(next)
          .filter(Boolean)
          .take(maxActivityCount);
      }, Set.of(initialActivity))
      .sortBy((x) => x.get('sequence'));
  }, [status]);

  const columns = useMemo(
    () => [
      {
        id: 'placeholder',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => (
          <TableRowActionsCell>
            <WorkItemStatusIcon status={row.original.get('status')} />
          </TableRowActionsCell>
        ),
      },
      {
        xs: 6,
        id: 'activity',
        Header: () => {
          if (!currentActivityNumber) return 'Activity';

          return `Activity (${currentActivityNumber}/${totalActivities})`;
        },
        disableSortBy: true,
        accessor: (activity) => activity.get('name'),
      },
      {
        xs: 3,
        Header: 'Depth',
        disableSortBy: true,
        accessor: (activity) => activity.get('targetDepth'),
      },
      {
        xs: 3,
        Header: 'Duration',
        Cell: ({ row }) => {
          const activity = row.original;

          if (activity.get('isPointInTime')) return null;

          const isTimerActive =
            activity.get('status') === WorkItemStatus.STARTED;

          return (
            <Counter
              isTimerActive={isTimerActive}
              key={activity.get('startTime')}
              endTime={activity.get('endTime')}
              startTime={activity.get('startTime')}
              calculateDurationFromStartAndEndtime
              renderValue={({ elapsedTime }) => formatDuration(elapsedTime)}
            />
          );
        },
      },
      {
        xs: 3,
        Header: 'Status',
        disableSortBy: true,
        accessor: (activity) =>
          getWorkItemStatusDisplayName(activity.get('status')),
      },
    ],
    [totalActivities, currentActivityNumber],
  );

  return (
    <Table
      items={items}
      columns={columns}
      useGlobalFilter={false}
      noItemsMessage={'No activities'}
    />
  );
};

export default ProjectExecutionStatusTable;
