import React from 'react';
import { AppBar } from '@material-ui/core';
import { Tab, Tabs } from 'altus-ui-components';
import {
  generatePath,
  useRouteMatch,
  NavLink,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';

function usePartialMatch(routePattern) {
  const { pathname } = useLocation();
  const pathSegments = pathname.split('/').filter(Boolean);
  const match = matchPath(pathname, { path: routePattern, end: false });
  if (match) {
    const matchSegments = match.url.split('/').filter(Boolean);
    if (
      matchSegments.length === pathSegments.length ||
      (pathSegments.length === matchSegments.length + 2 &&
        pathSegments.slice(0, matchSegments.length).join('/') ===
          matchSegments.join('/'))
    ) {
      return match;
    }
  }
  return null;
}

const NavTab = ({ route, TabComponent = Tab, ...rest }) => {
  const routeMatch = useRouteMatch(route);
  const partialMatch = usePartialMatch(route.path);
  const selected = routeMatch ? true : partialMatch;
  const theme = useTheme();

  return (
    <TabComponent
      {...rest}
      selected={selected}
      component={NavLink}
      disabled={route.disabled}
      style={{
        color: selected
          ? theme.mobile.NavBar.children.selected
          : theme.mobile.NavBar.children.main,
      }}
    />
  );
};

const MobileNavigationAppBar = ({ routesToDisplay, ...rest }) => {
  const routeMatch = useRouteMatch();

  const visibleRoutes = routesToDisplay.filter((route) => !route.hidden);
  return (
    <AppBar position="static" color="default">
      <Tabs value={null} variant="fullWidth" disableUnderline={true} {...rest}>
        {visibleRoutes.map((route) => {
          const { path, breadcrumb, Icon } = route;
          const url = generatePath(path, routeMatch.params);

          return (
            <NavTab
              to={url}
              key={path}
              value={null}
              route={route}
              label={breadcrumb}
              icon={Icon}
            />
          );
        })}
      </Tabs>
    </AppBar>
  );
};

export default MobileNavigationAppBar;
