import { compose } from 'redux';
import debounce from 'lodash/debounce';
import { Grid } from '@material-ui/core';
import { reduxForm } from 'redux-form/immutable';
import { connect, useSelector } from 'react-redux';
import { useMemo, useEffect, useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import {
  taskDetailsOnLoad,
  updateTaskDetails,
  updateTaskServices,
  copyAndRedirectToTask,
  initializeEditTaskForm,
  deleteTaskAndRedirectToTasks,
  requestCreateRiskSection,
  requestUpdateRiskSection,
  requestDeleteRiskSection,
  requestTaskRiskSections,
} from 'features/projects/tasks/task/details/taskDetails.actions';

import {
  TASK_FORMS,
  TASK_ACTIONS as TASKS_ACTIONS,
} from 'features/projects/tasks/tasks.constants';

import {
  createTaskRiskSectionsForWellboreSelector,
  getAllTasksFromState,
} from 'features/projects/tasks/tasks.selectors';

import {
  getAvailableServicesFromState,
  getSummarizedDataStateFromState,
  getAvailableDepartmentsFromState,
} from 'app/app.selectors';

import { EMPTY_LIST, ProjectPermission } from 'app/app.constants';
import EditTaskForm from 'features/projects/tasks/components/EditTaskForm';
import { TASK_ACTIONS } from 'features/projects/tasks/task/task.constants';
import { useProjectPermissions } from 'app/hooks/authorization/useProjectPermissions';
import { getTaskFromState } from 'features/projects/tasks/task/toolstring/toolstring.selectors';

const TaskDetailsContainer = ({
  task,
  taskId,
  classes,
  projectId,
  dataState,
  dispatchCopyTask,
  tasks = EMPTY_LIST,
  dispatchDeleteTask,
  dispatchUpdateTask,
  dispatchUpdateTaskServices,
  dispatchInitializeEditTaskForm,
  dispatchRequestCreateTaskRiskSection,
  dispatchRequestUpdateTaskRiskSection,
  dispatchRequestDeleteTaskRiskSection,
  dispatchRequestTaskRiskSectionsForTaskRisk,
}) => {
  const { hasPermission } = useProjectPermissions(ProjectPermission.EDIT_TASKS);

  const services = useSelector(getAvailableServicesFromState);
  const departments = useSelector(getAvailableDepartmentsFromState);

  const updateTaskDebounced = useMemo(
    () =>
      debounce(
        (objective) => dispatchUpdateTask(projectId, taskId, objective),
        1000,
      ),
    [dispatchUpdateTask, projectId, taskId],
  );

  const updateTaskServices = useCallback(
    (services) => dispatchUpdateTaskServices(projectId, taskId, services),
    [taskId, projectId, dispatchUpdateTaskServices],
  );

  useEffect(() => {
    dispatchInitializeEditTaskForm(task, tasks);
  }, [task, tasks, dispatchInitializeEditTaskForm]);

  useEffect(() => {
    dispatchRequestTaskRiskSectionsForTaskRisk(taskId);
  }, [dispatchRequestTaskRiskSectionsForTaskRisk, taskId]);

  const createTaskRiskSection = useCallback(
    (risk, formik, callback) =>
      dispatchRequestCreateTaskRiskSection(taskId, risk, formik, callback),
    [dispatchRequestCreateTaskRiskSection, taskId],
  );

  const updateTaskRiskSection = useCallback(
    (risk) => {
      dispatchRequestUpdateTaskRiskSection(taskId, risk);
    },
    [dispatchRequestUpdateTaskRiskSection, taskId],
  );

  const deleteTaskRiskSection = useCallback(
    (riskId) => dispatchRequestDeleteTaskRiskSection(riskId, taskId),
    [dispatchRequestDeleteTaskRiskSection, taskId],
  );

  const taskRiskSectionsSelector = useMemo(
    () => createTaskRiskSectionsForWellboreSelector(),
    [],
  );

  const taskRiskboreSections = useSelector(taskRiskSectionsSelector);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs justifyContent="center">
        <Grid container item xs={8}>
          <EditTaskForm
            task={task}
            displayProject
            taskId={taskId}
            services={services}
            projectId={projectId}
            disabled={!hasPermission}
            departments={departments}
            copyTask={dispatchCopyTask}
            deleteTask={dispatchDeleteTask}
            updateTask={updateTaskDebounced}
            updateTaskServices={updateTaskServices}
            // TODO: Remove toJS()
            tasks={tasks.toJS()}
            taskRiskboreSections={taskRiskboreSections}
            createTaskRiskSection={createTaskRiskSection}
            updateTaskRiskSection={updateTaskRiskSection}
            deleteTaskRiskSection={deleteTaskRiskSection}
          />
        </Grid>
      </Grid>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  task: getTaskFromState(state),
  tasks: getAllTasksFromState(state),
  dataState: getSummarizedDataStateFromState(
    state,
    TASKS_ACTIONS.COPY_TASK,
    TASK_ACTIONS.TASK_PAGE_LOADED,
    TASKS_ACTIONS.CONFIRM_DELETE_TASK,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: taskDetailsOnLoad,
  dispatchUpdateTask: updateTaskDetails,
  dispatchCopyTask: copyAndRedirectToTask,
  dispatchUpdateTaskServices: updateTaskServices,
  dispatchDeleteTask: deleteTaskAndRedirectToTasks,
  dispatchInitializeEditTaskForm: initializeEditTaskForm,

  dispatchRequestCreateTaskRiskSection: requestCreateRiskSection,
  dispatchRequestUpdateTaskRiskSection: requestUpdateRiskSection,
  dispatchRequestDeleteTaskRiskSection: requestDeleteRiskSection,
  dispatchRequestTaskRiskSectionsForTaskRisk: requestTaskRiskSections,
};

const styles = (theme) => ({
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: TASK_FORMS.EDIT_TASK.FORM_ID,
  }),
  withStyles(styles),
)(TaskDetailsContainer);
