import { useMemo, useCallback, useState, useEffect } from 'react';
import { IconButton, Grid, Button, Box, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { Map } from 'immutable';
import { Field } from 'formik';
import { MenuItem, Select } from '@material-ui/core';

import { compose } from 'redux';

import { useToggle } from 'altus-hooks';
import { Table } from 'altus-ui-components';

import { required } from 'utils/validation.util';
import {
  EMPTY_LIST,
  RiskConsequenceEnum,
  RiskMatrix,
  RiskProbabilityEnum,
  RiskStateEnum,
} from 'app/app.constants';
import CreateRiskSectionRow from 'features/projects/components/CreateRiskSectionRow';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import EditorFormik from 'app/components/form/formik/EditorFormik';
import EditableRichTextTableRowFormik from 'app/components/form/formik/EditableRichTextTableRowFormik';
import { isEqual } from 'lodash';
import { isValidJSON, sortRisks } from 'utils/app.util';
import RiskTableCellDense from 'features/projects/components/RiskTableCellDense';

export const RiskSectionFormFields = {
  RISK: 'description',
  MITIGATION: 'mitigation',
  CONSEQUENCE: 'consequence',
  PROBABILITY: 'probability',
  STATE: 'state',
  RATING: 'rating',
  DESCRIPTION: 'description',
};

const RiskSectionsTable = ({
  createRiskSection,
  updateRiskSection,
  deleteRiskSection,
  defaultRiskSection = Map({
    risk: EditorState.createEmpty(),
    mitigation: EditorState.createEmpty(),
  }),
  riskSections = EMPTY_LIST,
  title = 'Risk',
}) => {
  const [convertedRiskSections, setConvertedRiskSections] =
    useState(riskSections);
  const [isCreateMode, toggleCreateMode] = useToggle();
  const [currentRiskSections, setCurrentRiskSections] = useState(null);
  const consequences = Object.values(RiskConsequenceEnum);
  const probabilities = Object.values(RiskProbabilityEnum);
  const states = Object.values(RiskStateEnum);

  useEffect(() => {
    if (riskSections) {
      const convertedSections = riskSections.map((section) => {
        const descriptionEditorState =
          section?.get('description') &&
          isValidJSON(section?.get('description'))
            ? EditorState.createWithContent(
                convertFromRaw(JSON.parse(section?.get('description'))),
              )
            : EditorState.createEmpty();

        const mitigationEditorState =
          section?.get('mitigation') && isValidJSON(section?.get('mitigation'))
            ? EditorState.createWithContent(
                convertFromRaw(JSON.parse(section?.get('mitigation'))),
              )
            : EditorState.createEmpty();

        return section
          .set('description', descriptionEditorState)
          .set('mitigation', mitigationEditorState);
      });

      setConvertedRiskSections(convertedSections);
    }
  }, [riskSections]);

  const [toolbarHeight, setToolbarHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const toolbar = document.querySelector('.rdw-editor-toolbar');
      if (toolbar) {
        const height = toolbar.offsetHeight;
        setToolbarHeight(height);
      } else {
        setToolbarHeight(44);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentRiskSections([riskSections]);
  }, [riskSections]);

  const onSubmit = useCallback(
    (values, { forceUpdate = false }) => {
      const foundRisk = convertedRiskSections.find(
        (risk) => risk?.get('riskId') === values.riskId,
      );
      const description = JSON.stringify(
        convertToRaw(values.description.getCurrentContent()),
      );
      const mitigation = JSON.stringify(
        convertToRaw(values.mitigation.getCurrentContent()),
      );
      if (foundRisk) {
        const oldDescription = JSON.stringify(
          convertToRaw(foundRisk.get('description').getCurrentContent()),
        );
        const newMitigation = JSON.stringify(
          convertToRaw(foundRisk.get('mitigation').getCurrentContent()),
        );

        if (
          !isEqual(oldDescription, description) ||
          !isEqual(mitigation, newMitigation) ||
          forceUpdate
        ) {
          const risk = {
            description: description,
            mitigation: mitigation,
            riskId: values.riskId,
            created: values.created,
            modified: values.modified,
            consequence: values.consequence,
            probability: values.probability,
            state: values.state,
          };

          updateRiskSection(risk);
        }
      }
    },
    [convertedRiskSections, updateRiskSection],
  );

  const handleChange = useCallback(
    (currentRow) => (event) => {
      const { name, value } = event.target;
      const consequenceId = currentRow?.get(RiskSectionFormFields.CONSEQUENCE);
      const probabilityId = currentRow?.get(RiskSectionFormFields.PROBABILITY);
      let color = RiskMatrix[consequenceId][probabilityId] ?? 'red';
      let updatedRiskSection;

      if (name === RiskSectionFormFields.CONSEQUENCE) {
        color = RiskMatrix[value][probabilityId] ?? 'red';
      }

      if (name === RiskSectionFormFields.PROBABILITY) {
        color = RiskMatrix[consequenceId][value] ?? 'red';
      }

      convertedRiskSections.map((section) => {
        if (section.get('riskId') === currentRow.get('riskId')) {
          updatedRiskSection = section
            .set(name, value)
            .set(RiskSectionFormFields.RATING, color);
          return updatedRiskSection;
        }
        return section;
      });

      if (updatedRiskSection) {
        onSubmit(updatedRiskSection.toJS(), { forceUpdate: true });
      }
    },
    [convertedRiskSections, onSubmit],
  );

  const columns = useMemo(
    () => [
      {
        xs: 8,
        id: 'risk',
        Header: 'Risk',
        accessor: 'risk',
        Cell: () => (
          <Field name={RiskSectionFormFields.RISK}>
            {({ form, ...formik }) => (
              <EditorFormik
                form={form}
                {...formik}
                toolbar={{
                  options: ['inline', 'list'],
                }}
                toolbarOnFocus
                toolbarHeight={toolbarHeight}
                xs={12}
                editorXS={12}
                generalMarginTop={2}
                editorMarginTop={0}
                marginBottom={5}
                isRisk={true}
              />
            )}
          </Field>
        ),
      },
      {
        xs: 8,
        id: 'mitigation',
        Header: 'Mitigation',
        accessor: 'mitigation',
        Cell: () => (
          <Field validate={required} name={RiskSectionFormFields.MITIGATION}>
            {({ form, ...formik }) => (
              <EditorFormik
                form={form}
                {...formik}
                toolbar={{
                  options: ['inline', 'list'],
                }}
                toolbarOnFocus
                toolbarHeight={toolbarHeight}
                xs={12}
                editorXS={12}
                generalMarginTop={2}
                editorMarginTop={0}
                marginBottom={5}
                isRisk={true}
              />
            )}
          </Field>
        ),
      },
      {
        xs: 2,
        id: 'rating',
        Header: () => (
          <Grid xs={12} style={{ height: '100%' }}>
            <div>Rating</div>
          </Grid>
        ),
        accessor: 'rating',
        Cell: ({ row }) => {
          const consequenceId = row.original.get('consequence');
          const probabilityId = row.original.get('probability');
          const color = RiskMatrix[consequenceId][probabilityId] ?? 'red';

          return (
            <>
              <Grid direction="row">
                <div
                  style={{
                    backgroundColor: color,
                    borderRadius: '50%',
                    width: '25px',
                    height: '25px',
                    marginLeft: '3vh',
                  }}
                ></div>
              </Grid>
            </>
          );
        },
      },
      {
        xs: 4,
        id: 'consequence',
        Header: () => (
          <Grid direction="row" style={{ height: '100%' }}>
            <div>Consequence</div>
          </Grid>
        ),
        accessor: 'consequence',
        Cell: ({ row }) => (
          <>
            <Grid container item>
              <Field
                as={Select}
                name={RiskSectionFormFields.CONSEQUENCE}
                onChange={handleChange(row.original)}
                id="consequence"
              >
                {consequences.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </>
        ),
      },
      {
        xs: 4,
        id: 'probability',
        Header: () => (
          <Grid style={{ height: '100%' }}>
            <div>Probability</div>
          </Grid>
        ),
        accessor: 'probability',
        Cell: ({ row }) => (
          <>
            <Grid container>
              <Field
                as={Select}
                name={RiskSectionFormFields.PROBABILITY}
                onChange={handleChange(row.original)}
                id="probability"
              >
                {probabilities.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </>
        ),
      },
      {
        xs: 3,
        id: 'state',
        Header: () => (
          <Grid style={{ height: '100%' }}>
            <div>State</div>
          </Grid>
        ),
        accessor: 'state',
        Cell: ({ row }) => (
          <>
            <Grid container>
              <Field
                as={Select}
                name={RiskSectionFormFields.STATE}
                onChange={handleChange(row.original)}
                id="state"
              >
                {states.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </>
        ),
      },
      {
        xs: 1,
        id: 'actions',
        Header: ' ',
        Cell: ({ row }) => (
          <Grid xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {deleteRiskSection && (
              <IconButton
                size="small"
                title="Delete"
                disabled={isCreateMode}
                onClick={() => {
                  deleteRiskSection(row.original.get('riskId'));
                }}
                style={{ marginLeft: 'auto' }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Grid>
        ),
      },
    ],
    [
      consequences,
      deleteRiskSection,
      handleChange,
      isCreateMode,
      probabilities,
      states,
      toolbarHeight,
    ],
  );

  const renderTableRowComponent = useCallback(
    (props) => {
      return <EditableRichTextTableRowFormik {...props} onSubmit={onSubmit} />;
    },
    [onSubmit],
  );

  const initialState = useMemo(() => {
    if (!riskSections) {
      return { expanded: {} };
    }

    const arraySections = Array.isArray(riskSections)
      ? riskSections
      : riskSections.toArray();

    return {
      expanded: arraySections.reduce(
        (result, _riskSection, index) => ({
          ...result,
          [index]: true,
        }),
        {},
      ),
    };
  }, [riskSections]);

  return (
    <>
      {createRiskSection && (
        <>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            style={{ marginTop: '1%' }}
          >
            <Grid item>
              <Typography variant="h6">{title}</Typography>
            </Grid>
            <Grid item>
              <Box>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={isCreateMode}
                  onClick={toggleCreateMode}
                >
                  <AddIcon fontSize="small" />
                  Add risk
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      <Grid xs={12} style={{ padding: '0 !important' }} sx={{ padding: 0 }}>
        <Table
          stickyHeader
          disableSortBy
          columns={columns}
          useGlobalFilter={false}
          items={sortRisks(convertedRiskSections)}
          initialState={initialState}
          noItemsMessage="No risk sections added yet..."
          displayNoItemsMessage={!isCreateMode}
          TableRowComponent={renderTableRowComponent}
          TableCellComponent={RiskTableCellDense}
        />
      </Grid>

      {isCreateMode && (
        <CreateRiskSectionRow
          columns={columns}
          toggleCreateMode={toggleCreateMode}
          createRiskSection={createRiskSection}
          defaultRiskSection={defaultRiskSection}
          riskSection={currentRiskSections}
        />
      )}
    </>
  );
};

export default compose(RiskSectionsTable);
