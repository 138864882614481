import { put, call, takeEvery } from 'redux-saga/effects';

import {
  deleteWellboreSection,
  receiveWellboreSections,
  deleteWellboreSectionNipple,
  receiveWellboreSection,
} from 'features/wells/sections/wellboreSection.actions';

import { callAsync } from 'app/sagas/helperSagas';
import { FormikFormStatus } from 'app/app.constants';
import wellboreSectionService from 'services/wellboreSection.service';
import { ACTIONS } from 'features/wells/sections/wellboreSection.constants';

export function* requestWellboreSectionsForWellboreAsync(action) {
  const { fieldId, wellboreId } = action;

  const wellboreSections = yield call(
    wellboreSectionService.getWellboreSectionsForWellbore,
    fieldId,
    wellboreId,
  );

  yield put(receiveWellboreSections(wellboreSections));

  return wellboreSections;
}

export function* requestCreateWellboreSectionAsync(action) {
  const { fieldId, payload, callback, setStatus, wellboreId, setSubmitting } =
    action;

  try {
    const wellboreSection = yield call(
      wellboreSectionService.createWellboreSection,
      fieldId,
      wellboreId,
      payload,
    );

    // refresh sections to get correct order
    yield call(requestWellboreSectionsForWellboreAsync, action);

    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);

    if (callback) {
      yield call(callback);
    }

    return wellboreSection;
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);

    throw error;
  }
}

export function* requestUpdateWellboreSectionAsync(action) {
  const { fieldId, wellboreId, wellboreSectionId, payload } = action;

  const wellboreSection = yield call(
    wellboreSectionService.updateWellboreSection,
    fieldId,
    wellboreId,
    wellboreSectionId,
    payload,
  );

  // refresh sections to get correct order
  yield call(requestWellboreSectionsForWellboreAsync, action);

  return wellboreSection;
}

export function* requestDeleteWellboreSectionAsync(action) {
  const { fieldId, wellboreId, wellboreSectionId } = action;

  yield call(
    wellboreSectionService.deleteWellboreSection,
    fieldId,
    wellboreId,
    wellboreSectionId,
  );

  yield put(deleteWellboreSection(wellboreSectionId));
}

export function* requestCreateWellboreSectionNippleAsync(action) {
  const {
    fieldId,
    payload,
    callback,
    setStatus,
    wellboreId,
    wellboreSectionId,
    setSubmitting,
  } = action;

  try {
    const wellboreSection = yield call(
      wellboreSectionService.createWellboreSectionNipple,
      fieldId,
      wellboreId,
      wellboreSectionId,
      payload,
    );

    yield put(receiveWellboreSection(wellboreSection));

    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);

    if (callback) {
      yield call(callback);
    }

    return wellboreSection;
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);

    throw error;
  }
}

export function* requestUpdateWellboreSectionNippleAsync(action) {
  const {
    fieldId,
    wellboreId,
    wellboreSectionId,
    wellboreSectionNippleId,
    payload,
  } = action;

  const wellboreSection = yield call(
    wellboreSectionService.updateWellboreSectionNipple,
    fieldId,
    wellboreId,
    wellboreSectionId,
    wellboreSectionNippleId,
    payload,
  );

  yield put(receiveWellboreSection(wellboreSection));

  return wellboreSection;
}

export function* requestDeleteWellboreSectionNippleAsync(action) {
  const { fieldId, wellboreId, wellboreSectionId, wellboreSectionNippleId } =
    action;
  yield call(
    wellboreSectionService.deleteWellboreSectionNipple,
    fieldId,
    wellboreId,
    wellboreSectionId,
    wellboreSectionNippleId,
  );

  yield put(
    deleteWellboreSectionNipple(wellboreSectionId, wellboreSectionNippleId),
  );
}

export default function* root() {
  yield takeEvery(
    ACTIONS.REQUEST_WELLBORE_SECTIONS,
    callAsync,
    requestWellboreSectionsForWellboreAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_CREATE_WELLBORE_SECTION,
    callAsync,
    requestCreateWellboreSectionAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_UPDATE_WELLBORE_SECTION,
    callAsync,
    requestUpdateWellboreSectionAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_DELETE_WELLBORE_SECTION,
    callAsync,
    requestDeleteWellboreSectionAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_CREATE_WELLBORE_SECTION_NIPPLE,
    callAsync,
    requestCreateWellboreSectionNippleAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_UPDATE_WELLBORE_SECTION_NIPPLE,
    callAsync,
    requestUpdateWellboreSectionNippleAsync,
  );

  yield takeEvery(
    ACTIONS.REQUEST_DELETE_WELLBORE_SECTION_NIPPLE,
    callAsync,
    requestDeleteWellboreSectionNippleAsync,
  );
}
