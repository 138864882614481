import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { invokeIfFunction } from 'utils/app.util';
import CreateProjectModalListItem from 'features/projects/components/CreateProjectModalContainer/CreateProjectModalListItem';

const CreateProjectModalListGroup = ({ data, title, classes, visibleItem }) => {
  const { type, onClick, isItemDisabled } = visibleItem;

  return (
    <Grid xs item className={classes.itemGroup}>
      <Typography variant="h6">{title}</Typography>
      <Grid container>
        {data.map((item, index) => {
          const disabled = invokeIfFunction(isItemDisabled, item);

          return (
            <CreateProjectModalListItem
              key={index}
              type={type}
              item={item}
              onClick={onClick}
              disabled={disabled}
              visibleItem={visibleItem}
            />
          );
        })}
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  itemGroup: {
    padding: theme.spacing(2.5),
  },
});

export default withStyles(styles)(CreateProjectModalListGroup);
