export const NPT_ACTIONS = {
  CREATE_PROJECT_NON_PRODUCTIVE_TIME: 'CREATE_PROJECT_NON_PRODUCTIVE_TIME',
  PROJECT_NON_PRODUCTIVE_TIME_REPORT_LOADED:
    'PROJECT_NON_PRODUCTIVE_TIME_REPORT_LOADED',
  PROJECT_NON_PRODUCTIVE_TIME_REPORT_UNLOADED:
    'PROJECT_NON_PRODUCTIVE_TIME_REPORT_UNLOADED',
  EDIT_PROJECT_NON_PRODUCTIVE_TIME: 'EDIT_PROJECT_NON_PRODUCTIVE_TIME',
  DELETE_PROJECT_NON_PRODUCTIVE_TIME: 'DELETE_PROJECT_NON_PRODUCTIVE_TIME',
};

export const MODAL = {
  EDIT_PROJECT_NPT: 'EDIT_PROJECT_NPT_MODAL_ID',
  ADD_PROJECT_NPT: 'ADD_PROJECT_NPT_MODAL_ID',
};

export const NPT_FORM = {
  CREATE_ID: 'createNpt',
  EDIT_ID: 'createNpt',
  START: 'startTime',
  END: 'endTime',
  DESCRIPTION: 'description',
  DEPARTMENTS: 'departments',
  CASE_NUMBER: 'caseNumber',
};
