import { OrderedMap, fromJS, Set } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import authorizationMappers from 'authorization/authorization.mappers';
import { AUTHORIZATION_ACTIONS } from 'authorization/authorization.constants';

const initialState = fromJS({
  systemRoles: EMPTY_MAP,
  projectRoles: EMPTY_MAP,
  userRoles: EMPTY_MAP,
  projectPermissions: EMPTY_MAP,
  systemPermissions: EMPTY_MAP,
  projectRolePermissions: EMPTY_MAP,
  systemRolePermissions: EMPTY_MAP,
  systemRoleProjectPermissions: EMPTY_MAP,
});

const authorizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHORIZATION_ACTIONS.GET_SYSTEM_ROLES: {
      if (action.error) return state;

      const { payload } = action;

      return state.set(
        'systemRoles',
        OrderedMap(
          payload.map((systemRole) => [
            systemRole.roleId.toString(),
            authorizationMappers.SystemRole.from(systemRole),
          ]),
        ),
      );
    }

    case AUTHORIZATION_ACTIONS.CREATE_SYSTEM_ROLE:
    case AUTHORIZATION_ACTIONS.UPDATE_SYSTEM_ROLE:
    case AUTHORIZATION_ACTIONS.GET_SYSTEM_ROLE: {
      if (action.error) return state;

      const { payload: systemRole } = action;

      return state.setIn(
        ['systemRoles', systemRole.roleId.toString()],
        authorizationMappers.SystemRole.from(systemRole),
      );
    }

    case AUTHORIZATION_ACTIONS.DELETE_SYSTEM_ROLE: {
      if (action.error) return state;

      const { systemRoleId } = action;

      return state.deleteIn(['systemRoles', systemRoleId.toString()]);
    }

    case AUTHORIZATION_ACTIONS.GET_PROJECT_ROLES: {
      if (action.error) return state;

      const { payload } = action;

      return state.set(
        'projectRoles',
        OrderedMap(
          payload.map((projectRole) => [
            projectRole.roleId.toString(),
            authorizationMappers.ProjectRole.from(projectRole),
          ]),
        ),
      );
    }

    case AUTHORIZATION_ACTIONS.GET_USERS_ASSIGNED_TO_SYSTEM_ROLE: {
      if (action.error) return state;

      const { payload, roleId } = action;

      return state.setIn(
        ['userRoles', roleId.toString()],
        Set(payload.map((user) => user.userId.toString())),
      );
    }

    case AUTHORIZATION_ACTIONS.CREATE_PROJECT_ROLE:
    case AUTHORIZATION_ACTIONS.UPDATE_PROJECT_ROLE:
    case AUTHORIZATION_ACTIONS.GET_PROJECT_ROLE: {
      if (action.error) return state;

      const { payload: projectRole } = action;

      return state.setIn(
        ['projectRoles', projectRole.roleId.toString()],
        authorizationMappers.ProjectRole.from(projectRole),
      );
    }

    case AUTHORIZATION_ACTIONS.DELETE_PROJECT_ROLE: {
      if (action.error) return state;

      const { projectRoleId } = action;

      return state.deleteIn(['projectRoles', projectRoleId.toString()]);
    }

    case AUTHORIZATION_ACTIONS.ASSIGN_PERMISSIONS_TO_PROJECT_ROLE:
    case AUTHORIZATION_ACTIONS.UNASSIGN_PERMISSIONS_FROM_PROJECT_ROLE:
    case AUTHORIZATION_ACTIONS.GET_PROJECT_ROLE_PERMISSIONS: {
      if (action.error) return state;

      const { payload, roleId } = action;

      return state.setIn(
        ['projectRolePermissions', roleId.toString()],
        Set(payload.map((rolePermission) => rolePermission.permissionId)),
      );
    }

    case AUTHORIZATION_ACTIONS.ASSIGN_SYSTEM_PERMISSIONS_TO_SYSTEM_ROLE:
    case AUTHORIZATION_ACTIONS.UNASSIGN_SYSTEM_PERMISSIONS_FROM_SYSTEM_ROLE:
    case AUTHORIZATION_ACTIONS.GET_SYSTEM_ROLE_PERMISSIONS: {
      if (action.error) return state;

      const { payload, roleId } = action;

      return state.setIn(
        ['systemRolePermissions', roleId.toString()],
        Set(payload.map((rolePermission) => rolePermission.permissionId)),
      );
    }

    case AUTHORIZATION_ACTIONS.ASSIGN_PROJECT_PERMISSIONS_TO_SYSTEM_ROLE:
    case AUTHORIZATION_ACTIONS.UNASSIGN_PROJECT_PERMISSIONS_FROM_SYSTEM_ROLE:
    case AUTHORIZATION_ACTIONS.GET_SYSTEM_ROLE_PROJECT_PERMISSIONS: {
      if (action.error) return state;

      const { payload, roleId } = action;

      return state.setIn(
        ['systemRoleProjectPermissions', roleId.toString()],
        Set(payload.map((rolePermission) => rolePermission.permissionId)),
      );
    }

    case AUTHORIZATION_ACTIONS.GET_PROJECT_PERMISSIONS: {
      if (action.error) return state;

      const { payload } = action;

      return state.set(
        'projectPermissions',
        OrderedMap(
          payload.map((permission) => [
            permission.permissionId.toString(),
            authorizationMappers.Permission.from(permission),
          ]),
        ),
      );
    }

    case AUTHORIZATION_ACTIONS.GET_SYSTEM_PERMISSIONS: {
      if (action.error) return state;

      const { payload } = action;

      return state.set(
        'systemPermissions',
        OrderedMap(
          payload.map((permission) => [
            permission.permissionId.toString(),
            authorizationMappers.Permission.from(permission),
          ]),
        ),
      );
    }

    default:
      return state;
  }
};

export default authorizationReducer;
