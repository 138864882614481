import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/styles/withStyles';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import { useMemo, useCallback, useEffect } from 'react';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';

import { LoadingOverlay, NoContentBasePage } from 'altus-ui-components';
import { useModal } from 'altus-modal';

import {
  requestUpdateToolstring,
  requestGetTaskToolstring,
  redirectToTaskToolstringEdit,
  requestTaskToolstringSections,
  requestToogleToolstringFavorite,
  requestDeleteToolstringAndRedirect,
  requestDuplicateToolstringAndRedirect,
} from 'features/projects/tasks/task/toolstring/toolstring.actions';

import {
  getTaskByIdFromState,
  getToolstringFromState,
  getToolstringToolsFromState,
  getToolstringSectionsFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';

import {
  getCurrentUserFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';

import { EMPTY_MAP } from 'app/app.constants';
import { getCurrentProject } from 'features/projects/projects.selectors';
import {
  MODAL,
  TOOLSTRING_ACTIONS,
} from 'features/projects/tasks/task/toolstring/toolstring.constants';
import TaskToolstringHeader from 'features/projects/tasks/task/toolstring/components/TaskToolstringHeader';
import ToolstringToolsTable from 'features/projects/tasks/task/toolstring/components/ToolstringToolsTable';
import TaskToolstringPreview from 'features/projects/tasks/task/toolstring/components/TaskToolstringPreview';
import TaskToolstringComments from 'features/projects/tasks/task/toolstring/components/TaskToolstringComments';
import TaskToolstringDetailsHeader from 'features/projects/tasks/task/toolstring/components/TaskToolstringDetailsHeader';
import ToolstringItemHighlightProvider from 'features/projects/tasks/task/toolstring/components/ToolstringItemHighlightProvider';
import useLatestProjectWellboreDetail from 'features/projects/hooks/useLatestProjectWellboreDetails';
import TaskToolstringExportModalContainer from 'features/projects/tasks/task/toolstring/TaskToolstringExportModalContainer';

const NoToolsMessage = () => (
  <NoContentBasePage
    Icon={null}
    header="This BHA got no tools"
    description="Click 'Edit' to add some"
  />
);

const TaskToolstringContainer = ({
  taskId,
  classes,
  projectId,
  dataState,
  toolstring = EMPTY_MAP,
  toolstringId,
  toolstringTools,
  toolstringSections,
  dispatchGetToolstring,
  dispatchUpdateToolstring,
  dispatchToolstringSections,
  dispatchToggleToolstringFavorite,
  dispatchDeleteToolstringAndRedirect,
  dispatchRedirectToTaskToolstringEdit,
  dispatchDuplicateToolstringAndRedirect,
}) => {
  useEffect(() => {
    dispatchGetToolstring(projectId, taskId, toolstringId);
    dispatchToolstringSections(projectId, taskId, toolstringId);
  }, [
    dispatchGetToolstring,
    dispatchToolstringSections,
    projectId,
    taskId,
    toolstringId,
  ]);

  const [isToolstringExportModalOpen, toggleToolstringExportModal] = useModal(
    MODAL.SIMULATION_EXPORT_MODAL_ID,
  );

  const [latestWellboreDetail = EMPTY_MAP] =
    useLatestProjectWellboreDetail(projectId);

  const onUpdateToolstring = useCallback(
    (toolstring) =>
      dispatchUpdateToolstring(projectId, taskId, toolstringId, toolstring),
    [taskId, projectId, toolstringId, dispatchUpdateToolstring],
  );

  const onToggleFavorite = useCallback(
    () => dispatchToggleToolstringFavorite(projectId, taskId, toolstringId),
    [taskId, projectId, toolstringId, dispatchToggleToolstringFavorite],
  );

  const actions = useMemo(() => {
    const toolstringId = toolstring.get('id');

    return [
      {
        Icon: DeleteIcon,
        title: 'Delete',
        disabled: toolstring.get('isPlanned'),
        onClick: () =>
          dispatchDeleteToolstringAndRedirect(
            projectId,
            taskId,
            toolstringId,
            toolstringId,
          ),
      },
      {
        Icon: DuplicateIcon,
        title: 'Duplicate',
        onClick: () =>
          dispatchDuplicateToolstringAndRedirect(
            projectId,
            taskId,
            toolstringId,
          ),
      },
      {
        Icon: PictureAsPdf,
        title: 'Export',
        onClick: toggleToolstringExportModal,
      },
      {
        Icon: EditIcon,
        title: 'Edit',
        disabled: toolstring.get('isPlanned'),
        onClick: () =>
          dispatchRedirectToTaskToolstringEdit(projectId, taskId, toolstringId),
      },
    ];
  }, [
    taskId,
    projectId,
    toolstring,
    toggleToolstringExportModal,
    dispatchDeleteToolstringAndRedirect,
    dispatchRedirectToTaskToolstringEdit,
    dispatchDuplicateToolstringAndRedirect,
  ]);

  const isEmptyToolstring = !toolstringTools.size;

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid container item>
        <TaskToolstringHeader
          toolstring={toolstring}
          actions={actions}
          toggleFavorite={onToggleFavorite}
        />
      </Grid>
      {isEmptyToolstring && <NoToolsMessage />}
      {!isEmptyToolstring && (
        <ToolstringItemHighlightProvider>
          <Grid container item xs className={classes.toolstringContainer}>
            <Grid container item xs={2} className={classes.toolstringPreview}>
              <TaskToolstringPreview
                taskId={taskId}
                projectId={projectId}
                toolstringId={toolstringId}
                toolstringItems={toolstringTools}
                toolstringSections={toolstringSections}
              />
            </Grid>
            <Grid container item xs={8} direction="column">
              <Grid item container>
                <TaskToolstringDetailsHeader
                  taskId={taskId}
                  projectId={projectId}
                  toolstring={toolstring}
                  toolstringId={toolstringId}
                />
              </Grid>
              <Grid item container xs>
                <ToolstringToolsTable
                  toolstring={toolstring}
                  toolstringTools={toolstringTools}
                  maxAvailableHeight={latestWellboreDetail.get(
                    'totalAvailableBhaLength',
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item xs={2} direction="column">
              <Grid item xs container>
                <TaskToolstringComments
                  toolstring={toolstring}
                  onUpdateToolstring={onUpdateToolstring}
                />
              </Grid>
            </Grid>
          </Grid>
        </ToolstringItemHighlightProvider>
      )}
      <LoadingOverlay timeout={0} dataState={dataState} />
      <TaskToolstringExportModalContainer
        taskId={taskId}
        projectId={projectId}
        toolstringId={toolstringId}
        open={isToolstringExportModalOpen}
        toggleModal={toggleToolstringExportModal}
      />
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  toolstringContainer: {
    // This feels hacky, but couldn't figure out any other way of making the toolstring fit in the available height.
    height: `calc(100% - ${theme.spacing(12)}px)`,
  },
  toolstringPreview: {
    height: '100%',
  },
});

export default compose(
  connect(
    (state, { toolstringId, taskId }) => {
      return {
        currentUser: getCurrentUserFromState(state),
        dataState: getSummarizedDataStateFromState(
          state,
          TOOLSTRING_ACTIONS.REQUEST_GET_TOOLSTRING_FOR_TASK,
          TOOLSTRING_ACTIONS.DOWNLOAD_TOOLSTRING_PDF_REPORT,
        ),
        toolstring: getToolstringFromState(state, toolstringId),
        toolstringSections: getToolstringSectionsFromState(state, toolstringId),
        toolstringTools: getToolstringToolsFromState(state, toolstringId),
        project: getCurrentProject(state),
        task: getTaskByIdFromState(state, taskId),
      };
    },
    {
      dispatchGetToolstring: requestGetTaskToolstring,
      dispatchUpdateToolstring: requestUpdateToolstring,
      dispatchToolstringSections: requestTaskToolstringSections,
      dispatchToggleToolstringFavorite: requestToogleToolstringFavorite,
      dispatchRedirectToTaskToolstringEdit: redirectToTaskToolstringEdit,
      dispatchDeleteToolstringAndRedirect: requestDeleteToolstringAndRedirect,
      dispatchDuplicateToolstringAndRedirect:
        requestDuplicateToolstringAndRedirect,
    },
  ),
  withStyles(styles),
)(TaskToolstringContainer);
