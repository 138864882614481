import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { RouteContainer } from 'altus-ui-components';

import { getProjectById } from 'features/projects/projects.actions';

const DashboardContainer = ({
  routes,
  projectId,
  dispatchDashboardOnUnload,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectById(projectId));
  }, [projectId, dispatch, dispatchDashboardOnUnload]);

  return <RouteContainer routes={routes} />;
};

export default DashboardContainer;
