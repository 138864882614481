import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { compose } from 'redux';
import { INTERNAL_CATEGORIES } from 'features/projects/documents/documents.constants';

const DocumentsCategoryFilter = ({
  documents,
  documentCategories,
  classes,
  isAltus,
  filter,
  clearFilters,
  setFilter,
  setFilterApplied,
}) => {
  const toggleCategoryFilter = (category) => {
    setFilter((currentFilter) => {
      const updatedFilter = currentFilter.includes(category)
        ? currentFilter.remove(category)
        : currentFilter.add(category);

      if (updatedFilter.size === 0) {
        setFilterApplied(false);
      } else {
        setFilterApplied(true);
      }

      return updatedFilter;
    });
  };

  const checkIfCategoryIsInternal = (category) => {
    if (
      category.get('name') === INTERNAL_CATEGORIES.INTERNAL ||
      category.get('name') === INTERNAL_CATEGORIES.PROCEDURES ||
      category.get('name') === INTERNAL_CATEGORIES.CHECKLISTS
    ) {
      return null;
    } else {
      return false;
    }
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item container className={classes.title}>
        <Typography>Filter by Category</Typography>
      </Grid>
      <Grid item container className={classes.actions}>
        <Grid item xs={4}>
          <Typography variant="body2">{filter.size} selected</Typography>
        </Grid>
        <Grid xs={8} item container className={classes.buttons}>
          <Grid item>
            <Button onClick={() => clearFilters()}>CLEAR</Button>
          </Grid>
        </Grid>
      </Grid>
      {documentCategories
        .filter((category) => {
          if (!isAltus) {
            if (
              category.get('name') === INTERNAL_CATEGORIES.INTERNAL ||
              category.get('name') === INTERNAL_CATEGORIES.PROCEDURES ||
              category.get('name') === INTERNAL_CATEGORIES.CHECKLISTS
            ) {
              return null;
            }
          }
          return category;
        })
        .sortBy((category) => category.get('id'))
        .valueSeq()
        .map((category) => {
          const docPerCat = documents.filter(
            (action) =>
              action.get('category').toString() ===
              category.get('id').toString(),
          );

          const numOfFilesInCategory = Array.from(docPerCat.values()).length;

          return (
            <Grid item container xs={12} className={classes.category}>
              <Grid item xs={8}>
                <FormControlLabel
                  label={
                    <Typography
                      className={
                        checkIfCategoryIsInternal(category) ??
                        classes.internalCategory
                      }
                    >
                      {category.get('name')}
                    </Typography>
                  }
                  control={
                    <>
                      <Checkbox
                        color="default"
                        value={category.name}
                        onClick={() => toggleCategoryFilter(category)}
                        checked={filter.includes(category)}
                      />
                    </>
                  }
                />{' '}
              </Grid>

              <Grid item xs={1} className={classes.categoryCount}>
                {numOfFilesInCategory ? numOfFilesInCategory : 0}
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

const styles = (theme) => ({
  title: {
    backgroundColor: theme.palette.table.row.main,
    height: '80px',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  actions: {
    backgroundColor: theme.palette.table.row.main,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    gap: '2px',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '4px',
  },
  category: {
    backgroundColor: theme.palette.table.row.main,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  categoryCount: {
    alignItems: 'center',
    alignContent: 'center',
  },
  internalCategory: {
    color: theme.palette.primary.newLight,
  },
});

export default compose(withStyles(styles))(DocumentsCategoryFilter);
