import React from 'react';
import { compose } from 'redux';
import { BasePage } from 'altus-ui-components';
import withStyles from '@material-ui/styles/withStyles';
import {
  getActionDataStateFromState,
  getAvailableDepartmentsFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';
import { useSelector, connect } from 'react-redux';
import { getAllTasks } from 'features/projects/activities/activities.actions';
import {
  onLoad,
  onUnload,
  submitUpdateProject,
} from 'features/projects/details/details.actions';
import { useHeader } from 'app/hooks/useHeader';
import { getCurrentProject } from 'features/projects/projects.selectors';
import {
  getAllTasksFromState,
  getTaskTemplates,
} from 'features/projects/tasks/tasks.selectors';
import { useEffect } from 'react';
import { toggleModal } from 'app/app.actions';
import { DETAILS_ACTIONS } from 'features/projects/details/details.constants';
import { TASK_ACTIONS } from 'features/projects/tasks/tasks.constants';
import { ACTIVITIES_ACTIONS } from 'features/projects/activities/activities.constants';
// import ProjectCategoriesContainer from 'features/mobile/projects/project/ProjectCategoriesContainer';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import { getProjectResources } from 'features/projects/projects.actions';
import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';
import ProjectStatusOverviewContainer from 'features/mobile/projects/project/ProjectStatusOverviewContainer';
import { Divider } from '@material-ui/core';
import { requestProjectWellboreSections } from 'features/projects/wellbore/sections/projectWellboreSection.actions';
import { PROJECT_STATUS } from 'app/app.constants';

const MobileProjectOverviewContainer = ({
  classes,
  projectId,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnLoad,
  dispatchGetTasks,
  dispatchGetProjectResources,
  dispatchRequestProjectWellboreSections,
}) => {
  useHeader({
    subTitle: breadcrumb,
  });

  useEffect(() => {
    dispatchOnLoad(projectId);
    dispatchGetTasks(projectId);
    dispatchGetProjectResources(projectId);
    dispatchRequestProjectWellboreSections(projectId);

    return () => dispatchOnUnLoad();
  }, [
    projectId,
    dispatchOnLoad,
    dispatchOnUnLoad,
    dispatchGetTasks,
    dispatchGetProjectResources,
    dispatchRequestProjectWellboreSections,
  ]);
  const project = useCurrentProject();
  const projectStatus = useSelector(getExecuteStatusFromState);

  const dataState = useSelector((state) =>
    getSummarizedDataStateFromState(state),
  );

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <div className={classes.content}>
        {project.get('status') === PROJECT_STATUS.EXECUTE && (
          <>
            <ProjectStatusOverviewContainer
              status={projectStatus}
              projectId={projectId}
            />
            <Divider className={classes.divider} />
          </>
        )}
        {/* <ProjectCategoriesContainer /> */}
      </div>
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    padding: 0,
  },
  content: {
    padding: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
});

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  tasks: getAllTasksFromState(state),
  templateTasks: getTaskTemplates(state),
  departments: getAvailableDepartmentsFromState(state),
  dataState: getActionDataStateFromState(
    state,
    DETAILS_ACTIONS.PROJECT_DETAILS_PAGE_LOADED,
    TASK_ACTIONS.GET_ALL_TASKS,
    ACTIVITIES_ACTIONS.GET_TASK_ACTIVITIES,
    PROJECTS_ACTIONS.GET_PROJECT_RESOURCES,
  ),
});

const mapDispatchToProps = (dispatch) => {
  const dispatchUpdateProject = () => dispatch(submitUpdateProject);
  const dispatchToggleModal = (modalId) => dispatch(toggleModal({ modalId }));

  return {
    dispatchToggleModal,
    dispatchUpdateProject,
    dispatchGetTasks: (projectId) => dispatch(getAllTasks(projectId)),
    dispatchOnUnLoad: () => dispatch(onUnload()),
    dispatchOnLoad: (projectId) => dispatch(onLoad(projectId)),
    dispatchGetProjectResources: (projectId) =>
      dispatch(getProjectResources(projectId)),
    dispatchRequestProjectWellboreSections: requestProjectWellboreSections,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(MobileProjectOverviewContainer);
