import { fromJS, OrderedMap, Map } from 'immutable';
import { arrayMove } from 'react-sortable-hoc';

import { APP_ACTIONS, EMPTY_MAP } from 'app/app.constants';
import activitiesMappers from 'features/projects/activities/activities.mappers';
import { ACTIVITIES_ACTIONS as ACTIONS } from 'features/projects/activities/activities.constants';

const initialState = Map({
  allActivities: EMPTY_MAP,
});

const activityReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.GET_ALL_PROJECT_ACTIVITIES: {
      if (error) return state;

      const activities = OrderedMap(
        payload.map((activity) => [
          activity.activityId.toString(),
          activitiesMappers.Activity.from(activity),
        ]),
      );

      return state.set(
        'allActivities',
        activities.groupBy((activity) => activity.get('taskId').toString()),
      );
    }

    case ACTIONS.GET_PROJECT_ACTIVITIES: {
      if (error) return state;

      const activities = OrderedMap(
        payload.map((activity) => [
          activity.activityId.toString(),
          activitiesMappers.Activity.from(activity),
        ]),
      );

      return activities.groupBy((activity) =>
        activity.get('taskId').toString(),
      );
    }

    case ACTIONS.GET_TASK_ACTIVITIES: {
      if (error) return state;

      const { taskId } = action;

      return state.set(
        taskId.toString(),
        OrderedMap(
          payload.map((activity) => [
            activity.activityId.toString(),
            activitiesMappers.Activity.from(activity),
          ]),
        ),
      );
    }

    case ACTIONS.UPDATE_ACTIVITY: {
      if (error) return state;

      const { taskId, activityId } = payload;

      return state.setIn(
        [taskId.toString(), activityId.toString()],
        activitiesMappers.Activity.from(payload),
      );
    }

    case APP_ACTIONS.ASYNC_START: {
      const { subtype } = action;

      switch (subtype) {
        case ACTIONS.BACKEND_SORT_ACTIVITIES:
        case ACTIONS.BACKEND_SORT_ACTIVITIES_NO_NOTIFICATION: {
          const { taskId, oldIndex, newIndex } = action;

          return state.update(taskId.toString(), (activities) =>
            fromJS(
              arrayMove(activities.valueSeq().toArray(), oldIndex, newIndex),
            ),
          );
        }

        default:
          return state;
      }
    }

    default:
      return state;
  }
};

export default activityReducer;
