import { combineReducers } from 'redux-immutable';
import { reducer as formReducer } from 'redux-form/immutable';

import {
  confirmationReducer,
  notificationReducer,
} from 'altus-redux-middlewares';

import { modalReducer } from 'altus-modal';
import { dataStateReducer } from 'altus-datastate';

import wellReducer from 'features/wells/well.reducer';
import entityReducer from 'app/reducers/entityReducer';
import routerReducer from 'app/reducers/router.reducer';
import commonReducer from 'app/reducers/common.reducer';
import toolReducer from 'features/projects/tool/tool.reducer';
import projectReducer from 'features/projects/project.reducer';
import authorizationReducer from 'authorization/authorization.reducer';
import operationsReducer from 'features/operations/operations.reducer';

const appReducers = combineReducers({
  form: formReducer,
  dataState: dataStateReducer,
  modal: modalReducer,
  common: commonReducer,
  router: routerReducer,
  projects: projectReducer,
  wells: wellReducer,
  confirmation: confirmationReducer,
  notification: notificationReducer,
  authorization: authorizationReducer,
  entity: entityReducer,
  tool: toolReducer,
  operations: operationsReducer,
});

export default appReducers;
