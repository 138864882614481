import { compose } from 'redux';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDropzone } from 'react-dropzone';
import { connect, useSelector } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { useEffect, useCallback, useMemo, useState } from 'react';

import { BasePage } from 'altus-ui-components';

import {
  onLoad,
  onUnload,
  deleteFile,
  uploadFiles,
  downloadFile,
  updateFile,
} from 'features/facilities/documents/facilityDocuments.actions';

import {
  getSummarizedDataStateFromState,
  getAvailableFileCategoriesFromState,
} from 'app/app.selectors';

import {
  EMPTY_SET,
  ProjectPermission,
  MAX_UPLOAD_FILE_SIZE,
} from 'app/app.constants';

import { setFile } from 'app/app.actions';
import { useHeader } from 'app/hooks/useHeader';
import { Fab } from 'app/components/withTooltip';
import UploadDocumentModal from 'app/components/UploadDocumentModal';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { useProjectPermissions } from 'app/hooks/authorization/useProjectPermissions';
import FacilityDocumentsTable from 'features/facilities/documents/components/FacilityDocumentsTable';
import { FACILITY_DOCUMENTS_ACTIONS } from 'features/facilities/documents/facilityDocuments.constants';
import { getFacilityDocumentsFromState } from 'features/facilities/documents/facilityDocuments.selectors';

const FacilityDocumentsContainer = ({
  classes,
  dataState,
  facilityId,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchDropFiles,
  dispatchDeleteFile,
  dispatchUpdateFile,
  dispatchUploadFiles,
  dispatchDownloadFile,
}) => {
  useHeader({ subTitle: breadcrumb });

  useEffect(() => {
    dispatchOnLoad(facilityId);

    return () => dispatchOnUnload();
  }, [facilityId, dispatchOnLoad, dispatchOnUnload]);

  const { hasPermission: hasUploadDocumentPermission } = useProjectPermissions(
    ProjectPermission.UPLOAD_DOCUMENTS,
  );

  const [filter, setFilter] = useState(EMPTY_SET);

  const documents = useSelector(getFacilityDocumentsFromState);
  const documentCategories = useSelector(getAvailableFileCategoriesFromState);

  const filteredDocuments = useMemo(() => {
    if (filter.isEmpty()) return documents;

    return documents.filter((document) => {
      const documentCategory = documentCategories.get(document.get('category'));

      return filter.includes(documentCategory);
    });
  }, [filter, documents, documentCategories]);

  useEffect(() => {
    if (filteredDocuments.isEmpty()) {
      setFilter(EMPTY_SET);
    }
    // clear filter if deleting the last document to prevent a filter being active when no documents match it
  }, [filteredDocuments]);

  const downloadFile = useCallback(
    (facilityFileId, file) =>
      dispatchDownloadFile(facilityId, facilityFileId, file),
    [facilityId, dispatchDownloadFile],
  );

  const deleteFile = useCallback(
    (facilityFileId) => dispatchDeleteFile(facilityId, facilityFileId),
    [facilityId, dispatchDeleteFile],
  );

  const updateFile = useCallback(
    (file) => dispatchUpdateFile(facilityId, file.facilityFileId, file),
    [facilityId, dispatchUpdateFile],
  );

  const uploadFiles = useCallback(
    (files) => dispatchUploadFiles(facilityId)(files),
    [facilityId, dispatchUploadFiles],
  );

  const onDrop = useCallback(
    (files) => dispatchDropFiles(files),
    [dispatchDropFiles],
  );

  const { isDragActive, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      maxSize: MAX_UPLOAD_FILE_SIZE,
      noDrag: !hasUploadDocumentPermission,
    });

  // return null;

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
        root: classNames({
          [classes.dragOver]: isDragActive,
        }),
      }}
    >
      <Grid item container xs {...getRootProps()}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <FacilityDocumentsTable
            updateFile={updateFile}
            deleteFile={deleteFile}
            downloadFile={downloadFile}
            documents={filteredDocuments}
            documentCategoriesById={documentCategories}
            hasUploadDocumentPermission={hasUploadDocumentPermission}
            noItemsMessage="Upload one or more documents simultaneously by using the + button or drag them here"
          />
        </Grid>
        <Grid item xs={2}>
          <HasProjectPermission
            permissions={ProjectPermission.UPLOAD_DOCUMENTS}
          >
            <UploadDocumentModal
              uploadFiles={uploadFiles}
              enableFileCategories={false}
              rejectedFiles={fileRejections}
              availableFileCategories={documentCategories.valueSeq()}
              dialogContentText="Set meta data and upload files to project"
            />
            <Fab
              onClick={open}
              color="primary"
              title="Upload documents"
              className={classes.create}
            >
              <AddIcon />
            </Fab>
          </HasProjectPermission>
        </Grid>
      </Grid>
      <input {...getInputProps()} />
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    FACILITY_DOCUMENTS_ACTIONS.DOWNLOAD_FACILITY_FILE,
    FACILITY_DOCUMENTS_ACTIONS.UPLOAD_FACILITY_FILE,
    FACILITY_DOCUMENTS_ACTIONS.UPDATE_FACILITY_FILE,
    FACILITY_DOCUMENTS_ACTIONS.DELETE_FACILITY_FILE,
    FACILITY_DOCUMENTS_ACTIONS.PROJECT_FACILITY_PAGE_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoad,
  dispatchOnUnload: onUnload,
  dispatchDropFiles: setFile,
  dispatchDeleteFile: deleteFile,
  dispatchUpdateFile: updateFile,
  dispatchUploadFiles: uploadFiles,
  dispatchDownloadFile: downloadFile,
};

const styles = (theme) => ({
  create: {
    zIndex: 2,
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  dragOver: {
    opacity: '0.3',
    border: '5px gray dashed',
  },
  basePageChildren: {
    flexDirection: 'row',
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(FacilityDocumentsContainer);
