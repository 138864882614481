import { compose } from 'redux';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import AccountCircle from '@material-ui/icons/AccountCircle';

import { ApplicationSidebar as ApplicationSidebarCore } from 'altus-ui-components';

import RoutesContainer from 'app/components/RoutesContainer';
import { getApplicationHeaderFromState } from 'app/app.selectors';
import LogoAndAppTitle from 'app/components/ApplicationHeader/LogoAndAppTitle';
import UserMenuContainer from 'app/components/ApplicationHeader/UserMenuContainer';
import ApplicationSidebarTopContent from 'app/components/ApplicationHeader/ApplicationSidebarTopContent';

const ApplicationSidebar = ({ routes, classes }) => {
  const applicationHeader = useSelector(getApplicationHeaderFromState);

  const renderHeader = useCallback(
    () => <LogoAndAppTitle to={applicationHeader.get('logoNavigation')} />,
    [applicationHeader],
  );

  const renderTopContent = useCallback(
    () => <ApplicationSidebarTopContent />,
    [],
  );

  const renderBottomContent = useCallback(
    () => (
      <UserMenuContainer
        hideUsername
        Icon={AccountCircle}
        classes={{
          triggerIconRoot: classes.userMenuIcon,
        }}
      />
    ),
    [classes],
  );

  const renderApplicationSidebar = useCallback(
    (routes) => (
      <ApplicationSidebarCore
        routes={routes}
        renderHeader={renderHeader}
        renderTopContent={renderTopContent}
        renderBottomContent={renderBottomContent}
      />
    ),
    [renderHeader, renderTopContent, renderBottomContent],
  );

  return <RoutesContainer routes={routes} render={renderApplicationSidebar} />;
};

const styles = (theme) => ({
  userMenuIcon: {
    color: theme.palette.secondary.contrastText,
  },
});

export default compose(withStyles(styles))(ApplicationSidebar);
