import classNames from 'classnames';
import { SortableElement } from 'react-sortable-hoc';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';
import { PureComponent, createElement, Fragment } from 'react';

import {
  Grid,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionActions,
} from '@material-ui/core';

import { KEYS } from 'app/app.constants';
import { invokeIfFunction } from 'utils/app.util';
import { IconButton } from 'app/components/withTooltip';

const styles = (theme) => {
  return {
    paper: {
      'display': 'flex',
      'minHeight': 45,
      'outline': 'none',
      'padding': '5px 24px',
      'position': 'relative',
      'background': theme.palette.table.row.main,
      'marginBottom': 2,
      '&:hover': {
        background: theme.palette.table.row.hover,
      },
    },
    selected: {
      'background': theme.palette.table.row.selected,
      '&:hover': {
        background: theme.palette.table.row.selected,
      },
    },
    paperExpandable: {
      '&:hover': {
        cursor: 'pointer',
      },
      '&$header': {
        '&:hover': {
          background: theme.palette.table.header.hover,
        },
      },
    },
    paperExpanded: {
      borderBottom: 0,
    },
    expansionPanelRoot: {
      'backgroundColor': theme.palette.table.row.main,
      '&:before': {
        height: 0,
      },
      '&.Mui-expanded': {
        marginTop: 0,
        marginBottom: 16,
        width: '100%',
      },
    },
    expansionPanelExpanded: {
      marginTop: 0,
      marginBottom: 16,
      width: '100%',
    },
    expansionPanelDetailsRoot: {
      padding: '24px 24px 0',
    },
    expandIconHidden: {
      visibility: 'hidden',
    },
    expansionPanelActionsRoot: {},
    header: {
      'background': theme.palette.table.header.main,
      'borderBottom': `2px solid ${theme.palette.table.row.hover}`,
      'marginBottom': theme.spacing(0.5),
      '&:hover': {
        background: theme.palette.table.header.hover,
      },
      'marginTop': 0,
    },
    stickyHeader: {
      position: 'sticky',
      top: 0,
      zIndex: 2,
    },
    transparent: {
      'background': 'transparent',
      'boxShadow': 'none',
      '&:hover': {
        background: 'transparent',
      },
    },
    fullWidth: {
      width: '100%',
    },
    expandIcon: {},
    expandBtnActive: {},
  };
};

class PaperListItem extends PureComponent {
  state = {
    expandBtnActive: false,
  };

  render() {
    const {
      direction,
      isHeader,
      onClick,
      classes,
      Details,
      Actions,
      children,
      fullWidth,
      expandable,
      selected,
      isToggled,
      PaperProps,
      hideActions,
      transparent,
      hideExpandIcon,
      isStickyHeader,
      PaperComponent,
      ExpandButtonProps,
    } = this.props;

    return (
      <>
        {createElement(
          PaperComponent,
          {
            'tabIndex': 0,
            'role': 'button',
            'square': true,
            'elevation': 0,
            onClick,
            'aria-expanded': isToggled,
            'className': classNames(classes.paper, {
              [classes.header]: isHeader,
              [classes.selected]: selected,
              [classes.fullWidth]: fullWidth,
              [classes.transparent]: transparent,
              [classes.stickyHeader]: isStickyHeader,
              [classes.paperExpanded]: isToggled,
              [classes.paperExpandable]:
                (expandable || onClick) && !hideExpandIcon,
              [classes.expandBtnActive]: this.state.expandBtnActive,
            }),
            'onKeyDown': (e) => {
              if (
                expandable &&
                !hideExpandIcon &&
                [KEYS.ENTER, KEYS.SPACE].includes(e.key)
              ) {
                e.preventDefault();
                invokeIfFunction(onClick);
              }
            },
            ...PaperProps,
          },
          <Grid
            container
            alignItems="center"
            direction={direction}
            justifyContent="space-between"
          >
            <Grid item xs>
              {children}
            </Grid>
            {expandable && (
              <Grid item>
                <IconButton
                  onMouseOver={() => this.setState({ expandBtnActive: true })}
                  onMouseLeave={() => this.setState({ expandBtnActive: false })}
                  title={isToggled ? 'Collapse' : 'Expand'}
                  className={classNames(classes.expandIcon, {
                    [classes.expandIconHidden]: hideExpandIcon,
                  })}
                  {...ExpandButtonProps}
                >
                  {isToggled ? (
                    <ExpandLess fontSize="small" />
                  ) : (
                    <ExpandMore fontSize="small" />
                  )}
                </IconButton>
              </Grid>
            )}
          </Grid>,
        )}
        {expandable && (
          <Accordion
            expanded={isToggled}
            classes={{
              expanded: classes.expansionPanelExpanded,
              root: classes.expansionPanelRoot,
            }}
          >
            <div></div>
            {Actions && !hideActions && isToggled && (
              <Grid container>
                <AccordionActions
                  classes={{
                    root: classes.expansionPanelActionsRoot,
                  }}
                >
                  {Actions}
                </AccordionActions>
              </Grid>
            )}
            {isToggled && (
              <Grid container>
                <AccordionDetails
                  classes={{
                    root: classes.expansionPanelDetailsRoot,
                  }}
                >
                  {Details}
                </AccordionDetails>
              </Grid>
            )}
          </Accordion>
        )}
      </>
    );
  }
}

PaperListItem.defaultProps = {
  expandable: false,
  Actions: <Fragment />,
  PaperComponent: Paper,
  fullWidth: true,
};

const StyledPaperListItem = withStyles(styles, { name: 'PaperListItem' })(
  PaperListItem,
);

export const SortablePaperListItem = SortableElement(StyledPaperListItem);

export default StyledPaperListItem;
