import { Map } from 'immutable';

import {
  WellStatus,
  WELLS_ACTIONS as WELLBORE_ACTIONS,
} from 'features/wells/wells.constants';

const initialState = Map({
  wellStatuses: Map([
    [
      WellStatus.ONLINE_OPERATIONAL.toString(),
      Map({
        id: WellStatus.ONLINE_OPERATIONAL,
        name: 'Online/Operational',
      }),
    ],
  ]),
});

const wellboresReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case WELLBORE_ACTIONS.WELLS_PAGE_LOADED: {
      if (action.error) return state;

      const [wellStatuses] = action.payload;

      return state.setIn(
        ['wellStatuses'],
        Map(
          wellStatuses.map((wellStatus) => [
            wellStatus.id.toString(),
            Map({
              id: wellStatus.id,
              name: wellStatus.name,
            }),
          ]),
        ),
      );
    }

    default:
      return state;
  }
};

export default wellboresReducer;
