import { Formik } from 'formik';
import { useEffect, useRef, useMemo } from 'react';

import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import FluidForm from 'app/components/FluidsFormSection/FluidForm';

const EditFluidForm = ({
  disabled,
  isCreateMode,
  fluid,
  toggleCreateMode,
  updateFluid,
}) => {
  const isCreateModeRef = useRef(isCreateMode);

  useEffect(() => {
    if (isCreateModeRef.current) {
      toggleCreateMode();
    }
  }, [toggleCreateMode]);

  const initialValues = useMemo(() => fluid?.toJS(), [fluid]);

  if (!fluid) return null;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={updateFluid}
    >
      {(formik) => (
        <AutoSaveFormik>
          <FluidForm disabled={disabled} {...formik} />
        </AutoSaveFormik>
      )}
    </Formik>
  );
};

export default EditFluidForm;
