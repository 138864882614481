import Agent from 'infrastructure/agent';
import wellboreSectionMappers from 'features/wells/sections/wellboreSection.mappers';

const getDefaultWellboreSection = (fieldId, wellboreId) =>
  Agent.get(`/field/${fieldId}/wellbore/${wellboreId}/section/default`);

const getWellboreSection = (fieldId, wellboreId, wellboreSectionId) =>
  Agent.get(
    `/field/${fieldId}/wellbore/${wellboreId}/section/${wellboreSectionId}`,
  );

const getWellboreSectionsForWellbore = (fieldId, wellboreId) =>
  Agent.get(`/field/${fieldId}/wellbore/${wellboreId}/section`);

const createWellboreSection = (fieldId, wellboreId, wellboreSection) =>
  Agent.post(
    `/field/${fieldId}/wellbore/${wellboreId}/section`,
    wellboreSectionMappers.WellboreSection.to(wellboreSection),
  );

const updateWellboreSection = (
  fieldId,
  wellboreId,
  wellboreSectionId,
  wellboreSection,
) =>
  Agent.put(
    `/field/${fieldId}/wellbore/${wellboreId}/section/${wellboreSectionId}`,
    wellboreSectionMappers.WellboreSection.to(wellboreSection),
  );

const deleteWellboreSection = (fieldId, wellboreId, wellboreSectionId) =>
  Agent.delete(
    `/field/${fieldId}/wellbore/${wellboreId}/section/${wellboreSectionId}`,
  );

const createWellboreSectionNipple = (
  fieldId,
  wellboreId,
  wellboreSectionId,
  wellboreSectionNipple,
) =>
  Agent.post(
    `/field/${fieldId}/wellbore/${wellboreId}/section/${wellboreSectionId}/nipple`,
    wellboreSectionMappers.WellboreSectionNipple.to(wellboreSectionNipple),
  );

const updateWellboreSectionNipple = (
  fieldId,
  wellboreId,
  wellboreSectionId,
  wellboreSectionNippleId,
  wellboreSectionNipple,
) =>
  Agent.put(
    `/field/${fieldId}/wellbore/${wellboreId}/section/${wellboreSectionId}/nipple/${wellboreSectionNippleId}`,
    wellboreSectionMappers.WellboreSectionNipple.to(wellboreSectionNipple),
  );

const deleteWellboreSectionNipple = (
  fieldId,
  wellboreId,
  wellboreSectionId,
  wellboreSectionNippleId,
) =>
  Agent.delete(
    `/field/${fieldId}/wellbore/${wellboreId}/section/${wellboreSectionId}/nipple/${wellboreSectionNippleId}`,
  );

const wellboreSectionService = {
  getWellboreSection,
  updateWellboreSection,
  createWellboreSection,
  deleteWellboreSection,
  getDefaultWellboreSection,
  createWellboreSectionNipple,
  updateWellboreSectionNipple,
  deleteWellboreSectionNipple,
  getWellboreSectionsForWellbore,
};

export default wellboreSectionService;
