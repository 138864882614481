import withStyles from '@material-ui/styles/withStyles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { Button as NavigationButton } from 'app/components/withNavigation';

const MobileBackButton = ({ to, classes, children, ...rest }) => (
  <NavigationButton
    to={to}
    color="primary"
    // variant=""
    className={classes.backButton}
    {...rest}
  >
    <ChevronLeft className={classes.backButtonIcon} />
    {children}
  </NavigationButton>
);

const styles = {
  backButtonIcon: {
    marginRight: 5,
    width: '60%',
    height: '60%',
  },
};

export default withStyles(styles)(MobileBackButton);
