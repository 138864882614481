import { compose } from 'redux';
import { useCallback, useMemo } from 'react';
import { Formik, Form } from 'formik';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import { IconButton } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { TableRow, TableCell, TableRowActionsCell } from 'altus-ui-components';

import { required } from 'utils/validation.util';
import { invokeIfFunction } from 'utils/app.util';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import { WellboreSectionFormFields as FormFields } from 'features/wells/sections/components/WellboreSectionsTable';

const CreateWellboreSectionNippleRow = ({
  classes,
  columns,
  wellboreSectionId,
  toggleCreateMode,
  createWellboreSectionNipple,
  wellboreSection,
}) => {
  const onSubmit = useCallback(
    (wellboreSectionNipple, formik) =>
      createWellboreSectionNipple(
        wellboreSectionNipple,
        formik,
        toggleCreateMode,
      ),
    [toggleCreateMode, createWellboreSectionNipple],
  );

  const getTopValue = useCallback(() => {
    var result = 0;
    let length = 0;
    let top = 0;

    if (wellboreSection?.size) {
      Array.from(wellboreSection.values())
        .pop()
        .map((value, i) => {
          if (i === 'bottom' && result === 0) {
            result = value.value;
          }

          if (i === 'wellboreSectionNipples') {
            Array.from(value.values())
              .pop()
              ?.map((nipple, j) => {
                switch (j) {
                  case 'length':
                    length = nipple.value;
                    break;
                  case 'top':
                    top = nipple.value;
                    break;
                  default:
                    break;
                }

                result = length + top;
                return result;
              });
          }

          return result;
        });
    }

    return result;
  }, [wellboreSection]);

  let topValue = {
    hasValue: true,
    unit: 'm',
    value: getTopValue(),
  };

  const initialTopValue = useMemo(
    () => ({
      hasValue: true,
      roundedValue: getTopValue(),
      unit: 'm',
      value: getTopValue(),
    }),
    [getTopValue],
  );

  let initialValues = useMemo(
    () => ({
      wellboreSectionId,
      [FormFields.TOP]: initialTopValue,
    }),
    [initialTopValue, wellboreSectionId],
  );

  // simplify getting 'xs' and 'Header' values from column
  const renderCell = (index, render) => {
    const column = columns[index];
    return (
      <TableCell item xs={column.xs}>
        {invokeIfFunction(render, column)}
      </TableCell>
    );
  };

  return (
    <Formik validateOnMount onSubmit={onSubmit} initialValues={initialValues}>
      {({ isValid, isSubmitting, handleSubmit, dirty }) => (
        <Form>
          <TableRow fadeIn={false}>
            {renderCell(0, () => (
              <TableRowActionsCell minItems={1} />
            ))}
            {renderCell(1)}
            {renderCell(2, (column) => (
              <TableRowTextFieldFormik
                validate={required}
                name={FormFields.TYPE}
                placeholder={`Nipple ${column.Header}`}
              />
            ))}
            {renderCell(3, () => (
              <TableRowTextFieldFormik
                type="number"
                value={topValue}
                validate={required}
                name={FormFields.TOP}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
              />
            ))}
            {renderCell(4)}
            {renderCell(5, () => (
              <TableRowTextFieldFormik
                type="number"
                validate={required}
                name={FormFields.LENGTH}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
              />
            ))}
            {renderCell(6, () => (
              <TableRowTextFieldFormik
                type="number"
                validate={required}
                name={FormFields.INNER_DIAMETER}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
              />
            ))}
            {renderCell(7)}
            {renderCell(8)}
            <TableCell item>
              <TableRowActionsCell>
                <IconButton
                  title="Save"
                  onClick={handleSubmit}
                  className={classes.saveButton}
                  disabled={!isValid || isSubmitting || !dirty}
                >
                  <Check fontSize="small" />
                </IconButton>
                <IconButton title="Cancel" onClick={toggleCreateMode}>
                  <Close fontSize="small" />
                </IconButton>
              </TableRowActionsCell>
            </TableCell>
          </TableRow>
        </Form>
      )}
    </Formik>
  );
};

const styles = (theme) => ({
  saveButton: {
    background: theme.palette.success.dark,
    '&:hover': {
      background: theme.palette.success.main,
    },
  },
});

export default compose(withStyles(styles))(CreateWellboreSectionNippleRow);
