import { compose } from 'redux';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, CircularProgress } from '@material-ui/core';

import { ReceivedDataState, DataState } from 'altus-datastate';

const ToolstringPreviewContainer = ({
  classes,
  dataState,
  children,
  ...rest
}) => {
  if (dataState.isLoading()) {
    return (
      <Grid
        item
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <CircularProgress className={classes.progress} />
      </Grid>
    );
  }

  return (
    <Grid
      item
      container
      wrap="nowrap"
      direction="column"
      classes={{ root: classes.root }}
      {...rest}
    >
      {children}
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    height: '96%',
    paddingTop: (prop) => (prop.paddingTopPreview ? theme.spacing(2) : 0),
  },
  progress: {
    color: theme.palette.text.hint,
  },
});

ToolstringPreviewContainer.defaultProps = {
  dataState: ReceivedDataState,
};

ToolstringPreviewContainer.propTypes = {
  children: PropTypes.node,
  dataState: PropTypes.instanceOf(DataState),
};
export default compose(withStyles(styles))(ToolstringPreviewContainer);
