import { getTaskActivities } from 'features/projects/activities/activities.actions';
import { getTaskById } from 'features/projects/tasks/task/toolstring/toolstring.actions';
import { TASK_ACTIVITIES_ACTIONS } from 'features/projects/tasks/task/activities/taskActivities.constants';
import toolstringService from 'services/toolstring.service';
import { addNotification } from 'altus-redux-middlewares';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';

export const onLoad = (projectId, taskId) => (dispatch) => {
  dispatch({
    type: TASK_ACTIVITIES_ACTIONS.TASK_ACTIVITIES_PAGE_LOADED,
    payload: () =>
      Promise.all([
        dispatch(getTaskActivities(projectId, taskId)),
        dispatch(getTaskById(projectId, taskId)),
      ]),
  });
};

export const loadAndFilterPlannedToolstring =
  (projectId, taskId) => (dispatch) => {
    dispatch({
      type: TASK_ACTIVITIES_ACTIONS.TASK_ACTIVITIES_PLANNED_TOOLSTRING_LOADED,
      payload: () =>
        toolstringService
          .getPlannedToolstring(projectId, taskId)
          .then((toolstring) => dispatch(receivePlannedToolstring(toolstring)))
          .catch((error) => {
            if (error?.response?.body?.hasErrors) {
              var message = error.response.body.errors[0];

              dispatch(receiveNoPlannedToolstring());
              dispatch(
                addNotification({
                  message,
                  variant: NOTIFICATION_VARIANTS.ERROR,
                }),
              );
            }
          }),
    });
  };

const receivePlannedToolstring = (toolstring) => (dispatch) => {
  dispatch({
    type: TASK_ACTIVITIES_ACTIONS.TASK_ACTIVITIES_RECEIVE_PLANNED_TOOLSTRING,
    payload: toolstring,
  });
};

const receiveNoPlannedToolstring = () => (dispatch) => {
  dispatch({
    type: TASK_ACTIVITIES_ACTIONS.TASK_ACTIVITIES_RECEIVE_NO_TOOLSTRING,
  });
};
