import { useEffect, useCallback } from 'react';
import { InputAdornment } from '@material-ui/core';

import { EMPTY_QUANTITY, EMPTY_STRING } from 'app/app.constants';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';

const createDefaultQuantity = (unit) => ({
  ...EMPTY_QUANTITY,
  unit,
});

const QuantityTextFieldFormik = ({
  form,
  field,
  InputProps,
  useRoundedValue = false,
  destroyOnUnmount = false,
  minValue = null,
  ...rest
}) => {
  const { setFieldValue } = form;
  const { name, value: fieldValue = EMPTY_QUANTITY } = field;
  const { unit, value, roundedValue, hasValue } = fieldValue;

  const onChange = useCallback(
    (event) => {
      let inputValue = event.target.value;

      if (minValue !== null && inputValue < minValue) {
        inputValue = minValue;
      }

      setFieldValue(name, {
        unit,
        value: inputValue,
        hasValue: !!inputValue,
      });
    },
    [name, unit, setFieldValue, minValue],
  );

  useEffect(
    () => () => {
      if (destroyOnUnmount) {
        // clear value and unregister field
        setFieldValue(name, createDefaultQuantity(unit));
      }
    },
    [name, unit, setFieldValue, destroyOnUnmount],
  );

  const checkForRoundedValue = useCallback(() => {
    if (hasValue) {
      if (useRoundedValue) {
        return roundedValue;
      } else {
        return value;
      }
    } else {
        if (minValue !== null) {
        return minValue;
      }
      
      return EMPTY_STRING;
    }
  }, [hasValue, useRoundedValue, roundedValue, value, minValue]);

  return (
    <TextFieldFormik
      {...rest}
      form={form}
      field={field}
      onChange={onChange}
      destroyOnUnmount={false}
      value={checkForRoundedValue()}
      InputProps={{
        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
        ...InputProps,
      }}
    />
  );
};

export default QuantityTextFieldFormik;
