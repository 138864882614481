import toolService from 'services/tool.service';
import { getToolSuppliers } from 'features/equipment/equipment.actions';
import {
  EQUIPMENT_DETAILS_ACTIONS,
  SURFACE_EQUIPMENT_DETAILS_ACTIONS,
} from 'features/equipment/details/equipmentDetails.constants';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import equipmentService from 'features/equipment/equipment.service';

export const onLoad = (toolId) => (dispatch) => {
  dispatch(getToolSuppliers());
  dispatch({
    type: EQUIPMENT_DETAILS_ACTIONS.PAGE_LOADED,
    payload: () => toolService.getTool(toolId),
  });
};

export const onUnload = () => (dispatch) => {
  dispatch({ type: EQUIPMENT_DETAILS_ACTIONS.UNLOAD_TOOL });
};

export const onUnloadCable = () => (dispatch) => {
  dispatch({ type: EQUIPMENT_DETAILS_ACTIONS.UNLOAD_CABLE });
};

export const onLoadSurfaceEquipmentDetail = (equipmentId) => (dispatch) => {
  const payload = equipmentService
    .getSurfaceEquipmentById(equipmentId)
    .then((payload) => {
      dispatch({
        type: SURFACE_EQUIPMENT_DETAILS_ACTIONS.PAGE_LOADED,
        payload,
      });

      return payload;
    })
    .catch(() => {
      dispatch({
        hideable: true,
        notification: {
          message: 'Not Found.',
          variant: NOTIFICATION_VARIANTS.ERROR,
        },
        type: SURFACE_EQUIPMENT_DETAILS_ACTIONS.PAGE_LOADED,
        payload,
      });
    });
  return payload;
};

export const onUnloadSurfaceEquipment = () => (dispatch) => {
  dispatch({ type: SURFACE_EQUIPMENT_DETAILS_ACTIONS.UNLOAD_TOOL });
};
