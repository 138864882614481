import { Map } from 'immutable';
import { formatValue } from 'utils/format.util';
import { TOOL_STATUS } from 'features/projects/tasks/task/toolstring/toolstring.constants';

export const formatToolstringTotalLength = (totalLength) => {
  if (!totalLength) return 'N/A';

  if (Map.isMap(totalLength))
    return formatValue(
      totalLength.get('value'),
      totalLength.get('unit'),
      0.001,
    );

  return formatValue(totalLength.value, totalLength.unit, 0.001);
};

export const formatToolstringTotalWeight = (totalWeight) => {
  if (!totalWeight) return 'N/A';

  if (Map.isMap(totalWeight))
    return formatValue(totalWeight.get('value'), totalWeight.get('unit'), 0.01);

  return formatValue(totalWeight.value, totalWeight.unit, 0.01);
};

export const formatToolstringToolStatusString = (toolStatus) => {
  if (toolStatus === TOOL_STATUS.NOT_ASSIGNED) return 'N/A';
  if (toolStatus === TOOL_STATUS.SET) return 'Set Tool';
  if (toolStatus === TOOL_STATUS.RETRIEVE) return 'Retrieve Tool';
};
