import service from 'services/app.service';
import teamService from 'services/team.service';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import { getProjectById } from 'features/projects/projects.actions';
import { TEAM_ACTIONS } from 'features/projects/team/team.constants';
import { getProjectRoles } from 'authorization/authorization.actions';

export const getProjectMember = (projectId, projectMemberId) => (dispatch) => {
  const payload = teamService.getProjectMember(projectId, projectMemberId);

  dispatch({
    payload,
    type: TEAM_ACTIONS.GET_PROJECT_MEMBER,
  });

  return payload;
};

export const receiveProjectMember = (projectMember) => ({
  type: TEAM_ACTIONS.RECEIVE_PROJECT_MEMBER,
  payload: projectMember,
});

export const assignProjectFieldCrewToRole =
  (projectId, projectMember, roleId) => (dispatch) => {
    const userId = projectMember.get('userId');
    const projectMemberFieldCrewId = projectMember.get(
      'projectMemberFieldCrewId',
    );

    dispatch({
      userId,
      roleId,
      type: TEAM_ACTIONS.ASSIGN_PROJECT_FIELD_CREW_TO_ROLE,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'Team member was successfully assigned a role',
      },
      payload: () =>
        teamService
          .assignProjectFieldCrewToRole(
            projectId,
            projectMemberFieldCrewId,
            roleId,
          )
          .then(() => dispatch(getFieldCrews(projectId)))
          .then(() => dispatch(getProjectById(projectId))),
    });
  };

export const assignProjectMemberToRole =
  (projectMember, roleId) => (dispatch) => {
    const userId = projectMember.get('userId');
    const projectId = projectMember.get('projectId');
    const projectMemberId = projectMember.get('projectMemberId');

    dispatch({
      userId,
      roleId,
      type: TEAM_ACTIONS.ASSIGN_PROJECT_MEMBER_TO_ROLE,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'Team member was successfully assigned a role',
      },
      payload: () =>
        teamService
          .assignProjectMemberToRole(projectId, projectMemberId, roleId)
          .then(() => dispatch(getProjectMember(projectId, projectMemberId)))
          .then(() => dispatch(getProjectById(projectId))),
    });
  };

export const assignProjectMemberTeamRole =
  (projectMember, teamProjectRole) => (dispatch) => {
    const userId = projectMember.get('userId');
    const projectId = projectMember.get('projectId');
    const projectMemberId = projectMember.get('projectMemberId');
    const teamProjectRoleId = teamProjectRole.get('teamProjectRoleId');

    dispatch({
      userId,
      teamProjectRoleId,
      type: TEAM_ACTIONS.UPDATE_MEMBER_TEAM_PROJECT_ROLE,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Team member was successfully updated',
      },
      payload: () =>
        teamService
          .assignProjectMemberTeamRole(
            projectId,
            projectMemberId,
            teamProjectRole.toJS(),
          )
          .then(() => dispatch(getAllProjectMembers(projectId)))
          .then(() => dispatch(getProjectById(projectId))),
    });
  };

export const getAllAvailableUsers = (projectId) => (dispatch) => {
  const payload = teamService.allAvailableUsers(projectId);

  dispatch({
    payload,
    type: TEAM_ACTIONS.GET_ALL_AVAILABLE_USERS,
  });

  return payload;
};

export const getAllAvailableFieldCrewUsers = (projectId) => (dispatch) => {
  const payload = teamService.allAvailableUsers(projectId);

  dispatch({
    payload,
    type: TEAM_ACTIONS.GET_ALL_AVAILABLE_FIELD_CREW_USERS,
  });

  return payload;
};

export const onLoad = (projectId) => (dispatch) => {
  dispatch({
    type: TEAM_ACTIONS.PROJECT_MEMBER_PAGE_LOADED,
    payload: () =>
      dispatch(getProjectRoles()).then(() => {
        return Promise.all([
          dispatch(getAllAvailableUsers(projectId)),
          dispatch(getAllProjectMembers(projectId)),
          dispatch(getAllTeamProjectRoles(projectId)),
        ]);
      }),
  });
};

export const onLoadProjectFieldCrew = (projectId) => (dispatch) => {
  dispatch({
    type: TEAM_ACTIONS.PROJECT_FIELD_CREW_PAGE_LOADED,
    payload: () =>
      dispatch(getProjectRoles()).then(() => {
        return Promise.all([
          dispatch(getAllAvailableFieldCrewUsers(projectId)),
          dispatch(getFieldCrews(projectId)),
          dispatch(getCrews(projectId)),
          dispatch(getQualifications(projectId)),
        ]);
      }),
  });
};

export const getQualifications = (projectId) => (dispatch) => {
  const payload = teamService.getQualifications(projectId);

  dispatch({
    payload,
    type: TEAM_ACTIONS.GET_PROJECT_FIELD_QUALIFICATIONS,
  });

  return payload;
};

export const getCrews = (projectId) => (dispatch) => {
  const payload = teamService.getCrews(projectId);

  dispatch({
    payload,
    type: TEAM_ACTIONS.GET_PROJECT_FIELD_CREWS,
  });

  return payload;
};

export const onUnload = () => (dispatch) =>
  dispatch({ type: TEAM_ACTIONS.PROJECT_TEAM_PAGE_UNLOADED });

export const getAllProjectMembers = (projectId) => (dispatch) => {
  const payload = service
    .allMembers(projectId)
    .then((projectMembers) => dispatch(receiveProjectMembers(projectMembers)));

  dispatch({
    type: TEAM_ACTIONS.GET_ALL_PROJECT_MEMBERS,
    payload,
  });

  return payload;
};

export const getAllTeamProjectRoles = (projectId) => (dispatch) => {
  const payload = teamService
    .getTeamProjectRoles(projectId)
    .then((fieldCrews) => dispatch(receiveProjectTeamRoles(fieldCrews)));

  dispatch({
    type: TEAM_ACTIONS.GET_TEAM_PROJECT_ROLES,
    payload,
  });

  return payload;
};

export const getFieldCrews = (projectId) => (dispatch) => {
  const payload = teamService
    .getFieldCrew(projectId)
    .then((fieldCrews) => dispatch(receiveProjectFieldCrews(fieldCrews)));

  dispatch({
    type: TEAM_ACTIONS.GET_PROJECT_FIELD_MEMBER_CREWS,
    payload,
  });

  return payload;
};

export const deleteProjectMember =
  (projectId, projectMemberId, memberName) => (dispatch) => {
    return dispatch({
      projectMemberId,
      type: TEAM_ACTIONS.DELETE_PROJECT_MEMBER,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: `${memberName} was deleted from Onshore Team`,
      },
      confirmationDialog: {
        confirmButtonText: 'Remove',
        title: 'Remove Onshore Team Member',
        description: `Are you sure you want to remove ${memberName} from the Onshore team?`,
      },
      payload: () =>
        teamService.deleteProjectMember(projectId, projectMemberId),
    });
  };

export const addUsersToProject = (projectId, selectedUsers) => (dispatch) => {
  const userIds = selectedUsers.map((user) => user.get('id')).toArray();

  const userMsgPart =
    userIds.length <= 1
      ? `${selectedUsers.first().get('name')} was`
      : `${userIds.length} members were`;

  const payload = teamService
    .addProjectMembers(projectId, userIds)
    .then((projectMembers) => dispatch(receiveProjectMembers(projectMembers)));

  dispatch({
    payload,
    type: TEAM_ACTIONS.ADD_PROJECT_MEMBERS,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: `${userMsgPart} added to the Onshore team`,
    },
  });

  return payload;
};

export const addFieldCrewToProject =
  (projectId, selectedFieldCrews) => (dispatch) => {
    const userIds = selectedFieldCrews.map((user) => user.get('id')).toArray();

    const userMsgPart =
      userIds.length <= 1
        ? `${selectedFieldCrews.first().get('name')} was`
        : `${userIds.length} members were`;

    const payload = teamService
      .addProjectFieldCrews(projectId, userIds)
      .then((projectMembers) =>
        dispatch(receiveProjectFieldCrews(projectMembers)),
      );

    dispatch({
      payload,
      type: TEAM_ACTIONS.ADD_PROJECT_FIELD_CREW,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: `${userMsgPart} added to the Field crew team`,
      },
    });

    return payload;
  };

export const deleteProjectFieldCrew =
  (projectId, projectMemberId, memberName) => (dispatch) => {
    return dispatch({
      projectMemberId,
      type: TEAM_ACTIONS.DELETE_PROJECT_FIELD_CREW,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: `${memberName} was deleted from Field Crew`,
      },
      confirmationDialog: {
        confirmButtonText: 'Remove',
        title: 'Remove Field Crew Team Member',
        description: `Are you sure you want to remove ${memberName} from the Field crew team?`,
      },
      payload: () =>
        teamService.deleteProjectFieldCrew(projectId, projectMemberId),
    });
  };

export const receiveProjectMembers = (projectMembers) => ({
  payload: projectMembers,
  type: TEAM_ACTIONS.RECEIVE_PROJECT_MEMBERS,
});

export const receiveProjectFieldCrews = (fieldCrews) => ({
  payload: fieldCrews,
  type: TEAM_ACTIONS.RECEIVE_PROJECT_FIELD_CREWS,
});

export const receiveProjectTeamRoles = (projectTeamRoles) => ({
  payload: projectTeamRoles,
  type: TEAM_ACTIONS.RECEIVE_TEAM_PROJECT_ROLES,
});

export const receiveProjectFieldCrew = (fieldCrew) => ({
  type: TEAM_ACTIONS.RECEIVE_PROJECT_FIELD_CREW,
  payload: fieldCrew,
});

export const updateProjectFieldCrew =
  (projectId, projectMemId, vals) => (dispatch) => {
    const payload = teamService
      .updateProjectFieldCrew(projectId, projectMemId, vals)
      .then(() => dispatch(getFieldCrews(projectId)));

    dispatch({
      projectMemberFieldCrewId: projectMemId,
      payload,
      type: TEAM_ACTIONS.UPDATE_PROJECT_FIELD_CREW,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: 'Team was successfully updated',
      },
    });

    return payload;
  };
