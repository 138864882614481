import { compose } from 'redux';
import { Box, Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { useCallback, useMemo, useRef, useState } from 'react';

import useProjectWellbore from 'features/projects/hooks/useProjectWellbore';
import useProjectWellboreTrajectory from 'features/wells/hooks/useProjectWellboreTrajectory';
import CaliperDashboard from './components/CaliperDashboard';
import CorrelationDashboard from './components/CorrelationDashboard';
import LoggingNavigatorChart from './components/LoggingNavigatorChart';

// TODO: Add to Dashboard db?
const maxNumberOfCurvesByComponent = {
  [CaliperDashboard]: 8,
  [CorrelationDashboard]: 3,
};

const LoggingDashboardContainer = ({
  classes,
  projectId,
  setExtremes,
  curves: allCurves,
  registerDataPointsHandler,
  component: DashboardComponent,
}) => {
  const depthCurves = useMemo(
    () => allCurves?.filter((curve) => curve.get('isDepth')),
    [allCurves],
  );

  const depthUnit = useMemo(
    () =>
      depthCurves.size > 0
        ? depthCurves
            ?.find((curve) => curve.get('abbreviation') === 'WD')
            .get('unit')
        : null,
    [depthCurves],
  );

  const curves = useMemo(
    () =>
      allCurves
        ?.filter((curve) => !curve.get('isDepth'))
        .take(maxNumberOfCurvesByComponent[DashboardComponent]),
    [allCurves, DashboardComponent],
  );

  const [wellbore] = useProjectWellbore(projectId);
  const [wellboreTrajectory] = useProjectWellboreTrajectory(projectId);

  const fromTimestampRef = useRef();
  const toTimestampRef = useRef();
  const fromDepthRef = useRef();
  const toDepthRef = useRef();

  const [depthData, setDepthData] = useState();

  const onSetTimeExtremes = useCallback(
    (fromTimestamp, toTimestamp) =>
      setExtremes({
        toDepth: toDepthRef.current,
        fromDepth: fromDepthRef.current,
        toTimestamp,
        fromTimestamp,
      }).then((readings) => {
        fromTimestampRef.current = fromTimestamp;
        toTimestampRef.current = toTimestamp;
        setDepthData(readings);
      }),
    [setExtremes],
  );

  const onSetDepthExtremes = useCallback(
    (fromDepth, toDepth) => {
      setExtremes({
        toDepth,
        fromDepth,
        toTimestamp: toTimestampRef.current,
        fromTimestamp: fromTimestampRef.current,
      }).then((readings) => {
        fromDepthRef.current = fromDepth;
        toDepthRef.current = toDepth;
        setDepthData(readings);
      });
    },
    [setExtremes],
  );

  const measuredDepth = useMemo(
    () =>
      wellboreTrajectory?.getIn(['measuredDepth', 'value']) ??
      wellbore?.getIn(['md', 'value']),
    [wellbore, wellboreTrajectory],
  );

  if (!allCurves) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <Typography variant="body2" className={classes.noData}>
            No data to display
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item container justifyContent="center">
      <Grid
        item
        xs={8}
        container
        wrap="nowrap"
        paddingTop={1}
        component={Box}
        paddingBottom={1}
        direction="column"
      >
        <Grid item xs className={classes.chart}>
          <DashboardComponent
            curves={curves}
            data={depthData}
            depthUnit={depthUnit}
            measuredDepth={measuredDepth}
            onSetExtremes={onSetDepthExtremes}
            registerDataPointsHandler={registerDataPointsHandler}
          />
        </Grid>
        <Grid item className={classes.navigator}>
          <LoggingNavigatorChart
            curves={depthCurves}
            onSetExtremes={onSetTimeExtremes}
            registerDataPointsHandler={registerDataPointsHandler}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  chart: {
    overflow: 'hidden',
  },
  navigator: {
    height: 300,
  },
});

export default compose(withStyles(styles))(LoggingDashboardContainer);
