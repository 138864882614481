import { compose } from 'redux';
import { Button } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';

import { BasePage } from 'altus-ui-components';

import {
  requestWellboreRevisionForWellbore,
  requestWellboreRevisionFromWellcom,
} from 'features/wells/elements/wellboreElement.actions';

import useWellbore from 'features/wells/hooks/useWellbore';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { ACTIONS } from 'features/wells/elements/wellboreElement.constants';
import WellboreElementsTable from 'features/wells/elements/components/WellboreElementsTable';
import { getWellboreRevisionForWellboreSelector } from 'features/wells/elements/wellboreElement.selector';

const WellboreElementsContainer = ({
  fieldId,
  dataState,
  wellboreId,
  dispatchRequestWellboreRevisionForWellbore,
  dispatchRequestWellboreRevisionFromWellcom,
}) => {
  const { wellbore } = useWellbore(wellboreId);
  useEffect(() => {
    dispatchRequestWellboreRevisionForWellbore(fieldId, wellboreId);
  }, [fieldId, wellboreId, dispatchRequestWellboreRevisionForWellbore]);

  const getWellboreRevisionFromWellcom = useCallback(
    () => dispatchRequestWellboreRevisionFromWellcom(fieldId, wellboreId),
    [fieldId, wellboreId, dispatchRequestWellboreRevisionFromWellcom],
  );

  const wellboreRevisionSelector = useMemo(
    () => getWellboreRevisionForWellboreSelector(wellboreId),
    [wellboreId],
  );

  const wellboreRevision = useSelector(wellboreRevisionSelector);

  return (
    <BasePage title="Revision" dataState={dataState}>
      {wellbore?.get('wellcomId') === null ? (
        <i>This wellbore does not have wellcomId</i>
      ) : (
        <>
          {wellboreRevision ? (
            <>
              <i>
                Revision:
                {wellboreRevision?.getIn([wellboreId.toString(), 'revision'])}
              </i>
              <i>
                Tubing date:
                {wellboreRevision?.getIn([wellboreId.toString(), 'tubingDate'])}
              </i>
              <br />
              <WellboreElementsTable
                wellboreElements={wellboreRevision?.getIn([
                  wellboreId.toString(),
                  'wellboreElements',
                ])}
              />
            </>
          ) : (
            <>
              <i>
                There is no loaded revision for this wellbore. To load from
                wellcom, press button.
              </i>
              <Button
                size="small"
                onClick={() => {
                  getWellboreRevisionFromWellcom();
                }}
              >
                Load revision
              </Button>
            </>
          )}
        </>
      )}
    </BasePage>
  );
};
export default compose(
  connect(
    (state) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        ACTIONS.REQUEST_GET_WELLBORE_ELEMENTS,
        ACTIONS.REQUEST_GET_WELLBORE_ELEMENTS_FROM_WELLCOM,
      ),
    }),
    {
      dispatchRequestWellboreRevisionForWellbore:
        requestWellboreRevisionForWellbore,
      dispatchRequestWellboreRevisionFromWellcom:
        requestWellboreRevisionFromWellcom,
    },
  ),
)(WellboreElementsContainer);
