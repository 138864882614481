import { put, takeEvery, call } from 'redux-saga/effects';
import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';
import {
  getProjectFluids,
  deleteFluid,
  updateFluid,
  createFluid,
} from './project.actions.fluids';
import projectFluidService from 'services/projectFluid.service';
import { callAsync } from 'app/sagas/helperSagas';
import { FormikFormStatus } from 'app/app.constants';

export function* getAllProjectFluids(action) {
  const { projectId, projectWellboreDetailId } = action;
  const fluids = yield call(
    projectFluidService.getAllFluids,
    projectId,
    projectWellboreDetailId,
  );
  yield put(getProjectFluids(fluids));
}

export function* updateProjectFluid(action) {
  const {
    projectId,
    projectWellboreDetailId,
    fluidObj,
    setSubmitting,
    setStatus,
  } = action;
  try {
    yield call(
      projectFluidService.updateFluid,
      projectId,
      projectWellboreDetailId,
      fluidObj,
    );
    yield call(getAllProjectFluids, { projectId, projectWellboreDetailId });
    yield put(updateFluid(fluidObj.fluidId, projectWellboreDetailId));
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);
    throw error;
  }
}

export function* deleteProjectFluid(action) {
  const { projectId, projectWellboreDetailId, fluidId } = action;
  yield call(
    projectFluidService.deleteFluid,
    projectId,
    projectWellboreDetailId,
    fluidId,
  );
  yield call(getAllProjectFluids, { projectId, projectWellboreDetailId });
  yield put(deleteFluid(projectWellboreDetailId, fluidId));
}

export function* addProjectFluid(action) {
  const {
    projectId,
    projectWellboreDetailId,
    fluidObj,
    setSubmitting,
    setStatus,
  } = action;
  try {
    yield call(
      projectFluidService.addFluid,
      projectId,
      projectWellboreDetailId,
      fluidObj,
    );
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);
    yield call(getAllProjectFluids, { projectId, projectWellboreDetailId });
    yield put(createFluid(projectWellboreDetailId, fluidObj));
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);
    throw error;
  }
}

export default function* root() {
  yield takeEvery(
    PROJECTS_ACTIONS.INITIATE_GET_PROJECT_FLUIDS,
    callAsync,
    getAllProjectFluids,
  );
  yield takeEvery(
    PROJECTS_ACTIONS.INITIATE_UPDATE_PROJECT_FLUID,
    callAsync,
    updateProjectFluid,
  );
  yield takeEvery(
    PROJECTS_ACTIONS.INITIATE_DELETE_PROJECT_FLUID,
    callAsync,
    deleteProjectFluid,
  );
  yield takeEvery(
    PROJECTS_ACTIONS.INITIATE_CREATE_PROJECT_FLUID,
    callAsync,
    addProjectFluid,
  );
}
