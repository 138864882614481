import { compose } from 'redux';
import Add from '@material-ui/icons/Add';
import { Fab, Grid } from '@material-ui/core';
import { useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';

import {
  onLoadProjectFieldCrew,
  onUnload,
  deleteProjectFieldCrew,
  updateProjectFieldCrew,
  assignProjectFieldCrewToRole,
} from 'features/projects/team/team.actions';

import { useModal } from 'altus-modal';
import { BasePage } from 'altus-ui-components';

import {
  TEAM_ACTIONS,
  ADD_TEAM_MEMBERS_MODAL_ID,
} from 'features/projects/team/team.constants';
import {
  getFieldCrewKeyedByUserIdFromState,
  getAllAvailableFieldCrewUsersFromState,
  getProjectQualificationsFromState,
  getProjectCrewsFromState,
} from 'features/projects/team/team.selectors';

import { useHeader } from 'app/hooks/useHeader';
import { ProjectPermission } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { getProjectRolesFromState } from 'authorization/authorization.selectors';
import ProjectFieldCrewTable from 'features/projects/team/components/ProjectFieldCrewTable';
import CreateProjectFieldCrewModalContainer from 'features/projects/team/components/CreateProjectFieldCrewModalContainer';

const FieldCrew = ({
  users,
  crews,
  classes,
  dataState,
  projectId,
  breadcrumb,
  projectRoles,
  qualifications,
  dispatchOnLoad,
  dispatchOnUnload,
  fieldCrewByUserId,
  disptachUpdateProjectFieldCrew,
  dispatchDeleteProjectFieldCrew,
  dispatchAssignProjectMemberToRole,
}) => {
  const [isOpen, toggleModal] = useModal(ADD_TEAM_MEMBERS_MODAL_ID);

  useEffect(() => {
    dispatchOnLoad(projectId);

    return () => dispatchOnUnload();
  }, [projectId, dispatchOnLoad, dispatchOnUnload]);

  useHeader({
    subTitle: breadcrumb,
  });

  const deleteProjectFieldCrew = useCallback(
    (projectMemberId, memberName) =>
      dispatchDeleteProjectFieldCrew(projectId, projectMemberId, memberName),
    [projectId, dispatchDeleteProjectFieldCrew],
  );

  const assignRole = useCallback(
    (projectMemberFieldCrewId, roleId) =>
      dispatchAssignProjectMemberToRole(
        projectId,
        projectMemberFieldCrewId,
        roleId,
      ),
    [projectId, dispatchAssignProjectMemberToRole],
  );

  const updateProjectFieldCrew = useCallback(
    (fieldCrew) => {
      return disptachUpdateProjectFieldCrew(
        projectId,
        fieldCrew.projectMemberFieldCrewId,
        fieldCrew,
      );
    },
    [projectId, disptachUpdateProjectFieldCrew],
  );

  const project = useSelector(getCurrentProject);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid item container justifyContent="center">
        <Grid item xs={12} md={12}>
          <ProjectFieldCrewTable
            project={project}
            crews={crews}
            qualifications={qualifications}
            updateProjectFieldCrew={updateProjectFieldCrew}
            projectRoles={projectRoles}
            projectMembers={fieldCrewByUserId}
            deleteProjectMember={deleteProjectFieldCrew}
            assignProjectFieldCrewToRole={assignRole}
          />
          <HasProjectPermission
            permissions={ProjectPermission.ADD_TEAM_MEMBERS}
          >
            <CreateProjectFieldCrewModalContainer
              users={users}
              isOpen={isOpen}
              projectId={projectId}
              title="Add Field Crew Members"
              toggleModal={toggleModal}
              projectMembers={fieldCrewByUserId}
            />
            <Fab
              color="primary"
              onClick={toggleModal}
              className={classes.create}
              title="Add Field Crew Members"
            >
              <Add />
            </Fab>
          </HasProjectPermission>
        </Grid>
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
  create: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(8),
  },
});

export default compose(
  connect(
    (state) => ({
      users: getAllAvailableFieldCrewUsersFromState(state),
      projectRoles: getProjectRolesFromState(state),
      crews: getProjectCrewsFromState(state),
      qualifications: getProjectQualificationsFromState(state),
      fieldCrewByUserId: getFieldCrewKeyedByUserIdFromState(state),
      dataState: getSummarizedDataStateFromState(
        state,
        TEAM_ACTIONS.DELETE_PROJECT_FIELD_CREW,
        TEAM_ACTIONS.PROJECT_FIELD_CREW_PAGE_LOADED,
      ),
    }),
    {
      dispatchOnLoad: onLoadProjectFieldCrew,
      dispatchOnUnload: onUnload,
      disptachUpdateProjectFieldCrew: updateProjectFieldCrew,
      dispatchDeleteProjectFieldCrew: deleteProjectFieldCrew,
      dispatchAssignProjectMemberToRole: assignProjectFieldCrewToRole,
      dispatchUpdateProjectFieldCrew: updateProjectFieldCrew,
    },
  ),
  withStyles(styles),
)(FieldCrew);
