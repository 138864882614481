import { compose } from 'redux';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { LoadingDataState } from 'altus-datastate';

import { setHeaderValues } from 'app/app.actions';
import { getActionDataStateFromState } from 'app/app.selectors';
import { TASK_ACTIONS } from 'features/projects/tasks/task/task.constants';
import { taskContainerOnLoad } from 'features/projects/tasks/task/task.actions';
import TaskRoutesContainer from 'features/projects/tasks/task/TaskRoutesContainer';
import { getTaskByIdFromState } from 'features/projects/tasks/task/toolstring/toolstring.selectors';

const TaskContainer = ({
  task,
  taskId,
  routes,
  projectId,
  dataState,
  dispatchOnLoad,
}) => {
  useEffect(() => {
    dispatchOnLoad(projectId, taskId);
  }, [dispatchOnLoad, projectId, taskId]);

  return (
    <TaskRoutesContainer
      task={task}
      taskId={taskId}
      routes={routes}
      projectId={projectId}
      dataState={!dataState.requested ? LoadingDataState : dataState}
    />
  );
};

export default compose(
  connect(
    (state, { taskId }) => ({
      task: getTaskByIdFromState(state, taskId),
      dataState: getActionDataStateFromState(
        state,
        TASK_ACTIONS.TASK_PAGE_LOADED,
      ),
    }),
    {
      dispatchOnLoad: taskContainerOnLoad,
      dispatchSetHeaderValues: setHeaderValues,
    },
  ),
)(TaskContainer);
