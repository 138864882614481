import withStyles from '@material-ui/styles/withStyles';

const styles = {
  svg: {
    width: '1em',
    height: '1em',
    flexShrink: '0',
    fontSize: '24px',
    userSelect: 'none',
    fill: 'currentColor',
    display: 'inline-block',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  icon: {
    display: 'flex',
  },
};

const DashboardIcon = ({ classes }) => (
  <i className={classes.icon}>
    <svg className={classes.svg}>
      <path style={{ fill: 'none' }} d="M0,0h24v24H0V0z" />
      <path d="M21,3H3C1.9,3,1,3.9,1,5v12c0,1.1,0.9,2,2,2h5v2h8v-2h5c1.1,0,2-0.9,2-2l0-12C23,3.9,22.1,3,21,3z M21,17H3V5h18V17z" />
      <path
        d="M18.1,8.8c0,0.6-0.5,1.1-1.1,1.1c-0.1,0-0.2,0-0.3,0l-2,2c0,0.1,0,0.2,0,0.3c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1
	c0-0.1,0-0.2,0-0.3l-1.4-1.4c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0l-2.5,2.5c0,0.1,0,0.2,0,0.3c0,0.6-0.5,1.1-1.1,1.1
	s-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.1,0,0.2,0,0.3,0l2.5-2.5c0-0.1,0-0.2,0-0.3c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1
	c0,0.1,0,0.2,0,0.3l1.4,1.4c0.1,0,0.2,0,0.3,0s0.2,0,0.3,0l2-2c0-0.1,0-0.2,0-0.3c0-0.6,0.5-1.1,1.1-1.1S18.1,8.2,18.1,8.8z"
      />
    </svg>
  </i>
);

export default withStyles(styles)(DashboardIcon);
