import defer from 'lodash/defer';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { Field } from 'redux-form/immutable';
import toJSComponent from 'with-immutable-props-to-js';
import withStyles from '@material-ui/styles/withStyles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { reduxForm, formValueSelector } from 'redux-form/immutable';

import {
  Tab,
  Tabs,
  Grid,
  Switch,
  Button,
  FormLabel,
  Typography,
  FormControl,
  DialogActions,
  FormControlLabel,
} from '@material-ui/core';

import {
  MODAL,
  THEMES,
  USER_PREFERENCES_FORM,
  MEASUREMENT_PREFERENCES_FORM,
} from 'app/app.constants';

import themes from 'layout/themes';
import LoadingButton from 'app/components/LoadingButton';
import { getCurrentThemeFromState } from 'app/app.selectors';
import ModalContainer from 'app/components/Modal/ModalContainer';
import { updateUserPreferences, setTheme } from 'app/app.actions';
import MeasurementPreferencesForm from 'features/projects/details/components/MeasurementPreferencesForm';

const USER_PREFERENCES = {
  GENERAL: 1,
  UNITS: 2,
};

class UserPreferencesModalContainer extends PureComponent {
  state = {
    tab: USER_PREFERENCES.UNITS,
  };

  render() {
    const {
      theme,
      valid,
      title,
      onEnter,
      classes,
      options,
      submitting,
      handleSubmit,
      descriptions,
      dispatchSetTheme,
      system: selectedSystem,
    } = this.props;

    return (
      <MuiThemeProvider theme={themes[theme]}>
        <ModalContainer
          height="75vh"
          minWidth="600px"
          title={title}
          onEnter={onEnter}
          modalId={MODAL.EDIT_USER_PREFERENCES}
          classes={{
            content: classes.modalContent,
          }}
          Actions={({ toggleModal }) => (
            <DialogActions>
              <Button size="small" color="default" onClick={toggleModal}>
                Cancel
              </Button>
              <LoadingButton
                size="small"
                type="submit"
                color="primary"
                disabled={!valid}
                variant="contained"
                onClick={handleSubmit}
                submitting={submitting}
                form={MEASUREMENT_PREFERENCES_FORM.ID}
              >
                Save and close
              </LoadingButton>
            </DialogActions>
          )}
        >
          <Tabs
            value={this.state.tab}
            indicatorColor="primary"
            onChange={(event, value) => this.setState({ tab: value })}
          >
            <Tab label="Units" value={USER_PREFERENCES.UNITS} />
            <Tab label="General" value={USER_PREFERENCES.GENERAL} />
          </Tabs>
          <Grid container className={classes.content}>
            <Grid item xs={12}>
              {this.state.tab === USER_PREFERENCES.UNITS && (
                <MeasurementPreferencesForm
                  options={options}
                  namePrefix={'units.'}
                  descriptions={descriptions}
                  selectedSystem={selectedSystem}
                  name={USER_PREFERENCES_FORM.SYSTEM}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {this.state.tab === USER_PREFERENCES.GENERAL && (
                <form className={classes.form}>
                  <FormControl fullWidth>
                    <FormLabel component="legend">
                      <Typography>Notifications</Typography>
                    </FormLabel>
                    <FormControlLabel
                      classes={{
                        root: classes.formControlLabelRoot,
                        labelPlacementStart: classes.labelPlacementStart,
                      }}
                      labelPlacement="start"
                      control={
                        <Field
                          name={USER_PREFERENCES_FORM.RECEIVE_DAILY_EMAIL}
                          component={({ input }) => (
                            <Switch
                              classes={{
                                switchBase: classes.switchBase,
                              }}
                              color="primary"
                              checked={input.value}
                              onChange={input.onChange}
                            />
                          )}
                        />
                      }
                      label={
                        <Typography variant="caption" color="textSecondary">
                          Receive daily updates on changes in your projects
                        </Typography>
                      }
                    />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                    <FormLabel component="legend">
                      <Typography>Theme (beta)</Typography>
                    </FormLabel>
                    <FormControlLabel
                      labelPlacement="start"
                      classes={{
                        root: classes.formControlLabelRoot,
                        labelPlacementStart: classes.labelPlacementStart,
                      }}
                      control={
                        <Field
                          onChange={(event, value) => {
                            defer(() => dispatchSetTheme(value));
                          }}
                          name={USER_PREFERENCES_FORM.THEME}
                          component={({ input }) => (
                            <Switch
                              color="primary"
                              checked={input.value === THEMES.LIGHT}
                              classes={{
                                switchBase: classes.switchBase,
                              }}
                              onChange={(event, value) =>
                                input.onChange(
                                  value ? THEMES.LIGHT : THEMES.DARK,
                                )
                              }
                            />
                          )}
                        />
                      }
                      label={
                        <Typography variant="caption" color="textSecondary">
                          Light mode
                        </Typography>
                      }
                    />
                  </FormControl>
                </form>
              )}
            </Grid>
          </Grid>
        </ModalContainer>
      </MuiThemeProvider>
    );
  }
}

UserPreferencesModalContainer.defaultProps = {
  options: [],
};

const styles = (theme) => ({
  modalContent: {
    padding: 0,
  },
  content: {
    padding: theme.spacing(4),
  },
  form: {
    width: '100%',
  },
  labelPlacementStart: {
    marginLeft: 0,
  },
  formControlLabelRoot: {
    justifyContent: 'space-between',
  },
  switchBase: {
    height: theme.spacing(4),
  },
});

export default connect(
  (state) => {
    const selector = formValueSelector(USER_PREFERENCES_FORM.ID);

    return {
      theme: getCurrentThemeFromState(state),
      system: selector(state, USER_PREFERENCES_FORM.SYSTEM),
    };
  },
  {
    dispatchSetTheme: setTheme,
    onSubmit: updateUserPreferences,
  },
)(
  toJSComponent(
    reduxForm({
      form: USER_PREFERENCES_FORM.ID,
    })(withStyles(styles)(UserPreferencesModalContainer)),
  ),
);
