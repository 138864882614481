import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from '@material-ui/core';

import { UNITS } from 'app/app.constants';
import makeStyles from '@material-ui/styles/makeStyles';
import { formatQuantity, formatValue } from 'utils/format.util';

const details = [
  {
    label: 'Wellbore',
    accessor: (wellbore) => wellbore.get('name'),
  },
  {
    label: 'Field',
    accessor: (wellbore) => wellbore.get('fieldName'),
  },
  {
    label: 'Vertical depth',
    accessor: (_, trajectory) =>
      formatQuantity(trajectory.get('verticalDepth')),
  },
  {
    label: 'Measured depth',
    accessor: (_, trajectory) =>
      formatQuantity(trajectory.get('measuredDepth')),
  },
  {
    label: 'Water depth',
    accessor: (wellbore) => formatQuantity(wellbore.get('waterDepth')),
  },
  {
    label: 'Elevation',
    accessor: (wellbore) => formatQuantity(wellbore.get('elevation')),
  },
  {
    label: 'Max. Dogleg',
    accessor: (_, trajectory) => {
      const maximumDogLeg = formatValue(
        trajectory.get('maximumDogLeg'),
        UNITS.DEG,
        0.1,
      );

      const maximumDogLegDepth = formatQuantity(
        trajectory.get('maximumDogLegDepth'),
      );

      return maximumDogLeg && `${maximumDogLeg} (${maximumDogLegDepth})`;
    },
  },
  {
    label: 'Max. Inclination',
    accessor: (_, trajectory) => {
      const maximumInclination = formatValue(
        trajectory.get('maximumInclination'),
        UNITS.DEG,
        0.1,
      );

      const maximumInclinationDepth = formatQuantity(
        trajectory.get('maximumInclinationDepth'),
      );

      return (
        maximumInclination &&
        `${maximumInclination} (${maximumInclinationDepth})`
      );
    },
  },
];

const WellboreTrajectoryDetails = ({ wellbore, trajectory }) => {
  const classes = useStyles();

  return (
    <Table>
      <TableBody>
        {details.map(({ label, accessor }) => (
          <TableRow key={label} classes={{ root: classes.row }}>
            <TableCell classes={{ root: classes.labelCell }}>
              <Typography variant="subtitle1">{label}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle1" align="right">
                {accessor(wellbore, trajectory) || <i>N/A</i>}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles((theme) => ({
  labelCell: {
    color: theme.palette.primary.light,
  },
  row: {
    borderBottom: `2px dotted ${theme.palette.secondary.main}`,
    '& > td': {
      paddingTop: theme.spacing(3),
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    '& > td:not(:last-child)': {
      paddingRight: theme.spacing(3),
    },
  },
}));

export default WellboreTrajectoryDetails;
