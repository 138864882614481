import React, { useEffect, memo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Grid } from '@material-ui/core';
import { APP_ACTIONS } from 'app/app.constants';
import { BasePage, LoadingOverlay } from 'altus-ui-components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { useHeader } from 'app/hooks/useHeader';
import { fetchPrivacyPolicy } from 'app/app.actions';
import useDataState from 'app/hooks/useDataState';
import { getPrivacyPolicy } from 'app/app.selectors';
import PropTypes from 'prop-types';

const PrivacyPolicy = ({ classes, dispatchFetchPrivacyPolicy }) => {
  useHeader({
    title: '',
    subTitle: 'Privacy Policy',
  });
  const [markdown, setMarkdown] = useState(null);

  useEffect(() => {
    dispatchFetchPrivacyPolicy().then((response) => {
      setMarkdown(response.markdown);
    });
  }, [dispatchFetchPrivacyPolicy]);
  const loadingState = useDataState([
    APP_ACTIONS.INITIATE_FETCH_PRIVACY_POLICY,
  ]);

  return (
    <>
      <BasePage
        classes={{
          children: classes.basePageChildren,
        }}
      >
        <LoadingOverlay dataState={loadingState} />
        {loadingState.received && markdown && (
          <Grid container className={classes.content} justifyContent="flex-end">
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <ReactMarkdown>{markdown}</ReactMarkdown>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        )}
      </BasePage>
    </>
  );
};

PrivacyPolicy.propTypes = {
  dispatchFetchPrivacyPolicy: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    basePageChildren: PropTypes.shape({
      padding: PropTypes.number,
    }),
    content: PropTypes.shape({
      top: PropTypes.number,
      bottom: PropTypes.number,
      overflow: PropTypes.string,
      position: PropTypes.string,
    }),
  }),
  markdown: PropTypes.string,
};

const styles = () => ({
  basePageChildren: {
    padding: 0,
  },
  content: {
    top: 0,
    bottom: 0,
    overflow: 'auto',
    position: 'absolute',
  },
});

export default compose(
  connect(
    (state) => ({
      markdown: getPrivacyPolicy(state),
    }),
    {
      dispatchFetchPrivacyPolicy: fetchPrivacyPolicy,
    },
  ),
  memo,
  withStyles(styles),
)(PrivacyPolicy);
