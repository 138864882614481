import { compose } from 'redux';
import { useCallback } from 'react';
import { connect } from 'react-redux';

import { getActionDataStateFromState } from 'app/app.selectors';
import { TEAM_ACTIONS } from 'features/projects/team/team.constants';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import { addFieldCrewToProject } from 'features/projects/team/team.actions';

import CreateProjectMembersModal from 'features/projects/team/components/CreateProjectMembersModal';

const CreateProjectFieldCrewModalContainer = ({
  users,
  isOpen,
  title,
  projectId,
  dataState,
  toggleModal,
  projectMembers,
  dispatchAddUsersToProject,
}) => {
  const project = useCurrentProject();

  const addSelectedUsersToProject = useCallback(
    (users) => dispatchAddUsersToProject(projectId, users).then(toggleModal),
    [projectId, toggleModal, dispatchAddUsersToProject],
  );

  return (
    <CreateProjectMembersModal
      users={users}
      title={title}
      isOpen={isOpen}
      project={project}
      dataState={dataState}
      toggleModal={toggleModal}
      projectMembers={projectMembers}
      addUsersToProject={addSelectedUsersToProject}
    />
  );
};

export default compose(
  connect(
    (state) => ({
      dataState: getActionDataStateFromState(
        state,
        TEAM_ACTIONS.ADD_PROJECT_FIELD_CREW,
      ),
    }),
    {
      dispatchAddUsersToProject: addFieldCrewToProject,
    },
  ),
)(CreateProjectFieldCrewModalContainer);
