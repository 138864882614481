import { useMemo } from 'react';

import DashboardLineChart from 'features/projects/dashboard/components/charts/DashboardLineChart';
import useProjectDashboardHubConnection from 'features/projects/dashboard/hooks/useProjectDashboardHubConnection';
import DashboardGaugeChartsContainer from 'features/projects/dashboard/components/charts/DashboardGaugeChartsContainer';
import DashboardWellboreTrajectoryContainer from 'features/projects/dashboard/components/dashboards/DashboardWellboreTrajectoryContainer';
import ProjectExecutionStatusContainer from 'features/projects/dashboard/components/ProjectExecutionStatusContainer/ProjectExecutionStatusContainer';

const WinchCombinedDashboardController = ({
  projectId,
  renderSlots,
  projectDashboardId,
}) => {
  const { curves, setExtremes, registerDataPointsHandler } =
    useProjectDashboardHubConnection(projectId, projectDashboardId);

  const depthCurve = useMemo(
    () => curves.find((curve) => curve.get('isDepth')),
    [curves],
  );

  return renderSlots(
    <DashboardWellboreTrajectoryContainer
      curve={depthCurve}
      projectId={projectId}
      registerDataPointsHandler={registerDataPointsHandler}
    />,
    <DashboardLineChart
      curves={curves}
      projectId={projectId}
      onSetExtremes={setExtremes}
      registerDataPointsHandler={registerDataPointsHandler}
    />,
    <DashboardGaugeChartsContainer
      curves={curves}
      registerDataPointsHandler={registerDataPointsHandler}
    />,
    <ProjectExecutionStatusContainer projectId={projectId} />,
  );
};

export default WinchCombinedDashboardController;
