import { compose } from 'redux';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { Grid, Button } from '@material-ui/core';

import { NavTabsContainer, DetailsHeader } from 'altus-ui-components';

import {
  getProjectRole,
  deleteProjectRoleAskConfirmation,
} from 'authorization/authorization.actions';
import { EMPTY_MAP } from 'app/app.constants';
import SystemRoutesContainer from 'app/components/SystemRoutesContainer';
import { getProjectRoleFromState } from 'authorization/authorization.selectors';

class ProjectRoleContainer extends PureComponent {
  componentDidMount() {
    const { projectRoleId, dispatchGetProjectRole } = this.props;

    dispatchGetProjectRole(projectRoleId);
  }

  render() {
    const {
      routes,
      projectRole,
      projectRoleId,
      dispatchDeleteProjectRole,
    } = this.props;

    const displayDeleteBtn = !projectRole.get('isProtected');

    return (
      <Grid xs item container direction="column">
        <DetailsHeader
          title={projectRole.get('name')}
          subtitle={projectRole.get('description')}
        >
          {displayDeleteBtn && (
            <Grid container justifyContent="flex-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={() => dispatchDeleteProjectRole(projectRole)}
              >
                Delete
              </Button>
            </Grid>
          )}
        </DetailsHeader>
        <NavTabsContainer routes={routes} />
        <SystemRoutesContainer routes={routes} key={projectRoleId} />
      </Grid>
    );
  }
}

ProjectRoleContainer.defaultProps = {
  projectRole: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { projectRoleId }) => ({
      projectRole: getProjectRoleFromState(state, projectRoleId),
    }),
    {
      dispatchGetProjectRole: getProjectRole,
      dispatchDeleteProjectRole: deleteProjectRoleAskConfirmation,
    },
  ),
)(ProjectRoleContainer);
