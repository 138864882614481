import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import _isNil from 'lodash/isNil';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { useToggle, useRequest } from 'altus-hooks';
import { LoadingOverlay } from 'altus-ui-components';

import {
  requestSimulationFluids,
  requestCreateSimulationFluid,
  requestDeleteSimulationFluid,
  requestUpdateSimulationFluid,
} from 'features/projects/tasks/task/simulation/simulation.actions';
import {
  getSimulationFromState,
  createSimulationFluidsSelector,
} from 'features/projects/tasks/task/simulation/simulation.selectors';
import routePaths from 'app/routePaths';
import { EMPTY_MAP } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import simulationService from 'services/simulation.service';
import {
  toTaskSimulationFluid,
  toExecutionSimulationFluid,
  toTaskSimulation,
} from 'utils/route.util';
import { ACTIONS } from 'features/projects/tasks/task/simulation/simulation.constants';
import simulationMappers from 'features/projects/tasks/task/simulation/simulation.mappers';
import SimulationFluidsTable from 'features/projects/tasks/task/simulation/fluids/components/SimulationFluidsTable';
import SimulationFluidEditor from 'features/projects/tasks/task/simulation/fluids/components/SimulationFluidEditor';
import SimulationFluidTableRow from 'features/projects/tasks/task/simulation/fluids/components/SimulationFluidTableRow';

const TaskSimulationFluidsContainer = ({ path }) => {
  const dispatch = useDispatch();
  const {
    projectId,
    taskId,
    simulationId,
    simulationFluidId: selectedSimulationFluidId,
  } = useParams();
  const simulation =
    useSelector((state) => getSimulationFromState(state, simulationId)) ??
    EMPTY_MAP;
  const simulationFluids =
    useSelector(createSimulationFluidsSelector(simulationId)) ?? EMPTY_MAP;
  const dataState = useSelector((state) =>
    getSummarizedDataStateFromState(
      state,
      ACTIONS.REQUEST_SIMULATION_FLUIDS,
      ACTIONS.REQUEST_CREATE_SIMULATION_FLUID,
      ACTIONS.REQUEST_DELETE_SIMULATION_FLUID,
      ACTIONS.REQUEST_UPDATE_SIMULATION_FLUID,
    ),
  );
  const [isCreateMode, toggleCreateMode] = useToggle();

  // TODO/IMPROVE @SS: This is a bit frail but the fluid container logic is pretty locked
  // to the planning and routes and should probably be re-designed to be more route agnostic
  const inPlanning = path === routePaths.plan.task.simulation.fluids.match;
  const onRedirect = (fluidId = null) =>
    inPlanning
      ? _isNil(fluidId)
        ? toTaskSimulation(
            projectId,
            taskId,
            simulationId,
            routePaths.plan.task.simulation.fluids.root,
          )
        : toTaskSimulationFluid(projectId, taskId, simulationId, fluidId)
      : toExecutionSimulationFluid(projectId, taskId, simulationId, fluidId);

  useEffect(
    () => dispatch(requestSimulationFluids(projectId, taskId, simulationId)),
    [projectId, taskId, simulationId, dispatch],
  );

  const getDefaultSimulationFluid = useCallback(
    () =>
      simulationService
        .getDefaultSimulationFluid(projectId, taskId, simulationId)
        .then(simulationMappers.SimulationFluid.from),
    [taskId, projectId, simulationId],
  );
  const [defaultSimulationFluid = EMPTY_MAP] = useRequest(
    getDefaultSimulationFluid,
  );

  const createSimulationFluid = (
    simulationFluid,
    { setSubmitting, setStatus },
  ) =>
    dispatch(
      requestCreateSimulationFluid(
        projectId,
        taskId,
        simulationId,
        simulationFluid,
        setSubmitting,
        setStatus,
        onRedirect,
      ),
    );

  const updateSimulationFluid = (
    simulationFluid,
    { setSubmitting, setStatus },
  ) =>
    dispatch(
      requestUpdateSimulationFluid(
        projectId,
        taskId,
        simulationId,
        simulationFluid.simulationFluidId,
        simulationFluid,
        setSubmitting,
        setStatus,
      ),
    );

  const deleteSimulationFluid = (simulationFluidId) =>
    dispatch(
      requestDeleteSimulationFluid(
        projectId,
        taskId,
        simulationId,
        simulationFluidId,
        simulationFluidId.toString() === selectedSimulationFluidId
          ? onRedirect
          : null,
      ),
    );

  const renderTableRowComponent = useCallback(
    (props) => {
      const simulationFluidId = props.item.get('simulationFluidId').toString();
      const navigateToFluid = inPlanning
        ? toTaskSimulationFluid
        : toExecutionSimulationFluid;

      return (
        <SimulationFluidTableRow
          {...props}
          isSelected={selectedSimulationFluidId === simulationFluidId}
          navigateToFluid={() =>
            navigateToFluid(projectId, taskId, simulationId, simulationFluidId)
          }
        />
      );
    },
    [taskId, projectId, simulationId, selectedSimulationFluidId, inPlanning],
  );

  const selectedSimulationFluid = simulationFluids.get(
    selectedSimulationFluidId,
  );

  return (
    <Grid container direction="column">
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography variant="subtitle2" gutterBottom>
            Fluids
          </Typography>
          <SimulationFluidsTable
            taskId={taskId}
            projectId={projectId}
            simulationId={simulationId}
            isCreateMode={isCreateMode}
            inPlanning={inPlanning}
            toggleCreateMode={toggleCreateMode}
            simulationFluids={simulationFluids}
            disabled={simulation.get('isPlanned')}
            TableRowComponent={renderTableRowComponent}
            deleteSimulationFluid={deleteSimulationFluid}
            defaultSimulationFluid={defaultSimulationFluid}
          />
        </Grid>
        <Grid item xs={7}>
          <Typography variant="subtitle2" gutterBottom>
            Fluid Editor
          </Typography>
          <SimulationFluidEditor
            isCreateMode={isCreateMode}
            inPlanning={inPlanning}
            toggleCreateMode={toggleCreateMode}
            disabled={simulation.get('isPlanned')}
            simulationFluid={selectedSimulationFluid}
            updateSimulationFluid={updateSimulationFluid}
            createSimulationFluid={createSimulationFluid}
            simulationFluidId={selectedSimulationFluidId}
            defaultSimulationFluid={defaultSimulationFluid}
          />
        </Grid>
      </Grid>
      <LoadingOverlay dataState={dataState} />
    </Grid>
  );
};

export default TaskSimulationFluidsContainer;
