import { compose } from 'redux';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { Grid, Divider } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { matchPath, Redirect, Switch } from 'react-router-dom';

import {
  Menu,
  MenuHeader,
  LoadingOverlay,
  RouteContainer,
  MenuNavigationItem,
  withToggleModal,
} from 'altus-ui-components';

import {
  createProjectRoleOnSubmit,
  getProjectRoles,
} from 'authorization/authorization.actions';

import {
  AUTHORIZATION_ACTIONS,
  CREATE_PROJECT_ROLE_MODAL_ID,
} from 'authorization/authorization.constants';

import { EMPTY_LIST } from 'app/app.constants';
import { toProjectRole } from 'utils/route.util';
import { getActionDataStateFromState } from 'app/app.selectors';
import NoRoleSelectedPage from 'features/settings/accessRoles/NoRoleSelectedPage';
import { getProjectRolesListFromState } from 'authorization/authorization.selectors';
import CreateProjectRoleModal from 'features/settings/accessRoles/projectRoles/CreateProjectRoleModal';

class ProjectRolesContainer extends PureComponent {
  componentDidMount() {
    const { dispatchGetProjectRoles } = this.props;

    dispatchGetProjectRoles();
  }

  onAdd = () => {
    const { dispatchToggleModal } = this.props;

    dispatchToggleModal({ modalId: CREATE_PROJECT_ROLE_MODAL_ID });
  };

  renderMenuItem = (projectRole) => {
    const { pathname } = this.props;

    // use the current path, and replace the base url with the correct role url
    const match = matchPath(pathname, {
      path: '/v2/settings/access-roles/project/:projectRoleId',
    });

    if (!match) return null;

    const roleId = projectRole.get('roleId');
    const projectRoleUrl = toProjectRole(roleId);

    const to = pathname.replace(match.url, projectRoleUrl);

    return (
      <MenuNavigationItem
        to={to}
        key={roleId}
        title={projectRole.get('name')}
      />
    );
  };

  render() {
    const {
      path,
      routes,
      classes,
      dataState,
      projectRoles,
      dispatchCreateProjectRoleOnSubmit,
    } = this.props;

    return (
      <Grid container item xs wrap="nowrap" className={classes.root}>
        <Grid container item direction="column" xs={2} wrap="nowrap">
          <MenuHeader onAdd={this.onAdd} title="Role" />
          <Divider />
          <Grid item container className={classes.menuContainer}>
            <Grid item xs>
              <Menu
                items={projectRoles}
                renderMenuItem={(item) => this.renderMenuItem(item)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Switch>
          {!!projectRoles.size && (
            <Redirect
              exact
              from={path}
              to={toProjectRole(projectRoles.getIn([0, 'roleId']))}
            />
          )}
          <RouteContainer
            routes={routes}
            MissingRouteComponent={NoRoleSelectedPage}
          />
        </Switch>
        <CreateProjectRoleModal onSubmit={dispatchCreateProjectRoleOnSubmit} />
        <LoadingOverlay dataState={dataState} />
      </Grid>
    );
  }
}

ProjectRolesContainer.defaultProps = {
  projectRoles: EMPTY_LIST,
};

const styles = () => ({
  root: {
    overflow: 'hidden',
  },
  menuContainer: {
    overflow: 'auto',
  },
});

export default compose(
  withToggleModal,
  connect(
    (state) => ({
      projectRoles: getProjectRolesListFromState(state),
      dataState: getActionDataStateFromState(
        state,
        AUTHORIZATION_ACTIONS.GET_PROJECT_ROLES,
      ),
    }),
    {
      dispatchGetProjectRoles: getProjectRoles,
      dispatchCreateProjectRoleOnSubmit: createProjectRoleOnSubmit,
    },
  ),
  withStyles(styles),
)(ProjectRolesContainer);
