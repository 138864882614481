import {
  IgcDockManagerPaneType,
  IgcSplitPaneOrientation,
} from '@infragistics/igniteui-dockmanager';

import { DashboardName } from 'features/projects/dashboard/dashboard.constants';

export const DefaultLayout = {
  rootPane: {
    size: 100,
    type: IgcDockManagerPaneType.splitPane,
    orientation: IgcSplitPaneOrientation.vertical,
    panes: [
      {
        type: IgcDockManagerPaneType.contentPane,
        contentId: 'content0',
        size: 100,
      },
    ],
  },
};

export const DashboardLayout = {
  [DashboardName.WINCH_COMBINED]: {
    rootPane: {
      type: IgcDockManagerPaneType.splitPane,
      orientation: IgcSplitPaneOrientation.vertical,
      panes: [
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.horizontal,
          panes: [
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content0',
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content1',
            },
          ],
          size: 150,
        },
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.horizontal,
          panes: [
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content2',
              size: 150,
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content3',
              size: 75,
            },
          ],
          size: 75,
        },
      ],
    },
  },
  [DashboardName.WINCH_GRAPH_GAUGES]: {
    rootPane: {
      type: IgcDockManagerPaneType.splitPane,
      orientation: IgcSplitPaneOrientation.vertical,
      panes: [
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.horizontal,
          panes: [
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content0',
            },
          ],
          size: 150,
        },
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.horizontal,
          panes: [
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content1',
              size: 150,
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content2',
              size: 75,
            },
          ],
          size: 75,
        },
      ],
    },
  },
  [DashboardName.BHA_GRAPH_GAUGES]: {
    rootPane: {
      type: IgcDockManagerPaneType.splitPane,
      orientation: IgcSplitPaneOrientation.vertical,
      panes: [
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.horizontal,
          panes: [
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content0',
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content1',
            },
          ],
          size: 150,
        },
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.horizontal,
          panes: [
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content2',
              size: 150,
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content3',
              size: 75,
            },
          ],
          size: 75,
        },
      ],
    },
  },
  [DashboardName.WELL_GRAPH_GAUGES]: {
    rootPane: {
      type: IgcDockManagerPaneType.splitPane,
      orientation: IgcSplitPaneOrientation.vertical,
      panes: [
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.horizontal,
          panes: [
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content0',
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content1',
            },
          ],
          size: 150,
        },
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.horizontal,
          panes: [
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content2',
              size: 150,
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content3',
              size: 75,
            },
          ],
          size: 75,
        },
      ],
    },
  },
  [DashboardName.WINCH_SURVEY_GAUGES]: {
    rootPane: {
      type: IgcDockManagerPaneType.splitPane,
      orientation: IgcSplitPaneOrientation.vertical,
      panes: [
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.horizontal,
          panes: [
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content0',
            },
          ],
          size: 150,
        },
        {
          type: IgcDockManagerPaneType.splitPane,
          orientation: IgcSplitPaneOrientation.horizontal,
          panes: [
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content1',
              size: 150,
            },
            {
              type: IgcDockManagerPaneType.contentPane,
              contentId: 'content2',
              size: 75,
            },
          ],
          size: 75,
        },
      ],
    },
  },
};
