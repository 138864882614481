import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { useEffect, memo, useCallback, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useMemo } from 'react';

import {
  getProjectsFromState,
  getFacilitiesFromState,
  getDepartmentsFromState,
  getProjectStatusesFromState,
  getGanttProjectsFromState,
} from 'features/projects/projects.selectors';

import {
  archiveProject,
  getAllProjectsGantt,
  onLoad,
  onUnload,
  updateFilter,
} from 'features/projects/projects.actions';

import {
  getSummarizedDataStateFromState,
  getCurrentClientOrganizationIdFromState,
} from 'app/app.selectors';

import {
  PROJECT_VIEWS,
  PROJECTS_ACTIONS,
} from 'features/projects/projects.constants';

import { useHeader } from 'app/hooks/useHeader';
import BasePage from 'app/components/BasePageDense';
import ProjectFilter from 'features/projects/components/ProjectFilter';
import ArchiveProjectFilter from 'features/projects/components/ArchiveProjectFilter';
import { getFieldsFromState } from 'features/wells/wellbore.selectors';
import ProjectsByStatus from 'features/projects/components/ProjectsByStatus';
import { EUROPE_MAP_CENTER_COORDS, PROJECT_STATUS } from 'app/app.constants';
import ProjectMapLocations from 'features/projects/components/WellMapLocations/ProjectMapLocations';
import ProjectsGanttChartContainer from 'features/projects/projectsGanttChart/ProjectsGanttChartContainer';
import { Filters } from 'features/projects/components/ProjectFilter';

const allStatuses = [
  PROJECT_STATUS.PLAN,
  PROJECT_STATUS.REPORT,
  PROJECT_STATUS.EXECUTE,
];

const ProjectsContainer = ({
  classes,
  dataState,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchUpdateFilter,
  dispatchArchiveProject,
  dispatchLoadGanttProject,
}) => {
  const location = useLocation();
  const history = useHistory();
  const isArchive = location?.state?.isArchive;
  const [isArchiveProject, setIsArchiveProject] = useState(isArchive ?? false);

  useHeader({
    subTitle: isArchiveProject ? 'Archived Projects' : breadcrumb,
  });
  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromState,
  );

  useEffect(() => {
    dispatchOnLoad(currentClientOrganizationId);

    if (isArchive) {
      history.replace({ pathname: '/projects', state: { isArchive: null } });
    }

    return () => {
      dispatchOnUnload();
    };
  }, [
    dispatchOnLoad,
    dispatchOnUnload,
    currentClientOrganizationId,
    history,
    isArchive,
  ]);

  const filter = useMemo(
    () => ({
      [Filters.PROJECT_STATUS]: '',
      [Filters.FACILITY_ID]: '',
      [Filters.DEPARTMENT_ID]: '',
      [Filters.TEXT_SEARCH]: '',
      [Filters.GET_LOCATIONS]: true,
      [Filters.IS_ARCHIVE]: true,
      [Filters.PROJECT_STATUSES]: allStatuses,
      [Filters.GANTT_VIEW]: false,
    }),
    [],
  );

  const [currentFilter, setCurrentFilter] = useState();
  const currentFields = useSelector(getFieldsFromState);
  const currentProjects = useSelector(getProjectsFromState);
  const currentFacilities = useSelector(getFacilitiesFromState);
  const currentStatuses = useSelector(getProjectStatusesFromState);
  const currentGantProjects = useSelector(getGanttProjectsFromState);

  const currentDepartments = useSelector(getDepartmentsFromState);

  const [fields, setFields] = useState(currentFields);
  const [projects, setProjects] = useState(currentProjects);
  const [facilities, setFacilities] = useState(currentFacilities);
  const [departments, setDepartments] = useState(currentDepartments);

  const [statuses, setStatuses] = useState(currentStatuses);
  const search = new URLSearchParams(useLocation().search);
  const fieldId = search.get('fieldId');

  const [selectedView, setSelectedView] = useState(PROJECT_VIEWS.TABLE_VIEW);
  filter[Filters.GANTT_VIEW] = selectedView;

  const toggleViewChange = useCallback(
    (_event, value) => {
      filter[Filters.GANTT_VIEW] = value;
      setCurrentFilter(filter);

      if (!value) return;

      setSelectedView(value);
    },
    [filter],
  );

  useEffect(() => {
    setFields(currentFields);
    setProjects(currentProjects);
    setFacilities(currentFacilities);
    setDepartments(currentDepartments);
    setStatuses(currentStatuses);
  }, [
    currentFacilities,
    currentFields,
    currentProjects,
    currentStatuses,
    currentDepartments,
    projects,
  ]);

  const goBackToProjects = (event) => {
    event.preventDefault();
    setIsArchiveProject(false);
    dispatchOnLoad();
  };

  const updateProjectFilter = useCallback(
    (newSelected) => {
      const { Plan, Report, Execute } = newSelected;
      let selectedStatus = [];

      if (!Plan && !Report && !Execute) {
        filter[Filters.PROJECT_STATUSES] = allStatuses;
        setCurrentFilter(filter);
        dispatchUpdateFilter(filter);
        return;
      }

      if (Plan) selectedStatus.push(PROJECT_STATUS.PLAN);
      if (Report) selectedStatus.push(PROJECT_STATUS.REPORT);
      if (Execute) selectedStatus.push(PROJECT_STATUS.EXECUTE);

      filter[Filters.PROJECT_STATUSES] = selectedStatus;
      setCurrentFilter(filter);
      dispatchUpdateFilter(filter);
    },
    [dispatchUpdateFilter, filter],
  );

  const getArchiveProjects = useCallback(() => {
    filter[Filters.PROJECT_STATUSES] = undefined;
    dispatchUpdateFilter(filter);
    setIsArchiveProject(true);
    setCurrentFilter(filter);
  }, [dispatchUpdateFilter, filter]);

  const setArchiveProjects = useCallback(
    (projectId, isArchive) => {
      dispatchArchiveProject(projectId, isArchive, currentFilter);
    },
    [currentFilter, dispatchArchiveProject],
  );

  const renderProjectsTable = useCallback(() => {
    if (!projects.size && dataState.isLoading()) return null;

    if (!projects.size) {
      return (
        <Grid item xs container alignItems="center" justifyContent="center">
          No projects available
        </Grid>
      );
    }

    if (selectedView === PROJECT_VIEWS.CHART_VIEW) {
      return (
        <Grid container component={Box}>
          <ProjectsGanttChartContainer
            fields={fields}
            projects={currentGantProjects}
            dataState={dataState}
            dispatchLoadGanttProject={dispatchLoadGanttProject}
            filter={currentFilter}
          />
        </Grid>
      );
    } else if (selectedView === PROJECT_VIEWS.TABLE_VIEW) {
      return (
        <ProjectsByStatus
          projects={projects}
          isArchiveProject={isArchiveProject}
          setArchiveProjects={setArchiveProjects}
        />
      );
    }
  }, [
    projects,
    dataState,
    selectedView,
    fields,
    currentGantProjects,
    dispatchLoadGanttProject,
    currentFilter,
    isArchiveProject,
    setArchiveProjects,
  ]);

  return (
    <BasePage dataState={dataState}>
      <ProjectMapLocations
        projects={projects}
        center={EUROPE_MAP_CENTER_COORDS}
      />
      <Grid container className={classes.content} justifyContent="flex-end">
        <Grid
          item
          sm={selectedView === PROJECT_VIEWS.TABLE_VIEW ? 7 : 12}
          xs={12}
          container
          component={Box}
          paddingLeft={2}
          paddingRight={2}
          direction="column"
        >
          {isArchiveProject ? (
            <ArchiveProjectFilter
              selectedView={selectedView}
              onViewChange={toggleViewChange}
              onSubmit={dispatchUpdateFilter}
              currentClientOrganizationId={currentClientOrganizationId}
              goBackToProjects={goBackToProjects}
              updateProjectFilter={updateProjectFilter}
            />
          ) : (
            <ProjectFilter
              fieldId={fieldId}
              fields={fields}
              statuses={statuses}
              facilities={facilities}
              departments={departments}
              selectedView={selectedView}
              onViewChange={toggleViewChange}
              onSubmit={dispatchUpdateFilter}
              currentClientOrganizationId={currentClientOrganizationId}
              getArchiveProjects={getArchiveProjects}
            />
          )}
          {renderProjectsTable()}
        </Grid>
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  content: {
    top: 0,
    bottom: 0,
    overflow: 'auto',
    position: 'absolute',
  },
  createProjectBtn: {
    zIndex: 3,
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
});

export default compose(
  connect(
    (state) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        PROJECTS_ACTIONS.PROJECTS_PAGE_LOADED,
        PROJECTS_ACTIONS.SEARCH_PROJECTS,
        PROJECTS_ACTIONS.RECEIVE_GANTT_PROJECTS,
      ),
    }),
    {
      dispatchOnLoad: onLoad,
      dispatchOnUnload: onUnload,
      dispatchUpdateFilter: updateFilter,
      dispatchArchiveProject: archiveProject,
      dispatchLoadGanttProject: getAllProjectsGantt,
    },
  ),
  memo,
  withStyles(styles),
)(ProjectsContainer);
