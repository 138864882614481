import { compose } from 'redux';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import withStyles from '@material-ui/styles/withStyles';

import { useModal } from 'altus-modal';
import { BasePage } from 'altus-ui-components';

import {
  TASK_ACTIONS,
  TASKS_CREATE_TASK_MODAL_ID,
} from 'features/projects/tasks/tasks.constants';

import { useHeader } from 'app/hooks/useHeader';
import { Fab } from 'app/components/withTooltip';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { ProjectPermission, PROJECT_STATUS } from 'app/app.constants';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { tasksContainerOnUnload } from 'features/projects/tasks/tasks.actions';
import { getAllTasksWithProjects } from 'features/projects/activities/activities.actions';
import {
  getAllTasksFromState,
  getTaskTemplates,
} from 'features/projects/tasks/tasks.selectors';
import CreateTaskModalContainer from 'features/projects/tasks/components/createTask/CreateTaskModalContainer';

import TaskTableChart from 'features/projects/tasks/TaskTableChart';

const TasksContainer = ({
  tasks,
  classes,
  project,
  dataState,
  projectId,
  templateTasks,
  dispatchOnUnload,
  dispatchGetTasks,
  dispatchCopyTask,
  dispatchSortTasks,
  dispatchDeleteTask,
  totalElapsedDuration,
  totalPlannedDuration,
}) => {
  useEffect(() => {
    dispatchGetTasks(projectId);

    return () => dispatchOnUnload();
  }, [projectId, dispatchGetTasks, dispatchOnUnload]);

  useHeader({
    subTitle: `Plan ${tasks?.size ? `(${tasks?.size})` : ''}`,
  });

  const [_, toggleModal] = useModal(TASKS_CREATE_TASK_MODAL_ID);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs wrap="nowrap">
        <Grid item xs={2} />
        <Grid item xs={8}>
          <TaskTableChart
            tasks={tasks}
            project={project}
            projectId={projectId}
            copyTask={dispatchCopyTask}
            sortTasks={dispatchSortTasks}
            deleteTask={dispatchDeleteTask}
            totalPlannedDuration={totalPlannedDuration}
            totalElapsedDuration={totalElapsedDuration}
          />
          <HasProjectPermission permissions={ProjectPermission.CREATE_TASKS}>
            <Fab
              onClick={toggleModal}
              color="primary"
              title="Add to plan"
              className={classes.create}
              disabled={project.status === PROJECT_STATUS.REPORT}
            >
              <Add />
            </Fab>
            <CreateTaskModalContainer
              title="Add tasks"
              projectId={projectId}
              templateTasks={templateTasks}
            />
          </HasProjectPermission>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  tasks: getAllTasksFromState(state),
  templateTasks: getTaskTemplates(state),
  dataState: getSummarizedDataStateFromState(state, TASK_ACTIONS.GET_ALL_TASKS),
});

const mapDispatchToProps = {
  dispatchGetTasks: getAllTasksWithProjects,
  dispatchOnUnload: tasksContainerOnUnload,
};

const styles = (theme) => ({
  create: {
    zIndex: 3,
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(TasksContainer);
