import { call, put, takeEvery } from 'redux-saga/effects';

import { getAllTasks } from 'features/projects/activities/activities.actions';
import {
  TASK_ACTIONS as ACTIONS,
  TASK_SURFACE_EQUIPMENT_ACTIONS,
} from 'features/projects/tasks/tasks.constants';
import { ACTIVITIES_ACTIONS } from 'features/projects/activities/activities.constants';
import {
  storeTaskFluids,
  deletedTaskFluid,
  updatedTaskFluid,
  createTaskFluid,
} from 'features/projects/task.actions.fluids';
import { FormikFormStatus } from 'app/app.constants';
import taskService from 'services/task.service';
import { callAsync } from 'app/sagas/helperSagas';

const UPDATE_TASKS_TRIGGER_ACTIONS = [
  ACTIONS.COPY_TASK,
  ACTIONS.SORT_TASKS,
  ACTIONS.CONFIRM_DELETE_TASK,
  ACTIONS.ADD_TASKS_FROM_TEMPLATE,
  ACTIVITIES_ACTIONS.ACTIVITY_GO_BACK,
];

export function* getAllTasksAsync(action) {
  const { projectId, skipTracking } = action;

  // use skipTracking to only fetch the tasks when the promise is complete
  if (!skipTracking) return;

  // do not fetch the tasks if projectId is missing from action
  if (!projectId) return;
  return yield put(getAllTasks(projectId));
}

export function* getAllTaskFluids(action) {
  const { projectId, taskId } = action;
  const allFluids = yield call(taskService.getTaskFluids, projectId, taskId);
  yield put(storeTaskFluids(allFluids));
}

export function* deleteTaskFluid(action) {
  const { projectId, taskId, fluidId } = action;
  yield call(taskService.deleteTaskFluid, projectId, taskId, fluidId);
  yield call(getAllTaskFluids, { projectId, taskId });
  yield put(deletedTaskFluid({ fluidId }));
}

export function* addTaskFluid(action) {
  const { projectId, taskId, fluidObj, setSubmitting, setStatus } = action;

  try {
    yield call(taskService.addTaskFluid, projectId, taskId, fluidObj);
    yield call(getAllTaskFluids, { projectId, taskId });
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);
    yield put(createTaskFluid(fluidObj.fluidId, taskId));
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);
    throw error;
  }
}

export function* updateTaskFluid(action) {
  const { projectId, taskId, fluidObj, setSubmitting, setStatus } = action;

  try {
    yield call(taskService.updateTaskFluid, projectId, taskId, fluidObj);
    yield call(getAllTaskFluids, { projectId, taskId });
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);
    yield put(updatedTaskFluid(fluidObj.fluidId, taskId));
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);
    throw error;
  }
}

export function* updateCableSerialNumber(action) {
  const { payload: dto } = action;

  try {
    yield call(taskService.saveCableSerialNumberToTask, dto);
    yield put(getAllTasks(dto.projectId));
  } catch (error) {
    throw error;
  }
}

export default function* root() {
  yield takeEvery(UPDATE_TASKS_TRIGGER_ACTIONS, getAllTasksAsync);
  yield takeEvery(
    ACTIONS.INITIATE_GET_TASK_FLUIDS,
    callAsync,
    getAllTaskFluids,
  );
  yield takeEvery(
    ACTIONS.INITIATE_DELETE_TASK_FLUID,
    callAsync,
    deleteTaskFluid,
  );
  yield takeEvery(
    ACTIONS.INITIATE_UPDATE_TASK_FLUID,
    callAsync,
    updateTaskFluid,
  );
  yield takeEvery(ACTIONS.INITIATE_CREATE_TASK_FLUID, callAsync, addTaskFluid);
  yield takeEvery(
    TASK_SURFACE_EQUIPMENT_ACTIONS.SAVE_CABLE_SERIAL_NUMBER,
    callAsync,
    updateCableSerialNumber,
  );
}
