import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReadOnlyField from 'app/components/ReadOnlyField';

const CustomReadOnlyField = (props) => (
  <ReadOnlyField variant="body2" {...props} />
);

const CableWrapBraidedMonoTestDrawerComponent = ({ assetHistory, classes }) => {
  const innerStrand = assetHistory.getIn([
    'cableWrapBraidedMonoTest',
    'innerStrand',
  ]);
  const outerStrand = assetHistory.getIn([
    'cableWrapBraidedMonoTest',
    'outerStrand',
  ]);

  return (
    <>
      {!assetHistory && !innerStrand ? null : (
        <Grid item xs={12} className={classes.paperMargin}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomReadOnlyField
                margin="none"
                label="Inner Strand Pass X"
                className={classes.marginLabel}
              >
                {innerStrand.get('item1')}
              </CustomReadOnlyField>
            </Grid>
            <Grid item xs={6}>
              <CustomReadOnlyField
                margin="none"
                label="Inner Strand Out of Y"
                className={classes.marginLabel}
              >
                {innerStrand.get('item2')}
              </CustomReadOnlyField>
            </Grid>

            <Grid item xs={6}>
              <CustomReadOnlyField
                margin="none"
                label="Outer Strand Pass X"
                className={classes.marginLabel}
              >
                {outerStrand.get('item1')}
              </CustomReadOnlyField>
            </Grid>
            <Grid item xs={6}>
              <CustomReadOnlyField
                margin="none"
                label="Outer Strand Out of Y"
                className={classes.marginLabel}
              >
                {outerStrand.get('item2')}
              </CustomReadOnlyField>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: '4vh',
  },
  marginLabel: {
    marginTop: '4vh',
  },
  marginStrand: {
    marginBottom: '2vh',
  },
});

export default withStyles(styles)(CableWrapBraidedMonoTestDrawerComponent);
