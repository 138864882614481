import { compose } from 'redux';
import { useEffect } from 'react';
import { getName } from 'country-list';
import { connect, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';
import { useHeader } from 'app/hooks/useHeader';

import { facilityTypeToString } from 'mappers';
import { getActionDataStateFromState } from 'app/app.selectors';
import { getFacilityById } from 'features/facilities/facilities.actions';
import { FACILITIES_ACTIONS } from 'features/facilities/facilities.constants';
import { getFacilityByIdFromState } from 'features/facilities/facilities.selectors';
import LazyLoadImage from 'features/facilities/overview/components/FacilityLazyLoadImage';
import { GoogleMapFacilityOverview } from 'features/facilities/overview/components/GoogleMap';

const FacilityOverview = ({
  classes,
  dataState,
  breadcrumb,
  facilityId,
  dispatchOnLoad,
}) => {
  useHeader({
    subTitle: breadcrumb,
  });

  useEffect(() => {
    dispatchOnLoad(facilityId);
  }, [dispatchOnLoad, facilityId]);

  const facility = useSelector(getFacilityByIdFromState);

  return (
    <BasePage dataState={dataState}>
      {!facility.isEmpty() && (
        <Grid container xs={12} item className={classes.container}>
          <Grid item xs={4} container className={classes.bargeContainer}>
            <Grid className={classes.bargeImageContainer}>
              <LazyLoadImage facilityId={facilityId} />
            </Grid>
            <Grid item container className={classes.bargeInfo}>
              {/* <Typography variant="h6">Image Source</Typography>
            <Grid container className={classes.bargeInfoRow}>
              <Typography variant="h6">People on board currently</Typography>
              <Typography variant="h6">25</Typography>
            </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={4} container className={classes.positionContainer}>
            <Grid container className={classes.googleMapsContainer}>
              <GoogleMapFacilityOverview facilityId={facilityId} />
            </Grid>
            <Grid container className={classes.positionInfo}>
              {/* <Typography variant="h6">Last Updated: Fixed/DateTime</Typography> */}
            </Grid>
          </Grid>
          <Grid item xs={4} container className={classes.infoContainer}>
            <Grid className={classes.infoTextContainer}>
              <Grid item container className={classes.textLine}>
                <Grid item xs={6}>
                  <Typography variant="h6">Name:</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">{facility.get('name')}</Typography>
                </Grid>
              </Grid>
              <Grid item container className={classes.textLine}>
                <Grid item container xs={6}>
                  <Typography variant="h6">Type:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  <Typography variant="h6">
                    {facilityTypeToString(facility.get('type'))}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container className={classes.textLine}>
                <Grid item container xs={6}>
                  <Typography variant="h6">Operator:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  <Typography variant="h6">
                    {facility.get('operator')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container className={classes.textLine}>
                <Grid item container xs={6}>
                  <Typography variant="h6">Country:</Typography>
                </Grid>
                <Grid item container xs={6}>
                  <Typography variant="h6">
                    {getName(facility.get('countryCodeIso3166'))}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </BasePage>
  );
};

const styles = (theme) => ({
  container: {
    flexDirection: 'row',
    marginTop: theme.spacing(2.25),
  },
  bargeContainer: {
    flexDirection: 'row',
    padding: theme.spacing(2.25),
  },
  positionContainer: {
    padding: theme.spacing(2.25),
  },
  bargeImageContainer: {
    width: '100%',
    height: '50%',
    marginTop: '10%',
  },
  bargeInfo: {
    height: '20%',
    padding: theme.spacing(2.25),
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerText: {
    height: '10%',
  },
  bargeInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  googleMapsContainer: {
    width: '100%',
    height: '50%',
    marginTop: '10%',
  },
  positionInfo: {
    height: '20%',
  },
  infoContainer: {
    padding: theme.spacing(2.25),
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '50%',
    marginTop: '5%',
  },
  textLine: {
    flexDirection: 'row',
    height: 'fit-content',
  },
  infoTextContainer: {
    width: '100%',
    height: 'fit-content',
  },
});

export default compose(
  connect(
    (state) => ({
      dataState: getActionDataStateFromState(
        state,
        FACILITIES_ACTIONS.GET_FACILITY_BY_ID,
        FACILITIES_ACTIONS.GET_FACILITY_IMAGE,
        FACILITIES_ACTIONS.GET_FACILITY_LOCATION,
      ),
    }),
    {
      dispatchOnLoad: getFacilityById,
    },
  ),
  withStyles(styles),
)(FacilityOverview);
