import SearchIcon from '@material-ui/icons/Search';

import { NoContentBasePage } from 'altus-ui-components';

const NoToolstringSelectedPage = () => (
  <NoContentBasePage
    header="Select a BHA"
    description="Please choose a BHA or create a new one from the left navigation menu"
    Icon={SearchIcon}
  />
);

export default NoToolstringSelectedPage;
