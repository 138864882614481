import { compose } from 'redux';
import { useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { EMPTY_STRING } from 'app/app.constants';

import {
  getCablesByProject,
  onUnload,
  searchCables,
} from 'features/equipment/equipment.actions';
import { getCablesFromState } from 'features/equipment/equipment.selectors';
import AddCablesModal from 'features/projects/tasks/task/surfaceEquipment/components/cables/AddCablesModal';
import { Filters } from 'features/equipment/cables/components/CablesFilter';

const defaultFormValues = {
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
  [Filters.SERIAL_SEARCH]: EMPTY_STRING,
};

const AddCablesModalContainer = ({
  isOpen,
  classes,
  dataState,
  onAddCable,
  toggleModal,
  onRemoveCable,
  selectedCables,
  dispatchOnUnload,
  dispatchOnSearch,
  setSelectedCables,
  projectId,
}) => {
  const dispatch = useDispatch();

  const dispatchOnLoad = useCallback(() => {
    dispatch(getCablesByProject(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatchOnLoad();
    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload]);

  const handleSearch = useCallback(
    (filterValues) => {
      dispatchOnSearch({
        [Filters.TEXT_SEARCH]: filterValues[Filters.TEXT_SEARCH],
        [Filters.SERIAL_SEARCH]: filterValues[Filters.SERIAL_SEARCH],
        getByStatus: true, // filter by un/servicable
      });
    },
    [dispatchOnSearch],
  );

  const cables = useSelector(getCablesFromState);

  return (
    <AddCablesModal
      cables={cables}
      isOpen={isOpen}
      classes={classes}
      dataState={dataState}
      onSubmit={handleSearch}
      onAddCable={onAddCable}
      toggleModal={toggleModal}
      onRemoveCable={onRemoveCable}
      selectedCables={selectedCables}
      initialValues={defaultFormValues}
      setSelectedCables={setSelectedCables}
    />
  );
};

export default compose(
  connect(() => ({}), {
    dispatchOnUnload: onUnload,
    dispatchOnSearch: searchCables,
  }),
)(AddCablesModalContainer);
