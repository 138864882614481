import { compose } from 'redux';
import { useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Box, Grid, Button, DialogActions } from '@material-ui/core';

import { invokeIfFunction } from 'utils/app.util';
import LoadingButton from 'app/components/LoadingButton';

const CreateProjectModalActions = ({
  isValid,
  classes,
  onClose,
  isSubmitting,
  createProject,
}) => {
  // automatically focus on the Create button when the form turns valid
  const action = useCallback(
    (actions) => {
      if (isValid) {
        invokeIfFunction(actions?.focusVisible);
      }
    },
    [isValid],
  );

  return (
    <DialogActions
      classes={{
        root: classes.dialogActionsRoot,
      }}
    >
      <Grid container component={Box} padding={3}>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button onClick={onClose}>Cancel</Button>
          </Grid>
          <Grid item>
            <LoadingButton
              color="primary"
              action={action}
              variant="contained"
              disabled={!isValid}
              onClick={createProject}
              submitting={isSubmitting}
            >
              Create
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </DialogActions>
  );
};

const styles = (theme) => ({
  dialogActionsRoot: {
    background: theme.palette.grey[900],
  },
});

export default compose(withStyles(styles))(CreateProjectModalActions);
