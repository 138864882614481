import { compose } from 'redux';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import { useHeader } from 'app/hooks/useHeader';
import { EUROPE_MAP_CENTER_COORDS } from 'app/app.constants';
import { getActionDataStateFromState } from 'app/app.selectors';
import { loadEquipmentAssets } from 'features/equipment/equipment.actions';
import { getEquipmentAssetsFromState } from 'features/equipment/equipment.selectors';
import EquipmentAssetsTable from './components/EquipmentAssetsTable';
import EquipmentDetailsSubHeader from 'features/equipment/components/EquipmentDetailsSubHeader';
import ProjectMapLocations from 'features/projects/components/WellMapLocations/ProjectMapLocations';
import { EQUIPMENT_ACTIONS } from '../equipment.constants';

const EquipmentAssetsContainer = ({
  equipmentId,
  equipmentType,
  classes,
  assets,
  dataState,
  breadcrumb,
  dispatchOnLoad,
}) => {
  useHeader({ subTitle: breadcrumb, logoNavigation: '/' });

  useEffect(() => {
    dispatchOnLoad(equipmentId, equipmentType);
  }, [equipmentId, equipmentType, dispatchOnLoad]);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <ProjectMapLocations center={EUROPE_MAP_CENTER_COORDS} />
      <Grid item container lg={7} className={classes.container}>
        <Grid item container className={classes.topContainer}>
          <EquipmentDetailsSubHeader equipment={assets} />
          <Grid item xs={12} className={classes.tableContainer}>
            {!assets.size ? (
              <Typography>No assets...</Typography>
            ) : (
              <EquipmentAssetsTable
                equipmentId={equipmentId}
                equipmentType={equipmentType}
                assets={assets.get('m3ToolAssets')}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    padding: 0,
  },
  container: {
    flexDirection: 'column',
    top: 0,
    bottom: 0,
    overflow: 'auto',
    position: 'absolute',
    padding: theme.spacing(2.25),
  },
  topContainer: {
    flexDirection: 'row',
    height: '10%',
  },
  tableContainer: {
    paddingTop: '10px',
  },
});

export default compose(
  connect(
    (state) => ({
      assets: getEquipmentAssetsFromState(state),
      dataState: getActionDataStateFromState(
        state,
        EQUIPMENT_ACTIONS.RECEIVE_EQUIPMENT_ASSETS,
      ),
    }),
    {
      dispatchOnLoad: loadEquipmentAssets,
    },
  ),
  withStyles(styles),
)(EquipmentAssetsContainer);
