import { useEffect, useState } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { CircularProgress, Typography, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getFacilityImage } from 'features/facilities/facilities.actions';
import { EMPTY_STRING } from 'app/app.constants';

const FacilityLazyLoadImage = ({ facilityId, classes, ...props }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFacilityImage(facilityId))
      .then((payload) => {
        if (payload.urlImage === EMPTY_STRING) {
          setLoading(false);
          setError(true);
        } else {
          setImgSrc(payload.urlImage);
          setLoading(false);
        }
      })
      .catch(() => setError(true));
  }, [dispatch, facilityId]);

  return (
    <Grid container className={classes.container}>
      {error ? (
        <Typography variant="body2">No preview available</Typography>
      ) : loading ? (
        <CircularProgress />
      ) : (
        <img
          {...{ src: imgSrc, ...props }}
          alt={props.alt || ''}
          className={classes.img}
          onError={() => {
            setError(true);
          }}
        />
      )}
    </Grid>
  );
};

const styles = () => ({
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  container: {
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default withStyles(styles)(FacilityLazyLoadImage);
