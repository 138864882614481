import React from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Dialog, DialogContent } from '@material-ui/core';
import Tool3DObjectModalHeader from 'features/equipment/components/Tool3DObjectModalHeader';
import Tool3DObjectModalContent from 'features/projects/tool/components/Tool3DObjectModalContent';

const Tool3DObjectModalView = ({
  title,
  toggleModal,
  openModal,
  classes,
  tool3DObjectUrl,
  toolDiameter,
  toolLength,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth={'xl'}
      open={openModal}
      onClose={toggleModal}
      classes={{ paperScrollPaper: classes.root }}
    >
      <Grid className={classes.header}>
        <Tool3DObjectModalHeader
          closeModal={toggleModal}
          title={title ? title : '3D Object'}
        />
      </Grid>
      <DialogContent className={classes.content}>
        <Tool3DObjectModalContent
          tool3DObjectUrl={tool3DObjectUrl}
          toolDiameter={toolDiameter}
          toolLength={toolLength}
        />
      </DialogContent>
    </Dialog>
  );
};

const styles = (theme) => ({
  root: {
    height: 'inherit',
  },
  content: {},
  header: {
    padding: theme.spacing(3),
  },
});

export default compose(withStyles(styles))(Tool3DObjectModalView);
