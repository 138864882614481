import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useMemo, memo } from 'react';
import { Collection, Map } from 'immutable';
import { Grid, IconButton, Tooltip, Chip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ShowAttachments from '@material-ui/icons/Link';
import HideAttachments from '@material-ui/icons/LinkOff';
import withStyles from '@material-ui/styles/withStyles';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { formatValue } from 'utils/format.util';
import { getQuantityUnitFromItems } from 'utils/app.util';
import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';
import TaskToolstringTotals from 'features/projects/tasks/task/toolstring/components/TaskToolstringTotals';
import ToolstringToolsTableRow from 'features/projects/tasks/task/toolstring/components/ToolstringToolsTableRow';
import { ToolType } from 'features/projects/tool/tool.constants';
import { formatToolstringToolStatusString } from 'features/projects/tasks/task/toolstring/toolstring.util';
import { TOOL_STATUS } from 'features/projects/tasks/task/toolstring/toolstring.constants';

const getSubRows = (row) => {
  if (row.original) return;

  return row.get('attachedTools').size > 0
    ? row.get('attachedTools')
    : row.get('toolAssemblyTools');
};

const ToolstringToolsTable = ({
  classes,
  toolstring = EMPTY_MAP,
  maxAvailableHeight = { value: 0.0 },
  toolstringTools = EMPTY_MAP,
}) => {
  const lengthUnit = getQuantityUnitFromItems(toolstringTools, (result) =>
    result.get('length'),
  );

  const weightUnit = getQuantityUnitFromItems(toolstringTools, (result) =>
    result.get('weight'),
  );

  const diameterUnit = getQuantityUnitFromItems(toolstringTools, (result) =>
    result.get('outerDiameter'),
  );

  const columns = useMemo(
    () => [
      {
        xs: 0.5,
        id: 'expander',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const hasAttachments = row.original.get('attachedTools')?.size > 0;
          if (!row.canExpand && !hasAttachments)
            return <TableRowActionsCell minItems={1} />;

          const [less, more] = hasAttachments
            ? [<HideAttachments />, <ShowAttachments />]
            : [<ExpandLess />, <ExpandMore />];

          const title = hasAttachments
            ? 'Toggle Attached Tools'
            : row.getToggleRowExpandedProps().title;

          return (
            <TableRowActionsCell
              style={{
                paddingLeft:
                  row.original.get('type') === ToolType.ASSEMBLY_TOOL
                    ? 40
                    : 'inherit',
              }}
            >
              <IconButton {...row.getToggleRowExpandedProps()} title={title}>
                {row.isExpanded ? less : more}
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 0.5,
        id: 'dataVerified',
        Cell: ({ row }) => {
          const tool = row.original;

          if (row.canExpand) {
            const toolAssemblyTools = tool.get('toolAssemblyTools', EMPTY_LIST);
            const hasNullDataVerified = toolAssemblyTools.some(
              (tool) => tool.get('dataVerified') === null,
            );
            if (hasNullDataVerified) {
              return (
                <TableRowActionsCell minItems={0}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Tooltip title="Unverified tool">
                      <WarningIcon className={classes.warningIcon} />
                    </Tooltip>
                  </Grid>
                </TableRowActionsCell>
              );
            } else {
              return null;
            }
          } else if (!tool.get('dataVerified')) {
            return (
              <TableRowActionsCell minItems={0}>
                <Grid container justifyContent="center" alignItems="center">
                  <Tooltip title="Unverified tool">
                    <WarningIcon className={classes.warningIcon} />
                  </Tooltip>
                </Grid>
              </TableRowActionsCell>
            );
          } else return null;
        },
      },
      {
        xs: 5,
        id: 'toolName',
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
      {
        xs: 2,
        id: 'accumulatedLength',
        accessor: (tool) =>
          formatValue(tool.getIn(['accumulatedLength', 'value']), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Acc. Length" unit={lengthUnit} />
        ),
      },
      {
        xs: 2,
        id: 'toolLength',
        accessor: (tool) =>
          formatValue(tool.getIn(['length', 'value']), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Length" unit={lengthUnit} />
        ),
      },
      {
        xs: 2,
        id: 'toolWeight',
        accessor: (tool) =>
          formatValue(tool.getIn(['weight', 'value']), '', 0.1),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Weight" unit={weightUnit} />
        ),
      },
      {
        xs: 2,
        id: 'outerDiameter',
        accessor: (tool) =>
          formatValue(tool.getIn(['outerDiameter', 'value']), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell
            title="Outer Dia."
            unit={diameterUnit}
          />
        ),
      },
      {
        xs: 2,
        id: 'statusChip',
        Cell: ({ row }) => {
          const tool = row.original;
          const toolStatus = tool.get('toolStatus');

          if (
            toolStatus === TOOL_STATUS.NOT_ASSIGNED ||
            toolStatus === TOOL_STATUS.NULL_ASSIGNED
          )
            return null;
          return <Chip label={formatToolstringToolStatusString(toolStatus)} />;
        },
      },
    ],
    [lengthUnit, weightUnit, diameterUnit, classes],
  );

  return (
    <Grid item container direction="column">
      <Grid
        xs
        item
        container
        wrap="nowrap"
        direction="column"
        className={classes.scrollable}
      >
        <Grid item>
          <Table
            useExpanded
            stickyHeader
            disableSortBy
            columns={columns}
            useGlobalFilter={false}
            items={toolstringTools}
            getSubRows={getSubRows}
            noItemsMessage="This BHA got no tools"
            TableRowComponent={ToolstringToolsTableRow}
          />
        </Grid>
      </Grid>
      <Grid item container className={classes.totals}>
        <TaskToolstringTotals
          toolstring={toolstring}
          maxAvailableHeight={maxAvailableHeight}
        />
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  scrollable: {
    overflowY: 'auto',
  },
  totals: {
    paddingTop: theme.spacing(2),
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
});

ToolstringToolsTable.propTypes = {
  maxAvailableHeight: PropTypes.object,
  toolstring: PropTypes.instanceOf(Map),
  toolstringTools: PropTypes.instanceOf(Collection),
};

export default compose(memo, withStyles(styles))(ToolstringToolsTable);
