import { compose } from 'redux';
import PropTypes from 'prop-types';
import { useMemo, memo } from 'react';
import { Collection } from 'immutable';

import { Table } from 'altus-ui-components';

import { EMPTY_MAP } from 'app/app.constants';
import { formatValue } from 'utils/format.util';
import { getQuantityUnitFromItems } from 'utils/app.util';
import TableHeaderTitleCell from 'app/components/TableHeaderTitleCell';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';
import SimulationResultsTableCell from 'features/projects/tasks/task/simulation/components/SimulationResultsTableCell';
import SimulationResultsTableHeader from 'features/projects/tasks/task/simulation/components/SimulationResultsTableHeader';

const SimulationResultsTable = ({ simulationResults = EMPTY_MAP }) => {
  const depthUnit = getQuantityUnitFromItems(simulationResults, (result) =>
    result.get('measuredDepth'),
  );

  const forceUnit = getQuantityUnitFromItems(simulationResults, (result) =>
    result.getIn(['runInHole', 'surfaceTension']),
  );

  const columns = useMemo(
    () => [
      {
        xs: 4,
        Header: '',
        id: 'column1',
        columns: [
          {
            xs: 2,
            id: 'measuredDepth',
            Header: () => (
              <QuantityTableHeaderTitleCell
                title="MD"
                unit={depthUnit}
                justifyContent="center"
              />
            ),
            accessor: (simulationResult) =>
              formatValue(simulationResult.getIn(['measuredDepth', 'value'])),
          },
          {
            xs: 2,
            id: 'runInHole.tractorForce',
            Header: () => (
              <QuantityTableHeaderTitleCell
                title="Tractor Force"
                unit={forceUnit}
                justifyContent="center"
              />
            ),
            accessor: (simulationResult) =>
              formatValue(
                simulationResult.getIn(['runInHole', 'tractorForce', 'value']),
              ),
          },
        ],
      },
      {
        xs: 4,
        id: 'surfaceTension',
        Header: () => <TableHeaderTitleCell title="Surface Weight" />,
        columns: [
          {
            xs: 2,
            id: 'runInHole.surfaceTension',
            Header: () => (
              <QuantityTableHeaderTitleCell
                title="RIH"
                unit={forceUnit}
                justifyContent="center"
              />
            ),
            accessor: (simulationResult) =>
              formatValue(
                simulationResult.getIn([
                  'runInHole',
                  'surfaceTension',
                  'value',
                ]),
              ),
          },
          {
            xs: 2,
            id: 'pullOutOfHole.surfaceTension',
            Header: () => (
              <QuantityTableHeaderTitleCell
                title="POOH"
                unit={forceUnit}
                justifyContent="center"
              />
            ),
            accessor: (simulationResult) =>
              formatValue(
                simulationResult.getIn([
                  'pullOutOfHole',
                  'surfaceTension',
                  'value',
                ]),
              ),
          },
        ],
      },
      {
        xs: 4,
        id: 'column3',
        Header: () => <TableHeaderTitleCell title="Cable Head Tension" />,
        columns: [
          {
            xs: 2,
            id: 'runInHole.cableHeadTension',
            Header: () => (
              <QuantityTableHeaderTitleCell
                title="RIH"
                unit={forceUnit}
                justifyContent="center"
              />
            ),
            accessor: (simulationResult) =>
              formatValue(
                simulationResult.getIn([
                  'runInHole',
                  'cableHeadTension',
                  'value',
                ]),
              ),
          },
          {
            xs: 2,
            id: 'pullOutOfHole.cableHeadTension',
            Header: () => (
              <QuantityTableHeaderTitleCell
                title="POOH"
                unit={forceUnit}
                justifyContent="center"
              />
            ),
            accessor: (simulationResult) =>
              formatValue(
                simulationResult.getIn([
                  'pullOutOfHole',
                  'cableHeadTension',
                  'value',
                ]),
              ),
          },
        ],
      },
      {
        xs: 2,
        Header: '',
        id: 'column4',
        columns: [
          {
            xs: 2,
            id: 'pullOutOfHole.maxPickupWeight',
            Header: () => (
              <QuantityTableHeaderTitleCell
                unit={forceUnit}
                justifyContent="center"
                title="Max. Pick-up Force POOH"
              />
            ),
            accessor: (simulationResult) =>
              formatValue(
                simulationResult.getIn([
                  'pullOutOfHole',
                  'maxPickupWeight',
                  'value',
                ]),
              ),
          },
        ],
      },
    ],
    [depthUnit, forceUnit],
  );

  return (
    <Table
      stickyHeader
      disableSortBy
      columns={columns}
      useGlobalFilter={false}
      items={simulationResults}
      TableCellComponent={SimulationResultsTableCell}
      TableHeaderComponent={SimulationResultsTableHeader}
    />
  );
};

SimulationResultsTable.propTypes = {
  simulationResults: PropTypes.instanceOf(Collection),
};

export default compose(memo)(SimulationResultsTable);
