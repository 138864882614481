export const CREATE_SMLOCATION_MODAL = 'CREATE_SMLOCATION_MODAL';

export const SMLOCATION_CONFIG_ACTIONS = {
  GET_SMLOCATIONS: 'GET_SMLOCATIONS',
  RECEIVE_SMLOCATIONS: 'RECEIVE_SMLOCATIONS',
  RECEIVE_SMLOCATION: 'RECEIVE_SMLOCATION',
  CREATE_SMLOCATION: 'CREATE_SMLOCATION',
  UPDATE_SMLOCATION: 'UPDATE_SMLOCATION',
  DELETE_SMLOCATION: 'DELETE_SMLOCATION',
};

export const SMLocationCreateFormFields = {
  NAME: 'name',
};
