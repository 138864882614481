import { useState } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';
import { Grid } from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const defaultFormat = (value) =>
  value instanceof EditorState ? value : EditorState.createEmpty();

const EditorReduxForm = ({
  input,
  meta,
  format = defaultFormat,
  toolbarHeight,
  label,
  showbarByDefault = false,
  xs = 6,
  editorXS = 0,
  generalMarginTop = 12,
  editorMarginTop = 10,
  marginBottom = 0,
  disabled = false,
  ...rest
}) => {
  const { value, onChange, name, onBlur } = input;

  const [isFocused, setIsFocused] = useState(false);
  const [show, setShow] = useState(showbarByDefault);

  const formattedValue = format ? format(value) : EditorState.createEmpty();

  const handleEditorStateChange = (editorState) => {
    onChange(editorState);
  };

  const handleBlur = (event) => {
    if (!showbarByDefault) {
      setShow(false);
    }
    setIsFocused(false);
    onBlur(event);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setShow(true);
  };

  const handlePastedText = (text, _html, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const newContent = Modifier.replaceText(currentContent, selection, text);

    onChange(EditorState.push(editorState, newContent, 'insert-characters'));
    return 'handled';
  };

  return (
    <Grid
      container
      item
      xs={xs}
      style={{
        marginBottom: `${5}px`,
        marginTop: `${generalMarginTop}px`,
      }}
    >
      <Grid item>
        {label ? <label className="editor-label">{label}</label> : <></>}
      </Grid>
      <Grid
        style={{
          width: '100%',
          height: '100%',
          marginBottom: `${marginBottom}px`,
          marginTop: `${editorMarginTop}px`,
        }}
        xs={editorXS}
      >
        <Editor
          readOnly={disabled}
          editorState={formattedValue}
          onEditorStateChange={handleEditorStateChange}
          onBlur={handleBlur}
          name={name}
          toolbarClassName="my-toolbar"
          toolbarStyle={{
            visibility: `${show ? 'visible' : 'hidden'}`,
          }}
          editorStyle={{
            border: '1px solid #595959',
            borderRadius: '4px',
          }}
          editorClassName={isFocused ? 'editor-class-focus' : 'editor-class'}
          error={meta.touched && meta.error}
          helperText={meta.touched ? meta.error : undefined}
          onFocus={handleFocus}
          {...rest}
          toolbar={{
            options: ['inline', 'list'],
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            list: {
              inDropdown: false,
              options: ['unordered', 'ordered'],
            },
          }}
          handlePastedText={handlePastedText}
        />
      </Grid>
    </Grid>
  );
};
EditorReduxForm.propTypes = {
  format: PropTypes.func,
  destroyOnUnmount: PropTypes.bool,
};

export default EditorReduxForm;
