import {
  Grid,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';

let isPageLoaded = false;

const SimulationExportModalChartSelect = ({
  isChartSelected,
  availableCharts,
  onToggleChartSelect,
  unCheckItems,
}) => {

  return (
    <Grid item xs component={List}>
      {availableCharts.map((chart) => {
        let check = isChartSelected(chart);

        if (!isPageLoaded && unCheckItems.includes(chart.chartType)) {
          isPageLoaded = true;
          check = false;
        }

        return (
          <ListItem
            dense
            button
            key={chart.chartType}
            onClick={() => onToggleChartSelect(chart)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                disableRipple
                checked={check}
              />
            </ListItemIcon>
            <ListItemText primary={chart.title} />
          </ListItem>
        )
      })}
    </Grid>
  )
};

export default SimulationExportModalChartSelect;
