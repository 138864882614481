import { OrderedMap } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import { ACTIONS } from 'features/projects/tasks/task/simulation/simulation.constants';
import simulationMappers from 'features/projects/tasks/task/simulation/simulation.mappers';

const initialState = EMPTY_MAP;

const simulationFluidReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.DELETE_SIMULATION_FLUID: {
      if (error) return state;

      const { simulationFluidId } = action;

      return state.delete(simulationFluidId.toString());
    }

    case ACTIONS.RECEIVE_SIMULATION_FLUID: {
      if (error) return state;

      return state.set(
        payload.simulationFluidId.toString(),
        simulationMappers.SimulationFluid.from(payload),
      );
    }

    case ACTIONS.RECEIVE_SIMULATION_FLUIDS: {
      if (error) return state;

      return OrderedMap(
        payload.map((simulationFluid) => [
          simulationFluid.simulationFluidId.toString(),
          simulationMappers.SimulationFluid.from(simulationFluid),
        ]),
      );
    }

    default:
      return state;
  }
};

export default simulationFluidReducer;
