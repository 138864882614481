import { getIn } from 'formik';

import { CreateProjectFormFields as FormFields } from 'features/projects/projects.constants';

export const validateCreateProjectForm = (values) => {
  let errors = {};

  [
    FormFields.FIELD_ID,
    FormFields.WELLBORE_ID,
    FormFields.FACILITY_ID,
    FormFields.ORGANIZATION_ID,
    FormFields.OBJECTIVE_ID,
  ].forEach((name) => {
    const value = getIn(values, name);

    if (!value) {
      errors[name] = 'Required';
    }
  });

  return errors;
};
