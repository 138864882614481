import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { SimulationFormFields as FormFields } from 'features/projects/tasks/task/simulation/simulation.constants';

const CreateSimulationForm = () => (
  <Grid container direction="column">
    <Grid item>
      <Field
        required
        autoFocus
        fullWidth
        label="Name"
        margin="normal"
        component={TextFieldFormik}
        name={FormFields.NAME}
      />
    </Grid>
  </Grid>
);

export default CreateSimulationForm;
