import { Grid } from '@material-ui/core';
import { Field } from 'redux-form/immutable';

import DateTimePickerField from 'app/components/form/DateTimePickerField';

const AdjustTimeField = ({ names, disabled, DateTimePickerProps, ...rest }) => {
  const [startTimeName, endTimeName] = names;
  const startTime = rest[startTimeName];
  const endTime = rest[endTimeName];

  return (
    <Grid item xs={6}>
      <Field
        component={DateTimePickerField}
        name={startTime.input.name}
        onChange={(value) => {
          startTime.input.onChange(value);
          endTime.input.onChange(value);
        }}
        DateTimePickerProps={DateTimePickerProps}
      />
    </Grid>
  );
};

export default AdjustTimeField;
