import { callAsync } from '../../app/sagas/helperSagas';
import { WELLS_ACTIONS as ACTIONS } from './wells.constants';
import { call, takeEvery, put } from 'redux-saga/effects';
import wellboreFluidService from '../../services/wellboreFluid.service';
import {
  createFluid,
  getWellboreFluids,
  updateFluid,
} from './wellboreFluid.actions';
import { FormikFormStatus } from '../../app/app.constants';

function* getAllWellboreFluids(action) {
  const { wellboreId } = action;
  const fluids = yield call(wellboreFluidService.getAllFluids, wellboreId);
  yield put(getWellboreFluids(fluids));
}

export function* deleteWellboreFluid(action) {
  const { wellboreId, fluidId } = action;
  yield call(wellboreFluidService.deleteFluid, wellboreId, fluidId);
  yield call(getAllWellboreFluids, { wellboreId });
  yield put(deleteFluid(wellboreId, fluidId));
}

export const deleteFluid = (wellboreId, fluidId) => ({
  type: ACTIONS.DELETE_WELLBORE_FLUID,
  payload: {
    fluidId,
    wellboreId,
  },
});

function* updateWellboreFluid(action) {
  const { wellboreId, fluidObj, setSubmitting, setStatus } = action;
  try {
    yield call(wellboreFluidService.updateFluid, wellboreId, fluidObj);
    yield call(getAllWellboreFluids, { wellboreId });
    yield put(updateFluid(fluidObj.fluidId, wellboreId));
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);
    throw error;
  }
}

function* createWellboreFluid(action) {
  const { wellboreId, fluidObj, setSubmitting, setStatus } = action;
  try {
    yield call(wellboreFluidService.addFluid, wellboreId, fluidObj);
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.SUCCESS);
    yield call(getAllWellboreFluids, { wellboreId });
    yield put(createFluid(wellboreId, wellboreId));
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setStatus, FormikFormStatus.ERROR);
    throw error;
  }
}

export default function* root() {
  yield takeEvery(
    ACTIONS.INITIATE_GET_WELLBORE_FLUIDS,
    callAsync,
    getAllWellboreFluids,
  );

  yield takeEvery(
    ACTIONS.INITIATE_DELETE_WELLBORE_FLUID,
    callAsync,
    deleteWellboreFluid,
  );

  yield takeEvery(
    ACTIONS.INITIATE_UPDATE_WELLBORE_FLUID,
    callAsync,
    updateWellboreFluid,
  );

  yield takeEvery(
    ACTIONS.INITIATE_CREATE_WELLBORE_FLUID,
    callAsync,
    createWellboreFluid,
  );
}
