import { compose } from 'redux';
import classNames from 'classnames';
import { useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Chip, Grid, Typography } from '@material-ui/core';

import { EMPTY_LIST } from 'app/app.constants';

const ProjectDepartmentsField = ({ input, classes, departments }) => {
  const { value: projectDepartments = EMPTY_LIST } = input;

  const projectHasDepartment = useCallback(
    (departmentId) =>
      projectDepartments
        .map((department) => department.get('id').toString())
        .includes(departmentId),
    [projectDepartments],
  );

  if (!projectDepartments.size) {
    return (
      <Grid item>
        <Typography>
          <i>No departments currently set...</i>
        </Typography>
      </Grid>
    );
  }

  return departments
    .map((department, departmentId) => (
      <Grid key={departmentId} item>
        <Chip
          label={department.get('name')}
          classes={{
            root: classNames({
              [classes.chipRootDisabled]: !projectHasDepartment(departmentId),
            }),
          }}
        />
      </Grid>
    ))
    .valueSeq();
};

const styles = () => ({
  chipRootDisabled: {
    opacity: 0.5,
  },
});

export default compose(withStyles(styles))(ProjectDepartmentsField);
