import { useMemo, useCallback } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton, Link, Grid, Typography } from '@material-ui/core';

import { Table, TableRowActionsCell, UserAvatar } from 'altus-ui-components';

import { Format } from 'app/app.constants';
import { formatDate, formatDuration } from 'utils/format.util';
import NPTTableRow from 'features/projects/npt/components/NPTTableRow';
import { SYNERGY_CASE_URL } from 'features/projects/incidents/incidents.constants';

const NPTTable = ({ npts, projectId, editNpt, deleteNpt }) => {
  const columns = useMemo(
    () => [
      {
        xs: 2,
        Header: 'Case Number',
        accessor: (npt) => npt.get('caseNumber'),
        Cell: ({ row }) => {
          const url = SYNERGY_CASE_URL + row.original.get('caseNumber');
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              title="Case Number"
            >
              {row.original.get('caseNumber')}
            </Link>
          );
        },
      },
      {
        xs: 2,
        id: 'start',
        Header: 'Start time',
        accessor: (npt) => npt.get('startTime'),
        Cell: ({ value }) => formatDate(value, Format.time_long) || 'N/A',
      },
      {
        xs: 2,
        id: 'end',
        Header: 'End time',
        accessor: (npt) => npt.get('endTime'),
        Cell: ({ value }) => formatDate(value, Format.time_long) || 'N/A',
      },
      {
        xs: 3,
        id: 'duration',
        Header: 'Duration',
        accessor: (npt) => npt.get('duration'),
        Cell: ({ value }) => formatDuration(value),
      },
      {
        xs: 4,
        Header: 'Author',
        Cell: ({ row }) => {
          const npt = row.original;
          return (
            <Grid container style={{ alignItems: 'center' }}>
              <Grid item xs={3}>
                <UserAvatar
                  avatar={npt.getIn(['author', 'avatar'])}
                  initials={npt.getIn(['author', 'initials'])}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle2">
                  {npt.getIn(['author', 'name'])}
                </Typography>
              </Grid>
            </Grid>
          );
        },
      },
      {
        id: 'actions',
        Header: <TableRowActionsCell minItems={2} />,
        Cell: ({ row }) => {
          return (
            <TableRowActionsCell minItems={2}>
              <IconButton title="Edit" onClick={() => editNpt(row.index)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton
                title="Delete"
                onClick={() =>
                  deleteNpt(
                    projectId,
                    row.original.get('projectNonProductiveTimeId'),
                  )
                }
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
      {
        id: 'expansionIcon',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: () => (
          <TableRowActionsCell>
            <ExpandMoreIcon />
          </TableRowActionsCell>
        ),
      },
    ],
    [editNpt, deleteNpt, projectId],
  );

  const renderTableRowComponent = useCallback(
    (props) => <NPTTableRow {...props} />,
    [],
  );

  return (
    <Table
      items={npts}
      columns={columns}
      useGlobalFilter={false}
      noItemsMessage={'No non productive times'}
      TableRowComponent={renderTableRowComponent}
    />
  );
};
export default NPTTable;
