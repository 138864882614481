import {
  SENSOR_NAME_DEPTH,
  SensorOffset,
} from 'features/projects/dashboard/components/dashboards/logging/helpers/constants';

export const convertCurvesToOffsetConfiguration = (curves) => {
  return Array.from(curves)
    ?.filter((x) => x.get('name') !== SENSOR_NAME_DEPTH)
    .map((x) => ({ name: x.get('name'), offset: x.get('offset') }))
    .reduce((acc, curr) => {
      acc = { ...{ [curr.name]: curr.offset }, ...acc };
      return acc;
    }, {});
};
const getAllOffsetsAsArray = (offsetConfiguration) => {
  return Object.keys(offsetConfiguration).map(
    (key) => offsetConfiguration[key],
  );
};
const findExtreme = (offsetConfiguration, minMaxFn) => {
  if (!offsetConfiguration || Object.keys(offsetConfiguration).length === 0) {
    return SensorOffset.DEFAULT;
  } else {
    const allOffsets = getAllOffsetsAsArray(offsetConfiguration);
    return minMaxFn(...allOffsets);
  }
};
export const findMinimumOffset = (offsetConfiguration) =>
  findExtreme(offsetConfiguration, Math.min);
export const findMaximumOffset = (offsetConfiguration) =>
  findExtreme(offsetConfiguration, Math.max);

export const createNewEventWithOffset = (event, curves) => {
  const newEvent = { ...event };
  const offsetConfiguration = convertCurvesToOffsetConfiguration(curves);
  newEvent.min = event.min - findMaximumOffset(offsetConfiguration);
  newEvent.max = event.max - findMinimumOffset(offsetConfiguration);
  return newEvent;
};
