import { toggleModal } from 'app/app.actions';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import wellControlService from 'features/wellControlDrillActivity/wellControl.service';
import { WELL_CONTROL_ACTIONS } from 'features/wellControlDrillActivity/wellControl.constants';
import { getTaskActivities } from 'features/projects/activities/activities.actions';
import { EDIT_ACTIVITY_MODAL_ID } from 'features/projects/activities/activities.constants';

export const loadCategoriesAndSituations = (textSearch = '') => (dispatch) => {
  dispatch({
    type: WELL_CONTROL_ACTIONS.GET_ALL_CATEGORIES_AND_SITUATIONS,
    payload: () =>
      wellControlService.getWellControlDrillCategoriesAndSituations(textSearch),
  });
};

export const updateActivityWcd = (formValues) => (dispatch) => {
  const { projectId, taskId, ...activity } = formValues;

  const payload = wellControlService
    .updateActivityWcd(projectId, taskId, activity)
    .then(() => {
      return dispatch(
        getTaskActivities(projectId, taskId, { silent: true }),
      ).then(() => {
        dispatch(toggleModal({ modalId: EDIT_ACTIVITY_MODAL_ID }));
        window.location.reload();
      });
    });

  dispatch({
    type: WELL_CONTROL_ACTIONS.UPDATE_WELL_CONTROLL_DRILL_ACTIVITY,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Updating activity',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Activity successfully updated!',
    },
    payload,
  });
};
