import { compose } from 'redux';
import isNil from 'lodash/isNil';
import Error from '@material-ui/icons/Error';
import { Grid, Fab } from '@material-ui/core';
import { useCallback, useEffect } from 'react';
import Refresh from '@material-ui/icons/Refresh';
import { connect, useSelector } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage, NoContentBasePage } from 'altus-ui-components';

import {
  getProjectWellboreDetailFromState,
  getWellboreDetailFromProjectWellboreDetailFromState,
} from 'features/projects/wellbore/details/projectWellboreDetails.selectors';

import {
  getActionDataStateFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';

import {
  requestProjectWellboreDetail,
  submitProjectWellboreDetailForm,
  requestSyncProjectWellboreDetailsWithLatest as syncProjectWellboreDetailsWithLatest,
} from 'features/projects/wellbore/details/projectWellboreDetails.actions';

import { ProjectWellboreDetailStatus } from 'app/app.constants';
import { ProjectWellboreDetailsAndFluids } from 'app/components/WellboreDetailsForm/WellboreDetailsForm';
import { ACTIONS } from 'features/projects/wellbore/details/projectWellboreDetails.constants';

const ProjectWellboreDetailFormContainer = ({
  classes,
  projectId,
  projectWellboreDetailId,
  dispatchRequestProjectWellboreDetail,
  dispatchSubmitProjectWellboreDetailForm,
  dispatchSyncProjectWellboreDetailsWithLatest,
}) => {
  const dataState = useSelector((state) =>
    getSummarizedDataStateFromState(
      state,
      ACTIONS.LOAD_PROJECT_WELLBORE_DETAILS_PAGE,
      ACTIONS.SYNC_PROJECT_WELLBORE_DETAILS_WITH_LATEST,
    ),
  );

  const requestProjectWellboreDetailDataState = useSelector((state) =>
    getActionDataStateFromState(state, ACTIONS.REQUEST_PROJECT_WELLBORE_DETAIL),
  );

  const wellboreDetail = useSelector((state) =>
    getWellboreDetailFromProjectWellboreDetailFromState(
      state,
      projectWellboreDetailId,
    ),
  );

  const projectWellboreDetail = useSelector((state) =>
    getProjectWellboreDetailFromState(state, projectWellboreDetailId),
  );

  const status = projectWellboreDetail?.get('status');

  useEffect(() => {
    dispatchRequestProjectWellboreDetail(projectId, projectWellboreDetailId);
  }, [
    dispatchRequestProjectWellboreDetail,
    projectId,
    projectWellboreDetailId,
  ]);

  const disabled =
    isNil(status) || status === ProjectWellboreDetailStatus.APPROVED;

  const onSubmit = useCallback(
    (values, { setSubmitting, setStatus }) =>
      dispatchSubmitProjectWellboreDetailForm(
        projectId,
        projectWellboreDetailId,
        values,
        setSubmitting,
        setStatus,
      ),
    [
      projectId,
      projectWellboreDetailId,
      dispatchSubmitProjectWellboreDetailForm,
    ],
  );

  if (requestProjectWellboreDetailDataState.isError()) {
    return (
      <NoContentBasePage
        Icon={Error}
        header="An error occurred"
        description="An error occurred while getting the Well Details"
      />
    );
  }

  return (
    <BasePage dataState={dataState}>
      <Grid container justifyContent="center">
        <Grid container item xs={12} md={8}>
          <ProjectWellboreDetailsAndFluids
            disabled={disabled}
            onSubmit={onSubmit}
            projectId={projectId}
            wellboreDetail={wellboreDetail}
            projectWellboreDetailId={projectWellboreDetailId}
          />
        </Grid>
      </Grid>
      <Fab
        onClick={() =>
          dispatchSyncProjectWellboreDetailsWithLatest(
            projectId,
            projectWellboreDetailId,
          )
        }
        color="primary"
        title="Sync with latest"
        className={classes.fab}
      >
        <Refresh />
      </Fab>
    </BasePage>
  );
};

const styles = (theme) => ({
  fab: {
    zIndex: 2,
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
});

export default compose(
  connect(null, {
    dispatchRequestProjectWellboreDetail: requestProjectWellboreDetail,
    dispatchSubmitProjectWellboreDetailForm: submitProjectWellboreDetailForm,
    dispatchSyncProjectWellboreDetailsWithLatest:
      syncProjectWellboreDetailsWithLatest,
  }),
  withStyles(styles),
)(ProjectWellboreDetailFormContainer);
