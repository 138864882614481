import { List } from 'immutable';
import { useState, useCallback } from 'react';

import { useRequest } from 'altus-hooks';

import mappers from 'mappers';
import { EMPTY_LIST } from 'app/app.constants';
import operationService from 'services/operation.service';
import CheetahJobFilter from 'features/data-exchange/components/CheetahJobFilter';

const CheetahJobFilterContainer = ({ filter, ...rest }) => {
  const [facilities, setFacilities] = useState(EMPTY_LIST);
  const [organizations, setOrganizations] = useState(EMPTY_LIST);

  const getCheetahJobsFilter = useCallback(
    () =>
      operationService.getCheetahJobsFilter(filter).then((filter) => {
        const { facilities, organizations } = filter;

        setFacilities(
          List(facilities.filter(Boolean).map(mappers.Facility.from)),
        );

        setOrganizations(
          List(organizations.filter(Boolean).map(mappers.Organization.from)),
        );
      }),
    [filter],
  );

  useRequest(getCheetahJobsFilter);

  return (
    <CheetahJobFilter
      filter={filter}
      facilities={facilities}
      organizations={organizations}
      {...rest}
    />
  );
};

export default CheetahJobFilterContainer;
