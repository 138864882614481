import Agent from 'infrastructure/agent';

const getDefaultTaskRiskSection = (taskId) =>
  Agent.get(`/risk/taskRisk/${taskId}`);

const getTaskRiskSection = (taskId) =>
  Agent.get(`/risk/taskrisk/task/${taskId}`);

const getTaskRiskSectionsForTaskRisk = (taskId) =>
  Agent.get(`/risk/taskRisk/task/${taskId}`);

const createTaskRiskSection = (risks) => Agent.post('/risk/taskRisk', risks);

const updateTaskRiskSection = (risks) => Agent.put('/risk/taskRisk', risks);

const deleteTaskRiskSection = (taskRiskId) =>
  Agent.delete(`/risk/taskRisk/${taskRiskId}`);

//PROJECT

const getDefaultProjectRiskSection = (projectId) =>
  Agent.get(`/risk/projectrisk/${projectId}`);

const getProjectRiskSection = (projectId) =>
  Agent.get(`/risk/projectrisk/${projectId}`);

const getProjectRiskSectionsForProjectRisk = (projectId) =>
  Agent.get(`/risk/projectrisk/project/${projectId}`);

const getTaskRiskSectionsByProject = (projectId) =>
  Agent.get(`/risk/projectrisk/project/${projectId}/taskrisk`);

const createProjectRiskSection = (risks) =>
  Agent.post('/risk/projectRisk', risks);

const updateProjectRiskSection = (risks) =>
  Agent.put('/risk/projectRisk', risks);

const deleteProjectRiskSection = (projectRiskId) =>
  Agent.delete(`/risk/projectRisk/${projectRiskId}`);

const riskService = {
  getTaskRiskSection,
  updateTaskRiskSection,
  createTaskRiskSection,
  deleteTaskRiskSection,
  getDefaultTaskRiskSection,
  getTaskRiskSectionsForTaskRisk,

  getProjectRiskSection,
  updateProjectRiskSection,
  createProjectRiskSection,
  deleteProjectRiskSection,
  getDefaultProjectRiskSection,
  getProjectRiskSectionsForProjectRisk,
  getTaskRiskSectionsByProject,
};

export default riskService;
