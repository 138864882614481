import { memo } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import withStyles from '@material-ui/styles/withStyles';

import { TableRow } from 'altus-ui-components';

import { invokeIfFunction } from 'utils/app.util';
import { useHighlightedToolstringItem } from 'features/projects/tasks/task/toolstring/components/ToolstringItemHighlightProvider';

const ToolstringToolsTableRow = ({ item, classes, ...rest }) => {
  const { setHighlightedItemId, isItemHighlighted } =
    useHighlightedToolstringItem();

  return (
    <TableRow
      title={`${item.get('toolstringItemId')} - ${item.get('sequence')}`}
      data-tool-type={item.get('type')}
      item={item}
      onMouseEnter={() =>
        invokeIfFunction(setHighlightedItemId, item.get('toolstringItemId'))
      }
      onMouseLeave={() => invokeIfFunction(setHighlightedItemId)}
      classes={{
        root: classNames({
          [classes.highlighted]: isItemHighlighted(
            item.get('toolstringItemId'),
          ),
        }),
      }}
      {...rest}
    />
  );
};

const styles = (theme) => ({
  highlighted: {
    background: theme.palette.table.row.hover,
  },
});

export default compose(memo, withStyles(styles))(ToolstringToolsTableRow);
