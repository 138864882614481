import { fromJS, List, Map } from 'immutable';
import { arrayMove } from 'react-sortable-hoc';

import {
  TASK_STATE,
  TASK_SURFACE_EQUIPMENT_ACTIONS,
  TASK_ACTIONS as TASKS_ACTIONS,
} from 'features/projects/tasks/tasks.constants';

import { APP_ACTIONS, EMPTY_MAP } from 'app/app.constants';
import tasksMapper from 'features/projects/tasks/tasks.mappers';
import tasksMappers from 'features/projects/tasks/tasks.mappers';

const initialState = fromJS({
  [TASK_STATE.ACTIVE]: {
    [TASK_STATE.ACTIVE_DATA]: tasksMapper.Task.initial(),
    [TASK_STATE.ACTIVE_DIRECTION]: undefined,
  },
  [TASK_STATE.TEMPLATE]: Map({
    [TASK_STATE.TEMPLATE_DATA]: List(),
  }),
  [TASK_STATE.DATA]: List(),
  [TASK_STATE.CABLES]: EMPTY_MAP,
  [TASK_STATE.SURFACE_EQUIPMENT]: EMPTY_MAP,
  [TASK_STATE.SURFACE_EQUIPMENT]: EMPTY_MAP,
  [TASK_STATE.TASK_RISK]: List(),
});

const tasksReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TASKS_ACTIONS.GET_ALL_TASKS: {
      if (action.error) return state;
      return state.set(
        TASK_STATE.DATA,
        List(action.payload).map(tasksMapper.Task.from),
      );
    }

    case TASKS_ACTIONS.CREATE_TASK: {
      if (action.error) return state;
      return state
        .update(TASK_STATE.DATA, (tasks) =>
          tasks.push(tasksMapper.Task.from(action.payload)),
        )
        .set(TASK_STATE.NEW_TASK, tasksMapper.Task.initial())
        .setIn(
          [TASK_STATE.ACTIVE, TASK_STATE.ACTIVE_DATA],
          tasksMapper.Task.initial(),
        );
    }

    case TASKS_ACTIONS.UPDATE_TASK: {
      if (action.error) return state;

      return state
        .update(TASK_STATE.DATA, (tasks) => {
          let index = tasks.findIndex(
            (task) => task.get('id').toString() === action.taskId.toString(),
          );

          return tasks.set(index, tasksMapper.Task.from(action.payload));
        })
        .setIn(
          [TASK_STATE.ACTIVE, TASK_STATE.ACTIVE_DATA],
          tasksMapper.Task.initial(),
        );
    }
    case TASKS_ACTIONS.DELETE_TASK: {
      if (action.error) return state;

      const { id: taskId } = action;

      return state.update(TASK_STATE.DATA, (tasks) =>
        tasks.filterNot((task) => task.get('id') === taskId),
      );
    }
    case TASKS_ACTIONS.CREATE_TASK_MODAL_LOADED: {
      if (action.error) return state;
      return state
        .setIn(
          [TASK_STATE.ACTIVE, TASK_STATE.ACTIVE_DATA],
          tasksMapper.Task.initial(),
        )
        .setIn(
          [TASK_STATE.TEMPLATE, TASK_STATE.TEMPLATE_DATA],
          List(action.payload).map(tasksMapper.Task.Template.from),
        );
    }
    case TASKS_ACTIONS.PROJECT_UPDATE_PROGRESS: {
      if (action.error) return state;
      const [, updatedTask] = action.payload,
        task = tasksMapper.Task.from(updatedTask);
      return state
        .update(TASK_STATE.DATA, (tasks) => {
          let index = tasks.findIndex((r) => r.get('id') === task.get('id'));
          return tasks.set(index, task);
        })
        .setIn([TASK_STATE.ACTIVE, TASK_STATE.ACTIVE_DATA], task)
        .setIn([TASK_STATE.ACTIVE, TASK_STATE.ACTIVE_HAS_PROGRESSED], false);
    }

    case TASKS_ACTIONS.EDIT_TASK_MODAL_UNLOADED: {
      return state.setIn(
        [TASK_STATE.ACTIVE, TASK_STATE.ACTIVE_DATA],
        tasksMapper.Task.initial(),
      );
    }

    case APP_ACTIONS.ASYNC_START: {
      const { subtype } = action;

      switch (subtype) {
        case TASKS_ACTIONS.SORT_TASKS: {
          const currentTasks = state.get(TASK_STATE.DATA);

          const { oldIndex, newIndex } = action;

          return state.set(
            TASK_STATE.DATA,
            fromJS(arrayMove(currentTasks.toJS(), oldIndex, newIndex)),
          );
        }
        default: {
          return state;
        }
      }
    }

    case TASK_SURFACE_EQUIPMENT_ACTIONS.CABLES_LOADED: {
      if (action.error) return state;

      const cables = action.payload;
      return state.setIn([TASK_STATE.CABLES], fromJS(cables));
    }

    case TASK_SURFACE_EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_LOADED: {
      if (action.error) return state;

      const surfaces = action.payload;

      return state.setIn(
        [TASK_STATE.SURFACE_EQUIPMENT],
        List(surfaces).map(tasksMappers.Task.TaskSurfaceEquipment.from),
      );
    }

    default:
      return state;
  }
};

export default tasksReducer;
