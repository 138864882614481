import { compose } from 'redux';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import DownloadIcon from '@material-ui/icons/GetApp';
import UploadIcon from '@material-ui/icons/CloudUpload';
import withStyles from '@material-ui/styles/withStyles';
import { Box, Grid, Typography } from '@material-ui/core';
import { useCallback, useState, useMemo, memo } from 'react';

import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';

import { BasePage } from 'altus-ui-components';

import {
  getTrajectoryPreview,
  uploadWellboreTrajectory,
  getTrajectoryFromWellcomApi,
  downloadTrajectoryTemplateTxt,
  downloadTrajectoryTemplateCsv,
  downloadTrajectorySurveyTxt,
  downloadTrajectorySurveyCsv,
} from 'features/wells/overview/wellOverview.actions';

import { TrajectoryViewType } from 'app/components/WellboreTrajectory/WellboreTrajectoryViewToggle';

import { EMPTY_MAP } from 'app/app.constants';
import { useHeader } from 'app/hooks/useHeader';
import useWellbore from 'features/wells/hooks/useWellbore';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { getWellboreTrajectoryFromState } from 'features/wells/wellbore.selectors';
import { WELLS_ACTIONS as WELLBORE_ACTIONS } from 'features/wells/wells.constants';
import { WELL_OVERVIEW_ACTIONS } from 'features/wells/overview/wellOverview.constants';
import WellDashboardProjectHistory from 'features/wells/projects/WellDashboardProjectHistory';
import WellboreTrajectoryDetails from 'app/components/WellboreTrajectory/WellboreTrajectoryDetails';
import WellboreTrajectoryPreviewModalContainer from 'features/wells/overview/components/WellboreTrajectoryPreviewModalContainer';
import WellboreContextTrajectory3DView from 'app/components/WellboreContextualization/WellboreContextTrajectory3DView';

const WellOverviewContainer = ({
  classes,
  fieldId,
  dataState,
  breadcrumb,
  wellboreId,
  trajectory = EMPTY_MAP,
  dispatchUploadWellboreTrajectory,
  dispatchGetTrajectoryFromWellcomApi,
  dispatchGetWellboreTrajectoryPreview,
  dispatchDownloadTrajectoryTemplateTxt,
  dispatchDownloadTrajectorySurveyCsv,
  dispatchDownloadTrajectorySurveyTxt,
  dispatchDownloadTrajectoryTemplateCsv,
}) => {
  useHeader({
    subTitle: breadcrumb,
  });

  const { wellbore } = useWellbore(wellboreId);

  const [trajectoryFileToUpload, setTrajectoryFileToUpload] = useState();

  const onDrop = useCallback(
    (files) => setTrajectoryFileToUpload(files[0]),
    [],
  );

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    open: openTrajectoryFilePicker,
  } = useDropzone({
    onDrop,
    noClick: true,
    multiple: false,
    noKeyboard: true,
  });

  const [isActionsPopupOpen, setActionsPopupOpen] = useState(false);

  const [viewType] = useState(TrajectoryViewType.THREE_D);

  const actions = useMemo(
    () =>
      [
        {
          icon: <UploadIcon />,
          name: (
            <Typography variant="subtitle2" noWrap>
              Upload trajectory
            </Typography>
          ),
          onClick: openTrajectoryFilePicker,
        },
        {
          icon: <DownloadIcon />,
          name: (
            <Typography variant="subtitle2" noWrap>
              Template (.txt)
            </Typography>
          ),
          onClick: dispatchDownloadTrajectoryTemplateTxt,
        },
        {
          icon: <DownloadIcon />,
          name: (
            <Typography variant="subtitle2" noWrap>
              Template (.csv)
            </Typography>
          ),
          onClick: dispatchDownloadTrajectoryTemplateCsv,
        },

        {
          icon: <DownloadIcon />,
          name: (
            <Typography variant="subtitle2" noWrap>
              Survey (.txt)
            </Typography>
          ),
          hidden: !trajectory.get('trajectoryPoints')?.size,
          onClick: () => dispatchDownloadTrajectorySurveyTxt(wellboreId),
        },
        {
          icon: <DownloadIcon />,
          name: (
            <Typography variant="subtitle2" noWrap>
              Survey (.csv)
            </Typography>
          ),
          hidden: !trajectory.get('trajectoryPoints')?.size,
          onClick: () => dispatchDownloadTrajectorySurveyCsv(wellboreId),
        },
        {
          icon: <UploadIcon />,
          name: (
            <Typography variant="subtitle2" noWrap>
              Wellcom API
            </Typography>
          ),
          hidden: !(wellbore && wellbore.get('wellcomId') !== null),
          onClick: () => dispatchGetTrajectoryFromWellcomApi(wellboreId),
        },
      ].filter(({ hidden }) => !hidden),
    [
      wellbore,
      wellboreId,
      trajectory,
      openTrajectoryFilePicker,
      dispatchDownloadTrajectorySurveyTxt,
      dispatchDownloadTrajectorySurveyCsv,
      dispatchGetTrajectoryFromWellcomApi,
      dispatchDownloadTrajectoryTemplateTxt,
      dispatchDownloadTrajectoryTemplateCsv,
    ],
  );

  return (
    <BasePage
      dataState={dataState}
      LoadingOverlayProps={{
        timeout: 0,
      }}
      classes={{
        children: classes.basePageChildren,
        root: classNames({
          [classes.dragOver]: isDragActive,
        }),
      }}
    >
      <Grid
        xs
        item
        container
        {...getRootProps()}
        className={classes.topContainer}
      >
        <Grid
          item
          xs={3}
          component={Box}
          paddingLeft={2}
          className={classNames({
            [classes.wellboreDetails]: viewType === TrajectoryViewType.THREE_D,
          })}
        >
          <Grid container className={classes.stickySection}>
            <Grid container item component={Box} marginBottom={2}>
              <WellboreTrajectoryDetails
                wellbore={wellbore}
                trajectory={trajectory}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={9} className={classes.test}>
          <WellboreContextTrajectory3DView
            fieldId={fieldId}
            wellboreId={wellboreId}
          />
        </Grid>
      </Grid>
      <Grid xs item container>
        <WellDashboardProjectHistory
          wellboreId={wellboreId}
          fieldId={fieldId}
        />
      </Grid>
      <SpeedDial
        icon={<SpeedDialIcon icon={<MenuIcon />} openIcon={<CloseIcon />} />}
        open={isActionsPopupOpen}
        className={classes.actionsButton}
        ariaLabel="Upload survey and download templates"
        onClose={(_, reason) =>
          reason !== 'mouseLeave' && setActionsPopupOpen(false)
        }
        onOpen={(_, reason) => reason !== 'focus' && setActionsPopupOpen(true)}
      >
        {actions.map(({ icon, name, onClick }, index) => (
          <SpeedDialAction
            key={index}
            icon={icon}
            tooltipOpen
            tooltipTitle={name}
            FabProps={{ size: 'medium' }}
            onClick={() => {
              setActionsPopupOpen(false);
              onClick();
            }}
          />
        ))}
      </SpeedDial>
      <WellboreTrajectoryPreviewModalContainer
        fieldId={fieldId}
        wellbore={wellbore}
        dataState={dataState}
        wellboreId={wellboreId}
        onClose={setTrajectoryFileToUpload}
        trajectoryFile={trajectoryFileToUpload}
        getTrajectoryPreview={dispatchGetWellboreTrajectoryPreview}
        uploadWellboreTrajectory={dispatchUploadWellboreTrajectory}
      />
      <input {...getInputProps()} />
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    flexDirection: 'column',
    paddingTop: theme.spacing(2.25),
    overflow: 'hidden',
  },
  actionsButton: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
  createProjectButton: {
    position: 'absolute',
    left: theme.spacing(3),
  },
  wellboreDetails: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  dragOver: {
    opacity: '0.3',
    border: '5px gray dashed',
  },
  stickyHeader: {
    top: -theme.spacing(2.25),
  },
  stickySection: {
    top: 0,
    position: 'sticky',
  },
  topContainer: {
    padding: '5px',
    maxHeight: '60%',
    position: 'relative',
    flexDirection: 'row',
    width: '100%',
  },
  test: {
    overflow: 'hidden',
    paddingLeft: theme.spacing(2),
  },
});

export default compose(
  connect(
    (state, { wellboreId }) => {
      const trajectorySelector = getWellboreTrajectoryFromState(wellboreId);

      return {
        trajectory: trajectorySelector(state),
        dataState: getSummarizedDataStateFromState(
          state,
          WELLBORE_ACTIONS.REQUEST_WELLBORE_TRAJECTORY,
          WELL_OVERVIEW_ACTIONS.UPLOAD_WELLBORE_TRAJECTORY,
        ),
      };
    },
    {
      dispatchUploadWellboreTrajectory: uploadWellboreTrajectory,
      dispatchGetWellboreTrajectoryPreview: getTrajectoryPreview,
      dispatchDownloadTrajectoryTemplateCsv: downloadTrajectoryTemplateCsv,
      dispatchDownloadTrajectoryTemplateTxt: downloadTrajectoryTemplateTxt,
      dispatchGetTrajectoryFromWellcomApi: getTrajectoryFromWellcomApi,
      dispatchDownloadTrajectorySurveyCsv: downloadTrajectorySurveyCsv,
      dispatchDownloadTrajectorySurveyTxt: downloadTrajectorySurveyTxt,
    },
  ),
  memo,
  withStyles(styles),
)(WellOverviewContainer);
