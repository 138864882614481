import { List } from 'immutable';

import mappers from 'mappers';
import { EMPTY_MAP } from 'app/app.constants';
import { DASHBOARDS_ACTIONS } from 'features/projects/dashboard/dashboard.constants';

const initialState = EMPTY_MAP;

const projectDashboardCurveReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case DASHBOARDS_ACTIONS.RECEIVE_PROJECT_DASHBOARD_CURVES: {
      if (error) return state;

      const { projectDashboardId } = action;

      return state.set(
        projectDashboardId.toString(),
        List(payload).map(mappers.CheetahJobCurve.from),
      );
    }

    default:
      return state;
  }
};

export default projectDashboardCurveReducer;
