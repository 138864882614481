import mappers from 'mappers';
import Agent from 'infrastructure/agent';
import toolstringMappers from 'features/projects/tasks/task/toolstring/toolstring.mappers';
import toolstringMapper from 'features/projects/tasks/task/toolstring/toolstring.mappers';

// M3
const refreshToolsWithM3Items = (projectId, taskId) =>
  Agent.get(`/project/${projectId}/tasks/${taskId}/toolstrings/refreshM3Data`);

const refreshAllToolsWithM3Items = () => Agent.get('/tool/refresh');

// Tool images
const getToolImages = () => Agent.get('/tool/images');

const uploadToolImage = (file, meta = {}) =>
  Agent.file.post('/tool/image/upload', file, meta);

const associateImageToTool = (toolId, imageId) =>
  Agent.post(`/tool/${toolId}/image/${imageId}`);

const disassociateImageFromTool = (toolId, imageId) =>
  Agent.delete(`/tool/${toolId}/image/${imageId}`);

const deleteToolImage = (imageId) => Agent.delete(`/tool/image/${imageId}`);

// Tool import
const importTools = (file, meta = {}) =>
  Agent.file.post('/tool/import', file, meta);

// Search
const searchTools = (filters) => {
  if (filters?.projectId) {
    return Agent.post(`/tool/search/${filters.projectId}`, filters);
  }
  return Agent.post('/tool/search', filters);
};

const searchBhas = (filters) => Agent.post('/template/search', filters);

const saveBhaFromClient = (bhaTemplateFromClient) =>
  Agent.post('/template/savebhatemplate', bhaTemplateFromClient);

const createTool = (tool) => Agent.post('/tool/create', tool);

const getAllToolGroups = () =>
  Agent.get('/search/filter/valueset/toolgroup/all');

const getToolSuppliers = () =>
  Agent.get('/search/filter/valueset/toolsuppliers');

const getToolGroupsByCategory = (categoryId) =>
  Agent.get(`/search/filter/valueset/toolgroup/category/${categoryId}`);

const getToolConnectors = () =>
  Agent.get('/search/filter/valueset/toolconnectors');

const getToolTopConnectors = () =>
  Agent.get('/search/filter/valueset/tooltopconnector');

const getToolBottomConnectors = () =>
  Agent.get('/search/filter/valueset/toolbottomconnector');

const getCables = (filters) =>
  Agent.get('/search/filter/valueset/cable', filters);

const getCable = (cableId) => Agent.get(`/cable/${cableId}`);

const getCablesByProject = (projectId) =>
  Agent.get(`/search/filter/valueset/cable/${projectId}`);

// Search
const searchCables = (filters) => Agent.post('/cable/search', filters);

const getTool = (toolId) => Agent.get(`/tool/gettool/${toolId}`);

const getAllToolStrings = () => Agent.get('/template/toolstrings');

const updateTool = (tool) => Agent.put('/tool/update', tool);

const getToolProjectHistoryByToolId = (toolId) =>
  Agent.get(`/tool/projecthistory/${toolId}`);

const getAllSurfaceEquipment = () => Agent.get('/tool/surfaceequipment');

const saveToolAssembly = (toolAssembly) =>
  Agent.post('/tool/savetoolassembly', toolAssembly);

const getToolAssembly = (toolId) =>
  Agent.get(`/tool/gettoolassembly/${toolId}`);

const updateToolAssembly = (toolAssembly) =>
  Agent.post('/tool/updatetoolassembly', toolAssembly);

const getTool3DObjects = () => Agent.get('/tool/tool3dobjects');

const uploadTool3DObject = (file, meta = {}) =>
  Agent.file.post('/tool/tool3dobject/upload', file, meta);

const associate3DObjectToTool = (toolId, tool3DObjectId) =>
  Agent.put(`/tool/${toolId}/tool3dobject/${tool3DObjectId}`);

const disassociate3DObjectFromTool = (toolId, tool3DObjectId) =>
  Agent.delete(`/tool/${toolId}/tool3dobject/${tool3DObjectId}`);

const deleteTool3DObject = (tool3DObjectId) =>
  Agent.delete(`/tool/tool3dobject/${tool3DObjectId}`);

const getTemplate = (templateId) =>
  Agent.get(`/template/toolstring/${templateId}`);

const getTemplateSections = (templateId) =>
  Agent.get(`/template/${templateId}/sections`);

const createTemplateItem = (templateId, tool) => {
  Agent.post(
    `/template/${templateId}/items`,
    toolstringMappers.ToolstringItem.toCreate(tool),
  );
};

const deleteTemplateItem = (toolStringId, toolId) =>
  Agent.delete(`/template/toolstring/${toolStringId}/items/${toolId}`);

const deleteTemplateToolAssemblyTool = (toolStringId, toolAssemblyToolId) =>
  Agent.delete(
    `/template/toolstring/${toolStringId}/toolstringassembly/${toolAssemblyToolId}`,
  );

const updateTemplate = (toolStringId, toolstring) =>
  Agent.put(
    `/template/toolstring/${toolStringId}/update`,
    toolstringMapper.Toolstring.to(toolstring),
  );

const duplicateTemplateItem = (toolStringId, tool) =>
  Agent.post(
    `/template/toolstring/${toolStringId}/items/copy`,
    toolstringMapper.ToolstringItem.toDuplicate(tool),
  );

const updateTemplateItemSequence = (toolStringId, toolstringItemId, indices) =>
  Agent.post(
    `/template/toolstring/${toolStringId}/items/${toolstringItemId}/sequence`,
    mappers.Sequence.to(toolstringItemId, indices),
  );

const updateTemplateAssemblyToolSequence = (
  toolstringId,
  toolAssemblyToolId,
  sequences,
) =>
  Agent.post(
    `/template/toolstring/${toolstringId}/assembly/tool/${toolAssemblyToolId}/sequence`,
    mappers.Sequence.to(toolAssemblyToolId, sequences),
  );

const editToolImage = (values, imageId) =>
  Agent.post(`/tool/image/${imageId}/scale/`, values);

const toolService = {
  getTool,
  getCables,
  createTool,
  updateTool,
  searchTools,
  importTools,
  getToolImages,
  deleteToolImage,
  uploadToolImage,
  getAllToolGroups,
  getToolSuppliers,
  getToolConnectors,
  getToolTopConnectors,
  associateImageToTool,
  getToolGroupsByCategory,
  getToolBottomConnectors,
  refreshToolsWithM3Items,
  disassociateImageFromTool,
  refreshAllToolsWithM3Items,
  getAllToolStrings,
  searchBhas,
  getToolProjectHistoryByToolId,
  getAllSurfaceEquipment,
  saveToolAssembly,
  saveBhaFromClient,
  getTool3DObjects,
  uploadTool3DObject,
  associate3DObjectToTool,
  disassociate3DObjectFromTool,
  deleteTool3DObject,
  getToolAssembly,
  updateToolAssembly,
  getCable,
  getCablesByProject,
  getTemplate,
  getTemplateSections,
  createTemplateItem,
  deleteTemplateItem,
  updateTemplate,
  duplicateTemplateItem,
  updateTemplateItemSequence,
  updateTemplateAssemblyToolSequence,
  editToolImage,
  deleteTemplateToolAssemblyTool,
  searchCables,
};

export default toolService;
