export const FormFields = {
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  DESCRIPTION: 'description',
  DEPTH: 'depth',
  PARALLEL_ACTIVITY: 'parallelActivity',
  COMMENTS: 'comments',
  PREVIOUS_ACTIVITY_START_TIME: 'previousActivityStartTime',
  NEXT_ACTIVITY_START_TIME: 'nextActivityStartTime',
  HAS_WAIT_CODES: 'hasWaitCodes',
};
