import { compose } from 'redux';
import { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import {
  Grid,
  List,
  Switch,
  Divider,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

import { EMPTY_SET } from 'app/app.constants';
import { hasAllPermissions } from 'utils/authorization.util';

class PermissionGroup extends PureComponent {
  hasAllPermissions = () => {
    const { rolePermissions, permissions } = this.props;

    return hasAllPermissions(rolePermissions, permissions);
  };

  handleToggle = (permissionId) => {
    const { rolePermissions, onAddPermissions, onRemovePermissions } =
      this.props;

    if (rolePermissions.has(permissionId)) {
      onRemovePermissions([permissionId]);
    } else {
      onAddPermissions([permissionId]);
    }
  };

  handleToggleAll = () => {
    const { permissions, onAddPermissions, onRemovePermissions } = this.props;

    const hasAllPermissions = this.hasAllPermissions();

    if (hasAllPermissions) {
      onRemovePermissions(permissions);
    } else {
      onAddPermissions(permissions);
    }
  };

  render() {
    const {
      classes,
      children,
      groupName,
      permissions,
      rolePermissions,
      permissionsById,
      groupDescription,
      showSubpermissions,
    } = this.props;

    const hasAllPermissions = this.hasAllPermissions();

    return (
      <>
        <List>
          <ListItem classes={{ root: classes.listItemRoot }}>
            <ListItemText
              classes={{ primary: classes.listItemBold }}
              primary={groupName}
              secondary={groupDescription}
            />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                color="primary"
                checked={hasAllPermissions}
                onChange={this.handleToggleAll}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <Grid container direction="column" className={classes.subgroups}>
            {showSubpermissions &&
              permissions.map((permissionId) => {
                const permission = permissionsById.get(permissionId.toString());

                if (!permission) return null;

                return (
                  <ListItem
                    key={permissionId}
                    classes={{ root: classes.listItemRoot }}
                  >
                    <ListItemText
                      classes={{ primary: classes.listItemBold }}
                      primary={permission.get('name')}
                      secondary={permission.get('description')}
                      primaryTypographyProps={{
                        variant: 'caption',
                      }}
                      secondaryTypographyProps={{
                        variant: 'caption',
                      }}
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        edge="end"
                        color="primary"
                        onChange={() => this.handleToggle(permissionId)}
                        checked={rolePermissions.has(permissionId)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </Grid>
        </List>
        <Divider />
        <Grid container direction="column" className={classes.children}>
          {children}
        </Grid>
      </>
    );
  }
}

const styles = (theme) => ({
  listItemBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  listItemRoot: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  children: {
    paddingLeft: theme.spacing(3),
  },
  subgroups: {
    paddingLeft: theme.spacing(1.5),
  },
});

PermissionGroup.defaultProps = {
  rolePermissions: EMPTY_SET,
  showSubpermissions: true,
};

export default compose(withStyles(styles))(PermissionGroup);
