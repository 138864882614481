import maxBy from 'lodash/maxBy';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, LinearProgress as MuiLinearProgress } from '@material-ui/core';

import { formatDuration } from 'utils/format.util';
import withTooltip from 'app/components/withTooltip';

const LinearProgress = withTooltip(MuiLinearProgress);

const calculateProgressBarWidth = (duration, maxDuration) => {
  const maxDurationSec = maxDuration.asSeconds();
  const durationSec = duration.asSeconds();
  return maxDurationSec === durationSec
    ? 100
    : (durationSec / maxDurationSec) * 100;
};

const styles = () => ({
  progressBar: {
    'cursor': 'pointer',
    '&:not(:last-of-type)': {
      marginBottom: 4,
    },
  },
});

const DurationComparison = ({ durations, diffDuration, classes }) => {
  if (!diffDuration) return null;

  const [maxDuration] = maxBy(durations, ([duration]) => duration.asSeconds());

  return (
    <>
      {durations.map(([duration, tooltip, barColorPrimary, justify], index) => {
        const width = calculateProgressBarWidth(duration, maxDuration);

        return (
          <Grid
            container
            key={index}
            justifyContent={justify}
            className={classes.progressBar}
          >
            <LinearProgress
              value={100}
              variant="determinate"
              title={`${tooltip}: ${formatDuration(duration)}`}
              classes={{
                barColorPrimary: barColorPrimary,
              }}
              style={{
                width: `${width}%`,
              }}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default withStyles(styles)(DurationComparison);
