import { useMemo, useEffect } from 'react';

import { BasePage } from 'altus-ui-components';

import useCheetahJobs from 'features/data-exchange/hooks/useCheetahJobs';
import LiveProjectCheetahJobsTable from 'features/data-exchange/live-projects/components/LiveProjectCheetahJobsTable';

const LiveProjectOverviewContainer = ({ projectId, breadcrumb }) => {
  const filter = useMemo(
    () => ({
      projectId,
    }),
    [projectId],
  );

  const { dataState, cheetahJobs, searchCheetahJobs } = useCheetahJobs(filter);

  useEffect(() => searchCheetahJobs(filter), [filter, searchCheetahJobs]);

  return (
    <BasePage dataState={dataState} title={breadcrumb}>
      <LiveProjectCheetahJobsTable
        projectId={projectId}
        cheetahJobs={cheetahJobs}
      />
    </BasePage>
  );
};

export default LiveProjectOverviewContainer;
