import { useMemo } from 'react';
import { Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import TableCellDense from 'app/components/Table/TableCellDense';
import TableHeaderTitleCell from 'app/components/TableHeaderTitleCell';
import TableHeaderCellDense from 'app/components/Table/TableHeaderCellDense';
import ToolstringTemplateTableRow from 'features/projects/tasks/task/toolstring/components/ToolstringTemplateTableRow';

const ToolstringTemplatesTable = ({ onCreate, templates }) => {
  const columns = useMemo(
    () => [
      {
        id: 'expansionIcon',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: () => (
          <TableRowActionsCell>
            <ExpandMoreIcon />
          </TableRowActionsCell>
        ),
      },
      {
        xs: 10,
        id: 'name',
        Header: <TableHeaderTitleCell title="BHA Template Name" />,
        accessor: (template) => template.get('name'),
      },
      {
        id: 'button',
        Cell: ({ row }) => (
          <Button
            onClick={() => onCreate(row.original.get('id'))}
            variant="contained"
            color="primary"
            size="small"
          >
            Use
          </Button>
        ),
      },
    ],
    [onCreate],
  );
  return (
    <Table
      stickyHeader
      columns={columns}
      items={templates}
      TableRowComponent={ToolstringTemplateTableRow}
      TableCellComponent={TableCellDense}
      TableHeaderCellComponent={TableHeaderCellDense}
    />
  );
};

export default ToolstringTemplatesTable;
