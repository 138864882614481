import * as THREE from 'three';
import { useEffect, useState } from 'react';
import { useThree } from '@react-three/fiber';
import { PUBLIC_ASSETS_URL } from 'app/app.constants';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';

const Environment = () => {
  const [envMap, setEnvMap] = useState(null);
  const { scene, gl } = useThree();
  const src = `${PUBLIC_ASSETS_URL}/textures/envMap.hdr`;

  useEffect(() => {
    const loader = new RGBELoader();
    loader.load(src, (texture) => {
      setEnvMap(texture);
    });

    return () => setEnvMap(null);
  }, [src]);

  useEffect(() => {
    if (!envMap || !scene || !gl) return;

    const pmremGenerator = new THREE.PMREMGenerator(gl);
    pmremGenerator.compileEquirectangularShader();
    const envMapPMREM = createPMREM(pmremGenerator, envMap);
    scene.environment = envMapPMREM;

    return () => (scene.environment = null);
  }, [scene, envMap, gl]);

  return null;
};

const createPMREM = (pmremGenerator, texture) => {
  const envMap = pmremGenerator.fromEquirectangular(texture).texture;
  texture.dispose();
  pmremGenerator.dispose();

  return envMap;
};

export default Environment;
