import { React, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Grid, Tabs, Tab, withStyles, Divider, Box } from '@material-ui/core';

import { useCurrentUser } from 'app/hooks/useCurrentUser';
import TeamContainer from 'features/projects/team/TeamContainer';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { PROJECT_DETAILS_ACTIVE_TAB } from 'features/equipment/equipment.constants';
import ProjectDetailsContainer from 'features/projects/details/ProjectDetailsContainer';
import DocumentsContainerNew from 'features/projects/documents/newDocumentsContainer/DocumentsContainerNew';
import ProceduresAndChecklistsContainer from 'features/projects/documents/newDocumentsContainer/ProceduresAndChecklistsContainer';
import ProjectRiskContainer from 'features/projects/details/projectRisk/ProjectRiskContainer';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import ArchiveAlert from 'features/projects/details/components/ArchiveAlert';
import ProjectWellboreNewContainer from 'features/projects/wellbore/ProjectWellboreNewContainer';

const ProjectDetailsDescriptionContainer = ({
  classes,
  projectId,
  dataState,
  breadcrumb,
  departments,
  templateTasks,
  dispatchOnLoad,
  dispatchOnUnLoad,
  dispatchToggleModal,
  dispatchDeleteProject,
  dispatchUpdateProject,
  updateExternalIdDataState,
  dispatchUpdateProjectExternalId,
  dispatchUpdateMeasurementPreference,
  dispatchInitializeMeasurementPreferences,
  projectRoles,
  dispatchOnUnload,
  projectMembersByUserId,
  dispatchDeleteProjectMember,
  dispatchAssignProjectMemberToRole,
}) => {
  const [activeTab, setActiveTab] = useState(
    PROJECT_DETAILS_ACTIVE_TAB.PROJECT_DETAILS,
  );

  const project = useCurrentProject();
  const isArchive = project.get('isArchive');
  const currentUser = useCurrentUser();

  const activeTabHandler = (activeTab) => {
    switch (activeTab) {
      default:
        return null;
      case PROJECT_DETAILS_ACTIVE_TAB.WELL:
        return <ProjectWellboreNewContainer projectId={projectId} />;
      case PROJECT_DETAILS_ACTIVE_TAB.PROJECT_DETAILS:
        return (
          <ProjectDetailsContainer
            className={classes.projecDetails}
            classes={classes}
            projectId={projectId}
            dataState={dataState}
            breadcrumb={breadcrumb}
            departments={departments}
            templateTasks={templateTasks}
            dispatchOnLoad={dispatchOnLoad}
            dispatchOnUnLoad={dispatchOnUnLoad}
            dispatchToggleModal={dispatchToggleModal}
            dispatchDeleteProject={dispatchDeleteProject}
            dispatchUpdateProject={dispatchUpdateProject}
            updateExternalIdDataState={updateExternalIdDataState}
            dispatchUpdateProjectExternalId={dispatchUpdateProjectExternalId}
            dispatchUpdateMeasurementPreference={
              dispatchUpdateMeasurementPreference
            }
            dispatchInitializeMeasurementPreferences={
              dispatchInitializeMeasurementPreferences
            }
            isArchive={isArchive}
          />
        );
      case PROJECT_DETAILS_ACTIVE_TAB.TEAM:
        return (
          <TeamContainer
            classes={classes}
            dataState={dataState}
            projectId={projectId}
            breadcrumb={breadcrumb}
            projectRoles={projectRoles}
            dispatchOnLoad={dispatchOnLoad}
            dispatchOnUnload={dispatchOnUnload}
            projectMembersByUserId={projectMembersByUserId}
            dispatchDeleteProjectMember={dispatchDeleteProjectMember}
            dispatchAssignProjectMemberToRole={
              dispatchAssignProjectMemberToRole
            }
            isArchive={isArchive}
          />
        );
      case PROJECT_DETAILS_ACTIVE_TAB.DOCUMENTS:
        return (
          <DocumentsContainerNew projectId={projectId} isArchive={isArchive} />
        );
      case PROJECT_DETAILS_ACTIVE_TAB.RISKS:
        return (
          <ProjectRiskContainer projectId={projectId} isArchive={isArchive} />
        );
      case PROJECT_DETAILS_ACTIVE_TAB.PROCEDURES_CHECKLISTS:
        return (
          currentUser.get('isMainVendor') && (
            <ProceduresAndChecklistsContainer
              projectId={projectId}
              isArchive={isArchive}
            />
          )
        );
    }
  };

  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleTabs}
        className={classes.tabsContainer}
      >
        <Tab
          label="Overview"
          value={PROJECT_DETAILS_ACTIVE_TAB.PROJECT_DETAILS}
          className={classes.tab}
        />
        <Tab label="Well" value={PROJECT_DETAILS_ACTIVE_TAB.WELL} />
        <Tab label="Team" value={PROJECT_DETAILS_ACTIVE_TAB.TEAM} />
        <Tab label="Documents" value={PROJECT_DETAILS_ACTIVE_TAB.DOCUMENTS} />
        <Tab label="Risks" value={PROJECT_DETAILS_ACTIVE_TAB.RISKS} />
        {currentUser.get('isMainVendor') && (
          <Tab
            label="Procedures and checklists"
            value={PROJECT_DETAILS_ACTIVE_TAB.PROCEDURES_CHECKLISTS}
          />
        )}
      </Tabs>
      <Divider className={classes.divider} />
      <Box className={classes.box}>
        <ArchiveAlert isArchive={isArchive} />
        <Grid
          container
          className={classes.contentContainer}
          style={{ height: isArchive ? '96%' : '100%' }}
        >
          <Grid item container className={classes.tabPanel} spacing={1}>
            {activeTabHandler(activeTab)}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const styles = (theme) => ({
  contentContainer: {
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  box: {
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  tabsContainer: {
    paddingLeft: '12%',
    borderRadius: 3,
  },
  tab: {
    backgroundColor: 'transparent !important',
  },
  tabPanel: {
    flex: 1,
    padding: theme.spacing(1),
    backgroundColor: 'transparent',
  },
});

export default compose(
  connect((state) => ({
    dataState: getSummarizedDataStateFromState(state),
  })),
  withStyles(styles),
)(ProjectDetailsDescriptionContainer);
