import { useEffect, useState } from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';

import {
  ARM,
  WOP,
  WHEEL,
  DDRRPM,
  TORQUE,
  WOPRPM,
  WOB_GRADIENT,
  BITRPM_GRADIENT,
  DDRRPM_GRADIENT,
  TORQUE_GRADIENT,
  TRACARM_GRADIENT,
  TRACWHEEL_GRADIENT,
} from 'features/projects/dashboard/components/dashboards/metalMilling/constants';

import MetalMillingChart from 'features/projects/dashboard/components/dashboards/metalMilling/MetalMillingChart';

const initialData = {
  arm: 0,
  wob: 0,
  wheel: 0,
  torque: 0,
  ddrRpm: 0,
  bitRpm: 0,
};

const MetalMillingChartsContainer = ({
  classes,
  registerDataPointsHandler,
}) => {
  const [data, setData] = useState(initialData);

  useEffect(() => {
    registerDataPointsHandler(setData);
  }, [setData, registerDataPointsHandler]);

  return (
    <Grid container item className={classes.root} justifyContent="space-around">
      <Grid
        container
        item
        xs={3}
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        className={classes.graphsContainer}
      >
        <Typography variant="h5">TRAC</Typography>
        <MetalMillingChart
          type={'Wheel'}
          value={`${data.wheel} Bar`}
          target={data.wheel}
          min={WHEEL.MIN_VALUE}
          max={WHEEL.MAX_VALUE}
          chartGradient={TRACWHEEL_GRADIENT}
        />
        <MetalMillingChart
          type={'Arm'}
          value={`${data.arm} Bar`}
          target={data.arm}
          min={ARM.MIN_VALUE}
          max={ARM.MAX_VALUE}
          chartGradient={TRACARM_GRADIENT}
        />
      </Grid>
      <Grid
        container
        item
        xs={3}
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        className={classes.graphsContainer}
      >
        <Typography variant="h5">DDR</Typography>
        <MetalMillingChart
          type={'Torque'}
          value={`${data.torque} Nm`}
          target={data.torque}
          min={TORQUE.MIN_VALUE}
          max={TORQUE.MAX_VALUE}
          chartGradient={TORQUE_GRADIENT}
        />
        <MetalMillingChart
          type={'Rpm'}
          value={`${data.ddrRpm} Rpm`}
          target={data.ddrRpm}
          min={DDRRPM.MIN_VALUE}
          max={DDRRPM.MAX_VALUE}
          chartGradient={DDRRPM_GRADIENT}
        />
      </Grid>
      <Grid
        container
        item
        xs={3}
        direction="column"
        justifyContent="space-around"
        alignItems="center"
        className={classes.graphsContainer}
      >
        <Typography variant="h5">BIT</Typography>
        <MetalMillingChart
          type={'WOB'}
          value={`${data.wob} Kg`}
          target={data.wob}
          min={WOP.MIN_VALUE}
          max={WOP.MAX_VALUE}
          chartGradient={WOB_GRADIENT}
        />
        <MetalMillingChart
          type={'Rpm'}
          value={`${data.bitRpm} Rpm`}
          target={data.bitRpm}
          min={WOPRPM.MIN_VALUE}
          max={WOPRPM.MAX_VALUE}
          chartGradient={BITRPM_GRADIENT}
        />
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  root: {
    height: '100%',
  },
  graphsContainer: {
    overflow: 'auto',
    border: '2px solid gray',
    borderRadius: 10,
    paddingTop: 10,
    paddingBottom: 10,
    height: '100%',
  },
});

export default withStyles(styles)(MetalMillingChartsContainer);
