import { useCallback } from 'react';

import { useRequest } from 'altus-hooks';

import mappers from 'mappers';
import projectService from 'services/project.service';

const useProjectWellboreTrajectory = (projectId) => {
  const getProjectWellboreTrajectory = useCallback(
    () =>
      projectService
        .getWellboreTrajectoryByProject(projectId)
        .then((projectWellboreTrajectory) =>
          mappers.Trajectory.from(projectWellboreTrajectory),
        ),
    [projectId],
  );

  return useRequest(getProjectWellboreTrajectory);
};

export default useProjectWellboreTrajectory;
