import { compose } from 'redux';
import Add from '@material-ui/icons/Add';
import { Fab, Grid } from '@material-ui/core';
import { useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';

import {
  onLoad,
  onUnload,
  deleteProjectMember,
  assignProjectMemberToRole,
  assignProjectMemberTeamRole,
} from 'features/projects/team/team.actions';

import { useModal } from 'altus-modal';
import { BasePage } from 'altus-ui-components';

import {
  TEAM_ACTIONS,
  ADD_TEAM_MEMBERS_MODAL_ID,
} from 'features/projects/team/team.constants';

import {
  getTeamProjectRolesFromState,
  getAllAvailableUsersFromState,
  getProjectMembersKeyedByUserIdFromState,
} from 'features/projects/team/team.selectors';

import { useHeader } from 'app/hooks/useHeader';
import { ProjectPermission } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { getProjectRolesFromState } from 'authorization/authorization.selectors';
import ProjectMembersTable from 'features/projects/team/components/ProjectMembersTable';
import CreateProjectMembersModalContainer from 'features/projects/team/components/CreateProjectMembersModalContainer';

const OnshoreTeam = ({
  users,
  classes,
  dataState,
  projectId,
  breadcrumb,
  projectRoles,
  dispatchOnLoad,
  dispatchOnUnload,
  teamProjectRoles,
  projectMembersByUserId,
  dispatchDeleteProjectMember,
  dispatchAssignProjectMemberToRole,
  dispatchAssignTeamProjectRoleChange,
}) => {
  const [isOpen, toggleModal] = useModal(ADD_TEAM_MEMBERS_MODAL_ID);

  useEffect(() => {
    dispatchOnLoad(projectId);

    return () => dispatchOnUnload();
  }, [projectId, dispatchOnLoad, dispatchOnUnload]);

  useHeader({
    subTitle: breadcrumb,
  });

  const deleteProjectMember = useCallback(
    (projectMemberId, memberName) =>
      dispatchDeleteProjectMember(projectId, projectMemberId, memberName),
    [projectId, dispatchDeleteProjectMember],
  );

  const project = useSelector(getCurrentProject);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid item container justifyContent="center">
        <Grid item xs={12} md={12}>
          <ProjectMembersTable
            project={project}
            projectRoles={projectRoles}
            projectMembers={projectMembersByUserId}
            deleteProjectMember={deleteProjectMember}
            assignProjectMemberToRole={dispatchAssignProjectMemberToRole}
            assignTeamProjectRoleChange={dispatchAssignTeamProjectRoleChange}
            teamProjectRoles={teamProjectRoles}
          />
          <HasProjectPermission
            permissions={ProjectPermission.ADD_TEAM_MEMBERS}
          >
            <CreateProjectMembersModalContainer
              users={users}
              isOpen={isOpen}
              projectId={projectId}
              title="Add Onshore Team Members"
              toggleModal={toggleModal}
              projectMembers={projectMembersByUserId}
              teamProjectRoles={teamProjectRoles}
            />
            <Fab
              color="primary"
              onClick={toggleModal}
              className={classes.create}
              title="Add Onshore Team Members"
            >
              <Add />
            </Fab>
          </HasProjectPermission>
        </Grid>
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
  create: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(8),
  },
});

export default compose(
  connect(
    (state) => ({
      users: getAllAvailableUsersFromState(state),
      projectRoles: getProjectRolesFromState(state),
      projectMembersByUserId: getProjectMembersKeyedByUserIdFromState(state),
      teamProjectRoles: getTeamProjectRolesFromState(state),
      dataState: getSummarizedDataStateFromState(
        state,
        TEAM_ACTIONS.DELETE_PROJECT_MEMBER,
        TEAM_ACTIONS.PROJECT_MEMBER_PAGE_LOADED,
      ),
    }),
    {
      dispatchOnLoad: onLoad,
      dispatchOnUnload: onUnload,
      dispatchDeleteProjectMember: deleteProjectMember,
      dispatchAssignProjectMemberToRole: assignProjectMemberToRole,
      dispatchAssignTeamProjectRoleChange: assignProjectMemberTeamRole,
    },
  ),
  withStyles(styles),
)(OnshoreTeam);
