import { put, all, call, takeLatest } from 'redux-saga/effects';

import {
  receiveToolGroups,
  receiveToolImages,
  receiveToolsSearch,
  receiveCables,
  receiveToolSuppliers,
  receiveToolConnectors,
  receiveToolTopConnectors,
  receiveToolBottomConnectors,
} from 'features/projects/tool/tool.actions';

import toolService from 'services/tool.service';
import { callAsync } from 'app/sagas/helperSagas';
import { TOOL_ACTIONS } from 'features/projects/tool/tool.constants';

export function* requestToolImagesAsync() {
  const toolImages = yield call(toolService.getToolImages);

  yield put(receiveToolImages(toolImages));

  return toolImages;
}

export function* requestToolsSearchAsync(action = {}) {
  const { filters } = action;

  const toolsSearchResult = yield call(toolService.searchTools, filters);

  yield put(receiveToolsSearch(toolsSearchResult));

  return toolsSearchResult;
}

export function* requestCablesAsync() {
  const cables = yield call(toolService.getCables);

  yield put(receiveCables(cables));

  return cables;
}

export function* requestCablesByProjectAsync({ payload: projectId }) {
  let cables;

  if (!projectId) {
    cables = yield call(toolService.getCables);
  } else {
    cables = yield call(toolService.getCablesByProject, projectId);
  }

  yield put(receiveCables(cables));
  return cables;
}

export function* requestToolGroupsAsync() {
  const toolGroups = yield call(toolService.getAllToolGroups);

  yield put(receiveToolGroups(toolGroups));

  return toolGroups;
}

export function* requestToolGroupsByCategoryAsync(action = { categoryId: 1 }) {
  const categoryId = action.categoryId;

  const toolGroups = yield call(
    toolService.getToolGroupsByCategory,
    categoryId,
  );

  yield put(receiveToolGroups(toolGroups));

  return toolGroups;
}

export function* requestToolTopConnectorsAsync() {
  const toolTopConnectors = yield call(toolService.getToolTopConnectors);

  yield put(receiveToolTopConnectors(toolTopConnectors));

  return toolTopConnectors;
}

export function* requestToolBottomConnectorsAsync() {
  const toolBottomConnectors = yield call(toolService.getToolBottomConnectors);

  yield put(receiveToolBottomConnectors(toolBottomConnectors));

  return toolBottomConnectors;
}

export function* requestToolSuppliersAsync() {
  const toolSuppliers = yield call(toolService.getToolSuppliers);

  yield put(receiveToolSuppliers(toolSuppliers));

  return toolSuppliers;
}

export function* requestToolConnectorsAsync() {
  const toolConnectors = yield call(toolService.getToolConnectors);

  yield put(receiveToolConnectors(toolConnectors));

  return toolConnectors;
}

export function* toolPickerModalOnLoadAsync() {
  const result = yield all([
    call(requestToolGroupsByCategoryAsync),
    call(requestToolTopConnectorsAsync),
    call(requestToolBottomConnectorsAsync),
    call(requestToolSuppliersAsync),
  ]);

  return result;
}

export function* requestRefreshAllToolsWithM3Async() {
  const refreshResult = yield call(toolService.refreshAllToolsWithM3Items);
  const refreshedTools = yield call(requestToolsSearchAsync);

  return [refreshResult, refreshedTools];
}

export default function* root() {
  yield takeLatest(
    TOOL_ACTIONS.REQUEST_TOOL_IMAGES,
    callAsync,
    requestToolImagesAsync,
  );

  yield takeLatest(
    TOOL_ACTIONS.REQUEST_TOOLS_SEARCH,
    callAsync,
    requestToolsSearchAsync,
  );

  yield takeLatest(TOOL_ACTIONS.REQUEST_CABLES, callAsync, requestCablesAsync);

  yield takeLatest(
    TOOL_ACTIONS.REQUEST_CABLES_BY_PROJECT,
    callAsync,
    requestCablesByProjectAsync,
  );

  yield takeLatest(
    TOOL_ACTIONS.REQUEST_TOOL_GROUPS,
    callAsync,
    requestToolGroupsAsync,
  );

  yield takeLatest(
    TOOL_ACTIONS.REQUEST_TOOL_GROUPS_BY_CATEGORY,
    callAsync,
    requestToolGroupsByCategoryAsync,
  );

  yield takeLatest(
    TOOL_ACTIONS.REQUEST_TOOL_CONNECTORS,
    callAsync,
    requestToolConnectorsAsync,
  );

  yield takeLatest(
    TOOL_ACTIONS.REQUEST_TOOL_TOP_CONNECTORS,
    callAsync,
    requestToolTopConnectorsAsync,
  );

  yield takeLatest(
    TOOL_ACTIONS.REQUEST_TOOL_BOTTOM_CONNECTORS,
    callAsync,
    requestToolBottomConnectorsAsync,
  );

  yield takeLatest(
    TOOL_ACTIONS.TOOL_PICKER_MODAL_ON_LOAD,
    callAsync,
    toolPickerModalOnLoadAsync,
  );

  yield takeLatest(
    TOOL_ACTIONS.REQUEST_REFRESH_ALL_TOOLS_WITH_M3,
    callAsync,
    requestRefreshAllToolsWithM3Async,
  );

  yield takeLatest(
    TOOL_ACTIONS.REQUEST_TOOL_SUPPLIERS,
    callAsync,
    requestToolSuppliersAsync,
  );
}
