import React from 'react';
import { Grid } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';

import {
  INTEGRITY_MANAGEMENT_RULE_TYPES,
  IntegrityForm as FormFields,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import DefaultRuleValueWrapper from 'features/settings/integrityManagement/rules/DefaultRuleValueWrapper';

const IntegrityRuleInformationContainer = ({
  integrityManagementRuleTypes = INTEGRITY_MANAGEMENT_RULE_TYPES,
  onTitleChange,
}) => {
  const getId = (item) => item.id;
  const getName = (item) => item.name;
  const { setFieldValue } = useFormikContext();

  const handleTitleChange = (event) => {
    const newTitle = event.target.value;

    setFieldValue(FormFields.TITLE, newTitle);
    onTitleChange(newTitle);
  };
  return (
    <Grid container justifyContent="flex-start" pb={2}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={6} container>
            <Field
              type="string"
              label="Title"
              component={TextFieldFormik}
              name={FormFields.TITLE}
              placeholder=""
              onChange={handleTitleChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <SelectTextFieldFormik
              getItemName={getName}
              placeholder="Select"
              label="Rule Type"
              getItemValue={getId}
              items={integrityManagementRuleTypes}
              name={FormFields.RULE_TYPE}
              displayEmpty={false}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DefaultRuleValueWrapper />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntegrityRuleInformationContainer;
