import { compose } from 'redux';
import CheckBox from '@material-ui/icons/CheckBox';
import withStyles from '@material-ui/styles/withStyles';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';

import {
  Box,
  Grid,
  Button,
  Dialog,
  Typography,
  IconButton,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import {
  Table,
  UserAvatar,
  LoadingButton,
  TableRowActionsCell,
} from 'altus-ui-components';

import { useMultiselect } from 'altus-hooks';

import BasePageDense from 'app/components/BasePageDense';
import { PARTICIPANTSMODAL } from 'features/wellControlDrillActivity/constants';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const initialState = {
  pageSize: 25,
  sortBy: [
    {
      id: 'name',
      desc: false,
    },
  ],
};

const AddParticipantsModal = ({
  users,
  isOpen,
  classes,
  dataState,
  toggleModal,
  form,
  field,
}) => {
  const { clearSelection } = useMultiselect();

  const { value, name } = field;
  const { setFieldValue } = form;

  const [selectedValues, setSelectedValues] = useState([]);

  const checkIfSelected = useCallback(
    (user) => {
      const found = selectedValues.find((el) => el.id === user.get('id'));
      return found !== undefined;
    },
    [selectedValues],
  );

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  const toggleSelection = useCallback(
    (user) => {
      const valueLocal = [...selectedValues];
      const found = valueLocal.find((el) => el.id === user.get('id'));
      if (found) {
        const index = valueLocal.indexOf(found);
        if (index > -1) {
          valueLocal.splice(index, 1);
          setSelectedValues(valueLocal);
          setFieldValue(name, valueLocal);
        }
      } else {
        valueLocal.push(user.toJS());
        setSelectedValues(valueLocal);
        setFieldValue(name, valueLocal);
      }
    },
    [setFieldValue, selectedValues, name],
  );

  const columns = [
    {
      id: 'avatar',
      disableSortBy: true,
      Header: <TableRowActionsCell minItems={1} />,
      Cell: ({ row }) => {
        const user = row.original;

        return (
          <TableRowActionsCell>
            <UserAvatar
              avatar={user.get('avatar')}
              initials={user.get('initials')}
            />
          </TableRowActionsCell>
        );
      },
    },
    {
      xs: 4,
      id: 'name',
      Header: 'Name',
      accessor: (user) => user.get('name'),
    },
    {
      xs: 4,
      Header: 'Job title',
      accessor: (user) => user.get('title'),
    },
    {
      xs: 4,
      Header: 'Organization',
      accessor: (user) => user.get('organizationName'),
    },
    {
      id: 'actions',
      disableSortBy: true,
      Header: <TableRowActionsCell minItems={1} />,
      Cell: ({ row }) => {
        const user = row.original;

        return (
          <TableRowActionsCell>
            <IconButton
              size="small"
              title="Remove"
              color="default"
              variant="outlined"
              onClick={() => toggleSelection(user)}
            >
              {checkIfSelected(user) ? <CheckBox /> : <CheckBoxOutlineBlank />}
            </IconButton>
          </TableRowActionsCell>
        );
      },
    },
  ];

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={toggleModal}
      TransitionProps={{
        onExited: clearSelection,
      }}
    >
      <ApplicationHeaderBase>
        <Grid container alignItems="center">
          <Grid item xs />
          <Grid
            xs
            item
            container
            zeroMinWidth
            alignItems="center"
            direction="column"
          >
            <Typography item component={Typography} variant="h6">
              Add field crew
            </Typography>
          </Grid>
          <Grid item container xs justifyContent="flex-end">
            <Button color="secondary" variant="contained" onClick={toggleModal}>
              Close
            </Button>
          </Grid>
        </Grid>
      </ApplicationHeaderBase>
      <BasePageDense dataState={dataState}>
        <DialogContent>
          <Grid
            container
            component={Box}
            paddingTop={3}
            paddingBottom={3}
            justifyContent="center"
          >
            <Grid item xs={8}>
              <Table
                usePagination
                items={users}
                columns={columns}
                initialState={initialState}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </BasePageDense>
      <DialogActions
        classes={{
          root: classes.dialogActionsRoot,
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <Button onClick={toggleModal}>Cancel</Button>
          </Grid>
          <Grid item>
            <LoadingButton
              color="primary"
              variant="contained"
              dataState={dataState}
              onClick={() => {
                toggleModal({ modalId: PARTICIPANTSMODAL });
              }}
            >
              {'Save and close'}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const styles = (theme) => ({
  dialogActionsRoot: {
    padding: theme.spacing(3),
    background: theme.altus.background.header,
  },
});

export default compose(withStyles(styles))(AddParticipantsModal);
