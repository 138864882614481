import PropTypes from 'prop-types';
import { castArray } from 'lodash';
import { useCallback, useMemo } from 'react';

import { RouteContainer } from 'altus-ui-components';

import { EMPTY_LIST, SystemPermission } from 'app/app.constants';
import { invokeIfFunction } from 'utils/app.util';
import { isRouteEnabledForEnvironment } from 'utils/route.util';
import { useSystemPermissions } from 'app/hooks/authorization/useSystemPermissions';
import { aperioUserAllowedRoutepaths } from 'authorization/authorization.constants';

const ValidRoutesContainer = ({
  render,
  routes,
  hasPermission,
  MissingRouteComponent,
}) => {
  const { userPermissions } = useSystemPermissions();
  const filterRoutesWithPermissions = useCallback(
    (routes, callback) =>
      castArray(routes).reduce(callback, EMPTY_LIST).toArray(),
    [],
  );

  const handleValidRoute = useCallback(
    (route, callback) => {
      const { routes: subRoutes } = route;

      if (!subRoutes) return route;

      return {
        ...route,
        routes: filterRoutesWithPermissions(subRoutes, callback),
      };
    },
    [filterRoutesWithPermissions],
  );

  const filterRoute = useCallback(
    (validRoutes, route) => {
      const isValidRoute = hasPermission(route);
      const isEnabledForEnvironment = isRouteEnabledForEnvironment(route);

      return isValidRoute && isEnabledForEnvironment
        ? validRoutes.push(handleValidRoute(route, filterRoute))
        : validRoutes;
    },
    [hasPermission, handleValidRoute],
  );

  const validRoutes = useMemo(() => {
    if (userPermissions.includes(SystemPermission.WELL_ACCESS_ONLY)) {
      const filteredRoutesForAperio = routes.filter((route) => {
        if (
          aperioUserAllowedRoutepaths.some((path) => route.path.includes(path))
        )
          return route;
        else return null;
      });
      return filteredRoutesForAperio;
    }
    return filterRoutesWithPermissions(routes, filterRoute);
  }, [routes, filterRoute, filterRoutesWithPermissions, userPermissions]);

  if (render) return invokeIfFunction(render, validRoutes);

  return (
    <RouteContainer
      routes={validRoutes}
      MissingRouteComponent={MissingRouteComponent}
    />
  );
};

ValidRoutesContainer.propTypes = {
  render: PropTypes.func,
  MissingRouteComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.elementType,
  ]),
  hasPermission: PropTypes.func.isRequired,
  routes: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

export default ValidRoutesContainer;
