import {
  Fab as MuiFab,
  Grid as MuiGrid,
  Button as MuiButton,
  Avatar as MuiAvatar,
  Tooltip as MuiTooltip,
  IconButton as MuiIconButton,
  Typography as MuiTypography,
} from '@material-ui/core';

const withTooltip =
  (WrappedComponent) =>
  ({ title, TooltipProps = {}, ...rest }) => {
    return title ? (
      <MuiTooltip title={title} {...TooltipProps}>
        <WrappedComponent {...rest} />
      </MuiTooltip>
    ) : (
      <WrappedComponent {...rest} />
    );
  };

export default withTooltip;

export const Fab = withTooltip(MuiFab);
export const Image = withTooltip('img');
export const Grid = withTooltip(MuiGrid);
export const Button = withTooltip(MuiButton);
export const Avatar = withTooltip(MuiAvatar);
export const IconButton = withTooltip(MuiIconButton);
export const Typography = withTooltip(MuiTypography);
