import { Field } from 'redux-form/immutable';

import TextField from 'app/components/form/TextField';
import { EDIT_PROJECT_FORM } from 'features/projects/details/details.constants';
import ReadOnlyFieldReduxForm from 'app/components/form/redux-form/ReadOnlyFieldReduxForm';

const ProjectNumberField = ({
  input,
  updateProject,
  hasPermission,
  disabled = false,
}) => {
  const { value: displayReferenceNumber } = input;

  if (displayReferenceNumber) {
    return (
      <Field
        label="IOPS No."
        component={TextField}
        onChange={updateProject}
        disabled={!hasPermission || disabled}
        name={EDIT_PROJECT_FORM.REFERENCE}
        inputProps={{ maxLength: 10 }}
      />
    );
  }

  return (
    <Field
      label="No."
      name={EDIT_PROJECT_FORM.ID}
      component={ReadOnlyFieldReduxForm}
    />
  );
};

export default ProjectNumberField;
