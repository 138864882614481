import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Delete from '@material-ui/icons/Delete';
import FileCopy from '@material-ui/icons/FileCopy';
import withStyles from '@material-ui/styles/withStyles';

import { Button } from 'app/components/withTooltip';
import LoadingButton from 'app/components/LoadingButton';
import ReadOnlyField from 'app/components/ReadOnlyField';
import { getWorkItemStatusDisplayName } from 'utils/app.util';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { ProjectPermission, WORK_ITEM_STATUS } from 'app/app.constants';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { Button as NavigationButton } from 'app/components/withNavigation';
import { toWellbore, toProjectTasks, toProjectDetails } from 'utils/route.util';

const TaskDetails = ({
  task,
  taskId,
  classes,
  copyTask,
  projectId,
  deleteTask,
  TaskIdProps,
  TaskRunProps,
  displayProject,
  TaskFieldProps,
  TaskStatusProps,
  ReadOnlyFieldProps,
  displayCopyTaskBtn,
  displayNavigationBtn,
  displayDeleteTaskBtn,
  copyTaskDisabled,
}) => {
  // TODO: Pass as prop, using useSelector currently to remove toJSComponent gradually
  const project = useSelector(getCurrentProject);

  return (
    <Grid container item justifyContent="space-between">
      <Grid container item xs spacing={2} justifyContent="space-between">
        {[
          ['Task', taskId, TaskIdProps],
          ['Run', task.get('runNumber'), TaskRunProps],
          displayProject && [
            'Project',
            projectId,
            {
              to: toProjectDetails(projectId),
            },
          ],
          [
            'Well',
            project.get('wellboreName'),
            {
              target: '_blank',
              to: toWellbore(project.get('fieldId'), project.get('wellboreId')),
            },
          ],
          ['Field', project.get('fieldName'), TaskFieldProps],
          [
            'Status',
            getWorkItemStatusDisplayName(task.get('status')),
            TaskStatusProps,
          ],
        ]
          .filter((value) => value)
          .map(([label, value, props]) => (
            <Grid item key={label}>
              <ReadOnlyField
                margin="none"
                label={label}
                value={value}
                {...ReadOnlyFieldProps}
                {...props}
              />
            </Grid>
          ))}
      </Grid>
      {[displayCopyTaskBtn, displayDeleteTaskBtn, displayNavigationBtn].some(
        (displayBtn) => displayBtn,
      ) && (
        <Grid
          xs
          item
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <HasProjectPermission permissions={ProjectPermission.CREATE_TASKS}>
            {displayCopyTaskBtn && (
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => copyTask(projectId, task.get('id'))}
                  disabled={copyTaskDisabled}
                >
                  <FileCopy fontSize="small" className={classes.leftIcon} />
                  Duplicate
                </Button>
              </Grid>
            )}
          </HasProjectPermission>
          <HasProjectPermission permissions={ProjectPermission.DELETE_TASKS}>
            {displayDeleteTaskBtn && (
              <Grid item>
                <LoadingButton
                  size="small"
                  variant="contained"
                  onClick={() => deleteTask(projectId, taskId)}
                  disabled={task.get('status') !== WORK_ITEM_STATUS.PLANNED}
                >
                  <Delete fontSize="small" className={classes.leftIcon} />
                  Delete
                </LoadingButton>
              </Grid>
            )}
          </HasProjectPermission>
          {displayNavigationBtn && (
            <Grid item>
              <NavigationButton
                size="small"
                color="primary"
                variant="contained"
                to={toProjectTasks(projectId, taskId)}
              >
                Open
              </NavigationButton>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

TaskDetails.defaultProps = {
  displayCopyTaskBtn: true,
  displayDeleteTaskBtn: true,
  displayNavigationBtn: true,
  copyTaskDisabled: false,
};

const styles = (theme) => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

export default withStyles(styles)(TaskDetails);
