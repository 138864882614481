import _isNil from 'lodash/isNil';
import {
  UNIT_OF_MEASURE,
  EDITABLE_TOOL_PROPERTIES,
  TOOL_ASSET_EVENTS_ENUM,
} from 'features/equipment/equipment.constants';
import { CONVERT_MEASURES, UNITS, CUSTOM_UNIT_SYSTEM } from 'app/app.constants';

const replaceM3 = (tool, existingTool) => {
  if (tool.referenceNumber === null)
    tool.referenceNumber = existingTool.referenceNumber;
  for (var propName in tool) {
    if (tool[propName] === '') tool[propName] = existingTool[propName];
    if (tool[propName] === null || !tool[propName]) {
      tool[propName] = existingTool[propName];
    }
    if (tool[propName]) {
      if (tool[propName].value !== undefined && tool[propName].value === '') {
        tool[propName] = existingTool[propName];
      }
    }
  }
  if (tool.toolImageId === 0) tool.toolImageId = null;
  if (tool.tool3DObjectId === 0) tool.tool3DObjectId = null;
  if (tool.yield === undefined) tool.yield = existingTool.yieldValue;
  if (tool.bottomConnectorId === null || tool.bottomConnectorId === undefined)
    tool.bottomConnectorId = existingTool.bottomConnector;
  if (tool.topConnectorId === null || tool.topConnectorId === undefined)
    tool.topConnectorId = existingTool.topConnector;
  return tool;
};

const replaceThirdParty = (tool, existingTool) => {
  for (var propName in tool) {
    if (tool[propName] === '') tool[propName] = existingTool[propName];
    if (tool[propName] === null || !tool[propName]) {
      tool[propName] = existingTool[propName];
    }
    if (tool[propName]) {
      if (tool[propName].value !== undefined && tool[propName].value === '') {
        tool[propName] = existingTool[propName];
      }
    }
  }
  if (tool.yield === undefined) tool.yield = existingTool.yieldValue;
  if (tool.bottomConnectorId === null || tool.bottomConnectorId === undefined)
    tool.bottomConnectorId = existingTool.bottomConnector;
  if (tool.topConnectorId === null || tool.topConnectorId === undefined)
    tool.topConnectorId = existingTool.topConnector;
  if (tool.toolImageId === 0) tool.toolImageId = null;

  return tool;
};

export const existingToolToToolMapper = (tool, existingTool) => {
  return replaceM3(tool, existingTool);
};

export const existingThirdPartyToolToToolMapper = (tool, existingTool) => {
  return replaceThirdParty(tool, existingTool);
};

const customUnits = {
  length: 'length',
  depth: 'depth',
  mass: 'mass',
  toolDiameter: 'toolDiameter',
  force: 'force',
  bigForce: 'bigForce',
};

const customUnitsMetric = (unit) => {
  switch (unit) {
    case customUnits.toolDiameter:
      return 'mm';
    case customUnits.length:
      return 'm';
    case customUnits.depth:
      return 'm';
    case customUnits.mass:
      return 'kg';
    case customUnits.force:
      return 'N';
    case customUnits.bigForce:
      return 'kgf';
    default:
      return null;
  }
};

const customUnitsImperial = (unit) => {
  switch (unit) {
    case customUnits.toolDiameter:
      return 'in';
    case customUnits.length:
      return 'ft';
    case customUnits.depth:
      return 'ft';
    case customUnits.mass:
      return 'lbs';
    case customUnits.force:
      return 'lbf';
    case customUnits.bigForce:
      return 'kipf';
    default:
      return null;
  }
};

export const getUnitForProperty = (
  property,
  uomPreference,
  selectedCustomUnitsOfMeasure,
) => {
  if (uomPreference === UNIT_OF_MEASURE.IMPERIAL) {
    switch (property) {
      case EDITABLE_TOOL_PROPERTIES.OUTER_DIAMETER:
        return 'in';
      case EDITABLE_TOOL_PROPERTIES.INNER_DIAMETER:
        return 'in';
      case EDITABLE_TOOL_PROPERTIES.LENGTH:
        return 'ft';
      case EDITABLE_TOOL_PROPERTIES.DEPTH:
        return 'ft';
      case EDITABLE_TOOL_PROPERTIES.WEIGHT:
        return 'lbs';
      case EDITABLE_TOOL_PROPERTIES.FISHNECK:
        return 'in';
      case EDITABLE_TOOL_PROPERTIES.SWL:
        return 'lbf';
      case EDITABLE_TOOL_PROPERTIES.YIELD:
        return 'kipf';
      case EDITABLE_TOOL_PROPERTIES.MAXIMUM_OD:
        return 'in';
      case EDITABLE_TOOL_PROPERTIES.MINIMUM_OD:
        return 'in';
      case EDITABLE_TOOL_PROPERTIES.MAXIMUM_STANDOFF_FORCE:
        return 'lbf';
      case EDITABLE_TOOL_PROPERTIES.MINIMUM_STANDOFF_FORCE:
        return 'lbf';
      case EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_LOW:
        return 'lbf';
      case EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_HIGH:
        return 'lbf';
      case EDITABLE_TOOL_PROPERTIES.ADDITIONAL_NORMAL_FORCE:
        return 'lbf';
      case EDITABLE_TOOL_PROPERTIES.AXIAL_FRICTION_REDUCED_TO:
        return '';
      case EDITABLE_TOOL_PROPERTIES.WEIGHT_ABOVE_JARRING_POINT_FACTOR:
        return '';
      default:
        return null;
    }
  }
  if (
    uomPreference === UNIT_OF_MEASURE.METRIC ||
    uomPreference === UNIT_OF_MEASURE.PROJECT_DEFAULT
  ) {
    switch (property) {
      case EDITABLE_TOOL_PROPERTIES.OUTER_DIAMETER:
        return 'mm';
      case EDITABLE_TOOL_PROPERTIES.INNER_DIAMETER:
        return 'mm';
      case EDITABLE_TOOL_PROPERTIES.LENGTH:
        return 'm';
      case EDITABLE_TOOL_PROPERTIES.WEIGHT:
        return 'kg';
      case EDITABLE_TOOL_PROPERTIES.DEPTH:
        return 'm';
      case EDITABLE_TOOL_PROPERTIES.FISHNECK:
        return 'mm';
      case EDITABLE_TOOL_PROPERTIES.SWL:
        return 'kgf';
      case EDITABLE_TOOL_PROPERTIES.YIELD:
        return 'kgf';
      case EDITABLE_TOOL_PROPERTIES.MAXIMUM_OD:
        return 'mm';
      case EDITABLE_TOOL_PROPERTIES.MINIMUM_OD:
        return 'mm';
      case EDITABLE_TOOL_PROPERTIES.MAXIMUM_STANDOFF_FORCE:
        return 'N';
      case EDITABLE_TOOL_PROPERTIES.MINIMUM_STANDOFF_FORCE:
        return 'N';
      case EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_LOW:
        return 'N';
      case EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_HIGH:
        return 'N';
      case EDITABLE_TOOL_PROPERTIES.ADDITIONAL_NORMAL_FORCE:
        return 'N';
      case EDITABLE_TOOL_PROPERTIES.AXIAL_FRICTION_REDUCED_TO:
        return '';
      case EDITABLE_TOOL_PROPERTIES.WEIGHT_ABOVE_JARRING_POINT_FACTOR:
        return '';
      default:
        return null;
    }
  }
  if (uomPreference === UNIT_OF_MEASURE.CUSTOM) {
    switch (property) {
      case EDITABLE_TOOL_PROPERTIES.OUTER_DIAMETER:
        return selectedCustomUnitsOfMeasure?.toolDiameter ===
          UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.toolDiameter)
          : customUnitsMetric(customUnits.toolDiameter);
      case EDITABLE_TOOL_PROPERTIES.INNER_DIAMETER:
        return selectedCustomUnitsOfMeasure?.toolDiameter ===
          UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.toolDiameter)
          : customUnitsMetric(customUnits.toolDiameter);
      case EDITABLE_TOOL_PROPERTIES.LENGTH:
        return selectedCustomUnitsOfMeasure?.length === UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.length)
          : customUnitsMetric(customUnits.length);
      case EDITABLE_TOOL_PROPERTIES.DEPTH:
        return selectedCustomUnitsOfMeasure?.depth === UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.depth)
          : customUnitsMetric(customUnits.depth);
      case EDITABLE_TOOL_PROPERTIES.WEIGHT:
        return selectedCustomUnitsOfMeasure?.mass === UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.mass)
          : customUnitsMetric(customUnits.mass);
      case EDITABLE_TOOL_PROPERTIES.FISHNECK:
        return selectedCustomUnitsOfMeasure?.toolDiameter ===
          UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.toolDiameter)
          : customUnitsMetric(customUnits.toolDiameter);
      case EDITABLE_TOOL_PROPERTIES.SWL:
        return selectedCustomUnitsOfMeasure?.bigForce ===
          UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.bigForce)
          : customUnitsMetric(customUnits.bigForce);
      case EDITABLE_TOOL_PROPERTIES.YIELD:
        return selectedCustomUnitsOfMeasure?.bigForce ===
          UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.bigForce)
          : customUnitsMetric(customUnits.bigForce);
      case EDITABLE_TOOL_PROPERTIES.MAXIMUM_OD:
        return selectedCustomUnitsOfMeasure?.toolDiameter ===
          UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.toolDiameter)
          : customUnitsMetric(customUnits.toolDiameter);
      case EDITABLE_TOOL_PROPERTIES.MINIMUM_OD:
        return selectedCustomUnitsOfMeasure?.toolDiameter ===
          UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.toolDiameter)
          : customUnitsMetric(customUnits.toolDiameter);
      case EDITABLE_TOOL_PROPERTIES.MAXIMUM_STANDOFF_FORCE:
        return selectedCustomUnitsOfMeasure?.force === UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.force)
          : customUnitsMetric(customUnits.force);
      case EDITABLE_TOOL_PROPERTIES.MINIMUM_STANDOFF_FORCE:
        return selectedCustomUnitsOfMeasure?.force === UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.force)
          : customUnitsMetric(customUnits.force);
      case EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_LOW:
        return selectedCustomUnitsOfMeasure?.force === UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.force)
          : customUnitsMetric(customUnits.force);
      case EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_HIGH:
        return selectedCustomUnitsOfMeasure?.force === UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.force)
          : customUnitsMetric(customUnits.force);
      case EDITABLE_TOOL_PROPERTIES.ADDITIONAL_NORMAL_FORCE:
        return selectedCustomUnitsOfMeasure?.force === UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(customUnits.force)
          : customUnitsMetric(customUnits.force);
      case EDITABLE_TOOL_PROPERTIES.AXIAL_FRICTION_REDUCED_TO:
      case EDITABLE_TOOL_PROPERTIES.WEIGHT_ABOVE_JARRING_POINT_FACTOR:
        return ''; // TODO: Update if/when Ratio is added as a selectable custom unit of measurement
      default:
        return null;
    }
  }
};

export const unitPlaceholder = (tool, key, fallback = '') => {
  return _isNil(tool) || tool?.isEmpty()
    ? fallback
    : `${tool.getIn([key, 'value'], fallback)} ${tool.getIn(
        [key, 'unit'],
        '',
      )}`;
};

export const toolAssetHistoryEventToString = (event) => {
  switch (event) {
    case TOOL_ASSET_EVENTS_ENUM.COMPLAINTS:
      return 'Complaint';
    case TOOL_ASSET_EVENTS_ENUM.MAINTENANCE:
      return 'Maintenance';
    case TOOL_ASSET_EVENTS_ENUM.RUN:
      return 'Run';
    case TOOL_ASSET_EVENTS_ENUM.TASK:
      return 'Task';
    case TOOL_ASSET_EVENTS_ENUM.TEST:
      return 'Test';
    case TOOL_ASSET_EVENTS_ENUM.BOP_TEST:
      return 'BOP Test';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_TORSION_TEST:
      return 'Cable Torsion Test';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_WRAP_TEST:
      return 'Cable Wrap Test Slickline Cable';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_TORTURE_TEST:
      return 'Cable Torture Test';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_CONTINUITY_TEST:
      return 'Cable Continuity Test';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_INSULATION_TEST:
      return 'Cable Insulation Test';
    case TOOL_ASSET_EVENTS_ENUM.SIL2_TEST:
      return 'Sil2 Test';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_WRAP_BRAIDED_MONO_TEST:
      return 'Cable Wrap Test Braided/Mono Cable';
    case TOOL_ASSET_EVENTS_ENUM.STRING_TEST:
      return 'String Test';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_BREAK_TEST:
      return 'Cable Break Test';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_CUT_MAINTENANCE:
      return 'Cable Cut Maintenance';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_SERVICE_MAINTENANCE:
      return 'Cable Service Maintenance';
    case TOOL_ASSET_EVENTS_ENUM.CABLE_SPOOLING_MAINTENANCE:
      return 'Cable Spooling Maintenance';
    default:
      return 'Invalid event';
  }
};

export const getUnitForCustomUnit = (
  unit,
  uomPreference,
  selectedCustomUnitsOfMeasure,
  projectCustomPreferences,
) => {
  if (uomPreference === UNIT_OF_MEASURE.PROJECT_DEFAULT) {
    if (projectCustomPreferences?.get('unit') === CUSTOM_UNIT_SYSTEM) {
      const currentForceUnit = projectCustomPreferences?.getIn([
        'units',
        'force',
      ]);
      return currentForceUnit === UNIT_OF_MEASURE.IMPERIAL
        ? customUnitsImperial(unit)
        : customUnitsMetric(unit);
    } else {
      return projectCustomPreferences?.get('unit') === UNIT_OF_MEASURE.IMPERIAL
        ? customUnitsImperial(unit)
        : customUnitsMetric(unit);
    }
  }

  if (uomPreference === UNIT_OF_MEASURE.IMPERIAL) {
    switch (unit) {
      case CONVERT_MEASURES.FORCE:
        return UNITS.POUND_FORCE;
      default:
        return null;
    }
  }
  if (uomPreference === UNIT_OF_MEASURE.METRIC) {
    switch (unit) {
      case CONVERT_MEASURES.FORCE:
        return UNITS.NEWTON;
      default:
        return null;
    }
  }
  if (uomPreference === UNIT_OF_MEASURE.CUSTOM) {
    switch (unit) {
      case CONVERT_MEASURES.FORCE:
        return selectedCustomUnitsOfMeasure?.force === UNIT_OF_MEASURE.IMPERIAL
          ? customUnitsImperial(unit)
          : customUnitsMetric(unit);
      default:
        return null;
    }
  }
};
