import { EMPTY_MAP } from 'app/app.constants';
import GoogleMap from 'app/components/GoogleMap';
import ProjectMapMarker from 'features/projects/components/WellMapLocations/ProjectMapMarker';

const ProjectMapLocations = ({
  center,
  projects = EMPTY_MAP,
  isHovered,
  currentProjectId,
}) => {
  return (
    <GoogleMap center={center}>
      {projects.valueSeq().map((project) => {
        const projectId = project.get('id');
        const location = project.get('location');

        if (!location) return null;

        const nortSouth =
          location.get('northSouth') ?? location.get('locationNorthSouth');
        const eastWest =
          location.get('eastWest') ?? location.get('locationEastWest');

        return (
          <ProjectMapMarker
            key={projectId}
            lng={eastWest}
            status={project.get('status')}
            lat={nortSouth}
            isHovered={isHovered && projectId === currentProjectId}
          />
        );
      })}
    </GoogleMap>
  );
};

export default ProjectMapLocations;
