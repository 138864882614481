import classNames from 'classnames';
import { connect } from 'react-redux';
import Info from '@material-ui/icons/Info';
import Stop from '@material-ui/icons/Stop';
import Pause from '@material-ui/icons/Pause';
import Close from '@material-ui/icons/Close';
import Replay from '@material-ui/icons/Replay';
import { createRef, PureComponent } from 'react';
import { Button, Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import toJSComponent from 'with-immutable-props-to-js';

import {
  ACTIVITIES_ACTIONS,
  ACTIVITY_BUTTONS,
  TRAC_SUBACTIVITY_CODE,
} from 'features/projects/activities/activities.constants';

import {
  getExecuteStatusFromState,
  getPointInTimeActivityTypes,
  getWaitingActivityTypes,
} from 'features/projects/activities/activities.selectors';

import withDataState from 'app/components/withDataState';
import { getActionDataStateFromState } from 'app/app.selectors';
import { scrollSmoothlyIntoViewIfNeeded } from 'utils/app.util';
import { ButtonStop } from './components/ButtonStop';
import { alpha } from '@material-ui/core/styles';

class ActivityDetails extends PureComponent {
  constructor(props) {
    super(props);

    this.root = createRef();

    this.state = {
      toggledActivityButton: undefined,
    };
  }

  componentDidMount() {
    scrollSmoothlyIntoViewIfNeeded(this.root.current);
  }

  setToggledActivityButton = (type) => {
    this.setState((state) => ({
      toggledActivityButton:
        state.toggledActivityButton === type ? undefined : type,
    }));
  };

  render() {
    const {
      goBack,
      taskId,
      classes,
      activity,
      previous,
      projectId,
      abortTaskDataState,
      activityTypesWaiting,
      pauseActivityDataState,
      activityTypesPointInTime,
      completeActivityDataState,
      createPointInTimeActivityDataState,
      createNewPointInTimeActivity,
      pauseActivity,
    } = this.props;

    const { toggledActivityButton } = this.state;

    const completingActivity = completeActivityDataState.isLoading();

    const cantUndo = !previous.canGoBack;

    return (
      <Grid
        container
        spacing={2}
        justifyContent="center"
        className={classes.rootContainer}
        ref={this.root}
      >
        <Grid item>
          <Grid container spacing={1}>
            {[
              {
                type: ACTIVITY_BUTTONS.WAIT,
                Icon: Pause,
                label: 'Wait',
              },
              {
                type: ACTIVITY_BUTTONS.STOP,
                Icon: Stop,
                label: 'Stop',
              },
              {
                type: ACTIVITY_BUTTONS.EVENT,
                Icon: Info,
                label: 'Sub Activity',
              },
            ].map(({ type, Icon, label }) => (
              <Grid item key={type}>
                <Button
                  size="small"
                  className={classNames({
                    [classes.notSelected]:
                      toggledActivityButton && toggledActivityButton !== type,
                  })}
                  color={toggledActivityButton === type ? 'primary' : 'default'}
                  variant="contained"
                  disabled={completingActivity}
                  onClick={() => this.setToggledActivityButton(type)}
                >
                  {toggledActivityButton === type ? (
                    <Close className={classes.leftIcon} />
                  ) : (
                    <Icon className={classes.leftIcon} />
                  )}
                  {label}
                </Button>
              </Grid>
            ))}
            <Grid item>
              <Tooltip
                title={
                  cantUndo
                    ? "You can't undo a Task with preceding sub-surface tasks"
                    : `Go Back to ${
                        previous?.activity?.name ?? 'previous step'
                      }`
                }
              >
                <span>
                  <Button
                    size="small"
                    onClick={() => goBack(projectId, taskId)}
                    variant="contained"
                    disabled={completingActivity || cantUndo}
                  >
                    <Replay className={classes.leftIcon} />
                    Undo
                  </Button>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {toggledActivityButton && (
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="center">
              {withDataState(() => {
                switch (toggledActivityButton) {
                  case ACTIVITY_BUTTONS.WAIT: {
                    if (activityTypesWaiting.length) {
                      return activityTypesWaiting.map((activityType) => {
                        const { id: activityTypeId, value } = activityType;
                        let { name } = value;
                        name = `${name}`;

                        return (
                          <Grid item key={activityTypeId}>
                            <Chip
                              label={name}
                              icon={<Pause />}
                              onClick={() =>
                                pauseActivity(
                                  projectId,
                                  taskId,
                                  activity.id,
                                  activityTypeId,
                                )
                              }
                              classes={{
                                root: classes.chipRoot,
                              }}
                            />
                          </Grid>
                        );
                      });
                    }
                    return (
                      <Typography>
                        <i>No actions found...</i>
                      </Typography>
                    );
                  }
                  case ACTIVITY_BUTTONS.STOP: {
                    return ['Abort run'].map((stop) => (
                      <Grid item key={stop}>
                        <ButtonStop
                          label={stop}
                          projectId={projectId}
                          taskId={taskId}
                          activityId={activity.id}
                          classes={classes}
                        />
                      </Grid>
                    ));
                  }
                  case ACTIVITY_BUTTONS.EVENT: {
                    if (activityTypesPointInTime.length) {
                      return activityTypesPointInTime
                        .filter(
                          (activityType) =>
                            activityType?.value?.code !== TRAC_SUBACTIVITY_CODE,
                        )
                        .map((activityType) => {
                          const { id: activityTypeId, value } = activityType;
                          const { name } = value;

                          return (
                            <Grid item key={activityTypeId}>
                              <Chip
                                label={name}
                                icon={<Info />}
                                onClick={() => {
                                  createNewPointInTimeActivity(
                                    projectId,
                                    taskId,
                                    activity.id,
                                    activityTypeId,
                                  );
                                }}
                                classes={{
                                  root: classes.chipRoot,
                                }}
                              />
                            </Grid>
                          );
                        });
                    }
                    return (
                      <Typography>
                        <i>No actions found...</i>
                      </Typography>
                    );
                  }
                  default:
                    break;
                }
              })({
                renderWhileLoading: true,
                loadingText: 'Loading',
                dataState: [
                  abortTaskDataState,
                  pauseActivityDataState,
                  createPointInTimeActivityDataState,
                ],
                LoadingDimmerProps: {
                  classes: {
                    progress: classes.loadingProgress,
                  },
                },
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  activityTypesWaiting: getWaitingActivityTypes(state),
  activityTypesPointInTime: getPointInTimeActivityTypes(state),
  execute: getExecuteStatusFromState(state),
  completeActivityDataState: getActionDataStateFromState(
    state,
    ACTIVITIES_ACTIONS.COMPLETE_ACTIVITY,
  ),
  abortTaskDataState: getActionDataStateFromState(
    state,
    ACTIVITIES_ACTIONS.COMPLETE_ACTIVITY_ABORT_TASK,
  ),
  pauseActivityDataState: getActionDataStateFromState(
    state,
    ACTIVITIES_ACTIONS.INITIATE_PAUSE_ACTIVITY,
  ),
  createPointInTimeActivityDataState: getActionDataStateFromState(
    state,
    ACTIVITIES_ACTIONS.CREATE_NEW_POINT_IN_TIME_ACTIVITY,
  ),
});

const styles = (theme) => {
  return {
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    chipRoot: {
      height: 'auto',
      padding: 5,
    },
    loadingContainer: {
      backgroundColor: 'transparent',
      height: '100%',
      position: 'absolute',
    },
    loadingProgress: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
    },
    startActivityButton: {
      textTransform: 'none',
    },
    rootContainer: {
      background: alpha(theme.palette.common.white, 0.08),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  };
};

export default connect(
  mapStateToProps,
  {},
)(toJSComponent(withStyles(styles)(ActivityDetails)));
