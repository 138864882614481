import {
  getCurrentUserFromState,
  getUsersByUserIdFromState,
  getCurrentProjectMemberFromState,
} from 'app/app.selectors';

import { EMPTY_LIST } from 'app/app.constants';
import { createSelector } from 'utils/app.util';

const getSystemRoleSelector = (state, systemRoleId) =>
  state.getIn(['authorization', 'systemRoles', systemRoleId.toString()]);

const getProjectRoleSelector = (state, projectRoleId) =>
  state.getIn(['authorization', 'projectRoles', projectRoleId.toString()]);

export const getSystemRolesFromState = createSelector(
  (state) => state.getIn(['authorization', 'systemRoles']),
  (systemRoles) => systemRoles,
);

export const getSystemRolesListFromState = createSelector(
  getSystemRolesFromState,
  (systemRoles) => systemRoles.toList(),
);

export const getUserRolesFromState = createSelector(
  (state) => state.getIn(['authorization', 'userRoles']),
  (userRoles) => userRoles,
);

export const getUsersAssignedToSystemRoleFromState = (systemRoleId) =>
  createSelector(
    getUsersByUserIdFromState,
    getUserRolesFromState,
    (users, userRoles) =>
      userRoles
        .get(systemRoleId.toString(), EMPTY_LIST)
        .map((userId) => users.get(userId.toString()))
        .filter(Boolean),
  );

export const getSystemRoleFromStateSelector = (systemRoleId) =>
  createSelector(getSystemRolesFromState, (systemRoles) =>
    systemRoles.get(systemRoleId.toString()),
  );

export const getSystemRoleFromState = createSelector(
  getSystemRoleSelector,
  (systemRole) => systemRole,
);

export const getProjectRoleFromState = createSelector(
  getProjectRoleSelector,
  (projectRole) => projectRole,
);

export const getProjectRolesFromState = createSelector(
  (state) => state.getIn(['authorization', 'projectRoles']),
  (projectRoles) => projectRoles,
);

export const getProjectRolesListFromState = createSelector(
  getProjectRolesFromState,
  (projectRoles) => projectRoles.toList(),
);

const getProjectRolePermissionsSelector = (state, projectRoleId) =>
  state.getIn([
    'authorization',
    'projectRolePermissions',
    projectRoleId.toString(),
  ]);

export const getProjectRolePermissionsFromState = createSelector(
  getProjectRolePermissionsSelector,
  (projectRolePermissions) => projectRolePermissions,
);

const getSystemRolePermissionsSelector = (state, systemRoleId) =>
  state.getIn([
    'authorization',
    'systemRolePermissions',
    systemRoleId.toString(),
  ]);

export const getSystemRolePermissionsFromState = createSelector(
  getSystemRolePermissionsSelector,
  (systemRolePermissions) => systemRolePermissions,
);

const getSystemRoleProjectPermissionsSelector = (state, systemRoleId) =>
  state.getIn([
    'authorization',
    'systemRoleProjectPermissions',
    systemRoleId.toString(),
  ]);

export const getSystemRoleProjectPermissionsFromState = createSelector(
  getSystemRoleProjectPermissionsSelector,
  (systemRoleProjectPermissions) => systemRoleProjectPermissions,
);

export const getProjectPermissionsFromState = createSelector(
  (state) => state.getIn(['authorization', 'projectPermissions']),
  (projectPermissions) => projectPermissions,
);

export const getSystemPermissionsFromState = createSelector(
  (state) => state.getIn(['authorization', 'systemPermissions']),
  (systemPermissions) => systemPermissions,
);

export const getCurrentUserProjectPermissionsFromState = createSelector(
  getCurrentUserFromState,
  getCurrentProjectMemberFromState,
  (currentUser, currentProjectMember) =>
    currentProjectMember
      ? currentUser
          .get('projectPermissions')
          .union(currentProjectMember.get('permissions'))
      : currentUser.get('projectPermissions'),
);

export const getSystemAndProjectPermissionsFromState = createSelector(
  getSystemPermissionsFromState,
  getProjectPermissionsFromState,
  (systemPermissions, projectPermissions) =>
    systemPermissions && projectPermissions
      ? systemPermissions.concat(projectPermissions)
      : undefined,
);

export const getSystemAndProjectRolePermissionsFromState = createSelector(
  getSystemRolePermissionsFromState,
  getSystemRoleProjectPermissionsFromState,
  (systemRolePermissions, projectRolePermissions) =>
    systemRolePermissions && projectRolePermissions
      ? systemRolePermissions.concat(projectRolePermissions)
      : undefined,
);
