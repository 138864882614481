import { useCallback, useState, useMemo, useEffect } from 'react';
import { Field, Formik } from 'formik';
import Delete from '@material-ui/icons/Delete';
import { Grid, IconButton, Typography } from '@material-ui/core';

import { formatDate } from 'utils/format.util';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import DateTimePickerFormik from 'app/components/form/formik/DateTimePickerFormik';
import EditorFormik from 'app/components/form/formik/EditorFormik';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
// import { SortableElement } from 'react-sortable-hoc';
// import HasProjectPermission from 'app/components/HasProjectPermission';
// import { ProjectPermission } from 'app/app.constants';
// import WorkItemSortHandle from 'features/projects/components/WorkItemSortHandle';

const FormFields = {
  START_TIME: 'startTime',
  DESCRIPTION: 'description',
};

/**
 * Commented the sorting code for release
 * since it has some issues.
 * Planned to resolve it after the release.
 */
const ActivityChildComponent = ({
  child,
  classes,
  onClick,
  updateActivityTimes,
  onChildDescriptionChanged,
  isLastSubActivity = false,
  // isCurrentActivity = false,
}) => {
  const [rawActivityDescriptionJson, setRawActivityDescriptionJson] =
    useState();
  const [activityDescriptionEditorState, setActivityDescriptionEditorState] =
    useState(EditorState.createEmpty());

  const updateContent = useCallback(
    (content, setContent, setEditorState, editorState) => {
      // 8343 restored && !setContent due to non-refreshing fields
      if (child[content]?.getCurrentContent() && setContent) {
        const rawEditorState = convertToRaw(editorState.getCurrentContent());
        if (JSON.stringify(rawEditorState) !== JSON.stringify(setContent)) {
          const rawContentState = convertToRaw(
            child[content].getCurrentContent(),
          );
          const newEditorState = EditorState.createWithContent(
            convertFromRaw(rawContentState),
          );
          setEditorState(newEditorState);
        }
      }
    },
    [child],
  );

  const updateValues = (values, key, setRawJson) => {
    const JSONValue = JSON.stringify(
      convertToRaw(values[key].getCurrentContent()),
    );
    setRawJson(JSONValue);
  };

  const initialFormValues = useMemo(
    () => ({
      [FormFields.START_TIME]: formatDate(child.startTime),
      [FormFields.DESCRIPTION]: activityDescriptionEditorState,
    }),
    [activityDescriptionEditorState, child.startTime],
  );

  const onSubmit = useCallback(
    (values, formik) => {
      let hasChanged = false;
      if (
        JSON.stringify(convertToRaw(values.description.getCurrentContent())) !==
        JSON.stringify(
          convertToRaw(initialFormValues.description.getCurrentContent()),
        )
      ) {
        updateValues(values, 'description', setRawActivityDescriptionJson);
        setActivityDescriptionEditorState(values.description);
        hasChanged = true;
      }
      if (hasChanged) {
        onChildDescriptionChanged(values.description, child);
      }
      if (formatDate(child.startTime) !== values.startTime) {
        child.startTime = values.startTime;
        child.endTime = values.endTime;
        updateActivityTimes(child.projectId, child.taskId, false, child);
        formik.resetForm();
      }
    },
    [
      child,
      onChildDescriptionChanged,
      updateActivityTimes,
      initialFormValues.description,
    ],
  );

  useEffect(() => {
    const newRawActivityDescriptionJson = child.description
      ? convertToRaw(child.description.getCurrentContent())
      : child.description;

    if (
      JSON.stringify(rawActivityDescriptionJson) !==
        JSON.stringify(newRawActivityDescriptionJson) &&
      child.description
    ) {
      setRawActivityDescriptionJson(newRawActivityDescriptionJson);
      updateContent(
        'description',
        newRawActivityDescriptionJson,
        setActivityDescriptionEditorState,
        activityDescriptionEditorState,
      );
    }
    // eslint-disable-next-line
  }, [child, rawActivityDescriptionJson]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues}
      onSubmit={onSubmit}
    >
      <AutoSaveFormik timeout={1000}>
        <Grid
          item
          container
          xs={10}
          alignItems="center"
          direction="row"
          style={{ marginBottom: isLastSubActivity ? '3rem' : '0rem' }}
        >
          {/* <Grid item xs={1}>
            {isCurrentActivity && (
              <HasProjectPermission
                permissions={ProjectPermission.EDIT_ACTIVITIES}
              >
                <WorkItemSortHandle disabled={!isCurrentActivity} />
              </HasProjectPermission>
            )}
          </Grid> */}
          <Grid item xs={1} />
          <Grid item xs={1}>
            <WorkItemStatus
              status={child.status}
              isWaiting={child.isWait}
              isPointInTime={child.isPointInTime}
            />
          </Grid>
          <Grid item container xs={9} direction="row" alignItems="center">
            <Grid
              item
              container
              xs={2}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography noWrap variant="body2">
                {child.name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Field name={FormFields.START_TIME}>
                {({ form, ...formik }) => (
                  <DateTimePickerFormik
                    disableFuture={true}
                    form={form}
                    {...formik}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={7} className={classes.childDescription}>
              <Field name={FormFields.DESCRIPTION}>
                {({ form, ...formik }) => (
                  <EditorFormik
                    outlinedTextField
                    form={form}
                    {...formik}
                    label="Description"
                    fullWidth
                    toolbar={{
                      options: ['inline', 'list'],
                    }}
                    toolbarOnFocus
                    minHeight={7}
                    maxHeight={7}
                    labelMarginLeft={8}
                    xs={12}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
          <Grid item xs={1} container direction="row" justifyContent="flex-end">
            <IconButton
              title="Delete"
              onClick={onClick}
              classes={{
                root: classes.iconButtonRoot,
              }}
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </AutoSaveFormik>
    </Formik>
  );
};

// export default SortableElement(ActivityChildComponent);
export default ActivityChildComponent;
