import LocationOn from '@material-ui/icons/LocationOn';
import withStyles from '@material-ui/styles/withStyles';

const FacilityMapLocation = ({ classes }) => {
  return <LocationOn color="inherit" className={classes.root} />;
};

const styles = () => ({
  root: {
    fontSize: 40,
    color: 'red',
  },
});

export default withStyles(styles)(FacilityMapLocation);
