import { compose } from 'redux';
import { useEffect, useCallback } from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';

import {
  createSurfaceEquipment,
  onLoadAddToolsModal,
  updateTool,
} from 'features/equipment/equipment.actions';

import {
  getToolConnectorsFromState,
  getUnitsOfMeasurePreference,
} from 'features/equipment/equipment.selectors';

import {
  UNIT_OF_MEASURE,
  EQUIPMENT_ACTIONS,
} from 'features/equipment/equipment.constants';

import { EMPTY_STRING } from 'app/app.constants';
import { ThirdPartyLabel } from 'features/projects/tool/tool.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { FormFields } from 'features/equipment/surfaceEquipment/surfaceEquipmentModalForm/AddSurfaceEquipmentForm';
import { existingThirdPartyToolToToolMapper } from 'features/equipment/equipment.helpers';
import AddSurfaceEquipmentModal from 'features/equipment/surfaceEquipment/surfaceEquipmentModalForm/AddSurfaceEquipmentModal';

const initialFormValues = {
  [FormFields.NAME]: EMPTY_STRING,
  [FormFields.GROUP]: null,
  [FormFields.M3ITEMNUMBER]: EMPTY_STRING,
  [FormFields.LENGTH]: EMPTY_STRING,
  [FormFields.HEIGHT]: EMPTY_STRING,
  [FormFields.WIDTH]: EMPTY_STRING,
  [FormFields.WEIGHT]: EMPTY_STRING,
  [FormFields.DESCRIPTION]: EMPTY_STRING,
  [FormFields.IS_VERIFIED]: false,
  [FormFields.FILE]: null,
  [FormFields.TOOL_IMAGE_ID]: EMPTY_STRING,
};

const AddSurfaceEquipmentModalContainer = ({
  edit,
  tool,
  toolGroups,
  title,
  toolId,
  openModal,
  suppliers,
  toggleModal,
  existingTool,
  dispatchOnLoad,
}) => {
  const dispatch = useDispatch();
  const toolConnectors = useSelector(getToolConnectorsFromState);
  const unitsOfMeasurePreference = useSelector(getUnitsOfMeasurePreference);

  useEffect(() => {
    dispatchOnLoad();
  }, [dispatchOnLoad]);

  const saveTool = useCallback(
    (formValues) => {
      const tool = {
        name: formValues.name,
        description: formValues.description,
        m3ItemNumber: formValues.m3ItemNumber,
        equipmentGroupId: formValues.group,
        length: {
          value: formValues.length,
          unit:
            unitsOfMeasurePreference === UNIT_OF_MEASURE.IMPERIAL ? 'in' : 'mm',
        },
        weight: {
          value: formValues.weight,
          unit:
            unitsOfMeasurePreference === UNIT_OF_MEASURE.IMPERIAL
              ? 'lbs'
              : 'kg',
        },
        width: {
          value: formValues.width,
          unit:
            unitsOfMeasurePreference === UNIT_OF_MEASURE.IMPERIAL ? 'in' : 'mm',
        },
        height: {
          value: formValues.height,
          unit:
            unitsOfMeasurePreference === UNIT_OF_MEASURE.IMPERIAL ? 'in' : 'mm',
        },
        isVerified: formValues.isVerified,
        toolImageId: formValues.toolImageId,
      };
      if (edit) {
        tool.toolId = parseInt(toolId);
        tool.referenceNumber = ThirdPartyLabel;
        tool.m3ItemNumber = ThirdPartyLabel;
        const newTool = existingThirdPartyToolToToolMapper(tool, existingTool);
        dispatch(updateTool(newTool));
      } else {
        dispatch(createSurfaceEquipment(tool));
      }
    },
    [unitsOfMeasurePreference, edit, toolId, existingTool, dispatch],
  );

  return (
    <AddSurfaceEquipmentModal
      edit={edit}
      tool={tool}
      title={title}
      open={openModal}
      onSubmit={saveTool}
      saveTool={saveTool}
      suppliers={suppliers}
      toolGroups={toolGroups}
      toggleModal={toggleModal}
      toolConnectors={toolConnectors}
      initialFormValues={initialFormValues}
      unitsOfMeasurePreference={unitsOfMeasurePreference}
    />
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    EQUIPMENT_ACTIONS.CREATE_SURFACE_EQUIPMENT,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoadAddToolsModal,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddSurfaceEquipmentModalContainer,
);
