import { useMemo } from 'react';
import { Formik } from 'formik';
import { Grid, Button } from '@material-ui/core';

import { EMPTY_STRING } from 'app/app.constants';
import FluidForm from 'app/components/FluidsFormSection/FluidForm';

const CreateFluidForm = ({ onCancel, createFluid, defaultFluid }) => {
  const initialValues = useMemo(
    () => defaultFluid.setIn(['endDepth', 'value'], EMPTY_STRING).toJS(),
    [defaultFluid],
  );

  return (
    <Formik initialValues={initialValues} onSubmit={createFluid}>
      {(formik) => {
        const { dirty, isValid, submitForm, isSubmitting } = formik;

        return (
          <FluidForm autoFocus {...formik}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button onClick={onCancel}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={submitForm}
                  disabled={isSubmitting || !isValid || !dirty}
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </FluidForm>
        );
      }}
    </Formik>
  );
};

export default CreateFluidForm;
