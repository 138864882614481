import moment from 'moment';
import last from 'lodash/last';
import initial from 'lodash/initial';
import { fromJS, Map } from 'immutable';

export const initialDocument = () => {
  return {
    id: null,
    name: '',
    category: 1,
    extension: null,
    project: { id: null },
    wellbore: { id: null },
    created: null,
    modified: null,
  };
};

export const initialChecklist = () => {
  return {
    id: null,
    name: '',
    link: '',
    listKeywords: '',
    documentLink: '',
    organizationId: '',
    procedureType: '',
    source: '',
    procedureDepartments: [],
  };
};

export const toChecklistFromDto = ({
  id,
  name,
  link,
  listKeywords,
  source,
  documentLink,
  organizationId,
  procedureType,
  procedureDepartments,
}) => {
  const keywordsArray =
    listKeywords.trim() !== ''
      ? listKeywords.split(';').map((keyword) => keyword.trim())
      : [];

  return fromJS({
    id,
    name,
    link,
    listKeywords: keywordsArray,
    source,
    documentLink,
    organizationId,
    procedureType,
    procedureDepartments,
  });
};

export const toFileFromDTO = ({
  fileId,
  name,
  category,
  projectId,
  extension,
  created,
  modified,
  taskId,
  fullName,
  createdByUserId,
  categoryName,
  editDocumentUrl,
  createdByUser,
}) => {
  return fromJS({
    id: fileId,
    name: name,
    category: category,
    extension: extension,
    project: { id: projectId },
    created: moment(created),
    modified: modified,
    taskId: taskId,
    fullName: fullName,
    createdByUserId,
    fileId,
    projectId,
    categoryName,
    editDocumentUrl,
    createdByUser,
  });
};

const initialMetaFromFilename = ({ name }, initialCategory) => {
  const parts = name.split('.');

  return Map({
    name: initial(parts).join('.'),
    category: initialCategory.id,
    extension: `.${last(parts)}`,
  });
};

const documentMappers = {
  Document: {
    initial: initialDocument,
    from: toFileFromDTO,
    Meta: {
      initial: initialMetaFromFilename,
    },
  },
  toFileFromDTO,
  initialDocument,
  Checklist: {
    initial: initialChecklist,
    from: toChecklistFromDto,
  },
  toChecklistFromDto,
  initialChecklist,
};

export default documentMappers;
