import moment from 'moment';
import { List, Map } from 'immutable';

import {
  TaskType,
  CONTINGENCY_RUN_NUMBER,
} from 'features/projects/tasks/tasks.constants';

import mappers from 'mappers';
import { EMPTY_MAP, EMPTY_LIST, WORK_ITEM_STATUS } from 'app/app.constants';
import activitiesMappers from 'features/projects/activities/activities.mappers';
import {
  editorStateToString,
  formatDurationInSeconds,
  isValidJSON,
} from 'utils/app.util';

export const secondsToHoursAndMinutes = (seconds) => {
  const duration = moment.duration({ seconds });

  return {
    hours: Math.floor(duration.asHours()),
    minutes: duration.minutes(),
  };
};

export const initialTask = () => {
  return Map({
    id: null,
    title: null,
    description: null,
    comments: null,
    departments: EMPTY_LIST,
    services: EMPTY_LIST,
    targetDepth: {
      value: null,
      unit: 'm',
    },
    duration: {
      planned: EMPTY_MAP,
      actual: null,
    },
    status: WORK_ITEM_STATUS.PLANNED,
    progress: 0,
    sequence: null,
    runNumber: null,
    objective: null,
    type: TaskType.SUB_SURFACE,
    taskRisk: [],
  });
};

export const toTaskFromDTO = ({
  taskId,
  departments,
  files,
  durationPlanned,
  status,
  percentComplete = 0,
  services,
  startTime,
  endTime,
  taskType,
  runNumber,
  contingencyForTaskId,
  contingencyForTaskRunNumber,
  activities,
  sequence,
  objective,
  ...task
}) => {
  return Map({
    id: taskId,
    taskId,
    status,
    activities: activities
      ? List(activities).map(activitiesMappers.Activity.from)
      : undefined,
    contingencyForTaskId,
    services: List(services).map(mappers.Service.from),
    departments: List(departments).map(mappers.Department.from),
    estimated: formatDurationInSeconds(durationPlanned),
    durationPlanned: durationPlanned
      ? secondsToHoursAndMinutes(durationPlanned)
      : 0,
    progress: percentComplete,
    files: files ? List(files).map(toTaskFileFromDTO) : List(),
    type: taskType,
    startTime: startTime ? moment(startTime) : undefined,
    endTime: endTime ? moment(endTime) : undefined,
    sequence: sequence,
    runNumber: contingencyForTaskId ? CONTINGENCY_RUN_NUMBER : sequence,
    objective: objective,
    ...task,
  });
};

export const toTaskDTO = ({
  contingencyForTaskId,
  durationPlanned,
  departments,
  toolstring,
  runNumber,
  type,
  objective,
  description,
  comments,
  activities,
  ...task
}) => {
  return {
    departments: departments.map(mappers.Department.to),
    toolString: toolstring,
    durationPlanned: moment.duration(durationPlanned).asSeconds(),
    status: WORK_ITEM_STATUS.PLANNED,
    taskType: type,
    contingencyForTaskId: contingencyForTaskId,
    runNumber: null,
    objective: objective
      ? isValidJSON(objective)
        ? objective
        : editorStateToString(objective)
      : null,
    description: description
      ? isValidJSON(description)
        ? description
        : editorStateToString(description)
      : null,
    comments: comments
      ? isValidJSON(comments)
        ? comments
        : editorStateToString(comments)
      : null,
    activities: activities
      ? activities.map(activitiesMappers.Activity.to)
      : null,
    ...task,
  };
};

export const initialTemplateTask = () => {
  return Map({
    id: null,
    title: null,
    description: null,
  });
};

export const toTemplateTaskFromDTO = ({
  title,
  serviceId,
  description,
  templateTaskId,
  templateActivities,
}) => {
  return Map({
    id: templateTaskId,
    title: title,
    description: description,
    serviceId: serviceId,
    activities: List(templateActivities).map(activitiesMappers.Activity.from),
  });
};

export const toTaskCableFromDTO = (...taskCable) => Map(...taskCable);

export const toSurfaceEquipmentFromDTO = ({
  individualSerialNumber,
  ...surfaceEquipment
}) => {
  return Map({
    serialNo: individualSerialNumber,
    ...surfaceEquipment,
  });
};

export const toTaskFileFromDTO = ({ fileId, fullName, taskId }) => {
  return Map({
    id: fileId,
    name: fullName,
    taskId: taskId,
  });
};

export const toTaskFileDto = ({ id, name, taskId }) => {
  return Map({
    fileId: id,
    fullName: name,
    taskId: taskId,
  });
};

export const taskRiskFromDto = ({
  riskId,
  description,
  risk,
  mitigation,
  consequence,
  probability,
  state,
}) => ({
  riskId,
  description: description ?? risk,
  risk: description ?? risk,
  mitigation,
  consequence: consequence,
  probability: probability,
  state: state,
});

export const taskRiskToDto = ({
  riskId,
  description,
  mitigation,
  consequence,
  probability,
  state,
}) => ({
  riskId,
  risk: description,
  mitigation: mitigation,
  consequence: consequence,
  probability: probability,
  state: state,
});

const tasksMappers = {
  Task: {
    initial: initialTask,
    from: toTaskFromDTO,
    to: toTaskDTO,
    File: {
      from: toTaskFileFromDTO,
      to: toTaskFileDto,
    },
    Template: {
      initial: initialTemplateTask,
      from: toTemplateTaskFromDTO,
    },
    TaskCable: {
      from: toTaskCableFromDTO,
    },
    TaskSurfaceEquipment: {
      from: toSurfaceEquipmentFromDTO,
    },
    TaskRisk: {
      to: taskRiskToDto,
      from: taskRiskFromDto,
    },
  },
};

export default tasksMappers;
