import { put, call, takeLatest } from 'redux-saga/effects';

import {
  receiveDashboards,
  receiveProjectDashboard,
  receiveProjectDashboards,
  receiveProjectDashboardCurves,
} from 'features/projects/dashboard/dashboard.actions';

import { callAsync } from 'app/sagas/helperSagas';
import { invokeIfFunction } from 'utils/app.util';
import dashboardService from 'services/dashboard.service';
import projectDashboardService from 'services/projectDashboard.service';
import { DASHBOARDS_ACTIONS } from 'features/projects/dashboard/dashboard.constants';

export function* requestDashboardsAsync() {
  const dashboards = yield call(dashboardService.getDashboards);

  yield put(receiveDashboards(dashboards));

  return dashboards;
}

export function* requestProjectDashboardAsync(action) {
  const { projectId, projectDashboardId } = action;

  const projectDashboard = yield call(
    projectDashboardService.getProjectDashboard,
    projectId,
    projectDashboardId,
  );

  const projectDashboardCurves = yield call(
    projectDashboardService.getProjectDashboardCurves,
    projectId,
    projectDashboardId,
  );

  yield put(
    receiveProjectDashboard(projectId, projectDashboardId, projectDashboard),
  );

  yield put(
    receiveProjectDashboardCurves(projectDashboardId, projectDashboardCurves),
  );

  return projectDashboard;
}

export function* requestProjectDashboardsAsync(action) {
  const { projectId } = action;

  const projectDashboards = yield call(
    projectDashboardService.getProjectDashboards,
    projectId,
  );

  yield put(receiveProjectDashboards(projectId, projectDashboards));

  return projectDashboards;
}

export function* toggleProjectDashboardAsync(action) {
  const { callback, projectId, dashboardId } = action;

  const projectDashboard = yield call(
    projectDashboardService.toggleProjectDashboard,
    projectId,
    dashboardId,
  );

  yield call(requestProjectDashboardsAsync, action);

  if (projectDashboard) {
    invokeIfFunction(callback, projectDashboard.projectDashboardId);
  }
}

export function* toggleProjectDashboardsAsync(action) {
  const { callback, projectId } = action;

  const projectDashboards = yield call(
    projectDashboardService.toggleProjectDashboards,
    projectId,
  );

  invokeIfFunction(callback);

  yield put(receiveProjectDashboards(projectId, projectDashboards));
}

export function* updateProjectDashboardAsync(action) {
  const { projectId, projectDashboardId, payload } = action;

  const projectDashboard = yield call(
    projectDashboardService.updateProjectDashboard,
    projectId,
    projectDashboardId,
    payload,
  );

  yield put(
    receiveProjectDashboard(projectId, projectDashboardId, projectDashboard),
  );
}

export default function* root() {
  yield takeLatest(
    DASHBOARDS_ACTIONS.REQUEST_PROJECT_DASHBOARDS,
    callAsync,
    requestProjectDashboardsAsync,
  );

  yield takeLatest(
    DASHBOARDS_ACTIONS.REQUEST_DASHBOARDS,
    callAsync,
    requestDashboardsAsync,
  );

  yield takeLatest(
    DASHBOARDS_ACTIONS.TOGGLE_PROJECT_DASHBOARD,
    callAsync,
    toggleProjectDashboardAsync,
  );

  yield takeLatest(
    DASHBOARDS_ACTIONS.TOGGLE_PROJECT_DASHBOARDS,
    callAsync,
    toggleProjectDashboardsAsync,
  );

  yield takeLatest(
    DASHBOARDS_ACTIONS.UPDATE_PROJECT_DASHBOARD,
    callAsync,
    updateProjectDashboardAsync,
  );

  yield takeLatest(
    DASHBOARDS_ACTIONS.REQUEST_PROJECT_DASHBOARD,
    callAsync,
    requestProjectDashboardAsync,
  );
}
