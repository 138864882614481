/* eslint-disable */
/** disabling eslint to ignore exhaustive deps check as it was causing redundant hook execution
 * and gave out of memory exception
 */
import {
  between,
  convertMetric,
  distanceFromCenter,
  usePrevious,
} from 'app/components/WellboreTrajectoryDetailed3DView/Utils';
import Toolstring from 'app/components/WellboreTrajectoryDetailed3DView/Toolstring';
import Nipple from 'app/components/WellboreTrajectoryDetailed3DView/Nipple';
import Loader from 'app/components/WellboreTrajectoryDetailed3DView/Loader';
import Pipe from 'app/components/WellboreTrajectoryDetailed3DView/Pipe';
import BHA from 'app/components/WellboreTrajectoryDetailed3DView/BHA';
import React, { useRef, useEffect, useState, Suspense } from 'react';
import { EMPTY_LIST, EMPTY_QUANTITY } from 'app/app.constants';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

/** Commented lines in this component include code for mocking currentDepth and
 * hence have not removed them for now */
const WellboreTrajectoryDetailed3DView = ({
  trajectory,
  // targetDepth,
  cDepth,
  wellboreSections,
  BHA_LENGTH,
  maxOD,
  toolsByToolstringToolId,
}) => {
  const wellboreRef = useRef();
  const groupRef = useRef();
  const pipeGroupRef = useRef();
  const pipes = useRef([]);
  const nipples = useRef([]);
  const { scene } = useThree();

  const trajectoryPoints = trajectory.get('trajectoryPoints', EMPTY_LIST);

  const [radius, setRadius] = useState(null);
  const [pipeSections, setPipeSections] = useState(0);
  const [currentDepth, setCurrentDepth] = useState(EMPTY_QUANTITY);
  const previousDepth = usePrevious(
    convertMetric(
      currentDepth.value ? currentDepth.value : 0,
      currentDepth.unit ? currentDepth.unit : cDepth.unit,
    ),
  );

  const [inclination, setInclination] = useState(EMPTY_QUANTITY);

  useEffect(() => {
    setCurrentDepth(cDepth);
  }, [cDepth]);

  useEffect(() => {
    if (wellboreSections)
      wellboreSections.forEach((value, key) => {
        // adding pipe at default position when current depth is null.
        if (!currentDepth.hasValue || !currentDepth.value) {
          pipes.current.push(
            <Pipe
              key={key}
              radius={
                convertMetric(
                  value.get('innerDiameter').roundedValue,
                  value.get('innerDiameter').unit,
                ) / 2
              }
              outerRadius={
                convertMetric(
                  value.get('innerDiameter').roundedValue,
                  value.get('innerDiameter').unit,
                ) /
                  2 +
                0.02
              }
              position={[0, 0, 0]}
              length={
                convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) * 3
              }
            />,
          );
        } else if (
          currentDepth.hasValue &&
          between(
            convertMetric(currentDepth.value, currentDepth.unit),
            convertMetric(value.get('top').roundedValue, value.get('top').unit),
            convertMetric(
              value.get('bottom').roundedValue,
              value.get('bottom').unit,
            ),
          ) &&
          value.get('innerDiameter').hasValue
        ) {
          setPipeSections(
            Math.floor(
              (convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) * 3) /
                (convertMetric(currentDepth.value, currentDepth.unit) -
                  previousDepth),
            ),
          );
          setRadius(
            convertMetric(
              value.get('innerDiameter').roundedValue,
              value.get('innerDiameter').unit,
            ) / 2,
          );
          for (const nippleValue of value
            .get('wellboreSectionNipples')
            .values()) {
            if (
              nippleValue.get('isNipple') &&
              (between(
                convertMetric(
                  nippleValue.get('top').roundedValue,
                  nippleValue.get('top').unit,
                ),
                convertMetric(currentDepth.value, currentDepth.unit),
                convertMetric(currentDepth.value, currentDepth.unit) +
                  convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) * 2,
              ) ||
                between(
                  convertMetric(
                    nippleValue.get('top').roundedValue,
                    nippleValue.get('top').unit,
                  ),
                  convertMetric(currentDepth.value, currentDepth.unit) -
                    convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) * 2,
                  convertMetric(currentDepth.value, currentDepth.unit),
                ))
            ) {
              let object = scene.getObjectByName(
                `Nipple-${nippleValue.get('wellboreSectionNippleId')}`,
              );
              const data = {
                hasValue: true,
                length: convertMetric(
                  nippleValue.get('length').roundedValue,
                  nippleValue.get('length').unit,
                ),
                width:
                  convertMetric(
                    nippleValue.get('innerDiameter').roundedValue,
                    nippleValue.get('innerDiameter').unit,
                  ) / 2,
                offset:
                  convertMetric(
                    value.get('innerDiameter').roundedValue,
                    value.get('innerDiameter').unit,
                  ) / 2,
                positionX:
                  convertMetric(
                    nippleValue.get('top').roundedValue,
                    nippleValue.get('top').unit,
                  ) - currentDepth.value,
              };
              if (!object) {
                nipples.current.push(
                  <Nipple
                    key={nippleValue.get('wellboreSectionNippleId')}
                    id={nippleValue.get('wellboreSectionNippleId')}
                    position={[data.positionX, 0, 0]}
                    outerRadius={data.offset}
                    innerRadius={data.width}
                    length={data.length}
                  />,
                );
              } else {
                object.position.x = data.positionX;
              }
            } else {
              if (nipples.current.length)
                nipples.current = nipples.current.filter(
                  (nipple) =>
                    Number(nipple.key) !==
                    nippleValue.get('wellboreSectionNippleId'),
                );
              let object = scene.getObjectByName(
                `Nipple-${nippleValue.get('wellboreSectionNippleId')}`,
              );
              if (object) {
                scene.remove(object);
                object = null;
              }
            }
          }
        }
      });
  }, [wellboreSections, currentDepth]);

  useEffect(() => {
    if (
      pipeSections &&
      pipeSections > 0 &&
      convertMetric(currentDepth.value, currentDepth.unit) - previousDepth !== 0
    ) {
      pipes.current = [];
      for (let i = 0; i < pipeSections; i++) {
        let distance = 0,
          x = 0;
        if (i < Math.floor(pipeSections / 2)) {
          distance = distanceFromCenter(Math.ceil(pipeSections / 2), i);
          x =
            -(
              convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) / 2 +
              convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit)
            ) +
            (convertMetric(currentDepth.value, currentDepth.unit) -
              previousDepth) *
              distance;
        } else {
          distance = distanceFromCenter(i, Math.floor(pipeSections / 2));
          x =
            convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) / 2 +
            convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) -
            (convertMetric(currentDepth.value, currentDepth.unit) -
              previousDepth) *
              distance;
        }
        pipes.current.push(
          <Pipe
            key={i}
            pipeGroupRef={pipeGroupRef}
            radius={radius}
            outerRadius={radius + 0.02}
            position={[x, 0, 0]}
            length={
              convertMetric(currentDepth.value, currentDepth.unit) -
              previousDepth
            }
            currentDepth={convertMetric(currentDepth.value, currentDepth.unit)}
            previousDepth={previousDepth}
          />,
        );
      }
    } else {
      if (wellboreSections) {
        wellboreSections.forEach((value, key) => {
          if (
            currentDepth.hasValue &&
            between(
              convertMetric(currentDepth.value, currentDepth.unit),
              convertMetric(
                value.get('top').roundedValue,
                value.get('top').unit,
              ),
              convertMetric(
                value.get('bottom').roundedValue,
                value.get('bottom').unit,
              ),
            ) &&
            value.get('innerDiameter').hasValue
          ) {
            pipes.current.push(
              <Pipe
                key={key}
                radius={
                  convertMetric(
                    value.get('innerDiameter').roundedValue,
                    value.get('innerDiameter').unit,
                  ) / 2
                }
                outerRadius={
                  convertMetric(
                    value.get('innerDiameter').roundedValue,
                    value.get('innerDiameter').unit,
                  ) /
                    2 +
                  0.02
                }
                position={[
                  -convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) *
                    2.5,
                  0,
                  0,
                ]}
                length={
                  convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) * 3
                }
              />,
            );
          }
        });
      }
    }
  }, [pipeSections, currentDepth]);

  useEffect(() => {
    if (pipeGroupRef && pipeGroupRef.current) {
      if (
        convertMetric(currentDepth.value, currentDepth.unit) - previousDepth >
          0 &&
        pipeGroupRef.current.position.x >
          -(
            2 *
            (convertMetric(currentDepth.value, currentDepth.unit) -
              previousDepth)
          )
      ) {
        pipeGroupRef.current.position.x -=
          convertMetric(currentDepth.value, currentDepth.unit) - previousDepth;
      } else {
        pipeGroupRef.current.position.x = 0;
      }
      if (
        groupRef &&
        groupRef.current &&
        groupRef.current.rotation.z !==
          THREE.MathUtils.degToRad(inclination.value)
      ) {
        groupRef.current.rotation.z = THREE.MathUtils.degToRad(
          inclination.value,
        );
      }
    }
  }, [currentDepth, pipeGroupRef, groupRef, inclination.value]);

  useEffect(() => {
    trajectoryPoints.forEach((previousPoint, index) => {
      const currentPoint = trajectoryPoints.get(index + 1);

      // Bottom reached
      if (!currentPoint) return false;

      if (
        between(
          convertMetric(currentDepth.value, currentDepth.unit),
          convertMetric(
            previousPoint.getIn(['measuredDepth', 'value']),
            previousPoint.getIn(['measuredDepth', 'unit']),
          ),
          convertMetric(
            currentPoint.getIn(['measuredDepth', 'value']),
            currentPoint.getIn(['measuredDepth', 'unit']),
          ),
        )
      ) {
        setInclination({
          unit: 'degree',
          value: currentPoint.get('inclination'),
          hasValue: true,
        });
      }
    });
  }, [currentDepth, trajectoryPoints]);

  return (
    <Suspense fallback={<Loader />}>
      <group ref={wellboreRef} name="WELLBORE_STRUCTURE">
        <group rotation={[0, 0, -Math.PI / 2]}>
          <group ref={groupRef}>
            <group
              position={[
                convertMetric(BHA_LENGTH.roundedValue, BHA_LENGTH.unit) / 2,
                0,
                0,
              ]}
            >
              {nipples && nipples.current.length ? (
                <>{nipples.current}</>
              ) : null}
              {pipes && pipes.current.length ? (
                <>
                  <group ref={pipeGroupRef}>{pipes.current}</group>
                  <BHA
                    BHA_LENGTH={BHA_LENGTH}
                    maxOD={maxOD}
                    toolsByToolstringToolId={toolsByToolstringToolId}
                  />
                  <Toolstring BHA_LENGTH={BHA_LENGTH} />
                </>
              ) : null}
            </group>
          </group>
        </group>
      </group>
    </Suspense>
  );
};

export default WellboreTrajectoryDetailed3DView;
