import { useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { fromJS } from 'immutable';
import { formatDate } from 'utils/format.util';
import { ProjectPermission } from 'app/app.constants';
import ReadOnlyField from 'app/components/ReadOnlyField';
import TableRowExpandable from 'app/components/TableRowExpandable';
import { useProjectPermissions } from 'app/hooks/authorization/useProjectPermissions';
import { useTaskActivities } from 'features/projects/activities/hooks/useTaskActivities';
import { EditorState, convertFromRaw } from 'draft-js';
import EditorStateViewer from 'app/components/EditorStateViewer';
import TaskActivitiesTableWithDrawer from 'features/projects/tasks/task/activities/TaskActivitiesTableWithDrawer';

const ReportTableRow = ({ item: task, ...rest }) => {
  const taskId = task.get('taskId');

  const activities = useTaskActivities(taskId);

  const comments = task.get('comments')
    ? EditorState.createWithContent(
        convertFromRaw(JSON.parse(task.get('comments'))),
      )
    : EditorState.createEmpty();

  const { hasPermission: hasReadActivityCommentsPermission } =
    useProjectPermissions(ProjectPermission.READ_ACTIVITY_COMMENTS_REPORT);

  const renderDetails = useCallback(
    (isDoRender) => {
      return isDoRender ? (
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                {task.get('comments') && (
                  <Typography variant="caption" color="textSecondary">
                    <EditorStateViewer editorState={comments} />
                  </Typography>
                )}
              </Grid>
              <Grid container item spacing={2}>
                {task.get('startTime') && (
                  <Grid item>
                    <ReadOnlyField
                      label="Started"
                      value={formatDate(task.get('startTime'))}
                    />
                  </Grid>
                )}
                {task.get('endTime') && (
                  <Grid item>
                    <ReadOnlyField
                      label="Ended"
                      value={formatDate(task.get('endTime'))}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container item>
                <TaskActivitiesTableWithDrawer
                  useGlobalFilter={false}
                  activities={arrangeActivities(activities?.toJS())}
                  displayComments={hasReadActivityCommentsPermission}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null;
    },
    [activities, task, comments, hasReadActivityCommentsPermission],
  );

  return <TableRowExpandable {...rest} renderDetails={renderDetails} />;
};

export default ReportTableRow;

const arrangeActivities = (activities) => {
  if (activities) {
    Object.values(activities).forEach((activity) => {
      if (!activity.subActivities) activity.subActivities = [];
      if (activity.isPointInTime && activity.parentActivityId) {
        const parentActivityIndex = Object.values(activities).findIndex(
          (act) => act.id === activity.parentActivityId,
        );
        if (parentActivityIndex !== -1) {
          Object.values(activities)[parentActivityIndex]['subActivities'].push(
            activity,
          );

          delete activities[activity.id];
        }
      }
    });
    const sortedData = Object.values(activities).sort(
      (a, b) => a.sequence - b.sequence,
    );

    sortedData.forEach((item, index) => {
      item.sequence = index + 1;
    });
    return fromJS(sortedData);
  }
  return null;
};
