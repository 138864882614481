import { compose } from 'redux';
import { alpha } from '@material-ui/core/styles';
import { useEffect, useRef, useMemo } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, DialogContent, Fade } from '@material-ui/core';

import { scrollToTopSmoothly } from 'utils/app.util';
import ThreeJsManagerProvider from 'app/threeJs/ThreeJsManagerProvider';
import WellboreTrajectoryContainer from 'app/components/WellboreTrajectory/WellboreTrajectoryContainer';
import CreateProjectModalMap from 'features/projects/components/CreateProjectModalContainer/CreateProjectModalMap';
import CreateProjectModalContentItem from 'features/projects/components/CreateProjectModalContainer/CreateProjectModalContentItem';

const CreateProjectModalContent = ({
  classes,
  fieldId,
  wellbore,
  wellboreId,
  activeItem,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;

    scrollToTopSmoothly(ref.current);
  }, []);

  const wellboreTrajectoryClasses = useMemo(
    () => ({
      root: classes.wellboreTrajectoryRoot,
    }),
    [classes],
  );

  return (
    <Grid item xs component={DialogContent} className={classes.root}>
      <CreateProjectModalMap wellbore={wellbore} />
      <ThreeJsManagerProvider>
        <WellboreTrajectoryContainer
          fieldId={fieldId}
          wellboreId={wellboreId}
          classes={wellboreTrajectoryClasses}
        />
      </ThreeJsManagerProvider>
      <Fade timeout={0} in={!!activeItem}>
        <Grid
          ref={ref}
          container
          justifyContent="center"
          className={classes.modalContent}
        >
          {activeItem && (
            <CreateProjectModalContentItem activeItem={activeItem} fieldId={fieldId} />
          )}
        </Grid>
      </Fade>
    </Grid>
  );
};

const styles = (theme) => ({
  root: {
    padding: 0,
    position: 'relative',
  },
  modalContent: {
    top: 0,
    left: 0,
    bottom: 0,
    overflow: 'auto',
    position: 'absolute',
    padding: theme.spacing(6),
    background: `${theme.palette.common.black}0004d`,
  },
  wellboreTrajectoryRoot: {
    top: 0,
    left: 0,
    bottom: 0,
    position: 'absolute',
    background: alpha(theme.palette.grey[900], 0.8),
  },
});

export default compose(withStyles(styles))(CreateProjectModalContent);
