import React from 'react';
import { Box, Typography, withStyles, LinearProgress } from '@material-ui/core';

const HorizontalProgressContainer = withStyles({
  root: {
    height: '20px',
    width: '100%',
    background: 'linear-gradient(to left, #00f, #09f)',
    position: 'relative',
  },
})(Box);

const Marker = withStyles({
  root: {
    position: 'absolute',
    top: '0',
    height: '20px',
    width: '4px',
    backgroundColor: '#fff',
  },
})(Box);

const IntegrityMatrixComplianceComponent = ({ value }) => {
  const markerPosition = `${value}%`;

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Typography
        style={{ color: '#fff', fontSize: '16px', marginBottom: '8px' }}
      >
        Compliance
      </Typography>
      <HorizontalProgressContainer>
        <Marker style={{ left: markerPosition }} />
        <Typography
          style={{
            position: 'absolute',
            top: '50%',
            left: `calc(${markerPosition} + 10px)`,
            transform: 'translateY(-50%)',
            color: '#fff',
            fontSize: '14px',
          }}
        >
          {value}%
        </Typography>
      </HorizontalProgressContainer>
      <Typography style={{ color: '#fff', fontSize: '12px', marginTop: '8px' }}>
        * Document(s) is required in order to be compliant.
      </Typography>
    </Box>
  );
};

export default IntegrityMatrixComplianceComponent;
