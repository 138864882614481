import { isNil } from 'lodash';
import { getIn } from 'formik';
import { List } from 'immutable';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { useRequestWithDataState } from 'altus-hooks';

import mappers from 'mappers';
import appService from 'services/app.service';
import { EMPTY_LIST } from 'app/app.constants';
import { isClientOrganization } from 'app/app.selectors';
import { useCurrentUser } from 'app/hooks/useCurrentUser';
import objectiveService from 'services/objective.service';
import { CreateProjectFormFields as FormFields } from 'features/projects/projects.constants';
import CreateProjectModalAppBar from 'features/projects/components/CreateProjectModalContainer/CreateProjectModalAppBar';
import CreateProjectModalActions from 'features/projects/components/CreateProjectModalContainer/CreateProjectModalActions';
import CreateProjectModalContent from 'features/projects/components/CreateProjectModalContainer/CreateProjectModalContent';

const getItemIdDefault = (item) => item.get('id');
const getItemLabelDefault = (item) => item.get('name');

const CreateProjectModalForm = ({
  title,
  values,
  isValid,
  onClose,
  resetForm,
  submitForm,
  isSubmitting,
  setFieldValue,
  initialValues,
}) => {
  const currentUser = useCurrentUser();
  // items will be initialized below, but is defined here to be available in the setNextActiveItemType callback
  let items;

  const [currentIndex, setCurrentIndex] = useState();

  const [fields, setFields] = useState(EMPTY_LIST);
  const [wellbores, setWellbores] = useState(EMPTY_LIST);
  const [facilities, setFacilities] = useState(EMPTY_LIST);
  const [objectives, setObjectives] = useState(EMPTY_LIST);
  const [organizations, setOrganizations] = useState(EMPTY_LIST);

  const initialFieldId = getIn(initialValues, FormFields.FIELD_ID);
  const initialWellboreId = getIn(initialValues, FormFields.WELLBORE_ID);
  const initialFacilityId = getIn(initialValues, FormFields.FACILITY_ID);
  const initialOrganizationId = getIn(
    initialValues,
    FormFields.ORGANIZATION_ID,
  );

  const selectedFieldId = getIn(values, FormFields.FIELD_ID);
  const selectedWellboreId = getIn(values, FormFields.WELLBORE_ID);
  const selectedOrganizationId = getIn(values, FormFields.ORGANIZATION_ID);

  const getOrganizationsBySelectedField = useCallback(() => {
    const request = selectedFieldId
      ? appService.getOrganizationsByField(selectedFieldId)
      : appService.getAllOrganizations();

    return request.then((organizations) =>
      setOrganizations(List(organizations).map(mappers.Organization.from)),
    );
  }, [selectedFieldId]);

  const [getOrganizations, getOrganizationsDataState] = useRequestWithDataState(
    getOrganizationsBySelectedField,
  );

  const [getWellbores, getWellboresDataState] = useRequestWithDataState(
    appService.wellboresByFieldId,
  );

  const [getFields, getFieldsDataState] = useRequestWithDataState(
    appService.getFields,
  );

  const [getObjectivesWithDataState, getObjectivesDataState] =
    useRequestWithDataState(objectiveService.getObjectives);

  const [getFacilitiesByFieldId, getFacilitiesByFieldIdDataState] =
    useRequestWithDataState(appService.facilitiesByFieldId);

  const clearFormFields = useCallback(
    (...names) => {
      names.forEach((name) => {
        const initialValue = getIn(initialValues, name);

        if (!initialValue) {
          setFieldValue(name, initialValue);
        }
      });
    },
    [setFieldValue, initialValues],
  );

  const setNextActiveIndex = useCallback(() => {
    setCurrentIndex((currentIndex) =>
      items.findIndex(
        (item, index) =>
          index !== currentIndex && !getIn(values, item.names.id),
      ),
    );
  }, [items, values]);

  const onSelectOrganization = useCallback(() => {
    clearFormFields(
      FormFields.FIELD_ID,
      FormFields.FACILITY_ID,
      FormFields.WELLBORE_ID,
    );

    setNextActiveIndex();
  }, [clearFormFields, setNextActiveIndex]);

  const onSelectField = useCallback(() => {
    clearFormFields(FormFields.FACILITY_ID, FormFields.WELLBORE_ID);
    setNextActiveIndex();
  }, [clearFormFields, setNextActiveIndex]);

  const onFocusOrganization = useCallback(() => {
    clearFormFields(
      FormFields.FIELD_ID,
      FormFields.FACILITY_ID,
      FormFields.WELLBORE_ID,
    );
  }, [clearFormFields]);

  const onFocusField = useCallback(() => {
    clearFormFields(FormFields.FACILITY_ID, FormFields.WELLBORE_ID);
  }, [clearFormFields]);

  const getFieldsByOrganization = useCallback(() => {
    getFields({ organizationId: selectedOrganizationId }).then((fields) =>
      setFields(List(fields).map(mappers.Field.from)),
    );
  }, [getFields, selectedOrganizationId]);

  const getWellboresByField = useCallback(() => {
    getWellbores(selectedFieldId).then((wellbores) =>
      setWellbores(List(wellbores).map(mappers.Wellbore.from)),
    );
  }, [getWellbores, selectedFieldId]);

  const getFacilitiesByField = useCallback(() => {
    getFacilitiesByFieldId(selectedFieldId).then((facilities) =>
      setFacilities(List(facilities).map(mappers.Facility.from)),
    );
  }, [getFacilitiesByFieldId, selectedFieldId]);

  const getObjectives = useCallback(() => {
    getObjectivesWithDataState().then((objectives) =>
      setObjectives(List(objectives).map(mappers.Objective.from)),
    );
  }, [getObjectivesWithDataState]);

  const isOrganizationVisible = currentUser.get('isMainVendor');

  const organizationItem = useMemo(
    () => ({
      label: 'Organization',
      onLoad: getOrganizations,
      onFocus: onFocusOrganization,
      onClick: onSelectOrganization,
      isVisible: isOrganizationVisible,
      disabled: !!initialOrganizationId,
      dataState: getOrganizationsDataState,
      noItemsMessage: 'No organizations available',
      data: organizations.filter(isClientOrganization),
      names: {
        id: FormFields.ORGANIZATION_ID,
        search: FormFields.ORGANIZATION_NAME,
      },
    }),
    [
      organizations,
      getOrganizations,
      onFocusOrganization,
      onSelectOrganization,
      isOrganizationVisible,
      initialOrganizationId,
      getOrganizationsDataState,
    ],
  );

  const fieldDisabled =
    (isOrganizationVisible && !selectedOrganizationId) || !!initialFieldId;

  const fieldItem = useMemo(
    () => ({
      data: fields,
      label: 'Field',
      onFocus: onFocusField,
      onClick: onSelectField,
      disabled: fieldDisabled,
      dataState: getFieldsDataState,
      onLoad: getFieldsByOrganization,
      noItemsMessage: 'No fields available for this organization',
      names: {
        id: FormFields.FIELD_ID,
        search: FormFields.FIELD_NAME,
      },
    }),
    [
      fields,
      onFocusField,
      onSelectField,
      fieldDisabled,
      getFieldsDataState,
      getFieldsByOrganization,
    ],
  );

  const wellboreItem = useMemo(
    () => ({
      data: wellbores,
      label: 'Wellbore',
      onLoad: getWellboresByField,
      onClick: setNextActiveIndex,
      dataState: getWellboresDataState,
      itemDisabledTitle: 'Missing survey',
      groupBy: (wellbore) => wellbore.get('wellName'),
      disabled: !selectedFieldId || !!initialWellboreId,
      noItemsMessage: 'No wellbores available for this field',
      isItemDisabled: (wellbore) => !wellbore.get('trajectoryUpdated'),
      names: {
        id: FormFields.WELLBORE_ID,
        search: FormFields.WELLBORE_NAME,
      },
    }),
    [
      wellbores,
      selectedFieldId,
      initialWellboreId,
      setNextActiveIndex,
      getWellboresByField,
      getWellboresDataState,
    ],
  );

  const facilityItem = useMemo(
    () => ({
      data: facilities,
      label: 'Facility',
      onClick: setNextActiveIndex,
      onLoad: getFacilitiesByField,
      dataState: getFacilitiesByFieldIdDataState,
      disabled: !selectedFieldId || !!initialFacilityId,
      noItemsMessage: 'No facilities available for this field',
      groupBy: (facility) => facility.get('typeName'),
      names: {
        id: FormFields.FACILITY_ID,
        search: FormFields.FACILITY_NAME,
      },
    }),
    [
      facilities,
      selectedFieldId,
      initialFacilityId,
      setNextActiveIndex,
      getFacilitiesByField,
      getFacilitiesByFieldIdDataState,
    ],
  );

  const objectiveItem = useMemo(
    () => ({
      data: objectives,
      label: 'Main Objective',
      onLoad: getObjectives,
      onClick: setNextActiveIndex,
      dataState: getObjectivesDataState,
      noItemsMessage: 'No objectives available',
      getItemLabel: (objective) => objective.get('title'),
      getItemId: (objective) => objective.get('objectiveId'),
      names: {
        id: FormFields.OBJECTIVE_ID,
        search: FormFields.OBJECTIVE_NAME,
      },
    }),
    [objectives, getObjectives, setNextActiveIndex, getObjectivesDataState],
  );

  items = useMemo(
    () =>
      [organizationItem, fieldItem, wellboreItem, facilityItem, objectiveItem]
        .filter((item) => item.isVisible ?? true)
        .map((item) => ({
          ...item,
          // for now, the data signature is the same on all data (id, name), so we will just use the default getters for all items
          getItemId: item.getItemId ?? getItemIdDefault,
          getItemLabel: item.getItemLabel ?? getItemLabelDefault,
        })),
    [organizationItem, fieldItem, wellboreItem, facilityItem, objectiveItem],
  );

  const activeItem = items[currentIndex];

  useEffect(
    () => () => {
      // reset form and current index when closed
      resetForm();
      setCurrentIndex();
    },
    [resetForm, setCurrentIndex],
  );

  useEffect(() => {
    if (isNil(currentIndex)) {
      setNextActiveIndex();
    }
  }, [currentIndex, setNextActiveIndex]);

  const selectedWellbore = useMemo(
    () =>
      wellbores
        .toMap()
        .mapKeys((_, wellbore) => wellbore.get('id'))
        .get(selectedWellboreId),
    [wellbores, selectedWellboreId],
  );

  return (
    <>
      <CreateProjectModalAppBar
        title={title}
        items={items}
        toggleModal={onClose}
        activeItem={activeItem}
        currentIndex={currentIndex}
        clearFields={clearFormFields}
        setCurrentIndex={setCurrentIndex}
      />
      <CreateProjectModalContent
        activeItem={activeItem}
        fieldId={selectedFieldId}
        wellbore={selectedWellbore}
        wellboreId={selectedWellboreId}
      />
      <CreateProjectModalActions
        onClose={onClose}
        isValid={isValid}
        createProject={submitForm}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default CreateProjectModalForm;
