import React from 'react';
import { Field } from 'formik';

import { Grid } from '@material-ui/core';

import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';

const CableWrapBraidedMonoTestFormComponent = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} container>
          <Field
            type="number"
            destroyOnUnmount
            label="Inner Strand Pass X"
            component={TextFieldFormik}
            name={
              AssetHistoryForm.CABLE_WRAP_BRAIDED_MONO_TEST_INNER_STRAND_PASS_X
            }
          />
        </Grid>
        <Grid item xs={6} container>
          <Field
            type="number"
            destroyOnUnmount
            label="Inner Strand Out of Y"
            component={TextFieldFormik}
            name={
              AssetHistoryForm.CABLE_WRAP_BRAIDED_MONO_TEST_INNER_STRAND_OUT_OF_Y
            }
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6} container>
            <Field
              type="number"
              destroyOnUnmount
              label="Outer Strand Pass X"
              component={TextFieldFormik}
              name={
                AssetHistoryForm.CABLE_WRAP_BRAIDED_MONO_TEST_OUTER_STRAND_PASS_X
              }
            />
          </Grid>
          <Grid item xs={6} container>
            <Field
              type="number"
              destroyOnUnmount
              label="Outer Strand Out of Y"
              component={TextFieldFormik}
              name={
                AssetHistoryForm.CABLE_WRAP_BRAIDED_MONO_TEST_OUTER_STRAND_OUT_OF_Y
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CableWrapBraidedMonoTestFormComponent;
