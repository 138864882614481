import { useEffect } from 'react';
import Add from '@material-ui/icons/Add';
import { Box, Grid } from '@material-ui/core';

import { useModal } from 'altus-modal';
import { BasePage } from 'altus-ui-components';

import { CREATE_SMLOCATION_MODAL } from 'features/data-exchange/winch/smlocation.constants';
import { Button } from 'app/components/withNavigation';
import useSMLocations from 'features/data-exchange/hooks/useSMLocations';
import CreateSMLocationModalContainer from './CreateSMLocationModalContainer';
import SMLocationConfigsTable from 'features/data-exchange/components/SMLocationConfigsTable';

const ConfigurationWinchContainer = ({ breadcrumb }) => {
  const {
    dataState,
    smLocations,
    getSMLocations,
    createSMLocation,
    updateSMLocation,
  } = useSMLocations();

  useEffect(() => getSMLocations(), [getSMLocations]);

  const [, toggleAddSMLocationModal] = useModal(CREATE_SMLOCATION_MODAL);

  return (
    <BasePage dataState={dataState} title={breadcrumb}>
      <Grid component={Box} marginBottom={1}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          title="Add device"
          onClick={toggleAddSMLocationModal}
        >
          <Box component={Add} marginRight={0.5} />
          Add
        </Button>
      </Grid>

      <CreateSMLocationModalContainer createSMLocation={createSMLocation} />
      <SMLocationConfigsTable
        smLocations={smLocations}
        updateSMLocation={updateSMLocation}
      />
    </BasePage>
  );
};

export default ConfigurationWinchContainer;
