import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  getWellboreByIdFromState,
  getWellboreDetailByIdFromState,
} from 'features/wells/wellbore.selectors';

import { EMPTY_MAP } from 'app/app.constants';

const useWellbore = (wellboreId) => {
  const wellboreSelector = useMemo(
    () => getWellboreByIdFromState(wellboreId),
    [wellboreId],
  );

  const wellbore = useSelector(wellboreSelector) ?? EMPTY_MAP;

  const wellboreDetailSelector = useMemo(
    () => getWellboreDetailByIdFromState(wellbore.get('wellboreDetailId')),
    [wellbore],
  );

  const wellboreDetail =
    useSelector(wellboreDetailSelector) ?? wellbore.get('wellboreDetail');

  return {
    wellbore,
    wellboreDetail,
  };
};

export default useWellbore;
