import { compose } from 'redux';
import { RouteContainer } from 'altus-ui-components';
import routePaths from 'app/routePaths';
import MobileProjectHeader from 'app/components/ApplicationHeader/Mobile/MobileProjectHeader';
import MobileProjectsListHeader from 'app/components/ApplicationHeader/Mobile/MobileProjectsListHeader';
import MobilePrivacyPolicyHeader from 'app/components/ApplicationHeader/Mobile/MobilePrivacyPolicyHeader';
import DefaultMobileAppHeader from 'app/components/ApplicationHeader/Mobile/DefaultMobileAppHeader';

const MobileApplicationHeader = () => {
  const routes = [
    {
      path: routePaths.details,
      component: MobileProjectHeader,
    },
    {
      path: '/projects/:projectId/status',
      component: MobileProjectHeader,
    },
    {
      path: '/projects/:projectId/execution',
      component: MobileProjectHeader,
    },
    {
      path: '/projects/:projectId/execution/:taskId/:section',
      component: MobileProjectHeader,
    },
    {
      path: routePaths.wells.index,
      // component: WellboreHeader,
    },
    {
      path: routePaths.facilities.index,
      // component: FacilityHeader,
    },
    {
      path: routePaths.login,
      component: null,
    },
    {
      path: routePaths.privacyPolicy,
      component: MobilePrivacyPolicyHeader,
    },
    {
      path: [routePaths.projects],
      component: MobileProjectsListHeader,
    },
  ];

  return (
    <RouteContainer
      routes={routes}
      MissingRouteComponent={DefaultMobileAppHeader}
    />
  );
};

export default compose()(MobileApplicationHeader);
