import { compose } from 'redux';
import { List } from 'immutable';
import { useEffect, useMemo } from 'react';
import { getIn, useFormikContext } from 'formik';
import withStyles from '@material-ui/styles/withStyles';
import { Box, Grid, Typography } from '@material-ui/core';

import { useTextSearch } from 'altus-hooks';
import { LoadingOverlay } from 'altus-ui-components';
import { NotRequestedDataState } from 'altus-datastate';

import { invokeIfFunction } from 'utils/app.util';
import CreateProjectModalListGroup from 'features/projects/components/CreateProjectModalContainer/CreateProjectModalListGroup';

const GROUPS = {
  RELATED_FACILITY: 'Related Facility',
  FIXED: 'Fixed',
  MOBILE: 'Mobile',
};

const defaultGroupBy = (item, getItemLabel) => getItemLabel(item).charAt(0);

const CreateProjectModalContentItem = ({ classes, activeItem, fieldId }) => {
  const { values } = useFormikContext();

  const {
    data,
    names,
    label,
    onLoad,
    loadingText,
    getItemLabel,
    noItemsMessage,
    groupBy = defaultGroupBy,
    dataState = NotRequestedDataState,
  } = activeItem;

  const { id, search } = names;

  useEffect(() => {
    invokeIfFunction(onLoad);
  }, [onLoad]);

  const selectedItemId = getIn(values, id);
  const itemSearch = getIn(values, search);

  const { setSearch, matchingItems, clearSearch } = useTextSearch(
    data,
    getItemLabel,
  );

  useEffect(() => {
    if (itemSearch) {
      setSearch(itemSearch);
    }

    return () => clearSearch();
  }, [setSearch, selectedItemId, itemSearch, clearSearch]);

  const dataItems = List(matchingItems);

  const customGroupBy = useMemo(() => {
    return (item) => {
      if (item.get('fieldId') === fieldId) return GROUPS.RELATED_FACILITY;
      if (item.get('typeName') === GROUPS.FIXED) return GROUPS.FIXED;
      if (item.get('typeName') === GROUPS.MOBILE) return GROUPS.MOBILE;
      return 'Unknown';
    };
  }, [fieldId]);

  let groupedDataItems;

  const groupedItems = useMemo(
    () =>
      dataItems
        .groupBy((item) => groupBy(item, getItemLabel))
        .sortBy((_, key) => key),
    [groupBy, dataItems, getItemLabel],
  );

  const groupedFacilityItems = useMemo(
    () =>
      dataItems.groupBy(customGroupBy).sortBy((_, key) => {
        if (key === GROUPS.RELATED_FACILITY) return 0;
        if (key === GROUPS.FIXED) return 1;
        if (key === GROUPS.MOBILE) return 2;
        return 3;
      }),
    [customGroupBy, dataItems],
  );

  if (label !== 'Facility') {
    groupedDataItems = groupedItems;
  } else {
    groupedDataItems = groupedFacilityItems;
  }

  if (dataState.isLoading()) {
    return <LoadingOverlay dataState={dataState} text={loadingText} />;
  }

  if (!data.size) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        {noItemsMessage}
      </Grid>
    );
  }

  return (
    <Grid item xs={8}>
      <Typography variant="h5" className={classes.title}>
        {`Select ${label}`}
      </Typography>
      {!dataItems.size && (
        <Grid component={Box} padding={2.5}>
          <Typography variant="caption">
            No items matching your search
          </Typography>
        </Grid>
      )}
      <Grid container>
        {groupedDataItems
          .map((items, key) => (
            <CreateProjectModalListGroup
              key={key}
              title={key}
              data={items}
              visibleItem={activeItem}
            />
          ))
          .valueSeq()}
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  title: {
    padding: `0 ${theme.spacing(2.5)}px`,
  },
});

export default compose(withStyles(styles))(CreateProjectModalContentItem);
