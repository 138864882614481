export const EQUIPMENT_DETAILS_ACTIONS = {
  PAGE_LOADED: 'PAGE_LOADED',
  UNLOAD_TOOL: 'UNLOAD_TOOL',
  UNLOAD_CABLE: 'UNLOAD_CABLE',
};

export const SURFACE_EQUIPMENT_DETAILS_ACTIONS = {
  PAGE_LOADED: 'SURFACE_EQUIPMENT_DETAILS_PAGE_LOADED',
  UNLOAD_TOOL: 'SURFACE_EQUIPMENT_DETAILS_UNLOAD_TOOL',
};

export const EQUIPMENT_DETAILS_ACTIVE_TABS = {
  ASSETS: 'EQUIPMENT_ASSETS_TAB',
  DETAILS: 'EQUIPMENT_DETAILS_TAB',
};
