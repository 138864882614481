import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Grid, CircularProgress } from '@material-ui/core';

import { getSummarizedDataState } from 'altus-datastate';

import { EMPTY_MAP } from 'app/app.constants';
import WellboreTrajectory from 'app/components/WellboreTrajectory/WellboreTrajectory';
import useProjectWellboreTrajectory from 'features/wells/hooks/useProjectWellboreTrajectory';
import useLatestProjectWellboreDetail from 'features/projects/hooks/useLatestProjectWellboreDetails';

const ProjectWellboreTrajectoryContainer = ({
  projectId,
  targetDepth,
  children = null,
  ...rest
}) => {
  const [latestWellboreDetails = EMPTY_MAP, wellDetailsDataState] =
    useLatestProjectWellboreDetail(projectId);

  const [trajectory = EMPTY_MAP, trajectoryDataState] =
    useProjectWellboreTrajectory(projectId);

  const dataState = getSummarizedDataState([
    wellDetailsDataState,
    trajectoryDataState,
  ]);

  if (dataState.isLoading()) {
    return (
      <Grid
        xs
        item
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (!trajectory.size) return children;

  return (
    <WellboreTrajectory
      trajectory={trajectory}
      wellboreDetail={latestWellboreDetails}
      {...rest}
    />
  );
};

ProjectWellboreTrajectoryContainer.propTypes = {
  children: PropTypes.node,
  projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default compose()(ProjectWellboreTrajectoryContainer);
