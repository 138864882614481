import { Formik } from 'formik';
import { useMemo } from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';

import { TableRow } from 'altus-ui-components';

import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';

const EditableRichTextTableRowFormik = ({
  item,
  timeout,
  onSubmit,
  TableRowComponent = TableRow,
  classes,
  customStyle,
  ...rest
}) => {
  const initialValues = useMemo(() => item.toJS(), [item]);

  return (
    <Formik
      style={{ padding: '0 !important' }}
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <AutoSaveFormik timeout={timeout}>
        <TableRowComponent
          item={item}
          {...rest}
          style={{ padding: '0 !important' }}
          classes={{
            root: classes.tableRowRoot,
            rootStriped: classes.tableRowRoot,
            cellRoot: classes.tableCellRoot,
          }}
        />
      </AutoSaveFormik>
    </Formik>
  );
};

const styles = (theme) => ({
  tableRowRoot: {
    height: 'fit-content',
    minHeight: '7.2rem',
    maxHeight: 'fit-content',
    display: 'flex !important',
    alignItems: 'stretch',
    flex: '1 1 auto',
    '&:hover': {
      background: theme.altus.components.SortableListRow.background,
    },
    '& [class*="TableCell-root"]': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  },
  tableCellRoot: {},
});

export default compose(withStyles(styles))(EditableRichTextTableRowFormik);
