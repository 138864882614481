import { compose } from 'redux';
import { useEffect, useCallback } from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';

import {
  createTool,
  onLoadAddToolsModal,
  updateTool,
} from 'features/equipment/equipment.actions';

import {
  getToolConnectorsFromState,
  getUnitsOfMeasurePreference,
} from 'features/equipment/equipment.selectors';

import {
  EQUIPMENT_ACTIONS,
  EDITABLE_TOOL_PROPERTIES,
} from 'features/equipment/equipment.constants';

import { EMPTY_STRING } from 'app/app.constants';
import { ThirdPartyLabel } from 'features/projects/tool/tool.constants';
import BasePage from 'app/components/BasePageDense';
import {
  getCurrentClientSelectedUom,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';
import AddToolModal from 'features/equipment/components/AddToolModal';
import { FormFields } from 'features/equipment/components/AddToolForm';
import {
  existingThirdPartyToolToToolMapper,
  getUnitForProperty,
} from 'features/equipment/equipment.helpers';

const initialFormValues = {
  [FormFields.NAME]: EMPTY_STRING,
  [FormFields.SUPPLIER]: null,
  [FormFields.SUPPLIER_PART_NUMBER]: EMPTY_STRING,
  [FormFields.GROUP]: null,
  [FormFields.OUTER_DIAMETER]: EMPTY_STRING,
  [FormFields.INNER_DIAMETER]: EMPTY_STRING,
  [FormFields.LENGTH]: EMPTY_STRING,
  [FormFields.WEIGHT]: EMPTY_STRING,
  [FormFields.TOP_CONNECTOR]: null,
  [FormFields.BOTTOM_CONNECTOR]: null,
  [FormFields.FISHNECK]: EMPTY_STRING,
  [FormFields.SAFE_WORKING_LOAD]: EMPTY_STRING,
  [FormFields.YIELD]: EMPTY_STRING,
  [FormFields.DESCRIPTION]: EMPTY_STRING,
  [FormFields.FILE]: null,
  [FormFields.TOOL_IMAGE_ID]: EMPTY_STRING,
  [FormFields.MAXIMUM_OD]: EMPTY_STRING,
  [FormFields.MAXIMUM_STANDOFF_FORCE]: EMPTY_STRING,
  [FormFields.MINIMUM_OD]: EMPTY_STRING,
  [FormFields.MINIMUM_STANDOFF_FORCE]: EMPTY_STRING,
  [FormFields.JAR_ACTIVATION_LIMIT_LOW]: EMPTY_STRING,
  [FormFields.JAR_ACTIVATION_LIMIT_HIGH]: EMPTY_STRING,
  [FormFields.ADDITIONAL_NORMAL_FORCE]: EMPTY_STRING,
  [FormFields.IS_KNUCKLE_JOINT]: false,
  [FormFields.IS_VERIFIED]: false,
  [FormFields.AXIAL_FRICTION_REDUCED_TO]: EMPTY_STRING,
  [FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR]: EMPTY_STRING,
};

const AddToolModalContainer = ({
  edit,
  tool,
  title,
  toolId,
  openModal,
  suppliers,
  dataState,
  toolGroups,
  toggleModal,
  existingTool,
  dispatchOnLoad,
}) => {
  const dispatch = useDispatch();
  const toolConnectors = useSelector(getToolConnectorsFromState);
  const unitsOfMeasurePreference = useSelector(getUnitsOfMeasurePreference);
  const selectedCustomUnitsOfMeasure = useSelector(getCurrentClientSelectedUom);

  useEffect(() => {
    dispatchOnLoad();
  }, [dispatchOnLoad]);

  const saveTool = useCallback(
    (formValues) => {
      const tool = {
        topConnectorId: formValues.topConnector,
        bottomConnectorId: formValues.bottomConnector,
        name: formValues.name,
        toolGroupId: formValues.group,
        supplier: formValues.supplier,
        supplierPartNumber: formValues.supplierPartNumber,
        description: formValues.description,
        outerDiameter: {
          value: formValues.outerDiameter,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.OUTER_DIAMETER,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        innerDiameter: {
          value: formValues.innerDiameter,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.INNER_DIAMETER,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        length: {
          value: formValues.length,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.LENGTH,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        weight: {
          value: formValues.weight,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.WEIGHT,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        fishNeck: {
          value: formValues.fishneck,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.FISHNECK,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        safeWorkingLoad: {
          value: formValues.safeWorkingLoad,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.SWL,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        yield: {
          value: formValues.yield,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.YIELD,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        toolImageId: formValues.toolImageId,
        maximumOD: {
          value: formValues.maximumOD,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.MAXIMUM_OD,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        maximumStandoffForce: {
          value: formValues.maximumStandoffForce,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.MAXIMUM_STANDOFF_FORCE,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        minimumOD: {
          value: formValues.minimumOD,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.MINIMUM_OD,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        minimumStandoffForce: {
          value: formValues.minimumStandoffForce,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.MINIMUM_STANDOFF_FORCE,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        jarActivationLimitLow: {
          value: formValues.jarActivationLimitLow,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_LOW,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        jarActivationLimitHigh: {
          value: formValues.jarActivationLimitHigh,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_HIGH,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        additionalNormalForce: {
          value: formValues.additionalNormalForce,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.ADDITIONAL_NORMAL_FORCE,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        isKnuckleJoint: formValues.isKnuckleJoint,
        isVerified: formValues.isVerified,
        axialFrictionReducedTo: {
          value: formValues.axialFrictionReducedTo,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.AXIAL_FRICTION_REDUCED_TO,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        weightAboveJarringPointFactor: {
          value: formValues.weightAboveJarringPointFactor,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.WEIGHT_ABOVE_JARRING_POINT_FACTOR,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
      };
      if (edit) {
        tool.toolId = parseInt(toolId);
        tool.referenceNumber = ThirdPartyLabel;
        tool.m3ItemNumber = ThirdPartyLabel;
        const newTool = existingThirdPartyToolToToolMapper(tool, existingTool);
        dispatch(updateTool(newTool));
      } else {
        dispatch(createTool(tool));
      }
    },
    [
      edit,
      toolId,
      dispatch,
      existingTool,
      unitsOfMeasurePreference,
      selectedCustomUnitsOfMeasure,
    ],
  );

  return (
    <BasePage dataState={dataState}>
      <AddToolModal
        edit={edit}
        tool={tool}
        title={title}
        open={openModal}
        onSubmit={saveTool}
        saveTool={saveTool}
        suppliers={suppliers}
        toolGroups={toolGroups}
        toggleModal={toggleModal}
        toolConnectors={toolConnectors}
        initialFormValues={initialFormValues}
        unitsOfMeasurePreference={unitsOfMeasurePreference}
        selectedCustomUnitsOfMeasure={selectedCustomUnitsOfMeasure}
      />
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    EQUIPMENT_ACTIONS.CREATE_TOOL,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoadAddToolsModal,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AddToolModalContainer,
);
