export const ACTIONS = {
  REQUEST_SIMULATION_TOOL_PARAMETERS: 'REQUEST_SIMULATION_TOOL_PARAMETERS',
  REQUEST_UPDATE_SIMULATION_TOOL_PARAMETER:
    'REQUEST_UPDATE_SIMULATION_TOOL_PARAMETER',

  RECEIVE_SIMULATION_TOOL_PARAMETER: 'RECEIVE_SIMULATION_TOOL_PARAMETER',
  RECEIVE_SIMULATION_TOOL_PARAMETERS: 'RECEIVE_SIMULATION_TOOL_PARAMETERS',
};

export const DynamicToolParameter = {
  JAR_ACTIVATION: 1,
  FLUID_SELECTION: 2,
};
export const FluidSelectionType = {
  AIR: 0,
  WELLBORE_FLUID: 1,
};
