import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as actions from 'features/projects/tasks/task/simulation/dynamicToolParameters/simulationDynamicToolParameter.actions';
import { EMPTY_MAP } from 'app/app.constants';
export const useInitializeTaskSimulationDynamicToolParametersContainer = (
  projectId,
  taskId,
  simulationId,
) => {
  const dispatch = useDispatch();

  const selectedToolString = useSelector((state) =>
    state.getIn(['entity', 'simulation', simulationId, 'toolstringId']),
  );
  const parameters =
    useSelector((state) =>
      state
        .getIn(['entity', 'simulationDynamicToolParameter'])
        .groupBy((p) => p.get('dynamicToolParameter'))
        .map((v) => v.toList()),
    ) ?? EMPTY_MAP;

  // Fetch dynamic tool parameters for simulation
  useEffect(() => {
    if (simulationId) {
      dispatch(
        actions.requestSimulationDynamicToolParameters(
          projectId,
          taskId,
          simulationId,
        ),
      );
      return () => {
        return dispatch(actions.receiveSimulationDynamicToolParameters());
      };
    }
  }, [projectId, taskId, simulationId, selectedToolString, dispatch]);

  return parameters;
};
