import { WORK_ITEM_STATUS } from 'app/app.constants';
import SortableActivityListItem, {
  UnSortableActivityListItemCompleted,
  UnSortableActivityListItemCurrent,
} from 'features/projects/tasks/task/activities/ActivityListItem';

export const selectElementByActivityStatus = (status) => {
  return status !== WORK_ITEM_STATUS.PLANNED
    ? status === WORK_ITEM_STATUS.COMPLETED
      ? UnSortableActivityListItemCompleted
      : UnSortableActivityListItemCurrent
    : SortableActivityListItem;
};
