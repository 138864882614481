import { Grid, Box } from '@material-ui/core';

import StatusIndicator from 'features/data-exchange/components/StatusIndicator';

const StatusIndicatorWithText = ({ color, children }) => (
  <Grid container alignItems="center" wrap="nowrap">
    <Box marginRight={0.5}>
      <StatusIndicator color={color} />
    </Box>
    {children}
  </Grid>
);

export default StatusIndicatorWithText;
