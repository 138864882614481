import { useMemo } from 'react';

import { Table } from 'altus-ui-components';

import { projectStatusToString } from 'features/projects/projects.mappers';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';

const defaultInitialState = {
  pageSize: 50,
};

const ProjectSelectModalTable = ({ projects, ...rest }) => {
  const columns = useMemo(
    () => [
      {
        xs: 2,
        Header: 'Project',
        accessor: (project) => project.get('projectId'),
      },
      {
        xs: 2,
        Header: 'Objective',
        accessor: (project) => project.get('title'),
      },
      {
        xs: 2,
        Header: 'Well',
        accessor: (project) => project.get('wellboreName'),
      },
      {
        xs: 2,
        Header: 'Facility',
        accessor: (project) => project.get('facilityName'),
      },
      {
        xs: 2,
        Header: 'Client',
        accessor: (project) => project.get('organizationName'),
      },
      {
        xs: 2,
        Header: 'Phase',
        accessor: (project) => projectStatusToString(project.get('status')),
      },
    ],
    [],
  );

  return (
    <Table
      usePagination
      items={projects}
      columns={columns}
      useGlobalFilter={false}
      initialState={defaultInitialState}
      TableRowComponent={EditableTableRowFormik}
      {...rest}
    />
  );
};

export default ProjectSelectModalTable;
