import React from 'react';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { EMPTY_LIST } from 'app/app.constants';
import ReadOnlyField from 'app/components/ReadOnlyField';
import { getTotalElapsedTime } from 'utils/activity.util';
import { formatDuration, formatDurationDiff } from 'utils/format.util';
import { TimerType } from 'features/projects/activities/activities.constants';
import { timerTypeToDisplayName } from 'features/projects/activities/activities.mappers';

const ReportContainerHeader = ({
  totalNpt,
  timersByType,
  totalElapsedDuration,
  totalPlannedDuration,
  activities = EMPTY_LIST,
}) => {
  const totalTimeWait = getTotalElapsedTime(
    activities.filter((activity) => activity.get('isWait')),
  );
  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item>
          <ReadOnlyField
            label="Time Spent"
            value={formatDuration(totalElapsedDuration)}
          />
        </Grid>
        <Grid item>
          <ReadOnlyField
            label="Time Planned"
            value={formatDuration(totalPlannedDuration)}
          />
        </Grid>
        <Grid item>
          <ReadOnlyField
            label="Difference"
            value={formatDurationDiff(
              totalElapsedDuration,
              totalPlannedDuration,
            )}
          />
        </Grid>
        <Grid item>
          <ReadOnlyField
            label="Waiting"
            value={formatDuration(totalTimeWait)}
          />
        </Grid>
        <Grid item>
          <ReadOnlyField
            label="Non-productive time"
            value={formatDuration(totalNpt)}
          />
        </Grid>
        {Object.values(TimerType).map((timerType) => {
          const timers = timersByType[timerType];

          return (
            <Grid item key={timerType}>
              <ReadOnlyField
                label={timerTypeToDisplayName(timerType)}
                value={formatDuration(getTotalElapsedTime(timers))}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  emptyIcon: {
    fontSize: 200,
    color: theme.palette.action.disabled,
  },
});

export default withStyles(styles)(ReportContainerHeader);
