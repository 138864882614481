import React from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Grid,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import { WellIntegrityRiskMatrix as RiskMatrix } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { EMPTY_LIST } from 'app/app.constants';
import IntegrityRiskMatrixValueComponent from './IntegrityRiskMatrixValueComponent';
import IntegrityMatrixDetailsComponent from './IntegrityMatrixDetailsComponent';
import IntegrityMatrixComplianceComponent from './IntegrityMatrixComplianceComponent';

const IntegrityRiskMatrix = ({ classes, allRisks = EMPTY_LIST }) => {
  const theme = useTheme();
  const getBackgroundColor = (value) => {
    switch (value) {
      case 'Red':
        return theme.altus.components.RiskMatrix.background.red;
      case 'Yellow':
        return theme.altus.components.RiskMatrix.background.yellow;
      case 'Green':
        return theme.altus.components.RiskMatrix.background.green;
      default:
        return '';
    }
  };

  const getIntersectionNumber = (rowIndex, cellIndex) => {
    // const consequenceValue = rowIndex + 1;
    // const probabilityValue = 6 - cellIndex;

    let count = 0;
    // for (let risk of allRisks) {
    //     if (
    //         risk.get('consequence') === consequenceValue &&
    //         risk.get('probability') === probabilityValue
    //     ) {
    //         count++;
    //     }
    // }
    if (cellIndex === 6 && rowIndex === 0) count = 5;
    if (cellIndex === 2 && rowIndex === 1) count = 2;
    if (cellIndex === 1 && rowIndex === 2) count = 1;
    if (cellIndex === 5 && rowIndex === 3) count = 4;
    if (cellIndex === 4 && rowIndex === 4) count = 5;
    return count || null;
  };

  const ReversedRiskMatrix = [...RiskMatrix];
  ReversedRiskMatrix[0] = ['', ...ReversedRiskMatrix[0].slice(1).reverse()];
  for (let i = 1; i < ReversedRiskMatrix.length; i++) {
    ReversedRiskMatrix[i] = [
      ReversedRiskMatrix[i][0],
      ...ReversedRiskMatrix[i].slice(1).reverse(),
    ];
  }

  return (
    <Grid item container className={classes.riskMatrixContainer}>
      <Grid item xs={8} className={classes.riskMatrixDetails}>
        <Grid item xs={12} className={classes.riskScore}>
          <Typography color="textSecondary" variant="body2">
            Registered: 2024/11/21
          </Typography>
          <Grid item className={classes.riskScoreBox}>
            <Typography variant="h7">Risk score: 57</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Table className={classes.tableSpacing}>
            <TableBody>
              {ReversedRiskMatrix.slice(1).map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, cellIndex) => {
                    const [symbol, name] = cell.split('-');

                    if (cell === 'N/A')
                      return (
                        <TableCell
                          key={cellIndex}
                          className={`${classes.columnWidth}`}
                        />
                      );

                    if (cellIndex === 0) {
                      return (
                        <TableCell
                          key={cellIndex}
                          className={`${classes.borderNone} ${classes.centeredContent} ${classes.roundedCell} ${classes.myToolbar} ${classes.columnWidth}`}
                        >
                          <Typography variant="h7">
                            {symbol}
                            <br />
                            {name}
                          </Typography>
                        </TableCell>
                      );
                    }

                    if (cellIndex === 1 && rowIndex === 0) {
                      return (
                        <TableCell
                          key={'consequence_matrix'}
                          className={`${classes.consequence} ${classes.borderNone} ${classes.centeredContent} ${classes.roundedCell}`}
                          style={{
                            border: 'none',
                            backgroundColor: alpha(
                              theme.palette.common.white,
                              0.1,
                            ),
                          }}
                          rowSpan={ReversedRiskMatrix[0].length}
                          colSpan={1}
                        >
                          <Typography variant="h6">Weight</Typography>
                        </TableCell>
                      );
                    }

                    const number = getIntersectionNumber(rowIndex, cellIndex);
                    return (
                      <TableCell
                        key={cellIndex}
                        className={`${classes.roundedCell}`}
                        style={{
                          textAlign: 'center',
                          verticalAlign: 'middle',
                        }}
                      >
                        <div
                          style={{
                            display: 'inline-block',
                            lineHeight: 'normal',
                            opacity: number ? 1 : 0,
                          }}
                        >
                          <IntegrityRiskMatrixValueComponent
                            color={getBackgroundColor(cell)}
                            value={number || 0}
                          />
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
            <TableRow className={classes.headerRowSpacing}>
              <TableCell
                colSpan={2}
                className={`${classes.borderNone}`}
              ></TableCell>
              <TableCell
                colSpan={ReversedRiskMatrix[0].length - 1}
                className={`${classes.centeredContent} ${classes.roundedCell} ${classes.columnWidth}`}
                style={{
                  border: 'none',
                  backgroundColor: alpha(theme.palette.common.white, 0.1),
                }}
              >
                <Typography variant="h6">Likelihood</Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.headerRowSpacing}>
              {ReversedRiskMatrix[0].map((header, index) => {
                if (header === 'N/A') {
                  return <TableCell key={index} style={{ display: 'none' }} />;
                }
                const [symbol, name] = header.split('-');
                const cellClasses = [classes.roundedCell];
                cellClasses.push(
                  classes.myToolbar,
                  classes.centeredContent,
                  classes.borderNone,
                  classes.columnWidth,
                );

                if (index === 0) {
                  return (
                    <TableCell
                      key={index}
                      className={`${classes.borderNone} ${classes.columnWidth}`}
                      colSpan={2}
                    ></TableCell>
                  );
                }
                return (
                  <TableCell key={index} className={cellClasses.join(' ')}>
                    <Typography variant="h7">
                      {symbol}
                      <br />
                      {name}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </Table>
        </Grid>
      </Grid>
      <Grid item xs={9} className={classes.riskMatrixDetailsContents}>
        <IntegrityMatrixDetailsComponent title={'Score'} value={70} />
      </Grid>
      <Grid item xs={9} className={classes.riskMatrixDetailsContents}>
        <IntegrityMatrixDetailsComponent title={'Consequence'} value={33} />
      </Grid>
      <Grid item xs={9} className={classes.riskMatrixDetailsContents}>
        <IntegrityMatrixComplianceComponent value={40} />
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  myToolbar: {
    borderRadius: '4px',
    border: 'none',
  },
  tableSpacing: {
    width: '100%',
    maxWidth: '100%',
  },
  columnWidth: {
    width: '100%',
  },
  roundedCell: {
    border: '0.5px solid white',
  },
  coloredCell: (props) => ({
    backgroundColor: props.color,
  }),
  centeredContent: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  consequence: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  borderNone: {
    border: 'none',
  },
  headerRowSpacing: {
    borderSpacing: '0px !important',
  },
  riskMatrixContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  riskMatrixDetails: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  riskMatrixDetailsContents: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(15),
  },
  riskMatrixDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  riskMatrixDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  riskScore: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: theme.spacing(1),
  },
  riskScoreBox: {
    background: alpha(theme.palette.primary.newLight, 0.12),
    display: 'flex',
    alignItems: 'flex-end',
    padding: theme.spacing(1),
  },
});

export default compose(withStyles(styles))(IntegrityRiskMatrix);
