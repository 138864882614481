import Search from '@material-ui/icons/Search';
import { InputAdornment } from '@material-ui/core';
import { getIn, Field, useFormikContext } from 'formik';
import { useCallback, useEffect, useRef } from 'react';

import { invokeIfFunction } from 'utils/app.util';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';

const CreateProjectModalAppBarTextField = ({
  item,
  index,
  isActive,
  autoFocus,
  clearFields,
  setCurrentIndex,
}) => {
  const inputRef = useRef();

  const { label, names, disabled, onFocus } = item;
  const { id, search } = names;

  const { values, isSubmitting } = useFormikContext();

  const selectedItemId = getIn(values, id);

  // clear input if the corresponding item id gets cleared
  useEffect(() => {
    if (!selectedItemId) {
      clearFields(search);
    }
  }, [selectedItemId, clearFields, search]);

  // focus on the TextField if marked as active
  useEffect(() => {
    if (isActive) {
      inputRef.current.focus();
    }
  }, [isActive]);

  const handleFocus = useCallback(() => {
    clearFields(id, search);
    setCurrentIndex(index);
    invokeIfFunction(onFocus);
  }, [onFocus, clearFields, id, search, setCurrentIndex, index]);

  return (
    <Field
      required
      name={search}
      label={label}
      inputRef={inputRef}
      onFocus={handleFocus}
      autoFocus={!!autoFocus}
      component={TextFieldFormik}
      disabled={disabled || isSubmitting}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CreateProjectModalAppBarTextField;
