import * as yup from 'yup';
import { compose } from 'redux';
import { useRef, useCallback, useState } from 'react';
import Close from '@material-ui/icons/Close';
import { connect, useDispatch } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { Field, Formik, Form, ErrorMessage } from 'formik';

import {
  Grid,
  IconButton,
  Typography,
  InputAdornment,
} from '@material-ui/core';

import { Button } from 'app/components/withTooltip';
import { LoadingOverlay, LoadingButton } from 'altus-ui-components';

import {
  deleteToolImage,
  uploadToolImage,
} from 'features/equipment/equipment.actions';

import {
  EQUIPMENT_ACTIONS,
  EDITABLE_TOOL_PROPERTIES,
} from 'features/equipment/equipment.constants';

import {
  unitPlaceholder,
  getUnitForProperty,
} from 'features/equipment/equipment.helpers';

import { EMPTY_STRING } from 'app/app.constants';
import SwitchFormik from 'app/components/form/formik/SwitchFormik';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import YesNoSwitchFormik from 'app/components/form/formik/YesNoSwitchFormik';
import TextAreaFieldFormik from 'app/components/form/formik/TextAreaFieldFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';

export const FormFields = {
  NAME: 'name',
  SUPPLIER: 'supplier',
  SUPPLIER_PART_NUMBER: 'supplierPartNumber',
  GROUP: 'group',
  OUTER_DIAMETER: 'outerDiameter',
  INNER_DIAMETER: 'innerDiameter',
  LENGTH: 'length',
  WEIGHT: 'weight',
  TOP_CONNECTOR: 'topConnector',
  BOTTOM_CONNECTOR: 'bottomConnector',
  FISHNECK: 'fishneck',
  SAFE_WORKING_LOAD: 'safeWorkingLoad',
  YIELD: 'yield',
  DESCRIPTION: 'description',
  FILE: 'toolImageFile',
  TOOL_IMAGE_ID: 'toolImageId',
  AXIAL_FRICTION_REDUCED_TO: 'axialFrictionReducedTo',
  MAXIMUM_OD: 'maximumOD',
  MAXIMUM_STANDOFF_FORCE: 'maximumStandoffForce',
  MINIMUM_OD: 'minimumOD',
  MINIMUM_STANDOFF_FORCE: 'minimumStandoffForce',
  WEIGHT_ABOVE_JARRING_POINT_FACTOR: 'weightAboveJarringPointFactor',
  JAR_ACTIVATION_LIMIT_LOW: 'jarActivationLimitLow',
  JAR_ACTIVATION_LIMIT_HIGH: 'jarActivationLimitHigh',
  ADDITIONAL_NORMAL_FORCE: 'additionalNormalForce',
  IS_KNUCKLE_JOINT: 'isKnuckleJoint',
  IS_VERIFIED: 'isVerified',
};

const MAX_FILE_SIZE = 512 * 1024;

const getKey = (item) => item.get('id');
const getName = (item) => item.get('name')?.toUpperCase();

const AddToolForm = ({
  edit,
  tool,
  classes,
  saveTool,
  dataState,
  toolGroups,
  toolSuppliers,
  toolConnectors,
  initialFormValues,
  unitsOfMeasurePreference,
  selectedCustomUnitsOfMeasure,
}) => {
  const initialPlaceholders = {
    [FormFields.AXIAL_FRICTION_REDUCED_TO]: unitPlaceholder(
      tool,
      'axialFrictionReducedTo',
      '0 - 1',
    ),
    [FormFields.MAXIMUM_OD]: unitPlaceholder(tool, 'maximumOD'),
    [FormFields.NAME]:
      tool && !tool.isEmpty() ? tool.get('name') : 'Enter name',
    [FormFields.SUPPLIER_PART_NUMBER]:
      tool && !tool.isEmpty()
        ? tool.get('supplierPartNumber')
        : 'Enter part number',
    [FormFields.OUTER_DIAMETER]: unitPlaceholder(tool, 'outerDiameter'),
    [FormFields.INNER_DIAMETER]: unitPlaceholder(tool, 'innerDiameter'),
    [FormFields.LENGTH]:
      tool && !tool.isEmpty()
        ? `${tool.getIn(['length', 'value'])} ${tool.getIn(['length', 'unit'])}`
        : '',
    [FormFields.WEIGHT]:
      tool && !tool.isEmpty()
        ? `${tool.getIn(['weight', 'value'])} ${tool.getIn(['weight', 'unit'])}`
        : '',
    [FormFields.FISHNECK]:
      tool && !tool.isEmpty()
        ? `${tool.getIn(['fishNeck', 'value'])} ${tool.getIn([
            'fishNeck',
            'unit',
          ])}`
        : '',
    [FormFields.SAFE_WORKING_LOAD]: unitPlaceholder(tool, 'safeWorkingLoad'),
    [FormFields.YIELD]: unitPlaceholder(tool, 'yieldValue'),
    [FormFields.DESCRIPTION]:
      tool && !tool.isEmpty() ? tool.get('description') : '',
    [FormFields.MINIMUM_OD]: unitPlaceholder(tool, 'minimumOD'),
    [FormFields.MAXIMUM_STANDOFF_FORCE]: unitPlaceholder(
      tool,
      'maximumStandoffForce',
    ),
    [FormFields.MINIMUM_STANDOFF_FORCE]: unitPlaceholder(
      tool,
      'minimumStandoffForce',
    ),
    [FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR]: unitPlaceholder(
      tool,
      'weightAboveJarringPointFactor',
      '0 - 1',
    ),
    [FormFields.JAR_ACTIVATION_LIMIT_LOW]: unitPlaceholder(
      tool,
      'jarActivationLimitLow',
    ),
    [FormFields.JAR_ACTIVATION_LIMIT_HIGH]: unitPlaceholder(
      tool,
      'jarActivationLimitHigh',
    ),
    [FormFields.ADDITIONAL_NORMAL_FORCE]: unitPlaceholder(
      tool,
      'additionalNormalForce',
    ),
  };

  const [placeholders, setPlaceholders] = useState(initialPlaceholders);

  const resetPlaceholder = (fieldName) => {
    setPlaceholders((prevPlaceholders) => ({
      ...prevPlaceholders,
      [fieldName]: null,
    }));
  };

  const schema = yup.object().shape({
    additionalNormalForce: yup.number().typeError('Must be a number'),
    jarActivationLimitHigh: yup.number().typeError('Must be a number'),
    jarActivationLimitLow: yup.number().typeError('Must be a number'),
    weightAboveJarringPointFactor: yup
      .number()
      .nullable(true)
      .min(0, 'Value must be greater than or equal to 0')
      .max(1, 'Value must be less than or equal to 1')
      .typeError('Must be a number'),
    minimumStandoffForce: yup.number().typeError('Must be a number'),
    maximumStandoffForce: yup.number().typeError('Must be a number'),
    minimumOD: yup.number().typeError('Must be a number'),
    maximumOD: yup.number().typeError('Must be a number'),
    axialFrictionReducedTo: yup
      .number()
      .nullable(true)
      .min(0, 'Value must be greater than or equal to 0')
      .max(1, 'Value must be less than or equal to 1'),
    name: yup.string().required('Required'),
    supplier: yup.string().required('Required'),
    supplierPartNumber: yup.string(),
    fishneck: yup.string(),
    group: yup.number().required('Required').typeError('Required'),
    length: yup.number().required('Required').max(60),
    outerDiameter: yup.number().required('Required').typeError('Required'),
    innerDiameter: yup.number().required('Required').typeError('Required'),
    safeWorkingLoad: yup.string(),
    weight: yup.string().required('Required'),
    yield: yup.string(),
    toolImageFile: yup
      .mixed()
      .nullable()
      .notRequired()
      .test('FILE_SIZE', 'Uploaded file is too big', (value) => {
        if (value && fileRef.current) {
          if (fileRef.current.files[0].size <= MAX_FILE_SIZE) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .test('FILE_FORMAT', 'Only .png is allowed', (value) => {
        if (value && fileRef.current) {
          if (fileRef.current.files[0].type === 'image/png') {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }),
  });

  const initialEditFormValues = {
    ...initialFormValues,
    [FormFields.IS_VERIFIED]: tool?.get('isVerified'),
  };

  const dispatch = useDispatch();
  const fileRef = useRef(null);

  const setValue = useCallback((setValue) => {
    setValue(FormFields.TOOL_IMAGE_ID, EMPTY_STRING);
    setValue(FormFields.FILE, null);
  }, []);

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={edit ? null : schema}
      initialValues={edit ? initialEditFormValues : initialFormValues}
    >
      {({ isValid, values }) => (
        <Form>
          <Grid container className={classes.root}>
            <Grid container spacing={2} alignItems="center">
              <Grid
                xs={12}
                item
                container
                spacing={2}
                wrap="nowrap"
                className={classes.form}
              >
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Name*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.NAME}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.NAME]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.NAME);
                                    form.setFieldValue(
                                      FormFields.NAME,
                                      initialFormValues[FormFields.NAME],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Supplier*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectTextFieldFormik
                      getItemName={getName}
                      label={
                        tool && !tool.isEmpty() ? tool.get('supplier') : ''
                      }
                      getItemValue={getName}
                      margin="none"
                      items={toolSuppliers}
                      name={FormFields.SUPPLIER}
                    />
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Supplier part number</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.SUPPLIER_PART_NUMBER}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.SUPPLIER_PART_NUMBER]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.SUPPLIER_PART_NUMBER,
                                    );
                                    form.setFieldValue(
                                      FormFields.SUPPLIER_PART_NUMBER,
                                      initialFormValues[
                                        FormFields.SUPPLIER_PART_NUMBER
                                      ],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Tool group*</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectTextFieldFormik
                      getItemName={getName}
                      label={
                        tool && !tool.isEmpty() ? tool.get('toolGroupName') : ''
                      }
                      getItemValue={getKey}
                      margin="none"
                      items={toolGroups}
                      name={FormFields.GROUP}
                    />
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Outer diameter*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.OUTER_DIAMETER}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          type="number"
                          margin="none"
                          placeholder={placeholders[FormFields.OUTER_DIAMETER]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.OUTER_DIAMETER);
                                    form.setFieldValue(
                                      FormFields.OUTER_DIAMETER,
                                      initialFormValues[
                                        FormFields.OUTER_DIAMETER
                                      ],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.OUTER_DIAMETER,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Inner diameter*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.INNER_DIAMETER}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          type="number"
                          margin="none"
                          placeholder={placeholders[FormFields.INNER_DIAMETER]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.INNER_DIAMETER);
                                    form.setFieldValue(
                                      FormFields.INNER_DIAMETER,
                                      initialFormValues[
                                        FormFields.INNER_DIAMETER
                                      ],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.INNER_DIAMETER,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Length*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.LENGTH}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.LENGTH]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.LENGTH);
                                    form.setFieldValue(
                                      FormFields.LENGTH,
                                      initialFormValues[FormFields.LENGTH],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.LENGTH,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Weight*</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.WEIGHT}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.WEIGHT]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.WEIGHT);
                                    form.setFieldValue(
                                      FormFields.WEIGHT,
                                      initialFormValues[FormFields.WEIGHT],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.WEIGHT,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Top connector</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectTextFieldFormik
                      getItemName={getName}
                      label={
                        tool && !tool.isEmpty()
                          ? tool.get('topConnectorName')
                          : ''
                      }
                      getItemValue={getKey}
                      margin="none"
                      items={toolConnectors}
                      name={FormFields.TOP_CONNECTOR}
                    />
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Bottom connector</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectTextFieldFormik
                      getItemName={getName}
                      label={
                        tool && !tool.isEmpty()
                          ? tool.get('bottomConnectorName')
                          : ''
                      }
                      getItemValue={getKey}
                      margin="none"
                      items={toolConnectors}
                      name={FormFields.BOTTOM_CONNECTOR}
                    />
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Fishneck</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.FISHNECK}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.FISHNECK]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.FISHNECK);
                                    form.setFieldValue(
                                      FormFields.FISHNECK,
                                      initialFormValues[FormFields.FISHNECK],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.FISHNECK,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Safe working load</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.SAFE_WORKING_LOAD}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.SAFE_WORKING_LOAD]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.SAFE_WORKING_LOAD,
                                    );
                                    form.setFieldValue(
                                      FormFields.SAFE_WORKING_LOAD,
                                      initialFormValues[
                                        FormFields.SAFE_WORKING_LOAD
                                      ],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.SWL,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Yield</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.YIELD}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.YIELD]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.YIELD);
                                    form.setFieldValue(
                                      FormFields.YIELD,
                                      initialFormValues[FormFields.YIELD],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.YIELD,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Description</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.DESCRIPTION}>
                      {({ form, ...formik }) => (
                        <TextAreaFieldFormik
                          form={form}
                          placeholder={placeholders[FormFields.DESCRIPTION]}
                          margin="none"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.DESCRIPTION);
                                    form.setFieldValue(
                                      FormFields.DESCRIPTION,
                                      initialFormValues[FormFields.DESCRIPTION],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Upload image - .png only</Typography>
                  </Grid>
                  <Grid item container xs={6}>
                    <Field
                      name={FormFields.FILE}
                      innerRef={fileRef}
                      type="file"
                    >
                      {({ form, ...formik }) => (
                        <>
                          <Grid item xs={4}>
                            <input
                              ref={fileRef}
                              hidden
                              type="file"
                              onClick={(event) => {
                                event.target.value = null;
                              }}
                              onChange={(event) => {
                                if (event.target.files.length > 0) {
                                  form.setTouched({
                                    ...form.touched,
                                    [FormFields.FILE]: true,
                                  });
                                  yup
                                    .reach(schema, FormFields.FILE)
                                    .isValid(event.target.files[0])
                                    .then((valid) => {
                                      form.setFieldValue(
                                        FormFields.FILE,
                                        event.target.files[0],
                                      );
                                      valid === true &&
                                        dispatch(
                                          uploadToolImage(
                                            event.target.files[0],
                                          ),
                                        ).then((payload) => {
                                          form.setFieldValue(
                                            FormFields.TOOL_IMAGE_ID,
                                            payload.toolImageId,
                                          );
                                        });
                                    });
                                }
                              }}
                              {...formik}
                            />
                            <Button
                              color="secondary"
                              variant="contained"
                              onClick={() => {
                                fileRef.current.click();
                              }}
                            >
                              Upload
                            </Button>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={6}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography variant="body2" noWrap>
                              {values.toolImageFile &&
                                values.toolImageFile.name}
                            </Typography>
                          </Grid>
                          {values.toolImageFile &&
                            values.toolImageFile.name && (
                              <Grid
                                item
                                xs={2}
                                container
                                className={classes.removeUploadContainer}
                              >
                                <IconButton
                                  size="small"
                                  title="Remove uploaded file"
                                  onClick={() => {
                                    if (values.toolImageId) {
                                      dispatch(
                                        deleteToolImage(values.toolImageId),
                                      ).then(setValue(form.setFieldValue));
                                    } else {
                                      setValue(form.setFieldValue);
                                    }
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </Grid>
                            )}
                        </>
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center" />
                  <Grid item xs={6}>
                    <Typography variant="body2" className={classes.errorText}>
                      <ErrorMessage
                        name={FormFields.FILE}
                        render={(msg) => <div>{msg}</div>}
                      />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Axial friction reduced to </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.AXIAL_FRICTION_REDUCED_TO}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.AXIAL_FRICTION_REDUCED_TO]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.AXIAL_FRICTION_REDUCED_TO,
                                    );
                                    form.setFieldValue(
                                      FormFields.AXIAL_FRICTION_REDUCED_TO,
                                      initialFormValues[
                                        FormFields.AXIAL_FRICTION_REDUCED_TO
                                      ],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Centraliser Maximum OD</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.MAXIMUM_OD}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.MAXIMUM_OD]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.MAXIMUM_OD);
                                    form.setFieldValue(
                                      FormFields.MAXIMUM_OD,
                                      initialFormValues[FormFields.MAXIMUM_OD],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.MAXIMUM_OD,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Centraliser Minimum OD</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.MINIMUM_OD}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={placeholders[FormFields.MINIMUM_OD]}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(FormFields.MINIMUM_OD);
                                    form.setFieldValue(
                                      FormFields.MINIMUM_OD,
                                      initialFormValues[FormFields.MINIMUM_OD],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.MINIMUM_OD,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Maximum standoff force</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.MAXIMUM_STANDOFF_FORCE}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.MAXIMUM_STANDOFF_FORCE]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.MAXIMUM_STANDOFF_FORCE,
                                    );
                                    form.setFieldValue(
                                      FormFields.MAXIMUM_STANDOFF_FORCE,
                                      initialFormValues[
                                        FormFields.MAXIMUM_STANDOFF_FORCE
                                      ],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.MAXIMUM_STANDOFF_FORCE,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Minimum standoff force</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.MINIMUM_STANDOFF_FORCE}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.MINIMUM_STANDOFF_FORCE]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.MINIMUM_STANDOFF_FORCE,
                                    );
                                    form.setFieldValue(
                                      FormFields.MINIMUM_STANDOFF_FORCE,
                                      initialFormValues[
                                        FormFields.MINIMUM_STANDOFF_FORCE
                                      ],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.MINIMUM_STANDOFF_FORCE,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Weight above jarring point factor</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[
                              FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR
                            ]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR,
                                    );
                                    form.setFieldValue(
                                      FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR,
                                      initialFormValues[
                                        FormFields
                                          .WEIGHT_ABOVE_JARRING_POINT_FACTOR
                                      ],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Jar activation limit low</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.JAR_ACTIVATION_LIMIT_LOW}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={unitPlaceholder(
                            tool,
                            'jarActivationLimitLow',
                          )}
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.JAR_ACTIVATION_LIMIT_LOW,
                                    );
                                    form.setFieldValue(
                                      FormFields.JAR_ACTIVATION_LIMIT_LOW,
                                      initialFormValues[
                                        FormFields.JAR_ACTIVATION_LIMIT_LOW
                                      ],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_LOW,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Jar activation limit high</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.JAR_ACTIVATION_LIMIT_HIGH}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.JAR_ACTIVATION_LIMIT_HIGH]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.JAR_ACTIVATION_LIMIT_HIGH,
                                    );
                                    form.setFieldValue(
                                      FormFields.JAR_ACTIVATION_LIMIT_HIGH,
                                      initialFormValues[
                                        FormFields.JAR_ACTIVATION_LIMIT_HIGH
                                      ],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_HIGH,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Additional normal force</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Field name={FormFields.ADDITIONAL_NORMAL_FORCE}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          placeholder={
                            placeholders[FormFields.ADDITIONAL_NORMAL_FORCE]
                          }
                          InputProps={{
                            maxLength: 100,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() => {
                                    resetPlaceholder(
                                      FormFields.ADDITIONAL_NORMAL_FORCE,
                                    );
                                    form.setFieldValue(
                                      FormFields.ADDITIONAL_NORMAL_FORCE,
                                      initialFormValues[
                                        FormFields.ADDITIONAL_NORMAL_FORCE
                                      ],
                                    );
                                  }}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Typography>
                      {getUnitForProperty(
                        EDITABLE_TOOL_PROPERTIES.ADDITIONAL_NORMAL_FORCE,
                        unitsOfMeasurePreference,
                        selectedCustomUnitsOfMeasure,
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Is knuckle joint</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.IS_KNUCKLE_JOINT}>
                      {({ form, ...formik }) => (
                        <SwitchFormik form={form} {...formik} />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid xs item container className={classes.row}>
                  <Grid item container xs={6} alignItems="center">
                    <Typography>Verified</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name={FormFields.IS_VERIFIED}>
                      {({ form, ...formik }) => (
                        <YesNoSwitchFormik form={form} {...formik} />
                      )}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <LoadingOverlay dataState={dataState} text={'Loading...'} />
          </Grid>
          <Grid item container className={classes.submitBtn}>
            <LoadingButton
              color="primary"
              variant="contained"
              disabled={!isValid}
              onClick={() => {
                saveTool(values);
              }}
            >
              Save
            </LoadingButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const styles = (theme) => ({
  root: {
    paddingTop: 15,
    marginBottom: 15,
  },
  leftIcon: {
    marginRight: 10,
  },
  form: {
    flexDirection: 'column',
  },
  formLegend: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  submitBtn: {
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
  },
  errorText: {
    color: theme.palette.error.main,
  },
  removeUpload: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  removeUploadContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default compose(
  connect((state) => ({
    dataState: getSummarizedDataStateFromState(
      state,
      EQUIPMENT_ACTIONS.CREATE_TOOL,
      EQUIPMENT_ACTIONS.UPDATE_TOOL,
      EQUIPMENT_ACTIONS.UPLOAD_IMAGE,
      EQUIPMENT_ACTIONS.DELETE_IMAGE,
    ),
  })),
  withStyles(styles),
)(AddToolForm);
