import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { EMPTY_LIST } from 'app/app.constants';
import { ProjectDashboardFormFields as FormFields } from 'features/projects/dashboard/dashboard.constants';
import ProjectDashboardCurvesTable from 'features/projects/dashboard/components/ProjectDashboardCurvesTable';
import useProjectDashboardCheetahJobCurves from 'features/projects/dashboard/hooks/useProjectDashboardCheetahJobCurves';
import { getInitialSelectedProjectDashboardCurveRowIds } from 'features/projects/dashboard/components/ProjectDashboardCurvesTable';

const ProjectDashboardCheetahJobCurvesTableContainer = ({
  classes,
  projectId,
  cheetahJobId,
  setDataState,
  projectDashboardId,
  ...rest
}) => {
  const { setFieldValue } = useFormikContext();

  const [projectDashboardCheetahJobCurves = EMPTY_LIST, dataState] =
    useProjectDashboardCheetahJobCurves(
      projectId,
      projectDashboardId,
      cheetahJobId,
    );

  useEffect(() => {
    setDataState(dataState);
  }, [dataState, setDataState]);

  useEffect(() => {
    if (!projectDashboardCheetahJobCurves) return;
    if (!cheetahJobId) return;

    setFieldValue(
      FormFields.PROJECT_DASHBOARD_CURVES,
      projectDashboardCheetahJobCurves.toJS(),
    );

    return () => {
      setFieldValue(FormFields.PROJECT_DASHBOARD_CURVES, EMPTY_LIST.toJS());
    };
  }, [
    cheetahJobId,
    setFieldValue,
    projectDashboardId,
    projectDashboardCheetahJobCurves,
  ]);

  const initialState = useMemo(
    () => ({
      selectedRowIds: getInitialSelectedProjectDashboardCurveRowIds(
        projectDashboardCheetahJobCurves,
      ),
    }),
    [projectDashboardCheetahJobCurves],
  );

  return (
    <Grid container>
      <Grid
        container
        paddingTop={2}
        component={Box}
        paddingBottom={1}
        direction="column"
      >
        <Typography variant="h6">
          Data Sensors ({projectDashboardCheetahJobCurves.size})
        </Typography>
        <Typography variant="caption">
          Select up to <strong>eight</strong> sensors:
        </Typography>
      </Grid>
      <ProjectDashboardCurvesTable
        isEditable
        maxSelectedItems={8}
        initialState={initialState}
        items={projectDashboardCheetahJobCurves}
        fieldName={FormFields.PROJECT_DASHBOARD_CURVES}
        {...rest}
      />
    </Grid>
  );
};

export default ProjectDashboardCheetahJobCurvesTableContainer;
