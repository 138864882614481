import moment from 'moment';
import { useMemo, useCallback } from 'react';
import { IconButton, Link, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { Table, TableRowActionsCell, UserAvatar } from 'altus-ui-components';

import { ProjectPermission } from 'app/app.constants';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { SYNERGY_CASE_URL } from 'features/projects/incidents/incidents.constants';
import IncidentTableRow from 'features/projects/incidents/IncidentsTableRow';

const IncidentsTable = ({
  incidents,
  projectId,
  editIncident,
  deleteIncident,
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 2,
        Header: 'Case Number',
        accessor: (incident) => incident.get('caseNumber'),
        Cell: ({ row }) => {
          const url = SYNERGY_CASE_URL + row.original.get('caseNumber');
          return (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              title="Register Incident (SYNERGI)"
            >
              {row.original.get('caseNumber')}
            </Link>
          );
        },
      },
      {
        xs: 3,
        Header: 'Title',
        accessor: (incident) => incident.get('title'),
      },
      {
        xs: 5,
        Header: 'Author',
        Cell: ({ row }) => {
          const incident = row.original;
          return (
            <Grid container style={{ alignItems: 'center' }}>
              <Grid item xs={3}>
                <UserAvatar
                  avatar={incident.getIn(['author', 'avatar'])}
                  initials={incident.getIn(['author', 'initials'])}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="subtitle2">
                  {incident.getIn(['author', 'name'])}
                </Typography>
              </Grid>
            </Grid>
          );
        },
      },

      {
        xs: 4,
        Header: 'Created',
        accessor: (incident) => moment(incident.get('created')).calendar(),
      },
      {
        id: 'actions',
        Header: <TableRowActionsCell minItems={2} />,
        Cell: ({ row }) => {
          return (
            <TableRowActionsCell minItems={2}>
              <HasProjectPermission
                permissions={ProjectPermission.EDIT_INCIDENTS}
              >
                <IconButton
                  title="Edit"
                  onClick={() => editIncident(row.index)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </HasProjectPermission>
              <HasProjectPermission
                permissions={ProjectPermission.DELETE_INCIDENTS}
              >
                <IconButton
                  title="Delete"
                  onClick={() =>
                    deleteIncident(projectId, row.original.get('id'))
                  }
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </HasProjectPermission>
            </TableRowActionsCell>
          );
        },
      },
      {
        id: 'expansionIcon',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: () => (
          <TableRowActionsCell>
            <ExpandMoreIcon />
          </TableRowActionsCell>
        ),
      },
    ],
    [editIncident, deleteIncident, projectId],
  );

  const renderTableRowComponent = useCallback(
    (props) => <IncidentTableRow {...props} projectId={projectId} />,
    [projectId],
  );

  return (
    <Table
      items={incidents}
      columns={columns}
      useGlobalFilter={false}
      noItemsMessage={'No Incidents'}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

export default IncidentsTable;
