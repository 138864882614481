import { compose } from 'redux';
import classNames from 'classnames';
import { connect, useSelector } from 'react-redux';
import Refresh from '@material-ui/icons/Refresh';
import { Grid, Box, Fab } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { useCallback, useState, useEffect, useMemo } from 'react';
import { ACTIONS } from 'features/projects/wellbore/sections/projectWellboreSection.constants';

import { BasePage, TableHeader } from 'altus-ui-components';

import WellboreTrajectoryViewToggle, {
  TrajectoryViewType,
} from 'app/components/WellboreTrajectory/WellboreTrajectoryViewToggle';

import {
  getCurrentProjectWellboreFromState,
  getCurrentProjectWellboreTrajectoryFromState,
} from 'features/projects/wellbore/projectWellbore.selectors';

import {
  projectWellboreOnLoad,
  syncProjectWellboreTrajectoryWithLatest,
} from 'features/projects/wellbore/projectWellbore.actions';

import { EMPTY_MAP } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import {
  createProjectWellboreSectionsSelector,
  getCurrentProjectIdFromState,
} from 'features/projects/projects.selectors';
import { getProjectStatusLite } from 'features/projects/activities/activities.actions';
import { PROJECT_WELLBORE_ACTIONS } from 'features/projects/wellbore/projectWellbore.constants';
import WellboreTrajectoryDetails from 'app/components/WellboreTrajectory/WellboreTrajectoryDetails';
import useLatestProjectWellboreDetail from 'features/projects/hooks/useLatestProjectWellboreDetails';
import WellboreTrajectoryTableView from 'app/components/WellboreTrajectory/WellboreTrajectoryTableView';
import ProjectWellboreTrajectory3DView from 'features/projects/wellbore/survey/ProjectWellboreTrajectory3DView';
import { requestProjectWellboreSections } from 'features/projects/wellbore/sections/projectWellboreSection.actions';

const trajectoryTableInitialState = {
  pageSize: 100,
};

const ProjectWellboreOverview = ({
  classes,
  projectId,
  dataState,
  dispatchOnLoad,
  wellbore = EMPTY_MAP,
  trajectory = EMPTY_MAP,
  dispatchGetProjectStatus,
  dispatchSyncProjectWellboreTrajectoryWithLatest,
  dispatchRequestProjectWellboreSections,
}) => {
  useEffect(() => {
    dispatchRequestProjectWellboreSections(projectId);
  }, [projectId, dispatchRequestProjectWellboreSections]);

  const [wellboreId, setWellboreId] = useState();
  useEffect(() => {
    dispatchOnLoad(projectId);
  }, [projectId, dispatchOnLoad]);

  useEffect(() => {
    // Fetch status again if trajectory changes, in case we need to ask/stop asking for confirmation before updating again.
    dispatchGetProjectStatus(projectId);
  }, [projectId, trajectory, dispatchGetProjectStatus]);

  const [latestWellboreDetail = EMPTY_MAP] =
    useLatestProjectWellboreDetail(projectId);

  useEffect(() => {
    if (latestWellboreDetail.get('wellboreId'))
      setWellboreId(latestWellboreDetail.get('wellboreId'));
  }, [latestWellboreDetail]);

  const [viewType, setViewType] = useState(TrajectoryViewType.THREE_D);

  const projectWellboreSectionsSelector = useMemo(
    () => createProjectWellboreSectionsSelector(projectId),
    [projectId],
  );

  const wellboreSections = useSelector(projectWellboreSectionsSelector);

  const renderTableHeaderComponent = useCallback(
    (props) => (
      <TableHeader
        {...props}
        classes={{
          sticky: classes.stickyHeader,
        }}
      />
    ),
    [classes],
  );

  return (
    <BasePage
      dataState={dataState}
      LoadingOverlayProps={{
        timeout: 0,
      }}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid xs item container>
        {viewType === TrajectoryViewType.THREE_D && wellboreId && (
          <ProjectWellboreTrajectory3DView
            trajectory={trajectory}
            wellboreDetail={latestWellboreDetail}
            wellboreId={wellboreId}
            wellbore={wellbore}
            wellboreSections={wellboreSections}
          />
        )}
        {viewType === TrajectoryViewType.TABLE && (
          <>
            <Grid item xs={2} />
            <Grid item xs={8} container component={Box} paddingBottom={2}>
              <WellboreTrajectoryTableView
                initialState={trajectoryTableInitialState}
                TableHeaderComponent={renderTableHeaderComponent}
                unit={trajectory.getIn(['measuredDepth', 'unit'])}
                trajectoryPoints={trajectory.get('trajectoryPoints')}
              />
            </Grid>
          </>
        )}
        <Grid
          item
          xs={2}
          component={Box}
          paddingLeft={2}
          className={classNames({
            [classes.wellboreDetails]: viewType === TrajectoryViewType.THREE_D,
          })}
        >
          <Grid container className={classes.stickySection}>
            <Grid container item component={Box} marginBottom={2}>
              <WellboreTrajectoryDetails
                wellbore={wellbore}
                trajectory={trajectory}
              />
            </Grid>
            {!!trajectory.get('trajectoryPoints')?.size && (
              <WellboreTrajectoryViewToggle
                viewType={viewType}
                setViewType={setViewType}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Fab
        onClick={() =>
          dispatchSyncProjectWellboreTrajectoryWithLatest(projectId)
        }
        color="primary"
        title="Sync with latest"
        className={classes.fab}
      >
        <Refresh />
      </Fab>
    </BasePage>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    flexDirection: 'row',
    paddingTop: theme.spacing(3),
  },
  wellboreDetails: {
    width: '100%',
    position: 'absolute',
    right: theme.spacing(3),
  },
  stickySection: {
    top: 0,
    position: 'sticky',
  },
  fab: {
    zIndex: 2,
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
});

export default compose(
  connect(
    (state) => ({
      project: getCurrentProjectIdFromState(state),
      wellbore: getCurrentProjectWellboreFromState(state),
      trajectory: getCurrentProjectWellboreTrajectoryFromState(state),
      dataState: getSummarizedDataStateFromState(
        state,
        PROJECT_WELLBORE_ACTIONS.PROJECT_WELLBORE_ON_LOAD,
        ACTIONS.REQUEST_PROJECT_WELLBORE_SECTIONS,
      ),
    }),
    {
      dispatchOnLoad: projectWellboreOnLoad,
      dispatchGetProjectStatus: getProjectStatusLite,
      dispatchSyncProjectWellboreTrajectoryWithLatest:
        syncProjectWellboreTrajectoryWithLatest,
      dispatchRequestProjectWellboreSections: requestProjectWellboreSections,
    },
  ),
  withStyles(styles),
)(ProjectWellboreOverview);
