import React from 'react';
import { useSelector } from 'react-redux';
import Launch from '@material-ui/icons/Launch';
import { Grid, Typography, Button } from '@material-ui/core';

import { useModal } from 'altus-modal';

import PaperListItem from 'app/components/PaperListItem';
import { MODAL } from 'features/projects/npt/npt.constants';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { TASK_URLS } from 'features/projects/activities/activities.constants';
import CreateNPTModal from 'features/projects/npt/components/CreateNPTModal';
import TaskSelectItem from 'features/projects/activities/components/TaskSelectItem';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';

export const TasksMenu = ({ tasks, selected, onSelect }) => {
  const execute = useSelector(getExecuteStatusFromState);
  const project = useSelector(getCurrentProject);
  const [isOpen, toggleModal] = useModal(MODAL.ADD_PROJECT_NPT);

  return (
    <Grid container direction="column">
      <Grid item>
        <PaperListItem isHeader>
          <Typography variant="subtitle2">Task</Typography>
        </PaperListItem>
      </Grid>
      <Grid xs item container>
        {tasks.map((task) => {
          const id = task.get('id').toString();
          return (
            <TaskSelectItem
              task={task}
              key={id}
              isSelected={selected === id}
              to={() => onSelect(id)}
              progress={
                id === execute?.getIn(['current', 'task', 'id'])?.toString()
                  ? execute.get('currentTaskProgress')
                  : null
              }
            />
          );
        })}
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Button
          color="default"
          variant="contained"
          fullWidth
          onClick={toggleModal}
        >
          Register NPT
        </Button>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Button
          component="a"
          color="default"
          target="_blank"
          variant="contained"
          rel="noopener noreferrer"
          href={TASK_URLS.REGISTER_OBS_CARD_URL}
          title="Go to Register OBS Card"
          endIcon={<Launch />}
          fullWidth
        >
          Register OBS Card
        </Button>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Button
          component="a"
          color="default"
          target="_blank"
          variant="contained"
          rel="noopener noreferrer"
          href={TASK_URLS.REGISTER_INCIDENT_SYNERGY}
          title="Register Incident (SYNERGI)"
          endIcon={<Launch />}
          fullWidth
        >
          Register incident (synergi)
        </Button>
        <CreateNPTModal
          isOpen={isOpen}
          toggleModal={toggleModal}
          project={project}
        />
      </Grid>
    </Grid>
  );
};
