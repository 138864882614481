import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { useEffect, useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import { useModal } from 'altus-modal';
import { useHeader } from 'app/hooks/useHeader';
import { getActionDataStateFromState } from 'app/app.selectors';

import {
  MODAL,
  EQUIPMENT_ACTIONS,
} from 'features/equipment/equipment.constants';

import {
  getSurfaceEquipmentFromState,
  getSurfaceEquipmentGroupsFromState,
} from 'features/equipment/equipment.selectors';

import {
  onUnload,
  onLoadSurfaceEquipment,
  searchSurfaceEquipment,
} from 'features/equipment/equipment.actions';

import SurfaceEquipmentFilter, {
  Filters,
} from 'features/equipment/surfaceEquipment/components/SurfaceEquipmentFilter';

import { EMPTY_STRING, EMPTY_LIST, SystemPermission } from 'app/app.constants';
import HasSystemPermission from 'app/components/HasSystemPermission';

import SurfaceEquipmentTable from 'features/equipment/surfaceEquipment/components/SurfaceEquipmentTable';
import AddSurfaceEquipmentModalContainer from 'features/equipment/surfaceEquipment/surfaceEquipmentModalForm/AddSurfaceEquipmentModalContainer';

const defaultFormValues = {
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
  [Filters.TOOL_GROUPS]: EMPTY_LIST,
  [Filters.SERIAL_SEARCH]: EMPTY_STRING,
};

const SurfaceEquipmentsContainer = ({
  tool,
  classes,
  toolGroups,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchOnSearch,
}) => {
  useHeader({ subTitle: breadcrumb, logoNavigation: '/' });

  const [addToolModal, toggleAddSurfaceEquipmentlModal] = useModal(
    MODAL.ADD_SURFACE_EQUIPMENT,
  );

  useEffect(() => {
    dispatchOnLoad();

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload]);

  const handleSearch = useCallback(
    (filterValues) => {
      dispatchOnSearch({
        [Filters.TEXT_SEARCH]: filterValues[Filters.TEXT_SEARCH],
        [Filters.SERIAL_SEARCH]: filterValues[Filters.SERIAL_SEARCH],
        [Filters.TOOL_GROUPS]:
          filterValues[Filters.TOOL_GROUPS] === '' ||
          filterValues[Filters.TOOL_GROUPS] === EMPTY_LIST
            ? null
            : [filterValues[Filters.TOOL_GROUPS]],
      });
    },
    [dispatchOnSearch],
  );

  return (
    <>
      <AddSurfaceEquipmentModalContainer
        tool={tool}
        toolGroups={toolGroups}
        openModal={addToolModal}
        toggleModal={toggleAddSurfaceEquipmentlModal}
      />

      <Grid container>
        <Grid item xs={12} container direction="column">
          <Grid
            item
            container
            alignItems="center"
            className={classes.filterContainer}
          >
            <Grid item xs={4}>
              <SurfaceEquipmentFilter
                toolGroups={toolGroups}
                onSubmit={handleSearch}
                initialValues={defaultFormValues}
              />
            </Grid>
            <HasSystemPermission permissions={[SystemPermission.ITEM_TAB_EDIT]}>
              <Grid item xs={2}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => toggleAddSurfaceEquipmentlModal(true)}
                >
                  Add equipment
                </Button>
              </Grid>
            </HasSystemPermission>
          </Grid>
          <Grid item container className={classes.tableContainer}>
            <SurfaceEquipmentTable tools={tool} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  basePageChildren: {
    flexDirection: 'row',
    paddingTop: theme.spacing(2.25),
  },
  filterContainer: {
    paddingBottom: '10px',
  },
});

export default compose(
  connect(
    (state) => ({
      tool: getSurfaceEquipmentFromState(state),
      toolGroups: getSurfaceEquipmentGroupsFromState(state),
      dataState: getActionDataStateFromState(
        state,
        EQUIPMENT_ACTIONS.SURFACE_EQUIPMENT_LOADED,
        EQUIPMENT_ACTIONS.RECEIVE_SURFACE_EQUIPMENT_GROUPS,
      ),
    }),
    {
      dispatchOnLoad: onLoadSurfaceEquipment,
      dispatchOnUnload: onUnload,
      dispatchOnSearch: searchSurfaceEquipment,
    },
  ),
  withStyles(styles),
)(SurfaceEquipmentsContainer);
