import { useMemo } from 'react';

import { LoadingOverlay } from 'altus-ui-components';

import routePaths from 'app/routePaths';
import { EMPTY_MAP } from 'app/app.constants';
import { NavigationAppBarForTaskRoutes } from 'app/components/NavigationAppBar';
import { TaskType } from 'features/projects/tasks/tasks.constants';
import ProjectRoutesContainer from 'app/components/ProjectRoutesContainer';

const TaskRoutesContainer = ({
  taskId,
  routes,
  dataState,
  task = EMPTY_MAP,
}) => {
  const filteredTaskRoutes = useMemo(
    () =>
      routes.map(({ subSurfaceRoute, ...route }) =>
        subSurfaceRoute && task.get('type') === TaskType.SURFACE
          ? {
              ...route,
              hidden: true,
              redirectTo: routePaths.plan.task.index,
            }
          : route,
      ),
    [task, routes],
  );

  const isTaskTypeSurface = useMemo(
    () => task.get('type') === TaskType.SURFACE,
    [task],
  );

  if (dataState.isLoading()) {
    return <LoadingOverlay dataState={dataState} />;
  }

  return (
    <>
      <ProjectRoutesContainer key={taskId} routes={filteredTaskRoutes} />
      <NavigationAppBarForTaskRoutes
        routes={routes}
        isTaskTypeSurface={isTaskTypeSurface}
      />
    </>
  );
};

export default TaskRoutesContainer;
