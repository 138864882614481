import React from 'react';
import { Grid } from '@material-ui/core';
import IntegritiesTableLocation from 'features/settings/integrityManagement/integrities/IntegritiesTableLocation';

const IntegrityLocationContainer = ({
  integrities,
  parentId,
  setParentId,
  integrityType,
}) => (
  <Grid container justifyContent="flex-start">
    <Grid item xs={6}>
      <IntegritiesTableLocation
        parentId={parentId}
        setParentId={setParentId}
        integrities={integrities}
        integrityType={integrityType}
      />
    </Grid>
  </Grid>
);

export default IntegrityLocationContainer;
