import React from 'react';
import { IntegrityForm as FormFields } from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { Grid } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';

const IntegrityCategoryInformationContainer = ({ onTitleChange }) => {
  const { setFieldValue } = useFormikContext();

  const handleTitleChange = (event) => {
    const newTitle = event.target.value;

    setFieldValue(FormFields.TITLE, newTitle);
    onTitleChange(newTitle);
  };

  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item xs={12} container>
            <Field
              type="string"
              label="Title"
              component={TextFieldFormik}
              name={FormFields.TITLE}
              placeholder=""
              onChange={handleTitleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntegrityCategoryInformationContainer;
