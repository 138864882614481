import { call, put } from 'redux-saga/effects';
import { ACTIVITIES_ACTIONS } from 'features/projects/activities/activities.constants';
import projectService from 'services/project.service';
import activitiesService from 'services/activities.service';
import { APP_ACTIONS } from 'app/app.constants';
import taskService from 'services/task.service';

export function* getAllActivitiesForTask(projectId, taskId) {
  const allActivities = yield call(
    activitiesService.allActivitiesForTask,
    projectId,
    taskId,
  );
  yield put({
    taskId,
    payload: allActivities,
    type: ACTIVITIES_ACTIONS.GET_TASK_ACTIVITIES,
  });

  return allActivities;
}

export function* dispatchGetProjectStatus(status) {
  yield put({
    type: ACTIVITIES_ACTIONS.GET_PROJECT_STATUS,
    payload: status,
  });
}

export function* getProjectStatus(projectId) {
  const status = yield call(projectService.getProjectStatus, projectId);
  yield call(dispatchGetProjectStatus, status);
  return status;
}

export function* sendProjectChangedNotification(connection, projectId, taskId) {
  const notificationResponse = yield call(
    activitiesService.sendProjectChangedNotification,
    connection,
    projectId,
    taskId,
  );
  yield put({
    payload: notificationResponse,
    type: ACTIVITIES_ACTIONS.SEND_ACTIVITY_UPDATED_NOTIFICATION,
  });
}

export function* Identity(...args) {
  return yield [...args];
}

export function* toggleModal(action) {
  const { modalId, force = false, activeIndex, payload, ...extra } = action;
  yield put({
    type: APP_ACTIONS.TOGGLE_MODAL,
    modalId,
    force,
    payload,
    activeIndex,
    ...extra,
  });
  return payload;
}

export function* getAllTask(projectId) {
  const payload = yield call(taskService.getAllTasks, projectId);
  yield put({
    payload,
    type: ACTIVITIES_ACTIONS.GET_ALL_TASKS,
  });
  return payload;
}
