import { compose } from 'redux';
import usePWA from 'react-pwa-install-prompt';
import { Grid, Button } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import PwaInstallPopupIOS from 'react-pwa-install-ios';
import withStyles from '@material-ui/styles/withStyles';
import { IOSView, AndroidView, isMobile } from 'react-device-detect';
import { useEffect, useState, useCallback, useMemo, useRef } from 'react';

import { BasePage } from 'altus-ui-components';
import { NotRequestedDataState } from 'altus-datastate';

import {
  receiveOperations,
  searchOnlineOperations,
} from 'features/operations/operations.actions';

import {
  EMPTY_STRING,
  PUBLIC_ASSETS_URL,
  LIVE_OPERATIONS_APP_NAME,
  EUROPE_MAP_CENTER_COORDS,
} from 'app/app.constants';

import { useHeader } from 'app/hooks/useHeader';
import OperationsViews from 'features/operations/OperationsViews';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { getCurrentClientOrganizationIdFromState } from 'app/app.selectors';
import { OPERATIONS_VIEWS } from 'features/operations/operations.constants';
import { OPERATIONS_ACTIONS } from 'features/operations/operations.constants';
import { initialFilters } from 'features/operations/components/OperationFilter';
import { getOperationsFromState } from 'features/operations/operations.selectors';
import OperationFilterContainer from 'features/operations/components/OperationFilterContainer';
import ProjectMapLocations from 'features/projects/components/WellMapLocations/ProjectMapLocations';
import useLiveOperationsConnection from 'features/projects/dashboard/hooks/useLiveOperationsConnection';

const OperationsContainer = ({
  classes,
  operations,
  breadcrumb,
  dispatchSearchOperations,
  dispatchReceiveOperations,
  dataState = NotRequestedDataState,
}) => {
  const scrollContainerRef = useRef();

  const [filters, setFilters] = useState(initialFilters);
  const [selectedView, setSelectedView] = useState(OPERATIONS_VIEWS.TILES);
  const { isStandalone, isInstallPromptSupported, promptInstall } = usePWA();
  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromState,
  );

  useHeader({
    title: EMPTY_STRING,
    subTitle: breadcrumb,
  });

  useEffect(() => {
    const updatedFilters = {
      ...initialFilters,
      organizationId: currentClientOrganizationId,
    };

    setFilters(updatedFilters);
    dispatchSearchOperations(updatedFilters);
  }, [currentClientOrganizationId, dispatchSearchOperations]);

  const toggleViewChange = useCallback((_event, value) => {
    if (!value) return;

    setSelectedView(value);
  }, []);

  const onFiltersSubmit = useCallback(
    (updatedFilters) => {
      setFilters(updatedFilters);
      dispatchSearchOperations(updatedFilters);
    },
    [dispatchSearchOperations],
  );

  useLiveOperationsConnection(filters, dispatchReceiveOperations);

  const onClickInstall = async () => {
    const didInstall = await promptInstall();
    if (didInstall) {
      // User accepted PWA install
    }
  };

  const projects = useMemo(
    () => operations.map((operation) => operation.get('project')),
    [operations],
  );

  const basePageClasses = useMemo(
    () => ({
      children: classes.basePageChildren,
    }),
    [classes],
  );

  return (
    <BasePage dataState={dataState} classes={basePageClasses}>
      <ProjectMapLocations
        projects={projects}
        center={EUROPE_MAP_CENTER_COORDS}
      />
      <Grid
        container
        wrap="nowrap"
        direction="column"
        ref={scrollContainerRef}
        className={classes.content}
      >
        <OperationFilterContainer
          filters={filters}
          onSubmit={onFiltersSubmit}
          selectedView={selectedView}
          onViewChange={toggleViewChange}
          organizationId={currentClientOrganizationId}
        />
        <OperationsViews
          dataState={dataState}
          operations={operations}
          selectedView={selectedView}
          scrollContainerRef={scrollContainerRef}
        />
      </Grid>
      <IOSView>
        <PwaInstallPopupIOS
          delay={3}
          lang="en"
          appName={LIVE_OPERATIONS_APP_NAME}
          appIcon={`${PUBLIC_ASSETS_URL}/images/pwa/dwi192.png`}
        />
      </IOSView>
      <AndroidView>
        <Grid container spacing={2} className={classes.androidContent}>
          <Grid item xs={12}>
            {isInstallPromptSupported && !isStandalone && (
              <Button
                variant="contained"
                color="primary"
                style={{ width: '100%' }}
                onClick={onClickInstall}
              >
                Install LiveOps app
              </Button>
            )}
          </Grid>
        </Grid>
      </AndroidView>
    </BasePage>
  );
};

const styles = (theme) => ({
  androidContent: {
    padding: theme.spacing(1.25),
    paddingLeft: isMobile ? 0 : theme.spacing(1.25),
    paddingRight: isMobile ? 0 : theme.spacing(1.25),
  },
  container: {
    padding: theme.spacing(6),
  },
  icon: {
    fontSize: theme.spacing(8),
  },
  basePageChildren: {
    padding: 0,
  },
  content: {
    top: 0,
    bottom: 0,
    overflow: 'auto',
    position: 'absolute',
    paddingLeft: isMobile ? 0 : theme.spacing(1),
    paddingRight: isMobile ? 0 : theme.spacing(1),
  },
});

export default compose(
  connect(
    (state) => ({
      operations: getOperationsFromState(state),
      dataState: getSummarizedDataStateFromState(
        state,
        OPERATIONS_ACTIONS.SEARCH_OPERATIONS,
      ),
    }),
    {
      dispatchReceiveOperations: receiveOperations,
      dispatchSearchOperations: searchOnlineOperations,
    },
  ),
  withStyles(styles),
)(OperationsContainer);
