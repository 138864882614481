import CorrelationDashboard from 'features/projects/dashboard/components/dashboards/logging/components/CorrelationDashboard';
import LoggingDashboardContainer from 'features/projects/dashboard/components/dashboards/logging/LoggingDashboardContainer';
import ProjectExecutionStatusContainer from 'features/projects/dashboard/components/ProjectExecutionStatusContainer/ProjectExecutionStatusContainer';
import DashboardGaugeChartsContainer from 'features/projects/dashboard/components/charts/DashboardGaugeChartsContainer';
import useProjectDashboardHubConnection from 'features/projects/dashboard/hooks/useProjectDashboardHubConnection';
import WellGraphAndGuagesDataContainer from 'app/components/WellGraphAndGuages/WellGraphAndGuagesDataContainer';
import React, { useMemo } from 'react';
import useQueryParams from 'features/projects/dashboard/hooks/useQueryParams';

const WellGraphAndGuagesController = ({
  projectId,
  renderSlots,
  projectDashboardId,
}) => {
  const { curves, setExtremes, registerDataPointsHandler } =
    useProjectDashboardHubConnection(projectId, projectDashboardId);
  const { taskId = null } = useQueryParams();

  const depthCurve = useMemo(
    () => curves.find((curve) => curve.get('isDepth')),
    [curves],
  );

  return renderSlots(
    <WellGraphAndGuagesDataContainer
      curve={depthCurve}
      projectId={projectId}
      registerDataPointsHandler={registerDataPointsHandler}
      taskId={taskId}
    />,
    <LoggingDashboardContainer
      curves={curves}
      projectId={projectId}
      setExtremes={setExtremes}
      component={CorrelationDashboard}
      registerDataPointsHandler={registerDataPointsHandler}
    />,
    <DashboardGaugeChartsContainer
      curves={curves}
      registerDataPointsHandler={registerDataPointsHandler}
    />,
    <ProjectExecutionStatusContainer projectId={projectId} />,
  );
};

export default WellGraphAndGuagesController;
