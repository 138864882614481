import { compose } from 'redux';
import { Grid, Typography } from '@material-ui/core';
import { useSelector, connect } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { useCallback, useEffect } from 'react';

import { BasePage } from 'altus-ui-components';

import { getSummarizedDataStateFromState } from 'app/app.selectors';

import RiskSectionsTable from 'features/projects/components/RiskSectionsTable';
import {
  requestCreateProjectSection,
  requestDeleteProjectSection,
  requestProjectRiskSections,
  requestTaskRiskByProject,
  requestUpdateProjectSection,
} from 'features/projects/details/details.actions.js';
import { useMemo } from 'react';
import { RISK_TABLE_ACTIONS } from 'features/projects/tasks/task/details/taskDetails.constants';
import {
  createProjectRiskSectionsSelector,
  createTaskRiskSectionByProjectSelector,
} from 'features/projects/projects.selectors';
import ProjectRiskByTaskTable from 'features/projects/details/projectRisk/ProjectRiskByTaskTable';

const ProjectRiskContainer = ({
  classes,
  dataState,
  projectId,
  dispatchRequestCreateProjectRiskSection,
  dispatchRequestUpdateProjectRiskSection,
  dispatchRequestDeleteProjectRiskSection,
  dispatchRequestProjectRiskSectionsForTaskRisk,
  defaultTaskRiskSection,
  dispatchRequestTaskRiskSectionsByProject,
  isArchive,
}) => {
  useEffect(() => {
    dispatchRequestProjectRiskSectionsForTaskRisk(projectId);
    dispatchRequestTaskRiskSectionsByProject(projectId);
  }, [
    dispatchRequestProjectRiskSectionsForTaskRisk,
    dispatchRequestTaskRiskSectionsByProject,
    projectId,
  ]);

  const createTaskRiskSection = useCallback(
    (risk, formik, callback) =>
      dispatchRequestCreateProjectRiskSection(
        projectId,
        risk,
        formik,
        callback,
      ),
    [dispatchRequestCreateProjectRiskSection, projectId],
  );

  const updateTaskRiskSection = useCallback(
    (risk) => {
      dispatchRequestUpdateProjectRiskSection(projectId, risk);
    },
    [dispatchRequestUpdateProjectRiskSection, projectId],
  );

  const deleteTaskRiskSection = useCallback(
    (riskId) => dispatchRequestDeleteProjectRiskSection(riskId, projectId),
    [dispatchRequestDeleteProjectRiskSection, projectId],
  );

  const projectRiskSectionsSelector = useMemo(
    () => createProjectRiskSectionsSelector(),
    [],
  );
  const taskRiskSectionsSelector = useMemo(
    () => createTaskRiskSectionByProjectSelector(),
    [],
  );

  const taskRiskSections = useSelector(taskRiskSectionsSelector);
  const projectRiskSections = useSelector(projectRiskSectionsSelector);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid
        xs={12}
        justifyContent="center"
        alignItems="center"
        style={{ paddingTop: isArchive ? '2vh' : 0 }}
      >
        <RiskSectionsTable
          createRiskSection={createTaskRiskSection}
          updateRiskSection={updateTaskRiskSection}
          deleteRiskSection={deleteTaskRiskSection}
          defaultRiskSection={defaultTaskRiskSection}
          riskSections={projectRiskSections}
          title="Operational Risks"
          isArchive={isArchive}
        />

        <br />
        <br />
        <Typography variant="h6">Task Risks</Typography>
        <Grid item xs={12}>
          <ProjectRiskByTaskTable
            items={taskRiskSections}
            projectId={projectId}
            isArchive={isArchive}
          />
        </Grid>
      </Grid>
    </BasePage>
  );
};

const styles = () => ({
  dragOver: {
    opacity: '0.3',
    border: '5px gray dashed',
  },
  basePageChildren: {
    flexDirection: 'row',
  },
});

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    RISK_TABLE_ACTIONS.REQUEST_PROJECT_RISK_SECTIONS,
    RISK_TABLE_ACTIONS.REQUEST_TASK_RISK_BY_PROJECT,
  ),
});

const mapDispatchToProps = {
  dispatchRequestCreateProjectRiskSection: requestCreateProjectSection,
  dispatchRequestUpdateProjectRiskSection: requestUpdateProjectSection,
  dispatchRequestDeleteProjectRiskSection: requestDeleteProjectSection,
  dispatchRequestProjectRiskSectionsForTaskRisk: requestProjectRiskSections,
  dispatchRequestTaskRiskSectionsByProject: requestTaskRiskByProject,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ProjectRiskContainer);
