import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useCallback } from 'react';

import { requestToggleToolstringItemBreakpoint } from 'features/projects/tasks/task/toolstring/toolstring.actions';

import { EMPTY_MAP } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { TOOLSTRING_ACTIONS } from 'features/projects/tasks/task/toolstring/toolstring.constants';
import ToolstringPreviewSections from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewSections';
import ToolstringPreviewContainer from 'features/projects/tasks/task/toolstring/components/ToolstringPreviewContainer';
import ToolAssemblyCreatorToolstringPreviewSection from 'features/equipment/toolAssemblies/components/ToolAssemblyCreatorToolstringPreviewSection';

const TaskToolstringPreview = ({
  taskId,
  editable,
  projectId,
  dataState,
  toolstringId,
  toolAssemblyCreator = false,
  toolstringSections = toolAssemblyCreator ? [] : EMPTY_MAP,
  dispatchToggleToolstringItemBreakpoint,
  placeHolderAlignBha,
  paddingTopPreview,
  showBreakPoint,
}) => {
  const onToggleBreakpoint = useCallback(
    (toolstringItem) =>
      dispatchToggleToolstringItemBreakpoint(
        projectId,
        taskId,
        toolstringId,
        toolstringItem.get('toolstringItemId'),
      ),
    [taskId, projectId, toolstringId, dispatchToggleToolstringItemBreakpoint],
  );

  return (
    <ToolstringPreviewContainer
      dataState={dataState}
      paddingTopPreview={paddingTopPreview}
    >
      {toolAssemblyCreator ? (
        <ToolAssemblyCreatorToolstringPreviewSection
          hideToolDetails
          toolstringSections={toolstringSections}
          onToggleBreakpoint={editable ? onToggleBreakpoint : undefined}
        />
      ) : (
        <ToolstringPreviewSections
          hideToolDetails
          toolstringSections={toolstringSections}
          onToggleBreakpoint={editable ? onToggleBreakpoint : undefined}
          placeHolderAlignBha={placeHolderAlignBha}
          showBreakPoint={showBreakPoint}
        />
      )}
    </ToolstringPreviewContainer>
  );
};

TaskToolstringPreview.propTypes = {
  editable: PropTypes.bool,
  taskId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  toolstringId: PropTypes.string.isRequired,
};

export default compose(
  connect(
    (state) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        TOOLSTRING_ACTIONS.GET_TOOLSTRING_FOR_TASK,
      ),
    }),
    {
      dispatchToggleToolstringItemBreakpoint:
        requestToggleToolstringItemBreakpoint,
    },
  ),
)(TaskToolstringPreview);
