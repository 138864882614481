import { Link } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';

import { TableRow } from 'altus-ui-components';

import { toWellbore } from 'utils/route.util';

const WellboreTableRow = ({ classes, item, ...rest }) => {
  const isWell = item.get('isWell');
  const isField = item.get('isField');

  if (isField) {
    return (
      <TableRow
        {...rest}
        item={item}
        classes={{
          root: classes.fieldRoot,
        }}
      />
    );
  }

  if (isWell) {
    return (
      <TableRow
        {...rest}
        item={item}
        classes={{
          root: classes.wellRoot,
        }}
      />
    );
  }

  return (
    <Link to={toWellbore(item.get('fieldId'), item.get('id'))}>
      <TableRow {...rest} item={item} title="Open well" />
    </Link>
  );
};

const styles = (theme) => ({
  wellRoot: {
    '&:hover': {
      background: theme.altus.components.TableRow.background,
    },
  },
  fieldRoot: {
    top: 0,
    position: 'sticky',
    background: theme.altus.components.TableRow.hoverColor,
    '&:hover': {
      background: theme.altus.components.TableRow.hoverColor,
    },
  },
});

export default withStyles(styles)(WellboreTableRow);
