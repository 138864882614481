import { Switch } from 'react-router-dom';

import RoutesContainer from 'app/components/RoutesContainer';
import NavigationAppBar from 'app/components/NavigationAppBar';

const EquipmentRoutingContainer = ({ routes }) => (
  <>
    <RoutesContainer routes={routes} />
    <Switch>
      <NavigationAppBar routes={routes} />
    </Switch>
  </>
);

export default EquipmentRoutingContainer;
