import { compose } from 'redux';
import isEqual from 'lodash/isEqual';
import { Box, Grid } from '@material-ui/core';
import GoogleMap from 'app/components/GoogleMap';
import withStyles from '@material-ui/styles/withStyles';
import { useSelector, connect, useDispatch } from 'react-redux';
import { useEffect, useMemo, useRef, useCallback, useState } from 'react';

import {
  getFacilityOperatorsFromState,
  getFacilitiesPageParamsFromState,
  getFacilitiesPageResultsFromState,
} from 'features/facilities/facilities.selectors';

import {
  getSummarizedDataStateFromState,
  getCurrentClientOrganizationIdFromState,
} from 'app/app.selectors';

import {
  onLoad,
  updateFacilitiesFilter,
} from 'features/facilities/facilities.actions';

import {
  EMPTY_STRING,
  PagedQueryParams,
  EUROPE_MAP_CENTER_COORDS,
} from 'app/app.constants';

import { useHeader } from 'app/hooks/useHeader';
import BasePage from 'app/components/BasePageDense';
import { Filters } from 'features/facilities/components/FacilitiesFilter';
import FacilitiesTable from 'features/facilities/components/FacilitiesTable';
import { FACILITIES_ACTIONS } from 'features/facilities/facilities.constants';
import FacilitiesFilter from 'features/facilities/components/FacilitiesFilter';

const defaultFormValues = {
  [Filters.FACILITY_TYPE]: EMPTY_STRING,
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
  [Filters.OPERATOR]: EMPTY_STRING,
  [Filters.ORGANIZATION_ID]: EMPTY_STRING,
  [Filters.FACILITY_STATUS]: EMPTY_STRING,
};

const FacilitiesContainer = ({
  classes,
  dataState,
  breadcrumb,
  dispatchOnLoad,
}) => {
  useHeader({
    subTitle: breadcrumb,
  });
  const dispatch = useDispatch();
  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromState,
  );

  useEffect(() => {
    dispatchOnLoad(currentClientOrganizationId);
  }, [dispatchOnLoad, currentClientOrganizationId]);

  const operators = useSelector(getFacilityOperatorsFromState);
  const pageParams = useSelector(getFacilitiesPageParamsFromState);
  const facilities = useSelector(getFacilitiesPageResultsFromState);
  const rowCount = pageParams.get('rowCount');

  const initialFilterValues = useMemo(
    () => ({
      ...defaultFormValues,
      organizationId: currentClientOrganizationId,
    }),
    [currentClientOrganizationId],
  );

  const onFiltersChange = (values) => {
    setNewFilters(values);
    setTableSize(50);
    setInitialState({ pageSize: 50 });
    dispatch(
      updateFacilitiesFilter({
        [PagedQueryParams.PAGE]: 1,
        [PagedQueryParams.PAGE_SIZE]: 50,
        ...values,
      }),
    );
  };

  const [tableSize, setTableSize] = useState(50);
  const [initialState, setInitialState] = useState({ pageSize: 50 });
  const [newFilters, setNewFilters] = useState(initialFilterValues);
  const componentRef = useRef(null);

  const handleScroll = useCallback(() => {
    const componentElement = componentRef.current;

    if (tableSize >= rowCount) return;
    const threshold = 1;

    if (
      componentElement.scrollTop + componentElement.clientHeight + threshold >=
      componentElement.scrollHeight
    ) {
      const filter = {
        [PagedQueryParams.PAGE]: 1,
        [PagedQueryParams.PAGE_SIZE]:
          tableSize + 50 > rowCount
            ? tableSize + (rowCount - tableSize)
            : tableSize + 50,
        ...(isEqual(initialFilterValues, newFilters)
          ? initialFilterValues
          : newFilters),
      };
      dispatch(updateFacilitiesFilter(filter));
      const newTableSize = tableSize + 50;
      setTableSize(newTableSize);
      setInitialState({ pageSize: newTableSize });
    }
  }, [
    tableSize,
    rowCount,
    initialFilterValues,
    newFilters,
    dispatch,
  ]);

  useEffect(() => {
    const filter = {
      [PagedQueryParams.PAGE]: 1,
      [PagedQueryParams.PAGE_SIZE]: 50,
      ...initialFilterValues,
    };
    dispatch(updateFacilitiesFilter(filter));
  }, [dispatch, initialFilterValues]);

  useEffect(() => {
    const componentElement = componentRef.current;

    componentElement.addEventListener('scroll', handleScroll);

    return () => {
      componentElement.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <BasePage dataState={dataState}>
      <GoogleMap center={EUROPE_MAP_CENTER_COORDS} />
      <Grid
        container
        ref={componentRef}
        className={classes.content}
        justifyContent="flex-end"
      >
        <Grid
          item
          sm={7}
          xs={12}
          container
          component={Box}
          paddingLeft={2}
          paddingRight={2}
          direction="column"
        >
          <FacilitiesFilter
            operators={operators}
            onSubmit={onFiltersChange}
            initialValues={initialFilterValues}
            currentClientOrganizationId={currentClientOrganizationId}
          />
          <FacilitiesTable
            facilities={facilities}
            initialState={initialState}
          />
        </Grid>
      </Grid>
    </BasePage>
  );
};

const styles = () => ({
  content: {
    top: 0,
    bottom: 0,
    overflow: 'auto',
    position: 'absolute',
  },
});

export default compose(
  connect(
    (state) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        FACILITIES_ACTIONS.GET_OPERATORS,
        FACILITIES_ACTIONS.FACILITIES_PAGE_LOADED,
        FACILITIES_ACTIONS.UPDATE_FACILITIES_FILTER,
      ),
    }),
    {
      dispatchOnLoad: onLoad,
      dispatchUpdateFilter: updateFacilitiesFilter,
    },
  ),
  withStyles(styles),
)(FacilitiesContainer);
