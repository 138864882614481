import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import _isNil from 'lodash/isNil';

const useQueryParams = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  let results = {};
  searchParams.entries().forEach(([k, v]) => {
    results[k] = null;
    if (!_isNil(v)) {
      const n = Number(v);
      results[k] = isNaN(n) ? v : n;
    }
  });

  return results;
};

export default useQueryParams;
