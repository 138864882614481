import { compose } from 'redux';
import { Formik, Form } from 'formik';
import { Dialog } from '@material-ui/core';
import { useCallback } from 'react';
import { Button, DialogContent } from '@material-ui/core';

import {
  ModalActions,
  ModalHeader,
  withModal,
  LoadingButton,
} from 'altus-ui-components';

import { CREATE_SMLOCATION_MODAL } from 'features/data-exchange/winch/smlocation.constants';
import CreateSMLocationForm from 'features/data-exchange/winch/CreateSMLocationForm';

const initValues = { name: '' };

const CreateSMLocationModalContainer = ({
  open,
  toggleModal,
  createSMLocation,
}) => {
  const onSubmit = useCallback(
    (smLocation) => createSMLocation(smLocation).then(toggleModal),
    [createSMLocation, toggleModal],
  );

  return (
    <Dialog open={open} fullWidth onClose={toggleModal}>
      <ModalHeader title="Add Device" />
      <Formik onSubmit={onSubmit} initialValues={initValues}>
        {({ handleSubmit }) => (
          <Form>
            <DialogContent>
              <CreateSMLocationForm />
            </DialogContent>
            <ModalActions>
              <Button onClick={toggleModal}>Cancel</Button>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Add
              </LoadingButton>
            </ModalActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default compose(withModal(CREATE_SMLOCATION_MODAL))(
  CreateSMLocationModalContainer,
);
