import { useMemo } from 'react';
import { List } from 'immutable';
import { useCallback } from 'react';
import { Box } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import { useModal } from 'altus-modal';
import { useRequest } from 'altus-hooks';

import {
  CREATE_PROJECT_MODAL_ID,
  CreateProjectFormFields as FormFields,
} from 'features/projects/projects.constants';

import mappers from 'mappers';
import appService from 'services/app.service';
import { Button } from 'app/components/withNavigation';
import useWellbore from 'features/wells/hooks/useWellbore';
import { EMPTY_LIST, SystemPermission } from 'app/app.constants';
import HasSystemPermission from 'app/components/HasSystemPermission';
import CreateProjectModalContainer from 'features/projects/components/CreateProjectModalContainer/CreateProjectModalContainer';

const getInitialItem = (items) => {
  if (items.size === 1) return items.first();

  return null;
};

const CreateProjectFromWellboreModalContainer = ({
  fieldId,
  className,
  wellboreId,
}) => {
  const [, toggleModal] = useModal(CREATE_PROJECT_MODAL_ID);

  const { wellbore } = useWellbore(wellboreId);

  const getFacilitiesByField = useCallback(
    () =>
      appService
        .facilitiesByFieldId()
        .then((facilities) => List(facilities).map(mappers.Facility.from)),
    [],
  );

  const [facilities = EMPTY_LIST] = useRequest(getFacilitiesByField);

  const initialFacility = getInitialItem(facilities);

  const getOrganizationsByField = useCallback(
    () =>
      appService
        .getOrganizationsByField(fieldId)
        .then((organizations) =>
          List(organizations).map(mappers.Organization.from),
        ),
    [fieldId],
  );

  const [organizations = EMPTY_LIST] = useRequest(getOrganizationsByField);

  const initialOrganization = getInitialItem(organizations);

  const initialValues = useMemo(
    () => ({
      [FormFields.FIELD_ID]: wellbore.get('fieldId'),
      [FormFields.FIELD_NAME]: wellbore.get('fieldName'),
      [FormFields.FACILITY_ID]: initialFacility?.get('id'),
      [FormFields.FACILITY_NAME]: initialFacility?.get('name'),
      [FormFields.WELLBORE_NAME]: wellbore.get('name'),
      [FormFields.WELLBORE_ID]: wellbore.get('wellboreId'),
      [FormFields.ORGANIZATION_ID]: initialOrganization?.get('id'),
      [FormFields.ORGANIZATION_NAME]: initialOrganization?.get('name'),
    }),
    [wellbore, initialFacility, initialOrganization],
  );

  return (
    <HasSystemPermission permissions={SystemPermission.CREATE_PROJECTS}>
      <Button
        size="small"
        color="primary"
        variant="contained"
        title="New project"
        onClick={toggleModal}
        className={className}
      >
        <Box component={Add} marginRight={0.5} />
        New project
      </Button>
      <CreateProjectModalContainer initialValues={initialValues} />
    </HasSystemPermission>
  );
};

export default CreateProjectFromWellboreModalContainer;
