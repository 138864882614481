import withStyles from '@material-ui/styles/withStyles';

import {
  Logo,
  LogoAndAppTitle as LogoAndAppTitleCore,
} from 'altus-ui-components';

import routePaths from 'app/routePaths';
import config from 'infrastructure/config/index';
import {
  ENVIRONMENTS_NAMES,
  APP_SHORTNAME,
  // PUBLIC_ASSETS_URL,
} from 'app/app.constants';

const LogoAndAppTitle = ({ to = routePaths.root, classes, onClick }) => (
  <LogoAndAppTitleCore
    spacing={1}
    title={APP_SHORTNAME}
    environmentName={ENVIRONMENTS_NAMES[config.environmentName]}
    renderLogo={() =>
      onClick ? (
        <div onClick={onClick} className={classes.logo}>
          <Logo
            rootUrl={null}
            classes={{
              logo: classes.logoRoot,
            }}
          />
          {/* Reverted the changes for Collaborate but commented it for future use
          <img
            className={classes.logoRoot}
            height="40"
            src={`${PUBLIC_ASSETS_URL}/images/logo/Collaborate Logo.svg`}
            alt="Collaborate"
          /> */}
        </div>
      ) : (
        <Logo
          rootUrl={to}
          classes={{
            logo: classes.logoRoot,
          }}
        />
        // <img
        //   className={classes.logoRoot}
        //   height="40"
        //   src={`${PUBLIC_ASSETS_URL}/images/logo/Collaborate Logo.svg`}
        //   alt="Collaborate"
        // />
      )
    }
    classes={{
      environmentName: classes.environmentName,
    }}
  />
);

const styles = (theme) => ({
  logoRoot: {
    height: theme.spacing(4) - 2,
    '&:hover': {
      opacity: 0.5,
    },
  },
  logo: {
    cursor: 'pointer',
  },
  environmentName: {
    color: theme.palette.environment[config.environmentName],
  },
});

export default withStyles(styles)(LogoAndAppTitle);
