import { Field } from 'redux-form/immutable';
import { Grid } from '@material-ui/core';

import { required } from 'utils/validation.util';
import TextField from 'app/components/form/TextField';
import ServiceSelectField from 'app/components/ServiceSelectField';
import { TASK_FORMS } from 'features/projects/tasks/tasks.constants';
import useTaskTypes from 'features/projects/tasks/hooks/useTaskTypes';
import DepartmentSelectField from 'app/components/DepartmentSelectField';
import TaskTypeSelect from 'features/projects/tasks/components/TaskTypeSelect';
import QuantityTextFieldReduxForm from 'app/components/form/redux-form/QuantityTextFieldReduxForm';
import EditorReduxForm from 'app/components/form/EditorField';

const { NEW_TASK } = TASK_FORMS;

const CreateTaskForm = ({ services, departments }) => {
  const [taskTypes, taskTypesDataState] = useTaskTypes();

  return (
    <Grid container style={{alignItems: 'center'}}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Field
              required
              autoFocus
              label="Title"
              validate={[required]}
              component={TextField}
              name={NEW_TASK.TASK_TITLE}
            />
          </Grid>
          <Grid item xs={6}>
            <TaskTypeSelect
              validate={[required]}
              taskTypes={taskTypes}
              name={NEW_TASK.TASK_TYPE}
              disabled={taskTypesDataState.isLoading()}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Description"
              name={NEW_TASK.TASK_DESCRIPTION}
              component={EditorReduxForm}
              xs={12}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Objective"
              name={NEW_TASK.TASK_OBJECTIVE}
              component={EditorReduxForm}
              xs={12}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              format={null}
              label="Departments"
              departments={departments}
              component={DepartmentSelectField}
              name={NEW_TASK.TASK_DEPARTMENTS}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              format={null}
              label="Sub Objective"
              services={services}
              component={ServiceSelectField}
              name={NEW_TASK.TASK_SERVICES}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Field
                name={NEW_TASK.TASK_TARGET_DEPTH}
                format={null}
                type="number"
                label="TD - Target Depth"
                component={QuantityTextFieldReduxForm}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateTaskForm;
