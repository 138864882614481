import { fromJS, Map } from 'immutable';
import { LOCATION_CHANGE } from 'connected-react-router/immutable';

import routePaths, { pages } from 'app/routePaths';
import { APP_ACTIONS } from 'app/app.constants';

export const initialState = fromJS({
  previous: Map(),
  location: Map(),
  action: '',
  globalLocation: '/',
});

const ignorePreviousPages = [pages.login, pages.events, pages.dashboard];

export const previous = (state = initialState, action) => {
  switch (action.type) {
    case APP_ACTIONS.PROJECT_SET_SUBPAGE: {
      return state.set('subPage', action.page);
    }
    case LOCATION_CHANGE: {
      const subPage = state.get('subPage');

      return state
        .update('previous', (previous) => {
          return subPage
            ? ignorePreviousPages.includes(subPage)
              ? previous
              : state.get('location')
            : previous;
        })
        .set('location', action.payload.location)
        .set('action', action.payload.action);
    }
    case 'SAVE_REDIRECT_PATH': {
      return state.set(
        'previous',
        action.payload !== routePaths.login ? action.payload : routePaths.root,
      );
    }
    case 'SET_GLOBAL_LOCATION':
      return state.set('globalLocation', action.payload);
    default:
      return state;
  }
};

export default previous;
