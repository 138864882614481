import { toggleModal } from 'app/app.actions';
import { initialize } from 'redux-form/immutable';

import projectService from 'services/project.service';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import { getFormValuesFromState } from 'app/app.selectors';
import nptMappers from 'features/projects/npt/npt.mappers';

import {
  NPT_ACTIONS,
  MODAL,
  NPT_FORM,
} from 'features/projects/npt/npt.constants';

export const selectProjectNonProductiveTime = (index) => (dispatch) => {
  return dispatch(
    toggleModal({ modalId: MODAL.EDIT_PROJECT_NPT, activeIndex: index }),
  );
};
export const onEditProjectNonProductiveTimeModalLoaded =
  (npt) => (dispatch) => {
    return dispatch(initialize(NPT_FORM.EDIT_ID, npt));
  };
export const createProjectNonProductiveTime =
  (projectId, projectNonProductiveTime) => (dispatch) => {
    const payload = projectService.createProjectNonProductiveTime(
      projectId,
      nptMappers.NPT.to(projectNonProductiveTime.toJS()),
    );

    dispatch({
      payload,
      type: NPT_ACTIONS.CREATE_PROJECT_NON_PRODUCTIVE_TIME,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]:
          'A new project non productive time was successfully created',
      },
    });

    return payload;
  };

export const updateProjectNonProductiveTime =
  (projectId) => (dispatch, getState) => {
    const npt = getFormValuesFromState(getState(), NPT_FORM.EDIT_ID).toJS();
    const payload = projectService
      .updateProjectNonProductiveTime(projectId, nptMappers.NPT.to(npt))
      .then((response) => {
        dispatch(toggleModal({ modalId: MODAL.EDIT_PROJECT_NPT }));
        return response;
      });
    dispatch({
      projectNonProductiveTimeId: npt.projectNonProductiveTimeId,
      type: NPT_ACTIONS.EDIT_PROJECT_NON_PRODUCTIVE_TIME,
      payload,
    });
    return payload;
  };

export const deleteProjectNonProductiveTime =
  (projectId, projectNonProductiveTimeId) => (dispatch) => {
    dispatch({
      type: NPT_ACTIONS.DELETE_PROJECT_NON_PRODUCTIVE_TIME,
      payload: () =>
        projectService.deleteProjectNonProductiveTime(
          projectId,
          projectNonProductiveTimeId,
        ),
      projectNonProductiveTimeId: projectNonProductiveTimeId,
      confirmationDialog: {
        confirmButtonText: 'Delete',
        title: 'Delete project non productive time',
        description:
          'Are you sure you want to delete this project non productive time?',
      },
    });
  };

export const getAllProjectNonProductives = (projectId) => (dispatch) =>
  dispatch({
    type: NPT_ACTIONS.PROJECT_NON_PRODUCTIVE_TIME_REPORT_LOADED,
    payload: () => projectService.getAllProjectNonProductiveTimes(projectId),
  });

export const onProjectNonProductiveReportUnload = () => (dispatch) =>
  dispatch({
    type: NPT_ACTIONS.PROJECT_NON_PRODUCTIVE_TIME_REPORT_UNLOADED,
  });
