import RadioGroupField from 'app/components/form/RadioGroupField';

const RadioGroupFieldFormik = ({
  field,
  options,
  disabled,
  ...props
}) => {
  const updatedOptions = options.map((o) => ({ ...o, disabled }));

  return <RadioGroupField {...props} options={updatedOptions} input={field} />;
};

export default RadioGroupFieldFormik;
