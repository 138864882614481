import { useCallback } from 'react';

import { useCurrentUser } from 'app/hooks/useCurrentUser';
import { hasOneOrMorePermissions } from 'utils/authorization.util';
import ValidRoutesContainer from 'app/components/ValidRoutesContainer';

const SystemRoutesContainer = ({ render, routes }) => {
  const currentUser = useCurrentUser();

  const hasRoutePermission = useCallback(
    (route) => {
      if (!route.systemPermissions) return true;

      const permissions = currentUser.get('permissions');

      return hasOneOrMorePermissions(permissions, route.systemPermissions);
    },
    [currentUser],
  );

  return (
    <ValidRoutesContainer
      routes={routes}
      render={render}
      hasPermission={hasRoutePermission}
    />
  );
};

export default SystemRoutesContainer;
