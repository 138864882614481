import { useMemo } from 'react';
import { isImmutable } from 'immutable';
import { IconButton } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import Counter from 'app/components/Counter';
import { formatDate, formatDuration } from 'utils/format.util';
import { EMPTY_LIST, WORK_ITEM_STATUS } from 'app/app.constants';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import ActivityCommentModal from 'features/projects/tasks/task/activities/ActivityCommentModal';
import EditorStateViewer from 'app/components/EditorStateViewer';

const getSubRows = (row) =>
  isImmutable(row)
    ? row.get('subActivities')?.toArray()
    : row.original?.subActivities?.toArray();

const TaskActivitiesTableSimple = ({
  displayComments,
  activities = EMPTY_LIST,
  ...rest
}) => {
  const initialState = useMemo(
    () => ({
      hiddenColumns: displayComments ? [] : ['comments'],
    }),
    [displayComments],
  );

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 1,
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (!row.canExpand) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <IconButton
                {...row.getToggleRowExpandedProps({
                  ...row.getToggleRowExpandedProps({}),
                })}
                style={{
                  marginRight: `${1}rem`,
                }}
              >
                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
      {
        id: 'status',
        xs: 1,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const activity = row.original;

          return (
            <TableRowActionsCell>
              <WorkItemStatus
                status={activity.get('status')}
                isWaiting={activity.get('isWait')}
                isPointInTime={activity.get('isPointInTime')}
              />
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 3,
        Header: 'Activity',
        accessor: (activity) => activity.get('name'),
      },
      {
        xs: 6,
        Header: 'Description',
        Cell: ({ row }) => {
          const activity = row.original;
          if (!activity.get('description')) return null;
          return (
            <EditorStateViewer editorState={activity.get('description')} />
          );
        },
      },
      {
        xs: 2,
        Header: 'Started',
        accessor: (activity) => formatDate(activity.get('startTime')),
      },
      {
        xs: 2,
        Header: 'Duration',
        Cell: ({ row }) => {
          const activity = row.original;

          if (activity.get('isPointInTime')) return null;

          const isTimerActive =
            activity.get('status') === WORK_ITEM_STATUS.STARTED;

          return (
            <Counter
              isTimerActive={isTimerActive}
              key={activity.get('startTime')}
              endTime={activity.get('endTime')}
              startTime={activity.get('startTime')}
              calculateDurationFromStartAndEndtime
              renderValue={({ elapsedTime }) => formatDuration(elapsedTime)}
            />
          );
        },
      },
      {
        id: 'comments',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => (
          <TableRowActionsCell minItems={1}>
            <ActivityCommentModal activity={row.original} />
          </TableRowActionsCell>
        ),
      },
    ],
    [],
  );

  return (
    <Table
      columns={columns}
      items={activities ?? EMPTY_LIST}
      initialState={initialState}
      useExpanded
      stickyHeader
      getSubRows={getSubRows}
      {...rest}
    />
  );
};

export default TaskActivitiesTableSimple;
