import withStyles from '@material-ui/styles/withStyles';

import { HeaderBase } from 'altus-ui-components';

const ApplicationHeaderBase = ({ classes, children }) => (
  <HeaderBase
    disableGutters
    classes={{
      root: classes.headerBaseRoot,
    }}
  >
    {children}
  </HeaderBase>
);

const styles = (theme) => ({
  headerBaseRoot: {
    maxHeight: theme.spacing(8),
    background: theme.altus.background.header,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1)}px`,
  },
});

export default withStyles(styles)(ApplicationHeaderBase);
