export const ACTIONS = {
  REQUEST_SIMULATION_PARAMETERS: 'REQUEST_SIMULATION_PARAMETERS',
  REQUEST_CREATE_SIMULATION_PARAMETER: 'REQUEST_CREATE_SIMULATION_PARAMETER',
  REQUEST_DELETE_SIMULATION_PARAMETER: 'REQUEST_DELETE_SIMULATION_PARAMETER',
  REQUEST_UPDATE_SIMULATION_PARAMETER: 'REQUEST_UPDATE_SIMULATION_PARAMETER',

  DELETE_SIMULATION_PARAMETER: 'DELETE_SIMULATION_PARAMETER',
  RECEIVE_SIMULATION_PARAMETER: 'RECEIVE_SIMULATION_PARAMETER',
  RECEIVE_SIMULATION_PARAMETERS: 'RECEIVE_SIMULATION_PARAMETERS',

  LOAD_SIMULATION_PARAMETERS_PAGE: 'LOAD_SIMULATION_PARAMETERS_PAGE',
};
