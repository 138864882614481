import { useCallback } from 'react';
import { AutocompleteTextField } from 'app/components/form/AutocompleteTextField';
import { searchM3Items } from 'features/equipment/equipment.actions';
import { FormFields } from 'features/equipment/m3Components/M3ModalForm';

export const AutocompleteTextFieldAdapter = ({
  autoCompleteValue,
  setAutocompleteValue,
  placeholder,
  form,
}) => {
  const getOptionLabel = useCallback((option) => option.m3ItemName, []);
  const valueSelectorFromAutocomplete = useCallback(
    (newValue) => (newValue ? newValue.m3ItemNumber : null),
    [],
  );
  // There are no initial values here so this is NO-OP
  const setInitialFieldValue = useCallback(() => {}, []);

  return (
    <AutocompleteTextField
      form={form}
      autoCompleteValue={autoCompleteValue}
      setAutocompleteValue={setAutocompleteValue}
      formFieldName={FormFields.M3_ITEM}
      getOptionLabel={getOptionLabel}
      valueSelectorFromAutocomplete={valueSelectorFromAutocomplete}
      setInitialFieldValue={setInitialFieldValue}
      fetchAutocompleteDataAction={searchM3Items}
      placeholder={placeholder}
      id={'auto-complete-m3'}
    />
  );
};
