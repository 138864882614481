import { createSelector } from 'utils/app.util';

export const createProjectDashboardsSelector = (projectId) =>
  createSelector(
    (state) => state.getIn(['entity', 'projectDashboard']),
    (projectDashboards) => projectDashboards.get(projectId.toString()),
  );

export const createProjectDashboardSelector = (projectId, projectDashboardId) =>
  createSelector(
    (state) =>
      state.getIn([
        'entity',
        'projectDashboard',
        projectId.toString(),
        projectDashboardId.toString(),
      ]),
    (projectDashboard) => projectDashboard,
  );

export const createProjectDashboardCurvesSelector = (projectDashboardId) =>
  createSelector(
    (state) =>
      state.getIn([
        'entity',
        'projectDashboardCurve',
        projectDashboardId.toString(),
      ]),
    (projectDashboard) => projectDashboard,
  );

export const getDashboardsFromState = createSelector(
  (state) => state.getIn(['entity', 'dashboard']),
  (dashboards) => dashboards,
);
