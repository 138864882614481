import { useMemo } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, Grid, Button, IconButton } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { Table, TableRowActionsCell, TableRow } from 'altus-ui-components';

import { EMPTY_MAP } from 'app/app.constants';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';

import { fluidTypeToString } from 'utils/fluids/mappers.utils';

const FluidsTable = ({
  disabled,
  isCreateMode,
  toggleCreateMode,
  deleteFluid,
  fluids = EMPTY_MAP,
  defaultFluid,
  TableRowComponent = TableRow,
}) => {
  const lengthUnit = defaultFluid.getIn(['endDepth', 'unit']);

  const columns = useMemo(
    () => [
      {
        xs: 4,
        id: 'endDepth',
        accessor: (fluid) => {
          return fluid.getIn(['endDepth', 'roundedValue']);
        },
        Header: () => (
          <QuantityTableHeaderTitleCell title="End Depth" unit={lengthUnit} />
        ),
      },
      {
        xs: 4,
        Header: 'Name',
        accessor: (fluid) => fluid.get('name'),
      },
      {
        xs: 4,
        Header: 'Type',
        accessor: (fluid) => fluid.get('fluidType'),
        Cell: ({ row }) => fluidTypeToString(row.original.get('fluidType')),
      },
      {
        id: 'actions',
        Header: <TableRowActionsCell minItems={1} />,
        Footer: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (disabled) return null;

          return (
            <TableRowActionsCell minItems={1}>
              <IconButton
                title="Delete"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();

                  deleteFluid(row.original.get('fluidId'));
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
    ],
    [disabled, lengthUnit, deleteFluid],
  );

  return (
    <Grid container direction="column">
      <Table
        disableSortBy
        columns={columns}
        useGlobalFilter={false}
        items={fluids}
        TableRowComponent={TableRowComponent}
        noItemsMessage="No fluids added yet"
      />
      {!disabled && (
        <Grid container justifyContent="flex-end">
          <Box p={1}>
            <Button
              size="small"
              disabled={isCreateMode}
              onClick={toggleCreateMode}
            >
              <Box component={AddCircleOutlineIcon} marginRight={0.5} />
              Add
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default FluidsTable;
