import { compose } from 'redux';
import { Route } from 'react-router';
import { Grid, Divider } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { EMPTY_MAP } from 'app/app.constants';
import ProjectWellboreDetailHeader from 'features/projects/wellbore/details/ProjectWellboreDetailHeader';
import { PROJECT_WELLBORE_ACTIVE_TAB } from 'features/equipment/equipment.constants';

const ProjectWellboreHeader = ({
  classes,
  projectId,
  activeTab,
  project = EMPTY_MAP,
  handleActiveWellboreId,
  projectWellboreDetailId,
}) => {
  if (
    activeTab !== PROJECT_WELLBORE_ACTIVE_TAB.DETAILS &&
    activeTab !== PROJECT_WELLBORE_ACTIVE_TAB.SECTIONS
  )
    return null;
  return (
    <>
      <Grid
        container
        wrap="nowrap"
        className={classes.root}
        justifyContent="space-between"
      >
        <Route
          render={() => {
            return (
              <ProjectWellboreDetailHeader
                project={project}
                projectId={projectId}
                handleActiveWellboreId={handleActiveWellboreId}
                projectWellboreDetailId={projectWellboreDetailId}
              />
            );
          }}
        />
      </Grid>
      <Divider />
    </>
  );
};

const styles = (theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
});

export default compose(withStyles(styles))(ProjectWellboreHeader);
