import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import FormSection from 'app/components/form/FormSection';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import { WELLBORE_DETAIL_FORM as FORM } from 'features/projects/wellbore/details/projectWellboreDetails.constants';

const WellPropertiesSection = ({ disabled }) => (
  <FormSection title="Well Properties">
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Field
          type="number"
          label="Surface temperature"
          disabled={disabled}
          component={QuantityTextFieldFormik}
          name={FORM.SURFACE_TEMPERATURE}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          type="number"
          label="Wellhead pressure"
          disabled={disabled}
          component={QuantityTextFieldFormik}
          name={FORM.WELLHEAD_PRESSURE}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          type="number"
          label="Reservoir temperature"
          disabled={disabled}
          component={QuantityTextFieldFormik}
          name={FORM.RESERVOIR_TEMPERATURE}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          type="number"
          label="Reservoir pressure"
          disabled={disabled}
          component={QuantityTextFieldFormik}
          name={FORM.MAX_RESERVOIR_PRESSURE}
        />
      </Grid>
      <Grid item xs={3}>
        <Field
          type="number"
          label="Reservoir depth"
          disabled={disabled}
          component={QuantityTextFieldFormik}
          name={FORM.RESERVOIR_DEPTH}
        />
      </Grid>
    </Grid>
  </FormSection>
);

export default WellPropertiesSection;
