import React, { useCallback, useEffect, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';
import AutoCompleteDrumItemNumberAdapter from './AutoCompleteDrumItemNumberAdapter';
import AutoCompleteDrumSerialNumberAdapter from './AutoCompleteDrumSerialNumberAdapter';
import { useState } from 'react';
import { ASSET_HISTORY_EVENT_TYPES_ITEM } from 'features/equipment/equipment.constants';
import RadioGroupFieldFormik from 'app/components/form/formik/RadioGroupFieldFormik';
import { CableSpoolingStorageTypeEnum } from 'features/equipment/assets/components/equipmentMaintenance/utils/constants';

const CableSpoolingMaintenanceFormComponent = ({ form }) => {
  const [autoCompleteItemNumber, setAutoCompleteItemNumber] = useState(null);
  const [autoCompleteValue, setAutocompleteValue] = useState(null);
  const { setFieldValue, values: formValues } = useFormikContext();

  const FormFields = AssetHistoryForm;
  const options = [
    CableSpoolingStorageTypeEnum.addToStorageReel,
    CableSpoolingStorageTypeEnum.addToSpecificDrum,
  ];
  const storageTypeValue = formValues[FormFields.STORAGE_TYPE];
  const typeOfEventValue = formValues[FormFields.TYPE_OF_EVENT];
  const isAddToSpecificDrum = useMemo(
    () =>
      storageTypeValue === CableSpoolingStorageTypeEnum.addToSpecificDrum.value,
    [storageTypeValue],
  );

  const isEventOfMaintenanceType = useMemo(
    () =>
      String(typeOfEventValue) ===
      String(ASSET_HISTORY_EVENT_TYPES_ITEM.MAINTENANCE),
    [typeOfEventValue],
  );

  const clearInputValue = useCallback(() => {
    setAutocompleteValue(null);
    setFieldValue(AssetHistoryForm.CABLE_SPOOLING_DRUM_SERIAL_NUMBER, '');
  }, [setFieldValue]);

  useEffect(() => {
    if (autoCompleteItemNumber !== null) {
      clearInputValue();
    }
  }, [autoCompleteItemNumber, clearInputValue]);

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Field name={FormFields.STORAGE_TYPE}>
            {({ form, ...formik }) => {
              formik.field.onChange = (e) => {
                form.setFieldValue(
                  FormFields.STORAGE_TYPE,
                  Number(e.target.value),
                );
              };
              return (
                isEventOfMaintenanceType && (
                  <RadioGroupFieldFormik
                    options={options}
                    form={form}
                    row={false}
                    {...formik}
                  />
                )
              );
            }}
          </Field>
        </Grid>
      </Grid>
      {isAddToSpecificDrum ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography noWrap variant="body1">
                {'Drum Item Number'}
              </Typography>
            </Grid>
            <Field
              type="number"
              name={AssetHistoryForm.CABLE_SPOOLING_DRUM_ITEM_NUMBER}
              label="Drum Item Number"
              placeholder={''}
              component={() => (
                <AutoCompleteDrumItemNumberAdapter
                  form={form}
                  autoCompleteItemNumber={autoCompleteItemNumber}
                  setAutoCompleteItemNumber={setAutoCompleteItemNumber}
                  clearInputValue={clearInputValue}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Typography noWrap variant="body1">
                {'Drum Serial Number'}
              </Typography>
            </Grid>
            <Field
              type="number"
              name={AssetHistoryForm.CABLE_SPOOLING_DRUM_SERIAL_NUMBER}
              label="Drum Serial Number"
              placeholder={''}
              component={() => (
                <AutoCompleteDrumSerialNumberAdapter
                  form={form}
                  autoCompleteValue={autoCompleteValue}
                  setAutocompleteValue={setAutocompleteValue}
                />
              )}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default CableSpoolingMaintenanceFormComponent;
