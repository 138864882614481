import { Field } from 'formik';
import { CABLE_TAB_FIELDS } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import EditorFormik from 'app/components/form/formik/EditorFormik';
import { useHandleToolbarHeight } from 'features/projects/tasks/hooks/useHandleToolbarHeight';

export function CommentsRichText({ disabled } = { disabled: false }) {
  const toolbarHeight = useHandleToolbarHeight();

  return (
    <Field name={CABLE_TAB_FIELDS.COMMENTS}>
      {({ form, ...formik }) => {
        return (
          <EditorFormik
            form={form}
            {...formik}
            disabled={disabled}
            toolbarOnFocus
            toolbar={{
              options: ['inline', 'list'],
            }}
            xs={9}
            toolbarHeight={toolbarHeight}
            label="Comments"
            minHeight={7}
            maxHeight={7}
          />
        );
      }}
    </Field>
  );
}
