import moment from 'moment';
import { useMemo, useState } from 'react';
import { compose } from 'redux';

import withStyles from '@material-ui/styles/withStyles';
import { Tooltip } from '@material-ui/core';
import { Table } from 'altus-ui-components';
import { useToggle } from 'altus-hooks';

import GreenIcon from 'assets/Green.svg';
import OrangeIcon from 'assets/Orange.svg';
import RedIcon from 'assets/Red.svg';
import {
  getAssetTestNameById,
  TEST_ASSET_RESULT,
  TEST_RESULT_TO_TOOLTIP_TITLE,
} from 'features/equipment/equipment.constants';

import { Format } from 'app/app.constants';
import { formatDate } from 'utils/format.util';
import { extractTimeIn24HourFormat } from 'utils/app.util';
import AssetHistoryDrawer from 'features/equipment/assets/components/drawer/AssetHistoryDrawer';

export const testResultIcons = {
  [TEST_ASSET_RESULT.PASS]: GreenIcon,
  [TEST_ASSET_RESULT.FAIL]: RedIcon,
  [TEST_ASSET_RESULT.WARNING]: OrangeIcon,
};

const handleResultIcon = (testResult) => {
  return testResultIcons[testResult] || GreenIcon;
};

const getTooltipTitle = (testResult) => {
  return TEST_RESULT_TO_TOOLTIP_TITLE[testResult] || 'Unknown';
};

const AssetTestTable = ({ assetTests, displayComments }) => {
  const [assetHistory, setAssetHistory] = useState(null);
  const [isOpen, toggle] = useToggle();

  const columns = useMemo(
    () => [
      {
        xs: 3,
        id: 'event',
        Header: 'Test',
        accessor: (assetTest) => assetTest?.get('testType'),
        Cell: ({ value }) => <>{getAssetTestNameById(value)}</>,
      },
      {
        xs: 3,
        id: 'result',
        Header: 'Result',
        accessor: (assetTest) => assetTest?.get('result'),
        Cell: ({ value }) => {
          const title = getTooltipTitle(value);
          return (
            <Tooltip title={title}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={handleResultIcon(value)}
                  alt="Status icon"
                  style={{ marginRight: '8px' }}
                />
                <span>{title}</span>
              </div>
            </Tooltip>
          );
        },
      },
      {
        xs: 3,
        id: 'date',
        Header: 'Date',
        accessor: (item) => {
          if (!item?.get('startDate')) return '-';
          const startDate = item.get('startDate');
          return `${formatDate(
            moment(startDate),
            Format.date,
          )} ${extractTimeIn24HourFormat(item.get('startDate'))}`;
        },
      },
    ],
    [],
  );

  return (
    <>
      <Table
        items={assetTests.sortBy((asset) => asset.get('startDate')).reverse()}
        columns={columns}
        useGlobalFilter={false}
        noItemsMessage={'No items...'}
        onRowClick={(asset) => {
          setAssetHistory(asset);
          toggle();
        }}
      />
      {assetHistory && (
        <AssetHistoryDrawer
          isOpen={isOpen}
          toggleDrawer={toggle}
          assetHistory={assetHistory}
          displayComments={displayComments}
        />
      )}
    </>
  );
};
const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  editorClass: {
    width: '100%',
    paddingLeft: '10px',
    border: '1px solid #595959',
    borderRadius: '4px',
    fontSize: '1rem',
    flex: '1 1 auto',
    '& .DraftEditor-root': {
      display: 'flex',
    },
  },
});
export default compose(withStyles(styles))(AssetTestTable);
