import { compose } from 'redux';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import {
  getSystemAndProjectPermissionsFromState,
  getSystemAndProjectRolePermissionsFromState,
} from 'authorization/authorization.selectors';

import {
  systemRoleAccessRightsOnLoad,
  assignSystemPermissionsToSystemRole,
  assignProjectPermissionsToSystemRole,
  unassignSystemPermissionsFromSystemRole,
  unassignProjectPermissionsFromSystemRole,
} from 'authorization/authorization.actions';

import {
  PermissionType,
  systemPermissionGroups,
} from 'authorization/authorization.constants';

import PermissionGroup from 'features/settings/accessRoles/PermissionGroup';

class SystemRoleAccessRights extends PureComponent {
  componentDidMount() {
    const { systemRoleId, dispatchOnLoad } = this.props;

    dispatchOnLoad(systemRoleId);
  }

  addSystemPermissions = (permissionIds) => {
    const { systemRoleId, dispatchAssignSystemPermissionsToSystemRole } =
      this.props;

    dispatchAssignSystemPermissionsToSystemRole(systemRoleId, permissionIds);
  };

  removeSystemPermissions = (permissionIds) => {
    const { systemRoleId, dispatchUnassignSystemPermissionsFromSystemRole } =
      this.props;

    dispatchUnassignSystemPermissionsFromSystemRole(
      systemRoleId,
      permissionIds,
    );
  };

  addProjectPermissions = (permissionIds) => {
    const { systemRoleId, dispatchAssignProjectPermissionsToSystemRole } =
      this.props;

    dispatchAssignProjectPermissionsToSystemRole(systemRoleId, permissionIds);
  };

  removeProjectPermissions = (permissionIds) => {
    const { systemRoleId, dispatchUnassignProjectPermissionsFromSystemRole } =
      this.props;

    dispatchUnassignProjectPermissionsFromSystemRole(
      systemRoleId,
      permissionIds,
    );
  };

  render() {
    const {
      classes,
      systemAndProjectPermissions,
      systemAndProjectRolePermissions,
    } = this.props;

    return (
      <BasePage classes={{ children: classes.basePageChildren }}>
        <Grid container item xs={6}>
          <Grid container direction="column">
            {systemPermissionGroups.map((permissionGroup, groupIndex) => {
              const {
                title,
                hidden,
                permissions,
                rootPermissionId,
                showSubPermissions = false,
                rootPermissionType = PermissionType.SYSTEM,
              } = permissionGroup;
              if (hidden) return null;

              const rootPermission = rootPermissionId
                ? systemAndProjectPermissions.get(rootPermissionId.toString())
                : undefined;

              const groupDescription = rootPermission
                ? rootPermission.get('description')
                : undefined;

              const onAddPermissions =
                rootPermissionType === PermissionType.SYSTEM
                  ? this.addSystemPermissions
                  : this.addProjectPermissions;

              const onRemovePermissions =
                rootPermissionType === PermissionType.SYSTEM
                  ? this.removeSystemPermissions
                  : this.removeProjectPermissions;

              return (
                <PermissionGroup
                  key={groupIndex}
                  groupName={title}
                  permissions={permissions}
                  groupDescription={groupDescription}
                  onAddPermissions={onAddPermissions}
                  showSubpermissions={showSubPermissions}
                  onRemovePermissions={onRemovePermissions}
                  permissionsById={systemAndProjectPermissions}
                  rolePermissions={systemAndProjectRolePermissions}
                />
              );
            })}
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

const styles = (theme) => ({
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(
    (state, { systemRoleId }) => ({
      systemAndProjectPermissions:
        getSystemAndProjectPermissionsFromState(state),
      systemAndProjectRolePermissions:
        getSystemAndProjectRolePermissionsFromState(state, systemRoleId),
    }),
    {
      dispatchOnLoad: systemRoleAccessRightsOnLoad,
      dispatchAssignSystemPermissionsToSystemRole:
        assignSystemPermissionsToSystemRole,
      dispatchUnassignSystemPermissionsFromSystemRole:
        unassignSystemPermissionsFromSystemRole,
      dispatchAssignProjectPermissionsToSystemRole:
        assignProjectPermissionsToSystemRole,
      dispatchUnassignProjectPermissionsFromSystemRole:
        unassignProjectPermissionsFromSystemRole,
    },
  ),
  withStyles(styles),
)(SystemRoleAccessRights);
