import mappers from 'mappers';
import { EMPTY_MAP } from 'app/app.constants';
import { WELLS_ACTIONS as WELLBORE_ACTIONS } from 'features/wells/wells.constants';
import { ACTIONS } from 'features/projects/wellbore/details/projectWellboreDetails.constants';

const initialState = EMPTY_MAP;

const wellboreDetailReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.RECEIVE_PROJECT_WELLBORE_DETAIL: {
      if (error) return state;

      const { wellboreDetail } = payload;

      if (!wellboreDetail) return state;

      return state.set(
        wellboreDetail.wellboreDetailId.toString(),
        mappers.WellboreDetail.from(wellboreDetail),
      );
    }

    case WELLBORE_ACTIONS.RECEIVE_WELLBORE_DETAIL: {
      if (error) return state;

      return state.set(
        payload.wellboreDetailId.toString(),
        mappers.WellboreDetail.from(payload),
      );
    }

    case WELLBORE_ACTIONS.GET_WELLBORE_FLUIDS: {
      if (error) return state;
      const mapped = mappers.WellboreFluids.from(action.payload);
      return state.set('fluids', mapped);
    }

    default:
      return state;
  }
};

export default wellboreDetailReducer;
