import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Fab as MuiFab } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

import withNavigation, {
  IconButton as NavigationIconButton,
} from 'app/components/withNavigation';

import { useHeader } from 'app/hooks/useHeader';
import { toProjectTasks } from 'utils/route.util';
import BackButton from 'app/components/BackButton';
import useProjectHeader from 'app/hooks/useProjectHeader';
import { getPreviousNextTask, getTaskPageTitle } from 'utils/tasks.util';
import { getAllTasksFromState } from 'features/projects/tasks/tasks.selectors';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import ApplicationHeaderTitle from 'app/components/ApplicationHeader/ApplicationHeaderTitle';
import { getTaskFromState } from 'features/projects/tasks/task/toolstring/toolstring.selectors';

const NavigationFab = withNavigation(MuiFab);

const TaskHeader = ({
  task,
  tasks,
  taskId,
  classes,
  projectId,
  page: taskSubPage,
}) => {
  useProjectHeader();

  const defaultPreviousPage = toProjectTasks(projectId);

  const [previousTask, nextTask] = getPreviousNextTask(tasks, taskId);

  useHeader({
    subTitle: getTaskPageTitle(task, tasks),
  });

  return (
    <ApplicationHeaderBase>
      <Grid
        container
        spacing={2}
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs>
          <BackButton to={defaultPreviousPage} />
        </Grid>
        <Grid item>
          {previousTask && (
            <NavigationFab
              size="small"
              color="secondary"
              title={previousTask.get('title')}
              to={toProjectTasks(
                projectId,
                previousTask.get('id'),
                taskSubPage,
              )}
            >
              <ChevronLeft />
            </NavigationFab>
          )}
        </Grid>
        <Grid xs={8} item className={classes.title}>
          <ApplicationHeaderTitle />
        </Grid>
        <Grid item>
          {nextTask && (
            <NavigationFab
              size="small"
              color="secondary"
              title={nextTask.get('title')}
              to={toProjectTasks(projectId, nextTask.get('id'), taskSubPage)}
            >
              <ChevronRight />
            </NavigationFab>
          )}
        </Grid>
        <Grid item xs>
          <Grid container justifyContent="flex-end">
            <NavigationIconButton to={defaultPreviousPage}>
              <Close />
            </NavigationIconButton>
          </Grid>
        </Grid>
      </Grid>
    </ApplicationHeaderBase>
  );
};

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    padding: `0 ${theme.spacing(2)}px`,
  },
});

export default compose(
  withStyles(styles),
  connect((state) => ({
    task: getTaskFromState(state),
    tasks: getAllTasksFromState(state),
  })),
)(TaskHeader);
