import { Link } from 'react-router-dom';
import { useMemo, useCallback } from 'react';
import Typography from '@material-ui/core/Typography';

import { toTool } from 'utils/route.util';
import { Grid } from 'app/components/withTooltip';

import { Table, TableRow } from 'altus-ui-components';
import { ExifOrientation } from 'features/projects/tool/tool.constants';

import ToolImageTableCell from 'app/components/ToolImageTableCell';

const ThirdPartyToolsTable = ({ tools, toolCategory, ...rest }) => {
  const columns = useMemo(
    () => [
      {
        xs: 3,
        Header: 'Item no.',
        accessor: (tool) => tool.get('m3ItemNumber'),
        Cell: ({ row }) => {
          const tool = row.original;

          return (
            <Grid title={<Typography variant="h6">Open tool</Typography>}>
              {tool.get('m3ItemNumber')}
            </Grid>
          );
        },
      },
      {
        xs: 3,
        id: 'toolImage',
        Header: 'Image',
        Cell: ({ row }) => {
          const { original: tool } = row;

          return (
            <ToolImageTableCell
              onHoverDarken
              title={tool.get('name')}
              toolName={tool.get('name')}
              toolImageUrl={tool.get('imageUrl')}
              orientation={ExifOrientation.DEGREES_90_MIRRORED}
            />
          );
        },
      },
      {
        xs: 3,
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
      {
        xs: 3,
        Header: '3rd Party',
        accessor: (tool) => tool.get('supplier'),
      },
    ],
    [],
  );

  const renderTableRowComponent = useCallback(
    ({ item, ...rest }) => (
      <Link
        to={toTool(item.get('id'), toolCategory)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TableRow {...rest} />
      </Link>
    ),
    [toolCategory],
  );

  return (
    <Table
      stickyHeader
      usePagination
      items={tools}
      columns={columns}
      useGlobalFilter={false}
      TableRowComponent={renderTableRowComponent}
      {...rest}
    />
  );
};

export default ThirdPartyToolsTable;
