import moment from 'moment';
import { compose } from 'redux';
import { isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import capitalize from 'lodash/capitalize';
import { useHistory } from 'react-router-dom';
import { Field as FieldFormik, Formik } from 'formik';
import withStyles from '@material-ui/styles/withStyles';
import { useEffect, useState, useCallback } from 'react';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { reduxForm, Field, change } from 'redux-form/immutable';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';

import {
  Link,
  Grid,
  Paper,
  MenuItem,
  Typography,
  IconButton,
} from '@material-ui/core';

import { LoadingButton } from 'altus-ui-components';

import {
  EDIT_PROJECT_FORM,
  EDIT_PROJECT_UNITS_MODAL_ID,
} from 'features/projects/details/details.constants';

import TextField from 'app/components/form/TextField';
import { toFacility, toWellbore } from 'utils/route.util';
import EditorFormik from 'app/components/form/formik/EditorFormik';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import { CONVERT_SYSTEMS, CUSTOM_UNIT_SYSTEM, KEYS } from 'app/app.constants';
import ProjectTaskServicesField from 'app/components/ProjectTaskServicesField';
import ProjectNumberAndSource from 'features/projects/details/components/ProjectNumberAndSource';
import DateTimePickerFormik from 'app/components/form/formik/DateTimePickerFormik';
import ReadOnlyFieldReduxForm from 'app/components/form/redux-form/ReadOnlyFieldReduxForm';
import ProjectDepartmentsField from 'features/projects/details/components/ProjectDepartmentsField';
import ProjectUnitsModalContainer from 'features/projects/details/components/ProjectUnitsModalContainer';

// During preparations for may release, ticket #3627 Requested to temporarily hide this section
const displayWellcomExternalId = false;

const createMomentFromDateArray = (dateArray) => {
  var adjustedDateArray = dateArray.slice();
  adjustedDateArray[1] -= 1;
  const momentDate = moment(adjustedDateArray);
  if (!momentDate.isValid()) {
    return null;
  }
  return momentDate.format('YYYY-MM-DDTHH:mm:ss.SSS');
};

const ProjectDetailsForm = ({
  project,
  classes,
  projectId,
  departments,
  toggleModal,
  templateTasks,
  hasPermission,
  updateProject,
  updateProjectExternalId,
  updateExternalIdDataState,
  selectMeasurementPreference,
  updateMeasurementPreference,
  initializeMeasurementPreferences,
  hasCreateTaskPermission,
  isArchive,
}) => {
  const unitSystem = project.get('unit');
  const measurementPreference = project.get('units');
  const estimatedStart = createMomentFromDateArray(
    project.get('estimatedStart').toJS(),
  );
  const estimatedEnd = createMomentFromDateArray(
    project.get('estimatedEnd').toJS(),
  );
  const [rawObjectiveJson, setRawObjectiveJson] = useState();
  const [rawBackgroundJson, setRawBackgroundJson] = useState();
  const [rawInternalInformationJson, setRawInternalInformationJson] =
    useState();
  const dispatch = useDispatch();
  const [objectiveEditorState, setObjectiveEditorState] = useState(
    EditorState.createEmpty(),
  );
  const [backgroundEditorState, setBackgroundEditorState] = useState(
    EditorState.createEmpty(),
  );
  const [internalInformationEditorState, setInternalInformationEditorState] =
    useState(EditorState.createEmpty());
  const [estimatedStartState, setEstimatedStartState] =
    useState(estimatedStart);
  const [estimatedEndState, setEstimatedEndState] = useState(estimatedEnd);

  const updateContent = useCallback(
    (content, setContent, setEditorState, editorState) => {
      if (project.get(content) && setContent) {
        const rawEditorState = convertToRaw(editorState.getCurrentContent());
        if (JSON.stringify(rawEditorState) !== setContent) {
          const rawContentState = JSON.parse(project.get(content));
          const newEditorState = EditorState.createWithContent(
            convertFromRaw(rawContentState),
          );
          setEditorState(newEditorState);
        }
      }
    },
    [project],
  );

  const updateValues = (values, key, rawJson, setRawJson, changeValue) => {
    const JSONValue = JSON.stringify(
      convertToRaw(values[key].getCurrentContent()),
    );

    if (!isEqual(JSONValue, project.get(key))) {
      setRawJson(JSONValue);
      changeValue(JSONValue);
      updateProject();
    }
  };

  const updateDateContent = useCallback(() => {
    const estimatedStart = createMomentFromDateArray(
      project.get('estimatedStart').toJS(),
    );
    const estimatedEnd = createMomentFromDateArray(
      project.get('estimatedEnd').toJS(),
    );
    setEstimatedStartState(estimatedStart);
    setEstimatedEndState(estimatedEnd);
  }, [project]);

  useEffect(() => {
    updateDateContent();
  }, [updateDateContent]);

  const initialFormValues = {
    [EDIT_PROJECT_FORM.OBJECTIVE_DESCRIPTION]: objectiveEditorState,
    [EDIT_PROJECT_FORM.BACKGROUND]: backgroundEditorState,
    [EDIT_PROJECT_FORM.INTERNAL_INFORMATION]: internalInformationEditorState,
    [EDIT_PROJECT_FORM.ESTIMATED_START]: estimatedStartState,
    [EDIT_PROJECT_FORM.ESTIMATED_END]: estimatedEndState,
  };

  const changeBackground = (newBackground) => {
    dispatch(change(EDIT_PROJECT_FORM.FORM_ID, 'background', newBackground));
  };

  const changeObjective = (newValue) => {
    dispatch(change(EDIT_PROJECT_FORM.FORM_ID, 'objective', newValue));
  };

  const changeInternalInformation = (newValue) => {
    dispatch(
      change(EDIT_PROJECT_FORM.FORM_ID, 'internalInformation', newValue),
    );
  };

  const changeEstimatedStartDate = (newValue) => {
    dispatch(change(EDIT_PROJECT_FORM.FORM_ID, 'estimatedStart', newValue));
  };

  const onSubmit = (values) => {
    const updatedBackground = JSON.stringify(
      convertToRaw(values.background.getCurrentContent()),
    );
    const initialBackground = JSON.stringify(
      convertToRaw(initialFormValues.background.getCurrentContent()),
    );
    if (updatedBackground !== initialBackground) {
      updateValues(
        values,
        'background',
        rawBackgroundJson,
        setRawBackgroundJson,
        changeBackground,
      );
      setBackgroundEditorState(values.background);
    }

    const updatedObjective = JSON.stringify(
      convertToRaw(values.objective.getCurrentContent()),
    );
    const initialObjective = JSON.stringify(
      convertToRaw(initialFormValues.objective.getCurrentContent()),
    );
    if (updatedObjective !== initialObjective) {
      updateValues(
        values,
        'objective',
        rawObjectiveJson,
        setRawObjectiveJson,
        changeObjective,
      );
      setObjectiveEditorState(values.objective);
    }

    const updatedInternalInfo = JSON.stringify(
      convertToRaw(values.internalInformation.getCurrentContent()),
    );
    const initialInternalInfo = JSON.stringify(
      convertToRaw(initialFormValues.internalInformation.getCurrentContent()),
    );
    if (updatedInternalInfo !== initialInternalInfo) {
      updateValues(
        values,
        'internalInformation',
        rawInternalInformationJson,
        setRawInternalInformationJson,
        changeInternalInformation,
      );
      setInternalInformationEditorState(values.internalInformation);
    }
    updateDateValues(
      values,
      'estimatedStart',
      estimatedStartState,
      setEstimatedStartState,
      changeEstimatedStartDate,
    );
  };

  const updateDateValues = (values, key, stateValue, setValue, changeValue) => {
    if (!values[key]) return;
    const momentStateValue = moment(stateValue, 'YYYY-MM-DDTHH:mm:ss.SSS');
    const newValue = moment(values[key], 'YYYY/MM/DD HH:mm');

    if (!momentStateValue.isSame(newValue)) {
      const formattedNewValue = {
        year: newValue.format('YYYY'),
        month: newValue.format('MM'),
        day: newValue.format('DD'),
        hour: newValue.format('HH'),
        minute: newValue.format('mm'),
      };

      setValue(newValue.format('YYYY-MM-DDTHH:mm:ss.SSS'));
      changeValue(formattedNewValue);
      updateProject();
    }
  };

  const [toolbarHeight, setToolbarHeight] = useState(0);

  useEffect(() => {
    const newRawObjectiveJson = project.get('objective');
    if (!rawObjectiveJson || rawObjectiveJson !== newRawObjectiveJson) {
      setRawObjectiveJson(newRawObjectiveJson);
      updateContent(
        'objective',
        newRawObjectiveJson,
        setObjectiveEditorState,
        objectiveEditorState,
      );
    }

    const newRawBackgroundJson = project.get('background');
    if (!rawBackgroundJson || rawBackgroundJson !== newRawBackgroundJson) {
      setRawBackgroundJson(newRawBackgroundJson);
      updateContent(
        'background',
        newRawBackgroundJson,
        setBackgroundEditorState,
        backgroundEditorState,
      );
    }

    const newRawInternalInformationJson = project.get('internalInformation');
    if (
      !rawInternalInformationJson ||
      rawInternalInformationJson !== newRawInternalInformationJson
    ) {
      setRawInternalInformationJson(newRawInternalInformationJson);
      updateContent(
        'internalInformation',
        newRawInternalInformationJson,
        setInternalInformationEditorState,
        internalInformationEditorState,
      );
    }

    const handleResize = () => {
      const toolbar = document.querySelector('.rdw-editor-toolbar');
      if (toolbar) {
        const height = toolbar.offsetHeight;
        setToolbarHeight(height);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [
    project,
    rawBackgroundJson,
    rawObjectiveJson,
    rawInternalInformationJson,
  ]);

  const history = useHistory();

  const goBackToProjects = (event) => {
    event.preventDefault();
    history.push('/projects', { isArchive: true });
  };

  return (
    <>
      {isArchive ? (
        <Grid
          xs={2}
          style={{ left: '18px', position: 'fixed' }}
          onClick={goBackToProjects}
        >
          <Link href="#" color="inherit" underline="none">
            <IconButton>
              <ChevronLeftIcon />
            </IconButton>
            <Typography component="span">ARCHIVED PROJECTS</Typography>
          </Link>
        </Grid>
      ) : null}
      <Paper square className={classes.paper}>
        <form>
          <Formik
            enableReinitialize
            initialValues={initialFormValues}
            onSubmit={onSubmit}
          >
            <AutoSaveFormik timeout={1000}>
              <Grid container spacing={5}>
                <ProjectNumberAndSource
                  onChange={updateProject}
                  hasPermission={hasPermission}
                  disabled={isArchive}
                />
                {displayWellcomExternalId && (
                  <Grid item container>
                    <Grid item xs={3}>
                      <Field
                        label="Wellcom project No."
                        title="Press Enter or Synchronize button to look-up project in Wellcom"
                        component={TextField}
                        name={EDIT_PROJECT_FORM.EXTERNAL_ID}
                        fullWidth={false}
                        onKeyPress={(event) => {
                          if (event.key === KEYS.ENTER && !event.shiftKey) {
                            event.preventDefault();
                            updateProjectExternalId(projectId);
                          }
                        }}
                        disabled={updateExternalIdDataState.isLoading()}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <Field
                        label="Wellcom project name"
                        component={ReadOnlyFieldReduxForm}
                        name={EDIT_PROJECT_FORM.EXTERNAL_NAME}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <LoadingButton
                        color="primary"
                        variant="contained"
                        loading={updateExternalIdDataState.isLoading()}
                        onClick={() => updateProjectExternalId(projectId)}
                        title="Click to look-up project in Wellcom. Clear Wellcom project no and synchronize to remove link"
                      >
                        Synchronize
                      </LoadingButton>
                    </Grid>
                  </Grid>
                )}
                <Grid item container>
                  <Grid item xs>
                    <Field
                      label="Field"
                      component={ReadOnlyFieldReduxForm}
                      name={EDIT_PROJECT_FORM.FIELD}
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      label="Well"
                      target="_blank"
                      component={ReadOnlyFieldReduxForm}
                      name={EDIT_PROJECT_FORM.WELLBORE}
                      to={toWellbore(
                        project.get('fieldId'),
                        project.get('wellboreId'),
                      )}
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      label="Rig / Platform / Vessel"
                      target="_blank"
                      component={ReadOnlyFieldReduxForm}
                      to={toFacility(project.get('facilityId'))}
                      name={EDIT_PROJECT_FORM.FACILITY}
                    />
                  </Grid>
                  <Grid item xs>
                    <Field
                      label="Operator"
                      component={ReadOnlyFieldReduxForm}
                      name={EDIT_PROJECT_FORM.OPERATOR}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={5}
                  xs={12}
                  className={classes.selectFieldContainer}
                >
                  <FieldFormik name={EDIT_PROJECT_FORM.BACKGROUND}>
                    {({ form, ...formik }) => (
                      <EditorFormik
                        form={form}
                        {...formik}
                        toolbarOnFocus
                        toolbar={{
                          options: ['inline', 'list'],
                        }}
                        toolbarHeight={toolbarHeight}
                        label="Background (Client)"
                        minHeight={7}
                        maxHeight={7}
                        disabled={isArchive}
                      />
                    )}
                  </FieldFormik>
                  <FieldFormik name={EDIT_PROJECT_FORM.OBJECTIVE_DESCRIPTION}>
                    {({ form, ...formik }) => (
                      <EditorFormik
                        form={form}
                        {...formik}
                        toolbarOnFocus
                        toolbar={{
                          options: ['inline', 'list'],
                        }}
                        toolbarHeight={toolbarHeight}
                        label="Objective (Client)"
                        minHeight={7}
                        maxHeight={7}
                        disabled={isArchive}
                      />
                    )}
                  </FieldFormik>
                </Grid>
                <Grid
                  item
                  container
                  spacing={5}
                  xs={12}
                  alignItems="center"
                  className={classes.selectFieldContainer}
                >
                  <Grid item xs={3}>
                    <FieldFormik name={EDIT_PROJECT_FORM.ESTIMATED_START}>
                      {({ form, ...formik }) => (
                        <DateTimePickerFormik
                          form={form}
                          {...formik}
                          label="Start time"
                          margin=""
                          disabled={!hasPermission || isArchive}
                        />
                      )}
                    </FieldFormik>
                  </Grid>
                  <Grid item xs={3}>
                    <FieldFormik name={EDIT_PROJECT_FORM.ESTIMATED_END}>
                      {({ form, ...formik }) => (
                        <DateTimePickerFormik
                          form={form}
                          {...formik}
                          label="End time"
                          margin=""
                          disabled={true}
                        />
                      )}
                    </FieldFormik>
                  </Grid>
                  {hasPermission && (
                    <Grid item container xs={6}>
                      <FieldFormik
                        name={EDIT_PROJECT_FORM.INTERNAL_INFORMATION}
                      >
                        {({ form, ...formik }) => (
                          <EditorFormik
                            form={form}
                            {...formik}
                            toolbarOnFocus
                            toolbar={{
                              options: ['inline', 'list'],
                            }}
                            toolbarHeight={toolbarHeight}
                            label="Internal Information"
                            minHeight={7}
                            maxHeight={7}
                            disabled={isArchive}
                            xs={12}
                          />
                        )}
                      </FieldFormik>
                    </Grid>
                  )}
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      label="Main Objective"
                      component={ReadOnlyFieldReduxForm}
                      name={EDIT_PROJECT_FORM.TITLE}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      format={null}
                      label="Sub Objectives"
                      projectId={projectId}
                      templateTasks={templateTasks}
                      component={ProjectTaskServicesField}
                      disabled={!hasCreateTaskPermission || isArchive}
                      name={EDIT_PROJECT_FORM.TASK_SERVICES}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <fieldset>
                      <legend>
                        <Typography variant="body2">Department(s)</Typography>
                      </legend>
                      <Grid
                        container
                        spacing={1}
                        className={classes.departmentContainer}
                      >
                        <Field
                          format={null}
                          departments={departments}
                          component={ProjectDepartmentsField}
                          name={EDIT_PROJECT_FORM.DEPARTMENTS}
                        />
                      </Grid>
                    </fieldset>
                  </Grid>
                  <Grid item xs={6}>
                    <ProjectUnitsModalContainer
                      title="Project units"
                      modalId={EDIT_PROJECT_UNITS_MODAL_ID}
                      onSubmit={(values) =>
                        updateMeasurementPreference(values, project.get('id'))
                      }
                      onEnter={() =>
                        initializeMeasurementPreferences(
                          unitSystem,
                          measurementPreference,
                        )
                      }
                      descriptions={{
                        [CUSTOM_UNIT_SYSTEM]: 'Is set to custom units',
                        [CONVERT_SYSTEMS.METRIC]: 'Is displayed in Metric',
                        [CONVERT_SYSTEMS.IMPERIAL]: 'Is displayed in Imperial',
                      }}
                      options={[
                        {
                          value: CUSTOM_UNIT_SYSTEM,
                          label: `${CUSTOM_UNIT_SYSTEM} (default)`,
                        },
                        ...Object.values(CONVERT_SYSTEMS).map((system) => ({
                          value: system,
                          label: capitalize(system),
                        })),
                      ]}
                    />
                    <Field
                      select
                      label="Default units"
                      component={TextField}
                      disabled={!hasPermission || isArchive}
                      name={EDIT_PROJECT_FORM.DEFAULT_UNIT}
                      onChange={selectMeasurementPreference}
                      SelectProps={{
                        open: false,
                        displayEmpty: true,
                        onOpen: () => toggleModal(EDIT_PROJECT_UNITS_MODAL_ID),
                      }}
                    >
                      {[
                        {
                          value: CUSTOM_UNIT_SYSTEM,
                          label: CUSTOM_UNIT_SYSTEM,
                        },
                        ...Object.values(CONVERT_SYSTEMS).map((system) => ({
                          value: system,
                          label: capitalize(system),
                        })),
                      ].map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              </Grid>
            </AutoSaveFormik>
          </Formik>
        </form>
      </Paper>
    </>
  );
};

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2.5),
    background: theme.altus.background.panel,
  },
  chipRootDisabled: {
    opacity: 0.5,
  },
  departmentContainer: {
    marginTop: 0,
  },
  test: {
    backgroundColor: theme.palette.error.main,
  },
});

export default compose(
  reduxForm({
    form: EDIT_PROJECT_FORM.FORM_ID,
  }),
  withStyles(styles),
)(ProjectDetailsForm);
