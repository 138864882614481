import facilityFileService from 'services/facilityFile.service';
import { NOTIFICATION_VARIANTS, MODAL } from 'app/app.constants';
import { getAllFileCategories, toggleModal } from 'app/app.actions';
import { FACILITY_DOCUMENTS_ACTIONS } from 'features/facilities/documents/facilityDocuments.constants';

export const onLoad = (facilityId) => (dispatch) =>
  dispatch({
    type: FACILITY_DOCUMENTS_ACTIONS.PROJECT_FACILITY_PAGE_LOADED,
    payload: () =>
      Promise.all([
        dispatch(getAllFileCategories()),
        facilityFileService
          .getAllDocuments(facilityId)
          .then((files) => dispatch(receiveProjectFiles(files))),
      ]),
  });

export const onUnload = () => (dispatch) =>
  dispatch({ type: FACILITY_DOCUMENTS_ACTIONS.PROJECT_FACILITY_PAGE_UNLOADED });

export const deleteFile = (facilityId, facilityFileId) => (dispatch) => {
  dispatch({
    facilityFileId,
    type: FACILITY_DOCUMENTS_ACTIONS.DELETE_FACILITY_FILE,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Deleting...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The file was successfully deleted',
    },
    confirmationDialog: {
      title: 'Delete document',
      confirmButtonText: 'Delete',
      description: 'Are you sure you want to delete this document?',
    },
    payload: () => facilityFileService.deleteFile(facilityId, facilityFileId),
  });
};

export const downloadFile =
  (facilityId, facilityFileId, file) => (dispatch) => {
    const payload = facilityFileService.downloadFile(
      facilityId,
      facilityFileId,
    );

    dispatch({
      facilityFileId,
      type: FACILITY_DOCUMENTS_ACTIONS.DOWNLOAD_FACILITY_FILE,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: `Downloading ${file.get('name')}...`,
      },
      payload,
    });

    return payload;
  };

// TODO: Add error handling, for example if only 1 file upload fails
export const uploadFiles =
  (facilityId) => (dispatch) => (uploadDocumentFormValues) => {
    const { files } = uploadDocumentFormValues.toJS();

    const payload = Promise.all(
      files.map(({ file, meta }) =>
        facilityFileService
          .uploadFile(facilityId, file, meta)
          .then((projectFile) => dispatch(receiveProjectFile(projectFile))),
      ),
    ).then((response) => {
      dispatch(toggleModal({ modalId: MODAL.UPLOAD_FILE }));
      return response;
    });

    dispatch({
      type: FACILITY_DOCUMENTS_ACTIONS.UPLOAD_FACILITY_FILE,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: `The ${
          files.length > 1 ? 'files' : 'file'
        } ${files.length > 1 ? 'were' : 'was'} successfully uploaded`,
      },
      payload,
    });

    return payload;
  };

export const uploadFileModalOnUnload = () => (dispatch) =>
  dispatch({ type: FACILITY_DOCUMENTS_ACTIONS.UPLOAD_FILE_MODAL_UNLOADED });

export const updateFile = (facilityId, facilityFileId, file) => (dispatch) => {
  const payload = facilityFileService
    .updateFile(facilityId, facilityFileId, file)
    .then((projectFile) => receiveProjectFile(projectFile));

  dispatch({
    payload,
    facilityFileId,
    type: FACILITY_DOCUMENTS_ACTIONS.UPDATE_FACILITY_FILE,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'File was successfully updated',
    },
  });

  return payload;
};

export const receiveProjectFiles = (projectFiles) => ({
  payload: projectFiles,
  type: FACILITY_DOCUMENTS_ACTIONS.RECEIVE_FACILITY_FILES,
});

export const receiveProjectFile = (projectFile) => ({
  payload: projectFile,
  type: FACILITY_DOCUMENTS_ACTIONS.RECEIVE_FACILITY_FILE,
});
