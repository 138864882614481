import { compose } from 'redux';
import { Formik, Form } from 'formik';
import { useMemo, useCallback } from 'react';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import withStyles from '@material-ui/styles/withStyles';
import { IconButton, MenuItem } from '@material-ui/core';

import { TableRow, TableCell, TableRowActionsCell } from 'altus-ui-components';

import { required } from 'utils/validation.util';
import { invokeIfFunction } from 'utils/app.util';
import {
  WellboreSectionType,
  MINUSKEY,
} from 'features/wells/sections/wellboreSection.constants';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import { wellboreSectionTypeToString } from 'features/wells/sections/wellboreSection.mappers';
import { WellboreSectionFormFields as FormFields } from 'features/wells/sections/components/WellboreSectionsTable';

const CreateWellboreSectionRow = ({
  classes,
  columns,
  toggleCreateMode,
  createWellboreSection,
  defaultWellboreSection,
  wellboreSection,
}) => {
  let topValue = {
    hasValue: true,
    unit: 'm',
  };

  const getTopValue = useCallback(() => {
    var result = 0;
    let length = 0;
    let top = 0;

    if (wellboreSection?.size) {
      Array.from(wellboreSection.values())
        .pop()
        .map((value, i) => {
          if (i === 'bottom' && result === 0) {
            result = value.value;
          }

          if (i === 'wellboreSectionNipples') {
            Array.from(value.values())
              .pop()
              ?.map((nipple, j) => {
                switch (j) {
                  case 'length':
                    length = nipple.value;
                    break;
                  case 'top':
                    top = nipple.value;
                    break;
                  default:
                    break;
                }

                result = length + top;
                return result;
              });
          }

          return result;
        });
    }

    return result;
  }, [wellboreSection]);

  const initialTopValue = useMemo(
    () => ({
      hasValue: true,
      roundedValue: getTopValue(),
      unit: 'm',
      value: getTopValue(),
    }),
    [getTopValue],
  );

  const validateTopValues = (value) => {
    const validateRequired = required(value);
    topValue = value;

    if (validateRequired) {
      return validateRequired;
    }
  };

  const validateTopBottomValues = (value) => {
    const validateRequired = required(value);
    const bottomValue = Number.parseFloat(value?.value);
    const currentTopValue = Number.parseFloat(topValue?.value);

    if (
      validateRequired ||
      Number.isNaN(bottomValue) ||
      Number.isNaN(currentTopValue) ||
      !value?.hasValue
    ) {
      return validateRequired;
    }

    if (bottomValue <= currentTopValue) {
      return 'Bottom value must be greater than top value';
    }

    return undefined;
  };

  const onSubmit = useCallback(
    (wellboreSection, formik) =>
      createWellboreSection(wellboreSection, formik, toggleCreateMode),
    [toggleCreateMode, createWellboreSection],
  );

  // simplify getting 'xs' and 'Header' values from column
  const renderCell = (index, render) => {
    const column = columns[index];

    return (
      <TableCell item xs={column.xs}>
        {invokeIfFunction(render, column)}
      </TableCell>
    );
  };

  let initialValues = useMemo(
    () => ({
      [FormFields.FRICTION_FACTOR_IN]:
        defaultWellboreSection.get('frictionFactorIn'),
      [FormFields.FRICTION_FACTOR_OUT]:
        defaultWellboreSection.get('frictionFactorOut'),
      [FormFields.TOP]: initialTopValue,
    }),
    [defaultWellboreSection, initialTopValue],
  );

  return (
    <Formik validateOnMount onSubmit={onSubmit} initialValues={initialValues}>
      {({ isValid, isSubmitting, handleSubmit, dirty }) => (
        <Form>
          <TableRow
            fadeIn={false}
            classes={{
              root: classes.tableRowRoot,
              rootStriped: classes.tableRowRoot,
            }}
          >
            {renderCell(0, () => (
              <TableRowActionsCell minItems={1} />
            ))}
            {renderCell(1)}
            {renderCell(2, () => (
              <TableRowTextFieldFormik
                select
                autoFocus
                validate={required}
                name={FormFields.TYPE}
              >
                {Object.values(WellboreSectionType).map((type) => (
                  <MenuItem key={type} value={type}>
                    {wellboreSectionTypeToString(type)}
                  </MenuItem>
                ))}
              </TableRowTextFieldFormik>
            ))}
            {renderCell(3, () => (
              <TableRowTextFieldFormik
                type="number"
                validate={validateTopValues}
                disabled={JSON.stringify(wellboreSection) === '{}'}
                name={FormFields.TOP}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
                onKeyPress={(event) => {
                  if (event?.key === MINUSKEY) {
                    event.preventDefault();
                  }
                }}
              />
            ))}
            {renderCell(4, () => (
              <TableRowTextFieldFormik
                type="number"
                validate={validateTopBottomValues}
                name={FormFields.BOTTOM}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
                onKeyPress={(event) => {
                  if (event?.key === MINUSKEY) {
                    event.preventDefault();
                  }
                }}
              />
            ))}
            {renderCell(5)}
            {renderCell(6, () => (
              <TableRowTextFieldFormik
                type="number"
                validate={required}
                name={FormFields.INNER_DIAMETER}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
                onKeyPress={(event) => {
                  if (event?.key === MINUSKEY) {
                    event.preventDefault();
                  }
                }}
              />
            ))}
            {renderCell(7, () => (
              <TableRowTextFieldFormik
                type="number"
                validate={required}
                name={FormFields.FRICTION_FACTOR_IN}
                onKeyPress={(event) => {
                  if (event?.key === MINUSKEY) {
                    event.preventDefault();
                  }
                }}
              />
            ))}
            {renderCell(8, () => (
              <TableRowTextFieldFormik
                type="number"
                validate={required}
                name={FormFields.FRICTION_FACTOR_OUT}
                onKeyPress={(event) => {
                  if (event?.key === MINUSKEY) {
                    event.preventDefault();
                  }
                }}
              />
            ))}
            <TableCell item>
              <TableRowActionsCell>
                <IconButton
                  title="Save"
                  onClick={handleSubmit}
                  className={classes.saveButton}
                  disabled={!isValid || isSubmitting || !dirty}
                >
                  <Check fontSize="small" />
                </IconButton>
                <IconButton title="Cancel" onClick={toggleCreateMode}>
                  <Close fontSize="small" />
                </IconButton>
              </TableRowActionsCell>
            </TableCell>
          </TableRow>
        </Form>
      )}
    </Formik>
  );
};

const styles = (theme) => ({
  saveButton: {
    background: theme.palette.success.dark,
    '&:hover': {
      background: theme.palette.success.main,
    },
  },
  tableRowRoot: {
    '&:hover': {
      background: theme.altus.components.SortableListRow.background,
    },
  },
});

export default compose(withStyles(styles))(CreateWellboreSectionRow);
