import { Mesh } from 'three';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { useRef, useState, useMemo, useEffect, useCallback } from 'react';

import { PUBLIC_ASSETS_URL } from 'app/app.constants';

const modelUrl = `${PUBLIC_ASSETS_URL}/images/dashboard/drillModel.obj`;

const DrillModel = ({ registerDataPointsHandler }) => {
  const modelRef = useRef();
  const [position] = useState([50, 0, 0]);
  const [rpm, setRpm] = useState(0);

  const [obj, setObj] = useState();
  const mat = new THREE.MeshStandardMaterial({
    metalness: 1,
    roughness: 0.4,
  });

  const ObjToPrimitive = () => {
    useMemo(() => new OBJLoader().load(modelUrl, setObj), []);

    if (obj) {
      obj.traverse((child) => {
        if (child instanceof Mesh) {
          child.material = mat;
        }
      });
      return <primitive object={obj} />;
    }
  };

  useFrame(() => {
    modelRef.current.rotation.x += 0.03 * (rpm / 100);
  });

  const onReceiveData = useCallback(
    (data) => setRpm(data.bitRpm ?? 0),
    [setRpm],
  );

  useEffect(() => {
    registerDataPointsHandler(onReceiveData);
  }, [registerDataPointsHandler, onReceiveData]);

  return (
    <mesh ref={modelRef} position={position}>
      {ObjToPrimitive()}
    </mesh>
  );
};

export default DrillModel;
