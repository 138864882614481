import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import { Table } from 'altus-ui-components';

import { EMPTY_LIST } from 'app/app.constants';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import * as actions from 'features/projects/tasks/task/simulation/dynamicToolParameters/simulationDynamicToolParameter.actions';
import TableRowRadioGroupFieldFormik from 'app/components/form/formik/TableRowRadioGroupFieldFormik';

export const SimulationDynamicToolParameterJarActivationTable = ({
  data = EMPTY_LIST,
  forceUnit = '',
  disabled,
}) => {
  const dispatch = useDispatch();
  const { projectId, taskId, simulationId } = useParams();

  const updateDynamicSimulationParameter = (
    parameter,
    { setSubmitting, setStatus },
  ) =>
    dispatch(
      actions.requestUpdateSimulationDynamicToolParameter(
        projectId,
        taskId,
        simulationId,
        parameter.simulationDynamicToolParameterId,
        parameter,
        setSubmitting,
        setStatus,
        true,
      ),
    );

  const columns = [
    {
      xs: 5,
      id: 'toolName',
      Header: 'Name',
      Cell: ({ row }) => row.original.get('toolName'),
    },
    {
      xs: 5,
      id: 'jarActivationForce',
      Header: (
        <QuantityTableHeaderTitleCell
          title="Activation Force"
          unit={forceUnit}
        />
      ),
      accessor: (parameter) => parameter.get('jarActivationForce'),
      Cell: () => (
        <Field
          type="number"
          name="jarActivationForce"
          disabled={disabled}
          component={TableRowTextFieldFormik}
          TextFieldComponent={QuantityTextFieldFormik}
          InputProps={{
            endAdornment: null,
          }}
        />
      ),
    },
    {
      xs: 2,
      id: 'jarActivationIsActive',
      Header: 'Active',
      Cell: ({ row }) => {
        return (
          <Field
            name="jarActivationIsActive"
            disabled={disabled}
            component={TableRowRadioGroupFieldFormik}
            value={row.original.get('jarActivationIsActive')}
            options={[
              {
                name: 'jarActivationIsActive',
                value: true,
              },
            ]}
          />
        );
      },
    },
  ];

  const renderTableRowComponent = (props) => (
    <EditableTableRowFormik
      {...props}
      onSubmit={updateDynamicSimulationParameter}
    />
  );

  return (
    <Grid container padding={1}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Jar Activation
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Table
          disableSortBy
          columns={columns}
          useGlobalFilter={false}
          items={data}
          noItemsMessage={'No Jar Activation Tools in BHA'}
          displayNoItemsMessage
          TableRowComponent={renderTableRowComponent}
        />
      </Grid>
    </Grid>
  );
};
