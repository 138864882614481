import { compose } from 'redux';
import { connect } from 'react-redux';
import routePaths from 'app/routePaths';
import { PureComponent } from 'react';
import { Grid, Box, Divider } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import { Switch, Redirect, matchPath } from 'react-router-dom';

import {
  Menu,
  MenuHeader,
  LoadingOverlay,
  RouteContainer,
  withToggleModal,
} from 'altus-ui-components';

import {
  requestToolstringTemplates,
  redirectToTaskToolstringEdit,
  requestToogleToolstringFavorite,
  requestTaskToolstringsPageOnLoad,
  requestDeleteToolstringAndRedirect,
  requestDuplicateToolstringAndRedirect,
} from 'features/projects/tasks/task/toolstring/toolstring.actions';

import { EMPTY_LIST } from 'app/app.constants';
import { toTaskToolstring } from 'utils/route.util';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { MODAL } from 'features/projects/tasks/task/toolstring/toolstring.constants';
import { TOOLSTRING_ACTIONS } from 'features/projects/tasks/task/toolstring/toolstring.constants';
import { getTaskToolstringsFromState } from 'features/projects/tasks/task/toolstring/toolstring.selectors';
import NoToolstringSelectedPage from 'features/projects/tasks/task/toolstring/components/NoToolstringSelectedPage';
import CreateToolstringModal from 'features/projects/tasks/task/toolstring/components/create/CreateToolstringModal';
import ToolstringCardNavigationItem from 'features/projects/tasks/task/toolstring/components/ToolstringCardNavigationItem';

class TaskToolstringsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, projectId, taskId } = this.props;

    dispatchOnLoad(projectId, taskId);
  }

  toggleModal = () => {
    const { dispatchToggleModal, dispatchGetToolstringTemplates } = this.props;
    dispatchGetToolstringTemplates();
    dispatchToggleModal({ modalId: MODAL.CREATE_TOOLSTRING_MODAL_ID });
  };

  renderMenuItem = (toolstring) => {
    const {
      taskId,
      pathname,
      projectId,
      dispatchToggleToolstringFavorite,
      dispatchDeleteToolstringAndRedirect,
      dispatchRedirectToTaskToolstringEdit,
      dispatchDuplicateToolstringAndRedirect,
    } = this.props;

    const currentPathMatch = matchPath(pathname, {
      path: routePaths.plan.task.toolstring.index,
    });

    if (!currentPathMatch) return null;

    const { toolstringId: selectedToolstringId } = currentPathMatch.params;
    const toolstringId = toolstring.get('id');

    const to = toTaskToolstring(projectId, taskId, toolstringId);

    return (
      <Box padding={1} key={toolstringId}>
        <ToolstringCardNavigationItem
          to={to}
          toolstring={toolstring}
          onDuplicate={() =>
            dispatchDuplicateToolstringAndRedirect(
              projectId,
              taskId,
              toolstring.get('id'),
            )
          }
          onDelete={() =>
            dispatchDeleteToolstringAndRedirect(
              projectId,
              taskId,
              toolstring.get('id'),
              selectedToolstringId,
            )
          }
          toggleFavorite={() =>
            dispatchToggleToolstringFavorite(projectId, taskId, toolstringId)
          }
          onEdit={() =>
            dispatchRedirectToTaskToolstringEdit(
              projectId,
              taskId,
              toolstringId,
            )
          }
        />
      </Box>
    );
  };

  render() {
    const { path, routes, taskId, classes, projectId, dataState, toolstrings } =
      this.props;

    return (
      <Grid container item xs wrap="nowrap" className={classes.root}>
        <Grid container item xs={2} wrap="nowrap" direction="column">
          <MenuHeader title="BHA" onAdd={this.toggleModal} />
          <Divider />
          <Grid item container className={classes.menuContainer}>
            <Menu
              items={toolstrings}
              renderMenuItem={(item) => this.renderMenuItem(item)}
            />
          </Grid>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid container item xs wrap="nowrap">
          <Switch>
            {!!toolstrings.size && (
              <Redirect
                exact
                from={path}
                to={toTaskToolstring(
                  projectId,
                  taskId,
                  toolstrings.first().get('id'),
                )}
              />
            )}
            <RouteContainer
              routes={routes}
              MissingRouteComponent={NoToolstringSelectedPage}
            />
          </Switch>
        </Grid>
        <LoadingOverlay dataState={dataState} timeout={0} />
        <CreateToolstringModal projectId={projectId} taskId={taskId} />
      </Grid>
    );
  }
}

const styles = () => ({
  root: {
    overflow: 'hidden',
  },
  menuContainer: {
    overflow: 'auto',
  },
});

TaskToolstringsContainer.defaultProps = {
  toolstrings: EMPTY_LIST,
};

export default compose(
  connect(
    (state, { taskId }) => ({
      toolstrings: getTaskToolstringsFromState(state, taskId),
      dataState: getSummarizedDataStateFromState(
        state,
        TOOLSTRING_ACTIONS.GET_TOOLSTRING_FOR_TASK,
        TOOLSTRING_ACTIONS.REQUEST_TASK_TOOLSTRINGS_PAGE_ON_LOAD,
        TOOLSTRING_ACTIONS.REQUEST_DELETE_TOOLSTRING_FOR_TASK_AND_REDIRECT,
        TOOLSTRING_ACTIONS.REQUEST_DUPLICATE_TOOLSTRING_FOR_TASK_AND_REDIRECT,
      ),
    }),
    {
      dispatchOnLoad: requestTaskToolstringsPageOnLoad,
      dispatchGetToolstringTemplates: requestToolstringTemplates,
      dispatchToggleToolstringFavorite: requestToogleToolstringFavorite,
      dispatchRedirectToTaskToolstringEdit: redirectToTaskToolstringEdit,
      dispatchDeleteToolstringAndRedirect: requestDeleteToolstringAndRedirect,
      dispatchDuplicateToolstringAndRedirect:
        requestDuplicateToolstringAndRedirect,
    },
  ),
  withToggleModal,
  withStyles(styles),
)(TaskToolstringsContainer);
