import { ACTIONS } from 'features/projects/tasks/task/simulation/parameters/simulationParameter.constants';

export const requestSimulationParameters = (
  projectId,
  taskId,
  simulationId,
) => ({
  taskId,
  projectId,
  simulationId,
  type: ACTIONS.REQUEST_SIMULATION_PARAMETERS,
});

export const requestCreateSimulationParameter = (
  projectId,
  taskId,
  simulationId,
  simulationParameter,
  setSubmitting,
  setStatus,
  callback,
) => ({
  taskId,
  projectId,
  callback,
  setStatus,
  simulationId,
  setSubmitting,
  payload: simulationParameter,
  type: ACTIONS.REQUEST_CREATE_SIMULATION_PARAMETER,
  notification: {
    success: 'Parameter successfully created',
  },
});

export const requestUpdateSimulationParameter = (
  projectId,
  taskId,
  simulationId,
  simulationParameterId,
  simulationParameter,
  setSubmitting,
  setStatus,
) => ({
  taskId,
  projectId,
  setStatus,
  simulationId,
  setSubmitting,
  simulationParameterId,
  payload: simulationParameter,
  type: ACTIONS.REQUEST_UPDATE_SIMULATION_PARAMETER,
  notification: {
    info: 'Updating parameter',
    success: 'Parameter successfully updated',
  },
});

export const requestDeleteSimulationParameter = (
  projectId,
  taskId,
  simulationId,
  simulationParameterId,
) => ({
  taskId,
  projectId,
  simulationId,
  simulationParameterId,
  type: ACTIONS.REQUEST_DELETE_SIMULATION_PARAMETER,
  confirmationDialog: {
    title: 'Delete simulation parameter',
    confirmButtonText: 'Delete',
    description: 'Are you sure you want to delete this parameter? ',
  },
  notification: {
    success: 'Parameter successfully deleted',
  },
});

export const loadSimulationParametersPage = (
  projectId,
  taskId,
  simulationId,
) => ({
  taskId,
  projectId,
  simulationId,
  type: ACTIONS.LOAD_SIMULATION_PARAMETERS_PAGE,
});

export const receiveSimulationParameter = (simulationParameter) => ({
  payload: simulationParameter,
  type: ACTIONS.RECEIVE_SIMULATION_PARAMETER,
});

export const deleteSimulationParameter = (simulationParameterId) => ({
  simulationParameterId,
  type: ACTIONS.DELETE_SIMULATION_PARAMETER,
});

export const receiveSimulationParameters = (simulationParameters) => ({
  payload: simulationParameters,
  type: ACTIONS.RECEIVE_SIMULATION_PARAMETERS,
});
