import { EMPTY_LIST, EMPTY_MAP } from 'app/app.constants';
import { createSelector } from 'utils/app.util';
import toolstringMapper from 'features/projects/tasks/task/toolstring/toolstring.mappers';

export const getToolstringTemplatesFromState = createSelector(
  (state) =>
    state.getIn([
      'projects',
      'plan',
      'task',
      'toolstring',
      'data',
      'toolstringTemplates',
    ]),
  (toolstrings) => toolstrings?.toList() || EMPTY_LIST,
);

export const getTaskToolstringsFromState = createSelector(
  (state, taskId) => {
    return state.getIn([
      'projects',
      'plan',
      'task',
      'toolstring',
      'data',
      'toolstringsByTaskId',
      taskId.toString(),
    ]);
  },
  (toolstrings) => toolstrings?.toList() || EMPTY_LIST,
);

const getToolstringSelector = (state, toolstringId) => {
  const initialToolstring = toolstringMapper.Toolstring.initial();

  if (!toolstringId) {
    return initialToolstring;
  }

  return state.getIn(
    [
      'projects',
      'plan',
      'task',
      'toolstring',
      'data',
      'toolstringById',
      toolstringId.toString(),
    ],
    toolstringMapper.Toolstring.initial(),
  );
};

export const createGetToolstringSelector = (toolstringId) =>
  createSelector(
    (state) => getToolstringSelector(state, toolstringId),
    (toolstring) => toolstring,
  );

export const createGetToolstringToolsSelector = (toolstringId) =>
  createSelector(createGetToolstringSelector(toolstringId), (toolstring) =>
    toolstring
      .get('toolsByToolstringToolId')
      .toList()
      .sortBy((tool) => tool.get('sequence')),
  );

export const getToolstringFromState = createSelector(
  getToolstringSelector,
  (toolstring) => toolstring,
);

export const getTaskFromState = createSelector(
  (state) =>
    state.getIn(['projects', 'plan', 'task', 'toolstring', 'data', 'task']),
  (task) => task,
);

export const getTaskByIdFromState = createSelector(
  (state, taskId) =>
    state
      .getIn(['projects', 'plan', 'tasks', 'data'], EMPTY_LIST)
      .find((task) => task.get('id').toString() === taskId.toString()),
  (task) => task,
);

export const getToolstringToolsFromState = createSelector(
  getToolstringFromState,
  (toolstring) => {
    const toolsList = toolstring
      .get('toolsByToolstringToolId')
      .toList()
      .sortBy((tool) => tool.get('sequence'));

    return toolstringMapper.ToolstringTool.thirdPartyDataVerification(
      toolsList,
    );
  },
);

export const getCablesFromState = createSelector(
  (state) =>
    state.getIn(['projects', 'plan', 'task', 'toolstring', 'data', 'cables']),
  (cables) => cables,
);

export const getToolstringItemsByIdFromState = createSelector(
  getToolstringFromState,
  (toolstring) => toolstring.get('toolsByToolstringToolId'),
);

const getToolstringSectionsSelector = (state, toolstringId) =>
  state.getIn([
    'projects',
    'plan',
    'task',
    'toolstring',
    'data',
    'toolstringSectionsByToolstringId',
    toolstringId?.toString(),
  ]);

export const createGetToolstringSectionsSelector = (toolstringId) =>
  createSelector(
    (state) => getToolstringSectionsSelector(state, toolstringId),
    (toolstringSections) => toolstringSections,
  );

export const createGetToolstringSectionsItemsSelector = (toolstringId) =>
  createSelector(
    createGetToolstringSectionsSelector(toolstringId),
    createGetToolstringSelector(toolstringId),
    (toolstringSections, toolstringItemsById) =>
      toolstringSections?.map((section) =>
        section.update('items', (items) =>
          items?.map((itemId) =>
            toolstringItemsById.get(itemId.toString(), EMPTY_MAP),
          ),
        ),
      ),
  );

export const getToolstringSectionsFromState = createSelector(
  getToolstringSectionsSelector,
  getToolstringItemsByIdFromState,
  (toolstringSections, toolstringItemsById) =>
    toolstringSections?.map((section) =>
      section.update('items', (items) =>
        items?.map((itemId) =>
          toolstringItemsById.get(itemId.toString(), EMPTY_MAP),
        ),
      ),
    ),
);

export const getToolstringsByTaskFromState = createSelector(
  (state) =>
    state.getIn(['entity', 'toolString', 'data', 'toolstringsByTaskId']),
  (toolstrings) => toolstrings?.toList() || EMPTY_LIST,
);

export const getToolStringByIdFromState = createSelector((state) =>
  state.getIn(
    ['projects', 'plan', 'task', 'toolstring', 'data', 'toolstringById'],
    toolstringMapper.Toolstring.initial(),
  ),
);

export const getTaskCablesFromState = createSelector(
  (state) => state.getIn(['projects', 'plan', 'tasks', 'cables']),
  (cables) => cables,
);

export const getPlannedToolstringToolsWithStatusFromState = createSelector(
  (state) =>
    state.getIn([
      'projects',
      'plan',
      'task',
      'toolstring',
      'data',
      'plannedToolstringToolsWithStatus',
    ]),
  (tools) => tools,
);
