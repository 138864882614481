import Agent from 'infrastructure/agent';
import IncidentsMapper from 'features/projects/incidents/incidents.mappers';

export const allIncidents = (projectId) =>
  Agent.get(`/project/${projectId}/incidents`);

export const IncidentById = (projectId, IncidentId) =>
  Agent.get(`/project/${projectId}/incidents/${IncidentId}`);

export const updateIncident = (projectId, incident) =>
  Agent.put(
    `/project/${projectId}/incidents/${incident.id}`,
    IncidentsMapper.Incident.to(incident),
  );

export const createIncident = (projectId, incident) =>
  Agent.post(
    `/project/${projectId}/incidents`,
    IncidentsMapper.Incident.to(incident),
  );

export const deleteIncident = (projectId, incidentId) =>
  Agent.delete(`/project/${projectId}/incidents/${incidentId}`);

const IncidentsService = {
  allIncidents,
  IncidentById,
  updateIncident,
  createIncident,
  deleteIncident,
};

export default IncidentsService;
