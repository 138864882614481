import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import routePaths from 'app/routePaths';

const LocationProvider = ({ children, setGlobalLocation }) => {
  const location = useLocation();

  useEffect(() => {
    // Whenever the location changes, update the global location state
    if (
      location.pathname !== routePaths.login &&
      location.pathname !== routePaths.root
    )
      setGlobalLocation(location.pathname);
  }, [location, setGlobalLocation]);

  return children;
};

export default LocationProvider;
