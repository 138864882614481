import { useEffect, useCallback } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { Format } from 'app/app.constants';
import { formatDate } from 'utils/format.util';

const DatePickerFormik = ({
  form,
  field,
  classes,
  margin = 'normal',
  DateTimePickerProps,
  format = Format.date,
  destroyOnUnmount = false,
  inputVariant = 'standard',
  disableFuture = false,
  minDate = undefined,
  disabled = false,
  placeholder,
  ...rest
}) => {
  const { value, name, onBlur } = field;
  const { getFieldMeta, setFieldValue } = form;

  useEffect(
    () => () => {
      if (destroyOnUnmount) {
        setFieldValue(name, undefined);
      }
    },
    [name, setFieldValue, destroyOnUnmount],
  );

  const { _ } = getFieldMeta(name);

  const onChangeHandler = useCallback(
    (value) => {
      // formatDate(value) returns a string
      // replace it with just value to return a moment.js object
      return setFieldValue(name, formatDate(value));
    },
    [setFieldValue, name],
  );

  return (
    <div style={{ paddingBottom: '5px' }}>
      <KeyboardDatePicker
        autoOk
        fullWidth
        onBlur={onBlur}
        ampm={false}
        value={value}
        format={format}
        margin={margin}
        minDate={minDate}
        onChange={onChangeHandler}
        inputVariant={inputVariant}
        disabled={disabled}
        placeholder={placeholder}
        // empty space will reserve the height needed to display an error
        // helperText={error ? error : ' '}
        // FormHelperTextProps={{
        //   error: !!error,
        // }}
        classes={{ root: classes.customTextField }}
        disableFuture={disableFuture}
        InputProps={{
          style: {
            fontSize: 14,
          },
        }}
        {...DateTimePickerProps}
        {...rest}
      />
    </div>
  );
};

const styles = () => ({
  customTextField: {
    '& input::placeholder': {
      fontSize: '10',
    },
  },
  root: {
    '& .MuiFormLabel-root': {
      fontSize: '10',
    },
  },
});

export default withStyles(styles)(DatePickerFormik);
