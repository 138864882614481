import React from 'react';
import { useCallback } from 'react';
import ProjectRiskTableRowDetails from 'features/projects/details/projectRisk/ProjectRiskTableRowDetails';
import RiskTableRowExpandable from 'features/projects/details/projectRisk/RiskTableRowExpandable';

const ProjectRiskRow = ({ item, projectId, ...rest }) => {
  const renderDetails = useCallback(
    (isExpanded) => (
      <ProjectRiskTableRowDetails
        item={item}
        projectId={projectId}
        isExpanded={isExpanded}
      />
    ),
    [item, projectId],
  );

  return <RiskTableRowExpandable {...rest} renderDetails={renderDetails} />;
};

export default ProjectRiskRow;
