import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import highchartsTheme from 'layout/highcharts.theme';
import { CheetahJobSource } from 'features/projects/dashboard/dashboard.constants';
import DashboardGaugeChart from 'features/projects/dashboard/components/charts/DashboardGaugeChart';

const filterSimulationCurves = (curve) =>
  curve.get('source') !== CheetahJobSource.SIMULATION;

const DashboardGaugeChartsContainer = ({
  curves,
  classes,
  registerDataPointsHandler,
}) => {
  if (!curves) {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <Typography variant="body2" className={classes.noData}>
            No data to display
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={1}
      wrap="nowrap"
      justifyContent="center"
      className={classes.root}
    >
      {curves
        .take(8)
        .filter(filterSimulationCurves)
        .map((curve) => (
          <Grid
            xs
            item
            align="center"
            key={curve.get('id')}
            className={classes.gaugeContainer}
          >
            <DashboardGaugeChart
              curve={curve}
              registerDataPointsHandler={registerDataPointsHandler}
            />
          </Grid>
        ))}
    </Grid>
  );
};

DashboardGaugeChartsContainer.propTypes = {
  registerDataPointsHandler: PropTypes.func.isRequired,
};

const styles = (theme) => ({
  root: {
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(1),
  },
  gaugeContainer: {
    overflow: 'auto',
    height: '100%',
  },
  noData: {
    ...highchartsTheme.noData.style,
  },
});

export default withStyles(styles)(DashboardGaugeChartsContainer);
