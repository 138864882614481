import { PureComponent } from 'react';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import notfound from 'assets/not_found.jpg';

class NoMatchingRoute extends PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <BasePage
        classes={{
          children: classes.basePageChildren,
        }}
      >
        <img src={notfound} alt="Page not found" />
      </BasePage>
    );
  }
}

const styles = {
  basePageChildren: {
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default withStyles(styles)(NoMatchingRoute);
