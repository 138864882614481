import moment from 'moment';
import mappers from 'mappers';
import { fromJS } from 'immutable';

import { EMPTY_LIST } from 'app/app.constants';

import {
  getInitials,
  getAvatar,
  editorStateToString,
  stringToEditorState,
  durationFromStartAndEndTime,
} from 'utils/app.util';

export const projectNonProductiveTimeFromDTO = (npt) => {
  const {
    startTime,
    endTime,
    description,
    departments,
    createdByUserId,
    createdByUserAvatar,
    createdByUserFullName,
  } = npt;
  return fromJS({
    ...npt,
    startTime: moment.utc(startTime).local(),
    endTime: moment.utc(endTime).local(),
    duration: durationFromStartAndEndTime(moment(startTime), moment(endTime)),
    departments: departments
      ? departments.map((department) => mappers.Department.from(department))
      : EMPTY_LIST,
    author: {
      id: createdByUserId,
      name: createdByUserFullName,
      initials: getInitials(createdByUserFullName),
      avatar: getAvatar(createdByUserAvatar),
    },
    description: description ? stringToEditorState(description) : null,
  });
};

export const projectNonProductiveTimeToDTO = (npt) => {
  const { startTime, endTime, description, departments } = npt;
  return {
    ...npt,
    startTime: startTime?.startOf('minute'), // zero out seconds and milliseconds
    endTime: endTime?.startOf('minute'), // zero out seconds and milliseconds
    // error here if no departments
    departments: departments?.map((department) =>
      mappers.Department.to(department),
    ),
    description: description ? editorStateToString(description) : null,
  };
};

const nptMappers = {
  NPT: {
    to: projectNonProductiveTimeToDTO,
    from: projectNonProductiveTimeFromDTO,
  },
};

export default nptMappers;
