import { useCallback } from 'react';
import { isImmutable } from 'immutable';
import { InputAdornment } from '@material-ui/core';

import ReduxFormTextField from 'app/components/form/TextField';
import { EMPTY_QUANTITY, EMPTY_STRING } from 'app/app.constants';

const QuantityTextFieldReduxForm = ({
  meta,
  input,
  margin,
  fullwidth,
  InputProps,
  ...rest
}) => {
  const { value: quantity = EMPTY_QUANTITY, onChange, ...restInput } = input;
  const quantityJS = isImmutable(quantity) ? quantity.toJS() : quantity;

  const { unit, value, hasValue } = quantityJS;

  const quantityOnChange = useCallback(
    (event) =>
      onChange({
        unit,
        value: event.target.value,
        hasValue: !!event.target.value,
      }),
    [unit, onChange],
  );

  return (
    <ReduxFormTextField
      {...rest}
      input={{
        onChange: quantityOnChange,
        value: hasValue ? value : EMPTY_STRING,
        ...restInput,
      }}
      InputProps={{
        ...InputProps,
        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
      }}
    />
  );
};

export default QuantityTextFieldReduxForm;
