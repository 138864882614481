import { compose } from 'redux';
import AddIcon from '@material-ui/icons/Add';
import { fromJS, isImmutable } from 'immutable';
import DeleteIcon from '@material-ui/icons/Close';
import { memo, useMemo, useCallback } from 'react';
import { sortableElement } from 'react-sortable-hoc';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, IconButton, Typography } from '@material-ui/core';

import {
  DragHandle,
  SortableTable,
  TableRowActionsCell,
} from 'altus-ui-components';

import {
  ToolType,
  ToolCategoryType,
} from 'features/projects/tool/tool.constants';

import { formatValue } from 'utils/format.util';
import { getQuantityUnitFromItems } from 'utils/app.util';
import TableCellDense from 'app/components/Table/TableCellDense';
import TableHeaderCellDense from 'app/components/Table/TableHeaderCellDense';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import ToolstringToolsTableRow from 'features/projects/tasks/task/toolstring/components/ToolstringToolsTableRow';

const getSubRows = (row) =>
  isImmutable(row)
    ? row.get('toolAssemblyTools')?.toArray()
    : row.original?.toolAssemblyTools?.toArray();

const SortableFormikRow = sortableElement(EditableTableRowFormik);

const getConnectorName = (item) => item.get('name', '').toUpperCase();

const ToolAssemblyCreatorTable = ({
  classes,
  toolConnectors,
  toolstringTools,
  onDuplicateItem,
  onSortToolstringTool,
  updateItemProperties,
  onDeleteToolstringTool,
  onAddToolAssemblyTools,
  onDeleteToolAssemblyTool,
}) => {
  const lengthUnit = getQuantityUnitFromItems(toolstringTools, (result) =>
    result.get('length'),
  );

  const weightUnit = getQuantityUnitFromItems(toolstringTools, (result) =>
    result.get('weight'),
  );

  const forceUnit = getQuantityUnitFromItems(toolstringTools, (result) =>
    result.get('yieldValue'),
  );

  const diameterUnit = getQuantityUnitFromItems(toolstringTools, (result) =>
    result.get('outerDiameter'),
  );

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 1,
        Header: <TableRowActionsCell minItems={2} />,
        Cell: ({ row }) => {
          const actions = [
            {
              visible: !row.depth,
              content: <DragHandle key={0} />,
            },
            {
              visible: !row.canExpand && !row.depth, // placeholder to keep DragHandle left aligned
              content: null,
            },
            {
              visible: row.canExpand && !row.depth,
              content: (
                <IconButton key={1} {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              ),
            },
            {
              visible: !!row.depth,
              content: <DragHandle key={2} />,
            },
          ];

          return (
            <TableRowActionsCell minItems={2}>
              {actions
                .filter((action) => action.visible)
                .map((action) => action.content)}
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 1,
        id: 'toolItemNo',
        Header: 'Item No.',
        accessor: (tool) =>
          tool.get('m3ItemNumber')
            ? tool.get('m3ItemNumber')
            : tool.get('m3ITNO'),
        Cell: ({ value, row }) => (
          <Typography variant="subtitle2">
            {value
              ? row.original.get('toolGroupCategory') !==
                ToolCategoryType.ThirdParty
                ? `#${value}`
                : value
              : null}
          </Typography>
        ),
      },
      {
        xs: 2,
        id: 'toolName',
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
      {
        xs: 1,
        id: 'toolAccumulatedLength',
        accessor: (tool) =>
          formatValue(tool.getIn(['accumulatedLength', 'value']), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Acc. Length" unit={lengthUnit} />
        ),
      },
      {
        xs: 1,
        id: 'toolFishNeck',
        accessor: (tool) =>
          tool.getIn(['fishNeck', 'value'])
            ? formatValue(tool.getIn(['fishNeck', 'value']), '', 0.01)
            : formatValue(tool.get('fishNeck'), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="FN" unit={diameterUnit} />
        ),
      },
      {
        xs: 1,
        id: 'toolLength',
        accessor: (tool) =>
          tool.getIn(['length', 'value'])
            ? formatValue(tool.getIn(['length', 'value']), '', 0.01)
            : formatValue(tool.get('length') / 1000, '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Length" unit={lengthUnit} />
        ),
      },
      {
        xs: 1,
        id: 'toolWeight',
        accessor: (tool) =>
          tool.getIn(['weight', 'value'])
            ? formatValue(tool.getIn(['weight', 'value']), '', 0.01)
            : formatValue(tool.get('weight'), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Weight" unit={weightUnit} />
        ),
      },
      {
        xs: 1,
        id: 'toolOuterDiameter',
        accessor: (tool) =>
          tool.getIn(['outerDiameter', 'value'])
            ? formatValue(tool.getIn(['outerDiameter', 'value']), '', 0.01)
            : formatValue(tool.get('outerDiameter'), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="OD" unit={diameterUnit} />
        ),
      },
      {
        xs: 1,
        id: 'yieldValue',
        accessor: (tool) =>
          tool.getIn(['yieldValue', 'value'])
            ? formatValue(tool.getIn(['yieldValue', 'value']), '', 0.01)
            : formatValue(tool.get('yield'), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Yield" unit={forceUnit} />
        ),
      },

      {
        xs: 1,
        Header: 'Top C.',
        Cell: ({ row }) => {
          const connectorId = row.original.get('topToolConnectorId');

          if (!toolConnectors?.size || !connectorId) return null;

          return getConnectorName(toolConnectors.get(connectorId));
        },
      },
      {
        xs: 1,
        Header: 'Bot. C.',
        Cell: ({ row }) => {
          const connectorId = row.original.get('bottomToolConnectorId');

          if (!toolConnectors?.size || !connectorId) return null;

          return getConnectorName(toolConnectors.get(connectorId));
        },
      },
      {
        xs: 1,
        id: 'supplier',
        Header: 'Supplier',
        accessor: (tool) => tool.get('supplier'),
        Cell: ({ value: supplier, row }) => {
          const isAssembly = row.original.get('type') === ToolType.ASSEMBLY;

          if (isAssembly) return null;

          return (
            <Typography variant="subtitle2">
              {supplier || 'Altus Inc.'}
            </Typography>
          );
        },
      },
      {
        id: 'actions',
        Header: <TableRowActionsCell minItems={3} />,
        Cell: ({ row }) => {
          const { original: toolstringItem } = row;

          const isToolstringAssembly =
            toolstringItem.get('type') === ToolType.ASSEMBLY;

          const isToolstringAssemblyTool =
            toolstringItem.get('type') === ToolType.ASSEMBLY_TOOL;

          const actions = [
            {
              visible: isToolstringAssembly,
              content: (
                <IconButton
                  key={0}
                  title="Add tool"
                  onClick={() => onAddToolAssemblyTools(toolstringItem)}
                >
                  <AddIcon />
                </IconButton>
              ),
            },
            {
              visible: true,
              content: (
                <IconButton
                  key={1}
                  title="Duplicate item"
                  onClick={() => onDuplicateItem(toolstringItem)}
                >
                  <DuplicateIcon />
                </IconButton>
              ),
            },
            {
              visible: true,
              content: (
                <IconButton
                  key={2}
                  title="Remove tool"
                  onClick={() =>
                    isToolstringAssemblyTool
                      ? onDeleteToolAssemblyTool(toolstringItem)
                      : onDeleteToolstringTool(toolstringItem)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              ),
            },
          ];

          return (
            <TableRowActionsCell minItems={3}>
              {actions
                .filter((action) => action.visible)
                .map((action) => action.content)}
            </TableRowActionsCell>
          );
        },
      },
    ],
    [
      forceUnit,
      lengthUnit,
      weightUnit,
      diameterUnit,
      toolConnectors,
      onDuplicateItem,
      onDeleteToolstringTool,
      onAddToolAssemblyTools,
      onDeleteToolAssemblyTool,
    ],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <SortableFormikRow
        {...props}
        onSubmit={updateItemProperties}
        TableRowComponent={ToolstringToolsTableRow}
      />
    ),
    [updateItemProperties],
  );

  return (
    <Grid
      item
      container
      direction="column"
      xs={12}
      className={classes.scrollable}
    >
      <SortableTable
        useExpanded
        stickyHeader
        useDragHandle
        disableSortBy
        columns={columns}
        useGlobalFilter={false}
        items={fromJS(toolstringTools)}
        getSubRows={getSubRows}
        autoResetExpanded={false}
        onItemSorted={onSortToolstringTool}
        noItemsMessage="No tools in this assebmly"
        TableRowComponent={renderTableRowComponent}
        TableCellComponent={TableCellDense}
        TableHeaderCellComponent={TableHeaderCellDense}
      />
    </Grid>
  );
};

const styles = () => ({
  scrollable: {
    overflowY: 'auto',
  },
});

export default compose(memo, withStyles(styles))(ToolAssemblyCreatorTable);
