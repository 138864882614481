import React, { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';

const formatTime = (inputValue) => {
  let newValue = inputValue.replace(/[^0-9]/g, '');
  if (newValue.length >= 2) {
    newValue = `${newValue.substring(0, 2)}:${newValue.substring(2)}`;
  }
  return newValue.substring(0, 5);
};

const TimeSpanReduxForm = ({ input, ...props }) => {
  const { value, onChange } = input;
  const inputRef = useRef(null);
  const [formattedValue, setFormattedValue] = useState(formatTime(value || ''));

  useEffect(() => {
    setFormattedValue(formatTime(value || ''));
  }, [value]);

  const handleInputChange = (event) => {
    const cursorPosition = inputRef.current.selectionStart;
    const newValue = formatTime(event.target.value);

    setFormattedValue(newValue);
    onChange(newValue);

    setTimeout(() => {
      if (cursorPosition <= 2) {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);
  };

  return (
    <TextField
      {...props}
      value={formattedValue}
      onChange={handleInputChange}
      inputRef={inputRef}
      margin="normal"
      fullWidth={true}
    />
  );
};

export default TimeSpanReduxForm;
