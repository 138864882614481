import { EMPTY_MAP } from 'app/app.constants';
import { createSelector } from 'utils/app.util';

export const getFieldWithOrganizationsFromState = (fieldId) =>
  createSelector(
    (state) => state.getIn(['entity', 'field']),
    (fields) => {
      if (!fieldId || !fields) {
        return EMPTY_MAP;
      }

      return fields?.get(fieldId.toString());
    },
  );

export const getFieldDocumentsFromState = createSelector(
  (state) => state.getIn(['entity', 'fieldDocuments']),
  (documents) => documents,
);
