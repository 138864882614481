export const DATA_ACQUISITION_ACTIONS = {
  GET_DATA_ACQUISITION_ID: 'GET_DATA_ACQUISITION_ID',
  GET_DATA_ACQUISITION_BY_TASK: 'GET_DATA_ACQUISITION_BY_TASK',
  UPDATE_DATA_ACQUISITION: 'UPDATE_DATA_ACQUISITION',
  DELETE_DATA_ACQUISITION: 'DELETE_DATA_ACQUISITION',
  GET_ALL_DEBRIS_TYPES: 'GET_ALL_DEBRIS_TYPES',
  GET_ALL_GREASE_TYPES: 'GET_ALL_GREASE_TYPES',
  GET_ALL_GLYCOL_TYPES: 'GET_ALL_GLYCOL_TYPES',
  GET_ALL_FRICTION_REDUCER_TYPES: 'GET_ALL_FRICTION_REDUCER_TYPES',
  GET_ALL_PRODUCTION_MEDIUMS: 'GET_ALL_PRODUCTION_MEDIUMS',
  GET_ALL_INJECTION_MEDIUMS: 'GET_ALL_INJECTION_MEDIUMS',
  GET_EXPLOSIVES: 'GET_EXPLOSIVES',
  UPDATE_EXPLOSIVES: 'UPDATE_EXPLOSIVES',
};

export const DATA_ACQUISITION_FORM = {
  EDIT_FORM_ID: 'editDataAcquisition',
};

export const WELL_TAB_FIELDS = {
  WELLHEAD_PRESSURE: 'wellheadPressure',
  RESERVOIR_PRESSURE: 'reservoirPressure',
  RESERVOIR_DEPTH: 'reservoirDepth',
  RESERVOIR_TEMPERATURE: 'reservoirTemperature',
  HYDROGEN_SULFIDE: 'hydrogenSulfide',
  CARBON_DIOXIDE: 'carbonDioxide',
  SURFACE_TEMPERATURE: 'surfaceTemperature',
  FLOW_PATH: 'flowPath',
  INJECTION_MEDIUM: 'injectionMediumId',
  PRODUCTION_MEDIUM: 'productionMediumId',
  GAS_RATE: 'gasRate',
  LIQUID_RATE: 'liquidRate',
  INJECTION_RATE: 'injectionRate',
  INJECTION_PRODUCTION_VOLUME: 'injectionProductionVolume',
};

export const CABLE_TAB_FIELDS = {
  FLOWTUBE: 'flowtube',
  MAX_TENSION: 'maxTension',
  WEAK_POINT_BUILD: 'weakPointBuild',
  DISTANCE_WINCH_TO_FIRST_SHAVE: 'distanceWinchToLowerSheave',
  LOWER_WINCH_TO_FIRST_SHAVE: 'lowerSheaveToUpperSheave',
  LOWER_TOUPPER_SHEAVE_WHEEL: 'lowerToUpperSheaveWheel',
  COMMENTS: 'cableComments',
};

export const TOOLS_TAB_FIELDS = {
  TIME_LOGGED: 'timeLogged',
  DISTANCE_LOGGED: 'distanceLogged',
  DEPTH_OF_CUT: 'depthOfCut',
  DESCRIPTION: 'description',
  METERS_TRACTORED: 'metersTractored',
  TIME_TRACTORED: 'timeTractored',
  JARING_TIME: 'jaringTime',
  JARING_STROKES: 'jaringStrokes',
  DEBRIS_VOLUME_COLLECTED: 'debrisVolumeCollected',
  NOGO_SIZE: 'nogoSize',
  DISTANCE_MILLED: 'distanceMilled',
  TIME_MILLED: 'timeMilled',
  MILLING_SPEED: 'millingSpeed',
  ROTATION_SPEED: 'rotationSpeed',
  DEBRIS_TYPE: 'debrisTypeId',
};

export const CONSUMABLES_TAB_FIELDS = {
  GREASE_TYPE_ID: 'typeOfGreaseId',
  GREASE_CONSUMPTION: 'greaseConsumption',
  GLYCOL_TYPE_ID: 'typeOfGlycolId',
  GLYCOL_CONSUMPTION: 'glycolConsumption',
  FRICTION_REDUCER_TYPE_ID: 'typeOfFrictionReducerId',
  FRICTION_REDUCER_CONSUMPTION: 'frictionReducerConsumption',
  TYPE_OF_HYDRAULIC_FUID: 'typeOfHydraulicFluid',
  HYDRAULIC_FUID_CONSUMPTION: 'hydraulicFluidConsumption',
  FRICTION_REDUCER_DESCRIPTION: 'frictionReducerDescription',
};

export const EXPLOSIVES_FIELDS = {
  BOOSTER_BATCH_NUMBER: 'boosterBatchNumber',
  CHARGE_BATCH_NUMBER: 'chargeBatchNumber',
  CHARGER_TYPE_ID: 'chargerTypeId',
  CONSUMPTION: 'consumption',
  CORD_BATCH_NUMBER: 'cordBatchNumber',
  CUTTER_BATCH_NUMBER: 'cutterBatchNumber',
  IGNITER_BATCH_NUMBER: 'igniterBatchNumber',
  NUMBER_OF_BOOSTERS_USED: 'numberOfBoostersUsed',
  NUMBER_OF_CHARGERS_USED: 'numberOfChargersUsed',
  NUMBER_OF_IGNITERS_USED: 'numberOfIgnitersUsed',
};
