import Highcharts from 'highcharts';
import { isMobileOnly } from 'react-device-detect';

import themes, { defaultThemeType } from 'layout/themes';

import dashboardHighchartsTheme from 'features/projects/dashboard/components/dashboards/winch/dashboard.highcharts.theme';

const theme = themes[defaultThemeType];

const { fontColor, defaultFontSize, capFontSize } =
  dashboardHighchartsTheme.style;

const winchHighchartsTheme = {
  chart: {
    panning: {
      enabled: true,
      type: 'xy',
    },
    spacingRight: -20,
    panKey: 'alt',
    zoomType: 'y',
    pinchType: 'y',
    resetZoomButton: {
      position: {
        align: 'left',
        verticalAlign: 'bottom',
        x: 5,
        y: -100,
      },
    },
    alignTicks: !isMobileOnly,
    spacingBottom: 2,
    overflow: 'hidden',
  },
  navigator: {
    enabled: true,
    margin: 2,
    xAxis: {
      labels: {
        align: 'left',
        style: {
          color: fontColor,
          fontWeight: 'normal',
        },
        x: 3,
        y: -4,
      },
    },
    series: {
      type: 'line',
      fillOpacity: 0.05,
      dataGrouping: {
        smoothed: true,
      },
      lineWidth: 1,
      marker: {
        enabled: false,
      },
    },
    handles: {
      enabled: false,
    },
  },
  navigation: {
    buttonOptions: {
      enabled: true,
      align: 'left',
    },
  },
  plotOptions: {
    gauge: {
      dataLabels: {
        allowOverlap: true,
        animation: false,
        padding: 10,
        shadow: true,
        style: {
          color: fontColor,
          fontSize: defaultFontSize,
        },
        zIndex: 10,
        borderRadius: 5,
        backgroundColor: theme.palette.secondary.dark,
        borderColor: theme.palette.secondary.main,
        y: -18,
        formatter: function () {
          const { unit } = this.series.options;
          const yValue = this.y,
            formatted = Highcharts.numberFormat(yValue, 2);
          return `<div><span>${formatted} ${unit}</span></div>`;
        },
      },
      dial: {
        radius: '100%',
        borderWidth: 2,
        baseWidth: 20,
        baseLength: '0%', // of radius
        rearLength: '5%',
      },
    },
    series: {
      showInNavigator: true,
      animation: false,
      boostThreshold: 1,
      turboThreshold: 1,
      requireSorting: false,
      lineWidth: 1,
      symbol: 'square',
      allowPointSelect: true,
      marker: {
        enabled: true,
        symbol: 'square',
        // https://api.highcharts.com/highcharts/series.line.marker.radius
        radius: 0, // Make the legend , series and tool tip icons bigger.
        //https://api.highcharts.com/highcharts/series.line.marker.enabledThreshold
        enabledThreshold: 5,
        lineColor: null,
        states: {
          hover: {
            radius: 6,
          },
        },
      },
      events: {
        hide: function () {
          this.yAxis.update({
            visible: false,
          });
        },
        show: function () {
          this.yAxis.update({
            visible: true,
          });
        },
      },
    },
  },
  title: {
    style: {
      color: fontColor,
      fontSize: defaultFontSize,
    },
  },
  time: {
    useUTC: false,
  },
  rangeSelector: {
    floating: true,
    inputEnabled: false,
    buttonPosition: {
      align: 'left',
      x: 0,
      y: -100, // Negative y moves it up, positive y moves it down.
    },
    verticalAlign: 'bottom',
    labelStyle: {
      color: fontColor,
      fontSize: capFontSize,
    },
  },
  xAxis: {
    reversed: false,
    minRange: 36000,
    labels: {
      style: {
        color: fontColor,
        fontWeight: 'normal',
        fontSize: capFontSize,
      },
    },
    tickWidth: 2,
  },
};

export default Highcharts.merge(
  false,
  dashboardHighchartsTheme,
  winchHighchartsTheme,
);
