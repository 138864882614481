export const FACILITIES_ACTIONS = {
  GET_OPERATORS: 'GET_OPERATORS',
  FACILITIES_PAGE_LOADED: 'FACILITIES_PAGE_LOADED',
  GET_FACILITIES_FOR_ORG: 'GET_FACILITIES_FOR_ORG',
  RECEIVE_FACILITIES_PAGE: 'RECEIVE_FACILITIES_PAGE',
  UPDATE_FACILITIES_FILTER: 'UPDATE_FACILITIES_FILTER',
  GET_FACILITY_BY_ID: 'GET_FACILITY_BY_ID',
  GET_FACILITY_IMAGE: 'GET_FACILITY_IMAGE',
  GET_FACILITY_LOCATION: 'GET_FACILITY_LOCATION',
  GET_FACILITY_DECKS: 'GET_FACILITY_DECKS',
};

export const FacilityStatus = {
  Unknown: 0,
  Abandoned: 1,
  Decommissioned: 2,
  Fabrication: 3,
  Future: 4,
  InService: 5,
  Installation: 6,
  LaidUp: 7,
  Removed: 8,
};
