import { compose } from 'redux';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { useCallback, useMemo } from 'react';

import { useModal } from 'altus-modal';

import {
  CREATE_PROJECT_MODAL_ID,
  CreateProjectFormFields as FormFields,
} from 'features/projects/projects.constants';

import { useCurrentUser } from 'app/hooks/useCurrentUser';
import { submitCreateProjectForm } from 'features/projects/projects.actions';
import { validateCreateProjectForm } from 'features/projects/project.validation';
import CreateProjectModalForm from 'features/projects/components/CreateProjectModalContainer/CreateProjectModalForm';

const defaultInitialValues = {
  [FormFields.FIELD_ID]: null,
  [FormFields.FACILITY_ID]: null,
  [FormFields.WELLBORE_ID]: null,
  [FormFields.ORGANIZATION_ID]: null,
  [FormFields.OBJECTIVE_ID]: null,
};

const CreateProjectModalContainer = ({
  title = 'Create project',
  dispatchSubmitCreateProjectForm,
  initialValues = defaultInitialValues,
}) => {
  const currentUser = useCurrentUser();

  const [open, toggleModal] = useModal(CREATE_PROJECT_MODAL_ID);

  const onSubmit = useCallback(
    (project, formik) =>
      dispatchSubmitCreateProjectForm(project, formik, toggleModal),
    [toggleModal, dispatchSubmitCreateProjectForm],
  );

  // initially set OrganizationId if currentUser is not Main vendor
  const initialFormValues = useMemo(() => {
    const initialOrganizationId = currentUser.get('isMainVendor')
      ? defaultInitialValues[FormFields.ORGANIZATION_ID]
      : currentUser.get('organizationId');

    if (initialOrganizationId) {
      return {
        ...initialValues,
        [FormFields.ORGANIZATION_ID]: initialOrganizationId,
      };
    }

    return {
      ...initialValues,
    };
  }, [currentUser, initialValues]);

  return (
    <Dialog open={open} fullScreen onClose={toggleModal}>
      <Formik
        validateOnMount
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={validateCreateProjectForm}
      >
        {(formik) => (
          <CreateProjectModalForm
            open={open}
            title={title}
            onClose={toggleModal}
            {...formik}
          />
        )}
      </Formik>
    </Dialog>
  );
};

export default compose(
  connect(null, {
    dispatchSubmitCreateProjectForm: submitCreateProjectForm,
  }),
)(CreateProjectModalContainer);
