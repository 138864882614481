import { OrderedMap } from 'immutable';

import { EMPTY_MAP } from 'app/app.constants';
import { ACTIONS } from 'features/projects/tasks/task/simulation/simulation.constants';
import simulationMappers from 'features/projects/tasks/task/simulation/simulation.mappers';

const initialState = EMPTY_MAP;

const simulationReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.DELETE_SIMULATION: {
      if (error) return state;

      const { simulationId } = action;

      return state.delete(simulationId.toString());
    }

    case ACTIONS.RECEIVE_SIMULATION: {
      if (error) return state;

      return state.set(
        payload.simulationId.toString(),
        simulationMappers.Simulation.from(payload),
      );
    }

    case ACTIONS.RECEIVE_SIMULATIONS_FOR_TASK: {
      if (error) return state;

      return OrderedMap(
        payload.map((simulation) => [
          simulation.simulationId.toString(),
          simulationMappers.Simulation.from(simulation),
        ]),
      );
    }

    default:
      return state;
  }
};

export default simulationReducer;
