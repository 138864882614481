import React from 'react';
import { Grid } from '@material-ui/core';
import { useHeader } from 'app/hooks/useHeader';
import routePaths from 'app/routePaths';
import MobileApplicationHeaderBase from 'app/components/ApplicationHeader/Mobile/MobileApplicationHeaderBase';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';

const MobilePrivacyPolicyHeader = () => {
  let history = useHistory();
  useHeader({
    title: 'Privacy Policy',
    logoNavigation: routePaths.projects,
  });
  return (
    <MobileApplicationHeaderBase>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <CloseIcon onClick={() => history.goBack()} color="primary" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MobileApplicationHeaderBase>
  );
};

export default MobilePrivacyPolicyHeader;
