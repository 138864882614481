import { compose } from 'redux';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Grid, Tabs, Tab, withStyles, Divider, Box } from '@material-ui/core';

import { BasePage } from 'altus-ui-components';

import { getSummarizedDataStateFromState } from 'app/app.selectors';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import ProjectWellboreHeader from 'features/projects/wellbore/ProjectWellboreHeader';
import { PROJECT_WELLBORE_ACTIVE_TAB } from 'features/equipment/equipment.constants';
import ProjectWellboreOverview from 'features/projects/wellbore/survey/ProjectWellboreOverview';
import ProjectWellboreDetailsContainer from 'features/projects/wellbore/details/ProjectWellboreDetailsContainer';
import { requestProjectWellboreDetails } from 'features/projects/wellbore/details/projectWellboreDetails.actions';
import useLatestProjectWellboreDetailsWithId from 'features/projects/hooks/useLatestProjectWellboreDetailsWithId';
import ProjectWellboreSectionsContainer from 'features/projects/wellbore/sections/ProjectWellboreSectionsContainer';
import ProjectWellboreDetailStatusHeader from 'features/projects/wellbore/details/ProjectWellboreDetailStatusHeader';
import { getActiveProjectWellboreDetailIdFromState } from 'features/projects/wellbore/details/projectWellboreDetails.selectors';

const ProjectWellboreNewContainer = ({ classes, projectId, dataState }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(
    PROJECT_WELLBORE_ACTIVE_TAB.OVERVIEW,
  );
  const [activeWellboreDetailId, setActiveWellboredetailId] = useState(null);

  const activeWellboreDetailIdFromState = useSelector(
    getActiveProjectWellboreDetailIdFromState,
  );

  useEffect(() => {
    dispatch(requestProjectWellboreDetails(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (activeWellboreDetailIdFromState != null) {
      setActiveWellboredetailId(activeWellboreDetailIdFromState);
    }
  }, [activeWellboreDetailIdFromState]);

  const [latestWellboreDetailId = null] =
    useLatestProjectWellboreDetailsWithId(projectId);

  const project = useCurrentProject();

  const handleActiveWellboreId = (value) => {
    setActiveWellboredetailId(value);
  };

  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  const activeTabHandler = (activeTab) => {
    switch (activeTab) {
      default:
        return null;
      case PROJECT_WELLBORE_ACTIVE_TAB.OVERVIEW:
        return <ProjectWellboreOverview projectId={projectId} />;
      case PROJECT_WELLBORE_ACTIVE_TAB.DETAILS:
        return (
          <ProjectWellboreDetailsContainer
            projectId={projectId}
            activeWellboreDetailId={activeWellboreDetailId}
          />
        );
      case PROJECT_WELLBORE_ACTIVE_TAB.SECTIONS:
        return (
          <ProjectWellboreSectionsContainer
            projectId={projectId}
            breadcrumb="Sections"
            activeWellboreDetailId={
              activeWellboreDetailId
                ? activeWellboreDetailId
                : latestWellboreDetailId
            }
          />
        );
    }
  };

  return (
    <BasePage dataState={dataState}>
      <ProjectWellboreDetailStatusHeader
        projectId={projectId}
        currentProjectWellboreDetailId={
          activeWellboreDetailId
            ? activeWellboreDetailId
            : latestWellboreDetailId
        }
      />
      <ProjectWellboreHeader
        project={project}
        projectId={projectId}
        activeTab={activeTab}
        handleActiveWellboreId={handleActiveWellboreId}
        projectWellboreDetailId={
          activeWellboreDetailId
            ? activeWellboreDetailId
            : latestWellboreDetailId
        }
      />
      <Tabs
        value={activeTab}
        onChange={handleTabs}
        className={classes.tabsContainer}
      >
        <Tab
          label="Survey"
          value={PROJECT_WELLBORE_ACTIVE_TAB.OVERVIEW}
          className={classes.tab}
        />
        <Tab
          label="Details"
          value={PROJECT_WELLBORE_ACTIVE_TAB.DETAILS}
          className={classes.tab}
        />
        <Tab
          label="Sections"
          value={PROJECT_WELLBORE_ACTIVE_TAB.SECTIONS}
          className={classes.tab}
        />
        <Divider className={classes.divider} />
      </Tabs>
      <Box className={classes.box}>
        <Grid
          container
          className={classes.contentContainer}
          style={{ height: '100%' }}
        >
          {activeTabHandler(activeTab)}
        </Grid>
      </Box>
    </BasePage>
  );
};

const styles = (theme) => ({
  contentContainer: {
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  box: {
    height: '100%',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  tabsContainer: {
    width: '100%',
  },
  tab: {
    backgroundColor: 'transparent !important',
  },
  tabPanel: {
    flex: 1,
    padding: theme.spacing(1),
    backgroundColor: 'transparent',
  },
});

export default compose(
  connect((state) => ({
    dataState: getSummarizedDataStateFromState(state),
  })),
  withStyles(styles),
)(ProjectWellboreNewContainer);
