import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import NoMatchingRoute from 'app/components/NoMatchingRoute';
import ValidRoutesContainer from 'app/components/ValidRoutesContainer';
import { hasRequiredProjectPermissions } from 'utils/authorization.util';
import { getCurrentUserProjectPermissionsFromState } from 'authorization/authorization.selectors';

const ProjectRoutesContainer = ({
  routes,
  render,
  MissingRouteComponent = NoMatchingRoute,
}) => {
  const userProjectPermissions = useSelector(
    getCurrentUserProjectPermissionsFromState,
  );

  const hasRoutePermission = useCallback(
    (route) => {
      if (!route.projectPermissions) return true;

      return hasRequiredProjectPermissions(
        userProjectPermissions,
        route.projectPermissions,
      );
    },
    [userProjectPermissions],
  );

  return (
    <ValidRoutesContainer
      routes={routes}
      render={render}
      hasPermission={hasRoutePermission}
      MissingRouteComponent={MissingRouteComponent}
    />
  );
};

export default ProjectRoutesContainer;
