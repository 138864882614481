import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTheme } from '@material-ui/core/styles';

const defaultFormat = (value) =>
  value instanceof EditorState ? value : EditorState.createEmpty();

const useStyles = makeStyles({
  editorClassFocus: () => ({
    width: '100%',
    paddingLeft: '10px',
    border: '1px solid #90caf9',
    borderRadius: '4px',
    fontSize: '1rem',
  }),
  editorClass: {
    width: '100%',
    paddingLeft: '10px',
    border: '1px solid #595959',
    borderRadius: '4px',
    fontSize: '1rem',
    flex: '1 1 auto',
    '& .DraftEditor-root': {
      display: 'flex',
    },
  },
});

const EditorFormik = ({
  form,
  field,
  format = defaultFormat,
  destroyOnUnmount = false,
  toolbarHeight,
  label,
  showbarByDefault = false,
  xs = 6,
  editorXS = 0,
  generalMarginTop = 12,
  editorMarginTop = 10,
  marginBottom = 0,
  disabled = false,
  labelMarginLeft = '0',
  minHeight = 4.5,
  maxHeight = 4.5,
  isRisk = false,
  ...rest
}) => {
  const theme = useTheme();
  const { value, onChange, name, onBlur } = field;
  const { getFieldMeta, setFieldValue } = form;

  const [isFocused, setIsFocused] = useState(false);
  const [show, setShow] = useState(showbarByDefault);

  useEffect(() => {
    return () => {
      if (destroyOnUnmount) {
        // clear value and unregister field
        setFieldValue(name, EditorState.createEmpty());
      }
    };
  }, [name, setFieldValue, destroyOnUnmount]);

  const { touched, error } = getFieldMeta(name);

  const formattedValue = format ? format(value) : EditorState.createEmpty();

  const handleEditorStateChange = (editorState) => {
    onChange({ target: { name, value: editorState } });
  };

  const handleBlur = (event) => {
    if (!showbarByDefault) {
      setShow(false);
    }
    setIsFocused(false);
    onBlur(event);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setShow(true);
  };
  const classes = useStyles({ minHeight, maxHeight });
  const editorClassName = isFocused
    ? classes.editorClassFocus
    : classes.editorClass;

  const handlePastedText = (text, _html, editorState) => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    const newContent = Modifier.replaceText(currentContent, selection, text);

    onChange({
      target: {
        name,
        value: EditorState.push(editorState, newContent, 'insert-characters'),
      },
    });

    return 'handled';
  };

  return (
    <Grid
      container
      item
      xs={xs}
      style={{
        marginBottom: `${5}px`,
        marginTop: `${generalMarginTop}px`,
        display: 'flex',
        flex: '1 1 auto',
        height: isRisk ? '100%' : 'unset',
      }}
    >
      <Grid item>
        {label ? (
          <label
            style={{ marginLeft: `${labelMarginLeft}px` }}
            className="editor-label"
          >
            {label}
          </label>
        ) : (
          <></>
        )}
      </Grid>
      <Grid
        style={{
          width: '100%',
          height: '100%',
          marginBottom: `${marginBottom}px`,
          marginTop: `${editorMarginTop}px`,
        }}
        xs={editorXS}
      >
        <Editor
          readOnly={disabled}
          editorState={formattedValue}
          onEditorStateChange={handleEditorStateChange}
          onBlur={handleBlur}
          name={name}
          toolbarClassName="my-toolbar"
          toolbarStyle={{
            visibility: `${show ? 'visible' : 'hidden'}`,
            paddingTop: '2px',
          }}
          editorClassName={editorClassName}
          wrapperStyle={{
            height: '100%',
            color: `${
              disabled
                ? theme.altus.components.RichtextEditor.disabled
                : theme.altus.components.RichtextEditor.main
            }`,
          }}
          error={!!error && touched}
          helperText={touched ? error : undefined}
          onFocus={handleFocus}
          {...rest}
          toolbar={{
            options: ['inline', 'list'],
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            list: {
              inDropdown: false,
              options: ['unordered', 'ordered'],
            },
          }}
          handlePastedText={handlePastedText}
        />
      </Grid>
    </Grid>
  );
};
EditorFormik.propTypes = {
  format: PropTypes.func,
  destroyOnUnmount: PropTypes.bool,
};

export default EditorFormik;
