import { fromJS } from 'immutable';

import {
  getInitials,
  getAvatar,
  editorStateToString,
  stringToEditorState,
} from 'utils/app.util';

const incident = () => {
  return {
    id: null,
    title: null,
    description: null,
    caseNumber: null,
    author: { id: null },
    created: null,
    modified: null,
  };
};

const incidentFromDTO = ({
  incidentId,
  title,
  description,
  caseNumber,
  createdByUserId,
  createdByUserFullName,
  createdByUserAvatar,
  created,
  modified,
}) => {
  return fromJS({
    id: incidentId,
    title: title,
    description: description ? stringToEditorState(description) : null,
    caseNumber: caseNumber,
    author: {
      id: createdByUserId,
      name: createdByUserFullName,
      initials: getInitials(createdByUserFullName),
      avatar: getAvatar(createdByUserAvatar),
    },
    created: created,
    modified: modified,
  });
};

const incidentToDTO = ({ title, description, caseNumber }) => {
  return {
    title: title,
    description: description ? editorStateToString(description) : null,
    caseNumber: caseNumber,
  };
};

const incidentMappers = {
  Incident: {
    initial: incident,
    from: incidentFromDTO,
    to: incidentToDTO,
  },
};

export default incidentMappers;
