import { SvgIcon } from '@material-ui/core';

const DataExchangeIcon = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 48 48"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0h48v48H0V0z"
      style={{
        fill: 'none',
      }}
    />
    <path d="M38.7 20.1C37.3 13.2 31.3 8 24 8c-5.8 0-10.8 3.3-13.3 8.1C4.7 16.7 0 21.8 0 28c0 6.6 5.4 12 12 12h26c5.5 0 10-4.5 10-10 0-5.3-4.1-9.6-9.3-9.9zM22.8 32.5h-4c-2.8 0-5-2.2-5-5s2.2-5 5-5h4v1.9h-4c-1.7 0-3.1 1.4-3.1 3.1 0 1.7 1.4 3.1 3.1 3.1h4v1.9zm5-6v2h-8v-2h8zm1 6h-4v-1.9h4c1.7 0 3.1-1.4 3.1-3.1 0-1.7-1.4-3.1-3.1-3.1h-4v-1.9h4c2.8 0 5 2.2 5 5s-2.2 5-5 5z" />
  </SvgIcon>
);

export default DataExchangeIcon;
