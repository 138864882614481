import { invokeIfFunction } from 'utils/app.util';

export const updateSeriesData = (chartRef, readings, callback) => {
  const chart = chartRef.current.chart;

  if (!chart.series) return;

  readings.forEach((reading) => {
    const series = chart.series.filter(
      (serie) => serie.options.id === reading.id,
    );

    series.forEach((serie) => invokeIfFunction(callback, reading, serie));
  });

  chart.reflow();
  chart.redraw();
};
