import { compose } from 'redux';
import { connect } from 'react-redux';
import capitalize from 'lodash/capitalize';
import Settings from '@material-ui/icons/Settings';
import ExitToApp from '@material-ui/icons/ExitToApp';
import withStyles from '@material-ui/styles/withStyles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import { Grid, MenuItem, ListItemText, ListItemIcon } from '@material-ui/core';

import {
  MODAL,
  CONVERT_SYSTEMS,
  CUSTOM_UNIT_SYSTEM,
  USE_PROJECT_DEFAULT_UNIT_SYSTEM,
} from 'app/app.constants';

import { toChangeLog, toLogout } from 'utils/route.util';
import { invokeIfFunction } from 'utils/app.util';
import DropdownMenu from 'app/components/DropdownMenu';
import { changeUser } from 'features/login/login.actions';
import withNavigation from 'app/components/withNavigation';
import { getCurrentUserFromState } from 'app/app.selectors';
import { Avatar as TooltipAvatar } from 'app/components/withTooltip';
import { toggleModal, initializeUserPreferences } from 'app/app.actions';
import UserPreferencesModalContainer from 'app/components/UserPreferencesModalContainer';

const NavigationMenuItem = withNavigation(MenuItem);

const Avatar = ({ user, classes, onClick }) => (
  <TooltipAvatar
    title={user.get('name')}
    src={user.get('avatar')}
    onClick={onClick}
    classes={{
      root: classes.triggerIconRoot,
    }}
  >
    {!user.get('avatar') && user.get('initials')}
  </TooltipAvatar>
);

const UserMenuContainer = ({
  user,
  Icon,
  classes,
  hideUsername,
  dispatchChangeUser,
  dispatchToggleModal,
  dispatchInitializeUserPreferences,
}) => {
  if (!user || (!user.get('avatar') && !user.get('initials'))) {
    return null;
  }

  const avatar = <Avatar user={user} classes={classes} />;
  const triggerIcon = Icon ? (
    <Icon
      color="secondary"
      classes={{
        root: classes.triggerIconRoot,
      }}
    />
  ) : (
    avatar
  );

  return (
    <>
      <DropdownMenu Trigger={triggerIcon}>
        {[
          {
            text: user.get('name').split(' ')[0],
            Icon: avatar,
            disabled: true,
            hidden: hideUsername,
          },
          {
            text: 'Preferences',
            Icon: <Settings />,
            onClick: () =>
              dispatchToggleModal({
                modalId: MODAL.EDIT_USER_PREFERENCES,
              }),
          },
          {
            text: 'Change user',
            Icon: <AccountCircle />,
            onClick: dispatchChangeUser,
          },
          {
            text: 'Changelog',
            Icon: <DescriptionOutlined />,
            to: toChangeLog(),
          },
          {
            text: 'Log out',
            Icon: <ExitToApp />,
            to: toLogout(),
          },
        ]
          .filter((x) => !x.hidden)
          .map((item) => {
            const { text, Icon, disabled, onClick, to } = item;

            return (
              <NavigationMenuItem
                to={to}
                key={text}
                disabled={disabled}
                onClick={() => invokeIfFunction(onClick)}
              >
                <Grid container alignItems="center">
                  <Grid item xs>
                    <ListItemText>{text}</ListItemText>
                  </Grid>
                  <Grid container item xs={2} justifyContent="center">
                    <ListItemIcon>{Icon}</ListItemIcon>
                  </Grid>
                </Grid>
              </NavigationMenuItem>
            );
          })}
      </DropdownMenu>
      <UserPreferencesModalContainer
        title="User settings"
        onEnter={() =>
          dispatchInitializeUserPreferences(
            user.get('unit'),
            user.get('units'),
            user.get('preferences'),
          )
        }
        descriptions={{
          [USE_PROJECT_DEFAULT_UNIT_SYSTEM]:
            'Is the same as specified on projects',
          [CUSTOM_UNIT_SYSTEM]:
            'Is displayed in customized units defined by you',
          [CONVERT_SYSTEMS.METRIC]: 'Is displayed in Metric, just to you',
          [CONVERT_SYSTEMS.IMPERIAL]: 'Is displayed in Imperial, just to you',
        }}
        options={[
          {
            value: USE_PROJECT_DEFAULT_UNIT_SYSTEM,
            label: USE_PROJECT_DEFAULT_UNIT_SYSTEM,
          },
          {
            value: CUSTOM_UNIT_SYSTEM,
            label: CUSTOM_UNIT_SYSTEM,
          },
          ...Object.values(CONVERT_SYSTEMS).map((system) => ({
            value: system,
            label: capitalize(system),
          })),
        ]}
      />
    </>
  );
};

const styles = () => ({
  triggerIconRoot: {
    height: 40,
    width: 40,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
});

export default compose(
  connect(
    (state) => ({
      user: getCurrentUserFromState(state),
    }),
    {
      dispatchChangeUser: changeUser,
      dispatchToggleModal: toggleModal,
      dispatchInitializeUserPreferences: initializeUserPreferences,
    },
  ),
  withStyles(styles),
)(UserMenuContainer);
