import { Route, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import makeStyles from '@material-ui/styles/makeStyles';

import routePaths from 'app/routePaths';
import EditFluidForm from 'app/components/FluidsFormSection/FluidEditor/EditFluidForm';
import CreateFluidForm from 'app/components/FluidsFormSection/FluidEditor/CreateFluidForm';
import FluidEditorHeader from 'app/components/FluidsFormSection/FluidEditor/FluidEditorHeader';

const SimulationFluidEditor = ({
  disabled,
  isCreateMode,
  inPlanning,
  simulationFluid,
  toggleCreateMode,
  simulationFluidId,
  updateSimulationFluid,
  createSimulationFluid,
  defaultSimulationFluid,
}) => {
  const classes = useStyles();

  if (!isCreateMode && !simulationFluidId) {
    return (
      <FluidEditorHeader
        displayNoItemsMessage
        isCreateMode={isCreateMode}
        noItemsMessage="No fluid selected — select a fluid to the left or click «Add» to add one"
      />
    );
  }

  return (
    <>
      <FluidEditorHeader displayNoItemsMessage={false} />
      <Grid container className={classes.root}>
        <Switch>
          {isCreateMode && (
            <Route
              exact
              path={
                inPlanning
                  ? routePaths.plan.task.simulation.fluids.root
                  : routePaths.execution.tabs.simulation.tabs.fluids.root
              }
            >
              <CreateFluidForm
                onCancel={toggleCreateMode}
                createFluid={createSimulationFluid}
                defaultFluid={defaultSimulationFluid}
              />
            </Route>
          )}
          <Route
            key={simulationFluidId}
            path={
              inPlanning
                ? routePaths.plan.task.simulation.fluids.index
                : routePaths.execution.tabs.simulation.tabs.fluids.index
            }
          >
            <EditFluidForm
              disabled={disabled}
              isCreateMode={isCreateMode}
              fluid={simulationFluid}
              toggleCreateMode={toggleCreateMode}
              updateFluid={updateSimulationFluid}
            />
          </Route>
        </Switch>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    background: theme.altus.components.SortableListRow.background,
  },
}));

export default SimulationFluidEditor;
