import { compose } from 'redux';
import { useCallback } from 'react';
import { Field, Formik, Form } from 'formik';
import Close from '@material-ui/icons/Close';
import BarChartIcon from '@material-ui/icons/BarChart';
import withStyles from '@material-ui/styles/withStyles';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ArchiveIcon from '@material-ui/icons/Archive';
import Typography from '@material-ui/core/Typography';

import { Box, Grid, IconButton, InputAdornment } from '@material-ui/core';

import { PROJECT_VIEWS } from 'features/projects/projects.constants';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';

export const Filters = {
  PROJECT_STATUS: 'projectStatus',
  FACILITY_ID: 'facilityId',
  FIELD_ID: 'fieldId',
  DEPARTMENT_ID: 'departmentId',
  TEXT_SEARCH: 'textSearch',
  ORGANIZATION_ID: 'organizationId',
  GET_LOCATIONS: 'getLocations',
  IS_ARCHIVE: 'isArchive',
  PROJECT_STATUSES: 'ProjectStatuses',
  GANTT_VIEW: 'ganttView',
};

const defaultFilters = [
  Filters.PROJECT_STATUS,
  Filters.FACILITY_ID,
  Filters.FIELD_ID,
  Filters.DEPARTMENT_ID,
  Filters.TEXT_SEARCH,
  Filters.GET_LOCATIONS,
];

const shouldSubmit = ({ isValid }) => isValid;

const ProjectFilter = ({
  fieldId,
  fields,
  classes,
  statuses,
  onSubmit,
  facilities,
  departments,
  selectedView,
  onViewChange,
  organizations,
  filters = defaultFilters,
  currentClientOrganizationId,
  getArchiveProjects,
}) => {
  const initialValues = {
    [Filters.PROJECT_STATUS]: '',
    [Filters.FACILITY_ID]: '',
    [Filters.DEPARTMENT_ID]: '',
    [Filters.FIELD_ID]: fieldId ? fieldId : '',
    [Filters.TEXT_SEARCH]: '',
    [Filters.GET_LOCATIONS]: true,
    [Filters.IS_ARCHIVE]: false,
    [Filters.GANTT_VIEW]: selectedView,
  };
  const displayFilter = useCallback(
    (filter) => filters?.includes(filter) ?? true,
    [filters],
  );
  const displayFields = displayFilter(Filters.FIELD_ID);
  const displayTextSearch = displayFilter(Filters.TEXT_SEARCH);
  const displayFacilities = displayFilter(Filters.FACILITY_ID);
  const displayStatuses = displayFilter(Filters.PROJECT_STATUS);
  const displayOrganizations = displayFilter(Filters.ORGANIZATION_ID);
  const displayDepartments = displayFilter(Filters.DEPARTMENT_ID);

  const showArchiveProjectClick = () => {
    getArchiveProjects();
  };

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={{
        ...initialValues,
        organizationId: currentClientOrganizationId,
      }}
    >
      <Form>
        <AutoSaveFormik timeout={1000} shouldSubmit={shouldSubmit}>
          <Grid container className={classes.root}>
            <Grid container spacing={2} alignItems="center">
              <Grid item container xs={2} alignItems="center">
                <Box mr={1}>
                  <ArchiveIcon
                    onClick={showArchiveProjectClick}
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
                <Typography
                  onClick={showArchiveProjectClick}
                  style={{ cursor: 'pointer' }}
                >
                  ARCHIVE
                </Typography>
              </Grid>

              <Grid item container className={classes.viewToggle} xs={2}>
                <Grid item>
                  <ToggleButtonGroup
                    exclusive
                    value={selectedView}
                    onChange={onViewChange}
                  >
                    <ToggleButton value={PROJECT_VIEWS.TABLE_VIEW}>
                      <ViewAgendaIcon />
                    </ToggleButton>
                    <ToggleButton value={PROJECT_VIEWS.CHART_VIEW}>
                      <BarChartIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid item container xs spacing={2} wrap="nowrap">
                {displayTextSearch && (
                  <Grid xs item>
                    <Field name={Filters.TEXT_SEARCH}>
                      {({ form, ...formik }) => (
                        <TextFieldFormik
                          form={form}
                          margin="none"
                          label="Search"
                          placeholder="Search projects..."
                          inputProps={{
                            maxLength: 100,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  title="Remove"
                                  onClick={() =>
                                    form.setFieldValue(
                                      Filters.TEXT_SEARCH,
                                      initialValues[Filters.TEXT_SEARCH],
                                    )
                                  }
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          {...formik}
                        />
                      )}
                    </Field>
                  </Grid>
                )}
                {displayStatuses && (
                  <Grid xs item>
                    <SelectTextFieldFormik
                      items={statuses}
                      name={Filters.PROJECT_STATUS}
                      label="Status"
                      margin="none"
                      getItemValue={(status) => status.get('id')}
                      getItemName={(status) => status.get('name')}
                    />
                  </Grid>
                )}
                {displayOrganizations && (
                  <Grid xs item>
                    <SelectTextFieldFormik
                      label="Client"
                      margin="none"
                      items={organizations}
                      name={Filters.ORGANIZATION_ID}
                      getItemValue={(organization) => organization.get('id')}
                      getItemName={(organization) => organization.get('name')}
                    />
                  </Grid>
                )}
                {displayFields && (
                  <Grid xs item>
                    <SelectTextFieldFormik
                      label="Field"
                      margin="none"
                      items={fields}
                      name={Filters.FIELD_ID}
                      getItemValue={(field) => field.get('fieldId')}
                      getItemName={(field) => field.get('name')}
                    />
                  </Grid>
                )}
                {displayFacilities && (
                  <Grid xs item>
                    <SelectTextFieldFormik
                      margin="none"
                      label="Facility"
                      items={facilities}
                      name={Filters.FACILITY_ID}
                      getItemValue={(facility) => facility.get('id')}
                      getItemName={(facility) => facility.get('name')}
                    />
                  </Grid>
                )}
                {displayDepartments && (
                  <Grid xs item>
                    <SelectTextFieldFormik
                      margin="none"
                      label="Department"
                      items={departments}
                      name={Filters.DEPARTMENT_ID}
                      getItemValue={(department) => department.get('id')}
                      getItemName={(department) => department.get('name')}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </AutoSaveFormik>
      </Form>
    </Formik>
  );
};

const styles = {
  root: {
    marginBottom: 15,
    paddingTop: 15,
  },
  leftIcon: {
    marginRight: 10,
  },
  viewToggle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default compose(withStyles(styles))(ProjectFilter);
