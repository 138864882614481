import { useMemo } from 'react';
import { compose } from 'redux';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

const BasePageDense = ({ classes, ...rest }) => {
  const basePageClasses = useMemo(
    () => ({
      children: classes.basePageChildren,
    }),
    [classes],
  );

  return <BasePage classes={basePageClasses} {...rest} />;
};

const styles = () => ({
  basePageChildren: {
    padding: 0,
  },
});

export default compose(withStyles(styles))(BasePageDense);
