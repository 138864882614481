import { call, put, takeEvery } from 'redux-saga/effects';
import {
  getProjectStatus,
  Identity,
  sendProjectChangedNotification,
  toggleModal,
} from 'features/projects/activities/sagas/common.sagas';
import {
  ACTIVITIES_ACTIONS as ACTIONS,
  ACTIVITIES_ACTIONS,
  EDIT_ACTIVITY_MODAL_ID,
} from 'features/projects/activities/activities.constants';
import activitiesService from 'services/activities.service';
import { NOTIFICATION_VARIANTS } from 'app/app.constants';
import { callAsync } from 'app/sagas/helperSagas';

function* initiateDeleteActivityBase(action, notificationFn) {
  const {
    projectId,
    taskId,
    activityId,
    index,
    isSubActivity = false,
    connection,
  } = action;
  if (!isSubActivity)
    yield call(toggleModal, {
      modalId: EDIT_ACTIVITY_MODAL_ID,
      activeIndex: index,
      value: true,
    });
  yield call(activitiesService.deleteActivity, projectId, taskId, activityId);
  yield call(getProjectStatus, projectId);
  if (!isSubActivity)
    yield call(toggleModal, { modalId: EDIT_ACTIVITY_MODAL_ID });
  yield call(notificationFn, connection, projectId, taskId);
  yield put({
    taskId,
    projectId,
    activityId,
    name: 'Delete',
    skipTracking: true,
    type: ACTIVITIES_ACTIONS.DELETE_ACTIVITY_SUCCESS_DIALOG,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: 'The activity was successfully deleted',
    },
  });
}

function* initiateDeleteActivityWithNotification(action) {
  yield call(
    initiateDeleteActivityBase,
    action,
    sendProjectChangedNotification,
  );
}

function* initiateDeleteActivityWithoutNotification(action) {
  yield call(initiateDeleteActivityBase, action, Identity);
}

function* confirmDeleteActivity(action) {
  const { taskId, projectId, activityId } = action;
  yield put({
    taskId,
    projectId,
    activityId,
    name: 'Delete',
    skipTracking: true,
    type: ACTIVITIES_ACTIONS.CONFIRM_DELETE_ACTIVITY,
  });
}

export function* rootDeleteActivitySaga() {
  yield takeEvery(
    ACTIONS.INITIATE_CONFIRM_DELETE_ACTIVITY_WITH_NOTIFICATION,
    callAsync,
    initiateDeleteActivityWithNotification,
  );
  yield takeEvery(
    ACTIONS.INITIATE_CONFIRM_DELETE_ACTIVITY_WITHOUT_NOTIFICATION,
    callAsync,
    initiateDeleteActivityWithoutNotification,
  );
  yield takeEvery(
    ACTIONS.DELETE_ACTIVITY_SUCCESS_DIALOG,
    callAsync,
    confirmDeleteActivity,
  );
}
