import { Grid } from '@material-ui/core';
import { Field, Formik, Form } from 'formik';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';

import {
  getExplosives,
  updateExplosives,
} from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.actions';

import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import { EXPLOSIVES_FIELDS } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import { getExplosivesFromState } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.selectors';
import { explosivesValidationSchema } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.validators';

const Explosives = ({ taskId, projectId, disabled }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExplosives(projectId, taskId));
  }, [dispatch, projectId, taskId]);

  const explosives = useSelector(getExplosivesFromState);

  const initialValues = useMemo(
    () => ({
      ...explosives?.toJS(),
    }),
    [explosives],
  );

  const submitExplosivesData = useCallback(
    (values) => {
      dispatch(updateExplosives(projectId, taskId, values));
    },
    [dispatch, projectId, taskId],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={submitExplosivesData}
      validationSchema={explosivesValidationSchema}
    >
      <Grid item container>
        <AutoSaveFormik timeout={1000}>
          <Form>
            <Grid container item spacing={2}>
              <Grid item xs>
                <Typography variant="h5">Explosives</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Field
                  type="number"
                  label="Cord batch number"
                  disabled={disabled}
                  component={TextFieldFormik}
                  name={EXPLOSIVES_FIELDS.CORD_BATCH_NUMBER}
                  placeholder="0"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  type="number"
                  label="Igniter/Detonator batch number"
                  disabled={disabled}
                  component={TextFieldFormik}
                  name={EXPLOSIVES_FIELDS.IGNITER_BATCH_NUMBER}
                  placeholder="0"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  type="number"
                  label="Charge batch number"
                  disabled={disabled}
                  component={TextFieldFormik}
                  name={EXPLOSIVES_FIELDS.CHARGE_BATCH_NUMBER}
                  placeholder="0"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  type="number"
                  label="Booster batch number"
                  disabled={disabled}
                  component={TextFieldFormik}
                  name={EXPLOSIVES_FIELDS.BOOSTER_BATCH_NUMBER}
                  placeholder="0"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Field
                  type="number"
                  label="Consumption"
                  disabled={disabled}
                  component={QuantityTextFieldFormik}
                  name={EXPLOSIVES_FIELDS.CONSUMPTION}
                  placeholder="0"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  type="number"
                  label="How many igniters/detonators used"
                  disabled={disabled}
                  component={TextFieldFormik}
                  name={EXPLOSIVES_FIELDS.NUMBER_OF_IGNITERS_USED}
                  placeholder="0"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  type="number"
                  label="How many chargers are used"
                  disabled={disabled}
                  component={TextFieldFormik}
                  name={EXPLOSIVES_FIELDS.NUMBER_OF_CHARGERS_USED}
                  placeholder="0"
                />
              </Grid>
              <Grid item xs={3}>
                <Field
                  type="number"
                  label="How many boosters used"
                  disabled={disabled}
                  component={TextFieldFormik}
                  name={EXPLOSIVES_FIELDS.NUMBER_OF_BOOSTERS_USED}
                  placeholder="0"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Field
                  type="number"
                  label="Cutter batch number"
                  disabled={disabled}
                  component={TextFieldFormik}
                  name={EXPLOSIVES_FIELDS.CUTTER_BATCH_NUMBER}
                  placeholder="0"
                />
              </Grid>
            </Grid>
          </Form>
        </AutoSaveFormik>
      </Grid>
    </Formik>
  );
};

export default Explosives;
