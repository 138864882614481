export const FluidType = {
  LIQUID: 0,
  GAS: 1,
  MULTIPHASE: 3,
};
export const FluidFormFields = {
  NAME: 'name',
  DEPTH: 'endDepth',
  GAS_TYPE: 'gasType',
  FLUID_TYPE: 'fluidType',
  GAS_DENSITY: 'gasDensity',
  YIELD_POINT: 'yieldPoint',
  OIL_GRAVITY: 'oilGravity',
  YIELD_STRESS: 'yieldStress',
  WATER_DENSITY: 'waterDensity',
  WATER_FRACTION: 'waterFraction',
  RHEOLOGY_MODEL: 'rheologyModel',
  LIQUID_DENSITY: 'liquidDensity',
  LIQUID_CONTENT: 'liquidContent',
  MULTIPHASE_TYPE: 'multiphaseType',
  GAS_LIQUID_RATIO: 'gasLiquidRatio',
  PLASTIC_VISCOSITY: 'plasticViscosity',
  CONSISTENCY_INDEX: 'consistencyIndex',
  MULTIPHASE_GAS_TYPE: 'multiphaseGasType',
  NEWTONIAN_VISCOSITY: 'newtonianViscosity',
  FLOW_BEHAVIOUR_INDEX: 'flowBehaviourIndex',
  MULTIPHASE_GAS_DENSITY: 'multiphaseGasDensity',
};
export const RheologyModel = {
  NEWTONIAN: 0,
  BINGHAM_PLASTIC: 1,
  POWER_LAW: 2,
  HERSCHEL_BULKLEY: 3,
};
export const MultiphaseType = {
  BLACK_OIL: 0,
};
export const GasType = {
  NITROGEN: 0,
  AIR: 1,
  NATURAL_GAS: 2,
};
