import { compose } from 'redux';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import toJSComponent from 'with-immutable-props-to-js';
import withStyles from '@material-ui/styles/withStyles';

import {
  onLoad,
  onUnload,
  getEvents,
  markEventsAsSeen,
} from 'features/projects/events/events.actions';

import {
  getCurrentUserFromState,
  getActionDataStateFromState,
} from 'app/app.selectors';

import { pages } from 'app/routePaths';
import { APP_ACTIONS } from 'app/app.constants';
import EventList from 'app/components/EventList';
import BasePage from 'app/components/NewBasePage';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { getEventsFromState } from 'features/projects/events/events.selectors';

const styles = () => ({});

class Events extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, dispatchGetEvents, projectId } = this.props;

    dispatchOnLoad(projectId);

    //poll for project events TODO: WEBSOCKETS
    if (!this.pollId) {
      this.pollId = setInterval(() => dispatchGetEvents(projectId), 5000);
    }
  }

  componentWillUnmount() {
    const { dispatchOnUnload } = this.props;

    dispatchOnUnload();

    //stop existing poll
    if (this.pollId !== undefined && this.pollId !== null) {
      clearInterval(this.pollId);
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatchEventsSeen, projectId } = this.props;

    if (this.props.events.length !== prevProps.events.length) {
      dispatchEventsSeen(projectId);
    }
  }

  render() {
    const { project, events, dataState, currentUser } = this.props;

    return (
      <BasePage
        subTitle="Events"
        dataState={dataState}
        subPage={pages.events}
        loadingText="Loading events"
      >
        <EventList
          kind="project"
          events={events}
          project={project}
          currentUser={currentUser}
        />
      </BasePage>
    );
  }
}

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  currentUser: getCurrentUserFromState(state),
  events: getEventsFromState(state),
  dataState: getActionDataStateFromState(state, APP_ACTIONS.EVENTS_PAGE_LOADED),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoad,
  dispatchOnUnload: onUnload,
  dispatchGetEvents: getEvents,
  dispatchEventsSeen: markEventsAsSeen,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  toJSComponent,
  withStyles(styles),
)(Events);
