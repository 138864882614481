import { Field } from 'formik';
import { useCallback, useState } from 'react';
import { ThirdPartyLabel } from 'features/projects/tool/tool.constants';
import { AutocompleteTextField } from 'app/components/form/AutocompleteTextField';
import { findM3ItemsBySerialNumberAndStatus } from 'features/equipment/equipment.actions';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';

export const AutocompleteTextFieldAdapter = ({
  form,
  placeholder,
  keepChangeAfterOnBlur = false,
  InputProps,
  ...props
}) => {
  const [m3ItemNumber, setM3ItemNumber] = useState(null);
  const [isThirdPartyTool, setThirdPartyTool] = useState(false);
  const [autoCompleteValue, setAutocompleteValue] = useState(null);

  const setInitialFieldValue = useCallback(
    (form) => (setInputFieldValue) => {
      if (form?.values?.m3ItemNumber) {
        if (form?.values?.m3ItemNumber === ThirdPartyLabel) {
          setThirdPartyTool(true);
        } else {
          setM3ItemNumber(form?.values?.m3ItemNumber);
        }
      }
      // for cables referenceNumber
      else if (form?.values?.referenceNumber) {
        setM3ItemNumber(form?.values?.referenceNumber);
      }

      if (form?.values?.serialNo) {
        setInputFieldValue(form?.values?.serialNo);
      }
    },
    [],
  );
  const getOptionLabel = useCallback(
    (selectedValue) => selectedValue?.serialNumber,
    [],
  );
  const valueSelectorFromAutocomplete = useCallback(
    (selectedValue) => selectedValue?.serialNumber,
    [],
  );

  return (
    <div>
      {!isThirdPartyTool && (
        <AutocompleteTextField
          form={form}
          disabled={props.disabled}
          keepChangeAfterOnBlur={keepChangeAfterOnBlur}
          autoCompleteValue={autoCompleteValue}
          setAutocompleteValue={setAutocompleteValue}
          formFieldName={'serialNo'}
          getOptionLabel={getOptionLabel}
          valueSelectorFromAutocomplete={valueSelectorFromAutocomplete}
          setInitialFieldValue={setInitialFieldValue(form)}
          fetchAutocompleteDataAction={(value) =>
            findM3ItemsBySerialNumberAndStatus(m3ItemNumber, value)
          }
          placeholder={placeholder}
          id={'auto-complete-serial-number-m3'}
        />
      )}
      {isThirdPartyTool && (
        <Field name="serialNo" component={TableRowTextFieldFormik} />
      )}
    </div>
  );
};
