import { Grid } from '@material-ui/core';

import BlackOilMultiphaseFormSection from 'app/components/FluidsFormSection/FluidForm/FluidSurfaceProperties/MultiphaseFormSection/BlackOilMultiphaseFormSection';
import { MultiphaseType } from 'utils/fluids/constants.utils';

const MultiphaseFormSection = ({ disabled, multiphaseType }) => {
  const formSections = {
    [MultiphaseType.BLACK_OIL]: BlackOilMultiphaseFormSection,
  };

  return <Grid disabled={disabled} component={formSections[multiphaseType]} />;
};

export default MultiphaseFormSection;
