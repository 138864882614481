import { List } from 'immutable';
import { useCallback } from 'react';

import { useRequest } from 'altus-hooks';

import mappers from 'mappers';
import appService from 'services/app.service';

const useTaskTypes = () => {
  const getTaskTypes = useCallback(
    () =>
      appService
        .getTaskTypes()
        .then((taskTypes) => List(taskTypes).map(mappers.TaskType.from)),
    [],
  );

  return useRequest(getTaskTypes);
};

export default useTaskTypes;
