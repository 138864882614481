import { useEffect } from 'react';

import { ReceivedDataState } from 'altus-datastate';

import DockManagerIFrame from 'features/projects/dashboard/components/DynamicDockManager/DockManagerIFrame';

const IFrameDashboardController = ({ url, renderSlots, setDataState }) => {
  useEffect(() => {
    setDataState(ReceivedDataState);
  }, [setDataState]);

  return renderSlots(<DockManagerIFrame url={url} />);
};

export default IFrameDashboardController;
