import { useRef, useEffect } from 'react';

export const between = (x, min, max) => {
  const val = x === null || x === undefined ? 0 : x;
  return val >= min && val <= max;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const distanceFromCenter = (x, y) => {
  return x - y;
};

export const convertMetric = (value, unit) => {
  switch (unit) {
    case 'ft':
      return value / 3.2808;
    case 'in':
      return value / 39.3701;
    case 'cm':
      return value / 100;
    case 'mm':
      return value / 1000;
    case 'm':
      return value;

    default:
      break;
  }
};
