import { fromJS, List } from 'immutable';

import { EMPTY_LIST } from 'app/app.constants';
import lessonsMapper from 'features/projects/lessons/lessons.mappers';
import { LESSONS_ACTIONS } from 'features/projects/lessons/lessons.constants';

const initialState = fromJS({
  data: EMPTY_LIST,
});

const lessonsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case LESSONS_ACTIONS.PROJECT_LESSONS_PAGE_LOADED: {
      if (action.error) return state;

      return state.set(
        'data',
        List(action.payload).map(lessonsMapper.Lesson.from),
      );
    }
    case LESSONS_ACTIONS.CREATE_LESSON: {
      if (action.error) return state;
      return state.update('data', (lessons) =>
        lessons.push(lessonsMapper.Lesson.from(action.payload)),
      );
    }
    case LESSONS_ACTIONS.UPDATE_LESSON: {
      if (action.error) return state;

      const { lessonId } = action;

      return state.update('data', (lessons) => {
        const index = lessons.findIndex(
          (lesson) => lesson.get('id') === lessonId,
        );

        return lessons.set(index, lessonsMapper.Lesson.from(action.payload));
      });
    }
    case LESSONS_ACTIONS.DELETE_LESSON: {
      if (action.error) return state;
      return state.update('data', (lessons) =>
        lessons.filterNot((lesson) => lesson.get('id') === action.id),
      );
    }
    default:
      return state;
  }
};

export default lessonsReducer;
