import Agent from 'infrastructure/agent';

export const allEvents = (id) => Agent.get(`/project/${id}/events`);

export const allEventsUnreadCount = (id) =>
  Agent.get(`/project/${id}/events/unread`);

export const markEventsAsSeen = (id) =>
  Agent.post(`/project/${id}/events/seen`);

const eventsService = {
  allEvents,
  allEventsUnreadCount,
  markEventsAsSeen,
};

export default eventsService;
