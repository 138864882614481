import { ROLE_ACCESS_TYPES } from 'authorization/authorization.constants';
import { Map } from 'immutable';

const SystemRole = {
  from: ({ accessType, ...systemRole }) =>
    Map({
      isProtected: accessType === ROLE_ACCESS_TYPES.PROTECTED,
      ...systemRole,
    }),
};

const ProjectRole = {
  from: ({ accessType, ...projectRole }) =>
    Map({
      isProtected: accessType === ROLE_ACCESS_TYPES.PROTECTED,
      ...projectRole,
    }),
};

const RolePermission = {
  from: (rolePermission) =>
    Map({
      ...rolePermission,
    }),
  to: (roleId, permissionId) => ({
    roleId,
    permissionId,
  }),
};

const Permission = {
  from: ({ ...permission }) => Map({ ...permission }),
};

const authorizationMappers = {
  SystemRole,
  ProjectRole,
  RolePermission,
  Permission,
};

export default authorizationMappers;
