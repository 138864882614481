import isNil from 'lodash/isNil';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { invokeIfFunction } from 'utils/app.util';
import TextField from 'app/components/form/TextFieldV2';
import TextFieldOutlined from '../TextFieldOutlined';
import { EMPTY_STRING } from 'app/app.constants';

const defaultFormat = (value) => (isNil(value) ? '' : value);

const TextFieldFormik = ({
  outlinedTextField = false,
  form,
  field,
  format = defaultFormat,
  destroyOnUnmount = false,
  minValue = null,
  useRoundedValue = false,
  ...rest
}) => {
  const { value, onChange, name, onBlur } = field;
  const { getFieldMeta, setFieldValue } = form;

  const handleValueChange = (event) => {
    let inputValue = event.target.value;

    if (minValue !== null && inputValue < minValue) {
      inputValue = minValue;
      setFieldValue(name, inputValue);
      return;
    }

    if (onChange) onChange(event); // Call the original onChange if provided
  };

  useEffect(
    () => () => {
      if (destroyOnUnmount) {
        // clear value and unregister field
        setFieldValue(name, undefined);
      }
    },
    [name, setFieldValue, destroyOnUnmount],
  );

  const { touched, error } = getFieldMeta(name);

  const formattedValue = format ? invokeIfFunction(format, value) : value;

  const roundToThreeDecimals = (value) => {
    return typeof value !== typeof EMPTY_STRING
      ? parseFloat(Number(value)?.toFixed(3))
      : '';
  };

  if (outlinedTextField)
    return (
      <TextFieldOutlined
        name={name}
        title={error}
        onBlur={onBlur}
        onChange={handleValueChange}
        error={!!error && touched}
        helperText={touched ? error : undefined}
        {...rest}
      />
    );

  return (
    <TextField
      name={name}
      title={error}
      onBlur={onBlur}
      onChange={handleValueChange}
      value={useRoundedValue ? roundToThreeDecimals(value) : formattedValue}
      error={!!error && touched}
      helperText={touched ? error : undefined}
      {...rest}
    />
  );
};

TextFieldFormik.propTypes = {
  format: PropTypes.func,
  destroyOnUnmount: PropTypes.bool,
};

export default TextFieldFormik;
