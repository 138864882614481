import { Field } from 'formik';
import { useMemo, useCallback } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { Table } from 'altus-ui-components';

import { SmLocationStatus } from 'app/app.constants';
import { validateIPAddress } from 'utils/validation.util';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import SMLocationStatusIndicator from 'features/data-exchange/components/SMLocationStatusIndicator';

const defaultInitialState = {
  pageSize: 50,
};

const FormFields = {
  NOTES: 'notes',
  IP_ADDRESS: 'ipAddress',
  UNIT_IP_ADDRESS: 'unitIPAddress',
  POINT_OF_CONTACT: 'pointOfContact',
};

const SMLocationConfigsTable = ({
  smLocations,
  updateSMLocation,
  initialState = defaultInitialState,
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 2,
        Header: 'COMMS UNIT',
        accessor: (smLocation) => smLocation.get('name'),
        Cell: ({ row, value }) => (
          <Grid container alignItems="center" wrap="nowrap">
            <Box
              marginRight={0.5}
              component={SMLocationStatusIndicator}
              status={row.original.get('generalStatus')}
            />
            {value}
          </Grid>
        ),
      },
      {
        xs: 2,
        Header: 'COMS UNIT IP',
        Cell: ({ row }) => (
          <Grid container alignItems="center" wrap="nowrap">
            <Box
              marginRight={0.5}
              component={SMLocationStatusIndicator}
              status={row.original.get('unitStatus')}
            />
            <Grid item xs>
              <Field
                validate={validateIPAddress}
                name={FormFields.UNIT_IP_ADDRESS}
                component={TableRowTextFieldFormik}
                disabled={row.original.get('isCompleted')}
              />
            </Grid>
            <Grid item component={Box} marginLeft={0.5}>
              <Typography variant="caption" noWrap>
                {row.original.get('isUnitOnline') ? 'Ping' : 'No Ping'}
              </Typography>
            </Grid>
          </Grid>
        ),
      },
      {
        xs: 2,
        Header: 'Smart Monitor Winch IP',
        Cell: ({ row }) => {
          const smLocation = row.original;

          return (
            <Grid container alignItems="center" wrap="nowrap">
              <Box
                marginRight={0.5}
                component={SMLocationStatusIndicator}
                status={smLocation.get('smartMonitorStatus')}
              />
              <Grid item xs>
                <Field
                  name={FormFields.IP_ADDRESS}
                  validate={validateIPAddress}
                  component={TableRowTextFieldFormik}
                  disabled={smLocation.get('isCompleted')}
                />
              </Grid>
              <Grid item component={Box} marginLeft={0.5}>
                <Typography variant="caption" noWrap>
                  {smLocation.get('isOnline') ? 'Ping' : 'No Ping'}
                </Typography>
              </Grid>
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'POC',
        Cell: ({ row }) => (
          <Field
            name={FormFields.POINT_OF_CONTACT}
            component={TableRowTextFieldFormik}
            disabled={row.original.get('isCompleted')}
          />
        ),
      },
      {
        xs: 2,
        Header: 'Notes',
        Cell: ({ row }) => (
          <Field
            name={FormFields.NOTES}
            component={TableRowTextFieldFormik}
            disabled={row.original.get('isCompleted')}
          />
        ),
      },
      {
        xs: 2,
        Header: 'Status',
        accessor: (smLocation) => {
          switch (smLocation.get('generalStatus')) {
            case SmLocationStatus.PING:
              return 'Streaming';
            case SmLocationStatus.NO_PING:
              return 'Not Streaming';
            default:
              return smLocation.get('isCompleted') ? 'Competed' : 'Offline';
          }
        },
      },
    ],
    [],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <EditableTableRowFormik {...props} onSubmit={updateSMLocation} />
    ),
    [updateSMLocation],
  );

  return (
    <Table
      columns={columns}
      items={smLocations}
      useGlobalFilter={false}
      initialState={initialState}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

export default SMLocationConfigsTable;
