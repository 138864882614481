import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { LoadingButton } from 'altus-ui-components';
import AddIcon from '@material-ui/icons/Add';

import { getActionDataStateFromState } from 'app/app.selectors';
import * as simulationActions from 'features/projects/tasks/task/simulation/simulation.actions';
import { ACTIONS } from 'features/projects/tasks/task/simulation/simulation.constants';

export const CreateUpdatedPlannedButton = ({ simulations }) => {
  const { projectId, taskId } = useParams();
  const dispatch = useDispatch();
  const createUpdatedLoadingState = useSelector((state) =>
    getActionDataStateFromState(
      state,
      ACTIONS.REQUEST_CREATE_UPDATE_PLANNED_SIMULATION,
    ),
  );
  const active =
    simulations.some((simulation) => simulation.get('isPlanned')) &&
    simulations.every((simulation) => !simulation.get('isUpdatedPlanned'));

  if (!active) {
    return null;
  }

  return (
    <LoadingButton
      color="primary"
      variant="contained"
      startIcon={<AddIcon />}
      loading={createUpdatedLoadingState.isLoading()}
      onClick={() =>
        dispatch(
          simulationActions.requestCreateUpdatePlannedSimulation(
            projectId,
            taskId,
          ),
        )
      }
    >
      Simulation
    </LoadingButton>
  );
};
