import { compose } from 'redux';

import { Grid } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';

import { FrictionReducerTypeEnum } from 'app/app.constants';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import SelectTextFieldFormik from 'app/components/form/formik/SelectTextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import Explosives from 'features/projects/tasks/task/dataAcquisition/components/Explosives';
import { CONSUMABLES_TAB_FIELDS } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import { useAutoScroll } from 'features/projects/tasks/task/dataAcquisition/hooks/useScrollable';

const ConsumablesTabContainer = ({
  taskId,
  disabled,
  projectId,
  greaseTypes,
  glycolTypes,
  fieldMarginClass,
  frictionReducerTypes,
  classes,
}) => {
  const { values } = useFormikContext();
  const [maxHeight, vh] = useAutoScroll();
  const frictionReducerType =
    values[CONSUMABLES_TAB_FIELDS.FRICTION_REDUCER_TYPE_ID];

  return (
    <Grid
      className={classes.container}
      style={{
        maxHeight: `${maxHeight * vh}px`,
      }}
    >
      <Grid container spacing={3}>
        <Grid item container>
          <Grid container item spacing={2}>
            <Grid item xs>
              <Typography variant="h5">Chemicals</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectTextFieldFormik
                label="Type of Grease"
                margin="none"
                disabled={disabled}
                displayEmpty={false}
                items={greaseTypes}
                className={fieldMarginClass}
                name={CONSUMABLES_TAB_FIELDS.GREASE_TYPE_ID}
                getItemValue={(item) => item.get('id')}
                getItemName={(item) => item.get('name')}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                type="number"
                label="Grease Consumption"
                disabled={disabled}
                component={QuantityTextFieldFormik}
                name={CONSUMABLES_TAB_FIELDS.GREASE_CONSUMPTION}
                placeholder="0"
                useRoundedValue={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectTextFieldFormik
                label="Type of Friction Reducer"
                margin="none"
                disabled={disabled}
                displayEmpty={false}
                className={fieldMarginClass}
                items={frictionReducerTypes}
                name={CONSUMABLES_TAB_FIELDS.FRICTION_REDUCER_TYPE_ID}
                getItemValue={(item) => item.get('id')}
                getItemName={(item) => item.get('name')}
              />
              {frictionReducerType === FrictionReducerTypeEnum.Other && (
                <Field
                  destroyOnUnmount
                  disabled={disabled}
                  label="Friction Reducer description"
                  component={TextFieldFormik}
                  name={CONSUMABLES_TAB_FIELDS.FRICTION_REDUCER_DESCRIPTION}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <Field
                type="number"
                label="Friction Reducer Consumption"
                disabled={disabled}
                component={QuantityTextFieldFormik}
                name={CONSUMABLES_TAB_FIELDS.FRICTION_REDUCER_CONSUMPTION}
                placeholder="0"
                useRoundedValue={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SelectTextFieldFormik
                label="Type of Glycol"
                margin="none"
                disabled={disabled}
                displayEmpty={false}
                className={fieldMarginClass}
                items={glycolTypes}
                name={CONSUMABLES_TAB_FIELDS.GLYCOL_TYPE_ID}
                getItemValue={(item) => item.get('id')}
                getItemName={(item) => item.get('name')}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                type="number"
                label="Glycol Consumption"
                disabled={disabled}
                component={QuantityTextFieldFormik}
                name={CONSUMABLES_TAB_FIELDS.GLYCOL_CONSUMPTION}
                placeholder="0"
                useRoundedValue={true}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Field
                fullWidth
                disabled={disabled}
                component={TextFieldFormik}
                name={CONSUMABLES_TAB_FIELDS.TYPE_OF_HYDRAULIC_FUID}
                label="Type of Hydraulic Fluid"
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                type="number"
                label="Hydraulic Fluid Consumption"
                disabled={disabled}
                component={QuantityTextFieldFormik}
                name={CONSUMABLES_TAB_FIELDS.HYDRAULIC_FUID_CONSUMPTION}
                placeholder="0"
                useRoundedValue={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Explosives taskId={taskId} projectId={projectId} disabled={disabled} />
      </Grid>
    </Grid>
  );
};

const styles = () => ({
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});

export default compose(withStyles(styles))(ConsumablesTabContainer);
