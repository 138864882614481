import { IconButton } from '@material-ui/core';
import { Fullscreen, FullscreenExit } from '@material-ui/icons';
import { useFullscreen } from 'altus-hooks';

const FullScreenButton = () => {
  const [isFullscreen, toggleFullscreen] = useFullscreen();
  const FullScreenIcon = isFullscreen ? FullscreenExit : Fullscreen;

  return (
    <>
      <IconButton
        color="default"
        title={isFullscreen ? 'Exit fullscreen' : 'Fullscreen'}
        onClick={toggleFullscreen}
      >
        <FullScreenIcon />
      </IconButton>
    </>
  );
};

export default FullScreenButton;
