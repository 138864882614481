import { useCallback, useMemo, React } from 'react';
import { Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { Table } from 'altus-ui-components';

import WellDashboardProjectTableRow from './WellDashboardProjectTableRow';

const WellDashboardProjectsTable = ({
  title,
  classes,
  projects,
  displayLocationMarkers = true,
  zoomInAndOutByProjectId,
  isArchiveProject,
  setArchiveProjects,
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 12,
        id: 'title',
        Header: (
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        ),
      },
    ],
    [title, classes],
  );

  const renderTableRowComponent = useCallback(
    (props) => (
      <WellDashboardProjectTableRow
        {...props}
        displayLocationMarker={displayLocationMarkers}
        zoomInAndOutByProjectId={zoomInAndOutByProjectId}
        isArchiveProject={isArchiveProject}
        setArchiveProjects={setArchiveProjects}
      />
    ),
    [
      setArchiveProjects,
      displayLocationMarkers,
      isArchiveProject,
      zoomInAndOutByProjectId,
    ],
  );

  return (
    <Table
      stickyHeader
      items={projects}
      columns={columns}
      useGlobalFilter={false}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

const styles = () => ({
  title: {
    fontSize: '1.125rem',
  },
});

export default withStyles(styles)(WellDashboardProjectsTable);
