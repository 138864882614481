import { compose } from 'redux';
import withTheme from '@material-ui/styles/withTheme';
import GoogleMapReact from 'google-map-react';

import GoogleMapTheme from 'layout/googleMap.themes';
import { NORWAY_MAP_CENTER_COORDS } from 'app/app.constants';

export const GoogleMap = ({
  center,
  children,
  theme,
  allowZoom = false,
  ...rest
}) => (
  <GoogleMapReact
    clusterRadius={1}
    defaultCenter={NORWAY_MAP_CENTER_COORDS}
    bootstrapURLKeys={{ key: 'AIzaSyDR7pPPfpL06KsgWs4B5JbAkqXGY4bqJXA' }}
    center={center}
    zoom={5}
    options={{
      draggableCursor: 'inherit',
      styles: GoogleMapTheme[theme.palette.type],
      disableDefaultUI: true,
      gestureHandling: allowZoom ? undefined : 'none',
      scrollwheel: true,
      panControl: true,
      mapTypeControl: false,
    }}
    {...rest}
  >
    {children}
  </GoogleMapReact>
);

export default compose(withTheme)(GoogleMap);
