import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Chip } from '@material-ui/core';
import Stop from '@material-ui/icons/Stop';
import * as PropTypes from 'prop-types';
import { completeActivityAbortTask } from 'features/projects/activities/activities.actions';
import { ExecutionRootContainerContext } from 'features/projects/execution/ExecutionRootContainer';

export const ButtonStop = (props) => {
  const { label, projectId, taskId, activityId, classes } = props;
  const connection = useContext(ExecutionRootContainerContext);
  const dispatch = useDispatch();
  return (
    <Chip
      label={label}
      icon={<Stop />}
      onClick={() =>
        dispatch(
          completeActivityAbortTask(projectId, taskId, activityId, connection),
        )
      }
      classes={{
        root: classes.chipRoot,
      }}
    />
  );
};

ButtonStop.propTypes = {
  label: PropTypes.string,
  projectId: PropTypes.string,
  taskId: PropTypes.string,
  activityId: PropTypes.string,
  classes: PropTypes.any,
};
