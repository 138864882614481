export const AssetHistoryForm = {
  ID: 'assetHistoryModal',
  MODE: 'mode',
  TYPE_OF_EVENT: 'typeOfEvent',
  TYPE_OF_TEST: 'typeOfTest',
  RESULT: 'result',
  DATE: 'date',
  TIME: 'time',
  DESCRIPTION: 'description',
  TYPE: 'type',
  CABLE_CUT: 'cableCut',
  CABLE_SIGNAL: 'cableSignal',
  CABLE_RESISTANCE: 'cableResistance',
  WRAP_TEST: 'wrapTest',
  STORAGE_TYPE: 'storageType',

  //BOP TEST
  BOP_RAM: 'bopRam',
  BOP_LEAK_TYPE: 'bopLeakType',
  BOP_LEAK_TYPE_DESCRIPTION: 'bopLeakTypeDescription',
  BOP_LEAK_TYPE_DESCRIPTION_VALIDATION: 'bopLeakTypeDescriptionValidation',

  //CABLE TORSION TEST
  CABLE_TORSION_WRAP: 'cableTorsionWrap',

  //CABLE WRAP TEST
  CABLE_WRAP_TEST_STRAND: 'strandsPassed',
  CABLE_WRAP_TEST_STRAND_PASS_X: 'strandsPassedPassX',
  CABLE_WRAP_TEST_STRAND_OUT_Y: 'strandsPassedOutY',

  //CABLE TORTURE TEST
  CABLE_TORTURE_TEST_OUTER_ARMOUR_DUCTILITY_SCORE: 'outerArmourDuctilityScore',
  CABLE_TORTURE_TEST_INNER_ARMOUR_DUCTILITY_SCORE: 'innerArmourDuctilityScore',

  //CABLE CONTINUITY TEST
  CABLE_CONTINUITY_TEST_RESISTANCE: 'resistance',

  //CABLE INSULATION TEST
  CABLE_INSULATION_TEST_RESISTANCE: 'cableInsulationResistance',

  //SIL2 TEST
  ASSET_SIL2_TEST_BOP: 'assetSil2Bop',
  ASSET_SIL2_TEST_BCS: 'assetSil2Bcs',
  ASSET_SIL2_TEST_CABIN: 'assetSil2Cabin',

  //CABLE WRAP BRAIDED MONO TEST
  CABLE_WRAP_BRAIDED_MONO_TEST_INNER_STRAND_PASS_X:
    'cableWrapBraidedMonoTestInnerStrandPassX',
  CABLE_WRAP_BRAIDED_MONO_TEST_INNER_STRAND_OUT_OF_Y:
    'cableWrapBraidedMonoTestInnerStrandOutY',
  CABLE_WRAP_BRAIDED_MONO_TEST_OUTER_STRAND_PASS_X:
    'cableWrapBraidedMonoTestOuterStrandPassX',
  CABLE_WRAP_BRAIDED_MONO_TEST_OUTER_STRAND_OUT_OF_Y:
    'cableWrapBraidedMonoTestOuterStrandOutY',

  //CABLE BREAK TEST
  CABLE_BREAK_TEST_FORCE: 'cableBreakTestForceNumber',

  //CABLE CUT MAINTENANCE
  CABLE_CUT_MAINTENANCE_DISTANCE: 'distanceQuantity',

  //CABLE SERVICE MAINTENANCE
  CABLE_SERVICE_MAINTENANCE_CABLE_LENGTH: 'cableLengthQuantity',
  CABLE_SERVICE_MAINTENANCE_MAX_OD: 'maxODQuantity',

  //CABLE SPOOLING MAINTENANCE
  CABLE_SPOOLING_DRUM_ITEM_NUMBER: 'drumItemNumber',
  CABLE_SPOOLING_DRUM_SERIAL_NUMBER: 'drumSerialNumber',
};

export default AssetHistoryForm;
