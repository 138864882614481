import { ENVIRONMENTS as Environments } from 'app/app.constants';

const defaultClientConfig = {
  environmentName: Environments.LOCAL,
};

const clientConfig = window.clientConfig || defaultClientConfig;

// If the API root isn't present in the ClientConfig, add it based on the env variable.
if (!clientConfig.apiRoot) {
  clientConfig.apiRoot = process.env.REACT_APP_API_ROOT;
}

export default clientConfig;
