import Agent from 'infrastructure/agent';
import { saveFile } from 'utils/app.util';

export const getAllWellboreFiles = (wellboreId, fieldId) =>
  Agent.get(`/field/${fieldId}/wellbore/${wellboreId}/files`);

export const deleteWellboreFile = (wellboreId, wellboreFileId, fieldId) =>
  Agent.delete(
    `/field/${fieldId}/wellbore/${wellboreId}/files/${wellboreFileId}`,
  );

export const downloadWellboreFile = (wellboreId, wellboreFileId, fieldId) => {
  return Agent.file
    .get(
      `/field/${fieldId}/wellbore/${wellboreId}/files/${wellboreFileId}/download`,
    )
    .then(([file, filename]) => saveFile(file, filename));
};

export const uploadWellboreFile = (wellboreId, wellboreFile, meta, fieldId) =>
  Agent.file.post(
    `/field/${fieldId}/wellbore/${wellboreId}/files`,
    wellboreFile,
    meta,
  );

export const updateWellboreFile = (
  wellboreId,
  wellboreFileId,
  wellboreFile,
  fieldId,
) =>
  Agent.put(
    `/field/${fieldId}/wellbore/${wellboreId}/files/${wellboreFileId}`,
    wellboreFile,
  );

const wellboreFileService = {
  downloadWellboreFile,
  uploadWellboreFile,
  deleteWellboreFile,
  getAllWellboreFiles,
  updateWellboreFile,
};

export default wellboreFileService;
