import classNames from 'classnames';
import { SortableHandle } from 'react-sortable-hoc';
import DragHandle from '@material-ui/icons/DragHandle';
import withStyles from '@material-ui/styles/withStyles';

import { IconButton } from 'app/components/withTooltip';

const styles = () => {
  return {
    dragHandle: {
      cursor: 'move',
      display: 'flex',
    },
    dragHandleDisabled: {
      cursor: 'not-allowed',
    },
  };
};

const WorkItemSortHandle = ({ disabled, classes }) => (
  <IconButton
    onClick={(e) => e.preventDefault()}
    title={disabled ? '' : 'Move'}
    className={classNames(classes.dragHandle, {
      [classes.dragHandleDisabled]: disabled,
    })}
  >
    <DragHandle fontSize="small" color={disabled ? 'disabled' : 'action'} />
  </IconButton>
);

export default SortableHandle(withStyles(styles)(WorkItemSortHandle));
