import { APP_ACTIONS, NOTIFICATION_VARIANTS } from 'app/app.constants';
import { TOOL_ACTIONS } from 'features/projects/tool/tool.constants';
import toolService from 'services/tool.service';

export const refreshToolsUsingM3Items = (projectId, taskId) => (dispatch) => {
  const payload = () => toolService.refreshToolsWithM3Items(projectId, taskId);

  dispatch({
    payload,
    type: TOOL_ACTIONS.UPDATE_TOOLS_FROM_M3_ITEMS,
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Refreshing...',
      [NOTIFICATION_VARIANTS.SUCCESS]:
        'Tools on project updated with data from M3',
    },
  });
};

export const uploadToolImage = () => (dispatch) => (files) => {
  return toolService
    .uploadToolImage(files[0])
    .then((payload) => {
      dispatch({
        type: TOOL_ACTIONS.UPLOAD_TOOL_IMAGE,
        notification: {
          [NOTIFICATION_VARIANTS.INFO]: 'Uploading...',
          [NOTIFICATION_VARIANTS.SUCCESS]:
            'The image was successfully uploaded',
        },
        payload,
      });
      dispatch(requestToolImages());
      return payload;
    })
    .catch(() =>
      dispatch({
        hideable: true,
        type: APP_ACTIONS.ADD_NOTIFICATION,
        notification: {
          message:
            'Failed to upload image. Make sure the image format (PNG) is valid.',
          variant: NOTIFICATION_VARIANTS.ERROR,
        },
      }),
    );
};

export const deleteToolImage = (toolImageId) => (dispatch) => {
  dispatch({
    toolImageId,
    type: TOOL_ACTIONS.DELETE_TOOL_IMAGE,
    confirmationDialog: {
      description: 'Are you sure you want to delete this image?',
      title: 'Delete tool image',
      confirmButtonText: 'Delete',
    },
    notification: {
      [NOTIFICATION_VARIANTS.INFO]: 'Deleting image...',
      [NOTIFICATION_VARIANTS.SUCCESS]: 'Image successfully deleted!',
    },
    payload: () => {
      return toolService.deleteToolImage(toolImageId).then(() => {
        dispatch(requestToolImages());
      });
    },
  });
};

export const importToolsFromFile = () => (dispatch) => (files) => {
  return toolService
    .importTools(files[0])
    .then((payload) => {
      dispatch({
        type: TOOL_ACTIONS.IMPORT_TOOLS_FROM_FILE,
        notification: {
          [NOTIFICATION_VARIANTS.INFO]: 'Uploading...',
          [NOTIFICATION_VARIANTS.SUCCESS]: 'The file was successfully uploaded',
        },
        payload,
      });
      return payload;
    })
    .catch(() =>
      dispatch({
        hideable: true,
        type: APP_ACTIONS.ADD_NOTIFICATION,
        notification: {
          message:
            'Failed to upload file. Make sure the file format (xlsx or xls) and data is valid.',
          variant: NOTIFICATION_VARIANTS.ERROR,
        },
      }),
    );
};

export const requestToolImages = () => ({
  type: TOOL_ACTIONS.REQUEST_TOOL_IMAGES,
});

export const receiveToolImages = (toolImages) => ({
  type: TOOL_ACTIONS.RECEIVE_TOOL_IMAGES,
  payload: toolImages,
});

export const requestToolsSearch = (filters) => ({
  filters,
  type: TOOL_ACTIONS.REQUEST_TOOLS_SEARCH,
});

export const receiveToolsSearch = (tools) => ({
  type: TOOL_ACTIONS.RECEIVE_TOOLS_SEARCH,
  payload: tools,
});

export const requestToolGroupsByCategory = (categoryId) => ({
  categoryId,
  type: TOOL_ACTIONS.REQUEST_TOOL_GROUPS_BY_CATEGORY,
});

export const requestToolGroups = () => ({
  type: TOOL_ACTIONS.REQUEST_TOOL_GROUPS,
});

export const receiveToolGroups = (toolGroups) => ({
  type: TOOL_ACTIONS.RECEIVE_TOOL_GROUPS,
  payload: toolGroups,
});

export const requestToolConnectors = () => ({
  type: TOOL_ACTIONS.REQUEST_TOOL_CONNECTORS,
});

export const receiveToolConnectors = (toolConnectors) => ({
  type: TOOL_ACTIONS.RECEIVE_TOOL_CONNECTORS,
  payload: toolConnectors,
});

export const requestToolTopConnectors = () => ({
  type: TOOL_ACTIONS.REQUEST_TOOL_TOP_CONNECTORS,
});

export const receiveToolTopConnectors = (toolTopConnectors) => ({
  type: TOOL_ACTIONS.RECEIVE_TOOL_TOP_CONNECTORS,
  payload: toolTopConnectors,
});

export const requestToolBottomConnectors = () => ({
  type: TOOL_ACTIONS.REQUEST_TOOL_BOTTOM_CONNECTORS,
});

export const receiveToolBottomConnectors = (toolBottomConnectors) => ({
  type: TOOL_ACTIONS.RECEIVE_TOOL_BOTTOM_CONNECTORS,
  payload: toolBottomConnectors,
});

export const requestToolSuppliers = () => ({
  type: TOOL_ACTIONS.REQUEST_TOOL_SUPPLIERS,
});

export const receiveToolSuppliers = (toolSuppliers) => ({
  type: TOOL_ACTIONS.RECEIVE_TOOL_SUPPLIERS,
  payload: toolSuppliers,
});

export const toolPickerModalOnLoad = () => ({
  type: TOOL_ACTIONS.TOOL_PICKER_MODAL_ON_LOAD,
});

export const requestRefreshToolsWithM3 = () => ({
  type: TOOL_ACTIONS.REQUEST_REFRESH_ALL_TOOLS_WITH_M3,
  confirmationDialog: {
    title: 'Refresh Tools with M3',
    confirmButtonText: 'Refresh',
    description:
      'Are you sure you want to Refresh all tools with M3? This operation might take some time.',
  },
  notification: {
    [NOTIFICATION_VARIANTS.INFO]: 'Refreshing tools with M3...',
    [NOTIFICATION_VARIANTS.SUCCESS]: 'Tools successfully refreshed.',
  },
});

export const requestCables = () => ({
  type: TOOL_ACTIONS.REQUEST_CABLES,
});

export const requestCablesByProject = (projectId) => ({
  type: TOOL_ACTIONS.REQUEST_CABLES_BY_PROJECT,
  payload: projectId,
});

export const receiveCables = (cables) => ({
  type: TOOL_ACTIONS.RECEIVE_CABLES,
  payload: cables,
});

export const loadToolProjectHistory = (toolId) => (dispatch) => {
  dispatch({
    type: TOOL_ACTIONS.RECEIVE_TOOL_PROJECT_HISTORY,
    payload: () =>
      Promise.all([toolService.getToolProjectHistoryByToolId(toolId)]),
  });
};
