import isNil from 'lodash/isNil';

import { EMPTY_MAP } from 'app/app.constants';
import { invokeIfFunction } from 'utils/app.util';

// Mark all rows and nested subrows as expanded
export const getAllRowsExpanded = (items, getSubRows, index) => {
  if (!items) return EMPTY_MAP;

  return items
    .reduce((result, item, itemIndex) => {
      var subRows = invokeIfFunction(getSubRows, item);

      const currentIndex = !isNil(index) ? `${index}.${itemIndex}` : itemIndex;

      const expandedSubRows = getAllRowsExpanded(
        subRows,
        getSubRows,
        itemIndex,
      );

      return result.set(currentIndex, true).merge(expandedSubRows);
    }, EMPTY_MAP)
    .toJS();
};
