import { createSelector } from 'utils/app.util';

export const getDataAcquisitionFromState = createSelector(
  (state, taskId) =>
    state.getIn([
      'projects',
      'plan',
      'task',
      'dataAcquisition',
      'data',
      taskId.toString(),
    ]),
  (data) => data,
);

export const getDebrisTypesFromState = createSelector(
  (state) =>
    state.getIn(['projects', 'plan', 'task', 'dataAcquisition', 'debrisTypes']),
  (debrisTypes) => debrisTypes,
);

export const getGreaseTypesFromState = createSelector(
  (state) =>
    state.getIn(['projects', 'plan', 'task', 'dataAcquisition', 'greaseTypes']),
  (greaseTypes) => greaseTypes,
);

export const getGlycolTypesFromState = createSelector(
  (state) =>
    state.getIn(['projects', 'plan', 'task', 'dataAcquisition', 'glycolTypes']),
  (glycolTypes) => glycolTypes,
);

export const getFrictionReducerTypesFromState = createSelector(
  (state) =>
    state.getIn([
      'projects',
      'plan',
      'task',
      'dataAcquisition',
      'frictionReducerTypes',
    ]),
  (frictionReducerTypes) => frictionReducerTypes,
);

export const getInjectionMediumsFromState = createSelector(
  (state) =>
    state.getIn([
      'projects',
      'plan',
      'task',
      'dataAcquisition',
      'injectionMediums',
    ]),
  (injectionMediums) => injectionMediums,
);

export const getProductionMediumsFromState = createSelector(
  (state) =>
    state.getIn([
      'projects',
      'plan',
      'task',
      'dataAcquisition',
      'productionMediums',
    ]),
  (productionMediums) => productionMediums,
);

export const getExplosivesFromState = createSelector(
  (state) =>
    state.getIn(['projects', 'plan', 'task', 'dataAcquisition', 'explosives']),
  (explosives) => explosives,
);
