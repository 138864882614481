import { compose } from 'redux';
import isNil from 'lodash/isNil';
import withStyles from '@material-ui/styles/withStyles';
import { Field, Formik, useFormikContext, getIn } from 'formik';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Grid,
  Dialog,
  Button,
  Switch,
  Divider,
  ListItem,
  MenuItem,
  Typography,
  ListItemText,
  DialogContent,
  List as MuiList,
  ListItemSecondaryAction,
} from '@material-ui/core';

import {
  BasePage,
  ModalHeader,
  ModalActions,
  BasePageTitle,
  LoadingButton,
  LoadingOverlay,
} from 'altus-ui-components';

import { NotRequestedDataState, getSummarizedDataState } from 'altus-datastate';

import {
  CheetahJobSource,
  ProjectDashboardFormFields as FormFields,
} from 'features/projects/dashboard/dashboard.constants';

import { required } from 'utils/validation.util';
import { cheetahJobSourceToString } from 'mappers';
import { toProjectDashboardV2 } from 'utils/route.util';
import NavigationLink from 'app/components/NavigationLink';
import { EMPTY_MAP, EMPTY_STRING } from 'app/app.constants';
import useCheetahJobs from 'features/data-exchange/hooks/useCheetahJobs';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import useDashboards from 'features/projects/dashboard/hooks/useDashboards';
import DashboardImage from 'features/projects/dashboard/components/DashboardImage';
import useProjectDashboard from 'features/projects/dashboard/hooks/useProjectDashboard';
import useProjectDashboards from 'features/projects/dashboard/hooks/useProjectDashboards';
import CheetahJobStatusIndicator from 'features/data-exchange/components/CheetahJobStatusIndicator';
import ProjectDashboardCheetahJobCurvesTableContainer from 'features/projects/dashboard/components/ProjectDashboardCheetahJobCurvesTableContainer';
import ProjectDashboardSimulationCurvesTableContainer from 'features/projects/dashboard/components/ProjectDashboardSimulationCurvesTableContainer';

const NoSelectedProjectDashboard = () => (
  <BasePage title="Dashboards">
    <MuiList dense>
      <ListItem disableGutters>
        <ListItemText>
          1. Select relevant Project Dashboards by using on/off switches
        </ListItemText>
      </ListItem>
      <ListItem disableGutters>
        <ListItemText>2. Configure each Dashboard:</ListItemText>
      </ListItem>
      <MuiList dense>
        <ListItem>
          <ListItemText>- Select Data Source</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>- Select Data Sensors</ListItemText>
        </ListItem>
      </MuiList>
    </MuiList>
  </BasePage>
);

const SelectCheetahJobFormSection = ({
  source,
  cheetahJob,
  cheetahJobs,
  cheetahJobId,
  setFieldValue,
}) => {
  const ValidCheetahJobSources = [
    CheetahJobSource.CHEETAH,
    CheetahJobSource.SM_LOCATION,
    CheetahJobSource.WARRIOR,
    CheetahJobSource.WITSML,
  ];

  const sourceOnChange = useCallback(
    (event) => {
      setFieldValue(FormFields.SOURCE, event.target.value);
      setFieldValue(FormFields.CHEETAH_JOB_ID, undefined);
    },
    [setFieldValue],
  );

  return (
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item xs={3}>
        <Field
          select
          label="Data Source"
          name={FormFields.SOURCE}
          onChange={sourceOnChange}
          component={TextFieldFormik}
        >
          {ValidCheetahJobSources.map((cheetahJobSource) => (
            <MenuItem key={cheetahJobSource} value={cheetahJobSource}>
              {cheetahJobSourceToString(cheetahJobSource)}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs>
        <Field
          select
          required
          label="Project"
          validate={required}
          component={TextFieldFormik}
          name={FormFields.CHEETAH_JOB_ID}
          value={cheetahJob ? cheetahJobId : EMPTY_STRING}
          disabled={!ValidCheetahJobSources.includes(source)}
        >
          {cheetahJobs.valueSeq().map((cheetahJob) => (
            <MenuItem key={cheetahJob.get('id')} value={cheetahJob.get('id')}>
              <Grid container alignItems="center" wrap="nowrap">
                <Box marginRight={0.5}>
                  <CheetahJobStatusIndicator
                    fontSize="small"
                    cheetahJob={cheetahJob}
                  />
                </Box>
                {cheetahJob.get('displayName')}
              </Grid>
            </MenuItem>
          ))}
        </Field>
      </Grid>
    </Grid>
  );
};

const ProjectDashboardFormContainer = ({
  projectId,
  projectDashboard,
  projectDashboardId,
  setSelectedCurveRowIds,
  setSelectedSimulationCurveRowIds,
}) => {
  const [dataState, setDataState] = useState(NotRequestedDataState);

  const { values, setFieldValue } = useFormikContext();

  const isUrlConfigurable = projectDashboard.get('isUrlConfigurable');

  // use setFieldValue instead of initialValues, since that will override the
  // FormFields.PROJECT_DASHBOARD_CURVES and FormFields.PROJECT_DASHBOARD_SIMULATION_CURVES fields
  useEffect(() => {
    if (!projectDashboard) return;

    const url = projectDashboard.get('url');
    const source = projectDashboard.get('source');
    const cheetahJobId = projectDashboard.get('cheetahJobId');
    const simulationId = projectDashboard.get('simulationId');
    const projectDashboardId = projectDashboard.get('projectDashboardId');

    setFieldValue(FormFields.URL, url);
    setFieldValue(FormFields.SOURCE, source);
    setFieldValue(FormFields.SIMULATION_ID, simulationId);
    setFieldValue(FormFields.CHEETAH_JOB_ID, cheetahJobId);
    setFieldValue(FormFields.PROJECT_DASHBOARD_ID, projectDashboardId);
  }, [setFieldValue, projectDashboard]);

  const selectedSource = getIn(values, FormFields.SOURCE);
  const selectedCheetahJobId = getIn(values, FormFields.CHEETAH_JOB_ID);

  const filter = useMemo(
    () => ({
      projectId,
      source: selectedSource,
    }),
    [selectedSource, projectId],
  );

  const {
    cheetahJobs,
    searchCheetahJobs,
    dataState: cheetahJobDataState,
  } = useCheetahJobs(filter);

  useEffect(() => {
    if (isNil(selectedSource)) return;

    searchCheetahJobs({
      includeProject: false,
      ...filter,
    });
  }, [filter, selectedSource, searchCheetahJobs]);

  const selectedCheetahJob = selectedCheetahJobId
    ? cheetahJobs.get(selectedCheetahJobId.toString())
    : null;

  const summarizedDataState = useMemo(
    () => getSummarizedDataState([dataState, cheetahJobDataState]),
    [dataState, cheetahJobDataState],
  );

  const isConfigurable = projectDashboard.get('isConfigurable');

  if (isUrlConfigurable)
    return (
      <Field
        autoFocus
        label="URL"
        name={FormFields.URL}
        component={TextFieldFormik}
      />
    );

  return (
    <Grid container item xs direction="column">
      <SelectCheetahJobFormSection
        source={selectedSource}
        cheetahJobs={cheetahJobs}
        setFieldValue={setFieldValue}
        cheetahJob={selectedCheetahJob}
        cheetahJobId={selectedCheetahJobId}
      />
      {!isConfigurable && (
        <Grid container item xs alignItems="center" justifyContent="center">
          <Typography>Dashboard curves are not configurable</Typography>
        </Grid>
      )}
      {isConfigurable && (
        <ProjectDashboardCheetahJobCurvesTableContainer
          projectId={projectId}
          setDataState={setDataState}
          projectDashboard={projectDashboard}
          cheetahJobId={selectedCheetahJobId}
          onRowSelected={setSelectedCurveRowIds}
          projectDashboardId={projectDashboardId}
        />
      )}
      {projectDashboard.get('isSimulationConfigurable') && (
        <ProjectDashboardSimulationCurvesTableContainer
          projectId={projectId}
          setDataState={setDataState}
          projectDashboard={projectDashboard}
          projectDashboardId={projectDashboardId}
          onRowSelected={setSelectedSimulationCurveRowIds}
        />
      )}
      <LoadingOverlay dataState={summarizedDataState} />
    </Grid>
  );
};

const FormikFormContainerTitle = ({
  projectId,
  projectDashboard,
  projectDashboardId,
  toggleProjectDashboard,
}) => (
  <Grid
    xs
    item
    container
    wrap="nowrap"
    alignItems="center"
    justifyContent="space-between"
  >
    <Grid item>
      <Grid container>
        <BasePageTitle title={projectDashboard.get('title')} />
        <Switch
          checked
          color="primary"
          onChange={() =>
            toggleProjectDashboard(projectDashboard.get('dashboardId'))
          }
        />
      </Grid>
    </Grid>
    <Grid item component={Box} paddingRight={1}>
      <NavigationLink
        target="_blank"
        to={toProjectDashboardV2(projectId, projectDashboardId)}
      >
        Open dashboard
      </NavigationLink>
    </Grid>
  </Grid>
);

const SelectedProjectDashboardContainer = ({
  dataState,
  projectId,
  toggleModal,
  projectDashboardId,
  toggleProjectDashboard,
}) => {
  const [selectedProjectDashboardCurveRowIds, setSelectedCurveRowIds] =
    useState(EMPTY_MAP.toJS());

  const [
    selectedProjectDashboardSimulationCurveRowIds,
    setSelectedSimulationCurveRowIds,
  ] = useState(EMPTY_MAP.toJS());

  const { projectDashboard, updateProjectDashboard } = useProjectDashboard(
    projectId,
    projectDashboardId,
  );

  const onSubmit = useCallback(
    (projectDashboard) => {
      const curves =
        projectDashboard[FormFields.PROJECT_DASHBOARD_CURVES] ?? [];

      const simulationCurves =
        projectDashboard[FormFields.PROJECT_DASHBOARD_SIMULATION_CURVES] ?? [];

      const selectedCurves = curves.filter(
        (_, index) => !!selectedProjectDashboardCurveRowIds[index.toString()],
      );

      const selectedSimulationCurves = simulationCurves.filter(
        (_, index) =>
          !!selectedProjectDashboardSimulationCurveRowIds[index.toString()],
      );

      updateProjectDashboard({
        ...projectDashboard,
        projectDashboardCurves: [
          ...selectedCurves,
          ...selectedSimulationCurves,
        ],
      });
    },
    [
      updateProjectDashboard,
      selectedProjectDashboardCurveRowIds,
      selectedProjectDashboardSimulationCurveRowIds,
    ],
  );

  const initialValues = useMemo(() => {
    if (!projectDashboard) return undefined;

    return EMPTY_MAP.toJS();
  }, [projectDashboard]);

  if (!projectDashboard) return <NoSelectedProjectDashboard />;

  return (
    <Formik
      validateOnMount
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ isValid, handleSubmit }) => (
        <>
          <BasePage
            title={
              <FormikFormContainerTitle
                projectId={projectId}
                projectDashboard={projectDashboard}
                projectDashboardId={projectDashboardId}
                toggleProjectDashboard={toggleProjectDashboard}
              />
            }
          >
            <ProjectDashboardFormContainer
              projectId={projectId}
              projectDashboard={projectDashboard}
              projectDashboardId={projectDashboardId}
              setSelectedCurveRowIds={setSelectedCurveRowIds}
              setSelectedSimulationCurveRowIds={
                setSelectedSimulationCurveRowIds
              }
            />
          </BasePage>
          <ModalActions>
            <Button onClick={toggleModal}>Close</Button>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              loading={dataState.isLoading()}
              disabled={!isValid || dataState.isLoading()}
            >
              Save
            </LoadingButton>
          </ModalActions>
        </>
      )}
    </Formik>
  );
};

const ProjectDashboardsMenu = ({
  classes,
  dashboards,
  projectDashboards,
  toggleProjectDashboard,
  toggleProjectDashboards,
  selectedProjectDashboard,
  setSelectedProjectDashboardId,
  clearSelectedProjectDashboardId,
}) => {
  const isAllSelected = projectDashboards.size === dashboards.size;

  return (
    <MuiList>
      <ListItem button onClick={clearSelectedProjectDashboardId}>
        <ListItemText primary="All Dashboards" />
        <ListItemSecondaryAction>
          <Switch
            color="primary"
            checked={isAllSelected}
            onChange={() =>
              toggleProjectDashboards(clearSelectedProjectDashboardId)
            }
          />
        </ListItemSecondaryAction>
      </ListItem>
      {dashboards.valueSeq().map((dashboard) => {
        const dashboardId = dashboard.get('dashboardId');

        const projectDashboardId = projectDashboards
          .mapKeys((_, value) => value.get('dashboardId'))
          .getIn([dashboardId, 'projectDashboardId']);

        const isSelected =
          selectedProjectDashboard?.get('dashboardId') === dashboardId;

        return (
          <Fragment key={dashboardId}>
            <ListItem
              button
              selected={isSelected}
              disabled={!projectDashboardId}
              onClick={() => setSelectedProjectDashboardId(projectDashboardId)}
              classes={{
                root: classes.listItemRoot,
                selected: classes.listItemSelected,
              }}
            >
              <ListItemText primary={dashboard.get('title')} />
              <ListItemSecondaryAction>
                <Switch
                  color="primary"
                  checked={!!projectDashboardId}
                  onChange={() =>
                    toggleProjectDashboard(
                      dashboardId,
                      setSelectedProjectDashboardId,
                    )
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
            {isSelected && (
              <ListItem divider className={classes.dashboardImageListItem}>
                <Grid container className={classes.dashboardImageContainer}>
                  <DashboardImage
                    name={dashboard.get('name')}
                    title={dashboard.get('title')}
                    classes={{
                      root: classes.dashboardImageRoot,
                    }}
                  />
                </Grid>
              </ListItem>
            )}
          </Fragment>
        );
      })}
    </MuiList>
  );
};

const ProjectDashboardsModalContainer = ({
  isOpen,
  classes,
  projectId,
  toggleModal,
  project = EMPTY_MAP,
  projectDashboardId: initialProjectDashboardId,
}) => {
  const [
    selectedProjectDashboardId = EMPTY_STRING,
    setSelectedProjectDashboardId,
  ] = useState(initialProjectDashboardId);

  const clearSelectedProjectDashboardId = useCallback(
    () => setSelectedProjectDashboardId(undefined),
    [],
  );

  const {
    dashboards,
    requestDashboards,
    dataState: requestDashboardsDataState,
  } = useDashboards();

  useEffect(() => {
    if (isOpen) {
      requestDashboards();
    }
  }, [isOpen, requestDashboards]);

  const {
    toggleProjectDashboard,
    toggleProjectDashboards,
    projectDashboards = EMPTY_MAP,
    toggleProjectDashboardDataState,
    updateProjectDashboardDataState,
    toggleProjectDashboardsDataState,
  } = useProjectDashboards(projectId);

  const { projectDashboard: selectedProjectDashboard } = useProjectDashboard(
    projectId,
    selectedProjectDashboardId,
  );

  useEffect(() => {
    // reset selectedProjectDashboardId if selectedProjectDashboard does not exist
    if (!selectedProjectDashboard) {
      clearSelectedProjectDashboardId();
    }
  }, [selectedProjectDashboard, clearSelectedProjectDashboardId]);

  const summarizedDataState = getSummarizedDataState([
    requestDashboardsDataState,
    toggleProjectDashboardDataState,
    toggleProjectDashboardsDataState,
  ]);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="xl"
      onClose={toggleModal}
      classes={{
        paperScrollPaper: classes.dialogPaperScrollPaper,
      }}
    >
      <ModalHeader title={project.get('fullTitle')} toggleModal={toggleModal} />
      <DialogContent
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        <Grid container wrap="nowrap">
          <Grid
            xs={3}
            item
            container
            direction="column"
            className={classes.dashboardsListContainer}
          >
            <ProjectDashboardsMenu
              classes={classes}
              dashboards={dashboards}
              projectDashboards={projectDashboards}
              toggleProjectDashboard={toggleProjectDashboard}
              toggleProjectDashboards={toggleProjectDashboards}
              selectedProjectDashboard={selectedProjectDashboard}
              setSelectedProjectDashboardId={setSelectedProjectDashboardId}
              clearSelectedProjectDashboardId={clearSelectedProjectDashboardId}
            />
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid xs={9} item container direction="column">
            <SelectedProjectDashboardContainer
              projectId={projectId}
              toggleModal={toggleModal}
              dataState={updateProjectDashboardDataState}
              projectDashboardId={selectedProjectDashboardId}
              toggleProjectDashboard={toggleProjectDashboard}
            />
          </Grid>
        </Grid>
        <LoadingOverlay dataState={summarizedDataState} />
      </DialogContent>
    </Dialog>
  );
};

const styles = (theme) => ({
  dialogContentRoot: {
    padding: 0,
    display: 'flex',
  },
  dialogPaperScrollPaper: {
    height: '75vh',
  },
  listItemRoot: {
    '&$listItemSelected': {
      background: theme.palette.action.hover,
    },
    '&$listItemSelected:hover': {
      background: theme.palette.action.hover,
    },
  },
  listItemSelected: {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
  dashboardImageRoot: {
    objectFit: 'cover',
    position: 'absolute',
  },
  dashboardImageContainer: {
    position: 'relative',
    paddingBottom: '56.25%', // 16:9 ratio
  },
  dashboardImageListItem: {
    padding: 0,
  },
  dashboardsListContainer: {
    overflow: 'auto',
  },
});

export default compose(withStyles(styles))(ProjectDashboardsModalContainer);
