import { connect } from 'react-redux';
import toJSComponent from 'with-immutable-props-to-js';
import { reduxForm, Field } from 'redux-form/immutable';
import { Button, Grid, DialogActions } from '@material-ui/core';

import {
  createIncident,
  onCreateIncidentModalLoaded,
} from 'features/projects/incidents/incidents.actions';

import {
  MODAL,
  CREATE_INCIDENT_FORM,
} from 'features/projects/incidents/incidents.constants';

import { required } from 'utils/validation.util';
import TextField from 'app/components/form/TextField';
import LoadingButton from 'app/components/LoadingButton';
import ModalContainer from 'app/components/Modal/ModalContainer';
import EditorReduxForm from 'app/components/form/EditorField';

const CreateIncidentModalContainer = ({
  valid,
  children,
  submitting,
  handleSubmit,
  dispatchOnLoad,
}) => (
  <ModalContainer
    height="auto"
    title="Create Incident"
    onEnter={dispatchOnLoad}
    TriggerComponent={children}
    modalId={MODAL.CREATE_INCIDENT}
    Actions={({ toggleModal }) => (
      <DialogActions>
        <Button onClick={toggleModal} size="small">
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          size="small"
          color="primary"
          disabled={!valid}
          variant="contained"
          onClick={handleSubmit}
          submitting={submitting}
          form={CREATE_INCIDENT_FORM.ID}
        >
          Create
        </LoadingButton>
      </DialogActions>
    )}
  >
    <form id={CREATE_INCIDENT_FORM.ID} onSubmit={handleSubmit}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Field
            required
            autoFocus
            component={TextField}
            validate={[required]}
            label="Case Number"
            name={CREATE_INCIDENT_FORM.CASE_NUMBER}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            required
            label="Title"
            component={TextField}
            validate={[required]}
            name={CREATE_INCIDENT_FORM.TITLE}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Description"
            component={EditorReduxForm}
            name={CREATE_INCIDENT_FORM.DESCRIPTION}
            inputProps={{ maxLength: 500 }}
            xs={12}
          />
        </Grid>
      </Grid>
    </form>
  </ModalContainer>
);

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatchOnLoad: () => dispatch(onCreateIncidentModalLoaded()),
  onSubmit: () => dispatch(createIncident(ownProps.project.id)),
});

export default connect(
  null,
  mapDispatchToProps,
)(
  toJSComponent(
    reduxForm({
      form: CREATE_INCIDENT_FORM.ID,
    })(CreateIncidentModalContainer),
  ),
);
