import { compose } from 'redux';
import { Formik, Form } from 'formik';
import { useMemo, useCallback } from 'react';
import Close from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import { IconButton } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { TableRow, TableCell, TableRowActionsCell } from 'altus-ui-components';

import { required } from 'utils/validation.util';
import { invokeIfFunction } from 'utils/app.util';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';

const CreateSimulationParameterRow = ({
  classes,
  columns,
  direction,
  isCreateMode,
  toggleCreateMode,
  simulationParameters,
  createSimulationParameter,
}) => {
  const onSubmit = useCallback(
    (simulationParameter, formik) =>
      createSimulationParameter(simulationParameter, toggleCreateMode, formik),
    [toggleCreateMode, createSimulationParameter],
  );

  // simplify getting 'xs', 'id' and 'Header' values from column
  const renderCell = (index, render) => {
    const column = columns[index];

    return (
      <TableCell item xs={column.xs}>
        {invokeIfFunction(render, column)}
      </TableCell>
    );
  };

  const lastSimulationParameter = simulationParameters.last();

  const initialValues = useMemo(
    () => ({
      direction,
      endDepth: undefined,
      startDepth: undefined,
      gasRate: lastSimulationParameter?.get('gasRate'),
      cableSpeed: lastSimulationParameter?.get('cableSpeed'),
      liquidRate: lastSimulationParameter?.get('liquidRate'),
      tractorForce: lastSimulationParameter?.get('tractorForce'),
      axialForceOnEnd: lastSimulationParameter?.get('axialForceOnEnd'),
      wellheadPressure: lastSimulationParameter?.get('wellheadPressure'),
    }),
    [direction, lastSimulationParameter],
  );

  if (!isCreateMode) return null;

  return (
    <Formik validateOnMount onSubmit={onSubmit} initialValues={initialValues}>
      {({ isValid, isSubmitting, handleSubmit, dirty }) => (
        <Form>
          <TableRow fadeIn={false}>
            {renderCell(0)}
            {renderCell(1, (column) => (
              <TableRowTextFieldFormik
                autoFocus
                type="number"
                name={column.id}
                validate={required}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
              />
            ))}
            {renderCell(2, (column) => (
              <TableRowTextFieldFormik
                type="number"
                name={column.id}
                validate={required}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
              />
            ))}
            {renderCell(3, (column) => (
              <TableRowTextFieldFormik
                type="number"
                name={column.id}
                validate={required}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
              />
            ))}
            {renderCell(4, (column) => (
              <TableRowTextFieldFormik
                type="number"
                name={column.id}
                validate={required}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
              />
            ))}
            {renderCell(5, (column) => (
              <TableRowTextFieldFormik
                type="number"
                name={column.id}
                validate={required}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
              />
            ))}
            {renderCell(6, (column) => (
              <TableRowTextFieldFormik
                type="number"
                name={column.id}
                validate={required}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
              />
            ))}
            {renderCell(7, (column) => (
              <TableRowTextFieldFormik
                type="number"
                name={column.id}
                validate={required}
                TextFieldComponent={QuantityTextFieldFormik}
                InputProps={{
                  endAdornment: null,
                }}
              />
            ))}
            <TableCell item>
              <TableRowActionsCell>
                <IconButton
                  title="Save"
                  onClick={handleSubmit}
                  className={classes.saveButton}
                  disabled={!isValid || isSubmitting || !dirty}
                >
                  <Check fontSize="small" />
                </IconButton>
                <IconButton title="Cancel" onClick={toggleCreateMode}>
                  <Close fontSize="small" />
                </IconButton>
              </TableRowActionsCell>
            </TableCell>
          </TableRow>
        </Form>
      )}
    </Formik>
  );
};

const styles = (theme) => ({
  saveButton: {
    background: theme.palette.success.dark,
    '&:hover': {
      background: theme.palette.success.main,
    },
  },
});

export default compose(withStyles(styles))(CreateSimulationParameterRow);
