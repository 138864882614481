import toJSComponent from 'with-immutable-props-to-js';
import SerialNumberRegisteredIcon from 'assets/TaskCompletedIcon.svg';
import NoSerialNumberIcon from 'assets/task_planned.svg';

const ToolstringSerialNumberStatus = ({ serialNumber }) => {
  if (!serialNumber) {
    return <img src={NoSerialNumberIcon} alt="Serial Number not registered" />;
  } else {
    return (
      <img src={SerialNumberRegisteredIcon} alt="Serial Number is registered" />
    );
  }
};

export default toJSComponent(ToolstringSerialNumberStatus);
