import { combineReducers } from 'redux-immutable';

import wellsReducer from 'features/wells/wells.reducer';
import wellEventsReducer from 'features/wells/events/wellEvents.reducer';
import wellProjectsReducer from 'features/wells/projects/wellProjects.reducer';

const wellReducer = combineReducers({
  data: wellsReducer,
  projects: wellProjectsReducer,
  events: wellEventsReducer,
});

export default wellReducer;
