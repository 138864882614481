import Warning from '@material-ui/icons/Warning';
import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { DOWNLOAD_LINKS } from 'features/login/login.constants';

const styles = (theme) => ({
  notSupportedMessage: { marginTop: 30, color: theme.altus.action.question },
  downloadLink: {
    color: theme.palette.text.primary,
    textDecoration: 'underline',
  },
});

const NotSupported = ({ classes }) => (
  <Grid item className={classes.notSupportedMessage}>
    <Warning />
    <Typography variant="h6">Browser not supported</Typography>
    <Typography variant="subtitle1" color="textSecondary">
      To use Digital Well Intervention we recommend that you use the latest
      version of{' '}
      <a className={classes.downloadLink} href={DOWNLOAD_LINKS.CHROME}>
        Chrome
      </a>
      ,{' '}
      <a className={classes.downloadLink} href={DOWNLOAD_LINKS.FIREFOX}>
        Firefox
      </a>
      ,{' '}
      <a className={classes.downloadLink} href={DOWNLOAD_LINKS.SAFARI}>
        Safari
      </a>{' '}
      or{' '}
      <a className={classes.downloadLink} href={DOWNLOAD_LINKS.EDGE}>
        Edge
      </a>
    </Typography>
  </Grid>
);

export default withStyles(styles)(NotSupported);
