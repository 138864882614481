import { makeStyles } from '@material-ui/core/styles';

import RadioGroupField from 'app/components/form/RadioGroupField';

const useStyles = makeStyles((theme) => ({
  checkedRadio: {
    '& .MuiTypography-root': {
      fontSize: '0.85rem',
    },
    '& svg:last-child': {
      color: theme.palette.success.main,
    },
  },
}));

export const PlannedIndicator = ({
  simulation,
  onToggle = () => null,
  hidden = false,
  isDisabled = false,
}) => {
  const classes = useStyles();

  if (hidden) {
    return null;
  }

  const isPlanned = simulation?.get('isPlanned', false);
  const disabled = simulation.get('canResimulate', false) || isDisabled;

  return (
    <RadioGroupField
      variant="default"
      size="small"
      fontVariant="h1"
      input={{
        value: isPlanned ? 'planned' : 'not-planned',
      }}
      options={[
        {
          onClick: onToggle,
          value: 'planned',
          label: isPlanned ? 'Planned' : 'Not Planned',
          disabled,
          className: classes.checkedRadio,
          frozen: isPlanned && disabled, // component will be disabled but with frozen style like it is enabled
        },
      ]}
    />
  );
};
