import React from 'react';
import { Field } from 'formik';

import { Grid } from '@material-ui/core';

import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import { AssetHistoryForm } from 'features/equipment/assets/components/equipmentTest/form-utils/AssetHistoryForm';

const CableTortureStrandFormComponent = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} container>
          <Field
            type="number"
            destroyOnUnmount
            label="Outer armour ductility"
            component={TextFieldFormik}
            name={AssetHistoryForm.CABLE_TORTURE_TEST_OUTER_ARMOUR_DUCTILITY_SCORE}
          />
        </Grid>
        <Grid item xs={6} container>
          <Field
            type="number"
            destroyOnUnmount
            label="Inner armour ductility"
            component={TextFieldFormik}
            name={AssetHistoryForm.CABLE_TORTURE_TEST_INNER_ARMOUR_DUCTILITY_SCORE}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CableTortureStrandFormComponent;
