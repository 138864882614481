import BarChartIcon from '@material-ui/icons/BarChart';
import withStyles from '@material-ui/styles/withStyles';
import { Box, Grid, Typography } from '@material-ui/core';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import { formatDuration } from 'utils/format.util';
import { TASK_VIEWS } from 'features/projects/tasks/tasks.constants';

const TaskListRuntimeHeader = ({
  classes,
  selectedView,
  onViewChange,
  totalPlannedDuration,
  showToggleButtonOption = true,
}) => (
  <Grid
    container
    component={Box}
    marginBottom={1}
    className={classes.container}
  >
    {showToggleButtonOption ? (
      <>
        <Grid item container xs={10}>
          <Grid container item justifyContent="flex-end" alignItems="center">
            <Typography noWrap variant="body2">
              <span className={classes.title}>Estimated Project Runtime:</span>
              <span className={classes.value}>
                {formatDuration(totalPlannedDuration)}
              </span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={2} justifyContent="flex-end">
          <Grid item>
            <ToggleButtonGroup
              exclusive
              value={selectedView}
              onChange={onViewChange}
            >
              <ToggleButton value={TASK_VIEWS.LIST}>
                <ViewAgendaIcon />
              </ToggleButton>
              <ToggleButton value={TASK_VIEWS.CHART}>
                <BarChartIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </>
    ) : (
      <>
        <Grid item container xs={12}>
          <Grid container item justifyContent="flex-end" alignItems="center">
            <Typography noWrap variant="body2">
              <span className={classes.title}>Estimated Project Runtime:</span>
              <span className={classes.value}>
                {formatDuration(totalPlannedDuration)}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </>
    )}
  </Grid>
);

const styles = () => ({
  container: {
    flexDirection: 'row',
  },
  title: {
    marginRight: 10,
  },
  value: {
    fontWeight: 'bold',
  },
});

export default withStyles(styles)(TaskListRuntimeHeader);
