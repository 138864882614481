import { lighten } from '@material-ui/core/styles';

export const styles = (theme) => ({
  iconButtonRoot: {
    maxWidth: '33%',
    height: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    '&:hover': {
      background: theme.altus.background.listitem,
    },
  },
  expansionPanelDetailsRoot: {
    padding: 20,
  },
  expandIconHidden: {
    display: 'none',
  },
  expansionPanelExpanded: {},
  paperExpandable: {
    '&:hover': {
      cursor: 'initial',
    },
  },
  textAreaRoot: theme.typography.caption,
  datePickerContainer: {
    paddingRight: theme.spacing(2),
  },
  textFieldHighlight: {
    backgroundColor: lighten(
      theme.altus.components.SortableListRow.background,
      0.05,
    ),
  },
  statusIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actions: {
    marginTop: theme.spacing(2),
  },
  childrenContainer: {
    marginTop: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
  },
  childDescription: {
    paddingLeft: '7%',
  },
});
