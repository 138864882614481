export const ADD_PROJECT_WELL_DETAILS_ADDITIONAL_PARAMETERS_MODAL_ID =
  'ADD_PROJECT_WELL_DETAILS_ADDITIONAL_PARAMETERS_MODAL_ID';
export const PROJECT_WELLBORE_DETAIL_CHANGELOG_MODAL_ID =
  'PROJECT_WELLBORE_DETAIL_CHANGELOG_MODAL_ID';

export const WELLBORE_DETAIL_FORM = {
  WELLBORE_DETAIL_ID: 'wellboreDetailId',
  WELLBORE_NAME: 'wellboreName',
  WELLBORE_CONTENT: 'wellboreContent',
  WELLBORE_PURPOSE: 'wellborePurpose',
  KELLY_BUSHING_ELEVATION: 'kellyBushingElevation',

  // Well Information
  COMPLETION_TYPE: 'completionType',
  COMPLETION_SIZE: 'completionSize',
  DOWN_HOLE_SAFETY_VALVE_DEPTH: 'downHoleSafetyValveDepth',
  CORROSION_SCALE_BSS: 'corrosionScaleBSS',
  WELL_INFORMATION_COMMENTS: 'wellInformationComments',

  // Well Area
  TOTAL_LUBRICATOR_ABOVE_SPLIT_POINT: 'totalLubricatorAboveSplitPoint',
  TOTAL_RISER_BELOW_SPLIT_POINT: 'totalRiserBelowSplitPoint',
  TOTAL_AVAILABLE_BHA_LENGTH: 'totalAvailableBhaLength',
  WELL_AREA_COMMENTS: 'wellAreaComments',

  // Operation Details
  MAX_WORKING_DEPTH: 'maximumWorkingDepth',
  MAX_INCLINATION: 'maximumInclination',
  MAX_INCLINATION_DEPTH: 'maximumInclinationDepth',
  MAX_DOGLEG: 'maximumDogLeg',
  MAX_DOGLEG_DEPTH: 'maximumDogLegDepth',
  MIN_INNER_DIAMETER: 'minimumInnerDiameter',
  MIN_INNER_DIAMETER_DEPTH: 'minimumInnerDiameterDepth',
  MAX_INNER_DIAMETER: 'maximumInnerDiameter',
  MAX_INNER_DIAMETER_DEPTH: 'maximumInnerDiameterDepth',
  OPERATION_DETAILS_COMMENTS: 'operationDetailsComments',

  // Well Properties
  MAX_RESERVOIR_PRESSURE: 'maxReservoirPressure',
  RESERVOIR_TEMPERATURE: 'reservoirTemperature',
  FLUID_TYPE: 'fluidType',
  FLUID_DENSITY: 'fluidDensity',
  SHUT_IN_WELLHEAD_PRESSURE: 'shutInWellheadPressure',
  WELLHEAD_PRESSURE: 'wellheadPressure',
  FRICTION: 'friction',
  FLOWING_WELLHEAD_PRESSURE: 'flowingWellheadPressure',
  WELL_PROPERTIES_COMMENTS: 'wellPropertiesComments',
  SURFACE_TEMPERATURE: 'surfaceTemperature',
  RESERVOIR_DEPTH: 'reservoirDepth',

  // Hostile Fluids
  HOSTILE_FLUIDS_COMMENTS: 'hostileFluidsComments',
};

export const ACTIONS = {
  CLEAR_PROJECT_WELLBORE_DETAILS: 'CLEAR_PROJECT_WELLBORE_DETAILS',
  CREATE_PROJECT_WELLBORE_DETAIL_DRAFT: 'CREATE_PROJECT_WELLBORE_DETAIL_DRAFT',
  CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT:
    'CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT',
  CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT_AND_SYNC:
    'CREATE_AND_REDIRECT_PROJECT_WELLBORE_DETAIL_DRAFT_AND_SYNC',
  REQUEST_PROJECT_WELLBORE_DETAIL_DRAFT_APPROVAL:
    'REQUEST_PROJECT_WELLBORE_DETAIL_DRAFT_APPROVAL',
  APPROVE_PROJECT_WELLBORE_DETAIL_DRAFT:
    'APPROVE_PROJECT_WELLBORE_DETAIL_DRAFT',
  CANCEL_PROJECT_WELLBORE_DETAIL_DRAFT: 'CANCEL_PROJECT_WELLBORE_DETAIL_DRAFT',
  EDIT_PROJECT_WELLBORE_DETAIL_DRAFT: 'EDIT_PROJECT_WELLBORE_DETAIL_DRAFT',
  SUBMIT_PROJECT_WELLBORE_DETAIL_FORM: 'SUBMIT_PROJECT_WELLBORE_DETAIL_FORM',

  GET_PROJECT_WELLBORE_DETAIL_CHANGE_LOG:
    'GET_PROJECT_WELLBORE_DETAIL_CHANGE_LOG',

  DELETE_PROJECT_WELLBORE_DETAIL: 'DELETE_PROJECT_WELLBORE_DETAIL',
  REQUEST_PROJECT_WELLBORE_DETAIL: 'REQUEST_PROJECT_WELLBORE_DETAIL',
  RECEIVE_PROJECT_WELLBORE_DETAIL: 'RECEIVE_PROJECT_WELLBORE_DETAIL',

  REQUEST_PROJECT_WELLBORE_DETAILS: 'REQUEST_PROJECT_WELLBORE_DETAILS',
  RECEIVE_PROJECT_WELLBORE_DETAILS: 'RECEIVE_PROJECT_WELLBORE_DETAILS',

  REQUEST_ADD_HOSTILE_FLUIDS_TO_PROJECT_WELLBORE_DETAIL:
    'REQUEST_ADD_HOSTILE_FLUIDS_TO_PROJECT_WELLBORE_DETAIL',
  REQUEST_REMOVE_HOSTILE_FLUIDS_FROM_PROJECT_WELLBORE_DETAIL:
    'REQUEST_REMOVE_HOSTILE_FLUIDS_FROM_PROJECT_WELLBORE_DETAIL',
  REQUEST_ADD_PROJECT_WELLBORE_DETAIL_SERVICES:
    'REQUEST_ADD_PROJECT_WELLBORE_DETAIL_SERVICES',
  REQUEST_REMOVE_PROJECT_WELLBORE_DETAIL_SERVICE:
    'REQUEST_REMOVE_PROJECT_WELLBORE_DETAIL_SERVICE',

  REQUEST_LATEST_PROJECT_WELLBORE_DETAIL:
    'REQUEST_LATEST_PROJECT_WELLBORE_DETAIL',

  LOAD_PROJECT_WELLBORE_DETAIL_PAGE: 'LOAD_PROJECT_WELLBORE_DETAIL_PAGE',
  LOAD_PROJECT_WELLBORE_DETAILS_PAGE: 'LOAD_PROJECT_WELLBORE_DETAILS_PAGE',
  SYNC_PROJECT_WELLBORE_DETAILS_WITH_LATEST:
    'SYNC_PROJECT_WELLBORE_DETAILS_WITH_LATEST',
  SET_ACTIVE_WELLBOREDETAILSID: 'SET_ACTIVE_WELLBOREDETAILSID',
};
