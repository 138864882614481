import { compose } from 'redux';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';

import TextFieldV2 from 'app/components/form/TextFieldV2';

const EditableTitle = ({
  value,
  classes,
  onChange,
  InputProps = {},
  ...rest
}) => (
  <TextFieldV2
    value={value}
    margin="dense"
    variant="outlined"
    onChange={onChange}
    InputProps={{
      classes,
      ...InputProps,
    }}
    {...rest}
  />
);

const styles = (theme) => ({
  input: {
    ...theme.typography.h5,
    'padding': theme.spacing(1),
    'fontWeight': theme.typography.fontWeightMedium,
    '&:disabled': {
      color: theme.palette.text.primary,
    },
    '&:hover:not(:disabled) + $notchedOutline': {
      borderWidth: 1,
    },
    '&:focus:not(:disabled) + $notchedOutline': {
      borderWidth: 1,
    },
  },
  notchedOutline: {
    borderWidth: 0,
  },
});

EditableTitle.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default compose(withStyles(styles))(EditableTitle);
