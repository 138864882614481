import {
  FluidType,
  GasType,
  MultiphaseType,
  RheologyModel,
} from 'utils/fluids/constants.utils';

export const fluidTypeToString = (fluidType) =>
  ({
    [FluidType.GAS]: 'Gas',
    [FluidType.LIQUID]: 'Liquid',
    [FluidType.MULTIPHASE]: 'Multiphase',
  }[fluidType]);
export const rheologyModelToString = (rheologyModel) =>
  ({
    [RheologyModel.NEWTONIAN]: 'Newtonian',
    [RheologyModel.POWER_LAW]: 'Power law',
    [RheologyModel.BINGHAM_PLASTIC]: 'Bingham Plastic',
    [RheologyModel.HERSCHEL_BULKLEY]: 'Herschel–Bulkley',
  }[rheologyModel]);
export const multiphaseTypeToString = (multiphaseType) =>
  ({
    [MultiphaseType.BLACK_OIL]: 'Black Oil (Reservoir Only)',
  }[multiphaseType]);
export const gasTypeToString = (gasType) =>
  ({
    [GasType.AIR]: 'Air',
    [GasType.NITROGEN]: 'Nitrogen',
    [GasType.NATURAL_GAS]: 'Natural gas',
  }[gasType]);
