import { Field } from 'formik';
import { MenuItem } from '@material-ui/core';

import { required } from 'utils/validation.util';
import TextFieldFormik from 'app/components/form/formik/TextFieldFormik';
import {
  FluidFormFields as FormFields,
  GasType,
} from 'utils/fluids/constants.utils';
import { gasTypeToString } from 'utils/fluids/mappers.utils';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';

const GasFormSection = ({ disabled, gasType }) => (
  <>
    <Field
      select
      required
      label="Gas Type"
      disabled={disabled}
      validate={required}
      component={TextFieldFormik}
      name={FormFields.GAS_TYPE}
    >
      {Object.values(GasType).map((gasType) => (
        <MenuItem key={gasType} value={gasType}>
          {gasTypeToString(gasType)}
        </MenuItem>
      ))}
    </Field>
    {gasType === GasType.NATURAL_GAS && (
      <Field
        required
        type="number"
        label="Density"
        destroyOnUnmount
        disabled={disabled}
        validate={required}
        name={FormFields.GAS_DENSITY}
        component={QuantityTextFieldFormik}
        useRoundedValue={true}
      />
    )}
  </>
);

export default GasFormSection;
