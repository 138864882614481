import { compose } from 'redux';
import { useLocation } from 'react-router';
import { useEffect, useCallback } from 'react';
import Settings from '@material-ui/icons/Settings';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, TextField, MenuItem } from '@material-ui/core';

import {
  getClientOrganizationsFromState,
  getCurrentClientOrganizationIdFromState,
} from 'app/app.selectors';

import routePaths from 'app/routePaths';
import { useHeader } from 'app/hooks/useHeader';
import { SystemPermission } from 'app/app.constants';
import { useCurrentUser } from 'app/hooks/useCurrentUser';
import {
  loadOrganizations,
  setCurrentClientOrganization,
} from 'app/app.actions';
import MenuIcon from 'app/components/ApplicationHeader/MenuIcon';
import HasSystemPermission from 'app/components/HasSystemPermission';
import LogoAndAppTitle from 'app/components/ApplicationHeader/LogoAndAppTitle';
import DataExchangeIcon from 'app/components/ApplicationHeader/DataExchangeIcon';
import UserMenuContainer from 'app/components/ApplicationHeader/UserMenuContainer';
import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import ApplicationHeaderTitle from 'app/components/ApplicationHeader/ApplicationHeaderTitle';

const HomeApplicationHeader = ({ classes }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadOrganizations());
  }, [dispatch]);

  const currentUser = useCurrentUser();
  const clientOrganizations = useSelector(getClientOrganizationsFromState);
  const currentClientOrganizationId = useSelector(
    getCurrentClientOrganizationIdFromState,
  );

  const handleOnClientFilterChange = useCallback(
    ({ target }) => dispatch(setCurrentClientOrganization(target.value)),
    [dispatch],
  );

  useHeader({
    logoNavigation: pathname,
    title: currentUser?.get('organizationName'),
  });

  return (
    <ApplicationHeaderBase>
      <Grid
        container
        spacing={2}
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs container spacing={2} alignItems="center">
          <Grid item>
            <LogoAndAppTitle />
          </Grid>
          {currentUser.get('isMainVendor') && (
            <Grid item lg={5} xs>
              <TextField
                value={currentClientOrganizationId}
                onChange={handleOnClientFilterChange}
                select
                fullWidth
                size="small"
                label="Client"
                margin="dense"
                variant="filled"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="">
                  <i>All</i>
                </MenuItem>
                {clientOrganizations.valueSeq().map((clientOrganization) => (
                  <MenuItem
                    key={clientOrganization.get('id')}
                    value={clientOrganization.get('id')}
                  >
                    <img
                      className={classes.clientLogo}
                      src={clientOrganization.get('logoUri')}
                      alt={clientOrganization.get('name')}
                    />
                    {clientOrganization.get('name')}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
        </Grid>
        <Grid item xs={4}>
          <ApplicationHeaderTitle />
        </Grid>
        <Grid item xs>
          <Grid container justifyContent="flex-end" alignItems="center">
            <Grid item>
              <HasSystemPermission permissions={SystemPermission.DATA_EXCHANGE}>
                <MenuIcon
                  target="_blank"
                  title="Data Exchange"
                  Icon={<DataExchangeIcon />}
                  to={routePaths.dataExchange.root}
                />
              </HasSystemPermission>
            </Grid>
            <Grid item>
              <HasSystemPermission
                permissions={[
                  SystemPermission.MANAGE_USER_ACCESS,
                  SystemPermission.MANAGE_SYSTEM_ROLES,
                  SystemPermission.MANAGE_PROJECT_ROLES,
                ]}
              >
                <MenuIcon
                  title="Settings"
                  Icon={<Settings />}
                  to={routePaths.settings.root}
                />
              </HasSystemPermission>
            </Grid>
            <Grid item>
              <UserMenuContainer />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ApplicationHeaderBase>
  );
};

const styles = (theme) => ({
  clientLogo: {
    height: '30px',
    width: '30px',
    paddingRight: `${theme.spacing(1)}px`,
  },
});

export default compose(withStyles(styles))(HomeApplicationHeader);
