import { compose } from 'redux';
import { useCallback, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  getCurrentClientSelectedUom,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';

import {
  getUnitForProperty,
  existingToolToToolMapper,
} from 'features/equipment/equipment.helpers';

import {
  createTool,
  updateTool,
  onLoadAddToolsModal,
} from 'features/equipment/equipment.actions';

import {
  MODAL,
  EQUIPMENT_ACTIONS,
  EDITABLE_TOOL_PROPERTIES,
} from 'features/equipment/equipment.constants';

import {
  getToolConnectorsFromState,
  getUnitsOfMeasurePreference,
} from 'features/equipment/equipment.selectors';

import { EMPTY_STRING } from 'app/app.constants';
import BasePage from 'app/components/BasePageDense';
import M3Modal from 'features/equipment/m3Components/M3Modal';
import { FormFields } from 'features/equipment/m3Components/M3ModalForm';

const initialFormValues = {
  [FormFields.M3_ITEM]: null,
  [FormFields.NAME]: EMPTY_STRING,
  [FormFields.SUPPLIER]: null,
  [FormFields.SUPPLIER_PART_NUMBER]: EMPTY_STRING,
  [FormFields.GROUP]: null,
  [FormFields.OUTER_DIAMETER]: EMPTY_STRING,
  [FormFields.INNER_DIAMETER]: EMPTY_STRING,
  [FormFields.LENGTH]: EMPTY_STRING,
  [FormFields.WEIGHT]: EMPTY_STRING,
  [FormFields.TOP_CONNECTOR]: null,
  [FormFields.BOTTOM_CONNECTOR]: null,
  [FormFields.FISHNECK]: EMPTY_STRING,
  [FormFields.SAFE_WORKING_LOAD]: EMPTY_STRING,
  [FormFields.YIELD]: EMPTY_STRING,
  [FormFields.DESCRIPTION]: EMPTY_STRING,
  [FormFields.FILE]: null,
  [FormFields.TOOL_IMAGE_ID]: EMPTY_STRING,
  [FormFields.MAXIMUM_OD]: EMPTY_STRING,
  [FormFields.MAXIMUM_STANDOFF_FORCE]: EMPTY_STRING,
  [FormFields.MINIMUM_OD]: EMPTY_STRING,
  [FormFields.MINIMUM_STANDOFF_FORCE]: EMPTY_STRING,
  [FormFields.JAR_ACTIVATION_LIMIT_LOW]: EMPTY_STRING,
  [FormFields.JAR_ACTIVATION_LIMIT_HIGH]: EMPTY_STRING,
  [FormFields.ADDITIONAL_NORMAL_FORCE]: EMPTY_STRING,
  [FormFields.IS_KNUCKLE_JOINT]: undefined,
  [FormFields.IS_VERIFIED]: undefined,
  [FormFields.AXIAL_FRICTION_REDUCED_TO]: EMPTY_STRING,
  [FormFields.WEIGHT_ABOVE_JARRING_POINT_FACTOR]: EMPTY_STRING,
  [FormFields.TOOL_3D_OBJECT_ID]: EMPTY_STRING,
};

const M3ModalContainer = ({
  edit = false,
  tool,
  title,
  toolId,
  openModal,
  suppliers,
  dataState,
  toolGroups,
  toggleModal,
  existingTool,
  dispatchOnLoad,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatchOnLoad();
  }, [dispatchOnLoad]);

  const toolConnectors = useSelector(getToolConnectorsFromState);
  const unitsOfMeasurePreference = useSelector(getUnitsOfMeasurePreference);
  const selectedCustomUnitsOfMeasure = useSelector(getCurrentClientSelectedUom);

  const saveTool = useCallback(
    (formValues) => {
      let tool = {
        referenceNumber: formValues.m3Item,
        topConnectorId: formValues.topConnector,
        bottomConnectorId: formValues.bottomConnector,
        name: formValues.name,
        toolGroupId: formValues.group,
        supplier: formValues.supplier,
        supplierPartNumber: formValues.supplierPartNumber,
        description: formValues.description,
        outerDiameter: {
          value: formValues.outerDiameter,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.OUTER_DIAMETER,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        innerDiameter: {
          value: formValues.innerDiameter,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.INNER_DIAMETER,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        length: {
          value: formValues.length,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.LENGTH,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        weight: {
          value: formValues.weight,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.WEIGHT,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        fishNeck: {
          value: formValues.fishneck,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.FISHNECK,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        safeWorkingLoad: {
          value: formValues.safeWorkingLoad,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.SWL,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        yield: {
          value: formValues.yield,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.YIELD,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        maximumOD: {
          value: formValues.maximumOD,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.MAXIMUM_OD,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        maximumStandoffForce: {
          value: formValues.maximumStandoffForce,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.MAXIMUM_STANDOFF_FORCE,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        minimumOD: {
          value: formValues.minimumOD,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.MINIMUM_OD,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        minimumStandoffForce: {
          value: formValues.minimumStandoffForce,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.MINIMUM_STANDOFF_FORCE,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        jarActivationLimitLow: {
          value: formValues.jarActivationLimitLow,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_LOW,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        jarActivationLimitHigh: {
          value: formValues.jarActivationLimitHigh,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.JAR_ACTIVATION_LIMIT_HIGH,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        additionalNormalForce: {
          value: formValues.additionalNormalForce,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.ADDITIONAL_NORMAL_FORCE,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        isKnuckleJoint: formValues.isKnuckleJoint,
        isVerified: formValues.isVerified,
        axialFrictionReducedTo: {
          value: formValues.axialFrictionReducedTo,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.AXIAL_FRICTION_REDUCED_TO,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        weightAboveJarringPointFactor: {
          value: formValues.weightAboveJarringPointFactor,
          unit: getUnitForProperty(
            EDITABLE_TOOL_PROPERTIES.WEIGHT_ABOVE_JARRING_POINT_FACTOR,
            unitsOfMeasurePreference,
            selectedCustomUnitsOfMeasure,
          ),
        },
        toolImageId: formValues.toolImageId,
        tool3DObjectId: formValues.tool3DObjectId,
      };
      if (edit) {
        tool.toolId = parseInt(toolId);
        const newTool = existingToolToToolMapper(tool, existingTool);
        dispatch(updateTool(newTool, MODAL.EDIT_M3_TOOL));
      } else {
        dispatch(createTool(tool));
      }
    },
    [
      dispatch,
      unitsOfMeasurePreference,
      edit,
      toolId,
      existingTool,
      selectedCustomUnitsOfMeasure,
    ],
  );

  initialFormValues[FormFields.IS_KNUCKLE_JOINT] = tool?.get(
    FormFields.IS_KNUCKLE_JOINT,
    false,
  );

  initialFormValues[FormFields.IS_VERIFIED] = tool?.get(
    FormFields.IS_VERIFIED,
    false,
  );

  return (
    <BasePage dataState={dataState}>
      <M3Modal
        edit={edit}
        tool={tool}
        title={title}
        open={openModal}
        onSubmit={saveTool}
        saveTool={saveTool}
        suppliers={suppliers}
        toolGroups={toolGroups}
        toggleModal={toggleModal}
        toolConnectors={toolConnectors}
        initialFormValues={initialFormValues}
        unitsOfMeasurePreference={unitsOfMeasurePreference}
        selectedCustomUnitsOfMeasure={selectedCustomUnitsOfMeasure}
      ></M3Modal>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    EQUIPMENT_ACTIONS.CREATE_TOOL,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoadAddToolsModal,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  M3ModalContainer,
);
