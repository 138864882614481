import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import { CABLE_TAB_FIELDS } from 'features/projects/tasks/task/dataAcquisition/dataAcquisition.constants';
import { CommentsRichText } from 'features/projects/tasks/task/dataAcquisition/components/CableTabContainer/Components/CommentsRichText';

const CableTabContainer = ({ disabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item xs={3}>
          <Field
            type="number"
            label="Flowtube ID"
            disabled={disabled}
            component={QuantityTextFieldFormik}
            name={CABLE_TAB_FIELDS.FLOWTUBE}
            placeholder="0"
            useRoundedValue={true}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            type="number"
            label="Max Tension"
            disabled={disabled}
            component={QuantityTextFieldFormik}
            name={CABLE_TAB_FIELDS.MAX_TENSION}
            placeholder="0"
            useRoundedValue={true}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            type="number"
            label="Weak Point Build"
            disabled={disabled}
            component={QuantityTextFieldFormik}
            name={CABLE_TAB_FIELDS.WEAK_POINT_BUILD}
            placeholder="0"
            useRoundedValue={true}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={3}>
          <Field
            type="number"
            label="Distance Winch to Lower Sheave"
            disabled={disabled}
            component={QuantityTextFieldFormik}
            name={CABLE_TAB_FIELDS.DISTANCE_WINCH_TO_FIRST_SHAVE}
            placeholder="0"
            useRoundedValue={true}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            type="number"
            label="Lower Sheave to Upper Sheave"
            disabled={disabled}
            component={QuantityTextFieldFormik}
            name={CABLE_TAB_FIELDS.LOWER_WINCH_TO_FIRST_SHAVE}
            placeholder="0"
            useRoundedValue={true}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            type="number"
            label="Lower to Upper Sheave Wheel to 0-point"
            disabled={disabled}
            component={QuantityTextFieldFormik}
            name={CABLE_TAB_FIELDS.LOWER_TOUPPER_SHEAVE_WHEEL}
            placeholder="0"
            useRoundedValue={true}
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <CommentsRichText disabled={disabled} />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CableTabContainer;
