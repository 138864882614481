import { useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { List, isImmutable } from 'immutable';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';
import { Tooltip } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import {
  Fab,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { EMPTY_LIST, SystemPermission } from 'app/app.constants';
import TableCellDense from 'app/components/Table/TableCellDense';
import ToolImageTableCell from 'app/components/ToolImageTableCell';
import { ExifOrientation } from 'features/projects/tool/tool.constants';
import TableHeaderCellDense from 'app/components/Table/TableHeaderCellDense';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';
import { formatValue } from 'utils/format.util';
import { getQuantityUnitFromItems } from 'utils/app.util';
import HasSystemPermission from 'app/components/HasSystemPermission';
import ToolImageContainer from 'features/projects/tool/components/ToolImageContainer';

const defaultInitialState = {
  pageSize: 30,
};

const getSubRows = (row) =>
  isImmutable(row)
    ? row.get('toolAssemblyTools')?.toArray()
    : row.original?.toolAssemblyTools?.toArray();

const ToolAssembliesTable = ({
  classes,
  loading,
  tools = EMPTY_LIST,
  isModalForAdding = false,
  onEditTool,
  onAddTool,
}) => {
  const initialState = useMemo(
    () => ({
      ...defaultInitialState,
      sortBy: [
        {
          id: 'sequence',
          desc: false,
        },
      ],
    }),
    [],
  );

  const lengthUnit = getQuantityUnitFromItems(tools, (result) =>
    result.get('length'),
  );

  const weightUnit = getQuantityUnitFromItems(tools, (result) =>
    result.get('weight'),
  );

  const diameterUnit = getQuantityUnitFromItems(tools, (result) =>
    result.get('outerDiameter'),
  );

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 1,
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (!row.canExpand) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <IconButton {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 0,
        id: 'dataVerified',
        Header: '',
        Cell: ({ row }) => {
          const tool = row.original;
          if (!row.canExpand) {
            if (!tool.get('dataVerified')) {
              return (
                <TableRowActionsCell minItems={0}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Tooltip title="Unverified tool">
                      <WarningIcon className={classes.warningIcon} />
                    </Tooltip>
                  </Grid>
                </TableRowActionsCell>
              );
            } else return null;
          } else if (!tool.get('verifiedData')) {
            return (
              <TableRowActionsCell minItems={0}>
                <Grid container justifyContent="center" alignItems="center">
                  <Tooltip title="Unverified tool">
                    <WarningIcon className={classes.warningIcon} />
                  </Tooltip>
                </Grid>
              </TableRowActionsCell>
            );
          } else {
            return null;
          }
        },
      },
      {
        xs: 1,
        id: 'toolItemNo',
        Header: 'Item no.',
        accessor: (tool) => tool.get('m3ItemNumber'),
        Cell: ({ row, value }) => (
          <Typography
            variant="subtitle2"
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {value}
          </Typography>
        ),
      },
      {
        xs: 4,
        id: 'image',
        Header: 'Image',
        Cell: ({ row }) => {
          const { original: tool } = row;
          const toolStringItems = getSubRows(tool);
          if (toolStringItems && toolStringItems.length > 0) {
            return (
              <Grid container className={classes.toolContainer}>
                {toolStringItems.reverse().map((item) => {
                  return (
                    <ToolImageContainer
                      onHoverDarken
                      title={item.get('name')}
                      toolName={item.get('name')}
                      toolImageUrl={item.get('imageUrl')}
                      orientation={ExifOrientation.DEGREES_90_MIRRORED}
                      style={{
                        maxHeight: '50px',
                        maxWidth: `${100 / toolStringItems.length}%`,
                      }}
                    />
                  );
                })}
              </Grid>
            );
          }
          return (
            <ToolImageTableCell
              onHoverDarken
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth}rem`,
                },
              })}
              title={tool.get('name')}
              toolName={tool.get('name')}
              toolImageUrl={tool.get('imageUrl')}
              orientation={ExifOrientation.DEGREES_90_MIRRORED}
            />
          );
        },
      },
      {
        xs: 1,
        id: 'toolName',
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
        Cell: ({ row, value }) => (
          <Typography
            variant="subtitle2"
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth}rem`,
              },
            })}
          >
            {value}
          </Typography>
        ),
      },
      {
        xs: 1,
        id: 'toolLength',
        accessor: (tool) =>
          formatValue(tool.getIn(['length', 'value']), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Length" unit={lengthUnit} />
        ),
      },
      {
        xs: 1,
        id: 'toolWeight',
        accessor: (tool) =>
          formatValue(tool.getIn(['weight', 'value']), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Weight" unit={weightUnit} />
        ),
      },
      {
        xs: 1,
        id: 'toolOD',
        accessor: (tool) =>
          formatValue(tool.getIn(['outerDiameter', 'value']), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="OD" unit={diameterUnit} />
        ),
      },
      {
        xs: 1,
        id: 'editTool',
        Cell: ({ row }) => {
          const { original: tool } = row;
          if (!isModalForAdding && row.canExpand) {
            return (
              <HasSystemPermission
                permissions={[SystemPermission.ITEM_TAB_EDIT]}
              >
                <Grid item container justifyContent="flex-end">
                  <IconButton onClick={() => onEditTool(tool.get('id'))}>
                    <EditIcon />
                  </IconButton>
                </Grid>
              </HasSystemPermission>
            );
          } else {
            return null;
          }
        },
      },
      {
        id: 'actions',
        xs: 1,
        Header: <TableRowActionsCell minItems={3} />,
        Cell: ({ row }) => {
          const { original: tool } = row;
          if (isModalForAdding) {
            return (
              <TableRowActionsCell>
                <Fab
                  size="small"
                  color="primary"
                  title="Add tool"
                  variant="extended"
                  onClick={() => {
                    onAddTool(tool);
                  }}
                >
                  <span>Add</span>
                  <AddIcon fontSize="small" />
                </Fab>
              </TableRowActionsCell>
            );
          } else {
            return null;
          }
        },
      },
    ],
    [
      classes,
      isModalForAdding,
      onAddTool,
      onEditTool,
      lengthUnit,
      weightUnit,
      diameterUnit,
    ],
  );

  if (loading) {
    return (
      <Grid item container xs alignItems="center" justifyContent="center">
        <Grid item component={CircularProgress} color="secondary" />
      </Grid>
    );
  }

  return (
    <Grid item container direction="column">
      <Grid xs item container wrap="nowrap" direction="column">
        <Grid item>
          <Table
            useExpanded
            stickyHeader
            items={tools}
            usePagination
            columns={columns}
            useGlobalFilter={false}
            getSubRows={getSubRows}
            initialState={initialState}
            noItemsMessage="No tools found. Try changing the filters"
            TableCellComponent={TableCellDense}
            TableHeaderCellComponent={TableHeaderCellDense}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

ToolAssembliesTable.propTypes = {
  tools: PropTypes.instanceOf(List),
};

const styles = (theme) => ({
  rowActionCell: {
    paddingRght: '10px',
  },
  toolContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
});

export default compose(withStyles(styles))(ToolAssembliesTable);
