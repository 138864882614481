import { useCallback } from 'react';

import { RouteContainer } from 'altus-ui-components';

import { invokeIfFunction } from 'utils/app.util';
import SystemRoutesContainer from 'app/components/SystemRoutesContainer';
import ProjectRoutesContainer from 'app/components/ProjectRoutesContainer';

const RoutesContainer = ({ render, routes }) => {
  const renderRouteContainer = useCallback(
    (routes) => {
      if (render) return invokeIfFunction(render, routes);

      return <RouteContainer routes={routes} />;
    },
    [render],
  );

  const renderRoutes = useCallback(
    (routes) => (
      <ProjectRoutesContainer routes={routes} render={renderRouteContainer} />
    ),
    [renderRouteContainer],
  );

  // filter away routes with systemPermissions, then projectPermissions
  return <SystemRoutesContainer routes={routes} render={renderRoutes} />;
};

export default RoutesContainer;
