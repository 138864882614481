import { compose } from 'redux';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import {
  getToolstringFromState,
  getToolstringToolsFromState,
} from 'features/projects/tasks/task/toolstring/toolstring.selectors';

import { EMPTY_MAP, ProjectPermission } from 'app/app.constants';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { getToolstring } from 'features/projects/tasks/task/toolstring/toolstring.actions';
import { TOOLSTRING_ACTIONS } from 'features/projects/tasks/task/toolstring/toolstring.constants';
import TaskSimulationToolstringPreview from 'features/projects/tasks/task/simulation/components/TaskSimulationToolstringPreview';

const TaskSimulationToolstringContainer = ({
  taskId,
  classes,
  dataState,
  projectId,
  toolstringId,
  toolDataIsVerified,
  setToolDataIsVerified,
  dispatchGetToolstring,
  simulation = EMPTY_MAP,
  toolstring = EMPTY_MAP,
  toolstringTools = EMPTY_MAP,
}) => {
  useEffect(() => {
    if (!toolstringId) return;
    dispatchGetToolstring(projectId, taskId, toolstringId);
  }, [projectId, taskId, toolstringId, dispatchGetToolstring]);

  useEffect(() => {
    const checkToolDataVerification = (tools) => {
      for (const tool of tools) {
        if (tool.toolAssemblyTools && tool.toolAssemblyTools.length > 0) {
          if (!checkToolDataVerification(tool.toolAssemblyTools)) {
            return false;
          }
        } else if (_isNil(tool.dataVerified)) {
          return false;
        }
      }
      return true;
    };

    const allToolsVerified = checkToolDataVerification(toolstringTools.toJS());
    setToolDataIsVerified(allToolsVerified);
  }, [toolstringTools, setToolDataIsVerified]);

  return (
    <HasProjectPermission
      permission={ProjectPermission.EDIT_TOOLSTRING}
      render={(canEdit) => (
        <Grid container item xs className={classes.toolstringContainer}>
          <TaskSimulationToolstringPreview
            taskId={taskId}
            projectId={projectId}
            dataState={dataState}
            toolstring={toolstring}
            toolstringId={toolstringId}
            toolstringTools={toolstringTools}
            isDataVerified={toolDataIsVerified}
            canEdit={canEdit && !simulation.get('isPlanned', false)}
          />
        </Grid>
      )}
    />
  );
};

const styles = (theme) => ({
  toolstringContainer: {
    height: `calc(100% - ${theme.spacing(15)}px)`,
  },
});

export default compose(
  connect(
    (state, { toolstringId }) => ({
      dataState: getSummarizedDataStateFromState(
        state,
        TOOLSTRING_ACTIONS.GET_TOOLSTRING_FOR_TASK,
      ),
      toolstring: getToolstringFromState(state, toolstringId),
      toolstringTools: getToolstringToolsFromState(state, toolstringId),
    }),
    {
      dispatchGetToolstring: getToolstring,
    },
  ),

  withStyles(styles),
)(TaskSimulationToolstringContainer);
