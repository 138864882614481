import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router/immutable';

import {
  thunkMiddleware,
  promiseMiddleware,
  confirmationMiddleware,
  createNotificationMiddleware,
} from 'altus-redux-middlewares';

import rootSaga from 'app/sagas/rootSaga';
import history from 'infrastructure/history';
import { onUnauthorized } from 'app/app.actions';
import rootReducer from 'app/reducers/rootReducer';
import { IN_DEV } from 'app/app.constants';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware({
  onError: IN_DEV ? console.log : undefined,
});

const notificationMiddleware = createNotificationMiddleware({
  onUnauthorized: () => store.dispatch(onUnauthorized()),
});

const defaultMiddlewares = [
  confirmationMiddleware,
  sagaMiddleware,
  thunkMiddleware,
  routerMiddleware(history),
  promiseMiddleware,
  notificationMiddleware,
];

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...defaultMiddlewares)),
);

sagaMiddleware.run(rootSaga);

export default store;
