import { createSelector } from 'utils/app.util';

const getCheetahJobsSelector = (state) => state.getIn(['entity', 'cheetahJob']);

export const getCheetahJobsFromState = createSelector(
  getCheetahJobsSelector,
  (cheetahJobs) => cheetahJobs,
);

export const createFilteredCheetahJobsSelector = (filter) =>
  createSelector(getCheetahJobsFromState, (cheetahJobs) =>
    cheetahJobs.filter((cheetahJob) =>
      Object.keys(filter).every(
        (key) => cheetahJob.get(key)?.toString() === filter[key]?.toString(),
      ),
    ),
  );
