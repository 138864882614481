import withStyles from '@material-ui/styles/withStyles';

import ToolImageContainer from 'features/projects/tool/components/ToolImageContainer';

const ToolImageTableCell = ({ classes, ...rest }) => (
  <ToolImageContainer
    classes={{
      root: classes.root,
    }}
    {...rest}
  />
);

const styles = (theme) => ({
  root: {
    height: '100%',
    maxWidth: '100%',
    minWidth: '100%',
    maxHeight: 'inherit',
    objectFit: 'contain',
    padding: `${theme.spacing(0.5)}px 0`,
  },
});

export default withStyles(styles)(ToolImageTableCell);
