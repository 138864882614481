import { List, fromJS, Map } from 'immutable';

import {
  toFacilityFromDto,
  toProjectStatusFromDto,
  toDepartmentFromDto,
} from 'features/projects/projects.mappers';

import { EMPTY_LIST } from 'app/app.constants';
import { PROJECTS_ACTIONS } from 'features/projects/projects.constants';

const initialState = fromJS({
  data: Map({
    statuses: EMPTY_LIST,
    facilities: EMPTY_LIST,
    departments: EMPTY_LIST,
  }),
});

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECTS_ACTIONS.PROJECTS_PAGE_LOADED: {
      if (action.error) return initialState;

      const [_fields, facilities, statuses, departments] = action.payload;

      return state
        .setIn(['data', 'statuses'], List(statuses).map(toProjectStatusFromDto))
        .setIn(['data', 'facilities'], List(facilities).map(toFacilityFromDto))
        .setIn(
          ['data', 'departments'],
          List(departments).map(toDepartmentFromDto),
        );
    }

    case PROJECTS_ACTIONS.PROJECTS_PAGE_UNLOADED:
      return initialState;

    default:
      return state;
  }
};

export default projectsReducer;
