import { useMemo, useCallback } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import withStyles from '@material-ui/styles/withStyles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { FavoriteButton } from 'altus-ui-components';

import CardNavigationItem from 'app/components/CardNavigationItem/CardNavigationItem';

const ToolstringCardNavigationItem = ({
  to,
  onEdit,
  classes,
  onDelete,
  toolstring,
  onDuplicate,
  toggleFavorite,
}) => {
  const data = useMemo(
    () => [
      {
        key: 'Cable',
        value: toolstring.getIn(['cable', 'name']) || 'No Cable',
      },
      {
        key: 'Modified',
        value: toolstring.get('modified').fromNow(),
      },
    ],
    [toolstring],
  );

  const renderHeaderAction = useCallback(() => {
    if (toolstring.get('isPlanned')) {
      return (
        /* Wrap in IconButton so it looks/positions similarly to FavoriteButton */
        <IconButton
          disabled
          size="small"
          classes={{ root: classes.plannedIcon }}
        >
          <CheckCircleIcon fontSize="small" />
        </IconButton>
      );
    }

    return (
      <FavoriteButton
        isFavorite={toolstring.get('favorite')}
        onClick={toggleFavorite}
      />
    );
  }, [toolstring, toggleFavorite, classes]);

  // TODO: we need to disable some actions buttons based on permissions.
  // Will do this later, since in this case we will probably need to refactor or add
  // another HasProjectPermission component with a render method that provides multiple permissions verification
  const actions = useMemo(
    () =>
      [
        {
          Icon: EditIcon,
          title: 'Edit',
          disabled: toolstring.get('isPlanned'),
          onClick: onEdit,
        },
        {
          Icon: DuplicateIcon,
          title: 'Duplicate',
          onClick: onDuplicate,
        },
        {
          Icon: DeleteIcon,
          title: 'Delete',
          disabled: toolstring.get('isPlanned'),
          onClick: onDelete,
        },
      ].filter((action) => !!action.onClick),
    [onEdit, onDuplicate, onDelete, toolstring],
  );

  return (
    <CardNavigationItem
      to={to}
      data={data}
      actions={actions}
      title={toolstring.get('name')}
      renderHeaderAction={renderHeaderAction}
    />
  );
};

const styles = (theme) => ({
  plannedIcon: {
    '&:disabled': {
      color: theme.palette.text.primary,
    },
  },
});

export default withStyles(styles)(ToolstringCardNavigationItem);
