import { useState, useRef } from 'react';

import {
  Grid,
  Button,
  Dialog,
  Typography,
  DialogContent,
} from '@material-ui/core';

import { Tab, Tabs } from 'altus-ui-components';

import { EMPTY_MAP, EMPTY_STRING } from 'app/app.constants';

import ApplicationHeaderBase from 'app/components/ApplicationHeader/ApplicationHeaderBase';
import AddSurfaceEquipmentInPlanningModalTable from 'features/projects/tasks/task/surfaceEquipment/components/surfaceEquipment/AddSurfaceEquipmentInPlanningModalTable';
import SurfaceEquipmentFilter from 'features/equipment/surfaceEquipment/components/SurfaceEquipmentFilter';
import EquipmentTemplatesFilter from 'features/equipment/equipmentTemplates/components/EquipmentTemplatesFilter';
import EquipmentTemplatesTable from 'features/equipment/equipmentTemplates/components/EquipmentTemplatesTable';
import { Filters } from 'features/equipment/toolAssemblies/components/ToolAssembliesFilter';

const SURFACE_EQUIPMENT_OPTIONS = {
  ITEMS: 1,
  TEMPLATE: 2,
};
const defaultFormValues = {
  [Filters.TEXT_SEARCH]: EMPTY_STRING,
};

const AddSurfaceEquipmentInPlanningModal = ({
  isOpen,
  classes,
  toolGroups,
  toggleModal,
  onSubmitItem,
  initialValues,
  onRemoveSurface,
  onSubmitTemplate,
  tools = EMPTY_MAP,
  showTemplateSelection,
  onSaveSurfaceEquipment,
  selectedSurfaceEquipment,
  setSelectedSurfaceEquipment,
  equipmentTemplates = EMPTY_MAP,
  onSaveSurfaceEquipmentTemplate,
}) => {
  const [state, setState] = useState({
    tab: SURFACE_EQUIPMENT_OPTIONS.ITEMS,
  });
  const [initialTemplateFilterValues] = useState(defaultFormValues);

  const formRef = useRef(null);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      PaperProps={{
        style: {
          maxHeight: '80%',
          height: '80%',
        },
      }}
      open={isOpen}
      onClose={toggleModal}
    >
      <ApplicationHeaderBase>
        <Grid container alignItems="center">
          <Grid xs item container direction="column">
            <Typography item component={Typography} variant="h6">
              Add surface equipment
            </Typography>
          </Grid>
          <Grid item container xs justifyContent="flex-end">
            <Button color="secondary" variant="standard" onClick={toggleModal}>
              Close
            </Button>
          </Grid>
        </Grid>
      </ApplicationHeaderBase>
      {showTemplateSelection ? (
        <DialogContent>
          <Tabs
            value={state.tab}
            indicatorColor="primary"
            onChange={(_event, value) => setState({ tab: value })}
          >
            <Tab
              label="Surface equipment"
              value={SURFACE_EQUIPMENT_OPTIONS.ITEMS}
            />
            <Tab
              label={`Template (${equipmentTemplates.size})`}
              value={SURFACE_EQUIPMENT_OPTIONS.TEMPLATE}
            />
          </Tabs>
          <Grid container justifyContent="center">
            {state.tab === SURFACE_EQUIPMENT_OPTIONS.ITEMS && (
              <Grid item xs={12}>
                <SurfaceEquipmentFilter
                  onSubmit={onSubmitItem}
                  toolGroups={toolGroups}
                  initialValues={initialValues}
                />
                <AddSurfaceEquipmentInPlanningModalTable
                  tools={tools}
                  classes={classes}
                  onRemoveSurface={onRemoveSurface}
                  onSaveSurfaceEquipment={onSaveSurfaceEquipment}
                  selectedSurfaceEquipment={selectedSurfaceEquipment}
                  setSelectedSurfaceEquipment={setSelectedSurfaceEquipment}
                />
              </Grid>
            )}
            {state.tab === SURFACE_EQUIPMENT_OPTIONS.TEMPLATE && (
              <Grid item xs={12}>
                <EquipmentTemplatesFilter
                  ref={formRef}
                  displayAddButton={false}
                  onSubmit={onSubmitTemplate}
                  initialValues={initialTemplateFilterValues}
                />
                <EquipmentTemplatesTable
                  addMode={true}
                  equipmentTemplates={equipmentTemplates}
                  onSaveSurfaceEquipmentTemplate={
                    onSaveSurfaceEquipmentTemplate
                  }
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent>
          <Grid item xs={12}>
            <SurfaceEquipmentFilter
              onSubmit={onSubmitItem}
              toolGroups={toolGroups}
              initialValues={initialValues}
            />
            <AddSurfaceEquipmentInPlanningModalTable
              tools={tools}
              classes={classes}
              onRemoveSurface={onRemoveSurface}
              onSaveSurfaceEquipment={onSaveSurfaceEquipment}
              selectedSurfaceEquipment={selectedSurfaceEquipment}
              setSelectedSurfaceEquipment={setSelectedSurfaceEquipment}
            />
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AddSurfaceEquipmentInPlanningModal;
