import routePaths from 'app/routePaths';
import { useSelector } from 'react-redux';

import { useHeader } from 'app/hooks/useHeader';
import { getCurrentProject } from 'features/projects/projects.selectors';

const useProjectHeader = () => {
  const project = useSelector(getCurrentProject);

  const title = project.get('fullTitle');

  useHeader({
    title,
    logoNavigation: routePaths.projects,
  });
};

export default useProjectHeader;
