/* eslint-disable */
/** disabling eslint to ignore exhaustive deps check as it was causing redundant hook execution
 */
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import React, { useEffect, useRef } from 'react';
import { createTextSprite } from 'app/components/WellboreContextualization/RenderNippleText';

const objectUp = new THREE.Object3D().up;
const reusableVector = new THREE.Vector3();
const reusableMatrix = new THREE.Matrix4();

const orientationMultiplier = new THREE.Matrix4().set(
  1,
  0,
  0,
  0,
  0,
  0,
  1,
  0,
  0,
  -1,
  0,
  0,
  0,
  0,
  0,
  1,
);
const RenderNipples = ({
  v1,
  v2,
  length,
  material,
  radius = 30,
  outerRadius,
  name,
  nippleText,
  nippleTextRef,
}) => {
  const meshRef = useRef();
  const { scene } = useThree();
  const direction = reusableVector.subVectors(v1, v2);
  useEffect(() => {
    if (!v1 || !v2) return;
    reusableMatrix.lookAt(v1, v2, objectUp);
    reusableMatrix.multiply(orientationMultiplier);
  }, [v1, v2]);

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.applyMatrix4(reusableMatrix);
      meshRef.current.rotateX(Math.PI / 2);
      meshRef.current.position.set(
        (v1.x + v2.x) / 2,
        (v1.y + v2.y) / 2,
        (v1.z + v2.z) / 2,
      );

      const text = createTextSprite(nippleText, 40);
      text.position.set(
        meshRef.current.position.x,
        meshRef.current.position.y + 40,
        meshRef.current.position.z,
      );
      if (nippleTextRef.current.length) {
        for (const existingSprite of nippleTextRef.current) {
          if (
            checkIntersection(text, existingSprite) &&
            text.name !== existingSprite.name
          ) {
            text.position.set(
              text.position.x,
              text.position.y + 50,
              text.position.z,
            );
          }
        }
      }
      nippleTextRef.current.push(text);
      scene.add(text);
    }
  }, [meshRef, v1.x, v1.y, v1.z, v2.x, v2.y, v2.z, nippleText, scene]);

  return (
    <mesh
      ref={meshRef}
      name={name}
      geometry={getGeometry(length ?? direction.length(), outerRadius, radius)}
      material={material}
    />
  );
};

export default RenderNipples;

const getGeometry = (length, outerRadius, radius) => {
  const arcShape = new THREE.Shape()
    .moveTo(0, 0)
    .absarc(0, 0, outerRadius, 0, Math.PI * 2);

  const holePath = new THREE.Path()
    .moveTo(0, 0)
    .absarc(0, 0, radius, 0, Math.PI * 2);

  arcShape.holes.push(holePath);

  const geometry = new THREE.ExtrudeBufferGeometry(arcShape, {
    depth: length,
    bevelEnabled: true,
    bevelSegments: 2,
    steps: 2,
    bevelSize: 1,
    bevelThickness: 1,
  });
  return geometry;
};

export const checkIntersection = (sprite1, sprite2) => {
  sprite1.updateMatrixWorld();
  sprite2.updateMatrixWorld();
  const box1 = new THREE.Box3().setFromObject(sprite1);
  const box2 = new THREE.Box3().setFromObject(sprite2);

  return box1.intersectsBox(box2);
};

export const createNewNipple = ({ length, material, radius, outerRadius }) => {
  material.side = THREE.DoubleSide;

  const arcShape = new THREE.Shape()
    .moveTo(0, 0)
    .absarc(0, 0, outerRadius, 0, Math.PI * 2);

  const holePath = new THREE.Path()
    .moveTo(0, 0)
    .absarc(0, 0, radius, 0, Math.PI * 2);

  arcShape.holes.push(holePath);

  const geometry = getGeometry(length, outerRadius, radius);

  material.needsUpdate = true;
  const mesh = new THREE.Mesh(geometry, material);

  return mesh;
};
