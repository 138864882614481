import { compose } from 'redux';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { useFormikContext } from 'formik';
import { memo, useMemo, useEffect, useRef } from 'react';

import { invokeIfFunction } from 'utils/app.util';

const shouldSubmitDefault = ({ isValid, dirty }) => isValid && dirty;

const AutoSaveFormik = ({
  children,
  timeout = 1500,
  shouldSubmit = shouldSubmitDefault,
}) => {
  const formikContext = useFormikContext();
  const formikContextRef = useRef(formikContext);

  const { values, submitForm, dirty, isValid } = formikContext;

  useEffect(() => {
    formikContextRef.current = formikContext;
  }, [formikContext]);

  const debouncedSubmit = useMemo(
    () =>
      debounce((shouldSubmit) => {
        if (!shouldSubmit) return;

        invokeIfFunction(submitForm);
      }, timeout),
    [timeout, submitForm],
  );

  useEffect(() => {
    const shouldSubmitForm = invokeIfFunction(
      shouldSubmit,
      formikContextRef.current,
    );

    if (!dirty && shouldSubmitForm) {
      // If submitting on initialize, cancel previous debounced submissions and submit immediately.
      debouncedSubmit.cancel();
      invokeIfFunction(submitForm);
    } else {
      debouncedSubmit(shouldSubmitForm);
    }
  }, [debouncedSubmit, dirty, submitForm, shouldSubmit, values, isValid]);

  return children;
};

AutoSaveFormik.propTypes = {
  children: PropTypes.node,
  timeout: PropTypes.number,
  shouldSubmit: PropTypes.func,
};

export default compose(memo)(AutoSaveFormik);
