import HasProjectPermission from 'app/components/HasProjectPermission';

const withProjectPermission =
  (permissions) => (WrappedComponent) => (props) => {
    return (
      <HasProjectPermission permissions={permissions}>
        <WrappedComponent {...props} />
      </HasProjectPermission>
    );
  };

export default withProjectPermission;
