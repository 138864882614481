import { useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { List, isImmutable } from 'immutable';
import { IconButton } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';

import {
  Fab,
  Grid,
  Tooltip,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import {
  ToolType,
  ExifOrientation,
  ToolCategoryType,
} from 'features/projects/tool/tool.constants';

import { formatValue } from 'utils/format.util';
import { EMPTY_LIST } from 'app/app.constants';
import { getQuantityUnitFromItems } from 'utils/app.util';
import TableCellDense from 'app/components/Table/TableCellDense';
import { ThirdPartyLabel } from 'features/projects/tool/tool.constants';
import ToolImageTableCell from 'app/components/ToolImageTableCell';
import TableHeaderCellDense from 'app/components/Table/TableHeaderCellDense';
import ToolImageContainer from 'features/projects/tool/components/ToolImageContainer';
import QuantityTableHeaderTitleCell from 'app/components/QuantityTableHeaderTitleCell';

const defaultInitialState = {
  pageSize: 30,
};

const getSubRows = (row) =>
  isImmutable(row)
    ? row.get('toolAssemblyTools')?.toArray()
    : row.original?.toolAssemblyTools?.toArray();

const getHiddenColumns = (activeCategory) => {
  var hiddenColumns = [];

  if (activeCategory.value !== ToolCategoryType.Assemblies) {
    hiddenColumns = [...hiddenColumns, 'toolAssemblyTools', 'sequence'];
  }
  if (activeCategory.value !== ToolCategoryType.ThirdParty) {
    hiddenColumns = [...hiddenColumns, 'supplier'];
  }
  return hiddenColumns;
};

const ToolPickerModalTable = ({
  classes,
  tools = EMPTY_LIST,
  loading,
  onAddTool,
  activeCategory,
}) => {
  const initialState = useMemo(
    () => ({
      ...defaultInitialState,
      hiddenColumns: getHiddenColumns(activeCategory),
      sortBy: [
        {
          id:
            activeCategory.value === ToolCategoryType.Assemblies
              ? 'sequence'
              : 'toolName',
          desc: false,
        },
      ],
    }),
    [activeCategory],
  );

  const lengthUnit = getQuantityUnitFromItems(tools, (result) =>
    result.get('length'),
  );

  const weightUnit = getQuantityUnitFromItems(tools, (result) =>
    result.get('weight'),
  );

  const diameterUnit = getQuantityUnitFromItems(tools, (result) =>
    result.get('outerDiameter'),
  );

  const columns = useMemo(
    () => [
      {
        id: 'expander',
        xs: 1,
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (!row.canExpand) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <IconButton {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: 1,
        id: 'dataVerified',
        Cell: ({ row }) => {
          const tool = row.original;
          const thirdPary =
            tool.get('m3ItemNumber') === ThirdPartyLabel ? true : false;

          if (!row.canExpand) {
            if (!tool.get('dataVerified')) {
              if (thirdPary) return null;
              return (
                <Tooltip title="Unverified tool">
                  <WarningIcon className={classes.warningIcon} />
                </Tooltip>
              );
            } else return null;
          } else return null;
        },
      },
      {
        xs: 1,
        id: 'toolItemNo',
        Header: 'Item no.',
        accessor: (tool) => tool.get('m3ItemNumber'),
        Cell: ({ row, value }) => (
          <Typography
            variant="subtitle2"
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 2}rem`,
              },
            })}
          >
            {value
              ? activeCategory.value !== ToolCategoryType.ThirdParty
                ? `#${value}`
                : value
              : null}
          </Typography>
        ),
      },
      {
        xs: 4,
        id: 'toolImage',
        Header: 'Tool',
        Cell: ({ row }) => {
          const { original: tool } = row;

          const toolStringItems = tool.get('toolAssemblyTools');

          if (
            tool.get('toolAssemblyTools') &&
            tool.get('toolAssemblyTools').size
          ) {
            return (
              <Grid container className={classes.toolContainer}>
                {toolStringItems.reverse().map((item) => {
                  return (
                    <ToolImageContainer
                      onHoverDarken
                      title={item.get('name')}
                      toolName={item.get('name')}
                      toolImageUrl={item.get('imageUrl')}
                      orientation={ExifOrientation.DEGREES_90_MIRRORED}
                      style={{
                        maxHeight: '50px',
                        maxWidth: `${100 / toolStringItems.size}%`,
                      }}
                    />
                  );
                })}
              </Grid>
            );
          }

          return (
            <ToolImageTableCell
              onHoverDarken
              title={tool.get('name')}
              toolName={tool.get('name')}
              toolImageUrl={tool.get('imageUrl')}
              orientation={ExifOrientation.DEGREES_90_MIRRORED}
            />
          );
        },
      },
      {
        xs: 3,
        id: 'toolName',
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
      {
        xs: 1,
        id: 'toolOuterDiameter',
        sortType: 'basic',
        accessor: (tool) =>
          formatValue(tool.getIn(['outerDiameter', 'value']), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell
            title="Outer Dia."
            unit={diameterUnit}
          />
        ),
      },
      {
        xs: 1,
        id: 'toolLength',
        sortType: 'basic',
        accessor: (tool) =>
          formatValue(tool.getIn(['length', 'value']), '', 0.01),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Length" unit={lengthUnit} />
        ),
      },
      {
        xs: 1,
        id: 'toolWeight',
        sortType: 'basic',
        accessor: (tool) =>
          formatValue(tool.getIn(['weight', 'value']), '', 0.1),
        Header: () => (
          <QuantityTableHeaderTitleCell title="Weight" unit={weightUnit} />
        ),
      },
      {
        xs: 1,
        id: 'topConnectorDescription',
        Header: 'Top C.',
        sortType: 'basic',
        accessor: (tool) => tool.get('topConnectorDescription'),
        Cell: ({ value }) => value ?? '',
      },
      {
        xs: 1,
        id: 'bottomConnectorDescription',
        Header: 'Bottom C.',
        sortType: 'basic',
        accessor: (tool) => tool.get('bottomConnectorDescription'),
        Cell: ({ value }) => value ?? '',
      },
      {
        xs: 1,
        id: 'toolAssemblyTools',
        Header: 'Components',
        sortType: 'basic',
        accessor: (tool) => tool.get('toolAssemblyTools'),
        Cell: ({ value }) => (
          <Typography variant="subtitle2">
            {value && value.size ? `${value.size}` : null}
          </Typography>
        ),
      },
      {
        xs: 1,
        id: 'supplier',
        Header: 'Supplier',
        sortType: 'basic',
        accessor: (tool) => tool.get('supplier'),
      },
      {
        xs: 1,
        id: 'sequence',
        Header: 'Sequence',
        accessor: (tool) => tool.get('sequence'),
      },
      {
        id: 'actions',
        xs: 1,
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          const { original: tool } = row;
          const isToolInsideAssembly =
            !!row.depth && tool.get('type') === ToolType.TOOL;

          if (isToolInsideAssembly) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell className={classes.rowActionCell}>
              <Fab
                size="small"
                color="primary"
                title="Add tool"
                variant="extended"
                onClick={() => onAddTool(tool)}
              >
                <span>Add</span>
                <AddIcon fontSize="small" />
              </Fab>
            </TableRowActionsCell>
          );
        },
      },
    ],
    [lengthUnit, weightUnit, diameterUnit, classes, onAddTool, activeCategory],
  );

  if (loading) {
    return (
      <Grid item container xs alignItems="center" justifyContent="center">
        <Grid item component={CircularProgress} color="secondary" />
      </Grid>
    );
  }

  return (
    <Grid item container direction="column">
      <Grid xs item container wrap="nowrap" direction="column">
        <Grid item>
          <Table
            useExpanded
            stickyHeader
            items={tools}
            usePagination
            columns={columns}
            useGlobalFilter={false}
            getSubRows={getSubRows}
            initialState={initialState}
            noItemsMessage="No tools found. Try changing the filters"
            TableCellComponent={TableCellDense}
            TableHeaderCellComponent={TableHeaderCellDense}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

ToolPickerModalTable.propTypes = {
  tools: PropTypes.instanceOf(List),
};

const styles = (theme) => ({
  rowActionCell: {
    paddingRght: '10px',
  },
  warningIcon: {
    color: theme.palette.warning.main,
  },
  toolContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
  },
});

export default compose(withStyles(styles))(ToolPickerModalTable);
