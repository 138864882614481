import { compose } from 'redux';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Chip, Button, Box } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { BasePageTitle, FavoriteButton } from 'altus-ui-components';

import { EMPTY_MAP } from 'app/app.constants';

const TaskToolstringHeader = ({
  classes,
  actions,
  toolstring,
  toggleFavorite,
}) => {
  const isPlanned = toolstring.get('isPlanned');

  return (
    <Grid item container alignItems="center" justifyContent="space-between">
      {isPlanned ? (
        <CheckCircleIcon fontSize="large" />
      ) : (
        <FavoriteButton
          fontSize="large"
          onClick={toggleFavorite}
          isFavorite={toolstring.get('favorite')}
        />
      )}
      <Grid item classes={{ root: classes.title }}>
        <BasePageTitle title={toolstring.get('name')} />
      </Grid>
      <Grid xs item container justifyContent="flex-end" alignItems="center">
        <Grid item component={Box} paddingRight={1}>
          <Chip
            size="small"
            color={isPlanned ? 'primary' : 'default'}
            label={isPlanned ? 'Planned' : 'Not planned'}
            icon={isPlanned ? <CheckCircleIcon /> : <LockOpenIcon />}
          />
        </Grid>
        {actions.map(({ Icon, onClick, title, disabled }, index) => (
          <Button
            key={index}
            onClick={onClick}
            disabled={disabled}
            startIcon={<Icon />}
            classes={{
              label: classes.label,
            }}
          >
            {title}
          </Button>
        ))}
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  title: {
    paddingLeft: theme.spacing(1),
  },
  label: {
    textTransform: 'none',
  },
});

TaskToolstringHeader.defaultProps = {
  toolstring: EMPTY_MAP,
};

TaskToolstringHeader.propTypes = {
  toolstring: PropTypes.instanceOf(Map),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      title: PropTypes.string,
      Icon: PropTypes.elementType,
    }),
  ),
};

export default compose(withStyles(styles))(TaskToolstringHeader);
