export const convertToPreferredUnits = (tool) => {
  const convertedTool = { ...tool };

  if (tool.length.unit === 'ft') {
    convertedTool.length = {
      ...tool.length,
      value: tool.length.value * 0.3048,
      unit: 'm',
    };
  }

  if (tool.outerDiameter.unit === 'in') {
    convertedTool.outerDiameter = {
      ...tool.outerDiameter,
      value: tool.outerDiameter.value * 25.4,
      unit: 'mm',
    };
  }

  return convertedTool;
};
