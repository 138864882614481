import { connect } from 'react-redux';
import { PureComponent } from 'react';
import toJSComponent from 'with-immutable-props-to-js';

import { logOut } from 'app/app.actions';
import LoadingDimmer from 'app/components/LoadingDimmer';

class Logout extends PureComponent {
  constructor(props) {
    super(props);

    const { dispatchLogOut } = props;
    dispatchLogOut();
  }

  render() {
    return <LoadingDimmer text="Signing out" />;
  }
}

export default connect(null, {
  dispatchLogOut: logOut,
})(toJSComponent(Logout));
