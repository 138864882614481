import { compose } from 'redux';
import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { Chip, Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { UserAvatar, SortableList, BasePage } from 'altus-ui-components';

import {
  getAllUsersFromState,
  getSummarizedDataStateFromState,
} from 'app/app.selectors';

import {
  userAccessOnLoad,
  addSystemRoleToUser,
  removeSystemRoleFromUser,
} from 'authorization/authorization.actions';

import { getInitials } from 'utils/app.util';
import { APP_ACTIONS, EMPTY_LIST } from 'app/app.constants';
import { AUTHORIZATION_ACTIONS } from 'authorization/authorization.constants';
import UserRoleSelector from 'features/settings/userAccess/UserRoleSelector.js';
import { getSystemRolesFromState } from 'authorization/authorization.selectors';

class UserAccessContainer extends PureComponent {
  componentDidMount() {
    const { dispatchUserAccessOnLoad } = this.props;

    dispatchUserAccessOnLoad();
  }

  columns = [
    {
      xs: 4,
      title: 'User name',
      getSortProperty: (user) => user.get('name'),
    },
    {
      xs: true,
      component: Grid,
      title: 'Access Roles',
      getSortProperty: (user) => {
        const { classes, dispatchRemoveRoleFromUser, roles } = this.props;

        return (
          <Grid container spacing={1} className={classes.rolesColumn}>
            {user
              .get('roles')
              .map((roleId) => roles.get(roleId.toString()))
              .filter(Boolean)
              .sortBy((role) => role.get('name'))
              .map((role, index) => (
                <Grid item key={index}>
                  <Chip
                    label={role.get('name')}
                    title={role.get('description')}
                    classes={{ root: classes.chipRoot }}
                    onDelete={() => dispatchRemoveRoleFromUser(role, user)}
                  />
                </Grid>
              ))}
          </Grid>
        );
      },
    },
  ];

  actions = [
    {
      getValue: (user) => {
        const { roles, dispatchAddSystemRoleToUser } = this.props;

        return (
          <UserRoleSelector
            user={user}
            roles={roles}
            addRoleToUser={dispatchAddSystemRoleToUser}
          />
        );
      },
    },
  ];

  renderIcon = (user) => (
    <UserAvatar
      avatar={user.get('avatar')}
      initials={getInitials(user.get('name'))}
    />
  );

  getKey = (user) => user.get('id');

  render() {
    const { Icon, breadcrumb, users, dataState } = this.props;

    return (
      <BasePage title={breadcrumb} Icon={Icon} dataState={dataState}>
        <SortableList
          displaySearchField
          items={users}
          getKey={this.getKey}
          defaultSortColumn={0}
          columns={this.columns}
          actions={this.actions}
          renderIcon={this.renderIcon}
        />
      </BasePage>
    );
  }
}

UserAccessContainer.defaultProps = {
  users: EMPTY_LIST,
  roles: EMPTY_LIST,
};

const styles = (theme) => ({
  chipRoot: {
    backgroundColor: theme.palette.primary.main,
  },
  rolesColumn: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
});

export default compose(
  connect(
    (state) => ({
      users: getAllUsersFromState(state),
      roles: getSystemRolesFromState(state),
      dataState: getSummarizedDataStateFromState(
        state,
        AUTHORIZATION_ACTIONS.GET_SYSTEM_ROLES,
        APP_ACTIONS.GET_MAIN_VENDOR_USERS,
      ),
    }),
    {
      dispatchRemoveRoleFromUser: removeSystemRoleFromUser,
      dispatchAddSystemRoleToUser: addSystemRoleToUser,
      dispatchUserAccessOnLoad: userAccessOnLoad,
    },
  ),
  withStyles(styles),
)(UserAccessContainer);
