import { Grid } from '@material-ui/core';

import { NoContentBasePage } from 'altus-ui-components';

import ThreeJsManagerProvider from 'app/threeJs/ThreeJsManagerProvider';
import WellboreContextTrajectoryContainer from 'app/components/WellboreContextualization/WellboreContextTrajectoryContainer';

const ProjectWellboreTrajectory3DView = ({
  trajectory,
  wellboreDetail,
  wellboreId,
  wellbore,
  wellboreSections,
}) => {
  if (!trajectory.size) {
    return (
      <Grid item xs container>
        <NoContentBasePage
          Icon={null}
          header="Well Trajectory Not Available"
          description="Go to wells page to upload one."
        />
      </Grid>
    );
  }

  return (
    <ThreeJsManagerProvider>
      <Grid item xs container>
        <WellboreContextTrajectoryContainer
          trajectory={trajectory}
          wellboreId={wellboreId}
          wellboreDetail={wellboreDetail}
          wellbore={wellbore}
          wellboreSections={wellboreSections}
        />
      </Grid>
    </ThreeJsManagerProvider>
  );
};

export default ProjectWellboreTrajectory3DView;
