import RefreshIcon from '@material-ui/icons/Refresh';
import { Grid, Button, Typography } from '@material-ui/core';

const ToolPickerModalHeader = ({ onRefreshTools, onClose }) => (
  <Grid container alignItems="center">
    <Grid item xs>
      <Typography variant="h6">Add Tools</Typography>
    </Grid>
    <Grid container item xs justifyContent="flex-end" spacing={2}>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={onRefreshTools}
          startIcon={<RefreshIcon />}
          disabled // disabled by default until implementation of equipment service
        >
          Refresh M3
        </Button>
      </Grid>
      <Grid item>
        <Button color="secondary" variant="contained" onClick={onClose}>
          Close
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

export default ToolPickerModalHeader;
