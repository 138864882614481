import { Map, OrderedMap } from 'immutable';

import mappers from 'mappers';
import { EMPTY_MAP, EMPTY_LIST } from 'app/app.constants';
import {
  OPERATIONS_ACTIONS,
  STATE,
} from 'features/operations/operations.constants';

const initialState = Map({
  [STATE.PAGE_RESULTS]: EMPTY_LIST,
  [STATE.PAGE_PARAMS]: EMPTY_MAP,
});

const operationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPERATIONS_ACTIONS.RECEIVE_OPERATIONS_LIVE_PROJECTS: {
      if (action.error) return state;

      const { results, ...rest } = action.payload;
      const operations = OrderedMap(
        results.map((operation) => [
          operation.project.projectId.toString(),
          mappers.Operation.from(operation),
        ]),
      );
      return state
        .set(STATE.PAGE_PARAMS, new OrderedMap({ ...rest }))
        .set(STATE.PAGE_RESULTS, operations);
    }

    case OPERATIONS_ACTIONS.RECEIVE_OPERATIONS: {
      if (action.error) return state;

      const operations = OrderedMap(
        action.payload.map((operation) => [
          operation.project.projectId.toString(),
          mappers.Operation.from(operation),
        ]),
      );
      return state
        .set(STATE.PAGE_PARAMS, {})
        .set(STATE.PAGE_RESULTS, operations);
    }

    case OPERATIONS_ACTIONS.RECEIVE_OPERATION: {
      if (action.error) return state;

      const { payload } = action;

      return state.set(
        payload.projectId.toString(),
        mappers.Operation.from(payload),
      );
    }
    default:
      return state;
  }
};

export default operationsReducer;
