import { compose } from 'redux';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import { Grid, Box } from '@material-ui/core';

import { EMPTY_MAP } from 'app/app.constants';
import { required } from 'utils/validation.util';
import { formatQuantity } from 'utils/format.util';
import ReadOnlyField from 'app/components/ReadOnlyField';
import AutoSaveFormik from 'app/components/form/formik/AutoSaveFormik';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import { requestUpdateSimulation } from 'features/projects/tasks/task/simulation/simulation.actions';
import { getSimulationFromState } from 'features/projects/tasks/task/simulation/simulation.selectors';
import SimulationTemperatureGradient from 'features/projects/tasks/task/simulation/components/SimulationTemperatureGradient';

const TaskSimulationTemperatureContainer = ({
  taskId,
  projectId,
  simulationId,
  simulation = EMPTY_MAP,
  dispatchUpdateSimulation,
}) => {
  const onSubmit = useCallback(
    (simulation) =>
      dispatchUpdateSimulation(projectId, taskId, simulationId, simulation),
    [projectId, taskId, simulationId, dispatchUpdateSimulation],
  );

  const verticalDepth = formatQuantity(simulation.get('wellboreVerticalDepth'));
  const bottomTemperature = formatQuantity(simulation.get('bottomTemperature'));

  const surfaceTemperature = formatQuantity(
    simulation.get('surfaceTemperature'),
  );

  if (simulation.get('isPlanned')) {
    return (
      <Grid container component={Box} padding={1}>
        <Grid xs={3} item>
          <ReadOnlyField
            margin="none"
            label="At Surface"
            value={surfaceTemperature}
          />
        </Grid>
        <Grid xs={3} item>
          <ReadOnlyField
            margin="none"
            value={bottomTemperature}
            label={`At Total Depth - ${verticalDepth}`}
          />
        </Grid>
        <SimulationTemperatureGradient simulation={simulation} />
      </Grid>
    );
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={simulation.toJS()}
    >
      <Form>
        <AutoSaveFormik timeout={2000}>
          <Grid container spacing={2} direction="column">
            <Grid xs={4} item component={Box} marginBottom={2}>
              <Field
                required
                type="number"
                margin="dense"
                label="At Surface"
                validate={required}
                name="surfaceTemperature"
                component={QuantityTextFieldFormik}
              />
            </Grid>
            <Grid xs={4} item component={Box} marginBottom={2}>
              <Field
                required
                type="number"
                margin="dense"
                validate={required}
                name="bottomTemperature"
                component={QuantityTextFieldFormik}
                label={`At Total Depth - ${verticalDepth}`}
              />
            </Grid>
            <SimulationTemperatureGradient simulation={simulation} />
          </Grid>
        </AutoSaveFormik>
      </Form>
    </Formik>
  );
};

export default compose(
  connect(
    (state, { simulationId }) => ({
      simulation: getSimulationFromState(state, simulationId),
    }),
    {
      dispatchUpdateSimulation: requestUpdateSimulation,
    },
  ),
)(TaskSimulationTemperatureContainer);
