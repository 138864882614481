import { fromJS } from 'immutable';
import { useMemo, useCallback } from 'react';
import DeleteIcon from '@material-ui/icons/Close';
import { IconButton, Grid } from '@material-ui/core';
import DuplicateIcon from '@material-ui/icons/FileCopy';

import { Table } from 'altus-ui-components';

const defaultInitialState = {
  pageSize: 50,
};

const AddedSurfaceEquipmentInPlanningTable = ({
  tools,
  classes,
  execute = false,
  onRemoveSurface,
  onDuplicateToolItem,
  selectedSurfaceEquipment,
  setSelectedSurfaceEquipment,
  initialState = defaultInitialState,
  ...rest
}) => {
  const sortTools = useCallback(
    () =>
      fromJS(selectedSurfaceEquipment).sortBy((tool) =>
        tool.get('m3ItemNumber'),
      ),
    [selectedSurfaceEquipment],
  );

  const removeTool = useCallback(
    (id) => {
      const index = selectedSurfaceEquipment.indexOf(
        selectedSurfaceEquipment.find((tool) => tool.externalId === id),
      );
      const newNumbers = [
        ...selectedSurfaceEquipment.slice(0, index),
        ...selectedSurfaceEquipment.slice(index + 1),
      ];
      setSelectedSurfaceEquipment(newNumbers);
    },
    [selectedSurfaceEquipment, setSelectedSurfaceEquipment],
  );

  const columns = useMemo(
    () => [
      {
        xs: 2,
        Header: 'Item no.',
        accessor: (tool) => tool.get('m3ItemNumber'),
      },
      {
        xs: 2,
        Header: 'Name',
        accessor: (tool) => tool.get('name'),
      },
      {
        xs: 2,
        Header: 'Surface Eq. Group',
        accessor: (tool) => tool.getIn(['equipmentGroup', 'name']),
      },
      {
        xs: 2,
        Header: 'Weight (kg)',
        accessor: (tool) =>
          tool.get('weight') && tool.get('weight') !== 0
            ? tool.get('weight').toFixed(2)
            : tool.get('weight_kg') && tool.get('weight_kg') !== 0
            ? tool.get('weight_kg').toFixed(2)
            : '0.00',
      },
      {
        xs: 2,
        Header: 'Height (mm)',
        accessor: (tool) =>
          tool.get('height') && tool.get('height') !== 0
            ? tool.get('height').toFixed(2)
            : tool.get('height_mm') && tool.get('height_mm') !== 0
            ? tool.get('height_mm').toFixed(2)
            : '0.00',
      },
      {
        xs: 2,
        Header: 'Length (mm)',
        accessor: (tool) =>
          tool.get('length') && tool.get('length') !== 0
            ? tool.get('length').toFixed(2)
            : tool.get('length_mm') && tool.get('length_mm') !== 0
            ? tool.get('length_mm').toFixed(2)
            : '0.00',
      },
      {
        xs: 2,
        Header: 'Width (mm)',
        accessor: (tool) =>
          tool.get('width') && tool.get('width') !== 0
            ? tool.get('width').toFixed(2)
            : tool.get('width_mm') && tool.get('width_mm') !== 0
            ? tool.get('width_mm').toFixed(2)
            : '0.00',
      },
      {
        xs: 2,
        id: 'action',
        Cell: ({ row }) => {
          const tool = row.original;

          if (execute === true) return null;
          return (
            <Grid>
              <IconButton
                title="Duplicate"
                onClick={() => onDuplicateToolItem(tool.toJS())}
              >
                <DuplicateIcon />
              </IconButton>
              <IconButton
                title="Delete"
                onClick={() => {
                  onRemoveSurface(tool);
                  removeTool(tool.get('externalId'));
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          );
        },
      },
    ],
    [removeTool, onDuplicateToolItem, onRemoveSurface, execute],
  );

  return (
    <Table
      stickyHeader
      usePagination
      items={sortTools()}
      columns={columns}
      useGlobalFilter={false}
      initialState={initialState}
      {...rest}
    />
  );
};

export default AddedSurfaceEquipmentInPlanningTable;
