import { compose } from 'redux';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getProjectRoleFromState } from 'authorization/authorization.selectors';

class ProjectRoleBreadcrumb extends PureComponent {
  render() {
    const { projectRole } = this.props;

    return projectRole ? projectRole.get('name') : '...';
  }
}

export default compose(
  connect((state, { match }) => ({
    projectRole: getProjectRoleFromState(state, match.params.projectRoleId),
  })),
)(ProjectRoleBreadcrumb);
