import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import withStyles from '@material-ui/styles/withStyles';

import {
  Box,
  Chip,
  Grid,
  Button,
  Dialog,
  Select,
  MenuItem,
  Typography,
  DialogContent,
} from '@material-ui/core';

import {
  getTaskActivities,
  deleteActivitySetOrRetrieveItems,
  updateActivitySetOrRetrieveItems,
} from 'features/projects/activities/activities.actions';

import { formatToolstringToolStatusString } from 'features/projects/tasks/task/toolstring/toolstring.util';
import SetAndRetrieveItemModalHeader from 'features/projects/tasks/task/activities/SetAndRetrieveItem/SetAndRetrieveItemModalHeader';
import SetAndRetrieveItemModalFooter from 'features/projects/tasks/task/activities/SetAndRetrieveItem/SetAndRetrieveItemModalFooter';

const SetAndRetrieveItemModalContainer = ({
  open,
  classes,
  task,
  taskId,
  activities,
  projectId,
  toggleModal,
  plannedToolstringToolsWithStatus,
}) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);

  const onCloseModal = () => {
    dispatch(getTaskActivities(projectId, taskId));
    toggleModal();
  };

  useEffect(() => {
    const initializeRows = () => {
      return activities
        .filter((activity) => activity.setOrRetrieveToolstringItemId != null)
        .map((activity) => {
          const tool = plannedToolstringToolsWithStatus.find(
            (t) =>
              t.toolstringItemId === activity.setOrRetrieveToolstringItemId,
          );
          return {
            activityId: activity.id,
            toolId: tool ? tool.toolstringItemId : '',
            toolStatus: tool ? tool.toolStatus : '',
          };
        });
    };
    setRows(initializeRows());
  }, [activities, plannedToolstringToolsWithStatus]);

  const saveValuePairs = () => {
    dispatch(updateActivitySetOrRetrieveItems(projectId, taskId, rows));
    toggleModal();
  };

  const deleteValuePairs = (row) => {
    dispatch(
      deleteActivitySetOrRetrieveItems(projectId, taskId, row.activityId),
    );
  };

  const SelectingMenu = ({
    rows,
    setRows,
    plannedToolstringToolsWithStatus,
    activities,
    deleteValuePairs,
  }) => {
    const handleAddRow = () => {
      if (rows.length < plannedToolstringToolsWithStatus.length) {
        setRows([...rows, { activityId: '', toolId: '' }]);
      }
    };

    const handleActivityChange = (index, event) => {
      const updatedRows = rows.map((row, i) =>
        i === index ? { ...row, activityId: event.target.value } : row,
      );
      setRows(updatedRows);
    };

    const handleToolChange = (index, event) => {
      const toolId = event.target.value;
      const selectedTool = plannedToolstringToolsWithStatus.find(
        (tool) => tool.toolstringItemId === toolId,
      );

      const updatedRows = rows.map((row, i) =>
        i === index
          ? {
              ...row,
              toolId: toolId,
              toolStatus: selectedTool ? selectedTool.toolStatus : '',
            }
          : row,
      );

      setRows(updatedRows);
    };

    const isAddButtonDisabled =
      rows.length >= plannedToolstringToolsWithStatus.length;

    const getAvailableOptions = (options, selectedKey, currentIndex) => {
      const selectedIds = rows
        .map((row, index) => (index === currentIndex ? null : row[selectedKey]))
        .filter((id) => id !== null);
      return options.filter((option) => !selectedIds.includes(option.id));
    };

    const getAvailableOptionsTools = (options, selectedKey, currentIndex) => {
      const selectedIds = rows
        .map((row, index) => (index === currentIndex ? null : row[selectedKey]))
        .filter((id) => id !== null);

      return options.filter(
        (option) => !selectedIds.includes(option.toolstringItemId),
      );
    };

    const handleDeleteRow = (index) => {
      const updatedRows = rows.filter((_, i) => i !== index);
      setRows(updatedRows);
      deleteValuePairs(rows[index]);
    };

    return (
      <>
        <Grid item container spacing={2}>
          {rows.map((row, index) => (
            <React.Fragment key={index}>
              <Grid
                item
                container
                xs={5}
                justifyContent="center"
                alignItems="flex-end"
              >
                <Select
                  value={row.activityId}
                  onChange={(event) => handleActivityChange(index, event)}
                  displayEmpty
                  fullWidth
                >
                  {getAvailableOptions(activities, 'activityId', index).map(
                    (activity) => {
                      return (
                        <MenuItem key={activity.id} value={activity.id}>
                          {activity.name}
                        </MenuItem>
                      );
                    },
                  )}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <Select
                  value={row.toolId}
                  onChange={(event) => handleToolChange(index, event)}
                  displayEmpty
                  fullWidth
                >
                  {getAvailableOptionsTools(
                    plannedToolstringToolsWithStatus,
                    'toolId',
                    index,
                  ).map((tool) => (
                    <MenuItem
                      key={tool.toolstringItemId}
                      value={tool.toolstringItemId}
                    >
                      <Box display="flex" alignItems="center" width="100%">
                        <Box flex="1">{tool.name}</Box>
                        <Chip
                          label={formatToolstringToolStatusString(
                            tool.toolStatus,
                          )}
                        />
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                xs={1}
              >
                <Close
                  onClick={() => handleDeleteRow(index)}
                  style={{ cursor: 'pointer' }}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid
            item
            container
            justifyContent="flex-end"
            xs={12}
            component={Box}
            padding={3}
          >
            <Grid item>
              <Button
                onClick={handleAddRow}
                size="medium"
                variant="text"
                disabled={isAddButtonDisabled}
              >
                <AddIcon fontSize="small" />
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseModal}
      fullWidth
      maxWidth="md"
      classes={{
        paperScrollPaper: classes.root,
      }}
    >
      <SetAndRetrieveItemModalHeader
        title={task.get('title')}
        closeModal={toggleModal}
      />
      <DialogContent>
        <Grid container xs={12}>
          <Grid item container xs={12}>
            <Grid item xs={5}>
              <Typography>Activity</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Matching Set/Retrieved Tool</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <SelectingMenu
              rows={rows}
              setRows={setRows}
              saveValuePairs={saveValuePairs}
              plannedToolstringToolsWithStatus={
                plannedToolstringToolsWithStatus
              }
              activities={activities}
              deleteValuePairs={deleteValuePairs}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <SetAndRetrieveItemModalFooter
        title="test"
        closeModal={onCloseModal}
        saveValuePairs={saveValuePairs}
      />
    </Dialog>
  );
};

const styles = () => ({
  root: {
    minHeight: '50vh',
  },
});

export default withStyles(styles)(SetAndRetrieveItemModalContainer);
