import { Map } from 'immutable';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Grid, Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import ReadOnlyField from 'app/components/ReadOnlyField';
import TextFieldCustomSelect from 'app/components/TextFieldCustomSelect';
import CableDetails from 'features/projects/tool/components/CableDetails';

const TaskSimulationCableSelect = ({
  classes,
  onClick,
  disabled,
  selectedCable,
}) => (
  <Tooltip
    interactive
    classes={{ tooltip: classes.cableTooltip }}
    title={selectedCable ? <CableDetails cable={selectedCable} /> : 'No Cable'}
  >
    <Grid container>
      {disabled && (
        <ReadOnlyField
          label="Cable"
          margin="none"
          value={selectedCable?.get('name') || 'No cable'}
        />
      )}
      {!disabled && (
        <TextFieldCustomSelect
          required
          label="Cable"
          margin="none"
          onClick={onClick}
          value={selectedCable?.get('name') || 'No Cable'}
        />
      )}
    </Grid>
  </Tooltip>
);

const styles = (theme) => ({
  cableTooltip: {
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    maxWidth: 200,
  },
});

TaskSimulationCableSelect.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  selectedCable: PropTypes.instanceOf(Map),
};

export default compose(withStyles(styles))(TaskSimulationCableSelect);
