import { Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BasePage, LoadingOverlay } from 'altus-ui-components';

import useDataState from 'app/hooks/useDataState';
import { ProjectPermission } from 'app/app.constants';
import withProjectPermission from 'app/components/withProjectPermission';
import { ACTIONS as SIMULATION_ACTIONS } from 'features/projects/tasks/task/simulation/simulation.constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    basePageChildren: {
      paddingTop: theme.spacing(2.25),
    },
  }),
);

const ExecutionSimulationIndexContainer = () => {
  const classes = useStyles();
  const loadingState = useDataState([
    SIMULATION_ACTIONS.REQUEST_SIMULATIONS_FOR_TASK,
    SIMULATION_ACTIONS.RECEIVE_SIMULATIONS_FOR_TASK,
    SIMULATION_ACTIONS.LOAD_SIMULATIONS,
    SIMULATION_ACTIONS.LOAD_SIMULATION,
    SIMULATION_ACTIONS.RECEIVE_SIMULATION,
    SIMULATION_ACTIONS.RECEIVE_SIMULATION_RESULTS,
  ]);

  return (
    <BasePage
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs wrap="nowrap">
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
        >
          <LoadingOverlay dataState={loadingState} />
          {loadingState.received && (
            <Typography>
              <i>There are currently no planned simulations for this run.</i>
            </Typography>
          )}
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default withProjectPermission(ProjectPermission.EXECUTE)(
  ExecutionSimulationIndexContainer,
);
