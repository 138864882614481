import _isEmpty from 'lodash/isEmpty';
import { OrderedMap } from 'immutable';
import { EMPTY_MAP } from 'app/app.constants';
import { ACTIONS } from 'features/projects/tasks/task/simulation/simulation.constants';
import simulationMappers from 'features/projects/tasks/task/simulation/simulation.mappers';

const initialState = EMPTY_MAP;

const simulationResultReducer = (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.RECEIVE_SIMULATION_RESULTS: {
      if (error) return state;

      const updatedState = OrderedMap().asMutable();

      // Iterate over each simulation result in payload
      payload.forEach((resultSet) => {
        if (!_isEmpty(resultSet)) {
          const direction = resultSet.direction ?? 'null'; // Assuming direction can be null
          const simulationResults = resultSet.simulationResults || [];

          // Map simulation results to OrderedMap entries
          simulationResults.forEach((simulationResult) => {
            const measuredDepthKey =
              simulationResult.measuredDepth.value.toString();
            const mappedResult = simulationMappers.SimulationResult.from(
              simulationResult,
            ).set('simulationId', resultSet.simulationId);

            // Append to the existing entries for the direction or create new entry
            updatedState.update(direction, OrderedMap(), (existingResults) =>
              existingResults.set(measuredDepthKey, mappedResult),
            );
          });
        }
      });

      return updatedState.asImmutable();
    }

    case ACTIONS.DELETE_SIMULATION_RESULTS: {
      if (error) return state;

      return initialState;
    }

    default:
      return state;
  }
};

export default simulationResultReducer;
