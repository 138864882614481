import Agent from 'infrastructure/agent';
import { saveFile } from 'utils/app.util';

export const searchWellbores = (filter) =>
  Agent.get('/search/wellbore', filter);

export const getWellboreStatuses = () =>
  Agent.get('/search/filter/valueset/wellborestatus');

export const getWellboreTrajectory = (fieldId, wellId) =>
  Agent.get(`/field/${fieldId}/wellbore/${wellId}/trajectory`);

export const getWellbore = (fieldId, wellId) =>
  Agent.get(`/field/${fieldId}/wellbore/${wellId}`);

export const getWellboreEvents = (fieldId, wellId) =>
  Agent.get(`/field/${fieldId}/wellbore/${wellId}/events`);

export const uploadWellboreTrajectory = (fieldId, wellId, file, meta = {}) =>
  Agent.file.post(
    `/field/${fieldId}/wellbore/${wellId}/trajectory`,
    file,
    meta,
  );

export const getWellboreTrajectoryPreview = (
  fieldId,
  wellboreId,
  file,
  meta = {},
) =>
  Agent.file.post(
    `/field/${fieldId}/wellbore/${wellboreId}/trajectory/preview`,
    file,
    meta,
  );

export const downloadTrajectoryTemplateTxt = () =>
  Agent.file
    .get('/wellbore/trajectory/template/txt')
    .then(([file, filename]) => saveFile(file, filename));

export const downloadTrajectoryTemplateCsv = () =>
  Agent.file
    .get('/wellbore/trajectory/template/csv')
    .then(([file, filename]) => saveFile(file, filename));

export const downloadTrajectorySurveyTxt = (wellboreId) =>
  Agent.file
    .get(`/wellbore/${wellboreId}/trajectory/survey/txt`)
    .then(([file, filename]) => saveFile(file, filename));

export const downloadTrajectorySurveyCsv = (wellboreId) =>
  Agent.file
    .get(`/wellbore/${wellboreId}/trajectory/survey/csv`)
    .then(([file, filename]) => saveFile(file, filename));

export const getWellcomTrajectorySurvey = (wellId) =>
  Agent.get(`/wellbore/${wellId}/trajectory/wellcom`);

export const getWellboreRevisionWithElementsFromWellcom = (fieldId, wellId) =>
  Agent.get(`/field/${fieldId}/wellbore/${wellId}/elements/wellcom`);

export const getWellboreRevisionWithElements = (fieldId, wellId) =>
  Agent.get(`/field/${fieldId}/wellbore/${wellId}/elements`);

const wellboreService = {
  getWellbore,
  searchWellbores,
  getWellboreEvents,
  getWellboreStatuses,
  getWellboreTrajectory,
  uploadWellboreTrajectory,
  getWellcomTrajectorySurvey,
  downloadTrajectorySurveyTxt,
  downloadTrajectorySurveyCsv,
  getWellboreTrajectoryPreview,
  downloadTrajectoryTemplateTxt,
  downloadTrajectoryTemplateCsv,
  getWellboreRevisionWithElements,
  getWellboreRevisionWithElementsFromWellcom,
};

export default wellboreService;
