import { useEffect, useMemo } from 'react';
import { getIn, useFormikContext } from 'formik';
import { Fade, Box, Grid, Typography } from '@material-ui/core';

import { EMPTY_LIST } from 'app/app.constants';
import { ProjectDashboardFormFields as FormFields } from 'features/projects/dashboard/dashboard.constants';
import useProjectDashboardSimulation from 'features/projects/dashboard/hooks/useProjectDashboardSimulation';
import ProjectDashboardCurvesTable from 'features/projects/dashboard/components/ProjectDashboardCurvesTable';
import ProjectDashboardSimulationSelectField from 'features/projects/dashboard/components/ProjectDashboardSimulationSelectField';
import { getInitialSelectedProjectDashboardCurveRowIds } from 'features/projects/dashboard/components/ProjectDashboardCurvesTable';

const disabledFormFields = [FormFields.UNIT];

const ProjectDashboardSimulationCurvesTableContainer = ({
  projectId,
  setDataState,
  projectDashboardId,
  ...rest
}) => {
  const { values, setFieldValue } = useFormikContext();

  const selectedSimulationId = getIn(values, FormFields.SIMULATION_ID);

  const {
    dataState,
    simulations = EMPTY_LIST,
    projectDashboardSimulationCurves = EMPTY_LIST,
  } = useProjectDashboardSimulation(
    projectId,
    projectDashboardId,
    selectedSimulationId,
  );

  useEffect(() => {
    setDataState(dataState);
  }, [setDataState, dataState]);

  useEffect(() => {
    if (!projectDashboardSimulationCurves.size) return;

    setFieldValue(
      FormFields.PROJECT_DASHBOARD_SIMULATION_CURVES,
      projectDashboardSimulationCurves.toJS(),
    );

    return () => {
      setFieldValue(
        FormFields.PROJECT_DASHBOARD_SIMULATION_CURVES,
        EMPTY_LIST.toJS(),
      );
    };
  }, [setFieldValue, projectDashboardSimulationCurves]);

  const initialState = useMemo(
    () => ({
      selectedRowIds: getInitialSelectedProjectDashboardCurveRowIds(
        projectDashboardSimulationCurves,
      ),
    }),
    [projectDashboardSimulationCurves],
  );

  return (
    <Grid container>
      <Grid container component={Box} paddingTop={2} paddingBottom={1}>
        <Typography variant="h6">Simulation</Typography>
      </Grid>
      <Grid container component={Box} paddingBottom={1.5}>
        <Grid item xs={3}>
          <ProjectDashboardSimulationSelectField simulations={simulations} />
        </Grid>
      </Grid>
      <Fade in={!!selectedSimulationId}>
        <Grid container>
          <ProjectDashboardCurvesTable
            initialState={initialState}
            disabledFields={disabledFormFields}
            items={projectDashboardSimulationCurves}
            fieldName={FormFields.PROJECT_DASHBOARD_SIMULATION_CURVES}
            {...rest}
          />
        </Grid>
      </Fade>
    </Grid>
  );
};

export default ProjectDashboardSimulationCurvesTableContainer;
