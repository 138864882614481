import { compose } from 'redux';
import { useEffect, useState } from 'react';
import { lighten } from '@material-ui/core/styles';
import withStyles from '@material-ui/styles/withStyles';
import { connect, useSelector } from 'react-redux';
import { Grid, Typography, Tabs, Tab, Divider } from '@material-ui/core';

import { BasePage } from 'altus-ui-components';
import { useHeader } from 'app/hooks/useHeader';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import { ExifOrientation } from 'features/projects/tool/tool.constants';

import { getSurfaceEquipmentByIdFromState } from 'features/equipment/equipment.selectors';

import ToolImageContainer from 'features/projects/tool/components/ToolImageContainer';
import {
  SURFACE_EQUIPMENT_DETAILS_ACTIONS,
  EQUIPMENT_DETAILS_ACTIVE_TABS,
} from 'features/equipment/details/equipmentDetails.constants';
import { isImmutable } from 'immutable';
import {
  onLoadSurfaceEquipmentDetail,
  onUnloadSurfaceEquipment,
} from 'features/equipment/details/equipmentDetails.actions';
import EquipmentAssetsContainer from '../assets/EquipmentAssetsContainer';
import { EquipmentType } from '../equipment.constants';
import { ThirdPartyLabel } from 'features/projects/tool/tool.constants';

const SurfaceEquipmentDetails = ({
  equipmentId,
  classes,
  dataState,
  dispatchOnLoad,
  dispatchOnUnload,
}) => {
  const [activeTab, setActiveTab] = useState(
    EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS,
  );

  const headerSubtitleHandler = (activeTab) => {
    switch (activeTab) {
      case EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS:
        return 'Details';
      case EQUIPMENT_DETAILS_ACTIVE_TABS.ASSETS:
        return 'Assets';
      default:
        return 'Details';
    }
  };

  useHeader({
    subTitle: headerSubtitleHandler(activeTab),
    logoNavigation: '/',
  });

  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  const tool = useSelector(getSurfaceEquipmentByIdFromState);

  const equipmentImage = (tool) =>
    isImmutable(tool)
      ? tool?.get('equipmentImage')
      : tool.original?.equipmentImage;

  const equipmentGroup = (tool) =>
    isImmutable(tool)
      ? tool?.get('equipmentGroup')
      : tool.original?.equipmentGroup;

  const displayDataText = (data) => {
    if (dataState.isLoading()) {
      return 'Loading...';
    }

    if (!data) {
      return 'No Data';
    } else {
      return data;
    }
  };

  useEffect(() => {
    dispatchOnLoad(equipmentId);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload, equipmentId]);

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabs}
        className={classes.tabsContainer}
      >
        <Tab
          label="Details"
          value={EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS}
          className={classes.tab}
        />
        <Tab
          label="Assets"
          value={EQUIPMENT_DETAILS_ACTIVE_TABS.ASSETS}
          className={classes.tab}
        />
      </Tabs>
      <Divider className={classes.divider} />
      {activeTab === EQUIPMENT_DETAILS_ACTIVE_TABS.DETAILS && (
        <Grid item container xs className={classes.container}>
          <Grid item container className={classes.topContainer}>
            <Grid item container xs={7} className={classes.topText}>
              <Typography variant="h6">{tool.get('m3ItemNumber')}</Typography>
              <Typography variant="h6">
                {tool.get('description') ?? 'No description'}
              </Typography>
            </Grid>
            <Grid item container xs={5} className={classes.toolContainer}>
              <ToolImageContainer
                onHoverDarken
                title={
                  tool.size
                    ? Object.keys(equipmentImage(tool))?.length !== 0
                      ? equipmentImage(tool)?.get('name')
                      : ''
                    : ''
                }
                toolName={
                  tool.size
                    ? Object.keys(equipmentImage(tool))?.length !== 0
                      ? equipmentImage(tool)?.get('name')
                      : ''
                    : ''
                }
                toolImageUrl={
                  tool.size
                    ? Object.keys(equipmentImage(tool))?.length !== 0
                      ? equipmentImage(tool)?.get('imageUrl')
                      : ''
                    : ''
                }
                orientation={ExifOrientation.DEGREES_90_MIRRORED}
                style={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                }}
              />
            </Grid>
          </Grid>
          <Grid item container className={classes.bottomContainer}>
            {
              <>
                <Grid item container className={classes.bottomText}>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Name:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(tool.get('name'))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Group:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(tool.get('name'))}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Length:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool
                          ? displayDataText(
                              `${tool.getIn([
                                'length',
                                'roundedValue',
                              ])} ${tool.getIn(['length', 'unit'])}`,
                            )
                          : 'No Data'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Height:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool
                          ? displayDataText(
                              `${tool.getIn([
                                'height',
                                'roundedValue',
                              ])} ${tool.getIn(['height', 'unit'])}`,
                            )
                          : 'No Data'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Width:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool
                          ? displayDataText(
                              `${tool.getIn([
                                'width',
                                'roundedValue',
                              ])} ${tool.getIn(['width', 'unit'])}`,
                            )
                          : 'No Data'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Weight:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {tool
                          ? displayDataText(
                              `${tool.getIn([
                                'weight',
                                'roundedValue',
                              ])} ${tool.getIn(['weight', 'unit'])}`,
                            )
                          : 'No Data'}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* TODO ADD WHEN EDIT TOOL IS AVAILABLE */}
                  {/* <Typography variant="h5">Last modification:</Typography> */}
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Description:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(tool.get('description') ?? 'No Data')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.bottomTextLine}
                  >
                    <Grid item container xs={6}>
                      <Typography variant="h5">Is data verified:</Typography>
                    </Grid>
                    <Grid item container xs={6}>
                      <Typography variant="h5">
                        {displayDataText(
                          `${tool.get('isVerified') ? 'Yes' : 'No'}`,
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  {tool.get('isVerified') && (
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Data verified:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {tool.get('dataVerified')
                            ? displayDataText(`${tool.get('dataVerified')}`)
                            : displayDataText(null)}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {tool && tool.get('m3ItemNumber') === ThirdPartyLabel ? null : (
                  <Grid item container className={classes.bottomText}>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Group Description:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            equipmentGroup(tool)?.get('name') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Item number:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3ItemNumber') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Item group:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(tool.get('m3ITGR') ?? 'No Data')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Item type:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(tool.get('m3ItemType') ?? 'No Data')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Business area:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3BusinessArea') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Item status:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3ItemStatus') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          Item status description:
                        </Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3ItemStatusDescription') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Lot control method</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3LotControlMethod') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Measurement 1:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3Measurement1') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Measurement 2:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3Measurement2') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Measurement 3:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3Measurement3') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Entry date:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3EntryDate') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.bottomTextLine}
                    >
                      <Grid item container xs={6}>
                        <Typography variant="h5">Last edit date:</Typography>
                      </Grid>
                      <Grid item container xs={6}>
                        <Typography variant="h5">
                          {displayDataText(
                            tool.get('m3ChangeDate') ?? 'No Data',
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            }
          </Grid>
        </Grid>
      )}
      {activeTab === EQUIPMENT_DETAILS_ACTIVE_TABS.ASSETS && (
        <Grid item container xs>
          <EquipmentAssetsContainer
            equipmentId={equipmentId}
            equipmentType={EquipmentType.SURFACE}
          />
        </Grid>
      )}
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    SURFACE_EQUIPMENT_DETAILS_ACTIONS.PAGE_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoadSurfaceEquipmentDetail,
  dispatchOnUnload: onUnloadSurfaceEquipment,
};

const styles = (theme) => ({
  basePageChildren: {
    flexDirection: 'column',
    padding: 0,
  },
  container: {
    flexDirection: 'column',
    overflow: 'auto',
    padding: theme.spacing(2.25),
  },
  topContainer: {
    flexDirection: 'row',
    height: '10%',
  },
  topText: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& > img': {
      objectFit: 'contain',
    },
    maxHeight: '100%',
    paddingLeft: theme.spacing(3),
  },
  bottomContainer: {
    dispay: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '90%',
  },
  bottomText: {
    width: '48%',
    height: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    padding: theme.spacing(3),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  bottomTextLine: {
    height: 'fit-content',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  editBtn: {
    position: 'absolute',
    right: theme.spacing(5),
    bottom: theme.spacing(3),
    width: '200px',
    height: '50px',
  },
  tab: {
    backgroundColor: 'transparent !important',
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(SurfaceEquipmentDetails);
