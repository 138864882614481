import { Map } from 'immutable';

const ProjectMobilisationTasksMapper = {
  from: ({ ...projectMobilisationTasks }) =>
    Map({ ...projectMobilisationTasks }),
};

function groupByStringTest(data) {
  const grouped = data.reduce((acc, curr) => {
    const stringTestKey = curr.stringTest.stringTestId;

    if (!acc[stringTestKey]) {
      acc[stringTestKey] = {
        ...curr.stringTest,
        assets: [],
      };
    }

    acc[stringTestKey].assets.push(curr.asset);

    return acc;
  }, {});

  return Object.values(grouped);
}

export const ProjectMobilisationMappers = {
  projectMobilisationTasksMapper: ProjectMobilisationTasksMapper,
  stringTestAssetsGroupMapper: groupByStringTest,
};
