import { useRef, useState, useCallback, useEffect } from 'react';

import moment from 'moment';
import Highcharts from 'highcharts';
import gantt from 'highcharts/highcharts-gantt';
import HighchartsReact from 'highcharts-react-official';

import highchartsTheme from 'layout/highcharts.theme';
import winchHighchartsTheme from 'features/projects/dashboard/components/dashboards/winch/winch.highcharts.theme';
import { EMPTY_LIST } from 'app/app.constants';
import { checkIfNotASubActivity } from 'features/projects/tasks/task/activities/activityUtils';
import themes, { defaultThemeType } from 'layout/themes';

const theme = themes[defaultThemeType];

const { fontColor, defaultFontSize } = winchHighchartsTheme.style;

const TaskChart = ({
  tasks,
  activities,
  showActivities = true,
  showDefaultButtons = true,
  showNavigatorChart = true,
  chartBackgroundColor = theme.altus.background.dashboard,
}) => {
  const chartComponent = useRef(null);

  const updateSeriesData = useCallback(
    (tasks, activities) => {
      const chart = chartComponent.current.chart;

      const seriesToAdd = tasks.map((task) => {
        // Read activities from activities prop, since task.activites have their plannedStartTime and plannedEndTime as null
        const filteredTaskActivities = activities
          .filter(
            (x) =>
              x.get('taskId') === task.get('taskId') &&
              checkIfNotASubActivity(x),
          )
          .toList()
          .sortBy((activity) => activity.get('sequence'))
          .map((activity, index) => activity.set('sequence', index + 1));

        const taskStart = task.get('plannedStartTime')
          ? moment(task.get('plannedStartTime')).toDate().getTime()
          : null;
        const taskEnd = task.get('plannedEndTime')
          ? moment(task.get('plannedEndTime')).toDate().getTime()
          : null;

        const taskToAdd = showActivities
          ? {
              name: task.get('title'),
              id: task.get('taskId').toString(),
            }
          : {
              name: task.get('title'),
              id: task.get('taskId').toString(),
              start: taskStart,
              end: taskEnd,
              showCheckbox: false,
            };

        const tasksAndActivities = showActivities
          ? filteredTaskActivities.map((activity) => {
              const activityName = activities
                .find((x) => x.get('id') === activity.get('id'))
                .get('name');

              const dependencySearch = () => {
                if (activity.get('sequence') >= 2) {
                  return filteredTaskActivities
                    .findLast(
                      (x) => x.get('sequence') < activity.get('sequence'),
                    )
                    .get('id')
                    .toString();
                } else return null;
              };

              const activityToAdd = {
                name: activityName,
                id: activity.get('id').toString(),
                parent: task.get('id').toString(),
                start: activity.get('plannedStartTime')
                  ? moment(activity.get('plannedStartTime'))?.toDate().getTime()
                  : null,
                end: activity.get('plannedEndTime')
                  ? moment(activity.get('plannedEndTime'))?.toDate().getTime()
                  : null,
                dependency: dependencySearch(),
                showCheckbox: false,
              };

              return activityToAdd;
            })
          : EMPTY_LIST;

        const seriesToAdd = {
          id: task.get('id'),
          name: task.get('title'),
          data: tasksAndActivities.insert(0, taskToAdd).toJS(),
        };

        return seriesToAdd;
      });

      seriesToAdd.forEach((serie) => {
        const isSeriesAlreadyIncluded = chart.series
          .map((x) => x.userOptions.id)
          .includes(serie.id);
        if (!isSeriesAlreadyIncluded) {
          chart.addSeries(serie, false);
        }
      });

      seriesToAdd.forEach((newSeries) => {
        const existingSeries = chart.series.find(
          (s) => s.userOptions.id === newSeries.id,
        );
        if (existingSeries) {
          existingSeries.update(newSeries, false);
        } else {
          chart.addSeries(newSeries, false);
        }
      });

      chart.redraw();
      chart.reflow();
    },
    [showActivities],
  );

  useEffect(() => {
    if (tasks && activities) {
      updateSeriesData(
        tasks,
        activities?.filter(
          (x) =>
            x.get('parentActivityId') === null ||
            x.get('parentActivityId') === 0,
        ),
      );
    }
  }, [tasks, updateSeriesData, activities]);

  const [chartOptions] = useState(
    Highcharts.merge(false, highchartsTheme, {
      chart: {
        backgroundColor: chartBackgroundColor,
      },
      rangeSelector: {
        inputEnabled: true,
        enabled: showDefaultButtons,
        selected: 2,
        labels: {
          style: {
            color: fontColor,
            fontSize: defaultFontSize,
          },
        },
        buttons: showDefaultButtons
          ? [
              {
                type: 'minute',
                count: 1,
                text: '1mn',
                title: 'View 1 minute',
              },
              {
                type: 'month',
                count: 1,
                text: '1m',
                title: 'View 1 month',
              },
              {
                type: 'month',
                count: 3,
                text: '3m',
                title: 'View 3 months',
              },
              {
                type: 'month',
                count: 6,
                text: '6m',
                title: 'View 6 months',
              },
              {
                type: 'ytd',
                count: 2,
                text: 'YTD',
                title: 'View year to date',
              },
              {
                type: 'year',
                count: 1,
                text: '1y',
                title: 'View 1 year',
              },
              {
                type: 'all',
                text: 'All',
                title: 'View all',
              },
            ]
          : [],
      },
      scrollbar: {
        enabled: true,
      },
      navigator: {
        labels: {
          style: {
            color: fontColor,
            fontSize: defaultFontSize,
          },
        },
        enabled: showNavigatorChart,
        liveRedraw: true,
        series: {
          type: 'gantt',
          pointPlacement: 0.5,
          pointPadding: 0.25,
          accessibility: {
            enabled: false,
          },
        },
      },
      xAxis: {
        plotBands: {
          color: fontColor,
        },
        showEmpty: false,
        tickColor: fontColor,
        labels: {
          style: {
            color: fontColor,
            fontSize: defaultFontSize,
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            color: fontColor,
            fontSize: defaultFontSize,
          },
        },
      },
      accessibility: {
        keyboardNavigation: {
          seriesNavigation: {
            mode: 'serialize',
          },
        },
      },
    }),
  );

  return (
    <HighchartsReact
      ref={chartComponent}
      constructorType={'ganttChart'}
      highcharts={gantt}
      options={chartOptions}
    />
  );
};

export default TaskChart;
