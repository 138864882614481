import SearchIcon from '@material-ui/icons/Search';

import { NoContentBasePage } from 'altus-ui-components';

const NoRoleSelectedPage = () => (
  <NoContentBasePage
    header="Select a Role"
    description="Please choose a Role or create a new one from the left navigation menu"
    Icon={SearchIcon}
  />
);

export default NoRoleSelectedPage;
