import wellboreFileService from 'services/wellboreFile.service';
import { NOTIFICATION_VARIANTS, MODAL } from 'app/app.constants';
import { getAllFileCategories, toggleModal } from 'app/app.actions';
import { WELLBORE_FILE_ACTIONS } from 'features/wells/wellboreFiles.constants';

export const onLoad = (wellboreId, fieldId) => (dispatch) => {
  dispatch({
    type: WELLBORE_FILE_ACTIONS.WELLBORE_FILES_PAGE_LOADED,
    payload: () =>
      Promise.all([
        dispatch(getAllFileCategories()),
        wellboreFileService
          .getAllWellboreFiles(wellboreId, fieldId)
          .then((wellboreFiles) =>
            dispatch(receiveWellboreFiles(wellboreFiles)),
          ),
      ]),
  });
};

export const deleteFile =
  (wellboreId, wellboreFileId, fieldId) => (dispatch) => {
    dispatch({
      wellboreFileId,
      type: WELLBORE_FILE_ACTIONS.DELETE_WELLBORE_FILE,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: 'Deleting...',
        [NOTIFICATION_VARIANTS.SUCCESS]: 'The file was successfully deleted',
      },
      confirmationDialog: {
        title: 'Delete document',
        confirmButtonText: 'Delete',
        description: 'Are you sure you want to delete this document?',
      },
      payload: () =>
        wellboreFileService.deleteWellboreFile(
          wellboreId,
          wellboreFileId,
          fieldId,
        ),
    });
  };

export const downloadFile =
  (wellboreId, wellboreFileId, wellboreFile, fieldId) => (dispatch) => {
    const payload = wellboreFileService.downloadWellboreFile(
      wellboreId,
      wellboreFileId,
      fieldId,
    );

    dispatch({
      wellboreFileId,
      type: WELLBORE_FILE_ACTIONS.DOWNLOAD_WELLBORE_FILE,
      notification: {
        [NOTIFICATION_VARIANTS.INFO]: `Downloading ${wellboreFile.get(
          'name',
        )}...`,
      },
      payload,
    });

    return payload;
  };

// TODO: Add error handling, for example if only 1 file upload fails
export const uploadFiles =
  (wellboreId, fieldId) => (dispatch) => (uploadDocumentFormValues) => {
    const { files: wellboreFiles } = uploadDocumentFormValues.toJS();

    const payload = Promise.all(
      wellboreFiles.map(({ file, meta }) =>
        wellboreFileService
          .uploadWellboreFile(wellboreId, file, meta, fieldId)
          .then((wellboreFile) => dispatch(receiveWellboreFile(wellboreFile))),
      ),
    ).then((response) => {
      dispatch(toggleModal({ modalId: MODAL.UPLOAD_FILE }));
      return response;
    });

    dispatch({
      type: WELLBORE_FILE_ACTIONS.UPLOAD_WELLBORE_FILE,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: `The ${
          wellboreFiles.length > 1 ? 'wellboreFiles' : 'wellboreFile'
        } ${wellboreFiles.length > 1 ? 'were' : 'was'} successfully uploaded`,
      },
      payload,
    });

    return payload;
  };

export const uploadFileModalOnUnload = () => (dispatch) =>
  dispatch({ type: WELLBORE_FILE_ACTIONS.UPLOAD_FILE_MODAL_UNLOADED });

export const updateFile =
  (wellboreId, wellboreFileId, wellborefile, fieldId) => (dispatch) => {
    const payload = wellboreFileService
      .updateWellboreFile(wellboreId, wellboreFileId, wellborefile, fieldId)
      .then((wellborefile) => receiveWellboreFile(wellborefile));

    dispatch({
      payload,
      wellboreFileId,
      type: WELLBORE_FILE_ACTIONS.UPDATE_WELLBORE_FILE,
      notification: {
        [NOTIFICATION_VARIANTS.SUCCESS]: 'File was successfully updated',
      },
    });

    return payload;
  };

export const receiveWellboreFiles = (wellboreFiles) => ({
  payload: wellboreFiles,
  type: WELLBORE_FILE_ACTIONS.RECEIVE_WELLBORE_FILES,
});

export const receiveWellboreFile = (wellboreFiles) => ({
  payload: wellboreFiles,
  type: WELLBORE_FILE_ACTIONS.RECEIVE_WELLBORE_FILE,
});
