import { fromJS } from 'immutable';
import { createSelector } from 'utils/app.util';

export const getAllAvailableUsersFromState = createSelector(
  (state) => state.getIn(['projects', 'team', 'allAvailableUsers']),
  (availableUsers) => availableUsers,
);

export const getProjectMembersKeyedByUserIdFromState = createSelector(
  (state) => state.getIn(['projects', 'team', 'members']),
  (projectMembers) => {
    return projectMembers
      .toOrderedMap()
      .mapKeys((_, projectMember) => projectMember.get('userId').toString());
  },
);

export const getProjectMembersFromState = createSelector(
  (state) => state.getIn(['projects', 'team', 'members']),
  (projectMembers) =>
    projectMembers
      .map((member) => {
        return fromJS(member.get('user'));
      })
      .toList(),
);

export const getFieldCrewKeyedByUserIdFromState = createSelector(
  (state) => state.getIn(['projects', 'team', 'fieldCrews']),
  (projectMembers) => {
    return projectMembers
      .toOrderedMap()
      .mapKeys((_, projectMember) => projectMember.get('userId').toString());
  },
);

export const getFieldCrewFromState = createSelector(
  (state) => state.getIn(['projects', 'team', 'fieldCrews']),
  (projectMembers) =>
    projectMembers
      .map((member) => {
        return fromJS(member.get('user'));
      })
      .toList(),
);

export const getAllAvailableFieldCrewUsersFromState = createSelector(
  (state) => state.getIn(['projects', 'team', 'allAvailableFieldUsers']),
  (availableUsers) => availableUsers,
);

export const getProjectQualificationsFromState = createSelector(
  (state) => state.getIn(['projects', 'team', 'qualifications']),
  (qualifications) => qualifications,
);

export const getProjectCrewsFromState = createSelector(
  (state) => state.getIn(['projects', 'team', 'crews']),
  (crews) => crews,
);

export const getTeamProjectRolesFromState = createSelector(
  (state) => state.getIn(['projects', 'team', 'teamProjectRoles']),
  (teamProjectRoles) => teamProjectRoles,
);
