import { Grid, Typography } from '@material-ui/core';
import PaperListItem from 'app/components/PaperListItem';

const ActivityListHeader = () => {
  return (
    <PaperListItem isHeader isStickyHeader>
      {/* <Grid container spacing={1}> */}
      {/* <Grid item xs={1} /> */}
      <Grid item container xs={12}>
        <Grid item container justifyContent="center" xs={3}>
          <Typography variant="subtitle2">Activity</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2">Started</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">Duration</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="subtitle2">Depth</Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>
      {/* </Grid> */}
    </PaperListItem>
  );
};

export default ActivityListHeader;
