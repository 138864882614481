export const ACTIONS = {
  REQUEST_CREATE_PROJECT_WELLBORE_SECTION:
    'REQUEST_CREATE_PROJECT_WELLBORE_SECTION',
  REQUEST_UPDATE_PROJECT_WELLBORE_SECTION:
    'REQUEST_UPDATE_PROJECT_WELLBORE_SECTION',
  REQUEST_DELETE_PROJECT_WELLBORE_SECTION:
    'REQUEST_DELETE_PROJECT_WELLBORE_SECTION',
  REQUEST_PROJECT_WELLBORE_SECTIONS: 'REQUEST_PROJECT_WELLBORE_SECTIONS',
  REQUEST_CREATE_PROJECT_WELLBORE_SECTION_NIPPLE:
    'REQUEST_CREATE_PROJECT_WELLBORE_SECTION_NIPPLE',
  REQUEST_UPDATE_PROJECT_WELLBORE_SECTION_NIPPLE:
    'REQUEST_UPDATE_PROJECT_WELLBORE_SECTION_NIPPLE',
  REQUEST_DELETE_PROJECT_WELLBORE_SECTION_NIPPLE:
    'REQUEST_DELETE_PROJECT_WELLBORE_SECTION_NIPPLE',

  UPDATE_PROJECT_WELLBORE_SECTIONS_CONFIRMATION_DIALOG:
    'UPDATE_PROJECT_WELLBORE_SECTIONS_CONFIRMATION_DIALOG',

  SYNC_PROJECT_WELLBORE_SECTIONS_WITH_LATEST:
    'SYNC_PROJECT_WELLBORE_SECTIONS_WITH_LATEST',
 };
