import Agent from 'infrastructure/agent';

const getProjectMobilisationTasks = (projectId) =>
  Agent.get(`/mobilisation/${projectId}/tasks`);

const getProjectMobilisationToolstrings = (projectId) =>
  Agent.get(`/mobilisation/${projectId}/plannedtoolstrings`);

const getProjectMobilisationToolstringForTask = (projectId, taskId) =>
  Agent.get(`/mobilisation/${projectId}/${taskId}/mobilisationtoolstrings`);

const createStringTestReport = (projectId, taskId, stringTestReport) =>
  Agent.post(
    `/mobilisation/${projectId}/${taskId}/createstringtestreport`,
    stringTestReport,
  );

const getStringTestReportsForTask = (projectId, taskId) =>
  Agent.get(`/mobilisation/${projectId}/${taskId}/stringtestreports`);

const getStringTestReportEventById = (projectId, taskId, eventId) =>
  Agent.get(
    `/mobilisation/${projectId}/${taskId}/${eventId}/stringtestreportevent`,
  );

const createSecondaryStringTestReport = (projectId, taskId, toolstringId) =>
  Agent.post(
    `/mobilisation/${projectId}/${taskId}/${toolstringId}/secondarycreatestringtestreport`,
  );

const finishStringTestReport = (
  projectId,
  taskId,
  stringTestReportId,
  stringTestReport,
) =>
  Agent.post(
    `/mobilisation/${projectId}/${taskId}/${stringTestReportId}/finishstringtestreport`,
    stringTestReport,
  );

const projectMobilisationService = {
  createStringTestReport,
  finishStringTestReport,
  getProjectMobilisationTasks,
  getStringTestReportsForTask,
  getStringTestReportEventById,
  createSecondaryStringTestReport,
  getProjectMobilisationToolstrings,
  getProjectMobilisationToolstringForTask,
};

export default projectMobilisationService;
