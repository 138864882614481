import { connect } from 'react-redux';
import { PureComponent } from 'react';
import { BrowserView } from 'react-device-detect';
import toJSComponent from 'with-immutable-props-to-js';
import withStyles from '@material-ui/styles/withStyles';
import { Grid, Fab, Typography } from '@material-ui/core';

import logo from 'assets/altus_logo.svg';
import background from 'assets/login.jpg';
import stripes from 'assets/login_pattern.svg';
import { autoLogin } from 'app/app.actions';
import uaParser from 'infrastructure/uaParser';
import { getActionDataStateFromState } from 'app/app.selectors';
import LoadingDimmer from 'app/components/LoadingDimmer';
import NotSupported from 'features/login/components/NotSupported';
import { login, onLoad, onUnload } from 'features/login/login.actions';
import { INTERNET_EXPLORER, START_LOGIN } from 'features/login/login.constants';
import NavigationLink from 'app/components/NavigationLink';
import { toPrivacyPolicy } from 'utils/route.util';

class Login extends PureComponent {
  componentDidMount() {
    this.props.dispatchOnLoad();
  }

  componentWillUnmount() {
    this.props.dispatchOnUnload();
  }

  render() {
    const { classes, dataState } = this.props;
    const browser = uaParser.getBrowser();
    const loading = dataState.isLoading();

    return (
      <>
        {loading && (
          <LoadingDimmer
            text="Signing in"
            classes={{
              dimmer: classes.dimmer,
            }}
          />
        )}
        <img className={classes.logo} height="40" src={logo} alt="Altus" />
        <Grid container className={classes.login} spacing={0}>
          <Grid item xs={12} align="center" className={classes.firstRow}>
            {!loading && (
              <>
                <Grid item xs={12} className={classes.marginBottom}>
                  <Typography variant="h3" className={classes.title}>
                    Digital Well Intervention
                  </Typography>
                </Grid>
                {browser.name === INTERNET_EXPLORER ? (
                  <NotSupported />
                ) : (
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={2}
                    lg={2}
                    xl={2}
                    className={classes.marginTop}
                  >
                    <Fab
                      size="large"
                      color="secondary"
                      variant="extended"
                      onClick={() =>
                        this.props.dispatchLogin(this.props.dispatchAutologin)
                      }
                      className={classes.loginButton}
                    >
                      Login
                    </Fab>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <BrowserView>
            <Grid item xs={12}>
              <Grid container spacing={5} className={classes.lastRow}>
                <Grid item xs={12} align="center">
                  <NavigationLink
                    className={classes.privacyPolicy}
                    to={toPrivacyPolicy()}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <Typography variant="body1">Privacy Policy</Typography>
                  </NavigationLink>
                </Grid>
              </Grid>
            </Grid>
          </BrowserView>
        </Grid>
      </>
    );
  }
}

const styles = (theme) => ({
  title: {
    color: theme.palette.common.white,
    textShadow: '2px 2px rgba(0,0,0,0.2)',
  },
  login: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    '&:before': {
      content: 'no-open-quote',
      display: 'block',
      backgroundImage: `url("${background}")`,
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '100%',
      width: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      opacity: 0.7,
      zIndex: -1,
    },
    '&:after': {
      content: 'no-open-quote',
      display: 'block',
      backgroundImage: `url("${stripes}")`,
      backgroundPosition: 'right bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      opacity: 1,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'fixed',
      zIndex: -1,
    },
  },
  logo: {
    position: 'fixed',
    top: 40,
    left: 40,
    zIndex: 10,
  },
  pattern: {
    position: 'fixed',
    right: 0,
    top: 20,
    zIndex: 10,
    opacity: 0.2,
    pointerEvents: 'none',
  },
  loginButton: {
    zIndex: 20,
    opacity: 0.9,
    '&:hover': {
      opacity: 0.85,
    },
  },
  marginTop: { marginTop: 30, display: 'flex', justifyContent: 'center' },
  firstRow: {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  lastRow: { position: 'absolute', bottom: 50 },
  dimmer: {
    background: 'none',
  },
  privacyPolicy: {
    color: theme.palette.common.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default connect(
  (state) => ({
    dataState: getActionDataStateFromState(state, START_LOGIN),
  }),
  {
    dispatchLogin: login,
    dispatchOnLoad: onLoad,
    dispatchOnUnload: onUnload,
    dispatchAutologin: autoLogin,
  },
)(toJSComponent(withStyles(styles)(Login)));
