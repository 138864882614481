import { Field } from 'formik';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Collection } from 'immutable';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/GetApp';
import { useMemo, memo, useCallback } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, InputAdornment } from '@material-ui/core';

import { Table, TableRowActionsCell } from 'altus-ui-components';

import { formatDate } from 'utils/format.util';
import { required } from 'utils/validation.util';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { EMPTY_MAP, Format, ProjectPermission } from 'app/app.constants';
import EditableTableRowFormik from 'app/components/form/formik/EditableTableRowFormik';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';

const FieldDocumentsTable = ({
  updateFile,
  deleteFile,
  downloadFile,
  noItemsMessage,
  documents = EMPTY_MAP,
  hasUploadDocumentPermission,
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 4,
        Header: 'Name',
        accessor: (document) => document.get('name', null),
        Cell: ({ row, value }) => {
          if (!hasUploadDocumentPermission) return value;

          return (
            <Field
              name="name"
              validate={required}
              component={TableRowTextFieldFormik}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {row.original.get('extension')}
                  </InputAdornment>
                ),
              }}
            />
          );
        },
      },
      {
        xs: 6,
        Header: 'Uploaded',
        accessor: (document) =>
          formatDate(document.get('created'), Format.time),
      },
      {
        id: 'actions',
        disableSortBy: true,
        Header: <TableRowActionsCell minItems={2} />,
        Footer: <TableRowActionsCell minItems={2} />,
        Cell: ({ row }) => (
          <TableRowActionsCell minItems={2}>
            <HasProjectPermission
              permissions={ProjectPermission.DELETE_ALL_DOCUMENTS}
            >
              <IconButton
                title="Delete"
                onClick={() => deleteFile(row.original.get('fieldFileId'))}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </HasProjectPermission>
            <HasProjectPermission
              permissions={ProjectPermission.DOWNLOAD_DOCUMENTS}
            >
              <IconButton
                title="Download"
                onClick={() =>
                  downloadFile(row.original.get('fieldFileId'), row.original)
                }
              >
                <SaveIcon fontSize="small" />
              </IconButton>
            </HasProjectPermission>
            <IconButton
              component="a"
              target="_blank"
              href={row.original.get('editUrl')}
              disabled={!row.original.get('editUrl')}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </TableRowActionsCell>
        ),
      },
    ],
    [deleteFile, downloadFile, hasUploadDocumentPermission],
  );

  const renderTableRowComponent = useCallback(
    (props) => <EditableTableRowFormik {...props} onSubmit={updateFile} />,
    [updateFile],
  );

  return (
    <Table
      columns={columns}
      items={documents}
      useGlobalFilter={false}
      noItemsMessage={noItemsMessage}
      TableRowComponent={renderTableRowComponent}
    />
  );
};

FieldDocumentsTable.propTypes = {
  documents: PropTypes.instanceOf(Collection),
};

export default compose(memo)(FieldDocumentsTable);
