import { AutocompleteTextFieldAdapter } from 'app/components/form/formik/TableRowAutocompleteTextFieldFormik/components/AutocompleteTextFieldAdapter';
import TableRowTextFieldFormik from 'app/components/form/formik/TableRowTextFieldFormik';

const TableRowAutocompleteTextFieldFormik = (props) => {
  const { keepChangeAfterOnBlur = false, ...rest } = props;

  return (
    <TableRowTextFieldFormik
      keepChangeAfterOnBlur={keepChangeAfterOnBlur}
      TextFieldComponent={AutocompleteTextFieldAdapter}
      {...rest}
    />
  );
};
export default TableRowAutocompleteTextFieldFormik;
