import { Field } from 'formik';
import { Grid } from '@material-ui/core';

import { required } from 'utils/validation.util';
import QuantityTextFieldFormik from 'app/components/form/formik/QuantityTextFieldFormik';
import PowerLawLiquidFormSection from 'app/components/FluidsFormSection/FluidForm/FluidSurfaceProperties/LiquidFormSection/PowerLawLiquidFormSection';
import NewtonianLiquidFormSection from 'app/components/FluidsFormSection/FluidForm/FluidSurfaceProperties/LiquidFormSection/NewtonianLiquidFormSection';
import BinghamPlasticLiquidFormSection from 'app/components/FluidsFormSection/FluidForm/FluidSurfaceProperties/LiquidFormSection/BinghamPlasticLiquidFormSection';
import HerschelBulkleyLiquidFormSection from 'app/components/FluidsFormSection/FluidForm/FluidSurfaceProperties/LiquidFormSection/HerschelBulkleyLiquidFormSection';
import {
  FluidFormFields as FormFields,
  RheologyModel,
} from 'utils/fluids/constants.utils';

const LiquidFormSection = ({ disabled, rheologyModel }) => {
  const formSections = {
    [RheologyModel.POWER_LAW]: PowerLawLiquidFormSection,
    [RheologyModel.NEWTONIAN]: NewtonianLiquidFormSection,
    [RheologyModel.BINGHAM_PLASTIC]: BinghamPlasticLiquidFormSection,
    [RheologyModel.HERSCHEL_BULKLEY]: HerschelBulkleyLiquidFormSection,
  };

  return (
    <>
      <Field
        required
        type="number"
        label="Density"
        destroyOnUnmount
        disabled={disabled}
        validate={required}
        name={FormFields.LIQUID_DENSITY}
        component={QuantityTextFieldFormik}
        useRoundedValue={true}
      />
      <Grid disabled={disabled} component={formSections[rheologyModel]}></Grid>
    </>
  );
};

export default LiquidFormSection;
