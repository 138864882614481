import React from 'react';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import useCurrentProject from 'features/projects/hooks/useCurrentProject';
import { useState } from 'react';
import { useEffect } from 'react';
import routePaths from 'app/routePaths';
import { useHeader } from 'app/hooks/useHeader';
import MobileApplicationHeaderBase from 'app/components/ApplicationHeader/Mobile/MobileApplicationHeaderBase';
import { projectStatusToString } from 'features/projects/projects.mappers';
import MobileProjectListDrawer from 'app/components/Mobile/MobileProjectListDrawer';
import { useSelector } from 'react-redux';
import { getExecuteStatusFromState } from 'features/projects/activities/activities.selectors';
import MobileUserMenuContainer from 'app/components/ApplicationHeader/Mobile/MobileUserMenuContainer';

const MobileProjectHeader = () => {
  const [status, setStatus] = useState();
  const [projectsListOpen, setProjectsListOpen] = useState(false);
  const project = useCurrentProject();
  const projectStatus = useSelector(getExecuteStatusFromState);
  const title = project.get('title');
  const progress = projectStatus.get('progress');
  useHeader({
    title,
    logoNavigation: routePaths.details,
  });
  useEffect(() => {
    if (project.get('status')) {
      setStatus(projectStatusToString(project.get('status')));
    }
  }, [project]);

  const toggleProjectsList = (isOpen) => {
    setProjectsListOpen(isOpen);
  };
  return (
    <>
      <MobileApplicationHeaderBase>
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
        >
          {project.get('id') && (
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              onClick={() => toggleProjectsList(true)}
            >
              <Grid item xs={12}>
                <Typography variant="h6" align="left">
                  {`${project.get('projectNumber')} ${project.get('title')}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  noWrap
                  variant="body2"
                  align="left"
                  color="textSecondary"
                >
                  {`${project.get('fieldName')}  •  ${project.get(
                    'wellboreName',
                  )}  •  ${status}`}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <MobileUserMenuContainer />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MobileApplicationHeaderBase>
      <LinearProgress
        title={`Progress: ${progress}%`}
        value={progress ?? 0}
        variant="determinate"
        style={{
          height: '2px',
          width: '100%',
        }}
      />
      <MobileProjectListDrawer
        projectsListOpen={projectsListOpen}
        toggleProjectsList={toggleProjectsList}
      />
    </>
  );
};

export default MobileProjectHeader;
