import { compose } from 'redux';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';

import {
  onLoad,
  onUnload,
  deleteLesson,
  selectLesson,
} from 'features/projects/lessons/lessons.actions';

import { useHeader } from 'app/hooks/useHeader';
import { Fab } from 'app/components/withTooltip';
import { ProjectPermission } from 'app/app.constants';
import { getActionDataStateFromState } from 'app/app.selectors';
import LessonsTable from 'features/projects/lessons/LessonsTable';
import HasProjectPermission from 'app/components/HasProjectPermission';
import { getCurrentProject } from 'features/projects/projects.selectors';
import { LESSONS_ACTIONS } from 'features/projects/lessons/lessons.constants';
import { getLessonsFromState } from 'features/projects/lessons/lessons.selectors';
import EditLessonModal from 'features/projects/lessons/components/EditLessonModalContainer';
import CreateLessonModal from 'features/projects/lessons/components/CreateLessonModalContainer';

const LessonsContainer = ({
  lessons,
  classes,
  project,
  dataState,
  projectId,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchSelectLesson,
  dispatchDeleteLesson,
}) => {
  useEffect(() => {
    dispatchOnLoad(projectId);

    return () => {
      dispatchOnUnload();
    };
  }, [dispatchOnLoad, dispatchOnUnload, projectId]);

  useHeader({
    subTitle: breadcrumb,
  });

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
      }}
    >
      <Grid container item xs justifyContent="center">
        <Grid item xs={8}>
          <LessonsTable
            lessons={lessons}
            projectId={projectId}
            editLesson={dispatchSelectLesson}
            deleteLesson={dispatchDeleteLesson}
          />
        </Grid>
      </Grid>
      <HasProjectPermission permissions={ProjectPermission.CREATE_LESSONS}>
        <CreateLessonModal project={project.toJS()}>
          <Fab color="primary" title="New lesson" className={classes.create}>
            <AddIcon />
          </Fab>
        </CreateLessonModal>
      </HasProjectPermission>
      <HasProjectPermission permissions={ProjectPermission.EDIT_LESSONS}>
        <EditLessonModal project={project.toJS()} />
      </HasProjectPermission>
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  project: getCurrentProject(state),
  lessons: getLessonsFromState(state),
  dataState: getActionDataStateFromState(
    state,
    LESSONS_ACTIONS.PROJECT_LESSONS_PAGE_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoad,
  dispatchOnUnload: onUnload,
  dispatchSelectLesson: selectLesson,
  dispatchDeleteLesson: deleteLesson,
};

const styles = (theme) => ({
  create: {
    position: 'absolute',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
  basePageChildren: {
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(LessonsContainer);
