import { compose } from 'redux';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDropzone } from 'react-dropzone';
import { connect, useSelector } from 'react-redux';
import withStyles from '@material-ui/styles/withStyles';
import { useEffect, useCallback, useMemo, useState } from 'react';

import { BasePage } from 'altus-ui-components';

import {
  onLoad,
  deleteFile,
  updateFile,
  uploadFiles,
  downloadFile,
} from 'features/wells/wellboreFiles.actions';

import {
  getSummarizedDataStateFromState,
  getAvailableFileCategoriesFromState,
} from 'app/app.selectors';

import { setFile } from 'app/app.actions';
import { useHeader } from 'app/hooks/useHeader';
import { Fab } from 'app/components/withTooltip';
import { EMPTY_SET, MAX_UPLOAD_FILE_SIZE } from 'app/app.constants';
import UploadDocumentModal from 'app/components/UploadDocumentModal';
import WellboreFilesTable from 'features/wells/components/WellboreFilesTable';
import { WELLBORE_FILE_ACTIONS } from 'features/wells/wellboreFiles.constants';
import { getWellboreFilesFromState } from 'features/wells/wellboreFiles.selectors';
import DocumentsFilter from 'features/projects/documents/components/DocumentsFilter';

const WellboreFilesContainer = ({
  classes,
  dataState,
  wellboreId,
  fieldId,
  breadcrumb,
  dispatchOnLoad,
  dispatchDropFiles,
  dispatchDeleteFile,
  dispatchUpdateFile,
  dispatchUploadFiles,
  dispatchDownloadFile,
}) => {
  useHeader({ subTitle: breadcrumb });

  useEffect(() => {
    dispatchOnLoad(wellboreId, fieldId);
  }, [wellboreId, dispatchOnLoad, fieldId]);

  const [filter, setFilter] = useState(EMPTY_SET);

  const documents = useSelector(getWellboreFilesFromState);
  const documentCategories = useSelector(getAvailableFileCategoriesFromState);

  const filteredDocuments = useMemo(() => {
    if (filter.isEmpty()) return documents;

    return documents.filter((document) => {
      const documentCategory = documentCategories.get(document.get('category'));

      return filter.includes(documentCategory);
    });
  }, [filter, documents, documentCategories]);

  useEffect(() => {
    // clear filter if deleting the last document to prevent a filter being active when no documents match it
    if (filteredDocuments.isEmpty()) {
      setFilter(EMPTY_SET);
    }
  }, [filteredDocuments]);

  const downloadFile = useCallback(
    (wellboreFileId, wellboreFile) =>
      dispatchDownloadFile(wellboreId, wellboreFileId, wellboreFile, fieldId),
    [wellboreId, dispatchDownloadFile, fieldId],
  );

  const deleteFile = useCallback(
    (wellboreFileId) => dispatchDeleteFile(wellboreId, wellboreFileId, fieldId),
    [wellboreId, dispatchDeleteFile, fieldId],
  );

  const updateFile = useCallback(
    (wellboreFile) =>
      dispatchUpdateFile(
        wellboreId,
        wellboreFile.fileId,
        wellboreFile,
        fieldId,
      ),
    [wellboreId, dispatchUpdateFile, fieldId],
  );

  const uploadFiles = useCallback(
    (wellboreFiles) => dispatchUploadFiles(wellboreId, fieldId)(wellboreFiles),
    [wellboreId, dispatchUploadFiles, fieldId],
  );

  const onDrop = useCallback(
    (wellboreFiles) => dispatchDropFiles(wellboreFiles),
    [dispatchDropFiles],
  );

  const { isDragActive, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      maxSize: MAX_UPLOAD_FILE_SIZE,
    });

  return (
    <BasePage
      dataState={dataState}
      classes={{
        children: classes.basePageChildren,
        root: classNames({
          [classes.dragOver]: isDragActive,
        }),
      }}
    >
      <Grid item container xs {...getRootProps()}>
        <Grid item xs={2}>
          <DocumentsFilter
            filter={filter}
            documents={documents}
            setFilter={setFilter}
            documentCategories={documentCategories}
          />
        </Grid>
        <Grid item xs={8}>
          <WellboreFilesTable
            updateFile={updateFile}
            deleteFile={deleteFile}
            downloadFile={downloadFile}
            documents={filteredDocuments}
            documentCategoriesById={documentCategories}
            noItemsMessage="Upload one or more documents simultaneously by using the + button or drag them here"
          />
        </Grid>
        <Grid item xs={2}>
          <UploadDocumentModal
            uploadFiles={uploadFiles}
            rejectedFiles={fileRejections}
            availableFileCategories={documentCategories.valueSeq()}
            dialogContentText="Set meta data and upload files to project"
          />
          <Fab
            onClick={open}
            color="primary"
            title="Upload documents"
            className={classes.create}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <input {...getInputProps()} />
    </BasePage>
  );
};

const mapStateToProps = (state) => ({
  dataState: getSummarizedDataStateFromState(
    state,
    WELLBORE_FILE_ACTIONS.DOWNLOAD_WELLBORE_FILE,
    WELLBORE_FILE_ACTIONS.UPLOAD_WELLBORE_FILE,
    WELLBORE_FILE_ACTIONS.UPDATE_WELLBORE_FILE,
    WELLBORE_FILE_ACTIONS.DELETE_WELLBORE_FILE,
    WELLBORE_FILE_ACTIONS.WELLBORE_FILES_PAGE_LOADED,
  ),
});

const mapDispatchToProps = {
  dispatchOnLoad: onLoad,
  dispatchDropFiles: setFile,
  dispatchDeleteFile: deleteFile,
  dispatchUpdateFile: updateFile,
  dispatchUploadFiles: uploadFiles,
  dispatchDownloadFile: downloadFile,
};

const styles = (theme) => ({
  create: {
    zIndex: 2,
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  dragOver: {
    opacity: '0.3',
    border: '5px gray dashed',
  },
  documentList: {
    height: '100%',
  },
  basePageChildren: {
    flexDirection: 'row',
    paddingTop: theme.spacing(2.25),
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(WellboreFilesContainer);
