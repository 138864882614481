import { useMemo, useCallback, useState } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { Table, TableRowActionsCell } from 'altus-ui-components';
import { useToggle } from 'altus-hooks';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReportTableRow from 'features/projects/report/ReportTableRow';
import WorkItemStatus from 'features/projects/components/WorkItemStatus';
import DurationComparison from 'features/projects/report/components/DurationComparison';
import DashboardIcon from 'app/components/Icons/DashboardIcon';
import ProjectDashboardsDrawer from 'features/projects/dashboard/components/ProjectDashboardsDrawer';
import { WORK_ITEM_STATUS } from 'app/app.constants';

const ReportTable = ({
  tasks,
  classes,
  diffDuration,
  spentDuration,
  formatDuration,
  estimatedDuration,
  projectId = 0,
  project,
}) => {
  const checkTaskStatus = (status) =>
    status === WORK_ITEM_STATUS.COMPLETED ||
    status === WORK_ITEM_STATUS.STARTED ||
    status === WORK_ITEM_STATUS.PAUSED ||
    status === WORK_ITEM_STATUS.FAILED;
  const [isOpen, toggleDrawer] = useToggle();
  const [taskID, setTaskID] = useState(0);

  const handleToggle = useCallback(
    (event, taskId) => {
      toggleDrawer(event);
      setTaskID(taskId);
      event.stopPropagation();
    },
    [toggleDrawer],
  );

  const columns = useMemo(
    () => [
      {
        xs: 1,
        id: 'placeholder',
        Cell: ({ row }) => {
          const task = row.original;
          return (
            <Grid item xs={1}>
              <WorkItemStatus status={task.get('status')} />
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Task',
        accessor: (task) => task.get('title'),
      },
      {
        xs: 2,
        Header: 'Time planned',
        Cell: ({ row }) => {
          const task = row.original;
          const estDuration = estimatedDuration(task);
          return (
            <Grid item xs={2}>
              <Typography variant="body2">
                {estDuration ? formatDuration(estDuration) : null}
              </Typography>
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Time spent',
        Cell: ({ row }) => {
          const task = row.original;
          const spentTime = spentDuration(task);
          return (
            <Grid item xs={2}>
              <Typography variant="body2">
                {spentTime ? formatDuration(spentTime) : null}
              </Typography>
            </Grid>
          );
        },
      },
      {
        xs: 2,
        Header: 'Difference',
        Cell: ({ row }) => {
          const task = row.original;
          return (
            <Grid item xs={2}>
              <Typography variant="body2" className={classes.timeDifference}>
                {diffDuration(task) ? formatDuration(diffDuration(task)) : ''}
              </Typography>
            </Grid>
          );
        },
      },
      {
        xs: 2,
        id: 'comparison',
        Cell: ({ row }) => {
          const task = row.original;
          return (
            <Grid item xs={3}>
              <DurationComparison
                diffDuration={diffDuration(task)}
                durations={[
                  [
                    estimatedDuration(task),
                    'Time estimated',
                    classes.progressBarDisabled,
                  ],
                  [spentDuration(task), 'Time spent', classes.progressBarLess],
                  [
                    diffDuration(task),
                    'Difference',
                    classes.progressBarMore,
                    'flex-end',
                  ],
                ]}
              />
            </Grid>
          );
        },
      },
      {
        xs: 1,
        id: 'dashboard',
        Cell: ({ row }) => {
          const task = row.original;
          return checkTaskStatus(task.get('status')) ? (
            <Grid item>
              <IconButton
                color="default"
                onClick={(event) => handleToggle(event, task.get('taskId'))}
              >
                <DashboardIcon />
              </IconButton>
            </Grid>
          ) : null;
        },
      },
      {
        id: 'expansionIcon',
        Header: <TableRowActionsCell minItems={1} />,
        Cell: () => (
          <TableRowActionsCell>
            <ExpandMoreIcon />
          </TableRowActionsCell>
        ),
      },
    ],
    [
      classes.progressBarDisabled,
      classes.progressBarLess,
      classes.progressBarMore,
      classes.timeDifference,
      diffDuration,
      estimatedDuration,
      formatDuration,
      spentDuration,
      handleToggle,
    ],
  );

  return (
    <>
      <Table
        items={tasks}
        columns={columns}
        useGlobalFilter={false}
        TableRowComponent={ReportTableRow}
        noItemsMessage="There are no tasks on this project."
      />
      <ProjectDashboardsDrawer
        project={project}
        projectId={projectId}
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        taskId={taskID}
      />
    </>
  );
};

const styles = (theme) => ({
  progressBarLess: {
    backgroundColor: theme.altus.status.report,
  },
  progressBarDisabled: {
    backgroundColor: theme.palette.grey[700],
  },
  progressBarMore: {
    backgroundColor: theme.palette.error.main,
  },
  timeDifference: {
    color: theme.palette.error.main,
  },
});

export default withStyles(styles)(ReportTable);
